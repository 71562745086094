
































import { defineComponent } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'

export default defineComponent({
  name: 'ErrorBodySection',
  components: {
    ActionButtonsSection,
    AtomLinkButton,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    descriptions: {
      type: [],
      default: () => [],
    },
  },
  setup() {
    const loginUrl = `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/login`

    return {
      loginUrl,
    }
  },
})
