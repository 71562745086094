import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 時刻同期APIのレスポンスデータを表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/4003561552/API
 */
export default class CurrentTimeDocument extends DocumentWrapper {
  constructor(initProps?: Partial<CurrentTimeDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'time/current'

  idAttr = ''

  /**
   * クライアントのAPI呼び出し時刻
   */
  t1: number | null = null

  /**
   * サーバがリクエストを受け付けた時刻
   */
  t2: number | null = null

  /**
   * サーバがレスポンスを返却する時刻
   */
  t3: number | null = null
}
