


























import { defineComponent, PropType } from '@vue/composition-api'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'

/**
 * HOME SNSコンテンツ 投稿アイテム ヘッダーパーツ
 */
export default defineComponent({
  name: 'SnsPostItemHeaderParts',
  props: {
    snsPost: {
      type: Object as PropType<SnsPostData>,
      required: true,
    },
  },
})
