















































































































































import dayjs from 'dayjs'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api'
import AtomButton from '@/components/atoms/AtomButton.vue'
import MissionRankingSharePostSection from '@/components/MissionPage/MissionRankingPane/MissionRankingSharePostSection.vue'
import DeviceInfo from '@/util/DeviceInfo'
import {
  RankingUserImageDataType,
  ShareRankingImageType,
  RankingImageInfoType,
} from '@/store/stores/pageStore/MissionPage/MissionPageStore'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import Const from '@/util/Const'

type ImagePathStyleType = {
  backgroundImage: string
}

export default defineComponent({
  name: 'MissionRankingShareSection',
  components: { MissionRankingSharePostSection, AtomButton },
  props: {
    shareImageUrl: {
      type: String,
      default: '',
    },
    backgroundImage: {
      type: Object as PropType<RankingImageInfoType | null | undefined>,
      default: () => ({}),
    },
    backgroundImagePath: {
      type: String,
      default: '',
    },
    visiblePostSnsModal: {
      type: Boolean,
      default: false,
    },
    rankingUserImageData: {
      type: Object as PropType<RankingUserImageDataType>,
      default: () => ({}),
    },
    shareRankingImageInfo: {
      type: Object as PropType<ShareRankingImageType>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const { getDisplayDateJa } = useDisplayDependingOnLang()

    // XのURL
    const xUrl = Const.EXTERNAL_LINKS.X.HOME
    // InstagramのURL
    const instagramUrl = Const.EXTERNAL_LINKS.INSTAGRAM.HOME

    const backgroundImagePathStyle = computed(
      () =>
        ({
          backgroundImage: `url(${props.backgroundImagePath})`,
        } as ImagePathStyleType),
    )

    const isPc = DeviceInfo.isForPC()

    // 表示日時
    const today = getDisplayDateJa(dayjs().valueOf(), 'YYYY / MM / DD')

    // X, Instagramに投稿するが押下されたときの処理
    const openSnsPostModal = async () => {
      context.emit('openSnsPostModal', today)
    }

    /**
     * SNS投稿ボタンをクリックした場合に呼び出される。
     * @param url 外部サイトのリンク
     */
    const onClickAnchorLink = (url: string) => {
      // 外部リンクを開く
      window.open(url, '_blank')
      context.emit('savePostSnsMissionHistory')
    }

    // 画像をダウンロードするが押下されたときの処理
    const handleImageDownloadClicked = async () => {
      context.emit('handleImageDownloadClicked', today)
    }

    const isWideDevice = ref(false)

    const onClickCancel = () => {
      context.emit('onClickCancel')
    }

    const onClickPostSns = async (
      base64: string,
      comment: string,
      isPostX: boolean,
      isPostInstagram: boolean,
    ) => {
      context.emit('onClickPostSns', base64, comment, isPostX, isPostInstagram)
    }

    const checkDeviceWide = () => {
      isWideDevice.value = window.innerHeight > 700
    }

    onMounted(() => {
      checkDeviceWide()
      window.addEventListener('resize', checkDeviceWide)
    })

    onBeforeUnmount(() => {
      window.addEventListener('resize', checkDeviceWide)
    })

    return {
      backgroundImagePathStyle,
      today,
      handleImageDownloadClicked,
      openSnsPostModal,
      isWideDevice,
      isPc,
      onClickAnchorLink,
      xUrl,
      instagramUrl,
      onClickCancel,
      onClickPostSns,
    }
  },
})
