var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "sub-header-section",
      class: {
        "sub-header-section--no-link":
          !_vm.execTransitionEventParent && _vm.backLink === "",
      },
    },
    [
      !_vm.canReturnToAppLink && _vm.backLink
        ? [
            _vm.backLink
              ? _c(
                  "router-link",
                  {
                    staticClass: "sub-header-section__back-link",
                    attrs: {
                      to: !_vm.isPurchasingInAppPurchase ? _vm.backLink : "",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/icon_chevron_left_18.svg"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        : _vm.backLink
        ? [
            _c(
              "a",
              {
                staticClass: "sub-header-section__back-link",
                class: {
                  "sub-header-section__back-link--disabled":
                    _vm.isPurchasingInAppPurchase,
                },
                attrs: {
                  href: _vm.backLink,
                  target: _vm.target,
                  rel: "noopener noreferrer",
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/icon_chevron_left_18.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        : _vm.execTransitionEventParent
        ? [
            _c(
              "a",
              {
                staticClass: "sub-header-section__back-link",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.onClick()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/icon_chevron_left_18.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        : _vm._e(),
      _c("h2", {
        staticClass: "sub-header-section__title",
        domProps: { innerHTML: _vm._s(_vm.pageTitle) },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }