



































import { defineComponent, ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageLanguageSettingPane from '@/components/MypageLanguageSettingPage/MypageLanguageSettingPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import ConfirmLeavingPageModalSection from '@/components/common/modal/ConfirmLeavingPageModalSection.vue'

export type DataType = {
  screenTransitionModal: boolean
  screenTransitionTo: string
}

/**
 * マイページ: 言語選択ページ
 */
export default defineComponent({
  name: 'MypageLanguageSettingPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageLanguageSettingPane,
    ActionButtonsSection,
    AtomInputButton,
    ConfirmLeavingPageModalSection,
  },
  data(): DataType {
    return {
      screenTransitionModal: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '', // 画面遷移警告モーダル 遷移先指定
    }
  },
  setup() {
    const mypageLanguageSettingPane = ref<InstanceType<typeof MypageLanguageSettingPane>>()
    return {
      mypageLanguageSettingPane,
    }
  },
  methods: {
    /**
     * 変更確認通知
     */
    setLanguageSetting() {
      this.mypageLanguageSettingPane?.changeLanguage()
    },
    /**
     * マイページTOPページに遷移する
     */
    moveToMypageTop(): void {
      this.$router.replace({ path: '/mypage' })
    },
  },
})
