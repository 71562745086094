export default function useConvertObjectKeysType() {
  /**
   * Object.keys()の型がstring[]になってしまうため、型推論が効くようにする
   * Object.keys()のラッパー関数を定義し、ジェネリック型を利用して型注釈を付けることで解消する
   * @param obj オブジェクト
   * @see https://github.com/apache/cordova-plugin-device#devicemodel
   */
  const getKeys = <T extends { [key: string]: unknown }>(obj: T): (keyof T)[] => Object.keys(obj)

  return {
    getKeys,
  }
}
