var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-video-page",
      class: [
        "race-video-page--" + _vm.screenOrientationType,
        "race-video-page--" + _vm.viewMode,
        { "race-video-page--full-scale": _vm.videoFullScale },
      ],
    },
    [
      _vm.isFetchedRaceInfo
        ? _c("race-video-pane", {
            staticClass: "race-video-page__video",
            class: [
              { "race-video-page__video--single": _vm.viewMode === "single" },
              { "race-video-page__video--full-scale": _vm.videoFullScale },
            ],
            attrs: {
              viewMode: _vm.viewMode,
              screenOrientationType: _vm.screenOrientationType,
              lockOrientation: _vm.lockOrientation,
              videoFullScale: _vm.videoFullScale,
              highlightId: _vm.highlightId,
              animateGpsMapCar: _vm.animateGpsMapCar,
              hasVolumeControl: _vm.hasVolumeControl,
              movieStartActualTime: _vm.movieStartActualTime,
              togglePlayMode: _vm.togglePlayMode,
            },
            on: {
              viewModeChange: _vm.changeViewMode,
              fullScaleVideo: _vm.changeFullScaleVideo,
              setOrientationTimer: _vm.setOrientationTimer,
              handleTouchStartSeekBar: _vm.handleTouchStartSeekBar,
              handleTouchEndSeekBar: _vm.handleTouchEndSeekBar,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.screenOrientationType.match("portrait") ||
                (_vm.screenOrientationType.match("landscape") &&
                  _vm.viewMode !== "single"),
              expression:
                "\n      screenOrientationType.match('portrait') ||\n      (screenOrientationType.match('landscape') && viewMode !== 'single')\n    ",
            },
          ],
          staticClass: "race-video-page__data",
          class: [
            {
              "race-video-page__data--view-modal":
                _vm.racePlayerSelectModal ||
                _vm.radioVoiceEnabled ||
                (_vm.showHighlightsModal && _vm.isPc) ||
                (_vm.viewMode === "highlight" &&
                  _vm.noticeHighlightOnboardingVisible) ||
                (_vm.viewMode === "highlight" && _vm.highlightSlideMenu) ||
                (_vm.viewMode === "highlight" && _vm.showBlockConfirmModal),
            },
            {
              "race-video-page__data--view-race-select": _vm.raceSelectEnabled,
            },
          ],
        },
        [
          _vm.isFetchedUserInfo && _vm.viewMode === "telemetry"
            ? _c("race-video-data-view-pane", {
                staticClass: "race-video-page__telemetry",
                attrs: {
                  lockOrientation: _vm.lockOrientation,
                  radioVoiceEnabled: _vm.radioVoiceEnabled,
                  screenOrientationType: _vm.screenOrientationType,
                  racePlayerSelectModal: _vm.racePlayerSelectModal,
                  raceSelectEnabled: _vm.raceSelectEnabled,
                  livePlayerRadioEnabled: _vm.livePlayerRadioEnabled,
                },
                on: {
                  radioVoiceToggle: _vm.toggleRadioVoice,
                  radioLiveToggle: _vm.toggleRadioLive,
                  raceSelectToggle: _vm.toggleRaceSelect,
                  playerSelectModalShow: _vm.showPlayerSelectModal,
                },
              })
            : _vm.viewMode === "ranking"
            ? _c("race-video-ranking-pane", {
                staticClass: "race-video-page__ranking",
                attrs: {
                  lockOrientation: _vm.lockOrientation,
                  radioVoiceEnabled: _vm.radioVoiceEnabled,
                  screenOrientationType: _vm.screenOrientationType,
                  racePlayerSelectModal: _vm.racePlayerSelectModal,
                  raceSelectEnabled: _vm.raceSelectEnabled,
                  livePlayerRadioEnabled: _vm.livePlayerRadioEnabled,
                },
                on: {
                  radioVoiceToggle: _vm.toggleRadioVoice,
                  radioLiveToggle: _vm.toggleRadioLive,
                  raceSelectToggle: _vm.toggleRaceSelect,
                  playerSelectModalShow: _vm.showPlayerSelectModal,
                },
              })
            : _vm.viewMode === "radio"
            ? _c("race-video-radio-pane", {
                staticClass: "race-video-page__radio",
                attrs: {
                  lockOrientation: _vm.lockOrientation,
                  screenOrientationType: _vm.screenOrientationType,
                },
              })
            : _vm.viewMode === "gps"
            ? _c("race-video-gps-pane", {
                staticClass: "race-video-page__gps",
                attrs: {
                  radioVoiceEnabled: _vm.radioVoiceEnabled,
                  screenOrientationType: _vm.screenOrientationType,
                  lockOrientation: _vm.lockOrientation,
                  racePlayerSelectModal: _vm.racePlayerSelectModal,
                  raceSelectEnabled: _vm.raceSelectEnabled,
                  animateGpsMapCar: _vm.animateGpsMapCar,
                },
                on: {
                  radioVoiceToggle: _vm.toggleRadioVoice,
                  radioLiveToggle: _vm.toggleRadioLive,
                  raceSelectToggle: _vm.toggleRaceSelect,
                  playerSelectModalShow: _vm.showPlayerSelectModal,
                },
              })
            : _vm._e(),
          _vm.screenOrientationType.match("portrait")
            ? _c("data-view-select-menu-section", {
                staticClass: "race-video-page__menu",
                attrs: {
                  viewMode: _vm.viewMode,
                  screenOrientationType: _vm.screenOrientationType,
                },
                on: { viewModeChange: _vm.changeViewMode },
              })
            : _vm._e(),
          _vm.isFetchedRaceInfo
            ? _c("race-video-highlights-pane", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.viewMode === "highlight",
                    expression: "viewMode === 'highlight'",
                  },
                ],
                staticClass: "race-video-page__highlights",
                class: {
                  "race-video-page__highlights--view-filter":
                    _vm.highlightFilterSelect,
                },
                attrs: {
                  screenOrientationType: _vm.screenOrientationType,
                  lockOrientation: _vm.lockOrientation,
                  viewMode: _vm.viewMode,
                  inputComment: _vm.inputComment,
                  noticeHighlightOnboardingVisible:
                    _vm.noticeHighlightOnboardingVisible,
                  scrollToHighlightId: _vm.scrollToHighlightId,
                  highlightSlideMenu: _vm.highlightSlideMenu,
                },
                on: {
                  highlightFilterSelectShow: _vm.showHighlightFilterSelect,
                  openHighlightsModal: _vm.openHighlightsModal,
                  closeHighlightsModal: _vm.closeHighlightsModal,
                  onClickNoticeOk: _vm.handleHighlightNoticeCloseClick,
                  onOpenHighlightSlideMenu: _vm.openSlideMenu,
                  onOpenHighlightCommentSlideMenu: _vm.openSlideMenu,
                  onCloseSlideMenu: _vm.closeSlideMenu,
                  openBlockModal: function ($event) {
                    _vm.showBlockConfirmModal = true
                  },
                  closeBlockModal: function ($event) {
                    _vm.showBlockConfirmModal = false
                  },
                },
              })
            : _vm._e(),
          _c("radio-select-section", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.radioVoiceEnabled,
                expression: "radioVoiceEnabled",
              },
            ],
            staticClass: "race-video-page__radio-modal",
            attrs: {
              screenOrientationType: _vm.screenOrientationType,
              livePlayerRadioEnabled: _vm.livePlayerRadioEnabled,
              radioVoiceEnabled: _vm.radioVoiceEnabled,
            },
            on: {
              radioLiveToggle: _vm.toggleRadioLive,
              radioVoiceToggle: _vm.toggleRadioVoice,
            },
          }),
          _vm.hasSubVideo &&
          _vm.isSubVideoPlayerReady &&
          !_vm.isRebuildingSubVideoPane &&
          !_vm.isCircuitMode
            ? _c("sub-video-pane", {
                staticClass: "race-video-page__sub-video",
                on: { handlerReCreateElement: _vm.reCreateElement },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }