




import { computed, defineComponent, PropType } from '@vue/composition-api'
import { BadgeData } from '@/components/MypageTopPage/MembershipCardPane/parts/MemberBadgeItemParts.vue'

/* eslint-disable @typescript-eslint/no-var-requires */
const srcSupporter = require('@/assets/img/icon/badge_supporter.svg')

/**
 * マイページ: 会員証 バッヂ画像パーツ
 */
export default defineComponent({
  name: 'BadgeImageParts',
  props: {
    badge: {
      type: Object as PropType<BadgeData>,
      required: true,
    },
  },
  setup(props) {
    const badgeSrc = computed(() => (props.badge.badgeType === 'supporter' ? srcSupporter : ''))

    return {
      badgeSrc,
    }
  },
})
