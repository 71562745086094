














import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import LinkPane from '@/components/LinkPage/LinkPane.vue'

/**
 * LINK ページ
 */
export default defineComponent({
  name: 'LinkPage',
  components: {
    HeaderPane,
    GlobalNavigationPane,
    LinkPane,
  },
})
