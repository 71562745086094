var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "radio-header-section" }, [
    _c("div", { staticClass: "radio-header-section__inner" }, [
      !_vm.screenLandScape || _vm.lockOrientation !== undefined
        ? _c("h3", { staticClass: "radio-header-section__title" }, [
            _vm._v(" " + _vm._s(_vm.$t("RaceVideoPage.Radio.title")) + " "),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "radio-header-section__filter" },
        [
          _c("filter-selector-parts", {
            staticClass: "radio-header-section__filter__item",
            attrs: {
              defaultItemLabel: "Driver",
              defaultListItemLabel: "Driver (all)",
              selectID: _vm.driverFilterId,
              filterList: _vm.driverFilterList,
            },
            on: { filterChange: _vm.changeDriverFilter },
          }),
          _c("filter-selector-parts", {
            staticClass: "radio-header-section__filter__item",
            attrs: {
              defaultItemLabel: "Lap",
              defaultListItemLabel: "Lap (all)",
              selectID: _vm.lapFilterId,
              filterList: _vm.lapFilterItems,
            },
            on: { filterChange: _vm.changeLapFilter },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }