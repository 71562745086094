var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-user-complete-section" }, [
    _c("p", [
      _vm._v(
        _vm._s(_vm.$t("MypagePage.MypageUserCompletePage.completeMessage"))
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }