






import { defineComponent } from '@vue/composition-api'
import PasswordResetMailSentSection from '@/components/PasswordResetPage/PasswordResetMailSentPane/PasswordResetMailSentSection.vue'

/**
 * パスワードリセット 認証メール送信完了ペイン
 */
export default defineComponent({
  name: 'PasswordResetMailSentPane',
  components: {
    PasswordResetMailSentSection,
  },
})
