












import { defineComponent } from '@vue/composition-api'

/**
 * ボタン(button)を表示するAtomコンポーネント。
 */
export default defineComponent({
  name: 'AtomInputButton',
  props: {
    /**
     * リンクの文字
     */
    linkText: {
      type: String,
      required: true,
    },
    /**
     * ボタンを非活性にするかどうか
     */
    isDisabled: {
      type: Boolean,
      default: true,
    },
    /**
     * テーマカラー指定
     */
    theme: {
      type: String,
      default: 'light',
    },
    /**
     * カラー指定
     */
    colorType: {
      type: String,
      default: 'primary',
    },
    /**
     * サイズ指定
     */
    size: {
      type: String,
      default: 'large',
    },
  },
  methods: {
    push() {
      /**
       * ボタンが押下されたことを通知する
       * @event click
       * @type {Object}
       */
      this.$emit('push')
    },
  },
})
