import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import RankingRecord from '@/store/stores/collectionModule/documents/liveTiming/RankingRecord'
import { type RaceType } from '@/store/stores/collectionModule/documents/liveTiming/types/RaceType'

/**
 * ランキングデータのランキングデータ種別の型
 */
export type RankingDataType =
  | 'STANDINGS'
  | 'TOP_SPEED'
  | 'FASTEST_LAP'
  | 'FASTEST_SECTOR1'
  | 'FASTEST_SECTOR2'
  | 'FASTEST_SECTOR3'
  | 'FASTEST_SECTOR4'

/**
 * ライブタイミング集計のランキングデータ情報を表現するクラス。
 * ライブタイミング集計データの rankingDataSet配列内の要素のデータを表現する。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3421307066/09.+Race+Rank
 */
export default class RankingData extends DocumentWrapper {
  constructor(season: string, raceType: RaceType, initProps?: Partial<RankingData>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    const rankings = [] as Array<RankingRecord>
    initProps?.rankings?.forEach((rankingRecord) => {
      rankings.push(new RankingRecord(rankingRecord))
    })
    this.season = season
    this.raceType = raceType
    this.rankings = rankings
  }

  /**
   * レースシーズン
   * レースカテゴリと年度を合わせた文字列を指定する。
   * 例: SuperFormula_2023
   */
  season: string | null = null

  /**
   * レース種別
   * R: レース（決勝）
   * B: レース以外(予選)
   */
  raceType: RaceType | null = null

  /**
   * ランキングデータ種別
   */
  rankingDataType: RankingDataType | null = null

  /**
   * ランキング
   */
  rankings: Array<RankingRecord> = []
}
