











































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { HighlightSceneType } from '@/components/NotificationPane/NotificationPane.vue'

/**
 * Notification: ハイライトカード パーツコンポーネント
 */
export default defineComponent({
  name: 'HighlightItemParts',
  props: {
    highlightData: {
      type: Object as PropType<HighlightSceneType>,
      required: true,
    },
  },
  setup(props) {
    const highlight = computed(() => props.highlightData)

    return {
      highlight,
    }
  },
  methods: {},
})
