











import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CancelPaidPlanConfirmPane from '@/components/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanConfirmPane.vue'

/**
 * 有料会員解約 確認ページ
 */
export default defineComponent({
  name: 'CancelPaidPlanConfirmPage',
  components: {
    SubHeaderSection,
    CancelPaidPlanConfirmPane,
  },
  methods: {
    /**
     * 登録完了ページに遷移する
     */
    moveToCancelPaidPlanComplete() {
      this.$router.replace({ name: 'CancelPaidPlanCompletePage' })
    },
    /**
     * プラン選択画面へ遷移する
     */
    handlerCancel() {
      this.$router.replace({ name: 'CancelPaidPlanEditPage' })
    },
  },
})
