














import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import HomePane from '@/components/HomePage/HomePane.vue'

/**
 * HOME ページ
 */
export default defineComponent({
  name: 'HomePage',
  components: {
    HeaderPane,
    HomePane,
  },
  props: {
    /**
     * ヘッダーナビ表示指定
     */
    hasHeaderNav: {
      type: Boolean,
      default: true,
    },
  },
})
