










import { computed, defineComponent } from '@vue/composition-api'

export type DataType = {
  scrollView: boolean
}

/**
 * 共通ペイン: フッターボタンアクションシート
 */
export default defineComponent({
  name: 'ActionButtonsSection',
  components: {},
  data() {
    return {
      /**
       * スクロール計測時 表示フラグ
       */
      scrollView: false,
    }
  },
  props: {
    /**
     * スクロール計測ターゲット
     */
    scrollTargetSelector: {
      type: String,
      default: '',
    },
    /**
     * 初期表示フラグ
     */
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const scrollTargetDom = computed(() => document.querySelector(props.scrollTargetSelector))
    return {
      scrollTargetDom,
    }
  },
  mounted() {
    /**
     * ターゲットが指定されていれば、最下部までスクロールで表示
     */
    if (this.scrollTargetDom) {
      this.scrollTargetDom.addEventListener('scroll', this.watchScrollTarget, false)
      // 入力項目が少ないとスクロールが発生しない場合があるため、最初に一度だけ強制的に呼び出す
      this.$nextTick(() => {
        this.watchScrollTarget()
      })
    }
  },
  beforeDestroy() {
    /**
     * ターゲットが指定されていれば、リスナー解除
     */
    if (this.scrollTargetDom) {
      this.scrollTargetDom.removeEventListener('scroll', this.watchScrollTarget, false)
    }
  },
  methods: {
    /**
     * スクロール量監視
     * 最下部まで表示されたらDOMを表示
     */
    watchScrollTarget() {
      if (this.scrollTargetDom) {
        const scrollTargetHeight: number = this.scrollTargetDom.clientHeight
        const scrollValue: number = this.scrollTargetDom.scrollHeight
        if (
          scrollValue - (scrollTargetHeight + this.scrollTargetDom.scrollTop) < 20 ||
          this.visible
        ) {
          this.scrollView = true
        }
      }
    },
  },
})
