var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-switch-to-paid-plan-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageSwitchToPaidPlanPage.title"),
          backLink: "/mypage",
          isPurchasingInAppPurchase: _vm.isPurchasingInAppPurchase,
        },
      }),
      _c("mypage-switch-to-paid-plan-pane"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }