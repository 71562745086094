import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * ImageUploadDocument
 * (FL-UX: MasterUploadDocument)
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/359137582/01+API
 */
export default class ImageUploadDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ImageUploadDocument>) {
    super(initProps as Partial<ImageUploadDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/media/upload'

  /**
   * アップロード/削除する画像のコレクション名
   */
  master: string | null = null

  /**
   * アップロード/削除する画像のフィールド名
   */
  field: string | null = null

  /**
   * アップロード/削除対象のコレクションのプライマリーキー
   */
  pk: string | null = null
}
