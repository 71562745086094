var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standings-player-details-section" },
    [
      _vm.playerData.headerImgPath
        ? _c(
            "div",
            { staticClass: "standings-player-details-section__header" },
            [
              _c("img", {
                staticClass: "standings-player-details-section__header-img",
                attrs: { src: _vm.playerData.headerImgPath, alt: "" },
              }),
              _c(
                "svg",
                {
                  staticClass: "standings-player-details-section__header-mask",
                  attrs: {
                    width: "375",
                    height: "88",
                    viewBox: "0 0 375 88",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                    preserveAspectRatio: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: { d: "M0 87.5589L375 0V87.5589H0Z", fill: "white" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "standings-player-details-section__main" }, [
        _c(
          "figure",
          { staticClass: "standings-player-details-section__player-img" },
          [
            _c("img", {
              attrs: {
                src: _vm.playerData.playerImgPath,
                alt: _vm.playerData.name,
              },
            }),
          ]
        ),
        _c(
          "div",
          { staticClass: "standings-player-details-section__content" },
          [
            _c(
              "div",
              { staticClass: "standings-player-details-section__profile" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "standings-player-details-section__profile-name",
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.playerData.name) },
                    }),
                  ]
                ),
                _c("p", {
                  staticClass: "standings-player-details-section__profile-team",
                  domProps: { textContent: _vm._s(_vm.playerData.team) },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "standings-player-details-section__other" },
              [
                _c(
                  "button",
                  {
                    staticClass: "standings-player-details-section__favorite",
                    class: {
                      "standings-player-details-section__favorite--active":
                        _vm.isFavoritePlayer,
                    },
                    attrs: { type: "button" },
                    on: { click: _vm.onclickFavorite },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "18",
                          viewBox: "0 0 18 18",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M16.0133 5.56322L16.0141 5.56619C16.0926 5.86807 16.1299 6.17916 16.1251 6.49103L16.125 6.49917L16.125 6.5073C16.1287 7.09584 15.9738 7.67451 15.6765 8.18245L15.6711 8.19161L15.666 8.20091C15.5222 8.46336 15.339 8.70371 15.1241 8.91411L15.1241 8.91408L15.1184 8.91973L9 15.0382L2.88158 8.91973L2.88159 8.91973L2.87954 8.9177C2.6614 8.70122 2.47251 8.45716 2.31767 8.19171C2.17443 7.9311 2.06269 7.65436 1.98483 7.36734C1.91183 7.08707 1.875 6.79641 1.875 6.50257H1.87509L1.87492 6.49112C1.87015 6.17919 1.90749 5.86805 1.98592 5.56611L1.98596 5.56613L1.98796 5.55806C2.13358 4.97072 2.44059 4.43584 2.87428 4.01385L2.87432 4.01389L2.88069 4.00753C3.20165 3.68765 3.58163 3.43309 3.99956 3.25796C4.83883 2.91422 5.77956 2.91401 6.61895 3.25735C7.00387 3.42078 7.35865 3.64739 7.66866 3.92774L7.69259 3.95645L7.71437 3.98259L7.73842 4.00665L8.46967 4.7379L9 5.26823L9.53033 4.7379L10.2616 4.00664L10.2856 3.98259L10.3074 3.95645L10.3313 3.92774C10.6414 3.64739 10.9961 3.42078 11.381 3.25736C12.2204 2.91401 13.1612 2.91421 14.0004 3.25796C14.4184 3.43308 14.7983 3.68764 15.1193 4.00753L15.1192 4.0076L15.128 4.01602C15.3418 4.2224 15.5227 4.46049 15.6641 4.7219L15.6702 4.73315L15.6766 4.74419C15.8266 5.00013 15.9399 5.27582 16.0133 5.56322Z",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                      ]
                    ),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.isFavoritePlayer
                            ? _vm.$tc(
                                "StandingsPage.DetailsPage.settingFavorite"
                              )
                            : _vm.$tc("StandingsPage.DetailsPage.addFavorite")
                        ),
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "standings-player-details-section__profile-link",
                    attrs: {
                      href: _vm.playerData.profileLink,
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "18",
                          viewBox: "0 0 18 18",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M7.28571 3H3V15H15V10.7143",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M14.4391 2.5H11.5001L10.0001 1H11.5001H16.0001C16.5524 1 17.0001 1.44772 17.0001 2V6.5V8L15.5001 6.5V3.5603L9.1798 9.88064L8.11914 8.81998L14.4391 2.5Z",
                            fill: "black",
                          },
                        }),
                      ]
                    ),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$tc(
                            "StandingsPage.DetailsPage.ViewDriversProfile"
                          )
                        ),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("standings-player-result-parts", {
        staticClass: "standings-player-details-section__result",
        attrs: {
          totalPosition: _vm.playerData.totalPosition,
          totalPoints: _vm.playerData.totalPoints,
        },
      }),
      _c(
        "router-link",
        {
          staticClass: "standings-player-details-section__back-link",
          attrs: { to: "/standings?fromDetails=true" },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/img/icon/icon_chevron_left_24__white.svg"),
              alt: "<",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }