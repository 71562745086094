

















































import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { EditHighlightData } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import HighlightsPlayIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsPlayIcon.vue'
import HighlightPreviewVideoPlayerSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightPreviewVideoPlayerSection.vue'
import HighlightPreviewControllerParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightPreviewControllerParts.vue'
import Logger from '@/util/logger/Logger'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultIcon = require('@/assets/img/icon/icon_user_default.png')

type DataType = {
  isPlayingVideo: boolean
  isVisibleController: boolean
  visibleControllerTimer: number
}

/**
 * レース動画再生画面 ハイライトカード作成: ハイライトカード セクション
 */
export default defineComponent({
  name: 'HighlightsCardSection',
  components: {
    HighlightPreviewControllerParts,
    HighlightsPlayIcon,
    HighlightPreviewVideoPlayerSection,
  },
  data(): DataType {
    return {
      isPlayingVideo: false,
      isVisibleController: false,
      visibleControllerTimer: 0,
    }
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * ハイライトの作成・編集で使用するデータ
     */
    highlightData: {
      type: Object as PropType<EditHighlightData>,
      required: true,
    },
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { highlightStore } = raceVideoPageStore

    const isPc = DeviceInfo.isForPC()

    const { videoPlayer, videoStatus, videoPlayerStatus } = toRefs(
      highlightStore.raceVideoPageHighlightState,
    )
    const videoPaused = computed(() => videoPlayerStatus?.value?.playStatus === 'PAUSE')
    return {
      defaultIcon,
      videoPlayer,
      videoStatus,
      videoPlayerStatus,
      videoPaused,
      isPc,
    }
  },
  methods: {
    /**
     * サムネイルの再生ボタンが押下されたときの処理
     */
    handlePlayClicked() {
      this.isPlayingVideo = true
      this.visibleController()
      // コメント入力をフォーカスする
      const element = document.getElementById('comment')
      if (element) {
        element.focus()
      }
    },
    /**
     * コントローラーを表示
     */
    visibleController() {
      this.restartOverlayTimer()
      this.isVisibleController = true
    },
    /**
     * コントローラー：表示タイマーリセット
     */
    restartOverlayTimer() {
      if (this.visibleControllerTimer) {
        window.clearTimeout(this.visibleControllerTimer)
      }
      this.visibleControllerTimer = window.setTimeout(() => {
        this.isVisibleController = false
      }, 5000)
    },
    /**
     * 現在の動画の再生時間を指定された時間に変更する。
     * @param currentTime 動画再生時間
     */
    updateCurrentTime(currentTime: number) {
      this.videoPlayer?.setCurrentTime(currentTime)
    },
    /**
     * 動画再生・一時停止処理
     * @param mode 一時停止への変更の場合、 true が設定されて呼び出される。
     */
    async changeVideoPlayPause(mode: boolean) {
      if (mode) {
        await this.videoPlayer?.pause()
      } else {
        await this.videoPlayer?.play()
      }
      Logger.debug(
        `HighlightsCardPrats#changeVideoPlayPause: videoPausedStatus: ${this.videoPlayerStatus?.playStatus}, videoPaused: ${this.videoPaused}`,
      )
    },
  },
})
