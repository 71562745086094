














import { defineComponent } from '@vue/composition-api'
import MypageUserEditHeaderSection from '@/components/MypageUserEditPage/MypageUserEditPane/MypageUserEditHeaderSection.vue'
import MypageUserInitConfirmPane from '@/components/MypageUserInitConfirmPage/MypageUserInitConfirmPane.vue'
import UserStore from '@/store/stores/pageStore/common/UserStore'

/**
 * マイページ: 初期会員情報確認ページ
 */
export default defineComponent({
  name: 'MypageUserInitConfirmPage',
  components: {
    MypageUserEditHeaderSection,
    MypageUserInitConfirmPane,
  },
  methods: {
    /**
     * 会員情報登録
     * @event registration
     */
    moveToNextPage() {
      if (UserStore.value.ownBillingInfo.value?.isAccountPending) {
        // 決済未完了状態であれば強制的にマイページに遷移させる
        this.$router.replace({ name: 'MypageTopPage' })
        return
      }

      // TOP画面に遷移
      this.$router.replace({ name: 'TopPage' })
    },
  },
})
