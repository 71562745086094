import { reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'

class ErrorStore implements StoreBase {
  /* eslint-disable class-methods-use-this */
  createStore() {
    const state = reactive({
      title: 'ErrorPage.default.title',
      message: 'ErrorPage.default.message',
      descriptions: 'ErrorPage.default.descriptions',
    })

    /**
     * エラーページ表示に必要な項目を設定する
     * @param errorKey
     */
    const setConfig = (errorKey = 'default') => {
      state.title = `ErrorPage.${errorKey}.title`
      state.message = `ErrorPage.${errorKey}.message`
      state.descriptions = `ErrorPage.${errorKey}.descriptions`
    }

    return {
      setConfig,
      /**
       * ページタイトル
       */
      get title(): string {
        return state.title
      },
      /**
       * エラーメッセージ(エラー概要)
       */
      get message(): string {
        return state.message
      },
      /**
       * 詳細や備考など
       */
      get descriptions(): string {
        return state.descriptions
      },
    }
  }
  /* eslint-enable class-methods-use-this */
}
const value: ValueType<ErrorStore> = {}

export default {
  createStore: new ErrorStore().createStore,
  value: value as Required<typeof value>,
}
