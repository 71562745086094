var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "atom-input-radio" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputValue,
          expression: "inputValue",
        },
      ],
      class: { "atom-input-radio--checked": _vm.inputValue },
      attrs: { type: "radio", disabled: _vm.isDisabled },
      domProps: { checked: _vm._q(_vm.inputValue, null) },
      on: {
        change: function ($event) {
          _vm.inputValue = null
        },
      },
    }),
    _c("span", { staticClass: "atom-input-radio__radio" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }