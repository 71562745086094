var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_288_272)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M2.25 8.24148H4.99888V9.74148H3.75V16.7415H14.25V9.74148H13V8.24148H15.75V18.2415H2.25V8.24148Z",
            fill: "currentColor",
          },
        }),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M8.24914 4.08241L6.16784 6.16371H4.04652L8.28916 1.92107C8.67969 1.53054 9.31285 1.53054 9.70338 1.92107L13.946 6.16371H11.8247L9.74914 4.08815L9.74914 13.0219L8.24914 13.0219L8.24914 4.08241Z",
            fill: "currentColor",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_288_272" } }, [
          _c("rect", {
            attrs: {
              width: _vm.width,
              height: _vm.height,
              fill: "currentColor",
              transform: "translate(0 0.713867)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }