import { computed, inject, onMounted, onUnmounted, reactive } from '@vue/composition-api'
import VueRouter from 'vue-router'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import Logger from '@/util/logger/Logger'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import useConditionsMoveToUserEdit from '@/components/hook/useConditionsMoveToUserEdit'
import UserStore from '@/store/stores/pageStore/common/UserStore'

/**
 * 初期状態
 */
const initialState = {
  /**
   * リダイレクト先
   */
  redirectPath: '',
}

/**
 * 契約情報更新時にプッシュ通知する機能を提供する。
 */
export default function usePusherContractInfo() {
  const state = reactive({ ...initialState })

  // リアルタイムメッセージ
  const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
  // 郵便番号必須入力判定
  const { changeShouldEnterZipCode } = useConditionsMoveToUserEdit()

  const router = inject('router') as VueRouter

  /**
   * リダイレクト先を設定
   * return { string }
   */
  const redirectPath = computed({
    get: (): string => state.redirectPath,
    set: (path: string) => {
      state.redirectPath = path
    },
  })

  onMounted(async () => {
    // 契約情報更新に関連するリアルタイムメッセージを受信
    await initRTM()
    subscribeRTM('operation', async (data: RTMCallbackParamType) => {
      // FL-UXで作成されたハイライト（公式ハイライト）の際にも、この処理が動作するため、動画再生画面にいる時には処理をスキップする
      if (router.currentRoute.name === 'RaceVideoPage') return

      Logger.debug(`contract info update event. event: ${JSON.stringify(data)}`)

      /**
       * TODO: 現状、チケットを利用した時に操作ログを登録しているが、その際に以下の契約情報を更新する処理も動いてしまう。未実装となっているmessageKeyを使って制御できそうなので、BEに相談する。
       * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/617120122/FL-UX
       * アプリでチケット利用ページを開いている状態でブラウザ版からプラン変更することはほとんど考えられないため、暫定対応として、本日のチケットページにいる場合は後続処理を実行しないようにした
       */
      if (router.currentRoute.path.includes('/mypage/ticket-today')) {
        return
      }
      // if (data.messageKey === xxx) {
      //   return
      // }

      redirectPath.value = ''
      // 契約情報を再取得する
      const contractInfoResults = await ContractInfoStore.value.fetchUserContractInfo()
      const failedResult = contractInfoResults.find((result) => !result?.isSuccess)
      if (failedResult) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'PaidMembershipChange.ConfirmPage.errors.fetchUserContractInfoError.title',
          ),
          message: I18n.tc(
            'PaidMembershipChange.ConfirmPage.errors.fetchUserContractInfoError.message',
          ),
        })
        // 契約情報取得に失敗した場合は、強制ログアウトする（ユーザー操作で再度ログインしてもらう）
        redirectPath.value = '/logout'
        return
      }
      /** 有料会員でお住まいの国が日本の場合に郵便番号が登録されていない場合は郵便番号登録必須フラグを立てる */
      changeShouldEnterZipCode()
      if (UserStore.value.shouldEnterZipCode.value) {
        // 有料プランで郵便番号が入っていない場合、マイページの会員情報編集画面に遷移する
        redirectPath.value = '/mypage/user-edit'
      }
    })
  })

  onUnmounted(() => {
    // 契約情報更新に関連するリアルタイムメッセージ受信を停止
    unSubscribeRTM()
    // pusherContractInfoStateを初期化
    Object.assign(state, initialState)
  })

  return {
    redirectPath,
  }
}
