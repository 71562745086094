




























import { defineComponent } from '@vue/composition-api'

/**
 * レース一覧画面: レース選択アイテム コンポーネント
 */
export default defineComponent({
  name: 'RaceListItemParts',
  props: {
    current: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Number,
      default: 0,
    },
    courseImg: {
      type: String,
      default: '',
    },
    venue: {
      type: String,
      default: '',
    },
    raceDate: {
      type: String,
      default: '',
    },
    raceImg: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.current) {
      this.$el.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  },
  methods: {
    push() {
      /**
       * ボタンが押下されたことを通知する
       * @event login
       * @type {Object}
       */
      this.$emit('push')
    },
  },
})
