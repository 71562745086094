var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-list-view-button",
      class: {
        "race-list-view-button--for-pc": _vm.forPc,
      },
    },
    [
      _c("atom-icon-router-button", {
        staticClass: "race-list-view-button__button",
        attrs: {
          to: "",
          linkText: "View All Sessions (Time Schedule)",
          theme: "light",
          colorType: "secondary",
          size: "small",
          imgPath: require("@/assets/img/icon/icon_schedule.svg"),
        },
        on: { emitClick: _vm.handleViewTimetableClicked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }