import IVideoPlayer from '@/util/videoplayer/IVideoPlayer'
import VideoPlayerResult from '@/util/videoplayer/VideoPlayerResult'
import VideoPlayerInitOptionsType from '@/util/videoplayer/VideoPlayerInitOptionsType'
import Logger from '@/util/logger/Logger'
import VideoPlayStatus from '@/util/videoplayer/VideoPlayStatus'
import VideoPlayerStatus from '@/util/videoplayer/VideoPlayerStatus'
import { VideoPlayerClass, VideoPlayerType } from '@/util/videoplayer/VideoPlayerType'
import { VideoPlayerErrorType } from '@/util/videoplayer/VideoPlayerError'
import type SignedCookie from '@/@types/SignedCookie'

/**
 * 指定された動画をエア再生する動画プレーヤー。
 * ※ 'Air' という表記は、ギターの弾き真似をする エアギター(Air Guitar) から採用している
 *
 * この動画プレーヤーは、実際には動画を再生せず、指定された動画の撮影開始時刻と動画長を元に、
 * 以下のような再生に伴う処理をエミュレートし、呼び出し元に、実際に動画が再生されている様に見せかけている
 * - 再生を開始した場合、onPlayStatusUpdate コールバック関数が、'PLAY' 状態で呼び出される。
 * - 動画の再生位置を更新し、currentTimeが呼び出された場合、現在の動画の再生位置を返却する
 * - currentTime が設定された場合、（実際には動画の再生位置を変更する処理は行わないが)、再生位置情報を更新する
 * - 動画再生に伴い、onCurrentTimeUpdate がコールバックされ、動画の再生位置を返却する
 *
 * 動画をエア再生するために必要となるため、動画プレーヤーを初期化する際のinitメソッドのoptionには、以下のパラメタが必須となる。
 * - movieLength 動画長
 * - recordingStartTime 動画の撮影開始時刻
 */
export default class AirVideoPlayer implements IVideoPlayer {
  /**
   * 動画の再生位置を更新する周期(ms)
   */
  private static VIDEO_TIME_UPDATE_INTERVAL = 100

  /**
   * この動画プレーヤーが初期化済みかどうか
   */
  private videoPlayerInitialized = false

  /**
   * 現在の動画再生位置(秒)
   */
  private currentVideoTime = 0

  /**
   * 最後に呼び出し元に通知した動画の再生位置(秒)
   */
  private lastCurrentTime = 0

  /**
   * 最後に動画の再生位置を進める処理を行った時刻(UnitTime: ミリ秒)
   * この設定は、動画の再生位置を更新する処理の中で、次の再生位置を進める秒数を計算する際に利用される。
   */
  private lastExecutionTime = 0

  /**
   * 動画の再生位置が記録された実時間を取得する処理を行うかどうかを指定する。
   * 指定した場合、 onCurrentTimeUpdate コールバック関数のvideoTrackDateTimeパラメタに、
   * 動画の再生位置が記録された実時間が指定されるようになる。
   */
  readVideoTrackTime = false

  /**
   * 動画再生位置を定期的に更新するためのsetIntervalのID
   */
  private airVideoPlayerIntervalId: number | null = null

  /**
   * 再生速度
   */
  private playbackRate = 1.0

  /**
   * 音量
   */
  private volumeValue = 0

  /**
   * 再生中かどうかを示す
   */
  private playing = false

  /**
   * シーク中かどうかを示す
   */
  private seeking = false

  /**
   * ミュート状態かどうかを示す
   */
  private isMuted = false

  /**
   * 再生対象の動画がライブ配信動画かどうか
   */
  private isLive = false

  /**
   * 動画URL
   */
  private movieUrl: string | undefined = undefined

  /**
   * 指定された動画の動画長
   */
  private movieLength = 0

  /**
   * 指定された動画の撮影開始時刻(UnixTime: ミリ秒)
   */
  private recordingStartTime = 0

  /**
   * 現在日時からのずれを指定する(ミリ秒)。
   *
   * ライブ配信時に動画長を生成する際、このパラメタで指定された秒数だけ動画長を短くする。
   * この値が0の場合、動画の終端部分の実時間は現在日時となる。2を指定した場合、現在日時に-2秒した日時となる。
   * このパラメタは、テレメトリーデータのように、データが参照できるまでに数秒かかるデータとの同期をとるために、
   * 動画の終端位置の時刻を意図的に遅い時刻にするために利用する。
   */
  private offsetFromCurrentDateTime = 2000

  /**
   * SFgo が動作しているデバイスと現在時刻とのズレ(ミリ秒)
   */
  private timeDeviations = 0

  /**
   * 最新位置にシークするかどうか
   * @private
   */
  private seekToEnd = false

  /**
   * 動画の再生位置が変化した場合に呼び出されるコールバック関数
   * @param time 映像の再生時刻
   * @param videoTrackDateTime 再生対象の映像が記録された日時。UnixTime: 単位(ミリ秒)
   */
  private onCurrentTimeUpdate?: (time: number, videoTrackDateTime?: number) => void

  /**
   * 動画の再生状態(PLAY / PAUSE)が変化した場合に呼び出されるコールバック関数
   */
  private onPlayStatusUpdate?: (status: VideoPlayStatus) => void

  /**
   * 動画の再生可能状態が変化した場合に呼び出されるコールバック関数
   */
  private onPlayerStatusUpdate?: (status: VideoPlayerStatus, movieLength: number | null) => void

  /**
   * 動画プレーヤーの初期化に失敗した場合に呼び出されるコールバック関数を指定する
   * @param error エラー定義
   */
  private onInitializeFailed?: (error: VideoPlayerErrorType) => void

  /**
   * 動画を最後まで再生し、再生が終了した場合に呼び出されるコールバック関数
   */
  private onPlayFinished?: () => void

  /**
   * 再生対象の動画のURLが変化した場合に呼び出されるコールバック関数を指定する。
   * @param movieUrl 現在の動画のURL
   */
  private onChangeMovieUrl?: (movieUrl: string) => void

  /**
   * 映像がシークされた場合に呼び出されるコールバック関数を指定する。
   * @param time 映像の再生時刻
   */
  private onSeeked?: (time: number) => void

  /**
   * 動画プレーヤーの処理結果: 処理成功
   */
  private static SUCCESS = new VideoPlayerResult()

  /**
   * 動画プレーヤーの処理結果: 処理失敗
   */
  private static ERROR = new VideoPlayerResult('error')

  /**
   * 動画プレーヤーの処理結果: 動画プレーヤーの初期化が完了していない
   */
  private static NOT_INITIALIZED = new VideoPlayerResult('not initialized')

  /**
   * 動画プレーヤーを初期化する。
   * @param options 動画プレーヤーの初期化オプション
   * @return 動画プレーヤーの初期化を待機するためのPromise
   */
  async init(options: VideoPlayerInitOptionsType): Promise<VideoPlayerResult> {
    if (!options.recordingStartTime) {
      Logger.info(
        `AirVideoPlayer#init: recordingStartTime is required. options: ${JSON.stringify(options)}`,
      )
      return AirVideoPlayer.ERROR
    }

    this.isLive = !!options.live
    this.recordingStartTime = options.recordingStartTime
    this.timeDeviations = options.timeDeviations ?? 0
    this.seekToEnd = options.seekToEnd ?? false

    this.onPlayStatusUpdate = options.onPlayStatusUpdate
    this.onPlayerStatusUpdate = options.onPlayerStatusUpdate
    this.onInitializeFailed = options.onInitializeFailed
    this.onPlayFinished = options.onPlayFinished
    this.onChangeMovieUrl = options.onChangeMovieUrl

    if (options.live) {
      // ライブ映像の場合、動画長は現在の日時から撮影開始時間を引いた値となる。この値は、
      this.updateLiveMovieLength()
    } else if (!options.movieLength) {
      // VOD映像の場合、動画長は必須
      Logger.info(
        `AirVideoPlayer#init: For VOD video, movieLength is required. options: ${JSON.stringify(
          options,
        )}`,
      )
      return AirVideoPlayer.ERROR
    } else {
      this.movieLength = options.movieLength
    }

    if (this.seekToEnd) {
      // 対象の動画がライブ配信動画 かつ サーキットモードトグルボタンで再生モードを切り替ていない場合、末尾にシークする
      // ※ ライブ配信動画が指定された時のふるまいをネイティブプレーヤーと一致させるため、この処理を行う
      await this.seekEndOfVideo()
    }

    this.onCurrentTimeUpdate = options.onCurrentTimeUpdate

    this.airVideoPlayerIntervalId = window.setInterval(async () => {
      if (this.isLive) {
        // Live映像の場合、現在日時をもとに動画長を更新する
        this.updateLiveMovieLength()
        if (options.onChangeMovieLength) {
          options.onChangeMovieLength(await this.duration())
        }
      }

      const now = new Date().getTime()
      // 前回の実行日時と現在日時との差をもとめる。この差分だけ動画の再生位置を進める。
      // ※ 動画の再生位置を更新する処理は setInterval で周期的に実行されるが、
      // setIntervalは、指定した値の周期で正確に呼び出されることは保証されていない。
      // そのため、setIntervalが最後に実行された時刻を記録しておき、次にsetIntervalが実行された際の現在日時の差分を求めることで、
      // 前回の動画の再生位置の設定処理を実施後に何秒が経過したかを計測する。その計測された値が、動画の再生位置を進める時間となる。
      const intervalTime = this.lastExecutionTime ? (now - this.lastExecutionTime) / 1000 : 0

      if (this.movieLength > this.currentVideoTime) {
        if (this.playing) {
          // 再生速度を考慮して、現在の再生位置を加算する
          this.currentVideoTime += intervalTime * this.playbackRate

          if (!this.isLive && this.currentVideoTime > this.movieLength) {
            // 映像の終端を超えた場合は、映像の終端位置に設定する。ただし、ライブ映像の時は停止しない
            this.currentVideoTime = this.movieLength
            this.onPlayStatusUpdate?.('PAUSE')
            this.onPlayFinished?.()
          }
        }

        if (this.onCurrentTimeUpdate) {
          // 呼び出し元に、現在の再生位置とその映像が記録された実時間をコールバック関数で戻す。
          // ただし、再生位置の変化が0.1以下の時は、通知しても意味はないのでコールバック関数呼び出しは実施しない。
          if (Math.abs(this.lastCurrentTime - this.currentVideoTime) > 0.1) {
            await this.callbackCurrentTime()
          }
        }
      }

      this.lastExecutionTime = now
    }, AirVideoPlayer.VIDEO_TIME_UPDATE_INTERVAL)

    this.videoPlayerInitialized = true

    this.setMovieUrl(
      options.movieUrl,
      options.currentTime,
      undefined,
      options.readVideoTrackTime,
    ).then(() => {
      if (this.seekToEnd) {
        // 対象の動画がライブ配信動画 かつ サーキットモードトグルボタンで再生モードを切り替ていない場合、末尾にシークする
        // ※ ライブ配信動画が指定された時のふるまいをネイティブプレーヤーと一致させるため、この処理を行う
        this.seekEndOfVideo()
      }
    })

    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画長を現在の日時までライブ配信された映像の長さに設定する。
   */
  updateLiveMovieLength(): void {
    this.movieLength =
      (new Date().getTime() -
        this.recordingStartTime -
        (this.offsetFromCurrentDateTime + this.timeDeviations)) /
      1000
  }

  /**
   * 現在の動画の再生位置を呼び出し元に通知する。
   */
  async callbackCurrentTime() {
    if (this.onCurrentTimeUpdate) {
      this.onCurrentTimeUpdate(
        this.currentVideoTime,
        await this.getCurrentVideoTrackTime(this.currentVideoTime),
      )
      // 動画の再生位置を設定した時刻を記録する
      this.lastExecutionTime = new Date().getTime()
      // 最後に呼び出し元に通知した再生位置を記録する
      this.lastCurrentTime = this.currentVideoTime
    }
  }

  /**
   * 動画のシークイベントのリスナーを登録する。
   * @param listener シークイベントのリスナー
   */
  addSeekEventListener(listener: (time: number) => void): void {
    this.onSeeked = listener
  }

  /**
   * 動画プレーヤーを表示サイズに変更する。
   * TODO 現状未実装。ネイティブプレイヤーとインターフェイスを共通化するために作成している。
   * @param to 表示位置
   * @param size 表示サイズ
   * @param displayPosition 映像表示領域位置
   * @param scale 表示倍率
   * @return {Promise<VideoPlayerResult>} 非表示処理完了を待機するためのPromise
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  // eslint-disable-next-line class-methods-use-this
  async changeDisplaySize(
    to: { x: number; y: number },
    size: { width: number; height: number },
  ): Promise<VideoPlayerResult[]> {
    return [AirVideoPlayer.SUCCESS]
  }

  /**
   * 現在再生している動画のURLを返す。
   */
  currentMovieUrl(): string | undefined {
    return this.movieUrl
  }

  /**
   * 現在の動画再生位置を返す。
   * @return 動画の再生位置(単位: 秒)
   */
  async currentTime(): Promise<number> {
    return this.currentVideoTime
  }

  /**
   * この動画プレーヤーを破棄する。
   */
  async dispose(): Promise<VideoPlayerResult> {
    if (this.airVideoPlayerIntervalId) {
      clearInterval(this.airVideoPlayerIntervalId)
    }

    this.videoPlayerInitialized = false
    this.airVideoPlayerIntervalId = null
    this.onSeeked = undefined

    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画の長さを取得する。
   * @return 動画長(単位: 秒)
   */
  async duration(): Promise<number> {
    return this.movieLength
  }

  /**
   * 現在再生している動画の再生位置が記録された実時間を取得する。
   *
   * @param time 実時間を取得する再生位置(秒)。
   * 指定しなかった場合、動画プレーヤーから現在の再生位置を取得して、実時間を計算する。
   * @return 現在再生している動画の再生位置が記録された実時間。UnixTime(単位: ミリ秒)
   * 取得できなかった場合、undefined を返す。
   */
  async getCurrentVideoTrackTime(time?: number): Promise<number | undefined> {
    if (!this.readVideoTrackTime) {
      return undefined
    }

    const targetTime = time || this.currentVideoTime
    return this.recordingStartTime + targetTime * 1000
  }

  /**
   * 動画プレーヤーを表示する。
   * この動画プレーヤーは実際には動画を保持していないため、何も処理は行わない。
   *
   * @return {Promise<VideoPlayerResult>} 表示処理完了を待機するためのPromise
   */
  async show(): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED

    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画プレーヤーを非表示にする。
   * この動画プレーヤーは実際には動画を保持していないため、何も処理は行わない。
   *
   * @return {Promise<VideoPlayerResult>} 非表示処理完了を待機するためのPromise
   */
  async hide(): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED

    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画プレーヤーが初期化されているかどうかを返す。
   * @return 初期化されている場合 true を返す
   */
  initialized(): boolean {
    return this.videoPlayerInitialized
  }

  /**
   * シーク中かどうかを判定する
   */
  isSeeking(): boolean {
    return this.seeking
  }

  /**
   * 音量をミュートにする
   * @param shouldMute 音量をミュートにするかミュートを解除するかどうか、ミュートにする場合にはtrueを指定する
   * @return 音量をミュートにする処理の完了を待機するためのPromise
   */
  async muted(shouldMute: boolean): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED

    this.isMuted = shouldMute
    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画の再生を一時停止する。
   * @return 動画の再生一時停止処理の完了を待機するためのPromise
   */
  async pause(): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED

    this.playing = false
    if (this.onPlayStatusUpdate) {
      this.onPlayStatusUpdate?.(this.playing ? 'PLAY' : 'PAUSE')
    }

    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画を再生する。
   * @return 動画の再生開始処理の完了を待機するためのPromise
   */
  async play(): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED

    this.playing = true
    if (this.onPlayStatusUpdate) {
      this.onPlayStatusUpdate?.(this.playing ? 'PLAY' : 'PAUSE')
    }
    return AirVideoPlayer.SUCCESS
  }

  /**
   * 動画プレーヤーの種別を返す。
   */
  // eslint-disable-next-line class-methods-use-this
  get playerType(): VideoPlayerType {
    return VideoPlayerClass.AirVideoPlayer
  }

  /**
   * 動画の最後にシークする。
   */
  async seekEndOfVideo() {
    const endOfVideoTime = await this.duration()
    return this.setCurrentTime(endOfVideoTime)
  }

  /**
   * 動画の再生位置を設定する。
   * @param time 再生位置(単位: 秒)
   * @return 動画再生位置設定処理の完了を待機するためのPromise
   */
  async setCurrentTime(time: number): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED
    this.seeking = true
    this.currentVideoTime = time
    await this.callbackCurrentTime()
    this.seeking = false
    return AirVideoPlayer.SUCCESS
  }

  /**
   * 再生対象の動画URLを変更する。
   * @param movieUrl 動画のURL
   * @param currentTime 動画URL設定後、この位置にシークする。未指定の場合、先頭位置にシークする
   * @param cookies 動画データを取得する際に付与する署名Cookie
   * @param readVideoTrackTime 動画の再生位置が記録された実時間を取得する処理を行うかどうか
   * @return 動画URL設定変更処理の完了を待機するためのPromise
   */
  async setMovieUrl(
    movieUrl: string,
    currentTime = 0,
    cookies?: Array<SignedCookie>,
    readVideoTrackTime = true,
  ): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED

    Logger.debug(
      `AirVideoPlayer#setMovieUrl: Start to change movie url.
       movieUrl: ${movieUrl}, currentTime: ${currentTime},
       readVideoTrackTime: ${readVideoTrackTime}`,
    )

    this.readVideoTrackTime = readVideoTrackTime

    // 他の動画プレーヤーと動画プレーヤーの状態変化を一致させるため、
    // 'Not running' してから動画URLを設定し、'Running' に変更する
    this.onPlayerStatusUpdate?.('Not running', 0)
    this.movieUrl = movieUrl
    this.onPlayerStatusUpdate?.('Running', await this.duration())
    this.onPlayStatusUpdate?.(this.playing ? 'PLAY' : 'PAUSE')
    await this.setCurrentTime(currentTime)
    await this.callbackCurrentTime()
    this.onSeeked?.(currentTime)
    this.onChangeMovieUrl?.(movieUrl)

    return AirVideoPlayer.SUCCESS
  }

  /**
   * 再生速度を設定する
   * @param rate 再生速度
   */
  async setPlaybackRate(rate: number): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED
    this.playbackRate = rate
    return AirVideoPlayer.SUCCESS
  }

  /**
   * 音量を設定する。
   * @param volumeLevel 音量
   */
  async volume(volumeLevel: number): Promise<VideoPlayerResult> {
    if (!this.initialized()) return AirVideoPlayer.NOT_INITIALIZED
    this.volumeValue = volumeLevel
    return AirVideoPlayer.SUCCESS
  }

  /**
   * 再生対象の動画が、ライブ配信されている動画かどうかを指定する。
   * このメソッドは、もともとライブ配信された動画が配信が停止された場合に、このメソッドを利用してライブ配信が終了したこと設定し、
   * 動画長の自動更新処理を停止するために利用する。
   *
   * @param live ライブ配信されている動画の場合、true を指定する
   */
  set liveMode(live: boolean) {
    this.isLive = live
  }

  /**
   * 再生対象の動画が、ライブ配信されている動画かどうかを判定する。
   *
   * @return ライブ配信されている動画の場合、true を返す
   */
  get liveMode(): boolean {
    return this.isLive
  }
}
