

















import { computed, defineComponent } from '@vue/composition-api'

/**
 * レーサー 表示パーツ
 * todo: デザイン調整 / props設定 / emit等
 */
export default defineComponent({
  name: 'PlayerCardParts',
  props: {
    img: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    team: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * imgPath
     * @param {string} val 画像データパス
     */
    const imgPath = computed({
      get: () => props.img || './assets/img/common/card/icon_player__null.svg',
      set: (val: string) => val,
    })

    return {
      imgPath,
    }
  },
  methods: {
    /**
     * 押下されたことを通知
     */
    emitClick() {
      this.$emit('click')
    },
  },
})
