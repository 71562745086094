var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-today-summary-section" }, [
    _c("p", { staticClass: "mypage-ticket-today-summary-section__title" }, [
      _vm._v(_vm._s(_vm.ticketEvent.eventName)),
    ]),
    _c("p", { staticClass: "mypage-ticket-today-summary-section__place" }, [
      _vm._v(" " + _vm._s(_vm.displayPlaceName) + " "),
    ]),
    _c("p", { staticClass: "mypage-ticket-today-summary-section__date" }, [
      _vm._v(
        " " +
          _vm._s(_vm.displayEventDate) +
          " " +
          _vm._s(_vm.displayEventDayOfWeek) +
          " "
      ),
    ]),
    _c("p", { staticClass: "mypage-ticket-today-summary-section__time" }, [
      _c(
        "span",
        { staticClass: "mypage-ticket-today-summary-section__time-label" },
        [
          _vm._v(
            _vm._s(_vm.$tc("MypagePage.MypageTicketTodayPage.openingTitle"))
          ),
        ]
      ),
      _c(
        "span",
        { staticClass: "mypage-ticket-today-summary-section__time-data" },
        [_vm._v(_vm._s(_vm.displayOpeningTime))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }