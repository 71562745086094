










import { computed, defineComponent } from '@vue/composition-api'

/**
 * レース動画再生画面 動画プレーヤー LIVEアイコン コンポーネント
 */
export default defineComponent({
  name: 'LiveIconParts',
  props: {
    /**
     * LIVE表示有効化フラグ
     */
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isLiveEnabled = computed(() => props.isLive)
    return {
      isLiveEnabled,
    }
  },
  methods: {
    /**
     * LIVEボタンがクリックされた場合に呼び出される。
     */
    onLiveClicked() {
      this.$emit('onLiveClicked')
    },
  },
})
