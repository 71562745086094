import { computed } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useContractTerm from '@/components/hook/useContractTerm'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractInfoPlanPrice from '@/store/hook/useContractInfoPlanPrice'
import Logger from '@/util/logger/Logger'
import useMonthlyProRatedPrice from '@/components/pc/MyPage/CompletePane/hook/useMonthlyProRatedPrice'

/**
 * お支払いサイクル画像に関するHook
 */
export default function usePaymentCycleImageOnChange() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
  const { fetchContractInfo, ownContractInfo } = useContractInfo()
  const { fetchProvisionalBillingInfo, ownMonthlyBillingInfo } = useBillingInfo()
  const { fetchMonthlyPlanPrice, contractInfoPlanPrice } = useContractInfoPlanPrice()
  const { getCurrentContractTerm } = useContractTerm()
  const { getMonthlyPlanNextProRatedPriceParam } = useMonthlyProRatedPrice()

  /**
   * 契約中のプランの契約期間
   */
  const contractTerm = computed(() =>
    getCurrentContractTerm(
      effectiveContractPlan as ContractPlanDocument,
      effectiveOrgContract as ContractType,
    ),
  )

  /**
   * 支払いプランを切り替える画面（有料→有料）に表示する請求サイクル画像の表示パターンを判定する
   * 月額プランから年額プランに切り替える場合、３パターンの画像表示があるため、どのパターンになるかを返す
   * @param hasRenewedMonthlyPlan 月額プランの契約を更新済みかどうか
   */
  const getBillingCycleImage = async (hasRenewedMonthlyPlan = false) => {
    await Promise.all([fetchContractInfo(), fetchProvisionalBillingInfo()])

    const { contractInfoId } = ownContractInfo.value

    if (!contractInfoId) {
      return null
    }

    /**
     * 今月既に更新済み場合
     */
    if (hasRenewedMonthlyPlan) {
      /** 月額プランの翌々月請求金額（日割り分）を取得する */
      const requestData = getMonthlyPlanNextProRatedPriceParam(
        contractTerm.value.contractStartDate,
        contractTerm.value.contractEndDate,
        effectiveOrgContract?.contractPlanId,
      )
      const afterNextPriceResult = requestData
        ? await fetchMonthlyPlanPrice(contractInfoId, requestData)
        : null

      if (!afterNextPriceResult?.isSuccess) {
        Logger.info(
          `usePaymentSchedule#displayPaymentScheduleOnCancel: Failed to fetch fetchMonthlyPlanPrice. contractInfoId: ${contractInfoId}`,
        )

        // 翌々月の請求金額（日割り分）を取得できない場合は請求サイクル画像を表示しない
        return null
      }

      // 翌々月の支払い金額チェック
      if (contractInfoPlanPrice.value?.charge?.total === 0) {
        /**
         * 月額プラン今月既に更新済み かつ 翌々月の支払いが0円の場合
         * パターン1の画像を表示
         */
        return 1
      }

      /**
       * 1. 月額プラン今月既に更新済み かつ 翌々月の支払いが1円以上の場合 かつ 翌月の支払い予定が1,480円
       * - パターン2の画像を表示
       * 2. 月額プラン今月既に更新済み かつ 翌々月の支払いが1円以上の場合 かつ 翌月の支払い予定が1,480円未満
       * - パターン3の画像を表示
       */
      return ownMonthlyBillingInfo.value?.plans?.find((plan) => plan?.price === 1480) ? 2 : 3
    }

    /**
     * まだ更新していない場合
     * パターン1の画像を表示
     */
    return 1
  }

  return {
    getBillingCycleImage,
  }
}
