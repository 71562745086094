






















import { computed, defineComponent, ref, onMounted } from '@vue/composition-api'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * フィールドセット Display Name入力 バーツ
 */
export default defineComponent({
  name: 'FieldsetDisplayNameParts',
  components: {
    AtomInputText,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * Display Name入力フォームの変更が可能かどうか
     * ブラウザの場合にisDisabled: trueにする
     */
    const isDisabled = ref(!DeviceInfo.isCordova())

    /**
     * userDisplayName
     * @param {string} val
     */
    const userDisplayName = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })

    onMounted(async () => {
      /**
       * ChromeのブラウザだとDisplay Name入力フォームが認証情報と誤認されてしまう。
       * そのため、DOM生成時はdisabled状態とすることでChromeに認識させないようにし、0.5秒後に入力可能な状態にすることで対処している。
       */
      await new Promise((resolve) => {
        setTimeout(resolve, 500)
      })
      isDisabled.value = false
    })

    return {
      userDisplayName,
      isDisabled,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
