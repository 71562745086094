import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import I18n from '@/locales/I18n'

/**
 * 開場時間のkeyの型
 */
export type OpeningDatesType = 'day1' | 'day2' | 'day3'

/**
 * パーティションキーの型
 */
export type EcSiteType = 'ecforce' | ''

/**
 * デジタルチケットデータを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3805478936/13.
 */
export default class DigitalTicketDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<DigitalTicketDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    const digitalTicketInitProps = initProps
    const days: Array<OpeningDatesType> = ['day1', 'day2', 'day3']
    days.forEach((day) => {
      if (digitalTicketInitProps?.openingDates) {
        digitalTicketInitProps.openingDates[day] = digitalTicketInitProps?.openingDates[day] || 0
      }
    })
    this.mergeToThis(digitalTicketInitProps)

    if (digitalTicketInitProps?.ticketTypes) {
      this.ticketTypes = digitalTicketInitProps.ticketTypes.map(
        (p) => new DigitalTicketDataTicketTypeDocument(p),
      )
    }
  }

  /**
   * 連携するECサイトの種別
   * DynamoDB digital-ticket-dataのパーティションキー
   */
  ecSiteType: EcSiteType = 'ecforce'

  /**
   * イベント名日本語
   */
  eventJa: string | null = null

  /**
   * イベント名英語
   */
  eventEn: string | null = null

  /**
   * 場所日本語
   */
  placeJa: string | null = null

  /**
   * 場所英語
   */
  placeEn: string | null = null

  /**
   * 開場日時
   */
  openingDates: Record<OpeningDatesType, number> | null = null

  /**
   * スタジアムIDマスタのID
   */
  stadiumId: string | null = null

  /**
   * ユーザー画像ID
   */
  userImageId: string | null = null

  /**
   * 券種データ一覧
   */
  ticketTypes: Array<DigitalTicketDataTicketTypeDocument> | null = null

  /**
   * カウントダウンイベントID
   */
  eventId: string | null = null

  /**
   * 作成日時
   */
  createdDate = 0

  /**
   * uniqueKey（パーティションキー+ソートキー）
   */
  get uniqueKey() {
    return `${this.ecSiteType}+${this.createdDate}`
  }

  /**
   * イベント名
   */
  get eventName() {
    return I18n.locale === 'ja' ? this.eventJa : this.eventEn
  }

  /**
   * 場所
   */
  get placeName() {
    return I18n.locale === 'ja' ? this.placeJa : this.placeEn
  }

  /**
   * 開場日時 1日目
   */
  get openingDateDay1() {
    return this.openingDates?.day1
  }

  /**
   * 開場日時 2日目
   */
  get openingDateDay2() {
    return this.openingDates?.day2
  }

  /**
   * 開場日時 3日目
   */
  get openingDateDay3() {
    return this.openingDates?.day3
  }
}
