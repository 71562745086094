var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "standings-round-data-pane" },
    [
      _c("h2", {
        staticClass: "standings-round-data-pane__title",
        domProps: {
          innerHTML: _vm._s(
            _vm.selectedYear +
              " " +
              _vm.$tc("StandingsPage.DetailsPage.Standings")
          ),
        },
      }),
      _c("standings-round-list-section", {
        attrs: { roundsRecords: _vm.roundsRecords },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }