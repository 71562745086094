var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-one-time-pass-input-section" },
    [
      _c("h6", { staticClass: "mypage-one-time-pass-input-section__title" }, [
        _vm._v(" " + _vm._s(_vm.$tc("formParts.label.oneTimePass")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "mypage-one-time-pass-input-section__fieldset" },
        [
          _c("atom-input-text", {
            staticClass: "mypage-one-time-pass-input-section__input",
            attrs: {
              id: "coupon",
              error: _vm.oneTimePassError,
              placeholder: _vm.$tc("formParts.placeholder.oneTimePass"),
            },
            model: {
              value: _vm.oneTimePass,
              callback: function ($$v) {
                _vm.oneTimePass = $$v
              },
              expression: "oneTimePass",
            },
          }),
          _c("atom-input-button", {
            staticClass: "mypage-one-time-pass-input-section__submit",
            attrs: {
              isDisabled: false,
              "link-text": _vm.$tc("common.registrationShort"),
              colorType: "secondary",
            },
            on: { push: _vm.handlerSubmit },
          }),
        ],
        1
      ),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.oneTimePassError,
          message: _vm.oneTimePassErrorMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }