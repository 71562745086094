var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "standings-round-details-section",
      class: { "standings-round-details-section--is-open": _vm.open },
    },
    [
      _c("round-details-header-parts", {
        staticClass: "standings-round-details-section__header",
        attrs: { raceData: _vm.roundRecord.race },
      }),
      _c(
        "div",
        { staticClass: "standings-round-details-section__earn-points" },
        [
          _c(
            "dl",
            {
              staticClass: "standings-round-details-section__earn-points__data",
            },
            [
              _c("dt", {
                staticClass:
                  "standings-round-details-section__earn-points__label",
                domProps: {
                  textContent: _vm._s(
                    _vm.$tc("StandingsPage.DetailsPage.EarnPoints")
                  ),
                },
              }),
              _c("dd", {
                staticClass:
                  "standings-round-details-section__earn-points__value",
                domProps: {
                  textContent: _vm._s(_vm.roundRecord.earnPoints + " pt"),
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "standings-round-details-section__list-heading" },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$tc("StandingsPage.DetailsPage.RaceRank")
              ),
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "standings-round-details-section__list" },
        _vm._l(_vm.roundRecord.records, function (record, index) {
          return _c("round-record-parts", {
            key: "roundRecordParts" + index,
            staticClass: "standings-round-details-section__list-item",
            attrs: {
              title: record.title,
              value: record.record,
              position: record.position,
            },
          })
        }),
        1
      ),
      _c(
        "button",
        {
          staticClass: "standings-round-details-section__toggle-button",
          attrs: { type: "button" },
          on: { click: _vm.toggleOpen },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M16.1001 6.03906L10.2769 11.8623C9.59346 12.5457 8.48542 12.5457 7.802 11.8623L1.97878 6.03906H4.1001L8.86266 10.8016C8.96029 10.8993 9.11858 10.8993 9.21621 10.8016L13.9788 6.03906H16.1001Z",
                  fill: "black",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }