var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlight-preview-controller-parts",
      class: [
        "highlight-preview-controller-parts--" + _vm.screenOrientationType,
        { "highlight-preview-controller-parts--pc": _vm.isPc },
      ],
    },
    [
      _vm.videoTimesData
        ? _c("highlight-preview-seek-bar-parts", {
            staticClass: "highlight-preview-controller-parts__seek-bar",
            model: {
              value: _vm.videoTimesData,
              callback: function ($$v) {
                _vm.videoTimesData = $$v
              },
              expression: "videoTimesData",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "highlight-preview-controller-parts__buttons" },
        [
          _vm.videoPausedStatus
            ? _c(
                "button",
                {
                  staticClass:
                    "highlight-preview-controller-parts__button highlight-preview-controller-parts__button__play",
                  on: { click: _vm.playVideo },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon/video_controller_play.svg"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _vm._e(),
          !_vm.videoPausedStatus
            ? _c(
                "button",
                {
                  staticClass:
                    "highlight-preview-controller-parts__button highlight-preview-controller-parts__button__pause",
                  on: { click: _vm.pauseVideo },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon/video_controller_pause.svg"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }