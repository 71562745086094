import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * パスワード更新情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/2915172443/API
 */
export default class PasswordChangeTokenDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PasswordChangeTokenDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/user'

  idAttr = '_id'

  /**
   * パスワード
   */
  password: string | null = null
}
