
































































































import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import RacePlayerInfoSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RacePlayerInfoSection.vue'
import PlayerSelectModalSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/PlayerSelectModalSection.vue'
import RaceSelectSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RaceSelectSection.vue'
import GpsViewModeSelectorSection from '@/components/RaceVideoPage/RaceVideoGpsPane/GpsViewModeSelectorSection.vue'
import GpsDetailsMapSection from '@/components/RaceVideoPage/RaceVideoGpsPane/GpsDetailsMapSection.vue'
import GpsRankingSection from '@/components/RaceVideoPage/RaceVideoGpsPane/GpsRankingSection.vue'
import MapScaleParts from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/MapScaleParts.vue'
import StoreUtil from '@/store/StoreUtil'
import Const from '@/util/Const'
import { CircuitGpsPosition } from '@/components/RaceVideoPage/RaceVideoPane/CircuitMapSection.vue'
import Logger from '@/util/logger/Logger'
import useGps from '@/components/RaceVideoPage/hook/useGps'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * GPS表示モード
 */
export type GpsViewModeType = 'birdsEye' | 'battle' | 'focus'

/**
 * レース動画再生画面 GPS詳細マップペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceVideoGpsPane',
  components: {
    RacePlayerInfoSection,
    PlayerSelectModalSection,
    RaceSelectSection,
    GpsViewModeSelectorSection,
    GpsDetailsMapSection,
    GpsRankingSection,
    MapScaleParts,
  },
  props: {
    /**
     * 無線音声 ON/OFFフラグ
     */
    radioVoiceEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * レース選択 開閉フラグ
     */
    raceSelectEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * 選手選択モーダル表示フラグ
     */
    racePlayerSelectModal: {
      type: Boolean,
      default: false,
    },
    /**
     * 車両データアニメーションフラグ
     */
    animateGpsMapCar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const screenLandscape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)

    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { getCurrentPlayerRankingDataList } = useGps()

    const {
      setViewAngle,
      user,
      isLiveNotStarted,
      fetchRaceData,
      currentRaceId,
      highlightStore,
      targetChampionship,
    } = raceVideoPageStore
    const { selectedHighlightId } = highlightStore
    const { videoPlayer } = toRefs(raceVideoPageStore.raceVideoPageState)
    const favoriteRacePlayerSelected = computed(() => !!user.value?.favoritePlayerId)
    const selectedID = computed(() => raceVideoPageStore.raceVideoPageState.selectedPlayerId)

    const updateCurrentTime = (currentTime: number) => {
      videoPlayer.value?.setCurrentTime(currentTime)
    }

    /**
     * GPS表示モード
     * 'birdsEye' or 'battle' or 'focus'
     */
    const gpsViewMode = ref<GpsViewModeType>('birdsEye')

    /**
     * GPS詳細表示モード変更
     * @param changeMode
     */
    const onclickSelector = (changeMode: GpsViewModeType) => {
      gpsViewMode.value = changeMode
    }

    /**
     * 現在選択されているレースのサーキット
     */
    const currentCircuit = targetChampionship.value.course
    if (!currentCircuit) {
      Logger.warn('Failed to get circuit info')
    }

    /**
     * サーキットMAP GPS 座標
     */
    const circuitGpsPosition: CircuitGpsPosition = currentCircuit
      ? Const.GPS_CIRCUIT_MAP_GPS[currentCircuit]
      : Const.GPS_CIRCUIT_MAP_GPS.UNKNOWN

    /**
     * サーキット名取得
     */
    const circuitName = currentCircuit

    /**
     * MAP 表示倍率
     */
    const mapMagnification = computed(() => {
      let rt: number
      if (gpsViewMode.value === 'battle') {
        rt = Const.GPS_MAP_MAGNIFICATION.BATTLE
      } else if (gpsViewMode.value === 'focus') {
        rt = Const.GPS_MAP_MAGNIFICATION.FOCUS
      } else {
        rt = Const.GPS_MAP_MAGNIFICATION.BIRDSEYE
      }
      return rt
    })

    return {
      gpsViewMode,
      onclickSelector,
      favoriteRacePlayerSelected,
      setViewAngle,
      selectedID,
      isLiveNotStarted,
      raceVideoPageStore,
      fetchRaceData,
      selectedHighlightId,
      currentRaceId,
      videoPlayer,
      updateCurrentTime,
      getCurrentPlayerRankingDataList,
      mapMagnification,
      circuitName,
      circuitGpsPosition,
      screenLandscape,
    }
  },
  methods: {
    /**
     * 無線音声 ON/OFFボタンが押下されたことを通知する
     */
    toggleRadioVoice(): void {
      this.$emit('radioVoiceToggle')
    },

    /**
     * レース選択ボタンが押下されたことを通知する
     */
    toggleRaceSelect(state?: string): void {
      this.$emit('raceSelectToggle', state)
    },

    /**
     * 選手選択モーダル表示
     */
    showPlayerSelectModal(): void {
      this.$emit('playerSelectModalShow', true)
    },

    /**
     * 選択選手変更処理
     * @event changePlayer
     * @param submitType
     */
    changePlayer(submitType: string) {
      if (submitType === 'change') {
        // 選手を選択した場合、自動的にアングルを選手に変更する
        this.setViewAngle('player')
        // 視聴履歴取得
        this.raceVideoPageStore.saveHistory()
      }
      this.$emit('playerSelectModalShow', false)
    },
    changeScenes(raceId: string, highlightId: string) {
      if (this.currentRaceId !== raceId) {
        this.currentRaceId = raceId
      }
      if (highlightId) {
        this.selectedHighlightId = highlightId
      } else {
        this.updateCurrentTime(0)
      }
    },

    /**
     * 無線交信データ一覧のドライバー選択が変更された場合に呼び出される。
     * @param id ドライバーID
     */
    changeDriverFilter(id: string): void {
      this.$emit('changeDriverFilter', id)
    },

    /**
     * 無線交信データ一覧のLAPの選択が変更された場合に呼び出される。
     * @param id LAP数(周回数)
     */
    changeLapFilter(id: string): void {
      this.$emit('changeLapFilter', id)
    },
  },
})
