var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-header-section",
      class: {
        "login-header-section--disabled-logo":
          _vm.loginType !== "login" && _vm.loginType !== "notification",
      },
    },
    [
      _vm.loginType === "login" || _vm.loginType === "notification"
        ? _c("h1", { staticClass: "login-header-section__logo" }, [
            _c(
              "svg",
              {
                staticClass: "login-header-section__img",
                attrs: {
                  width: "72",
                  height: "22",
                  viewBox: "0 0 72 22",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("title", [_vm._v("SFgo")]),
                _c("path", {
                  attrs: {
                    d: "M50.4755 3.90547L55.0513 0.876099H4.07788C1.82573 0.876099 0 2.68915 0 4.92566V8.97521C0 11.2117 1.82573 13.0248 4.07788 13.0248H20.3894V17.0743H7.96884C6.76122 17.0743 5.58063 17.4293 4.57584 18.0945L0 21.1239H20.3894C22.6415 21.1239 24.4673 19.3108 24.4673 17.0743V13.0248C24.4673 10.7883 22.6415 8.97521 20.3894 8.97521H4.07788V4.92566H47.0825C48.2901 4.92566 49.4707 4.57068 50.4755 3.90547Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M32.623 8.97521H48.4248L43.8489 12.0046C42.8441 12.6698 41.6636 13.0248 40.4559 13.0248H32.623V21.1239H28.5451V13.0248C28.5451 10.7883 30.3709 8.97521 32.623 8.97521Z",
                    fill: "white",
                  },
                }),
                _c("ellipse", {
                  attrs: {
                    cx: "61.8053",
                    cy: "11",
                    rx: "10.1239",
                    ry: "10.1947",
                    transform: "rotate(90 61.8053 11)",
                    fill: "#00DBA6",
                  },
                }),
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M61.296 13.784L61.296 8.46899C60.672 8.46899 60.1527 8.91443 60.043 9.50258L59.7487 9.2103C59.2707 8.73565 58.6225 8.46899 57.9465 8.46899H56.4535C55.7497 8.46899 55.1792 9.03557 55.1792 9.73448L55.1792 11.2531C55.1792 11.952 55.7497 12.5186 56.4535 12.5186H59.0022C59.4191 12.5186 59.7892 12.3198 60.0217 12.0125V13.784H56.4535C55.7497 13.784 55.1792 14.3506 55.1792 15.0495H60.0217C60.0657 15.0495 60.1091 15.0473 60.152 15.043C60.2821 15.0299 60.4064 14.9973 60.522 14.9483C60.9771 14.7552 61.296 14.3066 61.296 13.784ZM56.4535 9.73448H60.0217V11.2531H56.4535V9.73448Z",
                    fill: "black",
                  },
                }),
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M62.3155 9.48138C62.3155 8.92226 62.7719 8.46899 63.3349 8.46899H67.4128C67.9758 8.46899 68.4323 8.92226 68.4323 9.48138V11.5062C68.4323 12.0653 67.9758 12.5186 67.4128 12.5186H63.3349C62.7719 12.5186 62.3155 12.0653 62.3155 11.5062V9.48138ZM63.5898 9.73448H67.1579V11.2531H63.5898V9.73448Z",
                    fill: "black",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _c("h2", { staticClass: "login-header-section__title" }, [
        _vm._v(_vm._s(_vm.pageTitle)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }