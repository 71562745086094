var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-section" }, [
    _c(
      "div",
      { staticClass: "pagination-section__list" },
      [
        _vm._l(_vm.totalPages, function (page) {
          return [
            _vm.isSeparatorLastShown(page)
              ? _c("span", {
                  key: "paginationSeparatorLast" + page,
                  staticClass: "pagination-section__list-item",
                  domProps: { textContent: _vm._s("...") },
                })
              : _vm._e(),
            _vm.isPageNumberShown(page)
              ? _c("button", {
                  key: "paginationItem" + page,
                  staticClass: "pagination-section__list-item",
                  class: {
                    "pagination-section__list-item--current":
                      page === _vm.currentPage,
                  },
                  domProps: { textContent: _vm._s(page) },
                  on: {
                    click: function ($event) {
                      return _vm.onClickButton(page)
                    },
                  },
                })
              : _vm._e(),
            _vm.isSeparatorFirstShown(page)
              ? _c("span", {
                  key: "paginationSeparatorFirst" + page,
                  staticClass: "pagination-section__list-item",
                  domProps: { textContent: _vm._s("...") },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }