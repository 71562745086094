


























import { defineComponent } from '@vue/composition-api'

/**
 * 共通 モーダルテンプレート
 */
export default defineComponent({
  name: 'CommonModalSection',
  props: {
    /**
     * モーダル内クローズボタンの有無
     */
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    /**
     * モーダル下部ボタンエリアの有無
     */
    hasButtons: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    emitClose(): void {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.$emit('close')
    },
  },
})
