var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "atom-link",
      class: [
        { "atom-link--remove-decoration": _vm.disableTextDecoration },
        _vm.colorClass,
      ],
      attrs: { href: _vm.url, target: _vm.target, rel: "noopener noreferrer" },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.linkText))]),
      _vm.imgPath
        ? _c("i", [_c("img", { attrs: { src: _vm.imgPath, alt: "" } })])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }