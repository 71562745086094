import { cloneDeep } from 'lodash'
import type { CommentDataType } from '@/components/RaceVideoPage/hook/useCommunication'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'

/**
 * ユーザーブロックに関する機能を提供する。
 */
export default function useBlockUser() {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { highlightCommentStore } = raceVideoPageStore
  const { fetchOrganizations, updateOrganization, ownOrganization } = contractInfoStore

  /**
   * 対象ユーザーをブロックする処理
   * ブロックしたユーザーのIDを組織情報に保存する
   */
  const blockTargetUser = async (
    toBeBlockedUserId: string,
    selectedComment: CommentDataType | null,
    isCancelBlock = false,
  ) => {
    const newOrgData = cloneDeep(ownOrganization.value)
    if (!toBeBlockedUserId || !newOrgData) {
      if (!selectedComment) {
        // ハイライト作成ユーザーの場合
        MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.blockHighlightUserNotFound.title'),
          message: I18n.tc('RaceVideoPage.errors.blockHighlightUserNotFound.message'),
        })
      } else {
        // ハイライトコメントを作成したユーザーの場合
        MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.blockHighlightCommentUserNotFound.title'),
          message: I18n.tc('RaceVideoPage.errors.blockHighlightCommentUserNotFound.message'),
        })
      }
      return { isSuccess: false }
    }

    if (!isCancelBlock) {
      // ブロックの場合
      if (!newOrgData?.additionalData) {
        newOrgData.additionalData = {}
      }
      if (!newOrgData.additionalData?.communication?.blockedUserIds) {
        newOrgData.additionalData.communication = {
          blockedUserIds: [toBeBlockedUserId],
        }
      } else {
        newOrgData.additionalData.communication.blockedUserIds.push(toBeBlockedUserId)
      }
    } else {
      // ブロックキャンセルの場合
      const beforeBlockUserIds =
        newOrgData.additionalData?.communication?.blockedUserIds?.filter(
          (userId) => userId !== toBeBlockedUserId,
        ) ?? []
      if (newOrgData.additionalData?.communication?.blockedUserIds) {
        // ブロックキャンセルの場合は以下の処理を必ず通る
        newOrgData.additionalData.communication.blockedUserIds = beforeBlockUserIds
      }
    }

    const result = await updateOrganization(newOrgData)

    if (!result.isSuccess) {
      // ブロックに失敗した場合、エラーメッセージを表示
      if (!selectedComment) {
        // ハイライト作成ユーザーの場合
        MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.blockHighlightUserError.title'),
          message: I18n.tc('RaceVideoPage.errors.blockHighlightUserError.message'),
          errorApiResponse: result.response,
        })
      } else {
        // ハイライトコメントを作成したユーザーの場合
        MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.blockHighlightCommentUserError.title'),
          message: I18n.tc('RaceVideoPage.errors.blockHighlightCommentUserError.message'),
          errorApiResponse: result.response,
        })
      }
      return { isSuccess: false }
    }

    // ブロックに成功した場合、組織情報を再取得
    await fetchOrganizations()

    // ハイライトコメントの場合はコメント数を再取得
    if (selectedComment?.highlightId) {
      highlightCommentStore.fetchHighlightCommentCount(selectedComment.highlightId)
    }

    return { isSuccess: true }
  }

  return {
    blockTargetUser,
  }
}
