


















import { defineComponent, PropType } from '@vue/composition-api'
import AtomLink from '@/components/atoms/AtomLink.vue'

export type ExternalLinkType = {
  name: string
  url: string
}

/**
 * アプリでは表示できない場合の案内テキスト セクション
 */
export default defineComponent({
  name: 'ExternalConfirmationSection',
  components: { AtomLink },
  props: {
    /**
     * タイトル
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * 本文
     */
    message: {
      type: String,
      default: '',
    },
    /**
     * 遷移先情報
     */
    links: {
      type: Array as PropType<Array<ExternalLinkType>>,
      default: () => [],
    },
  },
})
