import { computed, reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useCoupon from '@/store/hook/useCoupon'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractPlan from '@/store/hook/useContractPlan'
import useContractInfoPlanCoupon from '@/store/hook/useContractInfoPlanCoupon'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import useAvailableArea from '@/store/hook/useAvailableArea'

/**
 * 初期状態
 */
const initialState = {
  /**
   * 取得したワンタイムパス
   */
  oneTimePass: null as null | CouponDocument,
  /**
   * 取得したワンタイムパスのプラン
   */
  oneTimePassPlan: null as null | ContractPlanDocument,
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoのマイページ ワンタイムパス登録画面のStore
 */
class OneTimePassPageStore implements StoreBase {
  createStore() {
    const state = reactive({
      ...initialState,
    })

    // hook
    const { fetchContractInfo, ownContractInfo, clearContractInfo } = useContractInfo()
    const { fetchContractPlans, getCouponPlan, clearContractPlans } = useContractPlan()
    const { fetchContractInfoPlans, contractInfoPlans, clearContractInfoPlans } =
      useContractInfoPlan()
    const { saveContractInfoPlanCoupon } = useContractInfoPlanCoupon()
    const { fetchTargetCoupon, coupons, targetOneTimePass, clearCoupons } = useCoupon()
    const { fetchAvailableAreas, getTargetAvailableArea, clearAvailableAreas } = useAvailableArea()

    /**
     * 取得したワンタイムパス
     * @return { CouponDocument } ワンタイムパス情報
     */
    const oneTimePass = computed({
      get: (): CouponDocument | null => <CouponDocument | null>state.oneTimePass,
      set: (newOneTimePass: CouponDocument | null) => {
        state.oneTimePass = newOneTimePass
      },
    })

    /**
     * クーポンのプラン（正しいクーポンコードを入力した際に取得できる）
     * @return { ContractPlanDocument } 契約プラン情報
     */
    const oneTimePassPlan = computed({
      get: (): ContractPlanDocument | null => <ContractPlanDocument | null>state.oneTimePassPlan,
      set: (plan: ContractPlanDocument | null) => {
        state.oneTimePassPlan = plan
      },
    })

    // methods
    /**
     * ワンタイムパス登録ページのデータをクリアする
     */
    const clearOneTimePassPageData = () => {
      clearContractInfo()
      clearContractPlans()
      clearContractInfoPlans()
      clearCoupons()
      clearAvailableAreas()
      Object.assign(state, initialState)
    }

    return {
      oneTimePass,
      oneTimePassPlan,
      clearOneTimePassPageData,
      fetchContractInfo,
      fetchContractPlans,
      getCouponPlan,
      fetchContractInfoPlans,
      contractInfoPlans,
      ownContractInfo,
      saveContractInfoPlanCoupon,
      fetchTargetCoupon,
      coupons,
      targetOneTimePass,
      clearCoupons,
      fetchAvailableAreas,
      getTargetAvailableArea,
    }
  }
}

const value: ValueType<OneTimePassPageStore> = {}

export default {
  createStore: new OneTimePassPageStore().createStore,
  value: value as Required<typeof value>,
}
