var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "navigation-notification-icon-section",
      class: {
        "navigation-notification-icon-section--active":
          _vm.isDisplayNotificationIcon,
        "navigation-notification-icon-section--race": _vm.current === "race",
        "navigation-notification-icon-section--current":
          _vm.current === "notification",
        "navigation-notification-icon-section--is-sp": _vm.isSp,
      },
      attrs: { to: !_vm.isPurchasingInAppPurchase ? "/notification" : "" },
    },
    [
      _c("navigation-notification-icon-parts", {
        staticClass: "navigation-notification-icon-section__icon",
      }),
      _vm.hasName
        ? _c(
            "span",
            { staticClass: "navigation-notification-icon-section__name" },
            [_vm._v(_vm._s(_vm.$tc("navigation.notice")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }