var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standings-player-details-pane" },
    [
      _c("standings-player-details-section", {
        attrs: {
          playerData: _vm.playerData,
          isFavoritePlayer: _vm.isFavoritePlayer,
        },
        on: { onChangeFavoritePlayer: _vm.openConfirmAddFavoriteModal },
      }),
      _vm.isOpenConfirmAddFavoriteModal
        ? _c("confirm-modal-section", {
            attrs: {
              message: _vm.$tc("StandingsPage.DetailsPage.confirmAddFavorite"),
              confirmButtonLabel: _vm.$tc("common.set"),
            },
            on: {
              confirm: _vm.addFavorite,
              cancel: function ($event) {
                _vm.isOpenConfirmAddFavoriteModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }