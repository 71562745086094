
























import { computed, defineComponent } from '@vue/composition-api'
import Const from '@/util/Const'
import AtomInputSelectObject from '@/components/atoms/input/AtomInputSelectObject.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

type genderType = {
  name: Multilingual
  id: string
}
export type DataType = {
  genderData: Array<genderType>
}

/**
 * フィールドセット 性別入力 バーツ
 */
export default defineComponent({
  name: 'FieldsetGenderParts',
  components: {
    AtomInputSelectObject,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data(): DataType {
    return {
      /**
       * 性別回答一覧
       */
      genderData: Const.USER_GENDER_LIST,
    }
  },
  setup(props, context) {
    /**
     * gender
     * @param {string} val 性別
     */
    const gender = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    return {
      gender,
    }
  },
  methods: {
    /**
     * 選択が変更されたことを通知する
     */
    change() {
      /**
       * 選択が変更されたことを通知する
       * @event change
       * @type {Object}
       */
      this.$emit('change')
    },
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event enter
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
