var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cancel-paid-plan-confirm-pane" },
    [
      _c("h3", {
        staticClass: "cancel-paid-plan-confirm-pane__title",
        domProps: {
          innerHTML: _vm._s(_vm.$tc("CancelPaidPlan.ConfirmPage.heading")),
        },
      }),
      _c("ul", { staticClass: "cancel-paid-plan-confirm-pane__attention" }, [
        _c("li", [_vm._v(_vm._s(_vm.attention))]),
        _c("li", [
          _vm._v(_vm._s(_vm.$tc("CancelPaidPlan.ConfirmPage.attention02"))),
        ]),
      ]),
      _vm.attentionMessage
        ? _c("attention-message-section", {
            staticClass: "complete-pane__attention-message",
            attrs: { attentionMessage: _vm.attentionMessage },
          })
        : _vm._e(),
      _c(
        "action-buttons-section",
        {
          staticClass: "cancel-paid-plan-confirm-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".cancel-paid-plan-confirm-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("CancelPaidPlan.ConfirmPage.submit"),
              isDisabled: false,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }