var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-reset-new-password-section" },
    [
      _c("fieldset-password-parts", {
        attrs: {
          label: _vm.$t("formParts.label.newPassword"),
          showError: _vm.showNewPasswordError,
        },
        model: {
          value: _vm.newPassword.new,
          callback: function ($$v) {
            _vm.$set(_vm.newPassword, "new", $$v)
          },
          expression: "newPassword.new",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showNewPasswordError,
          message: _vm.newPasswordErrorMessage,
        },
      }),
      _c("fieldset-password-parts", {
        attrs: {
          label: _vm.$t("formParts.label.newPasswordReEnter"),
          showError: _vm.showNewPasswordReEnterError,
        },
        model: {
          value: _vm.newPassword.reEnter,
          callback: function ($$v) {
            _vm.$set(_vm.newPassword, "reEnter", $$v)
          },
          expression: "newPassword.reEnter",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showNewPasswordReEnterError,
          message: _vm.newPasswordReEnterErrorMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }