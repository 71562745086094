


















import { defineComponent, PropType } from '@vue/composition-api'
import BadgeImageParts from '@/components/MypageTopPage/MembershipCardPane/parts/BadgeImageParts.vue'
import BadgeYearParts from '@/components/MypageTopPage/MembershipCardPane/parts/BadgeYearParts.vue'

export type BadgeData = {
  badgeType: 'year' | 'supporter'
  label?: string
  years?: number
}

/**
 * マイページ: 会員証 バッヂアイテムパーツ
 */
export default defineComponent({
  name: 'MemberBadgeItemParts',
  components: {
    BadgeYearParts,
    BadgeImageParts,
  },
  props: {
    badge: {
      type: Object as PropType<BadgeData>,
      required: true,
    },
  },
})
