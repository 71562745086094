















import { computed, defineComponent, PropType } from '@vue/composition-api'
import GpsRankingPlayerParts from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/GpsRankingPlayerParts.vue'
import { PlayerLiveTimingDataType } from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * レース動画再生画面: GPS詳細 ランキングセクション
 */
export default defineComponent({
  name: 'GpsRankingSection',
  components: {
    GpsRankingPlayerParts,
  },
  props: {
    /**
     * 選択中選手ID
     */
    selectedID: {
      type: String,
      default: '',
    },
    /**
     * 選手のライブタイミングデータ
     */
    rankingData: {
      type: Array as PropType<Array<PlayerLiveTimingDataType>>,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const currentPlayerId = computed(() => props.selectedID)
    const rankingDataList = computed(() => props.rankingData)
    /**
     * 選手顔写真のURLを取得する
     * @param playerLiveTimingData 選手のライブタイミングデータ
     */
    const playerPictureUrl = (playerLiveTimingData: PlayerLiveTimingDataType) =>
      CloudFrontUtil.getSignedUrl(playerLiveTimingData.img)

    /**
     * お気に入り選手のIDを返す。
     * お気に入り選手がレース参加しているかチェックし、存在しない場合は、1位選手のIDを返す。
     */
    const checkSelectedID = () => {
      if (!rankingDataList.value || rankingDataList.value.length === 0) {
        return currentPlayerId.value
      }

      const targetPlayerLiveTiming = rankingDataList.value.find(
        (playerLiveTiming) => playerLiveTiming.id === currentPlayerId.value,
      )
      if (!targetPlayerLiveTiming) {
        // お気に入り選手がライブタイミングデータに含まれないので、先頭のライブタイミングを選択する
        return rankingDataList.value[0].id
      }

      return currentPlayerId.value
    }

    return {
      playerPictureUrl,
      checkSelectedID,
    }
  },
  methods: {},
})
