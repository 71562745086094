















import { defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'

export default defineComponent({
  name: 'MissionListTabsParts',
  props: {
    currentTab: {
      type: String,
      default: 'mission',
    },
  },
  setup() {
    const tabs = [
      {
        id: 'mission',
        name: I18n.tc('MissionPage.tabs.mission'),
        path: '/mission',
      },
      {
        id: 'ranking',
        name: I18n.tc('MissionPage.tabs.ranking'),
        path: '/mission/ranking',
      },
    ]
    return {
      tabs,
    }
  },
})
