var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _vm.title
                ? _c("modal-title-parts", { attrs: { title: _vm.title } })
                : _vm._e(),
              _c("modal-message-parts", { attrs: { message: _vm.message } }),
              _c(
                "div",
                { staticClass: "message-modal-section__content" },
                [_vm._t("default")],
                2
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "buttons",
          fn: function () {
            return [
              _c("atom-input-button", {
                attrs: {
                  linkText: _vm.buttonText,
                  colorType: "secondary",
                  isDisabled: false,
                },
                on: {
                  push: function ($event) {
                    return _vm.emitClose()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }