import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * InAppBrowser が返却するエラーコードのうち、エラー扱いとはしないエラーコードを指定する
 */
const ignoreErrorCodes = [
  // フレームの読み込みが中断(InAppBrowser表示後、Youtubeアプリで動画を開いた場合に発生する)
  102,
]

/**
 * InAppBrowserの処理結果の型定義
 */
export type InAppBrowserResult = {
  /**
   * ネットワークエラーが発生したかどうか
   */
  isNetworkError: boolean
  /**
   * サイトの読み込みでエラーが発生したかどうか
   */
  isError: boolean
  /**
   * InAppBrowser が閉じたかどうか
   */
  isClose: boolean

  /**
   * 処理結果
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result?: any
}

/**
 * InAppBrowserを表示する際に指定するオプションの型定義。
 * ※ 全てのオプションは定義していない。必要に応じて追記する。
 *
 * @see https://cordova.apache.org/docs/en/11.x/cordova/events/events.html#pause
 */
export type InAppBrowserOptionsType = {
  /**
   * InAppBrowser のロケーションバーを表示するかどうか。
   */
  location?: 'yes' | 'no'
  /**
   * yes に設定した場合、ブラウザーの作成とページの読み込みを行うが、表示はしない。
   */
  hidden?: 'yes' | 'no'

  /**
   * yes に設定した場合、新規のウィンドウを開く前に、ブラウザーの cookie とキャッシュを削除する。
   */
  clearcache?: 'yes' | 'no'

  /**
   * yes に設定した場合、新規のウィンドウを開く前に、セッションの cookie とキャッシュを削除する。
   */
  clearsessioncache?: 'yes' | 'no'

  /**
   * yes に設定した場合、新規のウィンドウを開く前に、ブラウザーのデータ(cookies, HTML5 local storage, IndexedDBなど)を削除する。
   */
  cleardata?: 'yes' | 'no'

  /**
   * ブラウザーウィンドウ内でのインライン再生を許可するかどうか。
   * iOSのみ
   */
  allowInlineMediaPlayback?: 'yes' | 'no'

  /**
   * フッターに表示するDone ボタンのラベルを設定。
   * iOSのみ
   */
  closebuttoncaption?: string

  /**
   * viewport metaタグを利用してviewportの尺度変更を行うかどうか。
   * iOSのみ
   */
  enableViewportScale?: 'yes' | 'no'

  /**
   * InAppBrowserの表示モード。iOSのUIModalPresentationStyle に設定される。
   * https://developer.apple.com/documentation/uikit/uimodalpresentationstyle
   * iOSのみ
   */
  presentationstyle?: 'pagesheet' | 'formsheet' | 'fullscreen' | 'overFullScreen'

  /**
   * InAppBrowserをフルスクリーン表示するかどうか。
   * Androidのみ
   */
  fullscreen?: 'yes' | 'no'
  /**
   * フッターを表示するかどうか。
   * Androidのみ
   */
  footer?: 'yes' | 'no'
  /**
   * フッターの色を16進色文字列で設定する( 例: #00ff00 )
   * Androidのみ
   */
  footercolor?: string
  /**
   * 閉じるボタンの色を16進色文字列で設定する( 例: #00ff00 )
   * Androidのみ
   */
  closebuttoncolor?: string
  /**
   * Android ブラウザーのズームコントロールを表示するかどうか。
   * Androidのみ
   */
  zoom?: 'yes' | 'no'
  /**
   * viewport metaタグを使用するか、wide viewport を使用するかを設定する。
   * Androidのみ
   */
  useWideViewPort?: 'yes' | 'no'
  /**
   * audio または video の自動再生をするかどうか
   * Androidのみ
   */
  mediaPlaybackRequiresUserAction?: 'yes' | 'no'
  /**
   * Androidの標準の戻るボタンを押下した際、InAppBrowser に記録されているページ遷移の履歴を使用するかどうか。
   * 戻るボタンを押下した際にすぐにInAppBrowserを閉じる挙動にする場合、noを指定する。
   * Androidのみ
   */
  hardwareback?: 'yes' | 'no'
  /**
   * アプリがバックグラウンド状態となった場合にオーディオを停止するかどうか。
   * Androidのみ
   */
  shouldPauseOnSuspend?: 'yes' | 'no'
  /**
   * ブラウザのロードをインターセプトするためのbeforeloadイベントを有効にするかどうか。
   */
  beforeload?: 'yes' | 'no' | 'get' | 'post'
}

/**
 * InAppBrowser 起動時に指定されるオプションのデフォルト値。
 */
const defaultOptions: InAppBrowserOptionsType = {
  location: 'no',
  clearcache: 'yes',
  clearsessioncache: 'yes',
  closebuttoncaption: 'Close',
  enableViewportScale: 'no',
  presentationstyle: 'fullscreen',
  footer: 'yes',
  hidden: 'no',
  fullscreen: 'yes',
  footercolor: '#000000',
  closebuttoncolor: '#FFFFFF',
  zoom: 'no',
  useWideViewPort: 'no',
  hardwareback: 'no',
  shouldPauseOnSuspend: 'yes',
}

/**
 * InAppBrowser のインスタンス。
 */
let windowRef = null as InAppBrowser | null

/**
 * InAppBrowser を操作するための機能を提供するクラス。
 * InAppBrowser については、以下のサイトを参照のこと。
 * https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-inappbrowser/index.html
 */
export default class InAppBrowserWrapper {
  /**
   * InAppBrowser が閉じた際に呼び刺されるコールバック関数
   */
  onClose?: (result: InAppBrowserResult) => void

  /**
   * InAppBrowser起動中、Orientation Lockを解除するかどうか。
   * 解除した場合、スマホの向きを変えることで、InAppBrowserの向きを変更できるようになる。
   */
  unLockOrientation = true

  /**
   * InAppBrowserウィンドウ上に挿入するJavaScriptコード
   */
  injectCode = ''

  /**
   * Androidの場合、loadstopイベントが複数回発火してしまうため、2回以上同じ処理が実行されないように制御するフラグ
   */
  loadStopFinished = false

  /**
   * InAppBrowser が利用可能かどうかを判定する
   */
  static isAvailable() {
    return !!window.cordova?.InAppBrowser
  }

  /**
   * InAppBrowser を表示する。
   * 各パラメタについては、cordova-plugin-inappbrowser の説明も参照のこと。
   *
   * @param url 表示対象のサイトのURL
   * @param target 使用するブラウザーの種別
   * @param options InAppBrowser に指定するオプション
   * @param onClose InAppBrowser が閉じた際に呼び出されるコールバック関数
   * @param unLockOrientation InAppBrowser起動中、Orientation Lockを解除するかどうか
   * @param injectCode InAppBrowserウィンドウ上に挿入されるJavaScriptコード。
   * @param doEncodeURI urlに対してURLエンコードを実施するかどうか
   * @see https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-inappbrowser/index.html#cordovainappbrowseropen
   */
  open(
    url: string,
    target: '_self' | '_blank' | '_system' = '_blank',
    options: InAppBrowserOptionsType = {},
    onClose?: (result: InAppBrowserResult) => void,
    unLockOrientation = true,
    injectCode = '',
    doEncodeURI = true,
  ) {
    // すでにInAppBrowserが表示している場合、閉じてから開く。
    // InAppBrowserがcloseイベントを発火せずに終了した場合に、ここで終了処理を行う。
    this.close(undefined, true)

    const inAppBrowserOptions: InAppBrowserOptionsType = { ...defaultOptions, ...options }
    this.onClose = onClose
    this.unLockOrientation = unLockOrientation
    this.injectCode = injectCode
    this.loadStopFinished = false

    const optionString = (Object.keys(inAppBrowserOptions) as (keyof InAppBrowserOptionsType)[])
      .map((key) => `${key}=${inAppBrowserOptions[key]}`)
      .join(',')

    Logger.debug(`Open InAppBrowser. option: ${optionString}`)
    const targetUrl = doEncodeURI ? encodeURI(url) : url
    windowRef = window.cordova.InAppBrowser.open(targetUrl, target, optionString)

    if (target === '_system') {
      // システム標準のブラウザを開いた場合、以降のリスナー登録などの処理は不要なため、処理を終了する
      return
    }

    windowRef?.addEventListener('loadstop', this.inAppBrowserLoadFinished.bind(this))
    windowRef?.addEventListener('loaderror', this.inAppBrowserLoadError.bind(this))
    windowRef?.addEventListener('exit', this.inAppBrowserClose.bind(this))
    windowRef?.addEventListener('beforeload', this.inAppBrowserBeforeLoad(windowRef))

    // InAppBrowser使用時にバックグラウンド移行すると、InAppBrowserウィンドウが後ろにまわり込み閉じられなくなる
    // 他のファイルが表示できなくなる問題への対応。バックグラウンド移行時に強制的に閉じる
    // ※ document の pauseイベントをlistenすることで、バックグラウンドへの移行を検出できる
    // https://cordova.apache.org/docs/en/11.x/cordova/events/events.html#pause
    document.addEventListener('pause', this.onPause.bind(this))
  }

  /**
   * InAppBrowser の終了処理を実施する。
   * このメソッドは、InAppBrowser を破棄したい場合に呼び出される。
   * InAppBrowserのexitやloaderrorイベント発火のタイミングで呼び出された場合、eventオプションを指定して、発生したイベントを指定する。
   *
   * @param event InAppBrowserの終了を発火したイベント
   * @param silent true を指定した場合、onCloseコールバック関数を呼び出し実施せずに終了する
   * @param result 処理結果
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close(event?: InAppBrowserEvent, silent = false, result?: any) {
    document.removeEventListener('pause', this.onPause)
    windowRef?.removeEventListener('loadstop', this.inAppBrowserLoadFinished.bind)
    windowRef?.removeEventListener('loaderror', this.inAppBrowserLoadError)
    windowRef?.removeEventListener('exit', this.inAppBrowserClose)
    windowRef?.close()
    windowRef = null

    window.screen.orientation
      .lock('portrait')
      .catch((e) =>
        Logger.debug(
          `Skip orientation lock due to screen.orientation.lock() is not available on this device. e: ${e}`,
        ),
      )
    if (!silent && event) {
      this.onClose?.({
        isNetworkError: event?.code === -1009,
        isError: !!event && !ignoreErrorCodes.includes(event?.code),
        isClose: true,
        result,
      })
    }
  }

  /**
   * BeforeLoadイベントが発生した際に呼び出される。
   *
   * @param ref InAppBrowser インスタンス
   * @protected
   */
  // eslint-disable-next-line class-methods-use-this
  protected inAppBrowserBeforeLoad(
    ref: InAppBrowser, // eslint-disable-line @typescript-eslint/no-unused-vars
  ): InAppBrowserEventListenerOrEventListenerObject {
    return (event: InAppBrowserEvent, callback: (url: string) => void) => {
      Logger.debug(`BeforeLoad event occurred. event: ${event}`)
      callback(event.url)
    }
  }

  /**
   * InAppBrowser の読み込みが終了した際に呼び出される。
   *
   * @protected
   */
  // eslint-disable-next-line class-methods-use-this
  protected inAppBrowserLoadFinished() {
    Logger.debug('Finish to load InAppBrowser.')

    if (this.loadStopFinished) {
      // Androidの場合、loadstopイベントが複数回発火してしまうため、loadstopイベントの処理が最後まで実行された時にloadStopFinishedをtrueとし、2回以上実行されないように制御している
      return
    }

    if (this.unLockOrientation) {
      window.screen.orientation
        .lock('any')
        .catch((e) =>
          Logger.debug(
            `Skip orientation lock due to screen.orientation.lock() is not available on this device. e: ${e}`,
          ),
        )
    }

    if (DeviceInfo.isAndroid()) {
      // https://github.com/apache/cordova-plugin-inappbrowser/issues/294#issuecomment-516069926
      windowRef?.insertCSS(
        { code: 'html{ height: 100%; } body{ height: calc(100% - 45px); }' },
        () => {
          Logger.debug('Success insert CSS to respect Android InAppBrowser footer height.')
        },
      )
    }

    const cssCode = this.makeInsertStyle()
    if (cssCode) {
      windowRef?.insertCSS({ code: cssCode }, () => {
        Logger.debug(`Success insert CSS to InAppBrowser. css: ${cssCode}`)
      })
    }

    if (this.injectCode) {
      // InAppBrowserウィンドウ上にJavaScriptコードを挿入する
      windowRef?.executeScript(
        {
          code: this.injectCode,
        },
        this.executeScriptCallBack,
      )
    }

    // loadstopイベント発火時に実行される処理を実行済みとする
    this.loadStopFinished = true
  }

  /**
   * InAppBrowser の読み込みがエラー終了した際に呼び出される。
   *
   * @param event エラー原因が設定されたイベント
   * @protected
   */
  protected inAppBrowserLoadError(event: InAppBrowserEvent) {
    Logger.info(`Failed to load InAppBrowser. e: ${event}`)
    this.close(event)
  }

  /**
   * InAppBrowser が閉じられた際に呼び出される。
   *
   * @protected
   */
  protected inAppBrowserClose() {
    Logger.debug('InAppBrowser has been closed')
    this.close()
  }

  /**
   * InAppBrowser がバックグラウンドに移動した場合に呼び出される。
   *
   * @protected
   */
  protected onPause() {
    Logger.debug('InAppBrowser has been paused.')
    this.close()
  }

  /**
   * InAppBrowserに埋め込むCSSを返す。
   * サブクラスは、このメソッドをオーバーライドして、InAppBrowserに指定したいCSSを生成することができる。
   *
   * @protected
   */
  // eslint-disable-next-line class-methods-use-this
  protected makeInsertStyle(): string | null {
    return null
  }

  /**
   * executeScript が実行された場合に呼び出される。
   *
   * @protected
   */
  // eslint-disable-next-line class-methods-use-this
  protected executeScriptCallBack() {
    Logger.debug('InAppBrowser executeScript has been executed.')
  }
}
