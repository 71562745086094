var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-details-setting-section" },
    [
      _vm.title
        ? _c("h3", { staticClass: "mypage-details-setting-section__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _c("details-data-list-parts", {
        attrs: { detailsListItems: _vm.detailsListItems },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }