var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3.2045 9.34171H4.49996V7.43262H2.79541C1.96698 7.43262 1.29541 8.10419 1.29541 8.93262V18.9846C1.29541 19.813 1.96698 20.4846 2.79541 20.4846H21.2045C22.0329 20.4846 22.7045 19.813 22.7045 18.9846V8.93262C22.7045 8.10419 22.0329 7.43262 21.2045 7.43262H19.5V9.34171H20.7954V18.5755H3.2045V9.34171Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 15.0806C12 15.0806 17.25 9.81802 17.25 6.08057C17.25 3.18107 14.8995 0.830566 12 0.830566C9.10051 0.830566 6.75 3.18107 6.75 6.08057C6.75 9.81802 12 15.0806 12 15.0806ZM14.3575 6.00802C14.3575 7.30984 13.3022 8.36517 12.0004 8.36517C10.6986 8.36517 9.64323 7.30984 9.64323 6.00802C9.64323 4.70621 10.6986 3.65088 12.0004 3.65088C13.3022 3.65088 14.3575 4.70621 14.3575 6.00802Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }