<template>
  <svg class="icon-circuit" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.2324 0.871165C30.3023 0.510958 30.4895 0.691062 31.1581 1.15418C32.3082 1.95178 32.2815 3.08386 30.9174 3.36688C30.4092 3.4698 28.8044 3.83 28.43 3.90719C28.0555 3.98438 27.6811 4.2674 27.4136 4.83344C27.0927 5.57958 26.6915 5.91405 25.7553 5.78541C24.2843 5.60531 23.6156 6.29999 23.4016 6.96894C22.9202 8.46123 21.4224 8.40977 20.727 7.66363C20.1653 7.04613 19.2292 6.55728 18.0791 7.30342C16.501 8.33258 16.2068 10.3652 17.2232 11.8575C17.5441 12.3206 18.5337 13.8386 18.6675 14.0702C18.8547 14.3532 18.8815 14.4304 18.828 14.7391C18.7745 14.9707 18.4 16.4115 18.3465 16.5916C18.293 16.746 18.1593 16.9261 17.9186 16.9003C17.5441 16.8489 12.9972 16.2571 12.7565 16.2057C12.489 16.1542 12.2215 16.0256 11.9808 15.8197C11.7401 15.6139 11.2052 15.125 10.9912 14.9192C10.5633 14.5333 10.1353 15.0479 10.4563 15.4081C10.6435 15.6654 11.5261 16.6945 11.7134 16.9003C13.0774 18.5985 12.9972 19.9364 11.6331 21.583C10.2155 23.3069 9.52014 23.6928 8.37003 23.8215C7.78161 23.8986 5.61513 24.1045 5.26743 24.1559C4.91972 24.2074 4.43828 24.3103 4.25106 25.0565C4.14407 25.4681 4.14407 25.6482 4.09058 25.8798C3.90335 27.1405 5.10695 27.7837 6.49777 26.9347C7.00596 26.6259 9.57363 24.9793 11.0982 23.1268C11.9273 22.1233 15.6184 17.6208 16.2603 16.8489C16.9022 16.0513 17.0092 15.6654 17.0092 14.9192C17.0092 14.1473 16.6882 13.1696 16.3673 12.5779C15.8858 11.6774 15.2974 10.4424 15.0567 9.51612C15.0032 9.28456 15.0032 9.18164 15.2707 9.07872C15.8323 8.82143 15.8591 8.71852 15.7254 8.33258C15.3509 7.43207 15.4846 6.60874 15.9126 6.09416C16.5545 5.27083 17.3034 4.93635 18.5872 4.49896C19.2559 4.2674 28.5102 1.10273 29.2324 0.871165Z"
      stroke="currentColor"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionSuzukaIcon',
})
</script>

<style scoped lang="scss">
.icon-circuit {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
