var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standings-top-pane" },
    [
      _c("select-year-section", {
        staticClass: "standings-top-pane__year-select",
        attrs: { raceYear: _vm.selectedYear, yearList: _vm.yearList },
        on: { change: _vm.yearChange },
      }),
      _vm.standingsPlayerList
        ? _c("standings-list-section", {
            attrs: { standingsPlayerList: _vm.standingsPlayerList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }