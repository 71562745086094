










import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageContactUsPane from '@/components/MypageContactUsPage/MypageContactUsPane.vue'

/**
 * マイページ: お問い合わせページ
 */
export default defineComponent({
  name: 'MypageContactPage',
  components: {
    SubHeaderSection,
    MypageContactUsPane,
  },
})
