









































import { defineComponent } from '@vue/composition-api'
import AtomButton from '@/components/atoms/AtomButton.vue'
import MypageTicketCloseIconParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypagetTicketCloseIconParts.vue'

export default defineComponent({
  name: 'MypageTicketTodaySafeModalSection',
  components: { MypageTicketCloseIconParts, AtomButton },
  setup(props, { emit }) {
    const handleSubmitClicked = () => {
      emit('onClickSubmit')
    }

    const handleCloseClicked = () => {
      emit('onClickClose')
    }

    return {
      handleSubmitClicked,
      handleCloseClicked,
    }
  },
})
