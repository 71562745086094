var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instagram-post-item-contents-parts" },
    [
      _c(
        "a",
        {
          staticClass: "instagram-post-item-contents-parts__link",
          attrs: { href: _vm.snsPost.link, target: "_blank" },
        },
        [
          _c("sns-post-item-header-parts", { attrs: { snsPost: _vm.snsPost } }),
          _c("p", { staticClass: "post-item-contents-parts__text" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.snsPost.post) } }),
          ]),
        ],
        1
      ),
      _vm.snsPost.images.length > 0
        ? _c("sns-post-item-images-parts", {
            key: _vm.snsPost.link,
            attrs: { images: _vm.snsPost.images, postLink: _vm.snsPost.link },
            on: { updateSnsContentsData: _vm.updateSnsContentsData },
          })
        : _vm._e(),
      _c(
        "a",
        {
          staticClass: "instagram-post-item-contents-parts__link",
          attrs: { href: _vm.snsPost.link, target: "_blank" },
        },
        [
          _c("div", { staticClass: "post-item-contents-parts__footer" }, [
            _c(
              "div",
              { staticClass: "post-item-contents-parts__action-count" },
              [
                _c(
                  "p",
                  {
                    staticClass: "post-item-contents-parts__action-count__data",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/icon_sns_like.svg"),
                        alt: "",
                      },
                    }),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.snsPost.like) },
                    }),
                  ]
                ),
              ]
            ),
            _c("p", {
              staticClass: "post-item-contents-parts__time",
              domProps: { textContent: _vm._s(_vm.snsPost.time) },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }