var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ranking-list-section",
      class: "ranking-list-section--" + _vm.screenOrientationType,
    },
    [
      _vm._l(_vm.battleData, function (value, battleKey) {
        return _c(
          "div",
          {
            key: battleKey,
            staticClass: "ranking-list-section__battle",
            style: {
              top: _vm.battleAreaTopPosition(value),
              height: _vm.battleAreaHeight(value),
            },
          },
          [_vm._m(0, true)]
        )
      }),
      _vm._l(_vm.rankingDataList, function (playerLiveTiming) {
        return _c("ranking-player-parts", {
          key: playerLiveTiming.id,
          attrs: {
            lockOrientation: _vm.lockOrientation,
            rank: playerLiveTiming.position,
            player: playerLiveTiming,
            img: _vm.playerPictureUrl(playerLiveTiming),
            leader: _vm.leader,
            rankingViewMode: _vm.rankingViewMode,
            selectedID: _vm.checkSelectedID(),
            battleData: _vm.battleData,
            screenOrientationType: _vm.screenOrientationType,
            "player-telemetry": _vm.playerTelemetry[playerLiveTiming.carNo],
            "player-radio": _vm.playerRadioDataByCarNo.get(
              playerLiveTiming.carNo
            ),
          },
          on: { rankingRadioPlay: _vm.playRankingRadio },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ranking-list-section__battle-title" }, [
      _c("span", [_vm._v("BATTLE")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }