/* eslint-disable max-classes-per-file */
import { nextTick, reactive } from '@vue/composition-api'
import Vue from 'vue'
import i18n from '@/locales/I18n'
import { StoreBase, ValueType } from '@/store/StoreBase'
import APIResponse, { errorCodes } from '@/util/APIResponse'
import Logger from '@/util/logger/Logger'

export type CustomButtonType = {
  /**
   * ボタン上に表示するラベル
   */
  btnLabel: string
  /**
   * ボタン押下時の戻り値
   */
  btnValue: string
  /**
   * ボタンの色
   * 指定できるカラーの仕様は以下を参照
   * @see https://vuetifyjs.com/ja/api/v-btn/
   * @see https://vuetifyjs.com/ja/styles/colors/
   */
  color: string
  /**
   * ボタン幅のサイズを指定
   * 指定できる値は下記を参照
   * @see https://vuetifyjs.com/ja/api/v-btn/
   */
  width: string | number
}

/* eslint-disable class-methods-use-this */
class MessageDialogStore implements StoreBase {
  createStore() {
    const state = reactive({
      isVisible: false,
      title: '',
      message: '',
      declineCode: '',
      description: '',
      nextUserAction: '',
      apiRequestErrorMessage: '',
      isVisibleCancelButton: false,
      buttons: [] as CustomButtonType[],
    })

    let resolver: ((result: string | 'OK' | 'Cancel') => void) | null = null

    /**
     * ダイアログ表示に必要な項目を設定する。
     * ボタンのcolorには assets/scss/messageDialogColor.scss に定義されているCSSカスタムスタイルが指定できる。
     * @param title ダイアログタイトル
     * @param message ダイアログメッセージ
     * @param declineCode 拒否コード
     * @param description 拒否コード：説明
     * @param nextUserAction 拒否コード：次のステップ
     * @param errorApiResponse
     * @param cancelButton キャンセルボタン表示有無 default=非表示
     * @param customButtons 表示するボタンの配列(未指定の場合はOK、さらにisVisibleCancelButton=trueの場合はCANCELをデフォルトで表示)
     */
    const setConfig = ({
      title = '',
      message = '',
      declineCode = '',
      description = '',
      nextUserAction = '',
      errorApiResponse = null as APIResponse | null,
      isVisibleCancelButton = false,
      customButtons = [] as CustomButtonType[],
    }) => {
      state.title = title
      state.message = message
      state.declineCode = declineCode
      state.description = description
      state.nextUserAction = nextUserAction
      state.isVisibleCancelButton = isVisibleCancelButton
      if (errorApiResponse) {
        const occurredErrorCode =
          errorCodes.find((code) => errorApiResponse?.status === code) ?? 500
        state.apiRequestErrorMessage = i18n.t(
          `apiNotification.dialog.${occurredErrorCode}.message`,
        ) as string
      } else {
        state.apiRequestErrorMessage = ''
      }
      if (!customButtons || customButtons.length === 0) {
        const buttons = [] as CustomButtonType[]
        if (isVisibleCancelButton)
          buttons.push({
            btnLabel: i18n.t('common.cancel') as string,
            btnValue: 'CANCEL',
            color: 'var(--message-dialog-button-color-cancel)',
            width: '7rem',
          })
        buttons.push({
          btnLabel: i18n.t('common.ok') as string,
          btnValue: 'OK',
          color: 'var(--message-dialog-button-color-ok)',
          width: '7rem',
        })
        state.buttons = buttons
      } else {
        state.buttons = customButtons
      }
    }

    /**
     * ダイアログを表示する
     * 先にダイアログの設定をしてから、ダイアログを開く場合に使用。
     * マニュアルダイアログオープン
     */
    const dialogOpen = (): Promise<string | 'OK' | 'Cancel' | 'CanNotOpen'> => {
      if (resolver) {
        Logger.info('MessageDialogStore#dialogOpen: The dialog could not be opened.')
        return Promise.resolve('CanNotOpen')
      }
      return new Promise((resolve) => {
        state.isVisible = true
        Vue.nextTick().then(() => {
          resolver = (msg) => resolve(msg)
        })
      })
    }

    /**
     * ダイアログを表示する
     * @param title ダイアログタイトル
     * @param message ダイアログメッセージ
     * @param declineCode 拒否コード
     * @param description 拒否コード：説明
     * @param nextUserAction 拒否コード：次のステップ
     * @param isVisibleCancelButton キャンセルボタン表示有無 default=非表示
     * @param errorApiResponse エラーとなったAPIレスポンス。
     * これを指定した場合、state.apiRequestErrorMessage から、APIレスポンスのエラー内容を表す文字列を取得できる。
     * @param customButtons 表示するボタンの配列(未指定の場合はOK、さらにisVisibleCancelButton=trueの場合はCANCELをデフォルトで表示)
     */
    const open = ({
      title = '',
      message = '',
      declineCode = '',
      description = '',
      nextUserAction = '',
      isVisibleCancelButton = false,
      errorApiResponse = null as APIResponse | null,
      customButtons = [] as CustomButtonType[],
    }) => {
      if (errorApiResponse?.data?.error_code === APIResponse.ERROR_CODE.SESSION_LIMIT_EXCEEDED) {
        // 同時ログイン上限数を超えている場合、同時ログインエラーのメッセージが表示されるため、このダイアログはオープンしない
        return Promise.resolve('CanNotOpen')
      }
      setConfig({
        title,
        message,
        declineCode,
        description,
        nextUserAction,
        errorApiResponse,
        isVisibleCancelButton,
        customButtons,
      })
      return dialogOpen()
    }

    /**
     * ダイアログを閉じる
     */
    const close = async (btn: string | 'OK' | 'Cancel') => {
      state.isVisible = false
      await nextTick()
      if (resolver) {
        resolver(btn)
      }
      resolver = null
    }

    return {
      open,
      close,
      setConfig,
      dialogOpen,
      /**
       * 表示フラグ
       */
      get isVisible(): boolean {
        return state.isVisible
      },
      /**
       * ダイアログタイトル
       */
      get title(): string {
        return state.title
      },
      /**
       * ダイアログメッセージ
       */
      get message(): string {
        return state.message
      },
      /**
       * 拒否コード
       */
      get declineCode(): string {
        return state.declineCode
      },
      /**
       * 拒否コード：説明
       */
      get description(): string {
        return state.description
      },
      /**
       * 拒否コード：次のステップ
       */
      get nextUserAction(): string {
        return state.nextUserAction
      },
      /**
       * 表示フラグ
       */
      get isVisibleCancelButton(): boolean {
        return state.isVisibleCancelButton
      },
      /**
       * 表示するボタンの配列
       */
      get buttons(): CustomButtonType[] {
        return state.buttons
      },
      /**
       * APIリクエストエラーメッセージ
       */
      get apiRequestErrorMessage(): string {
        return state.apiRequestErrorMessage
      },
    }
  }
}

const value: ValueType<MessageDialogStore> = {}

export default {
  createStore: new MessageDialogStore().createStore,
  value: value as Required<typeof value>,
}
