var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "favorite-selector-button-parts",
      on: { click: _vm.emitClick },
    },
    [
      _vm._t("default"),
      _c("i", { staticClass: "favorite-selector-button-parts__arrow" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "13",
              height: "15",
              viewBox: "0 0 13 15",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M7.73757 0.676626L12.5608 5.49985H10.4395L6.67691 1.73729C6.57928 1.63965 6.42099 1.63965 6.32335 1.73729L2.56079 5.49985H0.43947L5.26269 0.676625C5.94611 -0.0067932 7.05415 -0.00679034 7.73757 0.676626ZM5.26268 14.3231L0.439453 9.49985H2.56077L6.32334 13.2624C6.42097 13.36 6.57926 13.36 6.67689 13.2624L10.4395 9.49985H12.5608L7.73755 14.3231C7.05413 15.0065 5.94609 15.0065 5.26268 14.3231Z",
                fill: "black",
              },
            }),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }