


































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { UserDataType } from '@/components/MypageTopPage/MembershipCardPane.vue'
import SwitchEditModeButtonParts from '@/components/MypageTopPage/MembershipCardPane/parts/SwitchEditModeButtonParts.vue'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

/**
 * マイページ: 会員証 編集モードセクション
 */
export default defineComponent({
  name: 'MembershipCardEditModeSection',
  components: {
    SwitchEditModeButtonParts,
    AtomInputText,
  },
  props: {
    /**
     * 登録済みのユーザー情報
     */
    userData: {
      type: Object as PropType<UserDataType>,
      default: () => ({}),
      require: true,
    },
    userPreviewData: {
      type: Object as PropType<UserDataType>,
      default: () => ({}),
      require: true,
    },
    /**
     * 会員証を編集モードで表示するかどうか
     */
    isEditCardForHighlightComment: {
      type: Boolean,
      default: false,
    },
    /**
     * 遷移元が動画再生画面でハイライト作成でニックネームが未登録で遷移してきたかどうか
     */
    isTransitionCreateHighlight: {
      type: Boolean,
      default: false,
    },
    /**
     * アイコン画像データ
     */
    valueUserIconFileData: {
      type: File as PropType<File>,
      default: null,
    },
  },
  setup(props, context) {
    /** プレビュー用表示名 */
    const previewName = computed({
      get: () => props.userPreviewData.name,
      set: (val: string) => context.emit('onChangeUsername', val),
    })

    /** ユーザーのアイコン画像ファイル */
    const userIconFileData = computed(() => props.valueUserIconFileData)

    /** アップロードしたファイルの一時URL */
    const userIconTmpUrl = computed(() =>
      userIconFileData.value ? URL.createObjectURL(userIconFileData.value) : null,
    )

    return {
      previewName,
      userIconTmpUrl,
    }
  },
  methods: {
    /**
     * 編集キャンセル通知
     */
    async emitCancel() {
      this.$emit('handlerCancel')
    },
    /**
     * アップロード画像
     * プレビューセット処理
     */
    uploadFile() {
      const fileInput = this.$refs.imageInput as HTMLInputElement | null

      if (fileInput?.files) {
        this.$emit('onChangeUserIconData', fileInput.files[0])
        fileInput.value = ''
      }
    },
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      if (this.previewName === '') {
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.errors.displayNameNull.title') as string,
          message: I18n.tc('MypagePage.errors.displayNameNull.message') as string,
        })
        return
      }
      if (this.previewName.length > Const.MAX_DISPLAY_NAME_LENGTH) {
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.errors.displayNameLengthExceeded.title') as string,
          message: I18n.tc('MypagePage.errors.displayNameLengthExceeded.message') as string,
        })
        return
      }

      this.$emit('handlerSubmit')
    },
  },
})
