var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sns-content-section" },
    [
      _c("sns-content-tabs-parts", {
        staticClass: "sns-content-section__tabs",
        attrs: { currentType: _vm.snsContentType },
        on: { select: _vm.tabsChange },
      }),
      _c(
        "div",
        {
          staticClass: "sns-content-section__content",
          class: { "sns-content-section__content__isApp": !_vm.isForPC },
        },
        [
          _vm.snsContentType === "driver" ? _c("sns-driver-section") : _vm._e(),
          _vm.snsContentType === "team" ? _c("sns-team-section") : _vm._e(),
          _vm.snsContentType === "official"
            ? _c("sns-official-section")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }