import dayjs from 'dayjs'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import I18n from '@/locales/I18n'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { InAppPurchasePlanGroupType } from '@/util/Const'

/**
 * 契約プラン情報.定価の型
 */
export type Price = {
  JPY: number
  USD: number
  EUR: number
} | null

/**
 * プランマスタに登録済みの契約プランの型
 */
export type PlanGroupType =
  | InAppPurchasePlanGroupType
  | 'settlementReadonlyPlan'
  | 'freePlan'
  | 'monthlyPlan'
  | 'annualPlan'
  | 'limitedTimePaidPlan'
  | 'limitedTimePaidPlanForAnnual'
  | 'trialPlan'
  | 'trialPlanForAnnual'
  | ''

/**
 * 有料プラン登録で選択できるプランの型
 */
export type PaidPlanGroupType = Extract<
  PlanGroupType,
  'monthlyPlan' | 'annualPlan' | 'limitedTimePaidPlan' | 'limitedTimePaidPlanForAnnual' | ''
>

/**
 * 無料プラン変更時に選択できるプランの型
 */
export type FreePlanGroupType = Extract<PlanGroupType, 'freePlan' | ''>

/**
 * クーポン終了後のプランの型
 */
export type PlanAfterCouponEndsType = Extract<
  PlanGroupType,
  'freePlan' | 'monthlyPlan' | 'annualPlan'
>

/**
 * 契約プラン情報を表現するクラス。
 */
export default class ContractPlanDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractPlanDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis<ContractPlanDocument>(initProps)
  }

  _path = 'data/master/contract_plan'

  idAttr = 'contractPlanId'

  /**
   * 契約プランID
   */
  contractPlanId: string | null = null

  /**
   * クーポンID
   */
  couponId?: string | null = null

  /**
   * 契約プラン名
   */
  name: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 定価
   */
  price: Price | null = null

  /**
   * プラングループID
   * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3379429607/SFgo
   */
  planGroupId: PlanGroupType = ''

  /**
   * 開始日時
   */
  startDate?: number | null = null

  /**
   * 終了日時
   */
  endDate?: number | null = null

  /**
   * クーポン/ワンタイムパスのプランを表示する時に使うデータ
   */
  couponData?: {
    /**
     * クーポン名
     */
    couponName: Multilingual
    /**
     * 見出し
     */
    title: Multilingual
    /**
     * クーポンの説明
     */
    description: Multilingual
    /**
     * クーポン適用期間
     */
    couponApplicationPeriodText: string
  }

  /**
   * 契約プランID別名
   */
  set id(key: string | null) {
    this.contractPlanId = key
  }

  get id() {
    return this.contractPlanId
  }

  /**
   * 契約プラン契約期間テキスト
   */
  get nextContractTermText() {
    const { getDisplayDate } = useDisplayDependingOnLang()

    const startDate = getDisplayDate(dayjs().valueOf())
    let endDate = ''

    if (this.planGroupId === 'annualPlan') {
      // 年払いプランの場合、契約終了日は1年-1日後
      endDate = getDisplayDate(dayjs().add(1, 'y').subtract(1, 'd').valueOf())
    }
    if (this.planGroupId === 'monthlyPlan') {
      // 月払いプランの場合、契約終了日は1ヶ月-1日後
      endDate = getDisplayDate(dayjs().add(1, 'M').subtract(1, 'd').valueOf())
    }

    // annualPlan、monthlyPlan以外の場合は契約期間を表示しない
    return endDate
      ? `${I18n.t('SignupPage.SelectPlanPage.plan.contractTerm', {
          startDate,
          endDate,
        }).toString()}`
      : ''
  }
}
