


















import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: STANDINGS リンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationStandingsIconParts',
})
