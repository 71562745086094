var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ec-site-connect-contact-parts" }, [
    _c("h3", {
      staticClass: "mypage-ec-site-connect-contact-parts__title",
      domProps: {
        innerHTML: _vm._s(_vm.$tc("MypagePage.MypageContactUsPage.titleEmail")),
      },
    }),
    _c(
      "div",
      { staticClass: "mypage-ec-site-connect-contact-parts__button" },
      [
        _c("atom-link-button", {
          attrs: {
            linkText: _vm.$tc("MypagePage.MypageContactUsPage.toContactForm"),
            url: _vm.inquiryFormUrl,
            isDisabled: true,
            colorType: "secondary",
          },
        }),
      ],
      1
    ),
    _c(
      "p",
      {
        staticClass: "mypage-ec-site-connect-contact-parts__attention-inquiry",
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.$tc("MypagePage.MypageContactUsPage.attentionInquiry")) +
            " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }