import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import type { ChargeType } from '@/store/stores/collectionModule/documents/contractInfo/BillingInfoTypes'

/**
 * プラン一覧の型
 */
type PlanPriceType = {
  /**
   * 契約プランID
   */
  contractPlanId: string | null
  /**
   * 請求予定日
   */
  billingDate: number | null
  /**
   * 開始日時
   */
  startDate?: number | null
  /**
   * 終了日時
   */
  endDate?: number | null
}

/**
 * 変更する新しい基本プランの型
 */
type NewPlanType = {
  /**
   * 派生元の契約プランID
   * 年契約プラン料金を取得する際に使用する
   */
  originContractPlanId?: string | null
  /**
   * 契約プランID
   * 月契約プラン料金を取得する際に使用する
   */
  contractPlanId?: string | null
}

/**
 * 契約プラン料金情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/2745532533/API
 */
export default class ContractInfoPlanPriceDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoPlanPriceDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contract_info'

  /**
   * key: このスキーマには既にidと言うキーが存在し、DocumentWrapperのid(computed)と競合するため、このスキーマではidをkeyという名称で扱うこととする
   * key = テーブル上のidに相当
   */
  protected idAttr = 'key'

  key: string | null = null

  /**
   * 請求金額
   */
  charge?: ChargeType | null = null

  /**
   * プラン一覧
   */
  plans?: Array<PlanPriceType> | null = null

  set id(key: string | null) {
    this.key = key
  }

  get id(): string | null {
    return this.key
  }

  /**
   * 以下、契約プラン料金を取得するのに必要なPOSTリクエストボディ
   * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/2745499793/API#%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%83%9C%E3%83%87%E3%82%A3
   */
  /**
   * 契約プラン開始日時
   */
  startDate?: number | null = null

  /**
   * 契約プラン終了日時
   */
  endDate?: number | null = null

  /**
   * 変更する新しい基本プラン
   */
  newPlans?: Array<NewPlanType>
}
