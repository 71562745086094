
















import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import NotificationPane from '@/components/NotificationPane/NotificationPane.vue'

/**
 * NOTIFICATION 通知ページ
 */
export default defineComponent({
  name: 'NotificationPage',
  components: {
    HeaderPane,
    GlobalNavigationPane,
    NotificationPane,
  },
})
