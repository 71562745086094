























































import { computed, defineComponent, onBeforeUnmount, PropType } from '@vue/composition-api'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import AttentionMessageSection, {
  AttentionMessageData,
} from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import PaymentScheduleSection, {
  PaymentScheduleData,
} from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * 変更完了画面 ペイン
 */
export default defineComponent({
  name: 'PaymentConfirmPane',
  components: {
    ActionButtonsSection,
    AtomLinkButton,
    AtomRouterButton,
    AttentionMessageSection,
    PaymentScheduleSection,
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    hasActionButton: {
      type: Boolean,
      default: true,
    },
    isReadOnlyPlan: {
      type: Boolean,
      default: false,
    },
    isCouponUsed: {
      type: Boolean,
      default: false,
    },
    displayAttentionMessage: {
      type: Boolean,
      default: false,
    },
    /**
     * 注意メッセージ
     */
    attentionMessage: {
      type: Object as PropType<AttentionMessageData>,
      default: null,
    },
    /**
     * 請求予定情報
     */
    paymentSchedule: {
      type: Array as PropType<PaymentScheduleData[]>,
      default: null,
    },
  },
  setup(props) {
    const isAndroidMobile = DeviceInfo.isAndroidBrowser()

    // PCで開いている場合は、アプリにリダイレクトされる可能性を考慮しない
    // TODO: ※iPhoneからアプリに戻れないバグの対応が出来次第、iPhoneもAndroidと同じ挙動にする。
    const transitionUrl = isAndroidMobile
      ? `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`
      : '/login'

    /**
     * アプリから遷移したかどうか
     */
    const transitionFromMobileApp = computed(() => LocalStorageAccessor.transitionFromMobileApp)

    /**
     * 注意メッセージを表示するかどうか
     * - 注意メッセージが存在する displayAttentionMessage がtrueの時
     * - 注意メッセージが存在する かつ 請求予定が存在する場合（月額プランを登録した場合に表示される）
     * - クーポンプランを登録 かつ 注意メッセージが存在する場合
     */
    const isShowAttentionMessage = computed(
      () =>
        (props.attentionMessage && props.displayAttentionMessage) ||
        (props.attentionMessage && props.paymentSchedule) ||
        (props.attentionMessage && props.isCouponUsed),
    )

    /**
     * 契約情報変更が完了した場合、契約情報更新漏れを防ぐためリロードしてログアウト状態にする
     * - 無料から有料プランに切り替える
     * - 支払いプランを切り替える（有料→有料）
     * - 有料会員を解約
     * - クレジットカード番号の変更
     * - 退会
     */
    onBeforeUnmount(() => {
      window.location.reload()
    })

    return {
      isAndroidMobile,
      transitionUrl,
      transitionFromMobileApp,
      isShowAttentionMessage,
    }
  },
})
