var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.linkData.url !== "" && _vm.linkData.transitionCase === "routerLink"
    ? _c(
        "div",
        [
          _c(
            "router-link",
            { staticClass: "link-item-parts", attrs: { to: _vm.linkData.url } },
            [
              _c("i", { staticClass: "link-item-parts__icon" }, [
                _c("img", { attrs: { src: _vm.linkData.iconPath, alt: "" } }),
              ]),
              _c("span", {
                staticClass: "link-item-parts__text",
                domProps: { innerHTML: _vm._s(_vm.linkData.text) },
              }),
            ]
          ),
        ],
        1
      )
    : _vm.linkData.url !== "" && _vm.linkData.transitionCase === "inAppBrowser"
    ? _c("div", [
        _c(
          "a",
          {
            staticClass: "link-item-parts",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.onClickAnchorLink(_vm.linkData.url)
              },
            },
          },
          [
            _c("i", { staticClass: "link-item-parts__icon" }, [
              _c("img", { attrs: { src: _vm.linkData.iconPath, alt: "" } }),
            ]),
            _c("span", {
              staticClass: "link-item-parts__text",
              domProps: { innerHTML: _vm._s(_vm.linkData.text) },
            }),
          ]
        ),
      ])
    : _vm.linkData.url !== ""
    ? _c("div", [
        _c(
          "a",
          {
            staticClass: "link-item-parts",
            attrs: { href: _vm.linkData.url, target: "_blank" },
          },
          [
            _c("i", { staticClass: "link-item-parts__icon" }, [
              _c("img", { attrs: { src: _vm.linkData.iconPath, alt: "" } }),
            ]),
            _c("span", {
              staticClass: "link-item-parts__text",
              domProps: { innerHTML: _vm._s(_vm.linkData.text) },
            }),
          ]
        ),
      ])
    : _c("div", { staticClass: "link-item-parts" }, [
        _c("i", { staticClass: "link-item-parts__icon" }, [
          _c("img", { attrs: { src: _vm.linkData.iconPath, alt: "" } }),
        ]),
        _c("span", {
          staticClass: "link-item-parts__text",
          domProps: { innerHTML: _vm._s(_vm.linkData.text) },
        }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }