






import { defineComponent } from '@vue/composition-api'
import MissionListPane from '@/components/MissionPage/MissionListPane.vue'

/**
 * ミッションページ
 */
export default defineComponent({
  name: 'MissionPage',
  components: { MissionListPane },
})
