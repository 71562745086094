var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ranking-setting-section",
      class: "ranking-setting-section--" + _vm.screenOrientationType,
    },
    [
      _c(
        "div",
        { staticClass: "ranking-setting-section__inner" },
        [
          _c("ranking-flag-parts", {
            staticClass: "ranking-setting-section__flag",
            attrs: { value: _vm.flagType },
          }),
          _c(
            "label",
            {
              staticClass: "ranking-setting-section__leader",
              class: {
                "ranking-setting-section__leader--disabled":
                  _vm.rankingViewMode !== "gap",
              },
            },
            [
              _c("atom-input-checkbox", {
                staticClass: "fieldset-checkbox-parts__input",
                attrs: { value: _vm.leader, color: "dark" },
                on: { input: _vm.toggleLeader },
              }),
              _c("span", [_vm._v("Leader")]),
            ],
            1
          ),
          _c("view-mode-selector-parts", {
            staticClass: "ranking-setting-section__data-select",
            attrs: {
              rankingViewMode: _vm.rankingViewMode,
              rankingViewModeList: _vm.rankingViewModeList,
            },
            on: { rankingViewModeChange: _vm.changeRankingViewMode },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }