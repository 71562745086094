














import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import HomePane from '@/components/HomePage/HomePane.vue'

/**
 * HOME ページ
 */
export default defineComponent({
  name: 'HomePage',
  components: {
    HeaderPane,
    GlobalNavigationPane,
    HomePane,
  },
})
