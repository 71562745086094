












import { defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'

/**
 * マイページ: 契約情報 今後適用されるプラン セクション
 */
export default defineComponent({
  name: 'MypageNextPlanSection',
  props: {
    nextPlanName: {
      type: String,
      default: '',
    },
    nextPlanStartDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const attention = I18n.t('MypagePage.MypageContract.detailsPage.nextPlanAttention', {
      date: props.nextPlanStartDate,
    }).toString()

    return {
      attention,
    }
  },
})
