var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.notification.link
    ? _c(
        "div",
        { staticClass: "base-notification-item-contents-wrapper-parts" },
        [_vm._t("default")],
        2
      )
    : _vm.isExternalLink(_vm.notification.link)
    ? _c(
        "a",
        {
          staticClass:
            "base-notification-item-contents-wrapper-parts base-notification-item-contents-wrapper-parts--link",
          attrs: {
            href: _vm.notification.link,
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "div",
        {
          staticClass:
            "base-notification-item-contents-wrapper-parts base-notification-item-contents-wrapper-parts--link base-notification-item-contents-wrapper-parts--router-link",
          on: {
            click: function ($event) {
              return _vm.clickRouterLink(_vm.notification)
            },
          },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }