var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "home-reward-icon",
      attrs: {
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M5.33328 19.9999H14.6666V29.6266H6.66661C5.93328 29.6266 5.33328 29.0266 5.33328 28.2933V19.9999ZM27.9733 12.5733V17.3333H17.3333V11.9999H14.6666V17.3333H4.02661V12.5733C4.02661 11.8399 4.62661 11.2399 5.35994 11.2399H9.23994C7.30661 10.5733 6.26661 9.58662 5.71994 8.70662C4.95994 7.47995 4.86661 5.95995 5.47994 4.73328C6.29328 3.09328 8.31994 2.34662 10.5199 2.86662C13.0533 3.46662 15.9999 5.86662 15.9999 10.6533C15.9999 5.85328 18.9466 3.45328 21.4799 2.86662C23.6799 2.34662 25.7066 3.09328 26.5199 4.73328C27.1333 5.94661 27.0399 7.46662 26.2799 8.70662C25.7333 9.59995 24.6933 10.5866 22.7599 11.2399H26.6399C27.3733 11.2399 27.9733 11.8399 27.9733 12.5733ZM13.2133 9.29328C12.7733 6.97328 11.2399 5.78662 9.90661 5.47995C9.63994 5.41328 9.38661 5.38661 9.14661 5.38661C8.53328 5.38661 8.03994 5.58661 7.85328 5.93328C7.69328 6.23995 7.65328 6.77328 7.98661 7.30661C8.31994 7.85328 9.47994 9.03995 13.2133 9.29328ZM18.7866 9.29328C22.5199 9.03995 23.6666 7.85328 24.0133 7.30661C24.3466 6.77328 24.2933 6.23995 24.1466 5.93328C23.9733 5.58661 23.4799 5.38661 22.8533 5.38661C22.6133 5.38661 22.3599 5.41328 22.0933 5.47995C20.7599 5.79995 19.2266 6.97328 18.7866 9.29328ZM17.3333 29.6266H25.3333C26.0666 29.6266 26.6666 29.0266 26.6666 28.2933V19.9999H17.3333V29.6266Z",
            fill: "black",
            "fill-opacity": "0.7",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }