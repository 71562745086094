




























import { defineComponent, PropType } from '@vue/composition-api'
import NewHighlightsInputSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/NewHighlightsInputSection.vue'
import { EditHighlightData } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import DeviceInfo from '@/util/DeviceInfo'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import { HighlightOperationType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane.vue'

export default defineComponent({
  name: 'EditHighlightSection',
  components: {
    NewHighlightsInputSection,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    editType: {
      type: String as PropType<HighlightOperationType>,
      default: 'create',
    },
    /**
     * ハイライトの作成・編集で使用するデータ
     */
    highlightData: {
      type: Object as PropType<EditHighlightData>,
    },
    /**
     * ハイライト作成・編集モーダルの表示/非表示
     */
    createHighlight: {
      type: Boolean,
      default: false,
    },
    /**
     * プレビュー動画プレイヤー再描画用のキー
     */
    videoPlayerKey: {
      type: Number,
      required: true,
    },
  },
  setup(_, { emit }) {
    const isPc = DeviceInfo.isForPC()
    const handleCloseClicked = () => {
      emit('onClickClose')
    }

    const handleCommentCancelClicked = () => {
      emit('onClickCommentCancel')
    }

    return {
      isPc,
      handleCloseClicked,
      handleCommentCancelClicked,
    }
  },
  methods: {
    /**
     * 動画プレイヤーを表示する
     */
    showHighlightPreviewVideoPlayer() {
      this.$emit('showHighlightPreviewVideoPlayer')
    },
    /**
     * 動画プレイヤーを非表示にする
     */
    hideHighlightPreviewVideoPlayer() {
      this.$emit('hideHighlightPreviewVideoPlayer')
    },
    /**
     * ハイライトを保存する
     */
    saveHighlight(
      comment: string,
      highlightData: HighlightDocument,
      postX: boolean,
      postInstagram: boolean,
    ) {
      this.$emit('onSaveHighlight', comment, highlightData, postX, postInstagram)
    },
  },
})
