var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-slide-parts" },
    [
      _vm.linkData.linkType === "router"
        ? _c(
            "router-link",
            {
              staticClass: "img-slide-parts__slide img-slide-parts__router",
              attrs: { to: _vm.linkData.link },
            },
            [
              _c("img", {
                staticClass: "img-slide-parts__img",
                attrs: { src: _vm.thumbnailPath, alt: "" },
              }),
              _c("div", { staticClass: "img-slide-parts__content" }, [
                _c("p", { staticClass: "img-slide-parts__text" }, [
                  _vm._v(" " + _vm._s(_vm.linkData.text) + " "),
                ]),
                _c("p", { staticClass: "img-slide-parts__button-wrapper" }, [
                  _c(
                    "span",
                    {
                      staticClass: "img-slide-parts__button",
                      class: {
                        "img-slide-parts__button--circuit-mode":
                          _vm.isCircuitMode,
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "18",
                            height: "18",
                            viewBox: "0 0 18 18",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M5 3L14.2854 8.57125C14.6091 8.76546 14.6091 9.23454 14.2854 9.42875L5 15V3Z",
                              fill: "#00ffba",
                            },
                          }),
                        ]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$tc("HomePage.playButton"))),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.linkData.linkType === "link"
        ? _c(
            "a",
            {
              staticClass: "img-slide-parts__slide img-slide-parts__link",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.onClickAnchorLink(_vm.linkData)
                },
              },
            },
            [
              _c("img", {
                staticClass: "img-slide-parts__img",
                attrs: { src: _vm.thumbnailPath, alt: "" },
              }),
              _c("div", { staticClass: "img-slide-parts__content" }, [
                _c("div", { staticClass: "img-slide-parts__text-wrapper" }, [
                  _c("p", { staticClass: "img-slide-parts__text" }, [
                    _vm._v(" " + _vm._s(_vm.linkData.text) + " "),
                    _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/icon/icon_external_link_gray.svg"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }