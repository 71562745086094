var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "highlight-check-icon",
      attrs: {
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.0838 5.34386L7.0425 12.0211C6.94325 12.1308 6.77098 12.1308 6.67173 12.0211L4.24939 9.34376H2.22656L5.55943 13.0275C6.25417 13.7953 7.46007 13.7953 8.1548 13.0275L15.1066 5.34386H13.0838Z",
          fill: "#00DBA6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }