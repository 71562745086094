var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sns-official-section" },
    [
      false
        ? _c("highlights-comment-trigger-parts", {
            attrs: { highlightTrigger: _vm.highlightTrigger },
            on: { click: _vm.changeHighlightsTrigger },
          })
        : _vm._e(),
      !_vm.isUseDynamicScroller
        ? [
            _c(
              "div",
              { staticClass: "sns-official-section__posts" },
              _vm._l(_vm.snsContentsData, function (post) {
                return _c("sns-post-item-parts", {
                  key: "" + post.link,
                  staticClass: "sns-official-section__posts__item",
                  attrs: { snsPost: post },
                })
              }),
              1
            ),
          ]
        : [
            _c("dynamic-scroller", {
              staticClass: "sns-official-section__posts",
              class: {
                "sns-official-section__posts__isApp": _vm.isUseDynamicScroller,
              },
              attrs: {
                items: _vm.snsContentsData,
                "min-item-size": 68,
                "key-field": "link",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var item = ref.item
                    var index = ref.index
                    var active = ref.active
                    return [
                      _c(
                        "dynamic-scroller-item",
                        {
                          attrs: {
                            item: item,
                            active: active,
                            "data-index": index,
                            sizeDependencies: [
                              item.post,
                              item.images,
                              item.highlightComment,
                            ],
                            watchData: true,
                          },
                        },
                        [
                          _c("sns-post-item-parts", {
                            staticClass:
                              "sns-official-section__posts__isApp__item",
                            class: {
                              "sns-official-section__posts__isApp__item--first":
                                index === 0,
                            },
                            attrs: { snsPost: item },
                            on: {
                              updateSnsContentsData: _vm.updateSnsContentsData,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
      _vm.snsLoading ? _c("sns-post-loader-parts") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }