var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-complete-page" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("PaymentPage.EditPage.pageTitle") },
      }),
      _c("complete-pane", {
        attrs: {
          pageTitle: _vm.$tc("PaymentPage.CompletePage.pageTitle"),
          isReadOnlyPlan: _vm.isReadOnlyPlan,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }