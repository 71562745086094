import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { PermissionByContractType } from '@/store/stores/pageStore/common/PermissionType'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { OpeningDatesType } from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'

export type ContractType = {
  id: string | null
  contractPlanId: string | null
  startDate: number | null
  endDate: number | null
  masterContractPlan: ContractPlanDocument
  planType: string | null
}

export type PointRankType = {
  point: number
  rank: number
}

/**
 * ポイント獲得ランキング情報の型
 */
type PointRankingType = {
  totalPointRanking: PointRankType
  seasonPointRanking: Record<string, PointRankType>
  monthlyPointRanking: Record<string, PointRankType>
}

export type PointRankingKeyType = 'totalPointRanking' | 'seasonPointRanking' | 'monthlyPointRanking'

/**
 * ECサイト連携エラー情報の型
 */
type LinkEcErrorType = {
  /**
   * SFgo ECサイト連携バッチが返すエラーコード
   */
  code: string | null
  /**
   * ECサイト側のAPIが返すエラーコード
   */
  ecErrorCodeList?: Array<string> | null
}

/**
 * ECサイト 商品購入履歴の型
 */
type PurchasedProductType = {
  /**
   * 受注商品ID
   */
  orderItemId: string | null
  /**
   * 商品コード
   */
  productNumber: string | null
  /**
   * 上記商品コードの商品購入数
   */
  quantity: number | null
  /**
   * 上記商品コードの商品の税込単価
   */
  unitPrice: number | null
}

/**
 * ECサイト チケット使用履歴の型
 */
type TicketUseHistoryType = {
  /**
   * 使用履歴
   * 使用した場合に使用したイベントの日目と使用ボタンを押した日時が入る
   */
  enteredDates: Record<OpeningDatesType, number> | null
}

/**
 * ecforce連携情報の型
 */
type LinkEcType = {
  /**
   * 顧客ID
   */
  customerId?: string | null
  /**
   * ecforce連携設定をしているかどうか
   * 連携設定中の場合もtrueになる
   */
  linkEnabled: boolean
  /**
   * ecforce側で持っているランクID
   */
  customerRankId?: string | null
  /**
   * エラー
   */
  error?: LinkEcErrorType
  /**
   * ecforce 商品購入履歴
   */
  purchasedProducts?: Array<PurchasedProductType>
  /**
   * ecforce 商品購入履歴更新日時
   */
  purchasedProductsLastLinkedDate?: number | null
  /**
   * ecforce チケット使用履歴
   * 商品コードごとに使用履歴データを保持する
   */
  ticketUseHistoriesByProductNumber?: Record<string, TicketUseHistoryType>
}

/**
 * ECサイト連携情報の型
 */
export type XAuthorizationType = {
  /**
   * アクセストークン
   */
  oauthToken: string
  /**
   * アクセストークンシークレット
   */
  oauthTokenSecret: string
  /**
   * トークン取得日
   */
  acquisitionDate: number
}

/**
 * 組織情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/314900944/02+API
 */
export default class OrganizationDocument extends DocumentWrapper {
  constructor(initProps?: Partial<OrganizationDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'organization'

  _removeOrgPath = true

  idAttr = '_organization'

  /**
   * 組織名
   */
  organizationName = ''

  /**
   * 契約情報ID
   */
  contractInfoId?: string | null = null

  /**
   * プラン情報
   */
  contract?: Array<ContractType> | null = null

  /**
   * 権限情報
   */
  permissions?: Array<PermissionByContractType> | null = null

  /**
   * リードオンリー状態
   */
  isReadOnly: boolean | null = false

  /**
   * 追加項目
   */
  additionalData?: {
    /**
     * ecforce連携情報
     */
    ec?: LinkEcType

    /**
     * ポイントランキング情報
     */
    pointRanking?: PointRankingType

    /**
     * SNSに投稿する際に必要になる認証情報
     */
    postSns?: {
      x?: XAuthorizationType
    }

    /**
     * コミュニケーション機能に関する情報
     */
    communication?: {
      /**
       * ブロックしたユーザーのユーザーIDリスト
       */
      blockedUserIds?: Array<string> | null
    }
  }

  /**
   * ecforce連携情報
   */
  get ec() {
    return this.additionalData?.ec || null
  }

  /**
   * ecforce連携 バッチが返すエラーコード
   */
  get ecConnectBatchError() {
    return this.additionalData?.ec?.error?.code || null
  }

  /**
   * ecforce連携 ecforce APIが返すエラーコード
   */
  get ecConnectEcApiErrors() {
    return this.additionalData?.ec?.error?.ecErrorCodeList || null
  }

  /**
   * SNSに投稿する際に必要になる認証情報
   */
  get xAuthentication() {
    return this.additionalData?.postSns?.x || null
  }

  /**
   * コミュニケーション機能に関する情報
   */
  get communication() {
    return this.additionalData?.communication || null
  }
}
