var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sns-driver-section" },
    [
      _c(
        "sns-favorite-section",
        {
          staticClass: "sns-driver-section__selected",
          attrs: {
            viewType: "driver",
            headingLabel: _vm.$tc("HomePage.driversToDisplay"),
          },
          on: { click: _vm.selectSnsPostDisplayPlayer },
        },
        [
          _c("player-card-parts", {
            attrs: {
              name: _vm.snsPostDisplayPlayer
                ? _vm.snsPostDisplayPlayer.getPlayerName()[_vm.$i18n.locale]
                : "",
              img: _vm.playerPictureUrl(_vm.snsPostDisplayPlayer),
            },
          }),
        ],
        1
      ),
      false
        ? _c("highlights-comment-trigger-parts", {
            attrs: { highlightTrigger: _vm.highlightTrigger },
            on: { click: _vm.changeHighlightsTrigger },
          })
        : _vm._e(),
      _vm.snsContentsData.length
        ? _c(
            "div",
            {
              staticClass: "sns-driver-section__posts",
              class: {
                "sns-driver-section__posts__isApp": _vm.isUseDynamicScroller,
              },
            },
            [
              false
                ? _vm._l(_vm.highlightDummyData, function (post) {
                    return _c("sns-post-item-parts", {
                      key: "" + post.link,
                      staticClass: "sns-driver-section__posts__item",
                      attrs: { snsPost: post },
                    })
                  })
                : _vm._e(),
              !_vm.isUseDynamicScroller
                ? _vm._l(_vm.snsContentsData, function (post) {
                    return _c("sns-post-item-parts", {
                      key: "" + post.link,
                      staticClass: "sns-driver-section__posts__item",
                      attrs: { snsPost: post },
                    })
                  })
                : [
                    _vm.snsPostDisplayPlayer
                      ? _c("dynamic-scroller", {
                          attrs: {
                            items: _vm.snsContentsData,
                            "min-item-size": 68,
                            "key-field": "link",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  var active = ref.active
                                  return [
                                    _c(
                                      "dynamic-scroller-item",
                                      {
                                        attrs: {
                                          item: item,
                                          active: active,
                                          "data-index": index,
                                          sizeDependencies: [
                                            item.post,
                                            item.images,
                                            item.highlightComment,
                                          ],
                                          watchData: true,
                                        },
                                      },
                                      [
                                        _c("sns-post-item-parts", {
                                          staticClass:
                                            "sns-driver-section__posts__isApp__item",
                                          class: {
                                            "sns-driver-section__posts__isApp__item--first":
                                              index === 0,
                                          },
                                          attrs: { snsPost: item },
                                          on: {
                                            updateSnsContentsData:
                                              _vm.updateSnsContentsData,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            942308317
                          ),
                        })
                      : _vm._e(),
                  ],
            ],
            2
          )
        : _c("p", {
            staticClass: "sns-driver-section__unselected-text",
            domProps: {
              innerHTML: _vm._s(_vm.$tc("HomePage.favoritePlayerUnselected")),
            },
          }),
      _vm.snsLoading ? _c("sns-post-loader-parts") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }