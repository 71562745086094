

















import { computed, defineComponent, inject, PropType } from '@vue/composition-api'
import VueRouter from 'vue-router'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * レース動画再生画面 動画プレーヤー オーバーレイヘッダーコンポーネント
 */
export default defineComponent({
  name: 'OverlayHeaderParts',
  inject: ['router'],
  props: {
    /**
     * 動画タイトル
     */
    videoTitle: {
      type: String,
      required: true,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
  },
  setup(props) {
    const router = inject('router') as VueRouter

    const screenLandScape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)
    const queryKeys = Object.keys(router.currentRoute.query)

    // レース一覧画面に戻すべきかどうか
    const backToTop = router.currentRoute.query.backToTop === 'true'

    return {
      screenLandScape,
      queryKeys,
      backToTop,
    }
  },
  methods: {
    historyBack() {
      if (this.backToTop) {
        // レース一覧画面に戻す
        this.$router.replace({ name: 'TopPage' })
        return
      }
      if (this.queryKeys.length > 0) {
        /**
         * NOTE: クエリパラメータが付いている場合、this.$router.go(-1)とすると1つ前の画面に戻れなかった
         * this.$router.go(-2)とすると1つ前の画面に戻れたので、以下のように記載している
         */
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
  },
})
