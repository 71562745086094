import DeviceInfo from '@/util/DeviceInfo'
import Logger from '@/util/logger/Logger'
import { initLocalCache, preLoadStartWithPlayListInfoArray } from '@/util/videoplayer'
import SignedCookie, { SignedCookieNames } from '@/@types/SignedCookie'
import LocalCachePreLoadItemType from '@/util/localcache/LocalCachePreLoadItemType'
import LocalCacheSignatureType from '@/util/localcache/LocalCacheSignatureType'
import LocalCacheResult from '@/util/localcache/LocalCacheResult'
import LocalCacheResponseType from '@/util/localcache/LocalCacheResponseType'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * ローカルキャッシュ設定
 */
const LOCAL_CACHE = {
  /** 先読み件数の上限 */
  PRE_LOAD_MAX: 20,
}

/**
 * 以下のプラグインを利用して、動画データの先読み、キャッシュを行うための機能を提供する。
 * https://bitbucket.org/pitchbase/runedge-cordova-plugin-videoplayer
 *
 * このプラグインは、HLS動画の指定された再生位置の動画ファイルをあらかじめダウンロードしたり（これを先読みと呼ぶ）、
 * 取得した動画ファイル(tsファイル)をデバイスのストレージにキャッシュする。
 * 以下のAPIを利用して実現する。
 * - initLocalCache
 * - preLoadStartWithPlayListInfoArray
 * このAPIは、iOS/Androidデバイス上で動作している場合のみ利用可能となる。
 *
 * プラグインでは、この動画データの先読み、キャッシュ機能を提供するモジュールをローカルキャッシュ(LocalCache)と呼称するため、
 * このコード中でもその呼称で記載する。
 */
export default class LocalCache {
  /**
   * ローカルキャッシュを初期化し、動画データの先読み、キャッシュ機能を利用可能にする。
   * @return 初期化処理を待機するためのPromise
   */
  static init(): Promise<LocalCacheResult> {
    return new Promise((resolve, reject) => {
      if (!DeviceInfo.isNativeVideoPlayerAvailable()) {
        Logger.debug(
          `LocalCache#init: Skip initialize localCache. isNativeVideoPlayerAvailable: ${DeviceInfo.isNativeVideoPlayerAvailable()}`,
        )
        resolve(new LocalCacheResult('not initialized'))
        return
      }

      Logger.debug('LocalCache#init: Start to initialize LocalCache.')
      initLocalCache(
        LoginStore.value.authApiEndpoint,
        (response: LocalCacheResponseType) => {
          const result = new LocalCacheResult('success', undefined, response)
          Logger.debug(`LocalCache#init: Success initialize LocalCache. event: ${result}`)
          resolve(result)
        },
        (response: LocalCacheResponseType) => {
          const event = new LocalCacheResult('error', undefined, response)
          Logger.info(`LocalCache#init: Failed initialize LocalCache. event: ${event}`)
          reject(event)
        },
      )
    })
  }

  /**
   * 動画ファイルの先読みを行う
   * @param preLoadItems 先読み対象のリスト
   */
  static preLoad(preLoadItems: Array<LocalCachePreLoadItemType>): Promise<LocalCacheResult> {
    return new Promise((resolve, reject) => {
      if (!DeviceInfo.isNativeVideoPlayerAvailable()) {
        Logger.debug(
          `LocalCache#init: Skip preLoad. isNativeVideoPlayerAvailable: ${DeviceInfo.isNativeVideoPlayerAvailable()}`,
        )
        resolve(new LocalCacheResult('not initialized'))
        return
      }

      if (preLoadItems.length === 0) {
        resolve(new LocalCacheResult('success'))
        return
      }

      // 指定された先読み対象の数が閾値を肥えている場合、最大件数までに絞る
      const preLoadPlayList =
        preLoadItems.length > LOCAL_CACHE.PRE_LOAD_MAX
          ? preLoadItems.splice(0, LOCAL_CACHE.PRE_LOAD_MAX)
          : preLoadItems

      // 先読み処理を呼び出す
      preLoadStartWithPlayListInfoArray(
        preLoadPlayList,
        null,
        LocalCache.makeSignatures(LoginStore.value.movieCookies || []),
        LoginStore.value.refreshToken,
        (response: LocalCacheResponseType) => {
          const result = new LocalCacheResult('success', undefined, response)
          Logger.debug(`LocalCache#preLoad: Success preLoad highlight movie data. event: ${result}`)
          resolve(result)
        },
        (response: LocalCacheResponseType) => {
          const result = new LocalCacheResult('error', undefined, response)
          Logger.info(
            `LocalCache#preLoad: Failed to preLoad highlight movie data. event: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 署名Cookieのデータをローカルキャッシュに指定できる署名情報に変換する。
   * @param movieCookies 動画ファイルアクセス用の署名Cookie情報
   */
  static makeSignatures(movieCookies: Array<SignedCookie>): Array<LocalCacheSignatureType> {
    const signatures: Array<LocalCacheSignatureType> = []
    movieCookies.forEach((cookie) => {
      let name
      SignedCookieNames.some((cookieName) => {
        if (cookieName in cookie) {
          name = cookieName
          return true
        }
        return false
      })
      if (name) {
        const signature = {
          name,
          value: cookie[name],
          path: cookie.Path,
          domain: cookie.Domain,
        }
        Logger.debug(
          `LocalCache#makeSignatures: Signature has been make. ${JSON.stringify(signature)}`,
        )
        signatures.push(signature)
      }
    })
    return signatures
  }
}
