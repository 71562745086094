






import { defineComponent } from '@vue/composition-api'

/**
 * マイページ: 会員情報変更完了 コンテンツセクション
 */
export default defineComponent({
  name: 'MypageUserCompleteSection',
  methods: {},
})
