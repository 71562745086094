






























































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import Const from '@/util/Const'
import { GpsViewModeType } from '@/components/RaceVideoPage/RaceVideoGpsPane.vue'
import { CircuitGpsPosition } from '@/components/RaceVideoPage/RaceVideoPane/CircuitMapSection.vue'
import MapCarParts from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/MapCarParts.vue'
import CircuitDetailMapImage from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/CircuitDetailMapImage.vue'
import CircuitMapCarParts from '@/components/RaceVideoPage/RaceVideoPane/parts/CircuitMapCarParts.vue'
import useGps, {
  CarGpsPositionDataMap,
  CarGpsPositionDataType,
  emptyMapPositionData,
} from '@/components/RaceVideoPage/hook/useGps'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * コースマップのサイズ
 */
export type MapSize = {
  width: number
  height: number
}

/**
 * レース動画再生画面: GPS詳細 マップセクション
 */
export default defineComponent({
  name: 'GpsDetailsMapSection',
  components: {
    CircuitDetailMapImage,
    MapCarParts,
    CircuitMapCarParts,
  },
  props: {
    /**
     * GPSマップ表示倍率
     */
    mapMagnification: {
      type: Number,
      required: true,
    },
    /**
     * サーキット名称
     */
    circuitName: {
      type: String,
      required: true,
    },
    /**
     * サーキットMAP GPS 座標
     */
    circuitGpsPosition: {
      type: Object as PropType<CircuitGpsPosition>,
      required: true,
    },
    /**
     * GPS表示モード
     */
    gpsViewMode: {
      type: String as PropType<GpsViewModeType>,
      default: 'battle',
    },
    /**
     * 車両データアニメーションフラグ
     */
    animateGpsMapCar: {
      type: Boolean,
      default: true,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
  },
  setup(props) {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { selectedPlayer, selectedPlayerId } = raceVideoPageStore

    const { getCurrentPlayerGps } = useGps(false)

    /**
     * 全車両のGPS位置情報を取得する。
     * 車両番号毎にGPS位置情報を取得し、車両番号をキーにして、GPS位置情報を値にしたMapを返す。
     */
    const carPositionData = computed((): CarGpsPositionDataMap => getCurrentPlayerGps.value)

    /**
     * マップの中心に表示する車両のGPS位置情報を取得する。
     */
    const gpsMapPositionData = computed((): CarGpsPositionDataType => {
      if (!carPositionData.value) {
        return emptyMapPositionData
      }
      const targetCarNo = Object.keys(carPositionData.value).find(
        (carNo) => carNo === selectedPlayer?.value?.getDisplayCarNo(),
      )
      return targetCarNo ? carPositionData.value[targetCarNo] : emptyMapPositionData
    })

    // サーキットサイズ取得
    const mapWidthValue = props.circuitGpsPosition.end.lng - props.circuitGpsPosition.root.lng
    const mapHeightValue = props.circuitGpsPosition.end.lat - props.circuitGpsPosition.root.lat

    const tempPosition = ref({
      lat: props.circuitGpsPosition.root.lng,
      lng: props.circuitGpsPosition.root.lat,
      transform: '',
      createdDate: 0,
    })
    const tempThrottlingPosition = ref({
      lat: props.circuitGpsPosition.root.lng,
      lng: props.circuitGpsPosition.root.lat,
      createdDate: 0,
    })

    // スロットリング設定
    const THROTTLING_THRESHOLD = Const.GPS_THROTTLING.THRESHOLD
    const NO_ANIMATION_GAP_TIME = Const.GPS_THROTTLING.NO_ANIMATION_GAP_TIME_MS // データ反映時間の差が XX ms 以上だった場合に移動アニメーションを適用しない
    const DISABLED_THRESHOLD = Const.GPS_THROTTLING.CAR_DISABLED_THRESHOLD // データ反映時 データがX度連続して空だったら disabled表示 にするか (50ms * 8 * 5 = 2秒断続)
    let throttlingCount = THROTTLING_THRESHOLD

    const blankCount = ref(0)
    const enableAnimation = ref(true)
    const mapMounted = ref({
      mount: false,
      count: 0,
      moveCount: 0,
    })

    /**
     * マップサイズ取得
     */
    const LANDSCAPE_WIDTH = 230
    const BROWSER_WIDTH = 375

    const mapSize = ref({ width: 0, height: 0 })
    const getMapSize = () => {
      let contentWidth = 0
      if (props.lockOrientation !== undefined) {
        contentWidth = BROWSER_WIDTH
      } else {
        contentWidth =
          props.screenOrientationType.indexOf('landscape') !== -1
            ? LANDSCAPE_WIDTH
            : window.innerWidth
      }
      mapSize.value.width = contentWidth
      mapSize.value.height = (contentWidth / 100) * 56.266
    }

    onMounted(() => {
      getMapSize()
      window.addEventListener('resize', getMapSize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', getMapSize)
    })

    /**
     * GPS詳細 拡大サイズ
     */
    const expansionMapSize = computed(() => ({
      width: mapSize.value.width * props.mapMagnification,
      height: mapSize.value.height * props.mapMagnification,
    }))

    /**
     * 経度緯度位置指定 ポジション変換
     * @param gpsLat: 緯度
     * @param gpsLng: 経度
     * @param createdDate: GPS取得時間
     * @param animate: アニメーション有効化フラグ
     */
    const convertGpsMapPosition = (
      gpsLat: number,
      gpsLng: number,
      createdDate: number,
      animate: boolean,
    ) => {
      const gpsBlank = gpsLat === 0 || gpsLng === 0
      const gpsMoved =
        tempPosition.value.lat !== (gpsLat || tempThrottlingPosition.value.lat) ||
        tempPosition.value.lng !== (gpsLng || tempThrottlingPosition.value.lng)
      const createDateDiff =
        createdDate > tempThrottlingPosition.value.createdDate
          ? createdDate - tempThrottlingPosition.value.createdDate
          : tempThrottlingPosition.value.createdDate - createdDate

      if (!animate || createDateDiff >= NO_ANIMATION_GAP_TIME) {
        // アニメーションOFF & 初期化
        enableAnimation.value = false
        mapMounted.value.moveCount = 0
        throttlingCount = 0
      }

      if (throttlingCount % THROTTLING_THRESHOLD !== 0) {
        // 断続的にデータが得られなかった際、スロットリング中にデータが1回でも取得した場合に反映できるように保持
        tempThrottlingPosition.value.lat = !gpsBlank ? gpsLat : tempThrottlingPosition.value.lat
        tempThrottlingPosition.value.lng = !gpsBlank ? gpsLng : tempThrottlingPosition.value.lng
        tempThrottlingPosition.value.createdDate = gpsMoved
          ? createdDate
          : tempThrottlingPosition.value.createdDate

        // 空カウント等
        blankCount.value = !gpsBlank ? 0 : blankCount.value

        throttlingCount += 1
        return {
          width: `${expansionMapSize.value.width}px`,
          height: `${expansionMapSize.value.height}px`,
          transform: mapMounted.value.mount ? tempPosition.value.transform : null,
        }
      }

      // 移動判定: アニメーション有効化に使用
      mapMounted.value.moveCount += gpsMoved ? 1 : 0

      const transformValue = `translate(${
        -expansionMapSize.value.width *
        (((!gpsBlank ? gpsLng : tempThrottlingPosition.value.lng) -
          props.circuitGpsPosition.root.lng) /
          mapWidthValue)
      }px, ${
        -expansionMapSize.value.height *
        (((!gpsBlank ? gpsLat : tempThrottlingPosition.value.lat) -
          props.circuitGpsPosition.root.lat) /
          mapHeightValue)
      }px)`

      // 位置情報を計算
      tempPosition.value = {
        createdDate: gpsMoved ? createdDate : tempThrottlingPosition.value.createdDate,
        lat: gpsLat || tempThrottlingPosition.value.lat,
        lng: gpsLng || tempThrottlingPosition.value.lng,
        transform: transformValue,
      }

      // 初期にデータが空の場合は表示しないように判定
      mapMounted.value.count += (gpsLat || tempPosition.value.lat) > 0 ? 1 : 0
      mapMounted.value.mount = mapMounted.value.count > 1

      // データ空チェック
      blankCount.value = gpsBlank ? Number(blankCount.value) + 1 : 0

      // アニメーション有効化判定 リセット処理
      mapMounted.value.moveCount =
        blankCount.value > DISABLED_THRESHOLD ? 0 : mapMounted.value.moveCount

      // アニメーション有効化判定
      enableAnimation.value =
        mapMounted.value.moveCount > 1 && createDateDiff < NO_ANIMATION_GAP_TIME

      // 一時保持用に格納
      tempThrottlingPosition.value = {
        lat: gpsLat || tempPosition.value.lat,
        lng: gpsLng || tempPosition.value.lng,
        createdDate: gpsMoved ? createdDate : tempPosition.value.createdDate,
      }

      throttlingCount += 1
      return {
        width: `${expansionMapSize.value.width}px`,
        height: `${expansionMapSize.value.height}px`,
        transform: mapMounted.value.mount ? tempPosition.value.transform : null,
      }
    }

    const gpsMapPosition = computed(() => {
      if (props.mapMagnification !== Const.GPS_MAP_MAGNIFICATION.BIRDSEYE) {
        return convertGpsMapPosition(
          gpsMapPositionData.value.lat,
          gpsMapPositionData.value.lng,
          gpsMapPositionData.value.createdDate,
          props.animateGpsMapCar,
        )
      }
      return ''
    })

    return {
      selectedPlayerId,
      carPositionData,
      mapSize,
      gpsMapPosition,
      enableAnimation,
    }
  },
  methods: {},
})
