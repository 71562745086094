import { onMounted, onUnmounted } from '@vue/composition-api'

export default function useDialog() {
  /**
   * プラン選択画面を表示時、離脱警告ダイアログを表示
   */
  const showCloseAppCationDialog = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = ''
  }

  onMounted(() => {
    // マウント時にリスナーを登録
    window.addEventListener('beforeunload', showCloseAppCationDialog)
  })

  onUnmounted(() => {
    // アンマウント時にリスナーを解除
    window.removeEventListener('beforeunload', showCloseAppCationDialog)
  })
}
