




































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import CommentPostDateParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentPostDateParts.vue'
import LikeButtonParts, {
  ClickedLikeDataType,
} from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/LikeButtonParts.vue'
import ConfirmModalSection from '@/components/common/modal/ConfirmModalSection.vue'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import useCommunication, { CommentDataType } from '@/components/RaceVideoPage/hook/useCommunication'
import CommentEditorParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentEditorParts.vue'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultIcon = require('@/assets/img/icon/icon_user_default.png')

/**
 * レース動画再生画面 ハイライト 投稿コメント セクションコンポーネント
 */
export default defineComponent({
  name: 'HighlightCommentSection',
  components: {
    CommentEditorParts,
    ConfirmModalSection,
    CommentPostDateParts,
    LikeButtonParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 投稿コメントデータ
     */
    commentData: {
      type: Object as PropType<CommentDataType>,
      required: true,
    },
    showCommentInput: {
      type: Boolean,
      required: false,
    },
    loginUserId: {
      type: String,
      required: true,
    },
    /**
     * 対象ハイライトの編集状態
     */
    targetIsEditMode: {
      type: Boolean,
      default: false,
    },
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
  },
  setup(props, { emit }) {
    /** シェア、編集、削除ボタンを表示するかどうか */
    const controllerShown = ref<boolean>(false)
    /** ハイライトに対するコメント一覧 */
    const displayCommentData = computed((): CommentDataType => props.commentData)
    /** コメントシェア関数取得 */
    const { shareHighlightComment } = useCommunication()

    /**
     * enabledInput
     * @param {string} val ハイライトの入力状態
     */
    const isEditMode = computed(() => props.targetIsEditMode)

    // ログインユーザーコメント編集表示判定
    const isLoginUserComment = computed(
      () => props.loginUserId === displayCommentData.value.user.id && props.showCommentInput,
    )

    // コメントキャンセル確認用モーダル
    const enabledConfirmModal = ref<boolean>(false)

    /**
     * コメントしたユーザーの表示名を返す
     */
    const getCommentUserDisplayName = (userId: string) => {
      const targetUser = props.retrieveNameUsersByUserId?.[userId]
      return targetUser?.additionalData?.userDisplayName || '---'
    }
    /**
     * コメントしたユーザーのアイコン画像パスを返す
     */
    const getCommentUserIconPath = (userId: string) => {
      const targetUser = props.retrieveNameUsersByUserId?.[userId]
      return targetUser?.userPicture
        ? CloudFrontUtil.getSignedUrl(targetUser.userPicture)
        : defaultIcon
    }

    /**
     * コメントのメニューボタンが押下されたときの処理
     */
    const handleMenuClicked = () => {
      // 自分のコメントの場合は、編集、削除をできるようにする
      const slideMenu =
        LoginStore.value.loginId === displayCommentData.value.user.id
          ? 'highlightComment'
          : 'userBlock'

      emit('onClickMenu', displayCommentData.value, slideMenu)
    }

    return {
      controllerShown,
      displayCommentData,
      isLoginUserComment,
      isEditMode,
      enabledConfirmModal,
      getCommentUserDisplayName,
      getCommentUserIconPath,
      shareHighlightComment,
      handleMenuClicked,
    }
  },
  methods: {
    /**
     * コメント編集キャンセル
     */
    cancelInput() {
      this.enabledConfirmModal = false

      this.$emit('onToggleIsEditMode', false)
    },
    /**
     * コメント編集ボタンを押したことを親に伝える
     * @param comment string コメント
     */
    onSaveComment(comment: string) {
      // 入力状態でコメントがある場合
      this.$emit('handlerEdit', comment, this.displayCommentData.commentId)
    },
    /**
     * コメントLIKE処理
     * @param likeData 押下されたいいねの情報
     */
    onChangeLike(likeData: ClickedLikeDataType) {
      this.$emit('onChangeLike', likeData)
    },
  },
})
