var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ticket-today-table-parts" },
    _vm._l(_vm.ticketList, function (ticket, index) {
      return _c(
        "div",
        { key: index, staticClass: "mypage-ticket-today-table-parts__item" },
        [
          _c(
            "p",
            { staticClass: "mypage-ticket-today-table-parts__item-name" },
            [_vm._v(_vm._s(ticket.ticketName))]
          ),
          _c(
            "p",
            { staticClass: "mypage-ticket-today-table-parts__item-count" },
            [
              _vm._v(
                " ×" +
                  _vm._s(
                    _vm.getTicketTotalCountByTicket(ticket, _vm.ownOrganization)
                  ) +
                  " "
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }