import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * パスワードリセットトークン情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1567064246/URL+API
 */
export default class PasswordResetTokenDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PasswordResetTokenDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/password_reset_token'

  /**
   * reCAPTCHAのトークン
   */
  reCaptchaToken: string | null = null

  /**
   * メールアドレス
   */
  mailAddress: string | null = null
}
