import { render, staticRenderFns } from "./StandingsPlayerDetailsSection.vue?vue&type=template&id=79825887&scoped=true"
import script from "./StandingsPlayerDetailsSection.vue?vue&type=script&lang=ts"
export * from "./StandingsPlayerDetailsSection.vue?vue&type=script&lang=ts"
import style0 from "./StandingsPlayerDetailsSection.vue?vue&type=style&index=0&id=79825887&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79825887",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79825887')) {
      api.createRecord('79825887', component.options)
    } else {
      api.reload('79825887', component.options)
    }
    module.hot.accept("./StandingsPlayerDetailsSection.vue?vue&type=template&id=79825887&scoped=true", function () {
      api.rerender('79825887', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StandingsPage/StandingsPlayerDetailsPane/StandingsPlayerDetailsSection.vue"
export default component.exports