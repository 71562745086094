
































import { defineComponent } from '@vue/composition-api'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'

/**
 * お問い合わせページ ペイン
 */
export default defineComponent({
  name: 'MypageContactUsPane',
  components: {
    AtomLinkButton,
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { user } = mypagePageStore

    const userLang = user.value.lang || DeviceInfo.getLanguage()

    // SUPER FORMULA公式サイト ABOUT SFgoページへのリンク
    const sfAboutSFgoUrl =
      userLang === 'ja' ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN

    // お問い合わせフォームへのリンク
    const inquiryFormUrl = Const.EXTERNAL_LINKS.GOOGLE_INQUIRY_FORM

    return {
      sfAboutSFgoUrl,
      inquiryFormUrl,
    }
  },
})
