var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8 3H3.78078C3.32191 3 2.92193 3.3123 2.81063 3.75746L2 7H6.21922C6.67809 7 7.07807 6.6877 7.18937 6.24254L8 3Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18 3H13.7808C13.3219 3 12.9219 3.3123 12.8106 3.75746L12 7H16.2192C16.6781 7 17.0781 6.6877 17.1894 6.24254L18 3Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6 11H1.78078C1.32191 11 0.921926 11.3123 0.810634 11.7575L0 15H4.21922C4.67809 15 5.07807 14.6877 5.18937 14.2425L6 11Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 11H11.7808C11.3219 11 10.9219 11.3123 10.8106 11.7575L10 15H14.2192C14.6781 15 15.0781 14.6877 15.1894 14.2425L16 11Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 7H7.78078C7.32191 7 6.92193 7.3123 6.81063 7.75746L6 11H10.2192C10.6781 11 11.0781 10.6877 11.1894 10.2425L12 7Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }