/**
 * 動画プレーヤーの表示形式種別
 */
export const VideoPlayerViewClass = {
  Default: 'Default',
  HighlightPreview: 'HighlightPreview',
} as const

/**
 * 動画プレーヤーの表示形式種別の型
 */
export type VideoPlayerViewType = keyof typeof VideoPlayerViewClass
