





















import { defineComponent, Ref } from '@vue/composition-api'
import TeamSelectListSection from '@/components/MypageFavoriteTeamSelectPage/MypageFavoriteTeamSelectPane/TeamSelectListSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import StoreUtil from '@/store/StoreUtil'

export type DataType = {
  selectedID: string
}

/**
 * マイページ: お気に入りチーム変更 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageFavoriteTeamInitSelectPane',
  components: {
    TeamSelectListSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  data(): DataType {
    return {
      /**
       * セレクトID
       */
      selectedID: '',
    }
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      teams: mypagePageStore.addedSelectLaterAvailableTeams as Ref<TeamDocument[]>,
      savingFavoriteTeam: mypagePageStore.savingFavoriteTeam,
      setSavingFavoriteTeamId: mypagePageStore.setSavingFavoriteTeamId,
    }
  },
  mounted() {
    this.selectedID = this.savingFavoriteTeam ? this.savingFavoriteTeam.sid : ''
  },
  methods: {
    changeFavoriteTeam() {
      /**
       * 選択したお気に入りチームをストアに保存
       */
      this.setSavingFavoriteTeamId(this.selectedID)

      this.$emit('changed')
    },
    changeSelected(selectedItemID: string): void {
      /**
       * 選択変更
       * @param selectedItemID : string
       */
      this.selectedID = this.selectedID === selectedItemID ? '' : selectedItemID
    },
  },
})
