


















import { defineComponent, PropType } from '@vue/composition-api'
import MissionHexagonParts from '@/components/MissionPage/MissionListPane/parts/MissionHexagonParts.vue'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'

/**
 * ミッション画面 ミッション達成状況 獲得済みポイントパーツ
 */
export default defineComponent({
  name: 'MissionCheckListParts',
  components: { MissionHexagonParts },
  props: {
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
  },
})
