

















































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { BadgeData } from '@/components/MypageTopPage/MembershipCardPane/parts/MemberBadgeItemParts.vue'

/**
 * マイページ: 会員証 登録年数バッヂパーツ
 */
export default defineComponent({
  name: 'BadgeYearParts',
  props: {
    badge: {
      type: Object as PropType<BadgeData>,
      required: true,
    },
  },
  setup(props) {
    const badgeYear = computed(() => props.badge.years)

    return {
      badgeYear,
    }
  },
})
