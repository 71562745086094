






import { defineComponent } from '@vue/composition-api'
import OneTimePassInputSection from '@/components/MypageOneTimePassInputPage/OneTimePassInputPane/OneTimePassInputSection.vue'
import StoreUtil from '@/store/StoreUtil'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'

/**
 * マイページ: ワンタイムパス: 入力ペイン
 */
export default defineComponent({
  name: 'OneTimePassInputPane',
  components: {
    OneTimePassInputSection,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { oneTimePass, oneTimePassPlan, getCouponPlan } = oneTimePassPageStore
    /**
     * 現在利用している契約情報、契約情報契約プランを取得する
     */
    const getContractInfos = async () => {
      await Promise.all([
        // 現在利用している契約情報を取得
        oneTimePassPageStore.fetchContractInfo(),
        // 現地利用限定クーポンで使うエリア情報を取得
        oneTimePassPageStore.fetchAvailableAreas(),
      ])
      const { contractInfoId } = oneTimePassPageStore.ownContractInfo.value
      await oneTimePassPageStore.fetchContractInfoPlans(contractInfoId as string)
    }
    getContractInfos()

    return {
      oneTimePass,
      oneTimePassPlan,
      getCouponPlan,
    }
  },
  methods: {
    /**
     * ワンタイムパス登録ボタン押下時以下の処理を行う
     * 1. 取得したワンタイムパスのプラン情報をストアに保存
     * 2. 確認画面に遷移するための通知を親コンポーネントに伝える
     */
    async setOneTimePassPlanAndOnSubmit(oneTimePass: CouponDocument) {
      /** ワンタイムパスのプラン情報を取得 */
      const originalOneTimePassPlan = oneTimePass?.plans
        ? this.getCouponPlan(oneTimePass.plans)
        : null
      if (!originalOneTimePassPlan) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.getOneTimePassPlanError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.getOneTimePassPlanError.message',
          ),
        })
        // ワンタイムパスのプラン情報が取得できない場合、画面遷移しない
        return
      }

      // 取得したワンタイムパスをストアに格納
      this.oneTimePass = oneTimePass
      // 取得したワンタイムのプラン情報を元にワンタイム用のプラン情報を作成し、ストアに保存
      this.oneTimePassPlan = new ContractPlanDocument({
        ...originalOneTimePassPlan,
        couponData: {
          couponName: {
            ja: oneTimePass.couponName.ja,
            en: oneTimePass.couponName.en,
          },
          title: {
            ja: oneTimePass.additionalData?.title.ja,
            en: oneTimePass.additionalData?.title.en,
          },
          description: {
            ja: oneTimePass.description?.ja || '',
            en: oneTimePass.description?.en || '',
          },
          couponApplicationPeriodText: oneTimePass.couponApplicationPeriodText,
        },
      })

      /** 確認画面への遷移 */
      this.$emit('handlerSubmit')
    },
  },
})
