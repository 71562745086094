








import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypagePaymentEditPane from '@/components/MypagePaymentPage/MypagePaymentEditPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * アプリ版: クレジットカード情報変更ページ
 */
export default defineComponent({
  name: 'MypagePaymentEditPage',
  components: {
    GlobalNavigationPane,
    SubHeaderSection,
    MypagePaymentEditPane,
  },
})
