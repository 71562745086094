var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "comparison-plan-table-item-parts" }, [
    _c("th", { staticClass: "comparison-plan-table-item-parts__label" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "td",
      {
        staticClass:
          "comparison-plan-table-item-parts__data comparison-plan-table-item-parts__free",
      },
      [
        _vm.free
          ? _c(
              "div",
              {
                class: {
                  "comparison-plan-table-item-parts__footnote":
                    _vm.freeFootnote1 || _vm.freeFootnote2,
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/icon_check_18__gray50.svg"),
                    alt: "○",
                  },
                }),
                _vm.freeFootnote1
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "comparison-plan-table-item-parts__footnote-body",
                      },
                      [
                        _vm._v(" ※"),
                        _c(
                          "span",
                          {
                            staticClass:
                              "comparison-plan-table-item-parts__footnote-number",
                          },
                          [_vm._v("1")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.freeFootnote2
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "comparison-plan-table-item-parts__footnote-body",
                      },
                      [
                        _vm._v(" ※"),
                        _c(
                          "span",
                          {
                            staticClass:
                              "comparison-plan-table-item-parts__footnote-number",
                          },
                          [_vm._v("2")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _c("span", [_vm._v("-")]),
      ]
    ),
    _c(
      "td",
      {
        staticClass:
          "comparison-plan-table-item-parts__data comparison-plan-table-item-parts__paid",
      },
      [
        _vm.paid
          ? _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_check_18__green_white.svg"),
                alt: "○",
              },
            })
          : _c("span", [_vm._v("-")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }