import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 月契約の即時決済を実施する際に使用するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3845587031/API
 */
export default class ContractInfoBillingSettleMonthlyDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoBillingSettleMonthlyDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contract_info'

  idAttr = 'contractInfoId'

  /**
   * 契約情報ID
   */
  contractInfoId: string | null = null

  /**
   * 請求対象年月
   */
  billingTargetYearMonth: Array<string> | null = null
}
