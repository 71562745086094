var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-input-select-object" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        class: { "select--validation-error": _vm.error },
        attrs: { id: _vm.id },
        on: {
          enter: _vm.emitEnter,
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.inputValue = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.change,
          ],
        },
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v(_vm._s(_vm.placeholder)),
        ]),
        _vm._l(_vm.options, function (v) {
          return _c("option", { key: v.id, domProps: { value: v.id } }, [
            _vm._v(_vm._s(v.name[_vm.$i18n.locale])),
          ])
        }),
      ],
      2
    ),
    _c("span", { staticClass: "atom-input-select__value" }, [
      _vm._v(_vm._s(_vm.selectName[_vm.$i18n.locale] || _vm.placeholder)),
    ]),
    _c(
      "svg",
      {
        attrs: {
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("g", { attrs: { opacity: "0.5" } }, [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M1.93933 6L7.76256 11.8232C8.44597 12.5066 9.55401 12.5066 10.2374 11.8232L16.0607 6H13.9393L9.17677 10.7626C9.07914 10.8602 8.92085 10.8602 8.82321 10.7626L4.06065 6H1.93933Z",
              fill: "black",
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }