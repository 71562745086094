import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import CountdownEventDocument from '@/store/stores/collectionModule/documents/event/CountdownEventDocument'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * カウントダウンイベントを操作するための処理
 */
export default function useEvent() {
  // Collection modules
  const eventCollectionModule = CollectionModule.createStore(CountdownEventDocument)

  /**
   * 直近開催予定のイベント情報を1件取得
   */
  const fetchUpcomingEvent = () => {
    // 画面を開いた時点の日にちのタイムゾーン日本時間の0時以降のものを取得
    const startOfDate = dayjs(dayjs()).tz('Asia/Tokyo').startOf('day').valueOf()
    const filter = {
      startDate: { $gt: startOfDate },
    }
    return eventCollectionModule.fetch({
      query: {
        filter,
        sort: 'startDate',
        limit: 1,
      },
    })
  }

  /**
   * カウントダウンイベント情報
   */
  const events = computed(() => eventCollectionModule.data)

  return {
    fetchUpcomingEvent,
    events,
  }
}
