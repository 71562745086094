


























































import { defineComponent, PropType, computed } from '@vue/composition-api'
import NewHighlightsInputSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/NewHighlightsInputSection.vue'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'
import { EditHighlightData } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import DeviceInfo from '@/util/DeviceInfo'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import usePermission from '@/components/hook/usePermission'
import StoreUtil from '@/store/StoreUtil'

/**
 * レース動画再生画面 ハイライト新規作成 セクション
 */
export default defineComponent({
  name: 'NewHighlightsSection',
  components: {
    NewHighlightsInputSection,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
      default: 'landscape',
    },
    /**
     * ハイライトの作成・編集で使用するデータ
     */
    highlightData: {
      type: Object as PropType<EditHighlightData>,
    },
    /**
     * ハイライト作成・編集モーダルの表示/非表示
     */
    createHighlight: {
      type: Boolean,
      default: false,
    },
    /**
     * プレビュー動画プレイヤー再描画用のキー
     */
    videoPlayerKey: {
      type: Number,
      required: true,
    },
  },
  setup(_props, context) {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { isCreateHighlightAvailable } = raceVideoPageStore
    const { hasPermission } = usePermission()

    const disabledAddButton = computed(() => !isCreateHighlightAvailable.value)

    const isPc = DeviceInfo.isForPC()
    const showCreateHighlightButton = computed(() => hasPermission('createHighlight'))

    const enableHighlightCardCreate = () => {
      context.emit('openHighlightsModal')
    }

    const hideHighlightCardCreate = () => {
      context.emit('onClickConfirmCancel')
    }

    return {
      disabledAddButton,
      isPc,
      showCreateHighlightButton,
      enableHighlightCardCreate,
      hideHighlightCardCreate,
    }
  },
  methods: {
    /**
     * 動画プレイヤーを表示する
     */
    showHighlightPreviewVideoPlayer() {
      this.$emit('showHighlightPreviewVideoPlayer')
    },
    /**
     * 動画プレイヤーを非表示にする
     */
    hideHighlightPreviewVideoPlayer() {
      this.$emit('hideHighlightPreviewVideoPlayer')
    },
    saveHighlight(
      comment: string,
      highlightData: HighlightDocument,
      postX: boolean,
      postInstagram: boolean,
    ) {
      this.$emit('onSaveHighlight', comment, highlightData, postX, postInstagram)
    },
  },
})
