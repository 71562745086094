





















import { defineComponent, PropType } from '@vue/composition-api'
import MissionCheckIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCheckIcon.vue'
import MissionCheckPointMapIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCheckPointMapIcon.vue'
import { CheckPointType } from '@/components/MissionPage/hook/useMissionProgressData'

export default defineComponent({
  name: 'MissionCheckInPointSection',
  components: {
    MissionCheckPointMapIcon,
    MissionCheckIcon,
  },
  props: {
    /**
     * イベントチェックイン場所情報
     */
    checkPoint: {
      type: Object as PropType<CheckPointType>,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
