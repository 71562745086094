import { ref, Ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ContractInfoPlanDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanDocument'
import Logger from '@/util/logger/Logger'
import useMonthlyProRatedPrice from '@/components/pc/MyPage/CompletePane/hook/useMonthlyProRatedPrice'
import useContractInfoPlanPrice from '@/store/hook/useContractInfoPlanPrice'
import usePaymentPrice from '@/components/hook/usePaymentPrice'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * 月額プラン初回請求金額に関するHook
 * @return  price 請求金額
 * @return  settlementDate 決済日
 * @return  billingStartDate 請求対象利用期間開始日
 * @return  billingEndDate 請求対象利用期間終了日
 */
export default function useMonthlyPlanFirstProRatedBilling() {
  const { fetchMonthlyPlanPrice, contractInfoPlanPrice } = useContractInfoPlanPrice()
  const { calculatePaymentPriceString } = usePaymentPrice()
  const { getMonthlyPlanFirstProRatedPriceParam, getAgainMonthlyPlanFirstProRatedPriceParam } =
    useMonthlyProRatedPrice()

  /**
   * 次の月の月額プラン請求情報を返す。以下の場合に利用する。
   * -
   * - 月末に月額プランが切り替わり、プラン切り替わり月の請求が発生せず、初回請求日が1ヶ月遅れる場合
   */
  const settlementInfoOnNextMonth = (
    futureContactInfoPlan: ContractInfoPlanDocument | undefined,
  ) => {
    const startDate = dayjs(futureContactInfoPlan?.startDate).add(1, 'M').startOf('month').valueOf()
    /**
     * 初月の決済日
     * UTC基準で月初の0時を取得
     */
    const settlementDate = dayjs.tz(startDate).add(1, 'M').startOf('month').valueOf()
    return {
      // プラン料金APIで料金の取得が成功している場合に入る処理のため、firstBillingEndDateは存在している想定
      settlementDate,
      billingStartDate: startDate,
      billingEndDate: dayjs(futureContactInfoPlan?.startDate).add(1, 'M').endOf('month').valueOf(),
    }
  }

  /**
   * 月額プランの初回請求予定情報を取得する
   */
  const getFirstPaymentInfo = async (
    futureContactInfoPlan: ContractInfoPlanDocument | undefined,
    contractInfoId: string,
  ) => {
    const monthlyPlanPaymentPrice: Ref<string> = ref('')

    /** 月額プランの初回請求金額（日割り分）を取得する */
    const firstGeneratedParams = getMonthlyPlanFirstProRatedPriceParam(futureContactInfoPlan)
    const firstPaymentPriceResult = firstGeneratedParams?.requestData
      ? await fetchMonthlyPlanPrice(contractInfoId, firstGeneratedParams.requestData)
      : null

    // 月額プラン初回の支払い金額
    // 単純に日割り計算した請求金額のため、日割り料金が50円未満の場合は50円を請求金額として表示する
    monthlyPlanPaymentPrice.value = calculatePaymentPriceString(
      contractInfoPlanPrice.value?.charge?.total,
    )

    // 翌月の請求情報データ
    const nextMonthSettlementInfo = settlementInfoOnNextMonth(futureContactInfoPlan)

    /**
     * 初月の決済日
     * UTC基準で月初の0時を取得
     */
    const settlementDate = dayjs
      .tz(futureContactInfoPlan?.startDate)
      .add(1, 'M')
      .startOf('month')
      .valueOf()

    // UTCより時差が遅れている国で月末に月額プランが開始される場合、翌月利用分の日割り料金を取得できてしまう。そのため翌月の利用期間とその利用期間に対する請求日（=翌々月の日付）を表示するように調整する。
    const needAdjustDurationOfUse =
      dayjs.tz(futureContactInfoPlan?.startDate).format('MM') >
      dayjs(futureContactInfoPlan?.startDate).format('MM')

    if (firstPaymentPriceResult?.isSuccess && monthlyPlanPaymentPrice.value) {
      // 請求金額が1円以上の場合、初回の請求金額、決済日、利用期間を返す
      // 時差で決済日と利用期間がずれる場合は翌月の利用期間を表示する
      return {
        price: monthlyPlanPaymentPrice.value,
        settlementDate: needAdjustDurationOfUse
          ? nextMonthSettlementInfo.settlementDate
          : settlementDate,
        billingStartDate: needAdjustDurationOfUse
          ? nextMonthSettlementInfo.billingStartDate
          : futureContactInfoPlan?.startDate,
        billingEndDate: needAdjustDurationOfUse
          ? nextMonthSettlementInfo.billingEndDate
          : dayjs(futureContactInfoPlan?.startDate).endOf('month').valueOf(),
      }
    }
    Logger.info(
      `usePaymentSchedule#getFirstPaymentInfo: Cannot get price. contractInfoId: ${contractInfoId}, startDate: ${firstGeneratedParams?.requestData.startDate}, endDate: ${firstGeneratedParams?.requestData.endDate}`,
    )

    /**
     * 上記で計算した日割り料金が0円だった場合、次の月の請求情報を計算する
     * ケース1. UTCより時差が進んでいる国で、月初に契約が開始される場合
     * ex. 日本で2024/10/01 1:00(JST)に契約開始
     * ケース2. UTCより時差が遅れている国で、月末に契約が開始され かつ 請求対象の契約開始月がUTC基準の月と同じ場合
     * ex. ハワイで2024/9/30 5:00(HST)に契約開始（契約開始日時が2024/9/30 15:00(UTC)となりgetAgainMonthlyPlanFirstProRatedPriceParamで9/30 15:00(UTC)〜9/30 23:59(UTC)の利用期間に対する請求金額が計算され0円が返ってくるためこちらの再計算ロジックに入る。）
     */
    /** 月額プランの初回請求金額（日割り分）を取得する */
    const reCalculateRequestData = getAgainMonthlyPlanFirstProRatedPriceParam(
      futureContactInfoPlan?.contractPlanId ?? '',
      firstGeneratedParams?.firstBillingEndDate,
    )
    const reCalculateFirstPaymentPriceResult = reCalculateRequestData
      ? await fetchMonthlyPlanPrice(contractInfoId, reCalculateRequestData)
      : null

    // 再計算した月額プラン初回の支払い金額
    // 単純に日割り計算した請求金額のため、日割り料金が50円未満の場合は50円を請求金額として表示する
    monthlyPlanPaymentPrice.value = calculatePaymentPriceString(
      contractInfoPlanPrice.value?.charge?.total,
    )

    if (reCalculateFirstPaymentPriceResult?.isSuccess || monthlyPlanPaymentPrice.value) {
      // 請求金額が1円以上の場合、初回の請求金額、決済日、利用期間を返す
      // UTCより時差が遅れている国で月末に月額プランが開始された場合、再計算をする際に使用する請求対象の契約開始月がUTC基準の月と比較した際に1ヶ月マイナスされて表示されてしまう。そのため翌月の利用期間とその利用期間に対する請求日（=翌々月の日付）を表示するように調整する。
      const needAdjustDurationOfUseForSecond =
        dayjs.tz(reCalculateRequestData?.startDate).format('MM') >
        dayjs(reCalculateRequestData?.startDate).format('MM')

      return {
        price: monthlyPlanPaymentPrice.value,
        settlementDate: needAdjustDurationOfUseForSecond
          ? nextMonthSettlementInfo.settlementDate
          : settlementDate,
        billingStartDate: needAdjustDurationOfUseForSecond
          ? nextMonthSettlementInfo.billingStartDate
          : futureContactInfoPlan?.startDate,
        billingEndDate: needAdjustDurationOfUseForSecond
          ? nextMonthSettlementInfo.billingEndDate
          : dayjs(futureContactInfoPlan?.startDate).endOf('month').valueOf(),
      }
    }
    Logger.info(
      `usePaymentSchedule#getFirstPaymentInfo: Cannot get price again. contractInfoId: ${contractInfoId}, startDate: ${
        (firstGeneratedParams?.firstBillingEndDate ?? 0) + 1
      }, endDate: ${dayjs
        .tz((firstGeneratedParams?.firstBillingEndDate ?? 0) + 1)
        .endOf('month')
        .valueOf()}`,
    )

    // 2回とも請求金額を取得できなかった場合、nullを返す
    return null
  }

  return {
    getFirstPaymentInfo,
  }
}
