var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-list-tabs-parts" }, [
    _c(
      "ul",
      { staticClass: "mission-list-tabs-parts__list" },
      _vm._l(_vm.tabs, function (tab) {
        return _c(
          "li",
          { key: tab.id, staticClass: "mission-list-tabs-parts__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "mission-list-tabs-parts__item-link",
                class: {
                  "mission-list-tabs-parts__item-link--current":
                    _vm.currentTab === tab.id,
                },
                attrs: { to: tab.path },
              },
              [_vm._v(_vm._s(tab.name))]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }