
































import { defineComponent, PropType } from '@vue/composition-api'
import {
  CommentDataType,
  ParentCommentDataType,
} from '@/components/RaceVideoPage/hook/useCommunication'

/**
 * いいね対象のハイライト、ハイライトコメントのデータ型
 */
export type ClickedLikeDataType = {
  highlightId: string
  commentId: string
  liked: boolean
}

/**
 * レース動画再生画面 ハイライト投稿コメント いいねボタン パーツコンポーネント
 */
export default defineComponent({
  name: 'LikeButtonParts',
  props: {
    /**
     * ハイライト親コメント/投稿コメントデータ
     */
    commentData: {
      type: Object as PropType<ParentCommentDataType | CommentDataType>,
      required: true,
    },
  },
  methods: {
    emitClick() {
      this.$emit('click', {
        highlightId: this.commentData.highlightId,
        commentId: this.commentData.commentId,
        liked: this.commentData.liked,
      })
    },
  },
})
