var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-page" },
    [
      _c("registration-pane", {
        staticClass: "registration-page__content",
        attrs: { email: _vm.email, tokenId: _vm.tokenId },
        on: { formSubmit: _vm.moveToPlanSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }