import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import PlayerLiveTiming from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'

/**
 * ライブタイミング情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3187442036/API
 */
export default class LiveTimingDocument extends DocumentWrapper {
  constructor(initProps?: Partial<LiveTimingDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    const playerLiveTimings = [] as Array<PlayerLiveTiming>
    initProps?.liveTimings?.forEach((playerLiveTiming) => {
      playerLiveTimings.push(new PlayerLiveTiming(this, playerLiveTiming))
    })
    this.liveTimings = playerLiveTimings
    if (this.RACE_TYPE === 'R') {
      // 現在の先頭車両の走行ラップを取得し、LAPSに設定する
      const maxLapLiveTiming = this.liveTimings.reduce((previous, current) =>
        Number(previous.LAPS) > Number(current.LAPS) ? previous : current,
      )
      this.LAPS = maxLapLiveTiming?.LAPS ?? ''
    }
  }

  _path = 'data/record/live_timing'

  idAttr = 'createdDate'

  numberOfLaps: number | null = null

  /**
   * フラッグ情報
   * "G":グリーン, "Y":イエロー, "R":レッド, "F":フィニッシュ, "N": NONE
   */
  flag: 'G' | 'Y' | 'R' | 'F' | 'N' | null = null

  scFlag = false

  LAP_BEST_TIME: string | null = null

  RACE_TYPE: 'R' | 'B' | '' | null = null

  LAP_BEST_LAPS: string | null = null

  STATUS: string | null = null

  CARNO: string | null = null

  TOTAL_DISP: string | null = null

  PIT: string | null = null

  PIT_IN_CERTIFIED: string | null = null

  PIT_IN_COUNT_CERTIFIED: string | null = null

  LAPS: string | null = null

  COMPARE_PREVIOUS_DIFF: string | null = null

  CURRENT_SEC: string | null = null

  BATTLE_GROUP_ID: string | null = null

  createdDate: number | null = null

  liveTimings: Array<PlayerLiveTiming> = []

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `RadioDataDocument[createdDate: ${this.createdDate}, RACE_TYPE: ${this.RACE_TYPE}]`
  }
}
