var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-item-block-section" }, [
    _c("div", { staticClass: "mypage-ticket-item-block-section__header" }, [
      _c("p", { staticClass: "mypage-ticket-item-block-section__title" }, [
        _vm._v(_vm._s(_vm.ticketEvent.eventName)),
      ]),
      _c("p", { staticClass: "mypage-ticket-item-block-section__venue" }, [
        _vm._v(_vm._s(_vm.displayPlaceName)),
      ]),
      _c(
        "div",
        { staticClass: "mypage-ticket-item-block-section__date" },
        [
          _c(
            "p",
            { staticClass: "mypage-ticket-item-block-section__date-start" },
            [
              _c(
                "span",
                { staticClass: "mypage-ticket-item-block-section__date-data" },
                [_vm._v(_vm._s(_vm.displayEventStartDate))]
              ),
              _c(
                "span",
                { staticClass: "mypage-ticket-item-block-section__date-day" },
                [_vm._v(" " + _vm._s(_vm.displayEventStartDayOfWeek))]
              ),
            ]
          ),
          _vm.ticketEvent.openingDateDay2 || _vm.ticketEvent.openingDateDay3
            ? [
                _c(
                  "p",
                  { staticClass: "mypage-ticket-item-block-section__date-div" },
                  [_vm._v("-")]
                ),
                _c(
                  "p",
                  { staticClass: "mypage-ticket-item-block-section__date-end" },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "mypage-ticket-item-block-section__date-data",
                      },
                      [_vm._v(_vm._s(_vm.displayEventEndDate))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mypage-ticket-item-block-section__date-day",
                      },
                      [_vm._v(" " + _vm._s(_vm.displayEventEndDayOfWeek))]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "mypage-ticket-item-block-section__opening" }, [
        _c(
          "p",
          { staticClass: "mypage-ticket-item-block-section__opening-title" },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc("MypagePage.MypageTicketListPage.openingTitle")
                ) +
                " "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mypage-ticket-item-block-section__opening-body" },
          [
            _c(
              "table",
              {
                staticClass: "mypage-ticket-item-block-section__opening-table",
              },
              _vm._l(_vm.displayOpeningDates, function (date, index) {
                return _c(
                  "tr",
                  {
                    key: _vm.ticketEvent.uniqueKey + "-" + index,
                    staticClass:
                      "mypage-ticket-item-block-section__opening-data",
                  },
                  [
                    date.value
                      ? [
                          _vm.ticketEvent.openingDateDay2
                            ? _c(
                                "th",
                                {
                                  staticClass:
                                    "mypage-ticket-item-block-section__opening-data-date",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getOpeningDate(date.value)) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "td",
                            {
                              staticClass:
                                "mypage-ticket-item-block-section__opening-data-time",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getOpeningTime(date.value)) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ]),
    _vm.isExpanded &&
    _vm.purchasedTicketTypes &&
    _vm.purchasedTicketTypes.length > 0
      ? _c("div", { staticClass: "mypage-ticket-item-block-section__body" }, [
          _c(
            "ul",
            { staticClass: "mypage-ticket-item-block-section__ticket-list" },
            _vm._l(_vm.purchasedTicketTypes, function (ticketType, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "mypage-ticket-item-block-section__ticket-item",
                },
                [
                  _c("mypage-ticket-item-section", {
                    attrs: {
                      ticketType: ticketType,
                      purchasedTicketInfo: _vm.purchasedTicketInfo,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mypage-ticket-item-block-section__toggle" }, [
      _c(
        "button",
        {
          staticClass: "mypage-ticket-item-block-section__toggle-button",
          class: {
            "mypage-ticket-item-block-section__toggle-button--open":
              _vm.isExpanded,
          },
          on: { click: _vm.handleToggleClicked },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "mypage-ticket-item-block-section__toggle-button-label",
            },
            [_vm._v(_vm._s(_vm.toggleButtonLabel))]
          ),
          _c(
            "span",
            {
              staticClass:
                "mypage-ticket-item-block-section__toggle-button-icon",
            },
            [_c("mypage-ticket-accordion-icon-parts")],
            1
          ),
        ]
      ),
    ]),
    _vm.hasPastTickets
      ? _c(
          "div",
          { staticClass: "mypage-ticket-item-block-section__footer" },
          [
            _vm._l(_vm.displayOpeningDates, function (date, index) {
              return [
                _vm.isPastTicket(date)
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "mypage-ticket-item-block-section__footer-ticket",
                        on: {
                          click: function ($event) {
                            return _vm.moveToMypageTicketPastPage(date.key)
                          },
                        },
                      },
                      [
                        _c("atom-router-button", {
                          attrs: {
                            to: "",
                            linkText: _vm.getPastButtonText(index),
                            colorType: "secondary",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "mypage-ticket-item-block-section__circuit" }, [
      _c(
        "p",
        { staticClass: "mypage-ticket-item-block-section__circuit-icon" },
        [
          _vm.ticketEvent.stadiumId === "TWIN_RING_MOTEGI"
            ? _c("motegi-parts")
            : _vm._e(),
          _vm.ticketEvent.stadiumId === "FUJI_SPEEDWAY"
            ? _c("fuji-parts")
            : _vm._e(),
          _vm.ticketEvent.stadiumId === "SPORTSLAND_SUGO"
            ? _c("sugo-parts")
            : _vm._e(),
          _vm.ticketEvent.stadiumId === "AUTOPOLIS"
            ? _c("autopolice-parts")
            : _vm._e(),
          _vm.ticketEvent.stadiumId === "SUZUKA_CIRCUIT"
            ? _c("suzuka-parts")
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }