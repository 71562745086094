





























import { defineComponent } from '@vue/composition-api'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'HighlightToastParts',
  components: { AtomButton },
  props: {
    userName: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const handleCancelClicked = () => {
      emit('onClickCancel')
    }
    const handleCloseClicked = () => {
      emit('onClickClose')
    }
    return {
      handleCancelClicked,
      handleCloseClicked,
    }
  },
})
