


















































import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { defineComponent, onMounted, ref, watch, Ref } from '@vue/composition-api'
import SnsPostItemParts from '@/components/HomePage/HomePane/parts/SnsPostItemParts.vue'
import SnsPostLoaderParts from '@/components/HomePage/HomePane/parts/SnsPostLoaderParts.vue'
import StoreUtil from '@/store/StoreUtil'
import HighlightsCommentTriggerParts from '@/components/HomePage/HomePane/parts/HighlightsCommentTriggerParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * HOME SNS 公式コンテンツセクション
 */
export default defineComponent({
  name: 'SnsOfficialSection',
  components: {
    HighlightsCommentTriggerParts,
    SnsPostItemParts,
    SnsPostLoaderParts,
  },
  setup() {
    const store = StoreUtil.useStore('HomePageStore')
    const errorImgSrc = ref('')
    /**
     * アプリ版のAndroidのみvue-virtual-scroller（DynamicScroller）を導入する
     */
    const isUseDynamicScroller = DeviceInfo.isAndroid()

    onMounted(() => {
      // SF公式アカウントのSNS投稿を取得する
      store.fetchHomePageSnsFeed('SF')
    })

    /**
     * SF公式アカウントのSNS記事データ
     */
    const snsContentsData = ref([]) as Ref<SnsPostData[]>

    /**
     * store.getSnsFeedDataListの変更を監視し、リアクティブにデータを作成する
     */
    watch(
      () => store.getSnsFeedDataList.value,
      (newData) => {
        snsContentsData.value = newData.map((obj) => ({
          ...obj,
          images: obj.images.filter((image) => image.src !== errorImgSrc.value),
        }))
      },
      {
        immediate: true,
        deep: true,
      },
    )

    /**
     * SNSコンテンツローディング中フラグ
     */
    const snsLoading = ref<boolean>(false)

    /**
     * ハイライトのみ絞り込みフィルター
     */
    const highlightTrigger = ref<boolean>(false)

    /**
     * imgタグで読み込みエラーになったデータを削除して、データを再構築する
     * この処理によってdynamic-scrollerで再計算が行われる
     */
    const updateSnsContentsData = (src: string) => {
      errorImgSrc.value = src
      snsContentsData.value = snsContentsData.value.map((obj) => ({
        ...obj,
        images: obj.images.filter((image) => image.src !== src),
      }))
    }

    return {
      snsContentsData,
      snsLoading,
      highlightTrigger,
      updateSnsContentsData,
      isUseDynamicScroller,
    }
  },
  methods: {
    /**
     * ハイライトコメントフィルター切替
     */
    changeHighlightsTrigger() {
      // TODO: 絞り込み処理
      this.highlightTrigger = !this.highlightTrigger
    },
  },
})
