







import { defineComponent, inject, onMounted, ref, Ref } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import type VueRouter from 'vue-router'
import ReferralCouponSection from '@/components/MypageReferralCodePage/ReferralCodePane/ReferralCouponSection.vue'
import ReferralCodeSection from '@/components/MypageReferralCodePage/ReferralCodePane/ReferralCodeSection.vue'
import useUserCoupon from '@/store/hook/useUserCoupon'
import useContractPlan from '@/store/hook/useContractPlan'
import useReferralCode from '@/components/MypageReferralCodePage/hook/useReferralCode'
import UserCouponDocument from '@/store/stores/collectionModule/documents/coupon/UserCouponDocument'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'

/**
 * マイページ: 紹介コード: 入力セクション
 */
export default defineComponent({
  name: 'ReferralCodePane',
  components: {
    ReferralCouponSection,
    ReferralCodeSection,
  },
  inject: ['router', 'loading'],
  setup() {
    const referralCode = ref('')
    const baseCouponPlan = ref() as Ref<ContractPlanDocument>
    const { fetchUserCoupons, registerUserCoupon, userCoupons } = useUserCoupon()
    const { getCouponPlan } = useContractPlan()
    /** ランダムな文字列を生成するhook */
    const { createRandomCode } = useReferralCode()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    /** ベースクーポンが存在しない場合trueになる */
    const isBaseCouponEmpty = ref(false)

    /**
     * 9桁のランダムなクーポンコードを生成
     */
    const generateRequestData = () =>
      new UserCouponDocument({
        couponCode: createRandomCode(9),
      })

    /**
     * 表示するプラン情報を作成
     */
    const generateCouponPlanData = (plan: ContractPlanDocument, coupon: UserCouponDocument) =>
      new ContractPlanDocument({
        ...plan,
        couponData: {
          couponName: {
            ja: coupon?.couponName.ja || '',
            en: coupon?.couponName.en || '',
          },
          title: {
            ja: coupon?.additionalData?.title.ja || '',
            en: coupon?.additionalData?.title.en || '',
          },
          description: {
            ja: coupon?.description?.ja || '',
            en: coupon?.description?.en || '',
          },
          couponApplicationPeriodText: coupon?.couponApplicationPeriodText || '',
        },
      })

    onMounted(async () => {
      const loader = loading.show()
      // ユーザー個別のクーポンを取得する
      const result = await fetchUserCoupons()
      // 個別クーポンがあるか判定
      if (result.data.length === 0) {
        /**
         * 個別クーポンがなかった場合、個別クーポンを作成する
         * クーポンコードが重複していたら、5回くらいループでPOST処理を投げる
         * それでもコードが重複してしまう場合はエラーモーダルを表示してマイページに遷移する
         */
        for (let i = 0; i < 5; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const createNewCoupon = await registerUserCoupon(generateRequestData())
          if (createNewCoupon.isSuccess) {
            // 登録したユーザー個別のクーポンを取得し、ループを抜ける
            // eslint-disable-next-line no-await-in-loop
            await fetchUserCoupons()
            break
          }

          if (createNewCoupon.response?.data.error_code === '500_0038') {
            // 有効なデフォルトのベースクーポンが登録されていない場合のエラー
            isBaseCouponEmpty.value = true
            break
          }
        }
      }
      loader.hide()

      if (isBaseCouponEmpty.value) {
        // ベースクーポンが未登録の場合は紹介コードを生成できないため、マイページに戻す
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageReferralCode.errors.baseCouponEmptyError.title'),
          message: I18n.tc('MypagePage.MypageReferralCode.errors.baseCouponEmptyError.message'),
        })
        await router.replace({ name: 'MypageTopPage' })
        return
      }

      /** 表示するクーポンデータを取得 */
      const couponData = userCoupons.value.find((v) => v.couponCode)
      // 取得した紹介コードをセット
      referralCode.value = couponData?.couponCode || ''

      // クーポン情報に入っているプラン情報を取得
      const originalCouponPlan = couponData?.plans ? getCouponPlan(couponData.plans) : null
      if (!couponData?.plans || !originalCouponPlan || !referralCode.value) {
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageReferralCode.errors.getReferralError.title'),
          message: I18n.tc('MypagePage.MypageReferralCode.errors.getReferralError.message'),
        })
        await router.replace({ name: 'MypageTopPage' })
        return
      }

      // 表示するプラン情報を作成（ベースクーポンに登録されているプラン情報を表示する）
      baseCouponPlan.value = generateCouponPlanData(originalCouponPlan, couponData)
    })

    return {
      referralCode,
      baseCouponPlan,
    }
  },
})
