var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "award-ranking-list-section" }, [
    _c("thead", { staticClass: "award-ranking-list-section__header" }, [
      _c("tr", [
        _c("th", {
          staticClass:
            "award-ranking-list-section__header-label award-ranking-list-section__header-label--rank",
          domProps: {
            textContent: _vm._s(_vm.$tc("LinkPage.AwardPage.label.rank")),
          },
        }),
        _c("th", {
          staticClass:
            "award-ranking-list-section__header-label award-ranking-list-section__header-label--name",
          domProps: {
            textContent: _vm._s(
              _vm.$tc("LinkPage.AwardPage.label." + _vm.category)
            ),
          },
        }),
        _vm.category === "PIT_CREW"
          ? _c("th", {
              staticClass:
                "award-ranking-list-section__header-label award-ranking-list-section__header-label--time",
              domProps: {
                textContent: _vm._s(_vm.$tc("LinkPage.AwardPage.label.time")),
              },
            })
          : _vm._e(),
      ]),
    ]),
    _c(
      "tbody",
      _vm._l(_vm.rankingDataOtherFirst, function (rankingData, idx) {
        return _c(
          "tr",
          {
            key: "ranking-other-first" + idx,
            class: {
              "award-ranking-list-section__item-value--favorite":
                rankingData.favorite,
            },
          },
          [
            _c("td", {
              staticClass:
                "award-ranking-list-section__item-value award-ranking-list-section__item-value--rank",
              domProps: { textContent: _vm._s(rankingData.ranking) },
            }),
            _c(
              "td",
              {
                staticClass:
                  "award-ranking-list-section__item-value award-ranking-list-section__item-value--content",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "award-ranking-list-section__item-value__inner",
                  },
                  [
                    _c("figure", [
                      _c("img", { attrs: { src: rankingData.image, alt: "" } }),
                    ]),
                    _c("p", [
                      _c("span", {
                        domProps: { textContent: _vm._s(rankingData.name) },
                      }),
                      rankingData.favorite
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "award-ranking-list-section__item-value__favorite",
                            },
                            [
                              _c("i", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/AwardPage/icon_favorite.svg"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" " + _vm._s(_vm.favoriteText) + " "),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _vm.category === "PIT_CREW"
              ? _c("td", {
                  staticClass:
                    "award-ranking-list-section__item-value award-ranking-list-section__item-value--time",
                  domProps: { textContent: _vm._s(rankingData.time) },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }