


































import { computed, defineComponent, PropType } from '@vue/composition-api'
import useTelemetrySectionUtil from '@/components/RaceVideoPage/RaceVideoDataViewPane/hook/useTelemetrySectionUtil'
import TelemetryGearPosParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryGearPosParts.vue'
import TelemetryCarSpeedParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryCarSpeedParts.vue'
import TelemetryRpmParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryRpmParts.vue'
import TelemetrySteeringAngleParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetrySteeringAngleParts.vue'
import TelemetryBrkPressFrParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryBrkPressFrParts.vue'
import TelemetryRpmGaugeParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryRpmGaugeParts.vue'
import TelemetryThrottlePedalParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryThrottlePedalParts.vue'
import TelemetryFuelUsedParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryFuelUsedParts.vue'
import TelemetryOtsParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryOtsParts.vue'
import TelemetryTireTemperatureSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/TelemetryTireTemperatureSection.vue'
import { TelemetryDataType } from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

export type DataType = {
  diffMs: number
}

/**
 * レース動画再生画面 テレメトリーセクションのコンポーネント
 */
export default defineComponent({
  name: 'RaceTelemetrySection',
  components: {
    TelemetryGearPosParts,
    TelemetryCarSpeedParts,
    TelemetryRpmParts,
    TelemetrySteeringAngleParts,
    TelemetryBrkPressFrParts,
    TelemetryRpmGaugeParts,
    TelemetryThrottlePedalParts,
    TelemetryFuelUsedParts,
    TelemetryOtsParts,
    TelemetryTireTemperatureSection,
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 表示対象となるテレメトリーデータ
     */
    telemetryData: {
      type: Object as PropType<TelemetryDataType>,
      require: true,
    },
  },
  data(): DataType {
    return {
      diffMs: 0, // 数値表示 実データ間の穴埋め差分用mz
    }
  },
  setup(props) {
    const { adjustPosition } = useTelemetrySectionUtil()

    // 位置指定を整数化
    if (props.lockOrientation === undefined) {
      adjustPosition('.race-telemetry-section')
    }

    const currentTelemetryData = computed(() => props.telemetryData)
    return {
      currentTelemetryData,
    }
  },
  mounted() {
    /**
     * 数値表示 実データ間の穴埋め差分用ms計算
     */
    const socketInterval = 10 // 実データが秒間 XX 回来るか (※変更の際はゲージのアニメーション速度も要修正)
    const ms = Math.floor(1000 / socketInterval) // トデータを差し替えるms計算
    const fpsMs = Math.floor(1000 / 60) // 表示FPSのms計算 ※描画速度を切り替える場合ここを修正
    this.diffMs = Math.ceil(ms / fpsMs) // 数値表示 実データ間の穴埋め差分用ms計算
  },
  methods: {},
})
