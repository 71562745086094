





























import { defineComponent, Ref } from '@vue/composition-api'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import StoreUtil from '@/store/StoreUtil'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import useOneTimePassContract from '@/components/MypageOneTimePassConfirmPage/hook/useOneTimePassContract'
import useOneTimePassErrorHandling from '@/components/MypageOneTimePassConfirmPage/hook/useOneTimePassErrorHandling'
import useConditionsMoveToUserEdit from '@/components/hook/useConditionsMoveToUserEdit'
import useHistory from '@/store/hook/useHistory'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useGeolocation from '@/components/hook/useGeolocation'
import useErrorHandling from '@/components/hook/useErrorHandling'
import I18n from '@/locales/I18n'

/**
 * マイページ: ワンタイムパス: 確認ペイン
 */
export default defineComponent({
  name: 'OneTimePassConfirmPane',
  components: {
    CurrentPlanCardSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const userStore = StoreUtil.useStore('UserStore')
    const errorStore = StoreUtil.useStore('ErrorStore')
    const { oneTimePass, oneTimePassPlan, ownContractInfo, getTargetAvailableArea } =
      oneTimePassPageStore
    const { user } = userStore
    const { createOneTimePassContract } = useOneTimePassContract()
    const { changeShouldEnterZipCode } = useConditionsMoveToUserEdit()
    const { createContractError } = useOneTimePassErrorHandling()
    const { saveContractInfoHistory } = useHistory()
    const { getCurrentPosition, checkIsUserInArea } = useGeolocation()
    const { createGeolocationError } = useErrorHandling()

    return {
      errorStore,
      oneTimePass,
      oneTimePassPlan: oneTimePassPlan as Ref<ContractPlanDocument>,
      ownContractInfo,
      getTargetAvailableArea,
      user,
      createOneTimePassContract,
      changeShouldEnterZipCode,
      createContractError,
      saveContractInfoHistory,
      getCurrentPosition,
      checkIsUserInArea,
      createGeolocationError,
    }
  },
  methods: {
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      const loader = this.$loading.show()

      /**
       * サーキットなどの現地でのみ利用可能なワンタイムパスの場合の処理
       */
      const oneTimePassAvailableAreaId = this.oneTimePass?.additionalData?.availableArea?.areaId
      if (oneTimePassAvailableAreaId) {
        /** 現地でのみ利用可能なワンタイムパスの場合、以下の処理を行う */
        let positionResult = null
        try {
          // 現在地を取得
          positionResult = await this.getCurrentPosition()
          Logger.info(
            `OneTimePassConfirm#handlerSubmit currentGPS: ${positionResult.coords.latitude}, ${positionResult.coords.longitude}`,
          )
        } catch (e) {
          loader.hide()
          // HACK: ESLint: 'GeolocationPositionError' is not defined.(no-undef)エラーが表示さレてしまい、回避方法が不明なため、ひとまずエラーを無効化している
          // eslint-disable-next-line no-undef
          const positionError = e as GeolocationPositionError
          Logger.info(
            `OneTimePassConfirm#handlerSubmit getCurrentPositionError: code: ${positionError.code}, message: ${positionError.message}`,
          )
          await this.createGeolocationError(positionError)
          return
        }

        // 対象ワンタイムパスの利用可能エリア情報を取得
        const availableArea = this.getTargetAvailableArea(oneTimePassAvailableAreaId)

        let isInArea = false
        try {
          // ユーザーが利用可能エリア内にいるかを判定する
          isInArea = this.checkIsUserInArea(positionResult, availableArea)
        } catch (e) {
          loader.hide()
          await MessageDialogStore.value.open({
            title: this.$tc('common.errors.pointInPolygonError.title'),
            message: this.$tc('common.errors.pointInPolygonError.message'),
          })
          return
        }

        if (!isInArea) {
          // ワンタイムパス利用可能エリア外
          loader.hide()
          await MessageDialogStore.value.open({
            title: this.$tc(
              'MypagePage.MypageOneTimePass.ConfirmPage.errors.outsideAreaOneTimePassUse.title',
            ),
            message: this.$t(
              'MypagePage.MypageOneTimePass.ConfirmPage.errors.outsideAreaOneTimePassUse.message',
              {
                areaName: availableArea?.additionalData?.areaName?.[I18n.locale],
              },
            ).toString(),
          })
          return
        }
      }

      /** ワンタイムパスで契約情報契約プランを登録 */
      const result = await this.createOneTimePassContract(this.ownContractInfo.contractInfoId)

      if (!result.isSuccess) {
        loader.hide()
        await this.createContractError(result.response?.data)
        // ワンタイムパス画面に遷移する
        this.$emit('handlerCancel')
        return
      }

      /** 契約情報変更履歴を登録する */
      // contractInfoIdがnullの場合は上記処理が失敗するため、以下の処理には入らない。なので、引数contractInfoIdはstring型にアサーションしている
      const apiResult = await this.saveContractInfoHistory(
        this.ownContractInfo.contractInfoId as string,
        this.user,
      )
      Logger.debug(`ContractInfoHistory: ${apiResult.isSuccess}`)

      this.$emit('handlerSubmit')
      loader.hide()
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
