var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-sns-team-select-page" },
    [
      _c("header-pane", {
        staticClass: "home-page__header",
        attrs: { colorType: "light", pageTitle: _vm.$tc("HomePage.pageTitle") },
      }),
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("HomeSnsTeamSelectPage.pageTitle"),
          backLink: "/home",
        },
      }),
      _c("home-sns-team-select-pane", {
        staticClass: "home-sns-team-select-page__content",
        on: { changed: _vm.moveToHome },
      }),
      _c("global-navigation-pane", { attrs: { current: "home" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }