import useAWS from '@/store/hook/useAWS'
import type { DynamoDBTableType } from '@/store/stores/loginStore/DynamoDBCredentialStore'
import dynamoDBCredentialStore from '@/store/stores/loginStore/DynamoDBCredentialStore'
import Logger from '@/util/logger/Logger'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 日時の範囲検索メソッドの入力パラメタ
 */
export type SearchDateRangeDataType = {
  tableName: DynamoDBTableType
  partitionKey: string
  partitionKeyValue: string
  attributeNames?: Record<string, string>
  sortKey: string
  from?: number
  to?: number
}

/**
 * AWS DynamoDBを利用するための機能を提供する。
 */
export default function useDynamoDB() {
  const { initAWS } = useAWS()
  const { getAWSCredential, getDynamoTableName, getTableDocumentClass, auth } =
    dynamoDBCredentialStore.value

  /**
   * 対象のテーブルから、指定された日時の範囲に作成されたデータを検索する。
   *
   * @param tableName DynamoDB テーブル名
   * @param partitionKey パーティションキー名
   * @param partitionKeyValue 検索する際にパーティションキーに指定する値
   * @param attributeNames パーティションキー、または、ソートキーがAWSの予約語である場合に、
   * 属性値を#から始まる任意の英数字に置き換えるために指定する。
   * DynamoDB の expression-attribute-names の説明を参照する。
   * @param sortKey ソートキー名
   * @param from 検索範囲 - 開始位置(ミリ秒)
   * @param to 検索範囲 - 終了位置(ミリ秒)
   */
  const searchDateRangeData = async ({
    tableName,
    partitionKey,
    partitionKeyValue,
    attributeNames,
    sortKey,
    from,
    to,
  }: SearchDateRangeDataType): Promise<Array<DocumentWrapper>> => {
    const awsCredentials = getAWSCredential?.(tableName)
    const tokenExpiredDate = awsCredentials?.tokenExpiredDate || 0

    if (tokenExpiredDate < new Date().getTime()) {
      // DynamoDB認証情報の有効期限が切れていた場合、DynamoDBの認証情報を取得する
      await auth?.()
    }

    return new Promise((resolve, reject) => {
      if (!awsCredentials) {
        resolve([])
        return
      }
      const dynamoTableName = getDynamoTableName?.(tableName)
      if (!dynamoTableName) {
        resolve([])
        return
      }
      const AWS = initAWS(awsCredentials)

      const ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-08-10' })

      let keyCondition = `${partitionKey} = :${partitionKey}`
      if (attributeNames && Object.entries(attributeNames)[0]) {
        const partitionKeyAlias = Object.entries(attributeNames)[0][0]
        const attributeValueName = Object.entries(attributeNames)[0][1]
        keyCondition = `${partitionKeyAlias} = :${attributeValueName}`
      }

      // KeyConditionExpression: ソートキーに対してデータ検索の範囲指定がある場合、検索範囲情報を付加する
      if (sortKey && from && to) {
        keyCondition = `${keyCondition} and ${sortKey} BETWEEN :fromDate AND :toDate`
      }

      const params: AWS.DynamoDB.DocumentClient.QueryInput = {
        ExpressionAttributeValues: {
          [`:${partitionKey}`]: `${partitionKeyValue}`,
          ':fromDate': from,
          ':toDate': to,
        },
        KeyConditionExpression: keyCondition,
        TableName: dynamoTableName,
      }
      if (attributeNames) {
        // expression-attribute-names が指定されている場合に設定する
        // パーテーションキー、ソートキーがAWS 予約語である場合に指定する
        params.ExpressionAttributeNames = attributeNames
      }

      const results: Array<DocumentWrapper> = []
      const Doc = getTableDocumentClass?.(tableName)
      if (!Doc) {
        reject(new Error(`Failed to search ${dynamoTableName}`))
        return
      }
      ddb.query(params, (err, data) => {
        if (err) {
          Logger.error(`Failed to search DynamoDB table. tableName: ${dynamoTableName}`)
          reject(new Error(`Failed to search ${dynamoTableName}`))
        } else {
          data?.Items?.forEach((element) => {
            results.push(new Doc(element))
          })
          resolve(results)
        }
      })
    })
  }

  /**
   * テーブル種別からテーブル名を取得する。
   * @param tableName テーブル種別
   */
  const getTableName = (tableName: DynamoDBTableType): DynamoDBTableType | undefined =>
    getDynamoTableName?.(tableName)

  return {
    searchDateRangeData,
    getTableName,
  }
}
