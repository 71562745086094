var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlights-card-section" }, [
    _c("header", { staticClass: "highlights-card-section__header" }, [
      _vm.highlightData.user
        ? _c("figure", { staticClass: "highlights-card-section__player-img" }, [
            _c("img", {
              attrs: {
                src: _vm.highlightData.user.image
                  ? _vm.highlightData.user.image
                  : _vm.defaultIcon,
                alt: "",
              },
            }),
          ])
        : _vm._e(),
      _vm.highlightData.user
        ? _c("h3", {
            staticClass: "highlights-card-section__player-name",
            domProps: { textContent: _vm._s(_vm.highlightData.user.name) },
          })
        : _vm._e(),
      _vm.highlightData.editHighlightData
        ? _c("time", {
            staticClass: "highlights-card-section__time",
            domProps: {
              textContent: _vm._s(_vm.highlightData.editHighlightData.raceTime),
            },
          })
        : _vm._e(),
    ]),
    _vm.isPlayingVideo
      ? _c(
          "div",
          {
            staticClass: "highlights-card-section__video",
            on: { pointerdown: _vm.visibleController },
          },
          [
            _c("highlight-preview-video-player-section", {
              staticClass: "highlights-card-section__video-player",
              attrs: {
                screenOrientationType: _vm.screenOrientationType,
                highlightData: _vm.highlightData,
              },
            }),
            _vm.isVisibleController && _vm.isPc
              ? _c("highlight-preview-controller-parts", {
                  staticClass: "highlights-card-section__video-overlay",
                  attrs: {
                    videoTimes: _vm.videoStatus,
                    screenOrientationType: _vm.screenOrientationType,
                    videoPaused: _vm.videoPaused,
                  },
                  on: {
                    videoPlayPauseChange: _vm.changeVideoPlayPause,
                    updateCurrentTime: _vm.updateCurrentTime,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _c("div", { staticClass: "highlights-card-section__thumbnail" }, [
          _c(
            "button",
            {
              staticClass: "highlights-card-section__button",
              on: { click: _vm.handlePlayClicked },
            },
            [
              _c(
                "span",
                { staticClass: "highlights-card-section__button-icon" },
                [_c("highlights-play-icon")],
                1
              ),
              _c("img", {
                attrs: { src: _vm.highlightData.thumbnail, alt: "" },
              }),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }