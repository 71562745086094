







































































































































































































import { computed, defineComponent } from '@vue/composition-api'
import useTelemetrySectionUtil from '@/components/RaceVideoPage/RaceVideoDataViewPane/hook/useTelemetrySectionUtil'

/**
 * レース動画再生画面 テレメトリー オーバーテイク残時間 値パーツのコンポーネント
 */
export default defineComponent({
  name: 'TelemetryOtsParts',
  components: {},
  props: {
    /**
     * バリュー値
     */
    otsValue: {
      type: Number,
      default: 200,
    },
    /**
     * 固有ID
     */
    id: {
      type: Number,
      default: 0,
    },
    /**
     * オーバーテイクシステム有効フラグ
     */
    otsEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * オーバーテイクシステム無効残時間
     */
    otsDisabledTime: {
      type: Number,
      default: 0,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    carNoData: {
      type: Number,
      default: 0,
    },
    otsDatas: {
      type: Object,
      dafault: () => [],
    },
  },
  setup(props) {
    const { adjustPosition } = useTelemetrySectionUtil()

    // 位置指定を整数化
    adjustPosition('.ranking-ots-parts')

    // ゲージ横幅
    const gaugeSvgWidth = 85.36

    /**
     * gaugeWidth
     * ゲージの横幅を算出
     */
    const gaugeWidth = computed(() => (gaugeSvgWidth * props.otsValue) / 200)

    /**
     * gaugePositionX
     * ゲージのX位置を算出
     */
    // const gaugePositionX = computed(() => gaugeSvgWidth - (gaugeSvgWidth * props.otsValue) / 200 + 4.86)
    const gaugePositionX = computed(
      () => gaugeSvgWidth - (gaugeSvgWidth * props.otsValue) / 200 + 4.86,
    )

    /**
     * SVGカラー
     * 残秒数が一定以下時に赤へ変更
     */
    const fillColor = computed(() => (props.otsValue > 20 ? '#fff' : '#ff2e00'))

    /**
     * オーバーテイクシステム無効状態判定
     */
    const otsDisabled = computed(() => props.otsDisabledTime > 0)

    return {
      gaugeWidth,
      gaugePositionX,
      fillColor,
      otsDisabled,
    }
  },
  methods: {},
})
