












import { computed, defineComponent } from '@vue/composition-api'

/**
 * チーム 表示パーツ
 * todo: デザイン調整 / props設定 / emit等
 */
export default defineComponent({
  name: 'TeamCardParts',
  props: {
    img: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * imgPath
     * @param {string} val 画像データパス
     */
    const imgPath = computed({
      get: () => props.img || './assets/img/common/card/icon_team__null.svg',
      set: (val: string) => val,
    })

    return {
      imgPath,
    }
  },
})
