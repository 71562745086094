var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-contact-us-pane" }, [
    _c("h3", {
      staticClass: "mypage-contact-us-pane__title",
      domProps: {
        innerHTML: _vm._s(_vm.$tc("MypagePage.MypageContactUsPage.titleFAQ")),
      },
    }),
    _c(
      "a",
      {
        staticClass: "mypage-contact-us-pane__link",
        attrs: { href: _vm.sfAboutSFgoUrl, target: "_blank" },
      },
      [_vm._v(_vm._s(_vm.sfAboutSFgoUrl))]
    ),
    _c("p", {
      staticClass: "mypage-contact-us-pane__message",
      domProps: {
        innerHTML: _vm._s(_vm.$tc("MypagePage.MypageContactUsPage.message")),
      },
    }),
    _c("h3", {
      staticClass: "mypage-contact-us-pane__title",
      domProps: {
        innerHTML: _vm._s(_vm.$tc("MypagePage.MypageContactUsPage.titleEmail")),
      },
    }),
    _c(
      "div",
      { staticClass: "mypage-contact-us-pane__button" },
      [
        _c("atom-link-button", {
          attrs: {
            linkText: _vm.$tc("MypagePage.MypageContactUsPage.toContactForm"),
            url: _vm.inquiryFormUrl,
            isDisabled: true,
            colorType: "secondary",
          },
        }),
      ],
      1
    ),
    _c("p", { staticClass: "mypage-contact-us-pane__attention-inquiry" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$tc("MypagePage.MypageContactUsPage.attentionInquiry")) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }