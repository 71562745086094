
































































import { computed, defineComponent } from '@vue/composition-api'
import useTelemetrySectionUtil from '@/components/RaceVideoPage/RaceVideoDataViewPane/hook/useTelemetrySectionUtil'

/**
 * レース動画再生画面 テレメトリー エンジン回転数 ゲージ値セクションのコンポーネント
 */
export default defineComponent({
  name: 'TelemetryRpmGaugeParts',
  components: {},
  props: {
    /**
     * バリュー値
     */
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    /**
     * ゲージMAX値
     */
    max: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  setup(props) {
    const { adjustPosition } = useTelemetrySectionUtil()

    // 位置指定を整数化
    adjustPosition('.telemetry-rpm-gauge-parts')

    // ゲージ高さ
    const gaugeSvgHeight = 128

    /**
     * gaugeHeight
     * ゲージの高さを算出
     */
    const gaugeHeight = computed(() => (gaugeSvgHeight * props.value) / props.max)

    /**
     * gaugePositionY
     * ゲージのY位置を算出
     */
    const gaugePositionY = computed(
      () => gaugeSvgHeight - (gaugeSvgHeight * props.value) / props.max,
    )

    return {
      gaugeHeight,
      gaugePositionY,
    }
  },
  methods: {},
})
