import { computed, reactive } from '@vue/composition-api'
import { difference, now, orderBy, uniq, uniqBy } from 'lodash'
import dayjs from 'dayjs'
import sanitizeHtml from 'sanitize-html'
import FeedDataDocument, {
  FeedSourceType,
} from '@/store/stores/collectionModule/documents/feed/FeedDataDocument'
import usePlayer from '@/store/hook/usePlayer'
import useTeam from '@/store/hook/useTeam'
import I18n from '@/locales/I18n'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useSnsFeedData from '@/store/hook/useSnsFeedData'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import useDigitalTicketData from '@/store/hook/digitalTicket/useDigitalTicketData'
import useHighlightData from '@/store/hook/useHighlightData'
import useHighlightComment from '@/store/hook/useHighlightComment'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import HighlightCommentDocument from '@/store/stores/collectionModule/documents/highlight/HighlightCommentDocument'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'
import useUserRetrieveName from '@/store/hook/useUserRetrieveName'
import useChampionships from '@/store/hook/useChampionships'
import useRaces from '@/store/hook/useRaces'

/**
 * SNSタブ(DRIVER/TEAM/SF公式)の型
 */
export type SnsTabsType = 'driver' | 'team' | 'official'

/**
 * SNS投稿元の種別(Instagram / Twitter / SF公式サイト)
 */
export type SnsPostType = 'instagram' | 'twitter' | 'official' | 'highlights' | null

/**
 * SNS投稿画像の型
 */
export type SnsPostImage = {
  src: string
  type: 'image' | 'video'
}

/**
 * 新着ハイライトデータの型
 */
export type NewArrivalHighlightAdditionalType = {
  count: number | null
}

/**
 * 新着ハイライトデータの型
 */
export type NewArrivalHighlightDataType = {
  highlightCard: HighlightDocument | HighlightCommentDocument
  newArrivalAdditionalData: NewArrivalHighlightAdditionalType
}

/**
 * Highlightsコメント用データ型
 */
type highlightCommentDataType = {
  sceneTitle: string
  round: number
  raceDate: string
  circuit: string
  lap: number
  sector: number
}

/**
 * SNS投稿記事の型
 */
export type SnsPostData = {
  linkType: SnsPostType
  link: string
  thumbnail: string
  username: string
  time: string
  post: string
  like: number
  retweet: number
  images: SnsPostImage[]
  createdDate: number
  highlightComment: highlightCommentDataType | null
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoのHOME画面のStore
 */
class HomePageStore implements StoreBase {
  createStore() {
    /**
     * 何日前までのSNS投稿を取得するかを指定する
     */
    const retrieveDaysAgo = 30

    /**
     * HOME画面の状態
     */
    const homePageState = reactive({
      /**
       * SNS投稿表示中の選手
       */
      currentSnsPostDisplayPlayer: new PlayerDocument(),
      /**
       * SNS投稿表示中のチーム
       */
      currentSnsPostDisplayTeam: new TeamDocument(),
      /**
       * 現在選択しているSNSタブ
       */
      currentSnsTag: 'driver' as SnsTabsType,
      /**
       * HOME新着ハイライトカードに表示するハイライト、ハイライトコメント
       */
      newArrivalHighlightAndCommentData: [] as Array<NewArrivalHighlightDataType>,
    })

    const { fetchChampionshipsByIds, championships, clearChampionships } = useChampionships()
    const { fetchRacesByRaceIds, raceList, clearRaces } = useRaces()
    const {
      fetchRecentPublicHighlights,
      fetchHighlightsByIds,
      highlights,
      highlightsById,
      clearHighlight,
    } = useHighlightData()
    const {
      fetchRecentHighlightComments,
      fetchTargetRecentHighlightComments,
      highlightComments,
      commentsByHighlightId,
      clearHighlightComments,
    } = useHighlightComment()
    const { fetchRetrieveNameUsers, retrieveNameUsersByUserId, clearRetrieveNameUsers } =
      useUserRetrieveName()
    const { fetchSnsFeed, feedDataList, clearSnsFeedData } = useSnsFeedData()
    const { fetchPlayers, players, findPlayerById, clearPlayers } = usePlayer()
    const { fetchTeams, teams, findTeamById, clearTeams } = useTeam()
    const { fetchDigitalTickets, digitalTicketsByYear, clearDigitalTickets } =
      useDigitalTicketData()

    /**
     * HOME新着ハイライトカードに表示するハイライト、ハイライトコメント
     */
    const newArrivalHighlightAndCommentData = computed({
      get: (): Array<NewArrivalHighlightDataType> =>
        homePageState.newArrivalHighlightAndCommentData as Array<NewArrivalHighlightDataType>,
      set: (value: NewArrivalHighlightDataType[]) => {
        homePageState.newArrivalHighlightAndCommentData = value
      },
    })

    /**
     * 現在選択中のSNSタブ(DRIVER/TEAM/SF公式)
     */
    const currentSnsTab = computed({
      get: () => homePageState.currentSnsTag,
      set: (value: SnsTabsType) => {
        homePageState.currentSnsTag = value
      },
    })

    /**
     * 表示対象10件のHOME新着ハイライトに対して追加で必要なデータを取得する
     * 1. ハイライト情報
     * 2. ハイライトコメント数と直近コメント4件
     * 3. 新着ハイライトで利用するユーザー情報
     * 4. 表示するハイライト、ハイライトコメントに紐づくレース情報
     * 5. レース情報に紐づく大会情報
     */
    const fetchNewArrivalHighlightData = async () => {
      // カード表示候補のコメントデータのハイライトID一覧
      const highlightIdsOfPrevDisplayComments = highlightComments.value.map(
        (filteredComment) => (filteredComment as HighlightCommentDocument).eventId ?? '',
      )
      // 取得済みのハイライトID一覧
      const fetchedHighlightIds = highlights.value.map((highlight) => highlight.id ?? '')
      // ハイライトコメントカードで利用するハイライト情報のハイライトID
      const needToFetchHighlightIds = difference(
        highlightIdsOfPrevDisplayComments,
        fetchedHighlightIds,
      )

      /**
       * 1. 追加のハイライト情報を取得
       * カードに表示するハイライトコメントについてはハイライト情報が必要なため、ここでハイライトの取得処理を行う
       */
      const additionalHighlightResult = await Promise.all([
        fetchHighlightsByIds(needToFetchHighlightIds, false, true),
        fetchHighlightsByIds(needToFetchHighlightIds, true, true),
      ])
      // 表示対象のハイライトコメント
      // ハイライトが削除されているコメントは表示対象外とする
      // const commentsExistHighlight = highlightComments.value.filter((comment) =>
      //   highlights.value.some((highlight) => highlight.id === comment.highlightId),
      // )
      // HOME新着ハイライトカードに表示するハイライト、ハイライトコメント準備データ
      const newArrivalPrevData = orderBy(
        [
          ...highlights.value.filter(
            (highlight) => !needToFetchHighlightIds.includes(highlight.highlightId || ''),
          ),
          // TODO: ハイライトのみ新着するため、コメント化した
          //  新着ハイライト、ハイライトコメントを表示する場合はコメントアウトすれば良い
          // ...commentsExistHighlight,
        ],
        '_createdDate',
        'desc',
        // TODO: 新着ハイライト、ハイライトコメントを表示する場合は「as Array<HighlightDocument | HighlightCommentDocument>」を削除する
      ).slice(0, 10) as Array<HighlightDocument | HighlightCommentDocument>

      // カード表示対象のハイライトデータ
      const displayHighlights = newArrivalPrevData.filter(
        (highlightAndComment) => highlightAndComment instanceof HighlightDocument,
      )
      // カード表示対象のコメントデータ
      const displayComments = newArrivalPrevData.filter(
        (highlightAndComment) => highlightAndComment instanceof HighlightCommentDocument,
      )
      // カード表示対象のコメントデータのハイライトID一覧
      const highlightIdsOfDisplayComments = displayComments.map(
        (filteredComment) => (filteredComment as HighlightCommentDocument).eventId ?? '',
      )

      /**
       * 2. ハイライトコメント数と直近コメント4件（ハイライトカードに表示するコメント1件+その他新着コメント3件）
       */
      // コメントレスポンスデータ
      const otherCommentResults = [] as Array<Response<HighlightCommentDocument>>
      // ハイライトID毎に分類されたコメント件数
      const commentCountByHighlightId: Record<string, number> = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const highlightId of highlightIdsOfDisplayComments) {
        // eslint-disable-next-line no-await-in-loop
        const commentResult = await fetchTargetRecentHighlightComments(highlightId, true, 4)
        otherCommentResults.push(commentResult)

        if (commentResult.response?.data.count) {
          commentCountByHighlightId[highlightId] = commentResult.response?.data.count
        }
      }
      /**
       * HOME新着ハイライト用データにコメント件数をセットする
       * 表示カードのタイプ（ハイライトかハイライトコメントか）
       * コメント件数（ストアにコメント件数を保存していないため）
       */
      newArrivalHighlightAndCommentData.value = newArrivalPrevData.map((newArrival) => ({
        highlightCard: newArrival,
        newArrivalAdditionalData: {
          // ハイライトコメントカードの場合にコメント件数をセット
          count:
            newArrival instanceof HighlightCommentDocument
              ? commentCountByHighlightId?.[newArrival.highlightId ?? ''] ?? null
              : null,
        },
      }))

      /**
       * 3. 新着ハイライトで利用するユーザー情報を取得
       * - 新着ハイライト作成ユーザー
       * - 新着ハイライトコメント作成ユーザー
       * - 新着ハイライトコメントにコメントしている3人のユーザー（新しい順）
       * 4. 表示するハイライト、ハイライトコメントに紐づくのレース情報を取得
       */
      const userIds = uniq(
        [...displayHighlights, ...highlightComments.value].map((v) => v._createdBy ?? ''),
      )
      const raceIds = highlights.value.map((highlight) => highlight.matchId ?? '')
      const userAndRaceResults = await Promise.all([
        fetchRetrieveNameUsers(userIds),
        fetchRacesByRaceIds(raceIds),
      ])

      /**
       * 5. レース情報に紐づく大会情報を取得
       */
      const championshipMasterIds = raceList.value.map((race) => race.championshipMasterId ?? '')
      const championshipResult = await fetchChampionshipsByIds(championshipMasterIds)

      return [
        ...additionalHighlightResult,
        ...otherCommentResults,
        ...userAndRaceResults.flat(),
        championshipResult,
      ]
    }

    /**
     * HOME画面で必要なデータを取得する。
     */
    const fetchHomePageData = async () => {
      const results = await Promise.all([
        fetchRecentPublicHighlights(),
        fetchRecentHighlightComments(),
        fetchPlayers(),
        fetchTeams(),
        fetchDigitalTickets(),
      ])
      const failedResponse = results.find((response) => !response?.isSuccess)
      if (failedResponse) {
        return {
          isSuccess: false,
          response: failedResponse.response,
        }
      }

      // 上記で取得した直近のハイライトに紐づく、新着ハイライトで必要なデータを取得する
      const newArrivalHighlightResults = await fetchNewArrivalHighlightData()

      const newArrivalHighlightFailedResponse = newArrivalHighlightResults.find(
        (response) => !response.isSuccess,
      )
      if (newArrivalHighlightFailedResponse) {
        return {
          isSuccess: false,
          response: newArrivalHighlightFailedResponse.response,
        }
      }

      return {
        isSuccess: true,
      }
    }

    /**
     * 選手情報から同じ選手IDをもつ重複データを取り除く
     */
    const availablePlayers = computed(() =>
      uniqBy(players.value, 'playerId').filter((player) => player.position !== 'FW'),
    )
    /**
     * SNS投稿表示選手ID
     * return { PlayerDocument } SNS投稿表示中の選手情報
     */
    const snsPostDisplayPlayer = computed({
      get: (): PlayerDocument =>
        <PlayerDocument>(
          players.value.find((item) => item.sid === homePageState.currentSnsPostDisplayPlayer.sid)
        ),
      set: (player: PlayerDocument) => {
        homePageState.currentSnsPostDisplayPlayer = player || new PlayerDocument()
      },
    })

    /**
     * チーム情報から同じチームIDをもつ重複データを取り除く
     */
    const availableTeams = computed(() => uniqBy(teams.value, 'teamId'))
    /**
     * SNS投稿表示チームID
     * return { TeamDocument } SNS投稿表示中のチーム情報
     */
    const snsPostDisplayTeam = computed({
      get: (): TeamDocument =>
        <TeamDocument>(
          teams.value.find((item) => item.sid === homePageState.currentSnsPostDisplayTeam.sid)
        ),
      set: (team: TeamDocument) => {
        homePageState.currentSnsPostDisplayTeam = team || new TeamDocument()
      },
    })

    /**
     * 指定されたマスタIDのSNS投稿情報を取得する。
     * relatedDataIdには、以下の値を指定できる。
     * - 選手マスタ(PlayerDocument)のplayerId
     * - チームマスタ(TeamDocument)のteamId
     * - SF (SF公式アカウントの投稿した記事の場合、固定で設定される)
     *
     * @param relatedDataId マスタID、または 'SF'
     */
    const fetchHomePageSnsFeed = async (relatedDataId: string) =>
      fetchSnsFeed(relatedDataId, now() - retrieveDaysAgo * 24 * 60 * 60 * 1000, now())

    /**
     * フィード種別をlinkTypeに変換する。
     *
     * @param feedType フィード種別
     */
    const convertLinkType = (feedType: FeedSourceType): SnsPostType | null => {
      switch (feedType) {
        case 'Twitter':
          return 'twitter'
        case 'Instagram':
          return 'instagram'
        case 'SF_Official_Site':
          return 'official'
        default:
          return null
      }
    }

    /**
     * SNS/SF公式サイト記事データの投稿者のサムネイル画像のURLを取得する。
     *
     * @param feedData SNS/SF公式サイト記事データ
     */
    const getThumbnail = (feedData: FeedDataDocument) => {
      if (feedData.feedType === 'Driver') {
        const driver = findPlayerById(feedData.relatedDataId)
        if (driver) {
          return CloudFrontUtil.getSignedUrl(
            driver.playerPicture ?? 'assets/img/common/card/icon_player__white.svg',
          )
        }
        return null
      }
      if (feedData.feedType === 'Team') {
        const team = findTeamById(feedData.relatedDataId)
        if (team) {
          return CloudFrontUtil.getSignedUrl(
            team.teamLogo ?? 'assets/img/common/card/icon_team__null.svg',
          )
        }
        return null
      }
      if (feedData.feedType === 'SF_Official') {
        return 'assets/img/HomePage/icon_SF_Official.jpg'
      }
      return null
    }

    /**
     * SNS/SF公式サイト記事のメディアをSnsPostImageに変換する。
     *
     * @param feedData SNS/SF公式サイト記事データ
     */
    const convertSnsPostImages = (feedData: FeedDataDocument): Array<SnsPostImage> => {
      if (!feedData.medias) {
        return []
      }
      return feedData.medias
        .map(
          (media) =>
            ({
              src: media.previewImageUrl || media.url,
              type: media.type,
            } as SnsPostImage),
        )
        .filter((media) => !!media.src)
    }

    /**
     *
     * @param feedData
     */
    const getPostUserName = (feedData: FeedDataDocument) => {
      if (feedData.feedType === 'Driver') {
        const driver = findPlayerById(feedData.relatedDataId)
        if (driver) {
          return driver.getPlayerName()[I18n.locale]
        }
        return ''
      }
      if (feedData.feedType === 'Team') {
        const targetTeam = findTeamById(feedData.relatedDataId)
        if (targetTeam) {
          return targetTeam.teamName[I18n.locale]
        }
        return ''
      }
      if (feedData.feedType === 'SF_Official') {
        return 'SUPER FORMULA OFFICIAL'
      }
      return ''
    }

    /**
     * SNS/SF公式サイト記事データをHOME画面に表示可能な形式に変換する。
     * 変換後、記事の作成日時の降順にソートして返却する。
     */
    const getSnsFeedDataList = computed(
      (): Array<SnsPostData> =>
        orderBy(
          uniqBy(
            feedDataList.value
              .map(
                (feed) =>
                  ({
                    linkType: convertLinkType(feed.feedSource),
                    link: feed.feedUrl,
                    thumbnail: getThumbnail(feed) ?? '',
                    username: getPostUserName(feed),
                    time: dayjs(feed.createdDate).format('YYYY-MM-DD HH:mm') ?? '',
                    createdDate: feed.createdDate,
                    // 改行コードを <br>に変換し、サニタイジングする
                    post: sanitizeHtml(feed.normalizedContents.replace(/(\r\n|\r|\n)/g, '<br>')),
                    like: feed.favoriteCount,
                    retweet: feed.retweetCount,
                    images: convertSnsPostImages(feed),
                  } as SnsPostData),
              )
              .filter((postData) => !!postData.link && !!postData.thumbnail),
            'link',
          ),
          'createdDate',
          'desc',
        ),
    )

    /**
     * このストアのデータをクリアする。
     */
    const clearHomePageStore = () => {
      clearChampionships()
      clearRaces()
      clearHighlight()
      clearHighlightComments()
      clearRetrieveNameUsers()
      clearSnsFeedData()
      clearPlayers()
      clearTeams()
      clearDigitalTickets()
    }

    return {
      fetchHomePageData,
      newArrivalHighlightAndCommentData,
      availablePlayers,
      snsPostDisplayPlayer,
      availableTeams,
      snsPostDisplayTeam,
      fetchPlayers,
      fetchTeams,
      fetchHomePageSnsFeed,
      getSnsFeedDataList,
      clearHomePageStore,
      currentSnsTab,
      // useDigitalTicketData
      digitalTicketsByYear,
      highlightsById,
      championships,
      raceList,
      commentsByHighlightId,
      retrieveNameUsersByUserId,
    }
  }
}
const value: ValueType<HomePageStore> = {}

export default {
  createStore: new HomePageStore().createStore,
  value: value as Required<typeof value>,
}
