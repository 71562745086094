







import { defineComponent, ref, Ref, inject, onBeforeMount } from '@vue/composition-api'
import VueRouter from 'vue-router'
import { orderBy } from 'lodash'
import StandingsPlayerDetailsPane from '@/components/StandingsPage/StandingsPlayerDetailsPane.vue'
import StandingsRoundDataPane from '@/components/StandingsPage/StandingsRoundDataPane.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

/**
 * Standings: 詳細ページ
 */
export default defineComponent({
  name: 'StandingsDetailsPage',
  components: {
    StandingsPlayerDetailsPane,
    StandingsRoundDataPane,
  },
  props: {
    /**
     * 選手マスタID
     */
    playerMasterId: {
      type: String,
      required: true,
    },
  },
  inject: ['router'],
  setup(props) {
    const router = inject('router') as VueRouter
    const standingsPageStore = StoreUtil.useStore('StandingsPageStore')
    const { players } = standingsPageStore

    const targetPlayer: Ref<PlayerDocument | undefined> = ref(undefined)

    onBeforeMount(async () => {
      targetPlayer.value = orderBy(players.value, 'startDate', 'desc').find(
        (player) => player.id === props.playerMasterId,
      )

      if (!targetPlayer.value) {
        await MessageDialogStore.value.open({
          title: I18n.tc('StandingsPage.DetailsPage.errors.getDriverDataError.title'),
          message: I18n.tc('StandingsPage.DetailsPage.errors.getDriverDataError.message'),
        })
        await router.replace({ name: 'StandingsTopPage' })
      }
    })

    return {
      targetPlayer,
    }
  },
})
