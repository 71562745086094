











































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { MembersRankDataType } from '@/components/MypageTopPage/MembershipCardPane.vue'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'

/**
 * マイページ: 会員証 背景画像パーツ
 */
export default defineComponent({
  name: 'MembershipCardParts',
  components: {},
  props: {
    membersRankData: {
      type: Object as PropType<MembersRankDataType>,
      default: () => ({}),
      require: true,
    },
    isFreePlan: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { getMembersRankColor } = useMembershipCard()
    const membersColor = computed(() =>
      getMembersRankColor(props.membersRankData.currentRank, props.isFreePlan),
    )

    return {
      membersColor,
    }
  },
  methods: {},
})
