var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", {
    staticClass: "sns-post-loader-parts",
    domProps: { textContent: _vm._s(_vm.$tc("HomePage.loading")) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }