var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-init-section" },
    [
      _c("fieldset-email-parts", {
        attrs: { isReadOnly: true, required: false, showError: false },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("fieldset-password-parts", {
        attrs: { isReadOnly: true, required: false, showError: false },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("ValidationObserver", {
        ref: "validationObserver",
        staticClass: "user-init-section__validation-observer",
        attrs: { slim: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              var invalid = ref.invalid
              return [
                _c("fieldset-name-parts", {
                  attrs: {
                    valueFamily: _vm.familyName,
                    valueFirst: _vm.firstName,
                    required: true,
                    showError:
                      !!Object.assign({}, errors.familyName)[0] ||
                      !!Object.assign({}, errors.firstName)[0],
                    errorMessage:
                      Object.assign({}, errors.familyName)[0] ||
                      Object.assign({}, errors.firstName)[0],
                  },
                  on: {
                    inputfamilyName: function ($event) {
                      return _vm.inputValue($event, "familyName")
                    },
                    inputfirstName: function ($event) {
                      return _vm.inputValue($event, "firstName")
                    },
                  },
                }),
                _vm.userNameRubyEnabled
                  ? _c("fieldset-name-ruby-parts", {
                      attrs: {
                        valueFamily: _vm.familyNameKana,
                        valueFirst: _vm.firstNameKana,
                        required: true,
                        showError:
                          !!Object.assign({}, errors.familyNameKana)[0] ||
                          !!Object.assign({}, errors.firstNameKana)[0],
                        errorMessage:
                          Object.assign({}, errors.familyNameKana)[0] ||
                          Object.assign({}, errors.firstNameKana)[0],
                      },
                      on: {
                        inputfamilyNameKana: function ($event) {
                          return _vm.inputValue($event, "familyNameKana")
                        },
                        inputfirstNameKana: function ($event) {
                          return _vm.inputValue($event, "firstNameKana")
                        },
                      },
                    })
                  : _vm._e(),
                _c("ValidationProvider", {
                  attrs: { slim: "", name: "displayName", rules: "max:24" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("fieldset-display-name-parts", {
                              attrs: {
                                showError: errors.length > 0,
                                errorMessage: errors[0],
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inputValue(
                                    $event,
                                    "userDisplayName"
                                  )
                                },
                              },
                              model: {
                                value: _vm.userDisplayName,
                                callback: function ($$v) {
                                  _vm.userDisplayName = $$v
                                },
                                expression: "userDisplayName",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("ValidationProvider", {
                  attrs: {
                    slim: "",
                    name: "birthDay",
                    rules: "required|validBirthDay",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("fieldset-birthday-parts", {
                              attrs: {
                                openBirthdayMenuId: _vm.openBirthdayMenuId,
                                required: true,
                                showError: errors.length > 0,
                                errorMessage: errors[0],
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inputValue($event, "birthDay")
                                },
                                onClickBirthday: _vm.handleBirthdayClicked,
                                onClickCloseBirthday: function ($event) {
                                  return _vm.$emit("onClickCloseBirthday")
                                },
                              },
                              model: {
                                value: _vm.birthDay,
                                callback: function ($$v) {
                                  _vm.birthDay = $$v
                                },
                                expression: "birthDay",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("ValidationProvider", {
                  attrs: { slim: "", name: "gender", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("fieldset-gender-parts", {
                              attrs: {
                                required: true,
                                showError: errors.length > 0,
                                errorMessage: errors[0],
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inputValue($event, "gender")
                                },
                              },
                              model: {
                                value: _vm.gender,
                                callback: function ($$v) {
                                  _vm.gender = $$v
                                },
                                expression: "gender",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("ValidationProvider", {
                  attrs: { slim: "", name: "country", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("fieldset-country-parts", {
                              attrs: {
                                required: true,
                                showError: errors.length > 0,
                                errorMessage: errors[0],
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inputValue($event, "country")
                                },
                              },
                              model: {
                                value: _vm.country,
                                callback: function ($$v) {
                                  _vm.country = $$v
                                },
                                expression: "country",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm.showPrefecturesParts
                  ? _c("ValidationProvider", {
                      attrs: {
                        slim: "",
                        name: "prefectures",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("fieldset-prefectures-parts", {
                                  attrs: {
                                    required: true,
                                    showError: errors.length > 0,
                                    errorMessage: errors[0],
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputValue(
                                        $event,
                                        "prefectures"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.prefectures,
                                    callback: function ($$v) {
                                      _vm.prefectures = $$v
                                    },
                                    expression: "prefectures",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                _vm.showZipcodeParts
                  ? _c("ValidationProvider", {
                      attrs: {
                        slim: "",
                        name: "zipcode",
                        rules: "required|zipcodeFormat",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("fieldset-zipcode-parts", {
                                  attrs: {
                                    required: true,
                                    showError: errors.length > 0,
                                    errorMessage: errors[0],
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputValue($event, "zipcode")
                                    },
                                  },
                                  model: {
                                    value: _vm.zipcode,
                                    callback: function ($$v) {
                                      _vm.zipcode = $$v
                                    },
                                    expression: "zipcode",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                _c("fieldset-favorite-player-parts", {
                  attrs: {
                    required: true,
                    linkTo: "/mypage/init/favorite-player",
                    name: _vm.favoritePlayerName,
                    img: _vm.favoritePlayerImg,
                  },
                }),
                _c("fieldset-favorite-team-parts", {
                  attrs: {
                    required: true,
                    linkTo: "/mypage/init/favorite-team",
                    name: _vm.favoriteTeamName,
                    img: _vm.favoriteTeamImg,
                  },
                }),
                _c(
                  "action-buttons-section",
                  {
                    staticClass: "user-init-section__action-buttons",
                    attrs: { scrollTargetSelector: ".user-init-section" },
                  },
                  [
                    _c("atom-input-button", {
                      attrs: {
                        linkText: _vm.$tc("common.confirm"),
                        isDisabled: invalid || !_vm.isSelectedFavoriteItems,
                        colorType: "secondary",
                      },
                      on: { push: _vm.moveToConfirm },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }