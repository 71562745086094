


























import { defineComponent, Ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import UserInitConfirmSection from '@/components/MypageUserInitConfirmPage/MypageUserInitConfirmPane/UserInitConfirmSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import StoreUtil from '@/store/StoreUtil'
import { EditUserType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import useHistory from '@/store/hook/useHistory'

/**
 * 初期会員情報 確認画面ペインのコンポーネント
 */
export default defineComponent({
  name: 'MypageUserInitConfirmPane',
  components: {
    UserInitConfirmSection,
    ActionButtonsSection,
    AtomInputButton,
    AtomRouterButton,
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { saveMissionHistoryForProfile } = useHistory()

    return {
      cloneDeep,
      editUserInfo: mypagePageStore.editUserInfo as Ref<EditUserType>,
      favoritePlayer: mypagePageStore.savingFavoritePlayer as Ref<PlayerDocument>,
      favoriteTeam: mypagePageStore.savingFavoriteTeam as Ref<TeamDocument>,
      confirmUser: mypagePageStore.confirmUser,
      saveUser: mypagePageStore.saveUser,
      saveFavoritePlayer: mypagePageStore.saveFavoritePlayer,
      saveFavoriteTeam: mypagePageStore.saveFavoriteTeam,
      ownOrganization: contractInfoStore.ownOrganization,
      fetchOrganizations: contractInfoStore.fetchOrganizations,
      updateOrganization: mypagePageStore.updateOrganization,
      saveMissionHistoryForProfile,
    }
  },
  methods: {
    /**
     * 会員情報保存処理
     */
    async saveUserInfo() {
      const loader = this.$loading.show()

      /** 会員情報保存 */
      this.confirmUser(
        this.editUserInfo.familyName as string,
        this.editUserInfo.firstName as string,
        this.editUserInfo.familyNameKana as string,
        this.editUserInfo.firstNameKana as string,
        this.editUserInfo.userDisplayName as string,
        this.editUserInfo.birthDay as string,
        this.editUserInfo.gender as string,
        this.editUserInfo.country as string,
        this.editUserInfo.prefecture as string,
        this.editUserInfo.zipcode as string,
      )
      const saveUserResult = await this.saveUser()
      if (!saveUserResult.isSuccess) {
        // API通信不具合
        MessageDialogStore.value.open({
          title: this.$t('MypagePage.MypageUserConfirmPage.errors.title') as string,
          message: this.$t('MypagePage.MypageUserConfirmPage.errors.message') as string,
          errorApiResponse: saveUserResult?.response,
        })
        loader.hide()
        return
      }

      if (saveUserResult.data) this.saveMissionHistoryForProfile(saveUserResult.data)

      /** お気に入り選手保存 */
      const saveFavoritePlayerResult = await this.saveFavoritePlayer(this.favoritePlayer.sid)
      if (!saveFavoritePlayerResult.isSuccess) {
        loader.hide()
        // API通信不具合
        await MessageDialogStore.value.open({
          title: this.$t('MypagePage.MypageFavoritePlayerSelectPage.errors.title') as string,
          message: this.$t('MypagePage.MypageFavoritePlayerSelectPage.errors.message') as string,
          errorApiResponse: saveFavoritePlayerResult.response,
        })
        return
      }

      /** お気に入りチーム保存 */
      const saveFavoriteTeamResult = await this.saveFavoriteTeam(this.favoriteTeam.sid)
      if (!saveFavoriteTeamResult.isSuccess) {
        loader.hide()
        // API通信不具合
        await MessageDialogStore.value.open({
          title: this.$t('MypagePage.MypageFavoriteTeamSelectPage.errors.title') as string,
          message: this.$t('MypagePage.MypageFavoriteTeamSelectPage.errors.message') as string,
          errorApiResponse: saveFavoriteTeamResult.response,
        })
        return
      }

      /** 組織情報更新 */
      const newOrgData = this.cloneDeep(this.ownOrganization) as OrganizationDocument
      // フォームに入力した姓名をorganizationNameにセットする
      newOrgData.organizationName = `${this.editUserInfo.familyName} ${this.editUserInfo.firstName}`
      const updateOrganizationResult = await this.updateOrganization(newOrgData)
      if (!updateOrganizationResult.isSuccess) {
        loader.hide()
        // API通信不具合
        await MessageDialogStore.value.open({
          errorApiResponse: updateOrganizationResult.response,
        })
        return
      }

      await this.fetchOrganizations()

      // グローバルストアに保持するユーザー情報を更新
      await UserStore.value.fetchUserData(LoginStore.value.loginId)

      loader.hide()

      this.$emit('successRegist')
    },
  },
})
