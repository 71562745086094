import Vue from 'vue'
import VueHead from 'vue-head'
import router from '@/router/pc'
import main from '@/main-common'
import DeviceInfo from '@/util/DeviceInfo'
import '@/plugins/vue-youtube'

DeviceInfo.PLATFORM = 'pc'
Vue.use(VueHead)
main(router)
