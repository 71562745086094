







import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'

/**
 * パスワードリセット ページ
 */
export default defineComponent({
  name: 'PasswordResetPage',
  components: {
    HeaderPane,
  },
  methods: {},
})
