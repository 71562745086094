





































































import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import PageTitleSection from '@/components/common/Header/HeaderPane/PageTitleSection.vue'
import GlobalNavigationSection from '@/components/pc/common/GlobalNavigation/GlobalNavigationSection.vue'
import NavigationNotificationIconSection from '@/components/common/GlobalNavigation/GlobalNavigationPane/NavigationNotificationIconSection.vue'

/**
 * 共通ヘッダーペイン
 */
export default defineComponent({
  name: 'HeaderPane',
  components: {
    PageTitleSection,
    GlobalNavigationSection,
    NavigationNotificationIconSection,
  },
  props: {
    /**
     * ページタイトル
     */
    pageTitle: {
      type: String,
      default: '',
    },
    /**
     * カラータイプ
     */
    colorType: {
      type: String,
      default: '',
    },
    /**
     * ヘッダーグローバルナビ有無
     */
    hasHeaderNav: {
      type: Boolean,
      default: false,
    },
    /**
     * ナビゲーションカレント指定
     */
    navigationCurrent: {
      type: String,
      default: 'mypage',
    },
    /**
     * アプリ内課金購入処理実行中かどうか
     */
    isPurchasingInAppPurchase: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const visibleNotification = !DeviceInfo.isForPC()

    return {
      visibleNotification,
    }
  },
})
