<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="switch-icon" viewBox="0 0 18 19" fill="none">
    <path
      d="M4.75734 5.25729L3.16635 3.6663L3.16635 7.90894L7.40899 7.90894L5.818 6.31795C7.57339 4.56255 10.4266 4.56255 12.182 6.31795C12.7176 6.85358 13.0941 7.49528 13.2957 8.1741L14.8442 8.1741C14.6056 7.10814 14.0752 6.0899 13.2426 5.25729C10.8986 2.91323 7.1014 2.91323 4.75734 5.25729ZM12.182 12.6819C10.4266 14.4373 7.57339 14.4373 5.818 12.6819C5.28236 12.1463 4.90583 11.5046 4.7043 10.8258L3.15574 10.8258C3.39439 11.8917 3.92472 12.9099 4.75734 13.7426C7.1014 16.0866 10.8986 16.0866 13.2426 13.7426L14.8336 15.3336L14.8336 11.0909L10.591 11.0909L12.182 12.6819Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SwitchIcon',
})
</script>

<style scoped lang="scss">
.switch-icon {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
