

























































import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import I18n from '@/locales/I18n'
import FieldsetTextCreditParts from '@/components/common/form/FieldsetTextCreditParts.vue'
import FieldsetTextParts from '@/components/common/form/FieldsetTextParts.vue'
import type { StripeInputErrors } from '@/components/hook/useStripe'
import StoreUtil from '@/store/StoreUtil'

/**
 * 決済: クレジット入力セクション
 */
export default defineComponent({
  name: 'CreditCardInputSection',
  components: {
    FieldsetTextCreditParts,
    FieldsetTextParts,
  },
  props: {
    sectionTitle: {
      type: String,
      default: '',
    },
    /**
     * クライアントシークレット
     */
    clientSecret: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { stripe } = signupPageStore

    const validationProvider = ref<InstanceType<typeof ValidationObserver>>()
    const fieldsetTextNumberPartsNumber = ref<InstanceType<typeof FieldsetTextCreditParts>>()
    const fieldsetTextNumberPartsExpiry = ref<InstanceType<typeof FieldsetTextCreditParts>>()
    const fieldsetTextNumberPartsCvc = ref<InstanceType<typeof FieldsetTextCreditParts>>()

    onMounted(() => {
      stripe.destroy()
      stripe.prepare(
        fieldsetTextNumberPartsNumber.value?.numberElement,
        fieldsetTextNumberPartsExpiry.value?.expiryElement,
        fieldsetTextNumberPartsCvc.value?.cvcElement,
        props.clientSecret,
      )
    })

    /**
     * カード名義入力
     * @param val
     */
    const inputCardName = (val: string) => {
      // カード名義をストアに保存
      stripe.setCardName(val)
    }
    /**
     * カード入力時のエラーメッセージを返す
     * @param error
     */
    const creditErrorMessage = (error: StripeInputErrors) => error?.message || ''

    /**
     * 「確認画面へ」ボタンの活性/非活性を判定するための情報を検知し、親コンポーネントに伝える
     */
    watch(
      () => [
        stripe.state.isBlank,
        stripe.state.cardName,
        stripe.state.errors,
        validationProvider.value?.errors,
      ],
      (newValues) => {
        /** カード番号/有効期限/CVCのブランク状態 */
        const newCardElementBlank = newValues[0] as {
          number: boolean
          expiry: boolean
          cvc: boolean
        }
        /** カード名義の入力情報 */
        const newCardName = newValues[1] as string
        /** カード番号/有効期限/CVCのエラー情報 */
        const newCardElementErrors = newValues[2] as {
          cardNumber: StripeInputErrors | undefined
          cardExpiry: StripeInputErrors | undefined
          cardCvc: StripeInputErrors | undefined
        }
        /** カード名義のエラー情報 */
        const newCardNameErrors = newValues[3] as Record<string, string[]> | undefined

        /** 以下1、2に該当する場合、「確認画面へ」ボタン非活性 */
        let isDisabled = false
        if (
          newCardElementBlank.number ||
          newCardElementBlank.expiry ||
          newCardElementBlank.cvc ||
          !newCardName
        ) {
          /** 1. カード番号/有効期限/CVC、カード名義がブランクの場合 */
          isDisabled = true
        }
        if (
          !!newCardElementErrors.cardNumber ||
          !!newCardElementErrors.cardExpiry ||
          !!newCardElementErrors.cardCvc ||
          (Array.isArray(newCardNameErrors) && Number(newCardNameErrors.length) > 0)
        ) {
          /** 2. カード番号/有効期限/CVC、カード名義にエラーがある場合 */
          isDisabled = true
        }

        context.emit('handlerCardNameErrors', isDisabled)
      },
      { deep: true, immediate: true },
    )
    /**
     * 言語設定の変更を検知し、Stripe Element を初期化する
     */
    watch(
      () => I18n.locale,
      () => {
        stripe.destroy()
        stripe.prepare(
          fieldsetTextNumberPartsNumber.value?.numberElement,
          fieldsetTextNumberPartsExpiry.value?.expiryElement,
          fieldsetTextNumberPartsCvc.value?.cvcElement,
          props.clientSecret,
        )
      },
    )

    return {
      stripe,
      validationProvider,
      fieldsetTextNumberPartsNumber,
      fieldsetTextNumberPartsExpiry,
      fieldsetTextNumberPartsCvc,
      creditErrorMessage,
      inputCardName,
    }
  },
})
