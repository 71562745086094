import { render, staticRenderFns } from "./ReferralCodePane.vue?vue&type=template&id=49e7de66&scoped=true"
import script from "./ReferralCodePane.vue?vue&type=script&lang=ts"
export * from "./ReferralCodePane.vue?vue&type=script&lang=ts"
import style0 from "./ReferralCodePane.vue?vue&type=style&index=0&id=49e7de66&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e7de66",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49e7de66')) {
      api.createRecord('49e7de66', component.options)
    } else {
      api.reload('49e7de66', component.options)
    }
    module.hot.accept("./ReferralCodePane.vue?vue&type=template&id=49e7de66&scoped=true", function () {
      api.rerender('49e7de66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MypageReferralCodePage/ReferralCodePane.vue"
export default component.exports