




























import { defineComponent, PropType } from '@vue/composition-api'
import MypageTicketToggleIconParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypageTicketToggleIconParts.vue'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'

export default defineComponent({
  name: 'MypageTicketTodayCardSection',
  components: {
    MypageTicketToggleIconParts,
  },
  props: {
    activeCardId: {
      type: String,
      default: '',
    },
    ticketType: {
      type: Object as PropType<DigitalTicketDataTicketTypeDocument>,
      required: true,
    },
  },
  setup(props, { emit }) {
    /**
     * カードの開閉が押下されたときの処理
     */
    const handleToggleClicked = () => {
      emit('onClickToggle', props.ticketType.ticketTypeId)
    }
    return {
      handleToggleClicked,
    }
  },
})
