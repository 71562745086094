var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overlay-controller-parts",
      class: [
        "overlay-controller-parts--" + _vm.screenOrientationType,
        { "overlay-controller-parts--is-pc": _vm.isPc },
      ],
    },
    [
      _vm.hasRaceInfo && !_vm.isLiveNotStarted
        ? _c("seek-bar-parts", {
            staticClass: "overlay-controller-parts__seek-bar",
            on: {
              handleTouchStartSeekBar: _vm.handleTouchStartSeekBar,
              handleTouchEndSeekBar: _vm.handleTouchEndSeekBar,
            },
            model: {
              value: _vm.videoTimesData,
              callback: function ($$v) {
                _vm.videoTimesData = $$v
              },
              expression: "videoTimesData",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "overlay-controller-parts__inner" }, [
        _c(
          "div",
          { staticClass: "overlay-controller-parts__switch" },
          [
            _c("view-angle-toggle-switcher-parts", {
              staticClass: "overlay-controller-parts__angle-switcher",
              style: {
                visibility: _vm.hasRaceInfo,
              },
              attrs: {
                viewAngle: _vm.viewAngleStatus,
                mapViewEnabled: _vm.mapViewEnabled,
                angleSwitchEnabled: _vm.canAngleChange,
                screenOrientationType: _vm.screenOrientationType,
              },
              on: {
                viwAngleChange: _vm.toggleViewAngle,
                viewMapMode: _vm.toggleMapMode,
              },
            }),
            _vm.hasVolumeControl
              ? _c("volume-controller-parts", {
                  staticClass: "overlay-controller-parts__volume-controller",
                  on: {
                    handleTouchEndSeekBar: _vm.handleTouchEndVolumeSeekBar,
                    handleVolumeMute: _vm.handleVolumeMute,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLiveNotStarted,
                expression: "!isLiveNotStarted",
              },
            ],
            staticClass: "overlay-controller-parts__buttons",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "overlay-controller-parts__button overlay-controller-parts__button__prev30s",
                on: { click: _vm.prevVideo30s },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/video_controller_prev30s.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "overlay-controller-parts__button overlay-controller-parts__button__prev5s",
                on: { click: _vm.prevVideo5s },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/video_controller_prev5s.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _vm.videoPausedStatus
              ? _c(
                  "button",
                  {
                    staticClass:
                      "overlay-controller-parts__button overlay-controller-parts__button__play",
                    on: { click: _vm.playVideo },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/video_controller_play.svg"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            !_vm.videoPausedStatus
              ? _c(
                  "button",
                  {
                    staticClass:
                      "overlay-controller-parts__button overlay-controller-parts__button__pause",
                    on: { click: _vm.pauseVideo },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/video_controller_pause.svg"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass:
                  "overlay-controller-parts__button overlay-controller-parts__button__next5s",
                on: { click: _vm.nextVideo5s },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/video_controller_next5s.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "overlay-controller-parts__button overlay-controller-parts__button__next30s",
                on: { click: _vm.nextVideo30s },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/video_controller_next30s.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "toggle-button-parts",
              {
                staticClass:
                  "overlay-controller-parts__button overlay-controller-parts__button__slow",
                attrs: { enabled: _vm.videoSlowStatus },
                on: { toggle: _vm.slowVideo },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M17 12L10 8V16L17 12Z",
                        fill: "white",
                        "fill-opacity": "0.7",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M18 19L15 19L15 21L18 21L18 19ZM20.5788 18.5515L20.8766 20.6359C22.4091 20.015 23.5638 18.6561 23.9 17L21.8293 17C21.6001 17.6485 21.1543 18.1946 20.5788 18.5515ZM22 14L24 14L24 10L22 10L22 14ZM20.8766 3.36408C22.4091 3.98505 23.5638 5.34391 23.9 7L21.8293 7C21.6001 6.35154 21.1543 5.8054 20.5788 5.44854L20.8766 3.36408ZM15 3L18 3L18 5L15 5L15 3Z",
                        fill: "white",
                        "fill-opacity": "0.7",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M12 3L5 3C2.23858 3 -3.91405e-07 5.23858 -8.74228e-07 8L-2.27299e-06 16C-2.75581e-06 18.7614 2.23857 21 5 21L12 21L12 19L5 19C3.34314 19 2 17.6569 2 16L2 8C2 6.34315 3.34315 5 5 5L12 5L12 3Z",
                        fill: "white",
                        "fill-opacity": "0.7",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "overlay-controller-parts__view-tool",
            class: { "overlay-controller-parts__view-tool--pc": _vm.isPc },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLiveNotStarted,
                    expression: "!isLiveNotStarted",
                  },
                ],
                staticClass: "overlay-controller-parts__view-mode",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "overlay-controller-parts__button overlay-controller-parts__button__full-scale",
                    on: { click: _vm.changeFullScaleVideo },
                  },
                  [
                    !_vm.videoFullScaleStatus
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon/video_controller_fullscale.svg"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon/video_controller_un_fullscale.svg"),
                            alt: "",
                          },
                        }),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "overlay-controller-parts__button overlay-controller-parts__button__fullscreen",
                    on: { click: _vm.changeFullscreen },
                  },
                  [
                    _vm.screenOrientationType.indexOf("portrait") !== -1 ||
                    (_vm.lockOrientation !== undefined &&
                      _vm.viewContentsMode !== "single")
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon/video_controller_fullscreen.svg"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon/video_controller_un_fullscreen.svg"),
                            alt: "",
                          },
                        }),
                  ]
                ),
                _c("live-icon-parts", {
                  staticClass: "overlay-controller-parts__live",
                  attrs: { isLive: _vm.liveEnabled },
                  on: { onLiveClicked: _vm.onLiveClicked },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "overlay-controller-parts__view-small" },
          [
            _c(
              "button",
              {
                staticClass:
                  "overlay-controller-parts__button overlay-controller-parts__button__fullscreen",
                on: { click: _vm.changeFullscreen },
              },
              [
                _vm.screenOrientationType.indexOf("portrait") !== -1
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/video_controller_fullscreen.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/video_controller_un_fullscreen.svg"),
                        alt: "",
                      },
                    }),
              ]
            ),
            _c("live-icon-parts", {
              staticClass: "overlay-controller-parts__live",
              attrs: { isLive: _vm.liveEnabled },
              on: { onLiveClicked: _vm.onLiveClicked },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }