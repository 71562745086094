import dayjs from 'dayjs'
import ContractPlanDocument, {
  PlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import type { ContractTermType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 契約期間取得処理のHook
 */
export default function useContractTerm() {
  /**
   * 契約更新予定日 or 契約更新日を取得する。
   */
  const getReferenceDate = (contractStartDate: number) => {
    // 現在の年を取得
    const currentYear = dayjs().year().toString()
    // 現在の月を取得
    const currentMonth = (dayjs().month() + 1).toString().padStart(2, '0')
    // 契約した日にち以下を取得
    const contractStartDay = dayjs(contractStartDate).format('DD HH:mm:ss').toString()

    return dayjs(`${currentYear}-${currentMonth}-${contractStartDay}`).valueOf()
  }

  /**
   * 現在の契約期間を取得する。
   * @param effectiveContractPlan 有効なプランのプランマスタ情報
   * @param effectiveOrgContract 有効なプラン情報
   * @return {contractStartDate: number, contractEndDate: number}
   */
  const getCurrentContractTerm = (
    effectiveContractPlan: ContractPlanDocument,
    effectiveOrgContract: ContractType,
  ): ContractTermType => {
    /**
     * 月払いプランの場合
     * 契約開始日、契約終了日を計算して返却する
     */
    if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
      // 現在時刻を取得
      const now = dayjs().valueOf()
      // 基準日（契約更新予定日 or 契約更新日）を取得
      const referenceDate = getReferenceDate(effectiveOrgContract?.startDate || 0)

      // 今月既に更新済み場合
      if (referenceDate < now) {
        return {
          // 開始日：契約更新日
          contractStartDate: referenceDate,
          /**
           * 終了日：契約更新日+1ヶ月-1日の23:59:59
           * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDateを優先的に表示する
           */
          contractEndDate: !effectiveOrgContract?.endDate
            ? dayjs(referenceDate).add(1, 'M').subtract(1, 'd').endOf('day').valueOf()
            : effectiveOrgContract?.endDate,
        }
      }

      // まだ更新していない場合
      return {
        // 開始日：更新予定日-1ヶ月
        contractStartDate: dayjs(referenceDate).subtract(1, 'M').valueOf(),
        /**
         * 終了日：契約更新予定日-1日の23:59:59
         * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDateを優先的に表示する
         */
        contractEndDate: !effectiveOrgContract?.endDate
          ? dayjs(referenceDate).subtract(1, 'd').endOf('day').valueOf()
          : effectiveOrgContract?.endDate,
      }
    }

    /**
     * 年払いプラン、クーポンプラン、トライアルプランの場合
     * 契約情報の開始日、終了日を表示する
     */
    return {
      contractStartDate: effectiveOrgContract?.startDate,
      contractEndDate: effectiveOrgContract?.endDate,
    }
  }

  /**
   * 契約予定の契約期間を取得する。
   * @param effectiveContractPlan 有効なプランのプランマスタ情報
   * @param effectiveOrgContract 有効なプラン情報
   * @param selectedPlanGroupId 選択したプランのプラングループID
   * @return {contractStartDate: number, contractEndDate: number}
   */
  const getScheduledContractTerm = (
    effectiveContractPlan: ContractPlanDocument,
    effectiveOrgContract: ContractType,
    selectedPlanGroupId: PlanGroupType,
  ): ContractTermType => {
    let newContractStartDate = 0
    let newContractEndDate = 0
    /**
     * 月払いプラン → 新プランに更新する場合
     * 1. 年払いプランへの更新
     * 2. 無料プランへの移行
     * 契約開始日、契約終了日を計算して返却する
     */
    if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
      // 現在時刻を取得
      const now = dayjs().valueOf()
      // 基準日（契約更新予定日 or 契約更新日）を取得
      const referenceDate = getReferenceDate(effectiveOrgContract?.startDate || 0)

      // 今月既に更新済み場合
      if (referenceDate < now) {
        /**
         * 新プラン契約開始日：契約更新日+1ヶ月の00:00:00
         * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDate+1ミリ秒を優先的に表示する
         */
        newContractStartDate = !effectiveOrgContract?.endDate
          ? dayjs(referenceDate).add(1, 'M').startOf('day').valueOf()
          : (effectiveOrgContract?.endDate ?? 0) + 1

        if (selectedPlanGroupId === 'annualPlan') {
          // 年払いプランの場合の終了日：新プラン契約開始日+1年-1日の23:59:59
          newContractEndDate = dayjs(newContractStartDate)
            .add(1, 'y')
            .subtract(1, 'd')
            .endOf('day')
            .valueOf()
        }
        return {
          contractStartDate: newContractStartDate,
          contractEndDate: newContractEndDate,
        }
      }

      // まだ更新していない場合
      if (selectedPlanGroupId === 'annualPlan') {
        // 年払いプランの場合の終了日：契約更新日（=新プラン契約開始日）+1年-1日の23:59:59
        newContractEndDate = dayjs(referenceDate)
          .add(1, 'y')
          .subtract(1, 'd')
          .endOf('day')
          .valueOf()
      }
      return {
        /**
         * 新プラン契約開始日：契約更新日の00:00:00
         * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDate+1ミリ秒を優先的に表示する
         */
        contractStartDate: !effectiveOrgContract?.endDate
          ? dayjs(referenceDate).startOf('day').valueOf()
          : (effectiveOrgContract?.endDate ?? 0) + 1,
        contractEndDate: newContractEndDate,
      }
    }

    /**
     * 年払いプラン、クーポンプラン、トライアルプラン→ 新プランに更新する場合
     */
    // 開始日：契約終了日+1ミリ秒
    newContractStartDate = effectiveOrgContract?.endDate ? effectiveOrgContract.endDate + 1 : 0
    if (selectedPlanGroupId === 'annualPlan') {
      // 年払いプランの場合の終了日：新プラン契約開始日+1年-1日の23:59:59
      newContractEndDate = dayjs(newContractStartDate)
        .add(1, 'y')
        .subtract(1, 'd')
        .endOf('day')
        .valueOf()
    }
    if (selectedPlanGroupId === 'monthlyPlan') {
      // 月払いプランの場合の終了日：新プラン契約開始日+1ヶ月-1日の23:59:59
      newContractEndDate = dayjs(newContractStartDate)
        .add(1, 'M')
        .subtract(1, 'd')
        .endOf('day')
        .valueOf()
    }
    return {
      contractStartDate: newContractStartDate,
      contractEndDate: newContractEndDate,
    }
  }

  return {
    getReferenceDate,
    getCurrentContractTerm,
    getScheduledContractTerm,
  }
}
