














import { defineComponent } from '@vue/composition-api'
/**
 * マイページ会員情報編集画面ヘッダーセクション
 */
export default defineComponent({
  name: 'MypageUserEditHeaderSection',
  props: {
    /**
     * ページタイトル
     */
    pageTitle: {
      type: String,
      default: '',
    },
    /**
     * ページタイトル
     */
    hasBackLink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    pageBack() {
      /**
       * 戻るボタン
       */
      this.$emit('page-back')
    },
  },
})
