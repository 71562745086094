var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-current-plan-section" }, [
    _c("h4", { staticClass: "mypage-current-plan-section__title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$tc("MypagePage.MypageContract.detailsPage.currentPlan")) +
          " "
      ),
    ]),
    _c("div", { staticClass: "mypage-current-plan-section__plan" }, [
      _vm._v(" " + _vm._s(_vm.currentPlanName) + " "),
      _vm.contractTerm
        ? _c(
            "p",
            { staticClass: "mypage-current-plan-section__coupon-period" },
            [_vm._v(" " + _vm._s(_vm.couponExpirationDateText) + " ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }