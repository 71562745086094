

















































































































import { defineComponent, onMounted, PropType, reactive, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import DeviceInfo from '@/util/DeviceInfo'
import AtomDropdown, { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'
import I18n from '@/locales/I18n'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

export type OpenBirthdayMenuIdType = 'year' | 'month' | 'date' | ''

/**
 * フィールドセット 誕生日入力 バーツ
 */
export default defineComponent({
  name: 'FieldsetBirthdayParts',
  components: {
    AtomDropdown,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
    openBirthdayMenuId: {
      type: String as PropType<OpenBirthdayMenuIdType>,
      default: '',
    },
  },
  setup(props, context) {
    const { getDisplayMonth } = useDisplayDependingOnLang()

    /**
     * 登録されている生年月日
     * 生年月日未登録の場合はデフォルト値を返す
     */
    const year = props.value ? String(dayjs(props.value).year()) : ''
    const month = props.value ? String(dayjs(props.value).month() + 1) : ''
    const date = props.value ? String(dayjs(props.value).date()) : ''

    /** 画面に表示する月 */
    const displayMonthName = (targetMonth: string) => {
      if (!targetMonth) {
        return ''
      }
      return I18n.locale === 'ja'
        ? targetMonth.padStart(2, '0')
        : // 「1、2などを渡すと」iOSの場合にMMM、MMMM形式のフォーマットで正しく変換されないため、適当な年と日を付与している
          getDisplayMonth(`1980-${targetMonth.padStart(2, '0')}-01`)
    }
    const state = reactive({
      year: { id: year, name: year } as DropDownDataType,
      month: { id: month, name: displayMonthName(month) } as DropDownDataType,
      date: { id: date, name: date ? date.padStart(2, '0') : '' } as DropDownDataType,
    })

    const yearOptions = []
    const monthOptions = []
    const dateOptions = []
    for (let i = 1920; i <= new Date().getFullYear() - 3; i += 1) {
      yearOptions.push({ id: `${i}`, name: `${i}` })
    }
    for (let i = 1; i <= 12; i += 1) {
      monthOptions.push({ id: `${i}`, name: displayMonthName(String(i)) })
    }
    for (let i = 1; i <= 31; i += 1) {
      dateOptions.push({ id: `${i}`, name: String(i).padStart(2, '0') })
    }

    /**
     * 誕生日入力フォームの変更が可能かどうか
     * ブラウザの場合にisDisabled: trueにする
     */
    const isDisabled = ref(!DeviceInfo.isCordova())

    const closeOptionMenu = () => {
      context.emit('onClickCloseBirthday')
    }

    const handleCurrentClicked = (menuId: string) => {
      context.emit('onClickBirthday', menuId)
    }

    const handleOptionClicked = (option: DropDownDataType) => {
      if (props.openBirthdayMenuId === 'year') {
        state.year = option
      }
      if (props.openBirthdayMenuId === 'month') {
        state.month = option
      }
      if (props.openBirthdayMenuId === 'date') {
        state.date = option
      }
      if (state.year.id && state.month.id && state.date.id) {
        // 言語設定英語の場合にmonth.nameがJan、Febなどになるため、01、02などに変換して登録するデータ値となるようにしている
        context.emit(
          'input',
          `${state.year.name}/${String(state.month.id).padStart(2, '0')}/${state.date.name}`,
        )
      }
      closeOptionMenu()
    }

    onMounted(async () => {
      /**
       * Chromeのブラウザだと誕生日入力フォームが認証情報と誤認されてしまう。
       * そのため、DOM生成時はdisabled状態とすることでChromeに認識させないようにし、0.5秒後に入力可能な状態にすることで対処している。
       */
      await new Promise((resolve) => {
        setTimeout(resolve, 500)
      })
      isDisabled.value = false
    })

    return {
      I18n,
      state,
      isDisabled,
      yearOptions,
      monthOptions,
      dateOptions,
      handleCurrentClicked,
      handleOptionClicked,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event enter
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
