var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlight-preview-video-player-section",
      class: [
        "highlight-preview-video-player-section--" + _vm.screenOrientationType,
      ],
    },
    [
      _c("video", {
        staticClass: "video-js vjs-live vjs-liveui vjs-default-skin",
        attrs: {
          id: "sfgo-highlight-preview-video",
          crossorigin: "use-credentials",
          playsinline: "",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }