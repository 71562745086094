




import { defineComponent } from '@vue/composition-api'

/**
 * 共通 オーバーレイタイトル テンプレート
 */
export default defineComponent({
  name: 'OverlayTitleParts',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
})
