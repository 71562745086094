var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-race-video-page", {
    attrs: {
      lockOrientation: _vm.lockOrientation,
      championshipMasterId: _vm.championshipMasterId,
      raceId: _vm.raceId,
      highlightId: _vm.highlightId,
      movieStartActualTime: _vm.movieStartActualTime,
      driverId: _vm.driverId,
      hasSubVideo: true,
      hasVolumeControl: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }