











import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AtomButton',
  props: {
    /**
     * リンクの文字
     */
    linkText: {
      type: String,
      required: true,
    },
    /**
     * ボタンを非活性にするかどうか
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * テーマカラー指定
     */
    theme: {
      type: String,
      default: 'light',
    },
    /**
     * カラー指定
     */
    colorType: {
      type: String,
      default: 'primary',
    },
    /**
     * サイズ指定
     */
    size: {
      type: String,
      default: 'large',
    },
  },
  setup(_, { emit }) {
    const handleClicked = () => {
      emit('onClick')
    }
    return {
      handleClicked,
    }
  },
})
