var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-init-confirm-section" },
    [
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.email") },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.password"), isMask: true },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: {
          label: _vm.$tc("formParts.label.name"),
          value: _vm.editUserInfo.familyName + " " + _vm.editUserInfo.firstName,
        },
      }),
      _vm.userNameRubyEnabled
        ? _c("fieldset-confirm-parts", {
            attrs: {
              label: _vm.$tc("formParts.label.ruby"),
              value:
                _vm.editUserInfo.familyNameKana +
                " " +
                _vm.editUserInfo.firstNameKana,
            },
          })
        : _vm._e(),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.displayName") },
        model: {
          value: _vm.editUserInfo.userDisplayName,
          callback: function ($$v) {
            _vm.$set(_vm.editUserInfo, "userDisplayName", $$v)
          },
          expression: "editUserInfo.userDisplayName",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.birthDay") },
        model: {
          value: _vm.displayBirthDay,
          callback: function ($$v) {
            _vm.displayBirthDay = $$v
          },
          expression: "displayBirthDay",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.gender") },
        model: {
          value: _vm.gender[_vm.$i18n.locale],
          callback: function ($$v) {
            _vm.$set(_vm.gender, _vm.$i18n.locale, $$v)
          },
          expression: "gender[$i18n.locale]",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.country") },
        model: {
          value: _vm.country[_vm.$i18n.locale],
          callback: function ($$v) {
            _vm.$set(_vm.country, _vm.$i18n.locale, $$v)
          },
          expression: "country[$i18n.locale]",
        },
      }),
      _vm.showPrefecturesParts
        ? _c("fieldset-confirm-parts", {
            attrs: { label: _vm.$tc("formParts.label.prefectures") },
            model: {
              value: _vm.prefecture[_vm.$i18n.locale],
              callback: function ($$v) {
                _vm.$set(_vm.prefecture, _vm.$i18n.locale, $$v)
              },
              expression: "prefecture[$i18n.locale]",
            },
          })
        : _vm._e(),
      _vm.showZipcodeParts
        ? _c("fieldset-confirm-parts", {
            attrs: { label: _vm.$tc("formParts.label.zipcode") },
            model: {
              value: _vm.editUserInfo.zipcode,
              callback: function ($$v) {
                _vm.$set(_vm.editUserInfo, "zipcode", $$v)
              },
              expression: "editUserInfo.zipcode",
            },
          })
        : _vm._e(),
      _c("mypage-favorite-player-link-section", {
        staticClass: "default-user-init-confirm-section__player",
        attrs: {
          name: _vm.favoritePlayer
            ? _vm.favoritePlayer.getPlayerName()[_vm.$i18n.locale]
            : "",
          img: _vm.playerPictureUrl(_vm.favoritePlayer),
          "enabled-select": false,
        },
      }),
      _c("mypage-favorite-team-link-section", {
        staticClass: "default-user-init-confirm-section__team",
        attrs: {
          name: _vm.favoriteTeam
            ? _vm.getTeamName(_vm.favoriteTeam)[_vm.$i18n.locale]
            : "",
          img: _vm.teamLogoUrl(_vm.favoriteTeam),
          "enabled-select": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }