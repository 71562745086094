import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import PromiseLock from '@//util/PromiseLock'
import APIResponse from './APIResponse'

const promiseLock = new PromiseLock()

/**
 * エラーコード
 * 0はネットワークアクセスエラーを示す
 */
export type ErrorCode = 0 | 409 | 500 | 404 | 503

type Result = {
  isError: boolean
  isShowDialog: boolean
}

export default class APINotification {
  static get isShowDialog() {
    return MessageDialogStore.value.isVisible
  }

  /**
   * APIエラー発生時に共通ダイアログを表示する
   * どのステータスコードに対してダイアログを表示するかどうかは第2引数で指定する
   * @param responses APIAccessorの返り値。複数指定する場合は配列を設定する
   * @param errorCodes ダイアログを表示したいエラーコードを配列で指定する。例 [0,409,500,404]
   * 指定しない場合は定義されているすべてのエラーコードに対してダイアログを表示する
   */
  static async raiseNotification(
    responses: APIResponse | APIResponse[] | null | undefined,
    errorCodes: ErrorCode[] = [0, 409, 500, 404, 503],
  ): Promise<Result> {
    if (!responses) return { isError: false, isShowDialog: APINotification.isShowDialog }
    const _responses = !Array.isArray(responses) ? [responses] : responses
    const isError = _responses.some((response) => !response.isSuccess)

    const occurredErrorCode = errorCodes.find((code) =>
      _responses.some((response) => response.status === code),
    )
    if (occurredErrorCode == null) return { isError, isShowDialog: APINotification.isShowDialog }
    const title = `apiNotification.dialog.${occurredErrorCode}.title`
    const message = `apiNotification.dialog.${occurredErrorCode}.message`
    // モーダル表示中に他のエラーが発生した場合は、その表示を無視する
    if (promiseLock.isBusy('raiseNotification')) return { isError, isShowDialog: true }
    return promiseLock.acquire(
      async () => {
        await MessageDialogStore.value.open?.({
          title,
          message,
          isVisibleCancelButton: false,
        })
        return { isError, isShowDialog: true }
      },
      { key: 'raiseNotification' },
    )
  }
}
