














import { computed, defineComponent } from '@vue/composition-api'
import AtomInputCheckboxParts from '@/components/atoms/input/AtomInputCheckbox.vue'

/**
 * HOME SNSコンテンツ ハイライトコメントトリガーパーツ
 */
export default defineComponent({
  name: 'HighlightsCommentTriggerParts',
  components: {
    AtomInputCheckboxParts,
  },
  props: {
    highlightTrigger: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props) {
    const triggerValue = computed((): boolean => props.highlightTrigger)
    return {
      triggerValue,
    }
  },
  methods: {
    /**
     * チェックボックスクリック通知
     */
    onClick() {
      this.$emit('click')
    },
  },
})
