







































import { defineComponent, PropType } from '@vue/composition-api'
import RankingPlayerLinkParts from '@/components/RaceListPage/RaceListPane/parts/RankingPlayerLinkParts.vue'
import { PlayerRankData } from '@/components/RaceListPage/RaceListPane/parts/RankingCardParts.vue'

/**
 * レース一覧画面: レース ランキング 2位以下プレーヤー情報コンポーネント
 */
export default defineComponent({
  name: 'RankingPlayerListItemParts',
  components: {
    RankingPlayerLinkParts,
  },
  props: {
    rank: {
      type: Number,
      required: true,
    },
    playerData: {
      type: Object as PropType<PlayerRankData>,
      required: true,
    },
  },
  setup(props) {
    const svgColor = props.rank === 2 ? '#c2c2c2' : '#d98b52'
    return {
      svgColor,
    }
  },
})
