










import { defineComponent, ref } from '@vue/composition-api'
import PasswordResetNewPasswordSection from '@/components/PasswordResetPage/PasswordResetNewPasswordPane/PasswordResetNewPasswordSection.vue'
import usePasswordResetToken from '@/store/hook/usePasswordResetToken'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'

/**
 * パスワードリセット 新パスワード入力ペイン コンポーネント
 */
export default defineComponent({
  name: 'PasswordResetNewPasswordPane',
  components: {
    PasswordResetNewPasswordSection,
  },
  setup() {
    const { passwordReset: _passwordReset } = usePasswordResetToken()

    const passwordResetNewPasswordSection =
      ref<InstanceType<typeof PasswordResetNewPasswordSection>>()

    return {
      _passwordReset,
      passwordResetNewPasswordSection,
    }
  },
  methods: {
    changeButtonDisabledStatus(status: boolean): void {
      this.$emit('buttonDisabled', status)
    },
    /**
     * パスワードリセット
     */
    passwordReset(): void {
      return this.passwordResetNewPasswordSection?.passwordReset()
    },
    async reset(password: string) {
      const loader = this.$loading.show()
      const tokenId = this.$route.query ? (this.$route.query.tokenId as string) : ''
      const resetResult = await this._passwordReset(tokenId, password)
      loader.hide()
      if (resetResult.isSuccess) {
        this.$emit('success-reset')
      } else {
        // API通信不具合
        await MessageDialogStore.value.open({
          title: this.$t('PasswordResetPage.errors.title') as string,
          message: this.$t('PasswordResetPage.errors.message') as string,
        })
      }
    },
  },
})
