




































import { computed, defineComponent, PropType } from '@vue/composition-api'
import HighlightPreviewSeekBarParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightPreviewSeekBarParts.vue'
import { VideoStatusType } from '@/components/hook/useVideoPlayer'
import DeviceInfo from '@/util/DeviceInfo'
/**
 * ハイライト作成モーダル、プレビュー表示の動画コントローラーパーツ
 */
export default defineComponent({
  name: 'HighlightPreviewControllerParts',
  components: {
    HighlightPreviewSeekBarParts,
  },
  props: {
    /**
     * シークバー用再生時間データ
     */
    videoTimes: {
      type: Object as PropType<VideoStatusType>,
      required: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * ビデオ一時停止フラグ
     */
    videoPaused: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const videoTimesData = computed({
      get: () => props.videoTimes,
      set: (val) => context.emit('updateCurrentTime', val?.currentTime),
    })
    const videoPausedStatus = computed(() => props.videoPaused)

    const isPc = DeviceInfo.isForPC()

    return {
      isPc,
      videoTimesData,
      videoPausedStatus,
    }
  },
  methods: {
    /**
     * ビデオ 再生 処理
     */
    playVideo(): void {
      this.$emit('videoPlayPauseChange', false)
    },

    /**
     * ビデオ 一時停止 処理
     */
    pauseVideo(): void {
      this.$emit('videoPlayPauseChange', true)
    },
  },
})
