/**
 * 動画プレーヤーのエラー定義
 */
export const VideoPlayerError = {
  /**
   * 再生可能な動画ファイルが存在しない
   */
  COMPATIBLE_VIDEO_NOT_FOUND: 'COMPATIBLE_VIDEO_NOT_FOUND',
  /**
   * 動画ファイル取得に失敗
   */
  NETWORK_ERROR: 'NETWORK_ERROR',
  /**
   * 未定義のエラー
   */
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
} as const

/**
 * 動画プレーヤーのエラー定義の型
 */
export type VideoPlayerErrorType = keyof typeof VideoPlayerError

/**
 * 動画プレーヤーのエラー定義かどうかを判定するユーザ定義タイプガード
 * @param x 判定対象
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isVideoPlayerErrorType(x: any): x is VideoPlayerErrorType {
  return Object.values(VideoPlayerError).includes(x)
}
