import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * プレイイベントマスタを表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/314212483/10+API
 */
export default class PlayEventDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PlayEventDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/play_event'

  idAttr = '_id'

  /**
   * プレイイベントID
   */
  playEventId: string | null = null

  /**
   * プレイイベント名
   */
  playEventName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 非表示フラグ
   */
  hiddenFlag: boolean | null = null

  /**
   * プレイイベントID別名
   */
  get id() {
    return this.playEventId
  }

  /**
   * プレイイベント名別名
   */
  get name() {
    return this.playEventName
  }
}
