var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment-input-section",
      class: {
        "comment-input-section--active": !(
          _vm.isCommentEmpty || _vm.isSubmitDisabled
        ),
      },
    },
    [
      _vm.enabledInput
        ? _c(
            "div",
            { staticClass: "comment-input-section__textarea__container" },
            [
              _c(
                "div",
                { staticClass: "comment-input-section__textarea__inner" },
                [
                  _c(
                    "div",
                    { staticClass: "comment-input-section__textarea__dummy" },
                    [_vm._v(_vm._s(_vm.comment + "\n"))]
                  ),
                  _c("AtomTextArea", {
                    staticClass: "comment-input-section__textarea",
                    attrs: {
                      id: "comment",
                      focusOnMount: true,
                      placeholder: _vm.$tc(
                        "RaceVideoPage.highlights.comment.placeholder"
                      ),
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                  _vm.hasShare && (_vm.postX || _vm.postInstagram)
                    ? _c(
                        "p",
                        { staticClass: "comment-input-section__hashtag" },
                        [_vm._v(" " + _vm._s(_vm.hashtag) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "comment-input-section__buttons" }, [
        !_vm.enabledInput
          ? _c("span", {
              staticClass: "comment-input-section__placeholder",
              domProps: {
                textContent: _vm._s(
                  _vm.$tc("RaceVideoPage.highlights.comment.placeholder")
                ),
              },
              on: { click: _vm.startInput },
            })
          : _vm._e(),
        !_vm.isPc && _vm.hasShare
          ? _c(
              "div",
              { staticClass: "comment-input-section__sns" },
              [
                _c("highlights-sns-button-parts", {
                  attrs: {
                    postX: _vm.postX,
                    postInstagram: _vm.postInstagram,
                    postedSns: _vm.postedSns,
                  },
                  on: {
                    onChangePostX: _vm.onChangePostX,
                    onChangePostInstagram: _vm.onChangePostInstagram,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "comment-input-section__buttons__comment" }, [
          _vm.enabledInput
            ? _c(
                "div",
                { staticClass: "comment-input-section__count" },
                [
                  _c("comment-count-parts", {
                    attrs: {
                      comment: _vm.comment,
                      commentLength: _vm.commentLength,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.enabledInput
            ? _c(
                "button",
                {
                  staticClass:
                    "comment-input-section__button comment-input-section__button--cancel",
                  on: { click: _vm.handleCancelClicked },
                },
                [_c("highlights-cancel-icon")],
                1
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass:
                "comment-input-section__button comment-input-section__button--submit",
              attrs: { disabled: _vm.isCommentEmpty || _vm.isSubmitDisabled },
              on: { click: _vm.onPostComment },
            },
            [_c("highlights-submit-icon")],
            1
          ),
        ]),
      ]),
      _vm.enabledConfirmModal
        ? _c("confirm-modal-section", {
            staticClass: "comment-input-section__modal",
            attrs: {
              message: _vm.cancelMessage,
              confirmButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.confirmButtonLabel"
              ),
              cancelButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.cancelButtonLabel"
              ),
            },
            on: { confirm: _vm.cancelInput, cancel: _vm.continueInput },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }