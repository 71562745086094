import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import MisuseReferralCodeDocument from '@/store/stores/collectionModule/documents/checkMisuseReferralCode/MisuseReferralCodeDocument'

/**
 * 紹介コードの不正利用かどうかを判定するための処理
 */
export default function useMisuseReferralCode() {
  // Collection modules
  const misuseReferralCodeCollectionModule = CollectionModule.createStore(
    MisuseReferralCodeDocument,
  )

  /**
   * 紹介コードの不正利用かどうかを判定する。
   */
  const checkMisuseReferralCode = () =>
    misuseReferralCodeCollectionModule.fetch({ isSaveInStore: false })

  return {
    checkMisuseReferralCode,
  }
}
