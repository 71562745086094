
































import { computed, defineComponent, PropType } from '@vue/composition-api'
import useAward from '@/components/LinkPage/hooks/useAward'
import { AwardRankingData } from '@/components/LinkPage/AwardPane.vue'
import { AwardRankingCategoryType } from '@/store/stores/collectionModule/documents/award/AwardDataDocument'

/**
 * SFgo Award ランキング1位 セクション
 */
export default defineComponent({
  name: 'AwardWinnerSection',
  props: {
    category: {
      type: String as PropType<AwardRankingCategoryType>,
      required: true,
    },
    badgeImage: {
      type: String,
      required: true,
    },
    winnerData: {
      type: Object as PropType<AwardRankingData>,
      required: true,
    },
  },
  setup(props) {
    const { getFavoriteText } = useAward()

    /**
     * お気に入りテキストを取得
     */
    const favoriteText = computed(() => getFavoriteText(props.category))

    return {
      favoriteText,
    }
  },
})
