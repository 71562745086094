



























import { computed, Ref, defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * 共通 確認モーダル テンプレート
 */
export default defineComponent({
  name: 'ConfirmModalSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomInputButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
    confirmButtonLabel: {
      type: String,
      default: '',
    },
    cancelButtonLabel: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const confirmButtonText = computed(() => props.confirmButtonLabel || I18n.tc('common.ok'))

    const cancelButtonText = computed(() => props.cancelButtonLabel || I18n.tc('common.cancel'))

    return {
      confirmButtonText: confirmButtonText as Ref<string>,
      cancelButtonText: cancelButtonText as Ref<string>,
    }
  },
  methods: {
    emitConfirm(): void {
      /**
       * 確認モーダルのOKボタン押下時の処理
       * 親コンポーネントへ削除処理を通知
       */
      this.$emit('confirm')
    },
    emitCancel(): void {
      /**
       * 確認モーダルのキャンセルボタン押下時の処理
       * 親コンポーネントへキャンセル処理を通知
       */
      this.$emit('cancel')
    },
  },
})
