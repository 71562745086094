import Logger from '@/util/logger/Logger'
/**
 * ファイルを変換クラス
 */
export default class FileConverter {
  /**
   * blobをbase64に変換する処理
   * @param blob ファイルバイナリ
   * @return base64文字列
   */
  static async convertBlobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        // readAsDataURLを使う場合、必ずstring
        resolve(reader.result as string)
      }
      reader.onerror = (error) => reject(error)
    })
  }

  /**
   * 画像ファイルをbase64に変換する処理
   * @param imagePath 画像ファイルパス 例: require('@/assets/img/icon/icon_play__highlight.png')
   * @return base64文字列
   */
  static async imageToBase64(imagePath: string): Promise<string> {
    try {
      const response = await fetch(imagePath)
      const blob = await response.blob()
      return await FileConverter.convertBlobToBase64(blob)
    } catch (error) {
      Logger.info(`FileConverter#imageToBase64: Error converting image to Base64: ${error}`)
      throw error // エラーを投げることで、呼び出し元にエラーを伝える
    }
  }

  /**
   * base64をblobに変換する処理
   * @param base64 base64文字列
   * @param mediaType ファイルの種類
   * @return Blob
   */
  static base64ToBlob(base64: string, mediaType: string): Blob {
    const bin = atob(base64.replace(/^.*,/, ''))
    const buffer = new Uint8Array(bin.length)
    for (let i = 0; i < bin.length; i += 1) {
      buffer[i] = bin.charCodeAt(i)
    }
    return new Blob([buffer.buffer], { type: mediaType })
  }
}
