<template>
  <div class="mission-ranking-table-share-parts">
    <div v-if="!isPc" class="mission-ranking-table-share-parts__sns">
      <button
        class="mission-ranking-table-share-parts__sns-button-common"
        @click="onClickSnsButton"
      >
        {{ $tc('MissionPage.RankingPage.postRankingImageOnlyX') }}
      </button>
    </div>
    <div v-else class="mission-ranking-table-share-parts__sns--for-pc">
      <p class="mission-ranking-table-share-parts__sns-title">
        {{ $tc('MissionPage.RankingPage.snsLabel') }}
      </p>
      <ul class="mission-ranking-table-share-parts__sns-list">
        <li class="mission-ranking-table-share-parts__sns-item">
          <a
            class="mission-ranking-table-share-parts__sns-button"
            href="javascript:void(0)"
            @click="onClickAnchorLink(xUrl)"
          >
            <span class="mission-ranking-table-share-parts__sns-button-icon">
              <x-icon-parts />
            </span>
          </a>
        </li>
        <li v-if="false" class="mission-ranking-table-share-parts__sns-item">
          <a
            class="mission-ranking-table-share-parts__sns-button"
            href="javascript:void(0)"
            @click="onClickAnchorLink(instagramUrl)"
          >
            <span class="mission-ranking-table-share-parts__sns-button-icon">
              <instagram-icon-parts />
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="mission-ranking-table-share-parts__share">
      <button
        v-if="!isPc"
        class="mission-ranking-table-share-parts__share-button"
        @click="onClickShareIcon"
      >
        <span class="mission-ranking-table-share-parts__share-button-icon">
          <share-icon-parts />
        </span>
      </button>
      <button
        v-else
        class="mission-ranking-table-share-parts__show-button"
        @click="onClickSnsButton"
      >
        {{ $tc('MissionPage.RankingPage.showShareImage') }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import XIconParts from '@/components/common/icons/XIconParts.vue'
import InstagramIconParts from '@/components/common/icons/InstagramIconParts.vue'
import ShareIconParts from '@/components/common/icons/ShareIconParts.vue'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'

export default defineComponent({
  name: 'MissionRankingTableShareParts',
  components: {
    XIconParts,
    InstagramIconParts,
    ShareIconParts,
  },
  setup(props, context) {
    const isPc = DeviceInfo.isForPC()

    // XのURL
    const xUrl = Const.EXTERNAL_LINKS.X.HOME
    // InstagramのURL
    const instagramUrl = Const.EXTERNAL_LINKS.INSTAGRAM.HOME

    /**
     * SNS投稿ボタンをクリックした場合に呼び出される。
     * @param url 外部サイトのリンク
     */
    const onClickAnchorLink = (url) => {
      // 外部リンクを開く
      window.open(url, '_blank')
      context.emit('savePostSnsMissionHistory')
    }

    return {
      isPc,
      xUrl,
      instagramUrl,
      onClickAnchorLink,
    }
  },
  methods: {
    onClickSnsButton() {
      this.$emit('onClickSnsButton')
    },
    onClickShareIcon() {
      this.$emit('onClickShareIcon')
    },
  },
})
</script>

<style scoped lang="scss">
.mission-ranking-table-share-parts {
  display: flex;
  padding-right: 2px;
  padding-left: 10px;
  color: $white;

  &__sns {
    font-size: 12px;

    &--for-pc {
      display: flex;
      gap: 28px;
      align-items: center;
    }

    &-title {
      font-size: 12px;
      font-weight: 500;
    }

    &-list {
      display: flex;
      gap: 12px;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      background-color: rgba(#fff, 0.2);
      border-radius: 6px;

      &-icon {
        display: flex;
        width: 20px;
        height: 20px;
        color: $white;
      }

      &-common {
        padding: 9px 12px;
        font-size: 12px;
        font-weight: 500;
        color: $white;
        letter-spacing: 0.02em;
        background-color: rgba(#fff, 0.1);
        border-radius: 6px;
      }
    }
  }

  &__share {
    margin-left: auto;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;

      &-icon {
        display: flex;
        width: 18px;
        height: 18px;
        color: $white;
      }
    }
  }

  &__show {
    &-button {
      width: 293px;
      height: 34px;
      padding: 7px 12px;
      font-size: 12px;
      font-weight: 500;
      color: $white;
      letter-spacing: 0.02em;
      text-align: center;
      background-color: rgba(#fff, 0.1);
      border-radius: 6px;
    }
  }
}
</style>
