var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reward-pane" }, [
    _c(
      "div",
      { staticClass: "reward-pane__list" },
      [
        _c("reward-list-section", {
          attrs: { rewardDataList: _vm.rewardDataList },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }