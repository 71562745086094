






















import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'

export type VolumeDataType = {
  /**
   * 現在の音量
   */
  currentVolume: number
  /**
   * 最大音量
   */
  volumeLength: number
  /**
   * 最大音量
   */
  muted: boolean
  /**
   * ミュート前の音量保持
   */
  tempVolume: number
}

/**
 * レース動画再生画面 動画プレーヤー 音量調整シークバーコンポーネント
 *
 * ※動画再生時間シークバーを流用して作成
 * src/components/RaceVideoPage/RaceVideoPane/parts/SeekBarParts.vue
 * ブラウザー版のみの対応のためにinputからタッチイベントだけ外してfunctionは残しています。
 */
export default defineComponent({
  name: 'VolumeSeekBarParts',
  props: {
    /**
     * シークバー用再生時間データ
     */
    value: {
      type: Object as PropType<VolumeDataType>,
      required: true,
    },
  },
  setup(props, context) {
    const progressValue = reactive(props.value)
    const currentVolume = ref(0)

    const seekBarValue = computed({
      get: () => progressValue.currentVolume,
      set: (val: number) => {
        progressValue.currentVolume = val
        currentVolume.value = val
        context.emit(
          'input',
          Object.assign(props.value, {
            currentVolume: val,
            mute: progressValue.currentVolume === 0,
          }),
        )
      },
    })
    const currentVolumeVisible = ref(false)

    return {
      progressValue,
      seekBarValue,
      currentVolume,
      currentVolumeVisible,
    }
  },
  methods: {
    /**
     * シークバー touchStart時 イベント
     */
    handleTouchStartRange(): void {
      this.$emit('handleTouchStartSeekBar')
    },

    /**
     * シークバー touchEnd時 イベント
     */
    handleTouchEndRange(): void {
      this.$emit('handleTouchEndSeekBar')
    },
  },
})
