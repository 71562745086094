var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-user-confirm-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageUserConfirmPage.pageTitle"),
          backLink: "/mypage",
        },
      }),
      _c("mypage-user-confirm-pane", {
        ref: "mypageUserConfirmPane",
        staticClass: "mypage-user-confirm-page__content",
        on: { "success-regist": _vm.moveToComplete },
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector: ".mypage-user-confirm-page__content",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc(
                "MypagePage.MypageUserConfirmPage.registration"
              ),
              isDisabled: false,
            },
            on: { push: _vm.editUserInformation },
          }),
          _c("atom-router-button", {
            attrs: {
              to: "/mypage/user-edit",
              linkText: _vm.$tc("common.cancel"),
              colorType: "tertiary",
            },
          }),
        ],
        1
      ),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
      _c("confirm-leaving-page-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.confirmLeavingModalVisible,
            expression: "confirmLeavingModalVisible",
          },
        ],
        attrs: { screenTransitionTo: _vm.screenTransitionTo },
        on: {
          close: function ($event) {
            _vm.confirmLeavingModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }