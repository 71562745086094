




































import { defineComponent, PropType } from '@vue/composition-api'
import MissionRankingTableItemParts from '@/components/MissionPage/MissionRankingPane/parts/MissionRankingTableItemParts.vue'
import { RankInUserType } from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import StoreUtil from '@/store/StoreUtil'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import { RankingType } from '@/store/stores/pageStore/MissionPage/MissionPageStore'

export default defineComponent({
  name: 'MissionRankingTableSection',
  components: {
    MissionRankingTableItemParts,
  },
  props: {
    category: {
      type: String,
    },
    rankings: {
      type: Array as PropType<Array<RankInUserType>>,
      default: () => [],
    },
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
    rankingType: {
      type: String as PropType<RankingType>,
      default: 'TOTAL',
    },
  },
  setup(props) {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { ownOrganization } = contractInfoStore

    // 自分が上位１０位以内にランクインしてるかどうか
    const checkIsOwnRank = (userId: string) => {
      if (!ownOrganization.value || !props.rankings) return false
      const ownUserId = LoginStore.value.userId || ''
      return userId === ownUserId
    }

    return {
      checkIsOwnRank,
    }
  },
  methods: {
    onClickSnsButton() {
      this.$emit('onClickSnsButton')
    },
    onClickShareIcon() {
      this.$emit('onClickShareIcon')
    },
  },
})
