import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { SectorType } from '@/store/stores/collectionModule/documents/GeneralTypes'

dayjs.extend(utc)

type UserGameEventTypeType = 'CREATED_BY_SFGO_USER'

/**
 * 空のアングルフィールド
 */
const emptyAngleData = {
  angleMovieId: '', // 動画ID
  angleId: '', // アングルID
  movieStartTime: 0, // 動画開始時間
  movieFilePath: '', // 動画ファイルパス
  thumbnailPath: '',
}
/**
 * 画面表示用のアングルフィールドデータモデルの型
 */
export type AngleDataType = typeof emptyAngleData

/**
 * 空のプレイイベントフィールド
 */
const emptyPlayEventData = {
  playEventId: '', // プレイイベントID
}
/**
 * 画面表示用のプレイイベントフィールドデータモデルの型
 */
export type PlayEventDataType = typeof emptyPlayEventData

/**
 * 空のhandwriteフィールド
 */
const emptyHandwriteData = {
  thumbnailPath: '', // サムネイル画像
}
/**
 * 画面表示用のhandwriteフィールドデータモデルの型
 */
export type HandwriteDataType = typeof emptyHandwriteData

type PublicScopeType = {
  /**
   * 親組織ID
   * 親組織IDを設定した場合、その親組織配下の全子組織がコメントを参照することができる。
   */
  parentOrganization?: string | null
}

/**
 * SNSに投稿したことがあるかどうか
 */
export type HighlightPostedSnsType = {
  x?: boolean
  instagram?: boolean
}

/**
 * ハイライト情報を表現するクラス。
 */
export default class HighlightDocument extends DocumentWrapper {
  constructor(initProps?: Partial<HighlightDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'communication/user_game_event'

  idAttr = 'userGameEventId'

  /**
   * 試合ID
   */
  matchId: string | null = null

  /**
   * レースID
   */
  get raceId() {
    return this.matchId
  }

  /**
   * ユーザゲームイベントID
   */
  userGameEventId: string | null = null

  /**
   * ハイライトID
   * ※ ユーザーゲームイベントIDのエイリアス
   */
  get highlightId(): string | null {
    return this.userGameEventId
  }

  /**
   * 動画再生位置（基点）(秒)
   */
  movieStartTime: number | null = null

  /**
   * 動画再生位置（開始）(秒)。
   * 動画再生位置（基点）からの相対時間が設定される。
   */
  offsetStart: number | null = null

  /**
   * ハイライトの再生開始位置(秒)。
   * 動画の0秒地点からの時間を返す。
   */
  get startTime(): number {
    return (this.movieStartTime || 0) + (this.offsetStart || 0)
  }

  /**
   * 動画再生位置（終了）(秒)。
   * 動画再生位置（基点）からの相対時間が設定される。
   */
  offsetEnd: number | null = null

  /**
   * ハイライトの再生終了位置(秒)。
   * 動画の0秒地点からの時間を返す。
   */
  get endTime(): number {
    return (this.movieStartTime || 0) + (this.offsetEnd || 0)
  }

  /**
   * イベント時間
   */
  eventTime: number | null = null

  /**
   * 動画ファイルパス。
   */
  movieFilePath: string | null = null

  /**
   * プレイリストパス
   */
  playlistPath: string | null = null

  /**
   * 関係選手情報
   */
  playerMasterIds: Array<string> | null = null

  /**
   * プレイイベント情報一覧
   */
  playEvents: Array<PlayEventDataType> | null = null

  /**
   * プレイイベント情報一覧
   */
  angles: Array<AngleDataType> | null = null

  /**
   * プレイイベント情報一覧
   */
  angle: Array<AngleDataType> | null = null

  /**
   * コミュニケーション情報
   */
  communication: {
    /**
     * サムネイル情報
     */
    handwrite?: Array<HandwriteDataType>
  } | null = null

  /**
   * ユーザーが作成したハイライトのハイライトコメントを保存する
   */
  note: string | null = null

  /**
   * ハイライトの種類
   */
  userGameEventType: UserGameEventTypeType | null = null

  /**
   * ハイライトのサムネイルパス
   */
  thumbnailPath: string | null = null

  /**
   * 組織をまたいだ公開範囲
   */
  publicScope?: PublicScopeType | null = null

  /**
   * アングル動画情報ID
   */
  angleMovieId: string | null = null

  /**
   * 追加項目
   */
  additionalData?: {
    /**
     * 周回数
     */
    lap?: string

    /**
     * セクター位置
     */
    sector?: SectorType

    /**
     * movieStartTimeを実時間に変換した値（単位: ミリ秒)
     * 動画がタイムコードを持つ場合のみ設定する
     * 無線データを取得する際に使用する
     */
    videoTrackDateTime?: number

    /**
     * SNSに投稿したことがあるかどうか
     */
    postedSns?: HighlightPostedSnsType
  }

  /**
   * プレイイベントId
   */
  get playEventId(): string {
    return this.playEvents?.[0]?.playEventId || ''
  }

  /**
   * サムネイル画像URI
   */
  get thumbnailUri(): string {
    if (this.angleMovieId && this.angle?.length) {
      const targetAngle = this.angle.find(
        (angleInfo) => angleInfo.angleMovieId === this.angleMovieId,
      )
      return targetAngle?.thumbnailPath || ''
    }
    return this.communication?.handwrite?.[0]?.thumbnailPath || this.thumbnailPath || ''
  }

  /**
   * レース時間
   */
  get raceTime(): string {
    if (this.eventTime) {
      return dayjs.utc(this.eventTime * 1000).format('HH:mm:ss')
    }
    if (this.movieStartTime) {
      return dayjs.utc(this.movieStartTime * 1000).format('HH:mm:ss')
    }
    return ''
  }

  /**
   * movieStartTimeの実時間を返す（単位: ミリ秒)
   */
  get videoTrackDateTime() {
    return this.additionalData?.videoTrackDateTime || 0
  }

  /**
   * SFgoから作成されたハイライトかどうか
   */
  get isCreatedBySfgoUser(): boolean {
    if (!this.userGameEventType) return false
    return this.userGameEventType === 'CREATED_BY_SFGO_USER'
  }

  /**
   * 共有されたハイライトかどうか
   */
  get isShared() {
    return (
      this.publicScope?.parentOrganization === process.env.VUE_APP_SFGO_PARENT_ORG_ID ||
      this._organization === process.env.VUE_APP_SFGO_PARENT_ORG_ID
    )
  }

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `HighlightDocument[id: ${this.id}}]`
  }
}
