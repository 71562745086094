var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-decline-message" }, [
    _vm.declineCode
      ? _c("p", [
          _c("span", { staticClass: "modal-decline-message-title" }, [
            _vm._v(
              _vm._s(
                _vm.$tc(
                  "SignupPage.RegistrationPage.errors.createStripeError.declineCodeLabel"
                )
              ) + "："
            ),
          ]),
          _vm._v(_vm._s(_vm.declineCode) + " "),
        ])
      : _vm._e(),
    _vm.description
      ? _c("p", [
          _c("span", { staticClass: "modal-decline-message-title" }, [
            _vm._v(
              _vm._s(
                _vm.$tc(
                  "SignupPage.RegistrationPage.errors.createStripeError.descriptionLabel"
                )
              ) + "："
            ),
          ]),
          _vm._v(_vm._s(_vm.description) + " "),
        ])
      : _vm._e(),
    _vm.nextUserAction
      ? _c("p", [
          _c("span", { staticClass: "modal-decline-message-title" }, [
            _vm._v(
              _vm._s(
                _vm.$tc(
                  "SignupPage.RegistrationPage.errors.createStripeError.nextUserActionLabel"
                )
              ) + "："
            ),
          ]),
          _vm._v(_vm._s(_vm.nextUserAction) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }