import loglevel, { LogLevel } from 'loglevel'
import loglevelMessagePrefix from 'loglevel-message-prefix'
import { v1 as uuidv1 } from 'uuid'
import StackTrace, { StackFrame } from 'stacktrace-js'

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * SFgoアプリのロガー。
 */
export default class Logger {
  /**
   * セッションID。
   * ログを一意に識別する際に利用する
   */
  static currentSessionId: string

  /**
   * ロガーの実装
   */
  static loggerImpl: loglevel.Logger

  /**
   * Loggerを初期化する。
   * このメソッド呼び出し後、ログの書き込み処理が実施可能となる。
   */
  static init() {
    this.configure()

    Logger.loggerImpl.debug('Logger have been initialized.')
  }

  /**
   * 現在のログ書き込みのセッションIDを取得する。
   * @returns セッションID
   */
  static get sessionId() {
    return Logger.currentSessionId
  }

  /**
   * ロガーを取得する。
   * @returns ロガーのインスタンス
   */
  static get logger() {
    return Logger.loggerImpl
  }

  /**
   * ロガーを構成する。
   */
  private static configure() {
    Logger.currentSessionId = uuidv1().split('-')[0]
    loglevelMessagePrefix(loglevel, {
      prefixes: ['timestamp', 'level'],
      staticPrefixes: [Logger.sessionId],
    })

    // noinspection Annotator
    Logger.loggerImpl = loglevel.getLogger('system')
    Logger.loggerImpl.info(
      `Logger#configure: start configure. loglevel: ${process.env.VUE_APP_LOGGER_LEVEL}`,
    )
    Logger.loggerImpl.setLevel(process.env.VUE_APP_LOGGER_LEVEL as keyof LogLevel)
  }

  /**
   * debugログを出力する。
   * @param message メッセージ
   */
  static debug(message: string) {
    Logger.loggerImpl.debug(message)
  }

  /**
   * infoログを出力する。
   * @param message メッセージ
   */
  static info(message: string) {
    Logger.loggerImpl.info(message)
  }

  /**
   * warnログを出力する。
   * @param message メッセージ
   * @param error エラーオブジェクト。Errorが指定された場合、Logger#printStackTrace を利用して、エラーのスタックトレースをログに出力する。
   * Error 以外のインスタンスを指定した場合、無視される
   */
  static warn(message: string, error?: any) {
    if (error instanceof Error) {
      Logger.printStackTrace(message, error, Logger.loggerImpl.warn)
      return
    }
    Logger.loggerImpl.warn(`${message}`)
  }

  /**
   * errorログを出力する。
   * @param message メッセージ
   * @param error エラーオブジェクト。Errorが指定された場合、Logger#printStackTrace を利用して、エラーのスタックトレースをログに出力する。
   * Error 以外のインスタンスを指定した場合、無視される
   */
  static error(message: string, error?: any) {
    if (error instanceof Error) {
      Logger.printStackTrace(message, error, Logger.loggerImpl.error)
      return
    }
    Logger.loggerImpl.error(`${message}`)
  }

  /**
   * スタックトレースの内容を文字列に変換する。
   * @param stackFrames スタックフレーム
   * @return スタックトレースの文字列情報
   */
  private static getStackTraceStrings(stackFrames: StackFrame[]) {
    return stackFrames.map((sf) => sf.toString()).join('\n')
  }

  /**
   * 指定されたメッセージとエラーオブジェクトの内容をログに出力する。
   * @param message メッセージ
   * @param error エラーオブジェクト
   * @param logMethod ログ出力に利用するメソッド
   */
  private static printStackTrace(
    message: string,
    error: Error,
    logMethod: typeof loglevel.error | typeof loglevel.warn,
  ) {
    StackTrace.fromError(error)
      .then((stackframes) => {
        const stringifiedStack = Logger.getStackTraceStrings(stackframes)
        logMethod(`${message}\nError: ${error.message}\n${stringifiedStack}`)
      })
      .catch((e) => {
        logMethod(`${message} \n${e.message}`)
      })
  }
}
