var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan-select-for-paid-pane" },
    [
      _c("signup-header-section", {
        attrs: {
          steps: _vm.currentStep,
          pageTitle: _vm.$tc("SignupPage.Steps.Step3"),
        },
      }),
      _c("choose-plan-section", {
        staticClass: "plan-select-for-paid-pane__choose-plan",
        attrs: {
          selectedPlanGroupId: _vm.selectedPlanGroupId,
          planList: _vm.selectableContractPlans,
          procedureScreen: _vm.procedureScreen,
        },
        on: { handlerPlanSelect: _vm.handlerPlanSelect },
      }),
      _c("coupon-input-section", { on: { handlerSubmit: _vm.setCouponPlan } }),
      _vm.state.clientSecret
        ? _c("credit-card-input-section", {
            key: _vm.state.stripeElementResetKey,
            attrs: { clientSecret: _vm.state.clientSecret },
            on: { handlerCardNameErrors: _vm.updateHasCardNameErrors },
          })
        : _vm._e(),
      _c("comparison-plan-section", {
        staticClass: "plan-select-for-paid-pane__comparison",
        attrs: {
          "section-title": _vm.$tc(
            "SignupPage.SelectPlanPage.comparison.comparisonDescriptionForPaid"
          ),
        },
      }),
      _c(
        "action-buttons-section",
        {
          staticClass: "plan-select-for-paid-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".plan-select-for-paid-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.confirm"),
              isDisabled: _vm.state.isConfirmBtnDisabled,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _vm.enabledLeaveButton
            ? _c("atom-input-button", {
                attrs: {
                  linkText: _vm.$tc("common.back"),
                  isDisabled: false,
                  colorType: "tertiary",
                },
                on: {
                  push: function ($event) {
                    return _vm.handlerCancel()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("alert-overlay-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state.isShowAlertOverlay,
            expression: "state.isShowAlertOverlay",
          },
        ],
        attrs: {
          title: _vm.state.alertOverlayText.title,
          message: _vm.state.alertOverlayText.message,
          closeButtonColorType: "secondary",
          closeButtonLinkText: _vm.$i18n.tc("common.ok"),
        },
        on: { close: _vm.hideAlertOverlay },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }