

















































































import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { computed, defineComponent, onMounted, ref, Ref, watch } from '@vue/composition-api'
import SnsFavoriteSection from '@/components/HomePage/HomePane/SnsFavoriteSection.vue'
import PlayerCardParts from '@/components/common/card/PlayerCardParts.vue'
import SnsPostItemParts from '@/components/HomePage/HomePane/parts/SnsPostItemParts.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import SnsPostLoaderParts from '@/components/HomePage/HomePane/parts/SnsPostLoaderParts.vue'
import HighlightsCommentTriggerParts from '@/components/HomePage/HomePane/parts/HighlightsCommentTriggerParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * HOME SNS Driverコンテンツセクション
 */
export default defineComponent({
  name: 'SnsDriverSection',
  components: {
    SnsFavoriteSection,
    PlayerCardParts,
    SnsPostItemParts,
    SnsPostLoaderParts,
    HighlightsCommentTriggerParts,
  },
  setup() {
    const store = StoreUtil.useStore('HomePageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const errorImgSrc = ref('')
    /**
     * アプリ版のAndroidのみvue-virtual-scroller（DynamicScroller）を導入する
     */
    const isUseDynamicScroller = DeviceInfo.isAndroid()

    onMounted(() => {
      // 選択している選手のSNS投稿を取得する
      if (store.snsPostDisplayPlayer.value?.playerId) {
        store.fetchHomePageSnsFeed(store.snsPostDisplayPlayer.value.playerId)
      } else {
        if (!mypagePageStore.favoritePlayer.value?.playerId) {
          return
        }
        /** ログイン直後はお気に入り選手のSNSを表示 */
        store.snsPostDisplayPlayer.value = mypagePageStore.favoritePlayer.value
        store.fetchHomePageSnsFeed(mypagePageStore.favoritePlayer.value.playerId)
      }
    })

    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player?: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }

    /**
     * 選択したドライバーのSNS記事データ
     */
    const snsContentsData = ref([]) as Ref<SnsPostData[]>

    /**
     * SNSコンテンツローディング中フラグ
     */
    const snsLoading = ref<boolean>(false)

    /**
     * ハイライトのみ絞り込みフィルター
     */
    const highlightTrigger = ref<boolean>(false)

    /**
     * store.getSnsFeedDataListの変更を監視し、リアクティブにデータを作成する
     */
    watch(
      () => store.getSnsFeedDataList.value,
      (newData) => {
        snsContentsData.value = newData.map((obj) => ({
          ...obj,
          images: obj.images.filter((image) => image.src !== errorImgSrc.value),
        }))
      },
      {
        immediate: true,
        deep: true,
      },
    )

    /**
     * ハイライト用ダミーデータ
     * TODO: 実装時削除
     */
    const highlightDummyData = computed(() => {
      const dummyData = []
      for (let i = 0; i < 3; i += 1) {
        dummyData.push({
          linkType: 'highlights',
          link: `highlightsComment${i}`,
          thumbnail: 'https://pbs.twimg.com/media/FvpthE9aEAAAGvn.jpg',
          username: '野尻 智紀 #1',
          time: '',
          createdDate: 1681640055492,
          post: 'ここでのハンドリングが勝負の決め手になりました！ここでのハンドリングが勝負の決め手になりました！ここでのハンドリングが勝負の決め手になりました！',
          like: 0,
          retweet: 0,
          images: [],
          highlightComment: {
            sceneTitle: 'クラッシュクラッシュ野尻智紀',
            round: 1,
            raceDate: '2023.4.8',
            circuit: 'FUJI SPEEDWAY',
            lap: 18,
            sector: 3,
          },
        })
      }
      return dummyData
    })

    /**
     * imgタグで読み込みエラーになったデータを削除して、データを再構築する
     * この処理によってdynamic-scrollerで再計算が行われる
     */
    const updateSnsContentsData = (src: string) => {
      errorImgSrc.value = src
      snsContentsData.value = snsContentsData.value.map((obj) => ({
        ...obj,
        images: obj.images.filter((image) => image.src !== src),
      }))
    }

    return {
      snsPostDisplayPlayer: store.snsPostDisplayPlayer,
      playerPictureUrl,
      snsContentsData,
      snsLoading,
      highlightTrigger,
      highlightDummyData,
      updateSnsContentsData,
      isUseDynamicScroller,
    }
  },
  methods: {
    /**
     * ハイライトコメントフィルター切替
     */
    changeHighlightsTrigger() {
      // TODO: 絞り込み処理
      this.highlightTrigger = !this.highlightTrigger
    },
    selectSnsPostDisplayPlayer() {
      this.$router.replace({ name: 'HomeSnsPlayerSelectPage' })
    },
  },
})
