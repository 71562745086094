/**
 * トランスコードステータス
 */
export const TranscodeStatus = {
  /** 変換中 */
  InProgress: 'InProgress',
  /** 変換エラー */
  Error: 'Error',
  /** 変換完了 */
  Complete: 'Complete',
  /** ライブ配信待ち */
  Waiting: 'Waiting',
  /** ライブ配信中 */
  Live: 'Live',
} as const
/**
 * トランスコードステータスの型
 */
export type TranscodeStatusType = keyof typeof TranscodeStatus
