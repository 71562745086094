var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mission-top-page" },
    [
      _c("header-pane", {
        attrs: {
          hasHeaderNav: true,
          pageTitle: "MISSION",
          navigationCurrent: "mission",
          colorType: "light",
        },
      }),
      _c(
        "div",
        { staticClass: "mission-top-page__contents" },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }