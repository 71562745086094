import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

export type EventType = 'USER_EVENT' | 'GAME_EVENT'

type PublicScopeType = {
  /**
   * 親組織ID
   * 親組織IDを設定した場合、その親組織配下の全子組織がコメントを参照することができる。
   */
  parentOrganization?: string | null
}

/**
 * ハイライトコメント情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/600735873/50+API
 */
export default class HighlightCommentDocument extends DocumentWrapper {
  constructor(initProps?: Partial<HighlightCommentDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'communication/comment'

  idAttr = 'commentId'

  /**
   * コメントID
   */
  commentId: string | null = null

  /**
   * コメント
   */
  comment?: string | null = null

  /**
   * 試合ID
   */
  matchId?: string | null = null

  /**
   * イベントID
   */
  eventId?: string | null = null

  /**
   * イベントタイプ
   * SFgoでは現状ハイライトにのみコメントを関連付けるため、「USER_EVENT」のみ設定される
   */
  eventType?: EventType | null = 'USER_EVENT'

  /**
   * 組織をまたいだ公開範囲
   */
  publicScope?: PublicScopeType | null = null

  additionalData?: {
    /**
     * ユーザーID
     */
    userId?: string | null
  }

  /**
   * レースID
   */
  get raceId() {
    return this.matchId
  }

  /**
   * ハイライトID
   * ※ イベントIDのエイリアス
   */
  get highlightId() {
    return this.eventId
  }
}
