






import { defineComponent } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import MypageEcSiteConnectSection from '@/components/MypageEcSiteConnectPage/MypageEcSitePane/MypageEcSiteConnectSection.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'

export default defineComponent({
  name: 'MypageEcSiteConnectPane',
  components: { MypageEcSiteConnectSection },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      ownOrganization: mypagePageStore.ownOrganization,
      fetchOrganizations: mypagePageStore.fetchOrganizations,
      pollingOrganizations: mypagePageStore.pollingOrganizations,
      updateOrganization: mypagePageStore.updateOrganization,
    }
  },
  methods: {
    /**
     * ECサイト連携フラグを更新する
     */
    async connectEc() {
      if (!this.ownOrganization) {
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageEcSiteConnectPage.errors.missingOrganization.title'),
          message: I18n.tc('MypagePage.MypageEcSiteConnectPage.errors.missingOrganization.message'),
        })
        return
      }

      const loader = this.$loading.show()

      const newOrgData = cloneDeep(this.ownOrganization)
      newOrgData.additionalData = {
        ...this.ownOrganization?.additionalData,
        ec: {
          linkEnabled: true,
        },
      }
      // 組織情報を更新
      const result = await this.updateOrganization(newOrgData)
      if (!result.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageEcSiteConnectPage.errors.startConnectError.title'),
          message: I18n.tc('MypagePage.MypageEcSiteConnectPage.errors.startConnectError.message'),
          errorApiResponse: result.response,
        })
        return
      }
      await this.fetchOrganizations()

      loader.hide()

      // ポーリングする
      this.pollingOrganizations(42)
    },
  },
})
