




































































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import { orderBy } from 'lodash'
import MypageTicketToggleIconParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypageTicketToggleIconParts.vue'
import MypageTicketTodayTableParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypageTicketTodayTableParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import StoreUtil from '@/store/StoreUtil'
import usePurchasedTicketData, {
  UserPurchasedTicketType,
} from '@/components/hook/ticket/usePurchasedTicketData'

export default defineComponent({
  name: 'MypageTicketTodayEntryCardSection',
  components: {
    AtomButton,
    MypageTicketTodayTableParts,
    MypageTicketToggleIconParts,
  },
  props: {
    activeCardId: {
      type: String,
      default: '',
    },
    /**
     * デジタルチケット イベント情報
     */
    ticketEvent: {
      type: Object as PropType<DigitalTicketDataDocument>,
      required: true,
    },
    /**
     * デジタルチケット 券種情報
     */
    ticketType: {
      type: Object as PropType<DigitalTicketDataTicketTypeDocument>,
      required: true,
    },
    /**
     * ログインユーザーが購入した商品情報
     */
    purchasedTicketInfo: {
      type: Object as PropType<UserPurchasedTicketType>,
      required: true,
    },
  },
  setup(props, context) {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { ownOrganization } = mypagePageStore
    const { getTargetTicketUsedDateTime } = usePurchasedTicketData()

    /**
     * チケット使用済みにした日時
     */
    const acceptedDateTime = computed(() =>
      getTargetTicketUsedDateTime(props.ticketEvent, props.ticketType, ownOrganization.value),
    )

    /**
     * ログインユーザーが購入したチケット
     */
    const purchasedTickets = computed(() =>
      orderBy(props.ticketType.tickets, 'order')?.filter((ticket) =>
        props.purchasedTicketInfo.productNumbers.availableToday.some(
          (productNumber) => productNumber === ticket.productNumber,
        ),
      ),
    )

    /**
     * カードの開閉が押下されたときの処理
     * @param targetTicketTypeId 券種ID
     */
    const handleToggleClicked = (targetTicketTypeId: string) => {
      context.emit('onClickToggle', targetTicketTypeId)
    }

    /**
     * 係員がこの画面を確認しますボタンが押下されたときの処理
     * @param targetTicketType 対象の券種
     */
    const handleCheckTicketClicked = (targetTicketType: DigitalTicketDataTicketTypeDocument) => {
      context.emit('onAdmissionTicketClicked', targetTicketType)
    }

    return {
      // MypagePageStore
      ownOrganization: ownOrganization as Ref<OrganizationDocument | undefined>,
      // MypageTicketTodayEntryCardSection
      availableTodayProductNumbers: props.purchasedTicketInfo.productNumbers
        .availableToday as Array<string>,
      acceptedDateTime,
      purchasedTickets,
      handleToggleClicked,
      handleCheckTicketClicked,
    }
  },
})
