import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import StoreUtil from '@/store/StoreUtil'
import MissionProgressDocument, {
  AchievementConditionType,
  MissionCodeType,
} from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import I18n from '@/locales/I18n'
import useMissionProgressDesign, {
  AchievementCountDispType,
  HeaderIconType,
  HoneycombType,
  MissionGroupCodeType,
} from '@/components/MissionPage/hook/useMissionProgressDesign'
import usePaidMemberMissionProgressData from '@/components/MissionPage/hook/usePaidMemberMissionProgressData'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export type CheckPointType = {
  stadiumId: string
  area: Multilingual | null
  areaName: Multilingual | null
  eventCheckInMissionTerm: string
  startDate: number
  checked: boolean
  googleMapUrl: string
}

/**
 * ミッション達成状況カードデータの型
 */
export type MissionProgressCardType = {
  // ミッショングループコード
  missionGroupCode: MissionGroupCodeType
  // ミッションコード
  missionCode?: MissionCodeType
  // ミッション名
  missionName: string
  // ミッション説明
  description: string
  // ミッション期間タイトル
  missionTermTitle?: string
  // ミッション期間
  missionTerm?: string
  // 達成方法
  achievementMethod: {
    title: string
    text: string
    infoLink: string
    note: string
  } | null
  // 達成条件
  achievementCondition: Array<AchievementConditionType> | null
  // 次のポイント獲得までの残数
  leftCountUntilNextAchievement: number | null
  // ミッション達成回数
  achievedMissionsCount: number | null
  // 獲得済みポイント
  earnedPoints: number
  // コンプリート済みのミッションかどうか
  hasCompleted: boolean
  // 次にミッション達成した際に獲得するポイント（設定した数値がタイトル右端に表示される）
  nextAchievementPoint?: number | null
  /** デザイン系 */
  // ミッション運達成状況カードの色
  colorType: string
  // ヘッダーアイコン
  headerIcon: HeaderIconType
  // 達成状況のテキスト表示タイプ
  achievementCountDispType?: AchievementCountDispType
  // ハニカムをどのように表示するか
  honeycombType?: HoneycombType
  // 表示順
  order: number
  // イベントミッションのチェックイン場所リスト
  checkPointList?: Array<CheckPointType>
}

/**
 * ミッション画面のミッション達成状況機能を提供する。
 */
export default function useMissionProgressData() {
  // store
  const missionPageStore = StoreUtil.useStore('MissionPageStore')
  const {
    missionProgressesByMissionCode,
    missionAvailableAreas,
    beingHeldOrNextSeasonRoundCheckInMission,
  } = missionPageStore
  // hook
  const { progressDesigns } = useMissionProgressDesign()
  const { getAnnualPaidMissionProgress, getMonthlyPaidMissionProgress } =
    usePaidMemberMissionProgressData()
  const { getDisplayDateJa } = useDisplayDependingOnLang()

  /**
   * ミッション名を取得
   * ミッションカードに表示する見出しをDBに持っていないミッションについては、言語ファイルに設定している情報を表示する
   */
  const getMissionName = (
    missionProgress: MissionProgressDocument,
    missionGroupCode: MissionGroupCodeType,
  ) => {
    if (missionGroupCode === 'REGISTERED_PROFILE') {
      // プロフィール登録
      return I18n.tc('MissionPage.registeredProfile.missionName')
    }
    if (missionGroupCode === 'REGISTERED_FAVORITE') {
      // お気に入り登録
      return I18n.tc('MissionPage.registeredFavorite.missionName')
    }

    return missionProgress.missionName?.[I18n.locale] ?? ''
  }

  /**
   * ミッション達成に関する注意文を取得
   */
  const getAchievementNote = (missionProgress: MissionProgressDocument) => {
    if (
      missionProgress.missionCode === 'CONSECUTIVE_LOGINS' ||
      missionProgress.missionCode === 'CUMULATIVE_LOGINS'
    ) {
      // 連続ログインまたは累計ログインの場合
      return I18n.tc('MissionPage.achievementMethod.note2')
    }

    return missionProgress.countCondition?.termType === 'DAY' &&
      missionProgress.countCondition?.upperLimit === 2
      ? `${I18n.tc('MissionPage.achievementMethod.note')}<br>${I18n.tc(
          'MissionPage.achievementMethod.note2',
        )}`
      : ''
  }

  /**
   * 達成方法を取得
   */
  const getAchievementMethod = (missionProgress: MissionProgressDocument) => {
    if (
      !missionProgress.achievementMethod?.title?.[I18n.locale] ||
      !missionProgress.achievementMethod?.text?.[I18n.locale]
    ) {
      return null
    }

    return {
      title: missionProgress.achievementMethod?.title?.[I18n.locale] ?? '',
      text: missionProgress.achievementMethod?.text?.[I18n.locale] ?? '',
      infoLink: '', // TODO: ミッション説明ページの準備が間に合わないとのことだったので未実装
      note: getAchievementNote(missionProgress),
    }
  }

  /**
   * ミッション達成状況に情報を追加する
   */
  const addInfoToAchievementCondition = (
    missionProgress: MissionProgressDocument,
    missionGroupCode: MissionGroupCodeType,
    missionProgressCard: MissionProgressCardType | null = null,
  ) => {
    const clonedAchievementCondition = cloneDeep(missionProgress.achievementCondition?.[0])
    if (
      clonedAchievementCondition &&
      (missionGroupCode === 'REGISTERED_PROFILE' || missionGroupCode === 'REGISTERED_FAVORITE')
    ) {
      // プロフィール登録、お気に入り登録ミッションの場合、ハニカム内に表示するミッション名を追加
      // この処理に入るミッションは達成状況が１つしか存在しないことを前提にしている
      clonedAchievementCondition.missionNameOnCheckList =
        missionProgress.missionName?.[I18n.locale] ?? ''
      return [clonedAchievementCondition]
    }

    if (
      clonedAchievementCondition &&
      missionGroupCode === 'SEASON_ROUND_CHECK_IN' &&
      missionProgressCard &&
      missionProgressCard.achievementCondition
    ) {
      // シーズンラウンドチェックインの操作回数を更新する
      // この処理に入るミッションは達成状況が１つしか存在しないことを前提にしている
      const lsatOperationCount =
        missionProgressCard.achievementCondition[
          missionProgressCard.achievementCondition.length - 1
        ].operationCount || 0

      clonedAchievementCondition.operationCount = lsatOperationCount + 1
      return [clonedAchievementCondition]
    }

    return cloneDeep(missionProgress.achievementCondition)
  }

  /**
   * ミッション達成回数を取得
   * - 連続ログインの場合は最高連続ログイン日数
   */
  const getAchievedMissionsCount = (missionProgress: MissionProgressDocument) => {
    if (missionProgress.missionCode === 'CONSECUTIVE_LOGINS') {
      // 連続ログインの場合は最大連続操作回数+1（=最高連続ログイン日数)を返す。最大連続操作回数0の場合は連続ログイン1日とする。
      return missionProgress.maxContinuousOperationCount
        ? missionProgress.maxContinuousOperationCount + 1
        : 1
    }

    if (missionProgress.hiddenAchievedMissionsCount) {
      return null
    }

    // 上記以外
    return missionProgress.totalOperationCount ?? 0
  }

  /**
   * 次のポイント獲得までの残数
   */
  const getLeftCountUntilNextAchievement = (missionProgress: MissionProgressDocument) => {
    if (missionProgress.missionCode === 'CONSECUTIVE_LOGINS') {
      // 連続ログインの場合は連続操作回数+1（=現在の連続ログイン日数)を返す。連続操作回数0の場合は連続ログイン1日とする。
      return missionProgress.continuousOperationCount
        ? missionProgress.continuousOperationCount + 1
        : 1
    }

    if (missionProgress.hiddenAchievedMissionsCount) {
      return null
    }

    // 上記以外
    // 次にポイントが付与されるミッション達成回数
    const nextCountWillEarnPoints = missionProgress.achievementCondition?.find(
      (condition) => !condition.isAchieved,
    )?.operationCount
    // 現在の達成回数を取得
    const currentAchievedMissionsCount = missionProgress.totalOperationCount ?? 0

    return nextCountWillEarnPoints ? nextCountWillEarnPoints - currentAchievedMissionsCount : 0
  }

  /**
   * 全ミッションを達成しているか
   */
  const allMissionsCompleted = (
    achievementCondition: Array<AchievementConditionType> | null,
    missionCode: MissionCodeType,
  ) => {
    if (missionCode === 'CONSECUTIVE_LOGINS') {
      // 連続ログインはMISSION COMPLETEにならない
      return false
    }
    return achievementCondition?.every((condition) => condition.isAchieved) ?? false
  }
  /**
   * 開催中のミッションのチェックポイントを取得する
   */
  const getCheckPointBeingHeld = (missionProgress: MissionProgressDocument) => {
    const today = dayjs().valueOf()
    // ミッション開始日時、終了日の範囲外のミッションならnullを返す
    if (
      !(
        dayjs.tz(missionProgress.startDate).valueOf() <= today &&
        today <= dayjs.tz(missionProgress.endDate).valueOf()
      )
    )
      return null
    const checkPoint = missionAvailableAreas.value.find(
      (area) =>
        missionProgress.operation && missionProgress.operation[0] === area.mission?.operation,
    )
    return checkPoint
      ? ({
          stadiumId: checkPoint.stadiumId || '',
          area: checkPoint.area,
          areaName: checkPoint.areaName,
          eventCheckInMissionTerm: `${getDisplayDateJa(
            missionProgress.startDate,
          )} ${getDisplayDateJa(missionProgress.startDate, 'ddd', 'ddd')} - ${getDisplayDateJa(
            missionProgress.endDate,
          )} ${getDisplayDateJa(missionProgress.endDate, 'ddd', 'ddd')}`,
          startDate: missionProgress.startDate ?? 0,
          checked:
            (missionProgress.achievementCondition &&
              missionProgress.achievementCondition[0].isAchieved) ||
            false,
          googleMapUrl: checkPoint.googleMapUrl,
        } as CheckPointType)
      : null
  }

  /**
   * ミッションコード毎に分類されたミッション達成状況カードを返す
   */
  const getMissionProgressCardData = (year: number) => {
    const missionProgressCardsMap: Record<MissionGroupCodeType, MissionProgressCardType> =
      {} as Record<MissionGroupCodeType, MissionProgressCardType>

    Object.values(missionProgressesByMissionCode.value).forEach((missionProgress) => {
      if (!missionProgress.missionCode) {
        return
      }

      const targetProgressDesign = progressDesigns.find(
        (progressDesign) =>
          progressDesign?.missionCode === missionProgress.missionCode ||
          (progressDesign.missionCodeList as Array<string>)?.includes(
            missionProgress.missionCode || '',
          ),
      )

      if (!targetProgressDesign) {
        return
      }

      const { missionGroupCode } = targetProgressDesign

      if (missionProgressCardsMap[missionGroupCode]) {
        const newAchievementCondition = addInfoToAchievementCondition(
          missionProgress,
          missionGroupCode,
          missionProgressCardsMap[missionGroupCode],
        )
        if (
          missionProgressCardsMap[missionGroupCode].achievementCondition &&
          newAchievementCondition
        ) {
          // 既にグループが存在する場合新規にミッションカードを作成、既存のカードにミッションを追加する
          missionProgressCardsMap[missionGroupCode].achievementCondition?.push(
            ...newAchievementCondition,
          )
          // 達成状況を追加したため、最新の達成状況を見てコンプリート済みのミッションかどうかを判定し直す
          missionProgressCardsMap[missionGroupCode].hasCompleted = allMissionsCompleted(
            missionProgressCardsMap[missionGroupCode].achievementCondition,
            missionProgress.missionCode,
          )
          // 獲得済みポイントを加算
          missionProgressCardsMap[missionGroupCode].earnedPoints += missionProgress.pointEarned ?? 0
          // イベントチェックインの際の処理
          if (missionGroupCode === 'CHECKED_IN_BONUS') {
            // イベントチェックインの場合は、各マスタの操作回数を合計する
            missionProgressCardsMap[missionGroupCode].achievedMissionsCount =
              (missionProgressCardsMap[missionGroupCode].achievedMissionsCount ?? 0) +
              (getAchievedMissionsCount(missionProgress) ?? 0)
            // イベントチェックインの場所を取得
            const checkPoint = getCheckPointBeingHeld(missionProgress)
            // イベントチェックインの場所が取得できたら、チェックポイントリストに格納
            if (checkPoint) {
              missionProgressCardsMap[missionGroupCode].checkPointList?.push(checkPoint)

              // NOTE: 2024年3月に登録した鈴鹿市チェックインミッションのタイトルが鈴鹿市チェックインのみで利用できるタイトルだったため、有効なイベントチェックインに登録されているタイトルを上書きする
              missionProgressCardsMap[missionGroupCode].achievementMethod =
                getAchievementMethod(missionProgress)
            }
          }
        }
        return
      }

      // ミッションカードを作成
      missionProgressCardsMap[missionGroupCode] = {
        // ミッションコード
        missionCode: missionProgress.missionCode,
        // ミッション名
        missionName: getMissionName(missionProgress, missionGroupCode),
        // ミッション説明
        description: missionProgress.description?.[I18n.locale] ?? '',
        // 達成方法
        achievementMethod: getAchievementMethod(missionProgress),
        // 達成条件
        achievementCondition: addInfoToAchievementCondition(missionProgress, missionGroupCode),
        // 次のポイント獲得までの残数
        leftCountUntilNextAchievement: getLeftCountUntilNextAchievement(missionProgress),
        // ミッション達成回数
        achievedMissionsCount: getAchievedMissionsCount(missionProgress) ?? 0,
        // 獲得済みポイント
        earnedPoints: missionProgress.pointEarned ?? 0,
        // コンプリート済みのミッションかどうか
        hasCompleted: allMissionsCompleted(
          missionProgress.achievementCondition,
          missionProgress.missionCode,
        ),
        // 次にミッションを達成した場合に獲得できるポイント
        nextAchievementPoint:
          missionGroupCode === 'CHECKED_IN_BONUS' && missionProgress.achievementCondition
            ? missionProgress.achievementCondition[0].point
            : null,

        /** デザイン系 */
        // ミッショングループコード
        missionGroupCode: targetProgressDesign.missionGroupCode,
        // ミッション運達成状況カードの色
        colorType: targetProgressDesign.colorType,
        // ヘッダーアイコン
        headerIcon: targetProgressDesign.headerIcon,
        // 達成状況のテキスト表示タイプ
        achievementCountDispType: targetProgressDesign.achievementCountDispType,
        // ハニカムをどのように表示するか
        honeycombType: targetProgressDesign.honeycombType,
        // 表示順
        order: targetProgressDesign.order,
      }
      // イベントチェックインの際の処理
      if (missionGroupCode === 'CHECKED_IN_BONUS') {
        // チェックポイントリストの配列を初期化
        missionProgressCardsMap[missionGroupCode].checkPointList = []
        // イベントチェックインの場所を取得
        const checkPoint = getCheckPointBeingHeld(missionProgress)
        // イベントチェックインの場所が取得できたら、チェックポイントリストに格納
        if (checkPoint) missionProgressCardsMap[missionGroupCode].checkPointList?.push(checkPoint)
      }
    })

    if (missionProgressCardsMap.SEASON_ROUND_CHECK_IN) {
      const targetMission = beingHeldOrNextSeasonRoundCheckInMission.value

      if (targetMission && targetMission.missionCode) {
        missionProgressCardsMap.SEASON_ROUND_CHECK_IN.missionTerm = `${getDisplayDateJa(
          targetMission.startDate,
        )} ${getDisplayDateJa(targetMission.startDate, 'ddd', 'ddd')} - ${getDisplayDateJa(
          targetMission.endDate,
        )} ${getDisplayDateJa(targetMission.endDate, 'ddd', 'ddd')}`
        const round = targetMission.missionCode.replace('SEASON_ROUND_CHECK_IN_', '')
        missionProgressCardsMap.SEASON_ROUND_CHECK_IN.missionTermTitle = I18n.t(
          'MissionPage.achievementMethod.roundMissionTerm',
          { round },
        ).toString()
      }
    }

    // 年額プラン登録/継続ミッション達成状況を追加
    missionProgressCardsMap.ANNUAL_PAID_MEMBER = getAnnualPaidMissionProgress(year)
    // 月額プラン登録/継続ミッション達成状況を追加
    missionProgressCardsMap.MONTHLY_PAID_MEMBER = getMonthlyPaidMissionProgress(year)

    return missionProgressCardsMap
  }

  return {
    getMissionProgressCardData,
  }
}
