/**
 * アカウントの型
 * プランが存在しなくても有料機能が使えるスタッフアカウント用に特別に作成している
 */
export type SpecialAccountType = 'staff'

/**
 * ユーザーの権限の型
 * 組織APIで「expand: 'contract'」を指定して取得できるpermission
 * @see https://docs.google.com/spreadsheets/d/1ioITwdnP58wew4wZ_rZMUrQ9bqcDtPGI8aE04LWRgJQ/edit#gid=0
 */
export type PermissionByContractType =
  | 'showRaceMovie'
  | 'showQualifyingRaceMovie'
  | 'showFinalRaceMovie'
  | 'showFreeRunMovie'
  | 'showTelemetry'
  | 'showLiveTiming'
  | 'showHighlight'
  | 'showRadio'
  | 'showGps'
  | 'showPaidMembersOnlyMovie'
  | 'createComment'
  | 'createHighlight'
  | 'shareComment'
  | 'shareReaction'

/**
 * ユーザーの権限の型
 * ロールマスタAPIで取得できるpermission
 * @see https://docs.google.com/spreadsheets/d/1ioITwdnP58wew4wZ_rZMUrQ9bqcDtPGI8aE04LWRgJQ/edit#gid=0
 */
export type PermissionByRoleType =
  | 'createHighlight'
  | 'createComment'
  | 'shareHighlight'
  | 'shareComment'
  | 'shareReaction'

/**
 * SFgoで利用するユーザーの権限の型
 */
export type PermissionType = PermissionByContractType | PermissionByRoleType

/**
 * レースに関する権限の型
 */
export type RacePermissionType = Extract<
  PermissionType,
  'showFreeRunMovie' | 'showQualifyingRaceMovie' | 'showFinalRaceMovie'
>

/**
 * アカウントと権限の対応表の型
 */
type AccountPermissionsMapType = Record<SpecialAccountType, Array<PermissionType>>

/**
 * 権限を表現する。
 */
/**
 * アカウントと権限の対応表
 */
export const accountPermissionsMap: AccountPermissionsMapType = {
  /**
   *  JRPのスタッフアカウントはプランなし状態でユーザーが作成されているため、スタッフアカウント用に有料プランと同じ権限を定義する
   * （ここでのプランなし状態とは登録処理を途中でやめた会員以外のことを指している）
   */
  staff: [
    'showRaceMovie',
    'showQualifyingRaceMovie',
    'showFinalRaceMovie',
    'showFreeRunMovie',
    'showTelemetry',
    'showLiveTiming',
    'showHighlight',
    'showRadio',
    'showGps',
    'showPaidMembersOnlyMovie',
    'createComment',
    'createHighlight',
    'shareComment',
    'shareReaction',
  ],
}
