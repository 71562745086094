var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gps-details-map-section",
      class: "gps-details-map-section--mode-" + _vm.gpsViewMode,
    },
    [
      _c(
        "div",
        { staticClass: "gps-details-map-section__circuit" },
        [
          _c("circuit-detail-map-image", {
            staticClass: "gps-details-map-section__circuit-map",
            class: [{ "is-animation": _vm.enableAnimation }],
            style: _vm.gpsMapPosition,
            attrs: {
              gpsViewMode: _vm.gpsViewMode,
              circuitName: _vm.circuitName,
            },
          }),
        ],
        1
      ),
      _vm.gpsViewMode === "birdsEye"
        ? _c(
            "div",
            { staticClass: "gps-details-map-section__car-wrapper" },
            _vm._l(_vm.carPositionData, function (carGpsPositionData, carNo) {
              return _c("circuit-map-car-parts", {
                key: carNo,
                staticClass: "gps-details-map-section__car",
                attrs: {
                  gpsViewMode: _vm.gpsViewMode,
                  circuitGpsPosition: _vm.circuitGpsPosition,
                  number: carGpsPositionData.carNo,
                  gpsLat: carGpsPositionData.lat,
                  gpsLng: carGpsPositionData.lng,
                  createdDate: carGpsPositionData.createdDate,
                  selectedPlayer:
                    _vm.selectedPlayerId === carGpsPositionData.id,
                  animateGpsMapCar: _vm.animateGpsMapCar,
                  mapMagnification: _vm.mapMagnification,
                  mapSize: _vm.mapSize,
                  name: carGpsPositionData.shortName,
                  rank: carGpsPositionData.rank,
                  pitInWorking: carGpsPositionData.pitInWorking,
                  otsEnabled: carGpsPositionData.otsEnabled,
                },
                model: {
                  value: carGpsPositionData.ots,
                  callback: function ($$v) {
                    _vm.$set(carGpsPositionData, "ots", $$v)
                  },
                  expression: "carGpsPositionData.ots",
                },
              })
            }),
            1
          )
        : _c(
            "div",
            {
              staticClass: "gps-details-map-section__car-wrapper",
              class: [{ "is-animation": _vm.enableAnimation }],
              style: _vm.gpsMapPosition,
            },
            _vm._l(_vm.carPositionData, function (carGpsPositionData, carNo) {
              return _c("map-car-parts", {
                key: carNo,
                staticClass: "gps-details-map-section__car",
                attrs: {
                  gpsViewMode: _vm.gpsViewMode,
                  circuitGpsPosition: _vm.circuitGpsPosition,
                  number: carGpsPositionData.carNo,
                  gpsLat: carGpsPositionData.lat,
                  gpsLng: carGpsPositionData.lng,
                  createdDate: carGpsPositionData.createdDate,
                  selectedPlayer:
                    _vm.selectedPlayerId === carGpsPositionData.id,
                  animateGpsMapCar: _vm.animateGpsMapCar,
                  mapMagnification: _vm.mapMagnification,
                  mapSize: _vm.mapSize,
                  name: carGpsPositionData.shortName,
                  rank: carGpsPositionData.rank,
                },
              })
            }),
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }