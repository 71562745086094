






import { defineComponent } from '@vue/composition-api'
import MypageUserCompleteSection from '@/components/MypageUserCompletePage/MypageUserCompletePane/MypageUserCompleteSection.vue'

/**
 * マイページ: 会員情報変更完了 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageUserCompletePane',
  components: {
    MypageUserCompleteSection,
  },
  methods: {},
})
