import {
  REGISTERED_FAVORITE_MISSION_CODE_LIST,
  REGISTERED_PROFILE_MISSION_CODE_LIST,
  ROUND_CHECK_IN_MISSION_CODE_LIST,
  CIRCUIT_CHECK_IN_MISSION_CODE_LIST,
  EVENT_CHECK_IN_MISSION_CODE_LIST,
} from '@/util/Const'
import { MissionCodeType } from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'

/**
 * ヘッダーアイコンの型
 */
export type HeaderIconType =
  | 'race-icon'
  | 'member-icon'
  | 'clip-icon'
  | 'radiophone-icon'
  | 'share-icon'
  | 'map-icon'

/**
 * 達成状況テキストをどのように表示するかの型
 */
export type AchievementCountDispType = 'default' | 'login' | 'countUp' | 'continuation'

/**
 * ハニカムをどのように表示するかの型
 * count: ハニカム内に数字を表示
 * countNoOrder: ハニカム内に数字を表示（ミッション達成の順番が順不同なケースで利用）
 * check: ハニカム内にチェックマークを表示
 * circuit: ハニカム内にサーキットのアイコンを表示
 */
export type HoneycombType = 'count' | 'countNoOrder' | 'check' | 'circuit'

/**
 * ミッショングループコードの型
 * フロント側で定義している値
 * グルーピング不要のミッションについては「ミッショングループコード = ミッションコード」としている
 */
export type MissionGroupCodeType =
  | 'CONSECUTIVE_LOGINS'
  | 'CUMULATIVE_LOGINS'
  | 'WATCHED_HIGHTLIGHT'
  | 'WATCHED_FAVORITE_PLAYER_RADIO'
  | 'POSTED_ON_SNS'
  | 'INVITED_USING_REFERRAL_CODE'
  | 'ANNUAL_PAID_MEMBER' // バッチで処理してるためミッションマスタには存在しない
  | 'MONTHLY_PAID_MEMBER' // バッチで処理してるためミッションマスタには存在しない
  | 'REGISTERED_PROFILE'
  | 'REGISTERED_FAVORITE'
  | 'WATCHED_LIVE_VIDEO'
  | 'CHECKED_IN_CIRCUIT'
  | 'CHECKED_IN_BONUS'
  | 'SEASON_ROUND_CHECK_IN'

/**
 * ミッション達成状況レイアウト、テキスト表示データの型
 */
type ProgressDesignType = {
  missionGroupCode: MissionGroupCodeType
  // ミッショングループコード
  missionCode?: MissionCodeType
  // ミッショングループにするミッションコードのリスト
  missionCodeList?:
    | typeof ROUND_CHECK_IN_MISSION_CODE_LIST
    | typeof REGISTERED_PROFILE_MISSION_CODE_LIST
    | typeof CIRCUIT_CHECK_IN_MISSION_CODE_LIST
    | typeof EVENT_CHECK_IN_MISSION_CODE_LIST
    | typeof REGISTERED_FAVORITE_MISSION_CODE_LIST
  // ミッション運達成状況カードの色
  colorType: string
  // ヘッダーアイコン
  headerIcon: HeaderIconType
  // 運達成状況テキスト表示
  achievementCountDispType?: AchievementCountDispType
  // ハニカム表示
  honeycombType?: HoneycombType
  // 表示順。以下デザインの順に表示
  // https://www.figma.com/file/T65pYkrZTc8ZxrWX9ILqDp/SFgo-SP?type=design&node-id=32-8256&mode=dev
  order: number
}

/**
 * ミッション画面のミッション達成状況レイアウト、テキスト表示に関する機能を提供する。
 */
export default function useMissionProgressDesign() {
  /**
   * ミッションコードに対応するカードの色を返す
   */
  const progressDesigns: Array<ProgressDesignType> = [
    // サーキットチェックイン
    {
      missionGroupCode: 'CHECKED_IN_CIRCUIT',
      missionCodeList: CIRCUIT_CHECK_IN_MISSION_CODE_LIST,
      colorType: '1',
      headerIcon: 'map-icon',
      achievementCountDispType: undefined,
      honeycombType: 'circuit',
      order: 1,
    },
    // シーズンラウンドチェックイン
    {
      missionGroupCode: 'SEASON_ROUND_CHECK_IN',
      missionCodeList: ROUND_CHECK_IN_MISSION_CODE_LIST,
      colorType: '1',
      headerIcon: 'map-icon',
      achievementCountDispType: undefined,
      honeycombType: 'countNoOrder',
      order: 2,
    },
    // 周辺チェックイン
    {
      missionGroupCode: 'CHECKED_IN_BONUS',
      missionCodeList: EVENT_CHECK_IN_MISSION_CODE_LIST,
      colorType: '1',
      headerIcon: 'map-icon',
      achievementCountDispType: 'countUp',
      honeycombType: undefined,
      order: 3,
    },
    // 映像を視聴
    {
      missionGroupCode: 'WATCHED_LIVE_VIDEO',
      missionCode: 'WATCHED_LIVE_VIDEO',
      colorType: '2',
      headerIcon: 'race-icon',
      achievementCountDispType: 'default',
      honeycombType: 'count',
      order: 4,
    },
    // ハイライト映像を視聴
    {
      missionGroupCode: 'WATCHED_HIGHTLIGHT',
      missionCode: 'WATCHED_HIGHTLIGHT',
      colorType: '2',
      headerIcon: 'clip-icon',
      achievementCountDispType: 'default',
      honeycombType: 'count',
      order: 5,
    },
    // お気に入り選手の無線を視聴
    {
      missionGroupCode: 'WATCHED_FAVORITE_PLAYER_RADIO',
      missionCode: 'WATCHED_FAVORITE_PLAYER_RADIO',
      colorType: '2',
      headerIcon: 'radiophone-icon',
      achievementCountDispType: 'default',
      honeycombType: 'count',
      order: 6,
    },
    // 年齢を登録
    {
      missionGroupCode: 'REGISTERED_PROFILE',
      missionCodeList: REGISTERED_PROFILE_MISSION_CODE_LIST,
      colorType: '3',
      headerIcon: 'member-icon',
      achievementCountDispType: undefined,
      honeycombType: 'check',
      order: 7,
    },
    // お気に入り選手を登録
    {
      missionGroupCode: 'REGISTERED_FAVORITE',
      missionCodeList: REGISTERED_FAVORITE_MISSION_CODE_LIST,
      colorType: '3',
      headerIcon: 'member-icon',
      achievementCountDispType: undefined,
      honeycombType: 'check',
      order: 8,
    },
    // 連続ログイン
    {
      missionGroupCode: 'CONSECUTIVE_LOGINS',
      missionCode: 'CONSECUTIVE_LOGINS',
      colorType: '3',
      headerIcon: 'member-icon',
      achievementCountDispType: 'login',
      honeycombType: undefined,
      order: 9,
    },
    // 累計ログイン
    {
      missionGroupCode: 'CUMULATIVE_LOGINS',
      missionCode: 'CUMULATIVE_LOGINS',
      colorType: '3',
      headerIcon: 'member-icon',
      achievementCountDispType: 'default',
      honeycombType: 'count',
      order: 10,
    },
    // SFgoを友達に紹介
    {
      missionGroupCode: 'INVITED_USING_REFERRAL_CODE',
      missionCode: 'INVITED_USING_REFERRAL_CODE',
      colorType: '4',
      headerIcon: 'share-icon',
      achievementCountDispType: undefined,
      honeycombType: 'count',
      order: 13,
    },
    // SNSへ投稿
    {
      missionGroupCode: 'POSTED_ON_SNS',
      missionCode: 'POSTED_ON_SNS',
      colorType: '4',
      headerIcon: 'share-icon',
      achievementCountDispType: 'default',
      honeycombType: 'count',
      order: 14,
    },
  ]

  // 年額プラン登録/継続
  const annualPaidMember: ProgressDesignType = {
    missionGroupCode: 'ANNUAL_PAID_MEMBER',
    missionCode: 'ANNUAL_PAID_MEMBER',
    colorType: '3',
    headerIcon: 'member-icon',
    achievementCountDispType: undefined,
    honeycombType: 'check',
    order: 11,
  }
  // 月額プラン登録/継続
  const monthlyPaidMember: ProgressDesignType = {
    missionGroupCode: 'MONTHLY_PAID_MEMBER',
    missionCode: 'MONTHLY_PAID_MEMBER',
    colorType: '3',
    headerIcon: 'member-icon',
    achievementCountDispType: 'continuation',
    honeycombType: undefined,
    order: 12,
  }

  return {
    progressDesigns,
    annualPaidMember,
    monthlyPaidMember,
  }
}
