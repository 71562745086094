

























































import { computed, defineComponent, nextTick, PropType, ref, watch } from '@vue/composition-api'
import CalendarCheckIcon from '@/components/LinkPage/CalendarPane/parts/icons/CalendarCheckIcon.vue'

export type DropDownDataType = {
  id: number | string
  name: string
}

export default defineComponent({
  name: 'AtomDropdown',
  components: { CalendarCheckIcon },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    selectedOptions: {
      type: Array as PropType<DropDownDataType[]>,
      default: () => [],
    },
    menuList: {
      type: Array as PropType<DropDownDataType[]>,
      required: true,
    },
    isOpenMenu: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: 'none',
    },
    hasCheck: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    menuPosition: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const menu = ref<HTMLElement | null>(null)
    const offsetPositionY = ref<number>(props.menuPosition)
    const buttonLabel = computed(() => {
      if (props.selectedOptions?.length > 0) {
        return props.selectedOptions.map((option) => option.name).join(',')
      }
      return props.placeholder
    })

    /**
     * 選択中かどうか
     */
    const isSelected = (item: DropDownDataType) =>
      props.selectedOptions.some((option) => option.id === item.id && option.name === item.name)

    const handleCurrentClicked = () => {
      emit('onClickCurrent')
    }
    const handleOptionClicked = (option: DropDownDataType) => {
      emit('onClickOption', option)
    }

    watch(
      () => props.isOpenMenu,
      (newVal) => {
        if (newVal) {
          nextTick(() => {
            const targetElm = document.querySelectorAll('.atom-dropdown__option--selected')[0]
            const menuPositionY = menu.value?.getBoundingClientRect().top || 0
            if (targetElm) {
              offsetPositionY.value = targetElm.getBoundingClientRect().top - menuPositionY
            }
            menu.value?.scrollTo(0, offsetPositionY.value)
          })
        }
      },
    )

    return {
      menu,
      buttonLabel,
      isSelected,
      handleCurrentClicked,
      handleOptionClicked,
    }
  },
})
