






import { defineComponent } from '@vue/composition-api'
import MissionListPane from '@/components/MissionPage/MissionListPane.vue'

export default defineComponent({
  name: 'MissionPage',
  components: {
    MissionListPane,
  },
})
