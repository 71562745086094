import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import Const from '@/util/Const'

/**
 * コースの型
 */
export type CourseType = keyof typeof Const.HOME_COUNTDOWN_IMAGE_PATH

/**
 * イベント主体のユニオン型
 * レースかイベントのいずれか
 */
export type SubjectiveEventType = 'race' | 'event'

/**
 * イベント情報を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/314212635
 */
export default class CountdownEventDocument extends DocumentWrapper {
  constructor(initProps?: Partial<CountdownEventDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/record/game_event'

  idAttr = 'eventId'

  /**
   * 試合開始日時
   */
  startDate: number | null = null

  /**
   * イベント時間
   */
  eventTime: number | null = null

  /**
   * 追加情報
   */
  additionalData?: {
    /**
     * 種別（race or event）
     */
    type: SubjectiveEventType

    /**
     * サーキット名
     */
    circuit: CourseType

    /**
     * イベント名
     */
    eventName: Multilingual

    /**
     * カウントダウンにチケットボタンを表示するかどうか
     */
    isShowTicketButton: boolean
  }
}
