var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.messageDialogStore.isVisible,
        expression: "messageDialogStore.isVisible",
      },
    ],
    on: {
      close: function ($event) {
        return _vm.clickedButton()
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _vm.messageDialogStore.title
                ? _c("modal-title-parts", {
                    attrs: { title: _vm.messageDialogStore.title },
                  })
                : _vm._e(),
              _c("modal-message-parts", {
                attrs: { message: _vm.messageDialogStore.message },
              }),
              _vm.messageDialogStore.declineCode ||
              _vm.messageDialogStore.description ||
              _vm.messageDialogStore.nextUserAction
                ? _c("modal-decline-message", {
                    attrs: {
                      declineCode: _vm.messageDialogStore.declineCode,
                      description: _vm.messageDialogStore.description,
                      nextUserAction: _vm.messageDialogStore.nextUserAction,
                    },
                  })
                : _vm._e(),
              _vm.messageDialogStore.apiRequestErrorMessage
                ? _c("modal-message-parts", {
                    attrs: {
                      message: _vm.messageDialogStore.apiRequestErrorMessage,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "message-modal-section--content" },
                [_vm._t("default")],
                2
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "buttons",
          fn: function () {
            return [
              _c("atom-input-button", {
                attrs: {
                  linkText: _vm.$t("common.ok"),
                  colorType: "secondary",
                  isDisabled: false,
                },
                on: {
                  push: function ($event) {
                    return _vm.clickedButton()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }