import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import PasswordResetChangeTokenDocument from '@/store/stores/collectionModule/documents/user/PasswordResetTokenDocument'
import PasswordResetChangeDocument from '@/store/stores/collectionModule/documents/user/PasswordResetDocument'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * メールアドレス変更を操作するための処理を取得する。
 */
export default function usePasswordResetToken() {
  // Collection modules
  const validateEmailModule = CollectionModule.createStore(DocumentWrapper)
  const passwordResetChangeTokenDocument = CollectionModule.createStore(
    PasswordResetChangeTokenDocument,
  )
  const passwordResetChangeDocument = CollectionModule.createStore(PasswordResetChangeDocument)

  // API Requests
  /**
   * 指定されたメールアドレスが、すでに登録済みかどうかを判定する。
   * @param mailAddress メールアドレス
   * @param reCaptchaToken reCaptchaトークン
   * @return {Promise<APIResponse>} APIレスポンス
   */
  const checkEmailAddressDuplicated = (mailAddress: string, reCaptchaToken: string) =>
    validateEmailModule.fetch({
      url: `${
        process.env.VUE_APP_API_BASE_URL as string
      }/manage/service_registration/validate/mail_address/${mailAddress}?reCaptchaToken=${reCaptchaToken}`,
      query: {
        limit: 0, // パラメータにlimitを含めたくないため、0を指定
      },
      excludeFilter: true,
    })

  /**
   * パスワードリセットのトークンを取得する。
   * @return { Promise<APIResponse> } APIレスポンス
   */
  const registerToken = (mailAddress: string, reCaptchaToken: string) =>
    passwordResetChangeTokenDocument.save(
      new PasswordResetChangeTokenDocument({
        reCaptchaToken,
        mailAddress,
      }),
      { url: `${process.env.VUE_APP_API_BASE_URL as string}/manage/password_reset_token` },
    )

  /**
   * パスワードリセットする。
   * @return { Promise<APIResponse> } APIレスポンス
   */
  const passwordReset = (tokenId: string, password: string) =>
    passwordResetChangeDocument.save(
      new PasswordResetChangeDocument({
        _id: tokenId,
        password,
      }),
      {
        url: `${
          process.env.VUE_APP_API_BASE_URL as string
        }/manage/password_reset_token/${tokenId}/reset`,
      },
    )

  return {
    checkEmailAddressDuplicated,
    registerToken,
    passwordReset,
  }
}
