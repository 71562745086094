var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signup-header-section" }, [
    _vm.steps > 0
      ? _c("h4", { staticClass: "signup-header-section__steps" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$tc("SignupPage.Steps.label")) +
              " " +
              _vm._s(_vm.steps) +
              "/3 "
          ),
        ])
      : _vm._e(),
    _c("h3", { staticClass: "signup-header-section__title" }, [
      _vm._v(_vm._s(_vm.pageTitle)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }