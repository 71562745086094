var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-next-plan-section" }, [
    _c("h4", { staticClass: "mypage-next-plan-section__title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$tc("MypagePage.MypageContract.detailsPage.nextPlan")) +
          " "
      ),
    ]),
    _c("div", { staticClass: "mypage-next-plan-section__plan" }, [
      _vm._v(" " + _vm._s(_vm.nextPlanName) + " "),
      _c("p", {
        staticClass: "mypage-next-plan-section__plan__attention",
        domProps: { innerHTML: _vm._s(_vm.attention) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }