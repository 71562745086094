import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * チーム情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/314212483/10+API
 */
export default class TeamDocument extends DocumentWrapper {
  constructor(initProps?: Partial<TeamDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/team'

  idAttr = '_id'

  /**
   * チームID
   */
  teamId: string | null = null

  /**
   * チーム名
   */
  teamName: Multilingual = {
    ja: '',
    en: '',
  }

  teamShortName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * チーム画像
   */
  teamLogo: string | null = null

  /**
   * チームID
   */
  get sid() {
    return this.teamId || ''
  }

  /**
   * チーム写真ファイルのパス。
   */
  get teamLogoImagePath() {
    return this.teamLogo ? this.teamLogo : 'assets/img/common/card/icon_team__null.svg'
  }
}
