import { computed, reactive, Ref } from '@vue/composition-api'
import { sortBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import type { Response } from '@/store/stores/collectionModule/CollectionTypes'
import Logger from '@/util/logger/Logger'
import useRadioPlayer from '@/components/hook/useRadioPlayer'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * 無線交信データストアの状態の型
 */
type StateType = {
  /** 現在選択されている無線データ */
  selectedRadioId: string | null
  /** 現在無線交信データを再生しているかどうか */
  playingRadioNow: boolean
  /** 無線交信データのLIVE再生(自動再生) 有効/無効 */
  livePlayerRadioEnabled: boolean
}
/**
 * 無線交信データのストアを操作するための処理を取得する。
 */
export default function useRadioData(targetRace: Ref<RaceDocument | null>) {
  // 無線情報のコレクション
  const radioCollectionModule = CollectionModule.createStore(RadioDataDocument)

  /**
   * 無線交信データストアの状態
   */
  const state = reactive({
    selectedRadioId: null,
    playingRadioNow: false,
    livePlayerRadioEnabled: true,
  } as StateType)

  /**
   * 無線交信データのLIVE再生(自動再生)の有効/無効を設定する。
   */
  const livePlayerRadioEnabled = computed({
    set: (livePlayerRadioEnabledValue: boolean) => {
      state.livePlayerRadioEnabled = livePlayerRadioEnabledValue
    },
    get: (): boolean => state.livePlayerRadioEnabled,
  })

  /**
   * 指定したレースの指定した時間帯の無線交信データを取得する。
   *
   * @param {RaceDocument} race レース
   * @param {number} fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param {number} toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchRadios = async (
    race: RaceDocument,
    fromDate: number,
    toDate: number,
  ): Promise<Response<RadioDataDocument>> =>
    radioCollectionModule.fetch({
      query: {
        filter: {
          raceDate: race.scheduleDate,
          fromDate,
          toDate,
        },
      },
      isSaveInStore: true,
      isUnionExistData: true,
    })

  /**
   * データをCollectionModuleに保存する
   */
  const setRadios = (radioData: Array<RadioDataDocument>) =>
    radioCollectionModule.setData(radioData)

  /**
   * ストアが保持している無線交信データ一覧を取得する。
   * 無線交信データはアクセスが制限される。以下の条件でフィルタする。
   * - 対象のレースのraceTypeが決勝（フリー走行、予選では表示しない）
   * - レースの開始、終了日時の範囲内かどうか
   * - 無線データのステータスが公開かどうか
   */
  const radios = computed(() =>
    radioCollectionModule.data.filter(
      (radioData) =>
        targetRace?.value?.additionalData?.raceType === 'RACE' &&
        radioData.isInRaceTimePeriod(targetRace.value) &&
        !radioData.private,
    ),
  )

  /**
   * 所持している無線交信データのうち、最後に作成された無線の作成日時(UnixTime)を返す.
   */
  const latestRadioDataCreated = () => {
    const latestRadioData = sortBy(radioCollectionModule.data, 'clip_create_time').reverse()[0]
    return latestRadioData?.clip_start_time
  }

  /**
   * 指定した時間に存在する無線交信データを取得する。
   * @param time UNIXタイム(ミリ秒)
   * @param timeRange 無線交信データの開始位置から、何秒先までを検索範囲とするかを指定する。
   * 未指定の場合、無線交信データのdurationを利用する。
   */
  const getRadioDataSetAtTime = (time: number, timeRange?: number) =>
    radios.value.filter((radio) => {
      if (radio.clip_start_time) {
        const duration = radio.duration ? radio.duration : 10
        const range = !timeRange ? duration : timeRange
        return time >= radio.clip_start_time && radio.clip_start_time + range * 1000 >= time
      }
      return false
    })

  /**
   * 指定された車両の指定した時間の無線データを取得する。
   *
   * @param carNo 車両番号
   * @param time UNIXタイム(ミリ秒)
   */
  const getPlayerRadioDataByTime = (carNo: number, time: number) => {
    const currentRadios = getRadioDataSetAtTime(time)
    return currentRadios.find((radio) => radio.car_no === carNo)
  }

  /**
   * 下記の条件の無線データを取得する。
   * - 指定された車両
   * - 指定された位置から指定された秒数先まで
   */
  const getPlayerRadioDataSetFilteredTime = (
    carNo: number,
    clipStartTimeFrom: number,
    clipEndTimeFrom: number,
  ) =>
    radios.value.filter((radio) => {
      if (radio.clip_start_time && radio.car_no === carNo) {
        return (
          clipStartTimeFrom <= radio.clip_start_time && radio.clip_start_time <= clipEndTimeFrom
        )
      }
      return false
    })

  /**
   * 指定された車両の指定した時間以降に最初に登録された無線データを取得する。
   *
   * @param carNo 車両番号
   * @param time UNIXタイム(ミリ秒)
   * @param limit timeからこの時間以上離れているデータは対象としない（ミリ秒）
   */
  const getPlayerRadioDataClosestTime = (carNo: number, time: number, limit: number = 1000 * 60) =>
    sortBy(radios.value, 'clip_start_time').find(
      (radio) => radio.clip_start_time > time && radio.clip_start_time < time + limit,
    )

  // 無線交信データ再生プレーヤー (インスタンスを1つのみとするため、ストアに持たせる)
  const { audioPlayer: radioPlayer, initAudioPlayer, deleteAudioPlayer } = useRadioPlayer()

  /**
   * 現在選択されている（再生されている）無線交信データを取得する。
   *
   */
  const getSelectedRadioData = () =>
    radioCollectionModule.data.find((radio) => radio.audio_clip === state.selectedRadioId)

  /**
   * 無線交信データの音声を再生する。
   * 再生と同時に、対象の無線交信データを選択状態とする。
   * @param playerRadioData 無線交信データ
   */
  const playRadioAudio = async (playerRadioData: RadioDataDocument) => {
    if (!playerRadioData) {
      return
    }

    try {
      state.selectedRadioId = playerRadioData.audio_clip
      const audioClipUrl = CloudFrontUtil.getSignedUrl(playerRadioData.audio_clip)
      radioPlayer.value?.setSrc(audioClipUrl)
      await radioPlayer.value?.play()
    } catch (e) {
      if (state.selectedRadioId) {
        state.selectedRadioId = null
      }
    }
  }

  /**
   * 無線交信データの再生を停止する。
   */
  const pauseRadioAudio = async () => {
    await radioPlayer.value?.pause()
  }

  /**
   * 指定された無線交信データのリアクティブデータを変更する。
   *
   * 無線交信データ(RadioDataDocument)は、リアクティブなオブジェクトとして、stateというフィールドを保持している。
   * このstateの変更は、状態を監視しているコンポーネントに通知される。
   * @see RadioDataDocument#state
   *
   * @param radioData 無線交信データ
   * @param isPlaying 音声を再生中かどうか
   * @param currentTime 音声の再生位置
   */
  const updateRadioData = (
    radioData: RadioDataDocument,
    isPlaying: boolean,
    currentTime: number,
  ) => {
    if (!radioData) {
      return
    }
    const targetRadioData = radioData
    targetRadioData.state.isPlaying.value = isPlaying
    targetRadioData.state.currentTime.value = currentTime
  }

  /**
   * 無線交信データの音声の再生が開始された場合に呼び出されるコールバック関数。
   */
  const onPlayRadioAudio = () => {
    state.playingRadioNow = true
    const selectedRadioData = getSelectedRadioData()
    if (selectedRadioData) {
      updateRadioData(selectedRadioData, true, 0)
    }
  }

  /**
   * 無線交信データの音声の再生が停止された場合に呼び出されるコールバック関数。
   */
  const onPauseRadioAudio = () => {
    state.playingRadioNow = false
    const selectedRadioData = getSelectedRadioData()
    if (selectedRadioData) {
      updateRadioData(selectedRadioData, false, 0)
    }
    state.selectedRadioId = null
  }

  /**
   * 無線交信データの音声を最後まで再生し、再生が停止された場合に呼び出されるコールバック関数。
   */
  const onEndedRadio = () => {
    state.playingRadioNow = false
    const selectedRadioData = getSelectedRadioData()
    if (selectedRadioData) {
      updateRadioData(selectedRadioData, false, 0)
    }
    state.selectedRadioId = null
  }

  /**
   * 無線交信データの音声の再生位置が変更された場合に呼び出されるコールバック関数。
   */
  const onTimeUpdated = async () => {
    const selectedRadioData = getSelectedRadioData()
    if (selectedRadioData) {
      const currentTime = await radioPlayer.value?.getCurrentTime()
      if (currentTime) {
        updateRadioData(selectedRadioData, true, currentTime)
        Logger.debug(
          `radioPlayer.currentTime: ${currentTime}, selectedRadioData.currentTime: ${selectedRadioData.state.currentTime.value}`,
        )
      }
    }
  }

  /**
   * 無線交信データの音声の再生を行なっているかどうかを判定する。
   */
  const isPlayingRadio = computed(() => state.playingRadioNow)

  const createAudioPlayer = () => {
    // 音声プレイヤーを初期化
    initAudioPlayer()

    /**
     * 無線交信データ再生用のプレーヤーの各再生イベントにリスナーを追加する。
     */
    radioPlayer.value?.addEventListener('play', onPlayRadioAudio)
    radioPlayer.value?.addEventListener('pause', onPauseRadioAudio)
    radioPlayer.value?.addEventListener('ended', onEndedRadio)
    radioPlayer.value?.addEventListener('timeupdate', onTimeUpdated)
  }

  const clearAudioPlayer = () => {
    // NOTE: removeEventListenerでイベントリスナーを解除できなかったっため、音声プレイヤーを破棄してイベントリスナーを解除している
    deleteAudioPlayer()
  }

  /**
   * 無線交信データストアをクリアする
   */
  const clearRadioData = () => {
    radioCollectionModule.clearData()
    pauseRadioAudio().then(() => Logger.debug('Radio audio player stopped playing.'))
  }

  return {
    fetchRadios,
    setRadios,
    radios,
    clearRadioData,
    getRadioDataSetAtTime,
    getPlayerRadioDataByTime,
    getPlayerRadioDataClosestTime,
    getSelectedRadioData,
    getPlayerRadioDataSetFilteredTime,
    radioPlayer,
    playRadioAudio,
    pauseRadioAudio,
    isPlayingRadio,
    livePlayerRadioEnabled,
    latestRadioDataCreated,
    createAudioPlayer,
    clearAudioPlayer,
  }
}
