









import { defineComponent } from '@vue/composition-api'
import PaidMembershipChangeConfirmPane from '@/components/pc/MyPage/PaidMemberShipChangePage/PaidMembershipChangeConfirmPane.vue'

/**
 * 有料会員変更 確認ページ
 */
export default defineComponent({
  name: 'PaidMembershipChangeConfirmPage',
  components: {
    PaidMembershipChangeConfirmPane,
  },
  methods: {
    /**
     * 登録完了ページに遷移する
     */
    moveToPaidMembershipChangeComplete() {
      this.$router.replace({ name: 'PaidMembershipChangeCompletePage' })
    },
    /**
     * プラン選択画面へ遷移する
     */
    handlerCancel() {
      this.$router.replace({ name: 'PaidMembershipChangeEditPage' })
    },
  },
})
