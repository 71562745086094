var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-view-change-parts" },
    [
      _c("atom-input-password", {
        staticClass: "password-view-change-parts__input",
        attrs: {
          id: _vm.id,
          textVisible: _vm.textVisible,
          error: _vm.error,
          placeholder: _vm.placeholder,
          isReadOnly: _vm.isReadOnly,
        },
        on: { enter: _vm.emitEnter, focus: _vm.emitFocus, blur: _vm.emitBlur },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      !_vm.isReadOnly
        ? _c(
            "button",
            {
              staticClass: "password-view-change-parts__button",
              class: {
                "password-view-change-parts__button--text": _vm.textVisible,
              },
              attrs: { type: "button" },
              on: { click: _vm.changeInputType },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "18",
                    height: "18",
                    viewBox: "0 0 18 18",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M2 9L5.43826 6.24939C6.44922 5.44062 7.70534 5 9 5C10.2947 5 11.5508 5.44062 12.5617 6.24939L16 9L12.5617 11.7506C11.5508 12.5594 10.2947 13 9 13C7.70534 13 6.44922 12.5594 5.43826 11.7506L2 9ZM9 11.5C10.3807 11.5 11.5 10.3807 11.5 9C11.5 7.61929 10.3807 6.5 9 6.5C7.61929 6.5 6.5 7.61929 6.5 9C6.5 10.3807 7.61929 11.5 9 11.5Z",
                      fill: "#000",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }