var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.highlightData
    ? _c(
        "section",
        {
          staticClass: "new-highlights-input-section",
          class: "new-highlights-input-section--" + _vm.screenOrientationType,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return function () {}.apply(null, arguments)
            },
          },
        },
        [
          _c("label", { attrs: { for: "comment" } }, [
            _c("span", {
              staticClass: "new-highlights-input-section__title",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
            _c(
              "span",
              { staticClass: "new-highlights-input-section__content" },
              [
                _c("highlights-card-section", {
                  key: _vm.videoPlayerKey,
                  attrs: {
                    highlightData: _vm.highlightData,
                    screenOrientationType: _vm.screenOrientationType,
                  },
                }),
                _c("comment-input-section", {
                  staticClass: "new-highlights-input-section__comment",
                  attrs: {
                    targetEnableInput: true,
                    targetInputHighlightComment:
                      _vm.targetInputHighlightComment,
                    postX: _vm.postX,
                    postInstagram: _vm.postInstagram,
                    hasShare: true,
                    editType: _vm.editType,
                    postedSns: _vm.postedSns,
                  },
                  on: {
                    handlerSubmit: _vm.newHighlightsCard,
                    onChangePostX: _vm.onChangePostX,
                    onChangePostInstagram: _vm.onChangePostInstagram,
                    onChangeHighlightComment: _vm.changeHighlightComment,
                    onToggleEnabledInput: _vm.emitClose,
                    showHighlightPreviewVideoPlayer:
                      _vm.showHighlightPreviewVideoPlayer,
                    hideHighlightPreviewVideoPlayer:
                      _vm.hideHighlightPreviewVideoPlayer,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }