












import { defineComponent, ref } from '@vue/composition-api'
import MembershipCardPane from '@/components/MypageTopPage/MembershipCardPane.vue'
import MypageTopPane from '@/components/MypageTopPage/MypageTopPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * マイページ: トップページ
 */
export default defineComponent({
  name: 'MypageTopPage',
  components: {
    MembershipCardPane,
    GlobalNavigationPane,
    MypageTopPane,
  },
  props: {
    enabledListCarousel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /**
     * 会員証を編集モードで表示するかどうか
     * ハイライトコメントを入力しようとした際、SFgo用ニックネームが未入力だった場合にtrueになる
     */
    const isEditCardForHighlightComment = ref(false)

    return {
      isEditCardForHighlightComment,
    }
  },
  created() {
    const query = this.$route.query.isEditCard
    if (query && query === 'true') {
      this.isEditCardForHighlightComment = true
    }
  },
  methods: {
    /**
     * ログインページへ遷移
     * @event error-move
     */
    moveToLogin() {
      this.$router.replace({ path: '/login' })
    },
  },
})
