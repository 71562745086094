












































import { computed, defineComponent, PropType } from '@vue/composition-api'
import {
  UserDataType,
  MembersRankDataType,
} from '@/components/MypageTopPage/MembershipCardPane.vue'
import MemberBadgeListParts from '@/components/MypageTopPage/MembershipCardPane/parts/MemberBadgeListParts.vue'
import SwitchEditModeButtonParts from '@/components/MypageTopPage/MembershipCardPane/parts/SwitchEditModeButtonParts.vue'
import CurrentTimeParts from '@/components/MypageTopPage/MembershipCardPane/parts/CurrentTimeParts.vue'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'
import I18n from '@/locales/I18n'

/**
 * マイページ: 会員証 表示モードセクション
 */
export default defineComponent({
  name: 'MembershipCardViewModeSection',
  components: {
    SwitchEditModeButtonParts,
    MemberBadgeListParts,
    CurrentTimeParts,
  },
  props: {
    userData: {
      type: Object as PropType<UserDataType>,
      default: () => ({}),
      require: true,
    },
    membersRankData: {
      type: Object as PropType<MembersRankDataType>,
      default: () => ({}),
      require: true,
    },
    enabledListCarousel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { getUserRoleColor, getUserType } = useMembershipCard()

    const userName = computed(() => props.userData?.name || I18n.tc('common.displayNameNotSet'))
    const userThumbnail = computed(() => props.userData?.image)
    const userType = computed(() => getUserType(props.userData?.userRole))
    const roleColor = computed(() => getUserRoleColor(props.userData?.userRole))

    /**
     * 無料ユーザー判定取得
     */
    const isFreePlan = computed(() => props.userData.isFreePlan)

    return {
      userName,
      userThumbnail,
      userType,
      roleColor,
      isFreePlan,
    }
  },
  methods: {
    /**
     * 編集モードに切り替える
     */
    emitEdit() {
      this.$emit('edit')
    },
  },
})
