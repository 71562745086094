var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-edit-pane" },
    [
      _vm.state.cardLast4Digits
        ? _c("current-card-number-section", {
            attrs: { cardLast4Digits: _vm.state.cardLast4Digits },
          })
        : _vm._e(),
      _vm.state.clientSecret
        ? _c("credit-card-input-section", {
            key: _vm.state.stripeElementResetKey,
            attrs: {
              sectionTitle: _vm.$tc("PaymentPage.EditPage.newCard"),
              clientSecret: _vm.state.clientSecret,
            },
            on: { handlerCardNameErrors: _vm.updateHasCardNameErrors },
          })
        : _vm._e(),
      _c(
        "action-buttons-section",
        {
          staticClass: "payment-edit-pane__action-buttons",
          attrs: { scrollTargetSelector: ".payment-edit-pane", visible: true },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.registration"),
              isDisabled: _vm.state.isConfirmBtnDisabled,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.cancel"),
              isDisabled: false,
              colorType: "secondary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }