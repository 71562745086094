var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-switch-to-paid-plan-pane",
      style: { "padding-bottom": _vm.state.actionButtonsHeight + 22 + "px" },
    },
    [
      _c("comparison-plan-section", {
        attrs: {
          sectionTitle: _vm.$tc(
            "SignupPage.SelectPlanPage.comparison.comparisonDescriptionForFree"
          ),
        },
      }),
      _c(
        "action-buttons-section",
        {
          ref: "actionButtons",
          staticClass: "mypage-switch-to-paid-plan-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".mypage-switch-to-paid-plan-pane",
            visible: true,
          },
        },
        [
          _c(
            "ul",
            { staticClass: "mypage-switch-to-paid-plan-pane__action-list" },
            _vm._l(_vm.subscriptionItems, function (subscription, index) {
              return _c(
                "li",
                {
                  key: "subscription" + index,
                  staticClass: "mypage-switch-to-paid-plan-pane__action-item",
                },
                [
                  _c("mypage-select-plan-button-parts", {
                    attrs: {
                      name: subscription.name,
                      price: subscription.price,
                      unit: subscription.unit,
                      bargainPriced: subscription.bargainPriced,
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.purchase(subscription)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _c("atom-button", {
            staticClass: "mypage-switch-to-paid-plan-pane__action-restoration",
            attrs: {
              linkText: _vm.$tc(
                "MypagePage.MypageSwitchToPaidPlanPage.restoration"
              ),
              colorType: "tertiary",
            },
            on: {
              onClick: function ($event) {
                return _vm.restorePurchase()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }