var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-favorite-team-init-select-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc(
            "MypagePage.MypageFavoriteTeamSelectPage.pageTitle"
          ),
          backLink: "/mypage/init",
        },
      }),
      _c("mypage-favorite-team-init-select-pane", {
        staticClass: "mypage-favorite-team-init-select-page__content",
        on: { changed: _vm.moveToMypageUserInit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }