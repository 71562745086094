var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-language-setting-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageLanguageSettingPage.pageTitle"),
          backLink: "/mypage",
        },
      }),
      _c("mypage-language-setting-pane", {
        ref: "mypageLanguageSettingPane",
        staticClass: "mypage-language-setting-page__content",
        on: { "success-regist": _vm.moveToMypageTop },
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector: ".mypage-language-setting-page__content",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: { linkText: _vm.$tc("common.set"), isDisabled: false },
            on: { push: _vm.setLanguageSetting },
          }),
        ],
        1
      ),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
      _c("confirm-leaving-page-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.screenTransitionModal,
            expression: "screenTransitionModal",
          },
        ],
        attrs: { screenTransitionTo: _vm.screenTransitionTo },
        on: {
          close: function ($event) {
            _vm.screenTransitionModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }