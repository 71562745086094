




import { ref, defineComponent, onMounted, onUnmounted, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import I18n from '@/locales/I18n'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * レース動画再生画面 ハイライト 投稿コメント パーツコンポーネント
 */
export default defineComponent({
  name: 'CommentPostDateParts',
  props: {
    postDate: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { getDisplayDate } = useDisplayDependingOnLang()

    /**
     * 投稿日時 変換
     * 本日のものは日付を「今日orToday HH:MM」表記へ変換
     * それ以外は「YYYY/MM/DD HH:MM」表記へ変換
     * @param date
     * @returns {string}
     */
    const convertDateFormat = (date: number): string =>
      dayjs().isSame(dayjs(date), 'day')
        ? `${I18n.tc('RaceVideoPage.highlights.comment.today')} ${dayjs(date).format('HH:mm')}`
        : getDisplayDate(date)

    let checkingDateDelay = 0
    const postDateTime = ref(convertDateFormat(props.postDate))

    /**
     * 作成日を監視
     */
    watch(
      () => props.postDate,
      (newPostDate) => {
        // postDateTimeがcomputedになっていない影響でハイライト作成時、1つ後ろに表示されるハイライトの作成日が表示されてしまうため、ハイライトが作成されたら表示する投稿日を更新する
        postDateTime.value = convertDateFormat(newPostDate)
      },
    )

    onMounted(() => {
      /**
       * 当日のコメントの場合、日付が変わったら再取得
       * `dayjs(dayjs().add(1, 'day').startOf('day')).diff()`
       * で翌日の0時までの時間を取得して、setTimeoutでその時間後に再取得する
       */
      if (dayjs().isSame(dayjs(props.postDate), 'day')) {
        checkingDateDelay = setTimeout(() => {
          postDateTime.value = convertDateFormat(props.postDate)
        }, dayjs(dayjs().add(1, 'day').startOf('day')).diff(new Date()))
      }
    })

    onUnmounted(() => {
      if (checkingDateDelay > 0) {
        clearTimeout(checkingDateDelay)
      }
    })

    return {
      postDateTime,
    }
  },
  methods: {},
})
