var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standings-list-section" },
    [
      _vm.standingsPlayerList && _vm.standingsPlayerList.length > 0
        ? _vm._l(_vm.standingsPlayerList, function (player, index) {
            return _c("standings-list-player-parts", {
              key: "standingsListPlayer" + index,
              staticClass: "standings-list-section__item",
              attrs: { standingsPlayer: player },
            })
          })
        : [
            _c("p", {
              staticClass: "standings-list-section__no-data",
              domProps: { innerHTML: _vm._s(_vm.$tc("StandingsPage.noData")) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }