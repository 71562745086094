var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "overlay" }, [
      _c("div", {
        staticClass: "overlay__mask",
        on: {
          click: function ($event) {
            return _vm.emitClose()
          },
        },
      }),
      _c("div", { staticClass: "overlay__wrapper" }, [
        _c("div", { staticClass: "overlay__content" }, [_vm._t("default")], 2),
        _vm.hasButtons
          ? _c(
              "div",
              { staticClass: "overlay__buttons" },
              [_vm._t("buttons")],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }