











import { defineComponent, PropType, reactive } from '@vue/composition-api'

import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import MailInputSection from '@/components/SignupPage/EmailAuthenticationPane/MailInputSection.vue'
import useUser from '@/store/hook/useUser'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import useReCaptcha from '@/components/hook/useReCaptcha'

/**
 * 新規会員登録: 認証メール入力ペインのコンポーネント
 */
export default defineComponent({
  name: 'EmailAuthenticationPane',
  components: {
    SignupHeaderSection,
    MailInputSection,
  },
  props: {
    /**
     * 会員登録タイプ
     */
    memberType: {
      type: String as PropType<MemberType>,
      required: true,
    },
  },
  setup(props) {
    const { registerToken } = useUser()
    const { getReCaptchaToken } = useReCaptcha()

    const state = reactive({
      isSendingMail: false,
    })

    /** 以下の処理を行う
     * 1. reCaptchaトークンを取得
     * 2. ユーザー登録ページのURLに付与するトークンを生成し、入力したメールアドレス宛てにメール送信
     */
    const getTokenAndSendCreateUserRequest = async (email: string) => {
      const reCaptchaToken = await getReCaptchaToken()
      // reCaptchaのトークン取得に失敗した場合、apiEventを作成して処理を終了する。
      if (!reCaptchaToken) {
        return Promise.resolve({
          isSuccess: false,
          res: { response: { data: { error_code: 'NETWORK_ERROR' } } },
        })
      }
      return registerToken(props.memberType, email, reCaptchaToken)
    }

    return {
      state,
      getTokenAndSendCreateUserRequest,
    }
  },
  methods: {
    async onFormSubmit(email: string) {
      this.state.isSendingMail = true
      const loader = this.$loading.show()
      const result = await this.getTokenAndSendCreateUserRequest(email)
      if (result.isSuccess) {
        /**
         * ボタンが押下されたことを通知する
         * @event sendMail
         */
        this.$emit('formSubmit')
      }
      this.state.isSendingMail = false
      loader.hide()
    },
  },
})
