



















import { computed, defineComponent } from '@vue/composition-api'
import FieldsetConfirmParts from '@/components/common/form/FieldsetConfirmParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * マイページ: 会員情報変更 メールアドレス フィールドパーツ
 */
export default defineComponent({
  name: 'FieldsetUserEditMailPasswordParts',
  components: {
    FieldsetConfirmParts,
    AtomInputButton,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isMask: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const mailOrPasswrodValue = computed(() => props.value)
    return {
      mailOrPasswrodValue,
    }
  },
  methods: {
    emitEnter() {
      /**
       * emitEnter
       * 変更ボタン押下処理
       */
      this.$emit('push')
    },
  },
})
