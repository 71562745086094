import { computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ResultDataDocument from '@/store/stores/collectionModule/documents/standings/ResultDataDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'

/**
 * Resultデータを操作するための処理を取得する。
 */
export default function useResultData() {
  // Collection modules
  const resultCollectionModule = CollectionModule.createStore(ResultDataDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * Resultデータを取得する。
   */
  const fetchResultData = async (raceYear: number) => {
    const fromDate = new Date(raceYear, 1, 1, 0, 0, 0).getTime()
    const toDate = new Date(raceYear, 12, 31, 23, 59, 59).getTime()

    const result = await searchDateRangeData({
      tableName: 'race-result-data',
      partitionKey: 'seasonYear',
      partitionKeyValue: String(raceYear),
      sortKey: 'championshipStartDate',
      from: fromDate,
      to: toDate,
    })
    resultCollectionModule.data = result as Array<ResultDataDocument>
  }

  /**
   * 取得したResultデータ一覧
   */
  const resultData = computed(() => resultCollectionModule.data)

  /**
   * Car No毎に分類されたResultデータ一覧
   * 代走で出場する場合があるため、同じCar Noでもドライバーが異なるケースが存在する
   */
  const resultListByCarNo = computed(() => {
    const ResultsMap: Record<string, Array<ResultDataDocument>> = {}
    resultData.value.forEach((raceResult) => {
      raceResult.results?.forEach((result) => {
        const clonedRaceResult = cloneDeep(raceResult)
        const relatedPlayerResult = raceResult.results?.find((v) => v.carNo === result.carNo)
        if (!relatedPlayerResult) {
          return
        }
        // 配列resultsの1つ目のデータに、resultsの中にkeyの背番号に一致する選手のResultデータのみを持たせる
        clonedRaceResult.results = [relatedPlayerResult]

        if (result.carNo && ResultsMap[`${result.carNo}`]?.length > 0) {
          ResultsMap[`${result.carNo}`].push(clonedRaceResult)
        } else {
          ResultsMap[`${result.carNo}`] = [clonedRaceResult]
        }
      })
    })
    return ResultsMap
  })

  /**
   * 大会毎に分類されたResultデータ一覧
   */
  const resultListByRound = computed(() => {
    const ResultsMap: Record<string, ResultDataDocument> = {}
    resultData.value.forEach((raceResult) => {
      if (raceResult.championshipMasterId) {
        ResultsMap[raceResult.championshipMasterId] = raceResult
      }
    })
    return ResultsMap
  })

  /**
   * 取得したResultデータをクリアする
   */
  const clearResultData = () => {
    resultCollectionModule.clearData()
  }

  return {
    fetchResultData,
    resultListByCarNo,
    resultListByRound,
    clearResultData,
  }
}
