var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plan-card-parts" }, [
    _c("div", { staticClass: "plan-card-parts__detail" }, [
      _c("h5", { staticClass: "plan-card-parts__title" }, [
        _vm._v(_vm._s(_vm.planName)),
      ]),
      !_vm.isConfirmed && !!_vm.planBargainPriced
        ? _c("p", { staticClass: "plan-card-parts__deals" }, [
            _vm._v(" " + _vm._s(_vm.planBargainPriced) + " "),
          ])
        : _vm._e(),
      !_vm.isConfirmed && !!_vm.planAttention
        ? _c("p", { staticClass: "plan-card-parts__deals" }, [
            _vm._v(" " + _vm._s(_vm.planAttention) + " "),
          ])
        : _vm._e(),
      !_vm.isConfirmed && !!_vm.planCaption
        ? _c("p", {
            staticClass: "plan-card-parts__caption",
            domProps: { innerHTML: _vm._s(_vm.planCaption) },
          })
        : _vm._e(),
      _vm.isConfirmed
        ? _c("p", { staticClass: "plan-card-parts__term" }, [
            _vm._v(_vm._s(_vm.getContractTermText)),
          ])
        : _vm._e(),
      !_vm.isConfirmed && !!_vm.planDescription
        ? _c("p", {
            staticClass:
              "plan-card-parts__description plan-card-parts__description--stand-out",
            domProps: { innerHTML: _vm._s(_vm.planDescription) },
          })
        : _vm._e(),
      !_vm.isConfirmed && !!_vm.planDescription2
        ? _c("p", {
            staticClass: "plan-card-parts__description",
            domProps: { innerHTML: _vm._s(_vm.planDescription2) },
          })
        : _vm._e(),
    ]),
    _c("p", { staticClass: "plan-card-parts__price" }, [
      _c("span", { staticClass: "plan-card-parts__price__value" }, [
        _vm._v(" " + _vm._s(_vm.planPrice) + _vm._s(_vm.planPriceUnit) + " "),
        _c("small", { staticClass: "plan-card-parts__price__tax" }, [
          _vm._v(_vm._s(_vm.$tc("SignupPage.SelectPlanPage.plan.taxIn"))),
        ]),
      ]),
      _c("span", { staticClass: "plan-card-parts__price__unit" }, [
        _vm._v("/ " + _vm._s(_vm.planPaymentUnit)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }