var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "race-list-page" },
    [
      _c("header-pane", { attrs: { pageTitle: "ROUNDS" } }),
      _c("race-list-pane", { staticClass: "race-list-page__contents" }),
      _c("global-navigation-pane", { attrs: { current: "race" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }