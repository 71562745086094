var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "24",
        viewBox: "0 0 25 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.75 3.75C8.19365 3.75 4.5 7.44365 4.5 12C4.5 16.5563 8.19365 20.25 12.75 20.25C17.3063 20.25 21 16.5563 21 12C21 7.44365 17.3063 3.75 12.75 3.75ZM3 12C3 6.61522 7.36522 2.25 12.75 2.25C18.1348 2.25 22.5 6.61522 22.5 12C22.5 17.3848 18.1348 21.75 12.75 21.75C7.36522 21.75 3 17.3848 3 12Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.75 17.75C15.9256 17.75 18.5 15.1756 18.5 12C18.5 8.82436 15.9256 6.25 12.75 6.25C9.57436 6.25 7 8.82436 7 12C7 15.1756 9.57436 17.75 12.75 17.75ZM12.75 19C16.616 19 19.75 15.866 19.75 12C19.75 8.13401 16.616 5 12.75 5C8.88401 5 5.75 8.13401 5.75 12C5.75 15.866 8.88401 19 12.75 19Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.125 8V3H13.375V8H12.125Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.125 21V16H13.375V21H12.125Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.75 12.625L3.75 12.625L3.75 11.375L8.75 11.375L8.75 12.625Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M21.75 12.625L16.75 12.625L16.75 11.375L21.75 11.375L21.75 12.625Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }