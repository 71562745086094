import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { LanguageType, Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { MembersRankNameType } from '@/util/Const'

/**
 * 動画プラットフォームコンテンツ情報の表示箇所の型定義
 */
export type DisplayLocationType = 'sfOfficial' | 'membersOnly' | 'headline' | 'motorsport'

/**
 * RACE POINT VIDEO/RACE POINT RADIOで使用するvideoType
 */
export type RacePointVideoType = 'video' | 'radio'

/**
 * 特典画像/動画で使用するvideoType
 */
export type RewordVideoType = 'rewardImage' | 'rewardVideo'

export type VideoPlatformContentsVideoType = RacePointVideoType | RewordVideoType

/**
 * 動画プラットフォームコンテンツ情報を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3403808769/API
 */
export default class VideoPlatformContentsDocument extends DocumentWrapper {
  constructor(initProps?: Partial<VideoPlatformContentsDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/video_platform_contents'

  idAttr = 'videoContentsId'

  /**
   * 動画コンテンツID
   */
  videoContentsId: string | null = null

  /**
   * 動画プラットフォーム名
   */
  videoPlatform: string | null = null

  /**
   * 動画種別
   */
  videoType: VideoPlatformContentsVideoType | null = null

  /**
   * タイトル
   */
  title: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 動画識別子
   */
  videoId: string | null = null

  /**
   * 動画サムネイルパス
   */
  videoThumbnailPath: string | null = null

  /**
   * 動画の言語
   */
  videoLang: LanguageType | null = null

  /**
   * 開始日時
   */
  startDate: number | null = null

  /**
   * 終了日時
   */
  endDate: number | null = null

  additionalData?: {
    /**
     * このコンテンツの表示場所
     */
    displayLocation?: DisplayLocationType
    /**
     * このコンテンツが有料会員限定かどうか
     */
    isPaidMemberOnly?: boolean
    /**
     * Race Point Video/Radioの説明
     */
    description?: Multilingual | null
    /**
     * Race Point Radio
     * 無線更新データAPIで取得する無線のraceDate
     */
    raceDate?: string
    /**
     * Race Point Radio
     * 無線更新データAPIで取得する無線のlastModifiedDate
     */
    lastModifiedDate?: number
    /**
     * Race Point Radio
     * 無線更新データのcar_no
     */
    carNo?: number
    /**
     * 会員ランク特典画像・映像の対象ランクリスト
     */
    targetMemberRanks?: Array<MembersRankNameType>
  }

  /**
   * 対象ランクリスト
   */
  get targetMemberRanks() {
    return this.additionalData?.targetMemberRanks || []
  }

  /**
   * 説明
   */
  get description() {
    return this.additionalData?.description || null
  }

  /**
   * 指定された言語のユーザーに表示可能かどうかを判定する
   *
   * @param lang 言語
   */
  canDisplay(lang: keyof Multilingual | undefined | null) {
    if (!this.videoLang) {
      // 言語未設定の場合、どのユーザーでも参照可能
      return true
    }
    return lang === this.videoLang
  }
}
