import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

/**
 * RadioDataDocumentに追加で値を付与する際に使用するhook
 */
export default function useAddInfoToRadioData() {
  /**
   * 無線交信データに、選手の情報を追加する。
   * @param radio 選手情報を付与する無線交信データ
   * @param player 選手情報
   */
  const addPlayerDataToRadioData = (
    radio: RadioDataDocument,
    player: PlayerDocument,
  ): RadioDataDocument => {
    const playerRadioData = radio
    playerRadioData.playerName = player.getPlayerName()
    playerRadioData.playerPictureImagePath = player.playerPictureImagePath
    playerRadioData.sid = player.sid
    playerRadioData.playerId = player.id ?? ''
    // 省略表記は英語のみ利用する
    playerRadioData.abbreviation = {
      ja: player.playerShortName.en,
      en: player.playerShortName.en,
    }

    return playerRadioData
  }

  return {
    addPlayerDataToRadioData,
  }
}
