













import { computed, defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageContractLogPane from '@/components/MypageContractPage/MypageContractLogPane.vue'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import DeviceInfo from '@/util/DeviceInfo'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * マイページ: プラン契約履歴ページ
 */
export default defineComponent({
  name: 'MypageContractLogPage',
  components: {
    GlobalNavigationPane,
    SubHeaderSection,
    MypageContractLogPane,
  },
  setup() {
    /**
     * アプリから遷移したかどうか
     */
    const transitionFromMobileApp = computed(() => LocalStorageAccessor.transitionFromMobileApp)
    /**
     * /Androidブラウザで開いている場合はアプリに戻るようにするための条件
     * TODO: ※iPhoneからアプリに戻れないバグの対応が出来次第、「アプリから遷移した場合はアプリに戻る」という挙動にする。
     * 現在はAndroidのみアプリに戻るようにする
     */
    const canReturnToApp = computed(
      () => transitionFromMobileApp.value && DeviceInfo.isAndroidBrowser(),
    )

    const mypageUrl = computed(() =>
      // アプリから遷移 & Androidブラウザで開いてる場合は、アプリに戻るようにする。
      canReturnToApp.value
        ? `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`
        : '/mypage',
    )

    return {
      mypageUrl,
      canReturnToApp,
    }
  },
})
