





















import { computed, defineComponent, PropType } from '@vue/composition-api'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import useDisplayTicketData from '@/components/MypageTicketListPage/hook/useDisplayTicketData'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'

export default defineComponent({
  name: 'MypageTicketTodaySummarySection',
  props: {
    /**
     * デジタルチケット イベント情報
     */
    ticketEvent: {
      type: Object as PropType<DigitalTicketDataDocument>,
      required: true,
    },
  },
  setup(props) {
    const { getDisplayPlaceName } = useDisplayTicketData()
    const { getDisplayDateJa } = useDisplayDependingOnLang()
    const { matchedTodayDate } = usePurchasedTicketData()

    /**
     * 場所
     */
    const displayPlaceName = computed(() => getDisplayPlaceName(props.ticketEvent))

    /**
     * イベント期間開始日
     */
    const openingTodayInfo = computed(() => matchedTodayDate(props.ticketEvent))

    /**
     * イベント開催日
     */
    const displayEventDate = computed(() =>
      openingTodayInfo.value.key
        ? getDisplayDateJa(props.ticketEvent.openingDates?.[openingTodayInfo.value.key])
        : '',
    )

    /**
     * イベント開催日の曜日
     */
    const displayEventDayOfWeek = computed(() =>
      openingTodayInfo.value.key
        ? getDisplayDateJa(
            props.ticketEvent.openingDates?.[openingTodayInfo.value.key],
            'ddd',
            'ddd',
          )
        : '',
    )

    /**
     * 開場時間
     */
    const displayOpeningTime = computed(() =>
      openingTodayInfo.value.key
        ? getDisplayDateJa(
            props.ticketEvent.openingDates?.[openingTodayInfo.value.key],
            'HH:mm',
            'HH:mm',
          )
        : '',
    )

    return {
      displayPlaceName,
      displayEventDate,
      displayEventDayOfWeek,
      displayOpeningTime,
    }
  },
})
