
























































import { defineComponent, PropType } from '@vue/composition-api'
import { RankingViewMode } from '@/components/RaceVideoPage/RaceVideoRankingPane.vue'

type DataType = {
  rankingViewModeListEnabled: boolean
  selectMode: string
}

/**
 * レース動画再生画面 ランキング 表示データ切り替え(セレクター) パーツコンポーネント
 */
export default defineComponent({
  name: 'ViewModeSelectorParts',
  data(): DataType {
    return {
      rankingViewModeListEnabled: false,
      selectMode: 'gap',
    }
  },
  props: {
    /**
     * 非選択時セレクト テキストデータ
     */
    rankingViewMode: {
      type: String as PropType<RankingViewMode>,
      default: '',
    },
    /**
     * フィルターリストデータ
     */
    rankingViewModeList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /**
     * セレクトカレント表示切り替え
     */
    enabledViewModeSelect(): void {
      this.rankingViewModeListEnabled = !this.rankingViewModeListEnabled
    },
    /**
     * 表示データ切り替え
     */
    changeFilter(mode: string): void {
      if (!mode) {
        this.enabledViewModeSelect()
        return
      }

      this.selectMode = mode
      this.$emit('rankingViewModeChange', this.selectMode)

      this.enabledViewModeSelect()
    },
  },
})
