import { localeChanged } from 'vee-validate'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'
import StoreUtil from '@/store/StoreUtil'
import I18n from '@/locales/I18n'
import IndexedDBStore from '@/store/stores/IndexedDBstore/IndexedDBStore'
import useCurrentTime from '@/store/hook/useCurrentTime'

/**
 * ログイン処理のHook
 */
export default function useLogin() {
  const { getTimeDiff } = useCurrentTime()
  /**
   * ユーザ情報を取得する。
   * @param loginId 取得するユーザーのログインID
   * @return ユーザ情報取得のレスポンス
   */
  const fetchUser = (loginId: string) => {
    const userCollectionModule = CollectionModule.createStore(UserDocument)
    return userCollectionModule.fetch({ targetId: loginId, isSaveInStore: false })
  }

  // ログイン成功後に呼び出されるコールバック関数
  let onLogin: () => void

  /**
   * ログイン成功イベントを受信するためのコールバック関数を登録する。
   *
   * @param loginListener ログイン成功時に呼び出されるコールバック関数
   */
  const addLoginSuccessListener = (loginListener: () => void) => {
    onLogin = loginListener
  }

  /**
   * ログインを行う。
   * @param loginName ログイン名
   * @param password パスワード
   */
  const login = async (loginName: string, password: string) => {
    const loginStore = StoreUtil.useStore('LoginStore')
    const loginRes = await loginStore.login(loginName, password)
    if (loginRes.isSuccess) {
      const { data: userDocs } = await fetchUser(loginStore.loginId)
      if (userDocs && userDocs.length > 0) {
        loginStore.viewQuality = userDocs[0].viewQuality ?? 'STANDARD'
        if (userDocs[0].lang) {
          I18n.locale = userDocs[0].lang as string
          localeChanged()
        }
      }
      const timeDiff = await getTimeDiff()
      // 端末の時間とサーバーの時間の差分をストアに保存
      loginStore.setTimeDiff(timeDiff)

      // 端末の時間のずれが許容範囲内の場合、DynamoDB認証情報を初期化する
      if (loginStore.isTimeDiffWithinRange) {
        const dynamoDBCredentialStore = StoreUtil.useStore('DynamoDBCredentialStore')
        await dynamoDBCredentialStore.init()
      }

      // IndexedDBを初期化する
      IndexedDBStore.value.init()
      if (onLogin) {
        onLogin()
      }
    }
    return loginRes
  }

  return {
    login,
    addLoginSuccessListener,
  }
}
