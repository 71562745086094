var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gps-ranking-section" }, [
    _c(
      "div",
      { staticClass: "gps-ranking-section__list" },
      _vm._l(_vm.rankingData, function (playerLiveTiming) {
        return _c("gps-ranking-player-parts", {
          key: playerLiveTiming.id,
          attrs: {
            selectedID: _vm.checkSelectedID(),
            rank: playerLiveTiming.position,
            player: playerLiveTiming,
            img: _vm.playerPictureUrl(playerLiveTiming),
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }