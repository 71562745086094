







import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import Const from '@/util/Const'

/**
 * レース動画再生画面 GPSマップ 縮尺ゲージ コンポーネント
 */
export default defineComponent({
  name: 'MapScaleParts',
  props: {
    /**
     * GPSマップ表示倍率
     */
    mapMagnification: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  setup(props) {
    /**
     * マップ 縮尺
     * 小数点以下2位まで表示: 以下切り捨て
     */
    const mapScaleValue = computed(() => Math.floor((200 / props.mapMagnification) * 100) / 100)

    /**
     * マップ 縮尺ゲージ横幅取得
     * TODO: コース別に定数を取得
     */
    const MAP_SCALE_WIDTH = Const.GPS_CIRCUIT_SCALE_GAUGE_WIDTH.SPORTSLAND_SUGO

    /**
     * マップ 縮尺ゲージ横幅取得
     */
    const mapScaleGaugeWidth = ref(0)
    const getScaleGaugeSize = () => {
      mapScaleGaugeWidth.value =
        (((document.querySelector('.race-video-gps-pane__map') as HTMLElement).clientWidth || 375) /
          375) *
        MAP_SCALE_WIDTH
    }

    onMounted(() => {
      getScaleGaugeSize()
      window.addEventListener('resize', getScaleGaugeSize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', getScaleGaugeSize)
    })
    return {
      mapScaleValue,
      mapScaleGaugeWidth,
    }
  },
})
