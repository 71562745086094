import StoreUtil from '@/store/StoreUtil'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * 動画をレジューム再生するための機能を提供する。
 */
export default function useResumeVideoPlayback() {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

  const {
    currentRaceId,
    currentAngleId,
    raceVideoPageState,
    currentTimeMainVideoTime,
    isMainEnglishLiveMovie,
    englishLiveAngleId,
  } = raceVideoPageStore

  /**
   * 現在のレースの再生位置を記録する。
   */
  const recordVideoPlaybackPosition = () => {
    if (currentRaceId.value) {
      if (currentAngleId.value !== 'main') {
        // 現在の再生中の動画が中継映像ではない場合、中継映像の再生位置も記録する
        LocalStorageAccessor.recordVideoPlaybackPosition(
          currentRaceId.value,
          isMainEnglishLiveMovie.value && englishLiveAngleId.value
            ? englishLiveAngleId.value
            : 'main',
          currentTimeMainVideoTime.value || 0,
        )
      }
      LocalStorageAccessor.recordVideoPlaybackPosition(
        currentRaceId.value,
        currentAngleId.value,
        raceVideoPageState?.videoStatus?.currentTime || 0,
      )
    }
  }

  /**
   * 現在のレースの再生位置を取得する。
   */
  const getRecordedPlaybackPosition = () => {
    if (currentRaceId.value && currentAngleId.value) {
      return LocalStorageAccessor.getVideoPlaybackPosition(
        currentRaceId.value,
        currentAngleId.value,
      )
    }
    return null
  }

  /**
   * 現在のレースで記録されている再生位置をクリアする。
   */
  const clearRecordedPlaybackPosition = () => {
    if (currentRaceId.value) {
      LocalStorageAccessor.clearVideoPlaybackPosition(currentRaceId.value)
    }
  }
  return {
    recordVideoPlaybackPosition,
    getRecordedPlaybackPosition,
    clearRecordedPlaybackPosition,
  }
}
