var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "21",
        viewBox: "0 0 20 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.6294 9.32749L16.441 3.7345H15.3008L11.123 8.59081L7.78613 3.7345H3.9375L8.98343 11.0781L3.9375 16.9432H5.07774L9.48964 11.8148L13.0136 16.9432H16.8622L11.6292 9.32749H11.6294ZM10.0677 11.1428L9.55648 10.4115L5.48858 4.59285H7.23992L10.5228 9.28872L11.034 10.02L15.3013 16.1239H13.55L10.0677 11.1431V11.1428Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }