var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "link-section" }, [
    _c("h4", {
      staticClass: "link-section__title",
      domProps: { textContent: _vm._s(_vm.title) },
    }),
    _c(
      "ul",
      { staticClass: "link-section__list" },
      _vm._l(_vm.linksData, function (link, index) {
        return _c(
          "li",
          {
            key: "LinkSectionListItem" + index,
            staticClass: "link-section__list-item",
          },
          [
            _c("link-item-parts", {
              key: "linkItemParts" + index,
              attrs: { linkData: link },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }