import axios, { AxiosResponse } from 'axios'
import DeviceInfo from '@/util/DeviceInfo'
import HTTPClientResponse from '@/util/http/HTTPClientResponse'
import Logger from '@/util/logger/Logger'

/**
 * HTTPメソッドの型
 */
export type HTTPMethodType = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'options'

/**
 * リクエストデータのエンコード方法の型
 */
export type HTTPSerializerType = 'json' | 'urlencoded' | 'utf8' | 'multipart'

/**
 * HTTP リクエストデータの型
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HTTPRequestDataType = Record<string, any> | FormData

/**
 * HTTP通信処理を提供する。
 *
 * Cordovaで動作している場合、
 * https://github.com/silkimen/cordova-plugin-advanced-http を利用してHTTP通信を行う。
 * そのため、異なるオリジンからの通信を許可されていないウェブサーバーに対しても通信が可能。
 *
 * ブラウザの場合、axiosを利用して通信を行う。そのため、Same Origin-Policyの制限がある。
 */
export default class HTTPClient {
  /**
   * HTTPリクエストを送信する。
   * Cordova アプリの場合、CORSエラーを回避するために、cordova-plugin-advanced-http を利用する。
   * それ以外の場合、ブラウザのAjax通信を行う。
   *
   * @param url リクエスト先
   * @param method HTTP メソッド
   * @param headers HTTP ヘッダ
   * @param data リクエストボディ
   * @param serializer リクエストデータのエンコード方法
   */
  public async sendRequest(
    url: string,
    method: HTTPMethodType,
    headers: Record<string, string>,
    data?: HTTPRequestDataType,
    serializer?: HTTPSerializerType,
  ) {
    if (DeviceInfo.isCordova()) {
      return this.convertResponseForAdvancedHttp(
        await this.sendRequestForCordovaApp(url, method, headers, data, serializer),
      )
    }
    return this.convertResponseForAxios(
      await this.sendRequestForBrowser(url, method, headers, data),
    )
  }

  /**
   * cordova-plugin-advanced-http を利用してHTTP通信を行う。
   *
   * @param url 接続対象のURL
   * @param method HTTP メソッド
   * @param headers HTTP ヘッダー
   * @param data HTTP リクエストボディのデータ
   * @param serializer リクエストボディのエンコード方式
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  private sendRequestForCordovaApp(
    url: string,
    method: 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'options',
    headers: Record<string, string>,
    data?: HTTPRequestDataType,
    serializer?: 'json' | 'urlencoded' | 'utf8' | 'multipart',
  ) {
    return new Promise((resolve: (response: HTTPResponse) => void, reject) => {
      window.cordova.plugin.http.sendRequest(
        url,
        {
          method,
          headers,
          data,
          serializer,
        },
        (res: HTTPResponse) => {
          resolve(res)
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          reject(error)
        },
      )
    })
  }

  /**
   * axios を利用してHTTP通信を行う。
   *
   * @param url 接続対象のURL
   * @param method HTTP メソッド
   * @param headers HTTP ヘッダー
   * @param data HTTP リクエストボディのデータ
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  private sendRequestForBrowser(
    url: string,
    method: 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'options',
    headers: Record<string, string>,
    data?: HTTPRequestDataType,
  ) {
    // TODO ブラウザ版の処理を実装する
    return axios({
      method,
      url,
      data,
      headers,
    })
  }

  /**
   * cordova-plugin-advanced-http を利用してHTTP通信を行う。
   * アプリで画像をロードする場合に利用する処理。
   *
   * @param url 接続対象のURL
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  public async loadImageForCordovaApp(url: string) {
    try {
      const response = await new Promise<HTTPResponse>((resolve, reject) => {
        window.cordova.plugin.http.sendRequest(
          url,
          {
            method: 'get',
            headers: {
              accept: 'image/*',
            },
            serializer: 'json',
            responseType: 'arraybuffer',
          },
          (res: HTTPResponse) => {
            resolve(res)
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => {
            reject(error)
          },
        )
      })
      return this.convertResponseForAdvancedHttp(response)
    } catch (e) {
      Logger.error(
        `HTTPClient#loadImageForCordovaApp Failed to load image. e: ${JSON.stringify(e)}`,
      )
      return new HTTPClientResponse(500, e)
    }
  }

  /**
   * cordova-plugin-advanced-http のレスポンスデータを HTTPClientResponse に変換する。
   *
   * @param response cordova-plugin-advanced-http のレスポンス
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  private convertResponseForAdvancedHttp(response: HTTPResponse) {
    return new HTTPClientResponse(response.status, response.data)
  }

  /**
   * axios のレスポンスデータを HTTPClientResponse に変換する。
   *
   * @param response axios のレスポンス
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  private convertResponseForAxios(response: AxiosResponse) {
    return new HTTPClientResponse(response.status, response.data)
  }
}
