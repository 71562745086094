import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

type PublicScopeType = {
  /**
   * 親組織ID
   * 親組織IDを設定した場合、その親組織配下の全子組織がコメントを参照することができる。
   */
  parentOrganization?: string | null
}

/**
 * リアクション登録で利用する対象コレクション名
 */
export type ReactionTargetCollectionNameType =
  | 'communication_comment'
  | 'communication_user_game_event'

/**
 * SFgoで利用するリアクション種別の型
 */
export type ReactionType = 'LIKE'

/**
 * いいね情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3282370574/API
 */
export default class ReactionDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ReactionDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/reaction'

  idAttr = 'reactionId'

  /**
   * リアクションID
   */
  reactionId: string | null = null

  /**
   * 対象コレクション名
   */
  targetCollectionName: ReactionTargetCollectionNameType | null = null

  /**
   * 対象プライマリキー
   */
  targetId: string | null = null

  /**
   * リアクション種別
   */
  reactionType: ReactionType | null = null

  /**
   * ユーザID
   */
  userId: string | null = null

  /**
   * 組織をまたいだ公開範囲
   */
  publicScope?: PublicScopeType | null = null
}
