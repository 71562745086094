var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-list-pane",
      class: [
        // レースを再生したい場合であっても誤ってSFgo’s Race Key Pointsを押してしまうことがあるため、SFgo’s Race Key Points分のスペースを常に確保する。
        { "race-list-pane--has-information": true },
        { "race-list-pane--show-information": _vm.showInformation },
        { "race-list-pane--for-pc": _vm.forPc },
      ],
    },
    [
      _c("figure", { staticClass: "race-list-pane__bg" }, [
        _c("img", { attrs: { src: _vm.championshipLogoPath, alt: "" } }),
      ]),
      _c("select-year-section", {
        staticClass: "race-list-pane__year-select",
        class: {
          "race-list-pane__year-select--hidden": _vm.showInformation,
          "race-list-pane__year-select--timetable": _vm.showTimetable,
          "race-list-pane__year-select--for-pc": _vm.forPc,
        },
        attrs: { raceYear: _vm.currentYear, yearList: _vm.yearList },
        on: { change: _vm.yearChange },
      }),
      _c(
        "section",
        {
          staticClass: "race-list-pane__content",
          class: {
            "race-list-pane__content--show-timetable": _vm.showTimetable,
          },
          style: { height: _vm.contentStyleHeight },
        },
        [
          _c("div", { staticClass: "race-list-pane__content-inner" }, [
            _c(
              "div",
              { ref: "header", staticClass: "race-list-pane__content-header" },
              [
                _vm.currentChampionship
                  ? _c("race-list-header-section", {
                      attrs: {
                        currentChampionship: _vm.currentChampionship,
                        showInformation: _vm.showInformation,
                        "show-timetable": _vm.showTimetable,
                      },
                      on: {
                        onClickViewTimetable: _vm.toggleMatchList,
                        onClickClose: _vm.toggleMatchList,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.showTimetable
              ? _c(
                  "div",
                  { staticClass: "race-list-pane__circuit-mode" },
                  [
                    _c("circuit-mode-section", {
                      staticClass: "race-list-pane__circuit-mode-section",
                      attrs: { hasDisplayedOnboarding: true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showTimetable
              ? _c(
                  "div",
                  { staticClass: "race-list-pane__timetable" },
                  [
                    _c("race-timetable-section", {
                      attrs: { raceListByStartDate: _vm.raceListByStartDate },
                      on: {
                        showFlowLineToPaidPlanModal:
                          _vm.showFlowLineToPaidPlanModal,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.hasCurrentRaceInformation
            ? _c("race-information-section", {
                ref: "raceListPaneInformation",
                staticClass: "race-list-pane__information",
                attrs: { showInformation: _vm.showInformation },
                on: { radioPlay: _vm.playRadioAudio },
              })
            : _vm._e(),
          !_vm.showTimetable
            ? _c("information-toggle-button-parts", {
                staticClass: "race-list-pane__information-toggle-button",
                attrs: {
                  hasCurrentRaceInformation: _vm.hasCurrentRaceInformation,
                  showInformation: _vm.showInformation,
                },
                on: { click: _vm.toggleInformation },
              })
            : _vm._e(),
          _c("race-list-section", {
            staticClass: "race-list-pane__race-list",
            attrs: {
              championships: _vm.championships,
              enabledRaceListCarousel: _vm.enabledRaceListCarousel,
              currentChampionshipId: _vm.currentChampionship
                ? _vm.currentChampionship.id || ""
                : "",
            },
            on: { change: _vm.change },
          }),
          _c("div", { staticClass: "race-list-pane__content-bg" }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showTimetable
            ? _c("race-list-close-timetable-button-parts", {
                staticClass: "race-list-pane__timetable-close-button",
                on: { onClickClose: _vm.toggleMatchList },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("flow-line-to-paid-plan-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.flowLineToPaidPlanModalEnabled,
            expression: "flowLineToPaidPlanModalEnabled",
          },
        ],
        attrs: {
          title: _vm.flowLineToPaidPlanModalContents.title,
          message: _vm.flowLineToPaidPlanModalContents.message,
          submitText: _vm.flowLineToPaidPlanModalContents.submitText,
          link: _vm.flowLineToPaidPlanModalContents.link,
        },
        on: { close: _vm.hideFlowLineToPaidPlanModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }