import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

type UploadMetaTypeType = 'DIGITAL_TICKET_BACKGROUND_IMAGE'

/**
 * ユーザー画像APIのドキュメント
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/835551562/01+API
 */
export default class UserImageUploadDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserImageUploadDocument>) {
    super(initProps as Partial<UserImageUploadDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/user_image/upload'

  idAttr = 'userImageId'

  /**
   * ユーザー画像ID
   */
  userImageId: string | null = null

  /**
   * 画像パス
   */
  path: string | null = null

  /**
   * 画像名
   */
  name: string | null = null

  /**
   * 画像のタイプ（画像を分類するために使用する）
   */
  type: UploadMetaTypeType | null = null
}
