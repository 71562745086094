













import { computed, onBeforeMount, defineComponent, ref, Ref } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CompletePane from '@/components/pc/MyPage/CompletePane/CompletePane.vue'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import useContractTerm from '@/components/hook/useContractTerm'
import { AttentionMessageData } from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

/**
 * 有料会員解約 完了ページ
 */
export default defineComponent({
  name: 'CancelPaidPlanCompletePage',
  components: {
    SubHeaderSection,
    CompletePane,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
    const { getDisplayDate } = useDisplayDependingOnLang()
    const { getCurrentContractTerm } = useContractTerm()

    /** 注意事項テキスト */
    const attentionMessage: Ref<AttentionMessageData | null> = ref(null)

    // 契約中のプランの契約期間
    const contractTerm = computed(() =>
      getCurrentContractTerm(
        effectiveContractPlan as ContractPlanDocument,
        effectiveOrgContract as ContractType,
      ),
    )

    onBeforeMount(async () => {
      if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
        // 月額プランから無料プランへの切り替えの際には、注意書きを表示する
        const endDate = getDisplayDate(contractTerm.value.contractEndDate)
        attentionMessage.value = {
          message: I18n.t('CancelPaidPlan.CompletePage.attentionMessage', {
            limitDate: endDate,
          }).toString(),
          note: null,
        }
      }
    })

    return {
      attentionMessage,
    }
  },
})
