var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overlay-header-parts",
      class: "overlay-header-parts--" + _vm.screenOrientationType,
    },
    [
      _c(
        "div",
        { staticClass: "overlay-header-parts__inner" },
        [
          !_vm.screenLandScape || _vm.lockOrientation !== undefined
            ? _c(
                "router-link",
                {
                  staticClass: "overlay-header-parts__back-button",
                  attrs: { to: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.historyBack.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon/icon_chevron_left_24__white.svg"),
                      alt: "<",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _c("h2", { staticClass: "overlay-header-parts__title" }, [
            _vm._v(_vm._s(_vm.videoTitle)),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }