
































































import { defineComponent, PropType } from '@vue/composition-api'
import { SnsTabsType } from '@/store/stores/pageStore/HomePage/HomePageStore'

/**
 * HOME SNSコンテンツ タブパーツ
 */
export default defineComponent({
  name: 'SnsContentTabsParts',
  props: {
    currentType: {
      type: String as PropType<SnsTabsType>,
      default: 'driver',
    },
  },
  methods: {
    /**
     * タブ選択通知
     * @param type: SnsTabsType
     */
    emitSelect(type: SnsTabsType) {
      this.$emit('select', type)
    },
  },
})
