var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-section" }, [
    _c(
      "ul",
      {
        staticClass: "filter-section__list",
        class: {
          "filter-section__list--active": _vm.openFilterId !== "",
          "filter-section__list--is-pc": _vm.isPc,
        },
      },
      [
        _c(
          "li",
          { ref: "placeFilter", staticClass: "filter-section__item" },
          [
            _c("atom-dropdown", {
              attrs: {
                placeholder: _vm.$tc(
                  "LinkPage.MotorsportCalendarPage.filter.circuitPlaceholder"
                ),
                menuList: _vm.placeList,
                selectedOptions: _vm.selectedPlaceOptions,
                isOpenMenu: _vm.openFilterId === "place",
                maxHeight: _vm.placeFilterMaxHeight,
              },
              on: {
                onClickCurrent: function ($event) {
                  return _vm.handleFilterCurrentClicked("place")
                },
                onClickOption: _vm.handleFilterPlaceOptionClicked,
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { ref: "categoryFilter", staticClass: "filter-section__item" },
          [
            _c("atom-dropdown", {
              attrs: {
                placeholder: _vm.$tc(
                  "LinkPage.MotorsportCalendarPage.filter.categoryPlaceholder"
                ),
                menuList: _vm.categoryList,
                selectedOptions: _vm.selectedCategoryOptions,
                isOpenMenu: _vm.openFilterId === "category",
                maxHeight: _vm.categoryFilterMaxHeight,
              },
              on: {
                onClickCurrent: function ($event) {
                  return _vm.handleFilterCurrentClicked("category")
                },
                onClickOption: _vm.handleFilterCategoryOptionClicked,
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.openFilterId !== ""
      ? _c("div", {
          staticClass: "filter-section__overlay",
          on: { click: _vm.handleOverlayClicked },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }