












import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * ページタイトル セクション
 */
export default defineComponent({
  name: 'PageTitleSection',
  props: {
    /**
     * ページタイトル
     */
    pageTitle: {
      type: String,
      required: true,
      default: '',
    },
    /**
     * カラータイプ
     */
    colorType: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isPc = DeviceInfo.isForPC()
    return {
      isPc,
    }
  },
})
