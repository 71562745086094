























import { computed, defineComponent, PropType } from '@vue/composition-api'
import type { OtherCommentUserType } from '@/components/HomePage/HomePane/parts/SceneListCardParts.vue'

/**
 * HOME ハイライトシーンリスト その他ユーザー画像表示パーツ
 */
export default defineComponent({
  name: 'SceneCardUserImagesParts',
  props: {
    /**
     * その他直近コメントユーザー
     */
    otherCommentUsers: {
      type: Array as PropType<OtherCommentUserType[]>,
    },
    /**
     * コメント数
     */
    commentCount: {
      type: Number,
    },
  },
  setup(props) {
    /**
     * コメントユーザーの残り人数取得
     */
    const userSurplusCount = computed(() => {
      const totalCommentCount = props.commentCount ?? 0
      const otherCommentCount = props.otherCommentUsers?.length ?? 0
      // コメント数 - アイコンを表示しているユーザー数（最大3人） - 対象カードのユーザー（1人）
      return totalCommentCount - otherCommentCount - 1
    })

    return {
      userSurplusCount,
    }
  },
})
