








import { defineComponent } from '@vue/composition-api'

/**
 * ミッション画面 ミッション達成状況 紹介コード確認ボタンパーツ
 */
export default defineComponent({
  name: 'MissionReferralCodeParts',
})
