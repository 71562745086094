















import { computed, defineComponent } from '@vue/composition-api'

/**
 * パスワードインプットフィールド
 */
export default defineComponent({
  name: 'AtomInputPassword',
  props: {
    /**
     * ID
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * テキスト表示フラグ
     */
    textVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * Value値
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * Error判定
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * プレースホルダー
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * 表示のみ
     */
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * インプットの値
     * @param {string} val インプットの値
     */
    const inputValue = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    const inputType = computed(() => (props.textVisible ? 'text' : 'password'))

    return {
      inputValue,
      inputType,
    }
  },
  methods: {
    /**
     * エンターキーが押されたことを通知する
     */
    emitEnter() {
      /**
       * エンターキーが押されたことを通知する
       * @event enter
       * @type {Object}
       */
      this.$emit('enter')
    },
    /**
     * 入力欄がフォーカスされたことを通知する
     */
    emitFocus() {
      /**
       * 入力欄がフォーカスされたことを通知する
       * @event focus
       * @type {Object}
       */
      this.$emit('focus')
    },
    /**
     * 入力欄からフォーカスが外れたことを通知する
     */
    emitBlur() {
      /**
       * 入力欄からフォーカスが外れたことを通知する
       * @event blur
       * @type {Object}
       */
      this.$emit('blur')
    },
  },
})
