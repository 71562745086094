var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notice-new-arrivals-modal-section",
      class: { "notice-new-arrivals-modal-section--is-pc": _vm.isPc },
    },
    [
      _c("div", { staticClass: "notice-new-arrivals-modal-section__modal" }, [
        _c("p", { staticClass: "notice-new-arrivals-modal-section__new" }, [
          _vm._v(_vm._s(_vm.$tc("common.new"))),
        ]),
        _c("h3", { staticClass: "notice-new-arrivals-modal-section__title" }, [
          _vm._v(
            " " + _vm._s(_vm.$tc("HomePage.newArrivalsModal.title")) + " "
          ),
        ]),
        _c("p", {
          staticClass: "notice-new-arrivals-modal-section__message",
          domProps: {
            innerHTML: _vm._s(_vm.$tc("HomePage.newArrivalsModal.message")),
          },
        }),
        _vm.externalLink
          ? _c(
              "p",
              { staticClass: "notice-new-arrivals-modal-section__detail" },
              [
                _c("atom-link", {
                  staticClass: "notice-new-arrivals-modal-section__detail-link",
                  attrs: {
                    url: _vm.externalLink,
                    linkText: _vm.$tc(
                      "HomePage.newArrivalsModal.detailLinkLabel"
                    ),
                    imgPath: require("@/assets/img/icon/icon_external_link_gray75.svg"),
                    iconPosition: "right",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.pageLink
          ? _c(
              "p",
              {
                staticClass: "notice-new-arrivals-modal-section__mission",
                on: {
                  click: function ($event) {
                    return _vm.handleOkClicked(true)
                  },
                },
              },
              [
                _c("atom-router-button", {
                  staticClass:
                    "notice-new-arrivals-modal-section__mission-button",
                  attrs: {
                    to: "",
                    linkText: _vm.$tc(
                      "HomePage.newArrivalsModal.missionButtonLabel"
                    ),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass:
              "notice-new-arrivals-modal-section__button notice-new-arrivals-modal-section__button--light--secondary",
            class: {
              "notice-new-arrivals-modal-section__button--no-page-link":
                !_vm.pageLink,
            },
            on: {
              click: function ($event) {
                return _vm.handleOkClicked(false)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$tc("common.ok")) + " ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }