


































































import { defineComponent, PropType } from '@vue/composition-api'
import HighlightsXIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsXIcon.vue'
import HighlightsInstagramIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsInstagramIcon.vue'
import HighlightsSnsBalloonParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightsSnsBalloonParts.vue'
import HighlightCheckIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightCheckIcon.vue'
import HighlightCheckActiveIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightCheckActiveIcon.vue'
import { HighlightPostedSnsType } from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'

interface SnsButtonDataTypes {
  isActiveXBalloon: boolean
  isActiveInstagramBalloon: boolean
  timerXId: number | undefined
  timerInstagramId: number | undefined
  balloonDisplayTime: number
  balloonXStyle: object
  balloonInstagramStyle: object
  isXPosted: boolean
  isInstagramPosted: boolean
}

export default defineComponent({
  name: 'HighlightsSnsButtonParts',
  components: {
    HighlightCheckActiveIcon,
    HighlightCheckIcon,
    HighlightsSnsBalloonParts,
    HighlightsInstagramIcon,
    HighlightsXIcon,
  },
  props: {
    /**
     * Xにハイライトを投稿するかどうか
     */
    postX: {
      type: Boolean,
      default: false,
    },
    /**
     * Instagramにハイライトを投稿するかどうか
     */
    postInstagram: {
      type: Boolean,
      default: false,
    },
    /**
     * SNSに投稿したことがあるかどうか
     */
    postedSns: {
      type: Object as PropType<HighlightPostedSnsType>,
    },
  },
  data(): SnsButtonDataTypes {
    return {
      isActiveXBalloon: false,
      isActiveInstagramBalloon: false,
      timerXId: undefined,
      timerInstagramId: undefined,
      balloonDisplayTime: 3000,
      balloonXStyle: {},
      balloonInstagramStyle: {},
      isXPosted: false,
      isInstagramPosted: false,
    }
  },
  mounted() {
    this.isXPosted = this.postedSns?.x ?? false
    this.isInstagramPosted = this.postedSns?.instagram ?? false
  },
  methods: {
    onChangePostX() {
      this.$emit('onChangePostX', !this.postX)
      if (!this.postX) {
        this.isActiveInstagramBalloon = false
        // バルーンを表示
        this.isActiveXBalloon = true
        if (this.timerXId) {
          window.clearTimeout(this.timerXId)
        }
        this.$nextTick(() => {
          const leftSpace = (this.$refs.balloonX as HTMLElement).getBoundingClientRect().left
          const buttonXSpace = (this.$refs.buttonX as HTMLElement).getBoundingClientRect().left
          if (leftSpace < 20) {
            this.balloonXStyle = { left: `-${buttonXSpace - 20}px`, transform: 'translateX(0)' }
          }
        })
        // 数秒表示後にバルーンを非表示にする
        this.timerXId = window.setTimeout(() => {
          this.isActiveXBalloon = false
        }, this.balloonDisplayTime)
      } else {
        if (this.timerXId) {
          window.clearTimeout(this.timerXId)
        }
        this.isActiveXBalloon = false
      }
    },
    onChangePostInstagram() {
      this.$emit('onChangePostInstagram', !this.postInstagram)
      if (!this.postInstagram) {
        this.isActiveXBalloon = false
        this.isActiveInstagramBalloon = true
        if (this.timerInstagramId) {
          window.clearTimeout(this.timerInstagramId)
        }
        this.$nextTick(() => {
          const leftSpace = (this.$refs.balloonInstagram as HTMLElement).getBoundingClientRect()
            .left
          const buttonInstagramSpace = (
            this.$refs.buttonInstagram as HTMLElement
          ).getBoundingClientRect().left
          if (leftSpace < 20) {
            this.balloonXStyle = {
              left: `-${buttonInstagramSpace - 20}px`,
              transform: 'translateX(0)',
            }
          }
        })
        this.timerInstagramId = window.setTimeout(() => {
          this.isActiveInstagramBalloon = false
        }, this.balloonDisplayTime)
      } else {
        if (this.timerInstagramId) {
          window.clearTimeout(this.timerInstagramId)
        }
        this.isActiveInstagramBalloon = false
      }
    },
  },
  beforeDestroy() {
    if (this.timerXId) {
      window.clearTimeout(this.timerXId)
    }
    if (this.timerInstagramId) {
      window.clearTimeout(this.timerInstagramId)
    }
  },
})
