var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navigation-notification-icon-parts",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M20 19L20 17L18 17L18 10C18 8.61667 17.5833 7.38734 16.75 6.312C15.9167 5.23667 14.8333 4.53267 13.5 4.2L13.5 3.5C13.5 3.08334 13.354 2.729 13.062 2.437C12.77 2.145 12.416 1.99933 12 2C11.5833 2 11.229 2.146 10.937 2.438C10.645 2.73 10.4993 3.084 10.5 3.5L10.5 4.2C9.16667 4.53333 8.08333 5.23767 7.25 6.313C6.41667 7.38833 6 8.61733 6 10L6 17L4 17L4 19L20 19ZM12 22C12.55 22 13.021 21.804 13.413 21.412C13.805 21.02 14.0007 20.5493 14 20L10 20C10 20.55 10.196 21.021 10.588 21.413C10.98 21.805 11.4507 22.0007 12 22ZM16 17L8 17L8 10C8 8.9 8.39167 7.95833 9.175 7.175C9.95833 6.39167 10.9 6 12 6C13.1 6 14.0417 6.39167 14.825 7.175C15.6083 7.95834 16 8.9 16 10L16 17Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }