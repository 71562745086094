var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-dropdown" }, [
    _c(
      "button",
      {
        staticClass: "atom-dropdown__current",
        class: { "atom-dropdown__current--active": _vm.isOpenMenu },
        attrs: { disabled: _vm.isDisabled },
        on: { click: _vm.handleCurrentClicked },
      },
      [
        _c(
          "span",
          {
            class: {
              "atom-dropdown__placeholder": _vm.selectedOptions.length === 0,
            },
          },
          [_vm._v(_vm._s(_vm.buttonLabel))]
        ),
        _c("span", { staticClass: "atom-dropdown__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { opacity: "0.5" } }, [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M1.93945 6L7.76268 11.8232C8.44609 12.5066 9.55413 12.5066 10.2375 11.8232L16.0608 6H13.9395L9.17689 10.7626C9.07926 10.8602 8.92097 10.8602 8.82334 10.7626L4.06077 6H1.93945Z",
                    fill: "black",
                  },
                }),
              ]),
            ]
          ),
        ]),
      ]
    ),
    _vm.isOpenMenu
      ? _c(
          "div",
          {
            ref: "menu",
            staticClass: "atom-dropdown__menu",
            style: { "max-height": "" + _vm.maxHeight },
          },
          [
            _c(
              "ul",
              { staticClass: "atom-dropdown__menu-list" },
              _vm._l(_vm.menuList, function (item) {
                return _c(
                  "li",
                  { key: item.id, staticClass: "atom-dropdown__menu-item" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "atom-dropdown__option",
                        class: {
                          "atom-dropdown__option--selected":
                            _vm.isSelected(item),
                          "atom-dropdown__option-no-checkmark": !_vm.hasCheck,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleOptionClicked(item)
                          },
                        },
                      },
                      [
                        _vm.selectedOptions.includes(item)
                          ? _c(
                              "span",
                              { staticClass: "atom-dropdown__option-check" },
                              [_c("calendar-check-icon")],
                              1
                            )
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(item.name))]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }