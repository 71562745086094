var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standings-round-list-section" },
    [
      _vm.roundsRecords && _vm.roundsRecords.length > 0
        ? _vm._l(_vm.roundsRecords, function (round, index) {
            return _c("standings-round-details-section", {
              key: "standingsRoundDetails" + index,
              staticClass: "standings-round-list-section__item",
              attrs: { roundRecord: round },
            })
          })
        : [
            _c("p", {
              staticClass: "standings-round-list-section__no-data",
              domProps: { innerHTML: _vm._s(_vm.$tc("StandingsPage.noData")) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }