var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "complete-pane" },
    [
      _c("h3", { staticClass: "complete-pane__title" }, [
        _vm._v(_vm._s(_vm.pageTitle)),
      ]),
      _vm.message !== ""
        ? _c("p", {
            staticClass: "complete-pane__message",
            domProps: { innerHTML: _vm._s(_vm.message) },
          })
        : _vm._e(),
      _vm.isReadOnlyPlan
        ? _c("p", { staticClass: "complete-pane__message" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc("PaymentPage.CompletePage.messageSettlementResolved")
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.isShowAttentionMessage
        ? _c("attention-message-section", {
            staticClass: "complete-pane__attention-message",
            attrs: { attentionMessage: _vm.attentionMessage },
          })
        : _vm._e(),
      _vm.paymentSchedule
        ? _c("payment-schedule-section", {
            staticClass: "complete-pane__payment-schedule",
            attrs: { paymentSchedule: _vm.paymentSchedule },
          })
        : _vm._e(),
      _vm.hasActionButton
        ? _c(
            "action-buttons-section",
            {
              staticClass: "complete-pane__action-buttons",
              attrs: { scrollTargetSelector: ".complete-pane", visible: true },
            },
            [
              _vm.transitionFromMobileApp && _vm.isAndroidMobile
                ? _c("atom-link-button", {
                    attrs: {
                      linkText: _vm.$tc("common.backToApp"),
                      url: _vm.transitionUrl,
                      isDisabled: false,
                    },
                  })
                : _vm._e(),
              _vm.isAndroidMobile
                ? [
                    _c("atom-link-button", {
                      attrs: {
                        linkText: _vm.$tc("common.toMypage"),
                        url: _vm.transitionUrl,
                        isDisabled: false,
                        colorType: "secondary",
                      },
                    }),
                  ]
                : [
                    _c("atom-router-button", {
                      attrs: {
                        to: _vm.transitionUrl,
                        linkText: _vm.$tc("common.toLogin"),
                        colorType: "secondary",
                      },
                    }),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }