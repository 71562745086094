import Dexie, { EntityTable } from 'dexie'
import { IndexedDBDataType } from '@/store/stores/IndexedDBstore/IndexedDBAccessorType'

export type GeneralTableType = {
  key: string
  data: IndexedDBDataType
}

/**
 * Dexie.jsを利用し、IndexedDBを操作するためのクラス
 * @see https://dexie.org/docs/Typescript
 */
export class IndexedDBDataClass extends Dexie {
  // テーブルの型を定義
  generalTable!: EntityTable<GeneralTableType, 'key'>

  constructor() {
    // IndexDBでの名前を設定
    super('SFgoLocalDB')

    // DBの初期化
    this.version(1).stores({
      generalTable: '&key, data',
    })
  }
}
