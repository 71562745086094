import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import UserCouponDocument from '@/store/stores/collectionModule/documents/coupon/UserCouponDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * 紹介コードデータを操作するための処理を提供する
 */
export default function useUserCoupon() {
  // Collection modules
  const userCouponCollectionModule = CollectionModule.createStore(UserCouponDocument)

  /**
   * 紹介コードを取得する
   * @return APIレスポンス
   */
  const fetchUserCoupons = () => userCouponCollectionModule.fetch()

  /**
   * 紹介コードを登録する
   * @param requestData リクエストデータ
   */
  const registerUserCoupon = (requestData: UserCouponDocument) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/data/master/coupon/user`,
    }
    return userCouponCollectionModule.save(requestData, options)
  }

  /**
   * 取得したクーポン情報
   */
  const userCoupons = computed(() => userCouponCollectionModule.data)

  /**
   * 取得したクーポン情報をクリアする
   */
  const clearUserCoupons = () => {
    userCouponCollectionModule.clearData()
  }

  return {
    fetchUserCoupons,
    registerUserCoupon,
    userCoupons,
    clearUserCoupons,
  }
}
