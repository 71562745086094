



























import { defineComponent, PropType } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * 請求サイクル画像パス（URL）の型
 */
export type PaymentCycleImgPathType = {
  pc: string
  sp: string
}

/**
 * プラン: お支払いサイクル セクション
 */
export default defineComponent({
  name: 'PaymentCycleSection',
  props: {
    /**
     * 請求サイクル画像パス
     */
    paymentCycleImgPath: {
      type: Object as PropType<PaymentCycleImgPathType>,
      required: true,
    },
    /**
     * 注意メッセージ
     */
    attention: {
      type: String,
      default: '',
    },
  },
  setup() {
    const userStore = StoreUtil.useStore('UserStore')
    const { user } = userStore

    const userLang = user.value.lang || DeviceInfo.getLanguage()

    return {
      userLang,
    }
  },
})
