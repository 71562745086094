



















import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import HomeSnsPlayerSelectPane from '@/components/HomeSnsPlayerSelectPage/HomeSnsPlayerSelectPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

export type DataType = {
  screenTransitionModal: boolean
  screenTransitionTo: string
}

/**
 * HOME: SNS投稿表示選手選択ページ
 */
export default defineComponent({
  name: 'HomeSnsPlayerSelectPage',
  components: {
    HeaderPane,
    SubHeaderSection,
    HomeSnsPlayerSelectPane,
    GlobalNavigationPane,
  },
  data(): DataType {
    return {
      screenTransitionModal: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '', // 画面遷移警告モーダル 遷移先指定
    }
  },
  methods: {
    /**
     * HONE画面に遷移する
     */
    moveToHome(): void {
      this.$router.replace({ name: 'HomePage' })
    },
  },
})
