







import { defineComponent } from '@vue/composition-api'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import ConfirmationSentSection from '@/components/SignupPage/ConfirmationSentPane/ConfirmationSentSection.vue'

/**
 * 新規会員登録: 認証メール送信完了ペインのコンポーネント
 */
export default defineComponent({
  name: 'ConfirmationSentPane',
  components: {
    SignupHeaderSection,
    ConfirmationSentSection,
  },
  methods: {},
})
