var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-reset-mail-input-pane" },
    [
      _c("password-reset-mail-input-section", {
        on: { "success-regist": _vm.moveToSend },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }