var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "badge-year-parts",
      attrs: {
        width: "20",
        height: "26",
        viewBox: "0 0 20 26",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_5_314)" } }, [
        _c("path", {
          attrs: {
            opacity: "0.7",
            d: "M0.5 0H9L12.5056 10H4.0056L0.5 0Z",
            fill: "url(#paint0_linear_5_314)",
          },
        }),
        _c("path", {
          attrs: {
            d: "M19.5 0H11.0056L7.5 10H15.9944L19.5 0Z",
            fill: "url(#paint1_linear_5_314)",
          },
        }),
        _c("g", { attrs: { filter: "url(#filter0_d_5_314)" } }, [
          _c("path", {
            attrs: {
              d: "M10 26C15.5228 26 20 21.5228 20 16C20 10.4772 15.5228 6 10 6C4.47715 6 0 10.4772 0 16C0 21.5228 4.47715 26 10 26Z",
              fill: "#D3D3D3",
            },
          }),
          _c("path", {
            attrs: {
              d: "M10 23.5C14.1421 23.5 17.5 20.1421 17.5 16C17.5 11.8579 14.1421 8.5 10 8.5C5.85786 8.5 2.5 11.8579 2.5 16C2.5 20.1421 5.85786 23.5 10 23.5Z",
              stroke: "black",
              "stroke-opacity": "0.1",
            },
          }),
          _c(
            "text",
            {
              attrs: {
                transform: "matrix(1 0 0 1 10 19.5)",
                "text-anchor": "middle",
                fill: "black",
              },
            },
            [_vm._v(" " + _vm._s(_vm.badgeYear) + " ")]
          ),
        ]),
      ]),
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "filter0_d_5_314",
                x: "-8",
                y: "0",
                width: "36",
                height: "36",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _c("feColorMatrix", {
                attrs: {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha",
                },
              }),
              _c("feOffset", { attrs: { dy: "2" } }),
              _c("feGaussianBlur", { attrs: { stdDeviation: "4" } }),
              _c("feComposite", {
                attrs: { in2: "hardAlpha", operator: "out" },
              }),
              _c("feColorMatrix", {
                attrs: {
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0",
                },
              }),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in2: "BackgroundImageFix",
                  result: "effect1_dropShadow_5_314",
                },
              }),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "effect1_dropShadow_5_314",
                  result: "shape",
                },
              }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_5_314",
                x1: "0.5",
                y1: "10",
                x2: "12.5056",
                y2: "10",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#00A69C" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#00A69C" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint1_linear_5_314",
                x1: "19.5",
                y1: "10",
                x2: "7.5",
                y2: "10",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#00A69C" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#00A69C" } }),
            ],
            1
          ),
          _c("clipPath", { attrs: { id: "clip0_5_314" } }, [
            _c("rect", { attrs: { width: "20", height: "26", fill: "white" } }),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }