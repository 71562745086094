import { VideoPlayerResponseType } from '@/util/videoplayer/VideoPlayerResponseType'

/**
 * 動画プレーヤーの操作結果ステータスの型
 */
export type VideoPlayerStatusType = 'success' | 'error' | 'not initialized'

/**
 * 動画プレーヤーの操作結果を表現する。
 */
class VideoPlayerResult {
  /**
   * 動画プレーヤーの操作結果ステータス
   */
  status: VideoPlayerStatusType

  /**
   * 動画プレーヤーの操作結果がエラーとなった場合に、エラー内容が設定される。
   * NativeVideoPlayer の場合のみ設定される。
   */
  errorDetail?: string

  /**
   * 動画プレーヤーが返却した処理結果。
   * NativeVideoPlayer の場合のみ設定される。
   */
  playerEvent?: VideoPlayerResponseType

  /**
   * 動画プレーヤーの処理結果を初期化する。
   * @param status 動画プレーヤーの操作結果ステータス
   * @param errorDetail エラー内容
   * @param playerEvent 動画プレーヤーが返却した処理結果
   */
  constructor(
    status: VideoPlayerStatusType = 'success',
    errorDetail?: string,
    playerEvent?: VideoPlayerResponseType,
  ) {
    this.status = status
    this.errorDetail = errorDetail
    this.playerEvent = playerEvent
  }

  /**
   * 動画プレーヤーの操作結果の文字列表現を生成する。
   * @return 動画プレーヤーの操作結果の文字列表現
   */
  toString() {
    return `VideoPlayerResult[status: ${this.status}}, errorDetail: ${
      this.errorDetail
    }, playerEvent: ${JSON.stringify(this.playerEvent)}]`
  }
}

export default VideoPlayerResult
