var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "race-list-close-timetable-button",
      class: { "race-list-close-timetable-button--for-pc": _vm.forPc },
      on: { click: _vm.handleCloseClicked },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "18",
            height: "19",
            viewBox: "0 0 18 19",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M16.1016 6.53918L10.2783 12.3624C9.59492 13.0458 8.48688 13.0458 7.80347 12.3624L1.98024 6.53919L4.10156 6.53919L8.86413 11.3017C8.96176 11.3994 9.12005 11.3994 9.21768 11.3017L13.9802 6.53918L16.1016 6.53918Z",
              fill: "white",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }