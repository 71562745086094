import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'

/**
 * 新規ユーザー情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1144094729/API
 */
export default class InitialUserDocument extends UserDocument {
  constructor(initProps?: Partial<InitialUserDocument>) {
    super(initProps as Partial<UserDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/service_registration/initial_user'

  /**
   * 登録トークンID
   */
  tokenId?: string | null = null

  /**
   * パスワード
   */
  password?: string | null = null
}
