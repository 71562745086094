var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-ranking-header-section",
      class: { "mission-ranking-header-section--fixed": _vm.isFixed },
    },
    [
      _c(
        "div",
        {
          ref: "result",
          staticClass: "mission-ranking-header-section__result",
          class: {
            "mission-ranking-header-section__result--fixed": _vm.isFixed,
          },
        },
        [
          _c("members-point-section", {
            staticClass: "mission-ranking-header-section__result-section",
            attrs: {
              userData: _vm.userViewData,
              membersRankData: _vm.membersRankData,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "tab",
          staticClass: "mission-ranking-header-section__tab",
          class: { "mission-ranking-header-section__tab--fixed": _vm.isFixed },
          style: _vm.tabStyle,
        },
        [_c("mission-list-tabs-parts", { attrs: { currentTab: "ranking" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }