




import { defineComponent } from '@vue/composition-api'

/**
 * 共通 モーダルメッセージ テンプレート
 */
export default defineComponent({
  name: 'ModalMessageParts',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
})
