var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "point-gauge-parts" }, [
    _c("div", { staticClass: "point-gauge-parts__gauge" }, [
      _c("div", { staticClass: "point-gauge-parts__gauge-inner" }, [
        _c("div", {
          staticClass: "point-gauge-parts__gauge-active",
          class: "point-gauge-parts__gauge-active--" + _vm.gaugeColorName,
          style: "width: " + _vm.gaugeWidth + ";",
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "point-gauge-parts__labels" },
      _vm._l(_vm.rankNamePointList, function (rankNamePoint, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: rankNamePoint.point > 0,
                expression: "rankNamePoint.point > 0",
              },
            ],
            key: "point-gauge-parts__label" + index,
            staticClass: "point-gauge-parts__label",
            class: [
              {
                "point-gauge-parts__label--active":
                  rankNamePoint.point <= _vm.membersRankData.point,
              },
              "point-gauge-parts__label--" + rankNamePoint.rank,
            ],
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.displayGaugePoint(
                    rankNamePoint.point,
                    index === _vm.rankNamePointList.length - 1
                  )
                ),
              },
            }),
          ]
        )
      }),
      0
    ),
    _vm.isFreePlan && _vm.membersRankData.currentRank !== "bronze"
      ? _c("p", {
          staticClass: "point-gauge-parts__benefits",
          domProps: { innerHTML: _vm._s(_vm.rankBenefitsLink) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }