/**
 * CloudFrontの署名Cookieの型情報
 */
type SignedCookie = {
  'CloudFront-Signature'?: string
  'CloudFront-Key-Pair-Id'?: string
  'CloudFront-Policy'?: string
  Domain: string
  Path: string
}
/**
 * CloudFrontの署名Cookieのname属性に設定される値の配列
 */
export const SignedCookieNames = [
  'CloudFront-Signature',
  'CloudFront-Key-Pair-Id',
  'CloudFront-Policy',
] as const
/**
 * CloudFrontの署名Cookieのname属性に設定される値
 */
export type SignedCookieName = typeof SignedCookieNames[number]
/**
 * CloudFrontの署名Cookieのpath属性に設定される値
 */
export type SignedCookiePath = '/movies' | '/img' | '/files' | '/audios'
export default SignedCookie
