













import { computed, defineComponent } from '@vue/composition-api'
import FieldsetConfirmParts from '@/components/common/form/FieldsetConfirmParts.vue'

/**
 * クレジットカード情報変更 現在のカード番号 セクション
 */
export default defineComponent({
  name: 'CurrentCardNumberSection',
  components: {
    FieldsetConfirmParts,
  },
  props: {
    cardLast4Digits: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * 現在のカード番号
     * todo: カード番号取得
     */
    const currentCardNumber = computed(() => `************${props.cardLast4Digits}`)

    return {
      currentCardNumber,
    }
  },
})
