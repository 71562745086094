
























































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { AwardRankingData } from '@/components/LinkPage/AwardPane.vue'
import useAward from '@/components/LinkPage/hooks/useAward'
import { AwardRankingCategoryType } from '@/store/stores/collectionModule/documents/award/AwardDataDocument'

/**
 * Award 1位以外のランキング セクション
 */
export default defineComponent({
  name: 'AwardRankingListSection',
  props: {
    category: {
      type: String as PropType<AwardRankingCategoryType>,
      default: 'RECOMMEND_DRIVER',
    },
    rankingDataOtherFirst: {
      type: Array as PropType<AwardRankingData[]>,
      required: true,
    },
  },
  setup(props) {
    const { getFavoriteText } = useAward()

    /**
     * お気に入りテキストを取得
     */
    const favoriteText = computed(() => getFavoriteText(props.category))

    return {
      favoriteText,
    }
  },
})
