var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toggle-radio-card-parts",
      class: {
        "toggle-radio-card-parts--selected":
          _vm.currentValue === _vm.radioValue,
      },
      on: {
        click: function ($event) {
          return _vm.handlerOnClick(_vm.radioValue)
        },
      },
    },
    [
      _c("atom-input-radio", {
        staticClass: "toggle-radio-card-parts__radio",
        attrs: { value: _vm.currentValue === _vm.radioValue },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }