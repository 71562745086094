
























import { defineComponent, PropType } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import RaceListHeaderParts from '@/components/RaceListPage/RaceListPane/parts/RaceListHeaderParts.vue'
import RaceListViewButtonParts from '@/components/RaceListPage/RaceListPane/parts/RaceListViewButtonParts.vue'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'

export default defineComponent({
  name: 'RaceListHeaderSection',
  components: {
    RaceListViewButtonParts,
    RaceListHeaderParts,
  },
  props: {
    currentChampionship: {
      type: Object as PropType<ChampionshipDocument>,
      required: true,
    },
    showInformation: {
      type: Boolean,
      default: false,
    },
    showTimetable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const forPc = DeviceInfo.isForPC()
    const handleViewTimetableClicked = () => {
      emit('onClickViewTimetable')
    }
    const handleCloseClicked = () => {
      emit('onClickClose')
    }
    return {
      forPc,
      handleViewTimetableClicked,
      handleCloseClicked,
    }
  },
})
