import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * クーポンデータを操作するための処理を提供する。
 */
export default function useContractPlan() {
  // Collection modules
  const couponCollectionModule = CollectionModule.createStore(CouponDocument)

  /**
   * 指定したクーポンコードのクーポン情報を取得する。
   * @return APIレスポンス
   */
  const fetchTargetCoupon = (couponCode: string) =>
    couponCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/data/master/coupon/code/${couponCode}`,
    })

  /**
   * 指定したクーポンコードのクーポン情報を取得する。
   * @param couponId リクエストデータ
   * @param isUnionExistData
   */
  const fetchTargetCouponByCouponId = (couponId: string, isUnionExistData = true) =>
    couponCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/data/master/coupon/${couponId}`,
      isUnionExistData,
    })

  /**
   * 取得したクーポン一覧
   */
  const coupons = computed(() => couponCollectionModule.data)

  /**
   * クーポンコードに一致するクーポンデータを返す
   * @param couponCode 対象のクーポンコード
   */
  const targetCoupon = (couponCode: string) =>
    coupons.value.find(
      (v) =>
        v.couponCode === couponCode &&
        (v.additionalData?.couponPlanType === 'annualPlan' ||
          v.additionalData?.couponPlanType === 'monthlyPlan'),
    )

  /**
   * ワンタイムパスに一致するワンタイムパスデータを返す
   * @param oneTimePass 対象のワンタイムパス
   */
  const targetOneTimePass = (oneTimePass: string) =>
    coupons.value.find(
      (v) => v.couponCode === oneTimePass && v.additionalData?.couponPlanType === 'freePlan',
    )

  /**
   * 取得したクーポン情報をクリアする
   */
  const clearCoupons = () => {
    couponCollectionModule.clearData()
  }

  return {
    fetchTargetCoupon,
    fetchTargetCouponByCouponId,
    coupons,
    targetCoupon,
    targetOneTimePass,
    clearCoupons,
  }
}
