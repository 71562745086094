














import { defineComponent, PropType } from '@vue/composition-api'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'

/**
 * HOME SNS投稿コンテンツ 公式ニュース用
 */
export default defineComponent({
  name: 'OfficialPostItemContentsParts',
  props: {
    snsPost: {
      type: Object as PropType<SnsPostData>,
      required: true,
    },
  },
})
