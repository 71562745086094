












































import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: レース一覧リンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationRaceIconParts',
})
