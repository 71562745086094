var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-overlay-progress-parts" }, [
    _c("span", [
      _vm._v(
        _vm._s(
          _vm.i18n.t(_vm.progressItem.message, {
            progress: _vm.progressItem.progress,
          })
        )
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }