var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlight-comment-section",
      class: [
        "highlight-comment-section--" + _vm.screenOrientationType,
        { "highlight-comment-section--edit": _vm.isEditMode },
      ],
    },
    [
      _c("figure", { staticClass: "highlight-comment-section__image" }, [
        _c("img", {
          attrs: {
            src: _vm.getCommentUserIconPath(_vm.displayCommentData.user.id),
            alt: "",
          },
        }),
      ]),
      !_vm.isEditMode
        ? _c("div", { staticClass: "highlight-comment-section__content" }, [
            _c(
              "div",
              { staticClass: "highlight-comment-section__header" },
              [
                _c("h6", {
                  staticClass: "highlight-comment-section__header__title",
                  domProps: {
                    textContent: _vm._s(
                      _vm.getCommentUserDisplayName(
                        _vm.displayCommentData.user.id
                      )
                    ),
                  },
                }),
                _c("comment-post-date-parts", {
                  attrs: { postDate: _vm.displayCommentData.date },
                }),
              ],
              1
            ),
            _c("p", {
              staticClass: "highlight-comment-section__comment",
              domProps: { innerHTML: _vm._s(_vm.displayCommentData.comment) },
            }),
            _c(
              "div",
              { staticClass: "highlight-comment-section__like" },
              [
                _c("like-button-parts", {
                  attrs: { commentData: _vm.displayCommentData },
                  on: { click: _vm.onChangeLike },
                }),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "highlight-comment-section__controller-toggle",
                on: { click: _vm.handleMenuClicked },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "16",
                      height: "14",
                      viewBox: "0 0 16 14",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M2.5 6.05C1.86 6.05 1.34 6.57 1.34 7.2C1.34 7.84 1.86 8.36 2.5 8.36C3.13 8.36 3.65 7.84 3.65 7.2C3.65 6.57 3.13 6.05 2.5 6.05ZM7.9 6.05C7.26 6.05 6.74 6.57 6.74 7.2C6.74 7.84 7.26 8.36 7.9 8.36C8.53 8.36 9.05 7.84 9.05 7.2C9.05 6.57 8.53 6.05 7.9 6.05ZM13.3 6.05C12.66 6.05 12.14 6.57 12.14 7.2C12.14 7.84 12.66 8.36 13.3 8.36C13.93 8.36 14.45 7.84 14.45 7.2C14.45 6.57 13.93 6.05 13.3 6.05Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.isEditMode
        ? _c("comment-editor-parts", {
            staticClass: "highlight-item-section__parent-comment__editor",
            attrs: {
              screenOrientationType: _vm.screenOrientationType,
              message: _vm.displayCommentData.comment,
            },
            on: {
              handlerCancel: function ($event) {
                _vm.enabledConfirmModal = true
              },
              handlerSave: _vm.onSaveComment,
            },
          })
        : _vm._e(),
      _vm.enabledConfirmModal
        ? _c("confirm-modal-section", {
            staticClass: "highlight-comment-section__modal",
            attrs: {
              message: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.editCancelMessage"
              ),
              confirmButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.confirmButtonLabel"
              ),
              cancelButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.cancelButtonLabel"
              ),
            },
            on: {
              confirm: _vm.cancelInput,
              cancel: function ($event) {
                _vm.enabledConfirmModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }