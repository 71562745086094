import { movieExport } from '@/util/videoplayer'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import LocalCache from '@/util/localcache/LocalCache'
import LocalCacheSignatureType from '@/util/localcache/LocalCacheSignatureType'
import { RectType, SizeType } from '@/util/GeneralType'
import {
  MOVIE_EXPORT_MOVIE_SIZE,
  MOVIE_EXPORT_VIEW_ITEM_RECT,
  MOVIE_EXPORT_WATER_MARK_FOR_BASE64,
} from '@/util/Const'
import Logger from '@/util/logger/Logger'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import useDownload from '@/util/download/useDownload'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import FileConverter from '@/util/fileConverter/FileConverter'
import LoadingOverLayStore from '@/store/stores/pageStore/common/LoadingOverlayProgressStore'
/* eslint-disable @typescript-eslint/no-var-requires */
const imagePath = require('@/assets/img/common/logo_dark.svg')

/**
 * movieExportに渡せる音声ファイルの種類
 */
type FileFormatType = 'wav'

/**
 * 無線を合成する際に使用するデータを引数で渡す時の型
 */
type RadioSyntheticDataType = {
  clippingStartActualTime: number
  radioDataSet: Array<RadioDataDocument>
}

/**
 * movieExport引数：audioItemの型
 */
type AudioItemType = {
  startTime: number
  itemStartTime?: number
  itemEndTime?: number
  base64AudioData: string
  fileFormat: FileFormatType
  audioWeight?: number
  skipProcessSnapshot: boolean
}

/**
 * movieExport引数：movieInfoの型
 */
type MovieInfoType = {
  url: string
  signature: Array<LocalCacheSignatureType>
}

/**
 * movieExport引数：recordingMethodの型
 */
type RecordingMethod = {
  movieInfo: MovieInfoType
  startTime: number
  endTime: number
}

/**
 * movieExport引数：options.viewItemの型
 */
type ViewItemType = {
  rect: RectType
  startTime: number
  endTime: number
  base64ImageData: string
  hidePauseTime?: boolean
}

/**
 * movieExport引数：optionsの型
 */
type MovieExportOptionsType = {
  movieSize?: SizeType
  viewItem?: Array<ViewItemType>
  audioItem?: Array<AudioItemType>
  baseAudioWeight?: number
}

/**
 * movieExport状況リスナー：progressListenerの型
 */
type ProgressListenerType = {
  progress: string
}

/**
 * movieExport成功ハンドラの型
 */
export type SuccessResponseType = {
  // エラーコード
  errorCode: string
  // 処理結果メッセージ
  message: string
  // 詳細
  detail: string
  // 処理内容（movieExport）
  action: string
  // 選択ファイルパス
  // プレーヤプラグインに渡すパスの場合に指定
  filePath: string
  // ファイルサイズ
  fileSize: string
  // 直アクセスするためのファイルパス
  // 出力例 iOS : /var/mobile/Media/DCIM/100APPLE/IMG_0475.MP4
  directAccessFilePath: string
}

/**
 * movieExportエラーハンドラの型
 */
export type ErrorResponseType = {
  errorCode: string
  message: string
  detail: string
  action: string
}

/**
 * movieExportレスポンス
 */
type ExportHighlightResponseType = {
  isSuccess: boolean
  response: ErrorResponseType | SuccessResponseType
}

/**
 * ネイティブプレイヤー：movieExportに関する処理をまとめたHook
 */
export default function useMovieExport() {
  const { loadFile } = useDownload()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const readFileToBase64 = async (): Promise<string> =>
    new Promise((resolve) => {
      const img = new Image()

      img.onload = () => {
        // Canvas要素を作成
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // Canvasのサイズを画像のサイズに設定
        canvas.width = img.width
        canvas.height = img.height
        // 画像をCanvasに描画
        if (ctx) ctx.globalAlpha = 0.85
        // 画像に透過処理を入れる
        ctx?.drawImage(img, 0, 0)
        // Canvasの内容をBase64形式のデータとして取得
        // TODO iPhoneでは「SecurityError: The operation is insecure.」エラーになる。
        const base64data = canvas.toDataURL()
        // ここでbase64dataを使用して必要な処理を行います
        resolve(base64data)
      }
      // 画像を読み込み開始
      img.src = imagePath
    })

  /**
   * 音声アイテムの入力パラメータを生成する
   */
  const generateAudioItem = async (radioData: RadioSyntheticDataType, duration: number) =>
    Promise.all(
      radioData.radioDataSet.map(async (radio) => {
        // 音声の再生開始位置を計算し、m秒を秒に修正するため
        const startTime = (radio.clip_start_time - radioData.clippingStartActualTime) / 1000

        // 音声の再生終了位置を計算する。動画終了位置より音声の終了位置の方が長くなる場合には、動画終了位置を音声の終了位置にする。
        const itemEndTime =
          startTime + radio.duration > duration ? duration - startTime : startTime + radio.duration

        // 音声のファイルをダウンロードする
        const result = await loadFile<ArrayBuffer>(CloudFrontUtil.getSignedUrl(radio.audio_clip), {
          responseType: 'blob',
          headers: {
            accept: 'audio/wav',
            'Content-Type': 'application/json',
          },
        })

        // 音声のファイルをbase64に変換
        const base64 = (await FileConverter.convertBlobToBase64(
          new Blob([result?.data], { type: 'audio/wav' }),
        )) as string

        // 音声アイテムの入力パラメータを生成する
        const audioItem: AudioItemType = {
          startTime,
          itemStartTime: 0,
          itemEndTime,
          fileFormat: 'wav',
          base64AudioData: base64.replace('data:audio/wav;base64,', ''),
          audioWeight: 1,
          skipProcessSnapshot: false,
        }
        return audioItem
      }),
    )

  /**
   * ハイライト動画をエクスポートする
   */
  const exportHighlight = async (
    url: string,
    startTime: number,
    endTime: number,
    radioData: RadioSyntheticDataType | null,
  ): Promise<ExportHighlightResponseType> => {
    const base64 = MOVIE_EXPORT_WATER_MARK_FOR_BASE64
    const signature = LocalCache.makeSignatures(LoginStore.value.movieCookies || [])
    const audioItem = radioData ? await generateAudioItem(radioData, endTime - startTime) : []
    const recordingMethod: Array<RecordingMethod> = [
      {
        movieInfo: {
          url,
          signature,
        },
        startTime,
        endTime,
      },
    ]
    const options: MovieExportOptionsType = {
      movieSize: MOVIE_EXPORT_MOVIE_SIZE,
      viewItem: [
        {
          rect: MOVIE_EXPORT_VIEW_ITEM_RECT,
          startTime: 0,
          endTime: endTime - startTime,
          base64ImageData: base64,
          hidePauseTime: false,
        },
      ],
      audioItem,
      baseAudioWeight: 0,
    }
    return new Promise((resolve) => {
      movieExport(
        recordingMethod,
        options,
        (progress: ProgressListenerType) => {
          // 進捗状況を更新する
          LoadingOverLayStore.value.updateProgressList(
            'highlightExport',
            Math.round(Number(progress.progress) * 100),
          )
          Logger.info(`useMovieExport#exportHighlight progress: ${progress.progress}`)
        },
        (response: SuccessResponseType) => {
          // 進捗状況を更新する
          LoadingOverLayStore.value.updateProgressList('highlightExport', 100)
          Logger.info('useMovieExport#exportHighlight success')
          const result: ExportHighlightResponseType = {
            isSuccess: true,
            response,
          }
          resolve(result)
        },
        (error: ErrorResponseType) => {
          Logger.debug(
            `useMovieExport#exportHighlight errorCode: ${error.errorCode} errorMessage: ${error.message}`,
          )
          const result: ExportHighlightResponseType = {
            isSuccess: false,
            response: error,
          }
          resolve(result)
        },
      )
    })
  }

  return { exportHighlight }
}
