var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "highlight-download-icon",
      attrs: {
        viewBox: "0 0 19 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.25 9.99996V17.5275H16.75V9.99996H15.25V16.0275H3.75V9.99996H2.25Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.3297 7.99175L10.2484 10.073L10.2479 2H8.74792L8.74838 10.0673L6.67282 7.99175H4.5515L8.79414 12.2344C9.18467 12.6249 9.81783 12.6249 10.2084 12.2344L14.451 7.99175H12.3297Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }