import { computed, reactive } from '@vue/composition-api'
import dayjs from 'dayjs'
import { StoreBase, ValueType } from '@/store/StoreBase'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import useChampionships from '@/store/hook/useChampionships'
import useResultData from '@/store/hook/useResultData'
import useStandingsData from '@/store/hook/useStandingsData'
import usePlayer from '@/store/hook/usePlayer'
import useTeam from '@/store/hook/useTeam'

/**
 * 初期状態
 */
const initialState = {
  /**
   * 選択中の年度
   */
  selectedYear: dayjs().year(),
  /**
   * 選択中の選手Id
   */
  selectedPlayer: null as PlayerDocument | null,
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoのStandings画面のStore
 */
class StandingsPageStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    // hook
    const { fetchChampionships, championships, clearChampionships } = useChampionships()
    const { fetchResultData, resultListByCarNo, resultListByRound, clearResultData } =
      useResultData()
    const { fetchStandingsData, standingsListByYear, clearStandingsData } = useStandingsData()
    const { fetchTargetYearPlayers, players, findPlayersById, clearPlayers } = usePlayer()
    const { fetchTargetYearTeams, teams, clearTeams } = useTeam()

    /**
     * 選択中の年度
     * return { number } 選択中の年度
     */
    const selectedYear = computed({
      get: () => state.selectedYear,
      set: (year: number) => {
        state.selectedYear = year
      },
    })

    /**
     * 選択中の選手
     * return { PlayerDocument } 選択中の選手情報
     */
    const selectedPlayer = computed({
      get: (): PlayerDocument =>
        <PlayerDocument>players.value.find((item) => item.sid === state.selectedPlayer?.sid),
      set: (player: PlayerDocument) => {
        state.selectedPlayer = player
      },
    })

    /**
     * Standingsトップページに必要な情報を取得する。
     */
    const fetchStandingsTopPageData = () =>
      Promise.all([
        fetchChampionships(selectedYear.value),
        fetchResultData(selectedYear.value),
        fetchStandingsData(),
        fetchTargetYearPlayers(selectedYear.value),
        fetchTargetYearTeams(selectedYear.value),
      ])

    /**
     * Standings画面のデータをクリアする
     */
    const clearStandingsPageData = () => {
      clearChampionships()
      clearResultData()
      clearStandingsData()
      clearPlayers()
      clearTeams()
      Object.assign(state, initialState)
    }

    return {
      // Storeで管理している変数
      selectedYear,
      selectedPlayer,
      // 大会
      championships,
      // Resultデータ
      resultListByCarNo,
      resultListByRound,
      // Standingsデータ
      standingsListByYear,
      // ドライバー
      players,
      findPlayersById,
      // チーム
      teams,
      // Storeのメソッド
      fetchStandingsTopPageData,
      clearStandingsPageData,
    }
  }
}

const value: ValueType<StandingsPageStore> = {}

export default {
  createStore: new StandingsPageStore().createStore,
  value: value as Required<typeof value>,
}
