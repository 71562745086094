var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-notification-item-contents-parts",
    {
      staticClass: "notification-item-contents-parts",
      attrs: { notification: _vm.notification },
      on: {
        handlerClickLink: function ($event) {
          return _vm.$emit("handlerClickLink", _vm.notification)
        },
      },
    },
    [
      _vm.notification.thumbnail
        ? _c(
            "div",
            { staticClass: "notification-item-contents-parts__thumbnail" },
            [
              _c("img", {
                attrs: { src: _vm.notification.thumbnail, alt: "" },
              }),
              _vm.notification.mediaType === "video"
                ? _c(
                    "svg",
                    {
                      staticClass:
                        "notification-item-contents-parts__play-icon",
                      attrs: {
                        width: "48",
                        height: "49",
                        viewBox: "0 0 48 49",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("g", { attrs: { opacity: "0.7" } }, [
                        _c("path", {
                          attrs: {
                            d: "M9 6.27051L37.9011 22.5274C39.2604 23.292 39.2604 25.2491 37.9011 26.0137L9 42.2705V6.27051Z",
                            fill: "white",
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }