var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-telemetry-section",
      class: "race-telemetry-section--" + _vm.screenOrientationType,
    },
    [
      _c("img", {
        staticClass: "race-telemetry-section__bg",
        attrs: {
          src: "assets/img/RaceVideoPage/telemetry/telemetry_bg.png",
          alt: "",
          width: "343",
          height: "501",
        },
      }),
      _c("telemetry-ots-parts", {
        attrs: {
          otsEnabled: _vm.currentTelemetryData.otsEnabled,
          otsDisabledTime: _vm.currentTelemetryData.otsDisabledTime,
        },
        model: {
          value: _vm.currentTelemetryData.ots,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "ots", $$v)
          },
          expression: "currentTelemetryData.ots",
        },
      }),
      _c("telemetry-gear-pos-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.currentTelemetryData.gear,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "gear", $$v)
          },
          expression: "currentTelemetryData.gear",
        },
      }),
      _c("telemetry-car-speed-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.currentTelemetryData.speed,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "speed", $$v)
          },
          expression: "currentTelemetryData.speed",
        },
      }),
      _c("telemetry-rpm-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.currentTelemetryData.rpm,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "rpm", $$v)
          },
          expression: "currentTelemetryData.rpm",
        },
      }),
      _c("telemetry-steering-angle-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.currentTelemetryData.steering,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "steering", $$v)
          },
          expression: "currentTelemetryData.steering",
        },
      }),
      _c("telemetry-brk-press-fr-parts", {
        attrs: { max: 70 },
        model: {
          value: _vm.currentTelemetryData.brake,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "brake", $$v)
          },
          expression: "currentTelemetryData.brake",
        },
      }),
      _c("telemetry-rpm-gauge-parts", {
        attrs: { max: 11000 },
        model: {
          value: _vm.currentTelemetryData.rpm,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "rpm", $$v)
          },
          expression: "currentTelemetryData.rpm",
        },
      }),
      _c("telemetry-throttle-pedal-parts", {
        attrs: { max: 100 },
        model: {
          value: _vm.currentTelemetryData.throttle,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "throttle", $$v)
          },
          expression: "currentTelemetryData.throttle",
        },
      }),
      _c("telemetry-fuel-used-parts", {
        attrs: { diffMs: _vm.diffMs, max: 80 },
        model: {
          value: _vm.currentTelemetryData.fuel,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "fuel", $$v)
          },
          expression: "currentTelemetryData.fuel",
        },
      }),
      _c("telemetry-tire-temperature-section", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.currentTelemetryData.tireTemperature,
          callback: function ($$v) {
            _vm.$set(_vm.currentTelemetryData, "tireTemperature", $$v)
          },
          expression: "currentTelemetryData.tireTemperature",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }