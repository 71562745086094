var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volume-controller-parts" },
    [
      _c(
        "button",
        {
          staticClass: "volume-controller-parts__button",
          on: { click: _vm.toggleMute },
        },
        [
          _vm.volume.currentVolume === 0
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "white",
                      "fill-opacity": "0.7",
                      d: "M12,4L7,9H3v6h2l7-7V4z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "white",
                      "fill-opacity": "0.7",
                      d: "M14,3.2v2c0.2,0.1,0.4,0.1,0.5,0.2L16,4C15.4,3.7,14.7,3.4,14,3.2z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "white",
                      "fill-opacity": "0.7",
                      d: "M19,6.4C19,6.4,19,6.3,19,6.4l2-2.1L19.7,3l-2,2l-1.4,1.4l-1.8,1.8L14,8.7l-2,2l-4.6,4.6L3,19.7L4.3,21l4.4-4.4L10,18l2,2v-6.8l2-2V16c0.8-0.4,1.4-0.9,1.8-1.6c0.4-0.7,0.7-1.5,0.7-2.4c0-0.2,0-0.4,0-0.5c-0.1-0.7-0.3-1.3-0.6-1.9c0,0-0.1-0.1-0.1-0.1l1.8-1.8c0,0,0,0.1,0.1,0.1c0.4,0.5,0.7,1.1,0.9,1.6c0.3,0.8,0.5,1.6,0.5,2.6c0,1.6-0.5,3-1.4,4.2c-0.9,1.2-2.1,2.1-3.6,2.5v2c2.1-0.5,3.7-1.5,5-3.1C20.3,16,21,14.1,21,12c0-1.5-0.3-2.8-1-4.1C19.8,7.4,19.4,6.9,19,6.4z",
                    },
                  }),
                ]
              )
            : _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M14 20.7266V18.6766C15.5 18.2432 16.7083 17.4099 17.625 16.1766C18.5417 14.9432 19 13.5432 19 11.9766C19 10.4099 18.5417 9.0099 17.625 7.77656C16.7083 6.54323 15.5 5.7099 14 5.27656V3.22656C16.0667 3.69323 17.75 4.7389 19.05 6.36356C20.35 7.9889 21 9.8599 21 11.9766C21 14.0932 20.35 15.9639 19.05 17.5886C17.75 19.2139 16.0667 20.2599 14 20.7266ZM3 15.0016V9.00156H7L12 4.00156V20.0016L7 15.0016H3ZM14 16.0016V7.95156C14.7833 8.31823 15.396 8.86823 15.838 9.60156C16.2793 10.3349 16.5 11.1349 16.5 12.0016C16.5 12.8516 16.2793 13.6389 15.838 14.3636C15.396 15.0889 14.7833 15.6349 14 16.0016Z",
                      fill: "white",
                      "fill-opacity": "0.7",
                    },
                  }),
                ]
              ),
        ]
      ),
      _c("volume-seek-bar-parts", {
        staticClass: "volume-controller-parts__seek-bar",
        on: { handleTouchEndSeekBar: _vm.handleTouchEndSeekBar },
        model: {
          value: _vm.volume,
          callback: function ($$v) {
            _vm.volume = $$v
          },
          expression: "volume",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }