import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import NotificationDocument from '@/store/stores/collectionModule/documents/notification/NotificationDocument'

/**
 * お知らせ情報を操作するための処理
 */
export default function useNotification() {
  // Collection modules
  const notificationCollectionModule = CollectionModule.createStore(NotificationDocument)
  /**
   * お知らせ一覧取得
   * 半年前までの情報を取得できるようにしている
   */
  const fetchNotifications = () => {
    const sixMonthsAgo = dayjs().subtract(6, 'month').add(1, 'day').startOf('day').valueOf()
    return notificationCollectionModule.fetch({
      query: {
        filter: { releaseDate: { $gte: sixMonthsAgo } },
        // releaseDateが新しい順にソートする
        sort: '-releaseDate',
      },
    })
  }
  /**
   * 特定の日時以降のお知らせデータを取得する
   * 通知アイコン表示用に使用するため、データをストアに保存しない
   * 取得対象期間は半年前までとする
   *
   * @param lastFetchedDate 前回のお知らせ情報取得日時。指定した場合、この日時以降に登録されたデータのみを再取得する
   */
  const fetchNotificationsForNotify = (lastFetchedDate?: number) =>
    notificationCollectionModule.fetch({
      query: {
        filter: {
          releaseDate: { $gte: lastFetchedDate },
        },
        sort: '-releaseDate',
        limit: 1,
      },
      isSaveInStore: false,
      // isUnionExistData: !!lastModifiedDate,
    })
  /**
   * 取得したお知らせ情報一覧
   */
  const notificationList = computed(() => notificationCollectionModule.data)
  /**
   * 取得したお知らせ情報をクリアする
   */
  const clearNotifications = () => {
    notificationCollectionModule.clearData()
  }
  return {
    notificationList,
    fetchNotifications,
    fetchNotificationsForNotify,
    clearNotifications,
  }
}
