var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "attention-message-section" }, [
    _c("i", { staticClass: "attention-message-section__icon" }, [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "21",
            height: "20",
            viewBox: "0 0 21 20",
            fill: "none",
          },
        },
        [
          _c("g", { attrs: { "clip-path": "url(#clip0_14114_171474)" } }, [
            _c("path", {
              attrs: {
                d: "M3.42984 17.0698C2.47473 16.1474 1.71291 15.0439 1.18882 13.8239C0.664731 12.6038 0.388869 11.2916 0.377331 9.96385C0.365793 8.63605 0.61881 7.31926 1.12162 6.09029C1.62443 4.86133 2.36696 3.74481 3.30589 2.80589C4.24481 1.86696 5.36133 1.12443 6.59029 0.621618C7.81926 0.11881 9.13605 -0.134207 10.4638 -0.122669C11.7916 -0.111131 13.1038 0.164731 14.3239 0.688821C15.5439 1.21291 16.6474 1.97473 17.5698 2.92984C19.3914 4.81586 20.3994 7.34188 20.3766 9.96385C20.3538 12.5858 19.3021 15.0939 17.448 16.948C15.5939 18.8021 13.0858 19.8538 10.4638 19.8766C7.84188 19.8994 5.31586 18.8914 3.42984 17.0698ZM16.1598 15.6598C17.661 14.1587 18.5043 12.1227 18.5043 9.99984C18.5043 7.87692 17.661 5.84096 16.1598 4.33984C14.6587 2.83871 12.6227 1.99539 10.4998 1.99539C8.37692 1.99539 6.34096 2.83871 4.83984 4.33984C3.33871 5.84096 2.49539 7.87692 2.49539 9.99984C2.49539 12.1227 3.33871 14.1587 4.83984 15.6598C6.34096 17.161 8.37692 18.0043 10.4998 18.0043C12.6227 18.0043 14.6587 17.161 16.1598 15.6598ZM9.49984 4.99984H11.4998V10.9998H9.49984V4.99984ZM9.49984 12.9998H11.4998V14.9998H9.49984V12.9998Z",
                fill: "#FF2E00",
              },
            }),
          ]),
          _c("defs", [
            _c("clipPath", { attrs: { id: "clip0_14114_171474" } }, [
              _c("rect", {
                attrs: {
                  width: "20",
                  height: "20",
                  fill: "white",
                  transform: "translate(0.5)",
                },
              }),
            ]),
          ]),
        ]
      ),
    ]),
    _vm.message
      ? _c("h4", {
          staticClass: "attention-message-section__message",
          domProps: { innerHTML: _vm._s(_vm.message) },
        })
      : _vm._e(),
    _vm.note
      ? _c("p", {
          staticClass: "attention-message-section__note",
          domProps: { innerHTML: _vm._s(_vm.note) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }