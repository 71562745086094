




















import { defineComponent, PropType } from '@vue/composition-api'
import CouponPlanCardParts from '@/components/SignupPage/common/CouponPlanCardParts.vue'
import PlanCardParts from '@/components/SignupPage/common/PlanCardParts.vue'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { ContractTermType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 新規会員登録 プラン比較
 */
export default defineComponent({
  name: 'CurrentPlanCardSection',
  components: {
    CouponPlanCardParts,
    PlanCardParts,
  },
  props: {
    /**
     * 表示するプラン
     */
    plan: {
      type: Object as PropType<ContractPlanDocument>,
      default: null,
    },
    /**
     * 表示するタイトル
     */
    sectionTitle: {
      type: String,
      default: '',
    },
    /**
     * ワイタイムパスかどうか
     */
    isOneTimePassUsed: {
      type: Boolean,
      default: false,
    },
    /**
     * 確認画面かどうか
     */
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    /**
     * 契約中のプランの契約期間
     */
    contractTerm: {
      type: Object as PropType<ContractTermType>,
      default: null,
    },
  },
})
