var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "carousel-list-wrapper-parts",
      class: "carousel-list-wrapper-parts--" + _vm.carouselListType,
    },
    [
      _c(
        "div",
        {
          ref: "sceneListWrapperPartsContent",
          staticClass: "carousel-list-wrapper-parts__content",
          on: { scroll: _vm.checkCarouselEnabled },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-list-wrapper-parts__list" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm.enabledCarouselList
        ? _c(
            "button",
            {
              staticClass:
                "carousel-list-wrapper-parts__carousel carousel-list-wrapper-parts__carousel-prev",
              class: {
                "carousel-list-wrapper-parts__carousel--active":
                  _vm.enabledCarouselPrev,
              },
              on: {
                click: function ($event) {
                  return _vm.onclickCarousel("prev")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/icon/icon_chevron_left_24.svg"),
                  alt: "<",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.enabledCarouselList
        ? _c(
            "button",
            {
              staticClass:
                "carousel-list-wrapper-parts__carousel carousel-list-wrapper-parts__carousel-next",
              class: {
                "carousel-list-wrapper-parts__carousel--active":
                  _vm.enabledCarouselNext,
              },
              on: {
                click: function ($event) {
                  return _vm.onclickCarousel("next")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/icon/icon_chevron_right_24.svg"),
                  alt: ">",
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }