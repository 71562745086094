


































import { defineComponent, PropType } from '@vue/composition-api'
import SnsPostItemHeaderParts from '@/components/HomePage/HomePane/parts/SnsPostItemHeaderParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'

/**
 * HOME SNSコンテンツ 投稿アイテム ハイライトコメント用パーツ
 */
export default defineComponent({
  name: 'HighlightsPostItemContentsParts',
  components: {
    SnsPostItemHeaderParts,
  },
  props: {
    snsPost: {
      type: Object as PropType<SnsPostData>,
      required: true,
    },
  },
})
