var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-comment-toggle-parts" }, [
    _c(
      "button",
      {
        staticClass: "highlight-comment-toggle-parts__button",
        class: {
          "highlight-comment-toggle-parts__button--active": _vm.isActive,
        },
        on: { click: _vm.handleToggleCommentClicked },
      },
      [
        _c(
          "span",
          { staticClass: "highlight-comment-toggle-parts__button-label" },
          [
            _vm._v(
              _vm._s(_vm.commentCounts) +
                " " +
                _vm._s(_vm.commentToggleButtonLabel)
            ),
          ]
        ),
        _c(
          "span",
          { staticClass: "highlight-comment-toggle-parts__button-icon" },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M1.72388 5.33331L6.90008 10.5095C7.50756 11.117 8.49248 11.117 9.09996 10.5095L14.2762 5.33331L12.3905 5.33331L8.15715 9.5667C8.07037 9.65349 7.92967 9.65349 7.84289 9.5667L3.6095 5.33331H1.72388Z",
                    fill: "white",
                  },
                }),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }