var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-reset-mail-sent-section" }, [
    _c("h4", { staticClass: "password-reset-mail-sent-section__title" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("PasswordResetPage.MailSentPage.title")) + " "
      ),
    ]),
    _c("p", { staticClass: "password-reset-mail-sent-section__message" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("PasswordResetPage.MailSentPage.message")) + " "
      ),
    ]),
    _c("p", {
      staticClass: "password-reset-mail-sent-section__attention",
      domProps: {
        innerHTML: _vm._s(_vm.$t("PasswordResetPage.MailSentPage.attention")),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }