var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reward-notice-modal-section" },
    [
      _c(
        "div",
        { staticClass: "reward-notice-modal-section__container" },
        [
          _c("common-modal-section", { attrs: { hasCloseButton: false } }, [
            _c(
              "div",
              {
                staticClass: "reward-notice-modal-section__header",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("p", { staticClass: "reward-notice-modal-section__label" }, [
                  _vm._v(_vm._s(_vm.$tc("RewardNoticeModal.label"))),
                ]),
                _c("p", { staticClass: "reward-notice-modal-section__title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc("RewardNoticeModal.title", 0, {
                          name: _vm.reward.title[_vm.$i18n.locale],
                        })
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "reward-notice-modal-section__body" }, [
              _c("div", { staticClass: "reward-notice-modal-section__image" }, [
                _c("img", {
                  ref: "rewardImage",
                  style: _vm.imageStyle,
                  attrs: { src: _vm.signedImagePath, alt: "" },
                }),
              ]),
              _c("div", { staticClass: "reward-notice-modal-section__main" }, [
                _c("p", {
                  staticClass: "reward-notice-modal-section__message",
                  domProps: {
                    textContent: _vm._s(
                      _vm.reward.description[_vm.$i18n.locale]
                    ),
                  },
                }),
                _c(
                  "p",
                  { staticClass: "reward-notice-modal-section__deadline" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.deadlineLabel + "：" + _vm.reward.deadline) +
                        " "
                    ),
                  ]
                ),
                _c("p", {
                  staticClass: "reward-notice-modal-section__memo",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tc("RewardNoticeModal.memo", 0, {
                        totalLength: _vm.newArrivalsRewardsLength,
                      })
                    ),
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "reward-notice-modal-section__buttons",
                attrs: { slot: "buttons" },
                slot: "buttons",
              },
              [
                _c(
                  "ul",
                  { staticClass: "reward-notice-modal-section__button-list" },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "reward-notice-modal-section__button-item",
                      },
                      [
                        _c("atom-button", {
                          attrs: { linkText: _vm.buttonLabel },
                          on: { onClick: _vm.handleClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "reward-notice-modal-section__button-item",
                      },
                      [
                        _c("atom-button", {
                          attrs: {
                            linkText: _vm.$tc(
                              "RewardPage.buttonLabel.showListPage"
                            ),
                            colorType: "secondary",
                          },
                          on: { onClick: _vm.handleShowListPageClicked },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "reward-notice-modal-section__button-item",
                      },
                      [
                        _c("atom-button", {
                          attrs: {
                            linkText: _vm.$tc("RewardPage.buttonLabel.close"),
                            colorType: "secondary",
                          },
                          on: { onClick: _vm.handleCloseClicked },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm.videoModalEnabled && _vm.reward.videoId
        ? _c("limited-video-modal-section", {
            attrs: { videoId: _vm.reward.videoId },
            on: { close: _vm.hideVideoModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }