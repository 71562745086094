var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-referral-code-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageReferralCode.pageTitle"),
        },
      }),
      _c("referral-code-pane", {
        staticClass: "mypage-referral-code-page__content",
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector: ".mypage-referral-code-page__content",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
        ],
        1
      ),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }