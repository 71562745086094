






















import { defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * 無料会員が有料会員コンテンツへアクセスを試みた時に表示するモーダル
 * 非公開コンテンツへのアクセスを試みた場合にもこちらのモーダルを表示する
 */
export default defineComponent({
  name: 'FlowLineToPaidPlanModalSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomIconLinkButton,
    AtomInputButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    submitText: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitClose(): void {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.$emit('close')
    },
  },
})
