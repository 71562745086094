import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import type { OpeningDatesType } from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import I18n from '@/locales/I18n'

/**
 * デジタルチケットチケットデータを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3805478936/13.
 */
export default class DigitalTicketDataTicketDocument extends DocumentWrapper {
  constructor(initProps?: Partial<DigitalTicketDataTicketDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    const digitalTicketInitProps = initProps
    const days: Array<OpeningDatesType> = ['day1', 'day2', 'day3']
    days.forEach((day) => {
      if (digitalTicketInitProps?.availableDates) {
        digitalTicketInitProps.availableDates[day] =
          digitalTicketInitProps?.availableDates[day] ?? false
      }
    })
    this.mergeToThis(digitalTicketInitProps)
  }

  /**
   * 商品コード
   */
  productNumber: string | null = null

  /**
   * チケット日本語
   */
  ticketJa: string | null = null

  /**
   * チケット英語
   */
  ticketEn: string | null = null

  /**
   * 利用可能日
   */
  availableDates: Record<OpeningDatesType, boolean> | null = null

  /**
   * 集合時間
   */
  meetingTime: number | null = null

  /**
   * 表示順
   */
  order: number | null = null

  /**
   * チケット名
   */
  get ticketName() {
    return I18n.locale === 'ja' ? this.ticketJa : this.ticketEn
  }
}
