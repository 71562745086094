import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import { MissionOperationType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import RaceHistoryDocument from '@/store/stores/collectionModule/documents/history/RaceHistoryDocument'
import OnboardHistoryDocument from '@/store/stores/collectionModule/documents/history/OnboardHistoryDocument'
import SceneHighlightHistoryDocument from '@/store/stores/collectionModule/documents/history/SceneHighlightHistoryDocument'
import OnboardHighlightHistoryDocument from '@/store/stores/collectionModule/documents/history/OnboardHighlightHistoryDocument'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'
import ContractInfoHistoryDocument from '@/store/stores/collectionModule/documents/history/ContractInfoHistoryDocument'
import MissionHistoryDocument from '@/store/stores/collectionModule/documents/history/MissionHistoryDocument'
import OrganizationHistoryDocument from '@/store/stores/collectionModule/documents/history/OrganizationHistoryDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

/**
 * 動画アングル。
 * メイン映像か、現在選択されている選手のオンボード映像のどちらかを表す。
 * - race: メイン映像
 * - player: オンボード映像
 */
export type ViewAngleType = 'race' | 'player'

/**
 * 視聴履歴を操作するための処理を取得する。
 */
export default function useHistory() {
  // Collection modules
  const raceHistoryCollectionModule = CollectionModule.createStore(RaceHistoryDocument)
  const onboardHistoeyCollectionModule = CollectionModule.createStore(OnboardHistoryDocument)
  const SceneHighlightHistoryCollectionModule = CollectionModule.createStore(
    SceneHighlightHistoryDocument,
  )
  const OnboardHighlightHistoryCollectionModule = CollectionModule.createStore(
    OnboardHighlightHistoryDocument,
  )
  const contractInfoHistoryDocument = CollectionModule.createStore(ContractInfoHistoryDocument)
  const missionHistoryCollectionModule = CollectionModule.createStore(MissionHistoryDocument)
  const organizationHistoryCollectionModule = CollectionModule.createStore(
    OrganizationHistoryDocument,
  )

  // methods
  const saveRaceHistory = (
    targetChampionship: ChampionshipDocument,
    targetRace: RaceDocument,
    user: UserDocument,
  ) => {
    const browsingDateData = dayjs().format('YYYY-MM-DD hh:mm:ss')
    const date = targetChampionship.startDate
    const raceYearData = date ? dayjs(date).locale('ja').format('YYYY') : ''
    return raceHistoryCollectionModule.save(
      new RaceHistoryDocument({
        targetId: targetRace.id,
        targetCollectionName: 'game_match',
        operation: 'show_race',
        needNotification: false,
        contents: {
          raceYear: raceYearData,
          championshipId: targetChampionship.id,
          championshipName: `ROUND ${targetChampionship.round} ${targetChampionship.championshipName.ja}`,
          matchId: targetRace.id,
          title: targetRace.title,
          raceType: targetRace.raceType === 'RACE' ? '決勝' : '予選',
          userId: user.id,
          userName: `${user.familyName} ${user.firstName}`,
          browsingDate: browsingDateData,
        },
      }),
    )
  }

  const saveRaceOnboardHistory = (
    targetChampionship: ChampionshipDocument,
    targetRace: RaceDocument,
    user: UserDocument,
    playerIdData: string,
    playerNameData: string,
  ) => {
    const browsingDateData = dayjs().format('YYYY-MM-DD hh:mm:ss')
    const date = targetChampionship.startDate
    const raceYearData = date ? dayjs(date).locale('ja').format('YYYY') : ''
    return onboardHistoeyCollectionModule.save(
      new OnboardHistoryDocument({
        targetId: targetRace.id,
        targetCollectionName: 'game_match',
        operation: 'show_onboard',
        needNotification: false,
        contents: {
          raceYear: raceYearData,
          championshipId: targetChampionship.id,
          championshipName: `ROUND ${targetChampionship.round} ${targetChampionship.championshipName.ja}`,
          matchId: targetRace.id,
          title: targetRace.title,
          raceType: targetRace.raceType === 'RACE' ? '決勝' : '予選',
          playerId: playerIdData,
          playerName: playerNameData,
          userId: user.id,
          userName: `${user.familyName} ${user.firstName}`,
          browsingDate: browsingDateData,
        },
      }),
    )
  }

  const saveHighlightHistory = (
    highlightId: string,
    targetChampionship: ChampionshipDocument,
    targetRace: RaceDocument,
    user: UserDocument,
    playEventIdData: string,
    playEventNameData: string,
  ) => {
    const browsingDateData = dayjs().format('YYYY-MM-DD hh:mm:ss')
    const date = targetChampionship.startDate
    const raceYearData = date ? dayjs(date).locale('ja').format('YYYY') : ''
    return SceneHighlightHistoryCollectionModule.save(
      new SceneHighlightHistoryDocument({
        targetId: highlightId,
        targetCollectionName: 'communication_user_game_event',
        operation: 'show_highlight',
        needNotification: false,
        contents: {
          raceYear: raceYearData,
          championshipId: targetChampionship.id,
          championshipName: `ROUND ${targetChampionship.round} ${targetChampionship.championshipName.ja}`,
          matchId: targetRace.id,
          title: targetRace.title,
          raceType: targetRace.raceType === 'RACE' ? '決勝' : '予選',
          playEventId: playEventIdData,
          playEventName: playEventNameData,
          userId: user.id,
          userName: `${user.familyName} ${user.firstName}`,
          browsingDate: browsingDateData,
        },
      }),
    )
  }

  const saveHighlightOnboardHistory = (
    highlightId: string,
    targetChampionship: ChampionshipDocument,
    targetRace: RaceDocument,
    user: UserDocument,
    playEventIdData: string,
    playEventNameData: string,
    playerIdData: string,
    playerNameData: string,
  ) => {
    const browsingDateData = dayjs().format('YYYY-MM-DD hh:mm:ss')
    const date = targetChampionship.startDate
    const raceYearData = date ? dayjs(date).locale('ja').format('YYYY') : ''
    return OnboardHighlightHistoryCollectionModule.save(
      new OnboardHighlightHistoryDocument({
        targetId: highlightId,
        targetCollectionName: 'communication_user_game_event',
        operation: 'show_highlight_onboard',
        needNotification: false,
        contents: {
          raceYear: raceYearData,
          championshipId: targetChampionship.id,
          championshipName: `ROUND ${targetChampionship.round} ${targetChampionship.championshipName.ja}`,
          matchId: targetRace.id,
          title: targetRace.title,
          raceType: targetRace.raceType === 'RACE' ? '決勝' : '予選',
          playEventId: playEventIdData,
          playEventName: playEventNameData,
          playerId: playerIdData,
          playerName: playerNameData,
          userId: user.id,
          userName: `${user.familyName} ${user.firstName}`,
          browsingDate: browsingDateData,
        },
      }),
    )
  }

  /**
   * 契約情報を更新ログ
   */
  const saveContractInfoHistory = (contractInfoId: string, user: UserDocument) =>
    contractInfoHistoryDocument.save(
      new ContractInfoHistoryDocument({
        targetId: contractInfoId,
        targetCollectionName: 'manage_contract_info',
        operation: 'update_contract_info',
        needNotification: true,
        contents: {
          userId: user.id,
          userName: `${user.familyName} ${user.firstName}`,
        },
        acl: { privateFlag: true },
      }),
    )

  /**
   * ミッションの操作ログを登録
   * @param targetId ログとして登録する操作対象のプライマリキー
   * @param targetCollectionName ログとして登録する操作対象のコレクション名
   * @param operation 操作種別
   * @param missionTargetUserId ミッション対象先ユーザID。
   * 操作種別が特定の値の場合は、ミッション対象先ユーザがポイント付与の対象とする。
   */
  const saveMissionHistory = (
    targetId: string,
    targetCollectionName: string,
    operation: MissionOperationType,
    missionTargetUserId?: string | null,
  ) =>
    missionHistoryCollectionModule.save(
      new MissionHistoryDocument({
        targetId,
        targetCollectionName,
        operation,
        needNotification: false,
        missionTargetUserId,
      }),
    )

  /**
   * プロフィールミッションの操作ログを登録
   */
  const saveMissionHistoryForProfile = (user: UserDocument) => {
    if (user.birthDay) {
      saveMissionHistory(user.id || '', 'manage_user', 'register_age')
    }

    if (user.country) {
      saveMissionHistory(user.id || '', 'manage_user', 'register_area')
    }

    if (user.gender) {
      saveMissionHistory(user.id || '', 'manage_user', 'register_gender')
    }
  }

  /**
   * 契約情報を更新ログ
   */
  const saveOrganizationHistory = (organization: OrganizationDocument, user: UserDocument) =>
    organizationHistoryCollectionModule.save(
      new OrganizationHistoryDocument({
        targetId: organization.id,
        targetCollectionName: 'manage_organization',
        operation: 'update_organization',
        needNotification: true,
        contents: {
          organizationId: organization.id,
          userId: user.id,
          userName: `${user.familyName} ${user.firstName}`,
        },
        acl: { privateFlag: true },
      }),
    )

  return {
    saveRaceHistory,
    saveRaceOnboardHistory,
    saveHighlightHistory,
    saveHighlightOnboardHistory,
    saveContractInfoHistory,
    saveMissionHistory,
    saveMissionHistoryForProfile,
    saveOrganizationHistory,
  }
}
