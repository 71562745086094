















































import { defineComponent } from '@vue/composition-api'
import VolumeSeekBarParts from '@/components/RaceVideoPage/RaceVideoPane/parts/VolumeSeekBarParts.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * レース動画再生画面 動画プレーヤー 音量調整パーツ
 */
export default defineComponent({
  name: 'VolumeControllerParts',
  components: {
    VolumeSeekBarParts,
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

    return {
      volume: raceVideoPageStore.raceVideoPageState.volume,
    }
  },
  methods: {
    /**
     * volumeの値を渡す
     */
    handleTouchEndSeekBar(): void {
      if (this.volume.muted) {
        this.volume.muted = false
        this.$emit('handleVolumeMute', this.volume.muted)
      }
      this.$emit('handleTouchEndSeekBar', this.volume.currentVolume)
    },
    /**
     * Muteの値を渡す
     */
    toggleMute(): void {
      if (this.volume.currentVolume === 0) {
        // Muteからの回帰
        this.volume.currentVolume = this.volume.tempVolume
        this.volume.muted = false
        this.volume.currentVolume = this.volume.tempVolume
        this.$emit('handleVolumeMute', this.volume.muted)
        this.$emit('handleTouchEndSeekBar', this.volume.currentVolume)
      } else {
        // Mute処理
        this.volume.tempVolume = this.volume.currentVolume
        this.volume.currentVolume = 0
        this.volume.muted = true
        this.$emit('handleVolumeMute', this.volume.muted)
        this.$emit('handleTouchEndSeekBar', this.volume.currentVolume)
      }
    },
  },
})
