var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-ranking-table-section" }, [
    _c("div", { staticClass: "mission-ranking-table-section__heading" }, [
      _c(
        "p",
        { staticClass: "mission-ranking-table-section__heading-ranking" },
        [
          _vm._v(
            " " + _vm._s(_vm.$tc("MissionPage.RankingPage.table.rank")) + " "
          ),
        ]
      ),
      _c("p", { staticClass: "mission-ranking-table-section__heading-name" }, [
        _vm._v(
          " " + _vm._s(_vm.$tc("MissionPage.RankingPage.table.name")) + " "
        ),
      ]),
      _c("p", { staticClass: "mission-ranking-table-section__heading-point" }, [
        _vm._v(
          " " + _vm._s(_vm.$tc("MissionPage.RankingPage.table.point")) + " "
        ),
      ]),
    ]),
    _c("div", { staticClass: "mission-ranking-table-section__body" }, [
      _c(
        "ol",
        { staticClass: "mission-ranking-table-section__list" },
        _vm._l(_vm.rankings, function (user) {
          return _c(
            "li",
            {
              key: user.userId,
              staticClass: "mission-ranking-table-section__item",
              class: {
                "mission-ranking-table-section__item--self": _vm.checkIsOwnRank(
                  user.userId
                ),
              },
            },
            [
              _c("mission-ranking-table-item-parts", {
                attrs: {
                  userData: user,
                  isOwnRank: _vm.checkIsOwnRank(user.userId) || false,
                  retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
                  rankingType: _vm.rankingType,
                },
                on: {
                  onClickSnsButton: _vm.onClickSnsButton,
                  onClickShareIcon: _vm.onClickShareIcon,
                  savePostSnsMissionHistory: function ($event) {
                    return _vm.$emit("savePostSnsMissionHistory")
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }