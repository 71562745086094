















import { defineComponent } from '@vue/composition-api'

/**
 * パスワードリセット 認証メール送信完了 セクション
 */
export default defineComponent({
  name: 'PasswordResetMailSentSection',
})
