var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "contract-log-table-section" }, [
    _c("thead", [
      _c("tr", { staticClass: "contract-log-table-section__thead-row" }, [
        _c("th", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("MypagePage.MypageContract.logPage.date")
            ),
          },
        }),
        _c("th", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("MypagePage.MypageContract.logPage.detail")
            ),
          },
        }),
        _c("th", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("MypagePage.MypageContract.logPage.note")
            ),
          },
        }),
      ]),
    ]),
    _c(
      "tbody",
      _vm._l(_vm.planContractLogData, function (log, index) {
        return _c(
          "tr",
          {
            key: "ContractHistory" + index,
            staticClass: "contract-log-table-section__tbody-row",
            class: {
              "contract-log-table-section__tbody-row--is-error": log.error,
            },
          },
          [
            _c("th", { domProps: { textContent: _vm._s(log.date) } }),
            _c("td", { domProps: { textContent: _vm._s(log.detail) } }),
            _c("td", { domProps: { textContent: _vm._s(log.note) } }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }