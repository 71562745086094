var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-contract-details-pane" }, [
    _vm.isGuideExternalConfirmation
      ? _c(
          "div",
          { staticClass: "mypage-contract-details-pane__notice" },
          [
            _c("external-confirmation-section", {
              attrs: {
                title: _vm.externalConfirmation.title,
                message: _vm.externalConfirmation.message,
                links: _vm.externalConfirmation.links,
              },
            }),
          ],
          1
        )
      : _vm.myContractData
      ? _c(
          "div",
          { staticClass: "mypage-contract-details-pane__main" },
          [
            _c("mypage-current-plan-section", {
              attrs: {
                currentPlanName: _vm.currentPlanName,
                contractTerm: _vm.isCouponUsed ? _vm.contractTerm : undefined,
              },
            }),
            _vm.nextPlanName && _vm.nextPlanStartDate
              ? _c("mypage-next-plan-section", {
                  attrs: {
                    nextPlanName: _vm.nextPlanName,
                    nextPlanStartDate: _vm.nextPlanStartDate,
                  },
                })
              : _vm._e(),
            _vm.myContractData
              ? _c("mypage-contract-details-section", {
                  attrs: { myContractData: _vm.myContractData },
                })
              : _vm._e(),
            _c("div", [
              _c("p", {
                staticClass: "mypage-contract-details-pane__attention",
                domProps: { innerHTML: _vm._s(_vm.attention1) },
              }),
              _vm.attention2
                ? _c("p", {
                    staticClass:
                      "mypage-contract-details-pane__attention mypage-contract-details-pane__attention--stand-out",
                    domProps: { innerHTML: _vm._s(_vm.attention2) },
                  })
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }