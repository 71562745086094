











import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CompletePane from '@/components/pc/MyPage/CompletePane/CompletePane.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * クレジットカード情報変更ページ
 */
export default defineComponent({
  name: 'PaymentCompletePage',
  components: {
    SubHeaderSection,
    CompletePane,
  },
  setup() {
    const store = StoreUtil.useStore('ContractInfoStore')
    const { isReadOnlyPlan } = store

    return {
      isReadOnlyPlan,
    }
  },
})
