import { computed } from '@vue/composition-api'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import StoreUtil from '@/store/StoreUtil'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import useHistory from '@/store/hook/useHistory'

/**
 * レースのInformation画面のRace Point Radio機能を提供する。
 */
export default function useRacePointRadio() {
  const topPageStore = StoreUtil.useStore('TopPageStore')

  // hook
  const { radioDataStore } = topPageStore
  const { saveMissionHistory } = useHistory()

  /**
   * 現在、無線交信データを再生しているかどうかを判定する。
   */
  const isPlayingRadio = computed(() => radioDataStore.isPlayingRadio.value)

  /**
   * 無線交信データの音声を再生する。
   * 無線視聴の、ミッションの操作ログを登録する
   * @param playerRadioData 無線交信データ
   */
  const playRadioAudio = (playerRadioData: RadioDataDocument) => {
    saveMissionHistory(
      UserStore.value.user.value._organization || '',
      'manage_organization',
      'watch_radio',
    )
    radioDataStore.playRadioAudio(playerRadioData)
  }

  /**
   * 無線交信データの音声の再生を停止する。
   */
  const stopRadioAudio = async () => {
    await radioDataStore.pauseRadioAudio()
  }

  /**
   * 無線交信データを再生する。
   * 他に再生中の無線交信データがあった場合、その再生を停止し、指定された無線交信データを再生する。
   * 指定された無線交信データが再生中の場合、停止して処理を終了する。
   * seekフラグにtrueを指定された場合、動画の再生位置を、指定された無線が発生した場所に変更する。
   *
   * @param playerRadioData 無線交信データ
   */
  const playRadioDataAudio = async (playerRadioData: RadioDataDocument) => {
    if (isPlayingRadio.value) {
      const isSameRadio = playerRadioData.audio_clip === radioDataStore.getSelectedRadioData()?.id
      await stopRadioAudio()
      if (isSameRadio) {
        return
      }
    }
    await playRadioAudio(playerRadioData)
  }

  return {
    playRadioDataAudio,
  }
}
