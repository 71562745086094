














































import { computed, defineComponent } from '@vue/composition-api'
import PasswordWithToggleButton from '@/components/common/form/PasswordWithToggleButtonParts.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

/**
 * マイページ: 会員情報変更 パスワード変更入力フィールドセットパーツ
 */
export default defineComponent({
  name: 'FieldsetPasswordChangeParts',
  components: {
    PasswordWithToggleButton,
    FormErrorMessageParts,
  },
  props: {
    /**
     * パスワード
     * { old: string, new: string, reEnter: string  }
     */
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    /**
     * 現在のパスワード エラーフラグ
     */
    showOldPasswordError: {
      type: Boolean,
      default: false,
    },
    /**
     * 新しいパスワード エラー表示フラグ
     */
    showNewPasswordError: {
      type: Boolean,
      default: false,
    },
    /**
     * 新しいパスワード(確認用) エラー表示フラグ
     */
    showNewPasswordReEnterError: {
      type: Boolean,
      default: false,
    },
    /**
     * 現在のパスワード エラーメッセージ
     */
    oldPasswordErrorMessage: {
      type: String,
      default: '',
    },
    /**
     * 新しいパスワード エラーメッセージ
     */
    newPasswordErrorMessage: {
      type: String,
      default: '',
    },
    /**
     * 新しいパスワード(確認用) エラーメッセージ
     */
    newPasswordReEnterErrorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * password
     * @param {string} val パスワード
     */
    const inputPassword = computed({
      get: () => props.value, // eslint-disable-next-line
      set: (val: any) => context.emit('update:value', val),
    })

    return {
      inputPassword,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter(): void {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
    /**
     * Enter Push
     */
    changePassword(): void {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
