var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-form-page" },
    [
      _c("header-pane", {
        attrs: {
          pageTitle: _vm.$tc("SignupPage.pageTitle"),
          colorType: "light",
        },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }