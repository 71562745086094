var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-today-list-section" }, [
    _c(
      "ul",
      { staticClass: "mypage-ticket-today-list-section__list" },
      _vm._l(_vm.purchasedTicketTypes, function (ticketType, index) {
        return _c(
          "li",
          { key: index, staticClass: "mypage-ticket-today-list-section__item" },
          [
            ticketType.isAdmission
              ? _c("mypage-ticket-today-entry-card-section", {
                  attrs: {
                    activeCardId: _vm.showingDetailTodayTicketTypeId,
                    ticketEvent: _vm.ticketEvent,
                    ticketType: ticketType,
                    purchasedTicketInfo: _vm.purchasedTicketInfo,
                  },
                  on: {
                    onClickToggle: _vm.handleCardToggleClicked,
                    onAdmissionTicketClicked: _vm.onAdmissionTicketClicked,
                  },
                })
              : _c(
                  "mypage-ticket-today-card-section",
                  {
                    attrs: {
                      activeCardId: _vm.showingDetailTodayTicketTypeId,
                      ticketType: ticketType,
                    },
                    on: { onClickToggle: _vm.handleCardToggleClicked },
                  },
                  _vm._l(
                    _vm.getPurchasedTickets(ticketType),
                    function (ticket, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass:
                            "mypage-ticket-today-list-section__item-body",
                        },
                        [
                          _vm.getPurchasedTickets(ticketType).length > 1
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "mypage-ticket-today-list-section__item-ticket-name",
                                },
                                [_vm._v(" " + _vm._s(ticket.ticketName) + " ")]
                              )
                            : _vm._e(),
                          ticket.meetingTime
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "mypage-ticket-today-list-section__item-time",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getMeetingTime(ticket.meetingTime)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mypage-ticket-today-list-section__item-count",
                            },
                            [
                              _vm._v(
                                " ×" +
                                  _vm._s(
                                    _vm.getTicketTotalCountByTicket(
                                      ticket,
                                      _vm.ownOrganization
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }