import { computed } from '@vue/composition-api'
import _ from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import Const, { RaceYearType } from '@/util/Const'

/**
 * チームデータを操作するための処理を取得する。
 */
export default function useTeam() {
  // Collection modules
  const teamCollectionModule = CollectionModule.createStore(TeamDocument)

  /**
   * チーム情報一覧を取得する。
   * このメソッドは、アプリが対応している年度のうち、最新の年度のチームマスタを取得する。
   * Const.AVAILABLE_RACE_YEARS にアプリが対応可能な年度が定義されているため、
   * その年度の最新年度のチームマスタを取得する。
   */
  const fetchTeams = () => {
    const availableYear = _.last(Const.AVAILABLE_RACE_YEARS) as RaceYearType
    // 大会マスタの開始・終了日時は日本時間を基準に設定しているため、タイムゾーンは+0900にしている
    const startDate = new Date(`${availableYear}-01-01T00:00:00+0900`).getTime()
    const endDate = new Date(`${availableYear}-12-31T23:59:59+0900`).getTime()

    return teamCollectionModule.fetch({
      query: {
        filter: { startDate: { $gte: startDate }, endDate: { $lte: endDate } },
        sort: 'startDate',
      },
    })
  }

  /**
   * 対象年度のチーム情報一覧を取得する。
   * @return チーム情報取得のレスポンス
   */
  const fetchTargetYearTeams = (year: number) => {
    // 対象年度のチームマスタを取得する.
    // 大会マスタの開始・終了日時は日本時間を基準に設定しているため、タイムゾーンは+0900にしている
    const startDate = new Date(`${year}-01-01T00:00:00+0900`).getTime()
    const endDate = new Date(`${year}-12-31T23:59:59+0900`).getTime()

    return teamCollectionModule.fetch({
      query: {
        filter: { startDate: { $gte: startDate }, endDate: { $lte: endDate } },
        sort: '-startDate',
      },
    })
  }

  /**
   * 取得したチーム情報一覧。
   * Const.SUPER_FORMULA_DRIVERS_TEAM_NAME に定義されている名前のチームは、
   * スーパーフォーミュラの全ての選手の情報を登録するために用意されたチームのため、フィルタする
   */
  const teams = computed(() =>
    teamCollectionModule.data.filter(
      (team) => team.teamName.en !== Const.SUPER_FORMULA_DRIVERS_TEAM_NAME,
    ),
  )

  /**
   * 指定されたteamIdのチームマスタを取得する。
   *
   * @param teamId チームID
   */
  const findTeamById = (teamId: string) => teams.value.find((team) => team.teamId === teamId)

  // methods
  /**
   * 取得したチーム情報をクリアする
   */
  const clearTeams = () => {
    teamCollectionModule.clearData()
  }

  return {
    fetchTeams,
    fetchTargetYearTeams,
    teams,
    findTeamById,
    clearTeams,
  }
}
