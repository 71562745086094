import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import { ErrorCodeType } from '@/util/APIResponse'

/**
 * ワンタイムパス登録画面のエラー処理機能を提供する。
 */
export default function useOneTimePassErrorHandling() {
  /**
   * 契約情報契約APIエラー時の制御（ワンタイムパス用の処理）
   * @return boolean エラーページに遷移するかどうか
   * @return {isMoveToErrorPage: boolean}
   */
  const createContractError = async (errorCode: ErrorCodeType | undefined) => {
    switch (errorCode?.error_code) {
      case '400_0143':
        // ワンタイムパス有効期間外エラーの場合
        // 有効期間切れの場合はそもそもワンタイムパスを取得できないようになっているが、有効期間ギリギリにワンタイムパスを取得し、有効期間切れのタイミングでプラン登録を実行した場合、こちらのエラーが返ってくる想定。
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageOneTimePass.ConfirmPage.errors.oneTimePassPeriodError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.ConfirmPage.errors.oneTimePassPeriodError.message',
          ),
        })
        break
      case '400_0144':
        // ワンタイムパス利用可能回数エラー
        // 残り利用可能回数0の場合はそもそもワンタイムパスを取得できないようになっているが、残り利用可能回数1のワンタイムパスを2人のユーザーがほぼ同時に使ってプラン登録を試みた場合、片方のユーザーはこちらのエラーが返ってくる想定。
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageOneTimePass.ConfirmPage.errors.oneTimePassLimitError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.ConfirmPage.errors.oneTimePassLimitError.message',
          ),
        })
        break
      default:
        // 上記以外のエラー場合
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageOneTimePass.ConfirmPage.errors.createContractError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.ConfirmPage.errors.createContractError.message',
          ),
        })
        break
    }
  }

  return {
    createContractError,
  }
}
