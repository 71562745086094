import { render, staticRenderFns } from "./MypageUserEditHeaderSection.vue?vue&type=template&id=9faa5efe&scoped=true"
import script from "./MypageUserEditHeaderSection.vue?vue&type=script&lang=ts"
export * from "./MypageUserEditHeaderSection.vue?vue&type=script&lang=ts"
import style0 from "./MypageUserEditHeaderSection.vue?vue&type=style&index=0&id=9faa5efe&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9faa5efe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9faa5efe')) {
      api.createRecord('9faa5efe', component.options)
    } else {
      api.reload('9faa5efe', component.options)
    }
    module.hot.accept("./MypageUserEditHeaderSection.vue?vue&type=template&id=9faa5efe&scoped=true", function () {
      api.rerender('9faa5efe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MypageUserEditPage/MypageUserEditPane/MypageUserEditHeaderSection.vue"
export default component.exports