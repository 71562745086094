import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

type ErrorCode =
  | 'misuse_referral_code_400_01'
  | 'misuse_referral_code_500_01'
  | 'misuse_referral_code_500_02'

/**
 * 紹介コードの不正利用かどうかを判定するためのクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3992158246/02.
 */
export default class MisuseReferralCodeDocument extends DocumentWrapper {
  constructor(initProps?: Partial<MisuseReferralCodeDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'misuseReferralCode'

  _isSfgoApi = true

  /**
   * 紹介コードを利用できるかどうか。trueなら利用可能
   */
  isUsable: boolean | null = null

  /**
   * エラーメッセージ
   */
  description: string | null = null

  /**
   * エラーコード
   */
  errorCode: ErrorCode | null = null
}
