import LocalCacheResponseType from '@/util/localcache/LocalCacheResponseType'

/**
 * ローカルキャッシュAPIの処理結果ステータスの型
 */
export type LocalCacheStatusType = 'success' | 'error' | 'not initialized'

/**
 * ローカルキャッシュAPIの処理結果を表現する。
 */
class LocalCacheResult {
  /**
   * ローカルキャッシュの処理結果ステータス
   */
  status: LocalCacheStatusType

  /**
   * ローカルキャッシュの処理結果メッセージ
   */
  message?: string

  /**
   * ローカルキャッシュが返却した処理結果。
   */
  localCacheResponse?: LocalCacheResponseType

  /**
   * ローカルキャッシュAPIの処理結果を初期化する。
   * @param status ローカルキャッシュの処理結果ステータス
   * @param message 処理結果メッセージ
   * @param localCacheResponse ローカルキャッシュが返却した処理結果
   */
  constructor(
    status: LocalCacheStatusType = 'success',
    message?: string,
    localCacheResponse?: LocalCacheResponseType,
  ) {
    this.status = status
    this.message = localCacheResponse?.message
    this.localCacheResponse = localCacheResponse
  }

  /**
   * ローカルキャッシュの処理結果の文字列表現を生成する。
   * @return ローカルキャッシュの処理結果の文字列表現
   */
  toString() {
    return `LocalCacheResult[status: ${this.status}}, message: ${
      this.message
    }, playerEvent: ${JSON.stringify(this.localCacheResponse)}]`
  }
}

export default LocalCacheResult
