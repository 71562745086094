import Logger from '@/util/logger/Logger'

type ContextType = '2d'

type ColorType = {
  r: number
  g: number
  b: number
  a: number
}

type BaseImageType = {
  image: HTMLImageElement
  overlay?: ColorType
}

type FontWeightType =
  | 'normal'
  | 'bold'
  | 'bolder'
  | 'lighter'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'

type TextAlignType = 'start' | 'end' | 'left' | 'right' | 'center'

type FontType = {
  weight: FontWeightType
  size: number
  family: string
}

/**
 * canvasを操作するクラス
 */
export default class CanvasManager {
  canvas: HTMLCanvasElement

  ctx: CanvasRenderingContext2D | null | undefined

  // 1080を基準にした比率
  aspect: number

  constructor(context: ContextType, baseImage?: BaseImageType) {
    const offscreenCanvas = document.createElement('canvas')
    this.canvas = offscreenCanvas as HTMLCanvasElement
    let canvasSize = 1080
    let aspect = 1
    if (!baseImage) {
      // 画像がない場合
      canvasSize = 1080
    } else if (baseImage.image.width > baseImage.image.height) {
      // 画像の幅が高さより大きい場合
      aspect = baseImage.image.width / canvasSize
      canvasSize = baseImage.image.width
    } else {
      // 画像の高さが幅より大きい場合
      aspect = baseImage.image.height / canvasSize
      canvasSize = baseImage.image.height
    }
    this.canvas.width = canvasSize
    this.canvas.height = canvasSize
    this.aspect = aspect
    try {
      this.ctx = this.canvas.getContext(context)
      if (baseImage) {
        const imgAspectRatio = baseImage.image.width / baseImage.image.height
        const canvasAspectRatio = this.canvas.width / this.canvas.height

        let drawWidth = 0
        let drawHeight = 0
        let drawX = 0
        let drawY = 0

        // just fitで表示される部分だけを表示するようにするために必要な値を計算
        if (imgAspectRatio > canvasAspectRatio) {
          drawHeight = this.canvas.height
          drawWidth = this.canvas.height * imgAspectRatio
          drawX = (this.canvas.width - drawWidth) / 2
        } else {
          drawWidth = this.canvas.width
          drawHeight = this.canvas.width / imgAspectRatio
          drawY = (this.canvas.height - drawHeight) / 2
        }
        if (!this.ctx) {
          Logger.info('CanvasManager#constructor: error not create canvas')
          return
        }
        // 画像を描画
        this.ctx.drawImage(baseImage.image, drawX, drawY, drawWidth, drawHeight)
        if (baseImage.overlay) {
          // オーバーレイがある場合はオーバーレイを描画
          this.ctx.fillStyle = `rgba(${baseImage.overlay.r}, ${baseImage.overlay.g}, ${baseImage.overlay.b}, ${baseImage.overlay.a})`
          this.ctx.fillRect(drawX, drawY, drawWidth, drawHeight)
        }
      }
    } catch (error) {
      Logger.info(`CanvasManager#constructor: error create canvas: ${error}`)
    }
  }

  /**
   * 画像を描画する
   * - 1080px x 1080pxを基準にした比率で描画する
   */
  drawImage = (
    image: HTMLImageElement,
    x: number,
    y: number,
    width: number,
    height: number,
    overlay: ColorType | null = null,
  ) => {
    if (!this.ctx) {
      Logger.info('CanvasManager#drawImage: error not create canvas')
      return
    }
    try {
      this.ctx.drawImage(
        image,
        x * this.aspect,
        y * this.aspect,
        width * this.aspect,
        height * this.aspect,
      )
      if (overlay) {
        // オーバーレイがある場合はオーバーレイを描画
        this.ctx.fillStyle = `rgba(${overlay.r}, ${overlay.g}, ${overlay.b}, ${overlay.a})`
        this.ctx.fillRect(x, y, width, height)
      }
    } catch (error) {
      Logger.info(`CanvasManager#drawImage: error draw image for canvas: ${error}`)
    }
  }

  /**
   * テキストを描画する
   * - 1080px x 1080pxを基準にした比率で描画する
   */
  addText = (
    text: string,
    x: number,
    y: number,
    font: FontType = { weight: 'normal', size: 20, family: 'Arial' },
    color: ColorType,
    textAlign: TextAlignType = 'start',
    letterSpacing = 0,
  ) => {
    if (!this.ctx) {
      Logger.info('CanvasManager#addText: error not create canvas')
      return
    }
    try {
      this.ctx.textAlign = textAlign
      this.ctx.font = `${font.weight} ${font.size * this.aspect}px ${font.family}`
      this.ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`

      if (letterSpacing === 0 || textAlign !== 'start') {
        this.ctx.fillText(text, x * this.aspect, y * this.aspect)
      } else {
        const characters = text.split('')
        let currentX = x * this.aspect
        // eslint-disable-next-line no-restricted-syntax
        for (const char of characters) {
          // letterSpacingが設定されていて、かつtextAlignがstartの場合は、文字ごとに表示位置を調整する
          this.ctx.fillText(char, currentX, y * this.aspect)
          currentX += this.ctx.measureText(char).width + letterSpacing
        }
      }
    } catch (error) {
      Logger.info(`CanvasManager#addText: error add text for canvas: ${error}`)
    }
  }
}
