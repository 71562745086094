
























import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'NoticeHighlightSection',
  setup(_, { emit }) {
    const isPc = DeviceInfo.isForPC()

    const handleOkClicked = () => {
      emit('onClickOk')
    }

    return {
      isPc,
      handleOkClicked,
    }
  },
})
