






import { defineComponent } from '@vue/composition-api'

/**
 * ラジオ選択カードリスト セクション
 */
export default defineComponent({
  name: 'ToggleRadioCardListSection',
  components: {},
  props: {},
})
