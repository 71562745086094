var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paid-plan-change-edit-page" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("PaidPlanChange.EditPage.title") },
      }),
      !_vm.isLoading
        ? _c("paid-plan-change-edit-pane", {
            attrs: { selectedPlanGroupId: _vm.selectedPlanGroupId },
            on: {
              handlerPlanSelect: _vm.changePlan,
              handlerSubmit: _vm.moveToPaidPlanChangeConfirm,
              handlerCancel: _vm.handlerCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }