









import { defineComponent } from '@vue/composition-api'
import SignupPaymentConfirmPane from '@/components/SignupPage/SignupPaymentConfirmPane.vue'
import useDialog from '@/components/SignupPage/hook/useDialog'

/**
 * 新規会員登録: プラン選択 決済情報確認ページ
 */
export default defineComponent({
  name: 'SignupPaymentConfirmPage',
  components: {
    SignupPaymentConfirmPane,
  },
  setup() {
    /** ページアンロード時、離脱警告ダイアログを表示 */
    useDialog()
  },
  methods: {
    moveToSignupComplete() {
      /**
       * 登録完了ページに遷移する
       */
      this.$router.replace({ name: 'SignupCompletePage' })
    },
    handlerCancel() {
      /**
       * プラン選択画面へ遷移する
       */
      this.$router.replace({ name: 'PlanSelectPage' })
    },
  },
})
