
















import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * レース一覧画面: 会場・レース情報 ヘッダーUIパーツ
 */
export default defineComponent({
  name: 'RaceListHeaderParts',
  props: {
    round: {
      type: Number,
      default: 1,
    },
    raceDate: {
      type: String,
      default: '',
    },
    raceVenue: {
      type: String,
      default: '',
    },
    showTimetable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const forPc = DeviceInfo.isForPC()
    return {
      forPc,
    }
  },
})
