













import { defineComponent, PropType } from '@vue/composition-api'
import AppRaceVideoPage, { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * レース動画再生+テレメトリーページ
 */
export default defineComponent({
  name: 'RaceVideoPage',
  components: {
    AppRaceVideoPage,
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
      default: 'landscape',
    },
    championshipMasterId: {
      type: String,
      required: true,
    },
    raceId: {
      type: String,
      required: true,
    },
    highlightId: {
      type: String,
      required: false,
    },
    /**
     * 動画再生時に初期設定する再生位置の実時間。UnixTime(ミリ秒)
     */
    movieStartActualTime: {
      type: Number,
      required: false,
    },
    /**
     * 動画再生時に初期設定するドライバーのID
     */
    driverId: {
      type: String,
      required: false,
    },
  },
})
