var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "highlights-external-link-icon",
      attrs: {
        viewBox: "0 0 12 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.64645 5.64645C9.74021 5.55268 9.86739 5.5 10 5.5C10.1326 5.5 10.2598 5.55268 10.3536 5.64645C10.4473 5.74021 10.5 5.86739 10.5 6V9C10.5 9.39782 10.342 9.77936 10.0607 10.0607C9.77936 10.342 9.39782 10.5 9 10.5H3C2.60218 10.5 2.22064 10.342 1.93934 10.0607C1.65804 9.77936 1.5 9.39782 1.5 9V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H6C6.13261 1.5 6.25979 1.55268 6.35355 1.64645C6.44732 1.74021 6.5 1.86739 6.5 2C6.5 2.13261 6.44732 2.25979 6.35355 2.35355C6.25979 2.44732 6.13261 2.5 6 2.5H3C2.86739 2.5 2.74021 2.55268 2.64645 2.64645C2.55268 2.74021 2.5 2.86739 2.5 3V9C2.5 9.13261 2.55268 9.25979 2.64645 9.35355C2.74021 9.44732 2.86739 9.5 3 9.5H9C9.13261 9.5 9.25979 9.44732 9.35355 9.35355C9.44732 9.25979 9.5 9.13261 9.5 9V6C9.5 5.86739 9.55268 5.74021 9.64645 5.64645Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.79 2.5H8C7.86739 2.5 7.74021 2.44732 7.64645 2.35355C7.55268 2.25979 7.5 2.13261 7.5 2C7.5 1.86739 7.55268 1.74021 7.64645 1.64645C7.74021 1.55268 7.86739 1.5 8 1.5H10C10.1326 1.5 10.2598 1.55268 10.3536 1.64645C10.4473 1.74021 10.5 1.86739 10.5 2V4C10.5 4.13261 10.4473 4.25979 10.3536 4.35355C10.2598 4.44732 10.1326 4.5 10 4.5C9.86739 4.5 9.74021 4.44732 9.64645 4.35355C9.55268 4.25979 9.5 4.13261 9.5 4V3.21L6.355 6.35C6.30852 6.39686 6.25322 6.43406 6.19229 6.45945C6.13136 6.48483 6.06601 6.4979 6 6.4979C5.93399 6.4979 5.86864 6.48483 5.80771 6.45945C5.74678 6.43406 5.69148 6.39686 5.645 6.35C5.59814 6.30352 5.56094 6.24822 5.53555 6.18729C5.51017 6.12636 5.4971 6.06101 5.4971 5.995C5.4971 5.92899 5.51017 5.86364 5.53555 5.80271C5.56094 5.74178 5.59814 5.68648 5.645 5.64L8.79 2.5Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }