












import { defineComponent } from '@vue/composition-api'
import RaceLinkListSection from '@/components/RaceVideoPage/RaceVideoDataViewPane//RaceLinkListSection.vue'

/**
 * レース動画再生画面 レース選択セクションのコンポーネント
 */
export default defineComponent({
  name: 'RaceSelectSection',
  components: {
    RaceLinkListSection,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
  methods: {
    toggleRaceSelect(): void {
      /**
       * レース選択非表示化
       * @event toggleRaceSelect
       */
      this.$emit('raceSelectToggle', 'off')
    },
  },
})
