var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-name-parts" },
    [
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.name"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("ValidationProvider", {
        attrs: {
          slim: "",
          name: "name",
          rules: "required|max:32",
          vid: "familyName",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("atom-input-text", {
                  staticClass: "fieldset-name-parts__input",
                  attrs: {
                    placeholder: _vm.$tc("formParts.placeholder.nameFamily"),
                    error: errors.length > 0,
                  },
                  on: { enter: _vm.emitEnter },
                  model: {
                    value: _vm.familyName,
                    callback: function ($$v) {
                      _vm.familyName = $$v
                    },
                    expression: "familyName",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("ValidationProvider", {
        attrs: {
          slim: "",
          name: "name",
          rules: "required|max:32",
          vid: "firstName",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("atom-input-text", {
                  staticClass: "fieldset-name-parts__input",
                  attrs: {
                    placeholder: _vm.$tc("formParts.placeholder.nameFirst"),
                    error: errors.length > 0,
                  },
                  on: { enter: _vm.emitEnter },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showError,
          message:
            _vm.errorMessage !== ""
              ? _vm.errorMessage
              : _vm.$t("formParts.errorMessage.name"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }