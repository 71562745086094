import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import FeedMediaDataDocument from '@/store/stores/collectionModule/documents/feed/FeedMediaDataDocument'

/**
 * フィードデータソースの型
 */
export type FeedSourceType = 'Twitter' | 'Instagram' | 'SF_Official_Site' | null

/**
 * フィード種別の型
 */
export type FeedType = 'Driver' | 'Team' | 'SF_Official' | null

/**
 * SNS/SF公式サイト記事データを表現するクラス。
 * https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3395453098/SNS+SF
 */
export default class FeedDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<FeedDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    const feedMediaData = [] as Array<FeedMediaDataDocument>
    initProps?.medias?.forEach((media) => {
      feedMediaData.push(new FeedMediaDataDocument(media))
    })
    this.medias = feedMediaData
  }

  /**
   * AWS SDKでDynamoDBに直接アクセスしてデータを取得するため、pathは未定義.
   */
  _path = ''

  idAttr = 'relatedDataId'

  /**
   * フィードデータソース
   */
  feedSource: FeedSourceType = null

  /**
   * ユーザーID
   */
  userId = ''

  /**
   * ユーザー名
   */
  userName = ''

  /**
   * フィードID
   */
  feedId = ''

  /**
   * フィード種別
   */
  feedType: FeedType = null

  /**
   * 関連データID
   */
  relatedDataId = ''

  /**
   * フィードタイトル
   */
  title = ''

  /**
   * フィード説明
   */
  description = ''

  /**
   * フィードコンテンツ
   */
  content = ''

  /**
   * フィードURL
   */
  feedUrl = ''

  /**
   * 記事に関連する画像/動画の情報
   */
  medias: Array<FeedMediaDataDocument> = []

  /**
   * お気に入り数
   */
  favoriteCount = 0

  /**
   * リツイート数
   */
  retweetCount = 0

  /**
   * 作成日時
   */
  createdDate = 0

  /**
   * 取得日時
   */
  receivedDate = 0

  /**
   * Twitter APIで取得した場合、記事の末尾に その記事のURLが表記されるため、取り除く。
   */
  get normalizedContents() {
    if (!this.content) {
      return ''
    }
    const linkIndex = this.content.lastIndexOf('https://')
    if (linkIndex !== -1) {
      return this.content.substring(0, linkIndex)
    }
    return this.content
  }

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `FeedDataDocument[relatedDataId: ${this.relatedDataId}, feedUrl: ${this.feedUrl}}, feedSource: ${this.feedSource}, feedType: ${this.feedType}]`
  }
}
