var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "circuit-detail-map-image",
      class: "circuit-detail-map-image--mode-" + _vm.gpsViewMode,
    },
    [
      _vm.circuitName === "AUTOPOLIS"
        ? _c("circuit-map-image-auto-police", {
            staticClass: "circuit-detail-map-image__svg",
          })
        : _vm.circuitName === "FUJI_SPEEDWAY"
        ? _c("circuit-map-image-fuji", {
            staticClass: "circuit-detail-map-image__svg",
          })
        : _vm.circuitName === "TWIN_RING_MOTEGI"
        ? _c("circuit-map-image-motegi", {
            staticClass: "circuit-detail-map-image__svg",
          })
        : _vm.circuitName === "SPORTSLAND_SUGO"
        ? _c("circuit-map-image-sugo", {
            staticClass: "circuit-detail-map-image__svg",
          })
        : _vm.circuitName === "SUZUKA_CIRCUIT"
        ? _c("circuit-map-image-suzuka", {
            staticClass: "circuit-detail-map-image__svg",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }