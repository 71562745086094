import { ref, Ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ContractInfoPlanPriceDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanPriceDocument'
import ContractInfoPlanDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanDocument'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * 月額プラン日割り料金に関するHook
 */
export default function useMonthlyProRatedPrice() {
  /**
   * 月額プランの日割り計算分の料金を取得するためのリクエストパラメータを返す
   * 請求情報を取得できない翌々月の決済料金を把握したい時などに利用する
   */
  const getMonthlyPlanNextProRatedPriceParam = (
    startDate: number | null | undefined,
    endDate: number | null | undefined,
    contractPlanId: string | null | undefined,
  ) => {
    if (!(startDate && endDate)) {
      // startDate、endDateが存在しないと料金計算できないため、startDateまたはendDateが存在しない場合はエラーにする
      return null
    }

    /**
     * 翌月の月額プラン請求対象期間開始日時UTC UnixTime(ミリ秒)
     * UTC基準でセットする
     */
    const nextBillingStartDate: Ref<number | null> = ref(null)

    const now = dayjs().valueOf()
    // 翌月の請求対象期間開始日時UTC UnixTime(ミリ秒)
    const nextStartDateUtcNum = dayjs.tz(now).add(1, 'M').startOf('month').valueOf()

    if (dayjs.tz(nextStartDateUtcNum).format('MM') === dayjs(now).format('MM')) {
      // UTCより時差が進んでいる国で、月初に手続きを行った場合
      // ex. 日本で2023/10/01 1:00(JST)に手続き: nextStartDateUtcNumが2023/10/01 0:00となってしまうため、請求対象期間開始日を1ヶ月加算する
      // このケースは月額プランの契約終了日時が2023/10/1 23:59の場合で上記の日時に手続きを行った場合にあり得る
      nextBillingStartDate.value = dayjs
        .tz(nextStartDateUtcNum)
        .add(1, 'M')
        .startOf('month')
        .valueOf()
    } else if (dayjs.tz(nextStartDateUtcNum).format('MM') === dayjs(now).add(2, 'M').format('MM')) {
      // UTCより時差が遅れている国で、月末に手続きを行った場合
      // ex. ハワイで2023/9/30 22:00(HST)に手続き: nextStartDateUtcNumが2023/11/1 0:00となってしまうため、請求対象期間終了日を1ヶ月減算する
      nextBillingStartDate.value = dayjs
        .tz(nextStartDateUtcNum)
        .subtract(1, 'M')
        .startOf('month')
        .valueOf()
    } else {
      // ほとんどの場合、こちらのケースが該当する
      nextBillingStartDate.value = nextStartDateUtcNum
    }

    return new ContractInfoPlanPriceDocument({
      /**
       * 翌月の請求対象の期間（=翌月の利用期間）をセットする
       */
      startDate: nextBillingStartDate.value,
      endDate,
      newPlans: [
        {
          contractPlanId,
        },
      ],
    })
  }

  /**
   * 将来の月額プランの初月日割り計算分の料金を取得するためのリクエストパラメータを返す
   */
  const getMonthlyPlanFirstProRatedPriceParam = (
    futureContactInfoPlan: ContractInfoPlanDocument | undefined,
  ) => {
    if (!futureContactInfoPlan?.startDate) {
      // 将来切り替わる予定の月額プランの開始日時が存在しない場合はエラーにする
      return null
    }

    // 月額プラン初月の請求対象期間終了日時UTC UnixTime(ミリ秒)
    const firstBillingEndDate = dayjs.tz(futureContactInfoPlan.startDate).endOf('month').valueOf()

    const requestData = new ContractInfoPlanPriceDocument({
      /**
       * 月額プラン初月の請求対象の期間をセットする
       */
      startDate: futureContactInfoPlan.startDate,
      endDate: firstBillingEndDate,
      newPlans: [
        {
          contractPlanId: futureContactInfoPlan.contractPlanId,
        },
      ],
    })

    return {
      requestData,
      firstBillingEndDate,
    }
  }

  /**
   * 将来の月額プランの初月日割り計算分の料金を取得するためのリクエストパラメータを返す
   * getMonthlyPlanFirstProRatedPriceParamで日割り料金を計算して0円だった場合に次の月の日割り料金を計算する
   */
  const getAgainMonthlyPlanFirstProRatedPriceParam = (
    contractPlanId: string,
    firstBillingEndDate: number | undefined,
  ) => {
    if (!firstBillingEndDate) {
      return null
    }

    return new ContractInfoPlanPriceDocument({
      /**
       * 月額プラン初月の請求対象の期間をセットする
       */
      startDate: firstBillingEndDate + 1,
      endDate: dayjs
        .tz(firstBillingEndDate + 1)
        .endOf('month')
        .valueOf(),
      newPlans: [
        {
          contractPlanId,
        },
      ],
    })
  }

  return {
    getMonthlyPlanNextProRatedPriceParam,
    getMonthlyPlanFirstProRatedPriceParam,
    getAgainMonthlyPlanFirstProRatedPriceParam,
  }
}
