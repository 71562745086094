var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-hexagon-outer",
      attrs: {
        viewBox: "0 0 66 74",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M37.9614 3.2636C35.539 1.87939 34.3278 1.18728 33 1.18728C31.6722 1.18728 30.461 1.87939 28.0386 3.26361L6.03861 15.835C3.58013 17.2399 2.3509 17.9423 1.67545 19.1062C1 20.2701 1 21.6859 1 24.5175V49.4824C1 52.314 1 53.7298 1.67545 54.8937C2.3509 56.0576 3.58014 56.76 6.03861 58.1649L28.0386 70.7363C30.461 72.1205 31.6722 72.8126 33 72.8126C34.3278 72.8126 35.539 72.1205 37.9614 70.7363L59.9614 58.1649C62.4199 56.76 63.6491 56.0576 64.3245 54.8937C65 53.7298 65 52.314 65 49.4824V24.5175C65 21.6859 65 20.2701 64.3245 19.1062C63.6491 17.9423 62.4199 17.2399 59.9614 15.835L37.9614 3.2636Z",
          stroke: "currentColor",
          "stroke-miterlimit": "10",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }