import dayjs from 'dayjs'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * ユーザーのポイント履歴を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3625943157/API
 */
export default class UserPointDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserPointDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/user_point'

  idAttr = 'userPointId'

  /**
   * ユーザポイントID
   */
  userPointId: string | null = null

  /**
   * ポイント種別
   */
  pointType?: string | null = null

  /**
   * ポイントコードID
   */
  pointCodeId?: string | null = null

  /**
   * ユーザID
   */
  userId: string | null = null

  /**
   * ポイント
   */
  point: number | null = null

  /**
   * ポイントの付与日時
   */
  grantedDate?: number | null = null

  /**
   * ポイントの失効日時
   */
  pointExpirationDate?: number | null = null

  /**
   * 現在保有しているポイント
   */
  get currentOwnedPoint() {
    return this.point ?? 0
  }

  /**
   * 月額プラン登録/継続ミッションの場合pointTypeに記載されている日付を返す
   */
  get monthlyPaidMemberEarnedPointDate() {
    if (this.pointType === 'MONTHLY_PAID_MEMBER' && this.pointCodeId) {
      const earnedDate = this.pointCodeId.replace('MONTHLY_PAID_MEMBER_', '')
      // pointTypeに記載されている年月の1日(JST)を返す
      return dayjs.tz(`${earnedDate}-01`, 'Asia/Tokyo').startOf('day').valueOf()
    }

    return null
  }
}
