

























import { defineComponent } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * 新規会員登録:メール送信完了画面セクションのコンポーネント
 */
export default defineComponent({
  name: 'ConfirmationSentSection',
  components: {
    ActionButtonsSection,
    AtomInputButton,
  },
  setup() {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')

    return {
      memberType: signupPageStore.memberType,
    }
  },
  methods: {
    moveToEmailAuthentication() {
      /**
       * 認証メール送信画面へ遷移
       */
      this.$router.replace({ path: `/signup/${this.memberType}` })
    },
  },
})
