




import { defineComponent } from '@vue/composition-api'
import AppMypageOneTimePassConfirmPage from '@/views/MypagePage/MypageOneTimePassConfirmPage.vue'

/**
 * ブラウザ版: マイページ: ワンタイムパス: 内容確認ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassConfirmPage',
  components: {
    AppMypageOneTimePassConfirmPage,
  },
})
