var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-information-section",
      class: { "race-information-section--is-open": _vm.showInformation },
    },
    [
      _c("div", { staticClass: "race-information-section__content" }, [
        _vm.racePointRadioData.length > 0
          ? _c(
              "div",
              { staticClass: "race-information-section__points-list" },
              _vm._l(_vm.racePointRadioData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.raceRadioData.audio_clip + " + " + index,
                    staticClass: "race-information-section__points-item",
                  },
                  [
                    _c("race-point-radio-card-parts", {
                      attrs: { pointsCardData: item },
                      on: { radioPlay: _vm.playRadioAudio },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.racePointVideoData.length > 0
          ? _c(
              "div",
              { staticClass: "race-information-section__points-list" },
              _vm._l(_vm.racePointVideoData, function (racePointVideo, index) {
                return _c(
                  "div",
                  {
                    key: "racePointsVideoCard" + index,
                    staticClass: "race-information-section__points-item",
                  },
                  [
                    _c("race-point-video-card-parts", {
                      attrs: { racePointVideoData: racePointVideo },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.rankingsData.length > 0
          ? _c("div", { staticClass: "race-information-section__ranking" }, [
              _c(
                "h3",
                { staticClass: "race-information-section__ranking-title" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$tc("RaceListPage.Information.RaceRank")) +
                      " "
                  ),
                ]
              ),
              _c(
                "p",
                { staticClass: "race-information-section__ranking-attention" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc("RaceListPage.Information.RaceRankAttention")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "race-information-section__ranking-list" },
                _vm._l(_vm.rankingsData, function (ranking, index) {
                  return _c("ranking-card-parts", {
                    key: "RankingCard" + index,
                    attrs: { rankingCardData: ranking },
                  })
                }),
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }