












































import { computed, defineComponent } from '@vue/composition-api'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

/**
 * フィールドセット 名前入力 バーツ
 * todo: 正常なsetup / emit処理 /
 */
export default defineComponent({
  name: 'FieldsetNameParts',
  components: {
    AtomInputText,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    valueFamily: {
      type: String,
      default: '',
    },
    valueFirst: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * familyName
     * @param {string} val 姓(漢字)
     */
    const familyName = computed({
      get: () => props.valueFamily,
      set: (val: string) => context.emit('inputfamilyName', val),
    })
    /**
     * firstName
     * @param {string} val 名(漢字)
     */
    const firstName = computed({
      get: () => props.valueFirst,
      set: (val: string) => context.emit('inputfirstName', val),
    })
    return {
      familyName,
      firstName,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
