import { uniqBy } from 'lodash'
import dayjs from 'dayjs'
import I18n from '@/locales/I18n'
import CalendarDataDocument from '@/store/stores/collectionModule/documents/calendar/CalendarDataDocument'

/**
 * 年/年月のプルダウンの型
 */
export type CalendarDateSelectType = {
  type: 'year' | 'yearAndMonths'
  value: string
}

/**
 * モータースポーツカレンダー画面のプルダウンに関する機能を提供する。
 */
export default function useCalendarSelectList() {
  /**
   * 年/年月のプルダウンを生成
   */
  const generateYearsAndMonthsList = (calendarLatestYear: number) => {
    const calendarYearList = [] as Array<CalendarDateSelectType>
    const calendarYearAndMonthList = [] as Array<CalendarDateSelectType>
    const firstYear = 2024

    // 年のリストを格納
    for (let i = firstYear; i <= calendarLatestYear; i += 1) {
      calendarYearList.push({ type: 'year', value: String(i) })
    }

    // カレンダーが存在する最新年の月のリストを格納
    for (let i = 0; i < 12; i += 1) {
      const yearAndMonths =
        I18n.locale === 'ja'
          ? `${calendarLatestYear}.${dayjs().month(i).format('M')}`
          : `${dayjs().month(i).format('MMM')} ${calendarLatestYear}`
      calendarYearAndMonthList.push({ type: 'yearAndMonths', value: yearAndMonths })
    }

    return { calendarYearList, calendarYearAndMonthList }
  }

  /**
   * 場所のプルダウンを生成
   */
  const generatePlaceList = (calendarData: CalendarDataDocument[]) => {
    const calendarDataByPlaceName = uniqBy(calendarData, 'placeName')

    return calendarDataByPlaceName.map((calendar, index) => ({
      id: index + 1,
      name: calendar.placeName ?? '',
    }))
  }

  /**
   * カテゴリーのプルダウンを生成
   */
  const generateCategoryList = (calendarData: CalendarDataDocument[]) => {
    const calendarDataByPlaceName = uniqBy(calendarData, 'categoryName')

    return calendarDataByPlaceName.map((calendar, index) => ({
      id: index + 1,
      name: calendar.categoryName ?? '',
    }))
  }

  return {
    generateYearsAndMonthsList,
    generatePlaceList,
    generateCategoryList,
  }
}
