var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-ranking-table-share-parts" }, [
    !_vm.isPc
      ? _c("div", { staticClass: "mission-ranking-table-share-parts__sns" }, [
          _c(
            "button",
            {
              staticClass:
                "mission-ranking-table-share-parts__sns-button-common",
              on: { click: _vm.onClickSnsButton },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$tc("MissionPage.RankingPage.postRankingImageOnlyX")
                  ) +
                  " "
              ),
            ]
          ),
        ])
      : _c(
          "div",
          { staticClass: "mission-ranking-table-share-parts__sns--for-pc" },
          [
            _c(
              "p",
              { staticClass: "mission-ranking-table-share-parts__sns-title" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$tc("MissionPage.RankingPage.snsLabel")) +
                    " "
                ),
              ]
            ),
            _c(
              "ul",
              { staticClass: "mission-ranking-table-share-parts__sns-list" },
              [
                _c(
                  "li",
                  {
                    staticClass: "mission-ranking-table-share-parts__sns-item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "mission-ranking-table-share-parts__sns-button",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickAnchorLink(_vm.xUrl)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mission-ranking-table-share-parts__sns-button-icon",
                          },
                          [_c("x-icon-parts")],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                false
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "mission-ranking-table-share-parts__sns-item",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "mission-ranking-table-share-parts__sns-button",
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickAnchorLink(_vm.instagramUrl)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "mission-ranking-table-share-parts__sns-button-icon",
                              },
                              [_c("instagram-icon-parts")],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
    _c("div", { staticClass: "mission-ranking-table-share-parts__share" }, [
      !_vm.isPc
        ? _c(
            "button",
            {
              staticClass: "mission-ranking-table-share-parts__share-button",
              on: { click: _vm.onClickShareIcon },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "mission-ranking-table-share-parts__share-button-icon",
                },
                [_c("share-icon-parts")],
                1
              ),
            ]
          )
        : _c(
            "button",
            {
              staticClass: "mission-ranking-table-share-parts__show-button",
              on: { click: _vm.onClickSnsButton },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$tc("MissionPage.RankingPage.showShareImage")) +
                  " "
              ),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }