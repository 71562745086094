var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paid-plan-change-edit-pane" },
    [
      _c("current-plan-card-section", {
        attrs: {
          sectionTitle: _vm.$tc("MypagePage.plan.selectPlan"),
          plan: _vm.plan,
          contractTerm: _vm.scheduledContractTerm,
          isConfirmed: true,
        },
      }),
      _vm.attentionMessage
        ? _c("attention-message-section", {
            staticClass: "paid-plan-change-edit-pane__attention-message",
            attrs: { attentionMessage: _vm.attentionMessage },
          })
        : _vm._e(),
      _vm.paymentCycleImgPath.pc && _vm.paymentCycleImgPath.sp
        ? _c("payment-cycle-section", {
            attrs: {
              paymentCycleImgPath: _vm.paymentCycleImgPath,
              attention: _vm.textDisplayBlowImage,
            },
          })
        : _vm._e(),
      _c(
        "action-buttons-section",
        {
          staticClass: "paid-plan-change-edit-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".paid-plan-change-edit-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.registration"),
              isDisabled: false,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }