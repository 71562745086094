






























































import { defineComponent, ref, onMounted, onUnmounted } from '@vue/composition-api'
import dayjs from 'dayjs'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * マイページ: 会員証 現在時刻表示パーツ
 */
export default defineComponent({
  name: 'CurrentTimeParts',
  setup() {
    const { getDisplayDate } = useDisplayDependingOnLang()

    /**
     * 現在時刻を取得
     * dayjs使用 YYYY/MM/DD hh:mm:ss 形式
     * @returns 現在時刻
     */
    const getCurrentTime = () =>
      getDisplayDate(dayjs().valueOf(), 'YYYY/MM/DD HH:mm:ss', 'MMM DD, YYYY HH:mm:ss')
    const currentTime = ref<string>(getCurrentTime())

    let currentTimeTimer: number

    onMounted(() => {
      currentTime.value = getCurrentTime()

      currentTimeTimer = window.setInterval(() => {
        currentTime.value = getCurrentTime()
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(currentTimeTimer)
    })

    return {
      currentTime,
    }
  },
})
