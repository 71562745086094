/**
 * ファイルパスから拡張子を取り出すユーティリティ。
 */
export default class FileExtensionUtil {
  /**
   * @param filePath
   */
  static getFileExtension(filePath: string) {
    const lastDotIndex = filePath.lastIndexOf('.')
    if (lastDotIndex === -1) return ''
    return filePath.substring(lastDotIndex + 1)
  }
}
