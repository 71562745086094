import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import CurrentTimeDocument from '@/store/stores/collectionModule/documents/currentTime/CurrentTimeDocument'
import AppConfigStore from '@/store/stores/pageStore/common/AppConfigStore'
import Logger from '@/util/logger/Logger'

/**
 * 時刻同期データを操作するための処理
 */
export default function useCurrentTime() {
  // Collection modules
  const currentTimeCollectionModule = CollectionModule.createStore(CurrentTimeDocument)

  /**
   * 端末の時刻とサーバーの時刻のずれを取得
   */
  const getTimeDiff = async () => {
    const clientSubmitTime = dayjs().valueOf()
    const requestData = new CurrentTimeDocument({
      t1: clientSubmitTime,
    })
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/time/current`,
    }
    const currentTimeResult = await currentTimeCollectionModule.save(requestData, options)
    const clientReceiveTime = dayjs().valueOf()

    let timeDiff = 0
    if (currentTimeResult.isSuccess) {
      const t1 = currentTimeResult.data?.t1 ?? 0
      const t2 = currentTimeResult.data?.t2 ?? 0
      const t3 = currentTimeResult.data?.t3 ?? 0

      timeDiff = (t3 + t2 - (t1 + clientReceiveTime)) / 2
    }

    // デバイスの時刻のずれをAppConfigStoreに保存
    AppConfigStore.value.setTimeDeviation(timeDiff)
    Logger.info(`Success to init time deviation: ${AppConfigStore.value.getTimeDeviation()}`)

    return timeDiff
  }

  return {
    getTimeDiff,
  }
}
