var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "limited-video-alert-modal-section",
    attrs: { "has-close-button": false },
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("youtube", {
              ref: "youtubeRef",
              attrs: { "video-id": _vm.videoId, "player-vars": _vm.playerVars },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$tc("common.close"),
                colorType: "tertiary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }