import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * パスワードリセットトークン情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1569259643/API
 */
export default class PasswordResetDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PasswordResetDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = '/manage/password_reset_token'

  idAttr = '_id'

  /**
   * パスワード
   */
  password: string | null = null
}
