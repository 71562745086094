




































import {
  defineComponent,
  ref,
  inject,
  onUnmounted,
  onBeforeMount,
  computed,
} from '@vue/composition-api'
import { now } from 'lodash'
import VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import NotificationListSection from '@/components/NotificationPane/NotificationPane/NotificationListSection.vue'
import PaginationSection from '@/components/common/Pagination/PaginationSection.vue'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import useRaces from '@/store/hook/useRaces'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import FlowLineToPaidPlanModalSection from '@/components/common/modal/FlowLineToPaidPlanModalSection.vue'
import useNotification from '@/store/hook/useNotification'
import usePermission from '@/components/hook/usePermission'
import NotificationDocument, {
  NotificationTransitionType,
  NotificationTypeUnion,
} from '@/store/stores/collectionModule/documents/notification/NotificationDocument'
import StoreUtil from '@/store/StoreUtil'
import Const from '@/util/Const'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
/**
 * 通知: ハイライト用のデータの選手情報の型
 * TODO: デザイン実装のみなので、後にロジック実装する。不要になるかも？
 */
type playerType = {
  playerName: Multilingual
  playerPictureImagePath?: string
}

/**
 * 通知: ハイライト用のデータの型
 * TODO: デザイン実装のみなので、後にロジック実装する。不要になるかも？
 */
export type HighlightSceneType = {
  player: playerType
  raceTime: string
  thumbnailUri?: string
  comment?: string
}

/**
 * 表示用 通知一覧データの型
 */
export type NotificationItemType = {
  informationId: string
  type: NotificationTypeUnion | null
  date: number | null
  link: string
  title: Multilingual
  message: Multilingual
  note?: string
  url?: string
  extra?: HighlightSceneType
  thumbnail?: string
  mediaType?: 'image' | 'video'
  transitionType?: NotificationTransitionType
  transitionPath?: string | null
  sessionYear?: number | null
  sessionChampionshipMasterId?: string | null
}

/**
 * Notification ペイン
 */
export default defineComponent({
  name: 'NotificationPane',
  components: {
    NotificationListSection,
    PaginationSection,
    FlowLineToPaidPlanModalSection,
  },
  setup() {
    // 1ページあたりに表示するお知らせの数
    const NOTIFICATION_COUNT_PER_PAGE = 50

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi
    // hook
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
    const { fetchNotifications, notificationList, clearNotifications } = useNotification()
    const { canShowRace, getTargetRacePermission } = usePermission()
    const { fetchRace, targetRace, clearRaces } = useRaces()

    // store
    const loginStore = StoreUtil.useStore('LoginStore')
    const notificationStore = StoreUtil.useStore('NotificationStore')

    // ローディング状態
    const isLoading = ref(true)

    onBeforeMount(async () => {
      const loader = loading.show()
      const fetchResult = await fetchNotifications()
      if (!fetchResult.isSuccess) {
        MessageDialogStore.value.open({
          title: I18n.tc('NotificationPage.errors.fetchNotificationPageDataError.title'),
          message: I18n.tc('NotificationPage.errors.fetchNotificationPageDataError.message'),
        })
      }

      // お知らせを取得した日時をローカルストレージに保存
      LocalStorageAccessor.lastNotificationFetchedDate = String(now())
      notificationStore.isDisplayNotificationIcon.value = false

      loader.hide()
      isLoading.value = false

      // お知らせに関連するリアルタイムメッセージを受信
      await initRTM()
      subscribeRTM('information', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `NotificationPane#subscribeRTM: Receive notification event. event: ${JSON.stringify(
            data,
          )}`,
        )
        await fetchNotifications()
        // お知らせを取得した日時をローカルストレージに保存
        LocalStorageAccessor.lastNotificationFetchedDate = String(now())
        notificationStore.isDisplayNotificationIcon.value = false
      })
    })

    onUnmounted(() => {
      // お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
      // 取得したレース情報とレース関連情報をクリアする
      clearRaces()
      // 通知一覧をクリア
      clearNotifications()
    })

    // ページネーション: 現在のページ番号
    const currentPage = ref(1)
    // ページネーション: 総ページ数
    const totalPages = computed(() =>
      Math.ceil(notificationList.value.length / NOTIFICATION_COUNT_PER_PAGE),
    )

    /**
     * 遷移先を取得
     */
    const getTransitionPath = (item: NotificationDocument) => {
      const transitionType = item.additionalData?.transition?.type
      const transitionPath = item.additionalData?.transition?.path
      if (!transitionPath || !transitionType) return ''

      if (transitionType === 'url') return transitionPath

      if (transitionType === 'raceVideo') {
        const championshipMasterId = item.additionalData?.transition?.championshipMasterId
        if (!championshipMasterId) return ''
        return `/race-video/${championshipMasterId}/${transitionPath}`
      }

      if (transitionType === 'sfgoAppPage') {
        if (transitionPath === 'ROUNDS') {
          return '/'
        }
        if (transitionPath === 'HOME') {
          return '/home'
        }
        if (transitionPath === 'STANDINGS') {
          return '/standings'
        }
        if (transitionPath === 'MISSION') {
          return '/mission'
        }
        if (transitionPath === 'LINK') {
          return '/link'
        }
        if (transitionPath === 'MYPAGE') {
          return '/mypage'
        }
      }
      return ''
    }

    /**
     * 表示する一覧データ
     * ページネーションの変更をリアクティブに反映する
     */
    const notificationData = computed(() => {
      const startIndex = (currentPage.value - 1) * NOTIFICATION_COUNT_PER_PAGE
      const endIndex = startIndex + NOTIFICATION_COUNT_PER_PAGE
      const notifications = notificationList.value.map((item: NotificationDocument) => ({
        informationId: item.informationId ?? '',
        type: item.type,
        date: item.releaseDate,
        link: getTransitionPath(item),
        title: item.title,
        message: item.contents,
        thumbnailUri: '',
        transitionType: item.additionalData?.transition?.type,
        transitionPath: item.additionalData?.transition?.path,
        sessionYear: item.additionalData?.transition?.year ?? null,
        sessionChampionshipMasterId: item.additionalData?.transition?.championshipMasterId ?? null,
      }))
      return notifications.slice(startIndex, endIndex)
    })

    /**
     * ページ変更イベント
     * @param pageNum ページ番号
     */
    const changePage = (pageNum: number) => {
      currentPage.value = pageNum
      // 一番上までスクロールさせる
      const notificationPane: HTMLElement | null = document.querySelector('.notification-pane')
      if (notificationPane) {
        notificationPane.scrollTop = 0
      }
    }

    /**
     * ハイライト通知 ダミーデータ
     * TODO: ハイライト通知の場合、NotificationItemType > extraに以下のデータが入るようになる
     */
    // const highlightDummy = ref<HighlightSceneType>({
    //   player: {
    //     playerName: {
    //       ja: '野尻智紀Tomonori Nojiri野尻智紀Tomonori Nojiri',
    //       en: 'Tomonori Nojiri',
    //     },
    //     playerPictureImagePath: 'https://placehold.jp/150x150.png',
    //   },
    //   raceTime: "3:40'25",
    //   thumbnailUri: 'https://placehold.jp/900x600.png',
    //   comment:
    //     'ここでのハンドリングが勝負の決め手になりました！ここでのハンドリングが勝負の決め手になりました！ここでのハンドリングが勝負の決め手になりました！',
    // })

    /**
     * お知らせ一覧ダミーデータ
     * TODO: 以下を実装する場合に以下のデータ構造を参考にする
     * * コメント通知
     * * ハイライト通知
     *
     */
    // const notificationDummyData = [
    //   {
    //     type: 'highlight',
    //     date: dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    //     link: '/',
    //     title: '野尻智紀がROUND 2 Q1-A にシーンを作成しました',
    //     message: 'string',
    //     extra: highlightDummy.value,
    //   },
    //   {
    //     type: 'notification',
    //     date: dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    //     link: '/',
    //     title: '2023/08/19 15:00 ROUND 3がスタートします',
    //     message: '2023/08/19 15:00 ROUND 3がスタートします',
    //     thumbnail: 'https://placehold.jp/800x450.png',
    //     mediaType: 'image',
    //   },
    //   {
    //     type: 'notification',
    //     date: dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    //     link: '/',
    //     title: '2023/08/19 15:00 ROUND 3がスタートします',
    //     message: '2023/08/19 15:00 ROUND 3がスタートします',
    //     thumbnail: 'https://placehold.jp/800x450.png',
    //     mediaType: 'video',
    //   },
    //   {
    //     type: 'comment',
    //     date: '2023/07/30 18:30:00',
    //     link: '/',
    //     title: '野尻智紀がROUND 2 Q1-A Cornering にコメント',
    //     message: '後ろから見ててビビりました！',
    //     note: '後ろから見ててビビりました！',
    //   },
    //   {
    //     type: 'notification',
    //     date: dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    //     link: '/',
    //     title: '2023/08/19 15:00 ROUND 3がスタートします',
    //     message: '2023/08/19 15:00 ROUND 3がスタートします',
    //     thumbnail: 'https://placehold.jp/800x450.png',
    //     mediaType: 'image',
    //   },
    //   {
    //     type: 'notification',
    //     date: dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    //     link: '/',
    //     title: '2023/08/19 15:00 ROUND 3がスタートします',
    //     message: '2023/08/19 15:00 ROUND 3がスタートします',
    //     thumbnail: 'https://placehold.jp/800x450.png',
    //     mediaType: 'video',
    //   },
    //   {
    //     type: 'comment',
    //     date: '2023/07/30 18:30:00',
    //     link: '/',
    //     title: '野尻智紀がROUND 2 Q1-A Cornering にコメント',
    //     message: '後ろから見ててビビりました！',
    //     note: '後ろから見ててビビりました！',
    //   },
    // ]

    /**
     * 有料プランへのアップグレードを案内するモーダル フラグ
     */
    const flowLineToPaidPlanModalEnabled = ref(false)

    /**
     * 有料プランへのアップグレードを案内するモーダルに提供するコンテンツ
     */
    const flowLineToPaidPlanModalContents = {
      title: I18n.tc('common.flowLineToPaidPlan.title'),
      message: I18n.tc('common.flowLineToPaidPlan.message'),
      submitText: I18n.tc('common.flowLineToPaidPlan.submitText'),
      link:
        I18n.locale === 'ja'
          ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA
          : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN,
    }

    /**
     * 有料プランへのアップグレードを案内するモーダル 非表示
     */
    const hideFlowLineToPaidPlanModal = () => {
      flowLineToPaidPlanModalEnabled.value = false
    }

    /**
     * 以下の処理を行う
     * 1. お知らせの遷移先がセッション（レース）の場合、会員種別とレース公開範囲設定をチェックする。
     * 2. 閲覧可能な場合、セッション（レース）の動画再生画面に遷移させる。
     * 3. 閲覧不可の場合、有料プランへのアップグレードを案内するモーダルを出す。
     *
     * @param notification 対象のお知らせデータ
     */
    const moveToSession = async (notification: NotificationItemType) => {
      if (notification.transitionType === 'raceVideo') {
        const championshipMasterId = notification.sessionChampionshipMasterId
        const raceId = notification.transitionPath

        if (!championshipMasterId || !raceId) return

        // 対象のセッション（レース）を取得
        const loader = loading.show()
        const fetchRaceResponses = await fetchRace(championshipMasterId, raceId, loginStore.orgId)
        loader.hide()
        const failedFetchRaceResponse = [...fetchRaceResponses].find(
          (response) => !response.isSuccess,
        )
        if (failedFetchRaceResponse) {
          MessageDialogStore.value.open({
            title: I18n.tc('NotificationPage.errors.fetchRaceDataError.title'),
            message: I18n.tc('NotificationPage.errors.fetchRaceDataError.message'),
          })
          return
        }

        // 会員種別とレース公開範囲設定をチェック
        if (!canShowRace(targetRace.value, getTargetRacePermission(targetRace.value.raceType))) {
          // 有料会員（クーポンプランを含む）の場合、モーダルのテキストを変更する
          if (!ContractInfoStore.value.isFreePlan) {
            Object.assign(flowLineToPaidPlanModalContents, {
              title: I18n.tc('common.PrivateContent.title'),
              message: I18n.tc('common.PrivateContent.message'),
              submitText: '',
              link: '',
            })
          }
          // 有料プランへのアップグレードを案内するモーダルを出す
          flowLineToPaidPlanModalEnabled.value = true
          return
        }
      }

      // 指定の画面に遷移
      router.push(notification.link)
    }

    return {
      isLoading,
      notificationData,
      currentPage,
      totalPages,
      changePage,
      flowLineToPaidPlanModalContents,
      flowLineToPaidPlanModalEnabled,
      hideFlowLineToPaidPlanModal,
      moveToSession,
    }
  },
})
