










import { computed, defineComponent } from '@vue/composition-api'
import type VueRouter from 'vue-router'
import MessageDialog from '@/components/sections/MessageDialog/MessageDialogSection.vue'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'

export default defineComponent({
  components: {
    MessageDialog,
  },
  setup() {
    const messageDialogStore = StoreUtil.useStore('MessageDialogStore')
    const loginStore = StoreUtil.useStore('LoginStore')
    const userStore = StoreUtil.useStore('UserStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const isSessionExpired = computed(() => loginStore.isSessionExpired)
    const isSessionLimitExceeded = computed(() => loginStore.isSessionLimitExceeded)

    /**
     * ログイン画面に遷移する。
     *
     * @param router Vue Routerのインスタンス
     * @param logout ログアウト処理のみの場合 true を指定する
     */
    const goLoginPage = (router: VueRouter, logout = false) => {
      loginStore.clearLoginInfo()
      userStore.clearUserData()
      contractInfoStore.clearUserContractInfoData()

      if (logout) {
        router.replace('/logout')
        return
      }
      router.replace('/login')
    }
    return {
      messageDialogStore,
      isCordova: DeviceInfo.isCordova(),
      isSessionExpired,
      isSessionLimitExceeded,
      goLoginPage,
    }
  },
  watch: {
    /**
     * セッション有効期限切れを監視する。
     * セッション有効期限切れの場合、ログイン画面に遷移する。
     * @param sessionExpired セッション有効期限切れかどうか
     */
    async isSessionExpired(sessionExpired: boolean) {
      if (sessionExpired) {
        await MessageDialogStore.value.open({
          title: this.$t('common.sessionExpired.title') as string,
          message: this.$t('common.sessionExpired.message') as string,
        })
        this.goLoginPage(this.$router)
      }
    },
    /**
     * 同時ログイン上限数超えエラーの発生を監視する。
     * 同時ログイン上限数超えエラーが発生した場合、ログイン画面に遷移する。
     * @param sessionLimitExceeded 同時ログイン上限数超えエラーかどうか
     */
    async isSessionLimitExceeded(sessionLimitExceeded: boolean) {
      if (sessionLimitExceeded) {
        await MessageDialogStore.value.open({
          title: this.$t('common.sessionLimitExceeded.title') as string,
          message: this.$t('common.sessionLimitExceeded.message') as string,
        })
        this.goLoginPage(this.$router, true)
      }
    },
  },
})
