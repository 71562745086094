import { computed } from '@vue/composition-api'
import { now, orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import VideoPlatformContentsDocument, {
  RewordVideoType,
} from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import { MembersRankNameType } from '@/util/Const'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

// 特典画像表示用の型
export type RewardDataType = {
  // 動画コンテンツID
  videoContentsId: string
  // 動画種別
  videoType: RewordVideoType
  // 動画識別子
  videoId: string
  // 配布日
  distributionDate: string
  // 配布日UNIXタイムスタンプ
  distributionDateUnixTime: number
  // タイトル
  title: Multilingual
  // 説明
  description: Multilingual
  // 画像パス
  imagePath: string
  // 表示期限日
  deadline: string
}

/**
 * 動画プラットフォームコンテンツを操作するための処理
 */
export default function useVideoPlatformContents() {
  const videoPlatformContentsCollectionModule = CollectionModule.createStore(
    VideoPlatformContentsDocument,
  )
  const { getDisplayDate } = useDisplayDependingOnLang()

  /**
   * 動画プラットフォームコンテンツ情報一覧取得
   */
  const fetchVideoPlatformContents = async () => {
    const currentDate = now()
    return videoPlatformContentsCollectionModule.fetch({
      query: {
        filter: {
          // 表示対象となっている動画コンテンツのみを取得する
          $or: [
            { $and: [{ startDate: null }, { endDate: null }] },
            { $and: [{ startDate: { $lte: currentDate } }, { endDate: { $gte: currentDate } }] },
            { $and: [{ startDate: null }, { endDate: { $gte: currentDate } }] },
            { $and: [{ startDate: { $lte: currentDate } }, { endDate: null }] },
          ],
        },
        // 作成日時が新しい順にソートする
        sort: '-_createdDate',
      },
    })
  }

  /**
   * 動画プラットフォームコンテンツ情報一覧
   */
  const videoPlatformContentsList = computed(() => videoPlatformContentsCollectionModule.data)

  /**
   * 特典画像表示に必要なデータ
   */
  const rewardDataList = computed(
    () =>
      (currentRank: MembersRankNameType | undefined): Array<RewardDataType> =>
        orderBy(videoPlatformContentsList.value, 'startDate', 'desc')
          .filter((content) => {
            if (!currentRank) return false
            // 表示対象ランクであるかどうか
            const isTargetRank = content.targetMemberRanks?.includes(currentRank)

            return (
              (content.videoType === 'rewardImage' || content.videoType === 'rewardVideo') &&
              isTargetRank
            )
          })
          .map((reward) => ({
            videoContentsId: reward.id ?? '',
            videoType: reward.videoType as RewordVideoType, // 上記の処理でvideoTypeでフィルタしてるため、アサーションしている
            videoId: reward.videoId ?? '',
            distributionDate: getDisplayDate(reward.startDate),
            distributionDateUnixTime: reward.startDate ?? 0,
            title: reward.title ?? { ja: '', en: '' },
            description: reward.description ?? { ja: '', en: '' },
            imagePath: reward.videoThumbnailPath ?? '',
            deadline: reward.endDate ? getDisplayDate(reward.endDate) : '---',
          })),
  )

  /**
   * 取得した動画プラットフォームコンテンツ情報をクリアする
   */
  const clearVideoPlatformContents = () => {
    videoPlatformContentsCollectionModule.clearData()
  }

  return {
    fetchVideoPlatformContents,
    videoPlatformContentsList,
    rewardDataList,
    clearVideoPlatformContents,
  }
}
