








































import { defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import ComparisonPlanTableItemParts from '@/components/common/MembershipPlan/ComparisonPlanTableItemParts.vue'

/**
 * プラン比較テーブル
 */
export default defineComponent({
  name: 'ComparisonPlanTableParts',
  components: {
    ComparisonPlanTableItemParts,
  },
  setup() {
    /**
     * プラン比較データ指定
     */
    const planData = [
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.freeMovie'),
        free: true,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.paidMovie'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.snsPost'),
        free: true,
        paid: true,
      },
      // TODO: SFgo's Race Key Points, SFgo Race Rank, Standingsは実装後に表示させる
      // {
      //   label: I18n.tc('SignupPage.SelectPlanPage.comparison.raceKeyPoints'),
      //   free: true,
      //   paid: true,
      //   freeFootnote1: true,
      // },
      // {
      //   label: I18n.tc('SignupPage.SelectPlanPage.comparison.raceRank'),
      //   free: true,
      //   paid: true,
      //   freeFootnote2: true,
      // },
      // {
      //   label: I18n.tc('SignupPage.SelectPlanPage.comparison.standings'),
      //   free: true,
      //   paid: true,
      //   freeFootnote2: true,
      // },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.freeRunMovie'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.qualifyingRaceMovie'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.finalRaceMovie'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.relayMovie'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.onboardMovie'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.highlight'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.telemetry'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.liveTiming'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.locationInformation'),
        free: false,
        paid: true,
      },
      {
        label: I18n.tc('SignupPage.SelectPlanPage.comparison.teamRadio'),
        free: false,
        paid: true,
      },
    ]

    return {
      planData,
    }
  },
})
