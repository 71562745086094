import I18n from '@/locales/I18n'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import StoreUtil from '@/store/StoreUtil'

/**
 * チケット画面チケット情報表示に関する機能を提供する。
 */
export default function useDisplayTicketData() {
  const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
  const { getTargetAvailableArea } = mypageTicketPageStore

  /**
   * イベントの場所情報を返す
   */
  const getDisplayPlaceName = (ticketEvent: DigitalTicketDataDocument) => {
    if (ticketEvent.stadiumId) {
      // スタジアムIDマスタに登録されている情報を表示する場合
      const areaInfo = getTargetAvailableArea(ticketEvent.stadiumId)
      let displayName = areaInfo?.areaName?.[I18n.locale] ?? ''
      if (areaInfo?.championshipName?.[I18n.locale] ?? '') {
        // 大会名（SUGOとオートポリスの場合に表示する）
        displayName += ` ${areaInfo?.championshipName?.[I18n.locale]}`
      }
      if (areaInfo?.address?.[I18n.locale] ?? '') {
        // 住所
        displayName += ` [${areaInfo?.address?.[I18n.locale]}]`
      }
      return displayName
    }
    return I18n.locale === 'ja' ? ticketEvent.placeJa : ticketEvent.placeEn
  }

  return {
    getDisplayPlaceName,
  }
}
