







import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import PaymentEditPane from '@/components/pc/MyPage/PaymentPage/PaymentEditPane.vue'

/**
 * クレジットカード情報変更ページ
 */
export default defineComponent({
  name: 'PaymentEditPage',
  components: {
    SubHeaderSection,
    PaymentEditPane,
  },
})
