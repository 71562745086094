









































































import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import OverlayHeaderParts from '@/components/RaceVideoPage/RaceVideoPane/parts/OverlayHeaderParts.vue'
import OverlayControllerParts from '@/components/RaceVideoPage/RaceVideoPane/parts/OverlayControllerParts.vue'
import PortraitVideoControllerParts from '@/components/RaceVideoPage/RaceVideoPane/parts/PortraitVideoControllerParts.vue'
import LandscapeDataViewSelectorParts from '@/components/RaceVideoPage/RaceVideoPane/parts/LandscapeDataViewSelectorParts.vue'
import StoreUtil from '@/store/StoreUtil'
import { VideoStatusType } from '@/components/hook/useVideoPlayer'
import { ViewAngleType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import DeviceInfo from '@/util/DeviceInfo'
import DeviceOrientation from '@/util/deviceOrientation/DeviceOrientation'
import Logger from '@/util/logger/Logger'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * レース動画再生画面 動画プレーヤー オーバーレイコンポーネント
 */
export default defineComponent({
  name: 'VideoOverlaySection',
  components: {
    OverlayHeaderParts,
    OverlayControllerParts,
    PortraitVideoControllerParts,
    LandscapeDataViewSelectorParts,
  },
  props: {
    /**
     * 表示コンテンツモード
     */
    viewMode: {
      type: String,
      required: true,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * ビデオ一時停止フラグ
     */
    videoPaused: {
      type: Boolean,
      default: false,
    },
    /**
     * ビデオフルスケールフラグ
     */
    videoFullScale: {
      type: Boolean,
      default: false,
    },
    /**
     * スロー再生フラグ
     */
    videoSlow: {
      type: Boolean,
      default: false,
    },
    /**
     * MAP表示フラグ
     */
    mapViewEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 音量調整機能
     */
    hasVolumeControl: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { videoPlayer, videoStatus, viewAngle, isFetchedRaceInfo, videoPlayerStatus } = toRefs(
      raceVideoPageStore.raceVideoPageState,
    )
    const {
      targetChampionship,
      targetRace,
      isLiveNotStarted,
      setViewAngle,
      isAngleChangeAvailable,
      isLive,
    } = raceVideoPageStore

    /**
     * 動画タイトル。
     * 大会のラウンドとレースタイトルを表示する。
     */
    const videoTitle = computed(() => {
      if (targetRace.value && targetChampionship.value) {
        const raceTitle = targetRace.value?.title
        const round = `ROUND ${targetChampionship.value?.round}`
        return targetChampionship.value?.round !== 0 ? `${round} ${raceTitle}` : raceTitle
      }
      return ''
    })

    const viewContentsMode = computed(() => props.viewMode)
    const videoPausedStatus = computed(() => props.videoPaused)
    const videoSlowStatus = computed(() => props.videoSlow)
    const videoFullScaleStatus = computed(() => props.videoFullScale)
    const screenLandScape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)
    const isVideoPlayerReady = computed(() => videoPlayerStatus.value?.playerStatus !== 'Updating')

    /**
     * 現在の動画の再生時間を指定された時間に変更する。
     * @param currentTime 動画再生時間
     */
    const updateCurrentTime = (currentTime: number) => {
      videoPlayer.value?.setCurrentTime(currentTime)
    }

    /**
     * シークバーで、動画の再生位置が変更された場合に呼び出される。
     * @param videoTimesUpdated 動画時間の更新情報
     */
    const updateVideoTimes = (videoTimesUpdated: VideoStatusType) => {
      context.emit('overlayRestartTimer')
      updateCurrentTime(videoTimesUpdated.currentTime)
    }
    /**
     * 現在の動画生成位置を指定した時間(単位: 秒)だけ移動する。
     * @param seconds 移動する時間(単位: 秒)
     */
    const changeVideoTimes = (seconds: number) => {
      if (typeof videoStatus.value?.currentTime === 'number') {
        updateCurrentTime(videoStatus.value.currentTime + seconds)
      }
    }

    /**
     * ビデオヘッダー表示フラグ
     */
    const visibleVideoHeader = computed(
      () =>
        viewContentsMode.value === 'single' ||
        !screenLandScape.value ||
        props.lockOrientation !== undefined,
    )

    return {
      videoInfo: videoStatus,
      videoPlayer,
      viewContentsMode,
      videoPausedStatus,
      videoSlowStatus,
      videoFullScaleStatus,
      screenLandScape,
      isFetchedRaceInfo,
      updateVideoTimes,
      changeVideoTimes,
      videoTitle,
      isLive,
      isLiveNotStarted,
      setViewAngle,
      viewAngle,
      isAngleChangeAvailable,
      raceVideoPageStore,
      isVideoPlayerReady,
      visibleVideoHeader,
    }
  },
  methods: {
    /**
     * オーバーレイ 表示時間更新
     * ヘッダー・コントローラ箇所を押下時のみ表示時間更新通知
     * @param e: Event
     */
    restartOverlayTimer(e: Event): void {
      if (!(e.target as HTMLElement).classList.contains('video-overlay-section__hide_trigger')) {
        this.$emit('overlayRestartTimer')
      }
    },
    /**
     * オーバーレイ非表示化
     */
    hideOverlay(): void {
      this.$emit('overlayHide')
    },
    /**
     * 表示アングル変更
     * @param angle 'race' or 'player'
     *   - race: メイン映像
     *   - player: オンボード映像
     */
    toggleViewAngle(angle: ViewAngleType): void {
      // モード切替時に、サーキットモードを解除する
      const appConfigStore = StoreUtil.useStore('AppConfigStore')
      appConfigStore.currentCircuitMode.value = false

      // 表示アングルを変更
      this.setViewAngle(angle)
      // 簡易マップを非表示にする
      this.$emit('viewMapMode', true)
      // アングルが切り替わるとスロー再生が解除されるため、スロー再生フラグもリセットする
      this.$emit('videoSlowReset')
      // 視聴履歴取得
      this.raceVideoPageStore.saveHistory()
    },
    /**
     * 簡易マップ表示変更
     */
    toggleMapMode(): void {
      this.$emit('viewMapMode')
    },
    /**
     * 動画再生・一時停止処理
     * @param mode trueの場合、一時停止が設定される
     */
    changeVideoPlayPause(mode: boolean): void {
      this.$emit('videoPlayPauseChange', mode)
    },
    /**
     * 表示モード変更
     * @param mode 変更後のモード
     * @param oldMode 変更前のモード
     */
    changeViewMode(mode: string, oldMode: string): void {
      this.$emit('viewModeChange', mode, oldMode)
    },
    /**
     * 全画面表示有効化
     */
    async changeFullscreen() {
      if (this.lockOrientation !== undefined) {
        const rt = this.viewMode === 'single' ? 'telemetry' : 'single'
        this.$emit('viewModeChange', rt, this.viewMode)
        this.$emit('setOrientationTimer')
      } else if (this.screenOrientationType.indexOf('landscape') !== -1) {
        window.screen.orientation
          .lock('portrait-primary')
          .catch(() =>
            Logger.debug(
              'Skip orientation lock due to screen.orientation.lock() is not available on this device.',
            ),
          )
        this.$emit('setOrientationTimer')
      } else if (this.screenOrientationType.indexOf('portrait') !== -1) {
        if (!DeviceInfo.isCordova()) {
          window.screen.orientation
            .lock('landscape')
            .catch(() =>
              Logger.debug(
                'Skip orientation lock due to screen.orientation.lock() is not available on this device.',
              ),
            )
        } else {
          // 現在のデバイスの向きに合わせて方向を取得する.
          const targetOrientation = await DeviceOrientation.getLandscapeDeviceOrientation()

          window.screen.orientation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore 本来の引数は1つだが、改変して２個にしている
            .lock('landscape', targetOrientation)
            .catch(() =>
              Logger.debug(
                'Skip orientation lock due to screen.orientation.lock() is not available on this device.',
              ),
            )
        }
      }
    },
    /**
     * フルスクリーン再生時、動画プレーヤーが画面いっぱいの表示に切り替え切り替えられた際に呼び出される。
     */
    changeFullScaleVideo(): void {
      this.$emit('fullScaleVideo')
    },
    /**
     * 動画がスロー再生された際に呼び出される。
     */
    changeVideoSlow(): void {
      this.$emit('videoSlowChange')
    },
    /**
     * LIVEボタンがクリックされた場合に呼び出される。
     */
    onLiveClicked() {
      this.$emit('onLiveClicked')
    },
    /**
     * シークバー touchStart時イベント通知
     */
    handleTouchStartSeekBar(): void {
      this.$emit('handleTouchStartSeekBar')
    },
    /**
     * シークバー touchEnd時イベント通知
     */
    handleTouchEndSeekBar(): void {
      this.$emit('handleTouchEndSeekBar')
    },
    /**
     * 音声シークバー イベント通知
     * @param volumeLevel 音量
     */
    handleTouchEndVolumeSeekBar(volumeLevel: number) {
      this.$emit('handleTouchEndVolumeSeekBar', volumeLevel)
    },
    /**
     * 音声ミュート イベント通知
     * @param isMute ミュートしているか
     */
    handleVolumeMute(isMute: boolean) {
      this.$emit('handleVolumeMute', isMute)
    },
  },
})
