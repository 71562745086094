import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import UserImageUploadDocument from '@/store/stores/collectionModule/documents/userImageUpload/UserImageUploadDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'
import LoginStore from '@/store/stores/loginStore/LoginStore'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * デジタルチケットデータを操作するための処理
 */
export default function useDigitalTicketData() {
  // Collection modules
  const digitalTicketDataCollectionModule = CollectionModule.createStore(DigitalTicketDataDocument)
  const userImageUploadCollectionModule = CollectionModule.createStore(UserImageUploadDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * デジタルチケットデータ一覧を取得する
   */
  const fetchDigitalTickets = async () => {
    const result = await searchDateRangeData({
      tableName: 'digital-ticket-data',
      partitionKey: 'ecSiteType',
      partitionKeyValue: 'ecforce',
      sortKey: 'createdDate',
    })
    digitalTicketDataCollectionModule.setData(result)
  }

  /**
   * デジタルチケットデータ一覧
   */
  const digitalTickets = computed(() => digitalTicketDataCollectionModule.data)

  /**
   * 年毎に分類されたデジタルチケットデータ一覧
   */
  const digitalTicketsByYear = computed(() => {
    const digitalTicketsMap: Record<number, Array<DigitalTicketDataDocument>> = {}
    digitalTickets.value.forEach((ticketEvent) => {
      const year = dayjs.tz(ticketEvent.openingDateDay1).year()
      if (year) {
        if (digitalTicketsMap[year]) {
          digitalTicketsMap[year].push(ticketEvent)
        } else {
          digitalTicketsMap[year] = [ticketEvent]
        }
      }
    })
    return digitalTicketsMap
  })

  /**
   * uniqueKey（パーティションキー+ソートキー）毎に分類されたデジタルチケットデータ一覧
   */
  const digitalTicketsByUniqueKey = computed(() => {
    const digitalTicketsMap: Record<string, DigitalTicketDataDocument> = {}
    digitalTickets.value.forEach((ticketEvent) => {
      if (ticketEvent.uniqueKey) {
        digitalTicketsMap[ticketEvent.uniqueKey] = ticketEvent
      }
    })
    return digitalTicketsMap
  })

  /**
   * デジタルチケットサムネイル画像情報一覧取得
   */
  const fetchDigitalTicketThumbnailImages = () => {
    const options = {
      query: {
        filter: { type: 'DIGITAL_TICKET_BACKGROUND_IMAGE' },
      },
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/user_image/`,
    }

    return userImageUploadCollectionModule.fetch(options)
  }

  /**
   * デジタルチケットサムネイル画像情報一覧
   */
  const digitalTicketThumbnailImages = computed(() => userImageUploadCollectionModule.data)

  /**
   * ユーザ画像ID毎に分類されたデジタルチケットサムネイル画像情報
   */
  const digitalTicketImagesByUserImageId = computed(() => {
    const imagesMap: Record<string, UserImageUploadDocument> = {}
    digitalTicketThumbnailImages.value.forEach((image) => {
      if (image.userImageId) {
        imagesMap[image.userImageId] = image
      }
    })
    return imagesMap
  })

  /**
   * デジタルチケットデータをクリアする
   */
  const clearDigitalTickets = () => {
    digitalTicketDataCollectionModule.clearData()
    userImageUploadCollectionModule.clearData()
  }

  return {
    fetchDigitalTickets,
    digitalTickets,
    digitalTicketsByYear,
    digitalTicketsByUniqueKey,
    fetchDigitalTicketThumbnailImages,
    digitalTicketImagesByUserImageId,
    clearDigitalTickets,
  }
}
