var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "youtube-list-section" },
    [
      _vm.title && _vm.isVideoLinks
        ? _c("h3", { staticClass: "youtube-list-section__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _c(
        "carousel-list-wrapper-parts",
        {
          staticClass: "youtube-list-section__list",
          attrs: {
            enabledCarouselList: _vm.enabledCarouselList,
            carouselListType: "video",
          },
        },
        _vm._l(_vm.videoLinks, function (videoLink, index) {
          return _c("video-link-card-parts", {
            key: videoLink.img + "-" + index,
            class: "youtube-list-section__list-item" + index,
            attrs: { videoLink: videoLink, membership: _vm.membershipType },
            on: {
              click: function ($event) {
                return _vm.onVideoSelected(videoLink)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }