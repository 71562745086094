













































import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import AtomLink from '@/components/atoms/AtomLink.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'

/**
 * 機能アップデートのお知らせモーダル セクション
 */
export default defineComponent({
  name: 'NoticeNewArrivalsModalSection',
  components: {
    AtomRouterButton,
    AtomLink,
  },
  props: {
    /**
     * 外部リンク
     */
    externalLink: {
      type: String,
      default: '',
    },
    /**
     * ページ内リンク
     */
    pageLink: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const isPc = DeviceInfo.isForPC()
    const handleOkClicked = (pageTransition: boolean) => {
      emit('onClickOk', pageTransition)
    }

    return {
      isPc,
      handleOkClicked,
    }
  },
})
