var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-player-section",
      class: [
        { "video-player-section--single": _vm.viewMode === "single" },
        "video-player-section--" + _vm.screenOrientationType,
        { "video-player-section--full-scale": _vm.videoFullScaleStatus },
      ],
    },
    [
      _c("video", {
        staticClass: "video-js vjs-live vjs-liveui vjs-default-skin",
        attrs: {
          id: "sfgo-video",
          crossorigin: "use-credentials",
          playsinline: "",
        },
      }),
      _vm.showTimeCode
        ? _c(
            "div",
            {
              staticStyle: {
                color: "white",
                "text-align": "left",
                width: "100%",
                "z-index": "1",
              },
            },
            [_vm._v(" " + _vm._s(_vm.currentVideoTrackTime) + " ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }