














import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageFavoriteTeamInitSelectPane from '@/components/MypageFavoriteTeamInitSelectPage/MypageFavoriteTeamInitSelectPane.vue'

export type DataType = {
  screenTransitionModal: boolean
  screenTransitionTo: string
}

/**
 * マイページ: お気に入りチーム選択ページ
 */
export default defineComponent({
  name: 'MypageFavoriteTeamInitSelectPage',
  components: {
    SubHeaderSection,
    MypageFavoriteTeamInitSelectPane,
  },
  data(): DataType {
    return {
      screenTransitionModal: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '', // 画面遷移警告モーダル 遷移先指定
    }
  },
  methods: {
    /**
     * 会員情報入力ページに遷移する
     */
    moveToMypageUserInit(): void {
      this.$router.replace({ name: 'MypageUserInitPage' })
    },
  },
})
