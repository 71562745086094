var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "link-pane" },
    _vm._l(_vm.linksData, function (links, index) {
      return _c("link-section", {
        key: "linkSection" + index,
        staticClass: "link-pane__content",
        attrs: { title: links.sectionTitle, linksData: links.links },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }