var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.disabled
    ? _c(
        "div",
        { on: { click: _vm.emitClick } },
        [
          _c(
            "router-link",
            {
              staticClass:
                "atom-icon-router-btn atom-icon-router-btn--has-icon",
              class: [
                "atom-icon-router-btn--" + _vm.theme + "--" + _vm.colorType,
                "atom-icon-router-btn--" + _vm.size,
              ],
              attrs: { to: _vm.to },
            },
            [
              _c("img", { attrs: { src: _vm.imgPath, alt: "" } }),
              _c("span", [_vm._v(_vm._s(_vm.linkText))]),
            ]
          ),
        ],
        1
      )
    : _c("div", { on: { click: _vm.emitClick } }, [
        _c(
          "span",
          {
            staticClass: "atom-icon-router-btn atom-icon-router-btn--has-icon",
            class: [
              "atom-icon-router-btn--" + _vm.theme + "--" + _vm.colorType,
              "atom-icon-router-btn--" + _vm.size,
            ],
            staticStyle: { "background-color": "#ccc" },
          },
          [
            _c("img", { attrs: { src: _vm.imgPath, alt: "" } }),
            _c("span", [_vm._v(_vm._s(_vm.linkText))]),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }