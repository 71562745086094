import { computed, reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useNotification from '@/store/hook/useNotification'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * 新着のお知らせ通知を扱うStore
 * 本ストアはGlobalStoreとする
 */
/* eslint-disable class-methods-use-this */
class NotificationStore implements StoreBase {
  createStore() {
    // hook
    const { fetchNotificationsForNotify } = useNotification()

    // state
    const notificationState = reactive({
      // お知らせ 新着通知アイコンを表示するかどうか
      isDisplayNotificationIcon: false,
    })

    /**
     * お知らせ 新着通知アイコンを表示するかどうか
     */
    const isDisplayNotificationIcon = computed({
      get: (): boolean => notificationState.isDisplayNotificationIcon,
      set: (val: boolean) => {
        notificationState.isDisplayNotificationIcon = val
      },
    })

    /**
     * お知らせデータの取得処理
     * この処理は、お知らせ一覧画面以外で使用する
     */
    const fetchNotificationData = async () => {
      // 新着アイコン用のお知らせデータを取得する
      const notificationsFetchResult = await fetchNotificationsForNotify(
        Number(LocalStorageAccessor.lastNotificationFetchedDate),
      )
      // 新着通知が1件以上存在する場合は、アイコンを表示させる
      isDisplayNotificationIcon.value = notificationsFetchResult.data.length > 0
    }

    return {
      isDisplayNotificationIcon,
      fetchNotificationData,
    }
  }
}
const value: ValueType<NotificationStore> = {}

export default {
  createStore: new NotificationStore().createStore,
  value: value as Required<typeof value>,
}
