var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-card-parts" }, [
    _c("figure", { staticClass: "team-card-parts__img" }, [
      _c("img", { attrs: { src: _vm.imgPath, alt: "" } }),
    ]),
    _c("div", { staticClass: "team-card-parts__data" }, [
      _c("p", {
        staticClass: "team-card-parts__data__name",
        domProps: {
          innerHTML: _vm._s(_vm.name || _vm.$t("common.favoriteNotSet")),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }