import StoreUtil from '@/store/StoreUtil'

export default function useConditionsMoveToUserEdit() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const userStore = StoreUtil.useStore('UserStore')

  /**
   * 以下のユーザーは郵便番号の入力が必須
   * 1. 有料会員（有料プラン、クーポンプラン、トライアルプランの会員）
   * 2. お住まいの国が日本
   * 3. 郵便番号が登録されていない
   */
  const changeShouldEnterZipCode = () => {
    userStore.shouldEnterZipCode.value = !!(
      userStore.user &&
      contractInfoStore.isZipCodeRequiredPlan.value &&
      userStore.user.value.additionalData?.country === 'JP' &&
      userStore.user.value.additionalData?.zipcode === ''
    )
  }

  return {
    changeShouldEnterZipCode,
  }
}
