











import { computed, defineComponent } from '@vue/composition-api'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * Award ヘッダー セクション
 */
export default defineComponent({
  name: 'AwardHeaderSection',
  props: {
    /**
     * 選択年
     */
    targetYear: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const aboutAwardUrl =
      I18n.locale === 'ja'
        ? Const.EXTERNAL_LINKS.ABOUT_AWARD.JA
        : Const.EXTERNAL_LINKS.ABOUT_AWARD.EN

    /** アワードヘッダーロゴのパス */
    const headerLogoPath = computed(() =>
      CloudFrontUtil.getSignedUrl(
        `${process.env.VUE_APP_IMG_FILE_PATH}/award/${props.targetYear}/sfgo_award_header_logo.png`,
      ),
    )

    return {
      aboutAwardUrl,
      headerLogoPath,
    }
  },
})
