



































import { defineComponent } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import ModalDeclineMessage from '@/components/common/modal/parts/ModalDeclineMessageParts.vue'
/**
 * 共通メッセージダイアログのセクション
 */
export default defineComponent({
  name: 'MessageDialogSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomInputButton,
    ModalDeclineMessage,
  },
  setup() {
    const messageDialogStore = StoreUtil.useStore('MessageDialogStore')

    return {
      messageDialogStore,
    }
  },
  methods: {
    clickedButton(value: string) {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.messageDialogStore.close(value)
    },
  },
})
