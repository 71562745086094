import { AwardRankingCategoryType } from '@/store/stores/collectionModule/documents/award/AwardDataDocument'
import i18n from '@/locales/I18n'

/**
 * SFgo Awards Page内の機能を提供する
 */
export default function useAward() {
  /**
   * お気に入りテキスト取得
   */
  const getFavoriteText = (category: AwardRankingCategoryType) =>
    i18n.tc(`LinkPage.AwardPage.favorite.${category}`)

  return {
    getFavoriteText,
  }
}
