var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "referral-coupon-section" },
    [
      _c("current-plan-card-section", {
        attrs: { plan: _vm.baseCouponPlan, isConfirmed: true },
      }),
      false
        ? _c(
            "p",
            { staticClass: "referral-coupon-section__about" },
            [
              _c("atom-link", {
                attrs: {
                  url: "#",
                  disableTextDecoration: false,
                  isOpenAsBlank: true,
                  "link-text": _vm.$tc(
                    "MypagePage.MypageReferralCode.aboutReferralCode"
                  ),
                  imgPath: require("@/assets/img/icon/icon_external_link_gray.svg"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }