













































import {
  defineComponent,
  ref,
  computed,
  inject,
  reactive,
  Ref,
  onBeforeMount,
} from '@vue/composition-api'
import dayjs from 'dayjs'
import { cloneDeep, orderBy } from 'lodash'
import { PluginApi } from 'vue-loading-overlay'
import MypageTicketTodaySummarySection from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/MypageTicketTodaySummarySection.vue'
import MypageTicketTodayListSection from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/MypageTicketTodayListSection.vue'
import MypageTicketTodaySafeModalSection from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/MypageTicketTodaySafeModalSection.vue'
import MypageTicketTodayConfirmModalSection from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/MypageTicketTodayConfirmModalSection.vue'
import MypageTicketTodayCurrentTimeParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypageTicketTodayCurrentTimeParts.vue'
import StoreUtil from '@/store/StoreUtil'
import usePurchasedTicketData, {
  UserPurchasedTicketType,
} from '@/components/hook/ticket/usePurchasedTicketData'
import useHistory from '@/store/hook/useHistory'
import DigitalTicketDataDocument, {
  OpeningDatesType,
} from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import DeviceInfo from '@/util/DeviceInfo'

type ToBeUsedTicketInfoType = {
  ticketEvent: DigitalTicketDataDocument | undefined
  ticketType: DigitalTicketDataTicketTypeDocument | undefined
  targetPurchasedTicket: UserPurchasedTicketType | undefined
}

/**
 * チケットを使用する画面で利用する変数の初期状態
 */
const initialToBeUsedTicketInfo = {
  // こちれから利用するチケットのイベント
  ticketEvent: undefined,
  // こちれから利用するチケットの券種
  ticketType: undefined,
  // 対象イベントに紐づいているユーザーのチケット購入情報
  targetPurchasedTicket: undefined,
}

export default defineComponent({
  name: 'MypageTicketTodayPane',
  components: {
    MypageTicketTodayCurrentTimeParts,
    MypageTicketTodaySummarySection,
    MypageTicketTodayListSection,
    MypageTicketTodayConfirmModalSection,
    MypageTicketTodaySafeModalSection,
  },
  inject: ['loading'],
  setup() {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const {
      fetchTicketPageData,
      digitalTickets,
      digitalTicketImagesByUserImageId,
      showingDetailTodayTicketTypeId,
      clearTicketPageData,
    } = mypageTicketPageStore
    const { ownOrganization, fetchOrganizations, updateOrganization } = mypagePageStore
    const { userPurchasedTicket, matchedTodayDate } = usePurchasedTicketData()
    const { saveOrganizationHistory } = useHistory()

    const isPc = DeviceInfo.isForPC()

    const loading = inject('loading') as PluginApi

    // セーフティー１モーダルを表示するかどうか
    const safeModalView = ref(false)
    // セーフティー２モーダルを表示するかどうか
    const confirmModalView = ref(false)

    /**
     * チケットを使用する画面で利用する変数
     */
    const toBeUsedTicketInfo: ToBeUsedTicketInfoType = reactive({ ...initialToBeUsedTicketInfo })

    /**
     * ログインユーザーが購入したチケットに紐づくデジタルチケットデータ
     */
    const purchasedTodayAvailableTickets = computed(() => {
      const eventUniqueKeys = userPurchasedTicket(digitalTickets.value, ownOrganization.value)
        .ticketEventUniqueKeys.availableToday

      return orderBy(digitalTickets.value, 'openingDateDay1').filter((ticketEvent) =>
        eventUniqueKeys.some((uniqueKey) => uniqueKey === ticketEvent.uniqueKey),
      )
    })

    /**
     * 入場チケット背景画像
     */
    const todayTicketScreenBgImageStyle = computed(() => ({
      '--img': `url(${
        digitalTicketImagesByUserImageId.value[
          purchasedTodayAvailableTickets.value[0]?.userImageId ?? ''
        ]?.path
      })`,
    }))

    /**
     * 係員がこの画面を確認しますボタンが押下されたときの処理
     */
    const admissionTicketClicked = (
      targetTicketType: DigitalTicketDataTicketTypeDocument,
      targetTicketEvent: DigitalTicketDataDocument,
      purchasedTicketInfo: UserPurchasedTicketType,
    ) => {
      // モーダルで表示するチケット情報をセット
      toBeUsedTicketInfo.ticketEvent = cloneDeep(targetTicketEvent)
      toBeUsedTicketInfo.ticketType = cloneDeep(targetTicketType)
      toBeUsedTicketInfo.targetPurchasedTicket = cloneDeep(purchasedTicketInfo)

      safeModalView.value = !safeModalView.value
    }

    /**
     * チケットを使用済みにする
     */
    const updateTicketToUsed = async () => {
      const newOrgData = cloneDeep(ownOrganization.value)
      if (!newOrgData?.additionalData?.ec) {
        // ecサイト連携済みの場合、additionalData.ecは必ず存在する想定だが、一応エラーを表示しておく
        MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageTicketTodayPage.errors.noAvailableTicketsFound.title'),
          message: I18n.tc(
            'MypagePage.MypageTicketTodayPage.errors.noAvailableTicketsFound.message',
          ),
        })
        return
      }

      // 現在時刻を取得
      const now = dayjs().valueOf()
      // 今日の開催日時情報
      const openingTodayInfo = toBeUsedTicketInfo.ticketEvent
        ? matchedTodayDate(toBeUsedTicketInfo.ticketEvent)
        : undefined
      if (!openingTodayInfo?.key) {
        MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageTicketTodayPage.errors.noAvailableTicketsFound.title'),
          message: I18n.tc(
            'MypagePage.MypageTicketTodayPage.errors.noAvailableTicketsFound.message',
          ),
        })
        return
      }

      toBeUsedTicketInfo.targetPurchasedTicket?.productNumbers.availableToday.forEach(
        (productNumber) => {
          if (
            !toBeUsedTicketInfo.ticketType?.tickets?.some(
              (ticket) => ticket.productNumber === productNumber,
            )
          ) {
            // 使用済みにする券種以外に紐づくチケットについては使用済みにしない
            return
          }

          if (newOrgData.additionalData?.ec?.ticketUseHistoriesByProductNumber?.[productNumber]) {
            // 同一の商品で別日に使用済みの場合（通し券の場合にこちらの処理に入る）
            newOrgData.additionalData.ec.ticketUseHistoriesByProductNumber[
              productNumber
            ].enteredDates = {
              ...newOrgData.additionalData.ec.ticketUseHistoriesByProductNumber[productNumber]
                .enteredDates,
              ...({
                [openingTodayInfo.key as OpeningDatesType]: now,
              } as Record<OpeningDatesType, number>),
            }
          } else if (newOrgData.additionalData?.ec?.ticketUseHistoriesByProductNumber) {
            // 既に使用済みにした商品が存在し、別の商品を使用済みにする場合
            newOrgData.additionalData.ec.ticketUseHistoriesByProductNumber = {
              ...newOrgData.additionalData.ec.ticketUseHistoriesByProductNumber,
              ...{
                [productNumber]: {
                  enteredDates: {
                    [openingTodayInfo.key as OpeningDatesType]: now,
                  } as Record<OpeningDatesType, number>,
                },
              },
            }
          } else if (newOrgData.additionalData?.ec) {
            // ticketUseHistoriesByProductNumberが存在しない場合

            // NOTE: 処理の先頭で!newOrgData?.additionalData?.ecの場合は早期リターンしているが、forEach内でnewOrgData.additionalData.ecとするとTypeScriptエラーになってしまうため、
            // else if (newOrgData.additionalData?.ec)でもう一度「key:ec」が存在することをチェックしている
            newOrgData.additionalData.ec = {
              ...newOrgData.additionalData.ec,
              ticketUseHistoriesByProductNumber: {
                [productNumber]: {
                  enteredDates: {
                    [openingTodayInfo.key as OpeningDatesType]: now,
                  } as Record<OpeningDatesType, number>,
                },
              },
            }
          }
        },
      )

      const updateOrganizationResult = await updateOrganization(newOrgData)

      if (!updateOrganizationResult.isSuccess) {
        MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageTicketTodayPage.errors.updateOrganizationError.title'),
          message: I18n.tc(
            'MypagePage.MypageTicketTodayPage.errors.updateOrganizationError.message',
          ),
          errorApiResponse: updateOrganizationResult.response,
        })
        return
      }
      // pusher通知が動かないことも想定し、組織情報をfetchしておく
      await fetchOrganizations()

      // 操作ログを登録し、複数端末でチケット画面を開いていた場合でも両方の端末の組織情報を最新にする
      await saveOrganizationHistory(newOrgData, UserStore.value.user.value)
    }

    /**
     * セーフティー1
     * 操作しているのは係員ですか？モーダルで「はい」を押した場合の処理
     */
    const handleSubmitSafeModalClicked = () => {
      safeModalView.value = false
      confirmModalView.value = true
    }

    /**
     * セーフティー1
     * 操作しているのは係員ですか？モーダルで「いいえ」を押した場合の処理
     */
    const handleCloseSafeModalClicked = () => {
      safeModalView.value = false

      // モーダルで表示するチケット情報をクリア
      Object.assign(toBeUsedTicketInfo, initialToBeUsedTicketInfo)
    }

    /**
     * セーフティー2
     * 使用済みにするボタンを押せるモーダルで「使用済みにする」を押した場合の処理
     */
    const handleSubmitConfirmModalClicked = async () => {
      const loader = loading.show()
      await updateTicketToUsed()

      confirmModalView.value = false
      loader.hide()

      // モーダルで表示するチケット情報をクリア
      Object.assign(toBeUsedTicketInfo, initialToBeUsedTicketInfo)
    }

    /**
     * セーフティー2
     * 使用済みにするボタンを押せるモーダルで「キャンセル」を押した場合の処理
     */
    const handleCancelConfirmModalClicked = () => {
      confirmModalView.value = false

      // モーダルで表示するチケット情報をクリア
      Object.assign(toBeUsedTicketInfo, initialToBeUsedTicketInfo)
    }

    /**
     * チケットページに必要なデータを取得する
     */
    const fetchTicketData = async () => {
      const errorTitle = I18n.tc(
        'MypagePage.MypageTicketListPage.errors.fetchTicketPageDataError.title',
      )
      const errorMessage = I18n.tc(
        'MypagePage.MypageTicketListPage.errors.fetchTicketPageDataError.message',
      )
      const loader = loading.show()
      try {
        // チケット一覧を取得する
        const results = await fetchTicketPageData()
        loader.hide()

        const failedResult = results.find((result) => result && !result?.isSuccess)
        if (failedResult) {
          MessageDialogStore.value.open({
            title: errorTitle,
            message: errorMessage,
            errorApiResponse: failedResult.response,
          })
          clearTicketPageData()
        }
      } catch (e) {
        loader.hide()
        MessageDialogStore.value.open({
          title: errorTitle,
          message: errorMessage,
        })
        clearTicketPageData()
      }
    }

    onBeforeMount(async () => {
      if (digitalTickets.value.length === 0) {
        await fetchTicketData()
      }
      // 最新の組織情報で入場済みかどうかの判定を行いたいため、一応最新の組織情報を取得しておく
      await fetchOrganizations()

      // 入場券の券種
      const admissionTicketType = purchasedTodayAvailableTickets.value
        .find((ticketEvent) =>
          ticketEvent.ticketTypes?.some((ticketType) => ticketType.isAdmission),
        )
        ?.ticketTypes?.find((ticketType) => ticketType.isAdmission)

      if (admissionTicketType) {
        // 入場券の場合、詳細を開いた状態にする
        // 複数の券種で入場券が存在する場合は、1つ目の券種の詳細を開いた状態にする
        showingDetailTodayTicketTypeId.value = admissionTicketType.ticketTypeId
      }
    })

    return {
      isPc,
      safeModalView,
      confirmModalView,
      todayTicketScreenBgImageStyle,
      toBeUsedTicketInfo,
      purchasedTodayAvailableTickets: purchasedTodayAvailableTickets as Ref<
        Array<DigitalTicketDataDocument>
      >,
      admissionTicketClicked,
      handleSubmitSafeModalClicked,
      handleCloseSafeModalClicked,
      handleSubmitConfirmModalClicked,
      handleCancelConfirmModalClicked,
    }
  },
})
