










import { ComputedRef, defineComponent } from '@vue/composition-api'
import TeamSelectListSection from '@/components/MypageFavoriteTeamSelectPage/MypageFavoriteTeamSelectPane/TeamSelectListSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import StoreUtil from '@/store/StoreUtil'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'

export type DataType = {
  selectedID: string
}

/**
 * マイページ: お気に入りチーム変更 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageFavoriteTeamSelectPane',
  components: {
    TeamSelectListSection,
  },
  data(): DataType {
    return {
      /**
       * セレクトID
       */
      selectedID: '',
    }
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      teams: mypagePageStore.availableTeams as ComputedRef<Array<TeamDocument>>,
      favoriteTeam: mypagePageStore.favoriteTeam,
      saveFavoriteTeam: mypagePageStore.saveFavoriteTeam,
    }
  },
  mounted() {
    this.selectedID = this.favoriteTeam ? this.favoriteTeam.sid : ''
  },
  methods: {
    async changeFavoriteTeam() {
      /**
       * お気に入りチーム保存
       */
      const loader = this.$loading.show()
      const apiResult = await this.saveFavoriteTeam(this.selectedID)
      loader.hide()
      if (apiResult.isSuccess) {
        // グローバルストアに保持するユーザー情報を更新
        UserStore.value.fetchUserData(LoginStore.value.loginId)

        this.$emit('success-regist')
      } else {
        // API通信不具合
        await MessageDialogStore.value.open({
          title: this.$t('MypagePage.MypageFavoriteTeamSelectPage.errors.title') as string,
          message: this.$t('MypagePage.MypageFavoriteTeamSelectPage.errors.message') as string,
          errorApiResponse: apiResult.response,
        })
      }
    },
    changeSelected(selectedItemID: string): void {
      /**
       * 選択変更
       * @param selectedItemID : string
       */
      this.selectedID = this.selectedID === selectedItemID ? '' : selectedItemID
    },
  },
})
