

















































































































import { computed, defineComponent, inject, PropType, ref, Ref } from '@vue/composition-api'
import VueRouter from 'vue-router'
import { orderBy } from 'lodash'
import dayjs from 'dayjs'
import I18n from '@/locales/I18n'
import MypageTicketAccordionIconParts from '@/components/MypageTicketListPage/MypageTicketListPane/parts/MypageTicketAccordionIconParts.vue'
import MypageTicketItemSection from '@/components/MypageTicketListPage/MypageTicketListPane/MypageTicketItemSection.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import MotegiParts from '@/components/common/circuit/MotegiParts.vue'
import FujiParts from '@/components/common/circuit/FujiParts.vue'
import SugoParts from '@/components/common/circuit/SugoParts.vue'
import AutopoliceParts from '@/components/common/circuit/AutopoliceParts.vue'
import SuzukaParts from '@/components/common/circuit/SuzukaParts.vue'
import DigitalTicketDataDocument, {
  OpeningDatesType,
} from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import StoreUtil from '@/store/StoreUtil'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import usePurchasedTicketData, {
  UserPurchasedTicketType,
} from '@/components/hook/ticket/usePurchasedTicketData'
import useDisplayTicketData from '@/components/MypageTicketListPage/hook/useDisplayTicketData'

type OpeningDateDataType = {
  key: OpeningDatesType
  value: number
}

/**
 * マイページ: チケットブロックセクション
 * イベントとイベントに紐づくチケット一覧を1ブロックとして扱う
 */
export default defineComponent({
  name: 'MypageTicketBlockSection',
  components: {
    SuzukaParts,
    AutopoliceParts,
    SugoParts,
    FujiParts,
    MotegiParts,
    AtomRouterButton,
    MypageTicketItemSection,
    MypageTicketAccordionIconParts,
  },
  inject: ['router'],
  props: {
    /**
     * デジタルチケット イベント情報
     */
    ticketEvent: {
      type: Object as PropType<DigitalTicketDataDocument>,
      required: true,
    },
  },
  setup(props) {
    // store
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { selectedPastTicketInfo } = mypageTicketPageStore
    const { ownOrganization } = mypagePageStore
    // hook
    const { getDisplayPlaceName } = useDisplayTicketData()
    const { userPurchasedTicket } = usePurchasedTicketData()
    const { getDisplayDateJa } = useDisplayDependingOnLang()

    const router = inject('router') as VueRouter

    // 詳細を開くかどうか
    const isExpanded = ref(false)

    // 開閉ボタンのラベルを返す
    const toggleButtonLabel = computed(() =>
      isExpanded.value
        ? I18n.tc('MypagePage.MypageTicketListPage.toggleLabel.close')
        : I18n.tc('MypagePage.MypageTicketListPage.toggleLabel.open'),
    )

    /**
     * 場所
     */
    const displayPlaceName = computed(() => getDisplayPlaceName(props.ticketEvent))

    /**
     * 開場日時をkey(n日目)、value(開場日時)の配列に変換したデータ
     */
    const displayOpeningDates: Ref<Array<OpeningDateDataType>> = computed(() =>
      orderBy(
        Object.entries(props.ticketEvent.openingDates ?? {}).map(([key, value]) => ({
          key: key as OpeningDatesType,
          value,
        })),
        'key',
      ),
    )

    /**
     * イベント期間開始日
     */
    const displayEventStartDate = computed(() =>
      getDisplayDateJa(props.ticketEvent.openingDates?.day1),
    )

    /**
     * イベント期間開始日の曜日
     */
    const displayEventStartDayOfWeek = computed(() =>
      getDisplayDateJa(props.ticketEvent.openingDates?.day1, 'ddd', 'ddd'),
    )

    /**
     * イベント期間終了日
     */
    const displayEventEndDate = computed(() => {
      const endDate = orderBy(displayOpeningDates.value, 'key', 'desc').find(
        (date) => date.value,
      )?.value
      return getDisplayDateJa(endDate)
    })

    /**
     * イベント期間終了日の曜日
     */
    const displayEventEndDayOfWeek = computed(() => {
      const endDate = orderBy(displayOpeningDates.value, 'key', 'desc').find(
        (date) => date.value,
      )?.value
      return getDisplayDateJa(endDate, 'ddd', 'ddd')
    })

    /**
     * ログインユーザーが購入した商品情報
     */
    const purchasedTicketInfo = computed(() =>
      userPurchasedTicket([props.ticketEvent], ownOrganization.value),
    )

    /**
     * ログインユーザーが購入したチケットに紐づく券種データ
     */
    const purchasedTicketTypes = computed(() =>
      orderBy(props.ticketEvent.ticketTypes, 'order')?.filter((ticketType) =>
        purchasedTicketInfo.value.ticketTypeIds.all.some(
          (ticketTypeId) => ticketTypeId === ticketType.ticketTypeId,
        ),
      ),
    )

    /**
     * 開場日を取得する
     */
    const getOpeningDate = (date: number) =>
      `${getDisplayDateJa(date, 'MM/DD', 'MMM DD')} ${getDisplayDateJa(date, 'ddd', 'ddd')}`

    /**
     * 開場時間を取得する
     */
    const getOpeningTime = (date: number) => getDisplayDateJa(date, 'HH:mm', 'HH:mm')

    /**
     * 過去のチケットを表示するボタンのテキスト
     */
    const getPastButtonText = (index: number) =>
      I18n.t('MypagePage.MypageTicketListPage.pastTicketButtonLabel', {
        day: index + 1,
      }).toString()

    /** 今日の0:00:00 */
    const beginningOfTheDay = dayjs().startOf('day').valueOf()

    /**
     * 過去のチケットかどうか
     */
    const isPastTicket = (openingDateInfo: OpeningDateDataType) => {
      // 対象日に利用可能なチケットを持っているか
      const hasTargetDateTickets = purchasedTicketTypes.value?.some((ticketType) =>
        ticketType.tickets?.some((ticket) => {
          const isPurchased = purchasedTicketInfo.value.productNumbers.all.some(
            (productNumber) => productNumber === ticket.productNumber,
          )
          if (!isPurchased) {
            return false
          }
          return ticket.availableDates?.[openingDateInfo.key]
        }),
      )
      if (!hasTargetDateTickets) {
        // 対象日に利用可能なチケットを購入していない場合はボタンを表示しない
        return false
      }
      if (!openingDateInfo.value || beginningOfTheDay <= openingDateInfo.value) {
        // イベントの開催日が当日以降のチケットは表示しない
        return false
      }

      /**
       * 対象イベントの開場日に紐づく有効なチケットを持っている場合、ボタンを表示する
       * 以下で利用しているメソッドは対象イベントの開場日に紐づく当日以降に使えるチケット情報も取得できてしまうが、
       * 上記「イベントの開催日が今日以降のチケットは表示しない」の処理で当日以降のイベントの場合はこちらの処理に入らないように制御されている。
       */
      return purchasedTicketInfo.value.productNumbers.all.length > 0
    }

    /**
     * 過去のチケットが存在するかどうか
     */
    const hasPastTickets = computed(() =>
      displayOpeningDates.value.some((date) => isPastTicket(date)),
    )

    // 開閉ボタンが押下されたときの処理
    const handleToggleClicked = () => {
      isExpanded.value = !isExpanded.value
    }

    /**
     * 過去のチケットページに遷移する
     */
    const moveToMypageTicketPastPage = (targetDay: OpeningDatesType) => {
      // 表示対象のイベントとイベントの開催日目をストアに保存
      selectedPastTicketInfo.value = {
        ticketEventUniqueKey: props.ticketEvent.uniqueKey,
        day: targetDay,
      }
      router.replace('/mypage/ticket-past')
    }

    return {
      isExpanded,
      toggleButtonLabel,
      displayPlaceName,
      displayOpeningDates,
      displayEventStartDate,
      displayEventStartDayOfWeek,
      displayEventEndDate,
      displayEventEndDayOfWeek,
      purchasedTicketInfo: purchasedTicketInfo as Ref<UserPurchasedTicketType>,
      purchasedTicketTypes: purchasedTicketTypes as Ref<
        Array<DigitalTicketDataTicketTypeDocument> | undefined
      >,
      getOpeningDate,
      getOpeningTime,
      getPastButtonText,
      isPastTicket,
      hasPastTickets,
      handleToggleClicked,
      moveToMypageTicketPastPage,
    }
  },
})
