var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "highlightContentsSectionRef",
      staticClass: "highlight-contents-section",
    },
    [
      _c(
        "div",
        { staticClass: "highlight-contents-section__tab" },
        [
          _c("highlight-list-tab-parts", {
            attrs: { selectedHighlightTab: _vm.selectedHighlightTab },
            on: { onClickHighlightTab: _vm.toggleHighlightTabs },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.highlightContentSectionListRenderKey,
          staticClass: "highlight-contents-section__list",
        },
        _vm._l(_vm.highlightListExcludeHiddenFlag, function (v, i) {
          return _c("highlight-item-section", {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: _vm.fetchParentCommentCountAndReactions,
                expression: "fetchParentCommentCountAndReactions",
              },
            ],
            key: i,
            attrs: {
              screenOrientationType: _vm.screenOrientationType,
              id: "" + _vm.prefixHighlightItemSectionId + v.id,
              selectedHighlightTab: _vm.selectedHighlightTab,
              highlightData: v,
              current: _vm.selectedHighlightId,
              currentPlayer: _vm.selectedPlayerMasterId,
              playEventList: _vm.playEvents,
              playerList: _vm.players,
              targetHighlightComments:
                _vm.highlightCommentDataById[v.highlightId],
              targetEnableInput: _vm.enabledInputsMap[v.highlightId],
              editModesMap: _vm.editModesMap,
              editModesMainCommentMap: _vm.editModesMainCommentMap,
              targetInputHighlightComment:
                _vm.postingHighlightCommentsMap[v.highlightId],
              loginUserId: _vm.loginUserId,
              inputComment: _vm.inputComment,
              retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
              scrollToHighlightId: _vm.scrollToHighlightId,
              sceneMovies: _vm.sceneMoviesByHighlightId[v.id],
              highlightCommentCount:
                _vm.highlightCommentCountByHighlightId[v.highlightId],
            },
            on: {
              sceneChange: _vm.changeScene,
              onToggleEnabledInput: _vm.onToggleEnabledInput,
              onToggleIsEditMode: _vm.onToggleIsEditMode,
              onToggleMainCommentIsEditMode: _vm.onToggleMainCommentIsEditMode,
              onScrollToComment: _vm.scrollToComment,
              onScrollToHighlight: _vm.scrollToHighlight,
              onChangeHighlightComment: _vm.onChangeHighlightComment,
              onChangeHighlightMainComment: _vm.onChangeHighlightMainComment,
              onChangeLike: _vm.onChangeLike,
              handlerEdit: _vm.handlerEdit,
              handlerSubmit: _vm.handlerSubmit,
              onClickOpenMenu: _vm.handleOpenMenuClicked,
              onClickCommentMenu: _vm.handleCommentMenuClicked,
              downloadHighlight: _vm.downloadHighlight,
              fetchDiffTargetHighlightCommentsAndReactions:
                _vm.fetchDiffTargetHighlightCommentsAndReactions,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }