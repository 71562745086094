var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dl", { staticClass: "fieldset-confirm-parts" }, [
    _c("dt", [_vm._v(_vm._s(_vm.label))]),
    _c("dd", { domProps: { innerHTML: _vm._s(_vm.viewValue) } }),
    _vm.attention
      ? _c("div", {
          staticClass: "fieldset-confirm-parts__attention",
          domProps: { innerHTML: _vm._s(_vm.attention) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }