var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-ranking-share-section",
      class: { "mission-ranking-share-section--for-pc": _vm.isPc },
    },
    [
      _c(
        "div",
        {
          staticClass: "mission-ranking-share-section__container",
          class: {
            "mission-ranking-share-section__container--wide": _vm.isWideDevice,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mission-ranking-share-section__card",
              class:
                "mission-ranking-share-section__card--type-" +
                _vm.shareRankingImageInfo.ranking.type.toLowerCase(),
              style: _vm.backgroundImagePathStyle,
            },
            [
              _c(
                "div",
                { staticClass: "mission-ranking-share-section__card-inner" },
                [
                  _c(
                    "div",
                    { staticClass: "mission-ranking-share-section__user" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mission-ranking-share-section__user-name",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.shareRankingImageInfo.displayName) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mission-ranking-share-section__user-point",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.shareRankingImageInfo.point) +
                              _vm._s(
                                _vm.$tc("MissionPage.RankingPage.Share.point")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mission-ranking-share-section__rank" },
                    [
                      _vm.shareRankingImageInfo.ranking.rank < 4
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mission-ranking-share-section__rank-image",
                              class:
                                "mission-ranking-share-section__rank-image" +
                                _vm.shareRankingImageInfo.ranking.rank,
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mission-ranking-share-section__rank-number",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.shareRankingImageInfo.ranking.rank
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "mission-ranking-share-section__rank-display",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mission-ranking-share-section__rank-display-label",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "MissionPage.RankingPage.Share.rank"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mission-ranking-share-section__rank-display-value",
                                  class: {
                                    "mission-ranking-share-section__rank-display-value--over":
                                      _vm.shareRankingImageInfo.ranking.rank >
                                      99999,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.shareRankingImageInfo.ranking.rank
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mission-ranking-share-section__rank-body",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mission-ranking-share-section__rank-title",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.shareRankingImageInfo.ranking.headline
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mission-ranking-share-section__rank-name",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.shareRankingImageInfo.ranking.title
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mission-ranking-share-section__rank-date",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.shareRankingImageInfo.ranking.mothDate
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    { staticClass: "mission-ranking-share-section__date" },
                    [_vm._v(_vm._s(_vm.today))]
                  ),
                ]
              ),
              _c("div", {
                staticClass: "mission-ranking-share-section__card-overlay",
              }),
              _c("div", {
                staticClass: "mission-ranking-share-section__card-decoration",
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "mission-ranking-share-section__footer" },
            [
              _vm.isPc
                ? _c(
                    "p",
                    {
                      staticClass: "mission-ranking-share-section__footer-note",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc("MissionPage.RankingPage.Share.noteOnlyX")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isPc && !_vm.visiblePostSnsModal
                ? [
                    _c(
                      "ul",
                      { staticClass: "mission-ranking-share-section__buttons" },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "mission-ranking-share-section__buttons-item",
                          },
                          [
                            _c("atom-button", {
                              staticClass:
                                "mission-ranking-share-section__button",
                              attrs: {
                                theme: "dark",
                                colorType: "secondary",
                                linkText: _vm.$tc(
                                  "MissionPage.RankingPage.Share.postOnlyX"
                                ),
                              },
                              on: { onClick: _vm.openSnsPostModal },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass:
                              "mission-ranking-share-section__buttons-item",
                          },
                          [
                            _c("atom-button", {
                              staticClass:
                                "mission-ranking-share-section__button",
                              attrs: {
                                theme: "dark",
                                colorType: "tertiary",
                                linkText: _vm.$tc(
                                  "MissionPage.RankingPage.Share.download"
                                ),
                              },
                              on: { onClick: _vm.handleImageDownloadClicked },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm.isPc
                ? _c(
                    "ul",
                    { staticClass: "mission-ranking-share-section__buttons" },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "mission-ranking-share-section__buttons-item",
                        },
                        [
                          _c("atom-button", {
                            staticClass:
                              "mission-ranking-share-section__button",
                            attrs: {
                              theme: "dark",
                              linkText: _vm.$tc(
                                "MissionPage.RankingPage.Share.download"
                              ),
                            },
                            on: { onClick: _vm.handleImageDownloadClicked },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "mission-ranking-share-section__buttons-item",
                        },
                        [
                          _c("atom-button", {
                            staticClass:
                              "mission-ranking-share-section__button",
                            attrs: {
                              theme: "dark",
                              colorType: "secondary",
                              linkText: _vm.$tc(
                                "MissionPage.RankingPage.Share.postX"
                              ),
                            },
                            on: {
                              onClick: function ($event) {
                                return _vm.onClickAnchorLink(_vm.xUrl)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "li",
                            {
                              staticClass:
                                "mission-ranking-share-section__buttons-item",
                            },
                            [
                              _c("atom-button", {
                                staticClass:
                                  "mission-ranking-share-section__button",
                                attrs: {
                                  theme: "dark",
                                  colorType: "secondary",
                                  linkText: _vm.$tc(
                                    "MissionPage.RankingPage.Share.postInstagram"
                                  ),
                                },
                                on: {
                                  onClick: function ($event) {
                                    return _vm.onClickAnchorLink(
                                      _vm.instagramUrl
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              !_vm.visiblePostSnsModal
                ? _c(
                    "p",
                    { staticClass: "mission-ranking-share-section__submit" },
                    [
                      _c("atom-button", {
                        staticClass: "mission-ranking-share-section__button",
                        attrs: {
                          theme: "dark",
                          linkText: _vm.$tc("common.ok"),
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.$emit("onClickOk")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.visiblePostSnsModal
        ? _c(
            "div",
            { staticClass: "mission-ranking-share-section__post" },
            [
              _c("MissionRankingSharePostSection", {
                attrs: { imageUrl: _vm.shareImageUrl },
                on: {
                  onClickCancel: _vm.onClickCancel,
                  onClickPostSns: _vm.onClickPostSns,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }