import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import AvailableAreaDocument from '@/store/stores/collectionModule/documents/availableArea/AvailableAreaDocument'
import Const from '@/util/Const'

/**
 * 利用できる場所情報（スタジアムIDマスタ）を操作するための処理を取得する。
 */
export default function useAvailableArea() {
  // Collection modules
  const availableLocationCollectionModule = CollectionModule.createStore(AvailableAreaDocument)

  /**
   * 利用できる場所情報一覧を取得
   */
  const fetchAvailableAreas = () => availableLocationCollectionModule.fetch()

  /**
   * サーキット一覧を取得
   */
  const fetchCircuitList = () =>
    availableLocationCollectionModule.fetch({
      query: {
        filter: { stadiumId: { $in: Object.keys(Const.IMAGE_PATH).map((course) => course) } },
      },
    })

  /**
   * 場所一覧
   */
  const areas = computed(() =>
    availableLocationCollectionModule.data.filter((location) => location.stadiumId !== 'SF'),
  )

  /**
   * クーポンを利用できる場所一覧
   * レースの会場として登録しているSFを除く
   * // TODO: クーポンとして利用できる場所を絞り込む処理を入れるかもしれない
   */
  const couponAvailableAreas = computed(() =>
    availableLocationCollectionModule.data.filter((location) => location.stadiumId !== 'SF'),
  )

  /**
   * ミッションのチェックインができる場所一覧
   * レースの会場として登録しているSFを除く
   */
  const missionAvailableAreas = computed(() =>
    availableLocationCollectionModule.data.filter(
      (location) => location.stadiumId !== 'SF' && location.mission,
    ),
  )

  /**
   * 指定されたplayerIdの選手マスタを取得する。
   *
   * @param areaId 利用可能場所ID
   */
  const getTargetAvailableArea = (areaId: string) =>
    couponAvailableAreas.value.find((area) => area.stadiumId === areaId)

  /**
   * 取得した利用できる場所情報をクリアする
   */
  const clearAvailableAreas = () => {
    availableLocationCollectionModule.clearData()
  }

  return {
    fetchAvailableAreas,
    fetchCircuitList,
    areas,
    couponAvailableAreas,
    missionAvailableAreas,
    getTargetAvailableArea,
    clearAvailableAreas,
  }
}
