import Vue from 'vue'
import { computed, reactive } from '@vue/composition-api'
import { orderBy, uniq } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import HighlightCommentDocument, {
  EventType,
} from '@/store/stores/collectionModule/documents/highlight/HighlightCommentDocument'
import usePermission from '@/components/hook/usePermission'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'

/**
 * ハイライトコメントを操作するための処理を取得する。
 */
export default function useHighlightComment() {
  const state = reactive({
    highlightCommentCountByHighlightId: {} as Record<string, number>,
    lastHighlightCommentFetchedDateByHighlightId: {} as Record<string, number>,
  })

  // Collection modules
  const highlightCommentCollectionModule = CollectionModule.createStore(HighlightCommentDocument)
  const { hasPermission } = usePermission()

  /**
   * 取得したハイライトコメント情報一覧
   */
  const highlightComments = computed(() => {
    const blockedUserIds =
      ContractInfoStore.value.ownOrganization.value?.communication?.blockedUserIds ?? []

    // ブロックしたユーザーが作成したコメントを除外する
    return orderBy(
      highlightCommentCollectionModule.data.filter(
        (highlightComment) =>
          !highlightComment.deleted &&
          !blockedUserIds.includes(highlightComment.additionalData?.userId ?? ''),
      ),
      '_createdDate',
    )
  })

  /**
   * ハイライトID毎に分類されたハイライトコメント情報
   */
  const commentsByHighlightId = computed(() => {
    const commentsMap: Record<string, Array<HighlightCommentDocument> | undefined> = {}
    highlightComments.value.forEach((comment) => {
      if (comment.highlightId && !comment.deleted) {
        if (commentsMap[comment.highlightId]) {
          commentsMap[comment.highlightId]?.push(comment)
        } else {
          commentsMap[comment.highlightId] = [comment]
        }
      }
    })
    return commentsMap
  })

  /**
   * commentIdに紐づくハイライトコメント
   */
  const targetHighlightComment = (commentId: string) =>
    highlightComments.value.find((highlightComment) => highlightComment.commentId === commentId)

  /**
   * 取得したハイライトコメントのユーザーIDの配列
   */
  const highlightCommentUserIds = computed(() =>
    uniq(
      highlightComments.value
        .map((v: HighlightCommentDocument) => v.additionalData?.userId || '')
        .filter((v) => v !== ''),
    ),
  )

  /**
   * 対象レース全ハイライトのコメント情報一覧を取得する。
   * @param filterMatchId ハイライトコメント情報を取得する対象のレースのID
   * @param lastModifiedDate 前回のハイライトコメント情報取得日時。指定した場合、この日時以降に登録、更新、削除されたデータのみを再取得する
   * @param isSaveInStore fetch時にfetchの結果をCollectionModuleに保存するかどうか
   * @param limit 取得上限件数の指定
   */
  const fetchHighlightComments = (
    filterMatchId: string,
    lastModifiedDate?: number,
    isSaveInStore = true,
    limit?: number,
  ) => {
    const query = {
      filter: {
        matchId: filterMatchId,
      },
      sort: '_createdDate',
    } as {
      filter: {
        matchId: string
        _lastModifiedDate?: {
          $gte: number
        }
      }
      sort: string
      limit?: number
    }
    if (lastModifiedDate) {
      // 引数で指定された日時以降に追加・更新されたハイライトコメント情報のみを取得する
      query.filter._lastModifiedDate = {
        $gte: lastModifiedDate,
      }
    }

    if (limit) {
      query.limit = limit
    }

    return highlightCommentCollectionModule.fetch({
      query,
      isSaveInStore,
      isUnionExistData: !!lastModifiedDate,
      includeDeleted: true,
    })
  }

  /**
   * 対象のハイライトIDに紐づくコメント一覧を差分取得する
   * 差分取得の内容：前回取得日時以降の差分
   * @param highlightId 対象のハイライトID
   * @param isSaveInStore fetch時にfetchの結果をCollectionModuleに保存するかどうか
   * @param limit 取得上限件数の指定
   */
  const fetchDiffTargetHighlightComments = async (
    highlightId: string,
    isSaveInStore = true,
    limit?: number,
  ) => {
    const query = {
      filter: {
        eventId: highlightId,
        eventType: 'USER_EVENT',
      },
      sort: '_createdDate',
    } as {
      filter: {
        eventId: string
        eventType: EventType
        _lastModifiedDate?: {
          $gte: number
        }
      }
      sort: string
      limit?: number
    }

    const lastFetchedDate = state.lastHighlightCommentFetchedDateByHighlightId[highlightId]

    if (lastFetchedDate) {
      // 最後にハイライトコメント情報を取得した日時以降に追加・更新されたハイライトコメント情報のみを取得する
      query.filter._lastModifiedDate = {
        $gte: lastFetchedDate,
      }
    }

    if (limit) {
      query.limit = limit
    }

    const lastHighlightCommentsFetchedDate = new Date().getTime()

    const result = await highlightCommentCollectionModule.fetch({
      query,
      isSaveInStore,
      isUnionExistData: true,
      includeDeleted: true,
      includePublicScope: true,
    })

    if (result.isSuccess)
      state.lastHighlightCommentFetchedDateByHighlightId[highlightId] =
        lastHighlightCommentsFetchedDate

    return result
  }

  /**
   * 新着ハイライトコメント情報一覧を取得する。
   */
  const fetchRecentHighlightComments = (limit?: number) =>
    highlightCommentCollectionModule.fetch({
      query: {
        filter: {
          _organization: { $ne: process.env.VUE_APP_SFGO_PARENT_ORG_ID },
        },
        limit: limit ?? 10,
        sort: '-_createdDate',
      },
      includeDeleted: false,
      includePublicScope: true,
    })

  /**
   * 対象ハイライトIDにマッチする新着ハイライトコメント情報を取得する
   */
  const fetchTargetRecentHighlightComments = (
    highlightId: string,
    isUnionExistData: boolean,
    limit?: number,
  ) =>
    highlightCommentCollectionModule.fetch({
      query: {
        filter: {
          eventId: highlightId,
          _organization: { $ne: process.env.VUE_APP_SFGO_PARENT_ORG_ID },
        },
        limit: limit ?? 10,
        sort: '-_createdDate',
        count: true,
      },
      isUnionExistData,
      includePublicScope: true,
    })

  /**
   * ハイライトコメント登録/更新 リクエストデータ作成
   * - publicScopeの設定：shareCommentの権限を持っているかつハイライトが共有されているもしくは公式ハイライトなら親組織を設定する
   * @param comment 入力したコメント
   * @param raceId レースID
   * @param highlight ハイライト
   * @param userId ユーザーID
   * @return HighlightCommentDocument
   */
  const createHighlightCommentRequestData = (
    comment: string,
    raceId: string,
    highlight: HighlightDocument,
    userId: string,
  ) =>
    new HighlightCommentDocument({
      comment,
      matchId: raceId,
      eventId: highlight.highlightId,
      publicScope:
        hasPermission('shareComment') && highlight.isShared
          ? {
              parentOrganization: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
            }
          : null,
      additionalData: {
        userId,
      },
    })

  /**
   * 対象のハイライトのコメント数を取得する
   */
  const fetchHighlightCommentCount = async (highlightId: string) => {
    const blockedUserIds =
      ContractInfoStore.value.ownOrganization.value?.communication?.blockedUserIds ?? []

    const options = {
      query: {
        filter: {
          eventId: highlightId,
          _organization: { $ne: process.env.VUE_APP_SFGO_PARENT_ORG_ID },
        },
        count: true,
      },
      isSaveInStore: false,
      includePublicScope: true,
    }

    const [result, blockedCommentResults] = await Promise.all([
      // 対象ハイライトのコメント数を取得
      highlightCommentCollectionModule.fetch(options),
      // 対象ハイライトのブロックしたユーザーのコメント数を取得
      highlightCommentCollectionModule.fetchSplitFilterIn(
        options,
        1,
        100,
        '_createdBy',
        blockedUserIds,
        false,
      ),
    ])

    if (result.isSuccess && blockedCommentResults.every((r) => r.isSuccess)) {
      const totalCount = result.count ?? 0
      const blockedTotalCount = blockedCommentResults.reduce(
        (sum, comment) => sum + (comment.count ?? 0),
        0,
      )
      // 対象ハイライトの全コメント数 - ブロックユーザーのコメント数
      const displayCommentCount = totalCount - blockedTotalCount

      Vue.set(state.highlightCommentCountByHighlightId, highlightId, displayCommentCount)
    }

    return [result, ...blockedCommentResults]
  }

  const highlightCommentCountByHighlightId = computed(
    () => state.highlightCommentCountByHighlightId,
  )

  /**
   * ハイライトコメント登録/更新
   * @param requestData ハイライトコメント情報
   */
  const saveHighlightComment = (requestData: HighlightCommentDocument) =>
    highlightCommentCollectionModule.save(requestData)

  /**
   * ハイライトコメント削除
   * 定期的に差分取得を行う際、コメント削除の判定ができるように論理削除とする
   * @param commentId コメントId
   * @return APIレスポンス
   */
  const removeHighlightComment = (commentId: string) =>
    highlightCommentCollectionModule.remove(commentId, {
      isSoftDelete: true,
    })

  /**
   * 取得したハイライトコメント情報をクリアする
   */
  const clearHighlightComments = () => {
    highlightCommentCollectionModule.clearData()
    state.highlightCommentCountByHighlightId = {}
    state.lastHighlightCommentFetchedDateByHighlightId = {}
  }

  return {
    highlightComments,
    commentsByHighlightId,
    targetHighlightComment,
    fetchHighlightComments,
    fetchDiffTargetHighlightComments,
    fetchRecentHighlightComments,
    fetchTargetRecentHighlightComments,
    highlightCommentUserIds,
    createHighlightCommentRequestData,
    saveHighlightComment,
    removeHighlightComment,
    clearHighlightComments,
    fetchHighlightCommentCount,
    highlightCommentCountByHighlightId,
  }
}
