var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-video-ranking-pane",
      class: "race-video-ranking-pane--" + _vm.screenOrientationType,
    },
    [
      _c("race-player-info-section", {
        attrs: {
          radioVoiceEnabled: _vm.radioVoiceEnabled,
          raceSelectEnabled: _vm.raceSelectEnabled,
          screenOrientationType: _vm.screenOrientationType,
          favoriteRacePlayerSelected: _vm.favoriteRacePlayerSelected,
        },
        on: {
          radioVoiceToggle: _vm.toggleRadioVoice,
          raceSelectToggle: _vm.toggleRaceSelect,
          playerChange: _vm.showPlayerSelectModal,
        },
      }),
      _c("ranking-setting-section", {
        attrs: {
          flagType: _vm.getCurrentFlagStatus,
          leader: _vm.isLeaderFlagChecked,
          rankingViewMode: _vm.rankingViewMode,
          rankingViewModeList: _vm.rankingViewModeList,
          screenOrientationType: _vm.screenOrientationType,
        },
        on: {
          rankingViewModeChange: _vm.changeRankingViewMode,
          leaderToggle: _vm.toggleLeader,
        },
      }),
      _c("ranking-list-section", {
        staticClass: "race-video-ranking-pane__list",
        attrs: {
          lockOrientation: _vm.lockOrientation,
          rankingViewMode: _vm.rankingViewMode,
          rankingData: _vm.getCurrentPlayerRankingDataList,
          selectedID: _vm.selectedID,
          leader: _vm.isLeaderFlagChecked,
          battleData: _vm.getCurrentBattleData,
          screenOrientationType: _vm.screenOrientationType,
          "player-telemetry": _vm.getCurrentPlayerTelemetry,
          "player-radio-data": _vm.getCurrentPlayerRadioDataSetByPlayer,
        },
      }),
      _c("player-select-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.racePlayerSelectModal,
            expression: "racePlayerSelectModal",
          },
        ],
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          favoriteRacePlayerSelected: _vm.favoriteRacePlayerSelected,
        },
        on: { close: _vm.changePlayer },
      }),
      _c("race-select-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.raceSelectEnabled,
            expression: "raceSelectEnabled",
          },
        ],
        attrs: { screenOrientationType: _vm.screenOrientationType },
        on: {
          raceSelectToggle: _vm.toggleRaceSelect,
          changeScenes: _vm.changeScenes,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }