






















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageMailChangeCompletePane from '@/components/MypageMailChangeCompletePage/MypageMailChangeCompletePane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'

/**
 * マイページ: メールアドレス変更変更完了ページ
 */
export default defineComponent({
  name: 'MypageMailChangeCompletePage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageMailChangeCompletePane,
    ActionButtonsSection,
    AtomLinkButton,
  },
  setup() {
    const mypageUrl = `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`

    return {
      mypageUrl,
    }
  },
})
