import StoreUtil from '@/store/StoreUtil'
import APIResponse from '@/util/APIResponse'

/**
 * ワンタイムパス登録画面の機能を提供する。
 */
export default function useOneTimePassContract() {
  const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')

  /**
   * 契約情報契約プランを作成する。（ワンタイムパス用の処理）
   */
  const createContractInfoPlan = async (contractInfoId: string) => {
    /** ワンタイムパスを使って契約情報契約プランを作成 */
    const oneTimePass = oneTimePassPageStore.coupons.value[0]
    return oneTimePassPageStore.saveContractInfoPlanCoupon(
      contractInfoId,
      oneTimePass.couponId as string,
      oneTimePass.isSettingNumberOfDays,
    )

    // 無料プランに終了日がセットされるので、現在契約中のプランを終了する処理は不要
  }

  /**
   * 契約情報契約プランを登録。（ワンタイムパス用の処理）
   */
  const createOneTimePassContract = async (contractInfoId: string | null) => {
    if (!contractInfoId) {
      // 契約情報IDが存在しない場合、処理しない
      return { isSuccess: false, response: {} as APIResponse }
    }

    /** 契約情報契約プランを作成する */
    return createContractInfoPlan(contractInfoId)
  }

  return {
    createOneTimePassContract,
  }
}
