



























import { defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import FieldsetPasswordChangeParts from '@/components/MypageUserEditPage/MypageUserEditPane/parts/FieldsetPasswordChangeParts.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import usePasswordChangeToken from '@/store/hook/usePasswordChangeToken'
import StoreUtil from '@/store/StoreUtil'

interface newPasswordData {
  old: string
  new: string
  reEnter: string
}

export type DataType = {
  newPassword: newPasswordData
  showOldPasswordError: boolean
  showNewPasswordError: boolean
  showNewPasswordReEnterError: boolean
  oldPasswordErrorMessage: string
  newPasswordErrorMessage: string
  newPasswordReEnterErrorMessage: string
}

/**
 * マイページ: 会員情報変更 パスワード変更モーダル
 */
export default defineComponent({
  name: 'PasswordChangeModalSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    FieldsetPasswordChangeParts,
    AtomInputButton,
  },
  setup() {
    const { register: _register } = usePasswordChangeToken()

    return {
      _register,
    }
  },
  data(): DataType {
    return {
      newPassword: {
        old: '', // 現在のパスワード
        new: '', // 新しいパスワード
        reEnter: '', // 新しいパスワード(再入力)
      },
      oldPasswordErrorMessage: '', // 現在のパスワードエラーメッセージ
      newPasswordErrorMessage: '', // 新しいパスワード エラーメッセージ
      newPasswordReEnterErrorMessage: '', // 新しいパスワード(確認用) エラーメッセージ
      showOldPasswordError: false, // 現在のパスワードエラー表示フラグ
      showNewPasswordError: false, // 新しいパスワード エラー表示フラグ
      showNewPasswordReEnterError: false, // 新しいパスワード(確認用) エラー表示フラグ
    }
  },
  methods: {
    emitClose(eventType: string): void {
      if (eventType === 'submit') {
        /**
         * パスワード変更処理
         */
        this.changePassword()
      } else {
        /**
         * モーダル閉じる通知
         */
        this.$emit('close')
      }
    },
    async changePassword() {
      this.showOldPasswordError = false
      this.showNewPasswordError = false
      this.showNewPasswordReEnterError = false
      /**
       * 現在のパスワードが正しいかチェック
       */
      const loginStore = StoreUtil.useStore('LoginStore')
      if (loginStore.password !== this.newPassword.old) {
        this.oldPasswordErrorMessage = this.$t('formParts.errorMessage.password') as string
        this.showOldPasswordError = true
      }
      /**
       * 新しいパスワードと新しいパスワード（再入力）が一致しているかチェック
       */
      if (this.newPassword.new !== this.newPassword.reEnter) {
        this.newPasswordReEnterErrorMessage = this.$t(
          'formParts.errorMessage.reEnterPassword',
        ) as string
        this.showNewPasswordReEnterError = true
      }
      /**
       * 新しいパスワードのバリデーション チェック
       */
      const regex = new RegExp(/^[a-zA-Z0-9-_.@!#$%&'*+/=?^`{|}~]{8,32}$/)
      if (!regex.test(this.newPassword.new)) {
        this.newPasswordErrorMessage = this.$t(
          'MypagePage.MypageUserEditPage.NewPasswordFormatError',
        ) as string
        this.showNewPasswordError = true
      }
      /**
       * パスワード変更処理
       */
      if (
        !this.showOldPasswordError &&
        !this.showNewPasswordError &&
        !this.showNewPasswordReEnterError
      ) {
        const loader = this.$loading.show()
        const apiResult = await this._register(this.newPassword.new)
        if (apiResult.isSuccess) {
          /**
           * 完了通知
           */
          this.$emit('close', 'complete')
        } else {
          /**
           * エラー通知
           */
          this.$emit('error')
        }
        loader.hide()
      }
    },
    disablePasswordChangeButtonFlag(): boolean {
      /**
       * パスワード未入力チェック
       */
      let rt = true
      if (
        this.newPassword.new !== '' &&
        this.newPassword.reEnter !== '' &&
        this.newPassword.old !== ''
      ) {
        rt = false
      }
      return rt
    },
  },
})
