import { computed, reactive } from '@vue/composition-api'
import dayjs from 'dayjs'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useCalendarData from '@/store/hook/calendar/useCalendarData'
import I18n from '@/locales/I18n'
import { CalendarDateSelectType } from '@/components/LinkPage/hooks/useCalendarSelectList'
import { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'

/**
 * 現在リストを表示しているプルダウンを表現する型
 */
export type OpenFilterIdType = '' | 'place' | 'category'

/**
 * 初期状態
 */
const initialState = {
  /** 選択中の年（年月） */
  selectedYearOrMonth: {} as CalendarDateSelectType,
  /** 現在リストを表示しているプルダウン */
  openFilterId: '' as OpenFilterIdType,
  /** 選択中の場所リスト */
  selectedPlaceOptions: [] as DropDownDataType[],
  /** 選択中のカテゴリーリスト */
  selectedCategoryOptions: [] as DropDownDataType[],
}

/* eslint-disable class-methods-use-this */
/**
 * カレンダー画面のStore
 */
class CalendarPageStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    // hook
    const { fetchCalendarData, calendarData, clearCalendarData } = useCalendarData()

    /**
     * 選択中の年（年月）
     */
    const selectedYearOrMonth = computed({
      get: () => state.selectedYearOrMonth,
      set: (yearOrMonth: CalendarDateSelectType) => {
        state.selectedYearOrMonth = yearOrMonth
      },
    })

    /**
     * 現在リストを表示しているプルダウン
     */
    const openFilterId = computed({
      get: () => state.openFilterId,
      set: (filterId: OpenFilterIdType) => {
        state.openFilterId = filterId
      },
    })

    /**
     * 選択中の場所リスト
     */
    const selectedPlaceOptions = computed({
      get: () => state.selectedPlaceOptions,
      set: (selectedPlaces: DropDownDataType[]) => {
        state.selectedPlaceOptions = selectedPlaces
      },
    })

    /**
     * 選択中のカテゴリーリスト
     */
    const selectedCategoryOptions = computed({
      get: () => state.selectedCategoryOptions,
      set: (selectedCategories: DropDownDataType[]) => {
        state.selectedCategoryOptions = selectedCategories
      },
    })

    /**
     * 画面に表示するカレンダー一覧
     */
    const filteredCalendarData = computed(() =>
      calendarData.value.filter((calendar) => {
        // 場所にマッチしているカレンダーを探す。場所を選択していない場合は全場所のカレンダーを表示する。
        const matchedPlace =
          selectedPlaceOptions.value.length > 0
            ? selectedPlaceOptions.value.some((place) => place.name === calendar.placeName)
            : true
        if (!matchedPlace) {
          return false
        }

        // カテゴリにマッチしているカレンダーを探す。カテゴリを選択していない場合は全カテゴリのカレンダーを表示する。
        const matchedCategory =
          selectedCategoryOptions.value.length > 0
            ? selectedCategoryOptions.value.some((place) => place.name === calendar.categoryName)
            : true
        if (!matchedCategory) {
          return false
        }

        if (selectedYearOrMonth.value.type === 'yearAndMonths') {
          // 対象年月のカレンダーのみ表示する
          // 言語設定日本語の場合に表示しているdayjsで、dayjsで対象の値を年月に変換できるよう、「.」を「/」に変換する
          const convertedYearOrMonth =
            I18n.locale === 'ja'
              ? selectedYearOrMonth.value.value.replace('.', '/')
              : selectedYearOrMonth.value.value
          return (
            dayjs(convertedYearOrMonth).format('YYYY/MM') ===
            dayjs(calendar.eventDate).format('YYYY/MM')
          )
        }

        // 年のみでフィルターしている場合は場所、カテゴリーにマッチしたカレンダーを全て表示する
        return true
      }),
    )

    /**
     * モータースポーツカレンダー情報を取得
     */
    const fetchCalendarPageData = async (selectedYear: number) => {
      const fromDate = dayjs.tz(dayjs().year(selectedYear), 'Asia/Tokyo').startOf('year').valueOf()
      const toDate = dayjs.tz(dayjs().year(selectedYear), 'Asia/Tokyo').endOf('year').valueOf()

      await fetchCalendarData(fromDate, toDate)
    }

    /**
     * カレンダー画面のデータをクリアする
     */
    const clearCalendarPageData = () => {
      clearCalendarData()
      Object.assign(state, initialState)
    }

    return {
      // useCalendarData
      calendarData,
      // Store
      filteredCalendarData,
      selectedYearOrMonth,
      openFilterId,
      selectedPlaceOptions,
      selectedCategoryOptions,
      fetchCalendarPageData,
      clearCalendarPageData,
    }
  }
}

const value: ValueType<CalendarPageStore> = {}

export default {
  createStore: new CalendarPageStore().createStore,
  value: value as Required<typeof value>,
}
