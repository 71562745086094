var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-ticket-today-card-section",
      on: { click: _vm.handleToggleClicked },
    },
    [
      _c(
        "div",
        {
          staticClass: "mypage-ticket-today-card-section__toggle",
          class: {
            "mypage-ticket-today-card-section__toggle--active":
              _vm.activeCardId === _vm.ticketType.ticketTypeId,
          },
        },
        [
          _c(
            "p",
            { staticClass: "mypage-ticket-today-card-section__toggle-name" },
            [
              _c(
                "span",
                {
                  staticClass: "mypage-ticket-today-card-section__toggle-label",
                },
                [_vm._v(_vm._s(_vm.ticketType.ticketTypeName))]
              ),
              _c(
                "span",
                {
                  staticClass: "mypage-ticket-today-card-section__toggle-icon",
                },
                [_c("mypage-ticket-toggle-icon-parts")],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.activeCardId === _vm.ticketType.ticketTypeId
        ? _c(
            "div",
            { staticClass: "mypage-ticket-today-card-section__body" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }