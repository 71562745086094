



















import { defineComponent, PropType } from '@vue/composition-api'
import MissionRankingTableItemParts from '@/components/MissionPage/MissionRankingPane/parts/MissionRankingTableItemParts.vue'
import { RankInUserType } from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import { RankingType } from '@/store/stores/pageStore/MissionPage/MissionPageStore'

export default defineComponent({
  name: 'MissionRankingSelfNotRankedParts',
  components: {
    MissionRankingTableItemParts,
  },
  props: {
    selfData: {
      type: Object as PropType<RankInUserType>,
      required: true,
    },
    isOwnRank: {
      type: Boolean,
      default: false,
    },
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
    rankingType: {
      type: String as PropType<RankingType>,
      default: 'TOTAL',
    },
  },
  methods: {
    onClickSnsButton() {
      this.$emit('onClickSnsButton')
    },
    onClickShareIcon() {
      this.$emit('onClickShareIcon')
    },
  },
})
