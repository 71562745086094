import dayjs from 'dayjs'
import Const from '@/util/Const'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual, AlertMessages } from '@/store/stores/collectionModule/documents/GeneralTypes'
import i18n from '@/locales/I18n'

/**
 * コースの型
 */
export type CourseType = keyof typeof Const.IMAGE_PATH

/**
 * レースの大会情報を表現するクラス。
 */
export default class ChampionshipDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ChampionshipDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/championship'

  idAttr = 'championshipMasterId'

  /**
   * 大会情報の追加項目
   */
  additionalData?: {
    /**
     * 大会が行われるサーキット
     */
    course: CourseType
    /**
     * 大会のラウンド数
     */
    round: number
    /**
     * アラートメッセージ一覧
     */
    alertMessages: Array<AlertMessages>
    /**
     * 動画配信開始時間の調整処理を無効にするかどうか
     * false に設定された場合、Const.RECORDING_START_ADJUST_TIME の値を利用して、配信開始時間の調整が実施される。
     */
    disableAdjustRecordingStartTime: boolean
  }

  /**
   * 大会名
   */
  championshipName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * リーグ名
   */
  leagueName?: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 大会説明
   */
  description?: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 大会ロゴ画像
   */
  championshipLogo?: string

  /**
   * 開催期間開始日時
   * @type {Date | null}
   */
  startDate: Date | null = null

  /**
   * 開催期間終了日時
   * @type {Date | null}
   */
  endDate: Date | null = null

  /**
   * ラウンド番号
   */
  get round() {
    return this.additionalData?.round
  }

  /**
   * 大会が実施されるサーキット
   * @return サーキット
   */
  get course(): CourseType | undefined {
    return this?.additionalData?.course
  }

  /**
   * コース画像パス
   */
  get courseImg() {
    switch (this.course) {
      case 'FUJI_SPEEDWAY':
        return Const.IMAGE_PATH.FUJI_SPEEDWAY
      case 'AUTOPOLIS':
        return Const.IMAGE_PATH.AUTOPOLIS
      case 'SPORTSLAND_SUGO':
        return Const.IMAGE_PATH.SPORTSLAND_SUGO
      case 'SUZUKA_CIRCUIT':
        return Const.IMAGE_PATH.SUZUKA_CIRCUIT
      case 'TWIN_RING_MOTEGI':
        return Const.IMAGE_PATH.TWIN_RING_MOTEGI
      default:
        return ''
    }
  }

  /**
   * サーキット名
   * // 大会マスタにサーキット情報を登録できるように改修後、対象フィールドを変更する
   */
  get circuitName() {
    return this.championshipName
  }

  /**
   * 開始時間終了時間
   */
  get raceDate() {
    if (this.startDate && this.endDate) {
      const start =
        i18n.locale === 'ja'
          ? dayjs(this.startDate, 'x').format('YYYY/M/D')
          : dayjs(this.startDate, 'x').format('MMM. DD, YYYY')
      const end =
        i18n.locale === 'ja'
          ? dayjs(this.endDate, 'x').format('M/D')
          : dayjs(this.endDate, 'x').format('MMM. DD')
      return `${start} - ${end}`
    }
    return ''
  }

  /**
   * 開始時間終了時間 (レースカード用)
   * 英語の場合は年度を表示しない
   */
  get raceDateForRaceCard() {
    if (this.startDate && this.endDate) {
      const start =
        i18n.locale === 'ja'
          ? dayjs(this.startDate, 'x').format('YYYY/M/D')
          : dayjs(this.startDate, 'x').format('MMM. DD')
      const end =
        i18n.locale === 'ja'
          ? dayjs(this.endDate, 'x').format('M/D')
          : dayjs(this.endDate, 'x').format('MMM. DD')
      return `${start} - ${end}`
    }
    return ''
  }
}
