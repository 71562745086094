






















import { computed, defineComponent } from '@vue/composition-api'
import AtomInputEMail from '@/components/atoms/input/AtomInputEMail.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

/**
 * フィールドセット Email入力 バーツ
 */
export default defineComponent({
  name: 'FieldsetEmailParts',
  components: {
    AtomInputEMail,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: true,
    },
    /**
     * リードオンリーフラグ
     */
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * EMAIL
     * @param {string} val 入力中のメールアドレス
     */
    const email = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    return {
      email,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
