<template>
  <p class="mission-ranking-end-date-parts">
    <span class="mission-ranking-end-date-parts__date">{{ endDate }}</span>
    <span class="mission-ranking-end-date-parts__label">{{
      $tc('MissionPage.RankingPage.postingEnd')
    }}</span>
  </p>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionRankingEndDateParts',
  props: {
    endDate: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.mission-ranking-end-date-parts {
  font-size: 12px;
  font-weight: 500;
  color: $white;
  letter-spacing: 0.02em;
  line-height: 1.67;
  text-align: center;

  &__label {
    margin-left: 0.5em;
  }
}
</style>
