import { AxiosError, AxiosResponse } from 'axios'
import SignedCookie, { SignedCookiePath } from '@/@types/SignedCookie'

/**
 * エラーコード
 * 0はネットワークアクセスエラーを示す
 */
export const errorCodes = [0, 400, 401, 409, 500, 404, 503] as const

/**
 * AxiosResponse型か判定するためのtype guard
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAxiosResponse = (arg: any): arg is AxiosResponse => arg?.status != null
/**
 * AxiosError型か判定するためのtype guard
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAxiosError = (arg: any): arg is AxiosError => arg.isAxiosError
/**
 * AxiosResponseとAxiosErrorのInterface間の差異をなくし、
 * いくつかのgetterを追加したクラス
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class APIResponse<T = any> {
  /**
   * APIが返却するエラーコードの定義
   */
  static readonly ERROR_CODE = {
    /** 同時ログイン上限数を超えた */
    SESSION_LIMIT_EXCEEDED: '401_0002',
  }

  /**
   * ステータスコード
   * ネットワークエラーの場合は0になる
   */
  readonly status: number

  /**
   * レスポンスボディ
   */
  readonly data?: T

  /**
   * ネットワークエラーかどうか
   */
  readonly isNetworkError: boolean

  /**
   * コンフリクトエラーかどうか
   */
  readonly isConflict: boolean

  /**
   * リクエストが成功したかどうか
   */
  readonly isSuccess: boolean

  /**
   * レスポンスヘッダー
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly headers: any

  constructor(axiosResponse: AxiosResponse<T> | AxiosError<T> | undefined) {
    if (isAxiosResponse(axiosResponse)) {
      this.isSuccess = true
      this.isNetworkError = false
      this.isConflict = false
      this.status = axiosResponse.status
      this.data = axiosResponse.data
      this.headers = axiosResponse.headers
    } else {
      this.isSuccess = false
      this.status = axiosResponse?.response?.status ?? 0
      this.isNetworkError = this.status === 0
      this.isConflict = this.status === 409
      this.data = axiosResponse?.response?.data
      this.headers = axiosResponse?.response?.headers
    }
  }

  /**
   * レスポンス情報からCookie情報を取得する。
   * @param response API呼び出し結果
   * @return Cookie情報
   */
  public static getCookies(response: AxiosResponse): {
    [key in SignedCookiePath]: Array<SignedCookie>
  } {
    const authCookies = {} as { [key in SignedCookiePath]: Array<SignedCookie> }
    const rawCookieHeaders = response.headers['x-flux-set-cookie']
    if (rawCookieHeaders) {
      const cookieHeaders = rawCookieHeaders.split(',')
      cookieHeaders.forEach((cookieHeader) => {
        const headerValueMap = {} as SignedCookie
        const headerValues = cookieHeader.split(';')

        headerValues.forEach((headerValue) => {
          const headerKeyValue = headerValue.split('=')
          if (headerKeyValue[0]) {
            const key = headerKeyValue[0].trim() as keyof SignedCookie
            headerValueMap[key] = headerKeyValue[1]
          }
        })
        const path = headerValueMap.Path as SignedCookiePath
        if (authCookies[path] === undefined) {
          authCookies[path] = []
        }
        authCookies[path].push(headerValueMap)
      })
    }
    return authCookies
  }

  /**
   * レスポンス情報から Path属性が /movie の署名Cookieを取得する。
   * @param response API呼び出し結果
   * @return Path属性が /movie の署名Cookie
   */
  public static getMovieCookies(response: AxiosResponse): Array<SignedCookie> {
    const cookies = APIResponse.getCookies(response)
    return cookies && cookies['/movies'] ? cookies['/movies'] : []
  }

  /**
   * レスポンス情報から Path属性が /files の署名Cookieを取得する。
   * @param response API呼び出し結果
   * @return Path属性が /files の署名Cookie
   */
  public static getFileCookies(response: AxiosResponse): Array<SignedCookie> {
    const cookies = APIResponse.getCookies(response)
    return cookies && cookies['/files'] ? cookies['/files'] : []
  }

  /**
   * レスポンス情報から Path属性が /img の署名Cookieを取得する。
   * @param response API呼び出し結果
   * @return Path属性が /img の署名Cookie
   */
  public static getImageCookies(response: AxiosResponse): Array<SignedCookie> {
    const cookies = APIResponse.getCookies(response)
    return cookies && cookies['/img'] ? cookies['/img'] : []
  }

  /**
   * レスポンス情報から Path属性が /audios の署名Cookieを取得する。
   * @param response API呼び出し結果
   * @return Path属性が /audios の署名Cookie
   */
  public static getAudiosCookies(response: AxiosResponse): Array<SignedCookie> {
    const cookies = APIResponse.getCookies(response)
    return cookies && cookies['/audios'] ? cookies['/audios'] : []
  }
}

/**
 * エラーコード用の型定義
 */
export type ErrorCodeType = {
  description: string
  error_code: string
  payment_service?: {
    decline_code: string
    error_code: string
    message: string
  }
  status: number
}
