




















































import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  Ref,
  watch,
} from '@vue/composition-api'
import HighlightItemSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightItemSection.vue'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import PlayEventDocument from '@/store/stores/collectionModule/documents/highlight/PlayEventDocument'
import StoreUtil from '@/store/StoreUtil'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import useCommunication, {
  CommentDataType,
  ParentCommentDataType,
} from '@/components/RaceVideoPage/hook/useCommunication'
import HighlightListTabParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightListTabParts.vue'
import { HighlightTabsType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import { ClickedLikeDataType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/LikeButtonParts.vue'
import SceneMoviesDocument from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'
import { HighlightSlideMenuType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightsSlideMenuParts.vue'

const prefixHighlightItemSectionId = 'highlightItemSection'
/**
 * レース動画再生画面 ハイライト表示 コンテンツセクションのコンポーネント
 */
export default defineComponent({
  name: 'HighlightContentsSection',
  components: {
    HighlightListTabParts,
    HighlightItemSection,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選択中のハイライトID
     */
    current: {
      type: String as () => string | null,
      default: null,
    },
    currentPlayer: {
      type: String as () => string | null,
      default: null,
    },
    highlightList: {
      type: Array as PropType<Array<HighlightDocument>>,
      default: () => [],
    },
    playEventList: {
      type: Array as PropType<Array<PlayEventDocument>>,
      default: () => [],
    },
    playerList: {
      type: Array as PropType<Array<PlayerDocument>>,
      default: () => [],
    },
    /**
     * ハイライトのコメント入力状態（ハイライト毎に入力状態を保持する）
     */
    enabledInputsMap: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    /**
     * ハイライトに対する入力コメント（ハイライト毎に入力内容を保持する）
     */
    postingHighlightCommentsMap: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    /**
     * ハイライトのコメント編集状態（コメント毎に入力状態を保持する）
     */
    editModesMap: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    /**
     * ハイライトのメインコメント編集状態（ハイライト毎に入力状態を保持する）
     */
    editModesMainCommentMap: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    /**
     * ハイライトコメントを入力するために動画再生画面に遷移してきたか
     */
    inputComment: {
      type: String,
      default: '',
    },
    /**
     * ハイライトコメントしたユーザーのマップ
     */
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
    /**
     * ハイライトID
     * 遷移先の画面から戻ってきた時に対象のハイライトにスクロールさせるために利用する
     */
    scrollToHighlightId: {
      type: String,
      default: '',
    },
    /**
     * 動画集
     */
    sceneMoviesByHighlightId: {
      type: Object as PropType<Record<string, SceneMoviesDocument>>,
      default: () => ({}),
    },
    /**
     * 対象のハイライトのコメントの数
     */
    highlightCommentCountByHighlightId: {
      type: Object as PropType<Record<string, number>>,
      default: () => ({}),
    },
    /**
     * ハイライトのフィルタ条件：ドライバー
     */
    driverFilterId: {
      type: String,
      default: '',
    },
    /**
     * ハイライトのフィルタ条件：シーン
     */
    sceneFilterId: {
      type: String,
      default: '',
    },
    /**
     * ハイライトのフィルタ条件：ユーザー
     */
    userFilterId: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const state = reactive({
      acquiredFetchCommentForHighlightIdList: new Set<string>(),
    })

    const loginStore = StoreUtil.useStore('LoginStore')
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { highlightCommentDataById } = useCommunication()

    // 現在選択されているハイライト情報のID
    const selectedHighlightId = computed(() => props.current)
    const selectedPlayerMasterId = computed(() => props.currentPlayer)

    const playEvents = computed(() => props.playEventList)
    const players = computed(() => props.playerList)
    const playEventIds = computed(() => props.playEventList.map((playEvent) => playEvent.id))
    const highlightListExcludeHiddenFlag = computed(() =>
      props.highlightList.filter(
        (highlight) =>
          highlight.isCreatedBySfgoUser || playEventIds.value.includes(highlight.playEventId),
      ),
    )

    // ハイライトコンテンツセクションの参照
    const highlightContentsSectionRef = ref<HTMLElement | null>(null)

    /**
     * 該当コメントまでスクロールする
     * @param commentID コメントのID
     */
    const scrollToComment = (commentID: string) => {
      const comment = document.querySelector(`[id="${commentID}"]`) as HTMLElement
      const container = highlightContentsSectionRef.value as HTMLElement
      if (comment && container) {
        /**
         * モバイル時キーボードが開ききるタイミングによって
         * ズレが生じるため 0.1秒後にスクロールする
         */
        setTimeout(() => {
          container.scrollTo({
            top: comment.getBoundingClientRect().top + container.scrollTop - container.offsetTop,
            behavior: 'smooth',
          })
        }, 100)
      }
    }

    /**
     * 該当ハイライトまでスクロールする
     * @param highlightID ハイライトのID
     */
    const scrollToHighlight = (highlightID: string) => {
      const highlight = document.querySelector(`[id="${highlightID}"]`) as HTMLElement
      const container = highlightContentsSectionRef.value as HTMLElement
      if (highlight && container) {
        setTimeout(() => {
          container.scrollTo({
            top: highlight.getBoundingClientRect().top + container.scrollTop - container.offsetTop,
            behavior: 'smooth',
          })
        }, 100)
      }
    }

    const handleOpenMenuClicked = (
      comment: ParentCommentDataType,
      slideMenu: HighlightSlideMenuType,
    ) => {
      context.emit('onClickOpenMenu', comment, slideMenu)
    }

    /**
     * ハイライトタブを切り替える
     * @param tabId 選択肢したハイライトタブのID
     */
    const toggleHighlightTabs = (tabId: HighlightTabsType) => {
      raceVideoPageStore.selectedHighlightTab.value = tabId
    }
    /**
     * コメントのメニューボタンが押下されたときの処理
     */
    const handleCommentMenuClicked = (
      commentData: CommentDataType,
      slideMenu: HighlightSlideMenuType,
    ) => {
      context.emit('onClickCommentMenu', commentData, slideMenu)
    }

    // highlight-contents-section__list を再描画するために使用する
    const highlightContentSectionListRenderKey = ref(0)
    /**
     * highlightContentSectionListRenderKey をインクリメントして再描画する
     * タブ切り替えやフィルタによって一覧のハイライト表示が変わるとIntersection Observer APIが正しく発火しなくなるため、この処理を行う
     */
    const renderingHighlightContentSectionList = () => {
      highlightContentSectionListRenderKey.value += 1
    }

    /**
     * 「ハイライトタブのユーザー・公式タブ」・「ハイライトのフィルタ条件」の変更を監視
     */
    watch(
      () => [
        raceVideoPageStore.selectedHighlightTab.value,
        props.driverFilterId,
        props.sceneFilterId,
        props.userFilterId,
      ],
      () => {
        renderingHighlightContentSectionList()
      },
    )

    return {
      state,
      highlightContentSectionListRenderKey,
      selectedHighlightTab: raceVideoPageStore.selectedHighlightTab as Ref<HighlightTabsType>,
      highlightCommentDataById,
      selectedHighlightId,
      selectedPlayerMasterId,
      playEvents,
      players,
      loginUserId: loginStore.loginId as string,
      highlightContentsSectionRef,
      scrollToComment,
      highlightListExcludeHiddenFlag,
      handleOpenMenuClicked,
      toggleHighlightTabs,
      scrollToHighlight,
      handleCommentMenuClicked,
      prefixHighlightItemSectionId,
    }
  },
  methods: {
    /**
     * ハイライト選択切り替え
     * @param highlightId 選択されたハイライトID
     * @param isCreatedBySfgoUser 選択されたハイライトがユーザー作成のハイライトかどうか
     * @param playerId 選択された選手の選手マスタID
     */
    changeScene(highlightId: string, isCreatedBySfgoUser: boolean, playerId?: string): void {
      this.$emit('sceneChange', highlightId, isCreatedBySfgoUser, playerId)
    },
    /**
     * ハイライトコメント入力状態変更
     * @param enabledInput ハイライトコメント入力状態
     * @param highlightId コメント対象のハイライトID
     */
    onToggleEnabledInput(enabledInput: boolean, highlightId: string) {
      this.$emit('onToggleEnabledInput', enabledInput, highlightId)
    },
    /**
     * ハイライトコメント編集状態変更
     * @param isEditMode ハイライトコメント編集状態
     * @param commentId 編集対象のコメントID
     */
    onToggleIsEditMode(isEditMode: boolean, commentId: string) {
      this.$emit('onToggleIsEditMode', isEditMode, commentId)
    },
    /**
     * ハイライト メインコメント編集状態変更
     * @param isEditMode ハイライトメインコメント編集状態
     * @param commentId 編集対象のハイライトID
     */
    onToggleMainCommentIsEditMode(isEditMode: boolean, commentId: string) {
      this.$emit('onToggleMainCommentIsEditMode', isEditMode, commentId)
    },
    /**
     * 入力コメント変更
     * @param value 入力したハイライトコメント
     * @param highlightId コメント対象のハイライトID
     */
    onChangeHighlightComment(value: string, highlightId: string) {
      this.$emit('onChangeHighlightComment', value, highlightId)
    },
    /**
     * 入力コメント変更
     * @param value 入力したハイライトコメント
     * @param highlightId コメント対象のハイライトID
     */
    onChangeHighlightMainComment(value: string, highlightId: string) {
      this.$emit('onChangeHighlightMainComment', value, highlightId)
    },
    /**
     * コメント編集ボタン押下時の処理
     * @param editedComment 編集後のコメント
     * @param commentId 編集対象のコメントID
     */
    handlerEdit(editedComment: string, commentId: string) {
      this.$emit('handlerEdit', editedComment, commentId)
    },
    /**
     * コメント投稿ボタン押下時の処理
     * @param highlight コメント対象のハイライト
     */
    handlerSubmit(highlight: HighlightDocument) {
      this.$emit('handlerSubmit', highlight)
    },
    /**
     * コメントLIKE処理
     * @param likeData 押下されたいいねの情報
     */
    onChangeLike(likeData: ClickedLikeDataType) {
      this.$emit('onChangeLike', likeData)
    },
    /**
     * ダウンロード可能状態でユーザーがダウンロードボタンを押した際にファイルをダウンロードする
     */
    downloadHighlight(sceneMovies: SceneMoviesDocument | null) {
      this.$emit('downloadHighlight', sceneMovies)
    },
    /**
     * 対象のハイライトのコメント情報といいねを取得する
     */
    fetchDiffTargetHighlightCommentsAndReactions(highlightId: string) {
      this.$emit('fetchDiffTargetHighlightCommentsAndReactions', highlightId)
    },
    /**
     * 表示されているハイライトのコメント数といいねの数を取得する
     */
    fetchParentCommentCountAndReactions(isVisible: boolean, entry: IntersectionObserverEntry) {
      const highlightId = entry.target.id.replace(prefixHighlightItemSectionId, '')
      if (highlightId in this.highlightCommentCountByHighlightId) return
      if (isVisible) {
        this.state.acquiredFetchCommentForHighlightIdList.add(highlightId)
        setTimeout(() => {
          if (this.state.acquiredFetchCommentForHighlightIdList.has(highlightId)) {
            this.$emit('fetchParentCommentCountAndReactions', highlightId)
          }
        }, 2000)
      } else if (this.state.acquiredFetchCommentForHighlightIdList.has(highlightId)) {
        this.state.acquiredFetchCommentForHighlightIdList.delete(highlightId)
      }
    },
  },
})
