


































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export type CarouselListTypeData = 'video' | 'scene'

/**
 * HOME カルーセル付き リストWrapperパーツ
 */
export default defineComponent({
  name: 'CarouselListWrapperParts',
  components: {},
  props: {
    enabledCarouselList: {
      type: Boolean,
      default: false,
    },
    carouselListType: {
      type: String as PropType<CarouselListTypeData>,
      required: true,
    },
  },
  setup(props) {
    const enabledCarouselPrev = ref<boolean>(false)
    const enabledCarouselNext = ref<boolean>(false)

    const listCardWidth = computed(() => {
      switch (props.carouselListType) {
        case 'video':
          return -212
        case 'scene':
          return -170
        default:
          return -170
      }
    })

    const listPadding = computed(() => {
      switch (props.carouselListType) {
        case 'video':
          return 12
        case 'scene':
          return 6
        default:
          return 6
      }
    })

    return {
      enabledCarouselPrev,
      enabledCarouselNext,
      listCardWidth,
      listPadding,
    }
  },
  mounted() {
    // 読込時に表示判定
    setTimeout(() => this.checkCarouselEnabled(), 500)

    // リサイズ時に表示判定
    window.addEventListener('resize', this.checkCarouselEnabled)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkCarouselEnabled)
  },
  methods: {
    /**
     * カルーセルクリックアクション
     * @param type
     */
    onclickCarousel(type: 'prev' | 'next'): void {
      // スクロール量
      let scrollValue = this.listCardWidth

      // スクロールターゲット
      const sceneList = this.$refs.sceneListWrapperPartsContent as HTMLElement

      // nextの場合値をマイナス値へ
      scrollValue *= type === 'next' ? -1 : 1

      // スクロール実行
      sceneList.scroll({
        left: sceneList.scrollLeft + scrollValue,
        behavior: 'smooth',
      })
    },
    /**
     * ページャー有効化判定
     */
    checkCarouselEnabled() {
      const sceneList = this.$refs.sceneListWrapperPartsContent as HTMLElement

      this.enabledCarouselPrev = sceneList.scrollLeft > this.listPadding
      this.enabledCarouselNext =
        sceneList.scrollLeft < sceneList.scrollWidth - sceneList.clientWidth - this.listPadding
    },
  },
})
