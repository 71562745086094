var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "comparison-plan-table-parts" }, [
      _c("thead", { staticClass: "comparison-plan-table-parts__header" }, [
        _c("tr", [
          _c("th"),
          _c(
            "th",
            { staticClass: "comparison-plan-table-parts__header-free" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$tc("SignupPage.SelectPlanPage.comparison.free")) +
                  " "
              ),
            ]
          ),
          _c(
            "th",
            { staticClass: "comparison-plan-table-parts__header-paid" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("SignupPage.SelectPlanPage.comparison.membership")
                  ) +
                  " "
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "tbody",
        { staticClass: "comparison-plan-table-parts__list" },
        _vm._l(_vm.planData, function (item, index) {
          return _c("comparison-plan-table-item-parts", {
            key: "comparison-plan-item-parts" + index,
            attrs: {
              label: item.label,
              free: item.free,
              freeFootnote1: item.freeFootnote1,
              freeFootnote2: item.freeFootnote2,
              paid: item.paid,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }