var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "fieldset-checkbox-parts",
      class: { "fieldset-checkbox-parts--validation-error": _vm.showError },
    },
    [
      _c("atom-input-checkbox", {
        staticClass: "fieldset-checkbox-parts__input",
        attrs: {
          error: _vm.showError,
          isDisabled: _vm.isDisabled,
          color: _vm.color,
          value: _vm.checkValue,
        },
        on: { input: _vm.onChecked },
      }),
      _c("span", { staticClass: "fieldset-checkbox-parts__label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }