import TelemetryDocument from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'

/**
 * 全車両のテレメトリー情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3185803279/API
 */
export default class AllPlayerTelemetryDocument extends TelemetryDocument {
  constructor(initProps?: Partial<AllPlayerTelemetryDocument>) {
    super(initProps as Partial<TelemetryDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'data/record/telemetry/race'

  idAttr = 'raceId'
}
