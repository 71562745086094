var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-video-gps-pane",
      class: [
        "race-video-gps-pane--" + _vm.screenOrientationType,
        "race-video-gps-pane--mode-" + _vm.gpsViewMode,
      ],
    },
    [
      _c("race-player-info-section", {
        attrs: {
          radioVoiceEnabled: _vm.radioVoiceEnabled,
          raceSelectEnabled: _vm.raceSelectEnabled,
          screenOrientationType: _vm.screenOrientationType,
          favoriteRacePlayerSelected: _vm.favoriteRacePlayerSelected,
        },
        on: {
          radioVoiceToggle: _vm.toggleRadioVoice,
          raceSelectToggle: _vm.toggleRaceSelect,
          playerChange: _vm.showPlayerSelectModal,
        },
      }),
      _c(
        "div",
        { staticClass: "race-video-gps-pane__gps-content" },
        [
          _vm.gpsViewMode === "birdsEye"
            ? _c("gps-details-map-section", {
                staticClass: "race-video-gps-pane__map",
                attrs: {
                  gpsViewMode: _vm.gpsViewMode,
                  circuitName: _vm.circuitName,
                  circuitGpsPosition: _vm.circuitGpsPosition,
                  animateGpsMapCar: _vm.animateGpsMapCar,
                  mapMagnification: _vm.mapMagnification,
                  screenOrientationType: _vm.screenOrientationType,
                  lockOrientation: _vm.lockOrientation,
                },
              })
            : _vm._e(),
          _vm.gpsViewMode === "battle"
            ? _c("gps-details-map-section", {
                key: "race-video-gps-battle-map",
                staticClass: "race-video-gps-pane__map",
                attrs: {
                  gpsViewMode: _vm.gpsViewMode,
                  animateGpsMapCar: _vm.animateGpsMapCar,
                  circuitName: _vm.circuitName,
                  circuitGpsPosition: _vm.circuitGpsPosition,
                  mapMagnification: _vm.mapMagnification,
                  screenOrientationType: _vm.screenOrientationType,
                  lockOrientation: _vm.lockOrientation,
                },
              })
            : _vm._e(),
          _vm.gpsViewMode === "focus"
            ? _c("gps-details-map-section", {
                key: "race-video-gps-focus-map",
                staticClass: "race-video-gps-pane__map",
                attrs: {
                  gpsViewMode: _vm.gpsViewMode,
                  animateGpsMapCar: _vm.animateGpsMapCar,
                  circuitName: _vm.circuitName,
                  circuitGpsPosition: _vm.circuitGpsPosition,
                  mapMagnification: _vm.mapMagnification,
                  screenOrientationType: _vm.screenOrientationType,
                  lockOrientation: _vm.lockOrientation,
                },
              })
            : _vm._e(),
          !_vm.screenLandscape
            ? _c("map-scale-parts", {
                staticClass: "race-video-gps-pane__scale-gauge",
                attrs: {
                  gpsViewMode: _vm.gpsViewMode,
                  mapMagnification: _vm.mapMagnification,
                },
              })
            : _vm._e(),
          _c("gps-view-mode-selector-section", {
            staticClass: "race-video-gps-pane__mode-selector",
            attrs: {
              gpsViewMode: _vm.gpsViewMode,
              screenOrientationType: _vm.screenOrientationType,
            },
            on: { onclickSelector: _vm.onclickSelector },
          }),
          !_vm.screenLandscape
            ? _c("gps-ranking-section", {
                staticClass: "race-video-gps-pane__ranking",
                attrs: {
                  rankingData: _vm.getCurrentPlayerRankingDataList,
                  selectedID: _vm.selectedID,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("player-select-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.racePlayerSelectModal,
            expression: "racePlayerSelectModal",
          },
        ],
        staticClass: "race-video-gps-pane__player-select",
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          favoriteRacePlayerSelected: _vm.favoriteRacePlayerSelected,
        },
        on: { close: _vm.changePlayer },
      }),
      _c("race-select-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.raceSelectEnabled,
            expression: "raceSelectEnabled",
          },
        ],
        attrs: { screenOrientationType: _vm.screenOrientationType },
        on: {
          raceSelectToggle: _vm.toggleRaceSelect,
          changeScenes: _vm.changeScenes,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }