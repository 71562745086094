var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-link-card-parts", on: { click: _vm.emitClick } },
    [
      _c(
        "div",
        {
          staticClass: "video-link-card-parts__link",
          class: {
            "video-link-card-parts__link--disabled":
              _vm.videoLink.membership === "paid" && _vm.membership === "free",
          },
        },
        [
          _c("figure", [
            _c("img", {
              staticClass: "video-link-card-parts__img",
              attrs: { src: _vm.thumbnailPath, alt: "" },
            }),
          ]),
          _vm.videoLink.membership === "paid"
            ? _c("span", {
                staticClass: "video-link-card-parts__tag",
                domProps: {
                  textContent: _vm._s(
                    _vm.$tc("HomePage.limitedVideoMembershipTag")
                  ),
                },
              })
            : _vm._e(),
          _c("p", { staticClass: "video-link-card-parts__title" }, [
            _vm._v(" " + _vm._s(_vm.videoLink.title) + " "),
          ]),
          _c("div", { staticClass: "video-link-card-parts__meta" }, [
            _c("span", { staticClass: "video-link-card-parts__date" }, [
              _vm._v(_vm._s(_vm.videoLink.date)),
            ]),
          ]),
          _vm.premiumLabelEnabled
            ? _c("span", {
                staticClass: "video-link-card-parts__premium",
                domProps: {
                  textContent: _vm._s(
                    _vm.$tc("HomePage.limitedVideoPremiumTag")
                  ),
                },
              })
            : _vm.videoLink.membership === "paid" && _vm.membership === "free"
            ? _c("i", { staticClass: "video-link-card-parts__lock-icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "16",
                      height: "17",
                      viewBox: "0 0 16 17",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M10.6667 6.5013V5.16797C10.6667 3.69521 9.47276 2.5013 8 2.5013C6.52724 2.5013 5.33333 3.69521 5.33333 5.16797V6.5013H10.6667ZM8 1.16797C5.79086 1.16797 4 2.95883 4 5.16797V7.83463H12V5.16797C12 2.95883 10.2091 1.16797 8 1.16797Z",
                        fill: "#ABABAB",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M2.83398 6.4987C2.00556 6.4987 1.33398 7.17027 1.33398 7.9987L1.33398 14.332C1.33398 15.1605 2.00556 15.832 2.83398 15.832L13.1673 15.832C13.9957 15.832 14.6673 15.1605 14.6673 14.332L14.6673 7.9987C14.6673 7.17027 13.9957 6.4987 13.1673 6.4987L2.83398 6.4987ZM8.50065 13.4987V11.4018C8.98929 11.204 9.33398 10.7249 9.33398 10.1654C9.33398 9.42899 8.73703 8.83203 8.00065 8.83203C7.26427 8.83203 6.66732 9.42899 6.66732 10.1654C6.66732 10.7249 7.01201 11.204 7.50065 11.4018L7.50065 13.4987L8.50065 13.4987Z",
                        fill: "#ABABAB",
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }