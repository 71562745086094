






import { defineComponent } from '@vue/composition-api'
import PasswordResetCompleteSection from '@/components/PasswordResetPage/PasswordResetCompletePane/PasswordResetCompleteSection.vue'

/**
 * パスワードリセット完了ペイン
 */
export default defineComponent({
  name: 'PasswordResetCompletePane',
  components: {
    PasswordResetCompleteSection,
  },
})
