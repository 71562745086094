import { uniqBy } from 'lodash'
import AvailableAreaDocument from '@/store/stores/collectionModule/documents/availableArea/AvailableAreaDocument'
import { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'
import I18n from '@/locales/I18n'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'

/**
 * チケット画面のプルダウンに関する機能を提供する。
 */
export default function useTicketSelectList() {
  /**
   * 年のプルダウンを生成
   */
  const generateYearList = () => {
    const yearList = [] as Array<DropDownDataType>

    for (let i = 2024; i <= new Date().getFullYear() + 1; i += 1) {
      yearList.push({
        id: i,
        name: String(i),
      })
    }
    return yearList
  }

  /**
   * 場所のプルダウンを生成
   */
  const generatePlaceList = (
    areaData: Array<AvailableAreaDocument>,
    digitalTickets: Array<DigitalTicketDataDocument> | undefined,
  ) => {
    // サーキット一覧
    const circuitList = areaData.map((area) => ({
      id: area.stadiumId ?? '',
      name: area.areaName?.[I18n.locale] ?? '',
    }))
    // デジタルチケットイベントデータに登録されている自由入力の場所一覧
    const freeTextAreaList = digitalTickets
      ?.filter((event) => !event.stadiumId && event.placeName)
      .map((event) => ({
        id: event.uniqueKey,
        name: event.placeName ?? '',
      }))
    return [...circuitList, ...uniqBy(freeTextAreaList, 'name')]
  }

  return {
    generateYearList,
    generatePlaceList,
  }
}
