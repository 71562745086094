var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "year-month-selector-section" }, [
    _c(
      "button",
      {
        staticClass: "year-month-selector-section__selector",
        on: { click: _vm.handleSelectorClicked },
      },
      [
        _c("span", [_vm._v(_vm._s(_vm.selectedYearOrMonth.value))]),
        _c(
          "span",
          { staticClass: "year-month-selector-section__selector-icon" },
          [_c("calendar-chevron-down-icon")],
          1
        ),
      ]
    ),
    _vm.state.menuVisible
      ? _c("div", { staticClass: "year-month-selector-section__menu" }, [
          _c(
            "ul",
            { staticClass: "year-month-selector-section__menu-list" },
            _vm._l(
              _vm.calendarYearList.concat(_vm.calendarYearAndMonthList),
              function (item) {
                return _c(
                  "li",
                  {
                    key: item.value,
                    staticClass: "year-month-selector-section__menu-item",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "year-month-selector-section__option",
                        class: {
                          "year-month-selector-section__option--selected":
                            _vm.selectedYearOrMonth.value === item.value,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleYearsAndMonthsClicked(item)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.value))])]
                    ),
                  ]
                )
              }
            ),
            0
          ),
        ])
      : _vm._e(),
    _vm.state.menuVisible
      ? _c("div", {
          staticClass: "year-month-selector-section__overlay",
          on: { click: _vm.handleOverlayClicked },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }