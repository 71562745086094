
















































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import { orderBy } from 'lodash'
import MypageTicketTodayEntryCardSection from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/MypageTicketTodayEntryCardSection.vue'
import MypageTicketTodayCardSection from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/MypageTicketTodayCardSection.vue'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'
import StoreUtil from '@/store/StoreUtil'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * マイページ: 本日のチケット一覧セクション
 */
export default defineComponent({
  name: 'MypageTicketListSection',
  components: {
    MypageTicketTodayEntryCardSection,
    MypageTicketTodayCardSection,
  },
  props: {
    /**
     * デジタルチケット イベント情報
     */
    ticketEvent: {
      type: Object as PropType<DigitalTicketDataDocument>,
      required: true,
    },
  },
  setup(props, context) {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { showingDetailTodayTicketTypeId } = mypageTicketPageStore
    const { ownOrganization } = mypagePageStore
    const { userPurchasedTicket, getTicketTotalCountByTicket } = usePurchasedTicketData()
    const { getDisplayDateJa } = useDisplayDependingOnLang()

    /**
     * ログインユーザーが購入した商品情報
     */
    const purchasedTicketInfo = computed(() =>
      userPurchasedTicket([props.ticketEvent], ownOrganization.value),
    )

    /**
     * ログインユーザーが購入したチケットに紐づく券種データ
     */
    const purchasedTicketTypes = computed(() =>
      orderBy(props.ticketEvent.ticketTypes, 'order')?.filter((ticketType) =>
        purchasedTicketInfo.value.ticketTypeIds.availableToday.some(
          (ticketTypeId) => ticketTypeId === ticketType.ticketTypeId,
        ),
      ),
    )

    /**
     * ログインユーザーが購入したチケット
     */
    const getPurchasedTickets = (ticketType: DigitalTicketDataTicketTypeDocument) =>
      orderBy(ticketType.tickets, 'order')?.filter((ticket) =>
        purchasedTicketInfo.value.productNumbers.availableToday.some(
          (productNumber) => productNumber === ticket.productNumber,
        ),
      )

    /**
     * 集合時間を取得する
     */
    const getMeetingTime = (date: number) => getDisplayDateJa(date, 'HH:mm', 'HH:mm')

    /**
     * カードの開閉が押下されたときの処理
     */
    const handleCardToggleClicked = (ticketTypeId: string) => {
      showingDetailTodayTicketTypeId.value =
        showingDetailTodayTicketTypeId.value === ticketTypeId ? '' : ticketTypeId
    }

    /**
     * 係員がこの画面を確認しますボタンが押下されたときの処理
     * @param targetTicketType 対象の券種
     */
    const onAdmissionTicketClicked = (targetTicketType: DigitalTicketDataTicketTypeDocument) => {
      context.emit(
        'onAdmissionTicketClicked',
        targetTicketType,
        props.ticketEvent,
        purchasedTicketInfo,
      )
    }

    return {
      // MypageTicketPageStore
      showingDetailTodayTicketTypeId: showingDetailTodayTicketTypeId as Ref<string>,
      // mypagePageStore
      ownOrganization: ownOrganization as Ref<OrganizationDocument | undefined>,
      // usePurchasedTicketData
      getTicketTotalCountByTicket,
      // MypageTicketListSection
      purchasedTicketInfo,
      purchasedTicketTypes,
      getPurchasedTickets,
      getMeetingTime,
      handleCardToggleClicked,
      onAdmissionTicketClicked,
    }
  },
})
