var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "coupon-plan-card-parts" }, [
    _c("div", { staticClass: "coupon-plan-card-parts__detail" }, [
      _c("h5", { staticClass: "coupon-plan-card-parts__title" }, [
        _vm._v(_vm._s(_vm.plan.couponData.couponName[_vm.$i18n.locale])),
      ]),
      _c("p", { staticClass: "coupon-plan-card-parts__sub-title" }, [
        _vm._v(" " + _vm._s(_vm.plan.couponData.title[_vm.$i18n.locale]) + " "),
      ]),
      _c("p", { staticClass: "coupon-plan-card-parts__description" }, [
        _vm._v(
          " " + _vm._s(_vm.plan.couponData.description[_vm.$i18n.locale]) + " "
        ),
      ]),
      _c(
        "p",
        {
          class: [
            _vm.isOneTimePassUsed
              ? "coupon-plan-card-parts__one-time-pass-term"
              : "coupon-plan-card-parts__free-plan-term",
          ],
        },
        [
          _vm._v(
            " " + _vm._s(_vm.plan.couponData.couponApplicationPeriodText) + " "
          ),
        ]
      ),
    ]),
    _c("p", { staticClass: "coupon-plan-card-parts__price" }, [
      _c("span", { staticClass: "coupon-plan-card-parts__price__value" }, [
        _vm._v(
          _vm._s(_vm.plan.price.JPY.toLocaleString()) +
            _vm._s(_vm.planPriceUnit)
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }