var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.7539 5.53516C7.7539 4.8448 8.31355 4.28516 9.0039 4.28516C9.69426 4.28516 10.2539 4.8448 10.2539 5.53516C10.2539 6.22551 9.69426 6.78516 9.0039 6.78516C8.31355 6.78516 7.7539 6.22551 7.7539 5.53516ZM9.0039 2.28516C7.20898 2.28516 5.7539 3.74023 5.7539 5.53516C5.7539 7.33008 7.20898 8.78516 9.0039 8.78516C10.7988 8.78516 12.2539 7.33008 12.2539 5.53516C12.2539 3.74023 10.7988 2.28516 9.0039 2.28516ZM2.48441 12.7617C3.41174 11.3707 4.97289 10.5352 6.64466 10.5352H11.3632C13.035 10.5352 14.5961 11.3707 15.5234 12.7617L17.2058 15.2852H14.8021L13.8593 13.8711C13.3029 13.0365 12.3662 12.5352 11.3632 12.5352H6.64466C5.6416 12.5352 4.70491 13.0365 4.14851 13.8711L3.20578 15.2852H0.802075L2.48441 12.7617ZM17.2539 15.5352V15.3574L17.336 15.4805L17.2539 15.5352ZM0.753925 15.3574V15.5352L0.671875 15.4805L0.753925 15.3574Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }