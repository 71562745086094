














import { defineComponent, inject, ref } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CancelPaidPlanEditPane from '@/components/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanEditPane.vue'
import StoreUtil from '@/store/StoreUtil'
import { FreePlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

/**
 * 有料会員解約 入力ページ
 */
export default defineComponent({
  name: 'CancelPaidPlanEditPage',
  components: {
    SubHeaderSection,
    CancelPaidPlanEditPane,
  },
  inject: ['loading'],
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const {
      selectedPlanGroupId,
      fetchContractPlans,
      fetchContractInfo,
      ownContractInfo,
      fetchContractInfoPlans,
    } = mypagePageStore

    const isLoading = ref(true)
    const loading = inject('loading') as PluginApi

    const fetch = async () => {
      const loader = loading.show()
      /** ログインユーザーの契約情報、SFgo組織の契約プラン情報を取得する */
      await Promise.all([fetchContractInfo(), fetchContractPlans()])

      /** 契約情報IDに紐づく契約情報契約プランを取得 */
      const { contractInfoId } = ownContractInfo.value
      await fetchContractInfoPlans(contractInfoId as string)

      loader.hide()
      isLoading.value = false
    }
    fetch()

    // 解約画面で選択できるのは無料プランのみ
    selectedPlanGroupId.value = 'freePlan' as FreePlanGroupType

    /**
     * プラン選択
     * @param plan {FreePlanGroupType}
     */
    const changePlan = (plan: FreePlanGroupType) => {
      selectedPlanGroupId.value = plan
    }

    return {
      isLoading,
      selectedPlanGroupId,
      changePlan,
    }
  },
  methods: {
    /**
     * 確認画面へ遷移
     */
    moveToCancelPaidPlanConfirm() {
      this.$router.replace({ name: 'CancelPaidPlanConfirmPage' })
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$router.replace({ name: 'MypageTopPage' })
    },
  },
})
