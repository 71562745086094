var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c(
      "div",
      { staticClass: "login-page__container" },
      [
        _vm.loginType !== "login" && _vm.loginType !== "notification"
          ? _c("header-pane", {
              staticClass: "login-page__header",
              attrs: { colorType: "light" },
            })
          : _vm._e(),
        _c("login-pane", { attrs: { loginType: _vm.loginTypeValue } }),
        _vm.state.cautionMessageInfo
          ? _c("caution-maintenance-pane", {
              attrs: { cautionMessageInfo: _vm.state.cautionMessageInfo },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "login-page__background", style: _vm.bgStyle }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }