














import { defineComponent, PropType } from '@vue/composition-api'
import { NotificationItemType } from '@/components/NotificationPane/NotificationPane.vue'
import BaseNotificationItemContentsParts from '@/components/NotificationPane/NotificationPane/parts/BaseNotificationItemContentsParts.vue'
import HighlightCardParts from '@/components/NotificationPane/NotificationPane/parts/HighlightCardParts.vue'

/**
 * Notification 詳細アイテム: TYPE = ハイライト用 コンポーネント
 */
export default defineComponent({
  name: 'HighlightNotificationItemContentsParts',
  components: {
    BaseNotificationItemContentsParts,
    HighlightCardParts,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItemType>,
      required: true,
    },
  },
})
