import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 動画情報のサムネイル画像データを取得する際に利用するためのドキュメントクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/848265341/01+API
 */
export default class ThumbnailDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ThumbnailDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * アングル動画情報ID
   */
  angleMovieId: null | string = null

  /**
   * サムネイル画像パス
   */
  thumbnailPath: null | string = null
}
