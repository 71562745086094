var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sns-post-item-contents-parts" },
    [
      _vm.snsPost.linkType === "instagram"
        ? _c("instagram-post-item-contents-parts", {
            attrs: { snsPost: _vm.snsPost },
            on: { updateSnsContentsData: _vm.updateSnsContentsData },
          })
        : _vm.snsPost.linkType === "twitter"
        ? _c("twitter-post-item-contents-parts", {
            attrs: { snsPost: _vm.snsPost },
            on: { updateSnsContentsData: _vm.updateSnsContentsData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }