var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "mypage-ticket-accordion-icon-parts",
      attrs: {
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.72394 5.17313L7.05727 10.5065C7.57797 11.0272 8.42219 11.0272 8.94289 10.5065L14.2762 5.17313L12.3906 5.17313L8.00008 9.56365L3.60956 5.17313H1.72394Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }