





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MypageSelectPlanButtonParts',
  props: {
    /**
     * 商品名
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * 価格
     */
    price: {
      type: String,
      default: '',
    },
    /**
     * 支払い単位（サイクル）
     */
    unit: {
      type: String,
      default: '',
    },
    /**
     * お得額
     */
    bargainPriced: {
      type: String,
    },
    /**
     * ノート
     */
    note: {
      type: String,
    },
    /**
     * 契約期間
     */
    period: {
      type: String,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const handleClicked = () => {
      emit('onClick')
    }
    return {
      handleClicked,
    }
  },
})
