






import { defineComponent } from '@vue/composition-api'
import MissionRankingPane from '@/components/MissionPage/MissionRankingPane.vue'

export default defineComponent({
  name: 'MissionRankingPage',
  components: { MissionRankingPane },
})
