













import { defineComponent, inject, Ref, ref } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import PaidPlanChangeEditPane from '@/components/pc/MyPage/PaidPlanChange/PaidPlanChangeEditPane.vue'
import StoreUtil from '@/store/StoreUtil'
import { PaidPlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * プラン変更(有料=>有料) 入力ページ
 */
export default defineComponent({
  name: 'PaidPlanChangeEditPage',
  components: {
    SubHeaderSection,
    PaidPlanChangeEditPane,
  },
  inject: ['loading'],
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const {
      selectedPlanGroupId,
      fetchContractPlans,
      fetchContractInfo,
      ownContractInfo,
      fetchContractInfoPlans,
    } = mypagePageStore

    const isLoading = ref(true)
    const loading = inject('loading') as PluginApi

    const fetch = async () => {
      const loader = loading.show()
      /** ログインユーザーの契約情報、SFgo組織の契約プラン情報を取得する */
      await Promise.all([fetchContractInfo(), fetchContractPlans()])

      /** 契約情報IDに紐づく契約情報契約プランを取得 */
      const { contractInfoId } = ownContractInfo.value
      await fetchContractInfoPlans(contractInfoId as string)

      loader.hide()
      isLoading.value = false
    }
    fetch()

    // 画面ロード時、変更後のプランは未選択状態にする
    selectedPlanGroupId.value = '' as PaidPlanGroupType

    /**
     * プラン選択
     * @param plan {PaidPlanGroupType}
     */
    const changePlan = (plan: PaidPlanGroupType) => {
      selectedPlanGroupId.value = plan
    }

    return {
      isLoading,
      selectedPlanGroupId: selectedPlanGroupId as Ref<PaidPlanGroupType>,
      changePlan,
    }
  },
  methods: {
    /**
     * 確認画面へ遷移
     */
    moveToPaidPlanChangeConfirm() {
      this.$router.replace({ name: 'PaidPlanChangeConfirmPage' })
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      const { transitionFromMobileApp } = LocalStorageAccessor
      if (transitionFromMobileApp) {
        // アプリから遷移した場合、マイページのURLを開く。AppLinkの機能で、SFgoアプリが起動する
        window.open(`${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`)
      } else {
        this.$router.replace({ name: 'MypageTopPage' })
      }
    },
  },
})
