























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionCheckInIcon',
  props: {
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
})
