


















import { computed, defineComponent, onMounted, PropType, reactive } from '@vue/composition-api'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import VueI18n from 'vue-i18n'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import LoginPane, { LoginType } from '@/components/LoginPage/LoginPane.vue'
import CautionMaintenancePane from '@/components/LoginPage/CautionMaintenancePane.vue'
import i18n from '@/locales/I18n'
import type { CautionMessageInfoType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import DeviceInfo from '@/util/DeviceInfo'

export type DataType = {
  loginTypeValue: LoginType
}

type MessageContent = {
  title: string
  message: string
}

type MaintenanceContents = {
  ja: MessageContent
  en: MessageContent
}

type MaintenanceData = {
  contents: MaintenanceContents
}

/**
 * ログインページ
 */
export default defineComponent({
  name: 'LoginPage',
  components: {
    HeaderPane,
    LoginPane,
    CautionMaintenancePane,
  },
  props: {
    /**
     * ログインタイプ指定
     */
    loginType: {
      type: String as PropType<LoginType>,
      default: 'login',
    },
  },
  setup() {
    const state = reactive({
      cautionMessageInfo: null as CautionMessageInfoType | null,
      bgHeight: 0,
    })

    const isPc = DeviceInfo.isForPC()

    const bgStyle = computed(() => {
      if (isPc) {
        return {}
      }

      return {
        height: `${state.bgHeight}px`,
      }
    })

    onMounted(async () => {
      const options = {
        url: process.env.VUE_APP_MAINTENANCE_FILE_PATH,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      } as AxiosRequestConfig

      await axios(options)
        .then((res: AxiosResponse<MaintenanceData>) => {
          const { data } = res
          if (data) {
            const maintenanceI18n = new VueI18n({
              locale: i18n.locale,
              fallbackLocale: 'en',
              messages: {
                ja: data.contents.ja,
                en: data.contents.en,
              },
            })
            const cautionMessageInfo: CautionMessageInfoType = {
              title: maintenanceI18n.tc('title'),
              message: maintenanceI18n.tc('message'),
            }

            state.cautionMessageInfo = cautionMessageInfo
          }
        })
        .catch((e: AxiosError<{ error: string }>) => {
          console.log(e.message)
        })
      state.bgHeight =
        window.innerHeight > window.innerWidth ? window.innerHeight : window.innerWidth
    })

    return {
      state,
      bgStyle,
    }
  },
  data(): DataType {
    return {
      /**
       * ログインタイプ指定
       */
      loginTypeValue: (this.$route.params.loginType as LoginType) || 'login',
    }
  },
  watch: {
    /**
     * router.replace または router.push でルーティングを変更した後に、
     * コンポーネント内で直ちに $route オブジェクトの更新を検出できない場合があるため、監視して反映する。
     */
    $route: {
      handler(newRoute) {
        // $route が変更されたときの処理をここに書く
        this.loginTypeValue = newRoute.params.loginType || 'login'
      },
      immediate: true,
    },
  },
})
