















import { computed, defineComponent, PropType } from '@vue/composition-api'
import SelectListItemParts from '@/components/common/SelectList/parts/SelectListItemParts.vue'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'

/**
 * マイページ: 選択リスト セクション
 */
export default defineComponent({
  name: 'SelectListSection',
  components: {
    SelectListItemParts,
  },
  props: {
    /**
     * 表示対象の選手データ
     */
    listData: {
      type: Array as PropType<PlayerDocument[] | TeamDocument[]>,
      required: true,
    },
    /**
     * 選択済みフラグ
     */
    selectedID: {
      type: String as PropType<string | null>,
      required: true,
      default: '',
    },
  },
  setup(props) {
    /**
     * 選択済みを監視
     */
    const selectedItemID = computed(() => props.selectedID)
    return {
      selectedItemID,
    }
  },
  methods: {
    /**
     * 選手の選択が変更された際に呼び出される
     * @param selectedPlayerId 選択された選手ID
     */
    changeSelected(selectedPlayerId: string): void {
      this.$emit('selectChange', selectedPlayerId)
    },
  },
})
