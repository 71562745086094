/**
 * 数値計算を共通処理を提供するユーティリティ。
 */
export default class MathUtil {
  /**
   * 任意の桁を指定して四捨五入を行う。
   * @param value 値
   * @param base どの桁で四捨五入するか。100を指定した場合、100の桁で四捨五入する。
   * 50を指定した場合、100〜149 の場合は100、150〜199 の場合は150のように四捨五入される。
   */
  static round(value: number, base: 100 | 1000 | 50) {
    if (base % 100 === 0) {
      return Math.round(value / base) * base
    }
    return Math.floor(value / base) * base
  }
}
