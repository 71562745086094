




















import { defineComponent, Ref } from '@vue/composition-api'
import TeamSelectListSection from '@/components/MypageFavoriteTeamSelectPage/MypageFavoriteTeamSelectPane/TeamSelectListSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import StoreUtil from '@/store/StoreUtil'

export type DataType = {
  selectedID: string
}

/**
 * HOME: SNS投稿表示チーム変更 コンテンツペイン
 */
export default defineComponent({
  name: 'HomeSnsTeamSelectPane',
  components: {
    TeamSelectListSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  data(): DataType {
    return {
      /**
       * セレクトID
       */
      selectedID: '',
    }
  },
  setup() {
    const store = StoreUtil.useStore('HomePageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      teams: store.availableTeams as Ref<TeamDocument[]>,
      snsPostDisplayTeam: store.snsPostDisplayTeam,
      favoriteTeam: mypagePageStore.favoriteTeam,
    }
  },
  mounted() {
    this.selectedID = this.snsPostDisplayTeam?.sid || ''
  },
  methods: {
    changeSnsPostDisplayTeam() {
      /**
       * SNS投稿を表示するチームをストアに保存
       */
      const selectedTeam = this.teams.find((item) => item.sid === this.selectedID)
      if (selectedTeam) {
        this.snsPostDisplayTeam = selectedTeam
      }

      this.$emit('changed')
    },
    changeSelected(selectedItemID: string): void {
      /**
       * 選択変更
       * @param selectedItemID : string
       */
      if (this.selectedID !== selectedItemID) {
        this.selectedID = this.selectedID === selectedItemID ? '' : selectedItemID
      }
    },
  },
})
