




























import { defineComponent, ref } from '@vue/composition-api'
import FieldsetConfirmParts from '@/components/common/form/FieldsetConfirmParts.vue'

/**
 * 決済: クレジット入力情報確認セクション
 * Stripe Elementsを使うと入力したクレジットカード情報の取得ができず、確認画面にクレジットカード情報を表示することができなかったため、このコンポーネントは使われていない。
 */
export default defineComponent({
  name: 'CreditCardInputSection',
  components: {
    FieldsetConfirmParts,
  },
  setup() {
    /**
     * クレジットカード情報
     */
    const creditCard = ref({
      number: '1234123412341234',
      name: 'TARO YAMADA',
      period: '1124',
      cvc: '123',
    })
    return {
      creditCard,
    }
  },
})
