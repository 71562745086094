
















import { defineComponent, PropType, reactive } from '@vue/composition-api'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import { AwardRankingCategoryType } from '@/store/stores/collectionModule/documents/award/AwardDataDocument'

/**
 * SFgo Award スポンサーロゴパーツ
 */
export default defineComponent({
  name: 'AwardRankingSponsorParts',
  props: {
    /**
     * 選択年
     */
    targetYear: {
      type: Number,
      required: true,
    },
    /**
     * カテゴリ
     */
    category: {
      type: String as PropType<AwardRankingCategoryType>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      /** 読み込みできた画像枚数 */
      loadedImgCnt: 0,
      /** 全て読み込みが完了したかどうか */
      hasAllLoaded: false,
    })
    /** 読み込みを行った画像枚数 */
    let getImgCnt = 0

    /**
     * 10枚のロゴ画像読み込み完了時の処理
     */
    const loadStatusFinished = async () => {
      await new Promise((resolve) => {
        // 取得できなかった画像のリスト要素の削除が終わっていないため、0.5秒待つ
        setTimeout(resolve, 500)
      })
      state.hasAllLoaded = true
    }

    /**
     * ロゴ画像のパス
     */
    const getImgSrc = (index: number) => {
      getImgCnt += 1
      if (getImgCnt === 10) {
        loadStatusFinished()
      }

      return CloudFrontUtil.getSignedUrl(
        `${process.env.VUE_APP_IMG_FILE_PATH}/award/${
          props.targetYear
        }/${props.category.toLowerCase()}/sponsor/sponsor_logo_${index}.png`,
      )
    }

    /**
     * 画像取得できた際の処理
     */
    const srcLoad = () => {
      state.loadedImgCnt += 1
    }

    /**
     * 画像取得に失敗した際の処理
     */
    const srcError = (event: Event, index: number) => {
      const { target } = event
      if (!(target instanceof HTMLImageElement)) {
        return
      }
      target.closest(`.award-ranking-sponsor-parts__item--${index}`)?.remove()
    }

    return {
      state,
      getImgSrc,
      srcLoad,
      srcError,
    }
  },
})
