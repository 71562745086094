var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "sub-header-section",
      class: { "sub-header-section--has-back-link": _vm.hasBackLink },
    },
    [
      _vm.hasBackLink
        ? _c(
            "button",
            {
              staticClass: "atom-input-btn sub-header-section__back-link",
              attrs: { type: "button" },
              on: { click: _vm.pageBack },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/icon/icon_chevron_left_18.svg"),
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("h2", { staticClass: "sub-header-section__title" }, [
        _vm._v(_vm._s(_vm.pageTitle)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }