import dayjs from 'dayjs'
import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'

/**
 * 大会データを操作するための処理を取得する。
 */
export default function useChampionships() {
  // Collection modules
  const championshipCollectionModule = CollectionModule.createStore(ChampionshipDocument)

  /**
   * 指定された年でfilterする際のstartDateとendDateの値を返す
   * @return 開始日と終了日
   */
  const generateQueryForYear = (raceYear: number) => {
    const startDate = dayjs().year(raceYear).startOf('year').valueOf()
    const endDate = dayjs().year(raceYear).endOf('year').valueOf()
    return {
      startDate,
      endDate,
    }
  }
  // API Requests
  /**
   * 大会情報リストを取得する
   * @return APIレスポンス
   */
  const fetchChampionships = (raceYear: number) => {
    const { startDate, endDate } = generateQueryForYear(raceYear)
    return championshipCollectionModule.fetch({
      query: {
        filter: { startDate: { $gte: startDate }, endDate: { $lte: endDate } },
        sort: 'startDate',
      },
    })
  }
  /**
   * 指定した年の大会情報リストの数を取得する
   * @return APIレスポンス
   */
  const fetchChampionshipsCount = (raceYear: number) => {
    const { startDate, endDate } = generateQueryForYear(raceYear)
    return championshipCollectionModule.fetch({
      query: {
        filter: { startDate: { $gte: startDate }, endDate: { $lte: endDate } },
        count: true,
      },
      isSaveInStore: false,
    })
  }
  /**
   * 指定した大会マスタIDの大会を取得する
   * @param championshipMasterId 大会マスタID
   */
  const fetchChampionship = (championshipMasterId: string) =>
    championshipCollectionModule.fetch({ targetId: championshipMasterId, isSaveInStore: true })

  /**
   * 指定した大会マスタIDの大会を取得する
   * @param championshipMasterIds 大会マスタID
   */
  const fetchChampionshipsByIds = async (championshipMasterIds: string[]) =>
    championshipCollectionModule.fetch({
      query: {
        filter: { championshipMasterId: { $in: championshipMasterIds } },
      },
    })

  // computed
  /**
   * 取得した大会情報
   */
  const targetChampionship = computed(() => championshipCollectionModule.data[0])
  /**
   * 取得した大会情報一覧
   */
  const championships = computed(() => championshipCollectionModule.data)

  // methods
  /**
   * 取得した大会情報をクリアする
   */
  const clearChampionships = () => {
    championshipCollectionModule.clearData()
  }

  return {
    fetchChampionship,
    targetChampionship,
    fetchChampionships,
    fetchChampionshipsByIds,
    championships,
    clearChampionships,
    fetchChampionshipsCount,
  }
}
