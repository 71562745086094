

























import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import AtomButton from '@/components/atoms/AtomButton.vue'
import i18n from '@/locales/I18n'
import { RewardDataType } from '@/store/hook/useVideoPlatformContents'
import LimitedVideoModalSection from '@/components/HomePage/HomePane/LimitedVideoModalSection.vue'
import InAppBrowserWrapper, { InAppBrowserResult } from '@/util/inAppBrowser/InAppBrowserWrapper'
import InAppYoutubeBrowser from '@/util/inAppBrowser/InAppYoutubeBrowser'
import useDownload from '@/util/download/useDownload'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import DeviceInfo from '@/util/DeviceInfo'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import FileExtensionUtil from '@/util/FileExtensionUtil'

export default defineComponent({
  name: 'RewardItemSection',
  components: {
    AtomButton,
    LimitedVideoModalSection,
  },
  props: {
    reward: {
      type: Object as PropType<RewardDataType>,
      required: true,
    },
  },
  setup(props) {
    const { loadFileToDownload, loadFileToDownloadForCordova } = useDownload()

    // YouTubeをInAppBrowserで開く
    const inAppBrowser = new InAppYoutubeBrowser()

    // YouTubeモーダルを開くかどうか
    const videoModalEnabled = ref(false)

    const buttonLabel = computed(() => {
      if (props.reward.videoType === 'rewardVideo') {
        return i18n.tc('RewardPage.buttonLabel.video')
      }

      return i18n.tc('RewardPage.buttonLabel.image')
    })

    const deadlineLabel = computed(() => {
      if (props.reward.videoType === 'rewardVideo') {
        return i18n.tc('RewardPage.deadline.video')
      }

      return i18n.tc('RewardPage.deadline.image')
    })

    const signedImagePath = computed(() => CloudFrontUtil.getSignedUrl(props.reward.imagePath))

    /**
     * YouTubeを再生する
     */
    const playYouTube = () => {
      if (InAppBrowserWrapper.isAvailable()) {
        // アプリで動作している場合、InAppBrowserを利用してYoutube動画を再生する
        inAppBrowser.showYoutube(props.reward.videoId, async (result: InAppBrowserResult) => {
          if (result?.isNetworkError) {
            await MessageDialogStore.value.open({
              title: i18n.t('apiNotification.dialog.0.title') as string,
              message: i18n.t('apiNotification.dialog.0.message') as string,
            })
          } else if (result?.isError) {
            await MessageDialogStore.value.open({
              title: i18n.t('RewardPage.errors.VideoPlayError.title') as string,
              message: i18n.t('RewardPage.errors.VideoPlayError.message') as string,
            })
          }
        })
      } else {
        videoModalEnabled.value = true
      }
    }

    /**
     * 動画モーダルを閉じる
     */
    const hideVideoModal = () => {
      videoModalEnabled.value = false
    }

    /**
     * 画像をダウンロードする
     */
    const downloadImage = async () => {
      if (DeviceInfo.isCordova()) {
        const downloadResult = await loadFileToDownloadForCordova(signedImagePath.value)
        if (downloadResult?.saveImage?.isSuccess === false) {
          if (downloadResult.saveImage.response.errorCode === 'vp_110_008') {
            MessageDialogStore.value.open({
              title: i18n.tc('RewardPage.errors.downloadImagePermissionError.title'),
              message: i18n.tc('RewardPage.errors.downloadImagePermissionError.message'),
            })
          } else {
            MessageDialogStore.value.open({
              title: i18n.tc('RewardPage.errors.downloadImageError.title'),
              message: i18n.tc('RewardPage.errors.downloadImageError.message'),
            })
          }
        } else if (downloadResult?.loadFile && downloadResult?.loadFile?.status !== 200) {
          MessageDialogStore.value.open({
            title: i18n.tc('RewardPage.errors.loadImageError.title'),
            message: i18n.tc('RewardPage.errors.loadImageError.message'),
          })
        } else {
          MessageDialogStore.value.open({
            title: i18n.tc('RewardPage.success.downloadImage.title'),
            message: i18n.tc('RewardPage.success.downloadImage.message'),
          })
        }
      } else {
        // 画像パスを見て、拡張子をつける
        const extension = FileExtensionUtil.getFileExtension(props.reward.imagePath)
        const downloadResult = await loadFileToDownload(
          `SFgo_${props.reward.title.en}.${extension}`,
          signedImagePath.value,
          {
            responseType: 'blob',
            headers: {
              accept: 'image/*',
            },
          },
        )
        if (!downloadResult) {
          MessageDialogStore.value.open({
            title: i18n.tc('RewardPage.errors.loadImageError.title'),
            message: i18n.tc('RewardPage.errors.loadImageError.message'),
          })
        } else {
          MessageDialogStore.value.open({
            title: i18n.tc('RewardPage.success.downloadImage.title'),
            message: i18n.tc('RewardPage.success.downloadImage.message'),
          })
        }
      }
    }

    const handleClick = () => {
      if (props.reward.videoType === 'rewardVideo') {
        playYouTube()
      } else {
        downloadImage()
      }
    }

    return {
      buttonLabel,
      deadlineLabel,
      videoModalEnabled,
      signedImagePath,
      hideVideoModal,
      handleClick,
    }
  },
})
