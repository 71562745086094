import { onBeforeUnmount, onMounted, reactive } from '@vue/composition-api'
import BrowserVideoPlayer from '@/util/videoplayer/BrowserVideoPlayer'
import VideoPlayerInitOptionsType from '@/util/videoplayer/VideoPlayerInitOptionsType'
import NativeVideoPlayer from '@/util/videoplayer/NativeVideoPlayer'
import AirVideoPlayer from '@/util/videoplayer/AirVideoPlayer'
import DeviceInfo from '@/util/DeviceInfo'
import VideoPlayStatus from '@/util/videoplayer/VideoPlayStatus'
import VideoPlayerStatus from '@/util/videoplayer/VideoPlayerStatus'
import { VideoPlayerClass, VideoPlayerType } from '@/util/videoplayer/VideoPlayerType'
import { VideoPlayerErrorType } from '@/util/videoplayer/VideoPlayerError'
import Logger from '@/util/logger/Logger'
import IVideoPlayer from '@/util/videoplayer/IVideoPlayer'

/**
 * 動画再生状態の型
 */
export type VideoStatusType = {
  /**
   * 現在の再生時間(単位: 秒)
   */
  currentTime: number
  /**
   * 現在の再生時間の実時間（単位: ミリ秒)
   * 動画がタイムコードを持つ場合のみ取得可能.
   */
  currentVideoTrackDateTime?: number
  /**
   * 動画長(単位: 秒)
   */
  movieLength: number
  /**
   * 動画URL
   */
  movieUrl?: string
  /**
   * 動画再生開始位置。動画の開始位置を任意の位置に設定する。
   */
  startTime?: number
  /**
   * 動画再生終了位置。動画の終了位置を任意の位置に設定する。
   */
  endTime?: number
}

/**
 * 動画プレーヤー状態の型
 */
export type VideoPlayerStatusType = {
  /**
   * 再生状態
   */
  playStatus: VideoPlayStatus
  /**
   * 動画の最後まで再生完了した状態かどうか
   */
  playFinished: boolean
  /**
   * プレーヤの状態
   */
  playerStatus: VideoPlayerStatus
  /**
   * 動画プレーヤーの初期化が完了しているかどうか
   */
  initialized: boolean
  /**
   * 動画プレーヤーの種別
   */
  playerType: VideoPlayerType | undefined
  /**
   * 動画プレーヤーのエラー状況
   */
  error: VideoPlayerErrorType | undefined
}

/**
 * 動画状態の初期値
 */
const initialVideoStatus: VideoStatusType = {
  currentTime: 0,
  currentVideoTrackDateTime: 0,
  movieLength: 0,
  movieUrl: undefined,
  startTime: 0,
  endTime: 0,
}
/**
 * 動画再生状態の初期値
 */
const initialVideoPlayerStatus: VideoPlayerStatusType = {
  playStatus: 'PAUSE',
  playFinished: false,
  playerStatus: 'Not running',
  initialized: false,
  playerType: undefined as VideoPlayerType | undefined,
  error: undefined as VideoPlayerErrorType | undefined,
}

/**
 * 動画プレーヤーを取得する。
 * アプリがブラウザで動作している場合、video.js を利用して動画を再生する機能をもった動画プレーヤーを返す。
 * iOS/Androidで動作している場合、それぞれのプラットフォームが提供する動画再生機能を利用して動画を再生を行う動画プレーヤー（ネイティブプレーヤー）を返す。
 *
 * ネイティブプレーヤーのサイズと表示位置は、VideoPlayerInitOptionsのidに指定されたvideoタグの値が利用される。
 * ネイティブプレーヤーのサイズ、位置の変更を行うでも場合、videoタグのサイズ、位置を変更する。videoタグのサイズと位置は ResizeObserver で監視され、ネイティブプレーヤーにも反映される。
 * そのため、ネイティブプレーヤー利用時も、videoタグは削除せず、不可視の状態で存在させておく必要がある。
 *
 */
export default function useVideoPlayer(
  options: Partial<VideoPlayerInitOptionsType> & { id: string; movieUrl: string },
) {
  const videoStatus = reactive({
    ...initialVideoStatus,
  })
  const videoPlayerStatus = reactive({
    ...initialVideoPlayerStatus,
  })

  /**
   * ブラウザ向けの動画プレーヤーを取得する。
   * @return 動画プレーヤー
   */
  const getBrowserVideoPlayer = () => new BrowserVideoPlayer()

  /**
   * iPhone/Android用ネイティブ動画プレーヤーを取得する。
   */
  const getNativeVideoPlayer = () => new NativeVideoPlayer()

  /**
   * 動画再生をレミューレートする動画プレーヤーを取得する。
   */
  const getAirVideoPlayer = () => new AirVideoPlayer()

  /**
   * 動画プレーヤーのインスタンスを生成する。
   * @return 動画プレーヤーのインスタンス
   */
  const getMoviePlayer = (): IVideoPlayer => {
    if (options.playerType) {
      if (options.playerType === 'Browser') {
        return getBrowserVideoPlayer()
      }
      if (options.playerType === 'AirVideoPlayer') {
        return getAirVideoPlayer()
      }
      return getNativeVideoPlayer()
    }
    if (DeviceInfo.isiOS() || DeviceInfo.isAndroid()) {
      return getNativeVideoPlayer()
    }
    return getBrowserVideoPlayer()
  }
  const videoPlayer = getMoviePlayer()

  /**
   * ネイティブプレーヤーのサイズを変更する。
   * 初期化時に利用したvideoタグの表示サイズに変更する。
   */
  const resizeVideoPlayer = () => {
    const rect = BrowserVideoPlayer.getVideoPlayerSize(options.id)
    if (rect) {
      videoPlayer.changeDisplaySize(
        { x: rect.x, y: rect.y },
        { width: rect.width, height: rect.height },
      )
    }
  }
  /**
   * videoタグのサイズ変更を検出して、ネイティブプレーヤーのサイズを変更する
   */
  const resizeObserver = new ResizeObserver(() => {
    setTimeout(() => {
      resizeVideoPlayer()
    }, 0)
  })

  /**
   * 動画プレーヤーがネイティブプレーヤーかどうかを判定する
   */
  const isNativePlayer = () => videoPlayer.playerType === VideoPlayerClass.Native

  /**
   * videoタグを非表示にする。
   * ResizeObserverを利用してvideoタグのサイズ、位置の変更を監視するため、要素は削除せず、不可視の状態にする。
   */
  const hideVideoTag = () => {
    const videoTag = document.querySelector<HTMLElement>(`#${options.id}`)
    if (videoTag && videoTag.style) {
      videoTag.style.opacity = '0'
    }
  }

  /**
   * 動画の開始終了位置を任意の位置に設定する。
   */
  if (options.startTime) videoStatus.startTime = options.startTime

  /**
   * 動画の開始終了位置を任意の位置に設定する。
   */
  if (options.endTime) videoStatus.endTime = options.endTime

  /**
   * 動画プレーヤーを利用しているComponentがmountされた場合に呼び出される。
   *
   * 動画プレーヤーの初期化処理を行い、動画プレーヤーを利用可能な状態にする。
   */
  onMounted(async () => {
    try {
      if (videoPlayer.playerType !== VideoPlayerClass.Browser) {
        // ブラウザ用の動画プレーヤー以外の場合、videoタグは非表示にする
        hideVideoTag()
      }
      // Component がmountしたタイミングで動画プレーヤーを生成する
      const rect = BrowserVideoPlayer.getVideoPlayerSize(options.id)
      if (!rect) {
        return
      }
      const playerOptions: VideoPlayerInitOptionsType = { ...options, ...rect }
      const initResult = await videoPlayer?.init({
        /**
         * 現在の再生時刻が変更された場合に呼び出される。
         * @param time 現在の再生時刻: 単位: 秒
         * @param videoTrackDateTime 現在の動画の再生位置が記録された時間. UnixTime(ミリ秒)
         */
        onCurrentTimeUpdate: (time, videoTrackDateTime) => {
          // 再生可能状態でない場合更新は行わない
          if (videoPlayerStatus.playerStatus === 'Running') {
            videoStatus.currentTime = time
            videoStatus.currentVideoTrackDateTime = videoTrackDateTime

            if (videoPlayerStatus.playFinished) {
              // 動画再生のステータスが再生完了になっている際の処理
              const startTime = videoStatus.startTime || 0
              const endTime = videoStatus.endTime || videoStatus.movieLength
              // 再生完了になっているが再生位置が開始位置から終了位置の範囲内の際にいる際には再生完了ステータスをfalseにする
              if (startTime <= videoStatus.currentTime && endTime > videoStatus.currentTime) {
                videoPlayerStatus.playFinished = false
              }
            }
          }
        },
        /**
         * 動画の再生情報が変更された場合に呼び出される。
         * @param status 動画再生状態
         */
        onPlayStatusUpdate: (status) => {
          videoPlayerStatus.playStatus = status
        },
        /**
         * 動画の最後まで再生が完了した場合に呼び出される。
         */
        onPlayFinished: () => {
          videoPlayerStatus.playFinished = true
        },
        /**
         * 動画の再生可能状態が変化した場合に呼び出される。
         * @param status プレーヤ状態
         * @param movieLength 動画長
         */
        onPlayerStatusUpdate: (status, movieLength: number | null) => {
          videoPlayerStatus.playerStatus = status
          if (movieLength != null) {
            videoStatus.movieLength = movieLength
          }
        },
        /**
         * 動画プレーヤーの初期化に失敗した場合に呼び出される。
         * @param error エラー
         */
        onInitializeFailed: (error) => {
          videoPlayerStatus.error = error
        },
        /**
         * 動画長が変化した場合に呼び出される。
         * @param movieLength 現在の動画長
         */
        onChangeMovieLength: (movieLength: number) => {
          // 再生可能状態でない場合更新は行わない
          if (videoPlayerStatus.playerStatus === 'Running') {
            videoStatus.movieLength = movieLength
          }
        },
        /**
         * 再生対象の動画のURLが変化した場合に呼び出される。
         * @param movieUrl 現在の動画のURL
         */
        onChangeMovieUrl: (movieUrl: string) => {
          videoStatus.movieUrl = movieUrl
        },
        ...playerOptions,
      })
      videoPlayerStatus.initialized = initResult.status === 'success'

      if (videoPlayer.playerType === VideoPlayerClass.Native) {
        // ネイティブプレーヤーの場合、videoタグのサイズ変更に追従して、ネイティブプレーヤーのサイズを変更する
        // videoタグ要素を監視対象にするとX,Y座標が変更前の状態となるため、親要素を監視する
        const element = document.querySelector(`#${options.id}`)
        if (element?.parentElement) {
          resizeObserver.observe(element.parentElement)
        }
      }
    } catch (e: unknown) {
      Logger.info(`useVideoPlayer#onMounted: Failed to init video player. cause: ${e}`)
    }
  })

  /**
   * 動画プレーヤーを利用しているComponentがunmountされる前に呼び出される。
   * 動画プレーヤーを破棄する。
   */
  onBeforeUnmount(async () => {
    // Component がunmountするタイミングで動画プレーヤーと、videoタグのサイズ変更監視を破棄する
    resizeObserver.disconnect()
    await videoPlayer?.dispose()
    // stateを初期化する
    Object.assign(videoStatus, initialVideoStatus)
    Object.assign(videoPlayerStatus, initialVideoPlayerStatus)
  })

  return {
    videoPlayer,
    videoStatus,
    videoPlayerStatus,
    resizeVideoPlayer,
    isNativePlayer,
  }
}
