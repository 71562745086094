






















import { defineComponent, PropType } from '@vue/composition-api'
import MypageTicketBlockSection from '@/components/MypageTicketListPage/MypageTicketListPane/MypageTicketBlockSection.vue'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'

/**
 * マイページ: チケット一覧セクション
 */
export default defineComponent({
  name: 'MypageTicketListSection',
  components: {
    MypageTicketBlockSection,
  },
  props: {
    purchasedDigitalTickets: {
      type: Array as PropType<Array<DigitalTicketDataDocument>>,
    },
  },
})
