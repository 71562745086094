



































import { defineComponent, PropType, ref } from '@vue/composition-api'
import ToggleRadioCardListSection from '@/components/common/ToggleRadioCard/ToggleRadioCardListSection.vue'
import ToggleRadioCardParts from '@/components/common/ToggleRadioCard/ToggleRadioCardParts.vue'
import PlanCardParts from '@/components/SignupPage/common/PlanCardParts.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import i18n from '@/locales/I18n'
import ContractPlanDocument, {
  FreePlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import {
  ContractTermType,
  ProcedureScreenType,
} from '@/store/stores/collectionModule/documents/GeneralTypes'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * 有料会員解約: 使用できなくなる機能 セクション
 */
export default defineComponent({
  name: 'ChooseFreePlanSection',
  components: {
    ToggleRadioCardListSection,
    ToggleRadioCardParts,
    PlanCardParts,
    CurrentPlanCardSection,
  },
  props: {
    /**
     * 無料プラン
     */
    freePlan: {
      type: Object as PropType<ContractPlanDocument>,
      default: () => ({}),
    },
    /**
     * 現在のプラン
     */
    currentPlan: {
      type: Object as PropType<ContractPlanDocument>,
      default: () => ({}),
    },
    /**
     * 将来のプラン
     */
    futurePlan: {
      type: Object as PropType<ContractPlanDocument>,
      default: () => ({}),
    },
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<FreePlanGroupType>,
      required: true,
    },
    /**
     * 契約中のプランの契約期間
     */
    contractTerm: {
      type: Object as PropType<ContractTermType>,
      default: () => ({}),
    },
    /**
     * 将来のプランの契約期間
     */
    scheduledContractTerm: {
      type: Object as PropType<ContractTermType>,
      default: () => ({}),
    },
    /**
     * 有料プランを解約できるかどうか
     */
    canCancelPaidPlan: {
      type: Boolean,
      default: false,
    },
    /**
     * どの手続き画面か
     */
    procedureScreen: {
      type: String as PropType<ProcedureScreenType>,
      default: null,
    },
  },
  setup(props) {
    const { getDisplayDate } = useDisplayDependingOnLang()

    /** 注記 */
    const attention = ref('')

    /** 無料プランに切り替わる日付 */
    const endDate = props.contractTerm.contractEndDate as number
    const freePlanStartDate = getDisplayDate(endDate + 1)

    /** 注記を設定 */
    const setAttention = () => {
      if (!props.futurePlan || props.canCancelPaidPlan) {
        // 有料プランの解約が可能な場合
        attention.value = i18n
          .t('CancelPaidPlan.EditPage.changeFreePlanAttention', {
            date: freePlanStartDate,
          })
          .toString()
        return
      }

      if (props.futurePlan.planGroupId === 'freePlan') {
        // 将来のプランが無料プランの場合
        attention.value = i18n
          .t('CancelPaidPlan.EditPage.hasFutureFreePlanAttention', {
            date: freePlanStartDate,
          })
          .toString()
      } else {
        // 将来のプランが無料プラン以外場合
        attention.value = i18n
          .t('CancelPaidPlan.EditPage.hasFuturePaidPlanAttention', {
            date: freePlanStartDate,
          })
          .toString()
      }
    }
    /** 注記を設定する */
    setAttention()

    /** 将来のプランがある場合の見出し */
    const futurePlanTitle = i18n
      .t('MypagePage.plan.futurePlan', {
        date: freePlanStartDate,
      })
      .toString()

    return {
      attention,
      futurePlanTitle,
    }
  },
})
