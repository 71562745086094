import dayjs from 'dayjs'
import StoreUtil from '@/store/StoreUtil'
import ContractInfoDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoDocument'
import ContractInfoPlanDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanDocument'
import { PlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { SaveResponse } from '@/store/stores/collectionModule/CollectionTypes'
import Const from '@/util/Const'
import Logger from '@/util/logger/Logger'
import APIResponse from '@/util/APIResponse'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useContractPlan from '@/store/hook/useContractPlan'
import { ContractTermType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useHistory from '@/store/hook/useHistory'

/**
 * ユーザー登録画面の機能を提供する。
 */
export default function useContract() {
  const signupPageStore = StoreUtil.useStore('SignupPageStore')
  const updateContractInfoPlanRes = [] as Array<SaveResponse<ContractInfoPlanDocument>>

  /**
   * 契約中のプランを終了させる。
   */
  const endCurrentPlans = async (contractInfoId: string, endDate: number) => {
    // 現在契約中のプラン
    const currentPlans = signupPageStore.contractInfoPlans.value?.filter(
      (plan) =>
        plan.endDate !== undefined &&
        (plan.endDate === null || plan.endDate > new Date().getTime()),
    )

    // eslint-disable-next-line no-restricted-syntax
    for (const planData of currentPlans) {
      // 現在契約中のプランを終了
      // eslint-disable-next-line no-await-in-loop
      const result = await signupPageStore.updateContractInfoPlan(
        contractInfoId,
        planData.id as string,
        endDate,
      )
      updateContractInfoPlanRes.push(result)
    }
    return updateContractInfoPlanRes
  }

  /**
   * 契約情報を更新する。
   */
  const updateContractInfo = async (memberType: MemberType) => {
    let userContractInfoParams = {} as ContractInfoDocument
    if (memberType === Const.MEMBER_TYPE.FREE) {
      // 無料プランの場合にセットするパラメータ
      userContractInfoParams = signupPageStore.getFreeUserContractInfoParams(
        signupPageStore.ownContractInfo.value,
      )
    } else if (memberType === Const.MEMBER_TYPE.PAID) {
      // 有料プランの場合にセットするパラメータ
      userContractInfoParams = signupPageStore.getPaidUserContractInfoParams(
        signupPageStore.ownContractInfo.value,
        signupPageStore.paymentMethodId.value,
      )
    } else {
      // 通常の操作では想定されないが、万が一memberTypeがfree、paid 以外の場合はエラーを返す
      return { isSuccess: false, response: {} as APIResponse }
    }
    // 契約情報更新処理
    return signupPageStore.updateContractInfo(userContractInfoParams)
  }

  /**
   * 契約情報契約プランを作成する。
   */
  const createContractInfoPlan = async (
    selectedPlanGroupId: PlanGroupType,
    contractInfoId: string,
  ) => {
    const { recalculateBillingInfo } = useContractInfo()
    const { saveMissionHistory } = useHistory()

    // SFgo組織の契約プラン情報を取得する
    await signupPageStore.fetchContractPlans()
    // プランのidを取得
    const targetPlanIds = signupPageStore.selectedContractPlanIds(selectedPlanGroupId)
    const contractStartDate = new Date().getTime()
    let plans = null
    // 契約プランに登録するプランデータを格納
    if (selectedPlanGroupId === 'freePlan' || selectedPlanGroupId === 'monthlyPlan') {
      // 無料プランまたは月額プランを契約する場合
      plans = targetPlanIds.map((id) => ({
        contractPlanId: id,
        startDate: contractStartDate,
      }))
    }
    if (selectedPlanGroupId === 'annualPlan') {
      // 年額プランを契約する場合
      plans = targetPlanIds.map((id) => ({
        contractPlanId: id,
        startDate: contractStartDate,
        // 終了日：新プラン契約開始日+1年-1日の23:59:59
        endDate: dayjs(contractStartDate).add(1, 'y').subtract(1, 'd').endOf('day').valueOf(),
      }))
    }

    /**
     * 3-1. クーポンを使って契約情報契約プランを作成する場合の処理
     */
    if (
      selectedPlanGroupId === 'limitedTimePaidPlan' ||
      selectedPlanGroupId === 'limitedTimePaidPlanForAnnual'
    ) {
      const coupon = signupPageStore.coupons.value[0]
      const saveContractInfoPlanCouponResult = await signupPageStore.saveContractInfoPlanCoupon(
        contractInfoId,
        coupon.couponId as string,
        coupon.isSettingNumberOfDays,
      )
      if (!saveContractInfoPlanCouponResult.isSuccess) {
        return saveContractInfoPlanCouponResult
      }

      // 紹介コードのクーポンなら、ミッションログを登録する
      if (coupon.isReferralCode) {
        saveMissionHistory(
          coupon.couponId || '',
          'master_coupon',
          'invite_using_referral_code',
          coupon.distributorUserId,
        )
      }

      // 月額プランを契約した場合、月額プランの予定請求を再計算する
      await recalculateBillingInfo(contractInfoId)

      // 契約情報契約プランAPIのレスポンスを返却
      return saveContractInfoPlanCouponResult
    }

    /**
     * 3-2. クーポンを使わずに契約情報契約プランを作成する場合の処理
     */
    if (!plans) {
      return { isSuccess: false, response: {} as APIResponse }
    }

    const saveContractInfoPlanResult = await signupPageStore.saveContractInfoPlan(
      contractInfoId,
      plans,
    )
    if (!saveContractInfoPlanResult.isSuccess) {
      return saveContractInfoPlanResult
    }

    if (selectedPlanGroupId === 'monthlyPlan') {
      // 月額プランを契約した場合、月額プランの予定請求を再計算する
      await recalculateBillingInfo(contractInfoId)
    }

    if (selectedPlanGroupId === 'annualPlan' || selectedPlanGroupId === 'monthlyPlan') {
      // 年払いプランまたは月払いプランに変更する場合、現在契約中のプランを終了させる
      const endDate = plans[0].startDate - 1
      const endCurrentPlansResult = await endCurrentPlans(contractInfoId as string, endDate)
      const failedResponse = endCurrentPlansResult.find((response) => !response.isSuccess)
      if (failedResponse) {
        return failedResponse
      }
    }
    // 契約情報契約プランAPIのレスポンスを返却
    return saveContractInfoPlanResult
  }

  /**
   * 契約情報契約プランを登録。以下の流れで処理を行う。
   * 1. 利用中の契約情報、契約情報契約プランを取得
   * 2. 契約情報を更新
   * 3. SFgo組織の契約プラン情報のうち、以下の通りプランをセットして、契約情報契約プランを登録
   *  - 無料会員登録の場合：無料プラン（selectedPlanGroupIdがfreePlanのもの）
   *  - 有料会員登録の場合：ユーザーが選択した有料プラン
   *  契約情報契約プラン登録については、以下どちらかを実行する
   *  3-1. クーポンを使ってプラン登録
   *  3-2. クーポンを使わずにプラン登録
   */
  const createContract = async (selectedPlanGroupId: PlanGroupType, memberType: MemberType) => {
    /* 1. 現在利用している契約情報、契約情報契約プランを取得 */
    await signupPageStore.fetchContractInfo()
    const { contractInfoId } = signupPageStore.ownContractInfo.value
    await signupPageStore.fetchContractInfoPlans(contractInfoId as string)

    Logger.info(`useRaces#createContract: memberType: ${memberType}`)
    /** 2. 契約情報を更新 */
    const updateContractInfoResult = await updateContractInfo(memberType)
    if (!updateContractInfoResult.isSuccess) {
      return updateContractInfoResult
    }

    /** 3. 契約情報契約プランを作成 */
    return createContractInfoPlan(selectedPlanGroupId, contractInfoId as string)
  }

  /**
   * 契約中のプランを終了させる。（有料→有料に変更用の処理）
   */
  const endCurrentPaidPlans = async (
    contractInfoId: string,
    endDate: number,
    contractInfoPlans: Array<ContractInfoPlanDocument>,
  ) => {
    const { updateContractInfoPlan } = useContractInfoPlan()

    // endDateが設定されていないプラン
    const currentPlans = contractInfoPlans?.filter((plan) => !plan.endDate)

    // eslint-disable-next-line no-restricted-syntax
    for (const planData of currentPlans) {
      // 現在契約中のプランを終了
      // eslint-disable-next-line no-await-in-loop
      const result = await updateContractInfoPlan(contractInfoId, planData.id as string, endDate)
      updateContractInfoPlanRes.push(result)
    }
    return updateContractInfoPlanRes
  }

  /**
   * 支払いプランを切り替える（有料→有料）。
   */
  const changePaidContractInfoPlan = async (
    selectedPlanGroupId: PlanGroupType,
    contractInfoId: string,
    scheduledContractTerm: ContractTermType,
    contractInfoPlans: Array<ContractInfoPlanDocument>,
  ) => {
    const { fetchContractPlans, selectedContractPlanIds } = useContractPlan()
    const { saveContractInfoPlan } = useContractInfoPlan()
    const { recalculateBillingInfo } = useContractInfo()

    // SFgo組織の契約プラン情報を取得する
    await fetchContractPlans()
    // プランのidを取得
    const targetPlanIds = selectedContractPlanIds(selectedPlanGroupId)
    // 契約開始日：呼び出し元で契約期間が不明な場合は処理を行わないよう制御しているためnullは考慮しない
    const contractStartDate = scheduledContractTerm.contractStartDate as number
    let plans = null
    // 契約プランに登録するプランデータを格納
    if (selectedPlanGroupId === 'monthlyPlan') {
      // 月額プランを契約する場合
      plans = targetPlanIds.map((id) => ({
        contractPlanId: id,
        startDate: contractStartDate,
      }))
    }
    if (selectedPlanGroupId === 'annualPlan') {
      // 年額プランを契約する場合
      plans = targetPlanIds.map((id) => ({
        contractPlanId: id,
        startDate: contractStartDate,
        // 終了日：新プラン契約開始日+1年-1日の23:59:59
        endDate: dayjs(contractStartDate).add(1, 'y').subtract(1, 'd').endOf('day').valueOf(),
      }))
    }

    /** 契約情報契約プランを作成する場合の処理 */
    if (!plans) {
      return { isSuccess: false, response: {} as APIResponse }
    }

    const saveContractInfoPlanResult = await saveContractInfoPlan(contractInfoId, plans)
    if (!saveContractInfoPlanResult.isSuccess) {
      return saveContractInfoPlanResult
    }

    if (selectedPlanGroupId === 'monthlyPlan') {
      // 月額プランを契約した場合、月額プランの予定請求を再計算する
      await recalculateBillingInfo(contractInfoId)
    }

    // 現在契約中のプランを終了させる
    const endDate = plans[0].startDate - 1
    const endCurrentPlansResult = await endCurrentPaidPlans(
      contractInfoId as string,
      endDate,
      contractInfoPlans,
    )
    const failedResponse = endCurrentPlansResult.find((response) => !response.isSuccess)
    if (failedResponse) {
      return failedResponse
    }

    // 契約情報契約プランAPIのレスポンスを返却
    return saveContractInfoPlanResult
  }

  /**
   * 支払いプランの切り替え（有料→有料）。以下の流れで処理を行う。
   * 1. 利用中の契約情報、契約情報契約プランを取得
   * 2. SFgo組織の契約プラン情報のうち、ユーザーが選択したプランをセットして、契約情報契約プランを登録
   */
  const changePaidPlan = async (
    selectedPlanGroupId: PlanGroupType,
    scheduledContractTerm: ContractTermType,
  ) => {
    const { fetchContractInfo, ownContractInfo } = useContractInfo()
    const { fetchContractInfoPlans, contractInfoPlans } = useContractInfoPlan()

    /* 契約期間（プラン開始日）が不明な場合は処理を行わない */
    if (!scheduledContractTerm.contractStartDate) {
      return { isSuccess: false, response: {} as APIResponse }
    }

    /* 1. 現在利用している契約情報、契約情報契約プランを取得 */
    await fetchContractInfo()
    const { contractInfoId } = ownContractInfo.value
    await fetchContractInfoPlans(contractInfoId as string)

    /** 2. 契約情報契約プランを作成 */
    return changePaidContractInfoPlan(
      selectedPlanGroupId,
      contractInfoId as string,
      scheduledContractTerm,
      contractInfoPlans.value,
    )
  }

  /**
   * 契約中のプラン、将来のプランを終了させる。（有料プランを解約用の処理）
   */
  const endCurrentPaidPlansForCancel = async (
    contractInfoId: string,
    endDate: number,
    contractInfoPlans: Array<ContractInfoPlanDocument>,
  ) => {
    const { updateContractInfoPlan } = useContractInfoPlan()

    /** 以下の条件で終了させるプランを抽出
     * 1. endDateが設定されていないプラン
     * 2. 将来のプラン
     *  ※将来のプランがある場合に解約できるパターンは、現在のプランがトライアルプランかクーポンプランの場合（解約するボタンの活性/非活性で制御している）
     */
    const currentPlans = contractInfoPlans?.filter(
      (plan) => !plan.endDate || (plan.startDate && plan.startDate > endDate),
    )

    // eslint-disable-next-line no-restricted-syntax
    for (const planData of currentPlans) {
      // 現在契約中のプランを終了
      // eslint-disable-next-line no-await-in-loop
      const result = await updateContractInfoPlan(contractInfoId, planData.id as string, endDate)
      updateContractInfoPlanRes.push(result)
    }
    return updateContractInfoPlanRes
  }

  /**
   * 有料プランを解約する。
   */
  const cancelPaidContractInfoPlan = async (
    contractInfoId: string,
    scheduledContractTerm: ContractTermType,
    contractInfoPlans: Array<ContractInfoPlanDocument>,
  ) => {
    const { fetchContractPlans, selectedContractPlanIds } = useContractPlan()
    const { saveContractInfoPlan } = useContractInfoPlan()

    // SFgo組織の契約プラン情報を取得する
    await fetchContractPlans()
    // 無料プランのidを取得
    const targetPlanIds = selectedContractPlanIds('freePlan')
    // 契約開始日：呼び出し元で契約期間が不明な場合は処理を行わないよう制御しているためnullは考慮しない
    const contractStartDate = scheduledContractTerm.contractStartDate as number
    // 契約プランに登録するプランデータを格納
    const plans = targetPlanIds.map((id) => ({
      contractPlanId: id,
      startDate: contractStartDate,
    }))

    const saveContractInfoPlanResult = await saveContractInfoPlan(contractInfoId, plans)
    if (!saveContractInfoPlanResult.isSuccess) {
      return saveContractInfoPlanResult
    }

    // 現在契約中のプランを終了させる
    const endDate = plans[0].startDate - 1
    const endCurrentPlansResult = await endCurrentPaidPlansForCancel(
      contractInfoId as string,
      endDate,
      contractInfoPlans,
    )
    const failedResponse = endCurrentPlansResult.find((response) => !response.isSuccess)
    if (failedResponse) {
      return failedResponse
    }

    // 契約情報契約プランAPIのレスポンスを返却
    return saveContractInfoPlanResult
  }

  /**
   * 有料プランを解約。以下の流れで処理を行う。
   * 1. 契約情報契約プランを取得
   * 2. SFgo組織の契約プラン情報のうち、ユーザーが選択したプランをセットして、契約情報契約プランを登録
   */
  const cancelPaidPlan = async (
    selectedPlanGroupId: PlanGroupType,
    scheduledContractTerm: ContractTermType,
    contractInfoId: string,
  ) => {
    const { fetchContractInfoPlans, contractInfoPlans } = useContractInfoPlan()

    /* 契約期間（無料プランの開始日時）が不明、またはselectedPlanGroupIdが存在しない場合は処理を行わない */
    // selectedPlanGroupIdが存在しない場合は、解約できないように画面で制御しているが、デベロッパーツールで解除されても解約処理を実行させないようにする
    if (!scheduledContractTerm.contractStartDate || !selectedPlanGroupId) {
      return { isSuccess: false, response: {} as APIResponse }
    }

    /* 1. 契約情報契約プランを取得 */
    await fetchContractInfoPlans(contractInfoId as string)

    /** 2. 有料プランを解約 */
    return cancelPaidContractInfoPlan(
      contractInfoId as string,
      scheduledContractTerm,
      contractInfoPlans.value,
    )
  }

  return {
    createContract,
    changePaidPlan,
    cancelPaidPlan,
  }
}
