










































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import FilterSelectorParts, {
  FilterList,
} from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/FilterSelectorParts.vue'
import StoreUtil from '@/store/StoreUtil'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'
import type {
  HighlightUserFilterType,
  HighlightTabsType,
} from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'

type DataType = {
  driverFilterId: string
}

/**
 * レース動画再生画面 ハイライト表示 ヘッダーセクションのコンポーネント
 */
export default defineComponent({
  name: 'HighlightHeaderSection',
  components: {
    FilterSelectorParts,
  },
  data(): DataType {
    return {
      driverFilterId: '',
    }
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
  },
  setup(props) {
    const screenLandScape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

    const playEvents = computed(() =>
      raceVideoPageStore.playEvents.value.map((playEvent) => ({
        id: playEvent.id ?? '',
        name: {
          ja: playEvent.name.ja ?? '',
          en: playEvent.name.en ?? '',
        },
      })),
    )

    return {
      selectedHighlightTab: raceVideoPageStore.selectedHighlightTab as Ref<HighlightTabsType>,
      screenLandScape,
      driverFilterList: raceVideoPageStore.participatingPlayers,
      sceneFilterList: playEvents as Ref<Array<FilterList>>,
      userFilterList: raceVideoPageStore.userFilterListForUserCreateHighlights as Ref<
        Array<FilterList>
      >,
    }
  },
  methods: {
    /**
     * 選手・シーンフィルタが選択・解除された場合に呼び出される。
     */
    handleEnabledFilterSelect(value: boolean): void {
      this.$emit('filterSelectEnabled', value)
    },
    onChangeDriverFilter(id: string): void {
      this.$emit('onChangeDriverFilter', id)
    },
    onChangeSceneOrUserFilter(id: string, type?: HighlightUserFilterType): void {
      this.$emit('onChangeSceneOrUserFilter', id, type)
    },
  },
})
