










import { defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import MypageDetailsSettingSection from '@/components/MypageDetailsSettingPage/MypageDetailsSettingPane/MypageDetailsSettingSection.vue'
import MypageLinksSection from '@/components/MypageTopPage/MypageTopPane/MypageLinksSection.vue'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'

interface DetailsListData {
  name: string
  link: string
  text: string
}
interface OtherLinkItemType {
  name: string
  link: string
  blank: boolean
  isDisplay: boolean
}

/**
 * マイページ: 詳細設定 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageDetailsSettingPane',
  components: {
    MypageDetailsSettingSection,
    MypageLinksSection,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')

    // 無料プランフラグ
    const { isFreePlan } = contractInfoStore

    // 詳細情報 データリスト
    const systemListItems = [
      {
        name: I18n.tc('MypagePage.MypageDetailsSettingPage.systemData.licence'),
        text: '',
        link: './licenses.html',
      },
      {
        name: I18n.tc('MypagePage.MypageDetailsSettingPage.systemData.version'),
        text: `Ver ${process.env.VUE_APP_SFGO_APP_VERSION}`,
        link: '',
      },
    ] as Array<DetailsListData>

    // その他リンクリスト
    const otherLinkItems = [
      {
        name: I18n.tc('common.withdrawal'),
        link: DeviceInfo.isCordova()
          ? `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/cancel-account`
          : '/cancel/confirm',
        blank: DeviceInfo.isCordova(),
        isDisplay: isFreePlan,
      },
    ] as Array<OtherLinkItemType>

    return {
      systemListItems,
      otherLinkItems,
    }
  },
})
