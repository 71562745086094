import { MissionOperationType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * ミッションと紐づく操作ログを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/774308676/46+API
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3682271253/46+API
 */
export default class MissionHistoryDocument extends DocumentWrapper {
  constructor(initProps?: Partial<MissionHistoryDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/operation_log'

  idAttr = '_id'

  /**
   * 操作ログID
   */
  targetId: string | null = null

  /**
   * 操作対象のコレクション名
   */
  targetCollectionName: string | null = null

  /**
   * 操作種別
   */
  operation: MissionOperationType | null = null

  /**
   * 通知利用フラグ
   */
  needNotification: boolean | null = false

  /**
   * ミッション対象先ユーザID
   */
  missionTargetUserId: string | null = null
}
