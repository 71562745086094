var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "atom-input-checkbox",
      class: "atom-input-checkbox--" + _vm.color,
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        attrs: { type: "checkbox", disabled: _vm.isDisabled },
        domProps: {
          checked: Array.isArray(_vm.inputValue)
            ? _vm._i(_vm.inputValue, null) > -1
            : _vm.inputValue,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.inputValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputValue = $$c
            }
          },
        },
      }),
      _c("span", { staticClass: "atom-input-checkbox__checkbox" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "14",
              height: "9",
              viewBox: "0 0 14 9",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M11.0838 0L5.0425 6.67722C4.94325 6.78692 4.77098 6.78692 4.67173 6.67722L2.24939 3.9999H0.226562L3.55943 7.68359C4.25417 8.45146 5.46007 8.45146 6.1548 7.68359L13.1066 0H11.0838Z",
                fill: "#00DBA6",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }