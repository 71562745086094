


























import { defineComponent, ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import PasswordResetNewPasswordPane from '@/components/PasswordResetPage/PasswordResetNewPasswordPane.vue'

type DataType = {
  changeButtonDisabled: boolean
}
/**
 * パスワードリセット 新パスワード入力 ページ
 */
export default defineComponent({
  name: 'PasswordResetNewPasswordPage',
  components: {
    SubHeaderSection,
    ActionButtonsSection,
    AtomInputButton,
    AtomRouterButton,
    PasswordResetNewPasswordPane,
  },
  data(): DataType {
    return {
      // リセットボタンの非活性化フラグ
      changeButtonDisabled: true,
    }
  },
  setup() {
    const passwordResetNewPasswordPane = ref<InstanceType<typeof PasswordResetNewPasswordPane>>()
    return {
      passwordResetNewPasswordPane,
    }
  },
  mounted() {
    // スマートフォンのみ使える判定
    if (window.cordova && window.Keyboard) {
      window.Keyboard.hideFormAccessoryBar(false)
    }
  },
  methods: {
    changeButtonDisabledStatus(status: boolean): void {
      /**
       * リセットボタンの非活性化フラグ設定
       */
      this.changeButtonDisabled = status
    },
    resetPassword(): void {
      /**
       * パスワードリセット処理
       */
      return this.passwordResetNewPasswordPane?.passwordReset()
    },
    /**
     * パスワードリセット完了ページに遷移する
     */
    moveToComplete(): void {
      this.$router.replace({ path: '/password-reset/complete' })
    },
  },
})
