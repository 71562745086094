var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "player-select-modal-section",
    class: "player-select-modal-section--" + _vm.screenOrientationType,
    attrs: {
      hasCloseButton: _vm.favoriteRacePlayerSelected,
      hasButtons: false,
    },
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("modal-header-parts", {
              attrs: { title: _vm.$t("RaceVideoPage.PlayerSelectModal.title") },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("select-list-section", {
              attrs: {
                listData: _vm.exactParticipatingPlayers,
                selectedID: _vm.selectedPlayerId || "",
              },
              on: { selectChange: _vm.changeSelected },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (slotProps) {
                    return [
                      _c("player-card-parts", {
                        attrs: {
                          name: slotProps.item.playerName[_vm.$i18n.locale],
                          img: _vm.playerPictureUrl(slotProps.item),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$t("common.cancel"),
                colorType: "secondary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }