import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * テレメトリーデータ補正情報。
 * 動画の途中で、動画再生位置と、テレメトリーデータがずれた場合、この情報で補正する。
 * 例えば、動画の再生位置60秒目で、4秒の映像抜けが発生した場合、
 * syncPoint: 60
 * adjustValue: 4
 * を指定することで、動画再生位置60秒以降は、4秒加算した値で、テレメトリーデータとの位置あわせが実施される。
 */
export type TelemetrySyncInfoType = {
  /**
   * 補正を行う再生位置(秒)
   */
  syncPoint: number
  /**
   * 補正値(秒)
   */
  adjustValue: number
}

/**
 * 英語の実況中継映像としてアングル情報を登録しているか区別するための型
 */
type EnglishLiveVideoType = 'english_language_live_video'

/**
 * アングル情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/572784793/01+API
 */
export default class AngleInfoDocument extends DocumentWrapper {
  constructor(initProps?: Partial<AngleInfoDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/angle/info'

  idAttr = 'angleId'

  /**
   * 試合ID
   */
  matchId: null | string = null

  /**
   * レースID
   */
  get raceId() {
    return this.matchId
  }

  /**
   * アングル情報ID
   */
  angleId: null | string = null

  /**
   * アングル名
   */
  angleName: null | Multilingual = null

  /**
   * アングル情報の追加項目。
   * このアングルに関連づけらている選手の選手IDが設定される。
   */
  additionalData: null | {
    /** このアングルに関連する選手ID */
    playerId: string
    /** テレメトリーデータとの位置合わせを行う際の補正情報 */
    telemetrySyncInfos: Array<TelemetrySyncInfoType>
    /** ステアリング補正フラグ。true の場合、ステアリングの正負を逆転させる */
    steeringCorrection: boolean
    /**
     * 英語の実況中継映像としてアングル情報を登録しているかどうか
     * 英語の実況中継映像の場合、'english_language_live_video'が固定でセットされている
     */
    videoType?: EnglishLiveVideoType
    /** Program Date Time を補正したプレイリストの利用有無 */
    usePDTAdjustedPlaylist: boolean
  } = null

  /**
   * ステアリング補正フラグ
   */
  get steeringCorrection() {
    return !!this.additionalData?.steeringCorrection
  }

  /**
   * PDT補正プレイリストの利用有無
   */
  get usePDTAdjustedPlaylist() {
    return !!this.additionalData?.usePDTAdjustedPlaylist
  }

  /**
   * 指定された再生位置での、テレメトリーデータとの補正情報を取得する。
   * @param currentTime 動画再生位置
   */
  computeTelemetryAdjustValue(currentTime: number) {
    return this.additionalData?.telemetrySyncInfos?.reduce(
      (previousValue, currentSyncInfo) => {
        if (currentTime >= currentSyncInfo.syncPoint) {
          return {
            syncPoint: currentTime,
            adjustValue: previousValue.adjustValue + currentSyncInfo.adjustValue,
          }
        }
        return previousValue
      },
      { syncPoint: currentTime, adjustValue: 0 },
    )
  }

  /**
   * このアングルに紐づけられている選手の選手IDを取得する。
   */
  get playerId() {
    return this.additionalData?.playerId
  }

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `AngleInfoDocument[matchId: ${this.matchId}, angleId: ${this.angleId}}, angleName: ${this.angleName}]`
  }
}
