var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calendar-pane",
      class: { "calendar-pane--is-pc": _vm.isPc },
    },
    [
      _c("calendar-year-month-selector-section", {
        attrs: {
          calendarYearList: _vm.calendarYearList,
          calendarYearAndMonthList: _vm.calendarYearAndMonthList,
          selectedYearOrMonth: _vm.selectedYearOrMonth,
        },
        on: { onYearsAndMonthsSelect: _vm.changeYearsAndMonths },
      }),
      _c(
        "div",
        {
          staticClass: "calendar-pane__container",
          class: {
            "calendar-pane__container--open-filter": _vm.openFilterId !== "",
          },
        },
        [
          _c("div", { staticClass: "calendar-pane__inner" }, [
            _c(
              "div",
              { staticClass: "calendar-pane__filter" },
              [
                _c("calendar-filter-section", {
                  attrs: {
                    placeList: _vm.placeList,
                    categoryList: _vm.categoryList,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "calendar-pane__list" },
              [
                _c("calendar-list-section", {
                  attrs: { calendarData: _vm.filteredCalendarData },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }