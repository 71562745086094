var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-link-buttons-section" }, [
    _c("ul", { staticClass: "home-link-buttons-section__list" }, [
      _c(
        "li",
        { staticClass: "home-link-buttons-section__item" },
        [
          _c(
            "router-link",
            {
              staticClass: "home-link-buttons-section__button",
              attrs: { to: "/" },
            },
            [
              _c(
                "span",
                { staticClass: "home-link-buttons-section__button-icon" },
                [_c("home-race-icon")],
                1
              ),
              _c(
                "span",
                { staticClass: "home-link-buttons-section__button-label" },
                [_vm._v(_vm._s(_vm.$tc("HomePage.links.race")))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "li",
        { staticClass: "home-link-buttons-section__item" },
        [
          _c(
            "router-link",
            {
              staticClass: "home-link-buttons-section__button",
              attrs: { to: "/standings" },
            },
            [
              _c(
                "span",
                { staticClass: "home-link-buttons-section__button-icon" },
                [_c("home-standings-icon")],
                1
              ),
              _c(
                "span",
                { staticClass: "home-link-buttons-section__button-label" },
                [_vm._v(_vm._s(_vm.$tc("HomePage.links.standings")))]
              ),
            ]
          ),
        ],
        1
      ),
      _c("li", { staticClass: "home-link-buttons-section__item" }, [
        _c(
          "a",
          {
            staticClass: "home-link-buttons-section__button",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.onClickAnchorLink(_vm.SFBingoUrl)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "home-link-buttons-section__button-icon" },
              [_c("home-bingo-icon")],
              1
            ),
            _c(
              "span",
              { staticClass: "home-link-buttons-section__button-label" },
              [_vm._v(_vm._s(_vm.$tc("HomePage.links.bingo")))]
            ),
          ]
        ),
      ]),
      _c(
        "li",
        { staticClass: "home-link-buttons-section__item" },
        [
          _c(
            "router-link",
            {
              staticClass: "home-link-buttons-section__button",
              attrs: { to: "/reward" },
            },
            [
              _c(
                "span",
                { staticClass: "home-link-buttons-section__button-icon" },
                [_c("home-reward-icon")],
                1
              ),
              _c(
                "span",
                { staticClass: "home-link-buttons-section__button-label" },
                [_vm._v(_vm._s(_vm.$tc("HomePage.links.reward")))]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }