var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "payment-cycle-section" }, [
    _c("div", { staticClass: "payment-cycle-section__explanation" }, [
      _c("h4", { staticClass: "payment-cycle-section__title" }, [
        _vm._v(" " + _vm._s(_vm.$tc("common.billing.paymentCycle")) + " "),
      ]),
      _vm.paymentCycleImgPath.sp
        ? _c("img", {
            staticClass:
              "payment-cycle-section__img payment-cycle-section__img--sp",
            attrs: {
              src: require("@/assets/img/" + _vm.paymentCycleImgPath.sp),
              alt: "",
            },
          })
        : _vm._e(),
      _vm.paymentCycleImgPath.pc
        ? _c("img", {
            staticClass:
              "payment-cycle-section__img payment-cycle-section__img--pc",
            attrs: {
              src: require("@/assets/img/" + _vm.paymentCycleImgPath.pc),
              alt: "",
            },
          })
        : _vm._e(),
      _c("ul", {
        staticClass: "payment-cycle-section__attentions",
        domProps: { innerHTML: _vm._s(_vm.attention) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }