import { uniq } from 'lodash'
import dayjs from 'dayjs'
import DigitalTicketDataTicketDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketDocument'
import DigitalTicketDataDocument, {
  OpeningDatesType,
} from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

export type PurchasedTicketListType = {
  all: Array<string>
  availableToday: Array<string>
}

/**
 * ログインユーザーのチケット購入情報を管理する型
 */
export type UserPurchasedTicketType = {
  ticketEventUniqueKeys: PurchasedTicketListType
  ticketTypeIds: PurchasedTicketListType
  productNumbers: PurchasedTicketListType
}

/**
 * ログインユーザーが購入したチケット情報に関する機能を提供する
 */
export default function usePurchasedTicketData() {
  const { getDisplayDate } = useDisplayDependingOnLang()

  /**
   * 購入した商品かどうかを判定
   */
  const hasPurchased = (
    organization: OrganizationDocument | undefined,
    ticket: DigitalTicketDataTicketDocument,
  ) =>
    organization?.additionalData?.ec?.purchasedProducts?.some(
      (product) => product.productNumber === ticket.productNumber,
    )

  /**
   * チケットイベントの中から開場日時が今日の情報を探す
   */
  const matchedTodayDate = (ticketEvent: DigitalTicketDataDocument) => {
    /** 今日の0:00:00 */
    const beginningOfTheDay = dayjs().startOf('day').valueOf()
    /** 今日の23:59:59 */
    const endOfTheDay = dayjs().endOf('day').valueOf()
    const matchedOpeningDate = Object.entries(ticketEvent.openingDates ?? {}).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_key, date]) => beginningOfTheDay <= date && date <= endOfTheDay,
    )

    return {
      key: matchedOpeningDate?.[0] as OpeningDatesType | undefined,
      value: matchedOpeningDate?.[1],
    }
  }

  /**
   * ユーザーが購入したチケットに紐づく以下の情報をセットする
   * デジタルチケットのuniqueKey
   * デジタルチケット券種の券種ID
   */
  const userPurchasedTicket = (
    digitalTickets: Array<DigitalTicketDataDocument> | undefined,
    organization: OrganizationDocument | undefined,
  ): UserPurchasedTicketType => {
    // ログインユーザーが購入したチケットに紐づいているデジタルチケットのuniqueKey（パーティションキー+ソートキー）
    const ticketEventUniqueKeys: PurchasedTicketListType = {
      all: [],
      availableToday: [],
    }
    // ログインユーザーが購入したチケットに紐づいている券種の券種ID
    const ticketTypeIds: PurchasedTicketListType = {
      all: [],
      availableToday: [],
    }
    // ログインユーザーが購入したチケットに紐づいている商品コード
    const productNumbers: PurchasedTicketListType = {
      all: [],
      availableToday: [],
    }

    digitalTickets?.forEach((ticketEvent) => {
      ticketEvent.ticketTypes?.forEach((ticketType) => {
        ticketType.tickets?.forEach((ticket) => {
          if (hasPurchased(organization, ticket)) {
            // 購入した商品の場合
            ticketEventUniqueKeys.all.push(ticketEvent.uniqueKey)
            ticketTypeIds.all.push(ticketType.ticketTypeId)
            productNumbers.all.push(ticket.productNumber ?? '')

            const openingTodayInfo = matchedTodayDate(ticketEvent)
            if (openingTodayInfo.key && ticket.availableDates?.[openingTodayInfo.key]) {
              // 開場日時が今日のチケットイベントが存在し、かつ、チケットが今日利用できるものである場合、availableTodayに追加する
              ticketEventUniqueKeys.availableToday.push(ticketEvent.uniqueKey)
              ticketTypeIds.availableToday.push(ticketType.ticketTypeId)
              productNumbers.availableToday.push(ticket.productNumber ?? '')
            }
          }
        })
      })
    })

    return {
      ticketEventUniqueKeys: {
        all: uniq(ticketEventUniqueKeys.all),
        availableToday: uniq(ticketEventUniqueKeys.availableToday),
      },
      ticketTypeIds: {
        all: uniq(ticketTypeIds.all),
        availableToday: uniq(ticketTypeIds.availableToday),
      },
      productNumbers: {
        all: uniq(productNumbers.all),
        availableToday: uniq(productNumbers.availableToday),
      },
    }
  }

  /**
   * 対象イベントのn日目が入場済みかどうか
   * return { boolean }
   */
  const isEnteredEventDate = (
    ticketType: DigitalTicketDataTicketTypeDocument,
    organization: OrganizationDocument | undefined,
    targetDay: OpeningDatesType | undefined,
  ) =>
    ticketType.tickets?.some((ticket) => {
      if (!targetDay) {
        return false
      }
      const isTargetDateAvailable = ticket.availableDates?.[targetDay]
      if (!isTargetDateAvailable) {
        // 対象日に利用不可だった場合falseを返す
        return false
      }
      return Object.entries(
        organization?.additionalData?.ec?.ticketUseHistoriesByProductNumber ?? {},
      )?.some(([key, value]) => key === ticket.productNumber && value.enteredDates?.[targetDay])
    })

  /**
   * 対象イベントが入場（受付）済みかどうか
   * - 受付が１回のみ：1〜３日目のどれかで受付済みなら受付済みとなる
   * - 受付が１回のみではない：n日目が入場済みかどうか
   * return { boolean }
   */
  const isEnteredTargetEventDate = (
    ticketEvent: DigitalTicketDataDocument,
    organization: OrganizationDocument | undefined,
    targetDay: OpeningDatesType | undefined,
  ) =>
    ticketEvent.ticketTypes?.some((ticketType) => {
      if (ticketType.isOnlyOneReception) {
        // 受付が１回のみの券種
        if (!ticketEvent.openingDates) return false
        const openingDateKeys = Object.keys(ticketEvent.openingDates)
        return openingDateKeys.some((date) =>
          isEnteredEventDate(ticketType, organization, date as OpeningDatesType),
        )
      }
      // 受付が１回のみではない券種
      return isEnteredEventDate(ticketType, organization, targetDay)
    })

  /**
   * 対象チケットの使用済みにした日時を取得
   * 対象の券種に紐づく複数の入場チケットが存在する場合は同じ入場日が入るため、入場チケットを使用済みにした日時を一つ返す
   */
  const getTicketUsedDateTime = (
    ticketType: DigitalTicketDataTicketTypeDocument,
    organization: OrganizationDocument | undefined,
    targetDay: OpeningDatesType | undefined,
  ) => {
    let enteredDate = 0
    Object.entries(
      organization?.additionalData?.ec?.ticketUseHistoriesByProductNumber ?? {},
    )?.forEach(([key, value]) => {
      if (!targetDay) {
        return
      }
      const hasEntered = ticketType.tickets?.some((ticket) => ticket.productNumber === key)
      if (hasEntered && value.enteredDates?.[targetDay]) {
        enteredDate = value.enteredDates?.[targetDay]
      }
    })

    return enteredDate
      ? getDisplayDate(enteredDate, 'YYYY/MM/DD HH:mm:ss', 'MMM DD, YYYY HH:mm:ss')
      : undefined
  }

  /**
   * 対象チケットの使用済みにした日時を取得
   * - 受付が１回のみ：1〜３日目のどれかで使用済みにした日時
   * - 受付が１回のみではない：n日目で使用済みにした日時
   */
  const getTargetTicketUsedDateTime = (
    ticketEvent: DigitalTicketDataDocument,
    ticketType: DigitalTicketDataTicketTypeDocument,
    organization: OrganizationDocument | undefined,
  ) => {
    if (ticketType.isOnlyOneReception) {
      // 受付が１回のみの券種
      if (!ticketEvent.openingDates) return undefined
      const openingDateKeys = Object.keys(ticketEvent.openingDates)
      let ticketUsedDateTimeResult: string | undefined
      // eslint-disable-next-line no-restricted-syntax
      for (const key of openingDateKeys) {
        ticketUsedDateTimeResult = getTicketUsedDateTime(
          ticketType,
          organization,
          key as OpeningDatesType,
        )
        if (ticketUsedDateTimeResult) break
      }
      return ticketUsedDateTimeResult
    }
    // 受付が１回のみではない券種
    // 今日の開催日時情報
    const openingTodayInfo = matchedTodayDate(ticketEvent)
    return getTicketUsedDateTime(ticketType, organization, openingTodayInfo.key)
  }

  /**
   * 対象チケットの購入数
   * 同じチケットを分けて購入した場合、購入履歴が複数できるため、対象商品の購入数を合算して返す
   */
  const getTicketTotalCountByTicket = (
    targetTicket: DigitalTicketDataTicketDocument,
    organization: OrganizationDocument | undefined,
  ) => {
    const targetProducts = organization?.ec?.purchasedProducts?.filter(
      (product) => product.productNumber === targetTicket.productNumber,
    )
    return targetProducts?.reduce((sum, product) => sum + (product.quantity ?? 0), 0) ?? 0
  }

  /**
   * 対象券種に紐づく今日利用可能なチケットの購入数
   * 同じチケットを分けて購入した場合、購入履歴が複数できるため、対象商品の購入数を合算して返す
   */
  const getTodayTicketTotalCountByTicketType = (
    ticketType: DigitalTicketDataTicketTypeDocument,
    organization: OrganizationDocument | undefined,
    availableTodayProductNumbers: Array<string>,
  ) => {
    const targetProducts = organization?.ec?.purchasedProducts?.filter((product) => {
      const hasPurchasedTicket = ticketType.tickets?.some(
        (ticket) => ticket.productNumber === product.productNumber,
      )
      const isAvailableToday = availableTodayProductNumbers.some(
        (productNumber) => productNumber === product.productNumber,
      )
      // 購入済みでかつ今日利用可能なチケットを抽出
      return hasPurchasedTicket && isAvailableToday
    })
    return targetProducts?.reduce((sum, product) => sum + (product.quantity ?? 0), 0) ?? 0
  }

  return {
    matchedTodayDate,
    getTargetTicketUsedDateTime,
    userPurchasedTicket,
    isEnteredTargetEventDate,
    getTicketTotalCountByTicket,
    getTodayTicketTotalCountByTicketType,
  }
}
