



















































import { defineComponent } from '@vue/composition-api'
import HomeRaceIcon from '@/components/HomePage/HomePane/parts/icons/HomeRaceIcon.vue'
import HomeStandingsIcon from '@/components/HomePage/HomePane/parts/icons/HomeStandingsIcon.vue'
import HomeBingoIcon from '@/components/HomePage/HomePane/parts/icons/HomeBingoIcon.vue'
import InAppBrowserWrapper, { InAppBrowserResult } from '@/util/inAppBrowser/InAppBrowserWrapper'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import HomeRewardIcon from '@/components/HomePage/HomePane/parts/icons/HomeRewardIcon.vue'

export default defineComponent({
  name: 'HomeLinkButtonsSection',
  components: { HomeRewardIcon, HomeBingoIcon, HomeStandingsIcon, HomeRaceIcon },
  setup() {
    // SF BINGOのURL
    const SFBingoUrl = process.env.VUE_APP_SFBINGO_URL as string

    const inAppBrowser = new InAppBrowserWrapper()

    /**
     * タイルのアンカーリンクがクリックされた場合に呼び出される。
     *
     * @param externalLink 外部サイトのリンク
     */
    const onClickAnchorLink = (externalLink: string) => {
      if (!InAppBrowserWrapper.isAvailable()) {
        // ブラウザの場合、別ウィンドウか別タブで表示する
        const subWindow = window.open(externalLink, '_blank')
        if (subWindow && process.env.VUE_APP_SFBINGO_URL === externalLink) {
          // URLがSFbingoの場合には、APIの認証情報とユーザーIDをローカルストレージに保存する
          LocalStorageAccessor.setCredentialInfo(
            UserStore.value.user.value._organization || '',
            UserStore.value.user.value.id || '',
            LoginStore.value.accessToken || '',
            LoginStore.value.refreshToken || '',
          )
          setTimeout(() => {
            LocalStorageAccessor.clearCredentialInfo()
          }, 60000)
        }

        return
      }

      let injectCode = ''
      if (externalLink === process.env.VUE_APP_SFBINGO_URL) {
        const credentialInfo = {
          organizationId: UserStore.value.user.value._organization,
          userId: UserStore.value.user.value.id,
          accessToken: LoginStore.value.accessToken,
          refreshToken: LoginStore.value.refreshToken,
        }
        const sCredentialInfo = JSON.stringify(credentialInfo)
        injectCode = `window.onLoadCredentials('${sCredentialInfo}');`
      }

      // InAppBrowserでサイトを開く
      inAppBrowser.open(
        externalLink,
        '_blank',
        undefined,
        async (result: InAppBrowserResult) => {
          if (result?.isNetworkError) {
            await MessageDialogStore.value.open({
              title: I18n.t('apiNotification.dialog.0.title') as string,
              message: I18n.t('apiNotification.dialog.0.message') as string,
            })
          } else if (result?.isError) {
            await MessageDialogStore.value.open({
              title: I18n.t('HomePage.errors.headlineLinkError.title') as string,
              message: I18n.t('HomePage.errors.headlineLinkError.message') as string,
            })
          }
        },
        false,
        injectCode,
      )
    }

    return {
      SFBingoUrl,
      onClickAnchorLink,
    }
  },
})
