




















import { computed, defineComponent, PropType } from '@vue/composition-api'
import DigitalTicketDataTicketDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * マイページ: チケット情報パーツ
 */
export default defineComponent({
  name: 'MypageTicketItemDetailParts',
  props: {
    /**
     * デジタルチケット チケット情報
     */
    ticket: {
      type: Object as PropType<DigitalTicketDataTicketDocument>,
      required: true,
    },
    /**
     * ログインユーザーの組織情報
     */
    ownOrganization: {
      type: Object as PropType<OrganizationDocument>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getTicketTotalCountByTicket } = usePurchasedTicketData()
    const { getDisplayDateJa } = useDisplayDependingOnLang()

    /**
     * 対象チケットの税込単価 × 購入枚数
     */
    const displayTaxIncludedPrice = computed(() => {
      const unitPrice = props.ownOrganization.ec?.purchasedProducts?.find(
        (product) => product.productNumber === props.ticket.productNumber,
      )?.unitPrice

      const totalCount = getTicketTotalCountByTicket(props.ticket, props.ownOrganization)

      return unitPrice ? (unitPrice * totalCount).toLocaleString() : ''
    })

    /**
     * 集合時間
     */
    const displayMeetingTime = computed(() =>
      getDisplayDateJa(props.ticket.meetingTime, 'HH:mm', 'HH:mm'),
    )

    return {
      getTicketTotalCountByTicket,
      displayTaxIncludedPrice,
      displayMeetingTime,
    }
  },
})
