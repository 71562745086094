var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "data-view-select-menu-section",
      class: "data-view-select-menu-section--" + _vm.screenOrientationType,
    },
    [
      _c("li", { staticClass: "data-view-select-menu-section__item" }, [
        _c(
          "button",
          {
            staticClass: "data-view-select-menu-section__item-link",
            class: {
              "data-view-select-menu-section__item-link--current":
                _vm.viewMode === "ranking",
            },
            on: {
              click: function ($event) {
                return _vm.changeViewMode("ranking")
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "17",
                  height: "16",
                  viewBox: "0 0 17 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M12.4306 3.15201V1.77734H4.43058V3.15201H1.76392V5.11068C1.76392 6.79401 3.02325 8.17601 4.64658 8.39868C4.8791 9.08054 5.29152 9.68693 5.84024 10.1537C6.38896 10.6205 7.0536 10.9304 7.76392 11.0507V11.7773C7.76392 12.131 7.62344 12.4701 7.37339 12.7202C7.12334 12.9702 6.7842 13.1107 6.43058 13.1107H5.76392V14.444H11.0972V13.1107H10.4306C10.077 13.1107 9.73782 12.9702 9.48777 12.7202C9.23772 12.4701 9.09725 12.131 9.09725 11.7773V11.0507C9.80757 10.9304 10.4722 10.6205 11.0209 10.1537C11.5696 9.68693 11.9821 9.08054 12.2146 8.39868C13.8379 8.17601 15.0972 6.79401 15.0972 5.11068V3.15201H12.4306ZM3.09725 5.11068V4.48534H4.43058V6.99601C4.04091 6.85783 3.70356 6.60243 3.46483 6.26486C3.2261 5.9273 3.0977 5.52413 3.09725 5.11068ZM13.7639 5.11068C13.7634 5.52419 13.635 5.92743 13.3963 6.26509C13.1576 6.60274 12.8203 6.85829 12.4306 6.99668V4.48534H13.7639V5.11068Z",
                    fill: "#C7C7C7",
                  },
                }),
              ]
            ),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("RaceVideoPage.DataViewSelectMenu.ranking"))
              ),
            ]),
          ]
        ),
      ]),
      _c("li", { staticClass: "data-view-select-menu-section__item" }, [
        _c(
          "button",
          {
            staticClass: "data-view-select-menu-section__item-link",
            class: {
              "data-view-select-menu-section__item-link--current":
                _vm.viewMode === "telemetry",
            },
            on: {
              click: function ($event) {
                return _vm.changeViewMode("telemetry")
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "17",
                  height: "16",
                  viewBox: "0 0 17 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("g", { attrs: { "clip-path": "url(#clip0_4550_2201)" } }, [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M4.18056 1.33333C2.95326 1.33333 1.95833 2.32826 1.95833 3.55556V8.88889H0.625V3.55556C0.625 1.59188 2.21688 0 4.18056 0H13.0694C15.0331 0 16.625 1.59188 16.625 3.55556V8.88889H15.2917V3.55556C15.2917 2.32826 14.2967 1.33333 13.0694 1.33333H4.18056ZM10.6734 2.66667C10.5061 2.66667 10.3515 2.75634 10.2685 2.90164L7.27469 8.14082C7.08422 8.47415 7.3249 8.88889 7.70881 8.88889H9.13674C9.35998 8.88889 9.55617 8.7409 9.6175 8.52625L11.1218 3.26113C11.2069 2.96322 10.9832 2.66667 10.6734 2.66667ZM0.625 10.6667H5.95833V12H0.625V10.6667ZM5.95833 13.3333H0.625V14.6667H5.95833V13.3333ZM7.29167 10.6667H9.95833V12H7.29167V10.6667ZM9.95833 13.3333H7.29167V14.6667H9.95833V13.3333ZM11.2917 10.6667H16.625V12H11.2917V10.6667ZM16.625 13.3333H11.2917V14.6667H16.625V13.3333Z",
                      fill: "#C7C7C7",
                    },
                  }),
                ]),
                _c("defs", [
                  _c("clipPath", { attrs: { id: "clip0_4550_2201" } }, [
                    _c("rect", {
                      attrs: {
                        width: "16",
                        height: "16",
                        fill: "white",
                        transform: "translate(0.625)",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("RaceVideoPage.DataViewSelectMenu.telemetry"))
              ),
            ]),
          ]
        ),
      ]),
      _c("li", { staticClass: "data-view-select-menu-section__item" }, [
        _c(
          "button",
          {
            staticClass: "data-view-select-menu-section__item-link",
            class: {
              "data-view-select-menu-section__item-link--current":
                _vm.viewMode === "highlight",
            },
            on: {
              click: function ($event) {
                return _vm.changeViewMode("highlight")
              },
            },
          },
          [
            _vm.newHighlightIcon
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "16",
                      height: "16",
                      viewBox: "0 0 16 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        fill: "#C7C7C7",
                        d: "M1,1.8c-0.6,0-1,0.4-1,1v10.4c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1\n\tV2.8c0-0.6-0.4-1-1-1H1z M1.8,3.6h1.8v1.8H1.8V3.6z M7.1,4.4L13.3,8l-6.2,3.6V4.4z M3.6,10.7H1.8v1.8h1.8V10.7z M1.8,7.1h1.8v1.8\n\tH1.8V7.1z",
                      },
                    }),
                    _c("circle", {
                      attrs: { fill: "#2D2D2D", cx: "15", cy: "2.5", r: "4.5" },
                    }),
                    _c("circle", {
                      attrs: { fill: "#FF2E00", cx: "15", cy: "2.5", r: "2.5" },
                    }),
                  ]
                )
              : _c(
                  "svg",
                  {
                    attrs: {
                      width: "17",
                      height: "16",
                      viewBox: "0 0 17 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c(
                      "g",
                      { attrs: { "clip-path": "url(#clip0_4550_2213)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1.375 1.77734C0.822715 1.77734 0.375 2.22506 0.375 2.77734V13.2218C0.375 13.7741 0.822715 14.2218 1.375 14.2218H15.375C15.9273 14.2218 16.375 13.7741 16.375 13.2218V2.77734C16.375 2.22506 15.9273 1.77734 15.375 1.77734H1.375ZM2.15278 3.55512H3.93056V5.3329H2.15278V3.55512ZM7.48611 4.44401L13.7083 7.99957L7.48611 11.5551V4.44401ZM3.93056 10.6662H2.15278V12.444H3.93056V10.6662ZM2.15278 7.11068H3.93056V8.88846H2.15278V7.11068Z",
                            fill: "#C7C7C7",
                          },
                        }),
                      ]
                    ),
                    _c("defs", [
                      _c("clipPath", { attrs: { id: "clip0_4550_2213" } }, [
                        _c("rect", {
                          attrs: {
                            width: "16",
                            height: "16",
                            fill: "white",
                            transform: "translate(0.375)",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("RaceVideoPage.DataViewSelectMenu.highlight"))
              ),
            ]),
          ]
        ),
      ]),
      _c("li", { staticClass: "data-view-select-menu-section__item" }, [
        _c(
          "button",
          {
            staticClass: "data-view-select-menu-section__item-link",
            class: {
              "data-view-select-menu-section__item-link--current":
                _vm.viewMode === "radio",
            },
            on: {
              click: function ($event) {
                return _vm.changeViewMode("radio")
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "17",
                  height: "16",
                  viewBox: "0 0 17 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M5.90283 0H7.23617V4.44444H5.90283V0Z",
                    fill: "#C7C7C7",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M8.56934 3.55469H10.7916V4.44358H8.56934V3.55469Z",
                    fill: "#C7C7C7",
                  },
                }),
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M4.56934 6.33399C4.56934 5.7817 5.01705 5.33398 5.56934 5.33398H10.6804C11.2327 5.33398 11.6804 5.7817 11.6804 6.33398V15.0006C11.6804 15.5529 11.2327 16.0007 10.6804 16.0007H5.56934C5.01705 16.0007 4.56934 15.5529 4.56934 15.0007V6.33399ZM5.90267 6.66732H10.3471V7.55621H5.90267V6.66732ZM10.3471 8.4451H5.90267V9.33398H10.3471V8.4451ZM6.79156 11.5562C6.79156 11.8017 6.59257 12.0007 6.34711 12.0007C6.10165 12.0007 5.90267 11.8017 5.90267 11.5562C5.90267 11.3107 6.10165 11.1118 6.34711 11.1118C6.59257 11.1118 6.79156 11.3107 6.79156 11.5562ZM8.12489 12.0007C8.37035 12.0007 8.56934 11.8017 8.56934 11.5562C8.56934 11.3107 8.37035 11.1118 8.12489 11.1118C7.87943 11.1118 7.68045 11.3107 7.68045 11.5562C7.68045 11.8017 7.87943 12.0007 8.12489 12.0007ZM10.3471 11.5562C10.3471 11.8017 10.1481 12.0007 9.90267 12.0007C9.65721 12.0007 9.45822 11.8017 9.45822 11.5562C9.45822 11.3107 9.65721 11.1118 9.90267 11.1118C10.1481 11.1118 10.3471 11.3107 10.3471 11.5562ZM6.34711 13.7784C6.59257 13.7784 6.79156 13.5794 6.79156 13.334C6.79156 13.0885 6.59257 12.8895 6.34711 12.8895C6.10165 12.8895 5.90267 13.0885 5.90267 13.334C5.90267 13.5794 6.10165 13.7784 6.34711 13.7784ZM8.56934 13.334C8.56934 13.5794 8.37035 13.7784 8.12489 13.7784C7.87943 13.7784 7.68045 13.5794 7.68045 13.334C7.68045 13.0885 7.87943 12.8895 8.12489 12.8895C8.37035 12.8895 8.56934 13.0885 8.56934 13.334ZM9.90267 13.7784C10.1481 13.7784 10.3471 13.5794 10.3471 13.334C10.3471 13.0885 10.1481 12.8895 9.90267 12.8895C9.65721 12.8895 9.45822 13.0885 9.45822 13.334C9.45822 13.5794 9.65721 13.7784 9.90267 13.7784Z",
                    fill: "#C7C7C7",
                  },
                }),
              ]
            ),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("RaceVideoPage.DataViewSelectMenu.radio"))),
            ]),
          ]
        ),
      ]),
      _c("li", { staticClass: "data-view-select-menu-section__item" }, [
        _c(
          "button",
          {
            staticClass: "data-view-select-menu-section__item-link",
            class: {
              "data-view-select-menu-section__item-link--current":
                _vm.viewMode === "gps",
            },
            on: {
              click: function ($event) {
                return _vm.changeViewMode("gps")
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "17",
                  height: "16",
                  viewBox: "0 0 17 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("g", { attrs: { "clip-path": "url(#clip0_6084_33520)" } }, [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M2.48788 11.1751C2.22671 11.4362 2.22765 11.86 2.48998 12.12L3.8648 13.4826C4.12548 13.741 4.54598 13.7401 4.80551 13.4805L6.09106 12.195C6.61564 11.6704 7.43765 11.5893 8.05467 12.0012L11.6452 14.398C12.0843 14.6912 12.6694 14.6334 13.0427 14.2601C13.4751 13.8277 13.4751 13.1267 13.0427 12.6943L10.9878 10.6394C10.0332 9.68476 10.0331 8.13703 10.9878 7.18241L14.0687 4.1015C14.691 3.47918 14.691 2.4702 14.0687 1.84789C13.4464 1.22557 12.4374 1.22557 11.8151 1.84789L7.2158 6.44716L6.27299 5.50435L10.8723 0.905079C12.0153 -0.237937 13.8685 -0.237938 15.0115 0.905077C16.1545 2.04809 16.1545 3.90129 15.0115 5.04431L11.9306 8.12522C11.4967 8.55914 11.4967 9.26265 11.9306 9.69657L13.9855 11.7515C14.9386 12.7046 14.9386 14.2498 13.9855 15.2029C13.1626 16.0259 11.8729 16.1532 10.9049 15.507L7.31439 13.1101C7.22624 13.0513 7.10881 13.0629 7.03387 13.1378L5.74832 14.4234C4.96972 15.202 3.70823 15.2048 2.92619 14.4296L1.55137 13.067C0.764394 12.287 0.761569 11.0158 1.54507 10.2323L3.82408 7.95326L4.76689 8.89607L2.48788 11.1751Z",
                      fill: "#C7C7C7",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M4.81653 7.89661C5.20292 8.283 5.82948 8.28236 6.21556 7.89627C6.60165 7.51018 6.60229 6.88362 6.21591 6.49723C5.82952 6.11085 5.20296 6.11149 4.81687 6.49758C4.43079 6.88367 4.43015 7.51022 4.81653 7.89661ZM6.21857 7.43577C6.18312 7.54009 6.12382 7.63809 6.04068 7.72123C5.75105 8.01086 5.28112 8.01121 4.99149 7.72158C4.78503 7.51512 4.72592 7.21704 4.8139 6.95796C4.72586 7.21707 4.78496 7.5152 4.99145 7.72169C5.28108 8.01132 5.75101 8.01098 6.04064 7.72134C6.12381 7.63817 6.18313 7.54014 6.21857 7.43577Z",
                      fill: "#C7C7C7",
                    },
                  }),
                ]),
                _c("defs", [
                  _c("clipPath", { attrs: { id: "clip0_6084_33520" } }, [
                    _c("rect", {
                      attrs: {
                        width: "16",
                        height: "16",
                        fill: "white",
                        transform: "translate(0.5)",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("RaceVideoPage.DataViewSelectMenu.gps"))),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }