var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unusable-feature-section" }, [
    _c("h4", {
      staticClass: "unusable-feature-section__title",
      domProps: {
        innerHTML: _vm._s(
          _vm.$tc("CancelPaidPlan.EditPage.unusableFeatureTitle")
        ),
      },
    }),
    _c("div", { staticClass: "unusable-feature-section__list" }, [
      _c("figure", { staticClass: "unusable-feature-section__item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/CanselPaidPlanPage/unusable01.jpg"),
            alt: "",
          },
        }),
        _c("figcaption", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("CancelPaidPlan.EditPage.unusable.gps")
            ),
          },
        }),
      ]),
      _c("figure", { staticClass: "unusable-feature-section__item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/CanselPaidPlanPage/unusable02.jpg"),
            alt: "",
          },
        }),
        _c("figcaption", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("CancelPaidPlan.EditPage.unusable.radio")
            ),
          },
        }),
      ]),
      _c("figure", { staticClass: "unusable-feature-section__item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/CanselPaidPlanPage/unusable03.jpg"),
            alt: "",
          },
        }),
        _c("figcaption", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("CancelPaidPlan.EditPage.unusable.highlight")
            ),
          },
        }),
      ]),
      _c("figure", { staticClass: "unusable-feature-section__item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/CanselPaidPlanPage/unusable04.jpg"),
            alt: "",
          },
        }),
        _c("figcaption", {
          domProps: {
            textContent: _vm._s(
              _vm.$tc("CancelPaidPlan.EditPage.unusable.ranking")
            ),
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }