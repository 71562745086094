var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "telemetry-tire-temperature-section" },
    [
      _c("img", {
        staticClass: "telemetry-tire-temperature-section__car",
        attrs: {
          src: "assets/img/RaceVideoPage/telemetry/tire_car.svg",
          alt: "",
          width: "102",
          height: "63",
        },
      }),
      _c("telemetry-tire-temperature-fl-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.tireTemperature.fl,
          callback: function ($$v) {
            _vm.$set(_vm.tireTemperature, "fl", $$v)
          },
          expression: "tireTemperature.fl",
        },
      }),
      _c("telemetry-tire-temperature-fr-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.tireTemperature.fr,
          callback: function ($$v) {
            _vm.$set(_vm.tireTemperature, "fr", $$v)
          },
          expression: "tireTemperature.fr",
        },
      }),
      _c("telemetry-tire-temperature-rl-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.tireTemperature.rl,
          callback: function ($$v) {
            _vm.$set(_vm.tireTemperature, "rl", $$v)
          },
          expression: "tireTemperature.rl",
        },
      }),
      _c("telemetry-tire-temperature-rr-parts", {
        attrs: { diffMs: _vm.diffMs },
        model: {
          value: _vm.tireTemperature.rr,
          callback: function ($$v) {
            _vm.$set(_vm.tireTemperature, "rr", $$v)
          },
          expression: "tireTemperature.rr",
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "telemetry-tire-temperature-section__label" },
      [_c("span", [_vm._v("Tire temp.")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }