var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-race-section" }, [
    _c(
      "div",
      {
        ref: "selectRaceSectionListWrapper",
        staticClass: "select-race-section__list-wrapper",
        on: { scroll: _vm.checkCarouselEnabled },
      },
      [
        _c(
          "div",
          { staticClass: "select-race-section__list" },
          _vm._l(_vm.championships, function (championship, index) {
            return _c("race-list-item-parts", {
              key: championship.id,
              staticClass: "select-race-section__list__item",
              class: "select-race-section__list__item-" + index,
              attrs: {
                current: championship.id === _vm.currentChampionshipId,
                round: championship.round,
                courseImg: championship.courseImg,
                venue: championship.circuitName[_vm.$i18n.locale] || undefined,
                raceDate: championship.raceDateForRaceCard,
                raceImg: _vm.championshipLogoPath(championship),
              },
              on: {
                push: function ($event) {
                  return _vm.push(championship)
                },
              },
            })
          }),
          1
        ),
      ]
    ),
    _vm.enabledRaceListCarousel
      ? _c(
          "button",
          {
            staticClass:
              "select-race-section__carousel select-race-section__carousel-prev",
            class: {
              "select-race-section__carousel--active": _vm.enabledCarouselPrev,
            },
            on: {
              click: function ($event) {
                return _vm.onclickCarousel("prev")
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_chevron_left_24.svg"),
                alt: "<",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.enabledRaceListCarousel
      ? _c(
          "button",
          {
            staticClass:
              "select-race-section__carousel select-race-section__carousel-next",
            class: {
              "select-race-section__carousel--active": _vm.enabledCarouselNext,
            },
            on: {
              click: function ($event) {
                return _vm.onclickCarousel("next")
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_chevron_right_24.svg"),
                alt: ">",
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }