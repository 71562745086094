var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "check-in-failed-modal-section",
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("modal-title-parts", {
              attrs: {
                title: _vm.$tc(
                  "MissionPage.checkIn.errors.outOfAreaOrPeriod.title"
                ),
              },
            }),
            _c("modal-message-parts", {
              attrs: {
                message: _vm.$tc(
                  "MissionPage.checkIn.errors.outOfAreaOrPeriod.message"
                ),
              },
            }),
            _c(
              "div",
              { staticClass: "check-in-failed-modal-section__external-link" },
              [
                _c("atom-link", {
                  attrs: {
                    url: _vm.currentLocationgoogleMapUrl,
                    linkText: _vm.$tc(
                      "MissionPage.checkIn.errors.outOfAreaOrPeriod.checkGoogleMap"
                    ),
                    imgPath: require("@/assets/img/icon/icon_external_link_outline.svg"),
                    iconPosition: "right",
                  },
                }),
              ],
              1
            ),
            _c("modal-message-parts", {
              staticClass: "check-in-failed-modal-section__code",
              attrs: {
                message: _vm.$tc(
                  "MissionPage.checkIn.errors.outOfAreaOrPeriod.code"
                ),
              },
            }),
            _c("modal-message-parts", {
              staticClass: "check-in-failed-modal-section__current-location",
              attrs: { message: _vm.displayCurrentLocation },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$tc("common.ok"),
                colorType: "secondary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }