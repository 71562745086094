
























































import {
  defineComponent,
  PropType,
  reactive,
  watch,
  Ref,
  onBeforeMount,
  inject,
} from '@vue/composition-api'
import VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import { cloneDeep } from 'lodash'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import ComparisonPlanSection from '@/components/SignupPage/common/ComparisonPlanSection.vue'
import ChoosePlanSection from '@/components/SignupPage/PlanSelectForFreePane/ChoosePlanSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CouponInputSection from '@/components/common/payment/CouponInputSection.vue'
import CreditCardInputSection from '@/components/common/payment/CreditCardInputSection.vue'
import AlertOverlaySection from '@/components/common/overlay/AlertOverlaySection.vue'
import { ProcedureScreenType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useContractInfoCardRequest from '@/store/hook/useContractInfoCardRequest'
import useGeolocation from '@/components/hook/useGeolocation'
import useErrorHandling from '@/components/hook/useErrorHandling'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import I18n from '@/locales/I18n'
import { errorCodes } from '@/util/APIResponse'
import Logger from '@/util/logger/Logger'

/**
 * 新規会員登録:プラン選択画面ペインのコンポーネント (有料会員向け)
 */
export default defineComponent({
  name: 'PlanSelectForPaidPane',
  components: {
    SignupHeaderSection,
    ComparisonPlanSection,
    ChoosePlanSection,
    ActionButtonsSection,
    AtomInputButton,
    CouponInputSection,
    CreditCardInputSection,
    AlertOverlaySection,
  },
  inject: ['router'],
  props: {
    /**
     * 現在のステップ数
     */
    currentStep: {
      type: Number,
      default: 3,
    },
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<PaidPlanGroupType>,
      required: true,
    },
    /**
     * 戻るボタン有効化
     */
    enabledLeaveButton: {
      type: Boolean,
      required: false,
    },
    /**
     * どの手続き画面か
     */
    procedureScreen: {
      type: String as PropType<ProcedureScreenType>,
      default: null,
    },
  },
  setup(props, context) {
    const router = inject('router') as VueRouter

    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const {
      contractPaidPlans,
      fetchTargetCoupon,
      getCouponPlan,
      targetCoupon,
      couponPlan,
      contractInfoPlans,
      selectableContractPlans,
      getTargetAvailableArea,
    } = signupPageStore
    const { contractInfoCardRequest } = useContractInfoCardRequest()
    const { getCurrentPosition, checkIsUserInArea } = useGeolocation()
    const { createGeolocationError } = useErrorHandling()
    const errorStore = StoreUtil.useStore('ErrorStore')

    const loading = inject('loading') as PluginApi

    const state = reactive({
      /** 「確認画面へ」ボタン有効化判定 */
      isConfirmBtnDisabled: true,
      /** クレジットカード入力フォームを再描画するためのkey */
      stripeElementResetKey: 0,
      /** クライアントシークレット */
      clientSecret: null as string | null,
      /** オーバーレイを表示するかどうか */
      isShowAlertOverlay: false,
      /** オーバーレイに表示する内容 */
      alertOverlayText: {
        title: '',
        message: '',
      },
    })

    /**
     * プラン選択
     * @param planGroupId {PaidPlanGroupType}
     */
    const handlerPlanSelect = (planGroupId: PaidPlanGroupType) => {
      context.emit('handlerPlanSelect', planGroupId)
    }

    /**
     * プラン一覧にクーポンのプランをセットする
     */
    const setCouponPlan = async (coupon: CouponDocument | null) => {
      if (!coupon || !coupon?.plans) {
        couponPlan.value = null
        return
      }
      /** クーポンのプラン情報を取得 */
      const originalCouponPlan = getCouponPlan(coupon.plans)
      if (!originalCouponPlan) {
        couponPlan.value = null
        return
      }

      /**
       * サーキットなどの現地でのみ利用可能なクーポンの場合の処理
       */
      const couponAvailableAreaId = coupon?.additionalData?.availableArea?.areaId
      if (couponAvailableAreaId) {
        const loader = loading.show()
        /** 現地でのみ利用可能なクーポンの場合、以下の処理を行う */
        let positionResult = null
        try {
          // 現在地を取得
          positionResult = await getCurrentPosition()
          Logger.info(
            `PlanSelectForPaidPane#setCouponPlan currentGPS: ${positionResult.coords.latitude}, ${positionResult.coords.longitude}`,
          )
        } catch (e) {
          loader.hide()
          // HACK: ESLint: 'GeolocationPositionError' is not defined.(no-undef)エラーが表示さレてしまい、回避方法が不明なため、ひとまずエラーを無効化している
          // eslint-disable-next-line no-undef
          const positionError = e as GeolocationPositionError
          Logger.info(
            `OneTimePassConfirm#handlerSubmit getCurrentPositionError: code: ${positionError.code}, message: ${positionError.message}`,
          )
          await createGeolocationError(positionError)
          couponPlan.value = null
          return
        }

        // 対象クーポンの利用可能エリア情報を取得
        const availableArea = getTargetAvailableArea(couponAvailableAreaId)

        let isInArea = false
        try {
          // ユーザーが利用可能エリア内にいるかを判定する
          isInArea = checkIsUserInArea(positionResult, availableArea)
        } catch (e) {
          loader.hide()
          await MessageDialogStore.value.open({
            title: I18n.tc('common.errors.pointInPolygonError.title'),
            message: I18n.tc('common.errors.pointInPolygonError.message'),
          })
          return
        }

        if (!isInArea) {
          // クーポン利用可能エリア外
          loader.hide()
          await MessageDialogStore.value.open({
            title: I18n.tc('SignupPage.SelectPlanPage.errors.outsideAreaCouponUse.title'),
            message: I18n.t('SignupPage.SelectPlanPage.errors.outsideAreaCouponUse.message', {
              areaName: availableArea?.additionalData?.areaName?.[I18n.locale],
            }).toString(),
          })
          couponPlan.value = null
          return
        }

        loader.hide()
      }

      // 取得したクーポンのプラン情報を元にクーポン用のプラン情報を作成し、ストアに保存
      couponPlan.value = new ContractPlanDocument({
        ...originalCouponPlan,
        couponData: {
          couponName: {
            ja: coupon.couponName.ja,
            en: coupon.couponName.en,
          },
          title: {
            ja: coupon.additionalData?.title.ja,
            en: coupon.additionalData?.title.en,
          },
          description: {
            ja: coupon.description?.ja || '',
            en: coupon.description?.en || '',
          },
          couponApplicationPeriodText: coupon.couponApplicationPeriodText,
        },
      })

      /** 取得したクーポンのプランを選択状態にする */
      if (
        couponPlan.value.planGroupId === 'limitedTimePaidPlan' ||
        couponPlan.value.planGroupId === 'limitedTimePaidPlanForAnnual'
      ) {
        handlerPlanSelect(couponPlan.value.planGroupId)
      }
    }

    /**
     * 「確認画面へ」ボタン活性/非活性状態更新
     */
    const updateHasCardNameErrors = (isDisabled: boolean) => {
      state.isConfirmBtnDisabled = isDisabled
    }

    /**
     * オーバーレイを閉じる
     */
    const hideAlertOverlay = () => {
      state.isShowAlertOverlay = false
    }

    /**
     * 選択できるプラン一覧
     * ユーザーが正しいクーポンコードを入力した場合はクーポンプランも表示する
     */
    watch(
      () => [couponPlan.value, contractPaidPlans.value],
      () => {
        const newPlanList = cloneDeep(contractPaidPlans.value)
        if (couponPlan.value) {
          newPlanList.push(couponPlan.value)
        }
        selectableContractPlans.value = newPlanList
      },
      { immediate: true },
    )

    onBeforeMount(async () => {
      /**
       *
       */
      await Promise.all([
        // 現在利用している契約情報を取得
        signupPageStore.fetchContractInfo(),
        // 現地利用限定クーポンで使うエリア情報を取得
        signupPageStore.fetchAvailableAreas(),
      ])
      const { contractInfoId } = signupPageStore.ownContractInfo.value
      // 現在利用している契約情報契約プランを取得
      await signupPageStore.fetchContractInfoPlans(contractInfoId as string)

      // クライアントシークレットを取得
      state.clientSecret = (await contractInfoCardRequest()).data?.clientSecret || null
      if (!state.clientSecret) {
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.title'),
          message: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.message'),
        })
        errorStore.setConfig('failureGetClientSecret')
        await router.replace({ name: 'ErrorPage' })
      }
    })

    return {
      errorStore,
      Const,
      state,
      fetchTargetCoupon,
      targetCoupon,
      couponPlan,
      contractInfoPlans,
      selectableContractPlans: selectableContractPlans as Ref<Array<ContractPlanDocument>>,
      stripe: signupPageStore.stripe,
      paymentMethodId: signupPageStore.paymentMethodId,
      inputtedCouponCode: signupPageStore.inputtedCouponCode,
      contractInfoCardRequest,
      handlerPlanSelect,
      setCouponPlan,
      updateHasCardNameErrors,
      hideAlertOverlay,
    }
  },
  methods: {
    /**
     * クレジットカード入力フォームを再描画するためにkeyを変更
     */
    addStripeElementResetKey() {
      this.state.stripeElementResetKey += 1
    },
    /**
     * クレジットカード情報入力フォーム初期化
     */
    async initCreditCardInputForm() {
      // 一度検証（confirmCardSetup）した後、確認画面に遷移しなかったとしても再検証できるよう、新しいクライアントシークレットを取得する（基本的には確認画面へ遷移する想定）
      this.state.clientSecret = (await this.contractInfoCardRequest()).data?.clientSecret || null
      if (!this.state.clientSecret) {
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.title'),
          message: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.message'),
        })
        this.errorStore.setConfig('failureGetClientSecret')
        await this.$router.replace({ name: 'ErrorPage' })
      }

      /** クレジットカード入力フォームを再描画するためのkey変更 */
      this.addStripeElementResetKey()
    },
    /**
     * 「確認画面へ」押下時の処理
     */
    async handlerSubmit() {
      const loader = this.$loading.show()

      /** クーポンコードが入力されている場合の処理 */
      if (this.inputtedCouponCode || this.couponPlan) {
        const isSelectedCouponPlan = await this.checkIsSelectedCouponPlan(this.inputtedCouponCode)
        if (!isSelectedCouponPlan) {
          loader.hide()
          // クーポンのプランを選択していない場合は確認画面に進めない
          this.state.isShowAlertOverlay = true
          return
        }
      }

      /**
       * クレジットカードの検証を行い、支払い方法を取得する
       */
      const { paymentMethod, error } = await this.stripe.getPaymentMethod()

      /**
       * 以降の処理は以下どちらかのため、ここでローダーを非表示にしても二重サブミットは防止できる想定なので、ローダーを非表示にする
       * - エラーの場合はモーダルを表示し、モーダルを閉じてからAPIアクセスする（クレジットカード入力フォームの初期化）
       * - 確認画面へ遷移する
       */
      loader.hide()

      if (error) {
        // Stripeからエラーが返ってくる場合は、Stripeのエラーメッセージを表示する
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.SelectPlanPage.errors.failureGetPaymentMethod.title'),
          message: error?.message,
        })

        // クレジットカード情報を再入力してもらうため、クレジットカード入力フォームを初期化する
        await this.initCreditCardInputForm()
        return
      }

      if (
        !(
          (typeof paymentMethod === 'object' && typeof paymentMethod?.id === 'string') ||
          typeof paymentMethod === 'string'
        )
      ) {
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.SelectPlanPage.errors.failureGetPaymentMethod.title'),
          message: this.$tc('SignupPage.SelectPlanPage.errors.failureGetPaymentMethod.message'),
        })

        // クレジットカード情報を再入力してもらうため、クレジットカード入力フォームを初期化する
        await this.initCreditCardInputForm()
        return
      }

      /** 支払い方法IDをストアに保存 */
      if (typeof paymentMethod === 'string') {
        // useStripe.confirmCardSetupを呼び出して検証に成功した場合、paymentMethodに支払い方法IDがセットされている想定
        this.paymentMethodId = paymentMethod
      } else {
        this.paymentMethodId = paymentMethod.id
      }

      /** 確認ページへの遷移 */
      this.$emit('handlerSubmit')
    },
    /**
     * 「戻る」押下時の処理
     */
    handlerCancel() {
      /**
       * プラン選択切り替え処理 無料向けの画面に切り替わる / ログイン画面に戻る
       */
      this.$emit('handlerCancel', this.Const.MEMBER_TYPE.FREE)
    },
    /**
     * クーポンのプランを選択しているかチェック
     */
    async checkIsSelectedCouponPlan(inputtedValue: string) {
      if (
        this.couponPlan &&
        !(
          this.selectedPlanGroupId === 'limitedTimePaidPlan' ||
          this.selectedPlanGroupId === 'limitedTimePaidPlanForAnnual'
        )
      ) {
        // 正しいクーポンコードを入力して登録ボタンを押したが、クーポンのプランが選択されていない場合
        this.state.alertOverlayText.title = this.$i18n.tc(
          'SignupPage.SelectPlanPage.AlertOverlays.haveNotSelectedCouponPlan.title',
        )
        this.state.alertOverlayText.message = this.$i18n.tc(
          'SignupPage.SelectPlanPage.AlertOverlays.haveNotSelectedCouponPlan.message',
        )
        return false
      }

      if (!this.couponPlan && inputtedValue) {
        // 正しいクーポンコードを入力しているが、登録ボタンを押していない場合の処理
        const result = await this.fetchTargetCoupon(inputtedValue)
        if (!result.isSuccess && result.response?.isNetworkError) {
          // ネットワークエラーでクーポンを取得できなかった場合は、エラーメッセージを表示する
          const occurredErrorCode =
            errorCodes.find((code) => result.response?.status === code) ?? 500
          this.state.alertOverlayText.title = ''
          this.state.alertOverlayText.message = this.$i18n.tc(
            `apiNotification.dialog.${occurredErrorCode}.message`,
          )
          return false
        }

        // 取得したクーポン
        const targetCouponItem = this.targetCoupon(inputtedValue)

        if (this.isValidInputtedCouponCode(targetCouponItem)) {
          // 正しいクーポンコードが入力されている場合のエラーメッセージ
          this.state.alertOverlayText.title = this.$i18n.tc(
            'SignupPage.SelectPlanPage.AlertOverlays.haveNotPressedCoupRegisterButton.title',
          )
          this.state.alertOverlayText.message = this.$i18n.tc(
            'SignupPage.SelectPlanPage.AlertOverlays.haveNotPressedCoupRegisterButton.message',
          )
        } else {
          // 正しくないクーポンコードが入力されている場合のエラーメッセージ
          this.state.alertOverlayText.title = this.$i18n.tc(
            'SignupPage.SelectPlanPage.AlertOverlays.invalidCouponCode.title',
          )
          this.state.alertOverlayText.message = this.$i18n.tc(
            'SignupPage.SelectPlanPage.AlertOverlays.invalidCouponCode.message',
          )
        }
        return false
      }

      return true
    },
    /**
     * 入力しているクーポンコードが正しいかチェック
     */
    isValidInputtedCouponCode(couponItem: CouponDocument | undefined) {
      if (!couponItem) {
        // クーポンが見つからなかった場合
        return false
      }
      if (this.contractInfoPlans.find((v) => v.couponId === couponItem?.couponId)) {
        // すでに使用済みのクーポンの場合
        return false
      }

      // 上記以外
      return true
    },
  },
})
