import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import DeviceInfo from '@/util/DeviceInfo'

export type PaymentType = {
  method?: 'CREDIT_CARD' | 'BANK_TRANSFER' | 'MANUAL' | 'IN_APP_PURCHASE'
  yearlyMethod?: 'CREDIT_CARD' | 'BANK_TRANSFER' | 'MANUAL' | 'IN_APP_PURCHASE'
  paymentService?: {
    /**
     * サービスプロバイダ
     */
    _serviceProvider: string | null
    /**
     * サービス上の顧客ID
     */
    _externalCustomerId?: string | null
    /**
     * クレジットカード番号下4桁
     */
    _cardLast4Digits?: string | null
    /**
     * 有効期限（年）
     */
    _cardExpirationYear?: number | null
    /**
     * 有効期限（月）
     */
    _cardExpirationMonth?: number | null
    /**
     * 機密情報トークン
     */
    token?: string | null
    /**
     * 支払い方法ID
     */
    paymentMethodId?: string | null
  } | null
}

export type CustomerInfoType = {
  mail?: string | null // メールアドレス
  name?: string | null // 担当者名（SFgoでは登録者のメールアドレスの@より前の部分を登録する）
}

/**
 * アプリ内課金 定期購入の購入ステータスの型
 */
type InAppPurchaseStatusType = 'UNDER_CONTRACT' | 'CANCELLED'

/**
 * アプリ内課金 GooglePlayの型
 */
type GooglePlayContractType = {
  /**
   * トークン
   */
  purchaseToken?: string | null
  /**
   * 定期購入の購入ステータス
   */
  status?: InAppPurchaseStatusType | null
}

/**
 * アプリ内課金 AppStoreの型
 */
type AppStoreContractType = {
  /**
   * トランザクションID
   */
  transactionId?: string | null
  /**
   * 定期購入の購入ステータス
   */
  status?: InAppPurchaseStatusType | null
}

/**
 * アプリ内課金の型
 */
export type InAppPurchaseContractType = {
  /**
   * 次の請求予定日
   */
  nextSettlementDate?: number | null
  /**
   * GooglePlay
   */
  googlePlay?: GooglePlayContractType
  /**
   * AppStore
   */
  appStore?: AppStoreContractType
}

/**
 * 契約情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/828473474/API
 */
export default class ContractInfoDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis<ContractInfoDocument>(initProps)
    // ContractPlanDocumentのgetterを使えるようにするために、
    // plansの中身をContractPlanDocumentでnewする
    if (initProps?.plans) {
      this.plans = initProps?.plans.map((p) => new ContractPlanDocument(p))
    }
  }

  _path = 'manage/contract_info'

  idAttr = 'contractInfoId'

  /**
   * 契約情報ID
   */
  contractInfoId: string | null = null

  /**
   * 契約プラン
   */
  plans?: Array<ContractPlanDocument>

  /**
   * 通貨
   ** 現状はJPYのみ
   */
  currency?: string | null = 'JPY'

  /**
   * 支払いに関する情報
   */
  customerInfo?: CustomerInfoType | null = null

  /**
   * 支払いに関する情報
   */
  payment?: PaymentType | null = null

  /**
   * 消費税地域
   */
  taxRegion?: string | null = 'NONE'

  /**
   * 解約日時
   */
  cancellationDate?: number | null = null

  /**
   * アプリ内課金
   */
  inAppPurchase?: InAppPurchaseContractType | null

  /**
   * 契約プランID別名
   */
  get id() {
    return this.contractInfoId
  }

  /**
   * iOSデバイスで操作しており、かつ過去にAppStoreでアプリ内課金してプランを購入したことがあるか
   */
  get isiOSAndHasPurchasedAppStore() {
    return DeviceInfo.isiOS() && this.inAppPurchase?.appStore?.transactionId
  }
}
