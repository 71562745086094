var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-checkpoint-map",
      attrs: {
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { opacity: "0.6" } }, [
        _c("path", {
          attrs: {
            d: "M8.99994 2.03076C6.98885 2.03076 5.35865 3.66096 5.35865 5.67204C5.35865 7.29791 7.73849 10.2612 8.65026 11.3368C8.83492 11.5547 9.16524 11.5547 9.34961 11.3368C10.2614 10.2612 12.6412 7.29791 12.6412 5.67204C12.6412 3.66096 11.011 2.03076 8.99994 2.03076ZM8.99994 6.88581C8.32948 6.88581 7.78617 6.3425 7.78617 5.67204C7.78617 5.00159 8.32948 4.45828 8.99994 4.45828C9.67039 4.45828 10.2137 5.00159 10.2137 5.67204C10.2137 6.3425 9.67039 6.88581 8.99994 6.88581ZM1.25845 8.27152C1.08684 8.34016 0.939732 8.45863 0.836094 8.61167C0.732456 8.76471 0.677043 8.94528 0.677002 9.13011L0.677002 16.3641C0.677002 16.6913 1.00732 16.9149 1.31105 16.7936L5.30085 14.9775V8.24175C5.04539 7.77994 4.83645 7.33027 4.68675 6.90026L1.25845 8.27152ZM8.99994 12.4249C8.59333 12.4249 8.20868 12.2463 7.94483 11.9348C7.37667 11.2643 6.77239 10.5008 6.22562 9.71763V14.9773L11.7742 16.8268V9.71792C11.2275 10.5008 10.6235 11.2646 10.055 11.9351C9.79119 12.2463 9.40654 12.4249 8.99994 12.4249ZM16.6888 6.68814L12.699 8.50415V16.8271L16.7414 15.2102C16.9131 15.1416 17.0602 15.0231 17.1638 14.8701C17.2675 14.717 17.3229 14.5364 17.3229 14.3516V7.11758C17.3229 6.79044 16.9926 6.56676 16.6888 6.68814Z",
            fill: "currentColor",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }