var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paid-membership-change-edit-page" },
    [
      _vm.memberType === _vm.Const.MEMBER_TYPE.PAID
        ? _c("plan-select-for-paid-pane", {
            attrs: {
              selectedPlanGroupId: _vm.selectedPlanGroupId,
              enabledLeaveButton: _vm.enabledLeaveButton,
              procedureScreen: "paidMembership",
            },
            on: {
              handlerPlanSelect: _vm.changePlan,
              handlerSubmit: _vm.moveToPaidMembershipChangeConfirm,
              handlerCancel: _vm.handlerCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }