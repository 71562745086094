






import { defineComponent } from '@vue/composition-api'
import useMailAddressChangeToken from '@/store/hook/useMailAddressChangeToken'

export type DataType = {
  message: string
}

/**
 * マイページ: メールアドレス変更変更完了 コンテンツセクション
 */
export default defineComponent({
  name: 'MypageMailChangeCompleteSection',
  setup() {
    const { emailAddressChange: _emailAddressChange } = useMailAddressChangeToken()

    return {
      _emailAddressChange,
    }
  },
  data(): DataType {
    return {
      message: '',
    }
  },
  async mounted() {
    const loader = this.$loading.show()
    const tokenId = this.$route.query ? (this.$route.query.tokenId as string) : ''
    const apiResult = await this._emailAddressChange(tokenId)
    if (apiResult.isSuccess) {
      /**
       * 完了通知
       */
      this.message = this.$t('MypagePage.MypageMailChangeCompletePage.completeMessage') as string
    } else {
      /**
       * エラー通知
       */
      this.message = this.$t(
        'MypagePage.MypageMailChangeCompletePage.completeErrorMessage',
      ) as string
    }
    loader.hide()
  },
  methods: {},
})
