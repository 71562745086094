
































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { sortBy, times, uniq } from 'lodash'
import FilterSelectorParts, {
  FilterList,
} from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/FilterSelectorParts.vue'
import StoreUtil from '@/store/StoreUtil'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

type DataType = {
  driverFilterId: string
  lapFilterId: string
}

/**
 * レース動画再生画面 無線一覧表示 ヘッダーセクションのコンポーネント
 */
export default defineComponent({
  name: 'RadioHeaderSection',
  components: {
    FilterSelectorParts,
  },
  data(): DataType {
    return {
      driverFilterId: '',
      lapFilterId: '',
    }
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
  },
  setup(props) {
    const screenLandScape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { getAllPlayerRadioDataSet } = useRadio()

    // Lapプルダウンの選択項目を生成する
    // 現状の無線交信データのリストが保持しているLapの情報からリストを生成する。
    // リストが生成できなかった場合、1〜50の選択項目を生成する
    const lapFilterItems = computed<Array<FilterList>>(() => {
      let lapList = sortBy(
        uniq(getAllPlayerRadioDataSet.value.map((radio) => Number(radio.lap))).filter((lap) => lap),
      )

      if (lapList.length === 0) {
        lapList = times(50, String).map((lap) => Number(lap) + 1)
      }
      const lapFilterList: Array<FilterList> = []
      lapList.forEach((lap) => {
        lapFilterList.push({
          id: `${lap}`,
          name: {
            ja: `Lap ${lap}`,
            en: `Lap ${lap}`,
          },
        })
      })
      return lapFilterList
    })

    return {
      screenLandScape,
      driverFilterList: raceVideoPageStore.participatingPlayers,
      lapFilterItems,
    }
  },
  methods: {
    changeDriverFilter(id: string): void {
      this.$emit('changeDriverFilter', id)
    },
    changeLapFilter(id: string): void {
      this.$emit('changeLapFilter', id)
    },
  },
})
