var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-page" },
    [
      _c("header-pane", {
        staticClass: "notification-page__header",
        attrs: {
          colorType: "light",
          pageTitle: _vm.$tc("NotificationPage.pageTitle"),
          hasHeaderNav: _vm.hasHeaderNav,
          navigationCurrent: "notification",
        },
      }),
      _c("notification-pane", { staticClass: "notification-page__content" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }