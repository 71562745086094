import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import useDynamoDB from '@/store/hook/useDynamoDB'
import LiveTimingAggregationDocument from '@/store/stores/collectionModule/documents/liveTiming/LiveTimingAggregationDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

/**
 * ライブタイミング集計データを操作するための処理を取得する。
 */
export default function useLiveTimingAggregationData() {
  // Collection modules
  const playerCollectionModule = CollectionModule.createStore(PlayerDocument)
  const liveTimingAggregationDataCollectionModule = CollectionModule.createStore(
    LiveTimingAggregationDocument,
  )

  const { searchDateRangeData } = useDynamoDB()

  /**
   * 大会の参加選手を取得する
   * @param championshipMasterId 大会マスタID
   */
  const fetchPlayers = (championshipMasterId: string) =>
    playerCollectionModule.fetch({
      query: {
        filter: {
          championshipMasterId,
        },
        sort: 'squadNumber',
      },
    })

  /**
   * ライブタイミング集計情報を取得する。
   *
   * @param season ライブタイミング集計を取得するシーズン。
   * レースカテゴリと年度を合わせた文字列を指定する。SuperFormula_2022
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchLiveTimingAggregationData = async (
    season: string,
    fromDate: number,
    toDate: number,
  ) => {
    try {
      const result = await searchDateRangeData({
        tableName: 'live-timing-aggregation',
        partitionKey: 'season',
        partitionKeyValue: season,
        sortKey: 'raceStartDateTime',
        from: fromDate,
        to: toDate,
      })
      liveTimingAggregationDataCollectionModule.data =
        result as Array<LiveTimingAggregationDocument>
      return {
        isSuccess: true,
        response: null,
      }
    } catch (e) {
      return {
        isSuccess: false,
        response: null,
      }
    }
  }

  /**
   * 取得したライブタイミング集計情報。
   */
  const liveTimingAggregationDataList = computed(
    () => liveTimingAggregationDataCollectionModule.data,
  )

  /**
   * 大会に参加している選手一覧
   */
  const players = computed(() => playerCollectionModule.data)

  /**
   * 取得したライブタイミング集計情報をクリアする
   */
  const clearLiveTimingAggregationData = () => {
    liveTimingAggregationDataCollectionModule.clearData()
    playerCollectionModule.clearData()
  }

  return {
    fetchLiveTimingAggregationData,
    liveTimingAggregationDataList,
    clearLiveTimingAggregationData,
    fetchPlayers,
    players,
  }
}
