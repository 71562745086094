import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import PlayEventDocument from '@/store/stores/collectionModule/documents/highlight/PlayEventDocument'

/**
 * FL-UXでデフォルトで定義されるプレイイベントのIDの配列。SFgoでは利用しないため、除外する。
 */
const systemDefaultPlayEventIds = [
  'firstHalfStart',
  'secondHalfStart',
  'extraTimeFirstHalfStart',
  'extraTimeSecondHalfStart',
  'playStart',
]

/**
 * プレイイベントを操作するための処理を取得する。
 */
export default function usePlayEvent() {
  // Collection modules
  const playEventCollectionModule = CollectionModule.createStore(PlayEventDocument)

  /**
   * プレイイベント一覧を取得する。
   * @return プレイイベント情報取得のレスポンス
   */
  const fetchPlayEvent = () => playEventCollectionModule.fetch({ query: { sort: 'order' } })

  /**
   * プレイイベントのリストを取得する。
   * FL-UXでデフォルトで定義されているプレイイベントと非表示設定されているものは除外する。
   */
  const playEvents = computed(() =>
    playEventCollectionModule.data.filter((playEvent) => {
      if (playEvent.playEventId) {
        return !playEvent.hiddenFlag && !systemDefaultPlayEventIds.includes(playEvent.playEventId)
      }
      return false
    }),
  )

  // methods
  /**
   * 取得したプレイイベント情報をクリアする
   */
  const clearPlayEvent = () => {
    playEventCollectionModule.clearData()
  }

  return {
    fetchPlayEvent,
    playEvents,
    clearPlayEvent,
  }
}
