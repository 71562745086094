import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 車両番号のエイリアス
 * ※暫定的に定義
 */
const carNoAlias = {
  '16': '1',
  '1': '16',
}

/**
 * 選手情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/314212483/10+API
 */
export default class PlayerDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PlayerDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/player'

  idAttr = 'playerMasterId'

  /**
   * 選手ID
   */
  playerId: string | null = null

  /**
   * 選手マスタID
   */
  playerMasterId: string | null = null

  /**
   * 選手名
   */
  playerName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 選手画像
   */
  playerPicture: string | null = null

  /**
   * ゼッケン番号
   */
  squadNumber: string | null = null

  /**
   * 省略名
   */
  playerShortName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * ポジション。
   * FWの場合、選手一覧に表示しない。
   */
  position: 'GK' | 'DF' | 'MF' | 'FW' | null = null

  /**
   * 大会マスタID
   */
  championshipMasterId: string | null = null

  /**
   * 開始日時
   */
  startDate?: number | null = null

  /**
   * 終了日時
   */
  endDate?: number | null = null

  /**
   * 選手情報の追加項目
   */
  additionalData?: {
    /**
     * チームID
     */
    teamId?: string | null
    /**
     * プロフィールURL
     */
    profilePath?: string | null
    /**
     * Information Race Rank用の項目
     */
    raceRank?: {
      /**
       * 選手画像URL
       */
      playerImagePath: string | null
    }
    /**
     * Standings画面用の項目
     */
    standings?: {
      /**
       * ヘッダー画像URL
       */
      headerImagePath: string | null
      /**
       * 選手画像URL
       */
      playerImagePath: string | null
    }
  }

  /**
   * 選手ID別名
   */
  get sid() {
    return this.playerId || ''
  }

  /**
   * 選手マスタID別名
   */
  get id() {
    return this.playerMasterId
  }

  /**
   * 選手名別名
   */
  get name() {
    return this.playerName
  }

  /**
   * 選手の顔写真ファイルのパス。
   */
  get playerPictureImagePath() {
    return this.playerPicture ? this.playerPicture : 'assets/img/common/card/icon_player__white.svg'
  }

  /**
   * 車両番号のエイリアスを取得する。
   * 暫定処理: 野尻選手の場合、車両番号16に対して、1のエイリアスを返す。
   * @param carNo 車両番号
   * @param startDate 開始日時（紐づいている大会マスタの開始日と同じ値をスコアリングで登録している）
   */
  static getCarNoAlias(carNo: string, startDate: number | null) {
    if (startDate && startDate >= new Date('2024-01-01T00:00:00+0900').getTime()) {
      // 2024年以降のマスタについては暫定処理を行わない
      return ''
    }
    if (Object.keys(carNoAlias).includes(carNo)) {
      return carNoAlias[carNo as keyof typeof carNoAlias]
    }
    return ''
  }

  /**
   * 指定された車両番号に乗車しているかどうかを返す。
   * @param carNo 車両番号
   */
  isRelatedCar(carNo: string) {
    return (
      this.squadNumber === carNo ||
      this.squadNumber === PlayerDocument.getCarNoAlias(carNo, this.startDate ?? null)
    )
  }

  /**
   * 画面表示用の車両番号を取得する。
   * 2022年度の野尻選手の場合、マスタデータには車両番号が#16が設定されているが、画面には#1と表示する必要があるため、このメソッドで変換する。
   */
  getDisplayCarNo() {
    const carNo = PlayerDocument.getCarNoAlias(this.squadNumber ?? '', this.startDate ?? null)
    return (carNo || this.squadNumber) ?? ''
  }

  /**
   * 画面表示用の車両番号を取得する。（数値型）
   * 背番号順にソートする場合などで使用する
   */
  get getDisplayCarNoNumeric() {
    const carNo = PlayerDocument.getCarNoAlias(this.squadNumber ?? '', this.startDate ?? null)
    return (Number(carNo) || Number(this.squadNumber)) ?? 999
  }

  /**
   * 選手名から、車両番号(#16など)を取り除く。
   */
  getPlayerName(): Multilingual {
    if (!this.playerName) {
      return this.playerName
    }
    const playerName: Multilingual = { ...this.playerName }
    Object.keys(this.playerName).forEach((lang) => {
      const name = this.playerName[lang]
      if (name) {
        const carNoIndex = name.indexOf('#')
        if (carNoIndex !== -1) {
          playerName[lang] = name.substring(0, carNoIndex)
        }
      }
    })
    return playerName
  }

  /**
   * 選手英語名から、車両番号(#16など)を取り除く。
   */
  getPlayerNameEn(): string {
    if (!this.playerName.en) {
      return ''
    }
    let nameEn = this.playerName.en
    if (nameEn) {
      const carNoIndex = nameEn.indexOf('#')
      if (carNoIndex !== -1) {
        nameEn = nameEn.substring(0, carNoIndex)
      }
    }

    return nameEn.trim()
  }
}
