import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ThumbnailDocument from '@/store/stores/collectionModule/documents/thumbnail/ThumbnailDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'

/**
 * 動画のサムネイルを操作するための処理を取得する。
 */
export default function useThumbnail() {
  // Collection modules
  const thumbnailCollectionModule = CollectionModule.createStore(ThumbnailDocument)

  /**
   * サムネイル一覧を取得する。
   */
  const fetchThumbnail = (
    time: number,
    currentMovieInfo: ContentsInfoDocument | AngleMovieInfoDocument,
  ) => {
    const isMain = currentMovieInfo instanceof ContentsInfoDocument
    const movieInfoId = isMain ? currentMovieInfo.matchId : currentMovieInfo.id
    const url = `${process.env.VUE_APP_API_BASE_URL as string}/${LoginStore.value.orgId}${
      isMain ? '/manage/thumbnail/' : '/manage/angle/thumbnail/'
    }${movieInfoId}/${Math.round(time)}`
    return thumbnailCollectionModule.fetch({
      url,
    })
  }

  /**
   * 取得したサムネイル
   */
  const targetThumbnail = computed(() => thumbnailCollectionModule.data[0])

  /**
   * 取得したサムネイル情報をクリアする
   */
  const clearThumbnailData = () => {
    thumbnailCollectionModule.clearData()
  }

  return {
    fetchThumbnail,
    targetThumbnail,
    clearThumbnailData,
  }
}
