var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atom-icon-router-button", {
    staticClass: "ranking-player-link-parts",
    class: {
      "ranking-player-link-parts--disabled": !_vm.linkData.canShow,
    },
    attrs: {
      to: _vm.linkData.canShow ? _vm.linkData.link : "",
      linkText: _vm.buttonText,
      theme: "light",
      colorType: "secondary",
      size: "xxsmall",
      imgPath: _vm.buttonIcon,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }