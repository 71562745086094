var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navigation-mypage-icon-parts",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7ZM12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3ZM3.98051 16.2265C4.90784 14.8355 6.469 14 8.14076 14H15.8593C17.5311 14 19.0922 14.8355 20.0195 16.2265L22.5352 20H20.1315L18.3554 17.3359C17.799 16.5013 16.8624 16 15.8593 16H8.14076C7.1377 16 6.20101 16.5013 5.64461 17.3359L3.86854 20H1.46484L3.98051 16.2265Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }