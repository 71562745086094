import dayjs from 'dayjs'
import { computed, reactive, ref } from '@vue/composition-api'
import { cloneDeep, now, uniq, sortBy } from 'lodash'
import type { StoreBase, ValueType } from '@/store/StoreBase'
import IVideoPlayer from '@/util/videoplayer/IVideoPlayer'
import { VideoPlayerStatusType, VideoStatusType } from '@/components/hook/useVideoPlayer'
import useTelemetryData from '@/store/hook/useTelemetryData'
import useRaces from '@/store/hook/useRaces'
import useUser from '@/store/hook/useUser'
import useUserRetrieveName from '@/store/hook/useUserRetrieveName'
import usePlayEvent from '@/store/hook/usePlayEvent'
import useHighlightData from '@/store/hook/useHighlightData'
import useHighlightComment from '@/store/hook/useHighlightComment'
import useHistory from '@/store/hook/useHistory'
import useChampionships from '@/store/hook/useChampionships'
import Logger from '@/util/logger/Logger'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import Const from '@/util/Const'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import useLiveTiming, { SectorNameMap } from '@/store/hook/useLiveTiming'
import { AlertMessage } from '@/store/stores/collectionModule/documents/GeneralTypes'
import LiveTimingDocument from '@/store/stores/collectionModule/documents/liveTiming/LiveTimingDocument'
import MathUtil from '@/util/MathUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import useRadioData from '@/store/hook/useRadioData'
import useAllPlayerGps from '@/store/hook/useAllPlayerGps'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import useAllPlayerTelemetry from '@/store/hook/useAllPlayerTelemetry'
import DeviceInfo from '@/util/DeviceInfo'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import { VolumeDataType } from '@/components/RaceVideoPage/RaceVideoPane/parts/VolumeSeekBarParts.vue'
import type {
  CommentDataType,
  ParentCommentDataType,
} from '@/components/RaceVideoPage/hook/useCommunication'
import AppConfigStore from '@/store/stores/pageStore/common/AppConfigStore'
import useThumbnail from '@/store/hook/useThumbnail'
import useReaction from '@/store/hook/reaction/useReaction'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import useMovieExport from '@/util/movieExport/useMovieExport'
import useSceneMovies from '@/store/hook/useSceneMovies'
import SceneMoviesDocument, {
  SceneMoviesSceneType,
} from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'
import usePermission from '@/components/hook/usePermission'
import I18n from '@/locales/I18n'
import { LastSeenHighlightDataType } from '@/store/stores/IndexedDBstore/IndexedDBAccessorType'

type TargetVideoInfoType = {
  targetVideoTime: number
  targetVideoTrackDateTime: number
  targetMovieInfo: AngleMovieInfoDocument
}

/**
 * 動画アングル。
 * メイン映像か、現在選択されている選手のオンボード映像のどちらかを表す。
 * - race: メイン映像
 * - player: オンボード映像
 */
export type ViewAngleType = 'race' | 'player'

/**
 * ハイライトタブ(公式/ユーザー)の型
 */
export type HighlightTabsType = 'official' | 'user'

/**
 * ハイライトユーザータブ フィルターの種類の型
 */
export type HighlightUserFilterType = 'self' | 'role' | 'userDisplayName'

type EditHighlightUser = {
  name: string
  image: string
}

export type EditHighlightData = {
  thumbnail: string
  user: EditHighlightUser
  editHighlightData: HighlightDocument
}

const raceVideoPageInitialState = {
  /** ログインユーザーのログインID */
  loginId: null as string | null,
  /** ログインユーザーが所属する組織ID */
  organizationId: null as string | null,
  /** 対象の試合が属している大会の大会マスタID */
  championshipMasterId: null as string | null,
  /** 対象のレースのID(matchId) */
  raceId: null as string | null,
  isFetchedRaceInfo: false,
  /** 現在の再生時間から、何ミリ秒先までのテレメトリーデータを取得するかを指定する */
  preloadFetchingRange: 10 * 1000,
  /** 現在選択されている選手のID */
  selectedPlayerId: null as string | null,
  /** 動画アングル */
  viewAngle: 'player' as ViewAngleType,
  /** 動画再生状態 */
  videoStatus: null as VideoStatusType | null,
  /** 動画プレーヤー状態 */
  videoPlayerStatus: null as VideoPlayerStatusType | null,
  /** 動画プレーヤーのインスタンス */
  videoPlayer: null as IVideoPlayer | null,
  /** サブ動画プレーヤーのインスタンス */
  subVideoPlayer: null as IVideoPlayer | null,
  /** 前回のレース情報取得日時 */
  lastRaceDataFetchedDate: undefined as number | undefined,
  /** アラート表示抑止フラグ */
  ignoreAlert: false,
  /** 音声の状態 */
  volume: {
    currentVolume: 50,
    volumeLength: 100,
    muted: false,
    tempVolume: 50,
  } as VolumeDataType,
  /** 再生対象の動画の実時間 */
  startMovieActualTime: undefined as number | undefined,
  /** 動画プレーヤーをスロー再生するかどうか */
  videoSlow: false,
  /** 選択中のハイライトタブ */
  selectedHighlightTab: 'official' as HighlightTabsType,
  /** 新着ハイライトがあるかどうか */
  hasNewHighlight: false,
  /** ハイライト新着通知を管理するために使用する一時退避データ */
  savedLastFetchedHighlightForNotifyData: null as LastSeenHighlightDataType | null,
  /** 自分が作成した新規ハイライトコメントデータ */
  newOwnHighlightComments: [] as CommentDataType[],
}
/* eslint-disable class-methods-use-this */
/**
 * SFgoの動画再生画面のストア。
 * 動画再生画面で必要となる以下のデータを管理する。
 * - レース、コンテンツ情報
 * - レースに出場している選手情報
 * - テレメトリー
 * - ハイライト
 * - ライブタイミング
 */
class RaceVideoPageStore implements StoreBase {
  createStore() {
    const raceVideoPageState = reactive({ ...raceVideoPageInitialState })
    // hook
    const { hasPermission } = usePermission()
    const telemetryStore = useTelemetryData()
    const liveTimingStore = useLiveTiming()
    const allPlayerTelemetryStore = useAllPlayerTelemetry()
    const gpsStore = useAllPlayerGps()
    const { fetchUser, user, clearUsers } = useUser()
    const {
      fetchRetrieveNameUsers,
      retrieveNameUserList,
      retrieveNameUsersByUserId,
      fetchedRetrieveNameUserIds,
      clearRetrieveNameUsers,
    } = useUserRetrieveName()
    const {
      fetchRace,
      targetRace,
      startPollingRaceData,
      finishPollingRaceData,
      contentsInfo,
      angleInfos,
      angleInfosById,
      angleMovieInfos,
      angleMoviePlayInfoByAngleId,
      participatingPlayers,
      players,
      playersById,
      clearRaces,
    } = useRaces()

    const { fetchChampionship, targetChampionship, clearChampionships } = useChampionships()
    const { fetchPlayEvent, playEvents, clearPlayEvent } = usePlayEvent()
    const highlightStore = useHighlightData()
    const highlightCommentStore = useHighlightComment()
    const { clearHighlightComments } = highlightCommentStore
    const { raceVideoPageHighlightState } = highlightStore
    const { exportHighlight } = useMovieExport()
    const { fetchThumbnail, targetThumbnail, clearThumbnailData } = useThumbnail()
    const {
      reactions,
      reactionsCountByTargetId,
      ownReactionsByTargetId,
      fetchReactionsByHighlightId,
      fetchOwnReactionByTargetId,
      fetchDiffTargetHighlightCommentReactions,
      createReactionRequestData,
      saveReaction,
      removeReaction,
      updateStoredReactions,
      clearReactions,
    } = useReaction()

    const {
      saveSceneMovies,
      removeInvalidChars,
      fetchSceneMoviesList,
      sceneMoviesList,
      sceneMoviesByHighlightId,
      clearSceneMovies,
    } = useSceneMovies()

    const {
      saveRaceHistory,
      saveRaceOnboardHistory,
      saveHighlightHistory,
      saveHighlightOnboardHistory,
    } = useHistory()

    const radioDataStore = useRadioData(targetRace)

    // API requests

    // methods
    /**
     * 動画プレーヤーのインスタンスを設定する
     * @param videoPlayer 動画プレーヤー
     */
    const setVideoPlayer = (videoPlayer: IVideoPlayer) => {
      raceVideoPageState.videoPlayer = videoPlayer
    }
    /**
     * サブ動画プレーヤーのインスタンスを設定する
     * @param videoPlayer 動画プレーヤー
     */
    const setSubVideoPlayer = (videoPlayer: IVideoPlayer) => {
      raceVideoPageState.subVideoPlayer = videoPlayer
    }
    /**
     * 動画状態を設定する
     * @param videoStatus 動画状態
     */
    const setVideoStatus = (videoStatus: VideoStatusType) => {
      raceVideoPageState.videoStatus = videoStatus
    }
    /**
     * 動画プレーヤー状態を設定する
     * @param videoPlayerStatus 動画プレーヤー状態
     */
    const setVideoPlayerStatus = (videoPlayerStatus: VideoPlayerStatusType) => {
      raceVideoPageState.videoPlayerStatus = videoPlayerStatus
    }

    /**
     * 指定された実時間から、動画の再生位置を計算する。
     *
     * @param movieInfo 動画情報
     * @param movieStartActualTime 再生位置を求める実時間。UnixTime(ミリ秒)。未指定の場合、0秒を返却する
     */
    const computeMovieStartTime = (
      movieInfo: ContentsInfoDocument | AngleMovieInfoDocument,
      movieStartActualTime: number | undefined,
    ) => {
      if (!movieStartActualTime) {
        return 0
      }
      const recordingStartTime = movieInfo.recordingStartTimeCalculated
      return (movieStartActualTime - recordingStartTime) / 1000
    }

    /** 最後にアングル切り替えを行った時間 */
    let lastAngleChangedTime = 0

    /**
     * 再生対象の動画を切り替える。
     * @param viewAngle メイン映像か、オンボード映像
     * @param ignoreAlert 切り替え後のアラート表示を抑止するかどうか。
     * オンボード映像が未配信の場合にメイン映像に自動的に切り替える際、メイン映像に対するアラートの表示を抑止する場合に指定する。
     */
    const setViewAngle = (viewAngle: ViewAngleType, ignoreAlert = false) => {
      if (now() - lastAngleChangedTime < 300) {
        // アングル切り替えを100msよりも短い間隔で実施されると切り替え後の再生時間の計算が正しくできなくなるため、
        // アングル切り替え後、300msはアングル切り替え不可とする
        return
      }

      raceVideoPageState.ignoreAlert = ignoreAlert
      lastAngleChangedTime = now()

      if (AppConfigStore.value.currentCircuitMode.value) {
        // サーキットモードの場合、再生対象の動画はメイン映像のみとする
        raceVideoPageState.viewAngle = 'race'
        return
      }
      raceVideoPageState.viewAngle = viewAngle
    }

    /**
     * 現在選択されているレースの大会ID
     */
    const currentChampionshipMasterId = computed({
      get: (): string | null => {
        Logger.debug(
          `RaceVideoPageStore#raceVideoPageState.championshipMasterId: ${raceVideoPageState.championshipMasterId}`,
        )
        return raceVideoPageState.championshipMasterId
      },
      set: (championshipMasterId: string | null) => {
        raceVideoPageState.championshipMasterId = championshipMasterId
      },
    })

    /**
     * 現在選択されているレースのID
     */
    const currentRaceId = computed({
      get: (): string | null => {
        Logger.debug(
          `RaceVideoPageStore#raceVideoPageState.championshipMasterId: ${raceVideoPageState.raceId}`,
        )
        return raceVideoPageState.raceId
      },
      set: (raceId: string | null) => {
        raceVideoPageState.raceId = raceId
      },
    })

    /**
     * 現在選択されている選手のID
     */
    const selectedPlayerId = computed({
      get: (): string | null => {
        Logger.debug(
          `RaceVideoPageStore#raceVideoPageState.selectedPlayerId: ${raceVideoPageState.selectedPlayerId}`,
        )
        return raceVideoPageState.selectedPlayerId
      },
      set: (playerId: string | null) => {
        raceVideoPageState.selectedPlayerId = playerId
        telemetryStore.clearTelemetries()
        telemetryStore.lastTelemetryFetch = 0
      },
    })

    /**
     * 英語公式映像のアングルID。
     */
    const englishLiveAngleId = computed(() => {
      const englishLiveAngleInfo = angleInfos.value?.find(
        (angleInfoData) =>
          angleInfoData.additionalData?.videoType === 'english_language_live_video',
      )
      return englishLiveAngleInfo?.angleId
    })

    /**
     * 英語公式映像の動画情報。（英語実況中継映像はアングル情報として登録されている）
     */
    const englishLiveMovieInfo = computed(() => {
      const englishAngleMovieInfo = angleMovieInfos.value?.find(
        (angleMovieInfo) => angleMovieInfo.angleId === englishLiveAngleId.value,
      )
      if (!englishLiveAngleId.value) {
        Logger.debug('RaceVideoPageStore#englishAngleMovieInfo: englishLiveAngleId not found.')
        return null
      }

      const anglePlayInfo = angleMoviePlayInfoByAngleId.value?.[englishLiveAngleId.value]
      Logger.debug(`RaceVideoPageStore#englishAngleMovieInfo: ${englishAngleMovieInfo}`)
      if (englishAngleMovieInfo) {
        englishAngleMovieInfo.anglePlayInfo = anglePlayInfo
      }

      return englishAngleMovieInfo
    })

    /**
     * 現在選択されている選手情報
     */
    const selectedPlayer = computed(() =>
      players.value.find((player) => player.playerId === selectedPlayerId.value),
    )
    /**
     * ハイライト画面の選手フィルタで選択されている選手情報
     */
    const selectedPlayerForHighlight = computed(() => {
      if (raceVideoPageHighlightState.selectedPlayerMasterIdForHighlight) {
        return playersById.value[raceVideoPageHighlightState.selectedPlayerMasterIdForHighlight]
      }
      return undefined
    })

    /**
     * 現在選択中の選手のオンボード映像(アングル情報)
     */
    const currentAngleInfo = computed(() => {
      const playerId = selectedPlayer.value?.playerId
      if (!playerId) {
        return undefined
      }

      return angleInfos.value?.find((angleInfoData) => angleInfoData.playerId === playerId)
    })

    /**
     * 現在選択されている選手のオンボード映像のアングル動画情報。
     * 以下のいずれかの選択状態の選手のアングル動画情報を返す。
     * - ハイライトカードに関連づけられた選手を選択している
     * - テレメトリー表示中の動画再生画面の視聴選手として選択されている
     */
    const currentPlayerOnBoardMovieInfo = computed(() => {
      // 視聴選手で選択されている、あるいは、ハイライトカードで選択されている場合、その選手のオンボード映像のアングル動画情報を返す
      const playerId = selectedPlayer.value?.playerId
      const angleInfo = currentAngleInfo.value
      const onBoardAngleId = angleInfo?.angleId
      if (!onBoardAngleId) {
        Logger.debug(
          `RaceVideoPageStore#currentAngleMovieInfo: onBoardAngleId not found. playerId: ${playerId}`,
        )
        return null
      }

      const currentAngleMovieInfo = angleMovieInfos.value?.find(
        (angleMovieInfo) => angleMovieInfo.angleId === onBoardAngleId,
      )
      const anglePlayInfo = angleMoviePlayInfoByAngleId.value?.[onBoardAngleId]
      Logger.debug(`RaceVideoPageStore#currentAngleMovieInfo: ${currentAngleMovieInfo}`)
      if (currentAngleMovieInfo) {
        currentAngleMovieInfo.anglePlayInfo = anglePlayInfo
      }

      return currentAngleMovieInfo
    })

    /**
     * ハイライトカードで選択されている選手の選手マスタID
     */
    const selectedPlayerMasterIdForHighlight = computed({
      get: (): string | null => {
        Logger.debug(
          `RaceVideoPageStore#raceVideoPageState.selectedPlayerMasterIdForHighlight: ${raceVideoPageHighlightState.selectedPlayerMasterIdForHighlight}`,
        )
        return raceVideoPageHighlightState.selectedPlayerMasterIdForHighlight
      },
      set: (playerMasterId: string | null) => {
        raceVideoPageHighlightState.selectedPlayerMasterIdForHighlight = playerMasterId
        if (playerMasterId) {
          selectedPlayerId.value = playersById.value[playerMasterId]?.playerId
        }
      },
    })

    /**
     * ユーザーの言語設定に応じて中継映像を日本語/英語で出し分ける
     * ※ユーザーの言語設定が英語の場合であっても英語の中継映像が存在しない場合は日本語の中継映像を表示する
     */
    const officialMovieInfo = computed(() => {
      const userLang = UserStore.value.user.value?.lang || DeviceInfo.getLanguage()
      return userLang === 'en' && englishLiveMovieInfo.value
        ? englishLiveMovieInfo.value
        : contentsInfo.value
    })

    /**
     * 中継映像が英語かどうか
     */
    const isMainEnglishLiveMovie = computed(
      () => officialMovieInfo.value?.angleId === englishLiveAngleId.value,
    )

    /**
     * 現在の設定で再生対象となる動画情報を取得する。
     */
    const getMovieInfo = computed(() => {
      let movieInfo: ContentsInfoDocument | AngleMovieInfoDocument | undefined
      if (raceVideoPageState.viewAngle === 'race') {
        movieInfo = officialMovieInfo.value
      } else {
        movieInfo = currentPlayerOnBoardMovieInfo.value || undefined
      }

      if (movieInfo) {
        if (highlightStore.selectedHighlightId.value) {
          // ハイライトが選択されている場合、ハイライト再生用の情報を設定する
          movieInfo.highlightPlayInfo = { highlightId: highlightStore.selectedHighlightId.value }
        }
        if (movieInfo instanceof AngleMovieInfoDocument && movieInfo.angleId) {
          // アングル動画情報にアングル情報を紐づける
          const targetAngleInfo = angleInfosById.value[movieInfo.angleId]
          if (!targetAngleInfo) {
            Logger.debug(
              `There is no angle_info related to angle_movie_info. angle_movie_info: ${movieInfo}`,
            )
          }
          movieInfo.angleInfo = targetAngleInfo ?? null
        }
      }
      return movieInfo
    })

    /**
     * 現在再生対象となっている動画情報。
     * 公式映像か、現在選択されている選手のオンボード映像の動画情報のどちらかを返す。
     */
    const currentMovieInfo = computed(() => getMovieInfo.value || officialMovieInfo.value)

    /**
     * 現在再生している映像のアングルIDを取得する。
     * 日本語公式映像（メインアングル）の場合、 `main` を返す
     */
    const currentAngleId = computed(() => {
      if (currentMovieInfo.value instanceof ContentsInfoDocument) {
        return 'main'
      }
      return currentMovieInfo.value?.angleId || 'main'
    })

    /**
     * 日本語または英語の公式映像を表示しているかを判定
     * 日本語公式映像の場合：型がContentsInfoDocument
     * 英語公式映像の場合：現在再生対象となっている映像のアングルIDが英語公式映像のアングルID
     */
    const isOfficialMovie = computed(
      () =>
        currentMovieInfo.value instanceof ContentsInfoDocument ||
        currentMovieInfo.value?.angleId === englishLiveAngleId.value,
    )

    /**
     * 任意の動画の配信開始日時を取得する。
     * 大会マスタのdisableAdjustRecordingStartTimeの設定値に応じて、配信開始時間を調整する。
     */
    const getRaceLiveStreamingStartTime = (
      targetMovieInfo: ContentsInfoDocument | AngleMovieInfoDocument,
    ) =>
      (targetMovieInfo.recordingStartTimeCalculated || Const.RECORDING_START_ADJUST_TIME) -
      (targetChampionship.value?.additionalData?.disableAdjustRecordingStartTime
        ? 0
        : Const.RECORDING_START_ADJUST_TIME)

    /**
     * 動画プレーヤーをスロー再生するかどうか
     */
    const videoSlow = computed({
      get: (): boolean => raceVideoPageState.videoSlow,
      set: (isSlow: boolean) => {
        raceVideoPageState.videoSlow = isSlow
      },
    })

    /**
     * 新着ハイライトがあるかどうか
     */
    const hasNewHighlight = computed({
      get: (): boolean => raceVideoPageState.hasNewHighlight,
      set: (isNew: boolean) => {
        raceVideoPageState.hasNewHighlight = isNew
      },
    })

    /**
     * ハイライト新着通知を管理するために使用する一時退避データ
     */
    const savedLastFetchedHighlightForNotifyData = computed({
      get: (): LastSeenHighlightDataType | null =>
        raceVideoPageState.savedLastFetchedHighlightForNotifyData,
      set: (lastFetchedInfo: LastSeenHighlightDataType | null) => {
        raceVideoPageState.savedLastFetchedHighlightForNotifyData = lastFetchedInfo
      },
    })

    /**
     * 自分が作成した新規ハイライトコメントデータ
     */
    const newOwnHighlightComments = computed({
      get: (): CommentDataType[] => raceVideoPageState.newOwnHighlightComments,
      set: (highlightComments: CommentDataType[]) => {
        raceVideoPageState.newOwnHighlightComments = highlightComments
      },
    })

    /**
     * 選択中のハイライトタブ
     */
    const selectedHighlightTab = computed({
      get: () => raceVideoPageState.selectedHighlightTab,
      set: (tabId: HighlightTabsType) => {
        raceVideoPageState.selectedHighlightTab = tabId
      },
    })

    /**
     * ユーザー作成ハイライト一覧を表示している場合のプルダウン
     */
    const userFilterListForUserCreateHighlights = computed(() => {
      /** 自分の投稿 */
      const myPostLabel = I18n.tc('RaceVideoPage.highlights.selectListLabel.myPost')
      const myPost = [
        {
          id: user.value?.id ?? '',
          name: {
            ja: myPostLabel,
            en: myPostLabel,
          },
          type: 'self',
        },
      ]

      const createHighlightUsers = retrieveNameUserList.value.filter((v) =>
        highlightStore.highlights.value.some((highlight) => highlight?._createdBy === v.userId),
      )
      // ハイライトを作成したユーザーのroleリスト
      const relatedHighlightRoles = uniq(createHighlightUsers.map((v) => v.roles).flat())

      /** 特別なロールを持ったユーザーリスト（Ambassadorなど） */
      const specialUserList = Const.SFGO_ROLES_FOR_USER_TYPE.filter((role) =>
        // ハイライトを作成したユーザーのroleリストに含まれているロールのみを表示する
        relatedHighlightRoles.includes(role),
      ).map((filteredRole) => {
        const displayLabel = Const.SFGO_USER_TYPE_LABELS[filteredRole]
        return {
          id: filteredRole,
          name: {
            ja: displayLabel,
            en: displayLabel,
          },
          type: 'role',
        }
      })

      /**
       * ハイライト作成ユーザーリスト
       * ログインユーザーは除外する
       */
      const createHighlightUserList = createHighlightUsers
        .filter((createHighlightUser) => createHighlightUser.userId !== user.value.id)
        .map((createHighlightUser) => ({
          id: createHighlightUser.userId ?? '',
          name: {
            ja: createHighlightUser.additionalData?.userDisplayName ?? '',
            en: createHighlightUser.additionalData?.userDisplayName ?? '',
          },
          type: 'userDisplayName',
        }))

      return [...myPost, ...specialUserList, ...createHighlightUserList]
    })

    /**
     * 現在の再生位置から、指定されたアングルに切り替えた場合の再生位置を計算して返す。
     * 計算対象の再生位置を指定したい場合、targetCurrentTime に値を指定する。
     * @param nextAngleId 変更後のアングルID。メインの場合は 'main'
     * @param previousMovieInfo 変更前の動画の情報
     * @param targetCurrentTime 計算対象とする再生位置(変更前の動画の再生位置を指定する)
     * @param logging ログ出力するかどうか
     * @return アングル切り替え後の再生位置。マイナス値が返却された場合、対応する再生位置が存在しないことを示す。
     */
    const computeCurrentTime = (
      nextAngleId: string | 'main',
      previousMovieInfo: ContentsInfoDocument | AngleMovieInfoDocument,
      targetCurrentTime?: number,
      logging = true,
    ) => {
      if (logging) {
        Logger.info(
          `RaceVideoPageStore#computeCurrentTime: nextAngleId: ${nextAngleId}, previousMovieInfo: ${previousMovieInfo}`,
        )
      }
      // 現在再生している動画の再生位置
      const currentTime = targetCurrentTime || raceVideoPageState?.videoStatus?.currentTime || 0
      if (logging) {
        Logger.debug(`RaceVideoPageStore#computeCurrentTime: currentTime: ${currentTime}`)
      }
      if (previousMovieInfo.angleId === nextAngleId) {
        // 切り替え前と切り替え先のアングルが同じ場合、計算不要なため、現在の再生位置を返す
        Logger.debug(
          `RaceVideoPageStore#computeCurrentTime: Skip compute currentTime due to same angle specified. angleId: ${nextAngleId} currentTime: ${raceVideoPageState?.videoStatus?.currentTime}`,
        )
        return currentTime
      }

      // 変更後のアングル動画のアングル動画再生情報
      const nextAnglePlayInfo = angleMoviePlayInfoByAngleId.value[nextAngleId]

      if (!nextAnglePlayInfo) {
        // 変更先のアングルがない場合、切り替え不可なため、マイナス値を返す
        Logger.debug(
          `RaceVideoPageStore#computeCurrentTime: anglePlayInfo not found. angleId: ${nextAngleId}`,
        )
        return -1
      }
      if (logging) {
        Logger.debug(
          `RaceVideoPageStore#computeCurrentTime: next angleId: ${nextAngleId}, adjusting time: ${nextAnglePlayInfo.movieStartTime}`,
        )
      }

      if (previousMovieInfo instanceof ContentsInfoDocument) {
        // メイン動画を再生していた場合、メイン動画の再生位置に対して、アングル動画再生情報のmovieStartTimeの値を加算して、アングル動画の再生位置を求める
        // previousMovieInfo が未指定の場合、メイン動画を再生しているとみなす
        if (logging) {
          Logger.debug(`RaceVideoPageStore#computeCurrentTime: main -> angle[${nextAngleId}]`)
        }
        const nextCurrentTime = currentTime + nextAnglePlayInfo.movieStartTime
        if (logging) {
          Logger.debug(
            `RaceVideoPageStore#computeCurrentTime: currentTime[${currentTime}] + nextAnglePlayInfo.movieStartTime[${nextAnglePlayInfo.movieStartTime}] = ${nextCurrentTime}`,
          )
        }
        return nextCurrentTime
      }
      if (logging) {
        Logger.debug(
          `RaceVideoPageStore#computeCurrentTime: angle[${previousMovieInfo.angleId}] -> angle[${nextAngleId}]`,
        )
      }

      // アングル動画を再生していた場合、一度、メイン動画の再生位置を求めてから、変更後のアングル動画再生情報のmovieStartTimeの値を加算して、アングル動画の再生位置を求める
      if (previousMovieInfo.angleId === nextAnglePlayInfo.angleId) {
        return currentTime
      }
      const previousAnglePlayInfo = previousMovieInfo.anglePlayInfo
      const mainCurrentTime = currentTime - (previousAnglePlayInfo?.movieStartTime || 0)
      const nextCurrentTime = Number(mainCurrentTime) + Number(nextAnglePlayInfo.movieStartTime)
      if (logging) {
        Logger.info(
          `RaceVideoPageStore#computeCurrentTime: mainCurrentTime[${mainCurrentTime}] + nextAnglePlayInfo.movieStartTime[${nextAnglePlayInfo.movieStartTime}] = ${nextCurrentTime}`,
        )
      }
      return nextCurrentTime
    }

    /**
     * メイン動画の再生位置を算出する。
     * アングル動画を再生している場合、その再生位置からメイン動画の再生位置を計算して返す。
     */
    const currentTimeMainVideoTime = computed(() => {
      let currentTime = raceVideoPageState?.videoStatus?.currentTime || 0
      if (getMovieInfo.value instanceof AngleMovieInfoDocument) {
        // アングル動画を再生している場合、メイン動画の再生位置に変更する。
        // ハイライトはメイン動画に対してタグづけされているため、開始と終了は、メイン動画の再生位置が設定されているため。
        currentTime = computeCurrentTime('main', getMovieInfo.value, undefined, false)
      }
      return currentTime
    })

    /**
     * 現在再生しているアングルを切り替えた場合の再生位置を計算する。
     * @param targetCurrentTime 計算対象とする再生位置(変更前の動画の再生位置を指定する)
     */
    const computeOpponentAngleCurrentTime = (targetCurrentTime?: number) => {
      const movieInfo = getMovieInfo.value
      const opponentAngleInfo = isOfficialMovie.value
        ? currentPlayerOnBoardMovieInfo.value
        : officialMovieInfo.value
      if (opponentAngleInfo?.angleId && movieInfo) {
        return computeCurrentTime(opponentAngleInfo.angleId, movieInfo, targetCurrentTime, false)
      }
      return undefined
    }

    /**
     * メイン動画とオンボード映像の切り替えができるかどうかを判定する。
     */
    const isAngleChangeAvailable = computed(() => {
      if (AppConfigStore.value.currentCircuitMode.value) {
        // サーキットモードの場合、映像切り替えはできないため、切り替え不可と判定する
        return false
      }
      const opponentCurrentTime = computeOpponentAngleCurrentTime()
      // 切り替えた場合の再生位置を計算し、再生位置がマイナス値の場合、切り替え不可と判定する
      if (opponentCurrentTime === undefined) {
        return false
      }
      return opponentCurrentTime >= 0
    })

    /**
     * ハイライト作成ができるかどうかを判定する。
     */
    const isCreateHighlightAvailable = computed(
      () =>
        // メイン動画の再生位置がマイナスではない、かつサーキットモードではない場合、ハイライトを作成できる
        currentTimeMainVideoTime.value >= 0 && !AppConfigStore.value.currentCircuitMode.value,
    )

    /**
     * 動画の再生位置に対応する、実時間(UnixTime 単位:ミリ秒)を計算する。
     * 実時間は、対象のレースのライブ配信開始時間（これは動画の開始位置(0秒地点)と一致する）から、現在の動画の再生位置を足すことで求める。
     * @param round 実時間(UnixTime 単位:ミリ秒) を MathUtil.round を利用して丸めるかどうか
     * @param base 時間を丸める場合、どの桁で丸めるかを指定する。デフォルトは100の桁で四捨五入する
     * @param targetVideoInfo 任意の再生位置の実時間を取得する際に指定する
     */
    const computeActualTimeForVideoPlaybackPosition = (
      round = true,
      base: 100 | 1000 | 50 = 100,
      targetVideoInfo?: TargetVideoInfoType,
    ) => {
      const isCurrent = !targetVideoInfo

      // 映像のタイムコードから取得した実時間を設定する
      let currentVideoTrackDateTime = 0
      if (isCurrent) {
        currentVideoTrackDateTime = raceVideoPageState.videoStatus?.currentVideoTrackDateTime || 0
      } else if (!isCurrent) {
        currentVideoTrackDateTime = targetVideoInfo.targetVideoTrackDateTime
      }

      // 動画情報のライブ映像配信開始時間(recordingStartTime)の値をもとに、現在の動画の再生位置の時刻を計算する
      let currentTime = 0
      if (isCurrent) {
        currentTime = raceVideoPageState?.videoStatus?.currentTime || 0
      } else if (!isCurrent) {
        currentTime = targetVideoInfo.targetVideoTime
      }

      // 動画の再生時間の単位は秒だが、telemetryHashedByCreatedDateが保持する時間の単位はミリ秒であるため、ミリ秒に変換する
      const currentTimeMilliseconds = Math.round(currentTime * 1000)

      // 動画の再生位置の実時間を取得する。
      // ・映像のタイムコードから取得した実時間が取得できる場合、その値を利用する.
      // ・存在しない場合、対象のレースのライブ映像配信開始時間に、動画の再生時間を加算する。この計算で、動画の再生位置の実時間を取得する
      let actualTimeForVideoPlaybackPosition = !currentVideoTrackDateTime
        ? currentTimeMilliseconds +
          getRaceLiveStreamingStartTime(
            isCurrent ? currentMovieInfo.value : targetVideoInfo?.targetMovieInfo,
          )
        : currentVideoTrackDateTime

      // 映像途中のテレメトリー補正情報がある場合、それを加算する
      const adjustTelemetryValue = isCurrent
        ? currentAngleInfo.value?.computeTelemetryAdjustValue(currentTime)
        : 0
      if (adjustTelemetryValue) {
        actualTimeForVideoPlaybackPosition += adjustTelemetryValue.adjustValue * 1000
      }
      return round
        ? MathUtil.round(actualTimeForVideoPlaybackPosition, base)
        : actualTimeForVideoPlaybackPosition
    }

    /**
     * 直前に表示していたライブタイミングデータを保持する。
     */
    const previousLiveTiming = {
      /** 直前に表示していたライブタイミングデータ */
      liveTiming: null as LiveTimingDocument | null,
      /** 直前にライブタイミングデータを表示していた動画の再生時間 */
      movieTime: 0,
    }

    /**
     * 現在の動画の再生位置のライブタイミングデータを取得する。
     */
    const getCurrentLiveTiming = computed((): LiveTimingDocument | null => {
      const currentTime = raceVideoPageState?.videoStatus?.currentTime || 0
      const actualTimeRounded = computeActualTimeForVideoPlaybackPosition(true, 1000)
      const currentLiveTiming =
        liveTimingStore.liveTimingHashedByCreatedDate.value.get(actualTimeRounded)
      if (currentLiveTiming) {
        previousLiveTiming.liveTiming = currentLiveTiming
        previousLiveTiming.movieTime = currentTime
      }

      // ライブタイミングデータが見つからなかった場合、直前に表示していたライブタイミングデータを返す。
      // ライブタイミングデータが途切れてしまう場合があり、その際に、ライブタイミングデータの表示が0値になってしまうことを防ぐため、
      // 抜けがあった場合でも、5秒間は直前に表示していたライブタイミングデータを返す。
      if (Math.abs(previousLiveTiming.movieTime - currentTime) > 5) {
        previousLiveTiming.liveTiming = null
        previousLiveTiming.movieTime = 0
      }

      return currentLiveTiming || previousLiveTiming.liveTiming
    })

    /**
     * 動画で再生中のセッション(フリー走行/予選/決勝)に出走している選手のリストを取得する。
     * ライブタイミングのエントリーデータをもとに、セッションに出走して選手のリストを算出する。
     * 現在の動画の再生位置にライブタイミングがなかった場合、大会の参加選手のリストを返す。
     */
    const exactParticipatingPlayers = computed(() => {
      const currentLiveTimings = getCurrentLiveTiming.value?.liveTimings
      if (!currentLiveTimings) {
        return participatingPlayers.value
      }
      return participatingPlayers.value.reduce<Array<PlayerDocument>>((enteredPlayers, player) => {
        const existPlayerLiveTiming = currentLiveTimings.find((playerLiveTiming) =>
          player.isRelatedCar(playerLiveTiming.CARNO),
        )
        if (existPlayerLiveTiming) {
          enteredPlayers.push(player)
        } else if (player.getDisplayCarNo().includes(Const.CAR_NO_ALWAYS_SHOW)) {
          // ライブタイミングデータの有無に関わらず選手リストに表示する
          enteredPlayers.push(player)
        }
        return enteredPlayers
      }, [])
    })

    /**
     * 現在選択中のレースのアラートメッセージを取得
     */
    const currentRaceInformation = computed(() => {
      let commonMessage = {} as AlertMessage
      let resultMessage = {} as AlertMessage
      const noneMessage = {
        playerId: '',
        message: { ja: '', en: '' },
        isShow: false,
      } as AlertMessage

      if (!targetChampionship.value) return noneMessage
      if (!targetChampionship.value.additionalData) return noneMessage
      if (!targetChampionship.value.additionalData.alertMessages) return noneMessage

      const alertMessages = targetChampionship.value.additionalData?.alertMessages

      const currentRaceMessages = alertMessages.find(
        (messages) => messages.matchId === currentRaceId.value,
      )

      if (!currentRaceMessages) return noneMessage

      commonMessage = currentRaceMessages.alertMessages.find(
        (messages) => messages.playerId === 'common',
      ) as AlertMessage

      const playerId =
        raceVideoPageState.viewAngle === 'race' ? 'raceVideo' : selectedPlayer.value?.playerId

      resultMessage = currentRaceMessages.alertMessages.find(
        (messages) => messages.playerId === playerId,
      ) as AlertMessage

      if (!resultMessage) {
        return noneMessage
      }

      // 個別メッセージが空 && 表示フラグtrue、また、共通メッセージ(jaまたはen)がある場合
      if (resultMessage.isShow && (commonMessage.message.ja || commonMessage.message.en)) {
        if (!resultMessage.message.ja && !resultMessage.message.en) {
          resultMessage.message = {
            ja: commonMessage.message.ja,
            en: commonMessage.message.en,
          }
        } else if (!resultMessage.message.ja && resultMessage.message.en) {
          resultMessage.message = {
            ja: commonMessage.message.ja,
            en: resultMessage.message.en,
          }
        } else if (resultMessage.message.ja && !resultMessage.message.en) {
          resultMessage.message = {
            ja: resultMessage.message.ja,
            en: commonMessage.message.en,
          }
        }
      }

      return resultMessage
    })

    /**
     * 再生対象の動画がライブ配信中の動画がどうかを判定する。
     * @return ライブ配信中の動画の場合、true を返す
     */
    const isLive = computed(() => !!getMovieInfo.value?.isLiveBroadcasting())

    /**
     * 映像が未配信かどうかを判定する。
     */
    const isLiveNotStarted = computed(() => currentMovieInfo.value?.isNotBroadcasting())

    /**
     * ユーザー情報を取得済みかどうかを判定する。
     */
    const isFetchedUserInfo = computed(() => !!user.value)

    /**
     * レースの関連情報を取得する。
     */
    const fetchRaceData = async () => {
      if (
        raceVideoPageState.raceId &&
        raceVideoPageState.championshipMasterId &&
        raceVideoPageState.organizationId
      ) {
        const lastModifiedDate = raceVideoPageState.lastRaceDataFetchedDate
        // レース関連情報を取得
        Logger.debug(
          `RaceVideoPageStore#fetchRaceData: Start to fetch race data. raceId: ${raceVideoPageState.raceId}`,
        )
        const result = await fetchRace(
          raceVideoPageState.championshipMasterId,
          raceVideoPageState.raceId,
          raceVideoPageState.organizationId,
          lastModifiedDate,
        )
        Logger.debug(
          `RaceVideoPageStore#fetchRaceData: Finish to fetch race data. raceId: ${raceVideoPageState.raceId}`,
        )
        // テレメトリー補正情報が更新される場合があるため、テレメトリーデータも初期化する
        telemetryStore.clearTelemetries()
        raceVideoPageState.lastRaceDataFetchedDate = now()
        return result
      }
      return [] as Array<Response<DocumentWrapper>>
    }

    /**
     * 最後に無線交信データの取得を行なった日時(UnixTime: ミリ秒)
     */
    const lastFetchedRadioData = ref<number | null>(null)

    /**
     * 現在再生しているレースの無線更新データを取得する。
     *
     * レース開始時間からレース終了時刻までの無線交信データを取得する。
     * レースの開始と終了時刻は、レースのライブ配信開始日時と動画長を元に計算する。
     * ライブ配信中は、ライブ配信開始日時から現在日時までの無線交信データを取得する。
     */
    const fetchRadioData = async () => {
      let fromDate: number | undefined | null
      if (lastFetchedRadioData.value) {
        Logger.debug(
          `fetch radio data appended since the last data fetch. lastFetchedRadioData: ${new Date(
            lastFetchedRadioData.value,
          )}`,
        )
        fromDate = lastFetchedRadioData.value
      } else {
        fromDate = currentMovieInfo.value.recordingStartTime
          ? currentMovieInfo.value.recordingStartTime
          : targetRace.value.scheduleDate
      }

      if (fromDate && fromDate < now()) {
        await radioDataStore.fetchRadios(targetRace.value, fromDate, now())
        // 取得した無線交信データのうち、一番最後に作成された無線交信データの作成日時を取得する
        // 次に無線交信データを取得する時は、この時間以降の無線交信データのみを差分取得する
        lastFetchedRadioData.value = radioDataStore.latestRadioDataCreated()
      }
      return Promise.resolve({
        isSuccess: false,
      } as Response<RadioDataDocument>)
    }

    /**
     * レース動画再生画面で必要なデータを取得する。
     */
    const fetchRaceVideoPageData = async () => {
      if (
        raceVideoPageState.raceId &&
        raceVideoPageState.championshipMasterId &&
        raceVideoPageState.loginId &&
        raceVideoPageState.organizationId
      ) {
        Logger.debug(
          `RaceVideoPageStore#fetchRaceVideoPageData: Start to fetch race video page data. raceId: ${raceVideoPageState.raceId}`,
        )
        raceVideoPageState.isFetchedRaceInfo = false
        // レース関連情報を取得
        const fetchRaceResponses = await fetchRaceData()

        // 無線交信データを取得する。無線交信データの取得は時間がかかるため、完了を待機しない
        fetchRadioData().then((result) => {
          Logger.info(`Radio data fetch process completed. result: ${result}`)
        })

        // responses.find だと、TS2349: This expression is not callable. Each member of the union type ... のエラーがでるため、スプレッド構文で展開する
        // https://stackoverflow.com/questions/70262217/this-expression-is-not-callable-each-member-of-the-union-type-has-signature
        const failedFetchRaceResponse = [...fetchRaceResponses].find(
          (response) => !response.isSuccess,
        )
        if (failedFetchRaceResponse) {
          return [failedFetchRaceResponse]
        }

        const responses = await Promise.all([
          fetchUser(raceVideoPageState.loginId),
          fetchChampionship(raceVideoPageState.championshipMasterId),
          highlightStore.fetchOfficialHighlights(raceVideoPageState.raceId),
        ])

        const failedResponse = responses.find((response) => !response.isSuccess)
        if (failedResponse) {
          return [failedResponse]
        }

        if (!selectedPlayerId.value) {
          // 動画再生画面の表示した場合、お気に入り選手を選択状態にする。
          // ※動画再生画面で明示的に選手が変更されている場合は、それを維持する。
          selectedPlayerId.value = user?.value?.favoritePlayerId || null
        }

        raceVideoPageState.lastRaceDataFetchedDate = now()
        Logger.debug(
          `RaceVideoPageStore#fetchRaceVideoPageData: Finish to fetch race video page data. raceId: ${raceVideoPageState.raceId}`,
        )
        raceVideoPageState.isFetchedRaceInfo = true
        return responses
      }
      return [] as Array<Response<DocumentWrapper>>
    }

    /**
     * ハイライトの切り抜きが可能かどうか
     * ブラウザ版のみで利用する処理
     */
    const isTargetHighlightClipAvailableForPc = (targetHighlight: HighlightDocument): boolean => {
      if (targetHighlight.angleMovieId) {
        // オンボード映像または英語の中継映像（angleMovieId = englishLiveAngleId）の場合の処理
        const angleMovieInfo = angleMovieInfos.value.find(
          (angleMovie) => angleMovie.angleMovieId === targetHighlight.angleMovieId,
        )
        return angleMovieInfo?.canClipHighlightForPc() ?? false
      }

      // 日本語中継映像の場合の処理
      return contentsInfo.value.canClipHighlightForPc()
    }

    /**
     * ハイライトとコメントで取得したユーザーのID一覧
     */
    const highlightAndCommentUserIds = computed(() =>
      uniq([
        ...highlightCommentStore.highlightCommentUserIds.value,
        ...highlightStore.highlightUserIds.value,
      ]),
    )

    /**
     * ハイライトとコメントで表示するユーザー情報を取得する
     */
    const fetchHighlightAndCommentUsers = () =>
      fetchRetrieveNameUsers(highlightAndCommentUserIds.value)

    /**
     * 試合関連情報を定期的に取得する。
     */
    const startPollingFetchRaceData = () => {
      Logger.debug(
        `RaceVideoPageStore#fetchRaceData: Start to polling race data. raceId: ${raceVideoPageState.raceId}`,
      )
      if (
        raceVideoPageState.championshipMasterId &&
        raceVideoPageState.raceId &&
        raceVideoPageState.organizationId
      ) {
        startPollingRaceData(
          raceVideoPageState.championshipMasterId,
          raceVideoPageState.raceId,
          raceVideoPageState.organizationId,
        )
      }
    }

    /**
     * 視聴履歴の登録をする
     */
    const saveHistory = async () => {
      if (highlightStore.selectedHighlightId.value) {
        const playEventId = highlightStore.selectedHighlight.value?.playEventId || ''
        const playEventData =
          playEvents.value.find((playEvent) => playEvent.playEventId === playEventId) || null
        // 操作ログを登録する（ミッションとManagementサイトの利用情報DLで使用される）
        const apiResult =
          raceVideoPageState.viewAngle === 'race'
            ? await saveHighlightHistory(
                highlightStore.selectedHighlightId.value || '',
                targetChampionship.value || null,
                targetRace.value || null,
                user.value || null,
                playEventId,
                playEventData?.name.ja || '',
              )
            : await saveHighlightOnboardHistory(
                highlightStore.selectedHighlightId.value || '',
                targetChampionship.value || null,
                targetRace.value || null,
                user.value || null,
                playEventId,
                playEventData?.name.ja || '',
                selectedPlayerForHighlight.value?.playerId || '',
                selectedPlayerForHighlight.value?.playerName.ja || '',
              )
        Logger.debug(`HighlightHistory: ${apiResult.isSuccess}`)
      } else {
        const apiResult =
          raceVideoPageState.viewAngle === 'race'
            ? await saveRaceHistory(
                targetChampionship.value || null,
                targetRace.value || null,
                user.value || null,
              )
            : await saveRaceOnboardHistory(
                targetChampionship.value || null,
                targetRace.value || null,
                user.value || null,
                selectedPlayer.value?.playerId || '',
                selectedPlayer.value?.playerName.ja || '',
              )
        Logger.debug(`RaceHistory: ${apiResult.isSuccess}`)
      }
    }

    /**
     * サムネイルを取得する
     */
    const fetchThumbnailData = (time: number) => fetchThumbnail(time, currentMovieInfo.value)

    /**
     * ハイライトの作成・編集で使用するデータを取得する
     */
    const getHighlightData = (selectedHighlight = null as ParentCommentDataType | null) => {
      const highlightData = {
        user: {
          name: UserStore.value.user.value.userDisplayName || '',
          image: UserStore.value.user.value.userPicture
            ? CloudFrontUtil.getSignedUrl(UserStore.value.user.value.userPicture)
            : '',
        },
      } as EditHighlightData

      if (selectedHighlight) {
        const editHighlightData = highlightStore.highlightsSortByMovieStartTime.value.find(
          (highlight) => highlight.highlightId === selectedHighlight.highlightId,
        )
        if (editHighlightData) {
          highlightData.thumbnail = editHighlightData.thumbnailUri
          highlightData.editHighlightData = editHighlightData
        }
      } else {
        const videoTrackDateTime = raceVideoPageState.videoStatus?.currentVideoTrackDateTime || 0
        const isMain = getMovieInfo.value instanceof ContentsInfoDocument
        const playerLiveTiming = getCurrentLiveTiming.value?.liveTimings.find(
          (liveTiming) =>
            (isMain && liveTiming.POS === '1') ||
            (!isMain && selectedPlayer.value?.isRelatedCar(liveTiming.CARNO)),
        )
        const angleMovieId = !isMain ? getMovieInfo.value?.angleMovieId || '' : ''
        const playerMasterIds = []
        if (!isMain) {
          // オンボードの場合には、angleMovieIdから該当の選手のplayerMasterIdを取得し、playerMasterIdに設定する
          const angleMovieInfo = angleMovieInfos.value.find(
            (angleMovie) => angleMovie.angleMovieId === angleMovieId,
          )
          const angleInfo = angleInfos.value.find(
            (angle) => angle.angleId === angleMovieInfo?.angleId,
          )
          const targetPlayer = participatingPlayers.value.find(
            (player) => player.playerId === angleInfo?.playerId,
          )
          if (targetPlayer?.playerMasterId) playerMasterIds.push(targetPlayer.playerMasterId)
        }

        let eventTime = currentTimeMainVideoTime.value
        if (targetRace.value.raceType === 'QUALIFYING') {
          // 予選の場合には、直前のスタートイベントからの経過時間をeventTimeに設定する
          const targetStartEvent = highlightStore.startEvents.value
            .slice()
            .reverse()
            .find((highlight) => currentTimeMainVideoTime.value > (highlight.movieStartTime || 0))
          if (targetStartEvent)
            eventTime = currentTimeMainVideoTime.value - (targetStartEvent.movieStartTime || 0)
        }

        highlightData.thumbnail = CloudFrontUtil.getSignedUrl(
          targetThumbnail.value?.thumbnailPath || '',
        )

        const publicScope = hasPermission('shareHighlight')
          ? {
              parentOrganization: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
            }
          : null

        highlightData.editHighlightData = new HighlightDocument({
          eventTime,
          angleMovieId,
          playerMasterIds,
          userGameEventType: 'CREATED_BY_SFGO_USER',
          note: '',
          movieStartTime: currentTimeMainVideoTime.value,
          offsetStart: 0,
          offsetEnd: 30,
          matchId: currentMovieInfo.value?.matchId,
          publicScope,
          acl: { privateFlag: false },
          additionalData: {
            lap: playerLiveTiming?.LAPS || '',
            sector: SectorNameMap[`${playerLiveTiming?.CURRENT_SEC}`] ?? '--',
            videoTrackDateTime,
          },
        })
      }
      return highlightData
    }

    /**
     * ハイライトの作成・編集
     */
    const saveHighlight = (comment: string, highlight: HighlightDocument) => {
      const requestData = cloneDeep(highlight)
      requestData.note = comment
      return highlightStore.saveHighlight(requestData)
    }

    const getRadioDataSetForHighlight = (
      angleMovieInfo: AngleMovieInfoDocument,
      highlightStartActualTime: number,
    ) => {
      const angleInfo = angleInfos.value.find((angle) => angle.angleId === angleMovieInfo?.angleId)
      const targetPlayer = participatingPlayers.value.find(
        (player) => player.playerId === angleInfo?.playerId,
      )

      if (targetPlayer) {
        // 無線情報取得のフィルター：From
        const clipStartTimeFrom = highlightStartActualTime
        // 無線情報取得のフィルター：To
        const clipEndTimeFrom = highlightStartActualTime + 30 * 1000
        // 無線情報を取得し、clip_start_timeでソートする（降順）
        return [
          ...sortBy(
            radioDataStore.getPlayerRadioDataSetFilteredTime(
              targetPlayer.getDisplayCarNoNumeric,
              clipStartTimeFrom,
              clipEndTimeFrom,
            ),
            'clip_start_time',
          ),
        ]
      }
      return []
    }

    /**
     * ハイライト動画の切り抜き(アプリ)
     */
    const highlightExport = (highlight: HighlightDocument) => {
      let playlistPath = ''
      let startTime = 0
      const radioDataSet: Array<RadioDataDocument> = []
      let highlightStartActualTime = 0

      if (highlight.angleMovieId && highlight.angleMovieId !== englishLiveAngleId.value) {
        // オンボードの場合の処理
        const angleMovieInfo = angleMovieInfos.value.find(
          (angleMovie) => angleMovie.angleMovieId === highlight.angleMovieId,
        )

        if (angleMovieInfo) {
          // angleMovieInfoからパスを設定し、スタートタイムはメイン動画からの相対時間で算出し設定
          playlistPath = angleMovieInfo.playlistPath || ''
          startTime = computeCurrentTime(
            angleMovieInfo.angleId || '',
            contentsInfo.value,
            highlight.startTime,
            false,
          )
          // ユーザーゲームイベントの開始位置からハイライト開始位置の実時間を取得する
          highlightStartActualTime = computeActualTimeForVideoPlaybackPosition(false, 100, {
            targetVideoTime: startTime,
            targetVideoTrackDateTime: highlight.videoTrackDateTime,
            targetMovieInfo: angleMovieInfo,
          })
          radioDataSet.push(
            ...getRadioDataSetForHighlight(angleMovieInfo, highlightStartActualTime),
          )
        }
      } else {
        // 中継映像の場合には、userGameEventの値でパス・スタートタイムを設定
        playlistPath = highlight.playlistPath || ''
        startTime = highlight.startTime || 0
      }
      return exportHighlight(
        playlistPath,
        startTime || 0,
        startTime + (highlight.endTime || 0) - (highlight.startTime || 0) || 0,
        radioDataSet.length > 0
          ? { radioDataSet, clippingStartActualTime: highlightStartActualTime }
          : null,
      )
    }

    /**
     * ブラウザ用: 音声アイテムの入力パラメータを生成する
     * @param radioData 合成する無線交信データ
     * @param clippingStartActualTime ハイライト開始位置の実時間
     * @param clipStart クリップ範囲の始点（=ハイライトの再生開始位置）
     * @param clipEnd クリップ範囲の終点（=ハイライトの再生終了位置）
     */
    const generateAudioItemForPc = (
      radioData: Array<RadioDataDocument>,
      clippingStartActualTime: number,
      clipStart: number,
      clipEnd: number,
    ) =>
      radioData
        .map((radio) => {
          // 切り抜き動画の開始地点を基準とした音声の再生開始位置を計算し、m秒を秒にする
          const startTime = (radio.clip_start_time - clippingStartActualTime) / 1000

          return {
            // audios/以下のパスを設定する
            filePath: `audios/${radio.audio_clip.split('audios/')[1]}`,
            /**
             * 動画先頭位置を基準とした、音声の再生開始位置を設定
             * NOTE: 無線を合成すると2.5秒くらい再生が早まってしまう分「2.5秒」を加算（BE側に原因を調査してもらう必要がある）
             */
            offset: clipStart + startTime + 2.5,
          }
        })
        .filter(
          (requestData) =>
            // 上記で2.5秒加算した無線の合成開始位置が切り抜き動画の終了位置を超えている場合は合成対象外とする
            requestData.offset < clipEnd,
        )

    /**
     * ハイライト動画の切り抜き(PC)
     */
    const clipSceneMovie = (highlight: HighlightDocument) => {
      const round =
        targetChampionship.value.round === 0 ? '' : `RD${targetChampionship.value.round}_`
      const fileTitlePrefix = `${dayjs(targetRace.value.scheduleDate)
        .tz('Asia/Tokyo')
        .year()}_${round}${targetRace.value.title}`
      const scene = {
        gameEventId: highlight.id as string,
      } as SceneMoviesSceneType

      if (highlight.angleMovieId && highlight.angleMovieId !== englishLiveAngleId.value) {
        // オンボード映像の場合
        const angleMovieInfo = angleMovieInfos.value.find(
          (angleMovie) => angleMovie.angleMovieId === highlight.angleMovieId,
        )
        const angleInfo = angleInfos.value.find(
          (angle) => angle.angleId === angleMovieInfo?.angleId,
        )
        const targetPlayer = participatingPlayers.value.find(
          (player) => player.playerId === angleInfo?.playerId,
        )
        const clipStart = computeCurrentTime(
          angleMovieInfo?.angleId || '',
          contentsInfo.value,
          highlight.startTime,
          false,
        )

        const radioDataSet: Array<RadioDataDocument> = []
        let highlightStartActualTime = 0
        if (angleMovieInfo) {
          // ユーザーゲームイベントの開始位置からハイライト開始位置の実時間を取得する
          highlightStartActualTime = computeActualTimeForVideoPlaybackPosition(false, 100, {
            targetVideoTime: clipStart,
            targetVideoTrackDateTime: highlight.videoTrackDateTime,
            targetMovieInfo: angleMovieInfo,
          })
          radioDataSet.push(
            ...getRadioDataSetForHighlight(angleMovieInfo, highlightStartActualTime),
          )
        }

        scene.angleMovieId = highlight.angleMovieId
        scene.clipStart = clipStart
        scene.clipEnd = clipStart + (highlight.endTime || 0) - (highlight.startTime || 0)
        const carNo = targetPlayer?.getDisplayCarNoNumeric
        scene.fileTitle = removeInvalidChars(`${fileTitlePrefix}_${carNo ? String(carNo) : ''}`)
        scene.audio = generateAudioItemForPc(
          radioDataSet,
          highlightStartActualTime,
          scene.clipStart,
          scene.clipEnd,
        )
      } else {
        scene.fileTitle = removeInvalidChars(fileTitlePrefix)
      }

      const requestData = new SceneMoviesDocument({
        outputFormat: 'mp4',
        scenes: [scene],
        lang: UserStore.value.user.value?.lang || DeviceInfo.getLanguage(),
        watermarkPosition: {
          vertical: 'TOP',
          horizontal: 'RIGHT',
        },
      })

      return saveSceneMovies(requestData)
    }

    /**
     * 動画再生画面で取得したデータと、変更されたstateをクリアする。
     */
    const clearFetchRaceVideoPageData = () => {
      // 取得したデータをクリア
      clearRaces()
      clearUsers()
      clearChampionships()
      telemetryStore.clearTelemetries()
      highlightStore.clearHighlight()
      clearRetrieveNameUsers()
      clearPlayEvent()
      radioDataStore.clearRadioData()
      clearThumbnailData()
      clearReactions()
      clearSceneMovies()
      clearHighlightComments()
      lastFetchedRadioData.value = null

      // stateをクリア
      // ただし、選択されていた選手の状態は保持する
      const currentPlayerId = selectedPlayerId.value
      Object.assign(raceVideoPageState, raceVideoPageInitialState)
      raceVideoPageState.selectedPlayerId = currentPlayerId

      // 上記のObject.assignで適切に初期化されなかったため、個別に初期化
      raceVideoPageState.newOwnHighlightComments = []
    }

    return {
      fetchUser,
      fetchRaceData,
      fetchRaceVideoPageData,
      startPollingFetchRaceData,
      finishPollingRaceData,
      clearFetchRaceVideoPageData,
      raceVideoPageState,
      setVideoPlayer,
      setSubVideoPlayer,
      setVideoStatus,
      setVideoPlayerStatus,
      setViewAngle,
      computeCurrentTime,
      computeOpponentAngleCurrentTime,
      computeActualTimeForVideoPlaybackPosition,
      currentTimeMainVideoTime,
      videoPlayer: raceVideoPageState.videoPlayer,
      telemetryStore,
      liveTimingStore,
      targetChampionship,
      targetRace,
      user,
      isFetchedUserInfo,
      contentsInfo,
      officialMovieInfo,
      isMainEnglishLiveMovie,
      isOfficialMovie,
      getMovieInfo,
      currentAngleInfo,
      currentMovieInfo,
      currentAngleId,
      currentPlayerOnBoardMovieInfo,
      isAngleChangeAvailable,
      isCreateHighlightAvailable,
      isLive,
      isLiveNotStarted,
      players,
      participatingPlayers,
      currentChampionshipMasterId,
      currentRaceId,
      selectedPlayerId,
      selectedPlayer,
      fetchPlayEvent,
      playEvents,
      highlightStore,
      highlightCommentStore,
      fetchHighlightAndCommentUsers,
      fetchRetrieveNameUsers,
      retrieveNameUsersByUserId,
      fetchedRetrieveNameUserIds,
      selectedPlayerMasterIdForHighlight,
      selectedPlayerForHighlight,
      saveHistory,
      exactParticipatingPlayers,
      currentRaceInformation,
      fetchChampionship,
      getCurrentLiveTiming,
      fetchRadioData,
      lastFetchedRadioData,
      videoSlow,
      hasNewHighlight,
      savedLastFetchedHighlightForNotifyData,
      newOwnHighlightComments,
      selectedHighlightTab,
      userFilterListForUserCreateHighlights,
      gpsStore,
      radioDataStore,
      allPlayerTelemetryStore,
      computeMovieStartTime,
      fetchThumbnailData,
      getHighlightData,
      saveHighlight,
      angleInfos,
      angleMovieInfos,
      highlightExport,
      getRadioDataSetForHighlight,
      reactions,
      reactionsCountByTargetId,
      ownReactionsByTargetId,
      fetchOwnReactionByTargetId,
      createReactionRequestData,
      saveReaction,
      removeReaction,
      updateStoredReactions,
      englishLiveAngleId,
      isTargetHighlightClipAvailableForPc,
      clipSceneMovie,
      fetchSceneMoviesList,
      sceneMoviesList,
      sceneMoviesByHighlightId,
      fetchReactionsByHighlightId,
      fetchDiffTargetHighlightCommentReactions,
    }
  }
}

const value: ValueType<RaceVideoPageStore> = {}

export default {
  createStore: new RaceVideoPageStore().createStore,
  value: value as Required<typeof value>,
}
