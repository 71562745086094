var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-list-item-parts",
      class: { "select-list-item-parts--selected": _vm.isSelect },
      on: { click: _vm.selectChange },
    },
    [
      _c(
        "div",
        { staticClass: "select-list-item-parts__card" },
        [_vm._t("default")],
        2
      ),
      _vm.isSelect
        ? _c("img", {
            staticClass: "select-list-item-parts__check",
            attrs: {
              src: require("@/assets/img/icon/icon_checkbox_24.svg"),
              alt: "選択",
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }