import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { AngleMoviePlayInfoType } from '@/store/stores/collectionModule/documents/angleMovie/AngleMoviePlayInfoType'

/**
 * アングル切り替え情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/855638100/20+API
 */
export default class AngleMovieChangeInfoDocument extends DocumentWrapper {
  constructor(initProps?: Partial<AngleMovieChangeInfoDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  idAttr = 'matchId'

  /**
   * アングル動画情報配列
   */
  angle: Array<AngleMoviePlayInfoType> = []

  /**
   * このクラスの文字列表現を取得する。
   */
  toString() {
    return `AngleInfoDocument[matchId: ${this.id}, angle: ${this.angle?.map(
      (angleInfo) => angleInfo.angleId,
    )}}]`
  }
}
