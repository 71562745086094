









import { defineComponent } from '@vue/composition-api'
import ExternalConfirmationSection from '@/components/common/ExternalConfirmation/ExternalConfirmationSection.vue'

/**
 * アプリ版: クレジットカード情報変更ペイン
 */
export default defineComponent({
  name: 'MypagePaymentEditPane',
  components: {
    ExternalConfirmationSection,
  },
})
