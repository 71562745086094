var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-past-pane" }, [
    _c(
      "div",
      {
        staticClass: "mypage-ticket-past-pane__container",
        class: { "mypage-ticket-past-pane__container--pc": _vm.isPc },
      },
      [
        _c("div", { staticClass: "mypage-ticket-past-pane__ticket" }, [
          _c("div", { staticClass: "mypage-ticket-past-pane__header" }, [
            _c("p", { staticClass: "mypage-ticket-past-pane__place" }, [
              _vm._v(_vm._s(_vm.targetTicketEvent.eventName)),
            ]),
            _c("p", { staticClass: "mypage-ticket-past-pane__date" }, [
              _vm._v(_vm._s(_vm.displayEventDate)),
            ]),
          ]),
          _c("div", {
            staticClass: "mypage-ticket-past-pane__background",
            style: _vm.pastScreenBgImageStyle,
          }),
          _c("div", { staticClass: "mypage-ticket-past-pane__icons" }, [
            _vm.isEntered
              ? _c("div", { staticClass: "mypage-ticket-past-pane__stamp" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mypage-ticket-past-pane__circuit" },
              [
                _vm.targetTicketEvent.stadiumId === "TWIN_RING_MOTEGI"
                  ? _c("motegi-parts")
                  : _vm._e(),
                _vm.targetTicketEvent.stadiumId === "FUJI_SPEEDWAY"
                  ? _c("fuji-parts")
                  : _vm._e(),
                _vm.targetTicketEvent.stadiumId === "SPORTSLAND_SUGO"
                  ? _c("sugo-parts")
                  : _vm._e(),
                _vm.targetTicketEvent.stadiumId === "AUTOPOLIS"
                  ? _c("autopolice-parts")
                  : _vm._e(),
                _vm.targetTicketEvent.stadiumId === "SUZUKA_CIRCUIT"
                  ? _c("suzuka-parts")
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm.isPc && _vm.isOpen
          ? _c("div", { staticClass: "mypage-ticket-past-pane__buttons" }, [
              _c(
                "ul",
                { staticClass: "mypage-ticket-past-pane__buttons-list" },
                [
                  _c(
                    "li",
                    { staticClass: "mypage-ticket-past-pane__buttons-item" },
                    [
                      _c("atom-button", {
                        attrs: {
                          linkText: _vm.$tc(
                            "MypagePage.MypageTicketPastPage.download"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "mypage-ticket-past-pane__buttons-item" },
                    [
                      _c("atom-button", {
                        attrs: {
                          linkText: _vm.$tc(
                            "MypagePage.MypageTicketPastPage.post.x"
                          ),
                          colorType: "secondary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "mypage-ticket-past-pane__buttons-item" },
                    [
                      _c("atom-button", {
                        attrs: {
                          linkText: _vm.$tc(
                            "MypagePage.MypageTicketPastPage.post.instagram"
                          ),
                          colorType: "secondary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "mypage-ticket-past-pane__buttons-item" },
                    [
                      _c("atom-button", {
                        attrs: {
                          linkText: _vm.$tc(
                            "MypagePage.MypageTicketPastPage.edit"
                          ),
                          colorType: "secondary",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }