




























import { defineComponent, PropType } from '@vue/composition-api'
import RankingFlagParts from '@/components/RaceVideoPage/RaceVideoRankingPane/parts/RankingFlagParts.vue'
import ViewModeSelectorParts from '@/components/RaceVideoPage/RaceVideoRankingPane/parts/ViewModeSelectorParts.vue'
import AtomInputCheckbox from '@/components/atoms/input/AtomInputCheckbox.vue'
import { RankingViewMode } from '@/components/RaceVideoPage/RaceVideoRankingPane.vue'

/**
 * レース動画再生画面 ランキング表示設定セクションのコンポーネント
 */
export default defineComponent({
  name: 'RankingSettingSection',
  components: {
    RankingFlagParts,
    AtomInputCheckbox,
    ViewModeSelectorParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * Flag情報
     */
    flagType: {
      type: String,
      default: '',
    },
    /**
     * Leader表示状態
     */
    leader: {
      type: Boolean,
      default: false,
    },
    /**
     * データ表示モード指定
     */
    rankingViewMode: {
      type: String as PropType<RankingViewMode>,
      required: true,
      default: 'gap',
    },
    /**
     * データ表示モード一覧
     */
    rankingViewModeList: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    /**
     * チェックボックス押下
     */
    toggleLeader() {
      this.$emit('leaderToggle')
    },
    /**
     * 表示データ切り替え
     * @param mode: string
     */
    changeRankingViewMode(mode: RankingViewMode) {
      this.$emit('rankingViewModeChange', mode)
    },
  },
})
