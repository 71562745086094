










import { defineComponent } from '@vue/composition-api'

/**
 * エラーメッセージ表示
 */
export default defineComponent({
  name: 'FormErrorMessageParts',
  props: {
    /**
     * エラーを表示するかどうか
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * 表示するメッセージ
     */
    message: {
      type: String,
      default: '',
    },
  },
})
