































import { defineComponent } from '@vue/composition-api'

/**
 * 有料会員解約: 使用できなくなる機能 セクション
 */
export default defineComponent({
  name: 'UnusableFeatureSection',
  components: {},
})
