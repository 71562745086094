var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "telemetry-fuel-used-parts",
      class: { "telemetry-fuel-used-parts--running-low": _vm.fuelLow },
    },
    [
      _c("div", { staticClass: "telemetry-fuel-used-parts__header" }, [
        _vm._m(0),
        _c("p", { staticClass: "telemetry-fuel-used-parts__data" }, [
          _vm.fuelLow
            ? _c(
                "span",
                { staticClass: "telemetry-fuel-used-parts__low-label" },
                [_vm._v("LOW")]
              )
            : _vm._e(),
          !_vm.fuelLow
            ? _c("span", { staticClass: "telemetry-fuel-used-parts__number" }, [
                _vm._v(_vm._s(_vm.capacityUsed)),
              ])
            : _vm._e(),
          !_vm.fuelLow
            ? _c("sup", { staticClass: "telemetry-fuel-used-parts__unit" }, [
                _c("span", [_vm._v("L / 80")]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "svg",
        {
          staticClass: "telemetry-fuel-used-parts__gauge",
          attrs: {
            width: "145",
            height: "14",
            viewBox: "0 0 145 14",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c(
            "clipPath",
            { attrs: { id: "telemetry-fuel-used-parts__gauge__clip" } },
            [
              _c("path", {
                attrs: {
                  d: "M137 0H15.6569C14.596 0 13.5786 0.421427 12.8284 1.17157L4 10H123.686C125.808 10 127.843 9.15715 129.343 7.65685L137 0Z",
                  fill: "white",
                },
              }),
            ]
          ),
          _c(
            "g",
            {
              attrs: {
                "clip-path": "url(#telemetry-fuel-used-parts__gauge__clip)",
              },
            },
            [
              _c("rect", {
                staticClass: "telemetry-fuel-used-parts__gauge__meter",
                attrs: {
                  width: _vm.gaugeWidth,
                  height: "10",
                  x: "14",
                  y: "0",
                  transform: "skewX(-45)",
                  fill: "white",
                  opacity: "0.8",
                },
              }),
            ]
          ),
          _c("path", {
            attrs: {
              opacity: "0.3",
              d: "M1 13H124.858C127.51 13 130.054 11.9464 131.929 10.0711L142 0H144L132.636 11.364C130.948 13.0518 128.659 14 126.272 14H0L1 13Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              opacity: "0.2",
              d: "M137 0H15.6569C14.596 0 13.5786 0.421427 12.8284 1.17157L4 10H123.686C125.808 10 127.843 9.15715 129.343 7.65685L137 0Z",
              fill: "white",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "telemetry-fuel-used-parts__label" }, [
      _c("span", [_vm._v("Fuel")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }