var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-sns-post-menu-parts" }, [
    _c("div", { staticClass: "highlight-sns-post-menu-parts__inner" }, [
      _c("div", { staticClass: "highlight-sns-post-menu-parts__block" }, [
        _c("div", { staticClass: "highlight-sns-post-menu-parts__header" }, [
          _c("p", { staticClass: "highlight-sns-post-menu-parts__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc(
                    "RaceVideoPage.highlights.sns.xAndInstagram.titleOnlyX"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "button",
            {
              staticClass: "highlight-sns-post-menu-parts__button-close",
              on: {
                click: function ($event) {
                  return _vm.$emit("onClickClose")
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "highlight-sns-post-menu-parts__button-close-icon",
                },
                [_c("highlights-cancel-icon")],
                1
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "highlight-sns-post-menu-parts__body" }, [
          _c("p", { staticClass: "highlight-sns-post-menu-parts__message" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc(
                    "RaceVideoPage.highlights.sns.xAndInstagram.commonMessageOnlyX"
                  )
                ) +
                " "
            ),
          ]),
          !_vm.isComplete
            ? _c(
                "p",
                { staticClass: "highlight-sns-post-menu-parts__message" },
                [_vm._v(" " + _vm._s(_vm.downloadMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "highlight-sns-post-menu-parts__tool" },
            [
              _vm.isError
                ? _c(
                    "p",
                    {
                      staticClass: "highlight-sns-post-menu-parts__tool-error",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc(
                              "RaceVideoPage.highlights.sns.xAndInstagram.downloadError"
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.displayDownloadPreparationButton
                ? _c("atom-button", {
                    attrs: {
                      disabled: !_vm.canClipTargetOperationHighlightForPc,
                      theme: "dark",
                      linkText: _vm.$tc(
                        "RaceVideoPage.highlights.sns.xAndInstagram.download"
                      ),
                      colorType: "secondary",
                    },
                    on: { onClick: _vm.handleDownloadPreparationClicked },
                  })
                : _vm._e(),
              _vm.isInProgress
                ? _c(
                    "p",
                    { staticClass: "highlight-sns-post-menu-parts__download" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc(
                              "RaceVideoPage.highlights.sns.xAndInstagram.preparing"
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isComplete
                ? _c(
                    "div",
                    {
                      staticClass: "highlight-sns-post-menu-parts__downloaded",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "highlight-sns-post-menu-parts__downloaded-message",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "RaceVideoPage.highlights.sns.xAndInstagram.ready"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "highlight-sns-post-menu-parts__button-download",
                          on: { click: _vm.handleDownloadClicked },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "highlight-sns-post-menu-parts__button-download-label",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tc(
                                    "RaceVideoPage.highlights.sns.xAndInstagram.toDownload"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "highlight-sns-post-menu-parts__button-download-icon",
                            },
                            [_c("highlights-download-icon")],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("ul", { staticClass: "highlight-sns-post-menu-parts__list" }, [
            _c(
              "li",
              { staticClass: "highlight-sns-post-menu-parts__list-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "highlight-sns-post-menu-parts__button-sns",
                    class: {
                      "highlight-sns-post-menu-parts__button-sns--disabled":
                        !_vm.isComplete,
                    },
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.onClickAnchorLink(_vm.xUrl)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "highlight-sns-post-menu-parts__button-sns-label",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$tc(
                              "RaceVideoPage.highlights.sns.xAndInstagram.post.x"
                            )
                          )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "highlight-sns-post-menu-parts__button-sns-icon",
                      },
                      [_c("highlights-external-link-icon")],
                      1
                    ),
                  ]
                ),
              ]
            ),
            false
              ? _c(
                  "li",
                  { staticClass: "highlight-sns-post-menu-parts__list-item" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "highlight-sns-post-menu-parts__button-sns",
                        class: {
                          "highlight-sns-post-menu-parts__button-sns--disabled":
                            !_vm.isComplete,
                        },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickAnchorLink(_vm.instagramUrl)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "highlight-sns-post-menu-parts__button-sns-label",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "RaceVideoPage.highlights.sns.xAndInstagram.post.instagram"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "highlight-sns-post-menu-parts__button-sns-icon",
                          },
                          [_c("highlights-external-link-icon")],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }