






















import { defineComponent, toRefs } from '@vue/composition-api'
import useTelemetrySectionUtil from '@/components/RaceVideoPage/RaceVideoDataViewPane/hook/useTelemetrySectionUtil'

/**
 * レース動画再生画面 テレメトリー ステアリング操舵角 値セクションのコンポーネント
 */
export default defineComponent({
  name: 'TelemetrySteeringAngleParts',
  components: {},
  props: {
    /**
     * バリュー値
     */
    value: {
      type: Number,
      required: true,
    },
    /**
     * 表示fpsと受信データ間隔の差分ms
     */
    diffMs: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { value } = toRefs(props)
    const { setupIntermediateValue } = useTelemetrySectionUtil()
    const viewValue = setupIntermediateValue(value, props.diffMs)

    return {
      viewValue,
    }
  },
  methods: {},
})
