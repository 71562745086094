





















































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { AlertMessage } from '@/store/stores/collectionModule/documents/GeneralTypes'

export type DataType = {
  enableInformation: boolean
}

/**
 * レース動画再生画面 動画プレーヤー インフォメーションセクション
 */
export default defineComponent({
  name: 'VideoInformationSection',
  data(): DataType {
    return {
      enableInformation: false,
    }
  },
  props: {
    /**
     * インフォメーションメッセージ
     */
    informationMessage: {
      type: Object as PropType<AlertMessage>,
      // required: true,
      default: {
        playerId: '',
        message: {
          ja: '',
          en: '',
        },
        isShow: false,
      } as AlertMessage,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
  setup(props) {
    const screenLandScape = computed(() => props.screenOrientationType.indexOf('landscape') !== -1)
    return {
      screenLandScape,
    }
  },
})
