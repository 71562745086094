import { computed } from '@vue/composition-api'
import { sortBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractPlanDocument, {
  PlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { CouponContractPlanType } from '@/store/stores/collectionModule/documents/coupon/BaseCouponDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * 契約プランデータを操作するための処理を提供する。
 */
export default function useContractPlan() {
  // Collection modules
  const contractPlanCollectionModule = CollectionModule.createStore(ContractPlanDocument)

  /**
   * 契約プラン情報一覧を取得する。
   * @return APIレスポンス
   */
  const fetchContractPlans = () => contractPlanCollectionModule.fetch()

  /**
   * 契約プラン情報を取得する。
   * @return APIレスポンス
   */
  const fetchTargetContractPlan = (
    contractPlanId: string | null = null,
    isUnionExistData = true,
  ) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/data/master/contract_plan/${contractPlanId}`,
      isUnionExistData,
    }
    return contractPlanCollectionModule.fetch(options)
  }

  /**
   * クーポンプランを1件含む契約プラン情報一覧を取得する。
   * @return APIレスポンス
   */
  const fetchContractPlansWithCouponPlan = async (
    contractPlanId: string,
    couponPlanGroupId: string,
  ) => {
    const contractPlans = await contractPlanCollectionModule.fetch()

    if (
      couponPlanGroupId === 'limitedTimePaidPlan' ||
      couponPlanGroupId === 'limitedTimePaidPlanForAnnual'
    ) {
      // クーポンプランを取得
      await fetchTargetContractPlan(contractPlanId)
    }

    return contractPlans
  }

  /**
   * 取得した契約プラン情報一覧
   */
  const contractPlans = computed(() => contractPlanCollectionModule.data)

  /**
   * 対象の契約プラン情報
   */
  const getTargetContractPlan = (contractPlanId: string) =>
    contractPlanCollectionModule.data.find((plan) => plan.contractPlanId === contractPlanId)

  /**
   * 取得した契約プランから抽出した有料プランの一覧
   * 年払いプランを先に表示する
   */
  const contractPaidPlans = computed(() =>
    sortBy(
      contractPlans.value.filter(
        (contractPlan) =>
          contractPlan.planGroupId === 'monthlyPlan' || contractPlan.planGroupId === 'annualPlan',
      ),
      'planGroupId',
    ),
  )

  /**
   * 取得した契約プランから抽出した無料プラン（無料プランは1件のみのためfindしている）
   */
  const contractFreePlan = computed(() =>
    contractPlans.value.find((contractPlan) => contractPlan.planGroupId === 'freePlan'),
  )

  /**
   * 月額プランの金額
   */
  const monthlyPlanPriceJpy = computed(
    () =>
      contractPaidPlans.value
        .filter((contractPlan) => contractPlan.planGroupId === 'monthlyPlan')
        .map((contractPlan) => contractPlan.price?.JPY || 0)[0],
  )
  /**
   * 年額プランの金額
   */
  const annualPlanPriceJpy = computed(
    () =>
      contractPaidPlans.value
        .filter((contractPlan) => contractPlan.planGroupId === 'annualPlan')
        .map((contractPlan) => contractPlan.price?.JPY || 0)[0],
  )

  // methods

  /**
   * クーポンのプラン情報を取得
   */
  const getCouponPlan = (plans: Array<CouponContractPlanType>) =>
    plans
      .find(
        (plan) =>
          plan.planGroupId === 'limitedTimePaidPlan' ||
          plan.planGroupId === 'limitedTimePaidPlanForAnnual',
      )
      ?.masterContractPlans?.find(
        (plan) =>
          plan.planGroupId === 'limitedTimePaidPlan' ||
          plan.planGroupId === 'limitedTimePaidPlanForAnnual',
      )

  /**
   * クーポンのプラン情報を取得
   * CouponDocumentの型でデータを返却
   */
  const getCouponPlanWithCouponDocument = (plans: Array<CouponContractPlanType>) =>
    plans.find(
      (plan) =>
        plan.planGroupId === 'limitedTimePaidPlan' ||
        plan.planGroupId === 'limitedTimePaidPlanForAnnual',
    )

  /**
   * 特定の契約プラングループID
   */
  const selectedContractPlanIds = (planGroupId: PlanGroupType) =>
    contractPlans.value
      .filter((contractPlan) => contractPlan.planGroupId === planGroupId)
      .map((contractPlan) => contractPlan.contractPlanId)

  /**
   * トライアルプラングループID
   */
  const contractPlanIdForTrial = (planGroupId: PlanGroupType) =>
    contractPlans.value.find((contractPlan) => {
      if (planGroupId === 'monthlyPlan') {
        // 月払い向けのトライアルプランを返す
        return contractPlan.planGroupId === 'trialPlan'
      }
      if (planGroupId === 'annualPlan') {
        // 年払い向けのトライアルプランを返す
        return contractPlan.planGroupId === 'trialPlanForAnnual'
      }
      return false
    })?.contractPlanId || ''

  /**
   * 取得した契約プラン情報をクリアする
   */
  const clearContractPlans = () => {
    contractPlanCollectionModule.clearData()
  }

  return {
    fetchContractPlans,
    fetchTargetContractPlan,
    fetchContractPlansWithCouponPlan,
    contractPlans,
    getTargetContractPlan,
    getCouponPlan,
    getCouponPlanWithCouponDocument,
    selectedContractPlanIds,
    contractPlanIdForTrial,
    contractPaidPlans,
    contractFreePlan,
    monthlyPlanPriceJpy,
    annualPlanPriceJpy,
    clearContractPlans,
  }
}
