var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credit-card-input-section" },
    [
      _c("h6", { staticClass: "credit-card-input-section__title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.sectionTitle
                ? _vm.sectionTitle
                : _vm.$tc("CreditCardPayment.sectionTitle")
            ) +
            " "
        ),
      ]),
      _c("fieldset-text-credit-parts", {
        ref: "fieldsetTextNumberPartsNumber",
        staticClass:
          "credit-card-input-section__number credit-card-input-section__input",
        attrs: {
          id: "credit-number",
          refElement: "numberElement",
          label: _vm.$tc("CreditCardPayment.inputLabel.number"),
          showError: !!_vm.stripe.state.errors.cardNumber,
          errorMessage: _vm.creditErrorMessage(
            _vm.stripe.state.errors.cardNumber
          ),
        },
      }),
      _c("ValidationProvider", {
        ref: "validationProvider",
        attrs: { slim: "", name: "cardName", rules: "required" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("fieldset-text-parts", {
                  staticClass:
                    "credit-card-input-section__name credit-card-input-section__input",
                  attrs: {
                    id: "credit-name",
                    label: _vm.$tc("CreditCardPayment.inputLabel.name"),
                    value: _vm.stripe.state.cardName,
                    showError: errors.length > 0,
                    errorMessage: errors[0],
                    placeholder: _vm.$tc("CreditCardPayment.placeholder.name"),
                  },
                  on: { input: _vm.inputCardName },
                }),
              ]
            },
          },
        ]),
      }),
      _c("fieldset-text-credit-parts", {
        ref: "fieldsetTextNumberPartsExpiry",
        staticClass:
          "credit-card-input-section__period credit-card-input-section__input",
        attrs: {
          id: "credit-period",
          refElement: "expiryElement",
          label: _vm.$tc("CreditCardPayment.inputLabel.period"),
          showError: !!_vm.stripe.state.errors.cardExpiry,
          errorMessage: _vm.creditErrorMessage(
            _vm.stripe.state.errors.cardExpiry
          ),
        },
      }),
      _c("fieldset-text-credit-parts", {
        ref: "fieldsetTextNumberPartsCvc",
        staticClass:
          "credit-card-input-section__cvc credit-card-input-section__input",
        attrs: {
          id: "credit-cvc",
          refElement: "cvcElement",
          label: _vm.$tc("CreditCardPayment.inputLabel.cvc"),
          showError: !!_vm.stripe.state.errors.cardCvc,
          errorMessage: _vm.creditErrorMessage(_vm.stripe.state.errors.cardCvc),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }