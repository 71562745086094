













import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageTicketTodayPane from '@/components/MypageTicketTodayPage/MypageTicketTodayPane.vue'

export default defineComponent({
  name: 'MypageTicketTodayPage',
  components: { MypageTicketTodayPane, GlobalNavigationPane, SubHeaderSection },
})
