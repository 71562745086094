import { reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'

type ProgressIdType = 'highlightExport' | 'postX' | 'postInstagram'

/**
 * 共通ローディングオーバーレイの進捗表示用の型
 */
export type ProgressMessageType = {
  progressId: ProgressIdType
  message: string
  progress: number
}

const initialState = {
  progressList: [] as Array<ProgressMessageType>,
  cautionMessage: '',
}

/* eslint-disable class-methods-use-this */
class LoadingOverlayProgressStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    /**
     * 進捗状況リストを設定する
     */
    const setProgressList = (progressList: Array<ProgressMessageType>) => {
      state.progressList = []
      state.progressList.push(...progressList)
    }

    /**
     * 表示する注意メッセージを設定する
     */
    const setCautionMessage = (cautionMessage: string) => {
      state.cautionMessage = cautionMessage
    }

    /**
     * ローディングオーバーレイ進捗状況を更新する処理
     */
    const updateProgressList = (progressId: ProgressIdType, newProgress: number) => {
      const targetProgress = state.progressList.find(
        (progress) => progress.progressId === progressId,
      )
      if (targetProgress) targetProgress.progress = newProgress
    }

    const clearLoadingOverLayData = () => {
      Object.assign(state, initialState)
    }

    return {
      state,
      setProgressList,
      setCautionMessage,
      updateProgressList,
      clearLoadingOverLayData,
    }
  }
}

const value: ValueType<LoadingOverlayProgressStore> = {}

export default {
  createStore: new LoadingOverlayProgressStore().createStore,
  value: value as Required<typeof value>,
}
