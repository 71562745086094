




































import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: LINK リンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationLinkIconParts',
})
