






import { defineComponent } from '@vue/composition-api'
import StandingsTopPane from '@/components/StandingsPage/StandingsTopPane.vue'

/**
 * Standings: トップページ
 */
export default defineComponent({
  name: 'StandingsTopPage',
  components: {
    StandingsTopPane,
  },
})
