

















































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import ComparisonPlanSection from '@/components/SignupPage/common/ComparisonPlanSection.vue'
import ChooseFreePlanSection from '@/components/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanEditPane/ChooseFreePlanSection.vue'
import UnusableFeatureSection from '@/components/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanEditPane/UnusableFeatureSection.vue'
import StoreUtil from '@/store/StoreUtil'
import useContractTerm from '@/components/hook/useContractTerm'
import ContractPlanDocument, {
  FreePlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

/**
 * 有料会員解約 ペイン
 */
export default defineComponent({
  name: 'CancelPaidPlanEditPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    ChooseFreePlanSection,
    UnusableFeatureSection,
    CurrentPlanCardSection,
    ComparisonPlanSection,
  },
  props: {
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<FreePlanGroupType>,
      required: true,
    },
  },
  setup(props, context) {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { effectiveContractPlan, effectiveOrgContract, contractPlans } = contractInfoStore
    const { contractFreePlan, futureContactInfoPlan, getTargetContractPlan } = mypagePageStore
    const { getCurrentContractTerm, getScheduledContractTerm } = useContractTerm()

    /**
     * 確認ボタン非活性化チェック
     */
    const disabledConfirmBtn = computed(() => props.selectedPlanGroupId === '')

    /**
     * プラン選択
     * @param planGroupId {FreePlanGroupType}
     */
    const handlerPlanSelect = (planGroupId: FreePlanGroupType) => {
      context.emit('handlerPlanSelect', planGroupId)
    }

    /**
     * 現在契約中のプラン
     */
    const currentPlan = computed(() =>
      contractPlans.value.find((plan) => plan.planGroupId === effectiveContractPlan?.planGroupId),
    )

    /**
     * 将来のプラン
     */
    const futurePlan = computed(() =>
      futureContactInfoPlan?.value?.contractPlanId
        ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
        : null,
    )

    /** 以下いずれかの場合は解約ができる
     * 1. 将来のプランがない
     * 2. 現在のプランがトライアルプランでかつ将来のプランが無料プラン以外
     * 3. 現在のプランがトライアルプラン（年払い向け）でかつ将来のプランが無料プラン以外
     * 4. 現在のプランが月払いプラン（無料クーポン利用）でかつ将来のプランが無料プラン以外
     * 5. 現在のプランが年払いプラン（無料クーポン利用）でかつ将来のプランが無料プラン以外
     */
    const canCancelPaidPlan = computed(() => {
      const canCancel =
        !futurePlan.value ||
        (currentPlan.value?.planGroupId === 'trialPlan' &&
          futurePlan.value?.planGroupId !== 'freePlan') ||
        (currentPlan.value?.planGroupId === 'trialPlanForAnnual' &&
          futurePlan.value?.planGroupId !== 'freePlan') ||
        (currentPlan.value?.planGroupId === 'limitedTimePaidPlan' &&
          futurePlan.value?.planGroupId !== 'freePlan') ||
        (currentPlan.value?.planGroupId === 'limitedTimePaidPlanForAnnual' &&
          futurePlan.value?.planGroupId !== 'freePlan')

      if (!canCancel) {
        // キャンセルできない場合はプランの選択状態を解除
        handlerPlanSelect('')
      } else {
        handlerPlanSelect('freePlan')
      }
      return canCancel
    })

    /**
     * 契約中のプランの契約期間
     */
    const contractTerm = computed(() =>
      getCurrentContractTerm(
        effectiveContractPlan as ContractPlanDocument,
        effectiveOrgContract as ContractType,
      ),
    )

    /**
     * 契約予定のプランの契約期間
     */
    const scheduledContractTerm = computed(() =>
      futurePlan.value?.planGroupId
        ? getScheduledContractTerm(
            effectiveContractPlan as ContractPlanDocument,
            effectiveOrgContract as ContractType,
            futurePlan.value.planGroupId,
          )
        : null,
    )

    return {
      disabledConfirmBtn,
      contractFreePlan: contractFreePlan as Ref<ContractPlanDocument>,
      currentPlan,
      futurePlan,
      contractTerm,
      scheduledContractTerm,
      canCancelPaidPlan,
    }
  },
  methods: {
    /**
     * サブミット処理
     */
    handlerSubmit() {
      this.$emit('handlerSubmit')
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
