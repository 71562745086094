import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import {
  SeasonRoundCheckInMissionCodeType,
  ROUND_CHECK_IN_MISSION_CODE_LIST,
  CIRCUIT_CHECK_IN_MISSION_CODE_LIST,
  CircuitCheckInMissionCodeType,
  RegisteredProfileMissionCodeType,
  RegisteredFavoriteMissionCodeType,
  EventCheckInMissionCodeType,
} from '@/util/Const'

/**
 * ミッションコードの型
 */
export type MissionCodeType =
  | 'CONSECUTIVE_LOGINS'
  | 'CUMULATIVE_LOGINS'
  | 'WATCHED_HIGHTLIGHT'
  | 'WATCHED_FAVORITE_PLAYER_RADIO'
  | 'POSTED_ON_SNS'
  | 'INVITED_USING_REFERRAL_CODE'
  | 'ANNUAL_PAID_MEMBER' // バッチで処理してるためミッションマスタには存在しない
  | 'MONTHLY_PAID_MEMBER' // バッチで処理してるためミッションマスタには存在しない
  | 'WATCHED_LIVE_VIDEO'
  | EventCheckInMissionCodeType
  | CircuitCheckInMissionCodeType
  | RegisteredProfileMissionCodeType
  | RegisteredFavoriteMissionCodeType
  | SeasonRoundCheckInMissionCodeType

/**
 * 達成方法の型
 */
export type AchievementMethodType = {
  /**
   * タイトル
   */
  title?: Multilingual | null
  /**
   * 本文
   */
  text?: Multilingual | null
}

/**
 * 達成条件の型
 */
export type AchievementConditionType = {
  /**
   * 操作回数
   */
  operationCount: number | null
  /**
   * 付与ポイント
   */
  point: number | null
  /**
   * ポイントコード
   */
  pointCode: string | null
  /**
   * ミッションを達成しているか
   */
  isAchieved: boolean | null
  /**
   * ハニカムの所に表示するミッション名
   * フロントで設定する
   */
  missionNameOnCheckList?: string | null
}

/**
 * 期間種別の型
 */
export type CountConditionTermType = 'DAY' | 'MISSION_TERM'

/**
 * 操作計上条件の型
 */
export type CountConditionType = {
  /**
   * 期間種別
   */
  termType: CountConditionTermType | null
  /**
   * 上限数
   */
  upperLimit: number | null
  /**
   * タイムゾーン
   */
  timeZone?: string | null
}

/**
 * ミッション達成状況取得APIを操作するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3683975257/API
 */
export default class MissionProgressDocument extends DocumentWrapper {
  constructor(initProps?: Partial<MissionProgressDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'mission/progress/user'

  // ミッションマスタ
  /**
   * ミッションID
   */
  missionId: string | null = null

  /**
   * ミッションコード
   */
  missionCode?: MissionCodeType | null = null

  /**
   * ミッション名
   */
  missionName: Multilingual | null = null

  /**
   * 説明
   */
  description?: Multilingual | null = null

  /**
   * 達成方法
   */
  achievementMethod?: AchievementMethodType | null = null

  /**
   * ミッション達成にする対象の操作
   */
  operation?: Array<string> | null = null

  /**
   * 達成条件
   */
  achievementCondition: Array<AchievementConditionType> | null = null

  /**
   * 操作計上条件
   */
  countCondition: CountConditionType | null = null

  // ミッションマスタ以外
  /**
   * 総合操作回数
   */
  totalOperationCount: number | null = null

  /**
   * 最大連続操作回数
   */
  maxContinuousOperationCount: number | null = null

  /**
   * 連続操作回数
   */
  continuousOperationCount: number | null = null

  /**
   * 獲得済みポイントの合計値
   */
  pointEarned: number | null = null

  /**
   * ミッション開始日時
   */
  startDate?: number | null = null

  /**
   * ミッション終了日時
   */
  endDate?: number | null = null

  /**
   * 獲得回数・次の獲得までの操作回数を表示しないミッションかを判断する
   */
  get hiddenAchievedMissionsCount() {
    return (
      this.missionCode === 'INVITED_USING_REFERRAL_CODE' ||
      (CIRCUIT_CHECK_IN_MISSION_CODE_LIST as Array<string>).includes(this.missionCode || '') ||
      (ROUND_CHECK_IN_MISSION_CODE_LIST as Array<string>).includes(this.missionCode || '')
    )
  }
}
