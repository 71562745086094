var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "one-time-pass-complete-pane" }, [
    _c("h3", { staticClass: "one-time-pass-complete-pane__title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$tc("MypagePage.MypageOneTimePass.completePage.title")) +
          " "
      ),
    ]),
    _c("p", {
      staticClass: "one-time-pass-complete-pane__message",
      domProps: {
        innerHTML: _vm._s(
          _vm.$tc("MypagePage.MypageOneTimePass.completePage.message")
        ),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }