var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-video-radio-pane",
      class: "race-video-radio-pane--" + _vm.screenOrientationType,
    },
    [
      _c("radio-header-section", {
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          lockOrientation: _vm.lockOrientation,
        },
        on: {
          changeDriverFilter: _vm.changeDriverFilter,
          changeLapFilter: _vm.changeLapFilter,
        },
      }),
      _c("radio-list-section", {
        staticClass: "race-video-radio-pane__list",
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          raceRadioData: _vm.filteredRadioDataSet
            ? _vm.filteredRadioDataSet
            : [],
        },
        on: { radioPlay: _vm.playRadioAudio },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }