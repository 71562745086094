import { computed, reactive } from '@vue/composition-api'
import { orderBy } from 'lodash'
import dayjs from 'dayjs'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useDigitalTicketData from '@/store/hook/digitalTicket/useDigitalTicketData'
import useAvailableArea from '@/store/hook/useAvailableArea'
import { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'
import I18n from '@/locales/I18n'
import DigitalTicketDataDocument, {
  OpeningDatesType,
} from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'

export type PastTicketInfoType = {
  // チケットイベントのuniqueKey
  ticketEventUniqueKey: string
  // チケットイベントの開催日目
  day: OpeningDatesType
}

/**
 * 初期状態
 */
const initialState = {
  // 選択中の年度
  selectedYear: dayjs().year(),
  // チケットページに必要なデータをfetchしたかどうか
  isFetchedTicketPageData: false,
  // 選択中の年リスト
  selectedYearFilterOption: [] as Array<DropDownDataType>,
  // 選択中の場所リスト
  selectedPlaceFilterOption: [] as Array<DropDownDataType>,
  // 詳細を開いている本日利用できる券種の券種ID
  showingDetailTodayTicketTypeId: '',
  // 選択中の過去のチケット情報
  selectedPastTicketInfo: undefined as PastTicketInfoType | undefined,
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoのマイページ > チケット画面のStore
 */
class MypageTicketPageStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    // hook
    const {
      fetchDigitalTickets,
      digitalTickets,
      digitalTicketsByYear,
      digitalTicketsByUniqueKey,
      fetchDigitalTicketThumbnailImages,
      digitalTicketImagesByUserImageId,
      clearDigitalTickets,
    } = useDigitalTicketData()
    const { fetchCircuitList, areas, getTargetAvailableArea, clearAvailableAreas } =
      useAvailableArea()

    /**
     * 選択中の年
     */
    const selectedYear = computed({
      get: () => state.selectedYear,
      set: (year: number) => {
        state.selectedYear = year
      },
    })

    /**
     * チケットページに必要なデータをfetchしたかどうか
     */
    const isFetchedTicketPageData = computed({
      get: () => state.isFetchedTicketPageData,
      set: (isFetched: boolean) => {
        state.isFetchedTicketPageData = isFetched
      },
    })

    /**
     * 選択中の年リスト
     */
    const selectedYearFilterOption = computed({
      get: () => state.selectedYearFilterOption,
      set: (selectedYears: Array<DropDownDataType>) => {
        state.selectedYearFilterOption = selectedYears
      },
    })

    /**
     * 選択中の場所リスト
     */
    const selectedPlaceFilterOption = computed({
      get: () => state.selectedPlaceFilterOption,
      set: (selectedPlaces: Array<DropDownDataType>) => {
        state.selectedPlaceFilterOption = selectedPlaces
      },
    })

    /**
     * 詳細を開いている本日利用できる券種
     */
    const showingDetailTodayTicketTypeId = computed({
      get: () => state.showingDetailTodayTicketTypeId,
      set: (openingTicketTypeId: string) => {
        state.showingDetailTodayTicketTypeId = openingTicketTypeId
      },
    })

    /**
     * 選択中の過去のチケット情報
     */
    const selectedPastTicketInfo = computed({
      get: () => state.selectedPastTicketInfo,
      set: (pastTicketInfo: PastTicketInfoType | undefined) => {
        state.selectedPastTicketInfo = pastTicketInfo
      },
    })

    /**
     * 選択した場所にマッチしているかどうか
     * 場所でフィルタしていない場合はtrueを返す
     */
    const isMatchPlaces = (event: DigitalTicketDataDocument) =>
      selectedPlaceFilterOption.value.length > 0
        ? selectedPlaceFilterOption.value.some((place) => {
            if (event.stadiumId) {
              const areaName =
                getTargetAvailableArea(event.stadiumId)?.areaName?.[I18n.locale] ?? ''
              return place.name === areaName
            }
            return place.name === event.placeName
          })
        : true

    /**
     * 一覧表示するデジタルチケットデータ
     * 開催日順にソート
     * 購入したチケットに紐づくイベント、券種のフィルタは表示ファイル側で実施
     */
    const filteredDigitalTickets = computed(() =>
      orderBy(digitalTicketsByYear.value?.[selectedYear.value], 'openingDateDay1', 'desc').filter(
        (event) => {
          if (!isMatchPlaces(event)) {
            return false
          }

          // 上記以外はtrueを返す
          return true
        },
      ),
    )

    /**
     * チケット画面に必要な情報を取得する。
     */
    const fetchTicketPageData = async () => {
      isFetchedTicketPageData.value = false
      const result = await Promise.all([
        fetchDigitalTickets(),
        fetchCircuitList(),
        fetchDigitalTicketThumbnailImages(),
      ])
      isFetchedTicketPageData.value = true
      return result
    }

    /**
     * チケット画面のデータをクリアする
     */
    const clearTicketPageData = () => {
      clearDigitalTickets()
      clearAvailableAreas()
      Object.assign(state, initialState)
      selectedYearFilterOption.value = []
      selectedPlaceFilterOption.value = []
    }

    return {
      // useDigitalTicketData
      digitalTickets,
      digitalTicketsByYear,
      digitalTicketsByUniqueKey,
      digitalTicketImagesByUserImageId,
      // useAvailableArea
      areas,
      getTargetAvailableArea,
      // MypageTicketPageStore
      selectedYear,
      isFetchedTicketPageData,
      selectedYearFilterOption,
      selectedPlaceFilterOption,
      showingDetailTodayTicketTypeId,
      selectedPastTicketInfo,
      filteredDigitalTickets,
      fetchTicketPageData,
      clearTicketPageData,
    }
  }
}

const value: ValueType<MypageTicketPageStore> = {}

export default {
  createStore: new MypageTicketPageStore().createStore,
  value: value as Required<typeof value>,
}
