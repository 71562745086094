var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "atom-link-btn",
      class: [
        "atom-link-btn--" + _vm.theme + "--" + _vm.colorType,
        "atom-link-btn--" + _vm.size,
      ],
      attrs: { href: _vm.url, target: _vm.target, rel: "noopener noreferrer" },
    },
    [_c("span", [_vm._v(_vm._s(_vm.linkText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }