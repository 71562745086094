/* eslint-disable import/prefer-default-export */
import i18n from '@/locales/I18n'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getDeclineDescription } = require('stripe-utils')

/**
 * getDeclineInfoの返り値
 * 各内容に関しては以下のURLを参照
 * https://github.com/megumiteam/stripe-utils/blob/master/libs/charges/declineCode.js
 */
export type StripeCode = {
  /**
   * エラーの説明
   */
  description: string
  /**
   * 次の処理。日本語訳されていない
   */
  nextSteps: string
  /**
   * 次のユーザーのアクション
   * 日本語訳されている場合が多い
   */
  nextUserAction: string
}

/**
 * stripeのDeclineCodeから表示すべきメッセージを取得する
 * @param declineCode 対象の拒否コード
 * 詳しくは https://stripe.com/docs/declines/codes を参照
 */
export const getDeclineInfo = (declineCode: string): StripeCode | null => {
  const { code } = getDeclineDescription(declineCode)
  if (!code || !code.translations) {
    return null
  }
  const { locale } = i18n
  if (locale === 'ja') {
    return {
      ...code.translations.ja_JP,
      nextSteps: code.nextSteps,
    }
  }
  return code
}
