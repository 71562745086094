






import { computed, defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * PCページ 共通調整用Wrapper
 */
export default defineComponent({
  name: 'PageWrapper',
  setup() {
    const hasHeaderNav = computed(
      () =>
        !(DeviceInfo.isMobile() && (DeviceInfo.isAndroidBrowser() || DeviceInfo.isiOSBrowser())),
    )
    return {
      hasHeaderNav,
    }
  },
})
