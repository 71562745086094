var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "membership-card-view-mode-section" },
    [
      _c("switch-edit-mode-button-parts", {
        staticClass: "membership-card-view-mode-section__edit-button",
        attrs: { type: "edit" },
        on: { click: _vm.emitEdit },
      }),
      _c(
        "div",
        { staticClass: "membership-card-view-mode-section__user-data" },
        [
          _c(
            "figure",
            { staticClass: "membership-card-view-mode-section__image" },
            [
              _c("img", {
                attrs: {
                  src: _vm.userThumbnail
                    ? _vm.userThumbnail
                    : require("@/assets/img/icon/icon_user_default.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c("p", {
            staticClass: "membership-card-view-mode-section__name",
            domProps: { textContent: _vm._s(_vm.userName) },
          }),
        ]
      ),
      _c(
        "figure",
        { staticClass: "membership-card-view-mode-section__members-rank" },
        [
          _vm.isFreePlan
            ? _c("img", {
                staticClass:
                  "membership-card-view-mode-section__members-rank-badge membership-card-view-mode-section__members-rank-badge--free",
                attrs: {
                  src: require("@/assets/img/MyPage/rank-free.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass:
                  "membership-card-view-mode-section__members-rank-badge",
                attrs: {
                  src: require("@/assets/img/MyPage/rank-" +
                    _vm.membersRankData.currentRank +
                    ".png"),
                  alt: "",
                },
              }),
        ]
      ),
      _vm.userData.badgeList.length > 0
        ? _c("member-badge-list-parts", {
            staticClass: "membership-card-view-mode-section__badge-list",
            attrs: {
              badgeList: _vm.userData.badgeList,
              enabledListCarousel: _vm.enabledListCarousel,
            },
          })
        : _vm._e(),
      _c("current-time-parts", {
        staticClass: "membership-card-view-mode-section__current-time",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }