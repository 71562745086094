var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "circuit-map-car-parts",
      class: [
        { "is-selected": _vm.selectedPlayer },
        { "is-disabled": _vm.carDisabled },
        { "is-mounted": _vm.carMounted.mount },
        { "is-animation": _vm.enableAnimation },
      ],
      style: _vm.carPosition,
      attrs: {
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "gps-map-ots-shadow",
                x: "0",
                y: "0",
                width: "40",
                height: "40",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "4",
                  result: "gps-map-ots-shadow__grad",
                },
              }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "gps-map-ots-shadow__grad",
                x1: "9.84615",
                y1: "2",
                x2: "51.4866",
                y2: "26.0233",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { "stop-color": "#00FFC1", "stop-opacity": "0.4" },
              }),
              _c("stop", {
                attrs: { offset: "0.526042", "stop-color": "#00FFC1" },
              }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#00FFC1",
                  "stop-opacity": "0.4",
                },
              }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "gps-map-ots__grad",
                x1: "0.23009",
                y1: "197.4",
                x2: "40.9779",
                y2: "194.55",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#00FFBA" } }),
              _c("stop", {
                attrs: {
                  offset: "0.191981",
                  "stop-color": "#00FFBA",
                  "stop-opacity": "0.5",
                },
              }),
              _c("stop", {
                attrs: { offset: "0.40625", "stop-color": "#00FFBA" },
              }),
              _c("stop", {
                attrs: {
                  offset: "0.619792",
                  "stop-color": "#00FFBA",
                  "stop-opacity": "0.5",
                },
              }),
              _c("stop", {
                attrs: { offset: "0.8125", "stop-color": "#00FFBA" },
              }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#00FFBA",
                  "stop-opacity": "0.5",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectedPlayer
        ? _c("g", { attrs: { filter: "url(#gps-map-ots-shadow)" } }, [
            _c("circle", {
              attrs: {
                cx: "20",
                cy: "20",
                r: "12",
                fill: "url(#gps-map-ots-shadow__grad)",
              },
            }),
          ])
        : _vm._e(),
      _vm.selectedPlayer
        ? _c("circle", {
            staticClass: "circuit-map-car-parts__selected",
            attrs: { cx: "20", cy: "20", r: "11", fill: "#00DBA6" },
          })
        : _vm._e(),
      _c("circle", {
        staticClass: "circuit-map-car-parts__base",
        class: [
          { "is-pitin": _vm.pitInWorking },
          { "is-ots": _vm.otsEnabled && _vm.value > 0 },
        ],
        attrs: { cx: "20", cy: "20", r: "7", fill: "white" },
      }),
      _vm.otsEnabled && _vm.value > 0
        ? _c("circle", {
            staticClass: "circuit-map-car-parts__ots-grad",
            attrs: {
              r: "7",
              cx: "20",
              cy: "20",
              fill: "url(#gps-map-ots__grad)",
            },
          })
        : _vm._e(),
      _vm.selectedPlayer
        ? _c("circle", {
            staticClass: "circuit-map-car-parts__selected-border",
            attrs: {
              cx: "20",
              cy: "20",
              r: "7.5",
              "stroke-width": "1",
              stroke: "#fff",
            },
          })
        : _vm._e(),
      _c(
        "text",
        {
          staticClass: "circuit-map-car-parts__num",
          attrs: {
            x: "50%",
            y: "50%",
            "text-anchor": "middle",
            "dominant-baseline": "central",
            fill: "black",
          },
        },
        [_vm._v(" " + _vm._s(_vm.number) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }