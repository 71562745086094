var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-gender-parts" },
    [
      _c("label", { attrs: { for: "fieldset-gender" } }, [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.gender"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("atom-input-select-object", {
        staticClass: "login-inputs-parts__gender-field",
        attrs: {
          id: "fieldset-gender",
          placeholder: _vm.$tc("formParts.placeholder.gender"),
          error: _vm.showError,
          options: _vm.genderData,
        },
        on: { enter: _vm.emitEnter, change: _vm.change },
        model: {
          value: _vm.gender,
          callback: function ($$v) {
            _vm.gender = $$v
          },
          expression: "gender",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showError,
          message:
            _vm.errorMessage !== ""
              ? _vm.errorMessage
              : _vm.$tc("formParts.errorMessage.gender"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }