












import { defineComponent } from '@vue/composition-api'

export type DataType = {
  title: string
  message: string
}

/**
 * パスワードリセット完了 セクション
 */
export default defineComponent({
  name: 'PasswordResetCompleteSection',
  data(): DataType {
    return {
      /**
       * タイトル
       */
      title: '',
      /**
       * メッセージ
       */
      message: '',
    }
  },
})
