








import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import PasswordResetMailInputPane from '@/components/PasswordResetPage/PasswordResetMailInputPane.vue'

/**
 * パスワードリセット ページ
 */
export default defineComponent({
  name: 'PasswordResetMailInputPage',
  components: {
    SubHeaderSection,
    PasswordResetMailInputPane,
  },
  methods: {
    moveToSend() {
      /**
       * 送信完了画面へ遷移
       * @event success-regist
       */
      this.$router.replace({ path: '/password-reset/mail-sent' })
    },
  },
})
