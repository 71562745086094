

































import { defineComponent, computed } from '@vue/composition-api'
import Const from '@/util/Const'

export default defineComponent({
  name: 'CommentCountParts',
  props: {
    comment: {
      type: String,
      default: '',
    },
    commentLength: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const remainingCommentCount = computed(
      () => Const.MAX_HIGHLIGHT_COMMENT_LENGTH - props.commentLength,
    )
    const commentRatio = computed(() => {
      if (props.commentLength === 0) {
        return 0
      }
      return props.commentLength / Const.MAX_HIGHLIGHT_COMMENT_LENGTH
    })

    const commentCountStatus = computed(() => {
      if (remainingCommentCount.value <= -10) {
        // 残り文字数が-10以上になったら円表示を非表示にする
        return 'excess'
      }
      if (remainingCommentCount.value <= 0) {
        // 残りの文字数がなくなったら赤く表示する
        return 'over'
      }
      if (remainingCommentCount.value <= 20) {
        // 残り20文字以下は黄色の警告表示する
        return 'alert'
      }
      return 'normal'
    })

    const circleRadius = computed(() => {
      if (commentCountStatus.value === 'normal') {
        return 9
      }
      return 11
    })

    const indicatorBarStyle = computed(() => {
      if (remainingCommentCount.value <= 0) {
        return {}
      }
      // 円周
      const circumference = circleRadius.value * 2 * 3.14
      return {
        'stroke-dasharray': `${circumference}px ${circumference}px`,
        'stroke-dashoffset': `${(1 - commentRatio.value) * circumference}`,
      }
    })

    return {
      commentCountStatus,
      remainingCommentCount,
      circleRadius,
      indicatorBarStyle,
    }
  },
})
