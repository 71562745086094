var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-favorite-player-select-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc(
            "MypagePage.MypageFavoritePlayerSelectPage.pageTitle"
          ),
          backLink: "/mypage",
        },
      }),
      _c("mypage-favorite-player-select-pane", {
        ref: "favoritePlayerPane",
        staticClass: "mypage-favorite-player-select-page__content",
        on: { "success-regist": _vm.moveToMypageTop },
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector:
              ".mypage-favorite-player-select-page__content",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: { linkText: _vm.$tc("common.set"), isDisabled: false },
            on: { push: _vm.setFavoritePlayer },
          }),
        ],
        1
      ),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }