var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "award-ranking-section" },
    [
      _c("award-ranking-header-section", {
        attrs: { title: _vm.title, description: _vm.description },
      }),
      _c("award-ranking-sponsor-parts", {
        key: _vm.sponsorPartsRenderKey,
        attrs: { targetYear: _vm.targetYear, category: _vm.category },
      }),
      _vm.rankings && _vm.rankings.length > 0
        ? [
            _vm._l(_vm.partitionedRankingData.winner, function (winner, idx) {
              return _c("award-winner-section", {
                key: "award-winner" + idx,
                attrs: {
                  category: _vm.category,
                  badgeImage: _vm.badgeImage,
                  winnerData: winner,
                },
              })
            }),
            _vm.partitionedRankingData.rankingOtherFirst.length > 0
              ? _c("award-ranking-list-section", {
                  attrs: {
                    category: _vm.category,
                    rankingDataOtherFirst:
                      _vm.partitionedRankingData.rankingOtherFirst,
                  },
                })
              : _vm._e(),
          ]
        : [
            _c("p", {
              staticClass: "award-ranking-section__no-data",
              domProps: {
                innerHTML: _vm._s(_vm.$tc("LinkPage.AwardPage.noData")),
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }