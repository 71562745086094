
































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  PropType,
  ref,
  Ref,
  ComputedRef,
  WritableComputedRef,
  watch,
  inject,
  toRefs,
  onBeforeUnmount,
} from '@vue/composition-api'
import { now, difference, cloneDeep } from 'lodash'
import { PluginApi } from 'vue-loading-overlay'
import VueRouter from 'vue-router'
import dayjs from 'dayjs'
import HighlightHeaderSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightHeaderSection.vue'
import HighlightContentsSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightContentsSection.vue'
import MessageModalSection from '@/components/common/modal/MessageModalSection.vue'
import ConfirmModalSection from '@/components/common/modal/ConfirmModalSection.vue'
import NewHighlightsSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/NewHighlightsSection.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import PlayEventDocument from '@/store/stores/collectionModule/documents/highlight/PlayEventDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import LocalCache from '@/util/localcache/LocalCache'
import Logger from '@/util/logger/Logger'
import useRealtimeMessaging, {
  CommunicationCommentMessageType,
  RTMCallbackParamType,
} from '@/components/hook/useRealtimeMessaging'
import useHighlight from '@/components/RaceVideoPage/hook/useHighlight'
import useCommunication, {
  CommentDataType,
  ParentCommentDataType,
} from '@/components/RaceVideoPage/hook/useCommunication'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'
import i18n from '@/locales/I18n'
import NoticeHighlightSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/NoticeHighlightSection.vue'
import HighlightsSlideMenuParts, {
  HighlightSlideMenuType,
} from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightsSlideMenuParts.vue'
import EditHighlightSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/EditHighlightsSection.vue'
import {
  EditHighlightData,
  HighlightUserFilterType,
} from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import { ClickedLikeDataType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/LikeButtonParts.vue'
import { ReactionTargetCollectionNameType } from '@/store/stores/collectionModule/documents/reaction/ReactionDocument'
import LoadingOverlayProgressSection from '@/components/sections/LoadingOverlay/LoadingOverlayProgressSection.vue'
import LoadingOverLayStore, {
  ProgressMessageType,
} from '@/store/stores/pageStore/common/LoadingOverlayProgressStore'
import HighlightSnsPostMenuParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightSnsPostMenuParts.vue'
import HighlightToastParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightToastParts.vue'
import DeviceInfo from '@/util/DeviceInfo'
import useX from '@/components/hook/sns/useX'
import { SuccessResponseType } from '@/util/movieExport/useMovieExport'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import { RoleType } from '@/store/stores/pageStore/common/RoleType'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import SceneMoviesDocument from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'
import useDownload from '@/util/download/useDownload'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import Const from '@/util/Const'
import type { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import useBlockUser from '@/components/RaceVideoPage/hook/useBlockUser'
import useHistory from '@/store/hook/useHistory'
import useXAuthentication from '../hook/sns/useXAuthentication'
import IndexedDBAccessor from '@/store/stores/IndexedDBstore/IndexedDBAccessor'

type DataType = {
  highlightList: Array<HighlightDocument>
}

export type HighlightOperationType = 'create' | 'edit' | 'sns' | 'delete'
export type HighlightCommentOperationType = 'edit' | 'delete'
export type HighlightPcOperationType = 'download' | 'xAndInstagram' | 'edit' | 'delete'

/**
 * レース動画再生画面 ハイライト表示ペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceVideoHighlightsPane',
  components: {
    HighlightSnsPostMenuParts,
    HighlightToastParts,
    EditHighlightSection,
    HighlightsSlideMenuParts,
    NoticeHighlightSection,
    NewHighlightsSection,
    HighlightHeaderSection,
    HighlightContentsSection,
    MessageModalSection,
    ConfirmModalSection,
  },
  data(): DataType {
    return {
      highlightList: [],
    }
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
      default: 'landscape',
    },
    /**
     * コンテンツモード
     */
    viewMode: {
      type: String,
      required: true,
    },
    /**
     * ハイライトコメントを入力するために動画再生画面に遷移してきたか
     */
    inputComment: {
      type: String,
      default: '',
    },
    noticeHighlightOnboardingVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * ハイライトID
     * 遷移先の画面から戻ってきた時に対象のハイライトにスクロールさせるために利用する
     */
    scrollToHighlightId: {
      type: String,
      default: '',
    },
    /**
     * ハイライト、ハイライトコメントどちらのスライドメニューを表示するか
     */
    highlightSlideMenu: {
      type: String as PropType<HighlightSlideMenuType | null>,
      default: null,
    },
  },
  setup(props, context) {
    const xAPI = useX(process.env.VUE_APP_X_OAUTH_API_KEY, process.env.VUE_APP_X_OAUTH_API_SECRET)
    const { setLastSeenHighlightData, getTargetRaceLastSeenHighlightData } = IndexedDBAccessor()
    const userStore = StoreUtil.useStore('UserStore')
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { user } = userStore
    const {
      currentChampionshipMasterId,
      currentRaceId,
      selectedPlayerMasterIdForHighlight,
      playEvents,
      participatingPlayers,
      setViewAngle,
      officialMovieInfo,
      highlightCommentStore,
      saveHistory,
      selectedHighlightTab,
      hasNewHighlight,
      savedLastFetchedHighlightForNotifyData,
      angleInfos,
      angleMovieInfos,
      radioDataStore,
      fetchSceneMoviesList,
      sceneMoviesList,
      sceneMoviesByHighlightId,
      fetchReactionsByHighlightId,
      fetchDiffTargetHighlightCommentReactions,
      isTargetHighlightClipAvailableForPc,
      fetchRetrieveNameUsers,
      retrieveNameUsersByUserId,
      fetchedRetrieveNameUserIds,
      highlightStore,
    } = raceVideoPageStore
    const { loginId } = LoginStore.value
    const {
      fetchRaceVideoHighlightsPageData,
      highlightsSortByMovieStartTime,
      highlightsForPreLoad,
      highlightUserIds,
      selectedHighlightId,
    } = useHighlight()

    const { videoPlayer: raceVideoPlayer } = toRefs(raceVideoPageStore.raceVideoPageState)
    const { videoPlayer: highlightPreviewVideoPlayer } = toRefs(
      raceVideoPageStore.highlightStore.raceVideoPageHighlightState,
    )

    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
    const {
      createDisplayCommentData,
      createHighlightComment,
      updateHighlightComment,
      deleteHighlightComment,
    } = useCommunication()
    const { blockTargetUser } = useBlockUser()
    const { saveMissionHistory } = useHistory()

    const loading = inject('loading') as PluginApi
    const router = inject('router') as VueRouter

    const isPc = DeviceInfo.isForPC()

    // ハイライト作成・編集モーダルの表示/非表示
    const createHighlight = ref<boolean>(false)

    // メッセージモーダル
    const messageModal = ref(false)
    const messageModalText = ref('')

    // 削除用モーダル
    const confirmDeleteModal = ref(false)
    const deleteConfirmMessage = ref('')
    const deleteCommentId = ref('')
    const deleteHighlightId = ref('')

    // ユーザーブロックモーダル
    const blockConfirmModal = ref(false)
    const blockToastView = ref(false)
    const blockToastTimerId = ref<number | undefined>(undefined)
    const blockToastDisplayTime = 3000

    // プレビュー動画プレイヤー再描画用のキー
    const videoPlayerKey = ref(0)

    /**
     * highlightContentsSectionコンポーネント
     */
    const highlightContentsSection = ref<InstanceType<typeof HighlightContentsSection>>()
    /**
     * 選手フィルタで選択されている選手ID
     */
    const driverFilterId = ref('')
    /**
     * シーンフィルタで選択されているシーン(プレイイベント)ID
     */
    const sceneFilterId = ref('')
    /**
     * ユーザーフィルタで選択されているID
     */
    const userFilterId = ref('')
    /**
     * ユーザーフィルタで選択されているフィルタタイプ
     */
    const userFilterType: Ref<HighlightUserFilterType | undefined> = ref(undefined)
    /**
     * 動画集APIのポーリングで使用する変数
     */
    let sceneMoviesPollingTimerId: null | number = null

    /**
     * 選手フィルタ、シーンフィルタの選択に応じてハイライトをフィルタする。
     */
    const filteredHighlight = computed(() =>
      highlightsSortByMovieStartTime.value.filter((highlight) => {
        if (highlight.playEventId === 'playStart') {
          // スタートイベントは画面には表示しない
          return false
        }

        if (driverFilterId.value) {
          if (highlight.playerMasterIds?.indexOf(driverFilterId.value) === -1) {
            // プルダウンで選択したドライバー以外は表示しない
            return false
          }
        }
        if (selectedHighlightTab.value === 'official' && sceneFilterId.value) {
          // シーンでフィルタ
          if (highlight.playEventId !== sceneFilterId.value) {
            // プルダウンで選択したシーン以外は表示しない
            return false
          }
        }

        if (selectedHighlightTab.value === 'user' && userFilterId.value) {
          // ユーザーでフィルタ
          if (userFilterType.value === 'self' && highlight._createdBy !== user.value.id) {
            // 自分の投稿でフィルタ
            // 自分が作成した以外のシーンは表示しない
            return false
          }

          const createdUser: UserRetrieveNameResponseType | undefined =
            retrieveNameUsersByUserId.value[highlight._createdBy ?? '']
          if (userFilterType.value === 'role') {
            // ロールでフィルタ
            if (!createdUser?.roles?.includes(userFilterId.value as RoleType))
              // プルダウンで選択したロールが作成したハイライト以外は表示しない
              return false
          }
          if (userFilterType.value === 'userDisplayName') {
            // ユーザー表示でフィルタ
            if (createdUser?.userId !== userFilterId.value)
              // プルダウンで選択したユーザーが作成したハイライト以外は表示しない
              return false
          }
        }

        if (selectedHighlightTab.value === 'official') {
          if (highlight.isCreatedBySfgoUser) {
            // SFgoユーザーがSFgo上で作成したハイライトは表示しない
            return false
          }
        } else if (selectedHighlightTab.value === 'user') {
          // FL-UXで作成したハイライトは表示しない
          if (!highlight.isCreatedBySfgoUser) {
            return false
          }
        }

        // 上記以外
        return true
      }),
    )

    /** ハイライトの作成・編集で使用するデータ */
    const highlightData = ref({}) as Ref<EditHighlightData>

    /** ハイライトメインコメント編集状態 */
    const editModesMainCommentMap = ref<Record<string, boolean>>({})

    /** ハイライトに対する入力コメント（ハイライト毎に入力内容を保持する） */
    const postingHighlightCommentsMap = ref<Record<string, string>>({})
    /** ハイライトコメント入力状態 */
    const enabledInputsMap = ref<Record<string, boolean>>({})
    /** ハイライトコメント編集状態 */
    const editModesMap = ref<Record<string, boolean>>({})

    /** ハイライト情報を最後に取得した日時 */
    let lastHighlightFetchedDate: number

    /**
     * 追加でユーザー情報を取得する処理
     */
    const fetchAdditionalUser = async (targetUserIds: Array<string>) => {
      const newUserIds = difference(targetUserIds, fetchedRetrieveNameUserIds.value)
      if (newUserIds.length > 0) {
        // ユーザ名を取得してないコメントユーザーのユーザー名を取得する
        await fetchRetrieveNameUsers(newUserIds)
      }
    }

    /**
     * ハイライトの取得処理
     */
    const fetchHighlights = async () => {
      Logger.debug(
        `Start to fetch highlight. lastHighlightFetchedDate: ${lastHighlightFetchedDate}`,
      )
      await fetchRaceVideoHighlightsPageData(lastHighlightFetchedDate)
      await fetchAdditionalUser(highlightUserIds.value)

      lastHighlightFetchedDate = now()
    }

    /**
     * 対象ハイライトに対するコメント差分取得
     */
    const fetchDiffHighlightComments = async (highlightId: string) => {
      const fetchResult = await highlightCommentStore.fetchDiffTargetHighlightComments(highlightId)
      if (!fetchResult.isSuccess) {
        return
      }

      await fetchAdditionalUser(highlightCommentStore.highlightCommentUserIds.value)
    }

    /**
     * ハイライト新規作成ボタン表示フラグ
     */
    const newHighlightButtonVisible = ref<boolean>(true)

    /**
     * ハイライト編集画面表示フラグ
     */
    const editHighlightVisible = ref<boolean>(false)

    /**
     * 画面の向きを現在の向きで固定する
     */
    const lockScreenOrientation = () => {
      if (props.screenOrientationType.match('portrait')) {
        window.screen.orientation.lock('portrait')
      } else {
        window.screen.orientation.lock('landscape')
      }
    }

    /**
     * ブラウザ用SNS投稿モーダルを表示するかどうか
     */
    const highlightsBrowserMenuVisible = ref<boolean>(false)

    /**
     * ハイライト作成機能追加お知らせのOKボタンが押下されたときの処理
     */
    const handleNoticeOkClicked = () => {
      context.emit('onClickNoticeOk')
    }

    /**
     * ハイライト編集画面の状態
     */
    const highlightEditType = ref<HighlightOperationType>('create')
    /**
     * 選択されているハイライト自体のコメント
     */
    const selectedParentComment = ref<ParentCommentDataType | null>(null)
    /**
     * 選択されているハイライトに対するコメント
     */
    const selectedComment = ref<CommentDataType | null>(null)

    /**
     * ブロック対象のユーザー情報
     */
    const toBeBlockedUser = ref<UserRetrieveNameResponseType | null>(null)

    /**
     * ハイライト、ハイライトコメント通報URL
     */
    const reportFormUrl = computed(() => {
      // ハイライトの通報かどうか
      const isHighlight = !!selectedParentComment.value
      const { BASE_URL, OPTION } = Const.EXTERNAL_LINKS.REPORT_FORM_URL
      // 違反の疑いがあるユーザーのユーザーID
      const violatingUserId = isHighlight
        ? selectedParentComment.value?.userId
        : selectedComment.value?.user.id
      // 違反の疑いがあるユーザーのユーザー名
      const violatingUserName = encodeURIComponent(
        retrieveNameUsersByUserId.value[violatingUserId ?? '']?.additionalData?.userDisplayName ??
          '',
      )
      // ハイライトの時間（ハイライトカードに表示される時間と一致する）
      const highlightTime = isHighlight
        ? selectedParentComment.value?.raceTime ?? ''
        : raceVideoPageStore.highlightStore.highlightsById.value?.[
            selectedComment.value?.highlightId ?? ''
          ].raceTime ?? ''
      // 通報対象のコメント
      const comment = isHighlight
        ? encodeURIComponent(selectedParentComment.value?.comment ?? '')
        : encodeURIComponent(selectedComment.value?.comment ?? '')
      // 通報対象のハイライトID
      const highlightId = isHighlight
        ? selectedParentComment.value?.highlightId ?? ''
        : selectedComment.value?.highlightId ?? ''
      // 通報対象のコメントID
      const commentId = !isHighlight ? selectedComment.value?.commentId ?? '' : 'なし'
      // 対象ハイライトのURL（URL直打ちで該当箇所までスクロールできるようにする）
      const highlightUrl = encodeURIComponent(
        `${process.env.VUE_APP_BROWSER_BASE_URL}#${router.currentRoute.fullPath}${highlightId}/?playType=highlight&highlightId=${highlightId}`,
      )
      return `${BASE_URL}&${OPTION.REPORTER_EMAIL}=${encodeURIComponent(
        user.value.mailAddress ?? '',
      )}&${OPTION.REPORTER_ID}=${loginId}&${OPTION.VIOLATING_USER_NICKNAME}=${violatingUserName}&${
        OPTION.VIOLATING_USER_ID
      }=${violatingUserId}&${OPTION.HIGHLIGHT_TIME}=${highlightTime}&${
        OPTION.HIGHLIGHT_URL
      }=${highlightUrl}&${OPTION.COMMENT}=${comment}&${OPTION.HIGHLIGHT_ID}=${highlightId}&${
        OPTION.COMMENT_ID
      }=${commentId}`
    })

    /**
     * ブロック対象ユーザーの表示名
     */
    const toBeBlockedUserDisplayName = computed(
      () => toBeBlockedUser.value?.additionalData?.userDisplayName ?? '',
    )

    /**
     * ハイライトの切り抜きが可能かどうか
     * ブラウザ版のみで利用する処理
     */
    const canClipTargetOperationHighlightForPc = computed(() => {
      const targetHighlight = highlightsSortByMovieStartTime.value.find(
        (highlightItem) => highlightItem.id === selectedParentComment.value?.highlightId,
      )
      if (!targetHighlight) {
        return false
      }
      return isTargetHighlightClipAvailableForPc(targetHighlight)
    })

    /**
     * ハイライトの編集モーダルを開く
     */
    const openEditHighlight = () => {
      if (!isPc) lockScreenOrientation()
      raceVideoPlayer.value?.pause()
      context.emit('openHighlightsModal')

      editHighlightVisible.value = true
      highlightEditType.value = 'edit'
    }

    /**
     * ハイライトの編集モーダルを閉じる
     */
    const closeEditHighlight = () => {
      if (!isPc) window.screen.orientation.unlock()
      radioDataStore.pauseRadioAudio()
      context.emit('closeHighlightsModal')

      editHighlightVisible.value = false
    }

    /**
     * ハイライト削除確認モーダル表示
     * @param highlightId 削除するハイライトのID
     */
    const openDeleteHighlightConfirmModal = (highlightId: string) => {
      deleteConfirmMessage.value = i18n.tc('RaceVideoPage.highlights.deleteMessage')
      confirmDeleteModal.value = true
      deleteHighlightId.value = highlightId
    }

    /**
     * ハイライト、ハイライトコメントスライドメニューを閉じた時の処理
     */
    const onCloseSlideMenu = () => {
      context.emit('onCloseSlideMenu')
    }

    /**
     * ハイライトメニューの項目ボタンが押下されたときの処理
     * @param {string} menuId
     */
    const handleMenuClicked = async (menuId: HighlightOperationType) => {
      highlightEditType.value = menuId
      onCloseSlideMenu()
      const parentComment = selectedParentComment.value as ParentCommentDataType
      if (menuId !== 'delete') {
        const loader = loading.show()
        const highlight = raceVideoPageStore.getHighlightData(parentComment)
        loader.hide()
        if (highlight) {
          highlightData.value = highlight
          openEditHighlight()
        }
      } else {
        openDeleteHighlightConfirmModal(parentComment.highlightId)
      }
    }

    const handleBlockMenuClicked = (menuId: string) => {
      if (menuId === 'report') {
        onCloseSlideMenu()
      }
      if (menuId === 'block') {
        onCloseSlideMenu()
        blockConfirmModal.value = true
        context.emit('openBlockModal')
      }
    }

    /**
     * 選択されているハイライトに紐づく動画集
     */
    const sceneMoviesForSelectedHighlight = computed(() =>
      selectedParentComment.value
        ? sceneMoviesByHighlightId.value[selectedParentComment.value.highlightId]
        : null,
    )
    /**
     * シーン動画集取得のポーリングを停止する
     */
    const clearSceneMoviesPolling = () => {
      if (sceneMoviesPollingTimerId) clearInterval(sceneMoviesPollingTimerId)
    }
    /**
     * シーン動画集取得のポーリングを開始する
     * fetchしてステータスが全て、ErrorもしくはCompleteならポーリングはしない。
     */
    const startSceneMoviesPolling = async () => {
      if (!isPc) return
      await fetchSceneMoviesList()
      if (sceneMoviesList.value.some((sceneMovies) => sceneMovies.status === 'InProgress')) {
        sceneMoviesPollingTimerId = window.setInterval(() => {
          if (
            sceneMoviesList.value.every(
              (sceneMovies) => sceneMovies.status === 'Error' || sceneMovies.status === 'Complete',
            )
          ) {
            clearSceneMoviesPolling()
          } else {
            fetchSceneMoviesList().then(() => {
              Logger.debug(
                `RaceVideoHighlightsPane#startSceneMoviesPolling: Polling to fetch SceneMovies.`,
              )
            })
          }
        }, 10000)
      }
    }

    /**
     * 動画集をPOSTして、ダウンロード準備を開始する
     */
    const clipSceneMovie = async () => {
      const parentComment = selectedParentComment.value as ParentCommentDataType
      const highlight = highlightsSortByMovieStartTime.value.find(
        (highlightItem) => highlightItem.id === parentComment.highlightId,
      )
      if (!highlight) return null

      const loader = loading.show()
      const clipSceneMovieResult = await raceVideoPageStore.clipSceneMovie(highlight)
      loader.hide()

      return clipSceneMovieResult
    }

    /**
     * 動画集をPOSTして、ダウンロード準備を開始する
     */
    const startDownloadPreparation = async () => {
      const clipSceneMovieResult = await clipSceneMovie()

      if (!clipSceneMovieResult) {
        await MessageDialogStore.value.open({
          title: i18n.tc('RaceVideoPage.errors.notFindHighlightError.title'),
          message: i18n.tc('RaceVideoPage.errors.notFindHighlightError.message'),
        })
        return false
      }

      if (!clipSceneMovieResult.isSuccess) {
        await MessageDialogStore.value.open({
          title: i18n.tc('RaceVideoPage.errors.downloadHighlightError.title'),
          message: i18n.tc('RaceVideoPage.errors.downloadHighlightError.message'),
          errorApiResponse: clipSceneMovieResult?.response,
        })
        return false
      }

      // 動画集をPOSTしてすぐだと、ステータスがInProgressにならないことがあるためポーリング処理が終了してしまう。念の為、１待ってからポーリング処理を開始する。
      setTimeout(startSceneMoviesPolling, 1000)

      return true
    }

    /**
     * ブラウザ版のハイライトメニューの項目ボタンが押下されたときの処理
     */
    const handleHighlightPcMenuClicked = async (menuId: HighlightPcOperationType) => {
      if (menuId === 'download') {
        const downloadResult = await startDownloadPreparation()
        if (downloadResult) onCloseSlideMenu()
        return
      }

      if (menuId === 'xAndInstagram') {
        highlightsBrowserMenuVisible.value = true
        onCloseSlideMenu()
        return
      }

      const parentComment = selectedParentComment.value as ParentCommentDataType
      if (menuId === 'delete') {
        openDeleteHighlightConfirmModal(parentComment.highlightId)
        return
      }

      const loader = loading.show()
      const highlight = raceVideoPageStore.getHighlightData(parentComment)
      loader.hide()
      if (highlight) {
        highlightData.value = highlight
        openEditHighlight()
      }
      onCloseSlideMenu()
    }

    /**
     * ハイライトメニューのオーバーレイが押下されたときの処理
     */
    const handleMenuOverlayClicked = (slideMenu: HighlightSlideMenuType) => {
      onCloseSlideMenu()
      if (slideMenu === 'highlight') {
        selectedParentComment.value = null
      } else if (slideMenu === 'highlightComment') {
        selectedComment.value = null
      }
    }

    /**
     * ハイライトのメニューボタンが押下されたときの処理
     */
    const handleOpenMenuClicked = (
      comment: ParentCommentDataType,
      slideMenu: HighlightSlideMenuType,
    ) => {
      // 選択してるハイライトコメントを初期化
      selectedComment.value = null

      selectedParentComment.value = comment

      // ブロック対象ユーザー
      toBeBlockedUser.value = retrieveNameUsersByUserId.value[comment.userId]

      context.emit('onOpenHighlightSlideMenu', slideMenu)
    }

    /**
     * コメントのメニューボタンが押下されたときの処理
     */
    const handleOpenCommentMenuClicked = (
      comment: CommentDataType,
      slideMenu: HighlightSlideMenuType,
    ) => {
      // 選択してるハイライトを初期化
      selectedParentComment.value = null

      selectedComment.value = comment

      const commentUserId = comment.user.id

      // ブロック対象ユーザー
      toBeBlockedUser.value = retrieveNameUsersByUserId.value[commentUserId]

      context.emit('onOpenHighlightCommentSlideMenu', slideMenu)
    }

    const handleEditHighlightCloseClicked = () => {
      closeEditHighlight()
    }

    /**
     * ハイライトの作成・編集で使用するデータを取得する
     */
    const getHighlightData = async () => {
      if (highlightEditType.value === 'create' && !user.value.userDisplayName) {
        // 表示名未入力の場合、マイページで入力させる
        router.push({
          path: `/mypage/?championshipMasterId=${currentChampionshipMasterId.value}&raceId=${currentRaceId.value}&isEditCard=true`,
        })
        return null
      }

      if (highlightEditType.value === 'create') {
        // 作成の場合にはハイライトのサムネイルを取得する
        const result = await raceVideoPageStore.fetchThumbnailData(
          raceVideoPageStore.raceVideoPageState.videoStatus?.currentTime || 0,
        )
        if (!result.isSuccess) {
          // ハイライトのサムネイルを取得に失敗した際には、エラーメッセージを表示
          MessageDialogStore.value.open({
            title: i18n.tc('RaceVideoPage.errors.fetchHighlightThumbnailError.title'),
            message: i18n.tc('RaceVideoPage.errors.fetchHighlightThumbnailError.message'),
            errorApiResponse: result.response,
          })
          Logger.info(
            `RaceVideoHighlightsPane#getHighlightData: Failed to get highlight thumbnail.`,
          )
          return null
        }
      }

      return raceVideoPageStore.getHighlightData()
    }

    /**
     * ハイライトの作成モーダルを開く
     */
    const openHighlightsModal = async () => {
      highlightEditType.value = 'create'
      const result = await getHighlightData()
      if (result) {
        if (!isPc) lockScreenOrientation()
        raceVideoPlayer.value?.pause()
        createHighlight.value = true
        highlightData.value = result
        context.emit('openHighlightsModal')
      }
    }

    /**
     * ハイライトの作成モーダルを閉じる
     */
    const closeHighlightsModal = () => {
      if (!isPc) window.screen.orientation.unlock()
      radioDataStore.pauseRadioAudio()
      createHighlight.value = false
      context.emit('closeHighlightsModal')
    }

    const closeBlockConfirmModal = () => {
      blockConfirmModal.value = false
      context.emit('closeBlockModal')
    }

    /**
     * ユーザーブロックモーダルのOKが押下されたときの処理
     */
    const handleBlockOkClicked = async () => {
      closeBlockConfirmModal()
      if (blockToastTimerId.value) {
        clearTimeout(blockToastTimerId.value)
      }
      // 対象ユーザーをブロックする
      const result = await blockTargetUser(
        toBeBlockedUser.value?.userId ?? '',
        selectedComment.value,
      )
      if (!result.isSuccess) {
        return
      }
      // トーストを表示
      blockToastView.value = true
      // 3秒経ったら以下の処理を実行
      blockToastTimerId.value = setTimeout(() => {
        // トーストを消す
        blockToastView.value = false
        // ブロック対象のユーザーをクリア
        toBeBlockedUser.value = null
      }, blockToastDisplayTime)
    }

    /**
     * ブロックしましたのトーストを閉じる
     */
    const closeBlockToastView = () => {
      blockToastView.value = false
    }

    /**
     * ブロックを取り消す
     */
    const cancelBlockUser = async () => {
      // トーストを消す
      blockToastView.value = false
      await blockTargetUser(toBeBlockedUser.value?.userId ?? '', selectedComment.value, true)
      // ブロック対象のユーザーをクリア
      toBeBlockedUser.value = null
    }

    const handleBlockCancelClicked = () => {
      closeBlockConfirmModal()
    }

    /**
     * X・Instagramに投稿モーダルのダウンローするボタンが押下されたときの処理
     */
    const handleSnsImageDownloadClicked = () => {
      highlightsBrowserMenuVisible.value = false
    }

    /**
     * ブラウザ用の処理: SNS画面に遷移した際にミッション達成にする処理
     */
    const savePostSnsMissionHistoryForPc = () =>
      // SNS投稿ミッションログを登録する
      saveMissionHistory(user.value.id || '', 'manage_user', 'post_on_sns')

    /**
     * データ表示モードを監視
     */
    watch(
      () => props.viewMode,
      (newViewMode, oldViewMode) => {
        // ハイライトタブを表示した場合、ハイライト一覧を取得する
        if (newViewMode === 'highlight') {
          fetchHighlights()
          startSceneMoviesPolling()
        } else {
          clearSceneMoviesPolling()

          if (oldViewMode === 'highlight') {
            // ハイライトタブから切り替えを行った場合の処理
            if (currentRaceId.value) {
              // 対象レースのハイライト一覧を最後に見た日時をIndexedDBに保存
              setLastSeenHighlightData({
                matchId: currentRaceId.value,
                date: now(),
              })
              // 一時保存していたデータをクリア
              savedLastFetchedHighlightForNotifyData.value = null
            }
            // 新着通知アイコンを非表示にする
            hasNewHighlight.value = false
          }
        }
      },
    )

    /**
     * ハイライト新着通知を行うための処理を実行する
     */
    const execDisplayNewHighlightNotifications = async () => {
      if (!currentRaceId.value) {
        return
      }

      const lastSeenDate =
        (await getTargetRaceLastSeenHighlightData(currentRaceId.value))?.date ?? 0
      const [publicHighlightCountResult, notPublicHighlightCountResult] = await Promise.all([
        highlightStore.fetchNewHighlightCount(currentRaceId.value, true, lastSeenDate),
        highlightStore.fetchNewHighlightCount(currentRaceId.value, false, lastSeenDate),
      ])

      const newHighlightCount =
        (publicHighlightCountResult.response?.data.count ?? 0) +
        (notPublicHighlightCountResult.response?.data.count ?? 0)
      if (newHighlightCount > 0) {
        // 新着通知アイコンを表示する
        raceVideoPageStore.hasNewHighlight.value = true
      }

      // 取得したハイライト日時をIndexedDBに保存
      setLastSeenHighlightData({
        matchId: currentRaceId.value,
        date: now(),
      })
    }

    onBeforeMount(async () => {
      LocalCache.preLoad(
        highlightsForPreLoad(highlightsSortByMovieStartTime.value, officialMovieInfo.value),
      ).then(() => {
        Logger.info('RaceVideoHighlightsPane#mounted: Success to preload highlight movie data.')
      })
      // ハイライトに関連するリアルタイムメッセージを受信
      await initRTM()
      subscribeRTM('highlight', async (data: RTMCallbackParamType) => {
        Logger.debug(`Receive highlight event. event: ${JSON.stringify(data)}`)
        if (data.table === 'communication_user_game_event') {
          setTimeout(() => {
            fetchHighlights()
          }, 1000)
          if (data.type === 'created') {
            // ハイライト作成時には、新着通知アイコンを表示する
            execDisplayNewHighlightNotifications()
          }
        }
        if (data.table === 'communication_comment') {
          const message = data.message as CommunicationCommentMessageType
          const targetHighlightId = message.userGameEventId
          if (highlightCommentStore.commentsByHighlightId.value[targetHighlightId]) {
            // ハイライトコメント情報を更新する
            fetchDiffHighlightComments(targetHighlightId)
          }
          if (
            highlightCommentStore.highlightCommentCountByHighlightId.value[targetHighlightId] !==
              undefined &&
            data.type !== 'edited'
          ) {
            // 作成・削除の際には、ハイライトコメント数
            highlightCommentStore.fetchHighlightCommentCount(targetHighlightId)
          }
        }
      })
    })

    onBeforeUnmount(() => {
      clearTimeout(blockToastTimerId.value)
      clearSceneMoviesPolling()
    })

    onUnmounted(() => {
      // ハイライトの選択状態を解除する
      selectedHighlightId.value = null
      selectedPlayerMasterIdForHighlight.value = null
      // ハイライトに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
    })
    return {
      user,
      currentChampionshipMasterId,
      currentRaceId,
      createDisplayCommentData,
      createHighlightComment,
      updateHighlightComment,
      deleteHighlightComment,
      retrieveNameUsersByUserId: retrieveNameUsersByUserId as Ref<
        Record<string, UserRetrieveNameResponseType>
      >,
      saveMissionHistory,
      messageModal,
      messageModalText,
      confirmDeleteModal,
      deleteConfirmMessage,
      deleteCommentId,
      deleteHighlightId,
      blockConfirmModal,
      videoPlayerKey,
      blockToastView,
      handleBlockOkClicked,
      handleBlockCancelClicked,
      selectedHighlightId: selectedHighlightId as WritableComputedRef<string | null>,
      selectedPlayerMasterIdForHighlight: selectedPlayerMasterIdForHighlight as WritableComputedRef<
        string | null
      >,
      playEvents: playEvents as ComputedRef<PlayEventDocument[]>,
      participatingPlayers: participatingPlayers as ComputedRef<PlayerDocument[]>,
      driverFilterId,
      sceneFilterId,
      userFilterId,
      userFilterType,
      filteredHighlight: filteredHighlight as ComputedRef<HighlightDocument[]>,
      postingHighlightCommentsMap,
      enabledInputsMap,
      editModesMainCommentMap,
      editModesMap,
      setViewAngle,
      highlightCommentStore,
      saveHistory,
      newHighlightButtonVisible,
      handleNoticeOkClicked,
      handleMenuClicked,
      handleHighlightPcMenuClicked,
      handleBlockMenuClicked,
      handleOpenMenuClicked,
      handleMenuOverlayClicked,
      onCloseSlideMenu,
      handleOpenCommentMenuClicked,
      selectedComment,
      editHighlightVisible,
      handleEditHighlightCloseClicked,
      highlightEditType,
      selectedParentComment,
      canClipTargetOperationHighlightForPc,
      openHighlightsModal,
      closeHighlightsModal,
      highlightData,
      raceVideoPageStore,
      angleInfos,
      angleMovieInfos,
      loading,
      openDeleteHighlightConfirmModal,
      highlightContentsSection,
      fetchHighlights,
      createHighlight,
      closeEditHighlight,
      highlightPreviewVideoPlayer,
      isPc,
      xAPI,
      fetchOrganizations: contractInfoStore.fetchOrganizations,
      ownOrganization: contractInfoStore.ownOrganization as ComputedRef<OrganizationDocument>,
      updateOrganization: contractInfoStore.updateOrganization,
      radioDataStore,
      highlightsBrowserMenuVisible,
      handleSnsImageDownloadClicked,
      savePostSnsMissionHistoryForPc,
      sceneMoviesByHighlightId: sceneMoviesByHighlightId as Ref<
        Record<string, SceneMoviesDocument>
      >,
      sceneMoviesForSelectedHighlight,
      startDownloadPreparation,
      highlightCommentCountByHighlightId:
        highlightCommentStore.highlightCommentCountByHighlightId as ComputedRef<
          Record<string, number>
        >,
      fetchReactionsByHighlightId,
      fetchDiffTargetHighlightCommentReactions,
      fetchAdditionalUser,
      fetchDiffHighlightComments,
      reportFormUrl,
      toBeBlockedUser,
      toBeBlockedUserDisplayName,
      closeBlockToastView,
      cancelBlockUser,
    }
  },
  async mounted() {
    if (this.scrollToHighlightId) {
      // 動画再生画面表示時、ハイライトを再生し、かつハイライトカードの位置にスクロールさせる場合の処理
      /**
       * NOTE: mounted時に、ストアに保存しているハイライトデータ（highlightsById）を取得できなかったので、setIntervalを使ってストアに保存しているハイライトデータにアクセスできるまで処理を繰り返すという作りにした。
       * 最大10回実行し、それでもストアに保存しているハイライトデータを取得できない場合はシークさせない。
       */
      let count = 0
      const timerId = setInterval(() => {
        const targetHighlight = this.raceVideoPageStore.highlightStore.highlightsById.value?.[
          this.scrollToHighlightId
        ]
          ? this.raceVideoPageStore.highlightStore.highlightsById.value[this.scrollToHighlightId]
          : undefined

        if (targetHighlight) {
          if (targetHighlight.isCreatedBySfgoUser) {
            // ユーザータブに切り替える
            this.raceVideoPageStore.selectedHighlightTab.value = 'user'
          } else {
            // 公式タブに切り替える
            this.raceVideoPageStore.selectedHighlightTab.value = 'official'
          }

          // ハイライト選択を切り替え、指定のハイライトを再生できるようにする
          this.changeScene(
            targetHighlight.highlightId ?? '',
            targetHighlight.isCreatedBySfgoUser,
            // 公式ハイライトの場合は常にundefinedを渡し、中継映像を再生する
            targetHighlight.isCreatedBySfgoUser
              ? targetHighlight.playerMasterIds?.[0] ?? undefined
              : undefined,
          )

          // ハイライトを再生できたらsetIntervalを停止
          clearInterval(timerId)
          return
        }

        count += 1
        if (count >= 10) {
          // 10回試しても対象のハイライトを取得できない場合、setIntervalを停止
          clearInterval(timerId)
        }
      }, 500)
    }
  },
  methods: {
    /**
     * 動画プレイヤーを表示する
     */
    showHighlightPreviewVideoPlayer() {
      if (!this.isPc) this.highlightPreviewVideoPlayer?.show()
    },
    /**
     * 動画プレイヤーを非表示にする
     */
    hideHighlightPreviewVideoPlayer() {
      this.highlightPreviewVideoPlayer?.pause()
      if (!this.isPc) this.highlightPreviewVideoPlayer?.hide()
    },
    /**
     * 選手フィルタで選手が選択された場合に呼び出される。
     * @param id 選手マスターID
     */
    changeDriverFilter(id: string): void {
      this.driverFilterId = id
    },
    /**
     * シーンまたはユーザーフィルタで選択された場合に呼び出される。
     * @param id
     *  - シーンの場合: (プレイイベント)ID
     *  - ユーザーの場合: ユーザーIDまたはロール
     * @param type フィルタタイプ。ユーザーフィルタの時に値が渡ってくる。
     */
    changeSceneOrUserFilter(id: string, type?: HighlightUserFilterType): void {
      if (this.raceVideoPageStore.selectedHighlightTab.value === 'official') {
        // シーンでフィルタしている場合
        this.sceneFilterId = id
      } else {
        // ユーザーでフィルタしている場合
        this.userFilterId = id
        this.userFilterType = type
      }
    },
    /**
     * 選手・シーンフィルタが選択・解除された場合に呼び出される。
     */
    handleEnabledFilterSelect(value: boolean): void {
      this.newHighlightButtonVisible = !value
    },
    /**
     * ハイライト選択切り替え
     * @param highlightId ハイライトID
     * @param isCreatedBySfgoUser がユーザー作成のハイライトかどうか
     * @param playerMasterId 選手マスタID
     */
    changeScene(highlightId: string, isCreatedBySfgoUser: boolean, playerMasterId?: string): void {
      if (!playerMasterId && this.selectedHighlightId === highlightId) {
        // 同じハイライトがタップされた時はハイライトの選択状態を解除する
        this.selectedHighlightId = null
        this.selectedPlayerMasterIdForHighlight = null
        return
      }
      if (isCreatedBySfgoUser && this.selectedHighlightId === highlightId) {
        // SFgoユーザー作成ハイライトの場合、同じハイライトがタップされた時はplayerMasterIdの有無に関わらずハイライトの選択状態を解除する
        this.selectedHighlightId = null
        this.selectedPlayerMasterIdForHighlight = null
        return
      }
      const sameHighlightSelected = this.selectedHighlightId === highlightId
      // ハイライトを選択状態にする
      this.selectedHighlightId = highlightId || null
      // 視聴履歴取得
      this.saveHistory()
      if (playerMasterId) {
        if (sameHighlightSelected && this.selectedPlayerMasterIdForHighlight === playerMasterId) {
          // 同じ選手がタップされた時は、選手の選択状態を解除し、公式映像に切り替える
          this.setViewAngle('race')
          this.selectedPlayerMasterIdForHighlight = null
        } else {
          // 選択されているハイライトカードの選手マスタIDを記録し、オンボード映像に切り替える
          this.setViewAngle('player')
          this.selectedPlayerMasterIdForHighlight = playerMasterId
        }
      } else {
        // ハイライトタップ時は公式映像に切り替える
        this.setViewAngle('race')
        this.selectedPlayerMasterIdForHighlight = null
      }
    },
    /**
     * 対象のハイライトに入力したコメントを更新する
     * @param value 入力したハイライトコメント
     * @param highlightId コメント対象のハイライトID
     */
    setTargetHighlightComment(value: string, highlightId: string) {
      this.postingHighlightCommentsMap = {
        ...this.postingHighlightCommentsMap,
        ...{ [highlightId]: value },
      }
    },
    /**
     * 対象のハイライトに入力したメインコメントを更新する
     * @param _value 入力したハイライトメインコメント
     * @param highlightId コメント対象のハイライトID
     * TODO: 保存処理
     */
    setTargetHighlightMainComment(_value: string, highlightId: string) {
      this.$delete(this.editModesMainCommentMap, highlightId)
    },
    /**
     * 対象のハイライトに入力したコメントをクリアする
     * @param highlightId コメント対象のハイライトID
     */
    resetTargetHighlightComment(highlightId: string) {
      // 対象highlightIdのコメントをプロパティごと削除
      this.$delete(this.postingHighlightCommentsMap, highlightId)
    },
    /**
     * 対象のハイライトコメント入力状態を変更する
     * @param value 入力可能にするかどうか
     * @param highlightId コメント対象のハイライトID
     */
    changeTargetEnabledInput(value: boolean, highlightId: string) {
      if (!this.user.userDisplayName) {
        // 表示名未入力の場合、マイページで入力させる
        this.$router.push({
          path: `/mypage/?championshipMasterId=${this.currentChampionshipMasterId}&raceId=${this.currentRaceId}&highlightId=${highlightId}&isEditCard=true`,
        })
        return
      }

      if (value) {
        this.enabledInputsMap = {
          ...this.enabledInputsMap,
          ...{ [highlightId]: value },
        }
      } else {
        // 対象ハイライトの入力状態をプロパティごと削除して元に戻す
        this.$delete(this.enabledInputsMap, highlightId)
        // 対象のハイライトに入力したコメントをクリアする
        this.resetTargetHighlightComment(highlightId)
      }

      this.newHighlightButtonVisible = !value
    },
    /**
     * 対象のハイライトコメント編集状態を変更する
     * @param value 編集状態かどうか
     * @param commentId 編集対象のコメントID
     */
    changeTargetIsEditMode(value: boolean, commentId: string) {
      if (value) {
        this.editModesMap = {
          ...this.editModesMap,
          ...{ [commentId]: value },
        }
      } else {
        // 対象コメントの編集状態をプロパティごと削除して元に戻す
        this.$delete(this.editModesMap, commentId)
      }

      this.newHighlightButtonVisible = !value
    },
    /**
     * 対象のハイライトメインコメント編集状態を変更する
     * @param value 編集状態かどうか
     * @param highlightId 編集対象のハイライトID
     */
    changeTargetMainCommentIsEditMode(value: boolean, highlightId: string) {
      if (value) {
        this.editModesMainCommentMap = {
          ...this.editModesMainCommentMap,
          ...{ [highlightId]: value },
        }
      } else {
        // 対象コメントの編集状態をプロパティごと削除して元に戻す
        this.$delete(this.editModesMainCommentMap, highlightId)
      }
      this.newHighlightButtonVisible = !value
    },
    /**
     * ハイライトコメントメニューの項目ボタンが押下されたときの処理
     * @param {string} menuId
     */
    handleCommentMenuClicked(menuId: HighlightCommentOperationType) {
      this.onCloseSlideMenu()
      if (menuId === 'edit') {
        this.changeTargetIsEditMode(true, this.selectedComment?.commentId ?? '')
      } else if (menuId === 'delete') {
        this.openDeleteCommentConfirmModal(this.selectedComment?.commentId ?? '')
      }
    },
    /**
     * シェア完了モーダル表示
     * @param type share or copy
     * TODO: アプリの対象の画面に戻れる機能を実装次第、シェア機能を実装
     */
    // openSharedMessageModal(type: ShareResult): void {
    //   this.messageModalText =
    //     type === 'share' ? this.$tc('common.share') : this.$tc('common.urlCopy')
    //   this.messageModal = true
    // },
    /**
     * xにハイライト動画とコメントを投稿する
     */
    async postX(oAuthToken: string, oAuthSecretToken: string, comment: string, fileUri: string) {
      return new Promise((resolve) => {
        // エクスポートされたファイルを読み込む
        window.resolveLocalFileSystemURL(
          fileUri,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (fileEntry: any) => {
            fileEntry.file(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              async (file: any) => {
                const reader = new FileReader()
                reader.readAsArrayBuffer(file)
                reader.onloadend = async () => {
                  // arrayBufferとして読み込んだファイルをblobに変換する
                  const blob = new Blob([reader.result as string])
                  try {
                    // 動画ファイルをアップロードする
                    const mediaId = await this.xAPI.chunkUploadFile(
                      oAuthToken,
                      oAuthSecretToken,
                      blob,
                    )
                    LoadingOverLayStore.value.updateProgressList('postX', 80)
                    // ツイートする
                    const result = await this.xAPI.tweet(
                      oAuthToken,
                      oAuthSecretToken,
                      // ハッシュタグを付けて投稿する
                      `${comment}\n${process.env.VUE_APP_X_HASH_TAG}`,
                      mediaId,
                    )
                    LoadingOverLayStore.value.updateProgressList('postX', 100)
                    Logger.info(`Success to tweet. tweet.id:${result.id}, mediaId: ${mediaId}`)
                    resolve(true)
                  } catch (e) {
                    Logger.error(`Failed to tweet. e: ${JSON.stringify(e)}`)
                    resolve(false)
                  }
                }
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (error: any) => {
                Logger.info(
                  `RaceVideoHighlightsPane#saveHighlight: Failed to post sns for read file. highlightId: ${JSON.stringify(
                    error,
                  )}`,
                )
                resolve(false)
              },
            )
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => {
            Logger.info(
              `RaceVideoHighlightsPane#saveHighlight: Failed to post sns for read fileEntry. ${JSON.stringify(
                error,
              )}`,
            )
            resolve(false)
          },
        )
      })
    },
    /**
     * 対象ハイライトデータを更新し、SNS投稿済みにする
     */
    async updateHighlightPostedSnsStatus(createdHighlight: HighlightDocument) {
      await this.fetchHighlights()

      const ownHighlight: HighlightDocument | undefined =
        this.raceVideoPageStore.highlightStore.highlightsById.value[createdHighlight.id ?? '']
      if (ownHighlight) {
        const postedHighlightData = new HighlightDocument({
          ...ownHighlight,
          additionalData: {
            ...ownHighlight.additionalData,
            postedSns: {
              ...ownHighlight.additionalData?.postedSns,
              x: true,
            },
          },
        })
        // X投稿済みにする
        const updateResult = await this.raceVideoPageStore.saveHighlight(
          postedHighlightData.note ?? '',
          postedHighlightData,
        )
        if (!updateResult.isSuccess) {
          Logger.error(
            `RaceVideoHighlightsPane#saveHighlight: The process to make X posted failed.`,
          )
        }
      }
    },
    /**
     * ハイライトを保存する
     */
    async saveHighlight(
      comment: string,
      highlightData: HighlightDocument,
      isPostX: boolean,
      isPostInstagram: boolean,
    ) {
      const { xAuthentication } = useXAuthentication()
      const isCreate = !highlightData.id
      this.hideHighlightPreviewVideoPlayer()
      this.radioDataStore.pauseRadioAudio()

      if (DeviceInfo.isCordova() && (isPostX || isPostInstagram)) {
        /**
         * 保持しているxのトークンが取得から50分を超えているかどうかを判断するインライン関数
         */
        const availableXAuthentication = () =>
          !!this.ownOrganization.xAuthentication &&
          dayjs(this.ownOrganization.xAuthentication.acquisitionDate).add(50, 'm').valueOf() >
            dayjs().valueOf()

        let hasAvailableXAuthentication = availableXAuthentication()
        if (!hasAvailableXAuthentication) {
          // InAppBrowserから戻ってくるとプレビュー用のビデオプレイヤーが表示されなくなるため、プレビュー用のビデオプレイヤーを再描画する
          this.videoPlayerKey += 1
          hasAvailableXAuthentication = await xAuthentication()
          await this.fetchOrganizations()
        }
        if (!hasAvailableXAuthentication) {
          MessageDialogStore.value.open({
            title: i18n.tc('RaceVideoPage.errors.snsAuthorizationErrorForX.title'),
            message: i18n.tc('RaceVideoPage.errors.snsAuthorizationErrorForX.message'),
          })
          Logger.info(
            `RaceVideoHighlightsPane#saveHighlight: Failed to post x for authentication. highlightId: ${highlightData.id}`,
          )
          return
        }
      }

      let loader = this.loading.show()
      const result = await this.raceVideoPageStore.saveHighlight(comment, highlightData)
      loader.hide()

      if (!result.isSuccess) {
        if (isCreate) {
          // ハイライトの作成に失敗した場合、エラーメッセージを表示
          await MessageDialogStore.value.open({
            title: i18n.tc('RaceVideoPage.errors.postHighlightError.title'),
            message: i18n.tc('RaceVideoPage.errors.postHighlightError.message'),
            errorApiResponse: result.response,
          })
          Logger.info(`RaceVideoHighlightsPane#saveHighlight: Failed to post highlight.`)
        } else {
          // ハイライトの編集に失敗した場合、エラーメッセージを表示
          await MessageDialogStore.value.open({
            title: i18n.tc('RaceVideoPage.errors.editHighlightError.title'),
            message: i18n.tc('RaceVideoPage.errors.editHighlightError.message'),
            errorApiResponse: result.response,
          })
          Logger.info(
            `RaceVideoHighlightsPane#saveHighlight: Failed to edit highlight. highlightId: ${highlightData.id}`,
          )
        }
        this.showHighlightPreviewVideoPlayer()
        return
      }

      if (result.data && DeviceInfo.isCordova() && (isPostX || isPostInstagram)) {
        const displayProgressList: Array<ProgressMessageType> = [
          {
            progressId: 'highlightExport',
            message: 'RaceVideoPage.highlights.progress.exportHighlight',
            progress: 0,
          },
        ]
        if (isPostX) {
          displayProgressList.push({
            progressId: 'postX',
            message: 'RaceVideoPage.highlights.progress.postX',
            progress: 0,
          })
        }
        if (isPostInstagram) {
          displayProgressList.push({
            progressId: 'postInstagram',
            message: 'RaceVideoPage.highlights.progress.postInstagram',
            progress: 0,
          })
        }
        LoadingOverLayStore.value.setProgressList(displayProgressList)
        LoadingOverLayStore.value.setCautionMessage(
          this.$tc('RaceVideoPage.highlights.sns.caution'),
        )
        loader = this.loading.show(
          { opacity: 0.8 },
          { after: this.$createElement(LoadingOverlayProgressSection) },
        )

        const highlightExportResult = await this.raceVideoPageStore.highlightExport(result.data)
        if (!highlightExportResult.isSuccess) {
          loader.hide()
          if (highlightExportResult.response.errorCode === 'vp_097_008') {
            MessageDialogStore.value.open({
              title: i18n.tc('RaceVideoPage.errors.exportHighlightErrorForAccess.title'),
              message: i18n.tc('RaceVideoPage.errors.exportHighlightErrorForAccess.message'),
            })
            Logger.info(
              `RaceVideoHighlightsPane#saveHighlight: Failed to highlight export for lack of authority. highlightId: ${highlightData.id}`,
            )
          } else if (highlightExportResult.response.errorCode === 'vp_097_018') {
            MessageDialogStore.value.open({
              title: i18n.tc('RaceVideoPage.errors.exportHighlightErrorForDeviceLimited.title'),
              message: i18n.tc('RaceVideoPage.errors.exportHighlightErrorForDeviceLimited.message'),
            })
            Logger.info(
              `RaceVideoHighlightsPane#saveHighlight: Failed to highlight export for lack of authority. highlightId: ${highlightData.id}`,
            )
          } else {
            MessageDialogStore.value.open({
              title: i18n.tc('RaceVideoPage.errors.exportHighlightError.title'),
              message: i18n.tc('RaceVideoPage.errors.exportHighlightError.message'),
            })
            Logger.info(
              `RaceVideoHighlightsPane#saveHighlight: Failed to highlight export. highlightId: ${highlightData.id}`,
            )
          }
        } else {
          const response = highlightExportResult.response as SuccessResponseType
          const fileUri = `file://${response.directAccessFilePath}`

          Logger.info(`RaceVideoHighlightsPane#saveHighlight: fileUri: ${JSON.stringify(fileUri)}`)

          const resultPostX = await this.postX(
            this.ownOrganization.xAuthentication?.oauthToken || '',
            this.ownOrganization.xAuthentication?.oauthTokenSecret || '',
            comment,
            fileUri,
          )
          if (!resultPostX) {
            MessageDialogStore.value.open({
              title: i18n.tc('RaceVideoPage.errors.snsPostErrorForX.title'),
              message: i18n.tc('RaceVideoPage.errors.snsPostErrorForX.message'),
            })
            Logger.info(
              `RaceVideoHighlightsPane#saveHighlight: Failed to post x. highlightId: ${highlightData.id}`,
            )
          } else {
            // SNS投稿に成功したら、ミッションログを登録する
            this.saveMissionHistory(this.user.id || '', 'manage_user', 'post_on_sns')

            if (!result.data.additionalData?.postedSns) {
              // 対象ハイライトデータを更新し、X投稿済みにする
              await this.updateHighlightPostedSnsStatus(result.data)
            }
          }
        }
      }

      await this.fetchHighlights()
      if (isCreate) {
        if (this.raceVideoPageStore.selectedHighlightTab.value !== 'user') {
          // ユーザータブに切り替える
          this.raceVideoPageStore.selectedHighlightTab.value = 'user'
        }
        setTimeout(() => {
          // ハイライト作成で作成したハイライトまでスクロールさせる
          // 公式タブでハイライトを作成した場合、タブ切り替え後に即時スクロールされなかったため、0.1秒後にスクロールする処理を入れることで回避した
          this.highlightContentsSection?.scrollToHighlight(`highlightItemSection${result.data?.id}`)
        }, 100)
      }
      if (isCreate) {
        this.closeHighlightsModal()
      } else {
        this.closeEditHighlight()
      }
      loader.hide()
    },
    /**
     * コメント編集
     * @param editedComment 編集後のコメント
     * @param commentId 編集対象のコメントID
     */
    async editHighlightComment(editedComment: string, commentId: string) {
      const targetHighlightComment = this.highlightCommentStore.targetHighlightComment(commentId)

      const result = await this.updateHighlightComment(
        targetHighlightComment,
        editedComment,
        commentId,
      )

      if (!result.isSuccess) {
        return
      }

      // 対象のハイライトコメント編集状態を元に戻す
      this.changeTargetIsEditMode(false, commentId)

      this.messageModalText = this.$tc('RaceVideoPage.highlights.comment.editSaveMessage')
      this.messageModal = true

      this.highlightCommentStore.fetchDiffTargetHighlightComments(
        targetHighlightComment?.highlightId as string,
      )
    },
    /**
     * コメント投稿
     * @param highlight コメント対象のハイライト
     */
    async postHighlightComment(highlight: HighlightDocument) {
      const highlightId = highlight.highlightId || ''
      const comment = this.postingHighlightCommentsMap[highlightId]

      const result = await this.createHighlightComment(
        this.currentRaceId ?? '',
        comment,
        highlight,
        this.user.id ?? '',
      )

      if (!result.isSuccess) {
        return
      }

      // コメントが5件以上の場合、もっとコメントを見るの下に表示するコメントを追加
      if (result.data) {
        this.raceVideoPageStore.newOwnHighlightComments.value.push(
          this.createDisplayCommentData(result.data),
        )
      }

      // 対象のハイライトコメント入力状態を元に戻す
      this.changeTargetEnabledInput(false, highlightId)

      this.messageModalText = this.$tc('RaceVideoPage.highlights.comment.postedComment')
      this.messageModal = true

      this.highlightCommentStore.fetchHighlightCommentCount(highlightId)
      this.fetchDiffHighlightComments(highlightId)
    },
    /**
     * コメント削除確認モーダル表示
     * @param commentId 削除するコメントのID
     */
    openDeleteCommentConfirmModal(commentId: string) {
      this.deleteConfirmMessage = i18n.tc('RaceVideoPage.highlights.comment.deleteMessage')
      this.confirmDeleteModal = true
      this.deleteCommentId = commentId
    },
    /**
     * コメント削除キャンセル
     */
    cancelDeleteHighlightComment() {
      this.confirmDeleteModal = false
      this.deleteCommentId = ''
      this.deleteHighlightId = ''
    },
    /**
     * ハイライト / コメント 削除
     */
    onDeleteHighlightComment() {
      if (this.deleteCommentId) {
        this.deleteComment()
      } else if (this.deleteHighlightId) {
        this.deleteHighlight()
      }
      this.onCloseSlideMenu()
    },
    /**
     * ハイライト削除
     */
    async deleteHighlight() {
      // 確認モーダルを閉じて、削除対象のハイライトIDをリセット
      this.confirmDeleteModal = false

      const loader = this.loading.show()
      const result = await this.raceVideoPageStore.highlightStore.deleteHighlight(
        this.deleteHighlightId,
      )

      if (!result.isSuccess) {
        loader.hide()
        // ハイライトの削除に失敗した場合、エラーメッセージを表示
        MessageDialogStore.value.open({
          title: i18n.tc('RaceVideoPage.errors.deleteHighlightError.title'),
          message: i18n.tc('RaceVideoPage.errors.deleteHighlightError.message'),
        })
        Logger.info(
          `useHighlightData#deleteHighlight: Failed to delete highlight. highlightId: ${this.deleteHighlightId}`,
        )
      } else {
        await this.fetchHighlights()
        loader.hide()
        this.messageModalText = this.$tc('RaceVideoPage.highlights.successDeleteMessage')
        this.messageModal = true
      }

      this.deleteHighlightId = ''
    },
    /**
     * コメント削除
     */
    async deleteComment() {
      const result = await this.deleteHighlightComment(this.deleteCommentId)

      if (!result.isSuccess) {
        return
      }

      const targetHighlightComment = this.highlightCommentStore.targetHighlightComment(
        this.deleteCommentId,
      )

      const targetHighlightId = targetHighlightComment?.highlightId as string
      this.highlightCommentStore.fetchHighlightCommentCount(targetHighlightId)
      this.highlightCommentStore.fetchDiffTargetHighlightComments(targetHighlightId)

      // コメントが5件以上の場合に表示している、もっとコメントを見るの下の対象コメントを削除
      this.raceVideoPageStore.newOwnHighlightComments.value =
        this.raceVideoPageStore.newOwnHighlightComments.value.filter(
          (comment) => comment.commentId !== this.deleteCommentId,
        )

      // 確認モーダルを閉じて、削除対象のコメントIDをリセット
      this.confirmDeleteModal = false
      this.deleteCommentId = ''

      // 削除完了メッセージモーダル表示
      this.messageModalText = this.$tc('RaceVideoPage.highlights.comment.successDeleteMessage')
      this.messageModal = true
    },
    /**
     * コメントLIKE更新処理
     */
    async changeLike(likeData: ClickedLikeDataType) {
      let targetId = ''
      let targetCollectionName: ReactionTargetCollectionNameType | null = null
      if (likeData.commentId) {
        // コメントのいいねを更新する場合
        targetId = likeData.commentId
        targetCollectionName = 'communication_comment'
      } else {
        // ハイライトのいいねを更新する場合
        targetId = likeData.highlightId
        targetCollectionName = 'communication_user_game_event'
      }

      // いいねを更新する
      if (!likeData.liked) {
        // いいね登録
        const requestData = this.raceVideoPageStore.createReactionRequestData(
          targetId,
          targetCollectionName,
          'LIKE',
        )
        const saveResult = await this.raceVideoPageStore.saveReaction(requestData)
        if (!saveResult.isSuccess) {
          MessageDialogStore.value.open({
            title: i18n.tc('RaceVideoPage.errors.postReactionError.title'),
            message: i18n.tc('RaceVideoPage.errors.postReactionError.message'),
            errorApiResponse: saveResult.response,
          })
          return
        }

        // 保存したら対象の自分のいいね情報を取得する
        const reactionId = saveResult.data?.reactionId ?? ''
        this.raceVideoPageStore.fetchOwnReactionByTargetId(reactionId)
      } else {
        // いいね解除
        const deleteResult = await this.raceVideoPageStore.removeReaction(
          targetId,
          targetCollectionName,
          'LIKE',
        )
        if (!deleteResult.isSuccess) {
          MessageDialogStore.value.open({
            title: i18n.tc('RaceVideoPage.errors.deleteReactionError.title'),
            message: i18n.tc('RaceVideoPage.errors.deleteReactionError.message'),
            errorApiResponse: deleteResult.response,
          })
          return
        }

        // 解除したいいね情報をストアから削除する
        const reactionId =
          this.raceVideoPageStore.ownReactionsByTargetId.value[targetCollectionName]?.[targetId]
            .reactionId
        const newReactions = cloneDeep(this.raceVideoPageStore.reactions.value).filter(
          (reaction) => reaction.reactionId !== reactionId,
        )

        this.raceVideoPageStore.updateStoredReactions(newReactions)
      }
    },
    /**
     * ダウンロード可能状態でユーザーがダウンロードボタンを押した際にファイルをダウンロードする
     */
    downloadHighlight(sceneMovies: SceneMoviesDocument | null) {
      const { loadFileToDownload } = useDownload()
      if (!sceneMovies || !sceneMovies.scenes) return
      loadFileToDownload(
        `${sceneMovies.scenes[0].fileTitle}.${sceneMovies.outputFormat}`,
        CloudFrontUtil.getSignedUrl(sceneMovies.scenes[0].sceneMoviePath),
        {
          responseType: 'blob',
          headers: {
            accept: 'video/mp4',
            'Content-Type': 'application/json',
          },
        },
      )
    },
    /**
     * 対象のハイライトのコメント数といいねを取得する
     */
    fetchParentCommentCountAndReactions(highlightId: string) {
      this.highlightCommentStore.fetchHighlightCommentCount(highlightId)
      this.fetchReactionsByHighlightId(highlightId)
    },
    /**
     * 展開されているハイライトのコメント情報といいね情報を取得する
     */
    async fetchDiffTargetHighlightCommentsAndReactions(highlightId: string) {
      await this.fetchDiffHighlightComments(highlightId)
      const highlightComments = this.highlightCommentStore.commentsByHighlightId.value[highlightId]
      if (highlightComments?.length) {
        this.fetchDiffTargetHighlightCommentReactions(
          highlightId,
          highlightComments.map((highlight) => highlight.id as string),
        )
      }
    },
  },
})
