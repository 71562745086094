var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("header-pane", {
        attrs: {
          pageTitle: _vm.$tc("ErrorPage.pageTitle"),
          "color-type": "light",
        },
      }),
      _c("error-page-pane", {
        attrs: {
          descriptions: _vm.i18n.t(_vm.descriptions),
          title: _vm.i18n.t(_vm.title),
          message: _vm.i18n.t(_vm.message),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }