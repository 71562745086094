import { computed, onMounted, ref, onBeforeUnmount, Ref } from '@vue/composition-api'

/**
 * テレメトリー表示セクションの共通処理を提供する。
 */
export default function useTelemetrySectionUtil() {
  /**
   * タイヤの温度に応じたフラグ値を取得する
   *
   * @param temperature 温度の値を持った Ref
   * @return 温度に応じたフラグ値(CSSクラス名)の Ref
   */
  const getHeatFlag = (temperature: Ref<number>) =>
    computed(() => {
      if (temperature.value <= 60) {
        return 'cold'
      }

      if (temperature.value >= 120) {
        return 'hot'
      }

      return 'normal'
    })

  /**
   * 変動する値の表示用中間値を継続的に更新する
   *
   * @param valueRef 実際の値の Ref
   * @param updateInterval 更新間隔 (millisecond)
   * @return 表示用の値の Ref
   */
  const setupIntermediateValue = (valueRef: Ref<number>, updateInterval: number) => {
    const timer = ref()
    const comparisonValueData = ref(0)
    const displayValueRef = ref(0)

    onMounted(() => {
      timer.value = setInterval(() => {
        if (displayValueRef.value !== valueRef.value) {
          displayValueRef.value += (valueRef.value - comparisonValueData.value) / updateInterval
        }

        if (valueRef.value > comparisonValueData.value) {
          displayValueRef.value =
            valueRef.value < displayValueRef.value ? valueRef.value : displayValueRef.value
        } else if (valueRef.value < comparisonValueData.value) {
          displayValueRef.value =
            valueRef.value > displayValueRef.value ? valueRef.value : displayValueRef.value
        }

        if (displayValueRef.value === valueRef.value) {
          comparisonValueData.value = displayValueRef.value
        }
      }, updateInterval)
    })

    onBeforeUnmount(() => {
      if (timer.value) {
        clearInterval(timer.value)
      }
    })

    return displayValueRef
  }

  /**
   * DOMターゲットのstyle(left/top)数値を整数化する
   * (iOS 表示ブレバグ対策)
   * @param targetClass left/top 数値を整数化するターゲットクラス
   */
  const adjustPosition = (targetClass: string): void => {
    const setPosition = (): void => {
      const target: HTMLElement | null = document.querySelector(targetClass)

      if (!target) {
        return
      }

      target.style.left = ''
      target.style.top = ''
      target.style.width = ''
      target.style.height = ''

      if (targetClass === '.race-telemetry-section') {
        target.style.position = 'relative'
      }

      setTimeout(() => {
        const computedStyle = window.getComputedStyle(target)

        if (targetClass === '.race-telemetry-section') {
          const targetClientRect: DOMRect = target.getBoundingClientRect()

          target.style.left = `${Math.floor(targetClientRect.left)}px`
          target.style.top = `${Math.floor(targetClientRect.top)}px`
          target.style.position = 'fixed'
        } else {
          target.style.left = `${parseInt(computedStyle.left, 10)}px`
          target.style.top = `${parseInt(computedStyle.top, 10)}px`
        }

        target.style.width = `${parseInt(computedStyle.width, 10)}px`
        target.style.height = `${parseInt(computedStyle.height, 10)}px`
      }, 10)
    }
    onMounted(() => {
      setPosition()
      window.addEventListener('orientationchange', setPosition, false)
      window.addEventListener('resize', setPosition, false)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('orientationchange', setPosition, false)
      window.removeEventListener('resize', setPosition, false)
    })
  }

  return {
    getHeatFlag,
    setupIntermediateValue,
    adjustPosition,
  }
}
