























































import { defineComponent } from '@vue/composition-api'
import TeamCardParts from '@/components/common/card/TeamCardParts.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'

/**
 * フィールドセット お気に入りチーム選択 バーツ
 * todo: デザイン調整 / チーム選択ページ遷移処理 / props設定 / エラー表示 / emit等
 */
export default defineComponent({
  name: 'FieldsetFavoriteTeamParts',
  components: {
    TeamCardParts,
    AtomRouterButton,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * 遷移先リンク
     */
    linkTo: {
      type: String,
      required: true,
    },
    /**
     * お気に入りチーム名
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * お気に入りチーム画像
     */
    img: {
      type: String,
    },
  },
})
