import { render, staticRenderFns } from "./InstagramPostItemContentsParts.vue?vue&type=template&id=fa58e432&scoped=true"
import script from "./InstagramPostItemContentsParts.vue?vue&type=script&lang=ts"
export * from "./InstagramPostItemContentsParts.vue?vue&type=script&lang=ts"
import style0 from "./InstagramPostItemContentsParts.vue?vue&type=style&index=0&id=fa58e432&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa58e432",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fa58e432')) {
      api.createRecord('fa58e432', component.options)
    } else {
      api.reload('fa58e432', component.options)
    }
    module.hot.accept("./InstagramPostItemContentsParts.vue?vue&type=template&id=fa58e432&scoped=true", function () {
      api.rerender('fa58e432', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/HomePage/HomePane/parts/InstagramPostItemContentsParts.vue"
export default component.exports