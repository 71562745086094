






































import { computed, defineComponent, inject, onBeforeMount, reactive } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import MypageTicketListContainerSection from '@/components/MypageTicketListPage/MypageTicketListPane/MypageTicketListContainerSection.vue'
import DeviceInfo from '@/util/DeviceInfo'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * 選択中のフィルタタイプの型
 */
export type SelectingFilterType = 'year' | 'place'

/**
 * マイページ: チケット一覧ペイン
 */
export default defineComponent({
  name: 'MypageTicketListPane',
  components: {
    MypageTicketListContainerSection,
    AtomRouterButton,
  },
  inject: ['loading'],
  setup() {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { fetchTicketPageData, digitalTickets, clearTicketPageData } = mypageTicketPageStore
    const { ownOrganization } = mypagePageStore
    const { userPurchasedTicket } = usePurchasedTicketData()
    const { getDisplayDate } = useDisplayDependingOnLang()

    const loading = inject('loading') as PluginApi

    const state = reactive({
      // 選択中のフィルタタイプ
      selectingFilterType: undefined as SelectingFilterType | undefined,
    })
    const isPc = DeviceInfo.isForPC()

    /**
     * ecforce 商品購入履歴更新日時
     */
    const productHistoriesUpdatedDateTime = computed(() => {
      const updatedDateTime = getDisplayDate(
        ownOrganization.value?.ec?.purchasedProductsLastLinkedDate,
        'YYYY/MM/DD HH:mm',
        'MMM DD, YYYY HH:mm',
      )
      return updatedDateTime
        ? I18n.t('MypagePage.MypageTicketListPage.informationMessage.updatedDateTime', {
            dateTime: updatedDateTime,
          }).toString()
        : ''
    })

    /**
     * 今日使えるチケットを持っているかどうか
     */
    const hasTodayTicket = computed(() => {
      const purchasedTicket = userPurchasedTicket(digitalTickets.value, ownOrganization.value)
      // 今日利用できるチケットがある場合、trueを返す
      return purchasedTicket.productNumbers.availableToday.length > 0
    })

    /**
     * フィルターが押下されたときの処理
     * @param {string} filterType
     */
    const handleFilterClicked = (filterType: SelectingFilterType | undefined) => {
      if (state.selectingFilterType === filterType) {
        state.selectingFilterType = undefined
      } else {
        state.selectingFilterType = filterType
      }
    }

    /**
     * プルダウンを閉じる
     */
    const closeFilter = () => {
      state.selectingFilterType = undefined
    }

    onBeforeMount(async () => {
      const errorTitle = I18n.tc(
        'MypagePage.MypageTicketListPage.errors.fetchTicketPageDataError.title',
      )
      const errorMessage = I18n.tc(
        'MypagePage.MypageTicketListPage.errors.fetchTicketPageDataError.message',
      )
      const loader = loading.show()
      try {
        // チケット一覧を取得する
        const results = await fetchTicketPageData()
        loader.hide()

        const failedResult = results.find((result) => result && !result?.isSuccess)
        if (failedResult) {
          MessageDialogStore.value.open({
            title: errorTitle,
            message: errorMessage,
            errorApiResponse: failedResult.response,
          })
          clearTicketPageData()
        }
      } catch (e) {
        loader.hide()
        MessageDialogStore.value.open({
          title: errorTitle,
          message: errorMessage,
        })
        clearTicketPageData()
      }
    })

    return {
      // MypageTicketListPane
      state,
      isPc,
      productHistoriesUpdatedDateTime,
      hasTodayTicket,
      handleFilterClicked,
      closeFilter,
    }
  },
})
