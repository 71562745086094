import { computed, Ref, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import BillingInfoDocument from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import useContractTerm from '@/components/hook/useContractTerm'
import { PaymentScheduleData } from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractInfoPlanPrice from '@/store/hook/useContractInfoPlanPrice'
import useContractPlan from '@/store/hook/useContractPlan'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import usePaymentPrice from '@/components/hook/usePaymentPrice'
import useMonthlyProRatedPrice from '@/components/pc/MyPage/CompletePane/hook/useMonthlyProRatedPrice'
import Logger from '@/util/logger/Logger'
import useMonthlyPlanFirstProRatedBilling from '@/components/pc/MyPage/CompletePane/hook/useMonthlyPlanFirstProRatedBilling'

/**
 * 今後の請求予定に関するHook
 *
 */
export default function usePaymentScheduleOnChange() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
  const { fetchContractInfo, ownContractInfo } = useContractInfo()
  const { fetchContractInfoPlans, futureContactInfoPlan } = useContractInfoPlan()
  const { fetchProvisionalBillingInfo, ownAnnualBillingInfo, ownMonthlyBillingInfo } =
    useBillingInfo()
  const { fetchMonthlyPlanPrice, contractInfoPlanPrice } = useContractInfoPlanPrice()
  const { fetchContractPlans, monthlyPlanPriceJpy, getTargetContractPlan } = useContractPlan()
  const { getCurrentContractTerm } = useContractTerm()
  const { getDisplayDate } = useDisplayDependingOnLang()
  const { calculatePaymentPriceString } = usePaymentPrice()
  const { getMonthlyPlanNextProRatedPriceParam } = useMonthlyProRatedPrice()
  const { getFirstPaymentInfo } = useMonthlyPlanFirstProRatedBilling()

  /**
   * 契約中のプランの契約期間
   * 支払いプランを切り替える画面（有料→有料）
   */
  const contractTerm = computed(() =>
    getCurrentContractTerm(
      effectiveContractPlan as ContractPlanDocument,
      effectiveOrgContract as ContractType,
    ),
  )

  /**
   * 将来のプラン
   */
  const futurePlan = computed(() =>
    futureContactInfoPlan?.value?.contractPlanId
      ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
      : null,
  )

  /**
   * 月額プランの今後の請求予定
   * 支払いプランを切り替える画面（有料→有料）で月額プランから切り替えた場合に使用する
   */
  const getMonthlyPaymentSchedule = async (
    hasRenewedMonthlyPlan: boolean,
    contractInfoId: string,
    monthlyBillingInfo: BillingInfoDocument | undefined,
  ) => {
    const monthlyPaymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)

    let billingStartDate = ''
    let billingEndDate = ''

    // 現在時刻を取得
    const now = dayjs().valueOf()
    // 翌月の決済日
    const nextSettlementDateNum = monthlyBillingInfo?.billingDate ?? 0

    // 翌月の決済日（タイトル表示用）
    const nextSettlementDateForTitle = getDisplayDate(nextSettlementDateNum, 'M/D', 'MMM D')
    // 翌月の決済日
    const nextSettlementDate = getDisplayDate(nextSettlementDateNum)
    // 次の支払い金額
    const nextPaymentPrice = monthlyBillingInfo?.total
      ? monthlyBillingInfo.total.toLocaleString()
      : ''

    // 月額プランの請求情報の場合、月額プランのcontractPlanIdを取得できる想定
    const monthlyContractPlanId = monthlyBillingInfo?.plans?.find(
      (v) => !!v?.price && v.price > 0,
    )?.contractPlanId
    // プラン名を取得
    const planName = monthlyContractPlanId
      ? getTargetContractPlan(monthlyContractPlanId)?.name[I18n.locale] ?? ''
      : ''

    /**
     * 今月既に更新済み場合
     */
    if (hasRenewedMonthlyPlan) {
      // プラン変更月の請求対象期間開始日
      billingStartDate =
        effectiveOrgContract?.startDate &&
        effectiveOrgContract?.startDate < dayjs(now).startOf('month').valueOf()
          ? getDisplayDate(dayjs(now).startOf('month').valueOf()) // 前月以前に契約している場合
          : getDisplayDate(dayjs(contractTerm.value.contractStartDate).valueOf()) // プラン変更手続き日と同じ月に契約している場合
      // プラン変更手続き月の請求対象期間終了日
      billingEndDate = getDisplayDate(dayjs(now).endOf('month').valueOf())

      monthlyPaymentSchedule.value = [
        {
          title: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.title', {
            settlementDate: nextSettlementDateForTitle,
            plan: planName,
          }).toString(),
          period: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.period', {
            startDate: billingStartDate,
            endDate: billingEndDate,
          }).toString(),
          price: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.price', {
            price: nextPaymentPrice,
          }).toString(),
          description: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.description', {
            debitDate: nextSettlementDate,
          }).toString(),
          note: '',
          settlementDateNum: nextSettlementDateNum,
          priceNum: monthlyBillingInfo?.total ?? 0,
        },
      ]

      /** 月額プランの翌々月請求金額（日割り分）を取得する */
      const requestData = getMonthlyPlanNextProRatedPriceParam(
        effectiveOrgContract?.startDate,
        effectiveOrgContract?.endDate,
        effectiveOrgContract?.contractPlanId,
      )
      const afterNextPriceResult = requestData
        ? await fetchMonthlyPlanPrice(contractInfoId, requestData)
        : null

      // 翌々月の支払い金額
      // 単純に日割り計算した請求金額のため、日割り料金が50円未満の場合は50円を請求金額として表示する
      const afterNextPaymentPrice = calculatePaymentPriceString(
        contractInfoPlanPrice.value?.charge?.total,
      )

      if (!afterNextPriceResult?.isSuccess || !afterNextPaymentPrice) {
        Logger.info(
          `usePaymentSchedule#displayPaymentScheduleOnCancel: Failed to fetch fetchMonthlyPlanPrice. contractInfoId: ${contractInfoId}`,
        )

        /**
         * 翌々月の請求金額（日割り分）を取得できない場合は翌月の請求金額のみ表示する
         * 更新済みの場合、日本などUTC基準で時差が進んでいる国で初月の月末のみ利用時に請求予定0円の請求情報が作成される
         * そのため、請求予定0円のデータを表示対象外とする
         */
        return monthlyPaymentSchedule.value?.filter((schedule) => schedule.priceNum !== 0)
      }

      // プラン変更手続き翌月の請求対象期間開始日 UnixTime(ミリ秒)
      const nextBillingStartDateNum = dayjs(now).add(1, 'M').startOf('month').valueOf()
      /**
       * 翌々月の決済日（タイトル表示用）
       * UTC基準で月初の0時を取得
       */
      const afterNextSettlementDateForTitle = getDisplayDate(
        dayjs.tz(nextSettlementDateNum).add(1, 'M').startOf('month').valueOf(),
        'M/D',
        'MMM D',
      )
      /**
       * 翌々月の決済日
       * UTC基準で月初の0時を取得
       */
      const afterNextSettlementDateNum = dayjs
        .tz(nextSettlementDateNum)
        .add(1, 'M')
        .startOf('month')
        .valueOf()

      const afterNextSettlementDate = getDisplayDate(afterNextSettlementDateNum)
      // プラン変更手続き翌月の請求対象期間開始日
      const nextBillingStartDate = getDisplayDate(nextBillingStartDateNum)
      // プラン変更手続き翌月の請求対象期間終了日
      const nextBillingEndDate = getDisplayDate(dayjs(contractTerm.value.contractEndDate).valueOf())

      monthlyPaymentSchedule.value?.push({
        title: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.title', {
          settlementDate: afterNextSettlementDateForTitle,
          plan: planName,
        }).toString(),
        period: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.period', {
          startDate: nextBillingStartDate,
          endDate: nextBillingEndDate,
        }).toString(),
        price: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.price', {
          price: afterNextPaymentPrice,
        }).toString(),
        description: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.description', {
          debitDate: afterNextSettlementDate,
        }).toString(),
        note: '',
        settlementDateNum: afterNextSettlementDateNum,
      })

      /**
       * 更新済みの場合、日本などUTC基準で時差が進んでいる国で初月の月末のみ利用時に請求予定0円の請求情報が作成される
       * そのため、請求予定0円のデータを表示対象外とする
       */
      return monthlyPaymentSchedule.value?.filter((schedule) => schedule.priceNum !== 0)
    }

    /**
     * まだ更新していない場合
     */
    // プラン変更手続き時点の月の請求対象期間開始日
    billingStartDate = getDisplayDate(dayjs(now).startOf('month').valueOf())
    // プラン変更手続き時点の月の請求対象期間終了日
    billingEndDate = getDisplayDate(dayjs(contractTerm.value.contractEndDate).valueOf())

    monthlyPaymentSchedule.value = [
      {
        title: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.title', {
          settlementDate: nextSettlementDateForTitle,
          plan: planName,
        }).toString(),
        period: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.period', {
          startDate: billingStartDate,
          endDate: billingEndDate,
        }).toString(),
        price: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.price', {
          price: nextPaymentPrice,
        }).toString(),
        description: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.description', {
          debitDate: nextSettlementDate,
        }).toString(),
        note: '',
        settlementDateNum: nextSettlementDateNum,
      },
    ]

    return monthlyPaymentSchedule.value
  }

  /**
   * 年額プランの今後の請求予定
   * 支払いプランを切り替える画面（有料→有料）で月額プランから切り替えた場合に使用する
   */
  const getAnnualPaymentSchedule = async (
    contractInfoId: string,
    annualBillingInfo: BillingInfoDocument | undefined,
  ) => {
    // 年額プランの決済日（タイトル表示用）
    const nextSettlementDateForTitle = getDisplayDate(
      annualBillingInfo?.billingDate,
      'M/D',
      'MMM D',
    )
    // 年額プランの決済日
    const nextSettlementDateNum = annualBillingInfo?.billingDate ?? 0
    const nextSettlementDate = getDisplayDate(nextSettlementDateNum)
    // 次の支払い金額
    const nextPaymentPrice = annualBillingInfo?.total
      ? annualBillingInfo.total.toLocaleString()
      : ''

    // 年額プランの請求情報の場合、年額プランのcontractPlanIdを取得できる想定
    const annualBillingInfoPlan = annualBillingInfo?.plans?.find((v) => !!v?.price && v.price > 0)
    // プラン名を取得
    const planName = annualBillingInfoPlan?.contractPlanId
      ? getTargetContractPlan(annualBillingInfoPlan?.contractPlanId)?.name[I18n.locale] ?? ''
      : ''

    return [
      {
        title: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.title', {
          settlementDate: nextSettlementDateForTitle,
          plan: planName,
        }).toString(),
        period: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.period', {
          startDate: getDisplayDate(annualBillingInfoPlan?.startDate),
          endDate: getDisplayDate(annualBillingInfoPlan?.endDate),
        }).toString(),
        price: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.price', {
          price: nextPaymentPrice,
        }).toString(),
        description: I18n.t('PaidPlanChange.CompletePage.changeToAnnualPlan.card.description', {
          debitDate: nextSettlementDate,
        }).toString(),
        note: '',
        settlementDateNum: nextSettlementDateNum,
      },
    ]
  }

  /**
   * 支払いプランを切り替える画面（有料→有料）に表示する次の請求予定情報を返す
   * 月額プラン→年額プランへの切り替え手続き
   */
  const displayPaymentScheduleOnChangeToAnnual = async (
    hasRenewedMonthlyPlan: boolean,
    contractInfoId: string,
    annualBillingInfo: BillingInfoDocument | undefined,
    monthlyBillingInfo: BillingInfoDocument | undefined,
  ) => {
    /** 今後の請求情報 */
    const paymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)

    const monthlyPaymentScheduleData = await getMonthlyPaymentSchedule(
      hasRenewedMonthlyPlan,
      contractInfoId,
      monthlyBillingInfo,
    )
    if (monthlyPaymentScheduleData && monthlyPaymentScheduleData.length > 0) {
      paymentSchedule.value = monthlyPaymentScheduleData
    }

    const annualPaymentScheduleData = await getAnnualPaymentSchedule(
      contractInfoId,
      annualBillingInfo,
    )

    if (annualPaymentScheduleData && annualPaymentScheduleData.length > 0) {
      if (paymentSchedule.value && paymentSchedule.value.length > 0) {
        paymentSchedule.value = paymentSchedule.value?.concat(annualPaymentScheduleData)
      } else {
        paymentSchedule.value = annualPaymentScheduleData
      }
    }

    return orderBy(paymentSchedule.value, 'settlementDateNum')
  }

  /**
   * 支払いプランを切り替える画面（有料→有料）に表示する次の請求予定情報を返す
   * 年額プラン→月額プランへの切り替え手続き
   */
  const displayPaymentScheduleOnChangeToMonthly = async (
    contractInfoId: string,
    monthlyPlanPrice: number,
  ) => {
    /** 今後の請求情報 */
    const paymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)

    /** 月額プランの初回請求情報（日割り分）を取得する */
    const firstPaymentInfo = await getFirstPaymentInfo(futureContactInfoPlan.value, contractInfoId)

    if (!firstPaymentInfo) {
      // 初回の支払い金額を取得できなかった場合は請求予定を表示しない
      return null
    }

    /**
     * 初月の請求情報を作成
     */
    // 初月の決済日
    const firstSettlementDate = getDisplayDate(firstPaymentInfo.settlementDate)
    // 初月の請求対象期間開始日
    const firstBillingStartDate = getDisplayDate(firstPaymentInfo.billingStartDate)
    // 初月の請求対象期間終了日
    const firstBillingEndDate = getDisplayDate(firstPaymentInfo.billingEndDate)

    // 初月の請求情報
    paymentSchedule.value = [
      {
        title: I18n.tc('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.title1'),
        period: I18n.t('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.period', {
          startDate: firstBillingStartDate,
          endDate: firstBillingEndDate,
        }).toString(),
        price: I18n.t('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.price', {
          price: firstPaymentInfo.price,
        }).toString(),
        description: I18n.t('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.description1', {
          debitDate: firstSettlementDate,
        }).toString(),
        note: '',
      },
    ]

    /**
     * 2ヶ月目以降の請求情報を作成
     */
    // 2ヶ月目の決済日
    // UTC基準で月初の0時を取得
    const secondSettlementDate = getDisplayDate(
      dayjs.tz(firstPaymentInfo.settlementDate).add(1, 'M').startOf('month').valueOf(),
    )

    // 2ヶ月目以降の請求情報
    paymentSchedule.value?.push({
      title: I18n.tc('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.title2'),
      period: '',
      price: I18n.t('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.price', {
        price: monthlyPlanPrice.toLocaleString(),
      }).toString(),
      description: I18n.t('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.description2', {
        debitDate: secondSettlementDate,
      }).toString(),
      note: I18n.tc('PaidPlanChange.CompletePage.changeToMonthlyPlan.card.note'),
    })

    return paymentSchedule.value
  }

  /**
   * 支払いプランを切り替える画面（有料→有料）に表示する次の請求予定情報を返す
   * @param hasRenewedMonthlyPlan 月額プランの契約を更新済みかどうか
   *  月額プラン契約中の場合に必要となるもの
   */
  const getPaymentScheduleOnChange = async (hasRenewedMonthlyPlan = false) => {
    await Promise.all([fetchContractInfo(), fetchContractPlans()])

    const { contractInfoId } = ownContractInfo.value

    if (!contractInfoId) {
      return null
    }

    /** 契約情報契約プラン情報を取得 */
    await fetchContractInfoPlans(contractInfoId)

    if (futurePlan.value?.planGroupId === 'annualPlan') {
      await fetchProvisionalBillingInfo()
    }

    if (futurePlan.value?.planGroupId === 'annualPlan') {
      // 年額プランへの切り替え手続きを行った場合
      return displayPaymentScheduleOnChangeToAnnual(
        hasRenewedMonthlyPlan,
        contractInfoId,
        ownAnnualBillingInfo.value,
        ownMonthlyBillingInfo.value,
      )
    }
    if (futurePlan.value?.planGroupId === 'monthlyPlan') {
      // 月額プランへの切り替え手続きを行った場合
      // 1ヶ月以上前に切り替え手続きを行った場合、請求予定情報が取れないため、月額プランの基本料金から日割り料金を計算して請求予定を表示する
      return displayPaymentScheduleOnChangeToMonthly(contractInfoId, monthlyPlanPriceJpy.value)
    }

    // 上記以外
    return null
  }

  return {
    getPaymentScheduleOnChange,
  }
}
