


















import { defineComponent, PropType } from '@vue/composition-api'
import { NotificationItemType } from '@/components/NotificationPane/NotificationPane.vue'
import NotificationItemContentsParts from '@/components/NotificationPane/NotificationPane/parts/NotificationItemContentsParts.vue'
import HighlightNotificationItemContentsParts from '@/components/NotificationPane/NotificationPane/parts/HighlightNotificationItemContentsParts.vue'

/**
 * Notification 詳細アイテム
 */
export default defineComponent({
  name: 'NotificationItemParts',
  components: {
    NotificationItemContentsParts,
    HighlightNotificationItemContentsParts,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItemType>,
      required: true,
    },
  },
})
