var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-top-page" },
    [
      _c("membership-card-pane", {
        attrs: {
          enabledListCarousel: _vm.enabledListCarousel,
          isEditCardForHighlightComment: _vm.isEditCardForHighlightComment,
        },
      }),
      _c("mypage-top-pane", {
        staticClass: "mypage-top-page__content",
        on: { "error-move": _vm.moveToLogin },
      }),
      _c("global-navigation-pane", {
        staticClass: "mypage-top-page__navigation",
        attrs: { current: "mypage" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }