import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import OAuth3LeggedFlowBrowser, {
  OAuthRequestAccessTokenResponse,
} from '@/util/inAppBrowser/OAuth3LeggedFlowBrowser'
import Logger from '@/util/logger/Logger'
import { XAuthorizationType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import StoreUtil from '@/store/StoreUtil'

export default function useXAuthentication() {
  /**
   * xの認可処理を行う
   */
  const xAuthentication = (): Promise<boolean> => {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { ownOrganization, updateOrganization } = contractInfoStore

    return new Promise((resolve) => {
      // SNS動画投稿機能の動作確認用の処理
      const oauthCodeFlow = new OAuth3LeggedFlowBrowser(
        'https://api.twitter.com/oauth/request_token',
        'https://api.twitter.com/oauth/authorize',
        'https://api.twitter.com/oauth/access_token',
        process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL,
        process.env.VUE_APP_X_OAUTH_API_KEY,
        process.env.VUE_APP_X_OAUTH_API_SECRET,
      )
      try {
        // OAuth 1.0a 認可フローを開始する
        oauthCodeFlow.startOAuthFlow(async (returnValue) => {
          Logger.debug(`Finish OAuth Flow. result: ${JSON.stringify(returnValue)}`)
          const response = returnValue.result as OAuthRequestAccessTokenResponse
          if (!response) {
            resolve(false)
          } else {
            // xのトークンとシークレットトークンを組織情報に保存する
            const newOrgData = cloneDeep(ownOrganization.value)
            if (!newOrgData) {
              resolve(false)
              return
            }
            const xAuthorization: XAuthorizationType = {
              oauthToken: response.oauth_token,
              oauthTokenSecret: response.oauth_token_secret,
              acquisitionDate: dayjs().valueOf(),
            }
            if (!newOrgData.additionalData) newOrgData.additionalData = {}
            if (!newOrgData.additionalData.postSns) {
              newOrgData.additionalData.postSns = {
                x: xAuthorization,
              }
            } else {
              newOrgData.additionalData.postSns = {
                ...newOrgData.additionalData.postSns,
                x: xAuthorization,
              }
            }
            await updateOrganization(newOrgData)
            resolve(true)
          }
        })
      } catch (e) {
        Logger.info(`Failed to OAuth flow. e: ${JSON.stringify(e)}`)
        resolve(false)
      }
    })
  }

  return { xAuthentication }
}
