















import { computed, defineComponent } from '@vue/composition-api'
import SelectListSection from '@/components/common/SelectList/SelectListSection.vue'

/**
 * マイページ: 言語選択リスト セクション
 */
export default defineComponent({
  name: 'LanguageSelectListSection',
  components: {
    SelectListSection,
  },
  props: {
    /**
     * 表示データ
     */
    listData: {
      type: Array,
      required: true,
    },
    /**
     * 選択済みフラグ
     */
    selectedID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    /**
     * 選択済みを監視
     */
    const selectedItemID = computed(() => props.selectedID)
    return {
      selectedItemID,
    }
  },
  methods: {
    /**
     * 選択を通知
     * @param selectedItemID : string
     */
    changeSelected(selectedItemID: string): void {
      this.$emit('selectChange', selectedItemID)
    },
  },
})
