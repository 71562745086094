var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "telemetry-ots-parts",
      class: [
        { "telemetry-ots-parts--running-low": _vm.value < 20 },
        { "telemetry-ots-parts--enabled": _vm.otsEnabled && _vm.value > 0 },
        { "telemetry-ots-parts--disabled": _vm.otsDisabled },
      ],
    },
    [
      _vm.otsEnabled && _vm.value > 0
        ? _c(
            "svg",
            {
              staticClass: "telemetry-ots-parts__gauge__enabled__blink-light",
              attrs: {
                width: "215",
                height: "442",
                viewBox: "0 0 215 442",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c(
                "defs",
                [
                  _c(
                    "radialGradient",
                    {
                      attrs: {
                        id: "paint0_radial_808_13914",
                        cx: "0",
                        cy: "0",
                        r: "1",
                        gradientUnits: "userSpaceOnUse",
                        gradientTransform:
                          "translate(107.5 221) rotate(90) scale(221 107.5)",
                      },
                    },
                    [
                      _c("stop", { attrs: { "stop-color": "#00FF47" } }),
                      _c("stop", {
                        attrs: {
                          offset: "1",
                          "stop-color": "#00FF47",
                          "stop-opacity": "0",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("rect", {
                attrs: {
                  width: "215",
                  height: "442",
                  fill: "url(#paint0_radial_808_13914)",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "telemetry-ots-parts__header" }, [
        _c("p", { staticClass: "telemetry-ots-parts__data" }, [
          _c("span", { staticClass: "telemetry-ots-parts__number" }, [
            _vm._v(_vm._s(_vm.value)),
          ]),
          _vm._m(0),
        ]),
        _vm._m(1),
      ]),
      _c(
        "svg",
        {
          staticClass: "telemetry-ots-parts__gauge",
          attrs: {
            width: "144",
            height: "25",
            viewBox: "0 0 144 25",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c(
            "clipPath",
            { attrs: { id: "telemetry-ots-parts__gauge__clip" } },
            [
              _c("path", {
                attrs: {
                  d: "M0 0H121.343C122.404 0 123.421 0.421427 124.172 1.17157L133 10H13.3137C11.192 10 9.15715 9.15715 7.65685 7.65685L0 0Z",
                  fill: "white",
                },
              }),
            ]
          ),
          _c(
            "mask",
            {
              staticStyle: { "mask-type": "alpha" },
              attrs: {
                id: "telemetry-ots-parts__gauge__clip2",
                maskUnits: "userSpaceOnUse",
                width: "160",
                height: "14",
              },
            },
            [
              _c("rect", {
                attrs: {
                  width: _vm.gaugeWidth,
                  height: "10",
                  x: "0",
                  y: "0",
                  transform: "skewX(45)",
                  fill: "white",
                },
              }),
            ]
          ),
          _c(
            "clipPath",
            { attrs: { id: "telemetry-ots-parts__gauge__enabled-clip" } },
            [
              _c("path", {
                attrs: {
                  fill: "white",
                  d: "M0,0l121.4,0.1c1.1,0,2.1,0.4,2.8,1.2l17,16.9H21.5c-2.1,0-4.2-0.8-5.7-2.3L0,0z",
                },
              }),
            ]
          ),
          _c(
            "mask",
            {
              staticStyle: { "mask-type": "alpha" },
              attrs: {
                id: "gaugeReflectBarMask",
                maskUnits: "userSpaceOnUse",
                x: "0",
                y: "0",
                width: "256",
                height: "10",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 0H121.343C122.404 0 123.421 0.421427 124.172 1.17157L133 10H13.3137C11.192 10 9.15715 9.15715 7.65685 7.65685L0 0Z",
                  fill: "url(#gaugeReflectMaskGradient)",
                },
              }),
            ]
          ),
          _c(
            "defs",
            [
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "paint0_linear_804_13913",
                    x1: "2",
                    y1: "0",
                    x2: "21.5",
                    y2: "2.01711e-07",
                    gradientUnits: "userSpaceOnUse",
                  },
                },
                [
                  _c("stop", { attrs: { "stop-color": "#A2FFE8" } }),
                  _c("stop", {
                    attrs: {
                      offset: "1",
                      "stop-color": "#A1FFE8",
                      "stop-opacity": "0",
                    },
                  }),
                ],
                1
              ),
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "paint1_linear_804_13913",
                    x1: "2.5",
                    y1: "10.5",
                    x2: "21.5",
                    y2: "1.20132e-06",
                    gradientUnits: "userSpaceOnUse",
                  },
                },
                [
                  _c("stop", { attrs: { "stop-color": "white" } }),
                  _c("stop", {
                    attrs: {
                      offset: "1",
                      "stop-color": "white",
                      "stop-opacity": "0",
                    },
                  }),
                ],
                1
              ),
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "paint0_linear_803_13886",
                    x1: "-18.6667",
                    y1: "12",
                    x2: "-18.6261",
                    y2: "14.5104",
                    gradientUnits: "userSpaceOnUse",
                  },
                },
                [
                  _c("stop", { attrs: { "stop-color": "white" } }),
                  _c("stop", {
                    attrs: {
                      offset: "1",
                      "stop-color": "#98FFE6",
                      "stop-opacity": "0.4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "filter",
                {
                  attrs: {
                    id: "filter0_f_804_13902",
                    x: "0",
                    y: "0",
                    width: "42",
                    height: "32",
                    filterUnits: "userSpaceOnUse",
                    "color-interpolation-filters": "sRGB",
                  },
                },
                [
                  _c("feFlood", {
                    attrs: {
                      "flood-opacity": "0",
                      result: "BackgroundImageFix",
                    },
                  }),
                  _c("feBlend", {
                    attrs: {
                      mode: "normal",
                      in: "SourceGraphic",
                      in2: "BackgroundImageFix",
                      result: "shape",
                    },
                  }),
                  _c("feGaussianBlur", {
                    attrs: {
                      stdDeviation: "4",
                      result: "effect1_foregroundBlur_804_13902",
                    },
                  }),
                ],
                1
              ),
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "paint0_linear_804_13902",
                    x1: "10",
                    y1: "4",
                    x2: "42",
                    y2: "34",
                    gradientUnits: "userSpaceOnUse",
                  },
                },
                [
                  _c("stop", {
                    attrs: { "stop-color": "#00FFC1", "stop-opacity": "0.4" },
                  }),
                  _c("stop", {
                    attrs: { offset: "0.526042", "stop-color": "#00FFC1" },
                  }),
                  _c("stop", {
                    attrs: {
                      offset: "1",
                      "stop-color": "#00FFC1",
                      "stop-opacity": "0.4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "gaugeReflectMaskGradient",
                    x1: "128",
                    y1: "0",
                    x2: "128",
                    y2: "10",
                    gradientUnits: "userSpaceOnUse",
                  },
                },
                [
                  _c("stop", { attrs: { "stop-opacity": "0" } }),
                  _c("stop", { attrs: { offset: "0.630208" } }),
                ],
                1
              ),
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "gaugeReflectGradient",
                    x1: "0",
                    y1: "10",
                    x2: "246",
                    y2: "10",
                    gradientUnits: "userSpaceOnUse",
                    spreadMethod: "reflect",
                  },
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "x1",
                      dur: "2s",
                      from: "-100%",
                      to: "100%",
                      repeatCount: "indefinite",
                    },
                  }),
                  _c("animate", {
                    attrs: {
                      attributeName: "x2",
                      dur: "2s",
                      from: "0%",
                      to: "200%",
                      repeatCount: "indefinite",
                    },
                  }),
                  _c("stop", {
                    attrs: { offset: "0", "stop-color": "#00FFBA" },
                  }),
                  _c("stop", {
                    attrs: {
                      offset: "0.25",
                      "stop-color": "#00FFBA",
                      "stop-opacity": "0.4",
                    },
                  }),
                  _c("stop", {
                    attrs: { offset: "0.50", "stop-color": "#00FFBA" },
                  }),
                  _c("stop", {
                    attrs: {
                      offset: "0.75",
                      "stop-color": "#00FFBA",
                      "stop-opacity": "0.4",
                    },
                  }),
                  _c("stop", {
                    attrs: { offset: "1", "stop-color": "#00FFBA" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("path", {
            attrs: {
              opacity: "0.2",
              d: "M7 0H128.343C129.404 0 130.421 0.421427 131.172 1.17157L140 10H20.3137C18.192 10 16.1571 9.15715 14.6569 7.65685L7 0Z",
              fill: _vm.fillColor,
            },
          }),
          _c("path", {
            attrs: {
              opacity: "0.3",
              d: "M143 13H19.1421C16.49 13 13.9464 11.9464 12.0711 10.0711L2 0H0L11.364 11.364C13.0518 13.0518 15.341 14 17.7279 14H144L143 13Z",
              fill: _vm.fillColor,
            },
          }),
          _c(
            "g",
            {
              attrs: {
                "clip-path": "url(#telemetry-ots-parts__gauge__clip)",
                transform: "translate(7,0)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    mask: "url(#telemetry-ots-parts__gauge__clip2)",
                    transform: "translate(" + (_vm.gaugePositionX - 7) + ", 0)",
                  },
                },
                [
                  !_vm.otsEnabled
                    ? _c("rect", {
                        attrs: {
                          width: "123",
                          height: "10",
                          x: "0",
                          y: "0",
                          fill: _vm.fillColor,
                          transform: "skewX(45)",
                          opacity: "0.8",
                        },
                      })
                    : _c("rect", {
                        staticClass:
                          "telemetry-ots-parts__gauge__enabled__animation",
                        attrs: {
                          width: "123",
                          height: "10",
                          x: "0",
                          y: "0",
                          transform: "skewX(45)",
                          fill: "url(#gaugeReflectGradient)",
                        },
                      }),
                ]
              ),
            ]
          ),
          _vm.otsEnabled && _vm.value > 0
            ? _c(
                "g",
                {
                  staticClass:
                    "telemetry-ots-parts__gauge__enabled__left-shadow",
                  attrs: {
                    filter: "url(#filter0_f_804_13902)",
                    transform:
                      "translate(" + (_vm.gaugePositionX - 16) + ", -13)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M8 8H14.6863C16.808 8 18.8429 8.84285 20.3431 10.3431L34 24H27.3137C25.192 24 23.1571 23.1571 21.6569 21.6569L8 8Z",
                      fill: "url(#paint0_linear_804_13902)",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm.otsEnabled && _vm.value > 0
            ? _c(
                "g",
                {
                  attrs: {
                    transform: "translate(-0.6,-8.2)",
                    "clip-path":
                      "url(#telemetry-ots-parts__gauge__enabled-clip)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      transform:
                        "translate(" + (_vm.gaugePositionX - 1) + ", 6.2)",
                      d: "M4.17157 1.17157C3.42143 0.421427 2.40401 0 1.34315 0H0L10.8284 10.8284C11.5786 11.5786 12.596 12 13.6569 12H136.5L135.5 11H14.8284C14.298 11 13.7893 10.7893 13.4142 10.4142L4.17157 1.17157Z",
                      fill: "url(#paint0_linear_803_13886)",
                    },
                  }),
                  _c(
                    "g",
                    {
                      staticClass:
                        "telemetry-ots-parts__gauge__enabled__left-gradient",
                      attrs: {
                        transform:
                          "translate(" + (_vm.gaugePositionX + 0.8) + ",8.2)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0 0H19.6863C21.808 0 23.8429 0.842855 25.3431 2.34315L33 10H13.3137C11.192 10 9.15715 9.15715 7.65685 7.65685L0 0Z",
                          fill: "url(#paint0_linear_804_13913)",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M0 0H19.6863C21.808 0 23.8429 0.842855 25.3431 2.34315L33 10H13.3137C11.192 10 9.15715 9.15715 7.65685 7.65685L0 0Z",
                          fill: "url(#paint1_linear_804_13913)",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M0 0H19.6863C21.808 0 23.8429 0.842855 25.3431 2.34315L33 10H13.3137C11.192 10 9.15715 9.15715 7.65685 7.65685L0 0Z",
                          fill: "url(#paint1_linear_804_13913)",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.otsEnabled && _vm.value > 0
            ? _c(
                "g",
                {
                  attrs: {
                    mask: "url(#telemetry-ots-parts__gauge__clip)",
                    transform: "scale(1,-1) translate(8,-27)",
                    opacity: "0.5",
                  },
                },
                [
                  _c("g", { attrs: { mask: "url(#gaugeReflectBarMask)" } }, [
                    _c(
                      "g",
                      {
                        attrs: {
                          mask: "url(#telemetry-ots-parts__gauge__clip2)",
                          transform:
                            "translate(" + (_vm.gaugePositionX - 7) + ",0)",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: {
                            width: "123",
                            height: "10",
                            x: "0",
                            y: "0",
                            transform: "skewX(45)",
                            fill: "url(#gaugeReflectGradient)",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.otsDisabled
        ? _c("p", { staticClass: "telemetry-ots-parts__disabled-time" }, [
            _c(
              "svg",
              {
                staticClass: "telemetry-ots-parts__disabled-time__icon",
                attrs: {
                  width: "18",
                  height: "18",
                  viewBox: "0 0 18 18",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M14.5 9C14.5 12.0376 12.0376 14.5 9 14.5C7.75165 14.5 6.60043 14.0841 5.67736 13.3833L13.3833 5.67736C14.0841 6.60043 14.5 7.75165 14.5 9ZM4.6167 12.3226L12.3226 4.6167C11.3996 3.9159 10.2484 3.5 9 3.5C5.96243 3.5 3.5 5.96243 3.5 9C3.5 10.2484 3.9159 11.3996 4.6167 12.3226ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9Z",
                    fill: "#FFB800",
                  },
                }),
              ]
            ),
            _c(
              "span",
              { staticClass: "telemetry-ots-parts__disabled-time__data" },
              [
                _c("span", { staticClass: "telemetry-ots-parts__number" }, [
                  _vm._v(_vm._s(_vm.otsDisabledTime)),
                ]),
                _vm._m(2),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("sup", { staticClass: "telemetry-ots-parts__unit" }, [
      _c("span", [_vm._v("s")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "telemetry-ots-parts__label" }, [
      _c("span", [_vm._v("OTS")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("sup", { staticClass: "telemetry-ots-parts__unit" }, [
      _c("span", [_vm._v("s")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }