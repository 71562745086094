import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

type InAppPurchaseHistoryStatusType = 'InProgress' | 'Complete' | 'Error'

/**
 * アプリ内課金通知履歴を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3806625934/API
 */
export default class InAppPurchaseHistoryDocument extends DocumentWrapper {
  constructor(initProps?: Partial<InAppPurchaseHistoryDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'history/in_app_purchase'

  idAttr = 'inAppPurchaseId'

  /**
   * アプリ内課金通知履歴ID
   */
  inAppPurchaseId: string | null = null

  /**
   * アプリ内課金処理ステータス
   */
  status: InAppPurchaseHistoryStatusType | null = null
}
