var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-country-parts" },
    [
      _c("label", { attrs: { for: "fieldset-country" } }, [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.country"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("atom-input-select-object", {
        staticClass: "login-inputs-parts__country-field",
        attrs: {
          id: "fieldset-country",
          placeholder: _vm.$t("formParts.placeholder.country"),
          error: _vm.showError,
          options: _vm.countryData,
        },
        on: { enter: _vm.emitEnter, change: _vm.change },
        model: {
          value: _vm.country,
          callback: function ($$v) {
            _vm.country = $$v
          },
          expression: "country",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showError,
          message:
            _vm.errorMessage !== ""
              ? _vm.errorMessage
              : _vm.$t("formParts.errorMessage.country"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }