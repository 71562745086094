var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlight-item-section",
      class: "highlight-item-section--" + _vm.screenOrientationType,
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        {
          staticClass: "highlight-item-section__race-angle",
          class: {
            "highlight-item-section__race-angle--current":
              _vm.highlightData.highlightId === _vm.selectedHighlightId &&
              !_vm.selectedPlayerMasterId,
          },
          on: {
            click: function ($event) {
              return _vm.changeScene(_vm.highlightData)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "highlight-item-section__header" },
            [
              _vm.selectedHighlightTab === "official"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "highlight-item-section__header__title-label",
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.playEventName))])]
                  )
                : [
                    _c(
                      "figure",
                      {
                        staticClass:
                          "highlight-item-section__header__user-image",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.highlightCreateUser.userPicture,
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "highlight-item-section__header__user-name",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.highlightCreateUser.displayName) +
                            " "
                        ),
                      ]
                    ),
                  ],
              _c(
                "div",
                { staticClass: "highlight-item-section__header__information" },
                [
                  _vm.selectedHighlightTab === "user"
                    ? _c(
                        "p",
                        {
                          staticClass: "highlight-item-section__header__type",
                          class: ["" + _vm.highlightCreateUser.userType],
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "highlight-item-section__header__type-label",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.roleLabels[
                                    _vm.highlightCreateUser.userType
                                  ]
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "highlight-item-section__header__time" },
                    [_vm._v(_vm._s(_vm.highlight.raceTime))]
                  ),
                ]
              ),
            ],
            2
          ),
          _c(
            "figure",
            {
              staticClass: "highlight-item-section__thumbnail",
              class: {
                "highlight-item-section__thumbnail--blank":
                  !_vm.highlight.thumbnailUri,
              },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.highlight.thumbnailUri
                    ? _vm.highlightThumbnailUri
                    : require("@/assets/img/icon/icon_play__gray.svg"),
                  alt: "",
                },
              }),
              _vm.isPc
                ? [
                    _vm.isInProgress
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "highlight-item-section__thumbnail-status",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$tc(
                                    "RaceVideoPage.highlights.sns.xAndInstagram.preparing"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isError
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "highlight-item-section__thumbnail-error",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$tc(
                                    "RaceVideoPage.highlights.sns.xAndInstagram.error"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isComplete
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "highlight-item-section__thumbnail-button-download",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleDownloadClicked.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tc(
                                    "RaceVideoPage.highlights.sns.xAndInstagram.download"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "18",
                                  height: "19",
                                  viewBox: "0 0 18 19",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M1.75 10.0908V17.6184H16.25V10.0908H14.75V16.1184H3.25V10.0908H1.75Z",
                                    fill: "#00DBA6",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M11.8297 8.08257L9.74838 10.1639L9.74792 2.09082H8.24792L8.24838 10.1581L6.17282 8.08257H4.0515L8.29414 12.3252C8.68467 12.7157 9.31783 12.7157 9.70836 12.3252L13.951 8.08257H11.8297Z",
                                    fill: "#00DBA6",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._l(_vm.highlight.playerMasterIds, function (v, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "highlight-item-section__player-angle",
            class: {
              "highlight-item-section__player-angle--current":
                v === _vm.selectedPlayerMasterId &&
                _vm.highlightData.id === _vm.selectedHighlightId,
            },
            on: {
              click: function ($event) {
                return _vm.changeScenePlayer(v)
              },
            },
          },
          [
            _c(
              "button",
              { staticClass: "highlight-item-section__player-angle__button" },
              [
                _c("img", {
                  attrs: {
                    src:
                      v === _vm.selectedPlayerMasterId &&
                      _vm.highlightData.id === _vm.selectedHighlightId
                        ? require("@/assets/img/icon/icon_video__black.svg")
                        : require("@/assets/img/icon/icon_video__white.svg"),
                    alt: "",
                  },
                }),
                _c(
                  "span",
                  { staticClass: "highlight-item-section__player-angle__name" },
                  [_vm._v(_vm._s(_vm.playerName(v)))]
                ),
              ]
            ),
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "highlight-item-section__parent-comment" },
        [
          !_vm.enabledEditHighlight
            ? _c("p", {
                staticClass: "highlight-item-section__parent-comment__comment",
                domProps: { innerHTML: _vm._s(_vm.highlightComment.comment) },
              })
            : _vm._e(),
          !_vm.enabledEditHighlight
            ? _c(
                "div",
                {
                  staticClass: "highlight-item-section__parent-comment__footer",
                  class: {
                    "highlight-item-section__parent-comment__hidden-like-button":
                      !_vm.highlightData.isShared,
                  },
                },
                [
                  _vm.highlightData.isShared
                    ? _c("like-button-parts", {
                        attrs: { commentData: _vm.highlightComment },
                        on: { click: _vm.onChangeLike },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("comment-post-date-parts", {
                        attrs: { postDate: _vm.highlightComment.date },
                      }),
                      _vm.highlight._organization !== _vm.parentOrgId
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "highlight-item-section__parent-comment__controller-button",
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpenMenuClicked(
                                    _vm.highlightComment
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "16",
                                    height: "14",
                                    viewBox: "0 0 16 14",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M2.5 6.05C3.13 6.05 3.65 6.57 3.65 7.2C3.65 7.84 3.13 8.36 2.5 8.36C1.86 8.36 1.34 7.84 1.34 7.2C1.34 6.57 1.86 6.05 2.5 6.05ZM7.9 6.05C8.53 6.05 9.05 6.57 9.05 7.2C9.05 7.84 8.53 8.36 7.9 8.36C7.26 8.36 6.74 7.84 6.74 7.2C6.74 6.57 7.26 6.05 7.9 6.05ZM13.3 6.05C13.93 6.05 14.45 6.57 14.45 7.2C14.45 7.84 13.93 8.36 13.3 8.36C12.66 8.36 12.14 7.84 12.14 7.2C12.14 6.57 12.66 6.05 13.3 6.05Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.enabledEditHighlight
            ? _c("comment-editor-parts", {
                staticClass: "highlight-item-section__parent-comment__editor",
                attrs: {
                  screenOrientationType: _vm.screenOrientationType,
                  message: _vm.highlightComment.comment,
                },
                on: {
                  handlerCancel: function ($event) {
                    _vm.enabledConfirmModal = true
                  },
                  handlerSave: _vm.onSaveHighlightComment,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.highlightCommentCount > 0
        ? _c(
            "div",
            { staticClass: "highlight-item-section__posted-comments" },
            [
              _vm.visibleCommentList
                ? _c(
                    "div",
                    {
                      staticClass:
                        "highlight-item-section__posted-comments__list",
                    },
                    [
                      _vm._l(
                        _vm.highlightComments[0],
                        function (commentData, idx) {
                          return _c("highlight-comment-section", {
                            key: "HighlightCommentSection" + idx,
                            attrs: {
                              id: _vm.id + "Comment" + idx,
                              screenOrientationType: _vm.screenOrientationType,
                              commentData: commentData,
                              loginUserId: _vm.loginUserId,
                              showCommentInput: _vm.showCommentInput,
                              targetIsEditMode:
                                _vm.editModesMap[commentData.commentId],
                              retrieveNameUsersByUserId:
                                _vm.retrieveNameUsersByUserId,
                            },
                            on: {
                              handlerEdit: _vm.handlerEdit,
                              onShowDeleteConfirmModal:
                                _vm.onShowDeleteConfirmModal,
                              onToggleIsEditMode: function ($event) {
                                return _vm.onToggleIsEditMode(
                                  $event,
                                  commentData.commentId,
                                  _vm.id + "Comment" + idx
                                )
                              },
                              onChangeLike: _vm.onChangeLike,
                              onClickMenu: _vm.handleCommentMenuClicked,
                            },
                          })
                        }
                      ),
                      _vm.displayMoreCommentsButton
                        ? _c("atom-button", {
                            staticClass: "highlight-item-section__more-button",
                            attrs: {
                              linkText: _vm.$tc(
                                "RaceVideoPage.highlights.comment.more"
                              ),
                            },
                            on: { onClick: _vm.showMoreComment },
                          })
                        : _vm._e(),
                      _vm.isShowAllComment ||
                      _vm.displayNewOwnHighlightComments.length > 0
                        ? _vm._l(
                            _vm.displayMoreComments,
                            function (commentData, idx) {
                              return _c("highlight-comment-section", {
                                key: "HighlightCommentSection" + idx + "_more",
                                attrs: {
                                  id: _vm.id + "Comment" + idx,
                                  screenOrientationType:
                                    _vm.screenOrientationType,
                                  commentData: commentData,
                                  loginUserId: _vm.loginUserId,
                                  showCommentInput: _vm.showCommentInput,
                                  targetIsEditMode:
                                    _vm.editModesMap[commentData.commentId],
                                  retrieveNameUsersByUserId:
                                    _vm.retrieveNameUsersByUserId,
                                },
                                on: {
                                  handlerEdit: _vm.handlerEdit,
                                  onShowDeleteConfirmModal:
                                    _vm.onShowDeleteConfirmModal,
                                  onToggleIsEditMode: function ($event) {
                                    return _vm.onToggleIsEditMode(
                                      $event,
                                      commentData.commentId,
                                      _vm.id + "Comment" + idx
                                    )
                                  },
                                  onChangeLike: _vm.onChangeLike,
                                  onClickMenu: _vm.handleCommentMenuClicked,
                                },
                              })
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c("highlight-comment-toggle-parts", {
                attrs: {
                  isActive: _vm.visibleCommentList,
                  commentCounts: _vm.highlightCommentCount,
                },
                on: { onClickToggle: _vm.toggleComments },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showCommentInput
        ? _c(
            "div",
            {
              staticClass: "highlight-item-section__input-comment",
              attrs: { id: _vm.id + "PostInput" },
            },
            [
              _c("comment-input-section", {
                attrs: {
                  targetEnableInput: _vm.targetEnableInput,
                  targetInputHighlightComment: _vm.targetInputHighlightComment,
                },
                on: {
                  onToggleEnabledInput: function ($event) {
                    return _vm.onToggleEnabledInput(
                      $event,
                      _vm.highlightData.highlightId || ""
                    )
                  },
                  onChangeHighlightComment: function ($event) {
                    return _vm.onChangeHighlightComment(
                      $event,
                      _vm.highlightData.highlightId || ""
                    )
                  },
                  handlerSubmit: function ($event) {
                    return _vm.handlerSubmit(_vm.highlightData)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.enabledConfirmModal
        ? _c("confirm-modal-section", {
            staticClass: "highlight-item-section__modal",
            attrs: {
              message: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.editCancelMessage"
              ),
              confirmButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.confirmButtonLabel"
              ),
              cancelButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.cancelButtonLabel"
              ),
            },
            on: {
              confirm: _vm.onCancelHighlightCommentInput,
              cancel: function ($event) {
                _vm.enabledConfirmModal = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }