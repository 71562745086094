


























import { defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import useMailAddressChangeToken from '@/store/hook/useMailAddressChangeToken'

export type DataType = {
  email: string
  showEMailError: boolean
  errorMessage: string
}

/**
 * マイページ: 会員情報変更 メールアドレス変更モーダル
 */
export default defineComponent({
  name: 'EmailChangeModalSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    FieldsetEmailParts,
    AtomInputButton,
  },
  setup() {
    const {
      checkEmailAddressDuplicated: _checkEmailAddressDuplicated,
      emailAddressChangeToken: _emailAddressChangeToken,
    } = useMailAddressChangeToken()

    return {
      _checkEmailAddressDuplicated,
      _emailAddressChangeToken,
    }
  },
  data(): DataType {
    return {
      email: '',
      showEMailError: false,
      errorMessage: '',
    }
  },
  methods: {
    emitClose(): void {
      /**
       * モーダル閉じる通知
       */
      this.$emit('close')
    },
    async changeEmail(submitType: string) {
      this.showEMailError = false
      this.errorMessage = ''
      /**
       * 認証メール送信処理 → 完了通知
       */
      if (submitType === 'submit') {
        /**
         * メールアドレスのバリデーション チェック
         */
        const regex = new RegExp(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        )
        if (!regex.test(this.email)) {
          this.errorMessage = this.$t(
            'MypagePage.MypageUserEditPage.mailSendMissUsedError',
          ) as string
          this.showEMailError = true
        }
        if (!this.showEMailError) {
          const loader = this.$loading.show()
          const apiResult = await this._emailAddressChangeToken(this.email)
          if (apiResult.isSuccess) {
            /**
             * 完了通知
             */
            this.$emit('close', 'complete')
          } else {
            /**
             * エラー通知
             */
            this.$emit('error')
          }
          loader.hide()
        }
      } else if (submitType === 'cancel') {
        this.$emit('close', 'cancel')
      }
    },
  },
})
