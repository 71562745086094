




















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import OneTimePassInputPane from '@/components/MypageOneTimePassInputPage/OneTimePassInputPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * マイページ: ワンタイムパス: 入力ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassInputPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    OneTimePassInputPane,
    ActionButtonsSection,
    AtomRouterButton,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { fetchContractPlans } = oneTimePassPageStore

    const fetch = async () => {
      /** SFgo組織の契約プラン情報を取得する（ログイン状態であることが前提） */
      await fetchContractPlans()
    }
    fetch()
  },
  methods: {
    /**
     * ワンタイムパスの登録確認画面へ遷移
     */
    moveToOneTimePassConfirm() {
      this.$router.replace({ name: 'MypageOneTimePassConfirmPage' })
    },
  },
})
