import ImageUploadDocument from '@/store/stores/collectionModule/documents/imageUpload/ImageUploadDocument'
import { SaveOptions } from '../stores/collectionModule/CollectionTypes'
import FileModule from '@//store/stores/collectionModule/FileModule'

export default function useImageUpload() {
  const imageUploadFileModule = FileModule.createStore(ImageUploadDocument)
  /**
   * 画像をアップロード
   * @param { ImageUploadDocument } imageUploadDocument アップロードする画像情報
   * @param { File } file アップロードする画像のデータ
   * @param { SaveOptions } options アップロード時に指定するオプション
   */
  const upload = (imageUploadDocument: ImageUploadDocument, file: File, options?: SaveOptions) => {
    const formData = new FormData()
    const metaValue = new Blob([JSON.stringify(imageUploadDocument.getAPISchema())], {
      type: 'application/json',
    })

    formData.append('meta', metaValue)
    formData.append('media', file, file.name)
    return imageUploadFileModule.upload(formData, { ...options })
  }

  return {
    upload,
  }
}
