var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-zipcode-parts" },
    [
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.zipcode"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("atom-input-text", {
        attrs: {
          placeholder: _vm.$tc("formParts.placeholder.zipcode"),
          maxlength: 7,
          error: _vm.showError,
          isDisabled: _vm.isDisabled,
        },
        on: { enter: _vm.emitEnter },
        model: {
          value: _vm.zipcode,
          callback: function ($$v) {
            _vm.zipcode = $$v
          },
          expression: "zipcode",
        },
      }),
      _c("form-error-message-parts", {
        attrs: { showError: _vm.showError, message: _vm.errorMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }