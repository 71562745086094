












import { computed, defineComponent } from '@vue/composition-api'

/**
 * E-mailインプットフィールド
 */
export default defineComponent({
  name: 'AtomInputEMail',
  props: {
    /**
     * ID
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Value値
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * Error判定
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * プレースホルダー
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * リードオンリーフラグ
     */
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * インプットの値
     * @param {string} val インプットの値
     */
    const inputValue = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })

    return {
      inputValue,
    }
  },
  methods: {
    /**
     * エンターキーが押されたことを通知する
     */
    emitEnter() {
      /**
       * エンターキーが押されたことを通知する
       * @event enter
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
