



















import { computed, defineComponent } from '@vue/composition-api'
import TelemetryTireTemperatureFlParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryTireTemperatureFlParts.vue'
import TelemetryTireTemperatureFrParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryTireTemperatureFrParts.vue'
import TelemetryTireTemperatureRlParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryTireTemperatureRlParts.vue'
import TelemetryTireTemperatureRrParts from '@/components/RaceVideoPage/RaceVideoDataViewPane/parts/TelemetryTireTemperatureRrParts.vue'

/**
 * レース動画再生画面 テレメトリー タイヤ温度値セクションのコンポーネント
 */
export default defineComponent({
  name: 'TelemetryTireTemperatureSection',
  components: {
    TelemetryTireTemperatureFlParts,
    TelemetryTireTemperatureFrParts,
    TelemetryTireTemperatureRlParts,
    TelemetryTireTemperatureRrParts,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    diffMs: {
      type: Number,
      required: true,
    },
  },
  methods: {},
  setup(props) {
    const tireTemperature = computed(() => props.value)
    return {
      tireTemperature,
    }
  },
})
