var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.otherCommentUsers && _vm.otherCommentUsers.length > 0
    ? _c("div", { staticClass: "scene-card-user-images-parts" }, [
        _c(
          "div",
          { staticClass: "scene-card-user-images-parts__users" },
          _vm._l(_vm.otherCommentUsers, function (user) {
            return _c(
              "figure",
              {
                key: "commentUser" + user.id,
                staticClass: "scene-card-user-images-parts__user-image",
              },
              [_c("img", { attrs: { src: user.userPicture, alt: "" } })]
            )
          }),
          0
        ),
        _vm.userSurplusCount > 0
          ? _c("p", {
              staticClass: "scene-card-user-images-parts__surplus-count",
              domProps: { textContent: _vm._s("+" + _vm.userSurplusCount) },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }