var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-overlay-section", {
    staticClass: "alert-overlay-section",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("overlay-title-parts", { attrs: { title: _vm.title } }),
            _c("overlay-message-parts", { attrs: { message: _vm.message } }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _vm.url && _vm.linkText
              ? _c("atom-icon-link-button", {
                  attrs: {
                    linkText: _vm.linkText,
                    url: _vm.url,
                    imgPath: require("@/assets/img/icon/" +
                      _vm.imgIconFileName),
                  },
                  on: {
                    push: function ($event) {
                      return _vm.emitOpen()
                    },
                  },
                })
              : _vm._e(),
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.closeButtonText,
                colorType: _vm.closeButtonColorType,
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }