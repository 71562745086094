var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img-slider-section" }, [
    _c("div", { ref: "swiper", staticClass: "swiper" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.imgSlideLinks, function (slide, index) {
          return _c("img-slide-parts", {
            key: slide.img + "-" + index,
            staticClass: "swiper-slide",
            attrs: { linkData: slide },
          })
        }),
        1
      ),
      _c("div", { staticClass: "swiper-button-prev" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/icon/icon_swiper_prev.svg"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "swiper-button-next" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/icon/icon_swiper_next.svg"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "swiper-pagination" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }