


























































































































































import { computed, defineComponent } from '@vue/composition-api'
import ToggleButtonParts from '@/components/RaceVideoPage/RaceVideoPane/parts/ToggleButtonParts.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * レース動画再生画面 動画プレーヤー 縦画面 動画コントローラー コンポーネント
 */
export default defineComponent({
  name: 'LandscapeDataViewSelectorParts',
  components: {
    ToggleButtonParts,
  },
  props: {
    /**
     * 表示コンテンツモード
     */
    viewMode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { hasNewHighlight } = raceVideoPageStore

    const viewContentsMode = computed(() => props.viewMode)

    /**
     * ハイライト新着通知アイコンを表示するかどうか
     */
    const newHighlightIcon = computed(() => hasNewHighlight.value)

    return {
      viewContentsMode,
      newHighlightIcon,
    }
  },
  methods: {
    /**
     * 表示モード変更
     * @param mode
     */
    changeViewMode(mode: string): void {
      let rt = ''
      const orgMode = this.viewContentsMode
      rt = mode !== orgMode ? mode : 'single'
      this.$emit('viewModeChange', rt, orgMode)
    },
  },
})
