

























import { defineComponent, PropType } from '@vue/composition-api'
import { LinkData } from '@/components/LinkPage/LinkPane.vue'
import InAppBrowserWrapper, { InAppBrowserResult } from '@/util/inAppBrowser/InAppBrowserWrapper'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * Link画面から遷移するパターン
 */
export type LinkTransitionType = 'routerLink' | 'inAppBrowser' | 'externalLink'

/**
 * Link アイテム
 */
export default defineComponent({
  name: 'LinkItemParts',
  components: {},
  props: {
    linkData: {
      type: Object as PropType<LinkData>,
      required: true,
    },
  },
  setup() {
    const inAppBrowser = new InAppBrowserWrapper()

    /**
     * inAppBrowserで開くLINKカードをクリックした場合に呼び出される。
     * @param url 外部サイトのリンク
     */
    const onClickAnchorLink = (url: string) => {
      if (!InAppBrowserWrapper.isAvailable()) {
        // ブラウザの場合、別ウィンドウか別タブで表示する
        const subWindow = window.open(url, '_blank')
        if (subWindow && process.env.VUE_APP_SFBINGO_URL === url) {
          // URLがSFbingoの場合には、APIの認証情報とユーザーIDをローカルストレージに保存する
          LocalStorageAccessor.setCredentialInfo(
            UserStore.value.user.value._organization || '',
            UserStore.value.user.value.id || '',
            LoginStore.value.accessToken || '',
            LoginStore.value.refreshToken || '',
          )
          setTimeout(() => {
            LocalStorageAccessor.clearCredentialInfo()
          }, 60000)
        }
        return
      }

      let injectCode = ''
      if (url === process.env.VUE_APP_SFBINGO_URL) {
        const credentialInfo = {
          organizationId: UserStore.value.user.value._organization,
          userId: UserStore.value.user.value.id,
          accessToken: LoginStore.value.accessToken,
          refreshToken: LoginStore.value.refreshToken,
        }
        const sCredentialInfo = JSON.stringify(credentialInfo)
        injectCode = `window.onLoadCredentials('${sCredentialInfo}');`
      }

      // InAppBrowserでサイトを開く
      inAppBrowser.open(
        url,
        '_blank',
        undefined,
        async (result: InAppBrowserResult) => {
          if (result?.isNetworkError) {
            await MessageDialogStore.value.open({
              title: I18n.t('apiNotification.dialog.0.title') as string,
              message: I18n.t('apiNotification.dialog.0.message') as string,
            })
          }
        },
        false,
        injectCode,
      )
    }

    return {
      onClickAnchorLink,
    }
  },
})
