






























import { defineComponent, PropType } from '@vue/composition-api'

export type PaymentScheduleData = {
  title: string | null
  period: string | null
  price: string | null
  description: string | null
  note: string | null
  // 請求0円の場合に表示対象外とするかを判定するために使用する
  priceNum?: number
  /**
   * 決済日時 UnixTime(ミリ秒)
   * 請求予定ソート用に使用
   */
  settlementDateNum?: number
}

/**
 * 契約導線: 支払予定 セクション
 */
export default defineComponent({
  name: 'PaymentScheduleSection',
  props: {
    /**
     * 請求予定情報
     */
    paymentSchedule: {
      type: Array as PropType<PaymentScheduleData[]>,
      default: null,
    },
  },
})
