var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "81",
        height: "80",
        viewBox: "0 0 81 80",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.1839 31.1394H15.5021V24.7758H9.82031C7.05889 24.7758 4.82031 27.0143 4.82031 29.7758V63.2823C4.82031 66.0437 7.05889 68.2823 9.82031 68.2823H71.1839C73.9454 68.2823 76.1839 66.0437 76.1839 63.2823V29.7758C76.1839 27.0143 73.9454 24.7758 71.1839 24.7758H65.5021V31.1394H69.8203V61.9186H11.1839V31.1394Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M40.5 50.2699C40.5 50.2699 58 32.7281 58 20.2699C58 10.6049 50.165 2.7699 40.5 2.7699C30.835 2.7699 23 10.6049 23 20.2699C23 32.7281 40.5 50.2699 40.5 50.2699ZM38.8893 27.3534L51.0477 16.3104L48.6029 13.6186L37.691 23.5293L32.8919 18.9968L30.3951 21.6405L36.4185 27.3293L37.6428 28.4855L38.8893 27.3534Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }