var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-car-speed-parts" }, [
    _c("p", { staticClass: "telemetry-car-speed-parts__data" }, [
      _vm._v(_vm._s(Math.floor(_vm.viewValue))),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "telemetry-car-speed-parts__label" }, [
      _c("span", [_vm._v("km/h")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }