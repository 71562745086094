



















import { defineComponent, ref } from '@vue/composition-api'
import MessageModalSection from '@/components/common/modal/MessageModalSection.vue'
import DeviceInfo from '@/util/DeviceInfo'
import Logger from '@/util/logger/Logger'

/**
 * マイページ: 紹介コード: 紹介コード表示セクション
 */
export default defineComponent({
  name: 'ReferralCodeSection',
  components: {
    MessageModalSection,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isOpenModal = ref(false)

    return {
      isOpenModal,
    }
  },
  methods: {
    /**
     * 紹介コードシェア
     * @param code
     */
    shareCode(code: string) {
      if (window.navigator.share) {
        if (DeviceInfo.isMobile()) {
          window.navigator
            .share({
              title: this.$tc('MypagePage.MypageReferralCode.share.title'),
              text: `${this.$tc('MypagePage.MypageReferralCode.share.text')}${code}`,
            })
            .then(() => {
              Logger.info('ReferralCodeSection#shareCode: Success shared referral code.')
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((err: any) => {
              Logger.error(
                `ReferralCodeSection#shareCode: Failed shared referral code. error: ${JSON.stringify(
                  err,
                )}`,
              )
            })
        } else {
          this.copyTextToClipboard(code)
          this.isOpenModal = true
        }
      } else {
        // Web Share API未対応ブラウザ向けのフォールバックを実装する。
        this.copyTextToClipboard(code)
      }
    },
    /**
     *  Web Share API未対応ブラウザ向けにテキストコピーを実装
     * @param textVal
     */
    copyTextToClipboard(textVal: string) {
      navigator.clipboard.writeText(textVal).then(() => {
        this.isOpenModal = true
      })
    },
  },
})
