import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * リアルタイムメッセージングのエンドポイントの情報を保持する。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/617152806/59+API
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/617120122/FL-UX
 */
export default class RealtimeMessagingEndPointDocument extends DocumentWrapper {
  constructor(initProps?: Partial<RealtimeMessagingEndPointDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis<RealtimeMessagingEndPointDocument>(initProps)
  }

  _path = 'rtm/endpoint'

  protected idAttr = 'key'

  /**
   * キー
   */
  key: string | null = null

  /**
   * クラスタ
   */
  cluster: string | null = null

  /**
   * 組織用チャンネル名
   */
  commonChannelName: string | null = null

  /**
   * ユーザー用チャンネル名
   */
  personalChannelName: string | null = null

  /**
   * 親組織用チャンネル名
   */
  publicChannelName: string | null = null
}
