





























































import { defineComponent, PropType, ref } from '@vue/composition-api'
import RoundDetailsHeaderParts, {
  RoundDetailsHeaderDataType,
} from '@/components/StandingsPage/StandingsRoundDataPane/parts/RoundDetailsHeaderParts.vue'
import RoundRecordParts, {
  RoundRecordDataType,
} from '@/components/StandingsPage/StandingsRoundDataPane/parts/RoundRecordParts.vue'

export type StandingsRoundDataType = {
  earnPoints: number
  race: RoundDetailsHeaderDataType
  records: RoundRecordDataType[]
}

/**
 * Standings: レース詳細 セクション
 */
export default defineComponent({
  name: 'StandingsRoundDetailsSection',
  components: {
    RoundDetailsHeaderParts,
    RoundRecordParts,
  },
  props: {
    roundRecord: {
      type: Object as PropType<StandingsRoundDataType>,
      required: true,
    },
  },
  setup() {
    const open = ref<boolean>(false)
    const toggleOpen = () => {
      open.value = !open.value
    }
    return {
      open,
      toggleOpen,
    }
  },
  methods: {},
})
