import dayjs from 'dayjs'
import I18n from '@/locales/I18n'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import { RankInUserType } from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import {
  ShareRankingImageType,
  RankingUserImageDataType,
  RankingImageInfoType,
  PointRankingType,
} from '@/store/stores/pageStore/MissionPage/MissionPageStore'
import CanvasManager from '@/util/canvasManager/CanvasManager'
import {
  RANKING_SNS_POST_IMAGE_BRONZE_MEDAL_IMAGE_FOR_BASE64,
  RANKING_SNS_POST_IMAGE_COMMON_BACKGROUND_IMAGE_FOR_BASE64,
  RANKING_SNS_POST_IMAGE_GOLD_MEDAL_IMAGE_FOR_BASE64,
  RANKING_SNS_POST_IMAGE_SILVER_MEDAL_IMAGE_FOR_BASE64,
  RANKING_SNS_POST_IMAGE_DECORATION_IMAGE_FOR_BASE64,
} from '@/components/MissionPage/MissionRankingPane/hook/PostImageConst'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const commonBackgroundImagePath = require('@/assets/img/MissionPage/ranking/bg_share_card.png')

export default function useMissionRanking() {
  /**
   * 自分のランキングのデータを取得する
   */
  const getOwnRankingData = (
    pointRanking: PointRankingType,
    ownOrganization: OrganizationDocument | undefined,
  ) => {
    if (!pointRanking.category || !ownOrganization?.additionalData?.pointRanking) return undefined
    const ownData = ownOrganization.additionalData.pointRanking
    const ownUserId = LoginStore.value.userId || ''

    // 総合ランキングデータ
    if (pointRanking.category === 'totalPointRanking') {
      if (!ownData[pointRanking.category]) return undefined
      return {
        selfData: {
          ...ownData[pointRanking.category],
          userId: ownUserId,
        },
        isOwnRank: true,
      }
    }
    // シーズンランキングデータ
    if (pointRanking.category === 'seasonPointRanking' && pointRanking.seasonRankingYear) {
      if (!ownData[pointRanking.category][pointRanking.seasonRankingYear]) return undefined
      return {
        selfData: {
          ...ownData[pointRanking.category][pointRanking.seasonRankingYear],
          userId: ownUserId,
        },
        isOwnRank: true,
      }
    }
    // 月間ランキングデータ
    if (pointRanking.category === 'monthlyPointRanking' && pointRanking.monthlyRankingMonth) {
      if (!ownData[pointRanking.category][pointRanking.monthlyRankingMonth]) return undefined
      return {
        selfData: {
          ...ownData[pointRanking.category][pointRanking.monthlyRankingMonth],
          userId: ownUserId,
        },
        isOwnRank: true,
      }
    }
    return undefined
  }

  /**
   * ユーザーのニックネームを取得する
   */
  const getDisplayUserName = (
    retrieveNameUsersByUserId: Record<string, UserRetrieveNameResponseType> | undefined,
    userData: RankInUserType,
  ) => {
    if (!retrieveNameUsersByUserId?.[userData.userId] || !userData) {
      return I18n.tc('MissionPage.RankingPage.unableToGetDisplayName')
    }
    return (
      retrieveNameUsersByUserId[userData.userId]?.additionalData?.userDisplayName ||
      I18n.tc('common.displayNameNotSet')
    )
  }

  /**
   * 対象のランキング画像を取得する
   */
  const getTargetRankingUserImage = (
    shareRankingImageInfo: ShareRankingImageType,
    rankingUserImageData: RankingUserImageDataType,
    aggregationStartDate: number,
  ) => {
    const findRankingRange = (
      rankingImageInfoList: Array<RankingImageInfoType>,
      ranking: number,
    ) => {
      if (!rankingImageInfoList || rankingImageInfoList.length === 0) return undefined
      return rankingImageInfoList.find(
        (rankingImageInfo) =>
          ranking >= rankingImageInfo.startRanking && ranking <= rankingImageInfo.endRanking,
      )
    }

    // 総合の場合の処理
    if (shareRankingImageInfo.ranking.type === 'TOTAL') {
      return (
        findRankingRange(
          rankingUserImageData[shareRankingImageInfo.ranking.type],
          shareRankingImageInfo.ranking.rank,
        ) || findRankingRange(rankingUserImageData[shareRankingImageInfo.ranking.type], 0)
      )
    }

    // シーズン、月間の場合の処理
    const formatString = shareRankingImageInfo.ranking.type === 'SEASON' ? 'YYYY' : 'YYYYMM'
    const targetKey = dayjs(aggregationStartDate).tz('Asia/Tokyo').format(formatString)

    const targetRankingImageData =
      findRankingRange(
        rankingUserImageData[shareRankingImageInfo.ranking.type][Number(targetKey)],
        shareRankingImageInfo.ranking.rank,
      ) ||
      findRankingRange(
        rankingUserImageData[shareRankingImageInfo.ranking.type][Number(targetKey)],
        0,
      )

    if (targetRankingImageData) return targetRankingImageData

    const keys = Object.keys(rankingUserImageData[shareRankingImageInfo.ranking.type])
      .filter(
        (key) =>
          Number(key) <= Number(targetKey) &&
          findRankingRange(
            rankingUserImageData[shareRankingImageInfo.ranking.type][
              Number(key)
            ] as Array<RankingImageInfoType>,
            0,
          ),
      )
      .sort((a, b) => Number(b) - Number(a))

    if (!keys.length) {
      return undefined
    }

    let mostRecentNotRankingImageData: RankingImageInfoType | undefined
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      mostRecentNotRankingImageData = findRankingRange(
        rankingUserImageData[shareRankingImageInfo.ranking.type][
          Number(key)
        ] as Array<RankingImageInfoType>,
        0,
      )

      if (mostRecentNotRankingImageData) {
        break
      }
    }

    return mostRecentNotRankingImageData
  }

  /**
   * 投稿用のランキング画像を生成する
   */
  const createRankingImage = async (
    shareRankingImageInfo: ShareRankingImageType,
    backgroundImagePath: string,
    date: string,
  ) => {
    const loadImage = (src: string): Promise<HTMLImageElement> =>
      new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = (error) => reject(error)
        img.src = src
      })

    const fontFamily =
      "termina, 'Saira Expanded', 'Noto Sans JP', 'ヒラギノ角ゴ ProN W3',\n" +
      "  'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',\n" +
      '  sans-serif'
    let r = 0
    let g = 0
    let b = 0
    let a = 0

    if (shareRankingImageInfo.ranking.type === 'TOTAL') {
      r = 115
      g = 255
      b = 221
      a = 1
    } else if (shareRankingImageInfo.ranking.type === 'SEASON') {
      r = 235
      g = 229
      b = 83
      a = 1
    } else if (shareRankingImageInfo.ranking.type === 'MONTHLY') {
      r = 255
      g = 93
      b = 93
      a = 1
    }

    const baseImage = await loadImage(
      backgroundImagePath === commonBackgroundImagePath
        ? RANKING_SNS_POST_IMAGE_COMMON_BACKGROUND_IMAGE_FOR_BASE64
        : backgroundImagePath,
    )

    const canvas = new CanvasManager('2d', {
      image: baseImage,
      overlay: { r: 0, g: 0, b: 0, a: 0.6 },
    })
    const isMedalRanking = shareRankingImageInfo.ranking.rank < 4

    const decorationImage = await loadImage(RANKING_SNS_POST_IMAGE_DECORATION_IMAGE_FOR_BASE64)
    canvas.drawImage(decorationImage, 0, 700, 1080, 380)

    if (isMedalRanking) {
      let medalImageForBase64 = ''
      let medalImageX = 0
      let medalImageY = 0
      if (shareRankingImageInfo.ranking.rank === 1) {
        medalImageForBase64 = RANKING_SNS_POST_IMAGE_GOLD_MEDAL_IMAGE_FOR_BASE64
        medalImageX = 30
        medalImageY = 420
      } else if (shareRankingImageInfo.ranking.rank === 2) {
        medalImageForBase64 = RANKING_SNS_POST_IMAGE_SILVER_MEDAL_IMAGE_FOR_BASE64
        medalImageX = 42
        medalImageY = 425
      } else if (shareRankingImageInfo.ranking.rank === 3) {
        medalImageForBase64 = RANKING_SNS_POST_IMAGE_BRONZE_MEDAL_IMAGE_FOR_BASE64
        medalImageX = 42
        medalImageY = 425
      }
      const mealImage = await loadImage(medalImageForBase64)
      canvas.drawImage(mealImage, medalImageX, medalImageY, 470, 470)
    }

    canvas.addText(
      shareRankingImageInfo.displayName,
      1050,
      60,
      { weight: '300', size: 36, family: fontFamily },
      {
        r: 255,
        g: 255,
        b: 255,
        a: 0.7,
      },
      'end',
    )
    canvas.addText(
      `${shareRankingImageInfo.point}${I18n.tc('MissionPage.RankingPage.Share.point')}`,
      1050,
      130,
      { weight: 'normal', size: 56, family: fontFamily },
      {
        r: 255,
        g: 255,
        b: 255,
        a: 0.7,
      },
      'end',
    )
    if (!isMedalRanking) {
      canvas.addText(
        I18n.tc('MissionPage.RankingPage.Share.rank'),
        60,
        620,
        { weight: '600', size: 68, family: fontFamily },
        {
          r,
          g,
          b,
          a,
        },
      )
    }

    canvas.addText(
      String(shareRankingImageInfo.ranking.rank),
      !isMedalRanking ? 60 : 220,
      !isMedalRanking ? 770 : 730,
      { weight: '600', size: 150, family: fontFamily },
      {
        r,
        g,
        b,
        a,
      },
      'start',
      3,
    )
    canvas.addText(
      String(shareRankingImageInfo.ranking.headline),
      50,
      930,
      { weight: '600', size: 86, family: fontFamily },
      {
        r,
        g,
        b,
        a,
      },
      'start',
      3,
    )
    canvas.addText(
      String(shareRankingImageInfo.ranking.title),
      50,
      1000,
      { weight: '500', size: 48, family: fontFamily },
      {
        r,
        g,
        b,
        a,
      },
      'start',
      2,
    )
    if (shareRankingImageInfo.ranking.mothDate) {
      canvas.addText(
        String(shareRankingImageInfo.ranking.mothDate),
        50,
        1050,
        { weight: '200', size: 28, family: fontFamily },
        {
          r,
          g,
          b,
          a,
        },
      )
    }
    canvas.addText(
      date,
      1050,
      1050,
      { weight: '200', size: 28, family: fontFamily },
      {
        r,
        g,
        b,
        a,
      },
      'end',
    )

    return canvas
  }

  return {
    getDisplayUserName,
    getTargetRankingUserImage,
    createRankingImage,
    getOwnRankingData,
  }
}
