import { toRefs, watch } from '@vue/composition-api'
import { now } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import Logger from '@/util/logger/Logger'

/**
 * ハイライト画面の機能を提供する。
 */
export default function useHighlight() {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

  const {
    fetchPlayEvent,
    raceVideoPageState,
    currentTimeMainVideoTime,
    selectedPlayerMasterIdForHighlight,
  } = raceVideoPageStore

  const {
    fetchHighlights,
    highlightsSortByMovieStartTime,
    highlightsForPreLoad,
    selectedHighlight,
    selectedHighlightId,
    highlightUserIds,
  } = raceVideoPageStore.highlightStore
  const { raceVideoPageHighlightState } = raceVideoPageStore.highlightStore

  /**
   * ハイライト画面で必要なデータを取得する。
   */
  const fetchRaceVideoHighlightsPageData = async (lastHighlightFetchedDate?: number) => {
    if (raceVideoPageState.raceId && raceVideoPageState.championshipMasterId) {
      await Promise.all([
        fetchHighlights(raceVideoPageState.raceId, false, lastHighlightFetchedDate),
        fetchHighlights(raceVideoPageState.raceId, true, lastHighlightFetchedDate),
        fetchPlayEvent(),
      ])
    }
  }

  /**
   * 最後にハイライト選択状態の更新を行った日時
   */
  let lastUpdateHighlightSelectionState = 0

  /**
   * ハイライトの選択状態を更新する。
   * 動画の再生位置が、ハイライトの範囲外となった場合、選択状態を解除する。
   */
  const updateHighlightSelectionState = () => {
    if (raceVideoPageHighlightState.highlightSelectionStatus) {
      Logger.debug(
        `RaceVideoPageStore#updateHighlightSelectionState: Skip update highlight selection state due to highlight selecting now.`,
      )
      return
    }
    if (selectedHighlight.value && now() - lastUpdateHighlightSelectionState > 1000) {
      // 確認頻度を抑えるため1秒おきに処理を行う

      // メイン動画の再生位置
      const currentTime = currentTimeMainVideoTime.value

      if (
        // currentTimeには100ms前後の誤差があるため、300msの余裕を見て判定する
        Math.floor(selectedHighlight.value.startTime) > Math.floor(currentTime) + 0.3 ||
        Math.floor(selectedHighlight.value.endTime) < Math.floor(currentTime) - 0.3
      ) {
        Logger.debug(
          `RaceVideoPageStore#updateHighlightSelectionState: Update highlight selection state. startTime: ${selectedHighlight.value.startTime}, endTime: ${selectedHighlight.value.endTime}, currentTime: ${currentTime}`,
        )
        selectedHighlightId.value = null
        selectedPlayerMasterIdForHighlight.value = null
      }
      lastUpdateHighlightSelectionState = now()
    }
  }

  watch(
    toRefs(raceVideoPageState).videoStatus,
    async () => {
      // ハイライトの選択状態を更新する
      updateHighlightSelectionState()
    },
    { deep: true },
  )

  return {
    fetchRaceVideoHighlightsPageData,
    highlightsSortByMovieStartTime,
    selectedHighlightId,
    highlightsForPreLoad,
    highlightUserIds,
  }
}
