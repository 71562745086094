






















import { computed, defineComponent } from '@vue/composition-api'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

/**
 * フィールドセット テキスト(数字) バーツ
 */
export default defineComponent({
  name: 'FieldsetTextParts',
  components: {
    AtomInputText,
    FormErrorMessageParts,
  },
  props: {
    /**
     * データタイトル
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * 入力データ
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * プレースホルダー
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * リードオンリーフラグ
     */
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * text
     * @param {string} val
     */
    const text = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    return {
      text,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
