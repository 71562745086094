



















import { defineComponent } from '@vue/composition-api'

/**
 * マイページ: 詳細設定 データリスト パーツ
 */
export default defineComponent({
  name: 'DetailsDataListParts',
  props: {
    /**
     * データリスト
     */
    detailsListItems: {
      type: Array,
      required: true,
    },
  },
  methods: {},
})
