

















import { defineComponent, PropType } from '@vue/composition-api'

export type RoundDetailsHeaderDataType = {
  round: number
  imgPath: string
  courseName: string
  courseImgPath: string
}

/**
 * Standings: レース詳細 ヘッダーパーツ
 */
export default defineComponent({
  name: 'RoundDetailsHeaderParts',
  props: {
    raceData: {
      type: Object as PropType<RoundDetailsHeaderDataType>,
      required: true,
    },
  },
})
