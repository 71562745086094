/* eslint-disable @typescript-eslint/no-explicit-any */
import AsyncLock from 'async-lock'

type AsyncLockOptions = {
  timeout?: number
  maxPending?: number
  domainReentrant?: boolean
  Promise?: any
  skipQueue?: boolean
}

type AcquireOptions = {
  key?: string
  execLimitTime?: number
  asyncLockOpt?: AsyncLockOptions
}

/**
 * execLimitTime時に強制的にロックを解除できるようにするためのAsyncLockのラッパークラス
 * optionは次のURLで確認する。
 * https://github.com/rogierschouten/async-lock#options
 */
export default class PromiseLock {
  constructor(option?: AsyncLockOptions) {
    this.asyncLock = new AsyncLock(option)
  }

  asyncLock: AsyncLock

  /**
   * execLimitTime時に強制的にロックを解除できる async-Lock.acquire()
   *
   *
   * @param {Function} func 排他制御をかける非同期処理
   * @param {Object} options 以下のようなoptionを指定できる
   * <pre><code>
   * {
   *  execLimitTime: number, ミリ秒で指定。func関数の実行にここで指定した時間以上経過した場合はロックを解除し、新しいリクエストの実行を許可する
   *  key: string | string[]
   *  asyncLockOpt: Object, asyncLock.acquireの引数に指定できるoption
   * }
   * </code></pre>
   *
   * @return {Promise} 処理の実行を待機するPromise
   */
  acquire<T>(func: (...args: any) => Promise<T>, options: AcquireOptions = {}): Promise<T> {
    let { key } = options
    const { execLimitTime, asyncLockOpt } = options
    if (!key) {
      key = '__DEFAULT_KEY__'
    }

    return this.asyncLock.acquire<T>(
      key,
      () =>
        new Promise((resolve, reject) => {
          let isFinished = false
          if (execLimitTime) {
            setTimeout(async () => {
              if (!isFinished) {
                isFinished = true
                resolve(null)
              }
            }, execLimitTime)
          }

          func()
            .then((res: any) => {
              isFinished = true
              resolve(res)
            })
            .catch((e: any) => {
              isFinished = true
              reject(e)
            })
        }),
      asyncLockOpt,
    )
  }

  /**
   * キーに指定した非同期処理が実行中の場合trueを返す
   *
   * @param {string | string[]} key
   * @return {boolean} 非同期処理が実行中かどうか
   */
  isBusy(key = '__DEFAULT_KEY__') {
    return this.asyncLock.isBusy(key)
  }
}
