



































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import PlayerCardParts from '@/components/common/card/PlayerCardParts.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'

/**
 * レース動画再生画面 選手情報セクションのコンポーネント
 */
export default defineComponent({
  name: 'RacePlayerInfoSection',
  components: {
    PlayerCardParts,
  },
  props: {
    /**
     * 無線音声 ON/OFFフラグ
     */
    radioVoiceEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * レース選択 開閉フラグ
     */
    raceSelectEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 初期お気に入り選択フラグ
     */
    favoriteRacePlayerSelected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { getCurrentSelectedPlayerRadioDataSet } = useRadio()

    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player?: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }

    /**
     * 無線のカウント変更時にアニメーションクラス追加削除
     */
    const radioCountIcon = ref<HTMLElement & SVGElement>()
    watch(
      () => getCurrentSelectedPlayerRadioDataSet.value,
      (radioDataSet, oldRadioDataSet) => {
        const playerId = radioDataSet.length ? radioDataSet[0].playerId : null
        const oldPlayerId = oldRadioDataSet.length ? oldRadioDataSet[0].playerId : null
        if (
          radioDataSet.length > 0 &&
          radioDataSet.length > oldRadioDataSet.length &&
          playerId === oldPlayerId
        ) {
          radioCountIcon.value?.classList.add('is-animation')
          setTimeout(() => {
            radioCountIcon.value?.classList.remove('is-animation')
          }, 1000)
        }
      },
    )

    return {
      selectedPlayer: raceVideoPageStore.selectedPlayer,
      playerPictureUrl,
      radioCountIcon,
      getCurrentSelectedPlayerRadioDataSet,
    }
  },
  methods: {
    toggleRadioVoice(): void {
      /**
       * 無線音声 ON/OFFボタンが押下されたことを通知する
       * @event toggleRadioVoice
       * @type boolean
       */
      this.$emit('radioVoiceToggle')
    },
    toggleRaceSelect(): void {
      /**
       * レース選択ボタンが押下されたことを通知する
       * @event toggleRaceSelect
       */
      this.$emit('raceSelectToggle')
    },
    emitPlayerChange(): void {
      /**
       * 選手選択変更ボタンが押下されたことを通知する
       * @event emitPlayerChange
       * @type boolean
       */
      this.$emit('playerChange')

      this.$emit('raceSelectToggle', 'off')
    },
  },
})
