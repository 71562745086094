








import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageCancelPaidPlanEditPane from '@/components/MypageCancelPaidPlanPage/MypageCancelPaidPlanEditPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * アプリ版: 有料会員解約ページ
 */
export default defineComponent({
  name: 'MypageCancelPaidPlanEditPage',
  components: {
    GlobalNavigationPane,
    SubHeaderSection,
    MypageCancelPaidPlanEditPane,
  },
})
