var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-home-race",
      attrs: {
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M29.3333 15.9997H23.7077C23.0959 15.9997 22.5626 16.4161 22.4142 17.0096L21.3333 21.333H26.959C27.5708 21.333 28.1041 20.9166 28.2525 20.3231L29.3333 15.9997Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.3333 21.333H15.7077C15.0959 21.333 14.5626 21.7494 14.4142 22.343L13.3333 26.6663H18.959C19.5708 26.6663 20.1041 26.2499 20.2525 25.6564L21.3333 21.333Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 21.333H2.37437C1.76254 21.333 1.22923 21.7494 1.08085 22.343L0 26.6663H5.62563C6.23745 26.6663 6.77077 26.2499 6.91915 25.6564L8 21.333Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32 5.33301H26.3744C25.7625 5.33301 25.2292 5.7494 25.0808 6.34296L24 10.6663H29.6256C30.2375 10.6663 30.7708 10.2499 30.9192 9.65639L32 5.33301Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24 10.6663H18.3744C17.7625 10.6663 17.2292 11.0827 17.0808 11.6763L16 15.9997H21.6256C22.2375 15.9997 22.7708 15.5833 22.9192 14.9897L24 10.6663Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 15.9997H10.3744C9.76255 15.9997 9.22923 16.4161 9.08085 17.0096L8 21.333H13.6256C14.2375 21.333 14.7708 20.9166 14.9192 20.3231L16 15.9997Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.6667 5.33301H13.041C12.4292 5.33301 11.8959 5.7494 11.7475 6.34296L10.6667 10.6663H16.2923C16.9041 10.6663 17.4374 10.2499 17.5858 9.65639L18.6667 5.33301Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.6667 10.6663H5.04104C4.42921 10.6663 3.8959 11.0827 3.74751 11.6763L2.66667 15.9997H8.2923C8.90412 15.9997 9.43743 15.5833 9.58582 14.9897L10.6667 10.6663Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }