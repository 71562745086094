








import { defineComponent } from '@vue/composition-api'

/**
 * フィールドセット 情報入力確認 バーツ
 */
export default defineComponent({
  name: 'FieldsetConfirmParts',
  props: {
    /**
     * ラベル
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 文字マスクフラグ
     */
    isMask: {
      type: Boolean,
      default: false,
    },
    /**
     * 生年月日 文字置換フラグ
     */
    isBirthDay: {
      type: Boolean,
      default: false,
    },
    /**
     * 注記
     */
    attention: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * viewValue
     * @param {string} val 表示用データ
     * パスワードだった場合マスクする
     */
    let viewValue = props.isMask ? '' : props.value
    if (props.isMask === true) {
      for (let i = 0; i < props.value.length; i += 1) {
        viewValue += '●'
      }
    } else if (props.isBirthDay === true) {
      viewValue = props.value.replace(/-/g, ' / ')
    }
    return {
      viewValue,
    }
  },
})
