var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mission-referral-code" },
    [
      _c(
        "router-link",
        {
          staticClass: "mission-referral-code-link",
          attrs: { to: { name: "MypageReferralCodePage" } },
        },
        [_vm._v(_vm._s(_vm.$tc("MissionPage.referralCode")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }