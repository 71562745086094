var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ec-site-connect-complete-parts" }, [
    _c("h3", { staticClass: "mypage-ec-site-connect-complete-parts__title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$tc("MypagePage.MypageEcSiteConnectPage.complete.title")) +
          " "
      ),
    ]),
    _c(
      "p",
      { staticClass: "mypage-ec-site-connect-complete-parts__description" },
      [
        _vm._v(
          " " +
            _vm._s(
              _vm.$tc("MypagePage.MypageEcSiteConnectPage.complete.description")
            ) +
            " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }