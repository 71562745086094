import qs from 'qs'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import { SignedCookieNames } from '@/@types/SignedCookie'

/**
 * AWS CloudFront関連の機能を提供する。
 */
export default class CloudFrontUtil {
  /**
   * Cookie に保存されている署名情報を、指定されたURLに付与する。
   * 以下のような署名情報をもつクエリパラメタを生成する。
   * <code>?Signature={Signature}&Key-Pair-Id={Key-Pair-ID}}&Policy={Policy}</code>
   * @param url 署名情報を付与するURL。/movies,/files,/img 配下のリソースのURLを指定する
   * @return 署名情報が付与されたURL
   * @see https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html
   */
  static getSignedUrl(url?: string | null) {
    if (!url) return ''
    if (url.startsWith('blob') || url.startsWith('data')) {
      return url
    }

    // 署名クッキーのリスト、keyにURLに含まれる文字列、valueにcookieの値を持つ
    const cookieList = {
      '/img/': LoginStore.value.imageCookies,
      '/movies/': LoginStore.value.movieCookies,
      '/files/': LoginStore.value.fileCookies,
      '/audios/': LoginStore.value.audiosCookies,
    } as const

    const authCookieKey = Object.keys(cookieList).find(
      (cookiePathName) => url.indexOf(cookiePathName) !== -1,
    ) as keyof typeof cookieList
    if (!authCookieKey) {
      return url
    }
    const authCookies = cookieList[authCookieKey] ?? cookieList['/img/'] // 一致するパスが見つからない場合は、imageのcookieを付与する
    const cookieNames = SignedCookieNames
    const query = {} as {
      Signature: undefined | string
      'Key-Pair-Id': undefined | string
      Policy: undefined | string
    }
    cookieNames.forEach((cookieName) => {
      const cookie = authCookies?.find((authCookie) => authCookie[cookieName])
      if (cookie) {
        const signUrlParam = cookieName.substring('CloudFront-'.length) as keyof typeof query
        query[signUrlParam] = cookie[cookieName]
      }
    })

    const signed = qs.stringify(query)
    return `${url}?${signed}`
  }
}
