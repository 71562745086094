var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-user-edit-mail-password-parts" },
    [
      _c("fieldset-confirm-parts", {
        staticClass: "fieldset-user-edit-mail-password-parts__fieldset",
        attrs: { label: _vm.label, isMask: _vm.isMask },
        model: {
          value: _vm.mailOrPasswrodValue,
          callback: function ($$v) {
            _vm.mailOrPasswrodValue = $$v
          },
          expression: "mailOrPasswrodValue",
        },
      }),
      _c("atom-input-button", {
        staticClass: "fieldset-user-edit-mail-password-parts__confirm",
        attrs: {
          linkText: _vm.$t("common.edit"),
          colorType: "secondary",
          size: "medium",
          isDisabled: false,
        },
        on: { push: _vm.emitEnter },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }