import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * レース視聴履歴を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/774308676/46+API
 */
export default class RaceHistoryDocument extends DocumentWrapper {
  constructor(initProps?: Partial<RaceHistoryDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/operation_log'

  idAttr = '_id'

  /**
   * 操作ログID
   */
  targetId: string | null = null

  /**
   * 操作対象のコレクション名
   */
  targetCollectionName: string | null = null

  /**
   * 操作種別
   */
  operation: string | null = null

  /**
   * 通知利用フラグ
   */
  needNotification: boolean | null = false

  /**
   * 操作内容
   */
  contents: Multilingual = {
    raceYear: '',
    championshipId: '',
    championshipName: '',
    matchId: '',
    title: '',
    raceType: '',
    userId: '',
    userName: '',
    browsingDate: '',
  }
}
