import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { PermissionByRoleType } from '@/store/stores/pageStore/common/PermissionType'
import { RoleType } from '@/store/stores/pageStore/common/RoleType'

/**
 * ロール情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/2514059808/API
 */
export default class RoleDocument extends DocumentWrapper {
  constructor(initProps?: Partial<RoleDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/role'

  idAttr = 'roleId'

  /**
   * ロールID
   */
  roleId: string | null = null

  /**
   * ロール
   */
  role: RoleType | null = null

  /**
   * ロール名
   */
  roleName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 権限
   */
  permissions?: Array<PermissionByRoleType> | null = null
}
