































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import AttentionMessageSection, {
  AttentionMessageData,
} from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import PaymentScheduleSection, {
  PaymentScheduleData,
} from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * 新規会員登録:会員登録完了画面セクションのコンポーネント
 */
export default defineComponent({
  name: 'SignupCompleteSection',
  components: {
    AttentionMessageSection,
    PaymentScheduleSection,
  },
  props: {
    /**
     * クーポンを使用してるか
     */
    isCouponUsed: {
      type: Boolean,
      default: false,
    },
    /**
     * 注意メッセージ
     */
    attentionMessage: {
      type: Object as PropType<AttentionMessageData>,
      default: null,
    },
    /**
     * 請求予定情報
     */
    paymentSchedule: {
      type: Array as PropType<PaymentScheduleData[]>,
      default: null,
    },
  },
  setup(props) {
    /**
     * 注意メッセージを表示するかどうか
     * 注意メッセージが存在する かつ 請求予定が存在する場合（月額プランを登録した場合に表示される）
     * クーポンプランを登録 かつ 注意メッセージが存在する場合
     */
    const isShowAttentionMessage = computed(
      () =>
        (props.attentionMessage && props.paymentSchedule) ||
        (props.attentionMessage && props.isCouponUsed),
    )

    return {
      isMobile: DeviceInfo.isMobile(),
      sfgoUrl: `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/login`,
      isShowAttentionMessage,
    }
  },
})
