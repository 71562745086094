var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-about-using-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageAboutUsingPage.pageTitle"),
          backLink: "/mypage",
        },
      }),
      _c("mypage-about-using-pane"),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }