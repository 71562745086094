var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-sns-button-parts" }, [
    _c("h3", { staticClass: "mission-sns-button-parts__title" }, [
      _vm._v(" " + _vm._s(_vm.$tc("common.sns.post")) + " "),
    ]),
    _c("ul", { staticClass: "mission-sns-button-parts__buttons" }, [
      _c(
        "li",
        {
          ref: "buttonX",
          staticClass: "mission-sns-button-parts__buttons-item",
        },
        [
          _c(
            "button",
            {
              staticClass: "mission-sns-button-parts__button",
              class: {
                "mission-sns-button-parts__button--active": _vm.postX,
              },
              on: { click: _vm.onChangePostX },
            },
            [
              _c(
                "label",
                {
                  staticClass: "mission-sns-button-parts__button-label",
                  attrs: { for: "comment" },
                },
                [_c("mission-x-icon")],
                1
              ),
            ]
          ),
        ]
      ),
      false
        ? _c(
            "li",
            {
              ref: "buttonInstagram",
              staticClass: "mission-sns-button-parts__buttons-item",
            },
            [
              _c(
                "button",
                {
                  staticClass: "mission-sns-button-parts__button",
                  class: {
                    "mission-sns-button-parts__button--active":
                      _vm.postInstagram,
                  },
                  on: { click: _vm.onChangePostInstagram },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "mission-sns-button-parts__button-label",
                      attrs: { for: "comment" },
                    },
                    [_c("mission-instagram-icon")],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }