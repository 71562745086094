/**
 * HTTPClientの通信処理結果。
 */
export default class HTTPClientResponse {
  /**
   * HTTPステータス
   */
  status?: number

  /**
   * HTTPレスポンスボディ
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any

  /**
   * 指定された情報のレスポンスデータを作成する。
   *
   * @param status HTTPステータス
   * @param data HTTPレスポンスボディ
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(status: number, data?: any) {
    this.status = status
    this.data = data
  }
}
