var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "ranking-card-parts",
      class: { "ranking-card-parts--is-open": _vm.open },
    },
    [
      _c("h3", { staticClass: "ranking-card-parts__title" }, [
        _c("span", { staticClass: "ranking-card-parts__title-text" }, [
          _vm._v(_vm._s(_vm.rankingCardData.title)),
        ]),
        _c("span", { staticClass: "ranking-card-parts__title-number" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "15",
                viewBox: "0 0 16 15",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M12.5 1.5465V0H3.5V1.5465H0.5V3.75C0.5 5.64375 1.91675 7.1985 3.743 7.449C4.00458 8.2161 4.46855 8.89828 5.08586 9.42344C5.70317 9.94861 6.45089 10.2972 7.25 10.4325V11.25C7.25 11.6478 7.09196 12.0294 6.81066 12.3107C6.52936 12.592 6.14782 12.75 5.75 12.75H5V14.25H11V12.75H10.25C9.85218 12.75 9.47064 12.592 9.18934 12.3107C8.90804 12.0294 8.75 11.6478 8.75 11.25V10.4325C9.54911 10.2972 10.2968 9.94861 10.9141 9.42344C11.5315 8.89828 11.9954 8.2161 12.257 7.449C14.0833 7.1985 15.5 5.64375 15.5 3.75V1.5465H12.5ZM2 3.75V3.0465H3.5V5.871C3.06162 5.71554 2.68209 5.42822 2.41352 5.04846C2.14495 4.6687 2.0005 4.21513 2 3.75ZM14 3.75C13.9994 4.21521 13.855 4.66885 13.5864 5.04871C13.3179 5.42857 12.9384 5.71606 12.5 5.87175V3.0465H14V3.75Z",
                  fill: "#ECAA00",
                },
              }),
            ]
          ),
          _c("span", [_vm._v("1")]),
        ]),
      ]),
      _vm._l(_vm.getPlayerDataByPosData(0), function (rankingCardData, index) {
        return _c("ranking-card-winner-parts", {
          key: "rankingWinnerData" + index,
          staticClass: "ranking-card-parts__winner",
          attrs: { rankingCardData: rankingCardData },
        })
      }),
      _c(
        "section",
        { staticClass: "ranking-card-parts__other-list" },
        [
          _c(
            "svg",
            {
              staticClass: "ranking-card-parts__separate",
              attrs: {
                width: "313",
                height: "33",
                viewBox: "0 0 313 33",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  stroke: "#fff",
                  "stroke-miterlimit": "10",
                  "stroke-opacity": "0.3",
                  d: "M313,32.5h0.5c0-8.8-7.2-16-16-16h-282c-8.8,0-16-7.2-16-16l0.5,0",
                },
              }),
            ]
          ),
          _vm._l(
            _vm.getPlayerDataByPosData(1),
            function (rankingCardData, index) {
              return _c("ranking-player-list-item-parts", {
                key: "RankingPlayer2nd" + index,
                attrs: {
                  rank: rankingCardData.dispRank,
                  playerData: rankingCardData,
                },
              })
            }
          ),
          _vm._l(
            _vm.getPlayerDataByPosData(2),
            function (rankingCardData, index) {
              return _c("ranking-player-list-item-parts", {
                key: "RankingPlayer3rd" + index,
                attrs: {
                  rank: rankingCardData.dispRank,
                  playerData: rankingCardData,
                },
              })
            }
          ),
        ],
        2
      ),
      _c(
        "button",
        {
          staticClass: "ranking-card-parts__toggle-button",
          on: { click: _vm.toggleOpen },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M16.0996 6.03906L10.2764 11.8623C9.59297 12.5457 8.48493 12.5457 7.80151 11.8623L1.97829 6.03906H4.09961L8.86217 10.8016C8.9598 10.8993 9.11809 10.8993 9.21573 10.8016L13.9783 6.03906H16.0996Z",
                  fill: "black",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }