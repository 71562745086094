import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoPlanCouponDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanCouponDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * クーポン用契約プランデータを操作するための処理を提供する。
 */
export default function useContractPlan() {
  // Collection modules
  const contractPlanCouponCollectionModule = CollectionModule.createStore(
    ContractInfoPlanCouponDocument,
  )

  // methods
  /**
   * 契約情報契約プランを保存する。
   * @return APIレスポンス
   */
  const saveContractInfoPlanCoupon = async (
    contractInfoId: string,
    couponId: string,
    isSettingNumberOfDays: boolean | undefined,
  ) => {
    let requestData = {} as ContractInfoPlanCouponDocument
    if (isSettingNumberOfDays) {
      // クーポン適用期間が日数指定の場合、00:00:00の日付になるように指定する
      const startDate = dayjs().startOf('day').valueOf()
      // CollectionModule.saveでput処理を走らせるためにidを指定する。
      // しかしsaveメソッドを呼ぶ際にoptions.urlを引数に含めているため、保存処理の際idは無視されるので、とりあえずここではidに'dummy'をセットしている。
      requestData = new ContractInfoPlanCouponDocument({
        id: 'dummy',
        couponId,
        contractStartDate: startDate,
      })
    } else {
      requestData = new ContractInfoPlanCouponDocument({
        id: 'dummy',
        couponId,
      })
    }

    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/plans/coupon`,
    }
    return contractPlanCouponCollectionModule.save(requestData, options)
  }

  return {
    saveContractInfoPlanCoupon,
  }
}
