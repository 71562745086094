import { computed } from '@vue/composition-api'
import { now, sumBy, orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import UserPointDocument from '@/store/stores/collectionModule/documents/user/UserPointDocument'
import useMissionTerm from '@/store/hook/mission/useMissionTerm'
import { TermType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 有料会員。
 */
type MonthlyPaidMemberMissionDataType = {
  // 取得ポイント
  points: number
  // 契約期間
  contractMonths: number
  // 達成回数
  achievementCounts: number
}

/**
 * ユーザーのポイント履歴を操作するための処理を取得する。
 */
export default function useUserPoint() {
  // Collection modules
  const userPointCollectionModule = CollectionModule.createStore(UserPointDocument)

  // hook
  const { getTargetYearMissionTerm } = useMissionTerm()

  // computed
  /**
   * 取得したユーザーポイント一覧
   */
  const userPoints = computed(() => userPointCollectionModule.data)

  // methods
  /**
   * ユーザーポイント履歴一覧を取得する。
   * @return APIレスポンス
   */
  const fetchUserPoints = () => userPointCollectionModule.fetch()

  /**
   * 選択年度の年額プラン登録/継続ミッションかどうか
   */
  const isSelectedYearAnnualPaidMission = (userPoint: UserPointDocument, missionTerm: TermType) => {
    if (
      !(
        userPoint.pointType &&
        userPoint._createdDate &&
        missionTerm.startDate &&
        missionTerm.endDate
      )
    ) {
      return false
    }

    /**
     * 以下1かつ2を満たす場合対象
     * 1. pointTypeがANNUAL_PAID_MEMBER
     * 2. 選択年度のミッションとして付与されたポイント。pointCodeIdにある年は単純にポイントが付与された年を表すため、pointCodeIdから対象ミッション期間かどうかの判断ができない。なので_createdDateを使って付与されたポイントがミッション期間内かどうかの判定をしている。
     */
    return (
      userPoint.pointType === 'ANNUAL_PAID_MEMBER' &&
      missionTerm.startDate <= userPoint._createdDate &&
      missionTerm.endDate > userPoint._createdDate
    )
  }

  /**
   * 選択年度の月額プラン登録/継続ミッションかどうか
   */
  const isSelectedYearMonthlyPaidMission = (
    userPoint: UserPointDocument,
    missionTerm: TermType,
  ) => {
    if (
      !(
        userPoint.pointType &&
        userPoint.monthlyPaidMemberEarnedPointDate &&
        missionTerm.startDate &&
        missionTerm.endDate
      )
    ) {
      return false
    }

    /**
     * 以下1かつ2を満たす場合対象
     * 1. pointTypeがMONTHLY_PAID_MEMBER
     * 2. 選択年度のミッションとして付与されたポイント
     */
    return (
      userPoint.pointType === 'MONTHLY_PAID_MEMBER' &&
      missionTerm.startDate <= userPoint.monthlyPaidMemberEarnedPointDate &&
      missionTerm.endDate > userPoint.monthlyPaidMemberEarnedPointDate
    )
  }

  /**
   * 年額プラン登録/継続ミッションで獲得したポイント
   */
  const annualPaidMemberMissionPoint = (year: number) => {
    const missionTerm = getTargetYearMissionTerm(year)
    return userPointCollectionModule.data.find((userPoint) =>
      isSelectedYearAnnualPaidMission(userPoint, missionTerm),
    )?.point
  }

  /**
   * 月額プラン登録/継続ミッションのデータ
   */
  const monthlyPaidMemberMissionData = (year: number) => {
    const missionTerm = getTargetYearMissionTerm(year)
    const paidMemberPointData: MonthlyPaidMemberMissionDataType = {
      points: 0,
      contractMonths: 0,
      achievementCounts: 0,
    }

    orderBy(userPointCollectionModule.data, 'grantedDate').forEach((userPoint) => {
      if (isSelectedYearMonthlyPaidMission(userPoint, missionTerm)) {
        // 獲得ポイントを加算
        paidMemberPointData.points += userPoint.point ?? 0
        // 契約期間を加算
        paidMemberPointData.contractMonths += 1
        // 達成回数を加算
        paidMemberPointData.achievementCounts += 1
      }
    })

    return paidMemberPointData
  }

  /**
   * ユーザーの保有ポイント
   */
  const currentOwnedPoints = computed(() =>
    sumBy(
      userPointCollectionModule.data.filter(
        (point) => !(point.pointExpirationDate && point.pointExpirationDate < now()),
      ),
      'currentOwnedPoint',
    ),
  )

  /**
   * 取得したユーザーポイント履歴をクリアする
   */
  const clearUserPoints = () => {
    userPointCollectionModule.clearData()
  }

  return {
    userPoints,
    currentOwnedPoints,
    fetchUserPoints,
    annualPaidMemberMissionPoint,
    monthlyPaidMemberMissionData,
    clearUserPoints,
  }
}
