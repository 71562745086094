var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "member-badge-list-parts",
      class: { "member-badge-list-parts--hidden": !_vm.visibleList },
    },
    [
      _c(
        "div",
        {
          ref: "MemberBadgeListPartsContent",
          staticClass: "member-badge-list-parts__content",
          on: { scroll: _vm.checkCarouselEnabled },
        },
        [
          _c(
            "div",
            { staticClass: "member-badge-list-parts__list" },
            _vm._l(_vm.badgeList, function (badge, idx) {
              return _c("member-badge-item-parts", {
                key: "memberBadgeItem" + idx,
                class:
                  "member-badge-list-parts__item member-badge-list-parts__item--" +
                  idx,
                attrs: { badge: badge },
              })
            }),
            1
          ),
        ]
      ),
      _vm.enabledListCarousel
        ? _c(
            "button",
            {
              staticClass:
                "member-badge-list-parts__carousel member-badge-list-parts__carousel-prev",
              class: {
                "member-badge-list-parts__carousel--active":
                  _vm.enabledCarouselPrev,
              },
              on: {
                click: function ($event) {
                  return _vm.onclickCarousel("prev")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/icon/icon_chevron_left_24.svg"),
                  alt: "<",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.enabledListCarousel
        ? _c(
            "button",
            {
              staticClass:
                "member-badge-list-parts__carousel member-badge-list-parts__carousel-next",
              class: {
                "member-badge-list-parts__carousel--active":
                  _vm.enabledCarouselNext,
              },
              on: {
                click: function ($event) {
                  return _vm.onclickCarousel("next")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/icon/icon_chevron_right_24.svg"),
                  alt: ">",
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }