var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "section",
      {
        staticClass: "radio-select-section",
        class: "radio-select-section--" + _vm.screenOrientationType,
      },
      [
        _c("div", {
          staticClass: "radio-select-section__mask",
          on: { click: _vm.toggleRadioVoice },
        }),
        _c(
          "div",
          {
            staticClass: "radio-select-section__content",
            class: {
              "radio-select-section--has-items":
                _vm.getCurrentSelectedPlayerRadioDataSet.length > 0,
            },
          },
          [
            _c("h3", { staticClass: "radio-select-section__title" }, [
              _vm._v(_vm._s(_vm.$t("RaceVideoPage.RadioSelect.title"))),
            ]),
            _c(
              "button",
              {
                staticClass: "radio-select-section__live-button",
                on: { click: _vm.toggleRadioLive },
              },
              [
                _c(
                  "span",
                  { staticClass: "radio-select-section__live-button__label" },
                  [_vm._v("LIVE")]
                ),
                _c("span", {
                  staticClass: "radio-select-section__live-button__toggle",
                  class: {
                    "radio-select-section__live-button__toggle--active":
                      _vm.livePlayerRadioEnabled,
                  },
                }),
              ]
            ),
            _c("recycle-scroller", {
              staticClass: "radio-select-section__radio-list",
              attrs: {
                items: _vm.getCurrentSelectedPlayerRadioDataSet,
                "item-size": 54,
                "key-field": "audio_clip",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("radio-list-item-parts", {
                        attrs: {
                          radioData: item,
                          screenOrientationType: _vm.screenOrientationType,
                        },
                        on: {
                          selectPlayerRadioPlay: _vm.playSelectPlayerRadio,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }