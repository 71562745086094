var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-hexagon-inner",
      attrs: {
        viewBox: "0 0 56 62",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M32.9026 2.18625C30.5074 0.838925 29.3098 0.165265 28 0.165265C26.6902 0.165265 25.4926 0.838925 23.0974 2.18625L5.09739 12.3112C2.61107 13.7098 1.36791 14.4091 0.683953 15.5785C0 16.748 0 18.1743 0 21.027V40.8301C0 43.6827 0 45.1091 0.683953 46.2785C1.36791 47.448 2.61107 48.1473 5.09739 49.5458L23.0974 59.6708C25.4926 61.0181 26.6902 61.6918 28 61.6918C29.3098 61.6918 30.5074 61.0181 32.9026 59.6708L50.9026 49.5458C53.3889 48.1473 54.6321 47.448 55.316 46.2785C56 45.1091 56 43.6827 56 40.8301V21.027C56 18.1743 56 16.748 55.316 15.5785C54.6321 14.4091 53.3889 13.7098 50.9026 12.3112L32.9026 2.18625Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }