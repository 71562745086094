export default function useReferralCode() {
  /**
   * @param length 桁数
   * @returns 引数に指定された桁数のランダムな文字列（半角英数字）
   */
  const createRandomCode = (length: number) => {
    // フォントによっては判別が困難な小文字のl、大文字のIを除いた半角英数字
    const S = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789'

    return Array.from(Array(length))
      .map(() => S[Math.floor(Math.random() * S.length)])
      .join('')
  }
  return {
    createRandomCode,
  }
}
