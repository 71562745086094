

























import { defineComponent, PropType } from '@vue/composition-api'
import { GpsViewModeType } from '@/components/RaceVideoPage/RaceVideoGpsPane.vue'
import CircuitMapImageAutoPolice from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/CircuitMapImageAutoPolice.vue'
import CircuitMapImageFuji from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/CircuitMapImageFuji.vue'
import CircuitMapImageMotegi from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/CircuitMapImageMotegi.vue'
import CircuitMapImageSugo from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/CircuitMapImageSugo.vue'
import CircuitMapImageSuzuka from '@/components/RaceVideoPage/RaceVideoGpsPane/parts/CircuitMapImageSuzuka.vue'
import { CourseType } from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'

/**
 * レース動画再生画面: GPS詳細 マップ表示 コンポーネント
 */
export default defineComponent({
  name: 'CircuitDetailMapImage',
  components: {
    CircuitMapImageAutoPolice,
    CircuitMapImageFuji,
    CircuitMapImageMotegi,
    CircuitMapImageSugo,
    CircuitMapImageSuzuka,
  },
  props: {
    /**
     * GPS表示モード
     */
    circuitName: {
      type: String as PropType<CourseType>,
      required: true,
    },
    /**
     * GPS表示モード
     */
    gpsViewMode: {
      type: String as PropType<GpsViewModeType>,
      default: 'birdsEye',
    },
  },
  setup() {
    return {}
  },
})
