














import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import LinkPane from '@/components/LinkPage/LinkPane.vue'

/**
 * LINK ページ
 */
export default defineComponent({
  name: 'LinkPage',
  components: {
    HeaderPane,
    LinkPane,
  },
  props: {
    /**
     * ヘッダーナビ表示指定
     */
    hasHeaderNav: {
      type: Boolean,
      default: true,
    },
  },
})
