










































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import { orderBy } from 'lodash'
import AtomButton from '@/components/atoms/AtomButton.vue'
import MypageTicketTodayTableParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypageTicketTodayTableParts.vue'
import MypageTicketTodayCurrentTimeParts from '@/components/MypageTicketTodayPage/MypageTicketTodayPane/parts/MypageTicketTodayCurrentTimeParts.vue'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import usePurchasedTicketData, {
  UserPurchasedTicketType,
} from '@/components/hook/ticket/usePurchasedTicketData'
import StoreUtil from '@/store/StoreUtil'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

export default defineComponent({
  name: 'MypageTicketTodayConfirmModalSection',
  components: { MypageTicketTodayCurrentTimeParts, MypageTicketTodayTableParts, AtomButton },
  props: {
    /**
     * デジタルチケット 券種情報
     */
    ticketType: {
      type: Object as PropType<DigitalTicketDataTicketTypeDocument>,
      default: () => ({}),
    },
    /**
     * ログインユーザーが購入した商品情報
     */
    purchasedTicketInfo: {
      type: Object as PropType<UserPurchasedTicketType>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { ownOrganization } = mypagePageStore
    const { getTodayTicketTotalCountByTicketType } = usePurchasedTicketData()

    /**
     * ログインユーザーが購入したチケット
     */
    const purchasedTickets = computed(() =>
      orderBy(props.ticketType.tickets, 'order')?.filter((ticket) =>
        props.purchasedTicketInfo.productNumbers.availableToday.some(
          (productNumber) => productNumber === ticket.productNumber,
        ),
      ),
    )

    const handleSubmitClicked = () => {
      emit('onClickSubmit')
    }

    const handleCancelClicked = () => {
      emit('onClickCancel')
    }

    return {
      // MypagePageStore
      ownOrganization: ownOrganization as Ref<OrganizationDocument | undefined>,
      // usePurchasedTicketData
      getTodayTicketTotalCountByTicketType,
      // MypageTicketTodayConfirmModalSection
      purchasedTickets,
      availableTodayProductNumbers: props.purchasedTicketInfo.productNumbers
        .availableToday as Array<string>,
      handleSubmitClicked,
      handleCancelClicked,
    }
  },
})
