import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import StandingsDataDocument from '@/store/stores/collectionModule/documents/standings/StandingsDataDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'

/**
 * Standingsデータを操作するための処理を取得する。
 */
export default function useStandingsData() {
  // Collection modules
  const standingsCollectionModule = CollectionModule.createStore(StandingsDataDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * Standingsデータを取得する。
   */
  const fetchStandingsData = async () => {
    const result = await searchDateRangeData({
      tableName: 'standings-data',
      partitionKey: 'raceCategory',
      partitionKeyValue: 'SuperFormula',
      sortKey: 'seasonYear',
    })
    standingsCollectionModule.data = result as Array<StandingsDataDocument>
  }

  /**
   * 取得したStandingsデータ一覧
   */
  const standingsData = computed(() => standingsCollectionModule.data)

  /**
   * 年度毎に分類されたStandingsデータ一覧
   */
  const standingsListByYear = computed(() => {
    const StandingsMap: Record<string, StandingsDataDocument> = {}
    standingsData.value.forEach((standing) => {
      if (standing.seasonYear) {
        StandingsMap[standing.seasonYear] = standing
      }
    })
    return StandingsMap
  })

  /**
   * 取得したStandingsデータをクリアする
   */
  const clearStandingsData = () => {
    standingsCollectionModule.clearData()
  }

  return {
    fetchStandingsData,
    standingsListByYear,
    clearStandingsData,
  }
}
