



































































import { defineComponent, PropType } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import { HighlightTabsType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'

/**
 * プルダウンの項目が持つべきフィールドを定義したインタフェース
 */
export interface FilterList {
  id: string
  name: {
    ja: string
    en: string
  }
  type?: string
}

type DataType = {
  filterListEnabled: boolean
  selectId: string
}

/**
 * レース動画再生画面 ハイライト フィルター(セレクター) パーツコンポーネント
 */
export default defineComponent({
  name: 'FilterSelectorParts',
  data(): DataType {
    return {
      filterListEnabled: false,
      selectId: '',
    }
  },
  props: {
    /**
     * 非選択時セレクト テキストデータ
     */
    defaultItemLabel: {
      type: String,
      default: '',
    },
    /**
     * 非選択時リスト 空値テキストデータ
     */
    defaultListItemLabel: {
      type: String,
      default: '',
    },
    /**
     * 選択中のハイライトタブ
     */
    selectedHighlightTab: {
      type: String as PropType<HighlightTabsType>,
      default: '',
    },
    /**
     * フィルターリストデータ
     */
    filterList: {
      type: Array as PropType<FilterList[]>,
      required: true,
    },
  },
  setup(props) {
    const filterLabel = props.defaultItemLabel

    return {
      filterLabel,
    }
  },
  methods: {
    enabledFilter(): void {
      /**
       * フィルター表示切り替え
       */
      this.filterListEnabled = !this.filterListEnabled
      this.$emit('filterEnabled', this.filterListEnabled)
    },
    changeFilter(id: string | null, type?: string): void {
      if (id === null) {
        // プルダウンを閉じるだけの動作
        this.enabledFilter()
        return
      }

      /**
       * フィルター選択切り替え
       */
      this.selectId = id
      const filterData = this.filterList.filter((v) => v.id === this.selectId)
      if (filterData.length !== 0) {
        this.filterLabel = I18n.locale === 'en' ? filterData[0].name.en : filterData[0].name.ja
      } else {
        this.filterLabel = this.defaultItemLabel
      }
      this.$emit('filterChange', this.selectId, type)

      this.enabledFilter()
    },
  },
})
