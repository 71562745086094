var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlights-sns-balloon-parts" }, [
    _c("p", {
      staticClass: "highlights-sns-balloon-parts__message",
      domProps: {
        innerHTML: _vm._s(_vm.$tc("RaceVideoPage.highlights.comment.snsNote")),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }