var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-ranking-pane",
      on: { scroll: _vm.handlePaneScroll },
    },
    [
      _c("div", { staticClass: "mission-ranking-pane__container" }, [
        _c(
          "div",
          { ref: "header", staticClass: "mission-ranking-pane__header" },
          [
            _c("mission-ranking-header-section", {
              attrs: { isFixed: _vm.headerFixed },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "mission-ranking-pane__body",
            style: { "padding-top": _vm.bodyOffsetSize + "px" },
          },
          [
            _c(
              "p",
              { staticClass: "mission-ranking-pane__update-description" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc("MissionPage.RankingPage.calcDateDescription")
                    ) +
                    " "
                ),
              ]
            ),
            _c("p", { staticClass: "mission-ranking-pane__update-date" }, [
              _c(
                "span",
                { staticClass: "mission-ranking-pane__update-date-label" },
                [
                  _vm._v(
                    _vm._s(_vm.$tc("MissionPage.RankingPage.calcDate")) + "："
                  ),
                ]
              ),
              _c(
                "span",
                { staticClass: "mission-ranking-pane__update-date-data" },
                [_vm._v(_vm._s(_vm.aggregationDate))]
              ),
            ]),
            _vm._l(_vm.pointRankings, function (pointRanking) {
              return _c(
                "div",
                {
                  key: pointRanking.key,
                  staticClass: "mission-ranking-pane__block",
                },
                [
                  _c("mission-ranking-list-section", {
                    attrs: {
                      pointRanking: pointRanking,
                      retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
                    },
                    on: {
                      onClickSnsButton: _vm.openSnsShareModal,
                      onClickShareIcon: _vm.openOsShareModal,
                      savePostSnsMissionHistory: _vm.savePostSnsMissionHistory,
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "mission-ranking-pane__background" }, [
        _c("img", {
          staticClass: "mission-ranking-pane__background-image",
          attrs: { src: _vm.bgImagePath, alt: "" },
        }),
      ]),
      _vm.shareRankingImageView
        ? _c(
            "div",
            { staticClass: "mission-ranking-pane__share" },
            [
              _c("mission-ranking-share-section", {
                attrs: {
                  visiblePostSnsModal: _vm.visiblePostSnsModal,
                  shareImageUrl: _vm.shareImageUrl,
                  backgroundImage: _vm.backgroundImage,
                  backgroundImagePath: _vm.backgroundImagePath,
                  rankingUserImageData: _vm.rankingUserImageData,
                  shareRankingImageInfo: _vm.shareRankingImageInfo,
                },
                on: {
                  onClickPostSns: _vm.postSns,
                  onClickOk: _vm.closeSnsPostModal,
                  openSnsPostModal: _vm.openSnsPostModal,
                  handleImageDownloadClicked: _vm.downloadSnsShareImage,
                  onClickCancel: _vm.onClickCancel,
                  savePostSnsMissionHistory: _vm.savePostSnsMissionHistory,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }