import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { orderBy } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import useContractInfo from '@/store/hook/useContractInfo'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import BillingInfoDocument, {
  PaymentStatusType,
  DeclineInfoType,
  SettlementFailureHistoryType,
} from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'
import I18n from '@/locales/I18n'
import usePeriodOfUse from '@/components/MypageContractPage/hook/usePeriodOfUse'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * プラン契約履歴のデータ型
 */
export type PlanContractLogDataType = {
  /** ソート用の日付 */
  dateNum: number
  /** 日付 */
  date: string
  /** 内容 */
  detail: string
  /** 備考 */
  note: string
  /** エラーかどうか */
  error: boolean
}

/**
 * プラン契約履歴表示用の準備データの型
 * プランごとに決済金額を表示するため、プラン単位で請求情報をまとめたもの
 */
type PrepareForDisplayBillingInfoType = {
  contractPlanId?: string
  price?: number
  /** 契約プランにおける請求範囲の開始日時 */
  startDate?: number | null
  /** 契約プランにおける請求範囲の終了日時 */
  endDate?: number | null
  usageDetails?: Array<{
    contractDays: number
    usage: number
    pricePerUsage: number
  }>
  id?: string
  /** 請求対象年月 */
  billingTargetYearMonth: number | null
  /** 請求情報の決済ステータス */
  paymentStatus: PaymentStatusType | null
  /** 請求情報の決済日時 */
  paymentDateTime: number | null | undefined
  /** 決済失敗日時 */
  settlementFailureDate: number | undefined
  /** 請求情報の決済失敗情報 */
  declineInfo: DeclineInfoType | undefined
}

/**
 * マイページご契約内容確認画面の機能を提供する。
 */
export default function useMypageContractLog() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { ownOrganization, contractPlans, fetchTargetContractPlan } = contractInfoStore
  const { fetchContractInfo, ownContractInfo } = useContractInfo()
  const { fetchFixedBillingInfo, ownBillingInfoData } = useBillingInfo()
  const { getDisplayDate } = useDisplayDependingOnLang()
  const { getPeriodOfUseForTargetBilling } = usePeriodOfUse()
  /**
   * 今までに契約したプラン（キャンセル以外のプラン）
   */
  const contractedPlans = computed(() =>
    ownContractInfo.value.plans?.filter((plan) => {
      const hasCanceled = plan.startDate && plan.endDate && plan.startDate > plan.endDate
      return !hasCanceled
    }),
  )

  /**
   * 表示する日付
   */
  const displayDate = (date: number | undefined | null) => getDisplayDate(date)

  /**
   * プランマスタの情報を取得
   */
  const getPlanMaster = (contractPlanId: string | null | undefined) =>
    contractPlans.value.find((plan) => plan.contractPlanId === contractPlanId)

  /**
   * 対象ユーザーの決済が実行された請求情報を取得
   */
  const getUserSettledBillingInfo = () => {
    const data: Array<PrepareForDisplayBillingInfoType> = ownBillingInfoData.value
      ? ownBillingInfoData.value
          .map((billing: BillingInfoDocument) =>
            billing.plans
              ?.filter(
                (billingPlan) =>
                  // 請求金額が1円以上の請求情報を抽出
                  billingPlan?.price && billingPlan.price > 0,
              )
              .map((settledBillingPlan) => ({
                ...settledBillingPlan,
                // 請求対象年月
                billingTargetYearMonth: billing.billingTargetYearMonth,
                // 請求情報の決済ステータス
                paymentStatus: billing.paymentStatus,
                // 請求情報の決済日時
                paymentDateTime: billing.paymentDateTime,
                /**
                 * 請求情報の決済失敗日時
                 * 決済に失敗した場合、最後に決済に失敗した日時=更新日時となる
                 */
                settlementFailureDate: billing._lastModifiedDate,
                // 請求情報の決済失敗情報
                declineInfo: billing.declineInfo,
              })),
          )
          .flat()
          .filter((v): v is PrepareForDisplayBillingInfoType => v !== undefined)
      : []
    return data
  }

  /**
   * 決済失敗履歴を取得
   * @returns
   *
   */
  const getFailureLogDataFromBilling = () =>
    ownBillingInfoData.value
      ? ownBillingInfoData.value
          .filter((v) => v.settlementFailureHistories)
          .map((billing: BillingInfoDocument) =>
            billing.settlementFailureHistories
              ?.filter((v) => v.date)
              .map((history: SettlementFailureHistoryType) => ({
                dateNum: history.date ?? 0,
                date: displayDate(history.date),
                detail: I18n.tc('MypagePage.MypageContract.logPage.logText.billingError.detail'),
                note: billing.declineInfo?.description ?? '',
                error: true,
              })),
          )
          .flat()
          .filter((v): v is PlanContractLogDataType => v !== undefined)
      : []

  /**
   * 対象ユーザーの決済が実行された請求情報を取得
   */
  const getLogDataFromBilling = (userSettledBillingInfo: Array<PrepareForDisplayBillingInfoType>) =>
    userSettledBillingInfo
      .filter(
        (billingInfo) =>
          billingInfo.paymentStatus === 'Complete' || billingInfo.paymentStatus === 'Error',
      )
      .map((settledBilling) => {
        // エラーかどうか
        const isError = settledBilling.paymentStatus === 'Error'
        // 決済日付
        const settlementDate = isError
          ? settledBilling.settlementFailureDate
          : settledBilling.paymentDateTime
        // プランマスタ情報を取得
        const targetPlan = getPlanMaster(settledBilling.contractPlanId)
        // プラン名
        const planName = targetPlan?.name[I18n.locale] || ''
        // 決済金額（1円以上50円未満の場合は50円が引き落とされるため、この場合は請求履歴に50円が表示されるようにする）
        const settlementAmountNum =
          settledBilling.price && settledBilling.price > 0 && settledBilling.price < 50
            ? 50
            : settledBilling.price
        const settlementAmount = settlementAmountNum ? settlementAmountNum.toLocaleString() : ''
        // 利用分の期間
        const periodOfUse = getPeriodOfUseForTargetBilling(
          targetPlan?.planGroupId,
          settledBilling.startDate,
          settledBilling.endDate,
        ) as string

        // 備考（請求に関する情報）
        let settlementNote = ''
        if (planName && settlementAmount) {
          settlementNote = I18n.t('MypagePage.MypageContract.logPage.logText.billingSuccess.note', {
            plan: planName,
            amount: settlementAmount,
          }).toString()
        }

        if (settlementNote && periodOfUse) {
          /**
           * 備考（利用期間）
           * 備考（請求に関する情報）が存在し、かつ利用期間が存在する場合は備考（請求に関する情報）の右側に利用期間を表示する
           */
          const settlementNotePeriod = I18n.t(
            'MypagePage.MypageContract.logPage.logText.billingSuccess.notePeriod',
            {
              period: periodOfUse,
            },
          ).toString()

          settlementNote += ` ${settlementNotePeriod}`
        }

        return {
          dateNum: settlementDate ?? 0,
          date: isError ? displayDate(settlementDate) : displayDate(settlementDate),
          detail: isError
            ? I18n.tc('MypagePage.MypageContract.logPage.logText.billingError.detail')
            : I18n.tc('MypagePage.MypageContract.logPage.logText.billingSuccess.detail'),
          note: isError ? settledBilling.declineInfo?.description ?? '' : settlementNote,
          error: isError,
        }
      })

  /**
   * 契約情報契約プランの履歴（契約開始）を取得
   */
  const getLogDataFromContractPlanStart = () =>
    contractedPlans.value
      ? contractedPlans.value
          .filter((plan) => {
            // 契約開始していないプランは表示しない
            if (!(!!plan.startDate && plan.startDate < new Date().getTime())) {
              return false
            }

            // リードオンリープランは表示しない
            const planGroupId = getPlanMaster(plan.contractPlanId)?.planGroupId
            if (planGroupId === 'settlementReadonlyPlan') {
              return false
            }

            // 上記以外は表示する
            return true
          })
          .map((startedPlan) => {
            // 新規登録時のプランかどうか
            const isFirstPlan =
              startedPlan.startDate ===
              orderBy(ownContractInfo.value.plans, 'startDate')?.[0].startDate
            // プランマスタ情報を取得
            const targetPlanMaster = getPlanMaster(startedPlan.contractPlanId)
            // プラン名
            const planName = targetPlanMaster?.name[I18n.locale] || ''

            // 一つ前のプラン
            const previousPlan = orderBy(contractedPlans.value, 'endDate', 'desc')?.find(
              (plan) =>
                // 表示対象のプランを除く
                plan.contractPlanId !== startedPlan.contractPlanId &&
                plan.endDate &&
                startedPlan.startDate &&
                plan.endDate <= startedPlan.startDate,
            )
            // 一つ前のプランのプランマスタ情報を取得
            const previousPlanMaster = getPlanMaster(previousPlan?.contractPlanId)

            let detailText = ''
            if (previousPlanMaster?.planGroupId === 'settlementReadonlyPlan') {
              // リードオンリープランの次のプランの場合
              detailText =
                'MypagePage.MypageContract.logPage.logText.contractPlanStart.detailNextReadonly'
            } else if (isFirstPlan) {
              // 最初に契約したプランの場合
              detailText =
                'MypagePage.MypageContract.logPage.logText.contractPlanStart.detailRegister'
            } else {
              // プラン変更の場合
              detailText =
                'MypagePage.MypageContract.logPage.logText.contractPlanStart.detailChange'
            }

            return {
              dateNum: startedPlan?.startDate ?? 0,
              date: displayDate(startedPlan?.startDate),
              detail: I18n.t(detailText, { plan: planName }).toString(),
              note: '',
              error: false,
            }
          })
      : []

  /**
   * 契約情報契約プランの履歴（契約終了）を取得
   */
  const getLogDataFromContractPlanEnd = () =>
    contractedPlans.value
      ? contractedPlans.value
          .filter((plan) => {
            // 契約終了していないプランは表示しない
            if (!(!!plan.endDate && plan.endDate < new Date().getTime())) {
              return false
            }

            // リードオンリープランは表示しない
            const planGroupId = getPlanMaster(plan.contractPlanId)?.planGroupId
            if (planGroupId === 'settlementReadonlyPlan') {
              return false
            }

            // 上記以外は表示する
            return true
          })
          .map((endedPlan) => {
            // プランマスタ情報を取得
            const targetPlanMaster = getPlanMaster(endedPlan.contractPlanId)
            // プラン名
            const planName = targetPlanMaster?.name[I18n.locale] || ''
            /**
             * 「XXXが終了しました。」と表示するプラン
             * 対象プラン：無料プラン、クーポンプラン、トライアルプラン
             */
            const isDisplayPlanEnd =
              targetPlanMaster?.planGroupId === 'freePlan' ||
              targetPlanMaster?.planGroupId === 'trialPlan' ||
              targetPlanMaster?.planGroupId === 'trialPlanForAnnual' ||
              targetPlanMaster?.planGroupId === 'limitedTimePaidPlan' ||
              targetPlanMaster?.planGroupId === 'limitedTimePaidPlanForAnnual'

            // 一つ後のプラン
            const nextPlan = orderBy(contractedPlans.value, 'startDate')?.find(
              (plan) =>
                // 表示対象のプランを除く
                plan.contractPlanId !== endedPlan.contractPlanId &&
                plan.startDate &&
                endedPlan.endDate &&
                plan.startDate >= endedPlan.endDate,
            )
            // 一つ後のプランのプランマスタ情報を取得
            const nextPlanMaster = getPlanMaster(nextPlan?.contractPlanId)

            let detailText = ''
            if (nextPlanMaster?.planGroupId === 'settlementReadonlyPlan') {
              // リードオンリープランの前のプランの場合
              detailText =
                'MypagePage.MypageContract.logPage.logText.contractPlanEnd.detailPreviousReadonly'
            } else if (isDisplayPlanEnd) {
              // 「XXXが終了しました。」と表示するプランの場合
              detailText = 'MypagePage.MypageContract.logPage.logText.contractPlanEnd.detailEnd'
            } else {
              // 「XXXを解約しました。」と表示するプランの場合
              detailText = 'MypagePage.MypageContract.logPage.logText.contractPlanEnd.detailCancel'
            }

            return {
              dateNum: endedPlan?.endDate ?? 0,
              date: displayDate(endedPlan?.endDate),
              detail: I18n.t(detailText, { plan: planName }).toString(),
              note: '',
              error: false,
            }
          })
      : []

  /**
   * マイページに表示するプラン契約履歴データを生成
   */
  const createPlanContractLogData = () => {
    /** 対象ユーザーの決済が実行された請求情報 */
    const userSettledBillingInfo = getUserSettledBillingInfo()

    /** 請求情報の履歴 */
    const logDataFromBilling = getLogDataFromBilling(userSettledBillingInfo)

    /** 決済失敗履歴 */
    const failureLogDataFromBilling = getFailureLogDataFromBilling()

    /** 契約情報契約プランの履歴（契約開始） */
    const logDataFromContractPlanStart = getLogDataFromContractPlanStart()

    /** 契約情報契約プランの履歴（契約終了） */
    const logDataFromContractPlanEnd = getLogDataFromContractPlanEnd()

    return orderBy(
      logDataFromBilling
        .concat(failureLogDataFromBilling)
        .concat(logDataFromContractPlanStart)
        .concat(logDataFromContractPlanEnd),
      'dateNum',
    ).reverse()
  }

  /**
   * プラン情報にクーポンのプランを追加する
   *
   */
  const addCouponPlanToContractPlans = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const planData of ownContractInfo.value.plans ?? []) {
      const targetPlan = getPlanMaster(planData.contractPlanId)
      if (planData.couponId && !targetPlan) {
        // クーポン利用中以外のユーザーの場合、ContractInfoStoreのcontractPlansにクーポンプランが入っていないため、過去に利用したことがあるクーポンのプランを取得する
        // eslint-disable-next-line no-await-in-loop
        await fetchTargetContractPlan(planData.contractPlanId)
      }
    }
  }

  /**
   * マイページに表示するプラン契約履歴情報を取得
   */
  const fetchPlanContractLogData = async () => {
    /* 現在利用している契約情報、請求情報とプラン一覧を取得 */
    await Promise.all([fetchContractInfo(), fetchFixedBillingInfo()])

    const { contractInfoId } = ownContractInfo.value

    if (!ownOrganization.value || !contractInfoId) {
      return null
    }

    /* 過去に利用したことがあるクーポンのプランを取得し、プランマスタに追加する */
    await addCouponPlanToContractPlans()

    /* プラン契約履歴表示用のデータを作成し、返却する */
    return createPlanContractLogData()
  }

  return {
    fetchPlanContractLogData,
  }
}
