var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "payment-schedule-section" },
    _vm._l(_vm.paymentSchedule, function (schedule, index) {
      return _c(
        "div",
        {
          key: "schedule-" + index,
          staticClass: "payment-schedule-section__schedule",
        },
        [
          _c(
            "h4",
            { staticClass: "payment-schedule-section__schedule-title" },
            [
              schedule.title
                ? _c("span", [_vm._v(_vm._s(schedule.title))])
                : _vm._e(),
              schedule.period
                ? _c("small", [_vm._v("(" + _vm._s(schedule.period) + ")")])
                : _vm._e(),
            ]
          ),
          schedule.price
            ? _c("p", {
                staticClass: "payment-schedule-section__schedule-price",
                domProps: { textContent: _vm._s(schedule.price) },
              })
            : _vm._e(),
          schedule.description
            ? _c("p", {
                staticClass: "payment-schedule-section__schedule-description",
                domProps: { textContent: _vm._s(schedule.description) },
              })
            : _vm._e(),
          schedule.note
            ? _c("p", {
                staticClass: "payment-schedule-section__schedule-note",
                domProps: { textContent: _vm._s(schedule.note) },
              })
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }