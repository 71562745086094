var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "award-winner-section",
      class: { "award-winner-section--favorite": _vm.winnerData.favorite },
    },
    [
      _c("div", { staticClass: "award-winner-section__data" }, [
        _c("figure", { staticClass: "award-winner-section__badge" }, [
          _c("img", { attrs: { src: _vm.badgeImage, alt: "" } }),
        ]),
        _c("p", [
          _c("span", {
            staticClass: "award-winner-section__name",
            domProps: { textContent: _vm._s(_vm.winnerData.name) },
          }),
          _vm.category === "PIT_CREW" && _vm.winnerData.time
            ? _c("span", {
                staticClass: "award-winner-section__time",
                domProps: { textContent: _vm._s(_vm.winnerData.time) },
              })
            : _vm._e(),
          _vm.winnerData.favorite
            ? _c("span", { staticClass: "award-winner-section__favorite" }, [
                _c("i", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/AwardPage/icon_favorite.svg"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" " + _vm._s(_vm.favoriteText) + " "),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("figure", { staticClass: "award-winner-section__image" }, [
        _c("img", { attrs: { src: _vm.winnerData.image, alt: "" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }