import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * アワードランキングレコードの型
 */
export type AwardRankingRecordType = {
  // 項目名日本語
  nameJa: string | null
  // 項目名英語
  nameEn: string | null
  // 順位
  ranking: number | null
  // 値
  value: string | null
  // 値(表示用)
  valueDisp: string | null
}

/**
 * ランキングの種類
 */
export type AwardRankingCategoryType = 'RECOMMEND_DRIVER' | 'RECOMMEND_TEAM' | 'PIT_CREW'

/**
 * AWARDデータを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3651076374/10.AWARD
 */
export default class AwardDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<AwardDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * 年度
   * DynamoDB award-dataのパーティションキー
   *
   */
  seasonYear = ''

  /**
   * ランキングデータ種別
   */
  rankingDataType: AwardRankingCategoryType | null = null

  /**
   * ランキングデータ
   */
  rankings: Array<AwardRankingRecordType> | null = null

  /**
   * AWARDデータとして利用するかどうか
   */
  useAsAwardData: boolean | null = true

  /**
   * 集計日時
   * DynamoDB award-dataのソートキー
   * データを集計ではなく、データをインポートして登録する処理に変更したため、インポート日時がセットされる
   */
  aggregationDate = 0
}
