var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notice-highlight-section",
      class: { "notice-highlight-section--is-pc": _vm.isPc },
    },
    [
      _c("div", { staticClass: "notice-highlight-section__modal" }, [
        _c("p", { staticClass: "notice-highlight-section__new" }, [
          _vm._v("NEW"),
        ]),
        _c("h3", { staticClass: "notice-highlight-section__title" }, [
          _vm._v(
            " " + _vm._s(_vm.$tc("RaceVideoPage.highlights.notice.title")) + " "
          ),
        ]),
        _c("p", { staticClass: "notice-highlight-section__message" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$tc("RaceVideoPage.highlights.notice.message")) +
              " "
          ),
        ]),
        _c(
          "button",
          {
            staticClass:
              "notice-highlight-section__button notice-highlight-section__button--light--secondary",
            on: { click: _vm.handleOkClicked },
          },
          [_vm._v(" OK ")]
        ),
      ]),
      _c("div", { staticClass: "notice-highlight-section__bg-top" }),
      _c("div", { staticClass: "notice-highlight-section__bg-right" }),
      _c("div", { staticClass: "notice-highlight-section__bg-bottom" }),
      _c("div", { staticClass: "notice-highlight-section__bg-left" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }