import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import PasswordChangeTokenDocument from '@/store/stores/collectionModule/documents/user/PasswordChangeTokenDocument'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import StoreUtil from '@/store/StoreUtil'

/**
 * パスワード変更を操作するための処理を取得する。
 */
export default function usePasswordChangeToken() {
  // Collection modules
  const passwordChangeCollectionModule = CollectionModule.createStore(DocumentWrapper)
  const loginStore = StoreUtil.useStore('LoginStore')

  /**
   * パスワードを更新する。
   * @return { Promise<APIResponse> } APIレスポンス
   */
  const register = (password: string) =>
    passwordChangeCollectionModule.save(
      new PasswordChangeTokenDocument({
        _id: loginStore.loginId,
        password,
      }),
      {
        url: `${process.env.VUE_APP_API_BASE_URL as string}/${loginStore.orgId}/manage/user/${
          loginStore.loginId
        }/password`,
      },
    )

  return {
    register,
  }
}
