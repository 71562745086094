import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import MailAddressChangeTokenDocument from '@/store/stores/collectionModule/documents/user/MailAddressChangeTokenDocument'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import StoreUtil from '@/store/StoreUtil'

/**
 * メールアドレス変更を操作するための処理を取得する。
 */
export default function useMailAddressChangeToken() {
  // Collection modules
  const validateEmailModule = CollectionModule.createStore(DocumentWrapper)
  const mailChangeCollectionModule = CollectionModule.createStore(MailAddressChangeTokenDocument)
  const loginStore = StoreUtil.useStore('LoginStore')
  // API Requests
  /**
   * 指定されたメールアドレスが、すでに登録済みかどうかを判定する。
   * @param mailAddress メールアドレス
   * @return {Promise<APIResponse>} APIレスポンス
   */
  const checkEmailAddressDuplicated = (mailAddress: string) =>
    validateEmailModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        loginStore.orgId
      }/manage/users/validate/mail_address/${mailAddress}`,
    })

  /**
   * メールアドレス変更のトークンを取得する。
   * @return { Promise<APIResponse> } APIレスポンス
   */
  const emailAddressChangeToken = (mailAddress: string) =>
    mailChangeCollectionModule.save(
      new MailAddressChangeTokenDocument({
        mailAddress,
      }),
      {
        url: `${process.env.VUE_APP_API_BASE_URL as string}/${
          loginStore.orgId
        }/manage/mail_address_change_token`,
      },
    )

  /**
   * メールアドレス変更のトークンを取得する。
   * @return { Promise<APIResponse> } APIレスポンス
   */
  const emailAddressChange = (tokenId: string) =>
    // CollectionModule.saveでput処理を走らせるためにidを指定する。しかしsaveメソッドを呼ぶ際にoptions.urlを引数に含めているため、保存処理の際idは無視されるので、とりあえずここではidに'dummy'をセットしている。
    mailChangeCollectionModule.save(
      new MailAddressChangeTokenDocument({
        _id: 'dummy',
      }),
      {
        url: `${
          process.env.VUE_APP_API_BASE_URL as string
        }/manage/mail_address_change_token/${tokenId}/change`,
      },
    )

  return {
    checkEmailAddressDuplicated,
    emailAddressChangeToken,
    emailAddressChange,
  }
}
