var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "home-lock-icon",
      attrs: {
        width: "14",
        height: "15",
        viewBox: "0 0 14 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M1.16675 6.62516C1.16675 6.14191 1.5585 5.75016 2.04175 5.75016H3.50008V4.5835C3.50008 2.6505 5.06709 1.0835 7.00008 1.0835C8.93308 1.0835 10.5001 2.6505 10.5001 4.5835V5.75016H11.9584C12.4417 5.75016 12.8334 6.14191 12.8334 6.62516V13.0418C12.8334 13.5251 12.4417 13.9168 11.9584 13.9168H2.04175C1.5585 13.9168 1.16675 13.5251 1.16675 13.0418V6.62516ZM9.33342 5.75016H4.66675V4.5835C4.66675 3.29483 5.71142 2.25016 7.00008 2.25016C8.28875 2.25016 9.33342 3.29483 9.33342 4.5835V5.75016ZM6.56258 10.0404C6.13502 9.86729 5.83342 9.44811 5.83342 8.9585C5.83342 8.31416 6.35575 7.79183 7.00008 7.79183C7.64441 7.79183 8.16675 8.31416 8.16675 8.9585C8.16675 9.44811 7.86514 9.86729 7.43758 10.0404V11.8752H6.56258V10.0404Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }