import { computed, ref, toRefs, watch } from '@vue/composition-api'
import _, { now, throttle } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import PlayerLiveTiming, {
  PlayerLiveTimingDataType,
} from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'
import TelemetryDocument, {
  TelemetryDataType,
} from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'
import { SectorNameMap } from '@/store/hook/useLiveTiming'
import Logger from '@/util/logger/Logger'
import Const from '@/util/Const'

/**
 * バトル状態データの型。
 * バトル状態を一意に識別するグループID(BATTLE_GROUP_${連番})をキーにして、バトル状態となっている車両のポジションの配列を値として保持する。
 */
export type BattleDataType = Record<string, number[]>

/**
 * ランキング画面で参照する必要のあるストアデータの取得処理を提供する。
 * @param watchVideoStatus 動画再生位置の変化をwatchするかどうか。
 * 動画再生に応じてライブタイミングデータの取得を行う場合に true を指定する。
 */
export default function useRanking(watchVideoStatus = false) {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

  const {
    computeActualTimeForVideoPlaybackPosition,
    players,
    participatingPlayers,
    getCurrentLiveTiming,
    selectedPlayerId,
    targetRace,
    liveTimingStore,
    allPlayerTelemetryStore,
  } = raceVideoPageStore

  const { videoStatus, preloadFetchingRange } = toRefs(raceVideoPageStore.raceVideoPageState)
  const isLeaderFlagChecked = ref(false)

  // ライブタイミングデータ
  const { fetchLiveTiming, clearLiveTiming, isLiveTimingFetched } = liveTimingStore

  // 最後にライブタイミングデータのフェッチを行なった日時
  let lastLiveTimingDataFetchTime = 0
  // ライブタイミングデータのフェッチを行なっているかどうか
  let liveTimingDataFetchingNow = false
  // 最後に全車両テレメトリーデータのフェッチを行なった日時
  let lastAllPlayerTelemetryFetchTime = 0
  // 全車両テレメトリーデータのフェッチを行なっているかどうか
  let allPlayerTelemetryFetchingNow = false
  // データを保持する最小期間。メモリに保持時ているデータがこの期間よりも短くなった場合に、データフェッチを行う
  const minimumDataRetentionPeriods = 3 * 1000

  if (watchVideoStatus) {
    watch(
      videoStatus,
      throttle(async () => {
        if (!targetRace.value) {
          return
        }

        // 現在の動画の再生時間の実時間を取得する。
        const from = computeActualTimeForVideoPlaybackPosition(true, 1000)

        // 現在の動画の再生位置のライブタイミングデータを保持しているかチェックし、保持していない場合、APIを呼び出して取得する。
        if (
          !isLiveTimingFetched(from + minimumDataRetentionPeriods) &&
          now() - lastLiveTimingDataFetchTime > 2000 &&
          !liveTimingDataFetchingNow
        ) {
          // 現在の再生位置から、LiveTimingFetchingRange で指定された範囲までの範囲を取得する
          const to = from + preloadFetchingRange.value
          // 再生位置から3秒先のライブタイミングデータを保持していない場合、取得を開始
          // ただし、ライブ配信時など、まだ、先の時間帯にライブタイミングデータがそもそも存在しない場合もあるため、取得は2秒に一度に限定する
          lastLiveTimingDataFetchTime = now()
          liveTimingDataFetchingNow = true
          await fetchLiveTiming(targetRace.value, from, to).finally(() => {
            liveTimingDataFetchingNow = false
          })
        }

        // 取得先の再生位置の全車両テレメトリーデータを保持しているかどうかを判定する
        const hasAllPlayerTelemetry = allPlayerTelemetryStore.isAllPlayerTelemetryFetched(
          from + minimumDataRetentionPeriods,
        )
        // Logger.debug(`fetch all player telemetry data: isAllPlayerTelemetryFetched: ${hasAllPlayerTelemetry}`)
        if (
          !hasAllPlayerTelemetry &&
          now() - lastAllPlayerTelemetryFetchTime > 2000 &&
          !allPlayerTelemetryFetchingNow
        ) {
          // 取得開始位置から、LiveTimingFetchingRange で指定された範囲までの範囲を取得する
          const to = from + preloadFetchingRange.value
          // 再生位置からpreloadFetchingRangeで指定された秒数を加算した再生位置の全車両テレメトリーデータを保持していない場合、取得を開始
          // ただし、ライブ配信時など、まだ、その再生位置のテレメトリーデータがそもそも存在しない場合もあるため、取得は2秒に一度に限定する
          lastAllPlayerTelemetryFetchTime = now()
          allPlayerTelemetryFetchingNow = true
          // Logger.debug('Start fetch all player telemetry data')
          await allPlayerTelemetryStore
            .fetchAllPlayerTelemetry(targetRace.value, from, to)
            .finally(() => {
              // Logger.debug('Finish fetch all player telemetry data')
              allPlayerTelemetryFetchingNow = false
            })
        }
      }, 500),
      { deep: true },
    )
  }

  /**
   * 現在のフラッグの状態を返す。
   */
  const getCurrentFlagStatus = computed(() => {
    const currentLiveTiming = getCurrentLiveTiming.value
    if (currentLiveTiming?.scFlag) {
      return 'SC'
    }
    return currentLiveTiming?.flag ?? 'N'
  })

  /**
   * 指定された選手のライブタイミングにGAP値を設定する
   * @param playerRankingDataList 選手のライブタイミングのリスト
   * @param includeFirstRankDiff 先頭のライブタイミングのdiff値を計算に含めるかどうか
   * @param setNegativeValue GAP値をマイナス値で表示するかどうか
   */
  const setGapToPlayerLiveTimings = (
    playerRankingDataList: Array<PlayerLiveTimingDataType>,
    includeFirstRankDiff = false,
    setNegativeValue = false,
  ) => {
    let previousPlayerRankingData: PlayerLiveTimingDataType | null = null
    playerRankingDataList.forEach((playerRankingData) => {
      const targetPlayerRanking = playerRankingData
      if (previousPlayerRankingData) {
        const diffTime = includeFirstRankDiff
          ? previousPlayerRankingData.diffTime
          : playerRankingData.diffTime
        targetPlayerRanking.gapTime = `${(
          Number(previousPlayerRankingData.gapTime) + Number(diffTime)
        ).toFixed(3)}`

        const diffTimeInterval = includeFirstRankDiff
          ? previousPlayerRankingData.diffTimeIntervalForGapCalculation
          : playerRankingData.diffTimeIntervalForGapCalculation
        const gapTimeInterval =
          Number(previousPlayerRankingData.gapTimeInterval) + Number(diffTimeInterval)
        if (gapTimeInterval) {
          targetPlayerRanking.gapTimeInterval = `${gapTimeInterval.toFixed(3)}`
        } else {
          targetPlayerRanking.gapTimeInterval = ''
        }
      }
      previousPlayerRankingData = playerRankingData
    })
    if (setNegativeValue) {
      playerRankingDataList.forEach((playerRankingData) => {
        if (playerRankingData.gapTime) {
          const playerRanking = playerRankingData
          playerRanking.gapTime = `${(Number(playerRanking.gapTime) * -1).toFixed(3)}`
        }
      })
    }
  }

  /**
   * 現在の選手のライブタイミングデータのリストを返す。
   * リストは、選手のポジション順にソートされる。また、このメソッドでリストを生成するときに、GAPの値を計算して設定する。
   */
  const getCurrentPlayerRankingDataList = computed((): Array<PlayerLiveTimingDataType> => {
    const currentLiveTiming = getCurrentLiveTiming.value
    if (!currentLiveTiming?.liveTimings) {
      return participatingPlayers.value
        .filter(
          (player) =>
            // ライブタイミングデータの有無に関わらず選手リストに表示するデータについてはランキングデータを表示しない
            !player.getDisplayCarNo().includes(Const.CAR_NO_ALWAYS_SHOW),
        )
        .map((filteredPlayer) => PlayerLiveTiming.createEmptyData(filteredPlayer))
    }

    // ポジション順にソートする
    const currentPlayerRankingDataList = _.sortBy(
      currentLiveTiming.liveTimings,
      (playerLiveTiming) =>
        Number(playerLiveTiming.POS) ? Number(playerLiveTiming.POS) : Number.MAX_SAFE_INTEGER,
    ).reduce((playerRankingList, playerLiveTiming) => {
      const targetPlayer = players.value.find((player) =>
        player.isRelatedCar(playerLiveTiming.CARNO),
      )
      if (!targetPlayer || !playerLiveTiming.CARNO) {
        // 対象のライブタイミングに関連する選手が見つからなかった場合、そのライブタイミングはリストに含めない（ランキング画面に正しくデータを表示できないため）
        return playerRankingList
      }
      playerRankingList.push(
        playerLiveTiming.convertPlayerLiveTiming(targetPlayer, currentLiveTiming, SectorNameMap),
      )
      return playerRankingList
    }, [] as Array<PlayerLiveTimingDataType>)

    // GAP値を設定する
    const selectedPlayerIndex = currentPlayerRankingDataList.findIndex(
      (playerRankingData) => playerRankingData.id === selectedPlayerId.value,
    )
    if (isLeaderFlagChecked.value || selectedPlayerIndex === 0) {
      // 先頭からのGAP値を求める
      setGapToPlayerLiveTimings(currentPlayerRankingDataList)
    } else {
      // 選手が選択されている場合、その選手を基点として、上位の選手と、下位の選手のGAP値をそれぞれ計算する

      // 選択されている選手を基点にして、上位の選手のGAP値を設定する
      // 選択されている選手を先頭にしたライブタイミングのリストを作成して、GAP設定のメソッドに渡す
      // (その場合、選択選手のdiff値を計算に利用するため、includeFirstRankDiffをtrueに設定する)
      const upperSelectedPlayerLiveTimingList = currentPlayerRankingDataList
        .slice()
        .splice(0, selectedPlayerIndex + 1)
      setGapToPlayerLiveTimings(upperSelectedPlayerLiveTimingList.reverse(), true, true)

      // 選択されている選手を基点にして、下位の選手のGAP値を設定する
      // 選択されている選手を先頭にしたライブタイミングのリストを作成して、GAP設定のメソッドに渡す。
      const lowerSelectedPlayerLiveTimingList = currentPlayerRankingDataList
        .slice()
        .splice(selectedPlayerIndex)
      setGapToPlayerLiveTimings(lowerSelectedPlayerLiveTimingList)
    }
    return currentPlayerRankingDataList
  })

  /**
   * 直前に表示していたテレメトリーデータの型定義。
   */
  type PreviousTelemetryType = {
    /** テレメトリーデータ */
    telemetryData: TelemetryDataType | null
    /** テレメトリーデータの動画再生位置 */
    movieTime: number
  }

  /**
   * 選手毎の直前に表示していたテレメトリーデータ。
   * 選手の車両番号をキーにして、その選手の直前に表示していたテレメトリーデータを値として保持する。
   */
  const previousTelemetryByPlayer = {} as Record<string, PreviousTelemetryType>

  /**
   * 選手毎のテレメトリーデータのマップ。
   * 選手の車両番号をキーにして、その選手のテレメトリーデータを値として保持する。
   */
  type PlayerTelemetryMap = Record<string, TelemetryDataType>

  /**
   * 現在の再生位置の選手毎のテレメトリーデータを返す。
   */
  const getCurrentPlayerTelemetry = computed((): PlayerTelemetryMap => {
    // 現在のライブタイミングの時刻に一致するテレメトリーデータを取得する
    const currentTime = videoStatus.value?.currentTime || 0
    const actualTimeRounded = computeActualTimeForVideoPlaybackPosition(true, 1000)

    // 車両番号をキーにして、その選手のテレメトリーデータを値に設定したマップオブジェクトを生成する
    return getCurrentPlayerRankingDataList.value.reduce<PlayerTelemetryMap>(
      (map, playerLiveTiming) => {
        const playerTelemetry = allPlayerTelemetryStore.getPlayerTelemetry(
          playerLiveTiming.carNo,
          actualTimeRounded,
        )
        if (!previousTelemetryByPlayer[playerLiveTiming.carNo]) {
          previousTelemetryByPlayer[playerLiveTiming.carNo] = {
            telemetryData: TelemetryDocument.EMPTY_TELEMETRY_DATA,
            movieTime: 0,
          }
        }
        const previous = previousTelemetryByPlayer[playerLiveTiming.carNo]

        // テレメトリーデータが見つからなかった場合、直前に表示していたテレメトリーデータを返す。
        // テレメトリーデータが途切れてしまう場合があり、その際に、テレメトリーデータの表示が0値になってしまうことを防ぐため、
        // 抜けがあった場合でも、5秒間は直前に表示していたテレメトリーデータを返す。
        if (Math.abs(previous.movieTime - currentTime) > 5) {
          previous.telemetryData = TelemetryDocument.EMPTY_TELEMETRY_DATA
          previous.movieTime = 0
        }
        const telemetryData = playerTelemetry
          ? playerTelemetry.telemetryData
          : previous.telemetryData

        if (!telemetryData) {
          return map
        }

        const hashmap = map
        hashmap[playerLiveTiming.carNo] = telemetryData

        previousTelemetryByPlayer[playerLiveTiming.carNo] = {
          telemetryData,
          movieTime: currentTime,
        }

        return hashmap
      },
      {},
    )
  })

  /**
   * 現在のBATTLE状態を返す。
   */
  const getCurrentBattleData = computed(() => {
    const currentPlayerRankingDataList = getCurrentPlayerRankingDataList.value
    return currentPlayerRankingDataList.reduce<BattleDataType>((map, playerLiveTimingData) => {
      const hashmap = map
      const position = Number(playerLiveTimingData.position)
      if (position && playerLiveTimingData.battleId && playerLiveTimingData.valid) {
        if (!hashmap[playerLiveTimingData.battleId]) {
          hashmap[playerLiveTimingData.battleId] = []
        }
        hashmap[playerLiveTimingData.battleId].push(position)
      }
      return hashmap
    }, {})
  })

  /**
   * ランキング画面のデータをクリアする。
   *
   * ライブタイミングと、全車両テレメトリーデータをクリアする。
   */
  const clearRankingPlayerData = () => {
    clearLiveTiming()
    allPlayerTelemetryStore.clearAllPlayerTelemetry()
    Logger.debug('useRanking:clearRankingPlayerData')
  }

  return {
    getCurrentPlayerRankingDataList,
    getCurrentPlayerTelemetry,
    getPlayerTelemetry: allPlayerTelemetryStore.getPlayerTelemetry,
    getCurrentFlagStatus,
    clearRankingPlayerData,
    getCurrentBattleData,
    isLeaderFlagChecked,
  }
}
