



























import { defineComponent, PropType } from '@vue/composition-api'
import FieldsetConfirmParts from '@/components/common/form/FieldsetConfirmParts.vue'
import { MyContractDataType } from '@/components/MypageContractPage/hook/useMypageContract'
import I18n from '@/locales/I18n'

/**
 * マイページ: 契約情報 現在のプラン セクション
 */
export default defineComponent({
  name: 'MypageContractDetailsSection',
  components: {
    FieldsetConfirmParts,
  },
  props: {
    myContractData: {
      type: Object as PropType<MyContractDataType>,
      default: () => ({}),
    },
  },
  setup(props) {
    /* 次の支払い金額の利用期間 */
    const nextPaymentUsagePeriodText = I18n.t(
      'MypagePage.MypageContract.detailsPage.nextPaymentUsagePeriod',
      {
        period: props.myContractData.nextPaymentPeriod,
      },
    ).toString()

    /* 次の支払いに表示するテキスト */
    let nextPaymentText =
      props.myContractData.nextPaymentDate && props.myContractData.nextPaymentPrice
        ? `${props.myContractData.nextPaymentDate}<br>${props.myContractData.nextPaymentPrice}`
        : ''

    if (nextPaymentText && props.myContractData.nextPaymentPeriod) {
      // 利用期間が存在する場合は利用期間を表示する
      nextPaymentText += ` ${nextPaymentUsagePeriodText}`
    }

    return {
      nextPaymentText,
    }
  },
})
