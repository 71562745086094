var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-up" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible || _vm.scrollView,
            expression: "visible || scrollView",
          },
        ],
        staticClass: "action-buttons-section",
      },
      [
        _c(
          "div",
          { staticClass: "action-buttons-section__inner" },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }