<template>
  <svg class="icon-circuit" viewBox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7117 21.5373C11.4128 21.4504 11.0976 21.6132 11.0032 21.9032C10.7754 22.6029 9.80069 22.698 9.4354 22.0562L0.686072 6.68471C0.304022 6.01349 0.528025 5.1679 1.19557 4.76138L1.5131 4.56801C2.17791 4.16316 3.05102 4.33502 3.50296 4.9597L12.8668 17.9026C13.2769 18.4693 14.1201 18.5182 14.5959 18.0027L15.403 17.1285C16.2231 16.2402 16.2118 14.8877 15.3769 14.0126L5.11345 3.25292C4.59407 2.70843 4.93615 1.81913 5.69444 1.7425L11.0309 1.20328C12.7809 1.02645 14.4725 1.87596 15.342 3.36827L18.5429 8.86211C19.1384 9.88421 20.4437 10.2814 21.5291 9.77082L22.1525 9.47755C23.5288 8.83012 25.1177 9.84072 25.0659 11.3307L24.8533 17.4506C24.8271 18.2057 25.7358 18.6286 26.3207 18.1334L34.6508 11.0804C35.0909 10.7078 35.7365 10.6813 36.2071 11.0166C36.5709 11.276 36.6008 11.7952 36.2689 12.0926L19.0024 27.5636C18.5334 27.9839 17.7864 27.8736 17.4658 27.3368L15.0725 23.3293C14.6942 22.6959 14.0829 22.2268 13.3628 22.0174L11.7117 21.5373Z"
      stroke="currentColor"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionMotegiIcon',
})
</script>

<style scoped lang="scss">
.icon-circuit {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
