




import { defineComponent } from '@vue/composition-api'

/**
 * HOME SNSコンテンツ Loader Parts
 */
export default defineComponent({
  name: 'SnsPostLoaderParts',
})
