var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "header-pane",
      class: [
        _vm.colorType ? "header-pane--" + _vm.colorType : null,
        { "header-pane--has-nav": _vm.hasHeaderNav },
      ],
    },
    [
      _c("p", { staticClass: "header-pane__logo" }, [
        _c(
          "svg",
          {
            staticClass: "header-pane__img",
            attrs: {
              width: "48",
              height: "16",
              viewBox: "0 0 48 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("title", [_vm._v("SFgo")]),
            _c("path", {
              staticClass: "header-pane__img__text",
              attrs: {
                d: "M33.6503 2.84039L36.7009 0.637207H2.71858C1.21715 0.637207 0 1.95579 0 3.58234V6.52747C0 8.15403 1.21715 9.47261 2.71858 9.47261H13.5929V12.4177H5.31256C4.50748 12.4177 3.72042 12.6759 3.05056 13.1597L0 15.3629H13.5929C15.0944 15.3629 16.3115 14.0443 16.3115 12.4177V9.47261C16.3115 7.84605 15.0944 6.52747 13.5929 6.52747H2.71858V3.58234H31.3883C32.1934 3.58234 32.9805 3.32418 33.6503 2.84039Z",
                fill: "white",
              },
            }),
            _c("path", {
              staticClass: "header-pane__img__text",
              attrs: {
                d: "M21.7487 6.52747H32.2832L29.2326 8.73065C28.5628 9.21444 27.7757 9.47261 26.9706 9.47261H21.7487V15.3629H19.0301V9.47261C19.0301 7.84605 20.2472 6.52747 21.7487 6.52747Z",
                fill: "white",
              },
            }),
            _c("ellipse", {
              attrs: {
                cx: "41.2035",
                cy: "8.00004",
                rx: "7.36283",
                ry: "6.79646",
                transform: "rotate(90 41.2035 8.00004)",
                fill: "#00DBA6",
              },
            }),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M40.8638 10.0249L40.8638 6.15942C40.4477 6.15942 40.1016 6.48337 40.0284 6.91112L39.8322 6.69855C39.5136 6.35335 39.0814 6.15942 38.6308 6.15942H37.6354C37.1662 6.15942 36.7859 6.57148 36.7859 7.07978L36.7859 8.1842C36.7859 8.6925 37.1662 9.10456 37.6354 9.10456H39.3346C39.6125 9.10456 39.8592 8.96 40.0142 8.73651V10.0249H37.6354C37.1662 10.0249 36.7859 10.437 36.7859 10.9453H40.0142C40.0435 10.9453 40.0725 10.9437 40.1011 10.9405C40.1878 10.931 40.2707 10.9073 40.3477 10.8716C40.6511 10.7312 40.8638 10.405 40.8638 10.0249ZM37.6354 7.07978H40.0142V8.1842H37.6354V7.07978Z",
                fill: "#2B2B2B",
              },
            }),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M41.5434 6.89571C41.5434 6.48907 41.8477 6.15942 42.223 6.15942H44.9416C45.317 6.15942 45.6213 6.48907 45.6213 6.89571V8.36827C45.6213 8.77491 45.317 9.10456 44.9416 9.10456H42.223C41.8477 9.10456 41.5434 8.77491 41.5434 8.36827V6.89571ZM42.393 7.07978H44.7717V8.1842H42.393V7.07978Z",
                fill: "#2B2B2B",
              },
            }),
          ]
        ),
      ]),
      _vm.pageTitle
        ? _c("page-title-section", {
            attrs: { "page-title": _vm.pageTitle, colorType: _vm.colorType },
          })
        : _vm._e(),
      !_vm.hasHeaderNav
        ? _c(
            "p",
            {
              staticClass: "header-pane__notification",
              class: {
                "header-pane__notification--current":
                  _vm.navigationCurrent === "notification",
              },
            },
            [
              _vm.visibleNotification
                ? _c("navigation-notification-icon-section", {
                    attrs: {
                      isSp: true,
                      isPurchasingInAppPurchase: _vm.isPurchasingInAppPurchase,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.hasHeaderNav
        ? _c("global-navigation-section", {
            attrs: { current: _vm.navigationCurrent },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }