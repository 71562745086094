var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "notification-item-parts" },
    [
      _vm.notification.type === "SFGO_NOTIFICATION_FROM_MANAGEMENT" ||
      _vm.notification.type === "SFGO_GRANT_POINT_FROM_MANAGEMENT" ||
      _vm.notification.type === "comment"
        ? _c("notification-item-contents-parts", {
            attrs: { notification: _vm.notification },
            on: {
              handlerClickLink: function ($event) {
                return _vm.$emit("handlerClickLink", _vm.notification)
              },
            },
          })
        : _vm._e(),
      _vm.notification.type === "highlight"
        ? _c("highlight-notification-item-contents-parts", {
            attrs: { notification: _vm.notification },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }