






import { defineComponent } from '@vue/composition-api'
import MypageMailChangeCompleteSection from '@/components/MypageMailChangeCompletePage/MypageMailChangeCompletePane/MypageMailChangeCompleteSection.vue'

/**
 * マイページ: メールアドレス変更変更完了 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageMailChangeCompletePane',
  components: {
    MypageMailChangeCompleteSection,
  },
  methods: {},
})
