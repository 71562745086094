var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "like-button-parts",
      class: { "like-button-parts--liked": _vm.commentData.liked },
      attrs: { type: "button" },
      on: { click: _vm.emitClick },
    },
    [
      _c("i", { staticClass: "like-button-parts__icon" }, [
        _c(
          "svg",
          {
            staticClass: "like-button-parts__svg",
            attrs: {
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _vm.commentData.liked
              ? _c("path", {
                  attrs: {
                    d: "M11.9987 14.0003H4.66536V5.33366L9.33203 0.666992L10.1654 1.50033C10.2431 1.5781 10.3071 1.68366 10.3574 1.81699C10.4076 1.95033 10.4325 2.0781 10.432 2.20033V2.43366L9.6987 5.33366H13.9987C14.3543 5.33366 14.6654 5.46699 14.932 5.73366C15.1987 6.00033 15.332 6.31144 15.332 6.66699V8.00033C15.332 8.0781 15.3209 8.16144 15.2987 8.25033C15.2765 8.33921 15.2543 8.42255 15.232 8.50033L13.232 13.2003C13.132 13.4225 12.9654 13.6114 12.732 13.767C12.4987 13.9225 12.2543 14.0003 11.9987 14.0003ZM4.66536 5.33366H3.9987V14.0003H4.66536H1.33203V5.33366H4.66536Z",
                    fill: "#00DBA6",
                  },
                })
              : _c("path", {
                  attrs: {
                    d: "M11.9987 14.0003H4.66536V5.33366L9.33203 0.666992L10.1654 1.50033C10.2431 1.5781 10.3071 1.68366 10.3574 1.81699C10.4076 1.95033 10.4325 2.0781 10.432 2.20033V2.43366L9.6987 5.33366H13.9987C14.3543 5.33366 14.6654 5.46699 14.932 5.73366C15.1987 6.00033 15.332 6.31144 15.332 6.66699V8.00033C15.332 8.0781 15.3209 8.16144 15.2987 8.25033C15.2765 8.33921 15.2543 8.42255 15.232 8.50033L13.232 13.2003C13.132 13.4225 12.9654 13.6114 12.732 13.767C12.4987 13.9225 12.2543 14.0003 11.9987 14.0003ZM5.9987 12.667H11.9987L13.9987 8.00033V6.66699H7.9987L8.8987 3.00033L5.9987 5.90033V12.667ZM4.66536 5.33366V6.66699H2.66536V12.667H4.66536V14.0003H1.33203V5.33366H4.66536Z",
                    fill: "white",
                    "fill-opacity": "0.5",
                  },
                }),
          ]
        ),
      ]),
      _c("span", {
        staticClass: "like-button-parts__count",
        domProps: { innerHTML: _vm._s(_vm.commentData.likeCount) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }