import DeviceInfo from '@/util/DeviceInfo'
import Logger from '@/util/logger/Logger'

/**
 * ストレージに保存される認証情報のタイプ
 */
type credentialType = {
  loginId: string
  password: string
}
/**
 * ストレージに保存されるデータを、キー名と型でマップする
 */
type secureKeyStoreValueType = {
  sfgoUserCredential: credentialType
}
/**
 * セキュアなストレージを操作するための機能を提供する。
 * https://github.com/pradeep1991singh/cordova-plugin-secure-key-store を利用する。
 */
export default class SecureKeyStore {
  /**
   * セキュアなストレージから値を取得する
   * @param key 取得するキー
   * @return 取得した値
   */
  static async getItem<T extends keyof secureKeyStoreValueType>(
    key: T,
  ): Promise<secureKeyStoreValueType[T] | void> {
    if (DeviceInfo.isiOS() || DeviceInfo.isAndroid()) {
      const value = await new Promise<string>((resolve, reject) => {
        window.cordova.plugins.SecureKeyStore.get(
          (val: string) => {
            Logger.info(`KeyChainStore#getItem: Success to get value from secure key store: ${key}`)
            resolve(val)
          },
          (e: Error) => {
            Logger.info(`KeyChainStore#getItem: Failed to get value from secure key store: ${key}`)
            reject(e)
          },
          key,
        )
      })
      let res
      try {
        res = JSON.parse(value)
      } catch (e) {
        res = value
      }
      return Promise.resolve(res)
    }
    return Promise.resolve()
  }

  /**
   * セキュアなストレージに値を保存する
   * @param key キー
   * @param val 保存する値。保存する値はシリアライズされることに注意
   * @return
   */
  static async setItem<T extends keyof secureKeyStoreValueType>(
    key: T,
    val: secureKeyStoreValueType[T],
  ) {
    if (DeviceInfo.isiOS() || DeviceInfo.isAndroid()) {
      return new Promise<void>((resolve, reject) => {
        window.cordova.plugins.SecureKeyStore.set(
          () => {
            Logger.info(`KeyChainStore#setItem: Success to save to keyStore. key: ${key}`)
            resolve()
          },
          (e: Error) => {
            Logger.info(`KeyChainStore#setItem: Failed to save to keyStore. key: ${key}`)
            reject(e)
          },
          key,
          JSON.stringify(val),
        )
      })
    }
    return Promise.resolve()
  }
}
