













import { computed, defineComponent, onUnmounted } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import LoadingOverlayProgressParts from '@/components/sections/LoadingOverlay/parts/LoadingOverlayProgressParts.vue'

/**
 * 共通ローディングオーバーレイの進捗表示に使用するセクション
 */
export default defineComponent({
  name: 'LoadingOverlayProgressSection',
  components: {
    LoadingOverlayProgressParts,
  },
  setup() {
    const loadingOverLayStore = StoreUtil.useStore('LoadingOverlayProgressStore')

    const progressList = computed(() => loadingOverLayStore.state.progressList)
    const cautionMessage = computed(() => loadingOverLayStore.state.cautionMessage)

    onUnmounted(() => {
      loadingOverLayStore.clearLoadingOverLayData()
    })

    return {
      progressList,
      cautionMessage,
    }
  },
})
