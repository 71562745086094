var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-list-tab-parts" }, [
    _c("ul", { staticClass: "highlight-list-tab-parts__tabs" }, [
      _c("li", { staticClass: "highlight-list-tab-parts__tab" }, [
        _c(
          "button",
          {
            staticClass: "highlight-list-tab-parts__tab-button",
            class: [
              "highlight-list-tab-parts__tab-button-official",
              {
                "highlight-list-tab-parts__tab-button--active":
                  _vm.selectedHighlightTab === "official",
              },
            ],
            on: {
              click: function ($event) {
                return _vm.handleTabClicked("official")
              },
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$tc("RaceVideoPage.highlights.tabText.official")) +
                " "
            ),
          ]
        ),
      ]),
      _c("li", { staticClass: "highlight-list-tab-parts__tab" }, [
        _c(
          "button",
          {
            staticClass: "highlight-list-tab-parts__tab-button",
            class: [
              "highlight-list-tab-parts__tab-button-user",
              {
                "highlight-list-tab-parts__tab-button--active":
                  _vm.selectedHighlightTab === "user",
              },
            ],
            on: {
              click: function ($event) {
                return _vm.handleTabClicked("user")
              },
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$tc("RaceVideoPage.highlights.tabText.user")) +
                " "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }