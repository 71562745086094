var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-card-parts" }, [
    _c("div", { staticClass: "highlight-card-parts__race-angle" }, [
      _c("div", { staticClass: "highlight-card-parts__header" }, [
        _c("div", { staticClass: "highlight-card-parts__header__player" }, [
          _c("img", {
            staticClass: "highlight-card-parts__header__player-img",
            attrs: {
              src: _vm.highlight.player.playerPictureImagePath
                ? _vm.highlight.player.playerPictureImagePath
                : require("@/assets/img/icon/icon_player__null.svg"),
              alt: _vm.highlight.player.playerName[_vm.$i18n.locale],
            },
          }),
          _c("span", {
            staticClass: "highlight-card-parts__header__player-name",
            domProps: {
              textContent: _vm._s(
                _vm.highlight.player.playerName[_vm.$i18n.locale]
              ),
            },
          }),
        ]),
        _c("div", { staticClass: "highlight-card-parts__header__time" }, [
          _vm._v(_vm._s(_vm.highlight.raceTime)),
        ]),
      ]),
      _c(
        "figure",
        {
          staticClass: "highlight-card-parts__thumbnail",
          class: {
            "highlight-card-parts__thumbnail--blank":
              !_vm.highlight.thumbnailUri,
          },
        },
        [
          _c("img", {
            attrs: {
              src: _vm.highlight.thumbnailUri
                ? _vm.highlight.thumbnailUri
                : require("@/assets/img/icon/icon_play__gray.svg"),
              alt: "",
            },
          }),
        ]
      ),
    ]),
    _vm.highlight.comment
      ? _c("p", {
          staticClass: "highlight-card-parts__parent-comment",
          domProps: { innerHTML: _vm._s(_vm.highlight.comment) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }