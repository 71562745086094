


























import { computed, defineComponent } from '@vue/composition-api'
import TrophyIconParts from '@/components/StandingsPage/common/parts/TrophyIconParts.vue'

/**
 * Standings: 選手詳細 順位・ポイント表示パーツ
 */
export default defineComponent({
  name: 'StandingsPlayerResultParts',
  components: {
    TrophyIconParts,
  },
  props: {
    totalPosition: {
      type: Number,
      required: true,
    },
    totalPoints: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    /** 表示用の順位 */
    const displayTotalPosition = computed(() =>
      props.totalPosition ? String(props.totalPosition) : '---',
    )

    return {
      displayTotalPosition,
    }
  },
})
