import InAppBrowserWrapper, {
  InAppBrowserOptionsType,
  InAppBrowserResult,
} from '@/util/inAppBrowser/InAppBrowserWrapper'

/**
 * Youtubeを表示する際にInAppBrowserに指定するオプション。
 */
const defaultOptions: InAppBrowserOptionsType = {
  /**
   * インライン再生を許可する
   */
  allowInlineMediaPlayback: 'yes',
  /**
   * 自動再生を有効にするために、ユーザーアクションなしで、動画を再生可能にする
   */
  mediaPlaybackRequiresUserAction: 'no',
}

/**
 * Youtube動画をInAppBrowserで開くための機能を提供する。
 * iOSの場合に、YouTube Player API for iframe Embeds を利用して動画再生ができない問題があるため、
 * このクラスを利用する。
 *
 * @see https://stackoverflow.com/questions/27827796/youtube-iframe-file-protocol-error-using-cordova
 * @see https://developers.google.com/youtube/iframe_api_reference
 *
 */
export default class InAppYoutubeBrowser extends InAppBrowserWrapper {
  /**
   * YoutubeをInAppBrowserで開く。
   *
   * @param youtubeId Youtube動画ID
   * @param onClose InAppBrowser が閉じた際に呼び出されるコールバック関数を指定する
   * @param delegateSystem システム標準のブラウザを利用して表示時するかどうか。
   * このパラメタにtrueを指定した場合、Youtubeアプリ、または、システム標準のブラウザでYoutubeを開く。
   */
  public showYoutube(
    youtubeId: string,
    onClose?: (result: InAppBrowserResult) => void,
    delegateSystem = false,
  ) {
    if (delegateSystem) {
      super.open(`https://www.youtube.com/embed/${youtubeId}`, '_system', defaultOptions, onClose)
    } else {
      super.open(
        InAppYoutubeBrowser.makeEmbedYoutubeContentsUrl(youtubeId),
        '_blank',
        defaultOptions,
        onClose,
      )
    }
  }

  /**
   * 対象のYoutube動画が埋め込まれたHTMLのData URLを返す。
   * このメソッドは、対象のYoutube動画が埋め込まれたHTMLを生成し、Data URLに変換して返す。
   * このURLをInAppBrowserに指定することで、生成したHTMLを表示することができる。
   *
   * @param youtubeId Youtube動画ID
   * @see https://stackoverflow.com/a/41185803
   */
  static makeEmbedYoutubeContentsUrl(youtubeId: string) {
    // HTMLの内容をテンプレート文字列リテラルで生成する。
    const pageContent = `
    <meta
      name="viewport"
      content="user-scalable=no, initial-scale=1, width=device-width, viewport-fit=cover"
    />
    <style lang="css">
      @media screen {
        body {
          background-color: black;
        }
        #youtubePlayerWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        #youtubePlayer {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
      }
    </style>
    <div id="youtubePlayerWrapper"><div id="youtubePlayer"></div></div>
    <script>
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      function onYouTubePlayerAPIReady() {
        new YT.Player('youtubePlayer', {
          videoId: '${youtubeId}',
          width: document.body.offsetWidth,
          height: (document.body.offsetWidth * 9) / 16,
          playerVars: {
            autoplay: 1,
            loop: 1,
            rel: 0,
            playsinline: 1,
            fs: 1,
          },
          events: {
            onReady: onPlayerReady,
          },
        })
      }
      function onPlayerReady(event) {
        event.target.playVideo()
        const youtubePlayerElement = document.getElementById('youtubePlayer')
        if (screen.orientation) {
          screen.orientation.addEventListener('change', (event) => {
            if (event.target.type.indexOf('landscape') !== -1) {
              youtubePlayerElement.style.height = '100%'
              youtubePlayerElement.style.width = '100%'
            } else {
              youtubePlayerElement.style.removeProperty('height')
              youtubePlayerElement.style.removeProperty('width')
            }
          })
        } else {
          youtubePlayerElement.style.height = '100%'
          youtubePlayerElement.style.width = '100%'
        }
      }
    </script>
`
    return `data:text/html;base64,${btoa(pageContent)}`
  }
}
