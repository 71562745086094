







































































































import { defineComponent, PropType } from '@vue/composition-api'
import StandingsPlayerResultParts from '@/components/StandingsPage/StandingsPlayerDetailsPane/parts/StandingsPlayerResultParts.vue'

export type StandingPlayerDetailsType = {
  headerImgPath: string
  playerImgPath: string
  number: string
  name: string
  team: string
  profileLink: string
  totalPosition: number
  totalPoints: number
}

/**
 * Standings: 選手リストセクション
 */
export default defineComponent({
  name: 'StandingsPlayerDetailsSection',
  components: {
    StandingsPlayerResultParts,
  },
  props: {
    playerData: {
      type: Object as PropType<StandingPlayerDetailsType>,
      required: true,
    },
    isFavoritePlayer: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onclickFavorite() {
      this.$emit('onChangeFavoritePlayer')
    },
  },
})
