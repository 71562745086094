import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import UserImageUploadDocument from '@/store/stores/collectionModule/documents/userImageUpload/UserImageUploadDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

export type UserImageUploadFilteredType = '^RANKING_'

/**
 * ユーザー画像アップロードAPIの操作をするための処理
 */
export default function useUserImageUpload() {
  // Collection modules
  const userImageUploadCollectionModule = CollectionModule.createStore(UserImageUploadDocument)

  /**
   * ユーザー画像一覧取得
   */
  const fetchUserImageList = (type: UserImageUploadFilteredType) => {
    const options = {
      query: {
        filter: { type: { $regex: type } },
      },
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/user_image/`,
    }

    return userImageUploadCollectionModule.fetchSplit(options, 1, 1000)
  }

  /**
   * ユーザー画像一覧
   */
  const userImageList = computed(() => userImageUploadCollectionModule.data)

  /**
   * ユーザー画像データをクリアする
   */
  const clearUserImageList = () => {
    userImageUploadCollectionModule.clearData()
  }

  return {
    userImageList,
    fetchUserImageList,
    clearUserImageList,
  }
}
