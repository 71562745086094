var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-list-header",
      class: {
        "race-list-header--for-pc": _vm.forPc,
        "race-list-header--show-timetable": _vm.showTimetable,
      },
    },
    [
      _c("race-list-header-parts", {
        staticClass: "race-list-header__race-data",
        class: { "race-list-header__race-data--hidden": _vm.showInformation },
        attrs: {
          round: _vm.currentChampionship
            ? _vm.currentChampionship.round
            : undefined,
          raceDate: _vm.currentChampionship
            ? _vm.currentChampionship.raceDate
            : "",
          raceVenue: _vm.currentChampionship
            ? _vm.currentChampionship.circuitName[_vm.$i18n.locale] || ""
            : "",
          showTimetable: _vm.showTimetable,
        },
      }),
      !_vm.showInformation && !_vm.showTimetable
        ? _c("race-list-view-button-parts", {
            on: { onClickViewTimetable: _vm.handleViewTimetableClicked },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }