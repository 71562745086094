








































import { computed, defineComponent, PropType } from '@vue/composition-api'
import ToggleRadioCardListSection from '@/components/common/ToggleRadioCard/ToggleRadioCardListSection.vue'
import ToggleRadioCardParts from '@/components/common/ToggleRadioCard/ToggleRadioCardParts.vue'
import PlanCardParts from '@/components/SignupPage/common/PlanCardParts.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import StoreUtil from '@/store/StoreUtil'
import i18n from '@/locales/I18n'
import {
  ContractTermType,
  ProcedureScreenType,
} from '@/store/stores/collectionModule/documents/GeneralTypes'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * プラン変更(有料=>有料) プラン選択
 */
export default defineComponent({
  name: 'ChooseOtherPlanSection',
  components: {
    ToggleRadioCardListSection,
    ToggleRadioCardParts,
    PlanCardParts,
    CurrentPlanCardSection,
  },
  props: {
    /**
     * 契約中以外のプラン一覧
     */
    otherPlanList: {
      type: Array as () => Array<ContractPlanDocument>,
      default: () => [],
    },
    /**
     * 将来のプラン
     */
    futurePlan: {
      type: Object as () => ContractPlanDocument,
      default: () => ({}),
    },
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<PaidPlanGroupType>,
      required: true,
    },
    /**
     * 契約中のプランの契約期間
     */
    contractTerm: {
      type: Object as PropType<ContractTermType>,
      default: () => ({}),
    },
    /**
     * 将来のプランの契約期間
     */
    scheduledContractTerm: {
      type: Object as PropType<ContractTermType>,
      default: () => ({}),
    },
    /**
     * どの手続き画面か
     */
    procedureScreen: {
      type: String as PropType<ProcedureScreenType>,
      default: null,
    },
  },
  setup(props, context) {
    const { getDisplayDate } = useDisplayDependingOnLang()

    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { monthlyPlanPriceJpy, annualPlanPriceJpy } = mypagePageStore

    /** プランが切り替わる日付 */
    const endDate = props.contractTerm.contractEndDate as number
    const futurePlanStartDate = getDisplayDate(endDate + 1)
    /** 注記 */
    const attention = computed(() =>
      !props.futurePlan
        ? i18n
            .t('PaidPlanChange.EditPage.changePlanAttention', {
              date: futurePlanStartDate,
            })
            .toString()
        : i18n
            .t('PaidPlanChange.EditPage.hasFuturePlanAttention', {
              date: futurePlanStartDate,
            })
            .toString(),
    )

    /** 将来のプランがある場合の見出し */
    const futurePlanTitle = i18n
      .t('MypagePage.plan.futurePlan', {
        date: futurePlanStartDate,
      })
      .toString()

    /** 月払いプランと比べて、年払いプランを契約した場合のお得額を計算 */
    const bargainPriced = (plan: ContractPlanDocument) => {
      if (plan.planGroupId === 'monthlyPlan') {
        /** 月払いはお得にならない */
        return ''
      }

      // お得額
      return `${(
        100 -
        (annualPlanPriceJpy.value / (monthlyPlanPriceJpy.value * 12)) * 100
      ).toFixed()}%`
    }

    /**
     * プラン選択通知
     * @param val
     */
    const handlerPlanSelect = (val: PaidPlanGroupType) => context.emit('handlerPlanSelect', val)

    return {
      attention,
      futurePlanTitle,
      bargainPriced,
      handlerPlanSelect,
    }
  },
})
