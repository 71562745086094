var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "round-record-parts" }, [
    _c("dl", { staticClass: "round-record-parts__record" }, [
      _c("dt", {
        staticClass: "round-record-parts__record-title",
        domProps: { textContent: _vm._s(_vm.title) },
      }),
      _c("dd", {
        staticClass: "round-record-parts__record-value",
        class: { hidden: _vm.title === "RACE" || _vm.title === "QUALIFYING" },
        domProps: { textContent: _vm._s(_vm.value) },
      }),
    ]),
    _c(
      "div",
      { staticClass: "round-record-parts__position" },
      [
        _vm.position && _vm.position < 4
          ? _c("trophy-icon-parts", {
              staticClass: "round-record-parts__position__icon",
              attrs: { position: _vm.position },
            })
          : _vm._e(),
        _c("span", {
          staticClass: "round-record-parts__position__value",
          domProps: { textContent: _vm._s(_vm.displayPosition) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }