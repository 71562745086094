import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 契約情報契約プランAPI POST用の型
 */
export type SavePlanType = {
  contractPlanId: string | null
  startDate: number | null
  endDate?: number | null
}

/**
 * 契約情報契約プラン情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/829194320/API
 */
export default class ContractInfoPlanDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoPlanDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contract_info'

  /**
   * key: このスキーマには既にidと言うキーが存在し、DocumentWrapperのid(computed)と競合するため、このスキーマではidをkeyという名称で扱うこととする
   * key = テーブル上のidに相当
   */
  protected idAttr = 'key'

  key: string | null = null

  /**
   * 契約プランID
   */
  contractPlanId?: string | null = null

  plans?: Array<SavePlanType> | null = null

  /**
   * 開始日時
   */
  startDate?: number | null = null

  /**
   * 終了日時
   */
  endDate?: number | null = null

  /**
   * クーポンID
   */
  couponId?: string | null

  set id(key: string | null) {
    this.key = key
  }

  get id(): string | null {
    return this.key
  }
}
