



































































import { defineComponent, computed, ref } from '@vue/composition-api'
import twitterText from 'twitter-text'
import AtomTextArea from '@/components/atoms/input/AtomTextArea.vue'
import ConfirmModalSection from '@/components/common/modal/ConfirmModalSection.vue'
import MissionSnsButtonParts from '@/components/MissionPage/MissionRankingPane/parts/MissionSnsButtonParts.vue'
import MissionCancelIcon from '@/components/MissionPage/MissionRankingPane/parts/icons/MissionCancelIcon.vue'
import MissionSubmitIcon from '@/components/MissionPage/MissionRankingPane/parts/icons/MissionSubmitIcon.vue'
import CommentCountParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentCountParts.vue'
import Const from '@/util/Const'

export default defineComponent({
  name: 'MissionRankingShareInputSection',
  components: {
    CommentCountParts,
    MissionSubmitIcon,
    MissionCancelIcon,
    MissionSnsButtonParts,
    ConfirmModalSection,
    AtomTextArea,
  },
  props: {
    /**
     * 対象ハイライトの入力状態
     */
    targetEnableInput: {
      type: Boolean,
      default: false,
    },
    /**
     * 対象ハイライトに対する入力コメント
     */
    targetInputHighlightComment: {
      type: String,
      default: '',
    },
    hasShare: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hashtag = process.env.VUE_APP_X_HASH_TAG
    const postX = ref(false)
    const postInstagram = ref(false)

    // コメントキャンセル確認用モーダル
    const enabledConfirmModal = ref<boolean>(false)

    // 入力中のコメント
    const comment = ref('')

    /**
     * コメントの文字数
     */
    const commentLength = computed(() => {
      if (postX.value) {
        return twitterText.parseTweet(`${comment.value}\n${hashtag}`).weightedLength
      }
      return twitterText.parseTweet(comment.value).weightedLength
    })

    /**
     * コメント入力画面のキャンセルボタンが押下されたときの処理
     */
    const handleCancelClicked = () => {
      enabledConfirmModal.value = true
    }

    /**
     * 確定ボタンを押せるかどうか。下記の状態だと押せないようにする。
     * - コメントが空の場合
     * - SNS投稿ボタンがどちらも押されていない場合
     */
    const disabledSubmitButton = computed(
      () =>
        !comment.value.replace(/\s+/g, '') ||
        (!postInstagram.value && !postX.value) ||
        commentLength.value > Const.MAX_HIGHLIGHT_COMMENT_LENGTH,
    )

    return {
      hashtag,
      comment,
      commentLength,
      enabledConfirmModal,
      handleCancelClicked,
      postX,
      postInstagram,
      disabledSubmitButton,
    }
  },
  methods: {
    /**
     * コメント投稿ボタンを押したことを親に伝える
     */
    onPostComment() {
      // 入力状態でコメントがある場合
      if (!this.disabledSubmitButton) {
        this.$emit('onClickPostSns', this.comment, this.postX, this.postInstagram)
      } else {
        // 入力状態にする
        this.startInput()
      }
    },
    /**
     * 入力モード開始
     */
    startInput() {
      this.$emit('onToggleEnabledInput', true)
    },
    /**
     * 入力解除
     */
    cancelInput() {
      this.enabledConfirmModal = false
      this.$emit('onToggleEnabledInput', false)
    },
  },
})
