










import { defineComponent } from '@vue/composition-api'
import { localeChanged } from 'vee-validate'
import I18n from '@/locales/I18n'
import LanguageSelectListSection from '@/components/MypageLanguageSettingPage/MypageLanguageSelectPane/LanguageSelectListSection.vue'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'

interface listDataType {
  id: string
  locale: string
  sid: string
}

export type DataType = {
  listData: Array<listDataType>
  selectedID: UserLang
}

/**
 * マイページ: 言語設定 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageLanguageSettingPane',
  components: {
    LanguageSelectListSection,
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      user: mypagePageStore.user,
      saveLanguage: mypagePageStore.saveLanguage,
    }
  },
  async mounted() {
    this.selectedID = this.user.lang || DeviceInfo.getLanguage()
  },
  data(): DataType {
    return {
      /**
       * 言語リスト
       */
      listData: [
        {
          id: 'ja',
          locale: 'ja',
          sid: 'ja',
        },
        {
          id: 'en',
          locale: 'en',
          sid: 'en',
        },
      ],
      /**
       * セレクトID
       */
      selectedID: 'ja',
    }
  },
  methods: {
    /**
     * アプリの言語設定を変更
     */
    async changeLanguage() {
      const loader = this.$loading.show()
      const result = await this.saveLanguage(this.selectedID)
      loader.hide()
      if (result?.isSuccess) {
        I18n.locale = this.selectedID as string
        localeChanged()

        // グローバルストアに保持するユーザー情報を更新
        await UserStore.value.fetchUserData(LoginStore.value.loginId)
        /**
         * 更新に成功したことを通知する
         * @event success-regist
         */
        this.$emit('success-regist')
      } else {
        // API通信不具合
        await MessageDialogStore.value.open({
          title: this.$t('MypagePage.MypageLanguageSettingPage.errors.title') as string,
          message: this.$t('MypagePage.MypageLanguageSettingPage.errors.message') as string,
          errorApiResponse: result?.response,
        })
      }
    },
    changeSelected(selectedItemID: UserLang): void {
      /**
       * 選択変更
       * @param selectedItemID : string
       */
      this.selectedID = selectedItemID
    },
  },
})
