var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "email-authentication-page" },
    [
      _c("email-authentication-pane", {
        attrs: { memberType: _vm.memberType },
        on: { formSubmit: _vm.moveToConfirmationSent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }