var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cancel-confirm-pane" },
    [
      _c("h3", {
        staticClass: "cancel-confirm-pane__title",
        domProps: {
          innerHTML: _vm._s(_vm.$tc("CancelPage.ConfirmPage.title")),
        },
      }),
      _c("p", {
        staticClass: "cancel-confirm-pane__message",
        domProps: {
          innerHTML: _vm._s(_vm.$tc("CancelPage.ConfirmPage.message")),
        },
      }),
      _c("ul", { staticClass: "cancel-confirm-pane__attention" }, [
        _c("li", [
          _vm._v(_vm._s(_vm.$tc("CancelPage.ConfirmPage.attention01"))),
        ]),
      ]),
      _c(
        "action-buttons-section",
        {
          staticClass: "cancel-confirm-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".cancel-confirm-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("CancelPage.ConfirmPage.submit"),
              isDisabled: false,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-link-button", {
            attrs: {
              linkText: _vm.$tc("common.cancel"),
              url: _vm.mypageUrl,
              isOpenAsBlank: false,
              colorType: "tertiary",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }