






























































































































































































































































import {
  computed,
  defineComponent,
  ComputedRef,
  onMounted,
  PropType,
  ref,
  Ref,
  watch,
} from '@vue/composition-api'
import { orderBy } from 'lodash'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import PlayEventDocument from '@/store/stores/collectionModule/documents/highlight/PlayEventDocument'
import I18n from '@/locales/I18n'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import HighlightCommentSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightCommentSection.vue'
import LikeButtonParts, {
  ClickedLikeDataType,
} from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/LikeButtonParts.vue'
import CommentPostDateParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentPostDateParts.vue'
import CommentInputSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/CommentInputSection.vue'
import CommentEditorParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentEditorParts.vue'
import useCommunication, {
  CommentDataType,
  ParentCommentDataType,
} from '@/components/RaceVideoPage/hook/useCommunication'
import usePermission from '@/components/hook/usePermission'
import useUserTypeRoles from '@/components/hook/useUserTypeRoles'
import Const from '@/util/Const'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import ConfirmModalSection from '@/components/common/modal/ConfirmModalSection.vue'
import HighlightCommentToggleParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightCommentToggleParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import { HighlightTabsType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import StoreUtil from '@/store/StoreUtil'
import { UserType } from '@/store/stores/pageStore/common/UserType'
import SceneMoviesDocument from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'
import DeviceInfo from '@/util/DeviceInfo'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import { HighlightSlideMenuType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightsSlideMenuParts.vue'

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultIcon = require('@/assets/img/icon/icon_user_default.png')

type HighlightCreateUserType = {
  displayName: string
  userPicture: string
  userType: UserType
}

/**
 * レース動画再生画面 ハイライトアイテム セクションコンポーネント
 */
export default defineComponent({
  name: 'HighlightItemSection',
  components: {
    AtomButton,
    HighlightCommentToggleParts,
    ConfirmModalSection,
    HighlightCommentSection,
    LikeButtonParts,
    CommentPostDateParts,
    CommentInputSection,
    CommentEditorParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    id: {
      type: String,
    },
    current: {
      type: String,
    },
    currentPlayer: {
      type: String,
    },
    /**
     * 選択中のハイライトタブ
     */
    selectedHighlightTab: {
      type: String as PropType<HighlightTabsType>,
      required: true,
    },
    highlightData: {
      type: Object as PropType<HighlightDocument>,
      required: true,
    },
    playEventList: {
      type: Array as PropType<Array<PlayEventDocument>>,
      default: () => [],
    },
    playerList: {
      type: Array as PropType<Array<PlayerDocument>>,
      default: () => [],
    },
    targetHighlightComments: {
      type: Array as PropType<Array<CommentDataType>>,
      default: () => [],
    },
    loginUserId: {
      type: String,
      required: true,
    },
    /**
     * 対象ハイライトのコメント入力状態
     */
    targetEnableInput: {
      type: Boolean,
      default: false,
    },
    /**
     * 対象ハイライトに対する入力コメント
     */
    targetInputHighlightComment: {
      type: String,
      default: '',
    },
    /**
     * ハイライトのコメント編集状態（コメント毎に入力状態を保持する）
     */
    editModesMap: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    /**
     * ハイライトのメインコメント編集状態（ハイライト毎に入力状態を保持する）
     */
    editModesMainCommentMap: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    /**
     * ハイライトコメントを入力するために動画再生画面に遷移してきたか
     */
    inputComment: {
      type: String,
      default: '',
    },
    /**
     * ハイライトコメントしたユーザーのマップ
     */
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
    /**
     * ハイライトID
     * 遷移先の画面から戻ってきた時に対象のハイライトにスクロールさせるために利用する
     */
    scrollToHighlightId: {
      type: String,
      default: '',
    },
    /**
     * 動画集
     */
    sceneMovies: {
      type: Object as PropType<SceneMoviesDocument | null>,
      default: () => ({}),
    },
    /**
     * ハイライトコメントの数
     */
    highlightCommentCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    const isPc = DeviceInfo.isForPC()
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { loginId } = LoginStore.value
    const { hasPermission } = usePermission()
    const { createDisplayHighlightCommentData } = useCommunication()
    const { getUserTypeRoles } = useUserTypeRoles()

    /** 全コメントを表示するかどうか */
    const isShowAllComment = ref(false)
    /** コメントリストを表示するかどうか */
    const visibleCommentList = ref(false)

    const highlight = computed(() => props.highlightData)
    const selectedHighlightId = computed(() => props.current)
    const selectedPlayerMasterId = computed(() => props.currentPlayer)
    const playEvents = computed(() => props.playEventList)
    const players = computed(() => props.playerList)
    const enabledConfirmModal = ref<boolean>(false)

    // ハイライト動画切り抜き：ダウンロード準備中かどうか
    const isInProgress = computed(() => props.sceneMovies?.status === 'InProgress')
    // ハイライト動画切り抜き：ダウンロード準備が完了しているかどうか
    const isComplete = computed(() => props.sceneMovies?.status === 'Complete')
    // ハイライト動画切り抜き：エラーかどうか
    const isError = computed(() => props.sceneMovies?.status === 'Error')

    /**
     * シーン名を取得する
     */
    const sceneName = computed(() => {
      const sceneData = playEvents.value.find(
        (scene: PlayEventDocument) => scene.playEventId === highlight.value.playEventId,
      )
      if (sceneData) {
        return sceneData.playEventName[I18n.locale]
      }
      return ''
    })

    /**
     * ハイライトのサムネイル画像のURL
     */
    const highlightThumbnailUri = computed(() =>
      CloudFrontUtil.getSignedUrl(highlight.value.thumbnailUri),
    )

    /**
     * ハイライトコメント
     */
    const highlightComment = computed(() => createDisplayHighlightCommentData(highlight.value))

    /**
     * コメントデータ
     * 初期表示するコメントともっとコメントを見るを押した時に表示するコメントを分ける
     * @return [displayedComments（初期表示されているコメント）, moreComments（もっとコメントを見るを押した時に表示されるコメント）]
     */
    const highlightComments = computed((): CommentDataType[][] => {
      // 初期表示されているコメント
      const displayedComments: CommentDataType[] = []
      // もっとコメントを見るを押した時に表示されるコメント
      const moreComments: CommentDataType[] = []

      // 新規で登録したコメントが上に表示されてしまったため、日付が過去順になるように並べ替え直す
      orderBy(props.targetHighlightComments, '_createdDate').forEach((comment, index) => {
        if (index < 5) {
          displayedComments.push(comment)
        } else {
          moreComments.push(comment)
        }
      })
      return [displayedComments, moreComments]
    })

    /**
     * もっとコメントを見るの下に表示するコメント
     */
    const displayNewOwnHighlightComments = computed(() =>
      raceVideoPageStore.newOwnHighlightComments.value.filter((comment) => {
        if (props.highlightData.highlightId !== comment.highlightId) {
          // 対象ハイライトカードのコメントではない場合は表示しない
          return false
        }

        const isDisplayed = highlightComments.value[0].some(
          (displayedComment) => displayedComment.commentId === comment.commentId,
        )
        // コメント数が5件以下ですでに表示されている場合はもっとコメントを見るの下に表示しない
        return !isDisplayed
      }),
    )

    /**
     * もっとコメントを見るを表示するかどうか
     */
    const displayMoreCommentsButton = computed(() => {
      if (isShowAllComment.value) {
        // もっとコメントを見るを押した場合、「もっとコメントを見る」ボタンを表示しない
        return false
      }
      if (highlightComments.value[1].length === 0) {
        // コメント5件以下の場合、「もっとコメントを見る」ボタンを表示しない
        return false
      }
      if (
        displayNewOwnHighlightComments.value.length > 0 &&
        displayNewOwnHighlightComments.value.length === highlightComments.value[1].length
      ) {
        // コメント5件の状態でコメントを投稿した場合、自分のコメントが表示されるため、「もっとコメントを見る」ボタンを表示しない
        return false
      }

      // 上記以外
      return true
    })

    /**
     * 全コメント
     * もっとコメントを見るを押した時 または コメントが5件以上ある状態で新たに作成した自分のコメントを表示する
     */
    const displayMoreComments: ComputedRef<Array<CommentDataType>> = computed(() =>
      isShowAllComment.value ? highlightComments.value[1] : displayNewOwnHighlightComments.value,
    )

    /**
     * コメントの入力が可能かどうか
     * - ハイライトにコメントできる権限を持つユーザーかつハイライトが共有されているもしくは公式ハイライトならコメントができる
     */
    const showCommentInput = computed(
      () => hasPermission('createComment') && props.highlightData.isShared,
    )

    onMounted(() => {
      setTimeout(() => {
        if (
          props.inputComment === 'true' &&
          props.highlightData.highlightId === props.scrollToHighlightId
        ) {
          // 対象のハイライトコメントにスクロールする
          context.emit('onScrollToComment', `${props.id}PostInput`)
        }

        if (props.highlightData.highlightId === props.scrollToHighlightId) {
          // 対象のハイライトにスクロールする
          context.emit('onScrollToHighlight', `highlightItemSection${props.scrollToHighlightId}`)
        }
      }, 100)
    })

    /** シェア、編集、削除ボタンを表示するかどうか */
    const controllerShown = ref<boolean>(false)

    /** コメントシェア関数取得 */
    const { shareHighlightComment } = useCommunication()

    /** ハイライト メインコメント編集モード */
    const enabledEditHighlight = computed(() => {
      if (
        props.highlightData.highlightId !== null &&
        props.editModesMainCommentMap[props.highlightData.highlightId]
      ) {
        return props.editModesMainCommentMap[props.highlightData.highlightId]
      }

      return false
    })

    const handleOpenMenuClicked = (comment: ParentCommentDataType) => {
      // 自分のハイライトの場合は、編集、削除などをできるようにする
      let slideMenu = ''
      if (loginId === comment.userId) {
        slideMenu = isPc ? 'highlightPc' : 'highlight'
      } else {
        slideMenu = 'userBlock'
      }
      context.emit('onClickOpenMenu', comment, slideMenu)
    }

    /**
     * 投稿されたコメントを開閉する
     */
    const toggleComments = () => {
      visibleCommentList.value = !visibleCommentList.value

      if (!visibleCommentList.value) {
        // コメントを非表示にした場合の処理
        // コメントを見ていたハイライトの位置までスクロールさせる
        context.emit('onScrollToHighlight', `highlightItemSection${props.highlightData.id}`)
        // コメント全件表示をやめる
        isShowAllComment.value = false
        // もっとコメントを見るの下に表示された自分の新規作成コメントをクリア
        raceVideoPageStore.newOwnHighlightComments.value = []
      } else {
        context.emit(
          'fetchDiffTargetHighlightCommentsAndReactions',
          props.highlightData.highlightId,
        )
      }
    }

    /**
     * 「もっとコメントを見る」が押下されたときの処理
     */
    const showMoreComment = () => {
      isShowAllComment.value = true

      // もっとコメントを見るの下に表示された自分の新規作成コメントをクリア
      raceVideoPageStore.newOwnHighlightComments.value = []
    }

    /**
     * ハイライトを作成したユーザーの情報を返す
     */
    const highlightCreateUser: Ref<HighlightCreateUserType | null> = computed(() => {
      if (!props.highlightData._createdBy) {
        return null
      }
      const targetUser = props.retrieveNameUsersByUserId?.[props.highlightData._createdBy]

      const userTypeRoles = getUserTypeRoles(targetUser?.roles)

      const userRole = userTypeRoles?.find((role) => Const.SFGO_ROLES_FOR_USER_TYPE.includes(role))

      const contractPlan = targetUser?.contract?.planGroupId?.includes('freePlan')
        ? 'free-user'
        : 'paid-user'

      return {
        displayName: targetUser?.additionalData?.userDisplayName || '---',
        userPicture: targetUser?.userPicture
          ? CloudFrontUtil.getSignedUrl(targetUser.userPicture)
          : defaultIcon,
        userType: userRole ?? contractPlan,
      }
    })

    /**
     * コメントのメニューボタンが押下されたときの処理
     */
    const handleCommentMenuClicked = (
      commentData: CommentDataType,
      slideMenu: HighlightSlideMenuType,
    ) => {
      context.emit('onClickCommentMenu', commentData, slideMenu)
    }

    /**
     * 新たに自分が投稿したコメントを監視する
     */
    watch(
      () => raceVideoPageStore.newOwnHighlightComments.value,
      (newCommentList) => {
        if (
          !visibleCommentList.value &&
          newCommentList.length > 0 &&
          newCommentList[newCommentList.length - 1].highlightId === props.highlightData.highlightId
        ) {
          // コメントを投稿した場合、投稿対象のハイライトコメントを開いた状態にし、投稿したコメントを確認できるようにする
          visibleCommentList.value = true
        }
      },
    )

    return {
      isShowAllComment,
      selectedHighlightId,
      selectedPlayerMasterId,
      playEventName: sceneName,
      players,
      highlight: highlight as ComputedRef<HighlightDocument>,
      highlightThumbnailUri,
      displayMoreCommentsButton,
      highlightComments,
      controllerShown,
      showCommentInput,
      highlightComment,
      shareHighlightComment,
      enabledEditHighlight,
      enabledConfirmModal,
      handleOpenMenuClicked,
      parentOrgId: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
      toggleComments,
      showMoreComment,
      displayMoreComments,
      visibleCommentList,
      displayNewOwnHighlightComments,
      highlightCreateUser: highlightCreateUser as Ref<HighlightCreateUserType>,
      handleCommentMenuClicked,
      roleLabels: Const.SFGO_USER_TYPE_LABELS,
      isInProgress,
      isComplete,
      isError,
      isPc,
    }
  },
  methods: {
    /**
     * ハイライトカードが選択された際に呼び出される。
     * ハイライトシーン選択切り替えをを行う。
     */
    changeScene(highlight: HighlightDocument) {
      if (highlight.isCreatedBySfgoUser && highlight.playerMasterIds) {
        this.$emit(
          'sceneChange',
          highlight.id,
          highlight.isCreatedBySfgoUser,
          highlight.playerMasterIds[0],
        )
      } else {
        this.$emit('sceneChange', highlight.id, highlight.isCreatedBySfgoUser)
      }
    },

    /**
     * ハイライトカードの選手が選択された場合に呼び出される。
     * ハイライトシーンオンボード選択切り替えを行う。
     * @param playerMasterId 選択された選手の選手マスタID
     */
    changeScenePlayer(playerMasterId: string) {
      // 選手と、ハイライトカードを選択状態にする
      this.$emit(
        'sceneChange',
        this.highlightData.highlightId,
        this.highlightData.isCreatedBySfgoUser,
        playerMasterId,
      )
    },

    /**
     * ハイライトに関連づけられている選手の名前を取得する。
     * @param playerMasterId 選手マスタID
     */
    playerName(playerMasterId: string) {
      const targetPlayer = this.players.find(
        (player: PlayerDocument) => player.playerMasterId === playerMasterId,
      )
      if (targetPlayer) {
        return targetPlayer.playerName[this.$i18n.locale] || ''
      }
      return ''
    },
    /**
     * コメント編集ボタン押下時の処理
     * @param editedComment 編集後のコメント
     * @param commentId 編集対象のコメントID
     */
    handlerEdit(editedComment: string, commentId: string) {
      this.$emit('handlerEdit', editedComment, commentId)
    },
    /**
     * コメント投稿ボタン押下時の処理
     * @param highlight コメント対象のハイライト
     */
    handlerSubmit(highlight: HighlightDocument) {
      this.$emit('handlerSubmit', highlight)
    },
    /**
     * コメント削除確認モーダル表示通知
     * @param commentId コメントID
     */
    onShowDeleteConfirmModal(commentId: string) {
      this.$emit('onShowDeleteConfirmModal', commentId)
    },
    /**
     * コメント投稿入力開始/終了通知
     * @param enabledInput ハイライトの入力状態
     * @param highlightId コメント対象のハイライトID
     */
    onToggleEnabledInput(enabledInput: boolean, highlightId: string) {
      if (enabledInput) {
        // コメント入力可能な場合は、入力位置までスクロールするように親に伝える
        this.$emit('onScrollToComment', `${this.id}PostInput`)
      }

      this.$emit('onToggleEnabledInput', enabledInput, highlightId)
    },
    /**
     * コメント編集開始/終了通知
     * @param isEditMode ハイライトコメント編集状態
     * @param commentId 編集対象のコメントID
     * @param scrollToId スクロール対象のコメントID
     */
    onToggleIsEditMode(isEditMode: boolean, commentId: string, scrollToId: string) {
      if (isEditMode) {
        // コメント入力可能な場合は、入力位置までスクロールするように親に伝える
        this.$emit('onScrollToComment', scrollToId)
      }

      this.$emit('onToggleIsEditMode', isEditMode, commentId)
    },
    /**
     * 入力コメント変更
     * @param value 入力したハイライトコメント
     * @param highlightId コメント対象のハイライトID
     */
    onChangeHighlightComment(value: string, highlightId: string) {
      this.$emit('onChangeHighlightComment', value, highlightId)
    },
    /**
     * コメントLIKE処理
     * @param likeData 押下されたいいねの情報
     */
    onChangeLike(likeData: ClickedLikeDataType) {
      this.$emit('onChangeLike', likeData)
    },
    /**
     * 親コメント編集中止
     */
    onCancelHighlightCommentInput() {
      this.enabledConfirmModal = false
      this.$emit('onToggleMainCommentIsEditMode', false, this.highlightData.highlightId)
    },
    /**
     * 親コメント編集保存
     * @param comment 編集後のコメント
     */
    onSaveHighlightComment(comment: string) {
      this.$emit('onChangeHighlightMainComment', comment, this.highlightData.highlightId)
    },
    /**
     * ダウンロード可能状態でユーザーがダウンロードボタンを押した際にファイルをダウンロードする
     */
    handleDownloadClicked() {
      this.$emit('downloadHighlight', this.sceneMovies)
    },
  },
})
