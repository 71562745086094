var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", { staticClass: "notification-type-icon-parts" }, [
    _c(
      "svg",
      {
        attrs: {
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _vm.notificationType === "highlight"
          ? _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M1 2C0.447715 2 0 2.44772 0 3V15C0 15.5523 0.447716 16 1 16H17C17.5523 16 18 15.5523 18 15V3C18 2.44772 17.5523 2 17 2H1ZM2 4H4V6H2V4ZM8 5L15 9L8 13V5ZM4 12H2V14H4V12ZM2 8H4V10H2V8Z",
                fill: "#00DBA6",
              },
            })
          : _vm.notificationType === "comment"
          ? _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M17 16.4148L14.0193 14.2857H4C2.34315 14.2857 1 12.9426 1 11.2857V5C1 3.34315 2.34314 2 4 2H14C15.6569 2 17 3.34315 17 5V16.4148ZM4 7.1499V9.1499H6V7.1499H4ZM12 9.1499V7.1499H14V9.1499H12ZM8 7.1499V9.1499H10V7.1499H8Z",
                fill: "#00DBA6",
              },
            })
          : _vm.notificationType === "SFGO_NOTIFICATION_FROM_MANAGEMENT"
          ? _c("path", {
              attrs: {
                d: "M3 14.25V12.75H4.5V7.5C4.5 6.4625 4.8125 5.5405 5.4375 4.734C6.0625 3.9275 6.875 3.3995 7.875 3.15V2.625C7.875 2.3125 7.9845 2.04675 8.2035 1.82775C8.4225 1.60875 8.688 1.4995 9 1.5C9.3125 1.5 9.57825 1.6095 9.79725 1.8285C10.0162 2.0475 10.1255 2.313 10.125 2.625V3.15C11.125 3.4 11.9375 3.92825 12.5625 4.73475C13.1875 5.54125 13.5 6.463 13.5 7.5V12.75H15V14.25H3ZM9 16.5C8.5875 16.5 8.23425 16.353 7.94025 16.059C7.64625 15.765 7.4995 15.412 7.5 15H10.5C10.5 15.4125 10.353 15.7658 10.059 16.0598C9.765 16.3538 9.412 16.5005 9 16.5ZM6 12.75H12V7.5C12 6.675 11.7063 5.96875 11.1187 5.38125C10.5312 4.79375 9.825 4.5 9 4.5C8.175 4.5 7.46875 4.79375 6.88125 5.38125C6.29375 5.96875 6 6.675 6 7.5V12.75Z",
                fill: "#00DBA6",
              },
            })
          : _vm.notificationType === "SFGO_GRANT_POINT_FROM_MANAGEMENT"
          ? _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9ZM12.8567 8.1955C12.8567 6.8185 11.7767 6.0625 10.1567 6.0625H5.8457V12.3625H7.2857V10.3285H10.1567C11.7767 10.3285 12.8567 9.5905 12.8567 8.1955ZM11.3897 8.2045C11.3897 8.9245 10.8497 9.1405 10.0397 9.1405H7.2857V7.2685H10.0397C10.8497 7.2685 11.3897 7.4845 11.3897 8.2045Z",
                fill: "#00DBA6",
              },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }