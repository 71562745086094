







































import { computed, defineComponent } from '@vue/composition-api'
import AtomInputPassword from '@/components/atoms/input/AtomInputPassword.vue'

type DataType = {
  textVisible: boolean
}
/**
 * Password 表示切り替え対応 バーツ
 */
export default defineComponent({
  name: 'PasswordWithToggleButtonParts',
  components: {
    AtomInputPassword,
  },
  data(): DataType {
    return {
      /**
       * パスワード テキスト表示フラグ
       */
      textVisible: false,
    }
  },
  props: {
    /**
     * ID
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Value値
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 表示のみ
     */
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Error判定
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * プレースホルダー
     */
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * password
     * @param {string} val パスワード
     */
    const password = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })

    return {
      password,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
    /**
     * 入力欄がフォーカスされたことを通知する
     */
    emitFocus() {
      /**
       * 入力欄がフォーカスされたことを通知する
       * @event focus
       * @type {Object}
       */
      this.$emit('focus')
    },
    /**
     * 入力欄からフォーカスが外れたことを通知する
     */
    emitBlur() {
      /**
       * 入力欄からフォーカスが外れたことを通知する
       * @event blur
       * @type {Object}
       */
      this.$emit('blur')
    },
    changeInputType() {
      /**
       * パスワードをテキスト表示に変更切り替え
       */
      this.textVisible = !this.textVisible
    },
  },
})
