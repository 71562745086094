




























import { defineComponent, ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageFavoriteTeamSelectPane from '@/components/MypageFavoriteTeamSelectPage/MypageFavoriteTeamSelectPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

export type DataType = {
  screenTransitionModal: boolean
  screenTransitionTo: string
}

/**
 * マイページ: お気に入りチーム選択ページ
 */
export default defineComponent({
  name: 'MypageFavoriteTeamSelectPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageFavoriteTeamSelectPane,
    ActionButtonsSection,
    AtomInputButton,
  },
  data(): DataType {
    return {
      screenTransitionModal: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '', // 画面遷移警告モーダル 遷移先指定
    }
  },
  setup() {
    const mypageFavoriteTeamSelectPane = ref<InstanceType<typeof MypageFavoriteTeamSelectPane>>()
    return {
      mypageFavoriteTeamSelectPane,
    }
  },
  methods: {
    setFavoriteTeam(): void {
      /**
       * 変更確認通知
       */
      this.mypageFavoriteTeamSelectPane?.changeFavoriteTeam()
    },
    /**
     * マイページTOPページに遷移する
     */
    moveToMypageTop(): void {
      this.$router.replace({ path: '/mypage' })
    },
  },
})
