


































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import MissionHexagonOuterIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionHexagonOuterIcon.vue'
import MissionHexagonInnerIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionHexagonInnerIcon.vue'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'
import { AchievementConditionType } from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import MissionMotegiIcon from '@/components/MissionPage/MissionListPane/parts/icons/circuits/MissionMotegiIcon.vue'
import MissionFujiIcon from '@/components/MissionPage/MissionListPane/parts/icons/circuits/MissionFujiIcon.vue'
import MissionSuzukaIcon from '@/components/MissionPage/MissionListPane/parts/icons/circuits/MissionSuzukaIcon.vue'
import MissionAutopolisIcon from '@/components/MissionPage/MissionListPane/parts/icons/circuits/MissionAutopolisIcon.vue'
import MissionSugoIcon from '@/components/MissionPage/MissionListPane/parts/icons/circuits/MissionSugoIcon.vue'
import {
  CHECKED_IN_CIRCUIT_LIST,
  CHECKED_IN_SUZUKA_CIRCUIT,
  CHECKED_IN_AUTOPOLIS,
  CHECKED_IN_SPORTLAND_SUGO,
  CHECKED_IN_FUJI_SPEEDWAY,
  CHECKED_IN_MOBILITY_RESORT_MOTEGI,
} from '@/util/Const'

/**
 * 1度もミッションを達成したことがない場合に前回の達成条件にセットされる値の型
 */
type NoPreviousConditionType = 'noPreviousCondition'

/**
 * ミッション達成状況を表現する型
 */
type AchievementStatusType = 'inactive' | 'active' | 'completed'

/**
 * ミッション画面 ミッション達成状況 ハニカムサーキット表示六角形パーツ
 */
export default defineComponent({
  name: 'MissionHexagonCircuitParts',
  components: {
    MissionSugoIcon,
    MissionAutopolisIcon,
    MissionSuzukaIcon,
    MissionFujiIcon,
    MissionMotegiIcon,
    MissionHexagonInnerIcon,
    MissionHexagonOuterIcon,
  },
  props: {
    /**
     * 達成条件
     */
    condition: {
      type: Object as PropType<AchievementConditionType>,
      required: true,
    },
    /**
     * 前回の達成条件
     */
    prevCondition: {
      type: [Object, String] as PropType<AchievementConditionType | NoPreviousConditionType>,
    },
    /**
     * ミッション達成状況カード
     */
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
  },
  setup(props) {
    const forPc = DeviceInfo.isForPC()

    const iconSize = computed(() => {
      if (props.mission.hasCompleted && forPc) {
        return { x: 74, y: 74 }
      }

      if (props.mission.hasCompleted) {
        return { x: 54, y: 54 }
      }

      if (forPc) {
        return { x: 33, y: 33 }
      }

      return { x: 24, y: 24 }
    })

    /**
     * active状態の取得する
     */
    const getActiveStatus = () => {
      if (props.mission.honeycombType === 'check') {
        // ハニカム内がチェックマークの場合は、activeにする
        return true
      }

      if (props.prevCondition === 'noPreviousCondition') {
        // 該当ミッションを1度も達成していない場合は、最初のミッションをactiveにする
        return true
      }
      // 該当ミッションの一つ前の回数を達成している場合はactiveにする
      return props.prevCondition?.isAchieved
    }

    /**
     * ミッション達成状況のステータスを返す
     */
    const achievementStatus: Ref<AchievementStatusType> = computed(() => {
      if (props.condition.isAchieved) {
        // ハニカム内の該当回数達成済みの場合
        return 'completed'
      }
      if (getActiveStatus()) {
        return 'active'
      }

      // 上記以外
      return 'inactive'
    })

    /**
     * 対象のサーキット情報を取得する
     */
    const circuit = CHECKED_IN_CIRCUIT_LIST.find(
      (checkInCircuit) => props.condition.pointCode?.indexOf(checkInCircuit.missionCode) !== -1,
    )

    return {
      iconSize,
      achievementStatus,
      circuit,
      CHECKED_IN_SUZUKA_CIRCUIT,
      CHECKED_IN_AUTOPOLIS,
      CHECKED_IN_SPORTLAND_SUGO,
      CHECKED_IN_FUJI_SPEEDWAY,
      CHECKED_IN_MOBILITY_RESORT_MOTEGI,
    }
  },
})
