










import { defineComponent } from '@vue/composition-api'

/**
 * レース動画再生画面 動画プレーヤー オンオフ切り替えボタン コンポーネント
 */
export default defineComponent({
  name: 'ToggleButtonParts',
  props: {
    /**
     * 表示コンテンツモード
     */
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggle(): void {
      /**
       * 有効化切り替え
       */
      this.$emit('toggle')
    },
  },
})
