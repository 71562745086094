











import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageEcSiteConnectPane from '@/components/MypageEcSiteConnectPage/MypageEcSiteConnectPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

export default defineComponent({
  name: 'MypageEcSiteConnectPage',
  components: { GlobalNavigationPane, MypageEcSiteConnectPane, SubHeaderSection },
})
