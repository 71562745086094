import { computed, inject } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import useHighlightComment from '@/store/hook/useHighlightComment'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import Logger from '@/util/logger/Logger'
import HighlightCommentDocument from '@/store/stores/collectionModule/documents/highlight/HighlightCommentDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import { SaveResponse } from '@/store/stores/collectionModule/CollectionTypes'
import StoreUtil from '@/store/StoreUtil'

/**
 * 投稿コメントユーザー情報タイプ
 */
type commentUserDataType = {
  /**
   * ユーザーID
   */
  id: string
}

/**
 * 投稿コメントデータタイプ
 */
export type CommentDataType = {
  highlightId: string
  commentId: string
  date: number
  user: commentUserDataType
  comment: string | null
  likeCount: number
  liked: boolean
}

/**
 * 親コメントデータタイプ
 */
export type ParentCommentDataType = {
  highlightId: string
  commentId: string
  date: number
  comment: string | null
  likeCount: number
  liked: boolean
  raceTime: string
  userId: string
}

/**
 * シェア結果タイプ
 */
export type ShareResult = 'share' | 'copy' | 'error'

/**
 * シェアするデータ
 */
export type ShareComment = {
  title: string
  text: string
  url: string
}

/**
 * 動画再生画面コミュニケーション機能に関する機能を提供する。
 */
export default function useCommunication() {
  const loading = inject('loading') as PluginApi
  const { createHighlightCommentRequestData, saveHighlightComment, removeHighlightComment } =
    useHighlightComment()
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

  const { highlightCommentStore, reactionsCountByTargetId, ownReactionsByTargetId } =
    raceVideoPageStore

  /**
   * 表示用のコメントデータを作成
   */
  const createDisplayCommentData = (highlightComment: HighlightCommentDocument) => ({
    highlightId: highlightComment.highlightId ?? '',
    commentId: highlightComment.commentId ?? '',
    date: highlightComment._createdDate ?? 0,
    user: {
      id: highlightComment.additionalData?.userId ?? '',
    },
    comment: highlightComment.comment ?? '',
    likeCount:
      reactionsCountByTargetId.value.communication_comment?.[highlightComment.commentId ?? ''] ?? 0,
    liked: !!ownReactionsByTargetId.value.communication_comment?.[highlightComment.commentId ?? ''],
  })

  /**
   * 表示用のハイライトのコメントデータを作成
   */
  const createDisplayHighlightCommentData = (
    highlight: HighlightDocument,
  ): ParentCommentDataType => ({
    highlightId: highlight.id ?? '',
    commentId: '',
    date: highlight._createdDate || 0,
    comment: highlight.note || '',
    likeCount:
      reactionsCountByTargetId.value.communication_user_game_event?.[highlight.id ?? ''] ?? 0,
    liked: !!ownReactionsByTargetId.value.communication_user_game_event?.[highlight.id ?? ''],
    raceTime: highlight.raceTime,
    userId: highlight._createdBy ?? '',
  })

  /**
   * ハイライトID毎に分類されたハイライトコメント情報をハイライト一覧表示表データに加工して返す
   */
  const highlightCommentDataById = computed(() => {
    const highlightCommentsMap: Record<string, Array<CommentDataType>> = {}

    // SFgo親組織以外のハイライトコメント
    const filteredHighlightComments = highlightCommentStore.highlightComments.value.filter(
      (highlightComment) =>
        !highlightComment.deleted &&
        highlightComment._organization !== process.env.VUE_APP_SFGO_PARENT_ORG_ID,
    )

    filteredHighlightComments.forEach((highlightComment) => {
      const commentData = createDisplayCommentData(highlightComment)
      if (highlightComment.highlightId) {
        if (highlightCommentsMap[highlightComment.highlightId]) {
          // 対象ハイライトIDのプロパティが存在する場合、対象ハイライトIDに紐づくコメントを配列に追加する
          highlightCommentsMap[highlightComment.highlightId].push(commentData)
        } else {
          // 対象ハイライトIDのプロパティが存在しない場合、対象ハイライトIDをキーにして、対象ハイライトIDに紐づくコメントの配列を作成する
          highlightCommentsMap[highlightComment.highlightId] = [commentData]
        }
      }
    })
    return highlightCommentsMap
  })

  /**
   * コメント投稿
   * @param currentRaceId レースID
   * @param comment 入力したコメント
   * @param highlight ハイライト
   * @param userId ユーザーID
   * @return {isSuccess: boolean}
   */
  const createHighlightComment = async (
    currentRaceId: string,
    comment: string,
    highlight: HighlightDocument,
    userId: string,
  ): Promise<SaveResponse<HighlightCommentDocument>> => {
    if (currentRaceId && comment) {
      const requestData = createHighlightCommentRequestData(
        comment,
        currentRaceId,
        highlight,
        userId,
      )
      const loader = loading.show()
      const result = await saveHighlightComment(requestData)
      loader.hide()
      if (!result.isSuccess) {
        // ハイライトコメントの投稿に失敗した場合、エラーメッセージを表示
        await MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.postHighlightCommentError.title'),
          message: I18n.tc('RaceVideoPage.errors.postHighlightCommentError.message'),
        })
        Logger.info(
          `useCommunication#createHighlightComment: Failed to post comment. highlightId: ${highlight.highlightId}`,
        )
      }
      return result
    }

    // ハイライトコメントの投稿に失敗した場合、エラーメッセージを表示
    await MessageDialogStore.value.open({
      title: I18n.tc('RaceVideoPage.errors.postHighlightCommentError.title'),
      message: I18n.tc('RaceVideoPage.errors.postHighlightCommentError.message'),
    })
    Logger.info(
      `useCommunication#createHighlightComment: Undefined currentRaceId or comment. currentRaceId: ${currentRaceId}, comment: ${comment}`,
    )
    return { isSuccess: false, data: null }
  }

  /**
   * コメント編集
   * @param highlightComment 更新対象のハイライトコメント
   * @param editedComment 編集したコメント
   * @param commentId コメントId
   * @return {isSuccess: boolean}
   */
  const updateHighlightComment = async (
    highlightComment: HighlightCommentDocument | undefined,
    editedComment: string,
    commentId: string,
  ): Promise<{ isSuccess: boolean }> => {
    if (highlightComment) {
      const requestData = new HighlightCommentDocument({
        ...highlightComment,
        comment: editedComment,
      })
      const loader = loading.show()
      const result = await saveHighlightComment(requestData)
      loader.hide()
      if (!result.isSuccess) {
        // ハイライトコメントの編集に失敗した場合、エラーメッセージを表示
        await MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.editHighlightCommentError.title'),
          message: I18n.tc('RaceVideoPage.errors.editHighlightCommentError.message'),
        })
        Logger.info(
          `useCommunication#updateHighlightComment: Failed to update comment. commentId: ${highlightComment.commentId}`,
        )
        return { isSuccess: false }
      }
    } else {
      // ハイライトコメントの投稿に失敗した場合、エラーメッセージを表示
      await MessageDialogStore.value.open({
        title: I18n.tc('RaceVideoPage.errors.editHighlightCommentError.title'),
        message: I18n.tc('RaceVideoPage.errors.editHighlightCommentError.message'),
      })
      Logger.info(
        `useCommunication#updateHighlightComment: Undefined editing target. commentId: ${commentId}`,
      )
      return { isSuccess: false }
    }

    return { isSuccess: true }
  }

  /**
   * コメント削除
   * @param commentId コメントId
   * @return {isSuccess: boolean}
   */
  const deleteHighlightComment = async (commentId: string): Promise<{ isSuccess: boolean }> => {
    if (commentId) {
      const loader = loading.show()
      const result = await removeHighlightComment(commentId)
      loader.hide()
      if (!result.isSuccess) {
        // ハイライトコメントの削除に失敗した場合、エラーメッセージを表示
        await MessageDialogStore.value.open({
          title: I18n.tc('RaceVideoPage.errors.deleteHighlightCommentError.title'),
          message: I18n.tc('RaceVideoPage.errors.deleteHighlightCommentError.message'),
        })
        Logger.info(
          `useCommunication#deleteHighlightComment: Failed to delete comment. commentId: ${commentId}`,
        )
        return { isSuccess: false }
      }
    } else {
      // ハイライトコメントの投稿に失敗した場合、エラーメッセージを表示
      await MessageDialogStore.value.open({
        title: I18n.tc('RaceVideoPage.errors.deleteHighlightCommentError.title'),
        message: I18n.tc('RaceVideoPage.errors.deleteHighlightCommentError.message'),
      })
      Logger.info(
        `useCommunication#deleteHighlightComment: Undefined deletion target. commentId: ${commentId}`,
      )
      return { isSuccess: false }
    }

    return { isSuccess: true }
  }

  /**
   * ハイライトコメントシェア
   * @param shareData シェアするデータ ShareComment {title: string, text: string, url: string}
   * @return {isResponse: string} isResponse: 'share' or 'copy' or 'error'
   */
  const shareHighlightComment = async (
    shareData: ShareComment,
  ): Promise<{ isResponse: ShareResult }> => {
    if (!window.navigator.share) {
      await navigator.clipboard.writeText(shareData.url)

      return { isResponse: 'copy' }
    }
    const [share] = await Promise.all([
      window.navigator
        .share(shareData)
        .then(() => true)
        .catch((error) => {
          console.error(error)
          return false
        }),
    ])

    if (!share) {
      return { isResponse: 'error' }
    }

    return { isResponse: 'share' }
  }

  return {
    createDisplayCommentData,
    createDisplayHighlightCommentData,
    highlightCommentDataById,
    createHighlightComment,
    updateHighlightComment,
    deleteHighlightComment,
    shareHighlightComment,
  }
}
