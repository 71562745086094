




import { defineComponent, PropType } from '@vue/composition-api'
import CautionMaintenanceSection from '@/components/LoginPage/CautionMaintenancePane/CautionMaintenanceSection.vue'
import type { CautionMessageInfoType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * メンテナンス表示のペイン。
 */
export default defineComponent({
  name: 'CautionMaintenancePane',
  components: {
    CautionMaintenanceSection,
  },
  props: {
    cautionMessageInfo: {
      type: Object as PropType<CautionMessageInfoType>,
      required: true,
    },
  },
})
