var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-list-section", {
    attrs: { listData: _vm.listData, selectedID: _vm.selectedItemID },
    on: { selectChange: _vm.changeSelected },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (slotProps) {
          return [
            _c("team-card-parts", {
              attrs: {
                name: _vm.getTeamName(slotProps.item)[_vm.$i18n.locale],
                img: _vm.teamLogoUrl(slotProps.item),
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }