
















import { defineComponent, PropType } from '@vue/composition-api'
import StandingsListPlayerParts, {
  StandingsPlayerDataType,
} from '@/components/StandingsPage/StandingsTopPane/parts/StandingsListPlayerParts.vue'

/**
 * Standings: 選手リストセクション
 */
export default defineComponent({
  name: 'StandingsListSection',
  components: {
    StandingsListPlayerParts,
  },
  props: {
    standingsPlayerList: {
      type: Array as PropType<StandingsPlayerDataType[]>,
      required: true,
    },
  },
})
