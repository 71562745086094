




















import { defineComponent, PropType, ref } from '@vue/composition-api'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

type RefElementType = 'numberElement' | 'expiryElement' | 'cvcElement'

/**
 * フィールドセット テキスト(数字) バーツ
 */
export default defineComponent({
  name: 'FieldsetTextCreditParts',
  components: {
    FormErrorMessageParts,
  },
  props: {
    /**
     * データタイトル
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * 入力データ
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * カード情報を入力するElement
     */
    refElement: {
      type: String as PropType<RefElementType>,
      default: '',
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup() {
    /** props.refElementで渡ってきたものと同じプロパティの場合、onMounted後にTemplate ref経由で取得される */
    const paymentElements = {
      /**
       * カード番号を入力するElement
       * onMounted後にTemplate ref経由でここに取得される
       */
      numberElement: ref(null),
      /**
       * カード有効期限を入力するElement
       * onMounted後にTemplate ref経由でここに取得される
       */
      expiryElement: ref(null),
      /**
       * CVCを入力するElement
       * onMounted後にTemplate ref経由でここに取得される
       */
      cvcElement: ref(null),
    }

    return {
      ...paymentElements,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
