









import { defineComponent } from '@vue/composition-api'

/**
 * 新規会員登録 ヘッダーセクション
 */
export default defineComponent({
  name: 'SignupHeaderSection',
  components: {},
  props: {
    /**
     * カレントステップ番号
     */
    steps: {
      type: Number,
      default: 0,
    },
    /**
     * ページタイトル
     */
    pageTitle: {
      type: String,
      default: '',
    },
  },
})
