var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-notification-item-wrapper-parts",
    {
      staticClass: "base-notification-item-contents-parts",
      attrs: { notification: _vm.notification },
      on: {
        handlerClickLink: function ($event) {
          return _vm.$emit("handlerClickLink", _vm.notification)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "base-notification-item-contents-parts__contents",
          class: {
            "base-notification-item-contents-parts__contents--has-link":
              _vm.notification.link,
          },
        },
        [
          _c(
            "header",
            { staticClass: "base-notification-item-contents-parts__header" },
            [
              _c("notification-type-icon-parts", {
                staticClass: "base-notification-item-contents-parts__type-icon",
                attrs: { notificationType: _vm.notification.type },
              }),
              _c("comment-post-date-parts", {
                staticClass: "base-notification-item-contents-parts__post-date",
                attrs: { postDate: _vm.notification.date },
              }),
              _c("h3", {
                staticClass: "base-notification-item-contents-parts__title",
                domProps: {
                  textContent: _vm._s(_vm.notification.title[_vm.$i18n.locale]),
                },
              }),
            ],
            1
          ),
          _vm.notification.message
            ? _c("h5", {
                staticClass: "base-notification-item-contents-parts__message",
                domProps: {
                  innerHTML: _vm._s(_vm.notification.message[_vm.$i18n.locale]),
                },
              })
            : _vm._e(),
          _vm.notification.note
            ? _c("p", {
                staticClass: "base-notification-item-contents-parts__note",
                domProps: { innerHTML: _vm._s(_vm.notification.note) },
              })
            : _vm._e(),
          _vm.notification.link
            ? _c(
                "i",
                {
                  staticClass:
                    "base-notification-item-contents-parts__link-icon",
                },
                [
                  _vm.isExternalLink(_vm.notification.link)
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/icon/icon_external_link_outline.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/icon/icon_chevron_right_24.svg"),
                          alt: "",
                        },
                      }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }