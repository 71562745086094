var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "mission-ranking-end-date-parts" }, [
    _c("span", { staticClass: "mission-ranking-end-date-parts__date" }, [
      _vm._v(_vm._s(_vm.endDate)),
    ]),
    _c("span", { staticClass: "mission-ranking-end-date-parts__label" }, [
      _vm._v(_vm._s(_vm.$tc("MissionPage.RankingPage.postingEnd"))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }