




















import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import RadioListItemParts from '@/components/RaceVideoPage/RaceVideoRadioPane/parts/RadioListItemParts.vue'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * レース動画再生画面 無線一覧セクションのコンポーネント
 */
export default defineComponent({
  name: 'RadioListSection',
  components: {
    RadioListItemParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選択選手無線データ
     */
    raceRadioData: {
      type: Array as PropType<RadioDataDocument[]>,
      default: () => [],
    },
  },
  setup(props) {
    const playerRadioDataSet = computed((): Array<RadioDataDocument> => props.raceRadioData)
    const isForPc = computed(() => DeviceInfo.isForPC())

    return {
      playerRadioDataSet,
      isForPc,
    }
  },
  methods: {
    /**
     * 無線 シングル再生
     * 選択したコンポーネント以外の再生状態をOFFにする
     * @event radioPlay
     */
    async playRadioAudio(radioData: RadioDataDocument, scene: boolean) {
      await this.$emit('radioPlay', radioData, scene)
    },
  },
})
