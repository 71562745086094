var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "referral-code-pane" },
    [
      _c("referral-code-section", { attrs: { code: _vm.referralCode } }),
      _vm.baseCouponPlan
        ? _c("referral-coupon-section", {
            attrs: { baseCouponPlan: _vm.baseCouponPlan },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }