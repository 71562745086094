















import { defineComponent, PropType } from '@vue/composition-api'

type SwitchType = 'edit' | 'cancel'

/**
 * マイページ: 会員証 編集切り替えボタン
 */
export default defineComponent({
  name: 'SwitchEditModeButtonParts',
  props: {
    type: {
      type: String as PropType<SwitchType>,
      required: true,
    },
  },
  methods: {
    /**
     * 編集キャンセル通知
     */
    async emitClick() {
      this.$emit('click')
    },
  },
})
