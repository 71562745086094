
















import { defineComponent, PropType } from '@vue/composition-api'
import { LinkData } from '@/components/LinkPage/LinkPane.vue'
import LinkItemParts from '@/components/LinkPage/LinkPane/LinkItemParts.vue'

/**
 * Link セクション
 */
export default defineComponent({
  name: 'LinkSection',
  components: {
    LinkItemParts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    linksData: {
      type: Array as PropType<LinkData[]>,
      required: true,
    },
  },
})
