














import { defineComponent, PropType } from '@vue/composition-api'
import CalendarItemSection from '@/components/LinkPage/CalendarPane/CalendarItemSection.vue'
import CalendarDataDocument from '@/store/stores/collectionModule/documents/calendar/CalendarDataDocument'

export default defineComponent({
  name: 'CalendarListSection',
  components: { CalendarItemSection },
  props: {
    calendarData: {
      type: Array as PropType<CalendarDataDocument[]>,
      default: () => [],
    },
  },
})
