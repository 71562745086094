var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlights-slide-menu-parts",
      class: { "highlights-slide-menu-parts--isPc": _vm.isPc },
      on: { click: _vm.handleOverlayClicked },
    },
    [
      _c("div", { staticClass: "highlights-slide-menu-parts__inner" }, [
        _c(
          "ul",
          { staticClass: "highlights-slide-menu-parts__list" },
          [
            _vm._l(_vm.state.menuList[_vm.highlightSlideMenu], function (menu) {
              return [
                menu.display
                  ? _c(
                      "li",
                      {
                        key: menu.id,
                        staticClass: "highlights-slide-menu-parts__item",
                      },
                      [
                        menu.link
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "highlights-slide-menu-parts__button",
                                attrs: { href: menu.link, target: "_blank" },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(menu.name))]),
                                _c(
                                  "svg",
                                  {
                                    staticClass:
                                      "highlights-slide-menu-parts__button-icon",
                                    attrs: {
                                      width: "16",
                                      height: "17",
                                      viewBox: "0 0 16 17",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M12.8619 7.87265C12.987 7.74763 13.1565 7.67739 13.3333 7.67739C13.5101 7.67739 13.6797 7.74763 13.8047 7.87265C13.9298 7.99767 14 8.16724 14 8.34406V12.3441C14 12.8745 13.7893 13.3832 13.4142 13.7583C13.0391 14.1333 12.5304 14.3441 12 14.3441H4C3.46957 14.3441 2.96086 14.1333 2.58579 13.7583C2.21071 13.3832 2 12.8745 2 12.3441V4.34406C2 3.81362 2.21071 3.30491 2.58579 2.92984C2.96086 2.55477 3.46957 2.34406 4 2.34406H8C8.17681 2.34406 8.34638 2.41429 8.4714 2.53932C8.59643 2.66434 8.66667 2.83391 8.66667 3.01072C8.66667 3.18753 8.59643 3.3571 8.4714 3.48213C8.34638 3.60715 8.17681 3.67739 8 3.67739H4C3.82319 3.67739 3.65362 3.74763 3.5286 3.87265C3.40357 3.99767 3.33333 4.16724 3.33333 4.34406V12.3441C3.33333 12.5209 3.40357 12.6904 3.5286 12.8155C3.65362 12.9405 3.82319 13.0107 4 13.0107H12C12.1768 13.0107 12.3464 12.9405 12.4714 12.8155C12.5964 12.6904 12.6667 12.5209 12.6667 12.3441V8.34406C12.6667 8.16724 12.7369 7.99767 12.8619 7.87265Z",
                                        fill: "white",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M11.72 3.67739H10.6667C10.4899 3.67739 10.3203 3.60715 10.1953 3.48213C10.0702 3.3571 10 3.18753 10 3.01072C10 2.83391 10.0702 2.66434 10.1953 2.53932C10.3203 2.41429 10.4899 2.34406 10.6667 2.34406H13.3333C13.5101 2.34406 13.6797 2.41429 13.8047 2.53932C13.9298 2.66434 14 2.83391 14 3.01072V5.67739C14 5.8542 13.9298 6.02377 13.8047 6.14879C13.6797 6.27382 13.5101 6.34406 13.3333 6.34406C13.1565 6.34406 12.987 6.27382 12.8619 6.14879C12.7369 6.02377 12.6667 5.8542 12.6667 5.67739V4.62406L8.47333 8.81072C8.41136 8.87321 8.33762 8.9228 8.25638 8.95665C8.17515 8.9905 8.08801 9.00792 8 9.00792C7.91199 9.00792 7.82486 8.9905 7.74362 8.95665C7.66238 8.9228 7.58864 8.87321 7.52667 8.81072C7.46418 8.74875 7.41459 8.67501 7.38074 8.59377C7.34689 8.51253 7.32947 8.4254 7.32947 8.33739C7.32947 8.24938 7.34689 8.16224 7.38074 8.081C7.41459 7.99976 7.46418 7.92603 7.52667 7.86406L11.72 3.67739Z",
                                        fill: "white",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "button",
                              {
                                staticClass:
                                  "highlights-slide-menu-parts__button",
                                class: [
                                  "highlights-slide-menu-parts__button--" +
                                    menu.id,
                                  {
                                    "highlights-slide-menu-parts__button--disabled":
                                      menu.isDisabled,
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleMenuClicked(
                                      menu.id,
                                      menu.isDisabled
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(menu.name))]),
                                menu.id === "download"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "highlights-slide-menu-parts__note",
                                      },
                                      [_vm._v(_vm._s(_vm.downloadMemo))]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }