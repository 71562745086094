import APIAccessor from '@/util/APIAccessor'
import APINotification from '@//util/APINotification'
import APIResponse from '@/util/APIResponse'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import { BaseOptions, initSaveOptions, SaveOptions, SaveResponse } from './CollectionTypes'

class FileModule {
  // eslint-disable-next-line class-methods-use-this
  createStore<T extends DocumentWrapper>(documentClass: { new (addProps?: Partial<T>): T }) {
    const DocumentClass: { new (addProps?: Partial<T>): T } = documentClass

    /**
     *指定されたdocumentのインスタンス
     */
    const document: T = new DocumentClass()

    /**
     * アクセス先のURLを取得する
     * @param {BaseOptions} options オプション
     */
    const getUrl = (options: BaseOptions) => {
      if (options.url) {
        return options.url
      }
      const { baseUrl } = LoginStore.value
      const { orgId } = LoginStore.value
      if (!baseUrl || !orgId) return ''
      if (options.path) {
        return `${baseUrl}/${orgId}/${options.path}`
      }
      if (document._removeOrgPath) {
        return `${baseUrl}/${document._path}`
      }
      return `${baseUrl}/${orgId}/${document._path}`
    }

    /**
     * ファイルアップロードを行う
     * @param { FormData } formData アップロードするデータ
     * @param { SaveOptions } options アップロード時のオプション
     * @return axiosのレスポンスを返す
     */
    const upload = async (
      formData: FormData,
      options: SaveOptions = {},
    ): Promise<SaveResponse<T>> => {
      const initOptions = initSaveOptions()
      const _options = { ...initOptions, ...options }

      const { accessToken } = LoginStore.value

      let response: APIResponse | null = null
      response = await APIAccessor.request(
        {
          method: 'post',
          url: getUrl(_options),
          data: formData,
        },
        accessToken,
      )

      // エラー(ネットワークエラー、リソース不存在エラー、サーバ内部エラー)時にモーダルを出す。モーダルの終了を待たずにこの関数の処理を完了したいので、awaitはしない
      APINotification.raiseNotification(response, [0, 404, 500])

      let resData
      let isSuccess = false
      // FIXME: ここの判定文はヘボいので、後でちゃんとFixする
      if (response.status >= 200 && response.status < 300) {
        isSuccess = true
        resData = new DocumentClass(response.data)
      }
      return {
        isSuccess,
        data: (resData as Required<T>) || null,
        response,
      }
    }
    return {
      upload,
    }
  }
}

export default {
  createStore: new FileModule().createStore,
}
