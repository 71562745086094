




























import { defineComponent, onBeforeMount, onMounted, ref } from '@vue/composition-api'
import MissionRankingShareInputSection from '@/components/MissionPage/MissionRankingPane/MissionRankingShareInputSection.vue'

export default defineComponent({
  name: 'MissionRankingSharePostSection',
  components: { MissionRankingShareInputSection },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const thumbnail = ref<HTMLImageElement | null>(null)
    const imageHeight = ref(252)

    const setImageHeight = () => {
      imageHeight.value = thumbnail.value?.getBoundingClientRect().height || 252
    }

    onMounted(() => {
      setImageHeight()

      window.addEventListener('resize', setImageHeight)
    })

    onBeforeMount(() => {
      window.removeEventListener('resize', setImageHeight)
    })

    return {
      thumbnail,
      imageHeight,
    }
  },
  methods: {
    onClickPostSns(comment: string, postX: boolean, postInstagram: boolean) {
      this.$emit('onClickPostSns', this.imageUrl, comment, postX, postInstagram)
    },
  },
})
