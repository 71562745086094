import { render, staticRenderFns } from "./AwardPage.vue?vue&type=template&id=487b3640&scoped=true"
import script from "./AwardPage.vue?vue&type=script&lang=ts"
export * from "./AwardPage.vue?vue&type=script&lang=ts"
import style0 from "./AwardPage.vue?vue&type=style&index=0&id=487b3640&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487b3640",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('487b3640')) {
      api.createRecord('487b3640', component.options)
    } else {
      api.reload('487b3640', component.options)
    }
    module.hot.accept("./AwardPage.vue?vue&type=template&id=487b3640&scoped=true", function () {
      api.rerender('487b3640', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LinkPage/AwardPage.vue"
export default component.exports