import type VueRouter from 'vue-router'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import PushNotificationEndpointDocument from '@/store/stores/collectionModule/documents/notification/PushNotificationEndpointDocument'
import DeviceInfo from '@/util/DeviceInfo'
import Logger from '@/util/logger/Logger'

/**
 * Push通知を利用するための処理を提供する。
 */
export default function usePushNotification() {
  const pushNotificationCollectionModule = CollectionModule.createStore(
    PushNotificationEndpointDocument,
  )

  /**
   * プッシュ通知先エンドポイントAPIを利用して、このデバイスの情報を登録する。
   * デバイス情報を登録することで、iOS/Android のPush通知を受け取ることができるようになる。
   *
   * @param pushNotificationDeviceId プッシュ通知対象のデバイスのアプリケーションID
   * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1222049874/API
   */
  const registerDeviceId = async (pushNotificationDeviceId: string) => {
    // 通知先タイプ
    // iOSの場合、APNS または、APNS_SANDBOX を指定する。開発環境の場合、APNS_SANDBOXを指定する必要がある
    // Androidの場合、GCM を指定する
    const type = DeviceInfo.isiOS()
      ? process.env.VUE_APP_PUSH_TYPE_IOS
      : process.env.VUE_APP_PUSH_TYPE_ANDROID
    // デバイスアプリケーションID。AppStore/GooglePlayのアプリ識別子の値を指定する。
    const deviceApplicationId = process.env.VUE_APP_DEVICE_APPLICATION_ID

    const notificationEndpoint = new PushNotificationEndpointDocument({
      type,
      address: pushNotificationDeviceId,
      deviceApplicationId,
      deviceInfo: {
        model: DeviceInfo.device?.model,
        platform: DeviceInfo.device?.platform,
        platformVersion: DeviceInfo.device?.version,
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      },
    })
    return pushNotificationCollectionModule.save(notificationEndpoint)
  }

  /**
   * Push通知処理を初期化する。
   */
  const initPushNotification = async (router: VueRouter) => {
    if (!window.PushNotification) {
      Logger.debug('usePushNotification#initPushNotification: Skip initialize push notification.')
      return
    }

    // Push通知処理内でエラーが発生した場合でも後続の処理が実施できるように、try/catchで囲み、エラーは無視する
    try {
      const push = window.PushNotification.init({
        android: {
          icon: 'notification',
          iconColor: '#000000',
        },
        ios: {
          fcmSandbox: false,
          alert: 'true',
          badge: 'true',
          sound: 'true',
          clearBadge: 'true',
        },
      })
      push.on('registration', (data) => {
        Logger.debug(`usePushNotification#init: registrationId: ${data.registrationId}`)
        registerDeviceId(data.registrationId)
      })

      // Android はホーム画面からアプリ押下して起動したときにバッジが自動で削除されないのでここで削除する処理を追加する。
      if (DeviceInfo.isAndroid()) {
        // フォアグラウンドへの切り替わりを検知する
        document.addEventListener('resume', () => {
          push.clearAllNotifications(
            () => {
              Logger.debug(
                'usePushNotification#clearAllNotifications: Success to clear all notifications',
              )
            },
            () => {
              Logger.debug(
                'usePushNotification#clearAllNotifications: Failed to clear all notifications.',
              )
            },
          )
        })
      }

      push.on('notification', (data) => {
        if (
          // data.additionalData.foreground {boolean} アプリケーションがフォアグラウンドにいるときに通知を受け取った場合trueになる
          // ※ フォアグラウンドにいる場合は通知をタップしなくても受け取っただけでこのコールバック処理が実行されることに注意
          !data.additionalData.foreground &&
          (data.additionalData.dismissed != null || data.additionalData.coldstart)
        ) {
          // アプリがバックグラウンドにいて通知をタップした場合のみ処理を行う
          // 判定文は以下のURLを参考にした
          // https://forum.ionicframework.com/t/push-notification-how-to-reliably-detect-when-you-tap-the-notification-vs-tap-the-app-icon/117724
          router.replace('/login/notification')
          return
        }

        Logger.debug(
          `usePushNotification#notification: Notification received. data: ${JSON.stringify(data)}`,
        )

        // 既にフォアグラウンドの時は処理を抜ける（バッジ追加の処理は行わない）
        if (data.additionalData.foreground) return

        // アプリが実行されていないときに表示されている現在のバッジの数を取得する
        push.getApplicationIconBadgeNumber(
          (badgeNumber) => {
            Logger.debug(
              `'usePushNotification#getApplicationIconBadgeNumber:
              Success to get badge number. badgeNumber: ${badgeNumber}'`,
            )
            const newCount = badgeNumber + 1

            // アプリが実行されていないときに表示されるバッジの数を設定する
            push.setApplicationIconBadgeNumber(
              () => {
                Logger.debug(
                  'usePushNotification#setApplicationIconBadgeNumber: Success to set badge number.',
                )
              },
              () => {
                Logger.debug(
                  'usePushNotification#setApplicationIconBadgeNumber: Failed to set badge number',
                )
              },
              newCount,
            )
          },
          () => {
            Logger.debug(
              'usePushNotification#getApplicationIconBadgeNumber: An error occurred in the push notification process.',
            )
          },
        )
      })

      push.on('error', (e) => {
        // e.message
        Logger.info(`usePushNotification#error: data: ${e.message}`)
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      Logger.info(`usePushNotification#initPushNotification: Failed to init. e:${e.message}`)
    }
  }

  return {
    initPushNotification,
  }
}
