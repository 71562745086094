var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { ref: "notificationPane", staticClass: "notification-pane" },
        [
          _vm.notificationData && _vm.notificationData.length > 0
            ? [
                _c("notification-list-section", {
                  attrs: { notificationList: _vm.notificationData },
                  on: { handlerClickLink: _vm.moveToSession },
                }),
                _c("pagination-section", {
                  attrs: {
                    currentPage: _vm.currentPage,
                    totalPages: _vm.totalPages,
                  },
                  on: { pageSelected: _vm.changePage },
                }),
                _c("flow-line-to-paid-plan-modal-section", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.flowLineToPaidPlanModalEnabled,
                      expression: "flowLineToPaidPlanModalEnabled",
                    },
                  ],
                  attrs: {
                    title: _vm.flowLineToPaidPlanModalContents.title,
                    message: _vm.flowLineToPaidPlanModalContents.message,
                    submitText: _vm.flowLineToPaidPlanModalContents.submitText,
                    link: _vm.flowLineToPaidPlanModalContents.link,
                  },
                  on: { close: _vm.hideFlowLineToPaidPlanModal },
                }),
              ]
            : [
                _c("div", { staticClass: "notification-pane__no-data" }, [
                  _c("h4", {
                    staticClass: "notification-pane__no-data__title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tc("NotificationPage.noData.title")
                      ),
                    },
                  }),
                  _c("p", {
                    staticClass: "notification-pane__no-data__message",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tc("NotificationPage.noData.message")
                      ),
                    },
                  }),
                ]),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }