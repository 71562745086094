/**
 * URLをチェックするための処理を提供する
 */
export default function useCheckURL() {
  /**
   * 以下をテストするための正規表現
   *
   * 1.文字列が「http://」または「https://」で始まる
   * 2.文字列が「www.」で始まる
   * 3.文字列が「.com」、「.net」、「.org」などのドメインを含む
   */
  const linkRegex = /https?:\/\/|www\.|\.[a-z]+/

  /**
   * 外部リンク（http~、xxx.comなど）かを判定する。
   * @param link リンクテキスト
   */
  const isExternalLink = (link: string) => linkRegex.test(link)

  return {
    isExternalLink,
  }
}
