






import { defineComponent, ref } from '@vue/composition-api'
import UserConfirmFormSection from '@/components/MypageUserConfirmPage/MypageUserConfirmPane/UserConfirmFormSection.vue'

/**
 * マイページ: 会員情報変更内容確認 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageUserConfirmPane',
  components: {
    UserConfirmFormSection,
  },
  setup() {
    const userConfirmFormSection = ref<InstanceType<typeof UserConfirmFormSection>>()
    return {
      userConfirmFormSection,
    }
  },
  methods: {
    /**
     * 変更確認通知
     * todo: 仮処理
     */
    saveUserInfo(): void {
      this.userConfirmFormSection?.saveUserInfo()
    },
    regist(): void {
      this.$emit('success-regist')
    },
  },
})
