import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 組織情報更新履歴を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/774308676/46+API
 */
export default class OrganizationHistoryDocument extends DocumentWrapper {
  constructor(initProps?: Partial<OrganizationHistoryDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/operation_log'

  idAttr = '_id'

  /**
   * 操作ログID
   */
  targetId: string | null = null

  /**
   * 操作対象のコレクション名
   */
  targetCollectionName: string | null = null

  /**
   * 操作種別
   */
  operation: string | null = null

  /**
   * 通知利用フラグ
   */
  needNotification: boolean | null = false

  /**
   * Push通知する際のタイトル
   */
  title: Multilingual | null = null

  /**
   * Push通知する際のメッセージ
   */
  message: Multilingual | null = null

  /**
   * 操作内容
   */
  contents?: {
    /**
     * 組織ID
     */
    organizationId?: string | null
    /**
     * ユーザーID
     */
    userId?: string | null
    /**
     * ユーザー名
     */
    userName?: string
  }
}
