



















import { computed, defineComponent } from '@vue/composition-api'
import PasswordWithToggleButtonParts from '@/components/common/form/PasswordWithToggleButtonParts.vue'

/**
 * フィールドセット Password入力 バーツ
 */
export default defineComponent({
  name: 'FieldsetPasswordParts',
  components: {
    PasswordWithToggleButtonParts,
  },
  props: {
    /**
     * 表示ラベル
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * 表示のみ
     */
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * password
     * @param {string} val パスワード
     */
    const password = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    return {
      password,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
    /**
     * 入力欄がフォーカスされたことを通知する
     */
    emitFocus() {
      /**
       * 入力欄がフォーカスされたことを通知する
       * @event focus
       * @type {Object}
       */
      this.$emit('focus')
    },
    /**
     * 入力欄からフォーカスが外れたことを通知する
     */
    emitBlur() {
      /**
       * 入力欄からフォーカスが外れたことを通知する
       * @event blur
       * @type {Object}
       */
      this.$emit('blur')
    },
  },
})
