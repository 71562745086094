
























import { defineComponent, PropType } from '@vue/composition-api'
import useCheckURL from '@/components/hook/useCheckURL'
import { NotificationItemType } from '@/components/NotificationPane/NotificationPane.vue'

/**
 * Notification 詳細アイテム: リンク設定切り替えパーツ
 */
export default defineComponent({
  name: 'BaseNotificationItemContentsParts',
  props: {
    notification: {
      type: Object as PropType<NotificationItemType>,
      required: true,
    },
  },
  setup(_, context) {
    // 外部リンク判定関数取得
    const { isExternalLink } = useCheckURL()

    // アプリ内に遷移するお知らせがクリックされたことを親に伝える
    const clickRouterLink = (notification: NotificationItemType) => {
      context.emit('handlerClickLink', notification)
    }

    return {
      isExternalLink,
      clickRouterLink,
    }
  },
})
