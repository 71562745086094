























import { defineComponent } from '@vue/composition-api'

/**
 * HOME SNS投稿表示選手/チームボタン パーツ
 */
export default defineComponent({
  name: 'FavoriteSelectorButtonParts',
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
})
