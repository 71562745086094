









































import { defineComponent, PropType, Ref } from '@vue/composition-api'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import ComparisonPlanSection from '@/components/SignupPage/common/ComparisonPlanSection.vue'
import ChoosePlanSection from '@/components/SignupPage/PlanSelectForFreePane/ChoosePlanSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import { ProcedureScreenType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'

/**
 * 新規会員登録:プラン選択画面ペインのコンポーネント (無料会員向け)
 */
export default defineComponent({
  name: 'PlanSelectForFreePane',
  components: {
    SignupHeaderSection,
    ComparisonPlanSection,
    ChoosePlanSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  props: {
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<PaidPlanGroupType>,
      required: true,
    },
    /**
     * どの手続き画面か
     */
    procedureScreen: {
      type: String as PropType<ProcedureScreenType>,
      default: null,
    },
  },
  setup(props, context) {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { contractPaidPlans } = signupPageStore
    /**
     * プラン選択
     * @param planGroupId {PaidPlanGroupType}
     */
    const handlerPlanSelect = (planGroupId: PaidPlanGroupType) => {
      context.emit('handlerPlanSelect', planGroupId)
    }

    return {
      Const,
      contractPaidPlans: contractPaidPlans as Ref<Array<ContractPlanDocument>>,
      handlerPlanSelect,
    }
  },
  methods: {
    /**
     * プラン選択切り替え処理 有料向けの画面に切り替わる
     */
    handlerPaidChangeClick() {
      this.$emit('handlerPaidChangeClick', this.Const.MEMBER_TYPE.PAID)
    },
    /**
     * プラン登録遷移処理
     */
    handlerSubmit() {
      this.$emit('handlerSubmit')
    },
  },
})
