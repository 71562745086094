



































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import MissionHexagonOuterIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionHexagonOuterIcon.vue'
import MissionHexagonInnerIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionHexagonInnerIcon.vue'
import MissionCheckIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCheckIcon.vue'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'
import MissionProgressDocument, {
  AchievementConditionType,
} from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import { ROUND_CHECK_IN_MISSION_CODE_LIST } from '@/util/Const'

/**
 * 1度もミッションを達成したことがない場合に前回の達成条件にセットされる値の型
 */
type NoPreviousConditionType = 'noPreviousCondition'

/**
 * ミッション達成状況を表現する型
 */
type AchievementStatusType = 'inactive' | 'active' | 'completed'

/**
 * ミッション画面 ミッション達成状況 ハニカム表示六角形パーツ
 */
export default defineComponent({
  name: 'MissionHexagonParts',
  components: { MissionCheckIcon, MissionHexagonInnerIcon, MissionHexagonOuterIcon },
  props: {
    /**
     * 達成条件
     */
    condition: {
      type: Object as PropType<AchievementConditionType>,
      required: true,
    },
    /**
     * 前回の達成条件
     */
    prevCondition: {
      type: [Object, String] as PropType<AchievementConditionType | NoPreviousConditionType>,
    },
    /**
     * ミッション達成状況カード
     */
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
    /**
     * 開催中のラウンドチェックインまたはイベントチェックインミッション一覧
     */
    checkInMissionsBeingHeld: {
      type: Array as PropType<MissionProgressDocument[]>,
      default: () => [],
    },
  },
  setup(props) {
    const forPc = DeviceInfo.isForPC()

    const iconSize = computed(() => {
      if (props.mission.hasCompleted && forPc) {
        return { x: 74, y: 74 }
      }

      if (props.mission.hasCompleted) {
        return { x: 54, y: 54 }
      }

      if (forPc) {
        return { x: 33, y: 33 }
      }

      return { x: 24, y: 24 }
    })

    /**
     * active状態の取得する
     */
    const getActiveStatus = () => {
      if (props.mission.honeycombType === 'check') {
        // ハニカム内がチェックマークの場合は、activeにする
        return true
      }

      if (props.mission.honeycombType === 'countNoOrder') {
        if (props.mission.missionGroupCode === 'SEASON_ROUND_CHECK_IN') {
          // 開催中のシーズンラウンドチェックインミッションの場合、active状態にする
          return props.checkInMissionsBeingHeld.some((beingHeldMission) => {
            const isRoundCheckInMission = (
              ROUND_CHECK_IN_MISSION_CODE_LIST as Array<string>
            ).includes(beingHeldMission.missionCode || '')
            const round = beingHeldMission.missionCode?.replace('SEASON_ROUND_CHECK_IN_', '')
            return isRoundCheckInMission && round === String(props.condition.operationCount)
          })
        }

        // ミッション達成の順番が順不同な場合は全て非アクティブにする
        return false
      }

      if (props.prevCondition === 'noPreviousCondition') {
        // 該当ミッションを1度も達成していない場合は、最初のミッションをactiveにする
        return true
      }
      // 該当ミッションの一つ前の回数を達成している場合はactiveにする
      return props.prevCondition?.isAchieved
    }

    /**
     * ミッション達成状況のステータスを返す
     */
    const achievementStatus: Ref<AchievementStatusType> = computed(() => {
      if (props.condition.isAchieved) {
        // ハニカム内の該当回数達成済みの場合
        return 'completed'
      }
      if (getActiveStatus()) {
        return 'active'
      }

      // 上記以外
      return 'inactive'
    })

    return {
      iconSize,
      achievementStatus,
    }
  },
})
