var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "flow-line-to-paid-plan-modal-section",
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("modal-title-parts", { attrs: { title: _vm.title } }),
            _c("modal-message-parts", { attrs: { message: _vm.message } }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _vm.submitText
              ? _c("atom-icon-link-button", {
                  attrs: {
                    linkText: _vm.submitText,
                    url: _vm.link,
                    imgPath: require("@/assets/img/icon/icon_external_link_outline__green.svg"),
                  },
                })
              : _vm._e(),
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$tc("common.close"),
                colorType: "tertiary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }