

















import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import RadioHeaderSection from '@/components/RaceVideoPage/RaceVideoRadioPane/RadioHeaderSection.vue'
import RadioListSection from '@/components/RaceVideoPage/RaceVideoRadioPane/RadioListSection.vue'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * レース動画再生画面 無線一覧ペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceVideoRadioPane',
  components: {
    RadioHeaderSection,
    RadioListSection,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
  },
  setup() {
    const { getAllPlayerRadioDataSet, playRadioDataAudio } = useRadio()
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { isLive, fetchRadioData } = raceVideoPageStore

    /**
     * 選手フィルタで選択されている選手ID
     */
    const driverFilterId = ref('')
    /**
     * ラップフィルタで選択されているラップ数
     */
    const lapFilterId = ref('')

    if (isLive.value) {
      // 対象のレースがライブ配信中に無線データ一覧画面を表示した場合、無線データ一覧を取得する
      fetchRadioData()
    }

    /**
     * 選手フィルタ、シーンフィルタの選択に応じてハイライトをフィルタする。
     */
    const filteredRadioDataSet = computed(() =>
      getAllPlayerRadioDataSet.value
        .filter((radio) => {
          let match = true
          if (driverFilterId.value) {
            // ドライバーが指定されている場合、フィルタ
            match = radio.playerId === driverFilterId.value
          }
          if (match && lapFilterId.value) {
            // 周回数が指定されている場合、フィルタする
            match = radio.lap === lapFilterId.value
          }
          return match
        })
        .sort((a, b) => a.createdDate - b.createdDate),
    )

    return {
      driverFilterId,
      lapFilterId,
      filteredRadioDataSet,
      playRadioDataAudio,
    }
  },
  methods: {
    /**
     * 無線交信データの音声を再生する。
     * @param radioData 無線交信データ
     * @param scene 無線が発生した映像の位置にシークするかどうか
     */
    playRadioAudio(radioData: RadioDataDocument, scene: boolean): void {
      this.playRadioDataAudio(radioData, scene)
      // this.$emit('radioPlay', radioData, scene)
    },
    /**
     * フィルタリングするドライバーの変更
     */
    changeDriverFilter(id: string): void {
      this.driverFilterId = id
    },
    /**
     * フィルタリングするLAPSの変更
     */
    changeLapFilter(id: string): void {
      this.lapFilterId = id
    },
  },
})
