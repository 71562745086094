var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan-select-for-free-pane" },
    [
      _c("signup-header-section", {
        attrs: { steps: 3, pageTitle: _vm.$tc("SignupPage.Steps.Step3") },
      }),
      _c("h3", { staticClass: "plan-select-for-free-pane__title" }, [
        _vm._v(_vm._s(_vm.$tc("SignupPage.SelectPlanPage.title"))),
      ]),
      _c("comparison-plan-section", {
        staticClass: "plan-select-for-free-pane__comparison-plan",
        attrs: {
          "section-title": _vm.$tc(
            "SignupPage.SelectPlanPage.comparison.comparisonDescriptionForFree"
          ),
        },
      }),
      _c("choose-plan-section", {
        staticClass: "plan-select-for-free-pane__choose-plan",
        attrs: {
          selectedPlanGroupId: _vm.selectedPlanGroupId,
          planList: _vm.contractPaidPlans,
          procedureScreen: _vm.procedureScreen,
        },
        on: { handlerPlanSelect: _vm.handlerPlanSelect },
      }),
      _c(
        "action-buttons-section",
        {
          staticClass: "plan-select-for-free-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".plan-select-for-free-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            staticClass: "plan-select-for-free-pane__action-buttons__paid",
            attrs: {
              linkText: _vm.$tc(
                "SignupPage.SelectPlanPage.submit.changeMemberShip"
              ),
              isDisabled: _vm.selectedPlanGroupId === "",
            },
            on: { push: _vm.handlerPaidChangeClick },
          }),
          _c("atom-input-button", {
            staticClass: "plan-select-for-free-pane__action-buttons__free",
            attrs: {
              linkText: _vm.$tc(
                "SignupPage.SelectPlanPage.submit.stayOnFreePlan"
              ),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: { push: _vm.handlerSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }