var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-user-init-page" },
    [
      _c("mypage-user-edit-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageUserEditPage.pageTitle"),
          "has-back-link": false,
        },
      }),
      _c("mypage-user-init-pane", {
        staticClass: "mypage-user-init-page__content",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }