






















import { computed, defineComponent, PropType, Ref, watch } from '@vue/composition-api'
import PlanSelectForFreePane from '@/components/SignupPage/PlanSelectForFreePane.vue'
import PlanSelectForPaidPane from '@/components/SignupPage/PlanSelectForPaidPane.vue'
import type { PaidPlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import useDialog from '@/components/SignupPage/hook/useDialog'

/**
 * 新規会員登録: プラン選択ページ
 */
export default defineComponent({
  name: 'PlanSelectPage',
  components: {
    PlanSelectForFreePane,
    PlanSelectForPaidPane,
  },
  props: {
    /**
     * 会員登録タイプ
     */
    continueMemberType: {
      type: String as PropType<MemberType>,
      default: '',
    },
  },
  setup(props) {
    /** ページアンロード時、離脱警告ダイアログを表示 */
    useDialog()

    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { memberType, flowPathMemberType, selectedPlanGroupId, fetchContractPlans, coupons } =
      signupPageStore

    if (props.continueMemberType) {
      // 会員登録タイプがpropsで渡ってくる場合は、propsの値を優先させる
      memberType.value = props.continueMemberType
      flowPathMemberType.value = props.continueMemberType
    }

    const fetch = async () => {
      /** SFgo組織の契約プラン情報を取得する（ログイン状態であることが前提） */
      await fetchContractPlans()
    }
    fetch()

    /**
     * 会員登録画面流入時の会員登録タイプがfreeの場合、戻るボタン有効化
     */
    const enabledLeaveButton = computed(() => flowPathMemberType.value === Const.MEMBER_TYPE.FREE)

    /**
     * 選択プラン変更
     * @param plan {PaidPlanGroupType}
     */
    const changePlan = (plan: PaidPlanGroupType) => {
      if (memberType.value === Const.MEMBER_TYPE.FREE) {
        selectedPlanGroupId.value = selectedPlanGroupId.value === plan ? '' : plan
      } else {
        selectedPlanGroupId.value = plan
      }
    }

    /**
     * クーポンプラン選択中に別のクーポンコードを入力し、新たに入力したクーポンコードでクーポンが見つからなかった場合、年払いプランを選択させる
     */
    watch(
      () => coupons.value,
      (couponData) => {
        if (
          (selectedPlanGroupId.value === 'limitedTimePaidPlan' ||
            selectedPlanGroupId.value === 'limitedTimePaidPlanForAnnual') &&
          couponData.length === 0
        ) {
          selectedPlanGroupId.value = 'annualPlan'
        }
      },
    )

    return {
      Const,
      memberType,
      selectedPlanGroupId: selectedPlanGroupId as Ref<PaidPlanGroupType>,
      enabledLeaveButton,
      changePlan,
    }
  },
  methods: {
    /**
     * 登録完了画面へ遷移（無料プラン登録が対象）
     */
    moveToSignupComplete() {
      this.$router.replace({ name: 'SignupCompletePage' })
    },
    /**
     * プラン選択画面 有料向け/無料向けの切り替え
     */
    switchPlanSelectContent(memberType: MemberType) {
      this.memberType = memberType
    },
    /**
     * 確認画面へ遷移（有料プラン登録が対象）
     */
    moveToSignupPaymentConfirm() {
      this.$router.replace({ name: 'SignupPaymentConfirmPage' })
    },
  },
})
