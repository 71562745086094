









































import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: MISSION リンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationMissionIconParts',
})
