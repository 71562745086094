

















import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'RaceListCloseTimetableButtonParts',
  setup(_, { emit }) {
    const forPc = DeviceInfo.isForPC()
    const handleCloseClicked = () => {
      emit('onClickClose')
    }
    return {
      forPc,
      handleCloseClicked,
    }
  },
})
