























import { computed, defineComponent } from '@vue/composition-api'
import CommonOverlaySection from '@/components/common/overlay/CommonOverlaySection.vue'
import OverlayTitleParts from '@/components/common/overlay/parts/OverlayTitleParts.vue'
import OverlayMessageParts from '@/components/common/overlay/parts/OverlayMessageParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import I18n from '@/locales/I18n'

/**
 * マイページ 決済未完了状態確認モーダル
 */
export default defineComponent({
  name: 'PaymentPendingAlertModalSection',
  components: {
    CommonOverlaySection,
    OverlayTitleParts,
    OverlayMessageParts,
    AtomIconLinkButton,
    AtomInputButton,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
    const { getDisplayDate } = useDisplayDependingOnLang()

    /** 遷移先URL */
    const paymentUrl = computed(() => `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/payment`)

    /** 契約中プランの開始日 */
    const planStartDate = getDisplayDate(effectiveOrgContract?.startDate)

    /** モーダルに表示するメッセージ */
    const alertMessage = computed(() =>
      I18n.t('MypagePage.PaymentPendingAlertModal.message', {
        date: planStartDate,
        plan: effectiveContractPlan?.name[I18n.locale],
      }).toString(),
    )

    return {
      paymentUrl,
      alertMessage,
    }
  },
  methods: {
    /**
     * closeModal
     * 親コンポーネントへ閉じる処理
     */
    emitClose(): void {
      this.$emit('close')
    },
  },
})
