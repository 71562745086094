





















import { defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * 共通 メッセージモーダル テンプレート
 */
export default defineComponent({
  name: 'MessageModalSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomInputButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: I18n.tc('common.ok'),
    },
  },
  methods: {
    emitClose(): void {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.$emit('close')
    },
  },
})
