


























































import { defineComponent, watch, Ref, ref } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import OnboardingCircuitModeSection from '@/components/HomePage/HomePane/OnboardingCircuitModeSection.vue'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

/**
 * HOME サーキットモード切り替えセクション
 */
export default defineComponent({
  name: 'CircuitModeSection',
  components: {
    OnboardingCircuitModeSection,
  },
  props: {
    /**
     * アプリインストール後、オンボーディング画面を表示したかどうか
     */
    hasDisplayedOnboarding: {
      type: Boolean,
    },
  },
  setup(props) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    /**
     * サーキットモード説明テキストとリンク
     */
    const circuitModeExplanationText: Ref<string> = ref('')

    const setCircuitModeExplanationText = () => {
      const description = props.hasDisplayedOnboarding
        ? 'HomePage.countdown.circuitMode.description'
        : 'HomePage.countdown.circuitMode.onboarding.shortDescription'
      circuitModeExplanationText.value = I18n.t(description, {
        url:
          I18n.locale === 'ja'
            ? Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.JA
            : Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.EN,
      }).toString()
    }
    setCircuitModeExplanationText()

    watch(
      () => props.hasDisplayedOnboarding,
      () => {
        setCircuitModeExplanationText()
      },
    )

    /**
     * サーキットモード切り替え
     */
    const toggleCircuitMode = () => {
      appConfigStore.currentCircuitMode.value = !appConfigStore.currentCircuitMode.value
    }

    return {
      isCircuitMode: appConfigStore.currentCircuitMode,
      toggleCircuitMode,
      circuitModeExplanationText,
    }
  },
})
