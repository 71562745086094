




































import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { defineComponent, nextTick, watch } from '@vue/composition-api'
import RadioListItemParts from '@/components/RaceVideoPage/common/parts/RadioListItemParts.vue'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'

/**
 * レース動画再生画面 無線選択セクションのコンポーネント
 */
export default defineComponent({
  name: 'RadioSelectSection',
  components: {
    RadioListItemParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選択選手無線データ Live再生フラグ
     */
    livePlayerRadioEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 無線リスト 表示/非表示フラグ
     * recycle-scrollerの高さを調整するために使用する
     */
    radioVoiceEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { playRadioDataAudio, getCurrentSelectedPlayerRadioDataSet } = useRadio()

    watch(
      () => props.radioVoiceEnabled,
      () => {
        let minHeight = 0
        nextTick(() => {
          // 無線カードborder-bottomが消えないようにするため1pxだけ高さを調整
          const element = document.querySelector('.vue-recycle-scroller__item-wrapper')
          if (element) {
            minHeight = element.clientHeight + 2
          }
          element?.setAttribute('style', `min-height:${String(minHeight)}px;`)
        })
      },
    )

    return {
      playRadioDataAudio,
      getCurrentSelectedPlayerRadioDataSet,
    }
  },
  methods: {
    /**
     * 無線 ライブ再生トグルボタン
     * @event toggleRadioLive
     */
    toggleRadioLive(): void {
      this.$emit('radioLiveToggle')
    },
    /**
     * 無線音声 ON/OFFボタンが押下されたことを通知する
     * @event toggleRadioVoice
     * @type boolean
     */
    toggleRadioVoice(): void {
      this.$emit('radioVoiceToggle')
    },
    /**
     * 選択選手無線 再生
     * @event playSelectPlayerRadio
     */
    async playSelectPlayerRadio(singleRadio: RadioDataDocument, scene: boolean) {
      await this.playRadioDataAudio(singleRadio, scene)
    },
  },
})
