var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "mypage-ticket-close-icon-parts",
      attrs: {
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { opacity: "0.5" } }, [
        _c("path", {
          attrs: {
            d: "M16.5858 6H19.4143L13.4142 12.0001L19.4141 18L16.5857 18L12 13.4143L7.41427 18L4.58585 18L10.5858 12.0001L4.58569 6H7.41412L12 10.5859L16.5858 6Z",
            fill: "currentColor",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }