var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "section",
      {
        staticClass: "race-select-section",
        class: "race-select-section--" + _vm.screenOrientationType,
      },
      [
        _c("div", {
          staticClass: "race-select-section__mask",
          on: { click: _vm.toggleRaceSelect },
        }),
        _c(
          "div",
          { staticClass: "race-select-section__content" },
          [
            _c("h3", { staticClass: "race-select-section__title" }, [
              _vm._v(_vm._s(_vm.$t("RaceVideoPage.RaceSelect.title"))),
            ]),
            _c("race-link-list-section", {
              on: { raceSelectToggle: _vm.toggleRaceSelect },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }