import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoAppStoreDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoAppStoreDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * アプリ内課金 契約情報を操作するための処理を提供する。
 */
export default function useContractInfoAppStore() {
  // Collection modules
  const contractInfoAppStoreCollectionModule = CollectionModule.createStore(
    ContractInfoAppStoreDocument,
  )

  /**
   * アプリ内課金 AppStore用: 契約情報を更新する。
   * @return APIレスポンス
   */
  const updateContractInfoAppStore = async (requestData: ContractInfoAppStoreDocument) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${requestData.contractInfoId}/in_app_purchase/appstore`,
    }
    return contractInfoAppStoreCollectionModule.save(requestData, options)
  }

  /**
   * 取得した契約情報をクリアする
   */
  const clearContractInfoAppStore = () => {
    contractInfoAppStoreCollectionModule.clearData()
  }

  return {
    updateContractInfoAppStore,
    clearContractInfoAppStore,
  }
}
