























import { computed, defineComponent } from '@vue/composition-api'
import Const from '@/util/Const'
import AtomInputSelectObject from '@/components/atoms/input/AtomInputSelectObject.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

interface prefecturesType {
  name: Multilingual
  id: string
}

export type DataType = {
  prefecturesData: Array<prefecturesType>
}

/**
 * フィールドセット お住まいの都道府県入力 バーツ
 * todo: 選択肢取得・多言語対応？
 */
export default defineComponent({
  name: 'FieldsetPrefecturesParts',
  components: {
    AtomInputSelectObject,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data(): DataType {
    return {
      /**
       * 都道府県リスト
       */
      prefecturesData: Const.USER_PREFECTURES_LIST,
    }
  },
  setup(props, context) {
    /**
     * prefectures
     * @param {string} val 都道府県
     */
    const prefectures = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    return {
      prefectures,
    }
  },
  methods: {
    /**
     * エンターキーが押されたことを通知する
     */
    emitEnter() {
      /**
       * エンターキーが押されたことを通知する
       * @event enter
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
