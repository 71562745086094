

























import { computed, defineComponent, Ref } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import CommonOverlaySection from '@/components/common/overlay/CommonOverlaySection.vue'
import OverlayTitleParts from '@/components/common/overlay/parts/OverlayTitleParts.vue'
import OverlayMessageParts from '@/components/common/overlay/parts/OverlayMessageParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * 共通 アラート用オーバーレイ テンプレート
 */
export default defineComponent({
  name: 'AlertOverlaySection',
  components: {
    CommonOverlaySection,
    OverlayTitleParts,
    OverlayMessageParts,
    AtomIconLinkButton,
    AtomInputButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    imgIconFileName: {
      type: String,
      default: 'icon_external_link_outline__green.svg',
    },
    /**
     * 閉じるボタンテキスト
     */
    closeButtonLinkText: {
      type: String,
      default: '',
    },
    /**
     * 閉じるボタンカラー指定
     */
    closeButtonColorType: {
      type: String,
      default: 'tertiary',
    },
  },
  setup(props) {
    const closeButtonText = computed(() => props.closeButtonLinkText || I18n.tc('common.close'))

    return {
      closeButtonText: closeButtonText as Ref<string>,
    }
  },
  methods: {
    /**
     * click
     * 親コンポーネントへ閉じる処理
     */
    emitOpen(): void {
      this.$emit('click')
    },
    /**
     * closeOverlay
     * 親コンポーネントへ閉じる処理
     */
    emitClose(): void {
      this.$emit('close')
    },
  },
})
