















import { defineComponent, PropType } from '@vue/composition-api'
import DigitalTicketDataTicketDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'

export default defineComponent({
  name: 'MypageTicketTodayTableParts',
  props: {
    /**
     * デジタルチケット チケット情報
     */
    ticketList: {
      type: Array as PropType<Array<DigitalTicketDataTicketDocument>>,
      default: () => [],
    },
    /**
     * ログインユーザーの組織情報
     */
    ownOrganization: {
      type: Object as PropType<OrganizationDocument>,
      default: () => ({}),
    },
  },
  setup() {
    const { getTicketTotalCountByTicket } = usePurchasedTicketData()

    return {
      getTicketTotalCountByTicket,
    }
  },
})
