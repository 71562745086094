

































import { defineComponent, toRefs } from '@vue/composition-api'
import useTelemetrySectionUtil from '@/components/RaceVideoPage/RaceVideoDataViewPane/hook/useTelemetrySectionUtil'

export type DataType = {
  viewValue: number
  comparisonValue: number
}

/**
 * レース動画再生画面 テレメトリー タイヤ温度 リア左 値パーツのコンポーネント
 */
export default defineComponent({
  name: 'TelemetryTireTemperatureRlParts',
  components: {},
  props: {
    /**
     * バリュー値
     */
    value: {
      type: Number,
      required: true,
    },
    /**
     * 表示fpsと受信データ間隔の差分ms
     */
    diffMs: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { value } = toRefs(props)
    const { getHeatFlag, setupIntermediateValue } = useTelemetrySectionUtil()
    const heatFlag = getHeatFlag(value)
    const viewValue = setupIntermediateValue(value, props.diffMs)

    return {
      heatFlag,
      viewValue,
    }
  },
  methods: {},
})
