




























import { defineComponent } from '@vue/composition-api'
import dayjs from 'dayjs'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

export default defineComponent({
  name: 'TimetableHeaderParts',
  props: {
    title: {
      type: String,
      require: true,
    },
    date: {
      type: String,
      require: true,
    },
  },
  setup() {
    const { getDisplayDate } = useDisplayDependingOnLang()
    /**
     * タイムテーブル見出しに表示する日付
     */
    const getTimeTableDate = (date: string) => {
      if (!date) {
        return ''
      }
      return getDisplayDate(dayjs(date).valueOf(), 'YYYY.MM.DD(ddd)', 'MMM. DD, YYYY(ddd)')
    }
    return {
      getTimeTableDate,
    }
  },
})
