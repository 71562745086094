/**
 * 現在地取得処理のHook
 * @return {GeolocationPosition} GeolocationPositionを返す
 */
import { booleanPointInPolygon, point, polygon } from '@turf/turf'
import AvailableAreaDocument from '@/store/stores/collectionModule/documents/availableArea/AvailableAreaDocument'

export default function useGeolocation() {
  /**
   * ユーザーの現在地を取得する
   */
  const getCurrentPosition = () =>
    new Promise<GeolocationPosition>(
      (
        resolve: (position: GeolocationPosition) => void,
        reject: (positionError: GeolocationPositionError) => void,
      ) => {
        // 成功コールバック
        const success: PositionCallback = (position: GeolocationPosition): void => {
          resolve(position)
        }
        // エラーコールバック
        const error: PositionErrorCallback = (positionError: GeolocationPositionError): void => {
          reject(positionError)
        }

        // オプション定義
        const options: PositionOptions = {
          // より精度の高い位置情報を取得するか
          enableHighAccuracy: true,
          // 位置情報の有効期限（ミリ秒）
          // 0の場合は常に新しい情報に更新しようとする
          maximumAge: 0,
          // 取得タイムアウトまでの時間（ミリ秒）
          // タイムアウトを30秒（Max値）に設定
          timeout: 30000,
        }

        // 現在位置取得
        navigator.geolocation.getCurrentPosition(success, error, options)
      },
    )

  /**
   * ユーザーが対象エリアにいるかを判定する
   * additionalData.outerCoordinatesにデータがある場合は、エリア外の場合にtrueを返す
   * 使用するライブラリの詳細は以下を参照
   * https://turfjs.org/docs/api/booleanPointInPolygon
   */
  const checkIsUserInArea = (
    position: GeolocationPosition,
    availableArea: AvailableAreaDocument | undefined,
  ) => {
    // ユーザーの現在地の緯度と経度を使用して、ポイントFeatureを作成
    const pt = point([position.coords.latitude, position.coords.longitude])

    if (!availableArea?.additionalData?.outerCoordinates) {
      /**
       * ユーザーがエリア内にいるかを判定する
       */
      // 利用可能範囲の緯度、経度情報を配列に格納
      const availableRange = availableArea?.additionalData?.coordinates?.map((coordinate) => [
        coordinate.lat,
        coordinate.lng,
      ])

      const poly = availableRange ? polygon([availableRange]) : null

      return !!poly && booleanPointInPolygon(pt, poly)
    }

    /**
     * ユーザーがエリア外にいるかを判定する
     */
    // 利用可能範囲の緯度、経度情報を配列に格納
    const outerAvailableRanges = availableArea.additionalData.outerCoordinates.map((outer) =>
      outer.coordinates.map((coordinate) => [coordinate.lat, coordinate.lng]),
    )

    // 全指定エリアの外にいる場合はtrueを返す
    return outerAvailableRanges.every((outerRange) => {
      const poly = polygon([outerRange])
      return !booleanPointInPolygon(pt, poly)
    })
  }

  return {
    getCurrentPosition,
    checkIsUserInArea,
  }
}
