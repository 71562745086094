import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * SNS/SF公式サイト記事データのメディア種別の型
 */
export type FeedMediaType = 'image' | 'video' | null

/**
 * SNS/SF公式サイト記事データのメディア情報を表現するクラス。
 * https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3395453098/SNS+SF
 */
export default class FeedMediaDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<FeedMediaDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * メディア種別.
   */
  type: FeedMediaType = null

  /**
   * 幅
   */
  width = 0

  /**
   * 高さ
   */
  height = 0

  /**
   * URL
   */
  url = ''

  /**
   * プレビュー画像
   */
  previewImageUrl = ''

  /**
   * 動画長
   */
  duration = 0

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `FeedMediaDataDocument[type: ${this.type}, previewImageUrl: ${this.previewImageUrl}}, url: ${this.url}]`
  }
}
