var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sfgo-app",
      class: [
        { "sfgo-app-background-color-for-browser for-browser": !_vm.isCordova },
      ],
    },
    [
      _c("router-view"),
      _c("MessageDialog", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.messageDialogStore.isVisible,
            expression: "messageDialogStore.isVisible",
          },
        ],
        staticClass: "message-dialog",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }