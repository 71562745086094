







import { defineComponent } from '@vue/composition-api'
import MypageLinkListParts from '@/components/MypageTopPage/MypageTopPane/parts/MypageLinkListParts.vue'
/**
 * マイページ: トップ リンク セクション
 */
export default defineComponent({
  name: 'MypageLinksSection',
  components: {
    MypageLinkListParts,
  },
  props: {
    /**
     * セクションタイトル
     */
    title: {
      type: String,
    },
    /**
     * リンク情報リスト
     */
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleOpenModalClicked(modalId: string) {
      this.$emit('openModalClicked', modalId)
    },
  },
})
