var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "mypage-select-plan-button-parts",
      on: { click: _vm.handleClicked },
    },
    [
      _c("span", { staticClass: "mypage-select-plan-button-parts__header" }, [
        _c("span", { staticClass: "mypage-select-plan-button-parts__title" }, [
          _c(
            "span",
            { staticClass: "mypage-select-plan-button-parts__title-name" },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm.bargainPriced
            ? _c(
                "span",
                {
                  staticClass:
                    "mypage-select-plan-button-parts__title-attention",
                },
                [_vm._v(_vm._s(_vm.bargainPriced))]
              )
            : _vm._e(),
          _vm.note
            ? _c(
                "span",
                { staticClass: "mypage-select-plan-button-parts__title-memo" },
                [_vm._v(_vm._s(_vm.note))]
              )
            : _vm._e(),
        ]),
        _c("span", { staticClass: "mypage-select-plan-button-parts__price" }, [
          _c(
            "span",
            { staticClass: "mypage-select-plan-button-parts__price-data" },
            [_vm._v(_vm._s(_vm.price))]
          ),
          _c(
            "span",
            { staticClass: "mypage-select-plan-button-parts__price-unit" },
            [_vm._v("/" + _vm._s(_vm.unit))]
          ),
        ]),
      ]),
      _vm.period
        ? _c(
            "span",
            { staticClass: "mypage-select-plan-button-parts__period" },
            [
              _vm._v(
                _vm._s(
                  _vm.$tc("MypagePage.MypageSwitchToPaidPlanPage.plans.period")
                ) + _vm._s(_vm.period)
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }