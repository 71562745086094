import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'

type OutputFormatType = 'mp4'

/**
 * 合成する音声ファイルの型
 */
type SceneMoviesAudioType = {
  filePath: string
  offset: number
}

export type SceneMoviesSceneType = {
  gameEventId: string
  angleMovieId?: string
  clipStart?: number
  clipEnd?: number
  sceneMoviePath?: string
  fileTitle?: string
  audio?: Array<SceneMoviesAudioType>
}

export type SceneMoviesStatusType = 'InProgress' | 'Complete' | 'Error'

/**
 * ウォーターマーク表示位置の型
 */
type WatermarkPositionType = {
  vertical: 'TOP' | 'BOTTOM'
  horizontal: 'LEFT' | 'RIGHT'
}

/**
 * シーン動画集を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/894566502/46+API
 */
export default class SceneMoviesDocument extends DocumentWrapper {
  constructor(initProps?: Partial<SceneMoviesDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/scene_movies'

  idAttr = 'sceneMoviesId'

  /**
   * 動画集ID
   */
  sceneMoviesId: string | null = null

  /**
   * 出力するファイルのフォーマット
   */
  outputFormat: OutputFormatType | null = null

  /**
   * ハイライトの情報
   */
  scenes: Array<SceneMoviesSceneType> | null = null

  /**
   * 動画集の処理状況のステータス
   */
  status: SceneMoviesStatusType | null = null

  /**
   * イベントタグを合成する際に、使用する言語を設定する
   * SFgoでは利用しないが、必須になっているため設定している
   */
  lang: UserLang | null = null

  /**
   * ウォーターマーク表示位置
   */
  watermarkPosition: WatermarkPositionType | null = null
}
