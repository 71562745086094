











import { defineComponent, inject, onBeforeMount, onUnmounted, watch } from '@vue/composition-api'
import VueRouter from 'vue-router'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import usePusherContractInfo from '@/components/hook/usePusherContractInfo'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import Logger from '@/util/logger/Logger'
import StoreUtil from '@/store/StoreUtil'

/**
 * マイページ 共通Wrapper
 */
export default defineComponent({
  name: 'MyPageWrapper',
  components: {
    HeaderPane,
  },
  props: {
    /**
     * ヘッダーナビ表示指定
     */
    hasHeaderNav: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    // store
    const notificationStore = StoreUtil.useStore('NotificationStore')

    // hook
    const { fetchNotificationData } = notificationStore
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
    const { redirectPath } = usePusherContractInfo()

    const router = inject('router') as VueRouter

    onBeforeMount(async () => {
      // お知らせのリアルタイムメッセージを受信
      await initRTM()
      subscribeRTM('information', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `MyPageWrapper#subscribeRTM: Receive notification event. event: ${JSON.stringify(data)}`,
        )
        fetchNotificationData()
      })
    })

    onUnmounted(() => {
      // お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
    })

    const pathListNoRedirectToUserEdit = [
      '/mypage/one-time-pass-confirm',
      '/mypage/one-time-pass-complete',
    ]

    /**
     * 以下の条件に当てはまった場合はリダイレクトする
     * 1. 契約情報取得に失敗した場合は、強制ログアウトする（ユーザー操作で再度ログインしてもらう）
     * 2. 有料機能が使えるようになったタイミングで郵便番号が入っていない場合、マイページの会員情報編集画面に遷移する
     */
    watch(
      () => redirectPath.value,
      () => {
        if (redirectPath.value) {
          if (redirectPath.value === '/mypage/user-edit') {
            if (pathListNoRedirectToUserEdit.some((path) => path === router.currentRoute.path)) {
              // 有料にアップグレード手続きをしている場合は完了画面を表示させるため、強制的にリダイレクトしない
              return
            }
          }
          router.replace({ path: redirectPath.value })
        }
      },
    )
  },
})
