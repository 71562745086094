






import { defineComponent } from '@vue/composition-api'
import ConfirmationSentPane from '@/components/SignupPage/ConfirmationSentPane.vue'

/**
 * 新規会員登録: メール認証送信完了ページ
 */
export default defineComponent({
  name: 'ConfirmationSentPage',
  components: {
    ConfirmationSentPane,
  },
  methods: {},
})
