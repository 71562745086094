var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-ticket-today-entry-card-section",
      on: {
        click: function ($event) {
          return _vm.handleToggleClicked(_vm.ticketType.ticketTypeId)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "mypage-ticket-today-entry-card-section__toggle",
          class: {
            "mypage-ticket-today-entry-card-section__toggle--active":
              _vm.activeCardId === _vm.ticketType.ticketTypeId,
          },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "mypage-ticket-today-entry-card-section__toggle-name",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "mypage-ticket-today-entry-card-section__toggle-label",
                },
                [_vm._v(_vm._s(_vm.ticketType.ticketTypeName))]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "mypage-ticket-today-entry-card-section__toggle-icon",
                },
                [_c("mypage-ticket-toggle-icon-parts")],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.activeCardId === _vm.ticketType.ticketTypeId
        ? _c(
            "div",
            { staticClass: "mypage-ticket-today-entry-card-section__body" },
            [
              _c(
                "div",
                {
                  staticClass: "mypage-ticket-today-entry-card-section__table",
                },
                [
                  _c("mypage-ticket-today-table-parts", {
                    attrs: {
                      ticketList: _vm.purchasedTickets,
                      ownOrganization: _vm.ownOrganization,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mypage-ticket-today-entry-card-section__note" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mypage-ticket-today-entry-card-section__note-message",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc("MypagePage.MypageTicketTodayPage.note")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
              !_vm.acceptedDateTime
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mypage-ticket-today-entry-card-section__footer",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return function () {}.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("atom-button", {
                        attrs: {
                          linkText: _vm.$tc(
                            "MypagePage.MypageTicketTodayPage.operatedByStaff"
                          ),
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.handleCheckTicketClicked(_vm.ticketType)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "mypage-ticket-today-entry-card-section__footer",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mypage-ticket-today-entry-card-section__checkin",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mypage-ticket-today-entry-card-section__checkin-time",
                            },
                            [_vm._v(_vm._s(_vm.acceptedDateTime))]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mypage-ticket-today-entry-card-section__checkin-message",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "MypagePage.MypageTicketTodayPage.accepted"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
            ]
          )
        : _vm._e(),
      !!_vm.acceptedDateTime
        ? _c(
            "div",
            { staticClass: "mypage-ticket-today-entry-card-section__used" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/TicketPage/stamp_used.svg"),
                  alt: _vm.$tc("MypagePage.MypageTicketTodayPage.ticketUsed"),
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }