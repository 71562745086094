var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-notification-item-contents-parts",
    {
      staticClass: "highlight-notification-item-contents-parts",
      attrs: { notification: _vm.notification },
      on: {
        handlerClickLink: function ($event) {
          return _vm.$emit("handlerClickLink", _vm.notification)
        },
      },
    },
    [
      _vm.notification.extra
        ? _c("highlight-card-parts", {
            staticClass:
              "highlight-notification-item-contents-parts__highlight",
            attrs: { highlightData: _vm.notification.extra },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }