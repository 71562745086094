





















































































































import { defineComponent } from '@vue/composition-api'

/**
 * HOME サーキットモード用 オンボーディングセクション
 */
export default defineComponent({
  name: 'OnboardingCircuitModeSection',
  mounted() {
    /**
     * マスク(下)の高さを設定する
     */
    this.setBottomMaskHeight()
  },
  methods: {
    /**
     * クローズイベントをemitする
     */
    emitClose() {
      this.$emit('close')
    },
    /**
     * マスク(下)の高さを設定する
     */
    setBottomMaskHeight() {
      const homePane = document.querySelector('.home-pane') as HTMLElement
      const bottomMask = document.querySelector(
        '.onboarding-circuit-mode-section__mask--bottom',
      ) as HTMLElement
      const snsList = document.querySelector('.home-pane .home-pane__sns-list') as HTMLElement

      const { scrollTop } = homePane
      const { height, top } = snsList.getBoundingClientRect()
      const maskTop = bottomMask.getBoundingClientRect().top + scrollTop

      // マスクの高さを設定
      bottomMask.style.height = `${height + top + scrollTop - maskTop}px`
    },
  },
})
