var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.state.loadedImgCnt > 0 && _vm.state.hasAllLoaded,
          expression: "state.loadedImgCnt > 0 && state.hasAllLoaded",
        },
      ],
      staticClass: "award-ranking-sponsor-parts",
    },
    [
      _c("div", { staticClass: "award-ranking-sponsor-parts__block" }, [
        _c("p", { staticClass: "award-ranking-sponsor-parts__label" }, [
          _vm._v(_vm._s(_vm.$tc("LinkPage.AwardPage.sponsoredBy"))),
        ]),
        _c(
          "ul",
          { staticClass: "award-ranking-sponsor-parts__list" },
          _vm._l(10, function (i) {
            return _c(
              "li",
              { key: i, class: "award-ranking-sponsor-parts__item--" + i },
              [
                _c(
                  "figure",
                  { staticClass: "award-ranking-sponsor-parts__item__logo" },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgSrc(i), alt: "" },
                      on: {
                        load: function ($event) {
                          return _vm.srcLoad()
                        },
                        error: function ($event) {
                          return _vm.srcError($event, i)
                        },
                      },
                    }),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }