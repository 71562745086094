var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-list-section" }, [
    _c(
      "ul",
      { staticClass: "calendar-list-section__list" },
      _vm._l(_vm.calendarData, function (item, index) {
        return _c(
          "li",
          {
            key: "calendar-list" + index,
            staticClass: "calendar-list-section__item",
          },
          [_c("calendar-item-section", { attrs: { calendarItem: item } })],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }