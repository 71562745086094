var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "race-video-data-view-pane" },
    [
      _c("race-player-info-section", {
        attrs: {
          radioVoiceEnabled: _vm.radioVoiceEnabled,
          raceSelectEnabled: _vm.raceSelectEnabled,
          screenOrientationType: _vm.screenOrientationType,
          favoriteRacePlayerSelected: _vm.favoriteRacePlayerSelected,
        },
        on: {
          radioVoiceToggle: _vm.toggleRadioVoice,
          raceSelectToggle: _vm.toggleRaceSelect,
          playerChange: _vm.showPlayerSelectModal,
        },
      }),
      _c(
        "div",
        { staticClass: "race-video-data-view-pane__telemetry" },
        [
          _c("race-telemetry-section", {
            attrs: {
              lockOrientation: _vm.lockOrientation,
              screenOrientationType: _vm.screenOrientationType,
              "telemetry-data": _vm.getCurrentTelemetryData,
            },
          }),
        ],
        1
      ),
      _c("player-select-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.racePlayerSelectModal,
            expression: "racePlayerSelectModal",
          },
        ],
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          favoriteRacePlayerSelected: _vm.favoriteRacePlayerSelected,
        },
        on: { close: _vm.changePlayer },
      }),
      _c("race-select-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.raceSelectEnabled,
            expression: "raceSelectEnabled",
          },
        ],
        attrs: { screenOrientationType: _vm.screenOrientationType },
        on: { raceSelectToggle: _vm.toggleRaceSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }