
























import { defineComponent, PropType } from '@vue/composition-api'
import { PlanContractLogDataType } from '@/components/MypageContractPage/hook/useMypageContractLog'

/**
 * マイページ: プラン契約履歴一覧セクション
 */
export default defineComponent({
  name: 'MypageContractLogTableSection',
  props: {
    planContractLogData: {
      type: Array as PropType<Array<PlanContractLogDataType>>,
      default: () => [],
    },
  },
})
