
























import { defineComponent, inject, onBeforeMount, onUnmounted, ref, Ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { PluginApi } from 'vue-loading-overlay'
import CalendarFilterSection from '@/components/LinkPage/CalendarPane/CalendarFilterSection.vue'
import CalendarListSection from '@/components/LinkPage/CalendarPane/CalendarListSection.vue'
import CalendarYearMonthSelectorSection from '@/components/LinkPage/CalendarPane/CalendarYearMonthSelectorSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import CalendarDataDocument from '@/store/stores/collectionModule/documents/calendar/CalendarDataDocument'
import I18n from '@/locales/I18n'
import useCalendarSelectList, {
  CalendarDateSelectType,
} from '@/components/LinkPage/hooks/useCalendarSelectList'
import { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'
import { OpenFilterIdType } from '@/store/stores/pageStore/LinkPage/CalendarPageStore'

export default defineComponent({
  name: 'CalendarPane',
  components: {
    CalendarYearMonthSelectorSection,
    CalendarListSection,
    CalendarFilterSection,
  },
  setup() {
    const calendarPageStore = StoreUtil.useStore('CalendarPageStore')
    const { generateYearsAndMonthsList, generatePlaceList, generateCategoryList } =
      useCalendarSelectList()

    const loading = inject('loading') as PluginApi

    /** PCブラウザかどうか */
    const isPc = DeviceInfo.isForPC()
    /** 年/年月のプルダウン（年） */
    const calendarYearList = ref([]) as Ref<Array<CalendarDateSelectType>>
    /** 年/年月のプルダウン（年.月） */
    const calendarYearAndMonthList = ref([]) as Ref<Array<CalendarDateSelectType>>
    /**  モータースポーツカレンダー最新年 */
    const calendarLatestYear = ref(new Date().getFullYear())
    /**  場所のプルダウン */
    const placeList = ref([]) as Ref<Array<DropDownDataType>>
    /**  カテゴリーのプルダウン */
    const categoryList = ref([]) as Ref<Array<DropDownDataType>>

    /**
     * モータースポーツカレンダー情報を取得
     */
    const fetchCalendarData = async (selectedYear: number) => {
      const loader = loading.show()
      try {
        // 対象年のカレンダー一覧を取得する
        await calendarPageStore.fetchCalendarPageData(selectedYear)
        loader.hide()
      } catch (e) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('LinkPage.MotorsportCalendarPage.errors.fetchCalendarPageDataError.title'),
          message: I18n.tc(
            'LinkPage.MotorsportCalendarPage.errors.fetchCalendarPageDataError.message',
          ),
        })
      }
    }

    /**
     * 選択中の年（年月）を更新する
     * 年が切り替わった場合、対象年のカレンダーデータを取得する
     */
    const changeYearsAndMonths = async (yearsAndMonths: CalendarDateSelectType) => {
      const savedYearsAndMonths = calendarPageStore.selectedYearOrMonth.value
      calendarPageStore.selectedYearOrMonth.value = yearsAndMonths

      if (dayjs(savedYearsAndMonths.value).year() !== dayjs(yearsAndMonths.value).year()) {
        // 年が切り替わった場合、対象年のカレンダー情報を取得する
        await fetchCalendarData(dayjs(calendarPageStore.selectedYearOrMonth.value.value).year())

        // 取得したカレンダー情報に応じた場所のプルダウンを生成
        placeList.value = generatePlaceList(calendarPageStore.calendarData.value)
        // 取得したカレンダー情報に応じたカテゴリーのプルダウンを生成
        categoryList.value = generateCategoryList(calendarPageStore.calendarData.value)
      }
    }

    onBeforeMount(async () => {
      // eslint-disable-next-line no-await-in-loop
      await fetchCalendarData(calendarLatestYear.value)
      if (calendarPageStore.calendarData.value.length < 1 && calendarLatestYear.value > 2024) {
        // 2025年以降でデータ取得できなかった場合、一つ前の年を最新年とし、一つ前の年のカレンダーを取得
        calendarLatestYear.value -= 1
        // eslint-disable-next-line no-await-in-loop
        await fetchCalendarData(calendarLatestYear.value)
      }

      // 年/年月のプルダウンを生成
      const listData = generateYearsAndMonthsList(calendarLatestYear.value)
      calendarYearList.value = listData.calendarYearList
      calendarYearAndMonthList.value = listData.calendarYearAndMonthList

      // 場所のプルダウンを生成
      placeList.value = generatePlaceList(calendarPageStore.calendarData.value)
      // カテゴリーのプルダウンを生成
      categoryList.value = generateCategoryList(calendarPageStore.calendarData.value)

      // 最新年を選択中にする
      calendarPageStore.selectedYearOrMonth.value = calendarYearList.value.slice(-1)[0]
    })

    onUnmounted(() => {
      calendarPageStore.clearCalendarPageData()
    })

    return {
      // calendarPane
      isPc,
      calendarYearList,
      calendarYearAndMonthList,
      placeList,
      categoryList,
      // calendarPageStore
      selectedYearOrMonth: calendarPageStore.selectedYearOrMonth as Ref<CalendarDateSelectType>,
      filteredCalendarData: calendarPageStore.filteredCalendarData as Ref<CalendarDataDocument[]>,
      openFilterId: calendarPageStore.openFilterId as Ref<OpenFilterIdType>,
      changeYearsAndMonths,
    }
  },
})
