var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "member-badge-item-parts" }, [
    _c(
      "i",
      { staticClass: "member-badge-item-parts__badge" },
      [
        _vm.badge.badgeType === "year"
          ? _c("badge-year-parts", { attrs: { badge: _vm.badge } })
          : _c("badge-image-parts", { attrs: { badge: _vm.badge } }),
      ],
      1
    ),
    _vm.badge.badgeType === "supporter"
      ? _c("img", {
          staticClass: "member-badge-item-parts__logo",
          attrs: { src: require("@/assets/img/common/logo_dark.svg"), alt: "" },
        })
      : _vm._e(),
    _c("span", {
      staticClass: "member-badge-item-parts__label",
      domProps: { textContent: _vm._s(_vm.badge.label) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }