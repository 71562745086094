var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-map",
      attrs: {
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.9376 4.56055C12.9376 7.36364 9.00012 11.3105 9.00012 11.3105C9.00012 11.3105 5.06262 7.36364 5.06262 4.56055C5.06262 2.38593 6.8255 0.623047 9.00012 0.623047C11.1747 0.623047 12.9376 2.38593 12.9376 4.56055ZM9.0004 6.274C9.97676 6.274 10.7683 5.4825 10.7683 4.50614C10.7683 3.52978 9.97676 2.73828 9.0004 2.73828C8.02404 2.73828 7.23254 3.52978 7.23254 4.50614C7.23254 5.4825 8.02404 6.274 9.0004 6.274Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.37509 7.0064H2.4035V13.9317H15.5967V7.0064H14.6251V5.57458H15.9035C16.5248 5.57458 17.0285 6.07826 17.0285 6.69958V14.2386C17.0285 14.8599 16.5248 15.3636 15.9035 15.3636H2.09668C1.47536 15.3636 0.97168 14.8599 0.97168 14.2386V6.69958C0.97168 6.07826 1.47536 5.57458 2.09668 5.57458H3.37509V7.0064Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }