<template>
  <common-modal-section class="mypage-paid-plan-complete-modal-section" @close="handleOkClicked">
    <div class="mypage-paid-plan-complete-modal-section__header" slot="header">
      <p class="mypage-paid-plan-complete-modal-section__title" v-html="title" />
    </div>

    <div class="mypage-paid-plan-complete-modal-section__content" slot="default">
      <p
        class="mypage-paid-plan-complete-modal-section__message"
        v-html="$tc('MypagePage.PaidCompleteModal.message')"
      ></p>
      <!--「詳しくはこちら」を表示する場合に利用 -->
      <atom-icon-link-button
        v-if="false"
        class="mypage-paid-plan-complete-modal-section__link"
        :url="linkUrl"
        :linkText="$tc('MypagePage.PaidCompleteModal.linkLabel')"
        :colorType="'tertiary'"
        :imgPath="require('@/assets/img/icon/icon_external_link_gray.svg')"
      />
    </div>
    <div class="mypage-paid-plan-complete-modal-section__buttons" slot="buttons">
      <atom-button :linkText="'OK'" :colorType="'secondary'" @onClick="handleOkClicked" />
    </div>
  </common-modal-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

export default defineComponent({
  name: 'MypagePaidPlanCompleteModalSection',
  components: { AtomButton, CommonModalSection, AtomIconLinkButton },
  props: {
    /**
     * タイトル
     */
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const linkUrl =
      I18n.locale === 'ja' ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN
    const handleOkClicked = () => {
      emit('onClickOk')
    }
    return {
      linkUrl,
      handleOkClicked,
    }
  },
})
</script>

<style scoped lang="scss">
.mypage-paid-plan-complete-modal-section {
  &__header {
    padding: 36px 16px 0;
  }

  &__title {
    font-size: 17px;
    font-weight: bold;
    color: $gray75;
    letter-spacing: 0.04em;
  }

  &__content {
    margin-top: 16px;
  }

  &__message {
    font-size: 13px;
    font-weight: bold;
    color: $gray75;
    letter-spacing: 0.04em;
  }

  &__link {
    ::v-deep {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__buttons {
    width: 100%;
  }

  ::v-deep {
    .modal__buttons {
      margin-top: 0;
    }
  }
}
</style>
