




































import { defineComponent, PropType, ref } from '@vue/composition-api'
import MemberBadgeItemParts, {
  BadgeData,
} from '@/components/MypageTopPage/MembershipCardPane/parts/MemberBadgeItemParts.vue'

/**
 * マイページ: 会員証 バッヂリストパーツ
 */
export default defineComponent({
  name: 'MemberBadgeListParts',
  components: { MemberBadgeItemParts },
  props: {
    badgeList: {
      type: Array as PropType<BadgeData[]>,
      default: () => [],
    },
    enabledListCarousel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const visibleList = ref<boolean>(false)
    const enabledCarouselPrev = ref<boolean>(false)
    const enabledCarouselNext = ref<boolean>(false)

    const listItemWidths = ref<number[]>([])

    const setListItemWidths = () => {
      const memberBadgeList: HTMLElement | null = document.querySelector(
        '.member-badge-list-parts__list',
      )
      const listItems: NodeList = document.querySelectorAll('.member-badge-list-parts__item')
      let listWidth = 0

      if (listItems.length > 0) {
        listItems.forEach((item) => {
          const itemWidth = (item as HTMLElement).offsetWidth
          listItemWidths.value.push(itemWidth)

          listWidth += itemWidth
        })
        listWidth += listItems.length * 6 + 4
      }

      if (memberBadgeList !== null) {
        memberBadgeList.style.width = `${listWidth}px`
      }
    }

    return {
      visibleList,
      listItemWidths,
      setListItemWidths,
      enabledCarouselPrev,
      enabledCarouselNext,
    }
  },
  mounted() {
    if (this.enabledListCarousel) {
      // 読込時に表示判定
      setTimeout(() => {
        this.checkCarouselEnabled()
      }, 500)

      // リサイズ時に表示判定
      window.addEventListener('resize', this.checkCarouselEnabled)
    }
    setTimeout(() => {
      this.setListItemWidths()

      this.visibleList = true
    }, 500)
  },
  beforeDestroy() {
    if (this.enabledListCarousel) {
      window.removeEventListener('resize', this.checkCarouselEnabled)
    }
  },
  methods: {
    /**
     * ページャー有効化判定
     */
    checkCarouselEnabled() {
      if (this.$refs.MemberBadgeListPartsContent) {
        const LIST_PADDING = 6
        const { scrollLeft, clientWidth, scrollWidth } = this.$refs
          .MemberBadgeListPartsContent as HTMLElement

        this.enabledCarouselPrev = scrollLeft > LIST_PADDING
        this.enabledCarouselNext = scrollLeft < scrollWidth - clientWidth - LIST_PADDING
      }
    },
    /**
     * カルーセルクリックアクション
     * @param type
     */
    onclickCarousel(type: 'prev' | 'next'): void {
      const scroll = {
        value: 0,
        setValue: 0,
        returnFlag: false,
      }

      const badgeList = this.$refs.MemberBadgeListPartsContent as HTMLElement
      const { scrollLeft } = badgeList

      const typeDiff = type === 'next' ? -36 : 36

      this.listItemWidths.forEach((width, idx) => {
        scroll.value += width + 6

        if (scrollLeft <= scroll.value + typeDiff && !scroll.returnFlag) {
          scroll.setValue = scroll.value
          scroll.returnFlag = true

          if (type === 'prev') {
            scroll.setValue -= this.listItemWidths[idx] - 30
            scroll.setValue -= 36
          } else if (type === 'next') {
            scroll.setValue += this.listItemWidths[idx + 1] - 66
          }
        }
      })

      badgeList.scroll({
        left: scroll.setValue,
        behavior: 'smooth',
      })
    },
  },
})
