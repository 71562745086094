




















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import PasswordResetCompletePane from '@/components/PasswordResetPage/PasswordResetCompletePane.vue'

/**
 * パスワードリセット完了 ページ
 */
export default defineComponent({
  name: 'PasswordResetCompletePage',
  components: {
    SubHeaderSection,
    ActionButtonsSection,
    AtomLinkButton,
    PasswordResetCompletePane,
  },
  setup() {
    const loginUrl = `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/login`

    return {
      loginUrl,
    }
  },
})
