


































import { computed, defineComponent } from '@vue/composition-api'

/**
 * 共通下層用 サブヘッダーセクション
 */
export default defineComponent({
  name: 'SubHeaderSection',
  props: {
    /**
     * ページタイトル
     */
    pageTitle: {
      type: String,
      default: '',
    },
    /**
     * バックリンク
     */
    backLink: {
      type: String,
      default: '',
    },
    /**
     * アプリインストール済みの場合はアプリに戻るURLかどうか
     */
    canReturnToAppLink: {
      type: Boolean,
      default: false,
    },
    /**
     * 新しいタブやウインドウでリンク先を開くかどうか
     * falseの場合、現在のウィンドウで開く
     */
    isOpenAsBlank: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * 親側で遷移先の処理を行うかどうか
     */
    execTransitionEventParent: {
      type: Boolean,
      default: false,
    },
    /**
     * アプリ内課金購入処理実行中かどうか
     */
    isPurchasingInAppPurchase: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * リンクの開く先を指定する。
     * @return string リンクを別画面で開くかどうか
     */
    const target = computed(() => (props.isOpenAsBlank ? '_blank' : '_self'))

    const onClick = () => {
      context.emit('onClick')
    }

    return {
      target,
      onClick,
    }
  },
})
