var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-confirm-form-section" },
    [
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.name") },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _vm.userNameRubyEnabled
        ? _c("fieldset-confirm-parts", {
            attrs: { label: _vm.$tc("formParts.label.ruby") },
            model: {
              value: _vm.ruby,
              callback: function ($$v) {
                _vm.ruby = $$v
              },
              expression: "ruby",
            },
          })
        : _vm._e(),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.displayName") },
        model: {
          value: _vm.userDisplayName,
          callback: function ($$v) {
            _vm.userDisplayName = $$v
          },
          expression: "userDisplayName",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.birthDay"), isBirthDay: true },
        model: {
          value: _vm.birthDay,
          callback: function ($$v) {
            _vm.birthDay = $$v
          },
          expression: "birthDay",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.gender") },
        model: {
          value: _vm.gender[_vm.$i18n.locale],
          callback: function ($$v) {
            _vm.$set(_vm.gender, _vm.$i18n.locale, $$v)
          },
          expression: "gender[$i18n.locale]",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.country") },
        model: {
          value: _vm.country[_vm.$i18n.locale],
          callback: function ($$v) {
            _vm.$set(_vm.country, _vm.$i18n.locale, $$v)
          },
          expression: "country[$i18n.locale]",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.prefectures") },
        model: {
          value: _vm.prefecture[_vm.$i18n.locale],
          callback: function ($$v) {
            _vm.$set(_vm.prefecture, _vm.$i18n.locale, $$v)
          },
          expression: "prefecture[$i18n.locale]",
        },
      }),
      _c("fieldset-confirm-parts", {
        attrs: { label: _vm.$tc("formParts.label.zipcode") },
        model: {
          value: _vm.zipcode,
          callback: function ($$v) {
            _vm.zipcode = $$v
          },
          expression: "zipcode",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }