








import { defineComponent, PropType } from '@vue/composition-api'
import CommonRacePointsCardParts from '@/components/RaceListPage/RaceListPane/parts/CommonRacePointsCardParts.vue'
import { RacePointVideoCardDataType } from '@/components/RaceListPage/hook/useRacePointVideo'

/**
 * レース一覧画面: レース ポイント Videoカードコンポーネント
 */
export default defineComponent({
  name: 'RacePointVideoCardParts',
  components: {
    CommonRacePointsCardParts,
  },
  props: {
    racePointVideoData: {
      type: Object as PropType<RacePointVideoCardDataType>,
      required: true,
    },
  },
})
