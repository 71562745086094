import { computed, reactive } from '@vue/composition-api'
import { countBy, keyBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ReactionDocument, {
  ReactionTargetCollectionNameType,
  ReactionType,
} from '@/store/stores/collectionModule/documents/reaction/ReactionDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import usePermission from '@/components/hook/usePermission'

/**
 * いいねを操作するための処理を取得する。
 */
export default function useReaction() {
  const state = reactive({
    lastHighlightLikeFetchedDateByHighlightId: {} as Record<string, number>,
  })

  // Collection modules
  const reactionCollectionModule = CollectionModule.createStore(ReactionDocument)
  const { hasPermission } = usePermission()

  /**
   * 取得したいいね数一覧
   */
  const reactions = computed(() => reactionCollectionModule.data)

  /**
   * targetIdごとに分類されたいいね数一覧
   * targetId: リアクション対象コレクションのプライマリキー
   */
  const reactionsCountByTargetId = computed(() => ({
    communication_user_game_event: countBy(
      reactionCollectionModule.data.filter(
        (reaction) => reaction.targetCollectionName === 'communication_user_game_event',
      ),
      'targetId',
    ),
    communication_comment: countBy(
      reactionCollectionModule.data.filter(
        (reaction) => reaction.targetCollectionName === 'communication_comment',
      ),
      'targetId',
    ),
  }))

  /**
   * targetIdごとに分類されたログインユーザーのいいね一覧
   * targetId: リアクション対象コレクションのプライマリキー
   * 対象コレクションごとのtargetIdに紐づくいいねは1ユーザー2つ以上にはならないため、keyByを使っている
   */
  const ownReactionsByTargetId = computed(() => ({
    communication_user_game_event: keyBy(
      reactionCollectionModule.data.filter(
        (reaction) =>
          reaction.targetCollectionName === 'communication_user_game_event' &&
          reaction._createdBy === LoginStore.value.userId,
      ),
      'targetId',
    ),
    communication_comment: keyBy(
      reactionCollectionModule.data.filter(
        (reaction) =>
          reaction.targetCollectionName === 'communication_comment' &&
          reaction._createdBy === LoginStore.value.userId,
      ),
      'targetId',
    ),
  }))

  /**
   * 対象のハイライトのいいね数を取得する
   */
  const fetchReactionsByHighlightId = (highlightId: string) =>
    reactionCollectionModule.fetch({
      query: {
        filter: {
          targetId: highlightId,
        },
      } as {
        filter: {
          targetId: string
        }
      },
      isUnionExistData: true,
      includePublicScope: true,
    })

  /**
   * 対象のハイライトに紐づくコメントのいいね数を差分取得する
   * 差分取得の内容：前回取得日時以降の差分
   * @param highlightId 対象のハイライトID
   * @param highlightCommentIds 対象のコメントID一覧
   * @param isSaveInStore fetch時にfetchの結果をCollectionModuleに保存するかどうか
   * @param limit 取得上限件数の指定
   */
  const fetchDiffTargetHighlightCommentReactions = async (
    highlightId: string,
    highlightCommentIds: string[],
    isSaveInStore = true,
    limit?: number,
  ) => {
    const query = {
      filter: {},
      sort: '_createdDate',
    } as {
      filter: {
        _lastModifiedDate?: {
          $gte: number
        }
      }
      sort: string
      limit?: number
    }

    const lastFetchedDate = state.lastHighlightLikeFetchedDateByHighlightId[highlightId]

    if (lastFetchedDate) {
      // 最後にいいね情報を取得した日時以降に追加・更新されたハイライト情報のみを取得する
      query.filter._lastModifiedDate = {
        $gte: lastFetchedDate,
      }
    }

    if (limit) {
      query.limit = limit
    }

    const lastHighlightCommentReactionsFetchedDate = new Date().getTime()
    const result = await reactionCollectionModule.fetchSplitFilterIn(
      {
        query,
        isSaveInStore,
        isUnionExistData: true,
        includePublicScope: true,
      },
      1,
      100,
      'targetId',
      highlightCommentIds,
      false,
    )

    if (result.every((r) => r.isSuccess))
      state.lastHighlightLikeFetchedDateByHighlightId[highlightId] =
        lastHighlightCommentReactionsFetchedDate

    return result
  }

  /**
   * 対象の自分のいいね情報を取得する
   * @param targetId 対象プライマリキー
   */
  const fetchOwnReactionByTargetId = (targetId: string) =>
    reactionCollectionModule.fetch({
      targetId,
      isUnionExistData: true,
    })

  /**
   * いいね登録 リクエストデータ作成
   * @param targetId 対象プライマリキー
   * @param targetCollectionName 対象コレクション名
   * @param reactionType リアクション種別
   * @return ReactionDocument
   */
  const createReactionRequestData = (
    targetId: string,
    targetCollectionName: ReactionTargetCollectionNameType,
    reactionType: ReactionType,
  ) =>
    new ReactionDocument({
      targetCollectionName,
      targetId,
      reactionType,
      publicScope: hasPermission('shareReaction')
        ? {
            parentOrganization: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
          }
        : null,
    })

  /**
   * いいね登録
   * @param requestData いいね登録情報
   */
  const saveReaction = (requestData: ReactionDocument) => reactionCollectionModule.save(requestData)

  /**
   * いいね解除
   * @param targetId 対象プライマリキー
   * @param targetCollectionName 対象コレクション名
   * @param reactionType リアクション種別
   * @return APIレスポンス
   */
  const removeReaction = (
    targetId: string,
    targetCollectionName: ReactionTargetCollectionNameType,
    reactionType: ReactionType,
  ) =>
    reactionCollectionModule.remove(targetId, {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/reaction/${targetCollectionName}/${targetId}/${reactionType}`,
    })

  /**
   * ストアに保存しているデータを更新
   * @param newReactions 変更後のいいね一覧
   */
  const updateStoredReactions = (newReactions: ReactionDocument[]) => {
    reactionCollectionModule.setData(newReactions)
  }

  /**
   * 取得したいいねをクリアする
   */
  const clearReactions = () => {
    reactionCollectionModule.clearData()
    state.lastHighlightLikeFetchedDateByHighlightId = {}
  }

  return {
    reactions,
    reactionsCountByTargetId,
    ownReactionsByTargetId,
    fetchOwnReactionByTargetId,
    fetchDiffTargetHighlightCommentReactions,
    fetchReactionsByHighlightId,
    createReactionRequestData,
    saveReaction,
    removeReaction,
    updateStoredReactions,
    clearReactions,
  }
}
