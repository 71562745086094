var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "current-time-parts" }, [
    _c(
      "svg",
      {
        staticClass: "current-time-parts__bg current-time-parts__bg--left",
        attrs: {
          width: "69",
          height: "50",
          viewBox: "0 0 69 50",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("path", {
          attrs: {
            opacity: "0.25",
            d: "M51.0687 5.9102L0.5 50H68.5V0H66.8408C61.0421 0 55.4395 2.09945 51.0687 5.9102Z",
            fill: "url(#paint0_linear_223_2101)",
          },
        }),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_223_2101",
                  x1: "420.36",
                  y1: "8.63042e-07",
                  x2: "420.36",
                  y2: "50",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop"),
                _c("stop", { attrs: { offset: "1", "stop-opacity": "0" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "svg",
      {
        staticClass: "current-time-parts__bg current-time-parts__bg--right",
        attrs: {
          width: "69",
          height: "50",
          viewBox: "0 0 69 50",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("path", {
          attrs: {
            opacity: "0.25",
            d: "M17.9313 5.9102L68.5 50H0.5V0H2.15918C7.95791 0 13.5605 2.09945 17.9313 5.9102Z",
            fill: "url(#paint0_linear_223_2103)",
          },
        }),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_223_2103",
                  x1: "-351.36",
                  y1: "8.63042e-07",
                  x2: "-351.36",
                  y2: "50",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop"),
                _c("stop", { attrs: { offset: "1", "stop-opacity": "0" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", {
      staticClass: "current-time-parts__bg current-time-parts__bg--center",
    }),
    _c("span", {
      staticClass: "current-time-parts__time",
      domProps: { textContent: _vm._s(_vm.currentTime) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }