import qs from 'qs'
import OAuth1Client from '@/util/oauth/OAuth1Client'
import Logger from '@/util/logger/Logger'
import LoadingOverLayStore from '@/store/stores/pageStore/common/LoadingOverlayProgressStore'

/**
 * X API のMediaアップロード前のINITリクエストのレスポンスデータの型
 */
export type MediaInitResponseType = {
  media_id: number
  media_id_string: string
  expires_after_secs: number
}

/**
 * X API のMediaアップロード前のFINALIZEリクエストのレスポンスデータの型
 */
export type MediaFinalizeResponseType = {
  media_id: number
  media_id_string: string
  expires_after_secs: number
  size: number
  video?: {
    video_type: string
  }
  processing_info?: {
    state: 'pending' | 'in_progress' | 'failed' | 'succeeded'
    check_after_secs: number
  }
}

/**
 * X API のMediaアップロード前のSTATUSリクエストのレスポンスデータの型
 */
export type MediaStatusResponseType = {
  media_id: number
  media_id_string: string
  expires_after_secs: number
  processing_info: {
    state: 'pending' | 'in_progress' | 'failed' | 'succeeded'
    check_after_secs: number
    progress_percent: number
  }
}

/**
 * ツイートのレスポンスデータの型
 */
export type TweetResponseType = {
  id: string
  text: string
}

/**
 * X API を操作するための処理を返却する
 *
 * @param consumerKey OAuth コンシューマキー
 * @param consumerSecretKey OAuth コンシューマシークレットキー
 */
export default function useX(consumerKey: string, consumerSecretKey: string) {
  const oAuth1Client = new OAuth1Client(consumerKey, consumerSecretKey)
  const uploadAPIUrl = 'https://upload.twitter.com/1.1/media/upload.json'

  /**
   * 動画のアップロード完了を待機する.
   *
   * https://developer.twitter.com/en/docs/twitter-api/v1/media/upload-media/api-reference/get-media-upload-status
   * @param oAuthToken OAuth トークン
   * @param oAuthSecretToken OAuth シークレットトークン
   * @param mediaId Media ID
   * @param waitTime 初回の待機時間。初回の待機以降は、STATUS取得後に返却される processing_info.check_after_secs の時間間隔でSTATUSを確認する
   */
  const waitUploadFinished = (
    oAuthToken: string,
    oAuthSecretToken: string,
    mediaId: string,
    waitTime = 1000,
  ) =>
    new Promise((resolve: (status: MediaStatusResponseType) => void) => {
      setTimeout(async () => {
        const sendStatusData = {
          command: 'STATUS',
          media_id: mediaId,
        }
        const uploadStatusUrl = `${uploadAPIUrl}${qs.stringify(sendStatusData, {
          addQueryPrefix: true,
        })}`
        Logger.debug(`Wait until the video upload status becomes successful. media_id: ${mediaId}`)

        const statusResponse = await oAuth1Client.sendRequest(uploadStatusUrl, 'get', {
          Authorization: oAuth1Client.makeAuthorizationHeader(uploadAPIUrl, 'get', sendStatusData, {
            accessToken: oAuthToken,
            accessTokenSecret: oAuthSecretToken,
          }),
        })
        let statusData = JSON.parse(statusResponse.data) as MediaStatusResponseType
        if (
          statusData.processing_info.state === 'in_progress' ||
          statusData.processing_info.state === 'pending'
        ) {
          Logger.debug(
            `Start STATUS process due to processing_info.state is 'in_progress'. media_id: ${mediaId}`,
          )
          statusData = await waitUploadFinished(
            oAuthToken,
            oAuthSecretToken,
            mediaId,
            statusData.processing_info.check_after_secs * 1000,
          )
          resolve(statusData)
          return
        }
        if (statusData.processing_info.state === 'succeeded') {
          Logger.info(`Video upload status changed to succeeded. media_id: ${mediaId}`)
        } else {
          Logger.info(`Video upload status is abnormal. statusResponse: ${statusResponse.data}`)
        }
        resolve(statusData)
      }, waitTime)
    })

  /**
   * ファイルをチャンクサイズに分割してアップロードする。
   *
   * @param oAuthToken OAuth トークン
   * @param oAuthSecretToken OAuth シークレットトークン
   * @param mediaId Media ID
   * @param blob アップロード対象のBlob
   */
  const uploadFileChunked = async (
    oAuthToken: string,
    oAuthSecretToken: string,
    mediaId: string,
    blob: Blob,
  ) => {
    const chunkSize = 5 * 1024 * 1024
    Logger.debug(
      `Starts the process of uploading the video in segments. mediaId: ${mediaId}, file.size: ${blob.size}`,
    )
    let segmentCount = Math.floor(blob.size / chunkSize) + 1
    if (blob.size % chunkSize === 0) segmentCount += 1
    const addProgressPercent = Math.round(60 / segmentCount)
    let startPointer = 0
    let segmentIndex = 0
    const endPointer = blob.size
    while (startPointer < endPointer) {
      let newStartPointer = startPointer + chunkSize
      if (newStartPointer > blob.size) {
        newStartPointer = blob.size
      }
      Logger.debug(
        `Start  to upload Media. mediaId: ${mediaId}, range: ${startPointer} - ${newStartPointer}`,
      )

      const formData = new FormData()
      formData.append('command', 'APPEND')
      formData.append('media_id', mediaId)
      formData.append('media', blob.slice(startPointer, newStartPointer))
      formData.append('segment_index', `${segmentIndex}`)

      // eslint-disable-next-line no-await-in-loop
      await oAuth1Client.sendRequest(
        uploadAPIUrl,
        'post',
        {
          Authorization: oAuth1Client.makeAuthorizationHeader(uploadAPIUrl, 'post', undefined, {
            accessToken: oAuthToken,
            accessTokenSecret: oAuthSecretToken,
          }),
        },
        formData,
        'multipart',
      )
      LoadingOverLayStore.value.updateProgressList(
        'postX',
        addProgressPercent * (segmentIndex + 1) + 20,
      )

      startPointer = newStartPointer
      segmentIndex += 1
    }
  }

  /**
   * メディアのFINALIZEリクエストを送信する。
   *
   * @param oAuthToken OAUth トークン
   * @param oAuthSecretToken OAuth シークレットトークン
   * @param mediaId メディアID
   */
  const requestFinalizeMedia = async (
    oAuthToken: string,
    oAuthSecretToken: string,
    mediaId: string,
  ) => {
    const sendFinalizeData = {
      command: 'FINALIZE',
      media_id: mediaId,
    }
    Logger.debug(`Start to request finalize media. mediaId: ${mediaId}`)
    return oAuth1Client.sendRequest(
      uploadAPIUrl,
      'post',
      {
        Authorization: oAuth1Client.makeAuthorizationHeader(
          uploadAPIUrl,
          'post',
          sendFinalizeData,
          {
            accessToken: oAuthToken,
            accessTokenSecret: oAuthSecretToken,
          },
        ),
      },
      sendFinalizeData,
    )
  }

  /**
   * メディアのFINALIZE処理を行う。
   *
   * @param oAuthToken OAuth トークン
   * @param oAuthSecretToken OAuth シークレットトークン
   * @param mediaId メディアID
   */
  const finalizeMedia = async (oAuthToken: string, oAuthSecretToken: string, mediaId: string) => {
    const finalizeResponse = await requestFinalizeMedia(oAuthToken, oAuthSecretToken, mediaId)
    if (!finalizeResponse.data) {
      throw new Error(
        `Failed to finalize media. mediaId: ${mediaId}, response: ${JSON.stringify(
          finalizeResponse,
        )}`,
      )
    }
    Logger.debug(`Finish to request finalize media. mediaId: ${finalizeResponse.data}`)
    let finalizeData = JSON.parse(finalizeResponse.data) as MediaFinalizeResponseType
    if (finalizeData.processing_info) {
      // STATUS
      const responseMediaStatus = await waitUploadFinished(oAuthToken, oAuthSecretToken, mediaId)

      if (responseMediaStatus.processing_info.state !== 'succeeded') {
        throw new Error(
          `Media Upload Error: responseMediaStatus: ${JSON.stringify(responseMediaStatus)}`,
        )
      }
      const finalizeResponse2 = await requestFinalizeMedia(oAuthToken, oAuthSecretToken, mediaId)
      if (!finalizeResponse2.data) {
        throw new Error(
          `Failed to finalize media. mediaId: ${mediaId}, response: ${JSON.stringify(
            finalizeResponse2,
          )}`,
        )
      }
      Logger.debug(`Finish to request finalize media. mediaId: ${finalizeResponse2.data}`)
      finalizeData = JSON.parse(finalizeResponse2.data) as MediaFinalizeResponseType
    }
    return finalizeData
  }

  /**
   * ファイルをXにアップロードする。
   * https://developer.twitter.com/en/docs/twitter-api/v1/media/upload-media/overview
   * @param oAuthToken アクセストークン
   * @param oAuthSecretToken アクセストークンシークレット
   * @param base64 base64エンコードされたファイルデータ
   */
  const uploadFile = async (oAuthToken: string, oAuthSecretToken: string, base64: string) => {
    // base64データをプレフィックスなしで抽出
    const base64Data = base64.split(',')[1]

    const sendData = new FormData()
    sendData.append('media_data', base64Data)

    Logger.info('Start upload media.')

    // ※ APIの仕様では urlencoded でも呼び出し可能なはずだが、iOSの場合に401エラーとなるため、multipart/form-dataで送信する
    const response = await oAuth1Client.sendRequest(
      uploadAPIUrl,
      'post',
      {
        Authorization: oAuth1Client.makeAuthorizationHeader(uploadAPIUrl, 'post', undefined, {
          accessToken: oAuthToken,
          accessTokenSecret: oAuthSecretToken,
        }),
      },
      sendData,
      'multipart',
    )

    if (!response.data) {
      throw new Error(`Failed to Upload Media. response: ${JSON.stringify(response)}`)
    }
    Logger.info(`Finish upload media process successfully. media: ${JSON.stringify(response)}`)

    const responseData = JSON.parse(response.data) as MediaInitResponseType

    return responseData.media_id_string
  }

  /**
   * ファイルをXに分割でアップロードする。
   * Xでは、ファイルのアップロードを、INIT/APPEND/FINALIZEの3つのステップで実施する必要があるため、それらの3つの処理を行う。
   * https://developer.twitter.com/en/docs/twitter-api/v1/media/upload-media/overview
   * @param oAuthToken アクセストークン
   * @param oAuthSecretToken アクセストークンシークレット
   * @param blob アップロード対象のBlob
   */
  const chunkUploadFile = async (oAuthToken: string, oAuthSecretToken: string, blob: Blob) => {
    // POST media/upload (INIT)
    // ※ APIの仕様では urlencoded でも呼び出し可能なはずだが、iOSの場合に401エラーとなるため、multipart/form-dataで送信する
    const sendInitData = new FormData()
    sendInitData.append('command', 'INIT')
    sendInitData.append('total_bytes', `${blob.size}`)
    sendInitData.append('media_type', 'video/mp4')

    Logger.debug('Start INIT process to upload Media')
    const initResponse = await oAuth1Client.sendRequest(
      uploadAPIUrl,
      'post',
      {
        Authorization: oAuth1Client.makeAuthorizationHeader(uploadAPIUrl, 'post', undefined, {
          accessToken: oAuthToken,
          accessTokenSecret: oAuthSecretToken,
        }),
      },
      sendInitData,
      'multipart',
    )

    if (!initResponse.data) {
      throw new Error(`Failed to Media. response: ${JSON.stringify(initResponse)}`)
    }
    LoadingOverLayStore.value.updateProgressList('postX', 20)

    Logger.debug(`Finish INIT process to upload Media. response: ${initResponse.data}`)

    const initData = JSON.parse(initResponse.data) as MediaInitResponseType
    const mediaId = initData.media_id_string

    // APPEND
    await uploadFileChunked(oAuthToken, oAuthSecretToken, mediaId, blob)

    // FINALIZE
    const finalizeData = await finalizeMedia(oAuthToken, oAuthSecretToken, mediaId)
    Logger.info(`Finish upload media process successfully. media: ${JSON.stringify(finalizeData)}`)

    return mediaId
  }

  /**
   * Xにツイートする。
   *
   * X API v2 の を利用して投稿する。
   * https://developer.twitter.com/en/docs/twitter-api/tweets/manage-tweets/api-reference/post-tweets
   *
   * https://github.com/twitterdev/Twitter-API-v2-sample-code/blob/main/Manage-Tweets/create_tweet.js を参考にして実装した。
   *
   * @param oAuthToken アクセストークン
   * @param oAuthSecretToken アクセストークンシークレット
   * @param text ツイートメッセージ
   * @param mediaId ツイートに添付するメディアID
   */
  const tweet = async (
    oAuthToken: string,
    oAuthSecretToken: string,
    text: string,
    mediaId: string,
  ) => {
    const tweetV2Url = 'https://api.twitter.com/2/tweets'

    const tweetData = {
      text,
      media: { media_ids: [mediaId] },
    }

    const tweetResponse = await oAuth1Client.sendRequest(
      tweetV2Url,
      'post',
      {
        Authorization: oAuth1Client.makeAuthorizationHeader(tweetV2Url, 'post', undefined, {
          accessToken: oAuthToken,
          accessTokenSecret: oAuthSecretToken,
        }),
        'user-agent': 'SFgo',
        accept: 'application/json',
      },
      tweetData,
      'json',
    )
    if (!tweetResponse.data) {
      throw new Error(
        `Failed to tweet. mediaId: ${mediaId}, response: ${JSON.stringify(tweetResponse)}`,
      )
    }
    return JSON.parse(tweetResponse.data) as TweetResponseType
  }
  return {
    uploadFile,
    chunkUploadFile,
    tweet,
  }
}
