var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "caution-maintenance-section",
      class: [{ smartphone: _vm.isSmartPhone }],
    },
    [
      _c("p", { staticClass: "caution-maintenance-section__title" }, [
        _vm._v(_vm._s(_vm.cautionMessageInfo.title)),
      ]),
      _c("p", {
        staticClass: "caution-maintenance-section__message",
        domProps: { innerHTML: _vm._s(_vm.cautionMessageInfo.message) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }