









































import { computed, defineComponent, onBeforeMount, ref, Ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import AttentionMessageSection, {
  AttentionMessageData,
} from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import PaymentCycleSection from '@/components/pc/MyPage/common/PaymentCycleSection.vue'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import StoreUtil from '@/store/StoreUtil'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import useContractTerm from '@/components/hook/useContractTerm'
import useContract from '@/components/hook/useContract'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import I18n from '@/locales/I18n'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import usePaymentCycleImageOnChange from '@/components/pc/MyPage/PaidPlanChange/hook/usePaymentCycleImageOnChange'

/**
 * プラン変更(有料=>有料) ペイン
 */
export default defineComponent({
  name: 'PaidPlanChangeEditPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    CurrentPlanCardSection,
    AttentionMessageSection,
    PaymentCycleSection,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
    const {
      ownContractInfo,
      fetchContractInfoPlans,
      getSelectedContractPlan,
      selectedPlanGroupId,
    } = mypagePageStore
    const { getScheduledContractTerm, getReferenceDate } = useContractTerm()
    const { changePaidPlan } = useContract()
    const { getDisplayDate } = useDisplayDependingOnLang()
    const { getBillingCycleImage } = usePaymentCycleImageOnChange()

    /** 月額プランの契約を更新済みかどうか */
    const hasRenewedMonthlyPlan = ref(false)
    /** 注意事項テキストデータ */
    const attentionMessage: Ref<AttentionMessageData | null> = ref(null)
    /** 請求サイクル画像パス */
    const paymentCycleImgPath = ref({
      pc: '',
      sp: '',
    })
    /** 請求サイクル画像表示ケース */
    const displayCase: Ref<number | null> = ref(null)

    /** 請求サイクル画像下に表示するテキスト */
    const textDisplayBlowImage = computed(() =>
      I18n.tc(
        `PaidPlanChange.ConfirmPage.${selectedPlanGroupId.value}${displayCase.value}.attention`,
      ),
    )

    /**
     * 契約予定のプランの契約期間
     */
    const scheduledContractTerm = computed(() =>
      getScheduledContractTerm(
        effectiveContractPlan as ContractPlanDocument,
        effectiveOrgContract as ContractType,
        selectedPlanGroupId.value,
      ),
    )

    /**
     * 請求サイクル画像パスをセット
     */
    const setPaymentCycleImgPath = () => {
      if (displayCase.value) {
        /** 月額プランから年額プランに切り替わる請求サイクル画像を表示 */
        const path = `PaidPlanChangePage/confirmPage/payment_cycle_${selectedPlanGroupId.value}_${displayCase.value}_${I18n.locale}`
        paymentCycleImgPath.value = {
          pc: `${path}_pc.jpg`,
          sp: `${path}_sp.jpg`,
        }
      }
    }

    onBeforeMount(async () => {
      if (selectedPlanGroupId.value === 'monthlyPlan') {
        // 月額プランを選択している場合

        displayCase.value = 1
        /** 年額プランから月額プランに切り替わる請求サイクル画像を表示 */
        setPaymentCycleImgPath()

        /** 注意メッセージを表示する */
        // 翌月の決済日
        const nextSettlementDate = getDisplayDate(
          dayjs(scheduledContractTerm.value.contractStartDate)
            .add(1, 'M')
            .startOf('month')
            .valueOf(),
        )

        attentionMessage.value = {
          message: I18n.t('PaidMembershipChange.ConfirmPage.monthlyPlan.attentionMessage', {
            date: nextSettlementDate,
          }).toString(),
          note: '',
        }
      } else if (selectedPlanGroupId.value === 'annualPlan') {
        // 現在時刻を取得
        const now = dayjs().valueOf()
        // 基準日（契約更新予定日 or 契約更新日）を取得
        const referenceDate = getReferenceDate(effectiveOrgContract?.startDate ?? 0)
        // 契約更新済みかどうか
        hasRenewedMonthlyPlan.value = referenceDate < now
        /** 月額プランから年額年額プランに変更した場合、どの画像を表示するかを判定 */
        displayCase.value = await getBillingCycleImage(hasRenewedMonthlyPlan.value)

        /** 月額プランから年額プランに切り替わる請求サイクル画像パスをセットする */
        setPaymentCycleImgPath()
      }
    })

    return {
      ownContractInfo,
      fetchContractInfoPlans,
      plan: getSelectedContractPlan as Ref<ContractPlanDocument>,
      selectedPlanGroupId,
      displayCase,
      paymentCycleImgPath,
      textDisplayBlowImage,
      scheduledContractTerm,
      attentionMessage,
      changePaidPlan,
    }
  },
  methods: {
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      const loader = this.$loading.show()
      /** 契約情報契約プランを変更（有料→有料） */
      const changePaidPlanResult = await this.changePaidPlan(
        this.selectedPlanGroupId,
        this.scheduledContractTerm,
      )
      if (!changePaidPlanResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$t('PaidPlanChange.ConfirmPage.errors.changePaidPlanError.title') as string,
          message: this.$t(
            'PaidPlanChange.ConfirmPage.errors.changePaidPlanError.message',
          ) as string,
        })
        // プラン選択画面に遷移する
        this.$emit('handlerCancel')
        return
      }

      // プラン変更完了画面に請求情報を表示するため、以下を最新化する
      /**
       * マイページストアの契約情報契約プラン
       * グローバルストアの契約情報
       */
      await ContractInfoStore.value.fetchUserContractInfo()
      await Promise.all([
        this.fetchContractInfoPlans(this.ownContractInfo.contractInfoId ?? ''),
        ContractInfoStore.value.fetchUserContractInfo(),
      ])

      this.$emit('handlerSubmit')
      loader.hide()
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
