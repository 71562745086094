

















import { computed, defineComponent } from '@vue/composition-api'
import AtomInputCheckbox from '@/components/atoms/input/AtomInputCheckbox.vue'

/**
 * フィールドセット 汎用チェックボックス バーツ
 */
export default defineComponent({
  name: 'FieldsetCheckboxParts',
  components: {
    AtomInputCheckbox,
  },
  props: {
    /**
     * ラベルテキスト
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * 入力データ
     */
    value: {
      type: Boolean,
      required: true,
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: true,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * 非活性にするかどうか
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * カラーパターン
     */
    color: {
      type: String,
      default: 'light',
    },
  },
  setup(props, context) {
    const checkValue = computed(() => props.value)
    const onChecked = (newValue: boolean) => context.emit('changed', newValue)

    return {
      checkValue,
      onChecked,
    }
  },
})
