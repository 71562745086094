var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choose-plan-section" },
    [
      _c("h4", { staticClass: "choose-plan-section__title" }, [
        _vm._v(
          " " + _vm._s(_vm.$tc("SignupPage.SelectPlanPage.plan.title")) + " "
        ),
      ]),
      _c(
        "toggle-radio-card-list-section",
        _vm._l(_vm.planList, function (plan, index) {
          return _c(
            "toggle-radio-card-parts",
            {
              key: "planToggleCard" + index,
              attrs: {
                radioValue: plan.planGroupId,
                currentValue: _vm.selectedPlanGroupId,
              },
              on: { handlerOnClick: _vm.handlerPlanSelect },
            },
            [
              "couponData" in plan
                ? _c("coupon-plan-card-parts", {
                    attrs: {
                      plan: plan,
                      bargainPriced: _vm.bargainPriced(plan),
                    },
                  })
                : _c("plan-card-parts", {
                    attrs: {
                      plan: plan,
                      bargainPriced: _vm.bargainPriced(plan),
                      procedureScreen: _vm.procedureScreen,
                    },
                  }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }