var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment-input-section",
      class: { "comment-input-section--active": !_vm.disabledSubmitButton },
    },
    [
      _c("div", { staticClass: "comment-input-section__textarea__container" }, [
        _c(
          "div",
          { staticClass: "comment-input-section__textarea__inner" },
          [
            _c(
              "div",
              { staticClass: "comment-input-section__textarea__dummy" },
              [_vm._v(_vm._s(_vm.comment + "\n"))]
            ),
            _c("AtomTextArea", {
              staticClass: "comment-input-section__textarea",
              attrs: {
                id: "comment",
                focusOnMount: true,
                placeholder: _vm.$tc(
                  "RaceVideoPage.highlights.comment.placeholder"
                ),
              },
              model: {
                value: _vm.comment,
                callback: function ($$v) {
                  _vm.comment = $$v
                },
                expression: "comment",
              },
            }),
            _vm.postX
              ? _c("p", { staticClass: "comment-input-section__hashtag" }, [
                  _vm._v(_vm._s(_vm.hashtag)),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "comment-input-section__buttons" }, [
        _c(
          "div",
          { staticClass: "comment-input-section__sns" },
          [
            _c("mission-sns-button-parts", {
              attrs: { postX: _vm.postX, postInstagram: _vm.postInstagram },
              on: {
                onChangePostX: function ($event) {
                  _vm.postX = !_vm.postX
                },
                onChangePostInstagram: function ($event) {
                  _vm.postInstagram = !_vm.postInstagram
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "comment-input-section__buttons__comment" }, [
          _c(
            "div",
            { staticClass: "comment-input-section__count" },
            [
              _c("comment-count-parts", {
                attrs: {
                  comment: _vm.comment,
                  commentLength: _vm.commentLength,
                },
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass:
                "comment-input-section__button comment-input-section__button--cancel",
              on: { click: _vm.handleCancelClicked },
            },
            [_c("mission-cancel-icon")],
            1
          ),
          _c(
            "button",
            {
              staticClass:
                "comment-input-section__button comment-input-section__button--submit",
              attrs: { disabled: _vm.disabledSubmitButton },
              on: { click: _vm.onPostComment },
            },
            [_c("mission-submit-icon")],
            1
          ),
        ]),
      ]),
      _vm.enabledConfirmModal
        ? _c("confirm-modal-section", {
            staticClass: "comment-input-section__modal",
            attrs: {
              message: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.inputCancelMessage"
              ),
              confirmButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.confirmButtonLabel"
              ),
              cancelButtonLabel: _vm.$tc(
                "RaceVideoPage.highlights.comment.inputCancelModal.cancelButtonLabel"
              ),
            },
            on: {
              confirm: _vm.cancelInput,
              cancel: function ($event) {
                _vm.enabledConfirmModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }