







































import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  Ref,
} from '@vue/composition-api'
import VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import ComparisonPlanSection from '@/components/SignupPage/common/ComparisonPlanSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import MypageSelectPlanButtonParts from '@/components/MypageSwitchToPaidPlanPage/MypageSwitchToPaidPlanPane/MypageSelectPlanButtonParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import DeviceInfo from '@/util/DeviceInfo'
import useInAppPurchase from '@/components/hook/inAppPurchase/useInAppPurchase'
import I18n from '@/locales/I18n'
import { SubscriptionPlanType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import StoreUtil from '@/store/StoreUtil'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import ContractInfoAppStoreDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoAppStoreDocument'
import ContractInfoGooglePlayDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoGooglePlayDocument'
import { SaveResponse } from '@/store/stores/collectionModule/CollectionTypes'
import Const from '@/util/Const'

/**
 * 定期購入商品情報の型
 */
type SubscriptionItemType = {
  type: SubscriptionPlanType
  id: string
  name: string
  price: string
  unit: string
  bargainPriced?: string
  owned: boolean
}

/**
 * マイページ: アプリ内課金 有料プラン購入ペイン
 */
export default defineComponent({
  name: 'MypageSwitchToPaidPlanPane',
  components: {
    AtomButton,
    MypageSelectPlanButtonParts,
    ActionButtonsSection,
    ComparisonPlanSection,
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const {
      fetchContractInfo,
      ownContractInfo,
      updateContractInfoAppStore,
      updateContractInfoGooglePlay,
      fetchInAppPurchaseHistory,
      inAppPurchaseHistory,
    } = mypagePageStore
    const { store, sfgoSubscriptions, purchaseSubscription, restore, setDisplaySubscriptionData } =
      useInAppPurchase()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    const actionButtons = ref<InstanceType<typeof ActionButtonsSection> | null>(null)
    const state = reactive({
      actionButtonsHeight: 383,
    })

    /** 購入したプランタイプ */
    const purchasedPlanType: Ref<SubscriptionPlanType | undefined> = ref(undefined)

    /**
     * アプリ内課金 BEに連携するサブスクリプション購入時のUUID
     */
    const purchaseSubscriptionUuid = computed(() => {
      // ストアの変更が検知されなかったため、Loggerを定義することによって変更を検知できるように対応した
      Logger.info(
        `MypageSwitchToPaidPlanPane#purchaseSubscriptionUuid: ${mypagePageStore.purchaseSubscriptionUuid.value}`,
      )
      return mypagePageStore.purchaseSubscriptionUuid.value
    })

    /**
     * 製品IDを取得
     */
    const getProductId = (cycle: SubscriptionPlanType) => sfgoSubscriptions.value[cycle]?.id ?? ''

    /**
     * 金額を取得
     */
    const getPrice = (cycle: SubscriptionPlanType) =>
      sfgoSubscriptions.value[cycle]?.pricing?.price ?? ''

    /**
     * 金額（数値）を取得
     */
    const getPriceNum = (cycle: SubscriptionPlanType) =>
      sfgoSubscriptions.value[cycle]?.pricing?.priceMicros

    /**
     * 購入状態を取得
     */
    const getOwned = (cycle: SubscriptionPlanType) => sfgoSubscriptions.value[cycle]?.owned ?? false

    /**
     * 月額プランと比べて、年額プランを契約した場合のお得額を計算
     */
    const bargainPriced = computed(() => {
      const annualPlanPrice = getPriceNum('annual') ?? 0
      const monthlyPlanPrice = getPriceNum('monthly') ?? 0

      if (!annualPlanPrice || !monthlyPlanPrice) {
        return ''
      }

      // お得額
      const moneySaved = `${(100 - (annualPlanPrice / (monthlyPlanPrice * 12)) * 100).toFixed()}%`
      return I18n.t('MypagePage.MypageSwitchToPaidPlanPage.plans.annual.bargainPriced', {
        percent: moneySaved,
      }).toString()
    })

    /**
     * 定期購入商品情報
     */
    const subscriptionItems: Ref<SubscriptionItemType[]> = computed(() => [
      {
        type: 'annual',
        id: getProductId('annual'),
        name: I18n.tc('MypagePage.MypageSwitchToPaidPlanPage.plans.annual.title'),
        price: getPrice('annual'),
        unit: I18n.tc('MypagePage.MypageSwitchToPaidPlanPage.plans.annual.unit'),
        bargainPriced: bargainPriced.value,
        owned: getOwned('annual'),
      },
      {
        type: 'monthly',
        id: getProductId('monthly'),
        name: I18n.tc('MypagePage.MypageSwitchToPaidPlanPage.plans.monthly.title'),
        price: getPrice('monthly'),
        unit: I18n.tc('MypagePage.MypageSwitchToPaidPlanPage.plans.monthly.unit'),
        owned: getOwned('monthly'),
      },
    ])

    /**
     * アプリ内課金履歴情報を定期的に取得する。
     * @param latestSubscriptionUuid 最新のトランザクションから取得したpurchaseId（Google Play課金時のみ値が渡ってくる）
     */
    const pollingInAppPurchaseHistory = async (latestSubscriptionUuid = '') => {
      let count = 0
      return new Promise<void>((resolve) => {
        const inAppPurchaseId = purchaseSubscriptionUuid.value
          ? purchaseSubscriptionUuid.value
          : latestSubscriptionUuid
        const timerId = window.setInterval(async () => {
          await fetchInAppPurchaseHistory(inAppPurchaseId).then(() => {
            Logger.debug(
              `useInAppPurchase#pollingInAppPurchaseHistory: Polling to fetch inAppPurchaseHistory.`,
            )
          })

          count += 1
          if (count === 30 || inAppPurchaseHistory.value?.status === 'Complete') {
            // 30回実行したら（90秒経ったら）または契約情報の更新処理が完了したらポーリングを停止する
            clearInterval(timerId)
            resolve()
          }
        }, Const.CONTRACT_INFO_POLING_INTERVAL)
      })
    }

    /**
     * purchaseSubscriptionUuidを取得
     * useInAppPurchaseのfinishPurchaseでストアにトランザクションIDを保存しているが、
     * ストアに保存されているトランザクションIDが即時取得できないため、setIntervalを使ってトランザクションIDを取得する処理にしている
     */
    const getPurchaseSubscriptionUuid = async () => {
      let count = 0
      return new Promise<string>((resolve) => {
        const timerId = window.setInterval(async () => {
          if (purchaseSubscriptionUuid.value) {
            // purchaseSubscriptionUuidをセットできたらsetIntervalを停止
            clearInterval(timerId)
            resolve(purchaseSubscriptionUuid.value)
            return
          }

          count += 1
          if (count >= 10) {
            // 10回試してもpurchaseSubscriptionUuidを取得できない場合、setIntervalを停止
            clearInterval(timerId)
            resolve(purchaseSubscriptionUuid.value)
          }
        }, 500)
      })
    }

    /**
     * サブスクリプション購入時のUUIDと組織を紐づける
     * @param latestSubscriptionUuid 最新のトランザクションから取得したpurchaseId（Google Play課金時のみ値が渡ってくる）
     */
    const linkPurchaseSubscriptionUuid = async (latestSubscriptionUuid = '') => {
      const requestPurchaseSubscriptionUuid = await getPurchaseSubscriptionUuid()
      let linkResult: SaveResponse<
        ContractInfoAppStoreDocument | ContractInfoGooglePlayDocument
      > | null = null

      if (DeviceInfo.isiOS()) {
        // AppStoreアプリ内課金したユーザーのトランザクションIDと組織情報を紐づけるためのリクエストパラメータ
        const appStoreRequestData = new ContractInfoAppStoreDocument({
          contractInfoId: ownContractInfo.value.id,
          transactionId: requestPurchaseSubscriptionUuid,
        })
        linkResult = await updateContractInfoAppStore(appStoreRequestData)
      } else if (DeviceInfo.isAndroid()) {
        // GooglePlayアプリ内課金したユーザーのトークンと組織情報を紐づけるためのリクエストパラメータ
        const googlePlayRequestData = new ContractInfoGooglePlayDocument({
          contractInfoId: ownContractInfo.value.id,
          /**
           * GooglePlayの場合、時々課金状態が契約情報に紐づかないことがあり、恐らく購入時のトークンをストアから取得できていない可能性がある
           * そのため、requestPurchaseSubscriptionUuidが空の場合はsubscriptionUuid（リストア後に取得する最新のトランザクション情報に含まれるpurchaseId）を送るようにする
           */
          purchaseToken: requestPurchaseSubscriptionUuid
            ? requestPurchaseSubscriptionUuid ?? ''
            : latestSubscriptionUuid,
        })
        linkResult = await updateContractInfoGooglePlay(googlePlayRequestData)
      }

      if (!linkResult?.isSuccess) {
        MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.linkContractInfoError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.linkContractInfoError.message',
          ),
        })
        return { isSuccess: false }
      }
      return { isSuccess: true }
    }

    /**
     * アプリ内課金履歴情報を定期的に取得する処理を実行
     * @param latestSubscriptionUuid 最新のトランザクションから取得したpurchaseId（Google Play課金時のみ値が渡ってくる）
     */
    const execPollingInAppPurchaseHistory = async (latestSubscriptionUuid = '') => {
      // アプリ内課金履歴情報を定期的に取得する
      await pollingInAppPurchaseHistory(latestSubscriptionUuid)
      if (inAppPurchaseHistory.value?.status !== 'Complete') {
        MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.getInAppPurchaseHistoryError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.getInAppPurchaseHistoryError.message',
          ),
        })
        return { isSuccess: false }
      }

      return { isSuccess: true }
    }

    /**
     * purchaseTokenを取得する
     * purchaseTokenを取得できない場合はリトライ処理を行い、最大10回までリトライする
     */
    const retryToGetPurchaseToken = async () => {
      let purchaseToken = ''
      let attempts = 0
      const maxAttempts = 10

      while (!purchaseToken && attempts < maxAttempts) {
        // 購入ステータスを最新にする
        // eslint-disable-next-line no-await-in-loop
        await setDisplaySubscriptionData()
        const ownedSubscription = Object.values(sfgoSubscriptions.value).find((v) => v?.owned)
        if (ownedSubscription) {
          const latestReceipt = store.findInLocalReceipts(ownedSubscription)
          purchaseToken = latestReceipt?.purchaseId ?? ''
        }

        if (!purchaseToken) {
          // 2秒待機してから再試行
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            setTimeout(resolve, 2000)
          })
        }
        attempts += 1
      }

      return purchaseToken
    }

    /**
     * 商品を購入する
     */
    const purchase = async (subscription: SubscriptionItemType) => {
      if (mypagePageStore.isPurchasingInAppPurchase.value) {
        // アプリ内課金でプラン購入中は購入ボタンを押せない
        return
      }

      if (subscriptionItems.value.some((v) => v.owned)) {
        let title = ''
        let message = ''
        if (DeviceInfo.isiOS()) {
          title = I18n.tc(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.iOS.alreadyPurchasedPlan.title',
          )
          message = I18n.t(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.iOS.alreadyPurchasedPlan.message',
            {
              url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.IOS,
            },
          ).toString()
        } else if (DeviceInfo.isAndroid()) {
          title = I18n.tc(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.android.alreadyPurchasedPlan.title',
          )
          message = I18n.t(
            'MypagePage.MypageSwitchToPaidPlanPage.errors.android.alreadyPurchasedPlan.message',
            {
              url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.ANDROID,
            },
          ).toString()
        }

        // 既に有料プランを購入済みの場合は、購入処理を実施しない
        MessageDialogStore.value.open({
          title,
          message,
        })
        return
      }

      // BEに連携するサブスクリプション購入時のUUIDをクリア
      mypagePageStore.purchaseSubscriptionUuid.value = ''
      // 年額プラン、月額プランどちらを購入したか判別できるようにする
      purchasedPlanType.value = subscription.type

      // アプリ内のボタンを押せないようにする
      mypagePageStore.isPurchasingInAppPurchase.value = true
      // 対象商品を購入する
      const purchaseResult = await purchaseSubscription(subscription.id)

      if (!purchaseResult?.isSuccess) {
        // アプリ内のボタンを押せるようにする
        mypagePageStore.isPurchasingInAppPurchase.value = false

        // 購入処理をキャンセル、または失敗した場合は後続処理を実行しない
        return
      }

      const results: Array<{ isSuccess: boolean }> = []
      let pollingResult: { isSuccess: boolean } | null = null
      let subscriptionUuid = ''

      // アプリ内課金時に契約情報を更新する処理を実行
      const loader = loading.show()

      if (DeviceInfo.isAndroid()) {
        // Androidの場合、ストアからpurchaseTokenを取得できないことがあるため、その時はリトライしてpurchaseTokenを取得する
        subscriptionUuid = await retryToGetPurchaseToken()
      }

      if (!ownContractInfo.value.isiOSAndHasPurchasedAppStore) {
        /**
         * サブスクリプション購入時のUUIDと組織を紐づける
         * subscriptionUuidはiOSの場合は空文字、Androidの場合は最新のトランザクション情報から取得したpurchaseIdが入る
         */
        const linkResult = await linkPurchaseSubscriptionUuid(subscriptionUuid)
        results.push(linkResult)
        if (linkResult.isSuccess) {
          // 409エラーになるのを防ぐため、契約情報を再取得
          await fetchContractInfo()
          // アプリ内課金履歴情報を定期的に取得する処理を実行
          pollingResult = await execPollingInAppPurchaseHistory(subscriptionUuid)
          results.push(pollingResult)
        }
      } else {
        /**
         * iOSで過去にアプリ内課金したことがある場合の処理
         * 過去にAppStoreでアプリ内課金で購入したことがある場合、AppStoreから再購入通知が届くため、新しく発行されるtransactionIdをBEに送るとBE側で紐付けができなくなるとのこと。
         * そのため、appStore.transactionIdをチェクし、値が入っている場合はトランザクションIDと組織を紐づける処理を実施しない。
         * https://npbam.slack.com/archives/C02PNDTP2AF/p1714096498716749?thread_ts=1713951610.019409&cid=C02PNDTP2AF
         */
        // アプリ内課金履歴情報を定期的に取得する処理を実行
        pollingResult = await execPollingInAppPurchaseHistory()
        results.push(pollingResult)
      }
      if (
        DeviceInfo.isAndroid() &&
        pollingResult?.isSuccess &&
        mypagePageStore.inAppPurchaseTransaction.value
      ) {
        // Androidの場合、購入処理が完了したらトランザクションを閉じる
        mypagePageStore.inAppPurchaseTransaction.value.finish()
      }

      loader.hide()
      // アプリ内のボタンを押せるようにする
      mypagePageStore.isPurchasingInAppPurchase.value = false

      const failedResult = results.find((result) => !result?.isSuccess)
      if (!failedResult) {
        // アプリ内課金での購入が完了して、契約情報が完了した場合、マイページに戻り完了モーダルを出す
        if (purchasedPlanType.value === 'annual') {
          router.push({ name: 'MypageTopPage', query: { modalId: 'completeAnnualPlan' } })
        } else if (purchasedPlanType.value === 'monthly') {
          router.push({ name: 'MypageTopPage', query: { modalId: 'competeMonthlyPlan' } })
        }
      } else {
        // マイページトップへ遷移
        router.replace({ name: 'MypageTopPage' })
      }
    }

    /**
     * 購入情報を復元する
     */
    const restorePurchase = async () => {
      if (mypagePageStore.isPurchasingInAppPurchase.value) {
        // アプリ内課金でプラン購入中は復元ボタンを押せない
        return
      }

      // BEに連携するサブスクリプション購入時のUUIDをクリア
      mypagePageStore.purchaseSubscriptionUuid.value = ''
      // 購入したプラン情報をクリア
      purchasedPlanType.value = undefined

      const loader = loading.show()
      await restore()
      await fetchContractInfo()
      loader.hide()

      // TODO: 契約プラン情報が更新できなかった場合エラーモーダルを表示する
      // @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3806625934/API
      const isSuccess = true
      // isSuccess = xxx

      if (isSuccess) {
        router.push({ name: 'MypageTopPage', query: { modalId: 'completeRestore' } })
      } else {
        // マイページトップへ遷移
        router.replace({ name: 'MypageTopPage' })
      }
    }

    onMounted(async () => {
      state.actionButtonsHeight =
        actionButtons.value?.$el.getBoundingClientRect().height || state.actionButtonsHeight

      if (DeviceInfo.isiOS()) {
        /**
         * iosの場合、ストアから正しく購入ステータスを取得できないため、リストアして購入ステータスを取得できるようにする
         * マイページトップを表示した時に実行されるcordova-plugin-purchase初期化処理（initInAppPurchase）完了後に実行する必要がある
         */
        const loader = loading.show()
        await restore()
        loader.hide()
      }
      /**
       * 表示する商品情報データを設定
       * リストア後の最新の商品データを設定している
       */
      setDisplaySubscriptionData()

      await fetchContractInfo()
    })

    return {
      state,
      subscriptionItems,
      purchase,
      actionButtons,
      restorePurchase,
    }
  },
})
