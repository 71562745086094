import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { PlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { RoleType } from '@/store/stores/pageStore/common/RoleType'

/**
 * ユーザー名取得APIをPOSTした際のレスポンスデータの型
 */
export type UserRetrieveNameResponseType = {
  userId: string
  displayName?: string | null
  contract?: {
    planGroupId: PlanGroupType
  }
  userPicture: string | null
  roles: Array<RoleType> | null
  additionalData?: {
    userDisplayName: string | null
  }
}

/**
 * ユーザー名取得APIを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3532390426/API
 */
export default class UserRetrieveNameDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserRetrieveNameDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/users/retrieve_name'

  idAttr = '_id'

  /**
   * 取得するユーザーIDの配列
   */
  userIds?: Array<string> | null = null

  /**
   * ユーザー名取得APIのレスポンス
   */
  results?: Array<UserRetrieveNameResponseType> | null = null
}
