







































import { computed, defineComponent } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import useContractTerm from '@/components/hook/useContractTerm'
import useContract from '@/components/hook/useContract'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import I18n from '@/locales/I18n'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import AttentionMessageSection from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import useContractInfo from '@/store/hook/useContractInfo'
import useBillingInfo from '@/store/hook/useBillingInfo'

/**
 * 有料会員解約確認 ペイン
 */
export default defineComponent({
  name: 'CancelPaidPlanConfirmPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    AttentionMessageSection,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
    const { selectedPlanGroupId, immediatelyBillingSettleMonthly } = mypagePageStore
    const { getScheduledContractTerm } = useContractTerm()
    const { cancelPaidPlan } = useContract()
    const { getDisplayDate } = useDisplayDependingOnLang()
    const { fetchContractInfo, ownContractInfo } = useContractInfo()
    const { fetchProvisionalBillingInfo, ownMonthlyBillingInfo } = useBillingInfo()

    /**
     * 契約予定のプランの契約期間
     */
    const scheduledContractTerm = computed(() =>
      getScheduledContractTerm(
        effectiveContractPlan as ContractPlanDocument,
        effectiveOrgContract as ContractType,
        selectedPlanGroupId.value,
      ),
    )

    // 月額プランから無料プランへの切り替えの際に表示するメッセージ
    const attentionMessage =
      effectiveContractPlan?.planGroupId === 'monthlyPlan'
        ? {
            message: I18n.tc('CancelPaidPlan.ConfirmPage.attentionMessage'),
            note: null,
          }
        : null

    /** 無料プランに切り替わる日付 */
    const endDate = scheduledContractTerm.value.contractStartDate as number
    const freePlanChangeDate = getDisplayDate(endDate + 1)
    const attention = I18n.t('CancelPaidPlan.ConfirmPage.attention01', {
      date: freePlanChangeDate,
    }).toString()

    return {
      selectedPlanGroupId,
      scheduledContractTerm,
      attention,
      cancelPaidPlan,
      effectiveContractPlan,
      attentionMessage,
      immediatelyBillingSettleMonthly,
      ownContractInfo,
      fetchContractInfo,
      fetchProvisionalBillingInfo,
      ownMonthlyBillingInfo,
    }
  },
  methods: {
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      const loader = this.$loading.show()

      // 現在利用している契約情報を取得
      await this.fetchContractInfo()
      const { contractInfoId } = this.ownContractInfo

      /** 有料会員を解約 */
      const cancelPaidPlanResult = await this.cancelPaidPlan(
        this.selectedPlanGroupId,
        this.scheduledContractTerm,
        contractInfoId as string,
      )
      if (!cancelPaidPlanResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$t('CancelPaidPlan.ConfirmPage.errors.cancelPaidPanError.title') as string,
          message: this.$t(
            'CancelPaidPlan.ConfirmPage.errors.cancelPaidPanError.message',
          ) as string,
        })
        // 解約画面に遷移する
        this.$emit('handlerCancel')
        return
      }

      if (this.effectiveContractPlan?.planGroupId === 'monthlyPlan') {
        await this.fetchProvisionalBillingInfo()
        // 月契約から無料プランへの移行の際には、即時決済する
        const immediatelyBillingSettleMonthlyResult = await this.immediatelyBillingSettleMonthly(
          contractInfoId as string,
          String(this.ownMonthlyBillingInfo?.billingTargetYearMonth),
        )

        if (!immediatelyBillingSettleMonthlyResult.isSuccess) {
          loader.hide()
          // 即時決済に失敗した際の処理
          await MessageDialogStore.value.open({
            title: this.$t(
              'CancelPaidPlan.ConfirmPage.errors.immediatelyBillingSettleMonthlyError.title',
            ) as string,
            message: this.$t(
              'CancelPaidPlan.ConfirmPage.errors.immediatelyBillingSettleMonthlyError.message',
            ) as string,
          })
        }
      }

      /** 組織情報、契約情報を取得を取得し直す */
      await ContractInfoStore.value.fetchUserContractInfo()

      loader.hide()

      this.$emit('handlerSubmit')
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
