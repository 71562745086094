var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "details-data-list-parts" },
    _vm._l(_vm.detailsListItems, function (v, i) {
      return _c(
        "li",
        { key: i, staticClass: "details-data-list-parts__list" },
        [
          v.link
            ? _c(
                "a",
                {
                  staticClass: "details-data-list-parts__item",
                  attrs: { href: v.link, target: "_blank" },
                },
                [
                  _c("span", {
                    staticClass: "details-data-list-parts__label",
                    domProps: { innerHTML: _vm._s(v.name) },
                  }),
                  _c("img", {
                    staticClass: "details-data-list-parts__icon",
                    attrs: {
                      src: require("@/assets/img/icon/icon_external_link_outline.svg"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "details-data-list-parts__item details-data-list-parts__item--has-text",
                },
                [
                  _c("span", {
                    staticClass: "details-data-list-parts__label",
                    domProps: { innerHTML: _vm._s(v.name) },
                  }),
                  _c("span", { staticClass: "details-data-list-parts__data" }, [
                    _vm._v(_vm._s(v.text)),
                  ]),
                ]
              ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }