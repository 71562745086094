






















































import { defineComponent, PropType } from '@vue/composition-api'
import { LoginType } from '@/components/LoginPage/LoginPane.vue'

/**
 * ログイン画面のヘッダーセクション
 */
export default defineComponent({
  name: 'LoginHeaderSection',
  props: {
    /**
     * ログインタイプ指定
     */
    loginType: {
      type: String as PropType<LoginType>,
      default: 'login',
    },
    pageTitle: {
      type: String,
      default: '',
    },
  },
})
