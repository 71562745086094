














import { defineComponent, onUnmounted } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * ミッションページ
 */
export default defineComponent({
  name: 'MissionTopPage',
  components: { HeaderPane },
  setup() {
    const missionPageStore = StoreUtil.useStore('MissionPageStore')
    const { clearMissionPageData } = missionPageStore
    onUnmounted(() => {
      clearMissionPageData()
    })
  },
})
