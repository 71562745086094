import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoPlanPriceDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanPriceDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * 契約プラン料金データを操作するための処理を提供する。
 */
export default function useContractInfoPlanPrice() {
  // Collection modules
  const contractInfoPlanPriceCollectionModule = CollectionModule.createStore(
    ContractInfoPlanPriceDocument,
  )

  /**
   * 年額プランの基本プランの料金を取得する。
   * @return APIレスポンス
   */
  const fetchAnnualPlanPrice = async (
    contractInfoId: string,
    requestData: ContractInfoPlanPriceDocument,
  ) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/plans/yearly/price`,
    }
    const response = await contractInfoPlanPriceCollectionModule.save(requestData, options)
    // 取得してきたデータをCollectionModuleに保存する
    contractInfoPlanPriceCollectionModule.setData([response.data])
    return response
  }

  /**
   * 月額プランの基本プランの料金を取得する。
   * @return APIレスポンス
   */
  const fetchMonthlyPlanPrice = async (
    contractInfoId: string,
    requestData: ContractInfoPlanPriceDocument,
  ) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/plans/monthly/price`,
    }
    const response = await contractInfoPlanPriceCollectionModule.save(requestData, options)
    // 取得してきたデータをCollectionModuleに保存する
    contractInfoPlanPriceCollectionModule.setData([response.data])
    return response
  }

  /**
   * 契約プラン料金情報
   */
  const contractInfoPlanPrice = computed(() => contractInfoPlanPriceCollectionModule.data[0])

  /**
   * 取得した契約プラン料金情報をクリアする
   */
  const clearContractInfoPlanPrices = () => {
    contractInfoPlanPriceCollectionModule.clearData()
  }

  return {
    fetchAnnualPlanPrice,
    fetchMonthlyPlanPrice,
    contractInfoPlanPrice,
    clearContractInfoPlanPrices,
  }
}
