var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credit-card-confirm-section" },
    [
      _c("fieldset-confirm-parts", {
        staticClass:
          "credit-card-confirm-section__number credit-card-confirm-section__input",
        attrs: {
          label: _vm.$tc("CreditCardPayment.inputLabel.number"),
          value: _vm.creditCard.number,
        },
      }),
      _c("fieldset-confirm-parts", {
        staticClass:
          "credit-card-confirm-section__name credit-card-confirm-section__input",
        attrs: {
          label: _vm.$tc("CreditCardPayment.inputLabel.name"),
          value: _vm.creditCard.name,
        },
      }),
      _c("fieldset-confirm-parts", {
        staticClass:
          "credit-card-confirm-section__period credit-card-confirm-section__input",
        attrs: {
          label: _vm.$tc("CreditCardPayment.inputLabel.period"),
          value: _vm.creditCard.period,
        },
      }),
      _c("fieldset-confirm-parts", {
        staticClass:
          "credit-card-confirm-section__cvc credit-card-confirm-section__input",
        attrs: {
          label: _vm.$tc("CreditCardPayment.inputLabel.cvc"),
          value: _vm.creditCard.cvc,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }