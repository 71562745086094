var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sub-video-pane" }, [
    _c("video", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.videoInvalid,
          expression: "!videoInvalid",
        },
      ],
      staticClass:
        "sub-video-pane__video video-js vjs-live vjs-liveui vjs-default-skin",
      attrs: {
        id: "sfgo-sub-video",
        crossorigin: "use-credentials",
        playsinline: "",
      },
    }),
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.videoInvalid,
          expression: "videoInvalid",
        },
      ],
      staticClass: "sub-video-pane__img",
      attrs: {
        src: require("@/assets/img/RaceVideoPage/noVideo.png"),
        alt: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }