var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ranking-flag-parts",
      class: "ranking-flag-parts--" + _vm.screenOrientationType,
    },
    [
      _vm.value === "G"
        ? _c(
            "svg",
            {
              staticClass: "ranking-flag-parts__flag",
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M3 1.5V15H2.25V16.5H5.25V15H4.5V11.25H14.25C14.4489 11.25 14.6397 11.171 14.7803 11.0303C14.921 10.8897 15 10.6989 15 10.5V3C15 2.80109 14.921 2.61032 14.7803 2.46967C14.6397 2.32902 14.4489 2.25 14.25 2.25H4.5V1.5H3Z",
                  fill: "#00DBA6",
                },
              }),
            ]
          )
        : _vm.value === "Y"
        ? _c(
            "svg",
            {
              staticClass: "ranking-flag-parts__flag",
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M3 1.5V15H2.25V16.5H5.25V15H4.5V11.25H14.25C14.4489 11.25 14.6397 11.171 14.7803 11.0303C14.921 10.8897 15 10.6989 15 10.5V3C15 2.80109 14.921 2.61032 14.7803 2.46967C14.6397 2.32902 14.4489 2.25 14.25 2.25H4.5V1.5H3Z",
                  fill: "#F2C94C",
                },
              }),
            ]
          )
        : _vm.value === "R"
        ? _c(
            "svg",
            {
              staticClass: "ranking-flag-parts__flag",
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M3 1.5V15H2.25V16.5H5.25V15H4.5V11.25H14.25C14.4489 11.25 14.6397 11.171 14.7803 11.0303C14.921 10.8897 15 10.6989 15 10.5V3C15 2.80109 14.921 2.61032 14.7803 2.46967C14.6397 2.32902 14.4489 2.25 14.25 2.25H4.5V1.5H3Z",
                  fill: "#FF4949",
                },
              }),
            ]
          )
        : _vm.value === "F"
        ? _c(
            "svg",
            {
              staticClass: "ranking-flag-parts__flag",
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M3 1.5V15H2.25V16.5H5.25V15H4.5V11.25V9.75H6V11.25H7.5V9.75H9V11.25H10.5V9.75H12V11.25H13.5V9.75H15V8.25H13.5V6.75H15V5.25H13.5V3.75H15V2.25H13.5V3.75H12V2.25H10.5V3.75H9V2.25H7.5V3.75H6V2.25H4.5V1.5H3ZM6 3.75V5.25H7.5V3.75H9V5.25H10.5V3.75H12V5.25H13.5V6.75H12V8.25H13.5V9.75H12V8.25H10.5V9.75H9V8.25H7.5V9.75H6V8.25H4.5V6.75H6V5.25H4.5V3.75H6Z",
                  fill: "white",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M6 6.75H7.5V8.25H6V6.75ZM9 6.75H10.5V8.25H9V6.75ZM7.5 5.25H9V6.75H7.5V5.25ZM10.5 5.25H12V6.75H10.5V5.25Z",
                  fill: "white",
                },
              }),
            ]
          )
        : _vm.value === "SC"
        ? _c("i", { staticClass: "ranking-flag-parts__flag--safety" }, [
            _vm._v("SC"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }