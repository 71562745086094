







import { defineComponent } from '@vue/composition-api'
import DetailsDataListParts from '@/components/MypageDetailsSettingPage/MypageDetailsSettingPane/parts/DetailsDataListParts.vue'

/**
 * マイページ: 詳細設定 データ セクション
 */
export default defineComponent({
  name: 'MypageDetailsSettingSection',
  components: {
    DetailsDataListParts,
  },
  props: {
    /**
     * セクションタイトル
     */
    title: {
      type: String,
    },
    /**
     * データリスト
     */
    detailsListItems: {
      type: Array,
      required: true,
    },
  },
  methods: {},
})
