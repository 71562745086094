






























































import { defineComponent } from '@vue/composition-api'
import PageTitleSection from '@/components/common/Header/HeaderPane/PageTitleSection.vue'
import GlobalNavigationSection from '@/components/pc/common/GlobalNavigation/GlobalNavigationSection.vue'

/**
 * 共通ヘッダーペイン
 */
export default defineComponent({
  name: 'HeaderPane',
  components: {
    PageTitleSection,
    GlobalNavigationSection,
  },
  props: {
    /**
     * ページタイトル
     */
    pageTitle: {
      type: String,
      default: '',
    },
    /**
     * カラータイプ
     */
    colorType: {
      type: String,
      default: '',
    },
    /**
     * ヘッダーグローバルナビ有無
     */
    hasHeaderNav: {
      type: Boolean,
      default: false,
    },
    /**
     * ナビゲーションカレント
     */
    current: {
      type: String,
      default: 'race',
    },
  },
})
