var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-user-init-pane",
      on: { click: _vm.closeBirthdayOption },
    },
    [
      _c("user-init-section", {
        attrs: {
          mailAddress: _vm.mailAddress,
          lang: _vm.lang,
          userInfo: _vm.editUserInfo,
          favoritePlayerName: _vm.favoritePlayer
            ? _vm.favoritePlayer.getPlayerName()[_vm.$i18n.locale] || ""
            : "",
          favoritePlayerImg: _vm.playerPictureUrl(_vm.favoritePlayer),
          favoriteTeamName: _vm.favoriteTeam
            ? _vm.getTeamName(_vm.favoriteTeam)[_vm.$i18n.locale] || ""
            : "",
          favoriteTeamImg: _vm.teamLogoUrl(_vm.favoriteTeam),
          openBirthdayMenuId: _vm.openBirthdayMenuId,
        },
        on: {
          onChangeEditUserInfo: _vm.setEditUserInfo,
          onClickBirthday: _vm.handleBirthdayClicked,
          onClickCloseBirthday: _vm.closeBirthdayOption,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }