




























import { defineComponent, PropType } from '@vue/composition-api'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import I18n from '@/locales/I18n'

/**
 * クーポン用プランカード
 */
export default defineComponent({
  name: 'CouponPlanCardParts',
  props: {
    /**
     * 表示するプラン
     */
    plan: {
      type: Object as PropType<ContractPlanDocument>,
      required: true,
    },
    /**
     * お得額
     */
    bargainPriced: {
      type: String,
      default: '',
    },
    /**
     * 確認画面かどうか
     */
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    /**
     * ワイタイムパスかどうか
     */
    isOneTimePassUsed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /** 金額の単位（現状は円のみ） */
    const planPriceUnit = I18n.tc('SignupPage.SelectPlanPage.plan.priceUnit')

    return {
      planPriceUnit,
    }
  },
})
