var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-today-safe-modal-section" }, [
    _c(
      "div",
      { staticClass: "mypage-ticket-today-safe-modal-section__block" },
      [
        _c(
          "p",
          { staticClass: "mypage-ticket-today-safe-modal-section__title" },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc("MypagePage.MypageTicketTodayPage.safeModal.title")
                ) +
                " "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mypage-ticket-today-safe-modal-section__alert" },
          [
            _c(
              "p",
              {
                staticClass:
                  "mypage-ticket-today-safe-modal-section__alert-message",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc(
                        "MypagePage.MypageTicketTodayPage.safeModal.message1"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mypage-ticket-today-safe-modal-section__alert-message",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc(
                        "MypagePage.MypageTicketTodayPage.safeModal.message2"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ]
        ),
        _c(
          "ul",
          { staticClass: "mypage-ticket-today-safe-modal-section__buttons" },
          [
            _c(
              "li",
              { staticClass: "mypage-ticket-today-safe-modal-section__button" },
              [
                _c("atom-button", {
                  attrs: { linkText: _vm.$tc("common.yes") },
                  on: { onClick: _vm.handleSubmitClicked },
                }),
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "mypage-ticket-today-safe-modal-section__button" },
              [
                _c("atom-button", {
                  attrs: {
                    linkText: _vm.$tc("common.no"),
                    colorType: "tertiary",
                  },
                  on: { onClick: _vm.handleCloseClicked },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "mypage-ticket-today-safe-modal-section__button-close",
            on: { click: _vm.handleCloseClicked },
          },
          [
            _c(
              "span",
              {
                staticClass:
                  "mypage-ticket-today-safe-modal-section__button-close-icon",
              },
              [_c("mypage-ticket-close-icon-parts")],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }