var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.70703 2.58563L15.707 10.5856C16.4881 11.3667 16.4881 12.633 15.707 13.4141L7.70703 21.4141L7.70703 18.5856L14.2928 11.9998L7.70703 5.41406L7.70703 2.58563Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }