














import { defineComponent, onBeforeMount, Ref, ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CompletePane from '@/components/pc/MyPage/CompletePane/CompletePane.vue'
import { AttentionMessageData } from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import { PaymentScheduleData } from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import StoreUtil from '@/store/StoreUtil'
import usePaymentScheduleOnRegister from '@/components/pc/MyPage/CompletePane/hook/usePaymentScheduleOnRegister'
import I18n from '@/locales/I18n'
import usePaymentScheduleOnRegisterCoupon from '@/components/pc/MyPage/CompletePane/hook/usePaymentScheduleOnRegisterCoupon'

/**
 * 有料会員変更 完了ページ
 */
export default defineComponent({
  name: 'PaidMembershipChangeCompletePage',
  components: {
    SubHeaderSection,
    CompletePane,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { effectiveContractPlan } = contractInfoStore
    const { getPaymentScheduleOnRegister } = usePaymentScheduleOnRegister()
    const { getPaymentScheduleOnRegisterCoupon, getDataRequiredForAttention } =
      usePaymentScheduleOnRegisterCoupon()

    /** 注意事項テキスト */
    const attentionMessage: Ref<AttentionMessageData | null> = ref(null)
    /** 今後の請求情報 */
    const paymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)
    /** クーポン利用してるかのフラグ */
    const isCouponUsed: Ref<boolean> = ref(false)

    onBeforeMount(async () => {
      if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
        // 月額プランを登録した場合、以下、請求予定に関する情報を表示する

        /** 請求予定カードに表示する情報を取得 */
        paymentSchedule.value = await getPaymentScheduleOnRegister()

        /** 注意事項テキストをセット */
        attentionMessage.value = {
          message: I18n.tc('SignupPage.SignupCompletePage.monthlyPlan.attentionMessage'),
          note: null,
        }
      }

      if (
        effectiveContractPlan?.planGroupId === 'limitedTimePaidPlan' ||
        effectiveContractPlan?.planGroupId === 'limitedTimePaidPlanForAnnual'
      ) {
        // クーポンで月額、年額プランを登録した場合、以下、請求予定に関する情報を表示する

        /** 請求予定カードに表示する情報を取得 */
        paymentSchedule.value = await getPaymentScheduleOnRegisterCoupon()
        /** 注意メッセージに必要な情報を取得 */
        const requiredForAttention = getDataRequiredForAttention()

        /** クーポン期限切れ前に通知する設定をしてるかのフラグ */
        const isNeedToNotify = signupPageStore.coupons.value[0]?.notifyBeforeExpires
        const attentionMessageNote = isNeedToNotify
          ? I18n.tc(
              `SignupPage.SignupCompletePage.${effectiveContractPlan?.planGroupId}.attentionNote`,
            )
          : null

        /** 注意事項テキストをセット */
        attentionMessage.value = {
          message: I18n.t(
            `SignupPage.SignupCompletePage.${effectiveContractPlan?.planGroupId}.attentionMessage`,
            {
              changeDate: requiredForAttention.changeDate,
              plan: requiredForAttention.plan,
            },
          ).toString(),
          note: attentionMessageNote,
        }
        /** クーポン利用かのフラグをセット */
        isCouponUsed.value = true
      }
    })

    return {
      attentionMessage,
      paymentSchedule,
      isCouponUsed,
    }
  },
})
