var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "award-header-section" }, [
    _c("figure", { staticClass: "award-header-section__logo" }, [
      _c("img", { attrs: { src: _vm.headerLogoPath, alt: "" } }),
    ]),
    _c("p", { staticClass: "award-header-section__link" }, [
      _c("a", {
        attrs: { href: _vm.aboutAwardUrl, target: "_blank" },
        domProps: {
          textContent: _vm._s(_vm.$i18n.tc("LinkPage.AwardPage.about")),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }