var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "switch-edit-mode-button-parts",
      on: { click: _vm.emitClick },
    },
    [
      _c("img", {
        attrs: {
          src:
            _vm.type === "edit"
              ? require("@/assets/img/icon/icon_edit.svg")
              : require("@/assets/img/icon/icon_close_white_18.svg"),
          width: "18",
          height: "18",
          alt: "",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }