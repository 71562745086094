var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "mypage-link-list-parts" },
    [
      _vm._l(_vm.links, function (v, i) {
        return [
          v.modalId && v.isDisplay
            ? _c(
                "li",
                { key: i, staticClass: "mypage-link-list-parts__item" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "mypage-link-list-parts__link",
                      class: {
                        "mypage-link-list-parts__link--disabled": v.isDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleOpenModalClicked(v.modalId)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(v.name))]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/icon/icon_chevron_right_24.svg"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : v.isDisplay
            ? _c(
                "li",
                { key: i, staticClass: "mypage-link-list-parts__item" },
                [
                  !v.blank
                    ? _c(
                        "router-link",
                        {
                          staticClass: "mypage-link-list-parts__link",
                          class: {
                            "mypage-link-list-parts__link--disabled":
                              v.isDisabled,
                          },
                          attrs: { to: v.link },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(v.name))]),
                          v.subName
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mypage-link-list-parts__link-sub-name",
                                  class: {
                                    "mypage-link-list-parts__link-sub-name-error":
                                      v.hasError,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mypage-link-list-parts__link-div",
                                    },
                                    [_vm._v("：")]
                                  ),
                                  _c("span", [_vm._v(_vm._s(v.subName))]),
                                ]
                              )
                            : _vm._e(),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/icon/icon_chevron_right_24.svg"),
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "mypage-link-list-parts__link",
                          attrs: { href: v.link, target: "_blank" },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(v.name))]),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/icon/icon_external_link_outline.svg"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }