var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gps-mode-selector-section",
      class: "gps-mode-selector-section--" + _vm.screenOrientationType,
    },
    [
      _c(
        "button",
        {
          staticClass: "gps-mode-selector-section__button",
          class: {
            "gps-mode-selector-section__button--current":
              _vm.gpsViewMode === "birdsEye",
          },
          on: {
            click: function ($event) {
              return _vm.onclickSelector("birdsEye")
            },
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("RaceVideoPage.GPS.selector.birdsEye"))),
          ]),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "gps-mode-selector-section__button",
          class: {
            "gps-mode-selector-section__button--current":
              _vm.gpsViewMode === "battle",
          },
          on: {
            click: function ($event) {
              return _vm.onclickSelector("battle")
            },
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("RaceVideoPage.GPS.selector.battle"))),
          ]),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "gps-mode-selector-section__button",
          class: {
            "gps-mode-selector-section__button--current":
              _vm.gpsViewMode === "focus",
          },
          on: {
            click: function ($event) {
              return _vm.onclickSelector("focus")
            },
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("RaceVideoPage.GPS.selector.focus"))),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }