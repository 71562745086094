






































































import { defineComponent } from '@vue/composition-api'

/**
 * レース動画再生画面 ランキング表示 Flag状態のコンポーネント
 */
export default defineComponent({
  name: 'RankingFlagParts',
  components: {},
  props: {
    /**
     * バリュー値
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
})
