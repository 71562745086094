var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ticket-past-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("MypagePage.MypageTicketPastPage.title"),
          backLink: "/mypage/ticket-list",
          execTransitionEventParent: true,
        },
        on: { onClick: _vm.moveToMypageTicketListPage },
      }),
      _c(
        "div",
        { staticClass: "mypage-ticket-past-page__body" },
        [_c("mypage-ticket-past-pane")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }