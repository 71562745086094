var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "scene-list-card-parts",
      class:
        "scene-list-card-parts--role-" +
        _vm.card.highlightComment.createCommentUser.userType,
      attrs: { to: _vm.card.canShow ? _vm.card.link : "" },
      nativeOn: {
        click: function ($event) {
          return _vm.onClickNewHighlightCard.apply(null, arguments)
        },
      },
    },
    [
      _c("figure", { staticClass: "scene-list-card-parts__scene-thumbnail" }, [
        _c("img", {
          staticClass: "scene-list-card-parts__scene-image",
          attrs: { src: _vm.card.highlight.thumbnailPath, alt: "" },
        }),
        _c("figcaption", {
          staticClass: "scene-list-card-parts__scene-title",
          domProps: {
            textContent: _vm._s(
              "created by " + _vm.card.highlight.createdUserName
            ),
          },
        }),
        !_vm.card.canShow
          ? _c(
              "div",
              { staticClass: "scene-list-card-parts__lock" },
              [
                _c("home-lock-icon", {
                  staticClass: "scene-list-card-parts__lock-icon",
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "scene-list-card-parts__content" }, [
        _c(
          "div",
          { staticClass: "scene-list-card-parts__content-header" },
          [
            _c("figure", { staticClass: "scene-list-card-parts__user-image" }, [
              _c("img", {
                attrs: {
                  src: _vm.card.highlightComment.createCommentUser.userPicture,
                  alt: "",
                },
              }),
            ]),
            _c("scene-card-user-images-parts", {
              staticClass: "scene-list-card-parts__comment-users",
              attrs: {
                otherCommentUsers: _vm.card.highlightComment.otherCommentUsers,
                commentCount: _vm.card.highlightComment.commentCount,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "scene-list-card-parts__content-body" }, [
          _c("p", {
            staticClass: "scene-list-card-parts__user-name",
            domProps: {
              textContent: _vm._s(
                _vm.card.highlightComment.createCommentUser.userDisplayName
              ),
            },
          }),
          _c("p", {
            staticClass: "scene-list-card-parts__comment",
            domProps: { innerHTML: _vm._s(_vm.card.highlightComment.comment) },
          }),
          _c("p", { staticClass: "scene-list-card-parts__footer" }, [
            _c(
              "span",
              { staticClass: "scene-list-card-parts__footer__lap-pre" },
              [_vm._v("Lap")]
            ),
            _c("span", {
              staticClass: "scene-list-card-parts__footer__lap-count",
              domProps: { textContent: _vm._s(_vm.card.highlight.lap) },
            }),
            _c(
              "span",
              { staticClass: "scene-list-card-parts__footer__lap-sector" },
              [_vm._v("-" + _vm._s(_vm.card.highlight.sector))]
            ),
            _c(
              "span",
              { staticClass: "scene-list-card-parts__footer__round" },
              [_vm._v(" " + _vm._s(_vm.card.highlight.round) + " ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }