import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 空のGPSデータ
 */
const emptyGpsData = {
  carNo: '', // 車体番号
  gps_lat: 0, // GPS緯度
  gps_lng: 0, // GPS経度
  otsEnabled: false, // オーバーテイク有効化
  createdDate: 0, // 作成日時
}

/**
 * 画面表示用のGPSデータモデルの型
 */
export type GpsDataType = typeof emptyGpsData

/**
 * GPS情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3277357066/GPS+API
 */
export default class GpsDocument extends DocumentWrapper {
  constructor(initProps?: Partial<GpsDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    const gpsInitProps = initProps
    this.mergeToThis(gpsInitProps)
  }

  _path = 'data/record/gps'

  idAttr = 'raceDate'

  /* eslint-disable camelcase */
  raceDate: string | null = null

  type: string | null = null

  carNo: string | null = null

  lapctr: string | null = null

  distlap: string | null = null

  Accel_Lat_G: string | null = null

  Accel_Long_G: string | null = null

  Accel_Vert_G: string | null = null

  Yaw_Rate: string | null = null

  GPS_Late: string | null = null

  GPS_Long: string | null = null

  GPS_Altitude: string | null = null

  GPS_Angle: string | null = null

  P2P_Event: string | null = null

  P2P_disable_Time: string | null = null

  Push_to_Pass_Switch_On: string | null = null

  createdDate: number | null = null

  sendDate: number | null = null
  /* eslint-enable camelcase */

  /**
   * このGPSデータを画面に表示する際に利用可能なデータに変換して返す。
   */
  get gpsData(): GpsDataType {
    return {
      carNo: this.carNo ?? '', // 車体番号
      gps_lat: Number(this.GPS_Late) || 0, // GPS緯度
      gps_lng: Number(this.GPS_Long) || 0, // GPS経度
      otsEnabled: this.Push_to_Pass_Switch_On === '1', // オーバーテイク有効化
      createdDate: Number(this.createdDate), // 作成日時
    }
  }

  /**
   * GPSデータが存在しない場合に利用される空データ
   */
  static get EMPTY_GPS_DATA(): GpsDataType {
    return emptyGpsData
  }
}
