var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-honeycomb",
      class: { "is-completed": _vm.mission.hasCompleted },
    },
    [
      _c(
        "ul",
        { staticClass: "mission-honeycomb__list" },
        _vm._l(_vm.mission.achievementCondition, function (condition, index) {
          return _c(
            "li",
            { key: index, staticClass: "mission-honeycomb__item" },
            [
              _c("mission-hexagon-circuit-parts", {
                attrs: { mission: _vm.mission, condition: condition },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }