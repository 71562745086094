import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import TelemetryDocument, {
  TelemetryDataType,
} from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import MathUtil from '@/util/MathUtil'
import useDynamoDB from '@/store/hook/useDynamoDB'

/**
 * テレメトリーデータを操作するための処理を取得する。
 */
export default function useTelemetryData() {
  // Collection modules
  const telemetryCollectionModule = CollectionModule.createStore(TelemetryDocument)
  /**
   * 作成日時毎のテレメトリーデータを保持するデータの型
   * 作成日時をキーにして、テレメトリーデータを値として保持する。
   */
  type TelemetryHashedByCreatedDateType = Record<number, TelemetryDocument>

  /**
   * 作成日時毎のテレメトリーデータ
   */
  let telemetryHashedByCreatedDateMap = {}

  const { searchDateRangeData } = useDynamoDB()

  /**
   * 直前に表示していたテレメトリーデータを保持する。
   */
  const previousShowedTelemetry = {
    /** 直前に表示していたテレメトリーデータ */
    telemetry: null as TelemetryDataType | null,
    /** 直前にテレメトリーデータを表示していた動画の再生時間 */
    movieTime: 0,
  }

  // 最後にテレメトリーデータの取得を行なった時間
  let lastTelemetryFetch = 0

  /**
   * 指定したレースの指定した期間のテレメトリー情報を取得する。
   * @param race レース
   * @param player ドライバー
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchTelemetry = async (
    race: RaceDocument,
    player: PlayerDocument,
    fromDate: number,
    toDate: number,
  ) => {
    const result = await searchDateRangeData({
      tableName: 'telemetry-data',
      partitionKey: 'date_carNo',
      partitionKeyValue: `${dayjs(race.scheduleDate).utc().format('YYYY-MM-DD')}_#${
        player.squadNumber
      }`,
      sortKey: 'createdDate',
      from: fromDate,
      to: toDate,
    })
    telemetryCollectionModule.data = result as Array<TelemetryDocument>
  }

  /**
   * 取得したテレメトリー情報。
   * 作成日時でハッシュされたテレメトリーデータを返す。
   *
   * 作成日時をキーにして、テレメトリーデータが値に設定されたMapオブジェクトを返す。
   * その際、作成日時は、100の位で四捨五入された値が設定される。
   * テレメトリーデータは1秒に20回の頻度で登録されるが、性能を考慮して、1秒につき10個のデータに間引いて保持する。
   * このマップから日時を指定してテレメトリーデータを取得する際は、キーに指定する時間は100の桁で丸める必要がある。
   */
  const telemetryHashedByCreatedDate = computed(() =>
    telemetryCollectionModule.data.reduce<TelemetryHashedByCreatedDateType>((map, telemetry) => {
      if (telemetry.createdDate) {
        const roundedDateTime = MathUtil.round(telemetry.createdDate, 100)
        const hashmap = map
        hashmap[roundedDateTime] = telemetry
      }
      return map
    }, telemetryHashedByCreatedDateMap),
  )

  /**
   * 取得したテレメトリー情報をクリアする
   */
  const clearTelemetries = () => {
    telemetryCollectionModule.clearData()
    telemetryHashedByCreatedDateMap = {}
    lastTelemetryFetch = 0
    previousShowedTelemetry.movieTime = 0
    previousShowedTelemetry.telemetry = null
  }

  return {
    fetchTelemetry,
    telemetryHashedByCreatedDate,
    clearTelemetries,
    previousShowedTelemetry,
    lastTelemetryFetch,
  }
}
