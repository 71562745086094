var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.labelSet
    ? _c(
        "div",
        {
          staticClass: "mission-card-process",
          class: "color-type-" + _vm.mission.colorType,
        },
        [
          _c("p", { staticClass: "mission-card-process__title" }, [
            _vm._v(_vm._s(_vm.labelSet.title)),
          ]),
          _c(
            "div",
            { staticClass: "mission-card-process__main" },
            [
              _vm.mission.achievementCountDispType === "countUp"
                ? [
                    _c("p", { staticClass: "mission-card-process__rest" }, [
                      _c("span", [_vm._v(_vm._s(_vm.labelSet.restPre))]),
                      _c(
                        "span",
                        { staticClass: "mission-card-process__rest-count" },
                        [_vm._v(_vm._s(_vm.mission.achievedMissionsCount))]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.labelSet.restUnit))]),
                    ]),
                  ]
                : [
                    _c("p", { staticClass: "mission-card-process__rest" }, [
                      _c("span", [_vm._v(_vm._s(_vm.labelSet.restPre))]),
                      _c(
                        "span",
                        { staticClass: "mission-card-process__rest-count" },
                        [
                          _vm._v(
                            _vm._s(_vm.mission.leftCountUntilNextAchievement)
                          ),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.labelSet.restUnit))]),
                    ]),
                    _vm.mission.achievedMissionsCount
                      ? _c(
                          "p",
                          { staticClass: "mission-card-process__total" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.labelSet.currentPre)),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "mission-card-process__total-count",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.mission.achievedMissionsCount)
                                ),
                              ]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.labelSet.currentUnit)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }