var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-favorite-team-link-section" }, [
    _c("h3", { staticClass: "mypage-favorite-team-link-section__title" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("MypagePage.MypageTopPage.favoriteTeam")) + " "
      ),
    ]),
    _c(
      "div",
      { staticClass: "mypage-favorite-team-link-section__content" },
      [
        _c(
          "div",
          { staticClass: "mypage-favorite-team-link-section__selected" },
          [_c("team-card-parts", { attrs: { name: _vm.name, img: _vm.img } })],
          1
        ),
        _vm.enabledSelect
          ? _c("atom-router-button", {
              staticClass: "mypage-favorite-team-link-section__link",
              attrs: {
                to: "/mypage/favorite-team",
                linkText: _vm.$t("MypagePage.MypageTopPage.favoriteTeamChange"),
                colorType: "tertiary",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }