var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "complete-page" },
    [
      _c("signup-complete-pane", {
        attrs: {
          isCouponUsed: _vm.isCouponUsed,
          attentionMessage: _vm.attentionMessage || undefined,
          paymentSchedule: _vm.paymentSchedule || undefined,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }