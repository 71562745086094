var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paid-membership-change-confirm-page" },
    [
      _c("paid-membership-change-confirm-pane", {
        on: {
          handlerSubmit: _vm.moveToPaidMembershipChangeComplete,
          handlerCancel: _vm.handlerCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }