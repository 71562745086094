















import { defineComponent, PropType } from '@vue/composition-api'
import SnsPostItemContentsParts from '@/components/HomePage/HomePane/parts/SnsPostItemContentsParts.vue'
import OfficialPostItemParts from '@/components/HomePage/HomePane/parts/OfficialPostItemContentsParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'
import HighlightsPostItemContentsParts from '@/components/HomePage/HomePane/parts/HighlightsPostItemContentsParts.vue'

/**
 * HOME SNS投稿コンテンツ
 */
export default defineComponent({
  name: 'SnsPostItemParts',
  components: {
    HighlightsPostItemContentsParts,
    SnsPostItemContentsParts,
    OfficialPostItemParts,
  },
  props: {
    snsPost: {
      type: Object as PropType<SnsPostData>,
      required: true,
    },
  },
  methods: {
    updateSnsContentsData(src: string) {
      this.$emit('updateSnsContentsData', src)
    },
  },
})
