
























import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageUserCompletePane from '@/components/MypageUserCompletePage/MypageUserCompletePane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'

/**
 * マイページ: 会員情報変更完了ページ
 */
export default defineComponent({
  name: 'MypageUserCompletePage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageUserCompletePane,
    ActionButtonsSection,
    AtomRouterButton,
  },
})
