












import { defineComponent } from '@vue/composition-api'

/**
 * マイページ: ワンタイムパス: 登録完了ペイン
 */
export default defineComponent({
  name: 'OneTimePassCompletePane',
})
