















import { defineComponent, PropType } from '@vue/composition-api'
import CommonRacePointsCardParts from '@/components/RaceListPage/RaceListPane/parts/CommonRacePointsCardParts.vue'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import RadioListItemParts from '@/components/RaceVideoPage/RaceVideoRadioPane/parts/RadioListItemParts.vue'
import { RacePointRadioDataType } from '@/store/stores/pageStore/TopPage/TopPageStore'

/**
 * レース一覧画面: レース ポイント ラジオカードコンポーネント
 */
export default defineComponent({
  name: 'RacePointRadioCardParts',
  components: {
    CommonRacePointsCardParts,
    RadioListItemParts,
  },
  props: {
    /**
     * Race Point Radioデータ
     */
    pointsCardData: {
      type: Object as PropType<RacePointRadioDataType>,
      required: true,
    },
  },
  methods: {
    /**
     * 無線 シングル再生
     * 選択したコンポーネント以外の再生状態をOFFにする
     * @event radioPlay
     */
    playRadioAudio(radioData: RadioDataDocument) {
      this.$emit('radioPlay', radioData)
    },
  },
})
