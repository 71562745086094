var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portrait-video-controller-parts" }, [
    _c(
      "button",
      {
        staticClass:
          "portrait-video-controller-parts__button portrait-video-controller-parts__button__prev30s",
        on: { click: _vm.prevVideo30s },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/img/icon/video_controller_prev30s.svg"),
            alt: "",
          },
        }),
      ]
    ),
    _vm.videoPausedStatus
      ? _c(
          "button",
          {
            staticClass:
              "portrait-video-controller-parts__button portrait-video-controller-parts__button__play",
            on: { click: _vm.playVideo },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/video_controller_play.svg"),
                alt: "",
              },
            }),
          ]
        )
      : _vm._e(),
    !_vm.videoPausedStatus
      ? _c(
          "button",
          {
            staticClass:
              "portrait-video-controller-parts__button portrait-video-controller-parts__button__pause",
            on: { click: _vm.pauseVideo },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/video_controller_pause.svg"),
                alt: "",
              },
            }),
          ]
        )
      : _vm._e(),
    _c(
      "button",
      {
        staticClass:
          "portrait-video-controller-parts__button portrait-video-controller-parts__button__next30s",
        on: { click: _vm.nextVideo30s },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/img/icon/video_controller_next30s.svg"),
            alt: "",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }