






























import { defineComponent, ref } from '@vue/composition-api'

/**
 * レース一覧画面: Race Information 開閉ボタン
 */
export default defineComponent({
  name: 'InformationToggleButtonParts',
  props: {
    /**
     * Information
     */
    hasCurrentRaceInformation: {
      type: Boolean,
      default: true,
    },
    /**
     * Information 部開閉フラグ
     */
    showInformation: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const stopIconBounds = ref<boolean>(false)
    return {
      stopIconBounds,
    }
  },
  methods: {
    /**
     * Click通知
     */
    emitClick(): void {
      this.$emit('click')

      if (!this.stopIconBounds) {
        this.stopIconBounds = true
      }
    },
  },
})
