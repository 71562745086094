











import { defineComponent, inject } from '@vue/composition-api'
import type VueRouter from 'vue-router'
import MypageUserEditHeaderSection from '@/components/MypageUserEditPage/MypageUserEditPane/MypageUserEditHeaderSection.vue'
import MypageUserInitPane from '@/components/MypageUserInitPage/MypageUserInitPane.vue'
import UserStore from '@/store/stores/pageStore/common/UserStore'

/**
 * マイページ: 初期会員情報入力ページ
 */
export default defineComponent({
  name: 'MypageUserInitPage',
  components: {
    MypageUserEditHeaderSection,
    MypageUserInitPane,
  },
  inject: ['router'],
  setup() {
    const router = inject('router') as VueRouter

    if (!UserStore.value.isInitLogin()) {
      // 初回ログインではない場合は、会員情報入力ページに遷移（直リンクでアクセスしてきた場合の対策）
      router.replace({ name: 'TopPage' })
    }
  },
})
