import { load } from 'recaptcha-v3'

/**
 * reCaptcha処理のHook
 */
export default function useReCaptcha() {
  /**
   * reCaptchaトークンを取得する
   * @param action トークン取得時に指定するAction
   * @return トークン
   */
  const getReCaptchaToken = async (action = 'getToken') => {
    try {
      const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_SITE_KEY)
      return recaptcha.execute(action)
    } catch {
      return ''
    }
  }

  return {
    getReCaptchaToken,
  }
}
