







































import { computed, defineComponent, PropType } from '@vue/composition-api'
import {
  UserDataType,
  MembersRankDataType,
} from '@/components/MypageTopPage/MembershipCardPane.vue'
import PointGaugeParts from '@/components/MypageTopPage/MembershipCardPane/parts/PointGaugeParts.vue'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'

/**
 * マイページ: 会員証 表示モードセクション
 */
export default defineComponent({
  name: 'MembersPointSection',
  components: {
    PointGaugeParts,
  },
  props: {
    userData: {
      type: Object as PropType<UserDataType>,
      default: () => ({}),
      require: true,
    },
    membersRankData: {
      type: Object as PropType<MembersRankDataType>,
      default: () => ({}),
      require: true,
    },
  },
  setup(props) {
    /**
     * 無料ユーザー判定取得
     */
    const isFreePlan = computed(() => props.userData.isFreePlan)

    /**
     * 会員種別取得
     */
    const membershipType = computed(() => (props.userData.isFreePlan ? 'free' : 'paid'))

    /**
     * 現在のポイント
     */
    const currentPoint = computed(() => props.membersRankData.point)

    /**
     * 次のランクのインデックス番号を取得
     */
    const nextRankIndex = computed(
      () =>
        Const.MEMBERS_RANK_NAME_POINTS.findIndex(
          (rankPoint) => rankPoint.rank === props.membersRankData.currentRank,
        ) + 1,
    )

    /**
     * 次のランクまでのポイントを取得
     */
    const nextRankPoint = computed(() => {
      const nextPoint = Const.MEMBERS_RANK_NAME_POINTS[nextRankIndex.value]
        ? Const.MEMBERS_RANK_NAME_POINTS[nextRankIndex.value].point
        : undefined
      if (nextPoint) {
        return nextPoint - currentPoint.value
      }
      return 0
    })

    /**
     * 次のランクを取得
     */
    const nextRank = computed(() =>
      Const.MEMBERS_RANK_NAME_POINTS[nextRankIndex.value]
        ? Const.MEMBERS_RANK_NAME_POINTS[nextRankIndex.value].rank
        : undefined,
    )
    /**
     * 会員ランク特典説明へのリンク
     */
    const rankBenefitsLink = computed(() =>
      I18n.t(
        `MypagePage.MypageTopPage.membersRank.nextRank.${membershipType.value}.${nextRank.value}`,
        {
          url:
            I18n.locale === 'ja'
              ? Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.JA
              : Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.EN,
        },
      ).toString(),
    )

    return {
      membershipType,
      isFreePlan,
      currentPoint,
      nextRankPoint,
      nextRank,
      rankBenefitsLink,
    }
  },
})
