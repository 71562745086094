








import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import RaceListPane from '@/components/RaceListPage/RaceListPane.vue'

/**
 * レース一覧ページ
 */
export default defineComponent({
  name: 'RaceListPage',
  components: {
    HeaderPane,
    GlobalNavigationPane,
    RaceListPane,
  },
  methods: {},
})
