







import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import PaymentConfirmPane from '@/components/pc/MyPage/PaymentPage/PaymentConfirmPane.vue'

/**
 * クレジットカード情報変更ページ
 */
export default defineComponent({
  name: 'PaymentConfirmPage',
  components: {
    SubHeaderSection,
    PaymentConfirmPane,
  },
})
