






import { defineComponent } from '@vue/composition-api'
import AboutUsingDataListParts from '@/components/MypageAboutUsingPage/MypageAboutUsingPane/parts/AboutUsingDataListParts.vue'

/**
 * マイページ: ご利用について データ セクション
 */
export default defineComponent({
  name: 'MypageAboutUsingSection',
  components: {
    AboutUsingDataListParts,
  },
  props: {
    /**
     * セクションタイトル
     */
    title: {
      type: String,
    },
    /**
     * データリスト
     */
    aboutUsingListItems: {
      type: Array,
      required: true,
    },
  },
})
