var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-rpm-gauge-parts" }, [
    _c(
      "svg",
      {
        staticClass: "telemetry-rpm-gauge-parts__gauge",
        attrs: {
          width: "303",
          height: "128",
          viewBox: "0 0 303 128",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c(
          "clipPath",
          { attrs: { id: "telemetry-rpm-gauge-parts__gauge__clip" } },
          [
            _c("rect", {
              attrs: {
                width: "303",
                height: _vm.gaugeHeight,
                x: "0",
                y: _vm.gaugePositionY,
                fill: "white",
                opacity: "0.8",
              },
            }),
          ]
        ),
        _c("path", {
          attrs: {
            "clip-path": "url(#telemetry-rpm-gauge-parts__gauge__clip)",
            opacity: "0.5",
            d: "M263 16C263 8.26801 256.732 2 249 2H183V4H237C243.627 4 249 9.37258 249 16V111.029C249 114.212 250.264 117.264 252.515 119.515L263 130V16Z",
            fill: "#73FF31",
          },
        }),
        _c("path", {
          attrs: {
            "clip-path": "url(#telemetry-rpm-gauge-parts__gauge__clip)",
            opacity: "0.8",
            d: "M263 16C263 8.26801 256.732 2 249 2H183V3H249C256.18 3 262 8.8203 262 16V129L263 130V16Z",
            fill: "#73FF31",
          },
        }),
        _c("g", { attrs: { opacity: "0.5" } }, [
          _c("path", {
            attrs: {
              opacity: "0.8",
              d: "M263 16C263 8.26801 256.732 2 249 2H183V4H237C243.627 4 249 9.37258 249 16V111.029C249 114.212 250.264 117.264 252.515 119.515L263 130V16Z",
              fill: "url(#paint0_linear_757_14861)",
            },
          }),
          _c("path", {
            attrs: {
              d: "M263 16C263 8.26801 256.732 2 249 2H183V3H249C256.18 3 262 8.8203 262 16V129L263 130V16Z",
              fill: "#73FF31",
            },
          }),
        ]),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_757_14861",
                  x1: "249",
                  y1: "81.5",
                  x2: "263",
                  y2: "81.5",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", {
                  attrs: { "stop-color": "#73FF31", "stop-opacity": "0.5" },
                }),
                _c("stop", { attrs: { offset: "1", "stop-color": "#73FF31" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "text",
          {
            staticClass: "telemetry-rpm-gauge-parts__label",
            attrs: { x: "70", y: "-252.5", transform: "rotate(90)" },
          },
          [_vm._v(" ENGINE ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }