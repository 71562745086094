import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import { TranscodeStatus } from '@/@types/TranscodeStatusType'

/**
 * 動画コンテンツ情報を参照するための処理を取得する。
 */
export default function useMovieContentsStatus() {
  /**
   * 映像が配信されているかを判定する
   * @param race レース情報
   * @param contentsInfos コンテンツ動画情報
   * @param angleMovieInfos コンテンツ動画情報
   * @param qualifyingRaceStartEventList 予選レースに登録されているスタートイベント
   */
  const hasDistributedMovie = (
    race: RaceDocument,
    contentsInfos: Array<ContentsInfoDocument>,
    angleMovieInfos: Array<AngleMovieInfoDocument>,
    qualifyingRaceStartEventList: Array<HighlightDocument> | undefined,
  ) => {
    // LIVE配信映像、または、動画アップロード後、変換が完了している動画を検索する
    const distributedContentsInfo = contentsInfos.find(
      (contentsInfo) =>
        contentsInfo.matchId === race.id &&
        (contentsInfo.isLive || contentsInfo.transcodeStatus === TranscodeStatus.Complete),
    )
    // LIVE配信映像、または、動画アップロード後、変換が完了している動画を検索する
    const distributedAngleMovieInfo = angleMovieInfos.find(
      (angleMovieInfo) =>
        angleMovieInfo.matchId === race.id &&
        (angleMovieInfo.isLive || angleMovieInfo.transcodeStatus === TranscodeStatus.Complete),
    )
    // LIVE配信映像、または、動画アップロード後、変換が完了している動画があるかどうか
    const hasDistributed = !!distributedContentsInfo || !!distributedAngleMovieInfo

    if (race.raceType === 'QUALIFYING') {
      // 予選の場合
      if (!race.qualifyingLabelOrder || race.qualifyingLabelOrder === 0) {
        // 1つ目のラベル
        return hasDistributed
      }
      // 2つ目以降のラベル
      return (
        hasDistributed &&
        race.qualifyingLabelOrder &&
        qualifyingRaceStartEventList?.[race.qualifyingLabelOrder - 1]
      )
    }

    // 上記以外（フリー走行、決勝、raceType未指定の場合）
    // 映像が配信されているレースの場合はtrueを返す
    return hasDistributed
  }

  return {
    hasDistributedMovie,
  }
}
