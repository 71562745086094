var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isVideoPlayerReady
      ? _c(
          "div",
          {
            staticClass: "video-overlay-section",
            class: "video-overlay-section--" + _vm.screenOrientationType,
            on: { pointerdown: _vm.restartOverlayTimer },
          },
          [
            _c("div", {
              staticClass: "video-overlay-section__hide_trigger",
              on: { pointerdown: _vm.hideOverlay },
            }),
            _vm.visibleVideoHeader
              ? _c("overlay-header-parts", {
                  staticClass: "video-overlay-section__header",
                  attrs: {
                    videoTitle: _vm.videoTitle,
                    screenOrientationType: _vm.screenOrientationType,
                    lockOrientation: _vm.lockOrientation,
                    videoFullScale: _vm.videoFullScaleStatus,
                  },
                  on: { fullScaleVideo: _vm.changeFullScaleVideo },
                })
              : _vm._e(),
            !_vm.screenLandScape &&
            _vm.isFetchedRaceInfo &&
            !_vm.isLiveNotStarted
              ? _c("portrait-video-controller-parts", {
                  staticClass: "video-overlay-section__portrait_controller",
                  attrs: {
                    screenOrientationType: _vm.screenOrientationType,
                    videoPaused: _vm.videoPausedStatus,
                    isAngleChangeAvailable: _vm.isAngleChangeAvailable,
                  },
                  on: {
                    videoPlayPauseChange: _vm.changeVideoPlayPause,
                    videoPlayChangeCurrentTime: _vm.changeVideoTimes,
                  },
                })
              : _vm._e(),
            _vm.isFetchedRaceInfo
              ? _c("overlay-controller-parts", {
                  staticClass: "video-overlay-section__controller",
                  attrs: {
                    videoTimes: _vm.videoInfo,
                    liveEnabled: _vm.isLive,
                    isLiveNotStarted: _vm.isLiveNotStarted,
                    viewAngle: _vm.viewAngle,
                    viewMode: _vm.viewContentsMode,
                    videoFullScale: _vm.videoFullScaleStatus,
                    screenOrientationType: _vm.screenOrientationType,
                    lockOrientation: _vm.lockOrientation,
                    videoPaused: _vm.videoPausedStatus,
                    videoSlow: _vm.videoSlowStatus,
                    isFetchedRaceInfo: _vm.isFetchedRaceInfo,
                    isAngleChangeAvailable: _vm.isAngleChangeAvailable,
                    mapViewEnabled: _vm.mapViewEnabled,
                    hasVolumeControl: _vm.hasVolumeControl,
                  },
                  on: {
                    videoPlayPauseChange: _vm.changeVideoPlayPause,
                    videoSlowChange: _vm.changeVideoSlow,
                    viwAngleChange: _vm.toggleViewAngle,
                    viewModeChange: _vm.changeViewMode,
                    fullscreenChange: _vm.changeFullscreen,
                    fullScaleVideo: _vm.changeFullScaleVideo,
                    "update:videoTimes": _vm.updateVideoTimes,
                    videoPlayChangeCurrentTime: _vm.changeVideoTimes,
                    onLiveClicked: _vm.onLiveClicked,
                    viewMapMode: _vm.toggleMapMode,
                    handleTouchStartSeekBar: _vm.handleTouchStartSeekBar,
                    handleTouchEndSeekBar: _vm.handleTouchEndSeekBar,
                    handleTouchEndVolumeSeekBar:
                      _vm.handleTouchEndVolumeSeekBar,
                    handleVolumeMute: _vm.handleVolumeMute,
                  },
                })
              : _vm._e(),
            _vm.screenLandScape
              ? _c("landscape-data-view-selector-parts", {
                  staticClass: "video-overlay-section__view-selector",
                  attrs: { viewMode: _vm.viewContentsMode },
                  on: { viewModeChange: _vm.changeViewMode },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }