








import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CancelConfirmPane from '@/components/pc/MyPage/CancelPage/CancelConfirmPane.vue'
/**
 * 退会確認ページ
 */
export default defineComponent({
  name: 'CancelConfirmPage',
  components: {
    SubHeaderSection,
    CancelConfirmPane,
  },
})
