












import { defineComponent, Ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageSwitchToPaidPlanPane from '@/components/MypageSwitchToPaidPlanPage/MypageSwitchToPaidPlanPane.vue'
import StoreUtil from '@/store/StoreUtil'

export default defineComponent({
  name: 'MypageSwitchToPaidPlanPage',
  components: {
    SubHeaderSection,
    MypageSwitchToPaidPlanPane,
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { isPurchasingInAppPurchase } = mypagePageStore

    return {
      isPurchasingInAppPurchase: isPurchasingInAppPurchase as Ref<boolean>,
    }
  },
})
