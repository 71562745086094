import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import AllPlayerTelemetryDocument from '../stores/collectionModule/documents/liveTiming/AllPlayerTelemetryDocument'
import MathUtil from '@/util/MathUtil'
import useDynamoDB from '@/store/hook/useDynamoDB'
import Logger from '@/util/logger/Logger'

/**
 * 選手毎のテレメトリーデータを保持するマップ。
 * ${選手の車両番号}_${作成日値}をキーにして、その選手の対象の日時のテレメトリーデータを値として保持する。
 */
type AllPlayerTelemetryHashedByCreatedDateType = Map<string, AllPlayerTelemetryDocument>

/**
 * テレメトリーデータ取得を実施した作成日時を格納するためのマップオブジェクト
 */
const fetchedCreateDateTimes = new Map<number, boolean>()

/**
 * 取得したテレメトリーデータを保持するマップオブジェクト
 */
const allPlayerTelemetryHashedByCreatedDateMap = new Map<string, AllPlayerTelemetryDocument>()

/**
 * 全車両のテレメトリーデータを操作するための処理を提供する。
 */
export default function useAllPlayerTelemetry() {
  const liveTimingTelemetryCollectionModule = CollectionModule.createStore(
    AllPlayerTelemetryDocument,
  )

  const { searchDateRangeData } = useDynamoDB()

  /**
   * 取得したテレメトリーデータをクリアする
   */
  const clearAllPlayerTelemetry = () => {
    liveTimingTelemetryCollectionModule.clearData()
    fetchedCreateDateTimes.clear()
    allPlayerTelemetryHashedByCreatedDateMap.clear()
  }

  /**
   * 対象の日時のデータの取得を実施しているかどうかを判定する.
   * @param createdDate 作成日時
   */
  const isAllPlayerTelemetryFetched = (createdDate: number) => {
    const createDateRounded = MathUtil.round(createdDate, 1000)
    // テレメトリーデータは正確に1秒毎にデータが登録されない場合があるため、前後のデータも確認する
    return (
      fetchedCreateDateTimes.has(createDateRounded) ||
      fetchedCreateDateTimes.has(createDateRounded - 1000) ||
      fetchedCreateDateTimes.has(createDateRounded + 1000)
    )
  }

  /**
   * 取得した全車両のテレメトリーデータをもとに、車両ごとのテレメトリーデータを算出する。
   * "${車両番号}_${作成日時}"の値をキーとして、その車両のテレメトリーデータが値に設定されたマップオブジェクトを返す。
   */
  const allPlayerTelemetryHashedByCreatedDate = computed(() =>
    liveTimingTelemetryCollectionModule.data.reduce<AllPlayerTelemetryHashedByCreatedDateType>(
      (map, liveTimingTelemetry) => {
        if (liveTimingTelemetry.createdDate) {
          const roundedDateTime = MathUtil.round(liveTimingTelemetry.createdDate, 1000)
          const hashmap = map
          hashmap.set(`${liveTimingTelemetry.carNo}_${roundedDateTime}`, liveTimingTelemetry)
          fetchedCreateDateTimes.set(roundedDateTime, true)
        }
        return map
      },
      allPlayerTelemetryHashedByCreatedDateMap,
    ),
  )

  /**
   * 指定したレースに出走している全ての車両テレメトリーデータを取得する。
   * @param race レース
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchAllPlayerTelemetry = async (race: RaceDocument, fromDate: number, toDate: number) => {
    const result = await searchDateRangeData({
      tableName: 'telemetry-data-by-race',
      partitionKey: 'date',
      partitionKeyValue: dayjs(race.scheduleDate).utc().format('YYYY-MM-DD'),
      sortKey: 'createdDate',
      from: fromDate,
      to: toDate,
      attributeNames: { '#raceDate': 'date' },
    })
    liveTimingTelemetryCollectionModule.data = result as Array<AllPlayerTelemetryDocument>
    Logger.debug(
      `Finish to fetchAllPlayerTelemetry: all player telemetryHashedByCreatedDate. size: ${allPlayerTelemetryHashedByCreatedDate.value.size}`,
    )
  }

  /**
   * 指定された車両の指定した時間のテレメトリーデータを取得する。
   * @param carNo 車両番号
   * @param createdDate テレメトリーデータの作成日時
   */
  const getPlayerTelemetry = (carNo: string, createdDate: number) => {
    const targetCreatedDate = MathUtil.round(createdDate, 1000)
    return allPlayerTelemetryHashedByCreatedDate.value.get(`#${carNo}_${targetCreatedDate}`)
  }

  return {
    fetchAllPlayerTelemetry,
    getPlayerTelemetry,
    clearAllPlayerTelemetry,
    allPlayerTelemetryHashedByCreatedDate,
    isAllPlayerTelemetryFetched,
  }
}
