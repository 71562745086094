var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "membership-card-edit-mode-section" },
    [
      _c("switch-edit-mode-button-parts", {
        staticClass: "membership-card-edit-mode-section__cancel-button",
        attrs: { type: "cancel" },
        on: { click: _vm.emitCancel },
      }),
      _c("button", {
        staticClass: "membership-card-edit-mode-section__save-button",
        domProps: { textContent: _vm._s(_vm.$tc("common.save")) },
        on: {
          click: function ($event) {
            return _vm.handlerSubmit()
          },
        },
      }),
      _c("div", { staticClass: "membership-card-edit-mode-section__image" }, [
        _c(
          "figure",
          { staticClass: "membership-card-edit-mode-section__image-thumbnail" },
          [
            _c("img", {
              attrs: {
                src: _vm.userIconTmpUrl
                  ? _vm.userIconTmpUrl
                  : _vm.userPreviewData.image ||
                    require("@/assets/img/icon/icon_user_default.png"),
                alt: "",
              },
            }),
          ]
        ),
        _c(
          "label",
          { staticClass: "membership-card-edit-mode-section__image-select" },
          [
            _c("input", {
              ref: "imageInput",
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.uploadFile },
            }),
            _c(
              "svg",
              {
                attrs: {
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M12.666 5.33203V3.9987H11.3327V2.66536H12.666V1.33203H13.9994V2.66536H15.3327V3.9987H13.9994V5.33203H12.666ZM1.99935 14.6654C1.63268 14.6654 1.31868 14.5347 1.05735 14.2734C0.796017 14.012 0.665572 13.6983 0.666017 13.332V5.33203C0.666017 4.96536 0.796683 4.65136 1.05802 4.39003C1.31935 4.1287 1.63313 3.99825 1.99935 3.9987H4.09935L5.33268 2.66536H9.33269V3.9987H5.91602L4.69935 5.33203H1.99935V13.332H12.666V7.33203H13.9994V13.332C13.9994 13.6987 13.8687 14.0127 13.6074 14.274C13.346 14.5354 13.0322 14.6658 12.666 14.6654H1.99935ZM7.33269 12.332C8.16602 12.332 8.87446 12.0403 9.45802 11.4567C10.0416 10.8731 10.3331 10.1649 10.3327 9.33203C10.3327 8.4987 10.0409 7.79025 9.45735 7.2067C8.8738 6.62314 8.16557 6.33159 7.33269 6.33203C6.49935 6.33203 5.79091 6.62381 5.20735 7.20736C4.6238 7.79092 4.33224 8.49914 4.33268 9.33203C4.33268 10.1654 4.62446 10.8738 5.20802 11.4574C5.79157 12.0409 6.4998 12.3325 7.33269 12.332ZM7.33269 10.9987C6.86602 10.9987 6.47157 10.8376 6.14935 10.5154C5.82713 10.1931 5.66602 9.7987 5.66602 9.33203C5.66602 8.86536 5.82713 8.47092 6.14935 8.1487C6.47157 7.82648 6.86602 7.66536 7.33269 7.66536C7.79935 7.66536 8.1938 7.82648 8.51602 8.1487C8.83824 8.47092 8.99935 8.86536 8.99935 9.33203C8.99935 9.7987 8.83824 10.1931 8.51602 10.5154C8.1938 10.8376 7.79935 10.9987 7.33269 10.9987Z",
                    fill: "white",
                    "fill-opacity": "0.7",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "membership-card-edit-mode-section__name" },
        [
          _c("atom-input-text", {
            staticClass: "membership-card-edit-mode-section__name-input",
            attrs: {
              placeholder: _vm.$tc("formParts.placeholder.displayName"),
              error: _vm.isEditCardForHighlightComment && !_vm.userData.name,
            },
            model: {
              value: _vm.previewName,
              callback: function ($$v) {
                _vm.previewName = $$v
              },
              expression: "previewName",
            },
          }),
          _vm.isEditCardForHighlightComment && !_vm.userData.name
            ? _c(
                "div",
                {
                  staticClass: "membership-card-edit-mode-section__name__error",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "membership-card-edit-mode-section__name__error-message",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isTransitionCreateHighlight
                              ? _vm.$tc(
                                  "MypagePage.errorMessage.displayNameForHighlight"
                                )
                              : _vm.$tc(
                                  "MypagePage.errorMessage.displayNameForComment"
                                )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }