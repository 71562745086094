var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-brk-press-fr-parts" }, [
    _c(
      "svg",
      {
        staticClass: "telemetry-brk-press-fr-parts__gauge",
        attrs: {
          width: "303",
          height: "200",
          viewBox: "0 0 303 200",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c(
          "clipPath",
          { attrs: { id: "telemetry-brk-press-fr-parts__gauge__clip" } },
          [
            _c("rect", {
              attrs: {
                width: "303",
                height: _vm.gaugeHeight,
                x: "0",
                y: _vm.gaugePositionY,
                fill: "white",
              },
            }),
          ]
        ),
        _c("path", {
          attrs: {
            "clip-path": "url(#telemetry-brk-press-fr-parts__gauge__clip)",
            opacity: "0.8",
            d: "M0 24C0 10.7452 10.7452 0 24 0H120V4H66C59.3726 4 54 9.37258 54 16V136.059C54 142.424 51.4714 148.529 46.9706 153.029L0 200V24Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            "clip-path": "url(#telemetry-brk-press-fr-parts__gauge__clip)",
            d: "M0 24C0 10.7452 10.7452 0 24 0H120V1H25C12.2974 1 2 11.2975 2 24V198L0 200V24Z",
            fill: "white",
          },
        }),
        _c("g", { attrs: { opacity: "0.3" } }, [
          _c("path", {
            attrs: {
              d: "M0 24C0 10.7452 10.7452 0 24 0H120V4H66C59.3726 4 54 9.37258 54 16V136.059C54 142.424 51.4714 148.529 46.9706 153.029L0 200V24Z",
              fill: "url(#paint0_linear_757_14831)",
            },
          }),
          _c("path", {
            attrs: {
              d: "M0 24C0 10.7452 10.7452 0 24 0H120V1H25C12.2974 1 2 11.2975 2 24V198L0 200V24Z",
              fill: "white",
            },
          }),
        ]),
        _c("path", {
          attrs: {
            opacity: "0.6",
            d: "M0 88L12.4853 100.485C14.7357 102.736 16 105.788 16 108.971V184L0 200V88Z",
            fill: "black",
          },
        }),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_757_14831",
                  x1: "51",
                  y1: "250",
                  x2: "-1.22056e-08",
                  y2: "250",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", {
                  attrs: { "stop-color": "white", "stop-opacity": "0.3" },
                }),
                _c("stop", {
                  attrs: {
                    offset: "1",
                    "stop-color": "white",
                    "stop-opacity": "0.8",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "text",
          {
            staticClass: "telemetry-brk-press-fr-parts__label",
            attrs: { x: "-179", y: "12", transform: "rotate(-90)" },
          },
          [_vm._v(" BRAKE ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }