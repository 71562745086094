

































































































import { computed, defineComponent, Ref } from '@vue/composition-api'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import StoreUtil from '@/store/StoreUtil'
import usePermission from '@/components/hook/usePermission'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import useMovieContentsStatus from '@/components/hook/useMovieContentsStatus'

/**
 * レース動画再生画面 レース選択リストセクションのコンポーネント
 */
export default defineComponent({
  name: 'RaceLinkListSection',
  components: {},
  setup() {
    const topPageStore = StoreUtil.useStore('TopPageStore')
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { targetRace, currentTimeMainVideoTime, clearFetchRaceVideoPageData } = raceVideoPageStore
    const { canShowRace, getTargetRacePermission } = usePermission()
    const { hasDistributedMovie } = useMovieContentsStatus()

    /**
     * 現在再生中のセクションID
     * 決勝、または、予選(Q1-A, Q1-B, Q2のいずれか)のセクションIDを算出する。
     */
    const currentSectionId = computed(() => {
      if (
        targetRace.value.additionalData?.raceType === 'RACE' ||
        targetRace.value.additionalData?.raceType === 'FREE_PRACTICE'
      ) {
        return targetRace.value.id
      }

      // 現在の再生位置から、現在再生ている予選の各セクション(Q1-A, Q1-B, Q2)を求める
      const sectionList = [...topPageStore.qualifyingRaceList.value]
      sectionList.sort(
        (a, b) =>
          (b.highlightPlayInfo?.movieStartTime ?? 0) - (a.highlightPlayInfo?.movieStartTime ?? 0),
      )
      const targetSection = sectionList.find(
        (section) =>
          (currentTimeMainVideoTime.value ?? 0) > (section?.highlightPlayInfo?.movieStartTime ?? 0),
      )
      return targetSection ? targetSection.sectionId : null
    })

    return {
      sessionsWithOutRace: topPageStore.sessionsWithOutRace as Ref<Array<RaceDocument>>,
      finalRaces: topPageStore.mainRace as Ref<Array<RaceDocument>>,
      contentsInfos: topPageStore.contentsInfos as Ref<Array<ContentsInfoDocument>>,
      angleMovieInfos: topPageStore.angleMovieInfos as Ref<Array<AngleMovieInfoDocument>>,
      qualifyingRaceStartEventList: topPageStore.qualifyingRaceStartEventList as Ref<
        Array<HighlightDocument>
      >,
      canShowRace,
      getTargetRacePermission,
      hasDistributedMovie,
      currentSectionId,
      clearFetchRaceVideoPageData,
    }
  },
  methods: {
    /**
     * レース選択非表示化
     * @event toggleRaceSelect レースが選択された場合に発生する
     */
    toggleRaceSelect(): void {
      this.$emit('raceSelectToggle', 'off')
    },
    /**
     * 指定されたレースの動画再生画面に遷移する。
     * @param race レース
     */
    linkTo(race: RaceDocument) {
      // そのまま遷移すると、動画再生画面の状態が残ってしまい動画再生が正しく行われないため、一度TOP画面に遷移する。
      this.$router.replace({ name: 'TopPage' }).then(() => {
        const targetPath = race.highlightPlayInfo?.highlightId
          ? `/race-video/${race.championshipMasterId}/${race.id}/${race.highlightPlayInfo.highlightId}`
          : `/race-video/${race.championshipMasterId}/${race.id}/`

        this.$router.replace({ path: targetPath })
      })
    },
  },
})
