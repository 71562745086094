var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-contract-log-pane" },
    [
      _c(
        "div",
        { staticClass: "mypage-contract-log-pane__header" },
        [
          _c("p", {
            staticClass: "mypage-contract-log-pane__lead",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tc("MypagePage.MypageContract.logPage.lead")
              ),
            },
          }),
          _vm.appMessage
            ? _c("p", {
                staticClass: "mypage-contract-log-pane__message",
                domProps: { innerHTML: _vm._s(_vm.appMessage) },
              })
            : _vm._e(),
          _vm.appExternalLinks.length > 0
            ? _c(
                "ul",
                { staticClass: "mypage-contract-log-pane__list" },
                _vm._l(_vm.appExternalLinks, function (link, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "mypage-contract-log-pane__item",
                    },
                    [
                      _c("atom-link", {
                        staticClass: "mypage-contract-log-pane__itemLink",
                        attrs: {
                          url: link.url,
                          linkText: link.text,
                          imgPath: require("@/assets/img/icon/icon_external_link_black.svg"),
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.appAdditionalMessage && _vm.appAdditionalExternalLinks.length > 0
            ? [
                _c("p", {
                  staticClass: "mypage-contract-log-pane__message",
                  domProps: { innerHTML: _vm._s(_vm.appAdditionalMessage) },
                }),
                _c(
                  "ul",
                  { staticClass: "mypage-contract-log-pane__list" },
                  _vm._l(
                    _vm.appAdditionalExternalLinks,
                    function (link, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "mypage-contract-log-pane__item",
                        },
                        [
                          _c("atom-link", {
                            staticClass: "mypage-contract-log-pane__itemLink",
                            attrs: {
                              url: link.url,
                              linkText: link.text,
                              imgPath: require("@/assets/img/icon/icon_external_link_black.svg"),
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.planContractLogData
        ? _c("mypage-contract-log-table-section", {
            attrs: { planContractLogData: _vm.planContractLogData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }