import { render, staticRenderFns } from "./SugoParts.vue?vue&type=template&id=4390225c&scoped=true"
import script from "./SugoParts.vue?vue&type=script&lang=ts"
export * from "./SugoParts.vue?vue&type=script&lang=ts"
import style0 from "./SugoParts.vue?vue&type=style&index=0&id=4390225c&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4390225c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4390225c')) {
      api.createRecord('4390225c', component.options)
    } else {
      api.reload('4390225c', component.options)
    }
    module.hot.accept("./SugoParts.vue?vue&type=template&id=4390225c&scoped=true", function () {
      api.rerender('4390225c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/circuit/SugoParts.vue"
export default component.exports