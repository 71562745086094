var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.5 -0.000244141H8V4.99976H6.5V-0.000244141Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.5 3.99976H12V4.99976H9.5V3.99976Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5 6.99976C5 6.44747 5.44772 5.99976 6 5.99976H12C12.5523 5.99976 13 6.44747 13 6.99976V16.9998C13 17.552 12.5523 17.9998 12 17.9998H6C5.44772 17.9998 5 17.552 5 16.9998V6.99976ZM6.5 7.49976H11.5V8.49976H6.5V7.49976ZM11.5 9.49976H6.5V10.4998H11.5V9.49976ZM7.5 12.9998C7.5 13.2759 7.27614 13.4998 7 13.4998C6.72386 13.4998 6.5 13.2759 6.5 12.9998C6.5 12.7236 6.72386 12.4998 7 12.4998C7.27614 12.4998 7.5 12.7236 7.5 12.9998ZM9 13.4998C9.27614 13.4998 9.5 13.2759 9.5 12.9998C9.5 12.7236 9.27614 12.4998 9 12.4998C8.72386 12.4998 8.5 12.7236 8.5 12.9998C8.5 13.2759 8.72386 13.4998 9 13.4998ZM11.5 12.9998C11.5 13.2759 11.2761 13.4998 11 13.4998C10.7239 13.4998 10.5 13.2759 10.5 12.9998C10.5 12.7236 10.7239 12.4998 11 12.4998C11.2761 12.4998 11.5 12.7236 11.5 12.9998ZM7 15.4998C7.27614 15.4998 7.5 15.2759 7.5 14.9998C7.5 14.7236 7.27614 14.4998 7 14.4998C6.72386 14.4998 6.5 14.7236 6.5 14.9998C6.5 15.2759 6.72386 15.4998 7 15.4998ZM9.5 14.9998C9.5 15.2759 9.27614 15.4998 9 15.4998C8.72386 15.4998 8.5 15.2759 8.5 14.9998C8.5 14.7236 8.72386 14.4998 9 14.4998C9.27614 14.4998 9.5 14.7236 9.5 14.9998ZM11 15.4998C11.2761 15.4998 11.5 15.2759 11.5 14.9998C11.5 14.7236 11.2761 14.4998 11 14.4998C10.7239 14.4998 10.5 14.7236 10.5 14.9998C10.5 15.2759 10.7239 15.4998 11 15.4998Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }