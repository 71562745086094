











import { computed, defineComponent } from '@vue/composition-api'

/**
 * Standings: トロフィーアイコンパーツ
 */
export default defineComponent({
  name: 'TrophyIconParts',
  components: {},
  props: {
    position: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const positionIconColor = computed(() => {
      let iconColor = '#fff'
      const positionValue = props.position

      if (positionValue === 1) {
        iconColor = '#ECAA00'
      } else if (positionValue === 2) {
        iconColor = '#C2C2C2'
      } else if (positionValue === 3) {
        iconColor = '#D98B52'
      }
      return iconColor
    })

    return {
      positionIconColor,
    }
  },
})
