






import { defineComponent } from '@vue/composition-api'
import EmailAuthenticationPane from '@/components/SignupPage/EmailAuthenticationPane.vue'
import type { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import StoreUtil from '@/store/StoreUtil'
import Const from '@/util/Const'

export type DataType = {
  memberType: MemberType
}

/**
 * 新規会員登録: メール認証入力ページ
 */
export default defineComponent({
  name: 'EmailAuthenticationPage',
  components: {
    EmailAuthenticationPane,
  },
  data(): DataType {
    return {
      memberType: this.$route.params.memberType as MemberType,
    }
  },
  setup() {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')

    return {
      storeMemberType: signupPageStore.memberType,
      Const,
    }
  },
  mounted() {
    if (!this.hasMemberType()) {
      // 会員登録タイプが想定外だった場合はログイン画面に戻る
      this.moveToLogin()
    }
    // ストアのmemberTypeを更新
    this.storeMemberType = this.memberType
  },
  methods: {
    /**
     * URLに含まれている会員登録タイプが想定している値になっているか
     */
    hasMemberType() {
      return Object.values(this.Const.MEMBER_TYPE).find(
        (memberType) => memberType === this.memberType,
      )
    },
    moveToConfirmationSent() {
      /**
       * 認証メール送信画面へ遷移
       */
      this.$router.replace({ name: 'ConfirmationSentPage' })
    },
    moveToLogin() {
      /**
       * ログイン画面へ遷移
       */
      this.$router.replace({ path: '/login' })
    },
  },
})
