










import { defineComponent, PropType } from '@vue/composition-api'
import { ProgressMessageType } from '@/store/stores/pageStore/common/LoadingOverlayProgressStore'
import i18n from '@/locales/I18n'

/**
 * 共通ローディングオーバーレイの進捗表示に使用するパーツ
 */
export default defineComponent({
  name: 'LoadingOverlayProgressParts',
  props: {
    progressItem: {
      type: Object as PropType<ProgressMessageType>,
    },
  },
  setup() {
    return {
      i18n,
    }
  },
})
