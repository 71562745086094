import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import type LiveTimingDocument from '@/store/stores/collectionModule/documents/liveTiming/LiveTimingDocument'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import type { SectorNameMap } from '@/store/hook/useLiveTiming'

/**
 * 指定された選手の、値が未設定のライブタイミングデータを生成する。
 * @param player 選手
 */
const createEmptyData = (player: PlayerDocument) => ({
  id: player.playerId,
  playerId: player.playerId,
  carNo: player.getDisplayCarNo(),
  number: !Number.isNaN(Number(player.getDisplayCarNo())) ? player.getDisplayCarNo() : '',
  name: player.getPlayerName(),
  abbreviation: player.playerShortName.en,
  img: player.playerPictureImagePath,
  pitInWorking: false,
  pitInCount: 0,
  bestTimeEnabled: false,
  winner: false,
  lap: 0,
  sector: '--',
  lastLapTime: '',
  bestLap: 0,
  bestLapTime: '',
  bestTime: '',
  fastest: false,
  gapTime: '',
  gapTimeInterval: '',
  diffTime: '',
  diffTimeInterval: '',
  diffTimeIntervalForGapCalculation: '',
  position: '',
  battleId: '',
  valid: false,
  createdDate: 0,
})

/**
 * ランキング画面表示用の選手毎のライブタイミングのデータ型
 */
export type PlayerLiveTimingDataType = ReturnType<typeof createEmptyData>

/**
 * 選手のライブタイミング情報を表現するクラス。
 * ライブタイミングデータのliveTimings配列内の要素のデータを表現する。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3187442036/API
 */
export default class PlayerLiveTiming extends DocumentWrapper {
  constructor(parentLiveTiming: LiveTimingDocument, initProps?: Partial<PlayerLiveTiming>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    this.parentLiveTiming = parentLiveTiming
  }

  parentLiveTiming: LiveTimingDocument | null = null

  CARNO = ''

  LAPS = ''

  TOTAL_DISP = ''

  LAP_DISP = ''

  LAST_TIME = ''

  LAST_DISP = ''

  SEC1_DISP = ''

  SEC2_DISP = ''

  SEC3_DISP = ''

  SEC4_DISP = ''

  SPEED = ''

  DRIVER_J = ''

  PIT = ''

  PIT_IN_COUNT = ''

  PIT_IN = ''

  PIT_OUT = ''

  PIT_IN_CERTIFIED = ''

  PIT_IN_COUNT_CERTIFIED = ''

  START_POS = ''

  POS = ''

  BEST_TIME = ''

  BEST_DISP = ''

  BEST_LAPS = ''

  BEST_TOTAL_TIME = ''

  DIFF = ''

  COMPARE_PREVIOUS_DIFF = ''

  COMPARE_PREVIOUS_DIFF_FOR_GAP = ''

  CURRENT_SEC: 'SEC1' | 'SEC2' | 'SEC3' | 'SEC4' | '' = ''

  BATTLE_GROUP_ID = ''

  /**
   * この選手のライブタイミングデータを、ランキング画面に表示できる形式に変換する。
   * @param player 選手
   * @param liveTiming この選手のライブタイミングを保持している親ライブタイミングデータ
   * @param sectorNameMap セクター定義(SEC1など)とセクター名(S1)とのマップ
   */
  convertPlayerLiveTiming(
    player: PlayerDocument,
    liveTiming: LiveTimingDocument,
    sectorNameMap: typeof SectorNameMap,
  ): PlayerLiveTimingDataType {
    return {
      id: player.playerId,
      playerId: player.playerId,
      carNo: this.CARNO ?? '',
      number: !Number.isNaN(Number(this.CARNO)) ? this.CARNO : '',
      name: player.getPlayerName(),
      abbreviation: player.playerShortName.en,
      img: player.playerPictureImagePath,
      pitInWorking: this.PIT_IN_CERTIFIED === '1',
      pitInCount: Number(this.PIT_IN_COUNT) ?? 0,
      bestTimeEnabled: this.LAST_DISP ? this.LAST_DISP === this.BEST_DISP : false,
      winner: this.LAST_TIME === liveTiming.LAP_BEST_TIME,
      lap: Number(this.LAPS),
      sector: sectorNameMap[`${this.CURRENT_SEC}`] ?? '--',
      lastLapTime: this.LAST_DISP,
      bestLap: Number(this.BEST_LAPS),
      bestLapTime: this.BEST_DISP,
      bestTime: this.BEST_TIME,
      fastest:
        !!this.BEST_TIME &&
        !!liveTiming.LAP_BEST_TIME &&
        this.BEST_TIME === liveTiming.LAP_BEST_TIME,
      gapTime: '',
      gapTimeInterval: '',
      diffTime: this.DIFF,
      diffTimeInterval: this.COMPARE_PREVIOUS_DIFF,
      diffTimeIntervalForGapCalculation: this.COMPARE_PREVIOUS_DIFF_FOR_GAP,
      position: this.POS,
      battleId: this.BATTLE_GROUP_ID,
      valid: this.isValid,
      createdDate: liveTiming.createdDate ?? 0,
    }
  }

  /**
   * 対象の車両のライブタイミングデータが有効かどうかを判定する。
   * 現在の先頭車両のラップと、対象の車両のラップとの差を求め、その差が、規定週回数の90%よりも大きい値の場合、無効扱いとする。
   */
  get isValid(): boolean {
    if (!this.parentLiveTiming) {
      return false
    }
    if (this.parentLiveTiming.RACE_TYPE !== 'R') {
      return true
    }
    if (!this.parentLiveTiming.numberOfLaps || !this.parentLiveTiming.LAPS) {
      return false
    }
    // 現在の先頭車両のラップと、対象の車両のラップとの差を求め、その差が、規定週回数の90%よりも大きい値の場合、このデータは無効扱いとする
    const lapDiff = Number(this.parentLiveTiming.LAPS) - Number(this.LAPS)
    return lapDiff < Math.floor(this.parentLiveTiming.numberOfLaps * 0.1)
  }

  /**
   * ライブタイミングデータが存在しない場合に利用される空データを生成する。
   */
  static createEmptyData(player: PlayerDocument): PlayerLiveTimingDataType {
    return createEmptyData(player)
  }
}
