var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-form-section" },
    [
      _c(
        "div",
        { staticClass: "signup-form-section__form" },
        [
          _c("fieldset-email-parts", {
            attrs: { isReadOnly: true, showError: false, required: false },
            model: {
              value: _vm.inputData.email,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "email", $$v)
              },
              expression: "inputData.email",
            },
          }),
          _c("ValidationProvider", {
            ref: "validationProvider",
            staticClass: "signup-form-section__form__password",
            attrs: { name: "password", rules: "required|passwordFormat" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c("fieldset-password-parts", {
                      attrs: { required: true, showError: errors.length > 0 },
                      model: {
                        value: _vm.inputData.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputData, "password", $$v)
                        },
                        expression: "inputData.password",
                      },
                    }),
                    _c("form-error-message-parts", {
                      attrs: {
                        showError: errors.length > 0,
                        message: errors[0],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("p", {
        staticClass: "signup-form-section__term_message",
        domProps: {
          innerHTML: _vm._s(_vm.$tc("SignupPage.FormInput.TermLinkText")),
        },
      }),
      _c(
        "p",
        { staticClass: "signup-form-section__agreement" },
        [
          _c("fieldset-checkbox-parts", {
            attrs: {
              value: _vm.inputData.agreement,
              label: _vm.$tc("SignupPage.FormInput.Agreement"),
            },
            on: { changed: _vm.onAgreementChecked },
          }),
        ],
        1
      ),
      _c(
        "action-buttons-section",
        {
          staticClass: "signup-form-section__action-buttons",
          attrs: {
            scrollTargetSelector: ".signup-form-section",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.submitText,
              isDisabled: _vm.submitDisabled,
              colorType: "secondary",
            },
            on: { push: _vm.onFormSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }