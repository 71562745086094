var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "swiper",
      staticClass: "sns-post-item-images-parts",
      class: { "sns-post-item-images-parts--swiper": _vm.images.length > 1 },
    },
    [
      _vm.images.length > 1
        ? _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.images, function (img) {
              return _c(
                "div",
                {
                  key: "" + img.src,
                  staticClass:
                    "sns-post-item-images-parts__slider-img swiper-slide",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "sns-post-item-images-parts__link",
                      attrs: { href: _vm.postLink, target: "_blank" },
                    },
                    [
                      _c("img", {
                        staticClass: "sns-post-item-images-parts__img",
                        attrs: { src: _vm.addSignedUrl(img.src), alt: "" },
                        on: { error: _vm.srcError },
                      }),
                    ]
                  ),
                  img.type === "video"
                    ? _c("img", {
                        staticClass:
                          "sns-post-item-images-parts__img__play-icon",
                        attrs: {
                          src: require("@/assets/img/icon/video_controller_play.svg"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _c(
            "a",
            {
              staticClass: "sns-post-item-images-parts__link",
              attrs: { href: _vm.postLink, target: "_blank" },
            },
            [
              _c("img", {
                staticClass: "sns-post-item-images-parts__img",
                attrs: { src: _vm.addSignedUrl(_vm.images[0].src), alt: "" },
                on: { error: _vm.srcError },
              }),
            ]
          ),
      _vm.images[0].type === "video" && _vm.images.length === 1
        ? _c("img", {
            staticClass: "sns-post-item-images-parts__img__play-icon",
            attrs: {
              src: require("@/assets/img/icon/video_controller_play.svg"),
              alt: "",
            },
          })
        : _vm._e(),
      _vm.images.length > 1
        ? _c("div", { staticClass: "swiper-button-prev" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_swiper_prev.svg"),
                alt: "",
              },
            }),
          ])
        : _vm._e(),
      _vm.images.length > 1
        ? _c("div", { staticClass: "swiper-button-next" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_swiper_next.svg"),
                alt: "",
              },
            }),
          ])
        : _vm._e(),
      _vm.images.length > 1
        ? _c("div", { staticClass: "swiper-pagination" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }