var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "onboarding-circuit-mode-section" }, [
      _c("span", {
        staticClass:
          "onboarding-circuit-mode-section__mask onboarding-circuit-mode-section__mask--top",
      }),
      _c("span", {
        staticClass:
          "onboarding-circuit-mode-section__mask onboarding-circuit-mode-section__mask--bottom",
      }),
      _c("span", {
        staticClass:
          "onboarding-circuit-mode-section__mask onboarding-circuit-mode-section__mask--left",
      }),
      _c("span", {
        staticClass:
          "onboarding-circuit-mode-section__mask onboarding-circuit-mode-section__mask--right",
      }),
      _c(
        "svg",
        {
          staticClass:
            "onboarding-circuit-mode-section__corner onboarding-circuit-mode-section__corner--left-top",
          attrs: {
            width: "10",
            height: "10",
            viewBox: "0 0 10 10",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M10 0H0V10C0 4.47715 4.47715 0 10 0Z",
              fill: "black",
              "fill-opacity": "0.6",
            },
          }),
        ]
      ),
      _c(
        "svg",
        {
          staticClass:
            "onboarding-circuit-mode-section__corner onboarding-circuit-mode-section__corner--right-top",
          attrs: {
            width: "10",
            height: "10",
            viewBox: "0 0 10 10",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M10 10L10 0L0 -4.37114e-07C5.52285 -1.95703e-07 10 4.47715 10 10Z",
              fill: "black",
              "fill-opacity": "0.6",
            },
          }),
        ]
      ),
      _c(
        "svg",
        {
          staticClass:
            "onboarding-circuit-mode-section__corner onboarding-circuit-mode-section__corner--left-bottom",
          attrs: {
            width: "10",
            height: "10",
            viewBox: "0 0 10 10",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M1.31134e-06 0L0 10L10 10C4.47715 10 5.87108e-07 5.52285 1.31134e-06 0Z",
              fill: "black",
              "fill-opacity": "0.6",
            },
          }),
        ]
      ),
      _c(
        "svg",
        {
          staticClass:
            "onboarding-circuit-mode-section__corner onboarding-circuit-mode-section__corner--right-bottom",
          attrs: {
            width: "10",
            height: "10",
            viewBox: "0 0 10 10",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M0 10L10 10L10 0C10 5.52285 5.52285 10 0 10Z",
              fill: "black",
              "fill-opacity": "0.6",
            },
          }),
        ]
      ),
      _c("section", { staticClass: "onboarding-circuit-mode-section__modal" }, [
        _c(
          "svg",
          {
            staticClass: "onboarding-circuit-mode-section__bottom-arrow",
            attrs: {
              width: "20",
              height: "10",
              viewBox: "0 0 20 10",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M10 10L8.74228e-07 -1.74846e-06L20 0L10 10Z",
                fill: "white",
                "fill-opacity": "0.9",
              },
            }),
          ]
        ),
        _c(
          "header",
          { staticClass: "onboarding-circuit-mode-section__header" },
          [
            _c("p", {
              staticClass: "onboarding-circuit-mode-section__tag",
              domProps: { textContent: _vm._s(_vm.$tc("common.new")) },
            }),
            _c("h3", {
              staticClass: "onboarding-circuit-mode-section__title",
              domProps: {
                textContent: _vm._s(
                  _vm.$tc("HomePage.countdown.circuitMode.onboarding.title")
                ),
              },
            }),
          ]
        ),
        _c("p", {
          staticClass: "onboarding-circuit-mode-section__description",
          domProps: {
            innerHTML: _vm._s(
              _vm.$tc("HomePage.countdown.circuitMode.onboarding.description")
            ),
          },
        }),
        _c("button", {
          staticClass:
            "onboarding-circuit-mode-section__button onboarding-circuit-mode-section__button--light--secondary",
          domProps: { textContent: _vm._s(_vm.$tc("common.ok")) },
          on: { click: _vm.emitClose },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }