var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "atom-icon-link-btn atom-icon-link-btn--has-icon",
      class: [
        "atom-icon-link-btn--" + _vm.theme + "--" + _vm.colorType,
        "atom-icon-link-btn--" + _vm.size,
        "atom-icon-link-btn--has-icon-" + _vm.iconPosition,
      ],
      attrs: { href: _vm.url, target: _vm.target },
      on: { click: _vm.push },
    },
    [
      _c("img", { attrs: { src: _vm.imgPath, alt: "" } }),
      _c("span", [_vm._v(_vm._s(_vm.linkText))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }