var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "atom-router-btn",
      class: [
        "atom-router-btn--" + _vm.theme + "--" + _vm.colorType,
        "atom-router-btn--" + _vm.size,
      ],
      attrs: { to: _vm.to },
    },
    [_c("span", [_vm._v(_vm._s(_vm.linkText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }