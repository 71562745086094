import { computed, ref, Ref } from '@vue/composition-api'
import { uniqBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import UserRetrieveNameDocument, {
  UserRetrieveNameResponseType,
} from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'

/**
 * ユーザー名を操作するための処理を取得する。
 */
export default function useUserRetrieveName() {
  // Collection modules
  const userRetrieveNameCollectionModule = CollectionModule.createStore(UserRetrieveNameDocument)

  /**
   * ユーザー名一覧
   */
  const retrieveNameUsers = ref([]) as Ref<Array<UserRetrieveNameResponseType>>

  /**
   * SFgo親組織、ブロックユーザーを除外したユーザー名一覧
   */
  const retrieveNameUserList = computed(() => {
    const blockedUserIds =
      ContractInfoStore.value.ownOrganization.value?.communication?.blockedUserIds ?? []
    return retrieveNameUsers.value.filter(
      (user) =>
        user.userId !== process.env.VUE_APP_SFGO_PARENT_USER_ID &&
        !blockedUserIds.includes(user.userId),
    )
  })

  /**
   * ユーザーID毎に分類されたユーザー情報
   */
  const retrieveNameUsersByUserId = computed(() => {
    const usersMap: Record<string, UserRetrieveNameResponseType> = {}
    retrieveNameUsers.value.forEach((user) => {
      if (user.userId) {
        usersMap[user.userId] = user
      }
    })
    return usersMap
  })

  /**
   * 現在取得中のユーザーID一覧
   * @param userId
   * @returns
   */
  const fetchedRetrieveNameUserIds = computed(() => Object.keys(retrieveNameUsersByUserId.value))

  /**
   * ユーザー名を取得する
   * @param retrieveNameUserIds ユーザーIDの配列
   * @return APIレスポンス
   */
  const fetchRetrieveNameUsers = async (retrieveNameUserIds: Array<string>) => {
    const fetchUserRetrieveNameResponses = await userRetrieveNameCollectionModule.saveSplit(
      new UserRetrieveNameDocument({
        userIds: retrieveNameUserIds,
      }),
      {},
      'userIds',
      1,
      1000,
      {
        query: {
          expand: 'contract',
        },
      },
    )

    let retrieveNameUserData = [] as Array<UserRetrieveNameResponseType>
    fetchUserRetrieveNameResponses.forEach((response) => {
      retrieveNameUserData = [...(response.data?.results ?? []), ...retrieveNameUserData]
    })

    retrieveNameUsers.value =
      retrieveNameUserData.length > 0
        ? uniqBy([...retrieveNameUsers.value, ...retrieveNameUserData], 'userId')
        : retrieveNameUsers.value
    return fetchUserRetrieveNameResponses
  }

  /**
   * userRetrieveNameCollectionModuleのデータをクリアする
   */
  const clearRetrieveNameUsers = () => {
    retrieveNameUsers.value = []
  }

  return {
    retrieveNameUserList,
    retrieveNameUsersByUserId,
    fetchRetrieveNameUsers,
    fetchedRetrieveNameUserIds,
    clearRetrieveNameUsers,
  }
}
