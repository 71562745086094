var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-scale-parts" }, [
    _c("span", { staticClass: "map-scale-parts__value" }, [
      _vm._v(_vm._s(_vm.mapScaleValue) + "m"),
    ]),
    _c("div", {
      staticClass: "map-scale-parts__gauge",
      style: "width: " + _vm.mapScaleGaugeWidth + "px",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }