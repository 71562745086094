var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "share-icon-pars",
      attrs: {
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2.25 7.52762H4.99888V9.02761H3.75V16.0276H14.25V9.02761H13V7.52762H15.75V17.5276H2.25V7.52762Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.24914 3.36854L6.16784 5.44984H4.04652L8.28916 1.2072C8.67969 0.816675 9.31285 0.816676 9.70338 1.2072L13.946 5.44984H11.8247L9.74914 3.37428L9.74914 12.308L8.24914 12.308L8.24914 3.36854Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }