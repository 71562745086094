






















































import { computed, defineComponent } from '@vue/composition-api'
import useTelemetrySectionUtil from '@/components/RaceVideoPage/RaceVideoDataViewPane/hook/useTelemetrySectionUtil'

/**
 * レース動画再生画面 テレメトリー 燃料使用量積算値パーツのコンポーネント
 */
export default defineComponent({
  name: 'TelemetryFuelUsedParts',
  components: {},
  props: {
    /**
     * バリュー値
     */
    value: {
      type: Number,
      required: true,
    },
    /**
     * 表示fpsと受信データ間隔の差分ms
     */
    diffMs: {
      type: Number,
      required: true,
    },
    /**
     * ゲージMAX値
     */
    max: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { adjustPosition } = useTelemetrySectionUtil()

    // 位置指定を整数化
    adjustPosition('.telemetry-fuel-used-parts')

    // SVGゲージ横幅
    const gaugeSvgWidth = 123

    // 燃料値が設定されているかどうか
    // -1 の場合、テレメトリーデータが取得できていない状態のため、無効状態フラグを立てる
    const invalidFuel = computed(() => props.value === -1)

    /**
     * gaugeWidth
     * ゲージの横幅を算出
     */
    const gaugeWidth = computed(() =>
      invalidFuel.value ? 0 : gaugeSvgWidth - (gaugeSvgWidth * props.value) / props.max,
    )

    /**
     * capacityUsed
     * 使用容量 表示
     */
    const capacityUsed = computed(() => {
      if (invalidFuel.value) {
        return 0
      }
      const num: number = 80 - props.value
      // 稀に小数点以下10数桁までの半端な値になる場合があるため小数点1位までに四捨五入
      return Math.round(num * 10) / 10
    })

    /**
     * fuelLow
     * 使用容量が 75 を超えた場合にtrue判定
     */
    const fuelLow = computed(() => !invalidFuel.value && props.value >= 75)

    return {
      gaugeWidth,
      capacityUsed,
      fuelLow,
    }
  },
  methods: {},
})
