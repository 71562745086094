var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "about-using-data-list-parts" },
    _vm._l(_vm.aboutUsingListItems, function (v, i) {
      return _c(
        "li",
        { key: i, staticClass: "about-using-data-list-parts__list" },
        [
          _c(
            "a",
            {
              staticClass: "about-using-data-list-parts__item",
              attrs: { href: v.link, target: "_blank" },
            },
            [
              _c("span", {
                staticClass: "about-using-data-list-parts__label",
                domProps: { innerHTML: _vm._s(v.name) },
              }),
              _c("img", {
                staticClass: "about-using-data-list-parts__icon",
                attrs: {
                  src: require("@/assets/img/icon/icon_external_link_outline.svg"),
                  alt: "",
                },
              }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }