

























import { defineComponent, ref, watch, Ref } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import StoreUtil from '@/store/StoreUtil'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'

/**
 * マイページ: ワンタイムパス: 入力セクション
 */
export default defineComponent({
  name: 'OneTimePassInputSection',
  components: {
    AtomInputText,
    AtomInputButton,
    FormErrorMessageParts,
  },
  setup(props, context) {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { fetchTargetCoupon, targetOneTimePass, clearCoupons } = oneTimePassPageStore

    const oneTimePass = ref<string>('')
    const oneTimePassError = ref<boolean>(false)
    const oneTimePassErrorMessage = ref<string>('')
    /** 取得したワンタイムパス情報 */
    const targetOneTimePassItem = ref(undefined) as Ref<CouponDocument | undefined>

    /**
     * ワンタイムパスチェック関数
     * @param inputtedValue
     */
    const validateOneTimePass = (inputtedValue: string) => {
      const contractInfoPlans = oneTimePassPageStore.contractInfoPlans.value

      // 入力値がブランクの場合、エラーを返す
      if (inputtedValue === '') {
        oneTimePassError.value = true
        oneTimePassErrorMessage.value = I18n.tc('formParts.errorMessage.oneTimePassBlank')
        return
      }

      targetOneTimePassItem.value = targetOneTimePass(inputtedValue)
      // ワンタイムパスが見つからなかった場合、エラーを返す
      if (!targetOneTimePassItem.value) {
        oneTimePassError.value = true
        oneTimePassErrorMessage.value = I18n.tc('formParts.errorMessage.oneTimePassError')
        return
      }
      // すでに使用済みのワンタイムパスの場合、エラーを返す
      if (
        targetOneTimePassItem.value &&
        contractInfoPlans.find((v) => v.couponId === targetOneTimePassItem.value?.couponId)
      ) {
        oneTimePassError.value = true
        oneTimePassErrorMessage.value = I18n.tc('formParts.errorMessage.oneTimePassAlreadyUsed')
        return
      }

      oneTimePassError.value = false
    }

    /**
     * ワンタイムパスの入力値が変化したタイミングでバリデーションエラーを消す
     */
    watch(
      () => oneTimePass.value,
      () => {
        oneTimePassError.value = false
      },
    )

    /**
     * ワンタイムパス登録ボタン押下時処理
     */
    const handlerSubmit = async () => {
      clearCoupons()
      if (oneTimePass.value) {
        /** 入力したクーポンコードを取得 */
        const result = await fetchTargetCoupon(oneTimePass.value)
        if (!result.isSuccess && result.response?.isNetworkError) {
          // ネットワークエラーでクーポンを取得できなかった場合は、エラーメッセージを表示する
          await MessageDialogStore.value.open?.({
            errorApiResponse: result.response,
            isVisibleCancelButton: false,
          })
        }
      }

      /** バリデーションチェックを実行 */
      validateOneTimePass(oneTimePass.value)

      // エラーがある場合、画面遷移しない
      if (oneTimePassError.value) {
        return
      }

      context.emit('handlerSubmit', targetOneTimePassItem.value)
    }

    return {
      oneTimePass,
      oneTimePassError,
      oneTimePassErrorMessage,
      handlerSubmit,
    }
  },
})
