var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-selector-parts" }, [
    _c(
      "div",
      {
        staticClass: "filter-selector-parts__select",
        class: {
          "filter-selector-parts__select--current": _vm.filterListEnabled,
        },
        on: { click: _vm.enabledFilter },
      },
      [
        _c("span", { staticClass: "filter-selector-parts__select__label" }, [
          _vm._v(_vm._s(_vm.filterLabel)),
        ]),
        _c(
          "svg",
          {
            staticClass: "filter-selector-parts__select__icon",
            attrs: {
              width: "18",
              height: "18",
              viewBox: "0 0 18 18",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M1.93945 6L7.76268 11.8232C8.44609 12.5066 9.55413 12.5066 10.2375 11.8232L16.0608 6H13.9395L9.17689 10.7626C9.07926 10.8602 8.92097 10.8602 8.82334 10.7626L4.06077 6H1.93945Z",
                fill: "white",
              },
            }),
          ]
        ),
      ]
    ),
    _c("div", {
      staticClass: "filter-selector-parts__mask",
      class: { "filter-selector-parts__mask--current": _vm.filterListEnabled },
      on: {
        click: function ($event) {
          return _vm.changeFilter(null)
        },
      },
    }),
    _c(
      "div",
      {
        staticClass: "filter-selector-parts__list",
        class: {
          "filter-selector-parts__list--current": _vm.filterListEnabled,
        },
      },
      [
        _c(
          "button",
          {
            staticClass: "filter-selector-parts__list__item",
            class: {
              "filter-selector-parts__list__item--current": _vm.selectId === "",
            },
            on: {
              click: function ($event) {
                return _vm.changeFilter("")
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.defaultListItemLabel))]),
            _vm.selectId === ""
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/icon_check_18__green.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
          ]
        ),
        _vm._l(_vm.filterList, function (item, i) {
          return _c(
            "button",
            {
              key: i,
              staticClass: "filter-selector-parts__list__item",
              class: {
                "filter-selector-parts__list__item--current":
                  _vm.selectId === item.id,
              },
              on: {
                click: function ($event) {
                  return _vm.changeFilter(item.id, item.type)
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(item.name[_vm.$i18n.locale]))]),
              _vm.selectId === item.id
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon/icon_check_18__green.svg"),
                      alt: "",
                    },
                  })
                : _vm._e(),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }