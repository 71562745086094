









































import { defineComponent, PropType } from '@vue/composition-api'
import PlayerCardParts from '@/components/common/card/PlayerCardParts.vue'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import I18n from '@/locales/I18n'

/**
 * Standings選手一覧表示用データの型
 */
export type StandingsPlayerDataType = {
  name: Multilingual
  teamName: string
  playerPictureImagePath: string
  rank: string
  rankNum: number
  carNo: string
  carNoNum: number
  totalPts: number
  link: string
}

/**
 * Standings: 一覧用 選手パーツ
 */
export default defineComponent({
  name: 'StandingsListPlayerParts',
  components: {
    PlayerCardParts,
  },
  props: {
    standingsPlayer: {
      type: Object as PropType<StandingsPlayerDataType>,
      required: true,
    },
  },
  methods: {
    /**
     * 選手名を返す
     * @param name
     */
    setPlayerName(name: Multilingual): string {
      return name[I18n.locale] ?? ''
    },
  },
})
