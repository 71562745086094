var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "common-modal-section",
    {
      staticClass: "mypage-change-paid-plan-modal-section",
      on: { close: _vm.handleCloseClicked },
    },
    [
      _c(
        "div",
        {
          staticClass: "mypage-change-paid-plan-modal-section__header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c(
            "p",
            { staticClass: "mypage-change-paid-plan-modal-section__title" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mypage-change-paid-plan-modal-section__content",
          attrs: { slot: "default" },
          slot: "default",
        },
        [
          _c("P", {
            staticClass: "mypage-change-paid-plan-modal-section__message",
            domProps: { innerHTML: _vm._s(_vm.description) },
          }),
          false
            ? _c("atom-icon-link-button", {
                staticClass: "mypage-change-paid-plan-modal-section__link",
                attrs: {
                  url: _vm.linkUrl,
                  linkText: _vm.$tc("MypagePage.PaidCompleteModal.linkLabel"),
                  colorType: "tertiary",
                  imgPath: require("@/assets/img/icon/icon_external_link_gray.svg"),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mypage-change-paid-plan-modal-section__button",
          attrs: { slot: "buttons" },
          slot: "buttons",
        },
        [
          _vm.deviceNotSupportedMessage
            ? _c("P", {
                staticClass:
                  "mypage-change-paid-plan-modal-section__not-support-message",
                domProps: { innerHTML: _vm._s(_vm.deviceNotSupportedMessage) },
              })
            : _vm._e(),
          _c("atom-link-button", {
            attrs: {
              linkText: _vm.$tc(
                "MypagePage.MypageTopPage.InAppPurchaseModal.buttonLabel"
              ),
              url: _vm.url,
              colorType: "secondary",
            },
            on: { onClick: _vm.handleOkClicked },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }