
















































































































































































import { computed, defineComponent } from '@vue/composition-api'
import usePermission from '@/components/hook/usePermission'
import StoreUtil from '@/store/StoreUtil'

/**
 * レース動画再生画面 動画情報切り替えメニューセクションのコンポーネント
 */
export default defineComponent({
  name: 'DataViewSelectMenuSection',
  components: {},
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * データ表示モード指定
     */
    viewMode: {
      type: String,
      default: 'ranking',
    },
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { hasNewHighlight } = raceVideoPageStore

    /**
     * ハイライト新着通知アイコンを表示するかどうか
     */
    const newHighlightIcon = computed(() => hasNewHighlight.value)

    return {
      newHighlightIcon,
      usePermission,
    }
  },
  methods: {
    changeViewMode(changeMode: string): void {
      /**
       * 表示切り替え
       * @param changeMode
       */
      if (this.viewMode === changeMode) return

      this.$emit('viewModeChange', changeMode)
    },
  },
})
