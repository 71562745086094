

















































import { computed, defineComponent, onUnmounted, PropType, toRefs } from '@vue/composition-api'
import RacePlayerInfoSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RacePlayerInfoSection.vue'
import PlayerSelectModalSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/PlayerSelectModalSection.vue'
import RaceSelectSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RaceSelectSection.vue'
import RankingSettingSection from '@/components/RaceVideoPage/RaceVideoRankingPane/RankingSettingSection.vue'
import RankingListSection from '@/components/RaceVideoPage/RaceVideoRankingPane/RankingListSection.vue'
import StoreUtil from '@/store/StoreUtil'
import useRanking from '@/components/RaceVideoPage/hook/useRanking'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * 表示モード
 */
export type RankingViewMode = 'gap' | 'diff' | 'lastLapBestTime'

type DataType = {
  rankingViewMode: RankingViewMode
  rankingViewModeList: RankingViewMode[]
}

/**
 * レース動画再生画面 ランキングセクションペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceVideoRankingPane',
  components: {
    RacePlayerInfoSection,
    PlayerSelectModalSection,
    RaceSelectSection,
    RankingSettingSection,
    RankingListSection,
  },
  data(): DataType {
    return {
      rankingViewMode: 'gap', // データ表示モード指定 Gap | Diff | LastLap/BestTime
      rankingViewModeList: ['gap', 'diff', 'lastLapBestTime'], // データ表示モード選択肢
    }
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * 無線音声 ON/OFFフラグ
     */
    radioVoiceEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * レース選択 開閉フラグ
     */
    raceSelectEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選手選択モーダル表示フラグ
     */
    racePlayerSelectModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { getCurrentPlayerRadioDataSetByPlayer } = useRadio()
    const {
      getCurrentPlayerRankingDataList,
      getCurrentPlayerTelemetry,
      getCurrentBattleData,
      clearRankingPlayerData,
      getCurrentFlagStatus,
      isLeaderFlagChecked,
    } = useRanking()

    const { setViewAngle, user, isLiveNotStarted, fetchRaceData, currentRaceId, highlightStore } =
      raceVideoPageStore
    const { selectedHighlightId } = highlightStore
    const { videoPlayer } = toRefs(raceVideoPageStore.raceVideoPageState)
    const favoriteRacePlayerSelected = computed(() => !!user.value?.favoritePlayerId)
    const selectedID = computed(() => raceVideoPageStore.raceVideoPageState.selectedPlayerId)

    const updateCurrentTime = (currentTime: number) => {
      videoPlayer.value?.setCurrentTime(currentTime)
    }

    onUnmounted(() => {
      clearRankingPlayerData()
    })

    return {
      favoriteRacePlayerSelected,
      setViewAngle,
      selectedID,
      isLiveNotStarted,
      raceVideoPageStore,
      fetchRaceData,
      selectedHighlightId,
      currentRaceId,
      videoPlayer,
      updateCurrentTime,
      getCurrentPlayerRankingDataList,
      getCurrentPlayerTelemetry,
      getCurrentBattleData,
      getCurrentFlagStatus,
      isLeaderFlagChecked,
      getCurrentPlayerRadioDataSetByPlayer,
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeRouteEnter(to: any, from: any, next: any) {
    next()
  },
  methods: {
    /**
     * 無線音声 ON/OFFボタンが押下されたことを通知する
     */
    toggleRadioVoice(): void {
      this.$emit('radioVoiceToggle')
    },
    /**
     * レース選択ボタンが押下されたことを通知する
     */
    toggleRaceSelect(state?: string): void {
      this.$emit('raceSelectToggle', state)
    },
    /**
     * 選手選択モーダル表示
     */
    showPlayerSelectModal(): void {
      this.$emit('playerSelectModalShow', true)
    },
    /**
     * 選択選手変更処理
     * @event changePlayer
     * @param submitType
     */
    changePlayer(submitType: string) {
      if (submitType === 'change') {
        // 選手を選択した場合、自動的にアングルを選手に変更する
        this.setViewAngle('player')
        // 視聴履歴取得
        this.raceVideoPageStore.saveHistory()
      }
      this.$emit('playerSelectModalShow', false)
    },
    changeScenes(raceId: string, highlightId: string) {
      if (this.currentRaceId !== raceId) {
        this.currentRaceId = raceId
      }
      if (highlightId) {
        this.selectedHighlightId = highlightId
      } else {
        this.updateCurrentTime(0)
      }
    },
    /**
     * 表示データ切り替え
     * @param mode: string
     */
    changeRankingViewMode(mode: RankingViewMode) {
      this.rankingViewMode = mode
      if (mode !== 'gap') {
        this.isLeaderFlagChecked = false
      }
    },
    /**
     * Leader チェックボックス押下
     */
    toggleLeader() {
      this.isLeaderFlagChecked = !this.isLeaderFlagChecked
    },
  },
})
