













import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'

/**
 * テキストエリアインプットフィールド
 */
export default defineComponent({
  name: 'AtomTextArea',
  props: {
    /**
     * ID
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Value値
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * Error判定
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * プレースホルダー
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * maxlength値
     */
    maxlength: {
      type: Number,
      default: 0,
    },
    /**
     * 非活性フラグ
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * マウント時フォーカスフラグ
     */
    focusOnMount: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * インプットの値
     * @param {string} val インプットの値
     */
    const inputValue = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })

    const textareaRef = ref<HTMLTextAreaElement | null>(null)
    const focusTextarea = () => {
      if (textareaRef.value) {
        textareaRef.value.focus()
        textareaRef.value.setSelectionRange(0, 0)
        textareaRef.value.scrollTop = 0
      }
    }

    onMounted(() => {
      if (props.focusOnMount) {
        focusTextarea()
      }
    })

    return {
      inputValue,
      textareaRef,
      focusTextarea,
    }
  },
})
