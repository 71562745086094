var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-reset-mail-input-section" },
    [
      _c("p", {
        staticClass: "password-reset-mail-input-section__message",
        domProps: {
          innerHTML: _vm._s(_vm.$t("PasswordResetPage.MailInputPage.message")),
        },
      }),
      _c(
        "div",
        { staticClass: "password-reset-mail-input-section__input-area" },
        [
          _c("fieldset-email-parts", {
            staticClass: "modal__fieldset",
            attrs: {
              showError: _vm.showEMailError,
              isReadOnly: false,
              required: false,
              errorMessage: _vm.$t("LoginPage.errorEmail"),
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _c("atom-input-button", {
        staticClass: "password-reset-mail-input-section__send-mail-btn",
        attrs: {
          linkText: _vm.$t("MypagePage.MypageUserEditPage.mailEditModalButton"),
          isDisabled: _vm.email === "",
        },
        on: { push: _vm.sendCertificationMail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }