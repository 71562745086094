


























































import { defineComponent, PropType } from '@vue/composition-api'
import sanitizeHtml from 'sanitize-html'
import CalendarDataDocument from '@/store/stores/collectionModule/documents/calendar/CalendarDataDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'CalendarItemSection',
  methods: { sanitizeHtml },
  props: {
    calendarItem: {
      type: Object as PropType<CalendarDataDocument>,
      required: true,
    },
  },
  setup() {
    const { getDisplayDateJa } = useDisplayDependingOnLang()

    /** PCブラウザかどうか */
    const isPc = DeviceInfo.isForPC()
    // TODO: 将来的な対応として、イベント名のリンク先をManagementから登録するファイルで指定できるようにする
    const externalLinks = ''

    /**
     * カレンダーに表示するイベント日付
     */
    const displayEventDate = (eventDate: number | null) => {
      if (!eventDate) {
        return ''
      }

      return getDisplayDateJa(eventDate, 'M/D', 'MMM.D')
    }

    /**
     * カレンダーに表示する曜日
     */
    const displayEventDayOfWeek = (eventDate: number | null) => {
      if (!eventDate) {
        return ''
      }

      return getDisplayDateJa(eventDate, 'ddd', 'ddd')
    }

    /**
     * イベント名
     */
    const disPlayEventName = (eventName: string | null) =>
      // 改行コードを <br>に変換し、サニタイジングする
      eventName ? sanitizeHtml(eventName.replace(/(\r\n|\r|\n)/g, '<br>')) : ''

    return {
      isPc,
      externalLinks,
      displayEventDate,
      displayEventDayOfWeek,
      disPlayEventName,
    }
  },
})
