var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-password-parts" },
    [
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(
            _vm._s(
              _vm.label !== "" ? _vm.label : _vm.$t("formParts.label.password")
            )
          ),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("password-with-toggle-button-parts", {
        staticClass: "login-inputs-parts__password-field",
        attrs: {
          placeholder: _vm.$t("formParts.placeholder.password"),
          error: _vm.showError,
          isReadOnly: _vm.isReadOnly,
        },
        on: { enter: _vm.emitEnter, focus: _vm.emitFocus, blur: _vm.emitBlur },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }