var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "modal" }, [
      _c("div", {
        staticClass: "modal__mask",
        on: {
          click: function ($event) {
            return _vm.emitClose()
          },
        },
      }),
      _c("div", { staticClass: "modal__wrapper" }, [
        _vm.hasCloseButton
          ? _c(
              "button",
              {
                staticClass: "modal__close-button",
                on: {
                  click: function ($event) {
                    return _vm.emitClose()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/icon/icon_close_24.svg"),
                    alt: "閉じる",
                  },
                }),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "modal__header" }, [_vm._t("header")], 2),
        _c("div", { staticClass: "modal__content" }, [_vm._t("default")], 2),
        _vm.hasButtons
          ? _c("div", { staticClass: "modal__buttons" }, [_vm._t("buttons")], 2)
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }