var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "official-post-item-contents-parts" }, [
    _c(
      "a",
      {
        staticClass: "official-post-item-contents-parts__link",
        attrs: { href: _vm.snsPost.link, target: "_blank" },
      },
      [
        _c("p", { staticClass: "official-post-item-contents-parts__text" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.snsPost.post) } }),
          _c("i", [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_external_link_gray.svg"),
                alt: "",
              },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "official-post-item-contents-parts__footer" },
          [
            _c("p", {
              staticClass: "official-post-item-contents-parts__time",
              domProps: { textContent: _vm._s(_vm.snsPost.time) },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }