

























import { defineComponent, PropType } from '@vue/composition-api'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'

export type PurchasedTicketStatusType = 'noTickets' | 'hasTickets' | 'hasAvailableTodayTickets'

export default defineComponent({
  name: 'TicketLinkParts',
  components: {
    AtomIconLinkButton,
    AtomRouterButton,
  },
  props: {
    purchasedTicketStatus: {
      type: String as PropType<PurchasedTicketStatusType>,
      default: 'noTickets',
    },
  },
  setup() {
    // ECサイトURL
    const ecSiteUrl =
      I18n.locale === 'ja'
        ? Const.EXTERNAL_LINKS.ABOUT_EC_SITE.JA
        : Const.EXTERNAL_LINKS.ABOUT_EC_SITE.EN

    return {
      ecSiteUrl,
    }
  },
})
