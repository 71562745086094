import { RoleType } from '@/store/stores/pageStore/common/RoleType'
import { RoleForUserType } from '@/store/stores/loginStore/RoleType'

/**
 * ユーザーロール取得処理のhook
 */
export default function useUserTypeRoles() {
  const getUserTypeRoles = (
    roles: RoleType[] | null | undefined,
  ): RoleForUserType[] | undefined => {
    if (!roles || roles.length === 0) return undefined
    return roles.filter(
      (role): role is Exclude<typeof role, 'sfgo-dev-supporter'> => role !== 'sfgo-dev-supporter',
    )
  }

  return {
    getUserTypeRoles,
  }
}
