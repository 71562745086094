









import { defineComponent } from '@vue/composition-api'
import ExternalConfirmationSection from '@/components/common/ExternalConfirmation/ExternalConfirmationSection.vue'

/**
 * アプリ版: プラン変更(有料=>有料) ペイン
 */
export default defineComponent({
  name: 'MypagePaidPlanChangeEditPane',
  components: {
    ExternalConfirmationSection,
  },
})
