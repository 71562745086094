import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoGooglePlayDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoGooglePlayDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * アプリ内課金 契約情報を操作するための処理を提供する。
 */
export default function useContractInfoGooglePlay() {
  // Collection modules
  const contractInfoGooglePlayCollectionModule = CollectionModule.createStore(
    ContractInfoGooglePlayDocument,
  )

  /**
   * アプリ内課金 GooglePlay用: 契約情報を更新する。
   * @return APIレスポンス
   */
  const updateContractInfoGooglePlay = async (requestData: ContractInfoGooglePlayDocument) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${requestData.contractInfoId}/in_app_purchase/googleplay`,
    }
    return contractInfoGooglePlayCollectionModule.save(requestData, options)
  }

  /**
   * 取得した契約情報をクリアする
   */
  const clearContractInfoGooglePlay = () => {
    contractInfoGooglePlayCollectionModule.clearData()
  }

  return {
    updateContractInfoGooglePlay,
    clearContractInfoGooglePlay,
  }
}
