






















import { computed, defineComponent } from '@vue/composition-api'
import TrophyIconParts from '@/components/StandingsPage/common/parts/TrophyIconParts.vue'

export type RoundRecordDataType = {
  title: string
  record: string
  position: number
}

/**
 * Standings: レース詳細 各レコード パーツ
 */
export default defineComponent({
  name: 'RoundRecordParts',
  components: {
    TrophyIconParts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    /** 表示用の順位 */
    const displayPosition = computed(() => (props.position ? String(props.position) : '---'))

    return {
      displayPosition,
    }
  },
})
