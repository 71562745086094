var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-card-section",
      class: "color-type-" + _vm.mission.colorType,
    },
    [
      !_vm.mission.hasCompleted
        ? _c("div", { staticClass: "mission-card-section__default" }, [
            _c("div", { staticClass: "mission-card-section__header" }, [
              _c(
                "p",
                { staticClass: "mission-card-section__title" },
                [
                  _vm._t("header-icon"),
                  _c("span", [_vm._v(_vm._s(_vm.mission.missionName))]),
                ],
                2
              ),
              _c("div", { staticClass: "mission-card-section__header-info" }, [
                _c("p", { staticClass: "mission-card-section__description" }, [
                  _vm._v(_vm._s(_vm.mission.description)),
                ]),
                _vm.mission.nextAchievementPoint
                  ? _c("p", { staticClass: "mission-card-section__point" }, [
                      _vm._v(
                        " +" + _vm._s(_vm.mission.nextAchievementPoint) + "P "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "mission-card-section__body",
                class: { "mission-card-section__body-no-space": _vm.noSpace },
              },
              [_vm._t("default")],
              2
            ),
            _vm.displayFooter
              ? _c("details", { staticClass: "mission-card-section__footer" }, [
                  _c(
                    "summary",
                    { staticClass: "mission-card-section__accordion" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$tc("MissionPage.howTo"))),
                      ]),
                      _c("mission-accordion-icon", {
                        staticClass: "mission-card-section__accordion-icon",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mission-card-section__how" },
                    [_vm._t("howto")],
                    2
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "mission-card-section__complete" }, [
            _c(
              "p",
              { staticClass: "mission-card-section__complete-title" },
              [
                _vm._t("header-icon"),
                _c(
                  "span",
                  {
                    ref: "missionName",
                    class: { "is-wide": _vm.state.isMissionNameWide },
                  },
                  [_vm._v(_vm._s(_vm.mission.missionName))]
                ),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mission-card-section__complete-body" },
              [_vm._t("default")],
              2
            ),
            _c(
              "div",
              { staticClass: "mission-card-section__complete-message" },
              [_c("mission-complete-icon")],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }