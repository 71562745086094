











import { defineComponent, PropType } from '@vue/composition-api'
import AtomInputRadio from '@/components/atoms/input/AtomInputRadio.vue'
import { PlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

/**
 * ラジオ選択カード セクション
 */
export default defineComponent({
  name: 'ToggleRadioCardParts',
  components: {
    AtomInputRadio,
  },
  props: {
    /**
     * 選択中のvalue値
     */
    currentValue: {
      type: String as PropType<PlanGroupType>,
      required: true,
    },
    /**
     * ラヂオのvalue値
     */
    radioValue: {
      type: String as PropType<PlanGroupType>,
      required: true,
    },
  },
  setup(props, context) {
    const handlerOnClick = (val: string) => context.emit('handlerOnClick', val)

    return {
      handlerOnClick,
    }
  },
})
