var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "race-list-header-parts",
      class: {
        "race-list-header-parts--show-timetable": _vm.showTimetable,
        "race-list-header-parts--for-pc": _vm.forPc,
      },
    },
    [
      _c("p", { staticClass: "race-list-header-parts__data" }, [
        _vm.round !== 0
          ? _c("span", { staticClass: "race-list-header-parts__round" }, [
              _vm._v(" ROUND " + _vm._s(_vm.round) + " "),
            ])
          : _vm._e(),
        _c("span", { staticClass: "race-list-header-parts__date" }, [
          _vm._v(_vm._s(_vm.raceDate)),
        ]),
      ]),
      _c("h3", { staticClass: "race-list-header-parts__venue" }, [
        _vm._v(_vm._s(_vm.raceVenue)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }