









































import { defineComponent, PropType } from '@vue/composition-api'

/**
 * レース一覧画面: 年度選択コンポーネント
 */
export default defineComponent({
  name: 'SelectYearSection',
  props: {
    /**
     * 年度データ
     */
    raceYear: {
      type: Number,
      required: true,
    },
    /**
     * レース選択年度
     */
    yearList: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  data() {
    return {
      year: this.raceYear,
      isOpenOption: false,
    }
  },
  methods: {
    change(item: number) {
      /**
       * 年度を選択されたことを通知する
       * @event change
       * @type {Object}
       */
      this.$emit('change', item)
      this.isOpenOption = false
    },
    handleCurrentClick() {
      this.isOpenOption = !this.isOpenOption
    },
  },
  watch: {
    raceYear(value) {
      this.year = value
    },
  },
})
