













import { computed, defineComponent, onBeforeMount, ref, Ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CompletePane from '@/components/pc/MyPage/CompletePane/CompletePane.vue'
import { AttentionMessageData } from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import { PaymentScheduleData } from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import usePaymentScheduleOnChange from '@/components/pc/MyPage/CompletePane/hook/usePaymentScheduleOnChange'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import useContractTerm from '@/components/hook/useContractTerm'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * 有料会員変更 完了ページ
 */
export default defineComponent({
  name: 'PaidPlanChangeCompletePage',
  components: {
    SubHeaderSection,
    CompletePane,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
    const { futureContactInfoPlan, getTargetContractPlan } = mypagePageStore
    const { getPaymentScheduleOnChange } = usePaymentScheduleOnChange()
    const { getScheduledContractTerm, getReferenceDate } = useContractTerm()
    const { getDisplayDate } = useDisplayDependingOnLang()

    /** 注意事項テキスト */
    const attentionMessage: Ref<AttentionMessageData | null> = ref(null)
    /** 今後の請求情報 */
    const paymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)
    /** 月額プランの契約を更新済みかどうか */
    const hasRenewedMonthlyPlan = ref(false)

    /** 将来のプラン */
    const futurePlan = computed(() =>
      futureContactInfoPlan?.value?.contractPlanId
        ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
        : null,
    )

    onBeforeMount(async () => {
      if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
        /**
         * 月額プラン→年額プラン切り替え手続き
         */
        // 現在時刻を取得
        const now = dayjs().valueOf()
        // 基準日（契約更新予定日 or 契約更新日）を取得
        const referenceDate = getReferenceDate(effectiveOrgContract?.startDate ?? 0)
        // 契約更新済みかどうか
        hasRenewedMonthlyPlan.value = referenceDate < now

        /** 請求予定カードに表示する情報を取得 */
        paymentSchedule.value = await getPaymentScheduleOnChange(hasRenewedMonthlyPlan.value)
      } else if (effectiveContractPlan?.planGroupId === 'annualPlan') {
        /**
         * 年額プラン→月額プラン切り替え手続き
         */
        paymentSchedule.value = await getPaymentScheduleOnChange()
      }

      /** 注意事項テキストをセット */
      if (
        futurePlan.value?.planGroupId === 'annualPlan' ||
        futurePlan.value?.planGroupId === 'monthlyPlan'
      ) {
        /** 契約予定のプランの契約期間 */
        const scheduledContractTerm = getScheduledContractTerm(
          effectiveContractPlan as ContractPlanDocument,
          effectiveOrgContract as ContractType,
          futurePlan.value?.planGroupId,
        )
        // 契約予定のプランの開始日
        const scheduledContractStartDate = getDisplayDate(scheduledContractTerm.contractStartDate)

        // 先頭を大文字にしたplanGroupId
        const firstLetterCapitalizedPlanGroupId =
          futurePlan.value.planGroupId.charAt(0).toUpperCase() +
          futurePlan.value.planGroupId.slice(1)

        attentionMessage.value = {
          message: I18n.t(
            `PaidPlanChange.CompletePage.changeTo${firstLetterCapitalizedPlanGroupId}.attentionMessage`,
            {
              changeDate: scheduledContractStartDate,
              plan: futurePlan.value.name[I18n.locale],
            },
          ).toString(),
          note: null,
        }
      }
    })

    return {
      attentionMessage,
      paymentSchedule,
    }
  },
})
