var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "password-change-modal-section",
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("modal-title-parts", {
              attrs: {
                title: _vm.$t(
                  "MypagePage.MypageUserEditPage.PasswordChangeModalTitle"
                ),
              },
            }),
            _c("fieldset-password-change-parts", {
              staticClass:
                "modal__fieldset password-change-modal-section__fieldset",
              attrs: {
                oldPasswordErrorMessage: _vm.oldPasswordErrorMessage,
                newPasswordErrorMessage: _vm.newPasswordErrorMessage,
                newPasswordReEnterErrorMessage:
                  _vm.newPasswordReEnterErrorMessage,
                showOldPasswordError: _vm.showOldPasswordError,
                showNewPasswordError: _vm.showNewPasswordError,
                showNewPasswordReEnterError: _vm.showNewPasswordReEnterError,
              },
              model: {
                value: _vm.newPassword,
                callback: function ($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$t(
                  "MypagePage.MypageUserEditPage.PasswordChangeModalButton"
                ),
                isDisabled: _vm.disablePasswordChangeButtonFlag(),
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose("submit")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }