var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navigation-standings-icon-parts",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "currentColor",
          d: "M23.1,15.6c2-5.3-1.3-14.1-9.3-14.1c-5.9,0-8.4,2.6-10.3,6.7C2.8,9.5,2.2,11,1.6,12.6c-0.4,0.9-0.7,1.9-1.1,2.9l0,0.1v3.2l2.7,3.3h16L23.1,15.6z M4.8,8.7C4.6,9.3,4.3,9.9,4,10.6c-0.3,0.8-0.7,1.7-1,2.6l0,0.1c-0.3,0.7-0.6,1.5-0.9,2.3H9c0.5,0,0.9-0.2,1.3-0.5l3.4-2.9L4.8,8.7z M17.7,12.8c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3C17.1,11.5,17.7,12.1,17.7,12.8z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }