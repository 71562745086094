











































import { computed, defineComponent, PropType } from '@vue/composition-api'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import I18n from '@/locales/I18n'
import {
  ContractTermType,
  ProcedureScreenType,
} from '@/store/stores/collectionModule/documents/GeneralTypes'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * プランカード
 */
export default defineComponent({
  name: 'PlanCardParts',
  components: {},
  props: {
    /**
     * 表示するプラン
     */
    plan: {
      type: Object as PropType<ContractPlanDocument>,
      required: true,
    },
    /**
     * お得額
     */
    bargainPriced: {
      type: String,
      default: '',
    },
    /**
     * 確認画面かどうか
     */
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    /**
     * 契約中のプランの契約期間
     */
    contractTerm: {
      type: Object as PropType<ContractTermType>,
      default: null,
    },
    /**
     * どの手続き画面か
     */
    procedureScreen: {
      type: String as PropType<ProcedureScreenType>,
      default: null,
    },
  },
  setup(props) {
    const { getDisplayDate } = useDisplayDependingOnLang()
    /** キャプション
     * クーポンプランはキャプションなし
     */
    const planCaption =
      props.plan.planGroupId === 'freePlan' ||
      props.plan.planGroupId === 'annualPlan' ||
      props.plan.planGroupId === 'monthlyPlan'
        ? I18n.tc(`SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.caption`)
        : ''

    /** 金額の単位（現状は円のみ） */
    const planPriceUnit = I18n.tc('SignupPage.SelectPlanPage.plan.priceUnit')
    /** 支払い単位（年払い/月払い） */
    const planPaymentUnit = I18n.tc(
      `SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.paymentUnit`,
    )
    /** お得額 */
    const planBargainPriced = props.bargainPriced
      ? I18n.t('SignupPage.SelectPlanPage.plan.bargainPriced', {
          percent: props.bargainPriced,
        }).toString()
      : ''
    /** 注意事項 */
    const planAttention =
      props.plan.planGroupId === 'freePlan'
        ? I18n.tc(`SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.attention`)
        : ''

    /** 契約期間を表示
     * 新規の場合：本日の日付を開始日とし、契約するプランに応じて終了日を設定する
     * 契約中のプランを表示、またはプランアップグレードの場合：親から渡ってくる開始日、終了日を表示
     */
    const getContractTermText = computed(() => {
      if (props.contractTerm) {
        // 契約中のプラン期間を表示
        const startDate = getDisplayDate(props.contractTerm.contractStartDate)
        // 無料プランの場合はendDateがセットされていないことを考慮する
        const endDate = props.contractTerm.contractEndDate
          ? getDisplayDate(props.contractTerm.contractEndDate)
          : ''
        return endDate
          ? `${I18n.t('SignupPage.SelectPlanPage.plan.contractTerm', {
              startDate,
              endDate,
            }).toString()}`
          : `${I18n.t('SignupPage.SelectPlanPage.plan.contractTermEmptyEndDate', {
              startDate,
            }).toString()}`
      }

      // 切り替え予定のプラン期間を表示
      return props.plan.nextContractTermText
    })

    // プラン名
    const planName = computed(() => props.plan.name[I18n.locale])

    // プラン料金
    const planPrice = props.plan.price?.JPY.toLocaleString()

    /** プランの説明（請求に関する説明を記載している） */
    const planDescription = computed(() => {
      if (props.plan.planGroupId === 'monthlyPlan') {
        return I18n.tc(`SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.description`)
      }

      if (props.plan.planGroupId === 'annualPlan') {
        if (props.procedureScreen === 'register' || props.procedureScreen === 'paidMembership') {
          // 新規プラン登録画面 または （無料から）有料プランに切り替える画面の場合
          return I18n.tc(
            `SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.description.immediate`,
          )
        }
        if (props.procedureScreen === 'paidPlanChange') {
          // 支払いプランを切り替える（有料→有料）画面の場合
          return I18n.tc(
            `SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.description.afterPlanEnds`,
          )
        }
      }

      // 上記以外の場合は表示しない
      return ''
    })

    /**
     * プランの説明（請求に関する説明を記載している
     * 月額プランの場合のみ表示するテキスト
     */
    const planDescription2 = computed(() => {
      if (props.plan.planGroupId === 'monthlyPlan') {
        return I18n.tc(`SignupPage.SelectPlanPage.plan.${props.plan.planGroupId}.description2`)
      }

      // 上記以外の場合は表示しない
      return ''
    })

    return {
      planCaption,
      planPriceUnit,
      planPaymentUnit,
      planBargainPriced,
      planAttention,
      getContractTermText,
      planDescription,
      planDescription2,
      planName,
      planPrice,
    }
  },
})
