var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choose-free-plan-section" },
    [
      _vm.canCancelPaidPlan
        ? [
            _c("h4", {
              staticClass: "choose-free-plan-section__title",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$tc("CancelPaidPlan.EditPage.changeFreePlan")
                ),
              },
            }),
            _c(
              "toggle-radio-card-list-section",
              [
                _c(
                  "toggle-radio-card-parts",
                  {
                    attrs: {
                      radioValue: _vm.freePlan.planGroupId,
                      currentValue: _vm.selectedPlanGroupId,
                    },
                  },
                  [
                    _c("plan-card-parts", {
                      attrs: {
                        plan: _vm.freePlan,
                        procedureScreen: _vm.procedureScreen,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("ul", {
              staticClass: "choose-free-plan-section__attention",
              domProps: { innerHTML: _vm._s(_vm.attention) },
            }),
          ]
        : [
            _c("current-plan-card-section", {
              attrs: {
                sectionTitle: _vm.futurePlanTitle,
                plan: _vm.futurePlan,
                contractTerm: _vm.scheduledContractTerm,
                isConfirmed: true,
              },
            }),
            _c("ul", {
              staticClass:
                "choose-free-plan-section__attention choose-free-plan-section__future",
              domProps: { innerHTML: _vm._s(_vm.attention) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }