













import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageAboutUsingPane from '@/components/MypageAboutUsingPage/MypageAboutUsingPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * マイページ: ご利用について
 */
export default defineComponent({
  name: 'MypageAboutUsingPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageAboutUsingPane,
  },
  methods: {},
})
