/**
 * 動画プレーヤーの種別
 */
export const VideoPlayerClass = {
  Browser: 'Browser',
  Native: 'Native',
  AirVideoPlayer: 'AirVideoPlayer',
} as const

/**
 * 動画プレーヤーの種別の型
 */
export type VideoPlayerType = keyof typeof VideoPlayerClass
