




















import { defineComponent } from '@vue/composition-api'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import Const from '@/util/Const'

export default defineComponent({
  name: 'MypageEcSiteConnectContactParts',
  components: {
    AtomLinkButton,
  },
  setup() {
    // お問い合わせフォームへのリンク
    const inquiryFormUrl = Const.EXTERNAL_LINKS.GOOGLE_INQUIRY_FORM

    return {
      inquiryFormUrl,
    }
  },
})
