































import { computed, ref, defineComponent, PropType, watch } from '@vue/composition-api'
import { partition } from 'lodash'
import { AwardRankingData } from '@/components/LinkPage/AwardPane.vue'
import AwardRankingHeaderSection from '@/components/LinkPage/AwardPane/AwardRankingHeaderSection.vue'
import AwardWinnerSection from '@/components/LinkPage/AwardPane/AwardWinnerSection.vue'
import AwardRankingListSection from '@/components/LinkPage/AwardPane/AwardRankingListSection.vue'
import { AwardRankingCategoryType } from '@/store/stores/collectionModule/documents/award/AwardDataDocument'
import AwardRankingSponsorParts from '@/components/LinkPage/AwardPane/parts/AwardRankingSponsorParts.vue'

/**
 * Award Ranking セクション
 */
export default defineComponent({
  name: 'AwardRankingSection',
  components: {
    AwardRankingSponsorParts,
    AwardRankingHeaderSection,
    AwardWinnerSection,
    AwardRankingListSection,
  },
  props: {
    /**
     * 対象年
     */
    targetYear: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    badgeImage: {
      type: String,
      required: true,
    },
    category: {
      type: String as PropType<AwardRankingCategoryType>,
      default: 'RECOMMEND_DRIVER',
    },
    rankings: {
      type: Array as PropType<AwardRankingData[]>,
    },
  },
  setup(props) {
    const sponsorPartsRenderKey = ref(0)

    /** 1位と1位以外でランキングデータを分ける */
    const partitionedRankingData = computed(() => {
      const partitionedData = partition(props.rankings, (r) => r.ranking === 1)
      return {
        winner: partitionedData[0] as Array<AwardRankingData>,
        rankingOtherFirst: partitionedData[1] as Array<AwardRankingData>,
      }
    })

    watch(
      () => props.targetYear,
      () => {
        sponsorPartsRenderKey.value += 1
      },
    )

    return {
      sponsorPartsRenderKey,
      partitionedRankingData,
    }
  },
})
