




































import { defineComponent } from '@vue/composition-api'
import MissionInstagramIcon from '@/components/MissionPage/MissionRankingPane/parts/icons/MissionInstagramIcon.vue'
import MissionXIcon from '@/components/MissionPage/MissionRankingPane/parts/icons/MissionXIcon.vue'

export default defineComponent({
  name: 'MissionSnsButtonParts',
  components: {
    MissionXIcon,
    MissionInstagramIcon,
  },
  props: {
    /**
     * Xにランキングを投稿するかどうか
     */
    postX: {
      type: Boolean,
      default: false,
    },
    /**
     * Instagramにランキングを投稿するかどうか
     */
    postInstagram: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChangePostX() {
      this.$emit('onChangePostX', !this.postX)
    },
    onChangePostInstagram() {
      this.$emit('onChangePostInstagram', !this.postInstagram)
    },
  },
})
