
















import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: 通知リンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationNotificationIconParts',
})
