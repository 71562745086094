var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reward-list-section" },
    [
      _vm.rewardDataList.length > 0
        ? [
            _c(
              "ul",
              { staticClass: "reward-list-section__list" },
              _vm._l(_vm.rewardDataList, function (item) {
                return _c(
                  "li",
                  {
                    key: item.videoContentsId,
                    staticClass: "reward-list-section__item",
                  },
                  [_c("reward-item-section", { attrs: { reward: item } })],
                  1
                )
              }),
              0
            ),
          ]
        : [
            _c("p", {
              staticClass: "reward-list-section__no-data",
              domProps: { innerHTML: _vm._s(_vm.$tc("RewardPage.noData")) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }