










import { defineComponent, PropType, ref, Ref } from '@vue/composition-api'
import { orderBy, uniqBy } from 'lodash'
import StandingsRoundListSection from '@/components/StandingsPage/StandingsRoundDataPane/StandingsRoundListSection.vue'
import { StandingsRoundDataType } from '@/components/StandingsPage/StandingsRoundDataPane/StandingsRoundDetailsSection.vue'
import StoreUtil from '@/store/StoreUtil'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import { ResultDataType } from '@/store/stores/collectionModule/documents/standings/ResultDataDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

type ResultDataNumPropertyType = Extract<
  keyof ResultDataType,
  | 'raceNum'
  | 'qualifyingNum'
  | 'topSpeedNum'
  | 'bestLapNum'
  | 'sec1BestTimeNum'
  | 'sec2BestTimeNum'
  | 'sec3BestTimeNum'
  | 'sec4BestTimeNum'
>

/**
 * Standings: 各レース詳細 コンテンツペイン
 */
export default defineComponent({
  name: 'StandingsRoundDataPane',
  components: {
    StandingsRoundListSection,
  },
  props: {
    /**
     * 選手データ
     */
    player: {
      type: Object as PropType<PlayerDocument>,
      required: true,
    },
  },
  setup(props) {
    const standingsPageStore = StoreUtil.useStore('StandingsPageStore')
    const { selectedYear, championships, resultListByCarNo, resultListByRound, findPlayersById } =
      standingsPageStore

    /** 各大会ごとの結果データ */
    const roundsRecords: Ref<Array<StandingsRoundDataType> | undefined> = ref(undefined)

    /**
     * 結果データに表示する各レコード情報
     */
    const getRecord = (value: string | undefined, valueNum: number | undefined) =>
      value && valueNum ? value : '---'

    /**
     * 結果データに表示する各レコード情報（TOP SPEED用）
     */
    const getRecordTopSpeed = (value: string | undefined, valueNum: number | undefined) =>
      value && valueNum ? `${value}km/h` : '---'

    /**
     * 結果データに表示する各レコード毎の順位
     */
    const getPosition = (
      championshipMasterId: string,
      property: ResultDataNumPropertyType,
      valueNum: number | undefined,
      orders: 'desc' | 'asc' = 'asc',
    ) => {
      const targetResultData = resultListByRound.value[championshipMasterId]
      if (!targetResultData || !valueNum) {
        // 対象のリザルトデータが存在しない または 記録が存在しないの場合、順位を表示しない
        return 0
      }

      // ランキング算出用のデータ（値の小さい順に並べている）
      const dataForRankingCalc = orderBy(targetResultData.results, property, orders)
        // 数字が0のものを除外する
        .filter((result) => !!result[property])
        .map((filteredResult) => filteredResult[property])

      /**
       * 該当ドライバーのレコードが何番目に小さいかを返す
       * 一番小さい場合、targetResultData.resultsのインデックス番号0と一致：「0（インデックス番号） + 1 = 1位」を返却する
       */
      return dataForRankingCalc.indexOf(valueNum) + 1
    }

    /**
     * 対象ドライバーの各大会ごとに表示するResultデータを作成
     */
    const createStandingsRoundData = () => {
      // 対象の選手が代走するケースで、大会によって別のcarNoで走行することを考慮する
      const carNos = uniqBy(findPlayersById(props.player.playerId ?? ''), 'squadNumber').map(
        (player) => player.getDisplayCarNo(),
      )
      // 表示対象選手のResultデータ
      const targetPlayerResultData = carNos
        .map((carNo) => resultListByCarNo.value[carNo] ?? [])
        .flat()

      return targetPlayerResultData
        .filter((playerResult) => {
          // 大会マスタに紐づかない大会のResultデータが存在する場合はデータを表示しない（インポート機能に不具合がない場合はこちらの現象は発生しないはず）
          const hasChampionship = championships.value.some(
            (championship) => championship.id === playerResult.championshipMasterId,
          )
          if (!hasChampionship) {
            return false
          }

          /**
           * Resultデータ表示対象の大会に出場していた選手かを判定
           * ResultデータはcarNoを持っているが、同一年度に同じcarNoで異なる選手が走行する大会が稀にあるため、carNoが一致したとしても走行していない選手だった場合はResultデータ表示対象外とする
           */
          const isParticipatedPlayer = findPlayersById(props.player.playerId ?? '').some(
            (player) =>
              player.championshipMasterId === playerResult.championshipMasterId &&
              player.getDisplayCarNo() === playerResult.results?.[0].carNo,
          )
          if (!isParticipatedPlayer) {
            return false
          }

          // 上記以外はResultデータを表示する
          return true
        })
        .map((targetPlayerResult) => {
          const targetChampionship = championships.value.find(
            (championship) => championship.id === targetPlayerResult.championshipMasterId,
          )

          // earnPoints
          const earnPoints = targetPlayerResult.results?.[0].earnPointsNum ?? 0

          // resultsの先頭（results?.[0]）に表示対象選手のResultデータが入っている
          return {
            earnPoints,
            race: {
              round: targetChampionship?.additionalData?.round ?? 0,
              imgPath: CloudFrontUtil.getSignedUrl(targetChampionship?.championshipLogo) ?? '',
              // コース名は英語のみ表示
              courseName: targetChampionship?.circuitName.en ?? '',
              courseImgPath: targetChampionship?.courseImg ?? '',
            },
            records: [
              {
                title: 'RACE',
                record: getRecord(
                  targetPlayerResult.results?.[0].race,
                  targetPlayerResult.results?.[0].raceNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'raceNum',
                  targetPlayerResult.results?.[0].raceNum,
                ),
              },
              {
                title: 'QUALIFYING',
                record: getRecord(
                  targetPlayerResult.results?.[0].qualifying,
                  targetPlayerResult.results?.[0].qualifyingNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'qualifyingNum',
                  targetPlayerResult.results?.[0].qualifyingNum,
                ),
              },
              {
                title: 'TOP SPEED',
                record: getRecordTopSpeed(
                  targetPlayerResult.results?.[0].topSpeed,
                  targetPlayerResult.results?.[0].topSpeedNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'topSpeedNum',
                  targetPlayerResult.results?.[0].topSpeedNum,
                  'desc',
                ),
              },
              {
                title: 'BEST LAP',
                record: getRecord(
                  targetPlayerResult.results?.[0].bestLap,
                  targetPlayerResult.results?.[0].bestLapNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'bestLapNum',
                  targetPlayerResult.results?.[0].bestLapNum,
                ),
              },
              {
                title: 'SECTOR 1 BEST TIME',
                record: getRecord(
                  targetPlayerResult.results?.[0].sec1BestTime,
                  targetPlayerResult.results?.[0].sec1BestTimeNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'sec1BestTimeNum',
                  targetPlayerResult.results?.[0].sec1BestTimeNum,
                ),
              },
              {
                title: 'SECTOR 2 BEST TIME',
                record: getRecord(
                  targetPlayerResult.results?.[0].sec2BestTime,
                  targetPlayerResult.results?.[0].sec2BestTimeNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'sec2BestTimeNum',
                  targetPlayerResult.results?.[0].sec2BestTimeNum,
                ),
              },
              {
                title: 'SECTOR 3 BEST TIME',
                record: getRecord(
                  targetPlayerResult.results?.[0].sec3BestTime,
                  targetPlayerResult.results?.[0].sec3BestTimeNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'sec3BestTimeNum',
                  targetPlayerResult.results?.[0].sec3BestTimeNum,
                ),
              },
              {
                title: 'SECTOR 4 BEST TIME',
                record: getRecord(
                  targetPlayerResult.results?.[0].sec4BestTime,
                  targetPlayerResult.results?.[0].sec4BestTimeNum,
                ),
                position: getPosition(
                  targetChampionship?.id ?? '',
                  'sec4BestTimeNum',
                  targetPlayerResult.results?.[0].sec4BestTimeNum,
                ),
              },
            ],
          }
        })
    }

    roundsRecords.value = createStandingsRoundData()

    return {
      selectedYear,
      roundsRecords,
    }
  },
})
