var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "membership-card-pane" },
    [
      _c("membership-card-bg-parts", {
        staticClass: "membership-card-pane__bg",
        attrs: {
          isFreePlan: _vm.userViewData.isFreePlan,
          membersRankData: _vm.membersRankData,
        },
      }),
      _c(
        "div",
        { staticClass: "membership-card-pane__content" },
        [
          _vm.enabledEditMode
            ? _c("membership-card-edit-mode-section", {
                attrs: {
                  isEditCardForHighlightComment:
                    _vm.isEditCardForHighlightComment,
                  userPreviewData: _vm.userPreviewData,
                  userData: _vm.userViewData,
                  valueUserIconFileData: _vm.userIconFileData || undefined,
                  isTransitionCreateHighlight: _vm.isTransitionCreateHighlight,
                },
                on: {
                  onChangeUsername: _vm.setUsername,
                  onChangeUserIconData: _vm.setUserIconData,
                  handlerSubmit: _vm.saveCardData,
                  handlerCancel: _vm.toggleEditMode,
                },
              })
            : _c("membership-card-view-mode-section", {
                attrs: {
                  userData: _vm.userViewData,
                  membersRankData: _vm.membersRankData,
                  enabledListCarousel: _vm.enabledListCarousel,
                },
                on: { edit: _vm.toggleEditMode },
              }),
        ],
        1
      ),
      !_vm.enabledEditMode
        ? _c("members-point-section", {
            staticClass: "membership-card-pane__members-point",
            attrs: {
              userData: _vm.userViewData,
              membersRankData: _vm.membersRankData,
            },
          })
        : _vm._e(),
      !_vm.enabledEditMode
        ? _c("p", {
            staticClass: "membership-card-pane__about-members-rank",
            domProps: { innerHTML: _vm._s(_vm.rankBenefitsLink) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }