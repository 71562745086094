

























import { defineComponent } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CreditCardConfirmSection from '@/components/common/payment/CreditCardConfirmSection.vue'

/**
 * クレジットカード情報変更確認画面 ペイン
 */
export default defineComponent({
  name: 'PaymentConfirmPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    CreditCardConfirmSection,
  },
  methods: {
    handlerSubmit() {
      /**
       * todo: 完了画面遷移処理
       */
      this.$router.push('/payment/complete')
    },
    handlerCancel() {
      /**
       * todo: キャンセル処理
       */
      this.$router.push('/payment/edit')
    },
  },
})
