




















import { defineComponent } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'

export default defineComponent({
  name: 'RaceListViewButtonParts',
  components: { AtomIconRouterButton },
  setup(_, { emit }) {
    const forPc = DeviceInfo.isForPC()
    const handleViewTimetableClicked = () => {
      emit('onClickViewTimetable')
    }
    return {
      forPc,
      handleViewTimetableClicked,
    }
  },
})
