import { computed } from '@vue/composition-api'
import { orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import CalendarDataDocument from '@/store/stores/collectionModule/documents/calendar/CalendarDataDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'

/**
 * カレンダーデータを操作するための処理を取得する。
 */
export default function useCalendarData() {
  // Collection modules
  const calendarCollectionModule = CollectionModule.createStore(CalendarDataDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * カレンダーデータを取得する。
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchCalendarData = async (fromDate: number, toDate: number) => {
    const result = await searchDateRangeData({
      tableName: 'calendar-data',
      partitionKey: 'calendarType',
      partitionKeyValue: 'MotorSport', // 現状MotorSportしかないため、固定値を設定
      sortKey: 'eventDate',
      from: fromDate,
      to: toDate,
    })
    calendarCollectionModule.data = result as Array<CalendarDataDocument>
  }

  /**
   * 取得したカレンダーデータ一覧
   */
  const calendarData = computed(() => orderBy(calendarCollectionModule.data, 'eventDate'))

  /**
   * 取得したカレンダーデータをクリアする
   */
  const clearCalendarData = () => {
    calendarCollectionModule.clearData()
  }

  return {
    fetchCalendarData,
    calendarData,
    clearCalendarData,
  }
}
