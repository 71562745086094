























import { computed, defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * 画面遷移警告モーダルテンプレート
 * todo: この処理で良いのか確認
 */
export default defineComponent({
  name: 'ConfirmLeavingPageModalSection',
  components: {
    CommonModalSection,
    ModalMessageParts,
    AtomInputButton,
  },
  props: {
    /**
     * 画面遷移先
     */
    screenTransitionTo: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * 遷移先指定取得
     */
    const routerPath = computed(() => props.screenTransitionTo)
    return {
      routerPath,
    }
  },
  methods: {
    emitClose(eventType: string): void {
      if (eventType === 'submit' && this.routerPath !== '') {
        /**
         * 画面遷移許可
         */
        this.$router.replace({ path: this.routerPath })
      } else {
        /**
         * closeModal
         * 親コンポーネントへ閉じる処理を通知
         * return: submit / cancel
         */
        this.$emit('close', eventType)
      }
    },
  },
})
