var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-throttle-pedal-parts" }, [
    _c(
      "svg",
      {
        staticClass: "telemetry-throttle-pedal-parts__gauge",
        attrs: {
          width: "303",
          height: "200",
          viewBox: "0 0 303 200",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c(
          "clipPath",
          { attrs: { id: "telemetry-throttle-pedal-parts__gauge__clip" } },
          [
            _c("rect", {
              attrs: {
                width: "303",
                height: _vm.gaugeHeight,
                x: "0",
                y: _vm.gaugePositionY,
                fill: "white",
                opacity: "0.8",
              },
            }),
          ]
        ),
        _c("path", {
          attrs: {
            "clip-path": "url(#telemetry-throttle-pedal-parts__gauge__clip)",
            opacity: "0.5",
            d: "M303 24C303 10.7452 292.255 0 279 0H183V2H249C256.732 2 263 8.26801 263 16V150.059C263 156.424 265.529 162.529 270.029 167.029L303 200V24Z",
            fill: "#00DBA6",
            "fill-opacity": "0.7",
          },
        }),
        _c("path", {
          attrs: {
            "clip-path": "url(#telemetry-throttle-pedal-parts__gauge__clip)",
            d: "M303 24C303 10.7452 292.255 0 279 0H183V1H278C290.703 1 301 11.2975 301 24V198L303 200V24Z",
            fill: "#00DBA6",
          },
        }),
        _c("g", { attrs: { opacity: "0.5" } }, [
          _c("path", {
            attrs: {
              d: "M303 28C303 12.536 290.464 0 275 0H183V2H249C256.732 2 263 8.26801 263 16V150.059C263 156.424 265.529 162.529 270.029 167.029L303 200V28Z",
              fill: "url(#paint0_linear_757_14846)",
            },
          }),
          _c("path", {
            attrs: {
              d: "M303 24C303 10.7452 292.255 0 279 0H183V1H278C290.703 1 301 11.2975 301 24V198L303 200V24Z",
              fill: "#00DBA6",
            },
          }),
        ]),
        _c("path", {
          attrs: {
            opacity: "0.6",
            d: "M303 88L290.515 100.485C288.264 102.736 287 105.788 287 108.971V184L303 200V88Z",
            fill: "black",
          },
        }),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_757_14846",
                  x1: "303",
                  y1: "0",
                  x2: "270",
                  y2: "0",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", { attrs: { "stop-color": "#009B71" } }),
                _c("stop", {
                  attrs: {
                    offset: "1",
                    "stop-color": "#009B71",
                    "stop-opacity": "0.3",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "text",
          {
            staticClass: "telemetry-throttle-pedal-parts__label",
            attrs: { x: "109", y: "-291", transform: "rotate(90)" },
          },
          [_vm._v(" THROTTLE ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }