











import { defineComponent, onMounted, ref, inject } from '@vue/composition-api'
import type VueRouter from 'vue-router'
import RegistrationPane from '@/components/SignupPage/RegistrationPane.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import i18n from '@/locales/I18n'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import StoreUtil from '@/store/StoreUtil'

/**
 * 新規会員登録: 会員情報入力ページ
 */
export default defineComponent({
  name: 'RegistrationPage',
  inject: ['router'],
  components: {
    RegistrationPane,
  },
  props: {
    tokenId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = inject('router') as VueRouter
    const errorStore = StoreUtil.useStore('ErrorStore')
    const signupPageStore = StoreUtil.useStore('SignupPageStore')

    // 登録メールアドレス
    const email = ref('')

    onMounted(async () => {
      if (props.tokenId) {
        const validationResult = await signupPageStore.validateToken(props.tokenId)
        const responseData = validationResult?.data[0]
        if (!validationResult.isSuccess || !responseData.isValid) {
          await MessageDialogStore.value.open({
            title: i18n.t('SignupPage.RegistrationPage.errors.urlInvalidError.title') as string,
            message: i18n.t('SignupPage.RegistrationPage.errors.urlInvalidError.message') as string,
          })
          errorStore.setConfig('tokenInvalid')
          await router.replace({ name: 'ErrorPage' })
        }
        email.value = responseData.manageRegistToken?.mailAddress || ''
        // 会員登録タイプをストアに保存
        signupPageStore.flowPathMemberType.value = responseData.manageRegistToken?.additionalData
          ?.memberType as MemberType
        signupPageStore.memberType.value = responseData.manageRegistToken?.additionalData
          ?.memberType as MemberType
      } else {
        await MessageDialogStore.value.open({
          title: i18n.t('SignupPage.RegistrationPage.errors.urlInvalidError.title') as string,
          message: i18n.t('SignupPage.RegistrationPage.errors.urlInvalidError.message') as string,
        })
        errorStore.setConfig('tokenInvalid')
        await router.replace({ name: 'ErrorPage' })
      }
    })

    return {
      email,
    }
  },
  methods: {
    /**
     * プラン選択画面へ遷移
     */
    moveToPlanSelect() {
      this.$router.replace({ name: 'PlanSelectPage' })
    },
  },
})
