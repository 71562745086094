import useImageUpload from '@/store/hook/useImageUpload'
import ImageUploadDocument from '@/store/stores/collectionModule/documents/imageUpload/ImageUploadDocument'

/**
 * ユーザーのアイコン画像に関する機能を提供する。
 */
export default function useUserIconImage() {
  const { upload } = useImageUpload()

  /**
   * ユーザーのアイコン画像 アップロード処理
   */
  const uploadUserIconImage = async (userIconImageData: File, userMasterId: string) => {
    const requestImageUpLoadData = new ImageUploadDocument({
      master: 'user',
      field: 'userPicture',
      pk: userMasterId,
    })
    /** 画像アップロード */
    return upload(requestImageUpLoadData, userIconImageData)
  }

  return {
    uploadUserIconImage,
  }
}
