import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoCardRequestDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoCardRequestDocument'

/**
 * 契約情報クレジットカード登録リクエストを操作するための処理を提供する。
 */
export default function useContractInfoCardRequest() {
  // Collection modules
  const contractInfoCardRequestCollectionModule = CollectionModule.createStore(
    ContractInfoCardRequestDocument,
  )

  /**
   * クライアントシークレットを取得する。
   * @return APIレスポンス
   */
  const contractInfoCardRequest = async () =>
    contractInfoCardRequestCollectionModule.save(new ContractInfoCardRequestDocument())

  return {
    contractInfoCardRequest,
  }
}
