var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "current-plan-card-section" }, [
    _vm.sectionTitle !== ""
      ? _c("h4", {
          staticClass: "current-plan-card-section__title",
          domProps: { innerHTML: _vm._s(_vm.sectionTitle) },
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "current-plan-card-section__plan" },
      [
        "couponData" in _vm.plan
          ? _c("coupon-plan-card-parts", {
              attrs: {
                plan: _vm.plan,
                isOneTimePassUsed: _vm.isOneTimePassUsed,
              },
            })
          : _c("plan-card-parts", {
              attrs: {
                plan: _vm.plan,
                isConfirmed: _vm.isConfirmed,
                contractTerm: _vm.contractTerm,
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }