import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import RankingData from '@/store/stores/collectionModule/documents/liveTiming/RankingData'
import { type RaceType } from '@/store/stores/collectionModule/documents/liveTiming/types/RaceType'

/**
 * ライブタイミング集計情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3421307066/09.+Race+Rank
 */
export default class LiveTimingAggregationDocument extends DocumentWrapper {
  constructor(initProps?: Partial<LiveTimingAggregationDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    const rankingDataSet = [] as Array<RankingData>
    initProps?.rankingDataSet?.forEach((rankingData) => {
      if (initProps.season && initProps.raceType) {
        rankingDataSet.push(new RankingData(initProps.season, initProps.raceType, rankingData))
      }
    })
    this.rankingDataSet = rankingDataSet
  }

  /**
   * AWS SDKでDynamoDBに直接アクセスしてデータを取得するため、pathは未定義.
   */
  _path = ''

  idAttr = 'raceStartDateTime'

  /**
   * レースシーズン
   * レースカテゴリと年度を合わせた文字列を指定する。
   * 例: SuperFormula_2023
   */
  season: string | null = null

  /**
   * レースカテゴリ
   * ・SuperFormula: SUPER FORMULA
   */
  raceCategory: string | null = null

  /**
   * レース種別
   * R: レース（決勝）
   * B: レース以外(予選)
   */
  raceType: RaceType | null = null

  /**
   * 規定週回数
   * レース種別が "R" の場合に設定される
   */
  numberOfLaps: number | null = null

  /**
   * 走行時間
   * レース種別が "B" の場合に設定される
   */
  raceTime: number | null = null

  /**
   * 集計対象のセッション(フリー走行/予選/決勝)の開始日時。UnixTime(ミリ秒)
   */
  raceStartDateTime: number | null = null

  /**
   * 対象レースのID
   */
  raceId: string | null = null

  /**
   * ランキングデータ
   */
  rankingDataSet: Array<RankingData> = []

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `RadioDataDocument[season: ${this.season}, raceStartDateTime: ${this.raceStartDateTime}]`
  }
}
