var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reward-page" }, [
    _c(
      "div",
      { staticClass: "reward-page__header" },
      [
        _c("header-pane", {
          attrs: {
            colorType: "light",
            pageTitle: _vm.$tc("RewardPage.title"),
            hasHeaderNav: true,
            current: "",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "reward-page__pane" }, [_c("reward-pane")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }