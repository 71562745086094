var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "link-page" },
    [
      _c("header-pane", {
        staticClass: "link-page__header",
        attrs: {
          colorType: "light",
          pageTitle: _vm.$tc("LinkPage.pageTitle"),
          navigationCurrent: "link",
          hasHeaderNav: _vm.hasHeaderNav,
        },
      }),
      _c("link-pane"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }