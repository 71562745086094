var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sns-favorite-section" },
    [
      _vm.headingLabel
        ? _c("h4", {
            staticClass: "sns-favorite-section__title",
            domProps: { textContent: _vm._s(_vm.headingLabel) },
          })
        : _vm._e(),
      _c(
        "favorite-selector-button-parts",
        {
          staticClass: "sns-favorite-section__card",
          on: { click: _vm.emitClick },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }