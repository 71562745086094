var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-year-section" }, [
    _c("div", { staticClass: "select-year-section__container" }, [
      _c(
        "button",
        {
          staticClass: "select-year-section__current",
          on: { click: _vm.handleCurrentClick },
        },
        [
          _c("span", { staticClass: "select-year-section__current-label" }, [
            _vm._v(_vm._s(_vm.year)),
          ]),
          _vm.yearList.length > 1
            ? _c(
                "svg",
                {
                  staticClass: "select-year-section__arrow",
                  class: {
                    "select-year-section__arrow--open": _vm.isOpenOption,
                  },
                  attrs: {
                    width: "18",
                    height: "18",
                    viewBox: "0 0 18 18",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M1.93945 6L7.76268 11.8232C8.44609 12.5066 9.55413 12.5066 10.2375 11.8232L16.0608 6H13.9395L9.17689 10.7626C9.07926 10.8602 8.92097 10.8602 8.82334 10.7626L4.06077 6H1.93945Z",
                      fill: "currentColor",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.isOpenOption
        ? _c("div", { staticClass: "select-year-section__option" }, [
            _c(
              "ul",
              { staticClass: "select-year-section__option-list" },
              _vm._l(_vm.yearList, function (item) {
                return _c(
                  "li",
                  {
                    key: item,
                    staticClass: "select-year-section__option-item",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "select-year-section__option-button",
                        class: {
                          "select-year-section__option-button--selected":
                            _vm.raceYear === item,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.change(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }