var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "mypage-ticket-toggle-icon-parts",
      attrs: {
        viewBox: "0 0 25 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M21.9144 16.293L13.9144 8.29297C13.1333 7.51192 11.867 7.51192 11.0859 8.29297L3.08594 16.293H5.91437L12.5002 9.70718L19.0859 16.293L21.9144 16.293Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }