




















import { defineComponent, Ref } from '@vue/composition-api'
import PlayerSelectListSection from '@/components/MypageFavoritePlayerSelectPage/MypageFavoritePlayerSelectPane/PlayerSelectListSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

export type DataType = {
  selectedID: string
}

/**
 * HOME: SNS投稿表示選手変更 コンテンツペイン
 */
export default defineComponent({
  name: 'HomeSnsPlayerSelectPane',
  components: {
    PlayerSelectListSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  data(): DataType {
    return {
      /**
       * セレクトID
       */
      selectedID: '',
    }
  },
  setup() {
    const store = StoreUtil.useStore('HomePageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      players: store.availablePlayers as Ref<PlayerDocument[]>,
      snsPostDisplayPlayer: store.snsPostDisplayPlayer,
      favoritePlayer: mypagePageStore.favoritePlayer,
    }
  },
  mounted() {
    this.selectedID = this.snsPostDisplayPlayer?.sid || ''
  },
  methods: {
    changeSnsPostDisplayPlayer() {
      /**
       * SNS投稿を表示する選手をストアに保存
       */
      const selectedPlayer = this.players.find((item) => item.sid === this.selectedID)
      if (selectedPlayer) {
        this.snsPostDisplayPlayer = selectedPlayer
      }
      this.$emit('changed')
    },
    changeSelected(selectedPlayerID: string): void {
      /**
       * 選択変更
       * @param selectedPlayerID : string
       */
      if (this.selectedID !== selectedPlayerID) {
        this.selectedID = this.selectedID === selectedPlayerID ? '' : selectedPlayerID
      }
    },
  },
})
