var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-prefectures-parts" },
    [
      _c("label", { attrs: { for: "fieldset-prefectures" } }, [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.prefectures"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("atom-input-select-object", {
        staticClass: "login-inputs-parts__country-field",
        attrs: {
          id: "fieldset-prefectures",
          placeholder: _vm.$t("formParts.placeholder.prefectures"),
          error: _vm.showError,
          options: _vm.prefecturesData,
        },
        on: { enter: _vm.emitEnter },
        model: {
          value: _vm.prefectures,
          callback: function ($$v) {
            _vm.prefectures = $$v
          },
          expression: "prefectures",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showError,
          message:
            _vm.errorMessage !== ""
              ? _vm.errorMessage
              : _vm.$t("formParts.errorMessage.prefectures"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }