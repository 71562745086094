var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-circuit",
      attrs: {
        viewBox: "0 0 37 28",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.4917 22.4241C14.1446 22.3249 13.9462 22.2753 13.6487 22.1265C13.1775 21.9034 11.1443 21.0107 10.6484 20.8124C10.5244 20.7628 10.1029 20.5892 9.9789 20.5396C8.98707 20.1181 8.7639 18.8535 9.83012 18.1592C10.45 17.7377 14.0454 15.4565 14.219 15.3077C14.5413 15.0598 14.7645 14.7126 14.7397 14.2167C14.6901 13.7952 14.4669 11.3404 14.3925 10.8693C14.2934 10.299 13.971 9.95183 13.4007 9.72867C12.5577 9.40633 8.91268 8.14174 8.02003 7.7946C6.33392 7.14991 5.11893 5.53819 5.61484 3.57933C6.03637 2.01719 7.40014 0.727815 9.33421 0.678223C10.6732 0.653427 12.0617 1.09975 13.2271 2.11638C13.9462 2.76107 30.0139 16.6715 30.4602 17.0682C31.0305 17.5889 31.1297 18.0352 30.7825 18.5312C30.5346 18.8783 30.0139 19.647 29.7411 19.9941C29.3196 20.5644 28.6749 20.738 27.807 20.0437C27.0632 19.4486 25.6498 18.2584 25.3027 17.9857C24.782 17.5393 23.9141 17.4897 23.2198 17.9361C22.8479 18.184 19.7732 20.1429 19.3021 20.4404C18.6574 20.862 18.7814 21.829 19.6988 22.1265C20.17 22.2753 21.4097 22.6473 21.6577 22.7216C22.0792 22.8208 22.352 22.7216 22.5999 22.4241C22.8975 22.0522 23.1454 21.8042 23.567 21.8042C23.9885 21.8042 30.113 22.1017 30.5098 22.1265C31.0553 22.1513 31.4272 22.4489 31.5264 22.8952C31.6256 23.3663 31.9727 25.2508 32.0471 25.6475C32.2207 26.565 31.3032 27.5072 30.4602 27.2841C29.8899 27.1105 14.7645 22.4985 14.4917 22.4241Z",
          stroke: "currentColor",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }