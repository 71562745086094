





























import { defineComponent, PropType } from '@vue/composition-api'
import { GpsViewModeType } from '@/components/RaceVideoPage/RaceVideoGpsPane.vue'

/**
 * レース動画再生画面: GPS詳細 表示モードセレクター
 */
export default defineComponent({
  name: 'GpsViewModeSelectorSection',
  components: {},
  props: {
    /**
     * GPS表示モード
     */
    gpsViewMode: {
      type: String as PropType<GpsViewModeType>,
      default: 'birdsEye',
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
  setup() {
    return {}
  },
  methods: {
    onclickSelector(changeMode: GpsViewModeType): void {
      /**
       * GPS詳細表示モード変更
       * @param changeMode
       */
      if (this.gpsViewMode === changeMode) return

      this.$emit('onclickSelector', changeMode)
    },
  },
})
