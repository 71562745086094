























































import { defineComponent, PropType } from '@vue/composition-api'
import { ImgSliderLinkData } from '@/components/HomePage/HomePane/ImgSliderSection.vue'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import InAppBrowserWrapper, { InAppBrowserResult } from '@/util/inAppBrowser/InAppBrowserWrapper'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * HOME イメージスライダー: スライドセクション
 */
export default defineComponent({
  name: 'ImgSlideParts',
  props: {
    linkData: {
      type: Object as PropType<ImgSliderLinkData>,
      required: true,
    },
  },
  setup(props) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    // サムネイル画像のURLをCloudFrontの署名URLに変換する
    const thumbnailPath = CloudFrontUtil.getSignedUrl(props.linkData.img)

    const inAppBrowser = new InAppBrowserWrapper()

    /**
     * ヘッドラインのアンカーリンクがクリックされた場合に呼び出される。
     *
     * @param sliderLink 外部サイトのリンク
     */
    const onClickAnchorLink = (sliderLink: ImgSliderLinkData) => {
      if (!InAppBrowserWrapper.isAvailable()) {
        // ブラウザの場合、別ウィンドウか別タブで表示する
        const subWindow = window.open(sliderLink.link, '_blank')
        if (subWindow && process.env.VUE_APP_SFBINGO_URL === sliderLink.link) {
          // URLがSFbingoの場合には、APIの認証情報とユーザーIDをローカルストレージに保存する
          LocalStorageAccessor.setCredentialInfo(
            UserStore.value.user.value._organization || '',
            UserStore.value.user.value.id || '',
            LoginStore.value.accessToken || '',
            LoginStore.value.refreshToken || '',
          )
          setTimeout(() => {
            LocalStorageAccessor.clearCredentialInfo()
          }, 60000)
        }

        return
      }

      let injectCode = ''
      if (sliderLink.link === process.env.VUE_APP_SFBINGO_URL) {
        const credentialInfo = {
          organizationId: UserStore.value.user.value._organization,
          userId: UserStore.value.user.value.id,
          accessToken: LoginStore.value.accessToken,
          refreshToken: LoginStore.value.refreshToken,
        }
        const sCredentialInfo = JSON.stringify(credentialInfo)
        injectCode = `window.onLoadCredentials('${sCredentialInfo}');`
      }

      // InAppBrowserでサイトを開く
      inAppBrowser.open(
        sliderLink.link,
        '_blank',
        undefined,
        async (result: InAppBrowserResult) => {
          if (result?.isNetworkError) {
            await MessageDialogStore.value.open({
              title: I18n.t('apiNotification.dialog.0.title') as string,
              message: I18n.t('apiNotification.dialog.0.message') as string,
            })
          } else if (result?.isError) {
            await MessageDialogStore.value.open({
              title: I18n.t('HomePage.errors.headlineLinkError.title') as string,
              message: I18n.t('HomePage.errors.headlineLinkError.message') as string,
            })
          }
        },
        false,
        injectCode,
      )
    }
    return {
      isCircuitMode: appConfigStore.currentCircuitMode,
      thumbnailPath,
      onClickAnchorLink,
    }
  },
})
