
























































import { computed, defineComponent, PropType, reactive } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import I18n from '@/locales/I18n'
import SceneMoviesDocument from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'

/**
 * 以下のうちどのスライドメニューを表示するかの型
 * - ハイライト
 * - ハイライトコメント
 * - ブロック/通報
 */
export type HighlightSlideMenuType = 'highlight' | 'highlightPc' | 'highlightComment' | 'userBlock'

/**
 * スライドメニューリストの型
 */
export type HighlightSlideMenuListType = {
  highlight: {
    id: string
    name: string
    display: boolean
    isDisabled: boolean
    link?: string
  }[]
  highlightComment: {
    id: string
    name: string
    display: boolean
    isDisabled: boolean
    link?: string
  }[]
  highlightPc: {
    id: string
    name: string
    display: boolean
    isDisabled: boolean
    link?: string
  }[]
  userBlock: { id: string; name: string; display: boolean; isDisabled: boolean; link?: string }[]
}
/**
 * このページのstateの型
 */
type StateType = {
  menuList: HighlightSlideMenuListType
}
/**
 * レース動画再生画面 ハイライト、ハイライトコメント スライドメニューパーツ
 */
export default defineComponent({
  name: 'HighlightsSlideMenuParts',
  props: {
    /**
     * 表示対象となるスライドメニュー
     */
    highlightSlideMenu: {
      type: String as PropType<HighlightSlideMenuType | null>,
      default: null,
    },
    /**
     * 動画集
     */
    sceneMovies: {
      type: Object as PropType<SceneMoviesDocument | null>,
      default: () => ({}),
    },
    /**
     * ハイライトの切り抜きが可能かどうか
     */
    canClipTargetOperationHighlightForPc: {
      type: Boolean,
      default: false,
    },
    /**
     * 通報フォームURL
     */
    reportFormUrl: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isPc = DeviceInfo.isForPC()

    const state = reactive<StateType>({
      menuList: {
        highlight: [
          {
            id: 'sns',
            name: I18n.tc('RaceVideoPage.highlights.menu.sns'),
            display: true,
            isDisabled: false,
          },
          {
            id: 'edit',
            name: I18n.tc('RaceVideoPage.highlights.menu.edit'),
            display: true,
            isDisabled: false,
          },
          {
            id: 'delete',
            name: I18n.tc('RaceVideoPage.highlights.menu.delete'),
            display: true,
            isDisabled: false,
          },
        ],
        highlightPc: [
          {
            id: 'download',
            name: I18n.tc('RaceVideoPage.highlights.menu.download'),
            display: !(
              props.sceneMovies &&
              (props.sceneMovies.status === 'InProgress' || props.sceneMovies.status === 'Complete')
            ),
            isDisabled: !props.canClipTargetOperationHighlightForPc,
          },
          {
            id: 'xAndInstagram',
            name: I18n.tc('RaceVideoPage.highlights.menu.OnlyX'),
            display: true,
            isDisabled: false,
          },
          {
            id: 'share',
            name: I18n.tc('RaceVideoPage.highlights.menu.share'),
            display: false,
            isDisabled: false,
          },
          {
            id: 'edit',
            name: I18n.tc('RaceVideoPage.highlights.menu.edit'),
            display: true,
            isDisabled: false,
          },
          {
            id: 'delete',
            name: I18n.tc('RaceVideoPage.highlights.menu.delete'),
            display: true,
            isDisabled: false,
          },
        ],
        highlightComment: [
          {
            id: 'edit',
            name: I18n.tc('RaceVideoPage.highlights.menu.edit'),
            display: true,
            isDisabled: false,
          },
          {
            id: 'delete',
            name: I18n.tc('RaceVideoPage.highlights.menu.delete'),
            display: true,
            isDisabled: false,
          },
        ],
        userBlock: [
          {
            id: 'report',
            name: I18n.tc('RaceVideoPage.highlights.menu.report'),
            link: props.reportFormUrl,
            display: true,
            isDisabled: false,
          },
          {
            id: 'block',
            name: I18n.tc('RaceVideoPage.highlights.menu.block'),
            display: true,
            isDisabled: false,
          },
        ],
      },
    })

    /** ダウンロードメモ */
    const downloadMemo = computed(() =>
      props.canClipTargetOperationHighlightForPc
        ? I18n.tc('RaceVideoPage.highlights.menu.downloadMemo')
        : I18n.tc('RaceVideoPage.highlights.menu.downloadMemoWhenLive'),
    )

    /**
     * クリックされたメニューを親に伝える
     */
    const handleMenuClicked = (menuId: string, isDisabled: boolean) => {
      if (isDisabled) {
        // disabled状態の場合、後続処理をしない
        return
      }

      if (props.highlightSlideMenu === 'highlight') {
        emit('onClickMenu', menuId)
      } else if (props.highlightSlideMenu === 'highlightComment') {
        emit('onClickCommentMenu', menuId)
      } else if (props.highlightSlideMenu === 'highlightPc') {
        emit('onClickHighlightPcMenu', menuId)
      } else if (props.highlightSlideMenu === 'userBlock') {
        emit('onClickBlockMenu', menuId)
      }
    }

    /**
     * オーバーレイがクリックされたことを親に伝える
     */
    const handleOverlayClicked = () => {
      emit('onClickOverlay', props.highlightSlideMenu)
    }

    return {
      isPc,
      state,
      downloadMemo,
      handleMenuClicked,
      handleOverlayClicked,
    }
  },
})
