var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-today-current-time-parts" }, [
    _c(
      "svg",
      {
        staticClass:
          "mypage-ticket-today-current-time-parts__bg mypage-ticket-today-current-time-parts__bg--left",
        attrs: {
          width: "53",
          height: "50",
          viewBox: "0 0 53 50",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("g", { attrs: { "clip-path": "url(#clip0_3956_16647)" } }, [
          _c("path", {
            attrs: {
              d: "M-14 -1.90511e-05L36.8013 44.1202C41.1673 47.912 46.7556 50 52.5384 50L53 49.9999V-6.10352e-05L-14 -1.90511e-05Z",
              fill: "url(#paint0_linear_3956_16647)",
            },
          }),
        ]),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_3956_16647",
                  x1: "19.5",
                  y1: "50",
                  x2: "19.5",
                  y2: "-6.10352e-05",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop"),
                _c("stop", { attrs: { offset: "1", "stop-opacity": "0.5" } }),
              ],
              1
            ),
            _c("clipPath", { attrs: { id: "clip0_3956_16647" } }, [
              _c("rect", {
                attrs: {
                  width: "67",
                  height: "50",
                  fill: "white",
                  transform: "translate(-14)",
                },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "svg",
      {
        staticClass:
          "mypage-ticket-today-current-time-parts__bg mypage-ticket-today-current-time-parts__bg--right",
        attrs: {
          width: "53",
          height: "50",
          viewBox: "0 0 53 50",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("g", { attrs: { "clip-path": "url(#clip0_3956_16650)" } }, [
          _c("path", {
            attrs: {
              d: "M67 -1.90511e-05L16.1987 44.1202C11.8327 47.912 6.24438 50 0.461601 50L0 49.9999V-6.10352e-05L67 -1.90511e-05Z",
              fill: "url(#paint0_linear_3956_16650)",
            },
          }),
        ]),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_3956_16650",
                  x1: "33.5",
                  y1: "50",
                  x2: "33.5",
                  y2: "-6.10352e-05",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop"),
                _c("stop", { attrs: { offset: "1", "stop-opacity": "0.5" } }),
              ],
              1
            ),
            _c("clipPath", { attrs: { id: "clip0_3956_16650" } }, [
              _c("rect", {
                attrs: {
                  width: "67",
                  height: "50",
                  fill: "white",
                  transform: "matrix(-1 0 0 1 67 0)",
                },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
    _c("div", {
      staticClass:
        "mypage-ticket-today-current-time-parts__bg mypage-ticket-today-current-time-parts__bg--center",
    }),
    _c("p", {
      staticClass: "mypage-ticket-today-current-time-parts__time",
      domProps: { textContent: _vm._s(_vm.currentTime) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }