var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.informationMessage.isShow &&
    _vm.informationMessage.message[_vm.$i18n.locale] !== ""
    ? _c(
        "div",
        {
          class: [
            _vm.screenLandScape === false
              ? "video-information-section"
              : "video-information-section-side",
          ],
        },
        [
          _c(
            "button",
            {
              class: [
                _vm.screenLandScape === false
                  ? "video-information-section__button"
                  : "video-information-section-side__button",
              ],
              on: {
                click: function ($event) {
                  _vm.enableInformation = !_vm.enableInformation
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "30",
                    viewBox: "0 0 30 30",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M0 15C0 6.71652 6.71652 0 15 0C23.2835 0 30 6.71652 30 15C30 23.2835 23.2835 30 15 30C6.71652 30 0 23.2835 0 15Z",
                      fill: "black",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M15 0C6.71652 0 0 6.71652 0 15C0 23.2835 6.71652 30 15 30C23.2835 30 30 23.2835 30 15C30 6.71652 23.2835 0 15 0ZM15 27.4554C8.12277 27.4554 2.54464 21.8772 2.54464 15C2.54464 8.12277 8.12277 2.54464 15 2.54464C21.8772 2.54464 27.4554 8.12277 27.4554 15C27.4554 21.8772 21.8772 27.4554 15 27.4554Z",
                      fill: "#F2C94C",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M13.1108 9.5144V6.79297H16.8823V9.5144H13.1108ZM13.1323 22.7144V11.7858H16.8394V22.7144H13.1323Z",
                      fill: "#F2C94C",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c("p", {
            class: {
              "video-information-section__message": !_vm.screenLandScape,
              "video-information-section__message--show":
                !_vm.screenLandScape && _vm.enableInformation,
              "video-information-section-side__message": _vm.screenLandScape,
              "video-information-section-side__message--show":
                _vm.screenLandScape && _vm.enableInformation,
            },
            domProps: {
              innerHTML: _vm._s(
                _vm.informationMessage.message[_vm.$i18n.locale]
              ),
            },
            on: {
              click: function ($event) {
                _vm.enableInformation = false
              },
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }