import { render, staticRenderFns } from "./HighlightsInstagramIcon.vue?vue&type=template&id=27f42618"
import script from "./HighlightsInstagramIcon.vue?vue&type=script&lang=ts"
export * from "./HighlightsInstagramIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27f42618')) {
      api.createRecord('27f42618', component.options)
    } else {
      api.reload('27f42618', component.options)
    }
    module.hot.accept("./HighlightsInstagramIcon.vue?vue&type=template&id=27f42618", function () {
      api.rerender('27f42618', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsInstagramIcon.vue"
export default component.exports