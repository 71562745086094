








import { computed, defineComponent, inject, onBeforeMount } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import RewardListSection from '@/components/RewardPage/RewardPane/RewardListSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import useVideoPlatformContents from '@/store/hook/useVideoPlatformContents'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'

export default defineComponent({
  name: 'RewardPane',
  components: { RewardListSection },
  setup() {
    const loading = inject('loading') as PluginApi
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { getCurrentRank } = useMembershipCard()
    const { fetchVideoPlatformContents, rewardDataList } = useVideoPlatformContents()
    const { isFreePlan } = contractInfoStore

    // 現在の会員ランク
    const currentRank = computed(() =>
      isFreePlan ? 'free' : getCurrentRank(mypagePageStore.currentOwnedPoints.value),
    )
    const targetRankRewards = computed(() => rewardDataList.value(currentRank.value))

    onBeforeMount(async () => {
      const loader = loading.show()
      // 特典画像データを取得する
      try {
        const result = await fetchVideoPlatformContents()
        loader.hide()

        if (!result?.isSuccess) {
          await MessageDialogStore.value.open({
            title: I18n.tc('RewardPage.errors.fetchRewardPageDataError.title'),
            message: I18n.tc('RewardPage.errors.fetchRewardPageDataError.message'),
            errorApiResponse: result.response,
          })
          return
        }
      } catch (e) {
        loader.hide()

        await MessageDialogStore.value.open({
          title: I18n.tc('RewardPage.errors.fetchRewardPageDataError.title'),
          message: I18n.tc('RewardPage.errors.fetchRewardPageDataError.message'),
        })
      }
    })

    return {
      rewardDataList: targetRankRewards,
    }
  },
})
