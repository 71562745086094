var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-list-section", {
    staticClass: "language-select-list-section",
    attrs: { listData: _vm.listData, selectedID: _vm.selectedID },
    on: { selectChange: _vm.changeSelected },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (slotProps) {
          return [
            _c("p", { staticClass: "language-select-list-section__item" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("SingleSelectList.language." + slotProps.item.locale)
                  ) +
                  " "
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }