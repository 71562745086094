






































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { MembersRankDataType } from '@/components/MypageTopPage/MembershipCardPane.vue'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'

/**
 * マイページ: 会員証 ポイントゲージパーツ
 */
export default defineComponent({
  name: 'PointGaugeParts',
  props: {
    membersRankData: {
      type: Object as PropType<MembersRankDataType>,
      default: () => ({}),
      require: true,
    },
    isFreePlan: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { getRankAchievementPoint } = useMembershipCard()

    /** 会員ランクリストと必要なポイント */
    const rankNamePointList = Const.MEMBERS_RANK_NAME_POINTS

    /** 会員ランク特典説明へのリンク */
    const rankBenefitsLink = computed(() =>
      I18n.t(
        `MypagePage.MypageTopPage.membersRank.nextRank.free.benefits.${props.membersRankData.currentRank}`,
        {
          url:
            I18n.locale === 'ja'
              ? Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.JA
              : Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.EN,
        },
      ).toString(),
    )

    /**
     * ゲージ横幅 計算
     */
    const gaugeWidth = computed(() => {
      let width = 0
      const currentRank = props.membersRankData?.currentRank
      const point = props.membersRankData?.point || 0
      const rankLength = rankNamePointList.length
      const gaugeStep = rankLength - 1
      const currentRankIndex =
        rankNamePointList.findIndex((rankPoint) => rankPoint.rank === currentRank) || 0
      const nextRank = rankNamePointList[currentRankIndex + 1]
        ? rankNamePointList[currentRankIndex + 1].rank
        : undefined
      const currentRankThreshold = getRankAchievementPoint(currentRank)
      /**
       * 最大ランクの場合、次のランクが存在しないため0を返す
       * 下記、if (gaugeStep <= currentRankIndex)のロジックに入るため、nextRankThresholdに0が入っていても問題ない
       */
      const nextRankThreshold = nextRank ? getRankAchievementPoint(nextRank) : 0

      if (gaugeStep <= currentRankIndex) {
        // 最大ランク時
        width = (point / currentRankThreshold) * 100
      } else if (currentRankIndex === 0) {
        // 最低ランク時
        width = ((point / nextRankThreshold) * 100) / gaugeStep
      } else {
        // それ以外
        width = (100 / gaugeStep) * currentRankIndex
        width +=
          (((point - currentRankThreshold) / (nextRankThreshold - currentRankThreshold)) * 100) / 3
      }

      return `${width}%`
    })

    /**
     * ゲージカラー名取得
     */
    const gaugeColorName = computed(() => {
      if (props.isFreePlan) {
        return 'free'
      }
      return props.membersRankData.currentRank
    })

    /**
     * ゲージに表示するポイント
     * TODO: GOLDより上のランクについてはリリースしないため、ひとまず●だけ表示しておく
     */
    const displayGaugePoint = (point: number, isLast: boolean) =>
      !isLast ? point.toLocaleString() : ''

    return {
      rankNamePointList,
      gaugeWidth,
      gaugeColorName,
      displayGaugePoint,
      rankBenefitsLink,
    }
  },
})
