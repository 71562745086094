var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-home-standings",
      attrs: {
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M30.2222 19.7115C32.8888 12.6004 28.4444 0.888672 17.7778 0.888672C9.90411 0.888672 6.62199 4.33672 4.03861 9.76788C3.18935 11.5533 2.4156 13.5531 1.57907 15.7151C1.10289 16.9458 0.606363 18.2291 0.0639841 19.5553L0 19.7115V23.9998L3.55556 28.4442H24.8889L30.2222 19.7115ZM6.53122 10.7372C6.16395 11.498 5.8016 12.3248 5.43108 13.2222C4.98362 14.306 4.539 15.4551 4.06059 16.6915L4.03501 16.7576C3.68858 17.653 3.32596 18.5894 2.94006 19.5553H11.1572C11.9889 19.5553 12.7944 19.2637 13.4334 18.7312L17.7778 15.1109L6.53122 10.7372ZM23.1111 15.9998C23.1111 16.9816 22.3152 17.7776 21.3333 17.7776C20.3515 17.7776 19.5556 16.9816 19.5556 15.9998C19.5556 15.0179 20.3515 14.222 21.3333 14.222C22.3152 14.222 23.1111 15.0179 23.1111 15.9998Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }