var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landscape-data-view-selector-parts" },
    [
      _c(
        "toggle-button-parts",
        {
          staticClass:
            "landscape-data-view-selector-parts__button landscape-data-view-selector-parts__button__ranking",
          attrs: { enabled: _vm.viewContentsMode === "ranking" },
          on: {
            toggle: function ($event) {
              return _vm.changeViewMode("ranking")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M18 5.062V3H6V5.062H2V8C2 10.525 3.889 12.598 6.324 12.932C6.67278 13.9548 7.2914 14.8644 8.11448 15.5646C8.93756 16.2648 9.93452 16.7297 11 16.91V18C11 18.5304 10.7893 19.0391 10.4142 19.4142C10.0391 19.7893 9.53043 20 9 20H8V22H16V20H15C14.4696 20 13.9609 19.7893 13.5858 19.4142C13.2107 19.0391 13 18.5304 13 18V16.91C14.0655 16.7297 15.0624 16.2648 15.8855 15.5646C16.7086 14.8644 17.3272 13.9548 17.676 12.932C20.111 12.598 22 10.525 22 8V5.062H18ZM4 8V7.062H6V10.828C5.41549 10.6207 4.90946 10.2376 4.55137 9.73128C4.19327 9.22494 4.00067 8.62017 4 8ZM20 8C19.9992 8.62027 19.8066 9.22513 19.4486 9.73161C19.0905 10.2381 18.5845 10.6214 18 10.829V7.062H20V8Z",
                  fill: "white",
                  "fill-opacity": "0.7",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "toggle-button-parts",
        {
          staticClass:
            "landscape-data-view-selector-parts__button landscape-data-view-selector-parts__button__telemetry",
          attrs: { enabled: _vm.viewContentsMode === "telemetry" },
          on: {
            toggle: function ($event) {
              return _vm.changeViewMode("telemetry")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M20 3H4C2.89543 3 2 3.89543 2 5V14H0V5C0 2.79086 1.79086 1 4 1H20C22.2091 1 24 2.79086 24 5V14H22V5C22 3.89543 21.1046 3 20 3ZM15.0064 5C14.7536 5 14.5188 5.13046 14.3853 5.34503L9.47547 13.2358C9.26824 13.5689 9.50773 14 9.9 14H12.8319C13.0508 14 13.2443 13.8576 13.3093 13.6485L15.7048 5.94877C15.8513 5.47794 15.4995 5 15.0064 5ZM8 17V19H0V17H8ZM8 23V21H0V23H8ZM24 17V19H16V17H24ZM14 17H10V19H14V17ZM10 21H14V23H10V21ZM24 23V21H16V23H24Z",
                  fill: "white",
                  "fill-opacity": "0.7",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "toggle-button-parts",
        {
          staticClass:
            "landscape-data-view-selector-parts__button landscape-data-view-selector-parts__button__highlight",
          attrs: { enabled: _vm.viewContentsMode === "highlight" },
          on: {
            toggle: function ($event) {
              return _vm.changeViewMode("highlight")
            },
          },
        },
        [
          _vm.newHighlightIcon
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      opacity: "0.7",
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      fill: "#fff",
                      d: "M15,7c-2.5,0-4.5-2-4.5-4.5c0-0.2,0-0.5,0.1-0.7H1\n\tc-0.6,0-1,0.4-1,1v10.4c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V6.9C15.7,7,15.3,7,15,7z M3.6,12.4H1.8v-1.8h1.8V12.4z M3.6,8.9H1.8V7.1\n\th1.8V8.9z M3.6,5.3H1.8V3.6h1.8V5.3z M7.1,11.6V4.4L13.3,8L7.1,11.6z",
                    },
                  }),
                  _c("circle", {
                    attrs: { fill: "#ff2e00", cx: "15", cy: "2.5", r: "2.5" },
                  }),
                ]
              )
            : _c(
                "svg",
                {
                  attrs: {
                    width: "18",
                    height: "14",
                    viewBox: "0 0 18 14",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M1 0C0.447715 0 0 0.447716 0 1V13C0 13.5523 0.447716 14 1 14H17C17.5523 14 18 13.5523 18 13V1C18 0.447715 17.5523 0 17 0H1ZM2 2H4V4H2V2ZM8 3L15 7L8 11V3ZM4 10H2V12H4V10ZM2 6H4V8H2V6Z",
                      fill: "#fff",
                      "fill-opacity": "0.7",
                    },
                  }),
                ]
              ),
        ]
      ),
      _c(
        "toggle-button-parts",
        {
          staticClass:
            "landscape-data-view-selector-parts__button landscape-data-view-selector-parts__button__radio",
          attrs: { enabled: _vm.viewContentsMode === "radio" },
          on: {
            toggle: function ($event) {
              return _vm.changeViewMode("radio")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M8.66699 0H10.667V6.66667H8.66699V0Z",
                  fill: "white",
                  "fill-opacity": "0.7",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M12.667 5.33301H16.0003V6.66634H12.667V5.33301Z",
                  fill: "white",
                  "fill-opacity": "0.7",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M6.66699 9C6.66699 8.44772 7.11471 8 7.66699 8H16.3337C16.8859 8 17.3337 8.44772 17.3337 9V23C17.3337 23.5523 16.8859 24 16.3337 24H7.66699C7.11471 24 6.66699 23.5523 6.66699 23V9ZM8.66699 10H15.3337V11.3333H8.66699V10ZM15.3337 12.6667H8.66699V14H15.3337V12.6667ZM10.0003 17.3333C10.0003 17.7015 9.70185 18 9.33366 18C8.96547 18 8.66699 17.7015 8.66699 17.3333C8.66699 16.9651 8.96547 16.6667 9.33366 16.6667C9.70185 16.6667 10.0003 16.9651 10.0003 17.3333ZM12.0003 18C12.3685 18 12.667 17.7015 12.667 17.3333C12.667 16.9651 12.3685 16.6667 12.0003 16.6667C11.6321 16.6667 11.3337 16.9651 11.3337 17.3333C11.3337 17.7015 11.6321 18 12.0003 18ZM15.3337 17.3333C15.3337 17.7015 15.0352 18 14.667 18C14.2988 18 14.0003 17.7015 14.0003 17.3333C14.0003 16.9651 14.2988 16.6667 14.667 16.6667C15.0352 16.6667 15.3337 16.9651 15.3337 17.3333ZM9.33366 20.6667C9.70185 20.6667 10.0003 20.3682 10.0003 20C10.0003 19.6318 9.70185 19.3333 9.33366 19.3333C8.96547 19.3333 8.66699 19.6318 8.66699 20C8.66699 20.3682 8.96547 20.6667 9.33366 20.6667ZM12.667 20C12.667 20.3682 12.3685 20.6667 12.0003 20.6667C11.6321 20.6667 11.3337 20.3682 11.3337 20C11.3337 19.6318 11.6321 19.3333 12.0003 19.3333C12.3685 19.3333 12.667 19.6318 12.667 20ZM14.667 20.6667C15.0352 20.6667 15.3337 20.3682 15.3337 20C15.3337 19.6318 15.0352 19.3333 14.667 19.3333C14.2988 19.3333 14.0003 19.6318 14.0003 20C14.0003 20.3682 14.2988 20.6667 14.667 20.6667Z",
                  fill: "#fff",
                  "fill-opacity": "0.7",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "toggle-button-parts",
        {
          staticClass:
            "landscape-data-view-selector-parts__button landscape-data-view-selector-parts__button__gps",
          attrs: { enabled: _vm.viewContentsMode === "gps" },
          on: {
            toggle: function ($event) {
              return _vm.changeViewMode("gps")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "17",
                height: "16",
                viewBox: "0 0 17 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { "clip-path": "url(#clip0_6084_33520)" } }, [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M2.48788 11.1751C2.22671 11.4362 2.22765 11.86 2.48998 12.12L3.8648 13.4826C4.12548 13.741 4.54598 13.7401 4.80551 13.4805L6.09106 12.195C6.61564 11.6704 7.43765 11.5893 8.05467 12.0012L11.6452 14.398C12.0843 14.6912 12.6694 14.6334 13.0427 14.2601C13.4751 13.8277 13.4751 13.1267 13.0427 12.6943L10.9878 10.6394C10.0332 9.68476 10.0331 8.13703 10.9878 7.18241L14.0687 4.1015C14.691 3.47918 14.691 2.4702 14.0687 1.84789C13.4464 1.22557 12.4374 1.22557 11.8151 1.84789L7.2158 6.44716L6.27299 5.50435L10.8723 0.905079C12.0153 -0.237937 13.8685 -0.237938 15.0115 0.905077C16.1545 2.04809 16.1545 3.90129 15.0115 5.04431L11.9306 8.12522C11.4967 8.55914 11.4967 9.26265 11.9306 9.69657L13.9855 11.7515C14.9386 12.7046 14.9386 14.2498 13.9855 15.2029C13.1626 16.0259 11.8729 16.1532 10.9049 15.507L7.31439 13.1101C7.22624 13.0513 7.10881 13.0629 7.03387 13.1378L5.74832 14.4234C4.96972 15.202 3.70823 15.2048 2.92619 14.4296L1.55137 13.067C0.764394 12.287 0.761569 11.0158 1.54507 10.2323L3.82408 7.95326L4.76689 8.89607L2.48788 11.1751Z",
                    fill: "white",
                    "fill-opacity": "0.7",
                  },
                }),
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M4.81653 7.89661C5.20292 8.283 5.82948 8.28236 6.21556 7.89627C6.60165 7.51018 6.60229 6.88362 6.21591 6.49723C5.82952 6.11085 5.20296 6.11149 4.81687 6.49758C4.43079 6.88367 4.43015 7.51022 4.81653 7.89661ZM6.21857 7.43577C6.18312 7.54009 6.12382 7.63809 6.04068 7.72123C5.75105 8.01086 5.28112 8.01121 4.99149 7.72158C4.78503 7.51512 4.72592 7.21704 4.8139 6.95796C4.72586 7.21707 4.78496 7.5152 4.99145 7.72169C5.28108 8.01132 5.75101 8.01098 6.04064 7.72134C6.12381 7.63817 6.18313 7.54014 6.21857 7.43577Z",
                    fill: "white",
                    "fill-opacity": "0.7",
                  },
                }),
              ]),
              _c("defs", [
                _c("clipPath", { attrs: { id: "clip0_6084_33520" } }, [
                  _c("rect", {
                    attrs: {
                      width: "16",
                      height: "16",
                      fill: "white",
                      transform: "translate(0.5)",
                    },
                  }),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }