var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-one-time-pass-complete-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc(
            "MypagePage.MypageOneTimePass.completePage.pageTitle"
          ),
        },
      }),
      _c("one-time-pass-complete-pane", {
        staticClass: "mypage-one-time-pass-complete-page__content",
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector:
              ".mypage-one-time-pass-complete-page__content",
            visible: true,
          },
        },
        [
          _c("atom-router-button", {
            attrs: {
              to: "/mypage",
              linkText: _vm.$tc("common.toMypage"),
              colorType: "secondary",
            },
          }),
        ],
        1
      ),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }