import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 空のテレメトリーデータ
 */
const emptyTelemetryData = {
  gear: 1, // ギアポジション値
  speed: 0, // 車速値
  rpm: 0, // エンジン回転数
  brake: 0, // ブレーキ圧
  throttle: 0, // アクセルペダル
  steering: 0, // ステアリング操舵角
  fuel: -1, // 燃料使用量積算値
  ots: 0, // オーバーテイク残時間
  otsEnabled: false, // オーバーテイク有効化
  otsDisabledTime: 0, // オーバーテイク無効化時間
  tireTemperature: {
    fl: 0, // タイヤ温度 フロント左
    fr: 0, // タイヤ温度 フロント右
    rl: 0, // タイヤ温度 リア左
    rr: 0, // タイヤ温度 リア右
  },
}
/**
 * 画面表示用のテレメトリーデータモデルの型
 */
export type TelemetryDataType = typeof emptyTelemetryData

/**
 * テレメトリー情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/2951708677/API
 */
export default class TelemetryDocument extends DocumentWrapper {
  constructor(initProps?: Partial<TelemetryDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    const telemetryInitProps = initProps
    if (telemetryInitProps) {
      if (Number(initProps?.P2P_disable_Time) > 0 || Number(initProps?.P2P_Event) === 0) {
        telemetryInitProps.Push_to_Pass_Switch_On = '0'
      }
    }
    this.mergeToThis(telemetryInitProps)
  }

  _path = 'data/record/telemetry'

  idAttr = 'raceId_carNo'

  /* eslint-disable camelcase */
  date_carNo: string | null = null

  type: string | null = null

  carNo: string | null = null

  lapctr: string | null = null

  distlap: string | null = null

  lastlaptime: string | null = null

  laptime: string | null = null

  RPM: string | null = null

  Throttle_Pedal: string | null = null

  Gear_Pos: string | null = null

  Car_Speed: string | null = null

  Steering_ANGLE: string | null = null

  BRK_Press_Fr: string | null = null

  Fuel_Used: string | null = null

  Clutch_Position: string | null = null

  P2P_Event: string | null = null

  P2P_disable_Time: string | null = null

  Push_to_Pass_Switch_On: string | null = null

  Start_SW: string | null = null

  Pit_Lane_Switch_On: string | null = null

  Radio_SW: string | null = null

  Drink_SW: string | null = null

  Accel_Lat_G: string | null = null

  Accel_Long_G: string | null = null

  Accel_Vert_G: string | null = null

  Yaw_Rate: string | null = null

  TMS_PRES_FL: string | null = null

  TMS_PRES_FR: string | null = null

  TMS_PRES_RL: string | null = null

  TMS_PRES_RR: string | null = null

  TPR4_IR_Temp_FL: string | null = null

  TPR4_IR_Temp_FR: string | null = null

  TPR4_IR_Temp_RL: string | null = null

  TPR4_IR_Temp_RR: string | null = null

  GPS_Late: string | null = null

  GPS_Long: string | null = null

  Reserved1: string | null = null

  Reserved2: string | null = null

  Reserved3: string | null = null

  createdDate: number | null = null

  sendedDate: number | null = null
  /* eslint-enable camelcase */

  /**
   * ステアリングの値を補正するかどうか
   */
  steeringCorrection = false

  /**
   * このテレメトリーデータを画面に表示する際に利用可能なデータに変換して返す。
   */
  get telemetryData(): TelemetryDataType {
    let steering = Number(this.Steering_ANGLE) || 0
    if (this.steeringCorrection) {
      // ステアリング補正が指定されている場合、正負を逆にする
      steering *= -1
    }
    return {
      gear: (Number(this.Gear_Pos) || 2) - 1, // ギアポジション値(データ値から-1した値をギア値として表示する)
      speed: Number(this.Car_Speed) || 0, // 車速値
      rpm: Number(this.RPM) || 0, // エンジン回転数
      brake: Number(this.BRK_Press_Fr) < 0 ? 0 : Number(this.BRK_Press_Fr) || 0, // ブレーキ圧
      throttle: Number(this.Throttle_Pedal) || 0, // アクセルペダル
      steering, // ステアリング操舵角
      fuel: Math.floor((Number(this.Fuel_Used) || 0) * 10) / 10, // 燃料使用量積算値(小数第二位で切り捨てる)
      ots: Number(this.P2P_Event) > 200 ? 0 : Number(this.P2P_Event) || 0, // オーバーテイク残時間(OTSが利用できないレースの場合、200よりも大きい値となるため、0に変換する )
      otsEnabled: this.Push_to_Pass_Switch_On !== '0', // オーバーテイク有効化
      otsDisabledTime: Number(this.P2P_disable_Time) || 0, // オーバーテイク無効化時間
      tireTemperature: {
        fl: Number(this.TPR4_IR_Temp_FL) || 0, // タイヤ温度 フロント左
        fr: Number(this.TPR4_IR_Temp_FR) || 0, // タイヤ温度 フロント右
        rl: Number(this.TPR4_IR_Temp_RL) || 0, // タイヤ温度 リア左
        rr: Number(this.TPR4_IR_Temp_RR) || 0, // タイヤ温度 リア右
      },
    }
  }

  /**
   * テレメトリーデータが存在しない場合に利用される空データ
   */
  static get EMPTY_TELEMETRY_DATA(): TelemetryDataType {
    return emptyTelemetryData
  }
}
