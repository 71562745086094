import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * Standingsデータの型
 */
export type StandingsType = {
  /**
   * 背番号
   */
  carNo: string | null
  /**
   * 選手省略名
   */
  playerShortName: string | null
  /**
   * 順位
   */
  rank: number | null
  /**
   * 累計獲得ポイント
   */
  totalPts: number | null
}

/**
 * Standings情報を表現するクラス。
 * @see https://docs.google.com/spreadsheets/d/1Qq3QJ6kVCrVcPOzb8nQBEBOK51cnRPFuLvXKcd9zPcw/edit#gid=1945355657
 */
export default class StandingsDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<StandingsDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = ''

  idAttr = 'raceCategory'

  /**
   * レースカテゴリ
   */
  raceCategory: string | null = null

  /**
   * スタンディングスデータ
   */
  standings: Array<StandingsType> | null = null

  /**
   * 年度
   */
  seasonYear: number | null = null

  /**
   * 作成日時
   */
  createdDate: number | null = null

  /**
   * 更新日時
   */
  lastModifiedDate: number | null = null
}
