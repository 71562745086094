import { reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import { IndexedDBDataClass } from '@/store/stores/IndexedDBstore/IndexedDBDataClass'
import Logger from '@/util/logger/Logger'
import { IndexedDBDataType } from '@/store/stores/IndexedDBstore/IndexedDBAccessorType'

/**
 * indexedDBを操作するためのストア
 */
/* eslint-disable class-methods-use-this */
class IndexedDBStore implements StoreBase {
  createStore<T extends IndexedDBDataType>() {
    const state = reactive({ db: null as IndexedDBDataClass | null })

    /**
     * IndexedDBを初期化する
     */
    const init = () => {
      if (!state.db) {
        try {
          state.db = new IndexedDBDataClass()
          Logger.info('IndexedDBStore#init: Initialized indexedDB.')
        } catch (error) {
          Logger.info('IndexedDBStore#init: Failed to indexedDB initialize.')
        }
      }
    }

    /**
     * データを追加する
     */
    const add = (key: string, data: T) => {
      init()
      try {
        if (!state.db) {
          Logger.info('IndexedDBStore#add: Failed to add. db not initialize.')
        } else {
          state.db.generalTable.add({ key, data })
        }
      } catch (error) {
        Logger.info(
          `IndexedDBStore#add: Failed to add data for indexedDB. ${key}: ${JSON.stringify(
            data,
          )} error: ${JSON.stringify(error)}`,
        )
      }
    }

    /**
     * データを更新する
     */
    const update = (key: string, data: T) => {
      init()
      try {
        if (!state.db) {
          Logger.info('IndexedDBStore#update: Failed to update. db not initialize.')
        } else {
          state.db.generalTable.update(key, { data })
        }
      } catch (error) {
        Logger.info(
          `IndexedDBStore#update: Failed to update data for indexedDB. ${key}: ${JSON.stringify(
            data,
          )}`,
        )
      }
    }

    /**
     * データを削除する
     */
    const deleteData = async (key: string) => {
      init()
      try {
        if (!state.db) {
          Logger.info('IndexedDBStore#deleteData: Failed to delete. db not initialize.')
        } else {
          state.db.generalTable.delete(key)
        }
      } catch (error) {
        Logger.info(`IndexedDBStore#deleteData: Failed to delete data for indexedDB. ${key}`)
      }
    }

    /**
     * データを取得する
     */
    const get = async <K extends T>(key: string): Promise<K | null> => {
      init()
      try {
        if (!state.db) {
          Logger.info('IndexedDBStore#get: Failed to get. db not initialize.')
          return null
        }
        const data = await state.db.generalTable.get(key)
        if (!data) return null
        return data.data as K
      } catch (error) {
        Logger.info(`IndexedDBStore#get: Failed to get data for indexedDB. ${key}`)
        return null
      }
    }

    return {
      init,
      add,
      update,
      deleteData,
      get,
    }
  }
}

const value: ValueType<IndexedDBStore> = {}

export default {
  createStore: new IndexedDBStore().createStore,
  value: value as Required<typeof value>,
}
