var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-ranking-title-parts",
      class: "mission-ranking-title-parts--" + _vm.rankingType.toLowerCase(),
    },
    [
      _c("p", { staticClass: "mission-ranking-title-parts__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("p", { staticClass: "mission-ranking-title-parts__summary" }, [
        _vm._v(_vm._s(_vm.summary)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }