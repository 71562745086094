import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import type { ChargeType } from '@/store/stores/collectionModule/documents/contractInfo/BillingInfoTypes'
import { getDeclineInfo } from '@/util/StripeCodeGetter'

/**
 * 支払い周期の型
 */
export type PaymentCycleType = 'MONTH' | 'YEAR'

/**
 * 決済ステータスの型
 */
export type PaymentStatusType =
  | 'InProgress'
  | 'Error'
  | 'Complete'
  | 'Waiting'
  | 'ProcessCallError'
  | 'Requesting'
  | 'Cancel'
  | 'InRecoveryProgress'

/**
 * 決済失敗理由の型
 */
type SettlementFailureReasonType = {
  code:
    | 'custom_contractInfo_not_found'
    | 'custom_paymentService_is_null'
    | 'custom_externalCustomerId_is_blank'
    | 'custom_cannot_retrieve_customer_from_stripe'
    | 'custom_paymentIntent_is_not_succeeded'
    | 'authentication_required'
  declineCode: string | null
  message: string | null
}

/**
 * 決済失敗履歴の型
 */
export type SettlementFailureHistoryType = {
  date?: number | null
  reason?: SettlementFailureReasonType | null
}

/**
 * 請求ステータスの型
 */
type BillingStatusType = 'Provisional' | 'Fixed' | 'InProgress'

/**
 * 請求対象プランの型
 */
export type BillingPlanType = {
  contractPlanId?: string
  price?: number
  startDate?: number | null
  endDate?: number | null
  usageDetails?: Array<{
    contractDays: number
    usage: number
    pricePerUsage: number
  }>
  id?: string
} | null

/**
 * 決済失敗情報の型
 */
export type DeclineInfoType = {
  description: string
  nextSteps: string
  nextUserAction: string
}

/**
 * 請求情報APIを操作するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1274347570/API
 */
export default class BillingInfoDocument extends DocumentWrapper {
  constructor(initProps?: Partial<BillingInfoDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/billing_info'

  _useAdminAuthToken = true

  idAttr = 'billingInfoId'

  /**
   * 主キー
   */
  billingInfoId: string | null = null

  /**
   * 請求対象年月 YYYYMMの形式であることに注意
   */
  billingTargetYearMonth: number | null = null

  /**
   * 請求日時
   */
  billingDate?: number | null = null

  /**
   * 支払い周期
   */
  paymentCycle: PaymentCycleType | null = null

  /**
   * 請求金額
   */
  charge: ChargeType | null = null

  /**
   * 請求対象プラン
   */
  plans?: Array<BillingPlanType> | null = null

  /**
   * 決済ステータス
   */
  paymentStatus: PaymentStatusType | null = null

  /**
   * 決済日時
   */
  paymentDateTime?: number | null

  /**
   * 支払い方法
   */
  paymentMethod: 'CREDIT_CARD' | 'BANK_TRANSFER' | 'IN_APP_PURCHASE' | 'MANUAL' = 'MANUAL'

  /**
   *  決済失敗理由
   */
  settlementFailureReason: SettlementFailureReasonType | null = null

  /**
   *  決済失敗日時
   */
  settlementFailureDate?: number

  /**
   * 決済失敗履歴
   */
  settlementFailureHistories?: Array<SettlementFailureHistoryType> | null = null

  /**
   * 請求ステータス
   */
  billingStatus: BillingStatusType | null = null

  /**
   * 請求金額の合計
   */
  get total() {
    return this.charge?.total || 0
  }

  /**
   * 決済未完了状態かどうか
   * 2023/5/12以降にクレジットカード情報再登録を行い、3Dセキュア認証絡みのエラー（=エラーコード: authentication_required）の場合も暫定的に決済未完了状態の扱いをする。将来的には決済失敗とみなしてReadOnly状態にする予定。
   */
  get isAccountPending() {
    return (
      this.settlementFailureReason?.code === 'custom_paymentIntent_is_not_succeeded' ||
      this.settlementFailureReason?.code === 'authentication_required'
    )
  }

  /**
   * アプリ内課金の決済かどうか
   */
  get isInAppPurchasePayment() {
    return this.paymentMethod === 'IN_APP_PURCHASE'
  }

  /**
   * 決済失敗情報を取得する
   */
  get declineInfo(): DeclineInfoType | undefined {
    if (!this.settlementFailureReason?.declineCode) return

    const result = getDeclineInfo(this.settlementFailureReason.declineCode)
    // eslint-disable-next-line consistent-return
    return {
      description: result?.description || '',
      nextSteps: result?.nextSteps || '',
      nextUserAction: result?.nextUserAction || '',
    }
  }
}
