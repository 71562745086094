


















import { defineComponent } from '@vue/composition-api'
import NavigationNotificationIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationNotificationIconParts.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * グローバルナビゲーション: 通知リンクアイコンセクション
 */
export default defineComponent({
  name: 'NavigationNotificationSectionParts',
  components: {
    NavigationNotificationIconParts,
  },
  props: {
    hasName: {
      type: Boolean,
      default: false,
    },
    current: {
      type: String,
      default: '',
    },
    isSp: {
      type: Boolean,
      default: false,
    },
    /**
     * アプリ内課金購入処理実行中かどうか
     */
    isPurchasingInAppPurchase: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const notificationStore = StoreUtil.useStore('NotificationStore')
    const { isDisplayNotificationIcon } = notificationStore

    return {
      isDisplayNotificationIcon,
    }
  },
})
