var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-ticket-item-section" }, [
    _c("p", { staticClass: "mypage-ticket-item-section__title" }, [
      _vm._v(_vm._s(_vm.ticketType.ticketTypeName)),
    ]),
    _c(
      "table",
      { staticClass: "mypage-ticket-item-section__list" },
      _vm._l(_vm.purchasedTickets, function (ticket) {
        return _c("mypage-ticket-item-detail-parts", {
          key: ticket.productNumber,
          staticClass: "mypage-ticket-item-section__item",
          attrs: { ticket: ticket, ownOrganization: _vm.ownOrganization },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }