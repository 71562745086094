
















































import { computed, defineComponent, PropType } from '@vue/composition-api'
import RadioListPlayButtonParts from '@/components/RaceVideoPage/common/parts/RadioListPlayButtonParts.vue'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'

export default defineComponent({
  name: 'RadioListItemParts',
  components: {
    RadioListPlayButtonParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選手無線データ
     */
    radioData: {
      type: Object as PropType<RadioDataDocument>,
      require: true,
    },
  },
  setup(props) {
    const playerRadioData = computed(() => props.radioData)
    const lap = computed(() => props.radioData?.lap)
    const sector = computed(() => props.radioData?.sector)
    return {
      playerRadioData,
      lap,
      sector,
    }
  },
  methods: {
    /**
     * 無線交信データを再生する。
     */
    async playRadioAudio(radioData: RadioDataDocument, scene: boolean) {
      this.$emit('radioPlay', radioData, scene)
    },
  },
})
