import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'

/**
 * ユーザー登録トークン情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1135870048/07+API
 */
export default class UserRegisterTokenDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserRegisterTokenDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/regist_token'

  idAttr = '_id'

  /**
   * reCAPTCHAのトークン
   */
  reCaptchaToken: string | null = null

  /**
   * 組織名
   */
  organizationName = ''

  /**
   * 組織種別
   */
  organizationType = 'SFGO'

  /**
   * 言語
   */
  lang: UserLang | null = null

  /**
   * 登録方法
   *  現状はメールアドレス登録のみ
   */
  registMethod: 'MAIL_ADDRESS' | 'SNS_LINE' = 'MAIL_ADDRESS'

  /**
   * メールアドレス
   */
  mailAddress: string | null = null

  /**
   * 会員登録情報の追加項目
   */
  additionalData?: {
    /**
     * 会員登録タイプ
     */
    memberType: MemberType
  }
}
