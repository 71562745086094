






































import { defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import SelectListSection from '@/components/common/SelectList/SelectListSection.vue'
import ModalHeaderParts from '@/components/common/modal/parts/ModalHeaderParts.vue'
import PlayerCardParts from '@/components/common/card/PlayerCardParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * レース動画再生画面: 選手選択モーダル
 */
export default defineComponent({
  name: 'PlayerSelectModalSection',
  components: {
    CommonModalSection,
    SelectListSection,
    PlayerCardParts,
    ModalHeaderParts,
    AtomInputButton,
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { exactParticipatingPlayers, selectedPlayerId } = raceVideoPageStore

    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player?: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }

    return {
      /**
       * 大会に参加している選手一覧
       */
      exactParticipatingPlayers,
      /**
       * 現在選択されている選手のID
       */
      selectedPlayerId,
      playerPictureUrl,
    }
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 初期お気に入り選択フラグ
     */
    favoriteRacePlayerSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * モーダル閉じる通知
     */
    emitClose(): void {
      // 選手が未選択の場合、モーダルはクローズしない
      if (this.selectedPlayerId) {
        this.$emit('close')
      }
    },
    /**
     * 選手が選択された際に呼び出される。
     * @param selectedItemID 選手ID
     */
    changeSelected(selectedItemID: string): void {
      this.selectedPlayerId = selectedItemID
      this.$emit('close', 'change')
    },
  },
})
