





















































































































































































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import RankingOtsParts from '@/components/RaceVideoPage/RaceVideoRankingPane/parts/RankingOtsParts.vue'
import { RankingViewMode } from '@/components/RaceVideoPage/RaceVideoRankingPane.vue'
import { TelemetryDataType } from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'
import { BattleDataType } from '@/components/RaceVideoPage/hook/useRanking'
import { PlayerLiveTimingDataType } from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'
import RadioDataDocument, {
  createEmptyRadioData,
} from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * レース動画再生画面 ランキング表示プレイヤーのコンポーネント
 */
export default defineComponent({
  name: 'RankingPlayerParts',
  components: {
    RankingOtsParts,
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * Leader表示フラグ
     */
    leader: {
      type: Boolean,
      default: false,
    },
    /**
     * 選択中選手ID
     */
    selectedID: {
      type: String,
      default: '',
    },
    /**
     * 選手のライブタイミング情報
     */
    player: {
      type: Object as PropType<PlayerLiveTimingDataType>,
      required: true,
    },
    /**
     * 選手のテレメトリーデータ
     */
    playerTelemetry: {
      type: Object as PropType<TelemetryDataType>,
      required: true,
    },
    /**
     * ランキング
     */
    rank: {
      type: String,
      required: true,
    },
    /**
     * データ表示モード
     */
    rankingViewMode: {
      type: String as PropType<RankingViewMode>,
      required: true,
    },
    /**
     * バトルデータ
     */
    battleData: {
      type: Object as PropType<BattleDataType>,
      required: true,
    },
    /**
     * 無線データ
     */
    playerRadio: {
      type: Object as PropType<RadioDataDocument>,
      default: () => createEmptyRadioData(),
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 参加選手の情報一覧
     */
    img: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const enabledLeader = computed(() => props.leader)
    const playerData = computed(() => props.player)
    const playerTelemetryData = computed(() => props.playerTelemetry)
    const ranking = computed(() => props.rank)
    const selectedPlayerID = computed(() => props.selectedID)
    const battleList = computed(() => props.battleData)
    const viewModeData = computed(() => props.rankingViewMode)
    const playerRadioData = computed(
      (): RadioDataDocument => (props.playerRadio ? props.playerRadio : createEmptyRadioData()),
    )
    const isPlaying = computed(() => playerRadioData.value.state.isPlaying.value)
    const currentTime = computed(() => playerRadioData.value.state.currentTime.value)

    /**
     * 選手の顔写真ファイルのパス
     */
    const imgPath = computed({
      get: () => props.img || './assets/img/common/card/icon_player__null.svg',
      set: (val: string) => val,
    })

    /**
     * ハイライト表示有効化判定
     */
    const enabledHighlight = (): boolean => {
      if (viewModeData.value === 'lastLapBestTime') return false
      return (
        (enabledLeader.value && Number() === 1) ||
        (!enabledLeader.value && selectedPlayerID.value === playerData.value.id)
      )
    }

    /**
     * 下線部ハイライト表示有効化判定
     */
    const enabledHighlightBottom = (): number => {
      let inBattle = false
      let rt = 1
      if (viewModeData.value === 'lastLapBestTime') {
        return rt
      }
      inBattle = false
      Object.entries(battleList.value).forEach(([, value]) => {
        if (value.includes(Number(ranking.value))) {
          inBattle = true
        }
      })

      if (
        (!enabledLeader.value && selectedPlayerID.value === playerData.value.id && inBattle) ||
        (enabledLeader.value && Number(ranking.value) === 1 && inBattle)
      ) {
        // バトル中 && 選手ハイライト
        rt = 3
      } else if (
        (!enabledLeader.value && selectedPlayerID.value === playerData.value.id) ||
        (enabledLeader.value && Number(ranking.value) === 1)
      ) {
        // 選手ハイライト
        rt = 2
      }
      return rt
    }

    /**
     * 再生中の無線交信のプログレスの値を計算する
     */
    const gaugeWidth = computed(() => {
      if (!playerRadioData.value || !playerRadioData.value.duration) {
        return 0
      }
      return (currentTime.value / playerRadioData.value.duration) * 100
    })
    return {
      imgPath,
      enabledHighlight,
      enabledHighlightBottom,
      playerTelemetryData,
      gaugeWidth,
      playerRadioData,
      isPlaying,
    }
  },
  methods: {
    /**
     * ベストタイムアイコン表示判定
     */
    enabledBestTime(flag: boolean, lastLap: string, bestLap: string): boolean {
      return (
        (lastLap &&
          this.enabledLastLapHighlight(lastLap, bestLap) &&
          this.rankingViewMode === 'lastLapBestTime') ||
        (flag && this.rankingViewMode !== 'lastLapBestTime')
      )
    },
    /**
     * ラストラップがベストタイムか判定
     */
    enabledLastLapHighlight(lastLap: string, bestLap: string): boolean {
      return lastLap === bestLap && lastLap !== ''
    },
    /**
     * 全体1位か判定
     */
    enabledWinnerHighlight(winner: boolean): boolean {
      return this.player.lastLapTime !== '' && winner
    },
    /**
     * ラップタイムをトリミング
     * 空値の場合は空値用テキストを返却
     * @param time
     */
    trimmingLapTime(time: string): string {
      if (time === '') {
        return '-------'
      }

      // Last/bestTimeでは使用しない
      let plusTime = '+'
      if (!time.startsWith('-') && !time.includes(':')) {
        plusTime += time
        return plusTime
      }

      const trimLength = 8
      if (time.length <= trimLength) {
        return time
      }

      const returnTime = time.substring(0, trimLength - 1)

      if (!returnTime.endsWith('.')) {
        return returnTime
      }

      return time.substring(0, trimLength - 2)
    },
    /**
     * 無線交信データが選択された際に呼び出される。
     * 対象の無線交信データの再生処理を呼び出す。
     */
    playRankingRadio() {
      this.$emit('rankingRadioPlay', this.playerRadio)
    },
  },
})
