var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "standings-player-result-parts" }, [
    _c("div", { staticClass: "standings-player-result-parts__data" }, [
      _c(
        "span",
        { staticClass: "standings-player-result-parts__value" },
        [
          _vm.totalPosition && _vm.totalPosition < 4
            ? _c("trophy-icon-parts", {
                staticClass: "round-record-parts__position__icon",
                attrs: { position: _vm.totalPosition },
              })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.displayTotalPosition) + " "),
        ],
        1
      ),
      _c("span", {
        staticClass: "standings-player-result-parts__label",
        domProps: {
          textContent: _vm._s(
            _vm.$tc("StandingsPage.DetailsPage.totalPosition")
          ),
        },
      }),
    ]),
    _c("div", { staticClass: "standings-player-result-parts__data" }, [
      _c("span", {
        staticClass: "standings-player-result-parts__value",
        domProps: { textContent: _vm._s(_vm.totalPoints) },
      }),
      _c("span", {
        staticClass: "standings-player-result-parts__label",
        domProps: {
          textContent: _vm._s(_vm.$tc("StandingsPage.TopPage.totalPts")),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }