var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.inputValue,
        expression: "inputValue",
      },
    ],
    class: { "input--validation-error": _vm.error },
    attrs: {
      type: "text",
      id: _vm.id || null,
      maxlength: _vm.maxlength > 0 ? _vm.maxlength : null,
      placeholder: _vm.placeholder,
      disabled: _vm.isDisabled,
    },
    domProps: { value: _vm.inputValue },
    on: {
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        return _vm.emitEnter.apply(null, arguments)
      },
      input: function ($event) {
        if ($event.target.composing) {
          return
        }
        _vm.inputValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }