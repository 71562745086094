

































import { computed, defineComponent, PropType } from '@vue/composition-api'
import RankingPlayerParts from '@/components/RaceVideoPage/RaceVideoRankingPane/parts/RankingPlayerParts.vue'
import { RankingViewMode } from '@/components/RaceVideoPage/RaceVideoRankingPane.vue'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import { BattleDataType } from '@/components/RaceVideoPage/hook/useRanking'
import { PlayerLiveTimingDataType } from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'
import { TelemetryDataType } from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'
import type { RadioDataHashedByCarNoType } from '@/components/RaceVideoPage/hook/useRadio'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * レース動画再生画面 ランキング表示リスト設定セクションのコンポーネント
 */
export default defineComponent({
  name: 'RankingListSection',
  components: {
    RankingPlayerParts,
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * Leader表示フラグ
     */
    leader: {
      type: Boolean,
      default: false,
    },
    /**
     * 選択中選手ID
     */
    selectedID: {
      type: String,
      default: '',
    },
    /**
     * 選手のライブタイミングデータ
     */
    rankingData: {
      type: Array as PropType<Array<PlayerLiveTimingDataType>>,
      required: true,
      default: () => [],
    },
    /**
     * 選手毎の無線交信データ
     */
    playerRadioData: {
      type: Map as PropType<RadioDataHashedByCarNoType>,
      required: true,
    },
    /**
     * 選手のテレメトリーデータ
     */
    playerTelemetry: {
      type: Object as PropType<Record<string, TelemetryDataType>>,
      required: true,
    },
    /**
     * バトルデータ
     */
    battleData: {
      type: Object as PropType<BattleDataType>,
      required: true,
    },
    /**
     * データ表示モード
     */
    rankingViewMode: {
      type: String as PropType<RankingViewMode>,
      required: true,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
  setup(props) {
    const currentPlayerId = computed(() => props.selectedID)
    const rankingDataList = computed(() => props.rankingData)
    const playerRadioDataByCarNo = computed((): RadioDataHashedByCarNoType => props.playerRadioData)

    const { playRadioDataAudio } = useRadio()
    /**
     * 選手顔写真のURLを取得する
     * @param playerLiveTimingData 選手のライブタイミングデータ
     */
    const playerPictureUrl = (playerLiveTimingData: PlayerLiveTimingDataType) =>
      CloudFrontUtil.getSignedUrl(playerLiveTimingData.img)

    /**
     * お気に入り選手のIDを返す。
     * お気に入り選手がレース参加しているかチェックし、存在しない場合は、1位選手のIDを返す。
     */
    const checkSelectedID = () => {
      if (!rankingDataList.value || rankingDataList.value.length === 0) {
        return currentPlayerId.value
      }

      const targetPlayerLiveTiming = rankingDataList.value.find(
        (playerLiveTiming) => playerLiveTiming.id === currentPlayerId.value,
      )
      if (!targetPlayerLiveTiming) {
        // お気に入り選手がライブタイミングデータに含まれないので、先頭のライブタイミングを選択する
        return rankingDataList.value[0].id
      }

      return currentPlayerId.value
    }
    return {
      rankingDataList,
      playerRadioDataByCarNo,
      playerPictureUrl,
      checkSelectedID,
      playRadioDataAudio,
    }
  },
  methods: {
    /**
     * バトル表示TOP位置指定
     */
    battleAreaTopPosition(battle: number[]): string {
      return `${(battle[0] - 1) * 5.33166667 + 0.708}em`
    },
    /**
     * バトル表示高さ指定
     */
    battleAreaHeight(battle: number[]): string {
      return `${battle.length * 5.33166667 - 0.708}em`
    },
    /**
     * 無線交信データを再生する。
     */
    playRankingRadio(playerRadioData: RadioDataDocument) {
      this.playRadioDataAudio(playerRadioData)
    },
  },
})
