




















































import { computed, defineComponent, Ref } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'
import FujiParts from '@/components/common/circuit/FujiParts.vue'
import SuzukaParts from '@/components/common/circuit/SuzukaParts.vue'
import SugoParts from '@/components/common/circuit/SugoParts.vue'
import AutopoliceParts from '@/components/common/circuit/AutopoliceParts.vue'
import MotegiParts from '@/components/common/circuit/MotegiParts.vue'
import DeviceInfo from '@/util/DeviceInfo'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'MypageTicketPastPane',
  components: {
    AtomButton,
    MotegiParts,
    AutopoliceParts,
    SugoParts,
    SuzukaParts,
    FujiParts,
  },
  setup() {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { selectedPastTicketInfo, digitalTicketsByUniqueKey, digitalTicketImagesByUserImageId } =
      mypageTicketPageStore
    const { ownOrganization } = mypagePageStore
    const { isEnteredTargetEventDate } = usePurchasedTicketData()
    const { getDisplayDateJa } = useDisplayDependingOnLang()

    const isPc = DeviceInfo.isForPC()
    // TODO: SNSシェア機能を公開次第、こちらのフラグを削除する
    const isOpen = false

    /**
     * 対象チケットのイベント
     */
    const targetTicketEvent = computed(
      () =>
        digitalTicketsByUniqueKey.value?.[selectedPastTicketInfo.value?.ticketEventUniqueKey ?? ''],
    )

    /**
     * 過去のチケット背景画像
     */
    const pastScreenBgImageStyle = computed(() => ({
      '--img': `url(${
        digitalTicketImagesByUserImageId.value[targetTicketEvent.value.userImageId ?? '']?.path
      })`,
    }))

    /**
     * イベント開催日
     */
    const displayEventDate = computed(() =>
      selectedPastTicketInfo.value?.day
        ? getDisplayDateJa(targetTicketEvent.value.openingDates?.[selectedPastTicketInfo.value.day])
        : '',
    )

    /**
     * 入場済みかどうか
     */
    const isEntered = computed(() =>
      isEnteredTargetEventDate(
        targetTicketEvent.value,
        ownOrganization.value,
        selectedPastTicketInfo.value?.day,
      ),
    )

    return {
      pastScreenBgImageStyle,
      targetTicketEvent: targetTicketEvent as Ref<DigitalTicketDataDocument>,
      displayEventDate,
      isEntered,
      isPc,
      isOpen,
    }
  },
})
