var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    {
      staticClass: "mission-card-get-point",
      class: ["color-type-" + _vm.mission.colorType, { "is-dark": _vm.isDark }],
    },
    [
      _c("dt", { staticClass: "mission-card-get-point__title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$tc("MissionPage.get")))]),
      ]),
      _c("dd", { staticClass: "mission-card-get-point__data" }, [
        _vm._v(_vm._s(_vm.mission.earnedPoints) + "P"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }