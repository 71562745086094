var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sns-post-item-header-parts" }, [
    _c("img", {
      staticClass: "sns-post-item-header-parts__thumbnail",
      attrs: { src: _vm.snsPost.thumbnail, alt: "" },
    }),
    _c("p", {
      staticClass: "sns-post-item-header-parts__user-name",
      domProps: { textContent: _vm._s(_vm.snsPost.username) },
    }),
    _c("i", { staticClass: "sns-post-item-header-parts__sns-icon" }, [
      _vm.snsPost.linkType === "instagram"
        ? _c("img", {
            attrs: {
              src: require("@/assets/img/icon/icon_instagram.svg"),
              alt: "",
            },
          })
        : _vm.snsPost.linkType === "twitter"
        ? _c("img", {
            attrs: { src: require("@/assets/img/icon/icon_x.svg"), alt: "" },
          })
        : _vm.snsPost.linkType === "highlights"
        ? _c("img", {
            attrs: {
              src: require("@/assets/img/icon/icon_highlights.svg"),
              alt: "",
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }