






import { defineComponent } from '@vue/composition-api'
import ErrorHeaderSection from '@/components/ErrorPage/ErrorPane/ErrorHeaderSection.vue'
import ErrorBodySection from '@/components/ErrorPage/ErrorPane/ErrorBodySection.vue'

export default defineComponent({
  name: 'ErrorPagePane',
  components: {
    ErrorBodySection,
    ErrorHeaderSection,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    descriptions: {
      type: [],
      default: () => [],
    },
  },
})
