














import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ContractTermType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import I18n from '@/locales/I18n'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * マイページ: 契約情報 現在のプラン セクション
 */
export default defineComponent({
  name: 'MypageCurrentPlanSection',
  props: {
    currentPlanName: {
      type: String,
      default: '',
    },
    /**
     * 契約中のプランの契約期間
     */
    contractTerm: {
      type: Object as PropType<ContractTermType>,
      default: null,
    },
  },
  setup(props) {
    const { getDisplayDateTime } = useDisplayDependingOnLang()
    /**
     * クーポンの有効期限
     * クーポンプラン契約中の場合、contractTermが親から渡ってくる
     */
    const couponExpirationDateText = computed(() => {
      if (!props.contractTerm || !props.contractTerm.contractEndDate) return ''

      const endDate = getDisplayDateTime(props.contractTerm.contractEndDate)
      return `${I18n.t('MypagePage.MypageContract.detailsPage.couponContractTerm', {
        endDate,
      }).toString()}`
    })

    return {
      couponExpirationDateText,
    }
  },
})
