































import { computed, defineComponent, PropType } from '@vue/composition-api'
import dayjs from 'dayjs'
import StoreUtil from '@/store/StoreUtil'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import usePermission from '@/components/hook/usePermission'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import useMovieContentsStatus from '@/components/hook/useMovieContentsStatus'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'

/* eslint-disable @typescript-eslint/no-var-requires */
const playIconPath = require('@/assets/img/icon/icon_play__green.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const disabledPlayIconPath = require('@/assets/img/icon/icon_play__disabled.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const circuitPlayIconPath = require('@/assets/img/icon/icon_play__white.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const playLockIconPath = require('@/assets/img/icon/icon_play_lock.svg')

export default defineComponent({
  name: 'TimetableItemParts',
  components: { AtomIconRouterButton },
  props: {
    /**
     * レース情報
     */
    raceDataItem: {
      type: Object as PropType<RaceDocument>,
      required: true,
    },
    /**
     * コンテンツ動画情報
     */
    contentsInfos: {
      type: Array as PropType<Array<ContentsInfoDocument>>,
      required: true,
    },
    /**
     * アングル動画情報
     */
    angleMovieInfos: {
      type: Array as PropType<Array<AngleMovieInfoDocument>>,
      required: true,
    },
    /**
     * 予選レースに登録されているスタートイベント
     */
    qualifyingRaceStartEventList: {
      type: Array as PropType<Array<HighlightDocument>>,
    },
  },
  setup(props, context) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    const { canShowRace, getTargetRacePermission } = usePermission()
    const { hasDistributedMovie } = useMovieContentsStatus()

    /**
     * ボタンを押せない状態かどうか
     */
    const isDisabled = computed(
      () =>
        !hasDistributedMovie(
          props.raceDataItem,
          props.contentsInfos,
          props.angleMovieInfos,
          props.qualifyingRaceStartEventList,
        ),
    )

    const enableCircuitMode = computed(() => {
      // 再生権限がなかったらボタンの色を変更しない
      if (
        !canShowRace(
          props.raceDataItem,
          getTargetRacePermission(props.raceDataItem.additionalData?.raceType),
        )
      ) {
        return false
      }

      // ボタンが disabled だったらボタンの色を変更しない
      if (isDisabled.value) {
        return false
      }

      // サーキットモードになったら再生ボタンの色を変更する
      return appConfigStore.currentCircuitMode.value
    })

    /**
     * レースの閲覧権限がない場合、モーダルを表示するために親に通知
     * @param race 対象のレース情報
     */
    const clickRaceListRouterButton = (race: RaceDocument) => {
      if (isDisabled.value) {
        return
      }

      if (canShowRace(race, getTargetRacePermission(race.additionalData?.raceType))) {
        return
      }

      context.emit('showFlowLineToPaidPlanModal')
    }

    /**
     * レースの開始時間を返す
     */
    const getStartTime = (startDate: number) => {
      if (!startDate) return ''
      return dayjs(startDate).tz('Asia/Tokyo').format('HH:mm')
    }

    return {
      enableCircuitMode,
      canShowRace,
      getTargetRacePermission,
      hasDistributedMovie,
      isDisabled,
      clickRaceListRouterButton,
      getStartTime,
    }
  },
  methods: {
    /**
     * 指定されたレースの動画再生画面に遷移する。
     * @param race レース
     */
    linkTo(race: RaceDocument) {
      if (race.highlightPlayInfo?.highlightId) {
        return `/race-video/${race.championshipMasterId}/${race.id}/${race.highlightPlayInfo.highlightId}/`
      }
      return `/race-video/${race.championshipMasterId}/${race.id}/`
    },
    /**
     * 会員プランに応じてレース公開非公開の設定に応じたアイコンの出し分け
     * @param permission
     */
    setPlayIcon(permission: boolean): string {
      if (this.isDisabled) {
        return disabledPlayIconPath
      }
      if (!permission) {
        return playLockIconPath
      }
      return this.enableCircuitMode ? circuitPlayIconPath : playIconPath
    },
  },
})
