import dayjs from 'dayjs'
import { TermType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 期間を操作するための処理を取得する。
 */
export default function useMissionTerm() {
  // methods
  /**
   * 対象年度のミッション期間を取得する。
   */
  const getTargetYearMissionTerm = (year: number) => {
    // 選択年度のミッション期間を取得する。ミッション期間は対象年度の2/1〜翌年1/31（JST）となる。
    const startDate = dayjs.tz(`${year}-02-01`, 'Asia/Tokyo').startOf('day').valueOf()
    const endDate = dayjs
      .tz(`${year + 1}-01-31`, 'Asia/Tokyo')
      .endOf('day')
      .valueOf()

    return {
      startDate,
      endDate,
    } as TermType
  }

  return {
    getTargetYearMissionTerm,
  }
}
