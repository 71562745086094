var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-about-using-pane" },
    [
      _c("mypage-about-using-section", {
        attrs: {
          title: _vm.$tc("MypagePage.MypageAboutUsingPage.title"),
          aboutUsingListItems: _vm.aboutUsingListItems,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }