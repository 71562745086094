































import { defineComponent, PropType, computed } from '@vue/composition-api'
import HomeLockIcon from '@/components/HomePage/HomePane/parts/icons/HomeLockIcon.vue'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import type { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import usePermission from '@/components/hook/usePermission'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import I18n from '@/locales/I18n'

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultIcon = require('@/assets/img/icon/icon_user_default.png')

/**
 * 新着ハイライト作成ユーザーのデータ型
 */
type CreateHighlightUserType = {
  userDisplayName: string
  userPicture: string
}

/**
 * 新着ハイライトカードのデータ型
 */
type HomeHighlightCardType = {
  highlightId: string
  lap: string
  sector: string
  round: string
  movieStartTime: string
  note: string
  thumbnailPath: string
  link: string
  canShow: boolean
  createUser: CreateHighlightUserType
}

export default defineComponent({
  name: 'HighlightsCardParts',
  components: { HomeLockIcon },
  props: {
    /**
     * 大会データ
     */
    championships: {
      type: Array as PropType<Array<ChampionshipDocument>>,
      required: true,
    },
    /**
     * レースデータ
     */
    raceList: {
      type: Array as PropType<Array<RaceDocument>>,
      required: true,
    },
    /**
     * ハイライトデータ
     */
    highlight: {
      type: Object as PropType<HighlightDocument>,
      required: true,
    },
    /**
     * 新着ハイライトで利用するユーザー情報
     */
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
      required: true,
    },
  },
  setup(props, context) {
    const { canShowRace, getTargetRacePermission } = usePermission()

    /**
     * 新着ハイライトカードのデータ
     */
    const card = computed((): HomeHighlightCardType => {
      const propsHighlight = props.highlight
      const propsUsersByUserId = props.retrieveNameUsersByUserId

      // 対象のレース
      const targetRace = props.raceList.find((race) => race.id === propsHighlight.matchId)
      // 対象の大会
      const targetChampionship = props.championships.find(
        (championship) => championship.id === targetRace?.championshipMasterId,
      )
      const createdUser = propsUsersByUserId?.[propsHighlight._createdBy ?? '']
        ? propsUsersByUserId?.[propsHighlight._createdBy ?? '']
        : undefined

      return {
        highlightId: propsHighlight.highlightId ?? '',
        lap: propsHighlight.additionalData?.lap || '-',
        sector: propsHighlight?.additionalData?.sector ?? '--',
        round: targetChampionship?.round
          ? `Rd.${targetChampionship?.round}`
          : targetChampionship?.circuitName[I18n.locale] ?? '',
        movieStartTime: propsHighlight.raceTime,
        note: propsHighlight.note ?? '',
        thumbnailPath: propsHighlight.thumbnailUri
          ? CloudFrontUtil.getSignedUrl(propsHighlight.thumbnailUri)
          : '',
        createUser: {
          userDisplayName: createdUser?.additionalData?.userDisplayName ?? '',
          userPicture: createdUser?.userPicture
            ? CloudFrontUtil.getSignedUrl(createdUser?.userPicture)
            : defaultIcon,
        },
        link: targetRace
          ? `/race-video/${targetRace.championshipMasterId}/${targetRace.id}/${propsHighlight?.highlightId}/?playType=highlight&highlightId=${propsHighlight?.highlightId}`
          : '',
        canShow: targetRace
          ? canShowRace(targetRace, getTargetRacePermission(targetRace.raceType))
          : false,
      }
    })

    /**
     * 新着ハイライトカードクリック時の処理
     */
    const onClickNewHighlightCard = () => {
      if (card.value.canShow) {
        // レース視聴権限がある場合、後続処理をしない
        return
      }

      context.emit('onClickNewHighlightCard')
    }

    return {
      card,
      onClickNewHighlightCard,
    }
  },
})
