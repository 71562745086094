








































import { defineComponent, PropType } from '@vue/composition-api'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'
import { RacePointVideoCardDataType } from '@/components/RaceListPage/hook/useRacePointVideo'
import { RacePointRadioDataType } from '@/store/stores/pageStore/TopPage/TopPageStore'

/* eslint-disable @typescript-eslint/no-var-requires */
const playIconPath = require('@/assets/img/icon/icon_play__green.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const playLockIconPath = require('@/assets/img/icon/icon_play_lock.svg')

/**
 * レース一覧画面: レース ポイント 共通カードコンポーネント
 */
export default defineComponent({
  name: 'CommonRacePointsCardParts.vue',
  components: {
    AtomIconRouterButton,
  },
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    pointsCardData: {
      type: Object as PropType<RacePointVideoCardDataType | RacePointRadioDataType>,
      required: true,
    },
  },
  methods: {
    /**
     * 会員プランに応じてレース公開非公開の設定に応じたアイコンの出し分け
     * @param permission
     */
    setPlayIcon(permission: boolean): string {
      return permission ? playIconPath : playLockIconPath
    },
  },
})
