

















import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: ホームリンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationHomeIconParts',
})
