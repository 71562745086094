/**
 * ローカルストレージを操作するための処理を提供する。
 */
export default class LocalStorageAccessor {
  /**
   * 選択中のレース大会のIDを設定する
   * @param championshipId
   */
  public static set currentChampionshipId(championshipId: string) {
    localStorage.setItem('CURRENT_CHAMPIONSHIP_ID', championshipId)
  }

  /**
   * 選択しているレース大会のIDを取得する
   * @return レース大会のID
   */
  public static get currentChampionshipId(): string {
    return localStorage.getItem('CURRENT_CHAMPIONSHIP_ID') || ''
  }

  /**
   * 選択中のお気に入り選手のIDを設定する
   * @param playerId
   */
  public static set favoritePlayerId(playerId: string) {
    localStorage.setItem('FAVORITE_PLAYER_ID', playerId)
  }

  /**
   * 選択しているお気に入り選手のIDを取得する
   * @return お気に入り選手のID
   */
  public static get favoritePlayerId(): string {
    return localStorage.getItem('FAVORITE_PLAYER_ID') || ''
  }

  /**
   * 選択中のお気に入りチームのIDを設定する
   * @param teamId
   */
  public static set favoriteTeamId(teamId: string) {
    localStorage.setItem('FAVORITE_TEAM_ID', teamId)
  }

  /**
   * 選択しているお気に入りチームのIDを取得する
   * @return {string} お気に入りチームのID
   */
  public static get favoriteTeamId(): string {
    return localStorage.getItem('FAVORITE_TEAM_ID') || ''
  }

  /**
   * ログアウト画面から遷移したかどうかを設定する
   * ログアウト後、自動ログインを抑止するために利用される。
   * @param fromLogout ログアウト画面から遷移した場合 true が設定される
   */
  public static set transitionFromLogout(fromLogout: boolean) {
    localStorage.setItem('TRANSITION_FROM_LOGOUT', `${fromLogout}`)
  }

  /**
   * ログアウト画面から遷移したかどうかを判定する
   */
  public static get transitionFromLogout(): boolean {
    return localStorage.getItem('TRANSITION_FROM_LOGOUT') === 'true'
  }

  /**
   * アプリから遷移したかどうかを設定する
   */
  public static set transitionFromMobileApp(isFromMobileApp: boolean) {
    localStorage.setItem('TRANSITION_FROM_MOBILE_APP', `${isFromMobileApp}`)
  }

  /**
   * アプリから遷移したかどうかを判定する
   */
  public static get transitionFromMobileApp(): boolean {
    return localStorage.getItem('TRANSITION_FROM_MOBILE_APP') === 'true'
  }

  /**
   * 対象のレースの再生位置を記録した動画ID(アングルID)を取得する。
   *
   * @param raceId レースID
   * @return 動画ID(アングルID)の配列
   */
  private static getRecordedVideoPlaybackPositionMovieIds(raceId: string): Array<string> {
    const recordMovieIdsItem = localStorage.getItem(`VIDEO_PLAYBACK_POSITION_MOVIE_IDs_${raceId}`)
    let recordMovieIds: Array<string> = []
    if (recordMovieIdsItem) {
      recordMovieIds = JSON.parse(recordMovieIdsItem) as Array<string>
    }
    return recordMovieIds
  }

  /**
   * 対象のレースに対して再生位置を記録した動画IDを保存する。
   *
   * @param raceId レースID
   * @param movieId 動画ID(アングルID)
   */
  private static setRecordedVideoPlaybackPositionMovieIds(raceId: string, movieId: string) {
    const recordMovieIds = LocalStorageAccessor.getRecordedVideoPlaybackPositionMovieIds(raceId)
    if (!recordMovieIds.includes(movieId)) {
      recordMovieIds.push(movieId)
    }
    localStorage.setItem(
      `VIDEO_PLAYBACK_POSITION_MOVIE_IDs_${raceId}`,
      JSON.stringify(recordMovieIds),
    )
  }

  /**
   * 対象のレースの再生位置を記録する。
   * @param raceId レースID
   * @param movieId 動画ID(アングルID)
   * @param currentTime 再生位置(単位: 秒)
   */
  public static recordVideoPlaybackPosition(raceId: string, movieId: string, currentTime: number) {
    localStorage.setItem(
      `VIDEO_PLAYBACK_POSITION_${raceId}_${movieId}`,
      JSON.stringify({ movieId, currentTime }),
    )
    // 再生位置を記録した動画IDを記録する
    LocalStorageAccessor.setRecordedVideoPlaybackPositionMovieIds(raceId, movieId)
  }

  /**
   * 対象のレースの記録されている再生位置を取得する。
   * @param raceId レースID
   * @param movieId 動画ID(アングルID)
   */
  public static getVideoPlaybackPosition(
    raceId: string,
    movieId: string,
  ): { movieId: string; currentTime: number } {
    const playbackPosition = localStorage.getItem(`VIDEO_PLAYBACK_POSITION_${raceId}_${movieId}`)
    return playbackPosition ? JSON.parse(playbackPosition) : null
  }

  /**
   * 対象のレースで記録されている再生位置を消去する。
   * @param raceId レースID
   */
  public static clearVideoPlaybackPosition(raceId: string) {
    const recordMovieIds = LocalStorageAccessor.getRecordedVideoPlaybackPositionMovieIds(raceId)
    recordMovieIds.forEach((movieId) => {
      localStorage.removeItem(`VIDEO_PLAYBACK_POSITION_${raceId}_${movieId}`)
    })
  }

  /**
   * アプリ内でオンボーディング画面を表示したかをセットする。
   */
  public static set hasDisplayedOnboarding(isDisplayOnboardingDisplay: boolean) {
    localStorage.setItem('HAS_DISPLAYED_ONBOARDING', `${isDisplayOnboardingDisplay}`)
  }

  /**
   * アプリ内でオンボーディング画面を表示したかを取得する。
   */
  public static get hasDisplayedOnboarding(): boolean {
    return localStorage.getItem('HAS_DISPLAYED_ONBOARDING') === 'true'
  }

  /**
   * 最後に取得したお知らせの時間（タイムスタンプ）を取得する
   * @return 最後に取得した時間（タイムスタンプ）
   */
  public static get lastNotificationFetchedDate(): string {
    return localStorage.getItem('LAST_NOTIFICATION_FETCHED_DATE') || ''
  }

  /**
   * 最後に取得したお知らせの時間（タイムスタンプ）を保存する
   * @param date
   */
  public static set lastNotificationFetchedDate(date: string) {
    localStorage.setItem('LAST_NOTIFICATION_FETCHED_DATE', date)
  }

  /**
   * SFbingoが利用する情報をローカルストレージに保存する
   * @param organizationId
   * @param userId
   * @param accessToken
   * @param refreshToken
   */
  public static setCredentialInfo(
    organizationId: string,
    userId: string,
    accessToken: string,
    refreshToken: string,
  ) {
    localStorage.setItem(
      `CREDENTIAL_INFO`,
      JSON.stringify({ organizationId, userId, accessToken, refreshToken }),
    )
  }

  /**
   * SFbingoで利用するためにローカルストレージに保存してた情報を削除する
   */
  public static clearCredentialInfo() {
    localStorage.removeItem('CREDENTIAL_INFO')
  }
}
