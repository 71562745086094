

























































import { defineComponent } from '@vue/composition-api'
import CircuitMapCarParts from '@/components/RaceVideoPage/RaceVideoPane/parts/CircuitMapCarParts.vue'
import useGps from '@/components/RaceVideoPage/hook/useGps'
import StoreUtil from '@/store/StoreUtil'

export type GpsPosition = {
  lat: number
  lng: number
}

export type CircuitGpsPosition = {
  root: GpsPosition
  end: GpsPosition
}

/**
 * レース動画再生画面 サーキットマップセクションのコンポーネント
 */
export default defineComponent({
  name: 'CircuitMapSection',
  components: {
    CircuitMapCarParts,
  },
  props: {
    /**
     * 車両データアニメーションフラグ
     */
    animateGpsMapCar: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { selectedPlayerId } = raceVideoPageStore
    const { imgPath, gpsMap, getCurrentPlayerGps, clearGpsData } = useGps()

    const circuitImgPath = imgPath.value
    const circuitGpsPosition: CircuitGpsPosition = gpsMap.value

    return {
      selectedPlayerId,
      circuitGpsPosition,
      circuitImgPath,
      getCurrentPlayerGps,
      clearGpsData,
    }
  },
  beforeDestroy() {
    this.clearGpsData()
  },
})
