import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import DigitalTicketDataTicketDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketDocument'
import I18n from '@/locales/I18n'

/**
 * デジタルチケット券種データを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3805478936/13.
 */
export default class DigitalTicketDataTicketTypeDocument extends DocumentWrapper {
  constructor(initProps?: Partial<DigitalTicketDataTicketTypeDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)

    if (initProps?.tickets) {
      this.tickets = initProps.tickets.map((p) => new DigitalTicketDataTicketDocument(p))
    }
  }

  /**
   * 券種ID（UUID）
   */
  ticketTypeId = ''

  /**
   * 入場券かどうか、入場券ならtrue
   */
  isAdmission: boolean | null = null

  /**
   * 受付を1回のみにするかどうか、1回のみにするならtrue
   */
  isOnlyOneReception: boolean | null = null

  /**
   * 券種日本語
   */
  ticketTypeJa: string | null = null

  /**
   * 券種英語
   */
  ticketTypeEn: string | null = null

  /**
   * 表示順
   */
  order: number | null = null

  /**
   * 作成日時
   */
  createdDate: number | null = null

  /**
   * チケットデータ一覧
   */
  tickets: Array<DigitalTicketDataTicketDocument> | null = null

  /**
   * 券種名
   */
  get ticketTypeName() {
    return I18n.locale === 'ja' ? this.ticketTypeJa : this.ticketTypeEn
  }
}
