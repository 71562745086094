var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("time", {
    staticClass: "comment-post-date-parts",
    domProps: { textContent: _vm._s(_vm.postDateTime) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }