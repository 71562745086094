












































import { computed, defineComponent } from '@vue/composition-api'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

/**
 * フィールドセット 名前(ふりがな)入力 バーツ
 * todo: 正常なsetup / emit処理 /
 */
export default defineComponent({
  name: 'FieldsetNameRubyParts',
  components: {
    AtomInputText,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    valueFamily: {
      type: String,
      default: '',
    },
    valueFirst: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * familyNameKana
     * @param {string} val 姓(ふりがな)
     */
    const familyNameKana = computed({
      get: () => props.valueFamily,
      set: (val: string) => context.emit('inputfamilyNameKana', val),
    })
    /**
     * firstNameKana
     * @param {string} val 名(ふりがな)
     */
    const firstNameKana = computed({
      get: () => props.valueFirst,
      set: (val: string) => context.emit('inputfirstNameKana', val),
    })
    return {
      familyNameKana,
      firstNameKana,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
