var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticket-link-parts" },
    [
      _vm.purchasedTicketStatus === "hasAvailableTodayTickets"
        ? _c("atom-router-button", {
            attrs: {
              to: "/mypage/ticket-today",
              linkText: _vm.$tc("HomePage.countdown.ticket.todayTickets"),
            },
          })
        : _vm.purchasedTicketStatus === "hasTickets"
        ? _c("atom-router-button", {
            staticClass: "ticket-link-parts__button",
            attrs: {
              to: "/mypage/ticket-list",
              linkText: _vm.$tc("HomePage.countdown.ticket.purchasedTickets"),
              colorType: "tertiary",
            },
          })
        : _c("atom-icon-link-button", {
            staticClass: "ticket-link-parts__button",
            attrs: {
              url: _vm.ecSiteUrl,
              linkText: _vm.$tc("HomePage.countdown.ticket.unPurchased"),
              colorType: "tertiary",
              imgPath: require("@/assets/img/icon/icon_external_link_gray75.svg"),
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }