<template>
  <svg class="icon-circuit" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.7959 20.6739C5.9481 20.5471 30.3264 0.93788 30.4786 0.811042C30.8592 0.50663 31.3919 0.760306 31.3665 1.29303C31.3411 1.57207 31.1889 2.81509 31.1382 3.1956C31.0621 3.70296 30.9099 3.9059 30.6562 4.26104C30.504 4.48935 26.4705 9.58825 26.293 9.81656C25.9378 10.2732 25.9886 10.6283 26.2422 11.1103C26.3944 11.364 26.9018 12.3787 27.1301 12.886C27.8658 14.4842 27.4345 16.5644 25.5319 17.1732C24.3143 17.5537 23.5786 17.3254 22.8683 16.3868C22.5639 15.9809 21.4224 14.4081 21.2448 14.1798C20.915 13.7739 20.5599 13.8754 20.3062 14.1544C19.5705 14.8901 19.4944 15.8287 19.5959 16.5897C19.672 17.021 19.9257 18.7967 19.9257 18.9489C19.9764 19.4309 19.9003 19.8622 19.6213 20.4456C19.393 20.9276 18.6066 22.475 18.4544 22.8048C18.0739 23.5405 17.5919 24.0478 16.983 24.5298C16.704 24.7327 13.4823 27.0666 13.254 27.2188C12.9242 27.4471 12.6705 27.2695 12.6959 26.889C12.6959 26.6861 12.6959 26.5846 12.6959 26.4577C12.6705 26.0265 12.3408 25.7982 12.0363 25.8997C11.8334 25.9758 11.6558 26.0265 11.529 26.0519C11.2246 26.128 10.6411 25.9758 10.286 25.7475C9.80399 25.4684 9.2459 24.9611 8.96686 24.7074C8.43413 24.2 8.58634 23.3883 9.29664 23.1346C10.1338 22.8302 10.7172 22.3736 10.9455 21.714C11.1231 21.232 11.0724 20.4963 10.5143 19.8368C10.2606 19.5577 9.93083 19.5324 9.65178 19.8622C9.34737 20.268 8.71318 20.9783 8.25656 21.3335C7.31796 22.0945 6.75987 22.1199 6.02421 21.8155C5.54222 21.6633 5.33928 21.0544 5.7959 20.6739Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionFujiIcon',
})
</script>

<style scoped lang="scss">
.icon-circuit {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
