









































































import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { defineComponent, onMounted, ref, Ref, watch } from '@vue/composition-api'
import SnsFavoriteSection from '@/components/HomePage/HomePane/SnsFavoriteSection.vue'
import TeamCardParts from '@/components/common/card/TeamCardParts.vue'
import SnsPostLoaderParts from '@/components/HomePage/HomePane/parts/SnsPostLoaderParts.vue'
import StoreUtil from '@/store/StoreUtil'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import SnsPostItemParts from '@/components/HomePage/HomePane/parts/SnsPostItemParts.vue'
import HighlightsCommentTriggerParts from '@/components/HomePage/HomePane/parts/HighlightsCommentTriggerParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * HOME SNS Teamコンテンツセクション
 */
export default defineComponent({
  name: 'SnsTeamSection',
  components: {
    HighlightsCommentTriggerParts,
    SnsFavoriteSection,
    TeamCardParts,
    SnsPostItemParts,
    SnsPostLoaderParts,
  },
  setup() {
    const store = StoreUtil.useStore('HomePageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const errorImgSrc = ref('')
    /**
     * アプリ版のAndroidのみvue-virtual-scroller（DynamicScroller）を導入する
     */
    const isUseDynamicScroller = DeviceInfo.isAndroid()

    onMounted(() => {
      // 選択しているチームのSNS投稿を取得する
      if (store.snsPostDisplayTeam.value?.teamId) {
        store.fetchHomePageSnsFeed(store.snsPostDisplayTeam.value.teamId)
      } else {
        if (!mypagePageStore.favoriteTeam.value?.teamId) {
          return
        }
        /** ログイン直後はお気に入りチームのSNSを表示 */
        store.snsPostDisplayTeam.value = mypagePageStore.favoriteTeam.value
        store.fetchHomePageSnsFeed(mypagePageStore.favoriteTeam.value.teamId)
      }
    })

    /**
     * チームロゴ画像のURL
     * @param team チーム情報
     */
    const teamLogoUrl = (team?: TeamDocument) => {
      if (!team) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(team.teamLogo)
    }
    /**
     * チーム表示名を取得する
     * @param target チーム
     */
    const getTeamName = (target: TeamDocument) => {
      if (target.teamShortName) {
        return target.teamShortName
      }
      return target.teamName
    }

    /**
     * 選択したチームのSNS記事データ
     */
    const snsContentsData = ref([]) as Ref<SnsPostData[]>

    /**
     * SNSコンテンツローディング中フラグ
     */
    const snsLoading = ref<boolean>(false)

    /**
     * ハイライトのみ絞り込みフィルター
     */
    const highlightTrigger = ref<boolean>(false)

    /**
     * store.getSnsFeedDataListの変更を監視し、リアクティブにデータを作成する
     */
    watch(
      () => store.getSnsFeedDataList.value,
      (newData) => {
        snsContentsData.value = newData.map((obj) => ({
          ...obj,
          images: obj.images.filter((image) => image.src !== errorImgSrc.value),
        }))
      },
      {
        immediate: true,
        deep: true,
      },
    )

    /**
     * imgタグで読み込みエラーになったデータを削除して、データを再構築する
     * この処理によってdynamic-scrollerで再計算が行われる
     */
    const updateSnsContentsData = (src: string) => {
      errorImgSrc.value = src
      snsContentsData.value = snsContentsData.value.map((obj) => ({
        ...obj,
        images: obj.images.filter((image) => image.src !== src),
      }))
    }

    return {
      snsPostDisplayTeam: store.snsPostDisplayTeam,
      teamLogoUrl,
      getTeamName,
      snsContentsData,
      snsLoading,
      highlightTrigger,
      updateSnsContentsData,
      isUseDynamicScroller,
    }
  },
  methods: {
    /**
     * ハイライトコメントフィルター切替
     */
    changeHighlightsFilter() {
      // TODO: 絞り込み処理
      this.highlightTrigger = !this.highlightTrigger
    },
    selectSnsPostDisplayTeam() {
      this.$router.replace({ name: 'HomeSnsTeamSelectPage' })
    },
  },
})
