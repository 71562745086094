















import { computed, defineComponent } from '@vue/composition-api'
import SelectListSection from '@/components/common/SelectList/SelectListSection.vue'
import PlayerCardParts from '@/components/common/card/PlayerCardParts.vue'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

/**
 * マイページ: お気に入りチーム選択リスト セクション
 */
export default defineComponent({
  name: 'PlayerSelectListSection',
  components: {
    SelectListSection,
    PlayerCardParts,
  },
  props: {
    /**
     * 表示データ
     */
    listData: {
      type: Array,
      required: true,
    },
    /**
     * 選択済みフラグ
     */
    selectedID: {
      type: String,
      required: true,
      defalt: '',
    },
  },
  setup(props) {
    /**
     * 選択済みを監視
     */
    const selectedItemID = computed(() => props.selectedID)
    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }
    return {
      selectedItemID,
      playerPictureUrl,
    }
  },
  methods: {
    /**
     * 選択を通知
     * @param selectedItemID : string
     */
    changeSelected(selectedItemID: string): void {
      this.$emit('selectChange', selectedItemID)
    },
  },
})
