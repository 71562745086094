var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-highlights-section",
      class: { "edit-highlights-section--is-pc": _vm.isPc },
    },
    [
      _c("div", {
        staticClass: "edit-highlights-section__mask",
        class: { "edit-highlights-section__mask--active": true },
      }),
      _c(
        "div",
        { staticClass: "edit-highlights-section__inner" },
        [
          _c(
            "transition",
            { attrs: { name: "slide", appear: "" } },
            [
              _vm.highlightData
                ? _c("new-highlights-input-section", {
                    staticClass: "edit-highlights-section__create",
                    attrs: {
                      videoPlayerKey: _vm.videoPlayerKey,
                      screenOrientationType: _vm.screenOrientationType,
                      editType: _vm.editType,
                      highlightData: _vm.highlightData,
                      createHighlight: _vm.createHighlight,
                    },
                    on: {
                      close: _vm.handleCloseClicked,
                      onClickCommentCancel: _vm.handleCommentCancelClicked,
                      onSaveHighlight: _vm.saveHighlight,
                      showHighlightPreviewVideoPlayer:
                        _vm.showHighlightPreviewVideoPlayer,
                      hideHighlightPreviewVideoPlayer:
                        _vm.hideHighlightPreviewVideoPlayer,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }