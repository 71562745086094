



















import { computed, defineComponent, PropType } from '@vue/composition-api'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

/**
 * マイページ: 選択リスト アイテム パーツ
 */
export default defineComponent({
  name: 'SelectListItemParts',
  props: {
    /**
     * 表示データ
     */
    item: {
      type: Object as PropType<PlayerDocument>,
      required: true,
    },
    /**
     * 選択済みフラグ
     */
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    /**
     * 選択済みを監視
     */
    const isSelect = computed(() => props.selected)

    return {
      isSelect,
    }
  },
  methods: {
    /**
     * 選択を通知
     */
    selectChange(): void {
      this.$emit('select', this.item.id)
    },
  },
})
