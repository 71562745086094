var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calendar-item-section",
      class: { "calendar-item-section--is-pc": _vm.isPc },
    },
    [
      _c("div", { staticClass: "calendar-item-section__date-container" }, [
        _c("p", { staticClass: "calendar-item-section__date" }, [
          _vm._v(_vm._s(_vm.displayEventDate(_vm.calendarItem.eventDate))),
        ]),
        _c("p", { staticClass: "calendar-item-section__date-day" }, [
          _vm._v(
            " " +
              _vm._s(_vm.displayEventDayOfWeek(_vm.calendarItem.eventDate)) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "calendar-item-section__main" }, [
        _c("p", { staticClass: "calendar-item-section__name" }, [
          _vm.externalLinks
            ? _c(
                "a",
                {
                  staticClass: "calendar-item-section__name-link",
                  attrs: { href: _vm.externalLinks, target: "_blank" },
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.disPlayEventName(_vm.calendarItem.eventName)
                      ),
                    },
                  }),
                ]
              )
            : _c("span", {
                staticClass: "calendar-item-section__name-label",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.disPlayEventName(_vm.calendarItem.eventName)
                  ),
                },
              }),
        ]),
        _c("ul", { staticClass: "calendar-item-section__links" }, [
          _c("li", { staticClass: "calendar-item-section__links-item" }, [
            _vm.calendarItem.placeLink
              ? _c(
                  "a",
                  {
                    staticClass: "calendar-item-section__links-item-link",
                    attrs: {
                      href: _vm.calendarItem.placeLink,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.calendarItem.placeName))]
                )
              : _c("span", {
                  staticClass:
                    "calendar-item-section__links-item-link calendar-item-section__links-item-link--no-link",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.disPlayEventName(_vm.calendarItem.placeName)
                    ),
                  },
                }),
          ]),
          _c("li", { staticClass: "calendar-item-section__links-item" }, [
            _vm.calendarItem.placeLink
              ? _c(
                  "a",
                  {
                    staticClass: "calendar-item-section__links-item-link",
                    attrs: {
                      href: _vm.calendarItem.categoryLink,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.calendarItem.categoryName))]
                )
              : _c("span", {
                  staticClass:
                    "calendar-item-section__links-item-link calendar-item-section__links-item-link--no-link",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.disPlayEventName(_vm.calendarItem.categoryName)
                    ),
                  },
                }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }