import { computed, toRefs, watch } from '@vue/composition-api'
import { now, throttle } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import TelemetryDocument, {
  TelemetryDataType,
} from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'

/**
 * テレメトリー画面の機能を提供する。
 */
export default function useTelemetry() {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

  const {
    computeActualTimeForVideoPlaybackPosition,
    selectedPlayer,
    currentAngleInfo,
    targetRace,
    telemetryStore,
  } = raceVideoPageStore

  telemetryStore.lastTelemetryFetch = 0

  const {
    fetchTelemetry,
    clearTelemetries,
    previousShowedTelemetry,
    telemetryHashedByCreatedDate,
  } = raceVideoPageStore.telemetryStore

  const { videoStatus, preloadFetchingRange } = toRefs(raceVideoPageStore.raceVideoPageState)

  /**
   * 対象の日時のテレメトリーデータの取得を実施しているかどうかを判定する.
   * @param createdDate 作成日時
   */
  const isTelemetryFetched = (createdDate: number) =>
    // テレメトリーデータは正確に0.1秒毎にデータが登録されない場合があるため、前後のデータも確認する
    telemetryHashedByCreatedDate.value[createdDate] ||
    telemetryHashedByCreatedDate.value[createdDate - 200] ||
    telemetryHashedByCreatedDate.value[createdDate - 100] ||
    telemetryHashedByCreatedDate.value[createdDate + 100] ||
    telemetryHashedByCreatedDate.value[createdDate + 200]

  /**
   * 現在選択されている選手のテレメトリーデータを取得する。
   * @param from テレメトリーデータの取得開始位置
   * @param to テレメトリーデータの取得終了位置
   */
  const fetchPlayerTelemetry = async (from: number, to: number) => {
    if (targetRace.value && selectedPlayer.value) {
      await fetchTelemetry(targetRace.value, selectedPlayer.value, from, to)
    }
  }

  // テレメトリーデータのフェッチを行なっているかどうか
  let playerTelemetryFetchingNow = false
  // データを保持する最小期間。メモリに保持時ているデータがこの期間よりも短くなった場合に、データフェッチを行う
  const minimumDataRetentionPeriods = 2 * 1000

  watch(
    videoStatus,
    throttle(async () => {
      if (!targetRace.value) {
        return
      }

      // テレメトリーデータを更新する

      // 現在の動画の再生時間の実時間をテレメトリーデータ取得開始位置とする
      const from = computeActualTimeForVideoPlaybackPosition()

      if (
        !isTelemetryFetched(from + minimumDataRetentionPeriods) &&
        now() - telemetryStore.lastTelemetryFetch > 1000 &&
        !playerTelemetryFetchingNow
      ) {
        // テレメトリーデータ取得開始位置から、telemetryFetchingRange で指定された範囲までの範囲を取得する
        const to = from + preloadFetchingRange.value
        // 再生位置から2秒先のテレメトリーデータを保持していない場合、取得を開始
        // ただし、ライブ配信時など、まだ、先の時間帯にテレメトリーデータがそもそも存在しない場合もあるため、取得は1秒に一度に限定する
        telemetryStore.lastTelemetryFetch = now()
        playerTelemetryFetchingNow = true
        await fetchPlayerTelemetry(from, to).finally(() => {
          playerTelemetryFetchingNow = false
        })
      }
    }, 500),
    { deep: true },
  )

  /**
   * 現在の動画再生時間に対応するテレメトリーデータを返す算出プロパティ。
   */
  const getCurrentTelemetryData = computed((): TelemetryDataType => {
    const currentTime = videoStatus.value?.currentTime || 0
    // 対象のレースのライブ映像配信開始時間に、動画の再生時間を加算する。この計算で、動画の再生位置の実時間を取得する
    const actualTimeRounded = computeActualTimeForVideoPlaybackPosition()
    const currentTelemetryData = telemetryHashedByCreatedDate.value[actualTimeRounded]
    if (
      currentTelemetryData &&
      currentTelemetryData.carNo !== `#${selectedPlayer.value?.squadNumber}`
    ) {
      previousShowedTelemetry.telemetry = null
      previousShowedTelemetry.movieTime = currentTime
      return TelemetryDocument.EMPTY_TELEMETRY_DATA
    }
    if (currentTelemetryData) {
      // ステアリング補正フラグが指定されていれば設定する
      currentTelemetryData.steeringCorrection = !!currentAngleInfo.value?.steeringCorrection

      // 取得したテレメトリーデータを保持する。
      // この後の再生中、動画の再生時間に応じたテレメトリーデータを取得できなかった場合、保持しておいたテレメトリーデータを利用する。
      // これは、再生時間に対応するテレメトリーデータが一時的に存在しない場合に、
      // 画面上で、100Km -> 0Km -> 101Km のように、一瞬、値が0値になってしまうことを防ぐために行う。
      previousShowedTelemetry.telemetry = currentTelemetryData.telemetryData
      previousShowedTelemetry.movieTime = currentTime
    }
    // 直前のテレメトリーデータと現在の再生時間が、5秒以上離れている場合、利用しない
    if (Math.abs(currentTime - previousShowedTelemetry.movieTime) > 5) {
      previousShowedTelemetry.telemetry = null
      previousShowedTelemetry.movieTime = currentTime
    }
    return currentTelemetryData
      ? currentTelemetryData.telemetryData
      : previousShowedTelemetry.telemetry || TelemetryDocument.EMPTY_TELEMETRY_DATA
  })

  /**
   * テレメトリー画面のデータをクリアする。
   */
  const clearTelemetryData = () => {
    clearTelemetries()
  }

  return {
    getCurrentTelemetryData,
    clearTelemetryData,
  }
}
