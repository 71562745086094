import { computed } from '@vue/composition-api'
import { orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import AwardDataDocument, {
  AwardRankingCategoryType,
} from '@/store/stores/collectionModule/documents/award/AwardDataDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'

/**
 * AWARDデータを操作するための処理を取得する。
 */
export default function useAwardData() {
  // Collection modules
  const awardCollectionModule = CollectionModule.createStore(AwardDataDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * AWARDデータを取得する。
   */
  const fetchAwardData = async (seasonYear: number) => {
    const result = await searchDateRangeData({
      tableName: 'award-data',
      partitionKey: 'seasonYear',
      partitionKeyValue: String(seasonYear),
      sortKey: 'aggregationDate',
    })
    awardCollectionModule.data = result as Array<AwardDataDocument>
  }

  /**
   * 取得したAWARDデータ一覧
   */
  const awardData = computed(() => awardCollectionModule.data)

  /**
   * ランキングデータ種別毎に分類されたAWARDデータ一覧
   */
  const awardListByRankingDataType = computed(() => {
    const AwardMap: Record<AwardRankingCategoryType, AwardDataDocument | undefined> = {
      RECOMMEND_DRIVER: undefined,
      RECOMMEND_TEAM: undefined,
      PIT_CREW: undefined,
    }
    orderBy(awardData.value, 'aggregationDate').forEach((award) => {
      if (award.rankingDataType) {
        AwardMap[award.rankingDataType] = award
      }
    })
    return AwardMap
  })

  /**
   * 取得したAwardデータをクリアする
   */
  const clearAwardData = () => {
    awardCollectionModule.clearData()
  }

  return {
    fetchAwardData,
    awardListByRankingDataType,
    clearAwardData,
  }
}
