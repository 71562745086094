
























































import { defineComponent, PropType } from '@vue/composition-api'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * 無料会員、有料会員かを示す
 */
export type VideoLinkCardMembershipData = 'free' | 'paid'

/**
 * HOMEの動画カードのデータモデル
 */
export type VideoLinkCardData = {
  img: string
  title: string
  link: string
  date: string
  membership: VideoLinkCardMembershipData
}

/**
 * HOME Youtubeリストセクション
 */
export default defineComponent({
  name: 'VideoLinkCardParts',
  props: {
    videoLink: {
      type: Object as PropType<VideoLinkCardData>,
      required: true,
    },
    membership: {
      type: String as PropType<VideoLinkCardMembershipData>,
      required: true,
    },
  },
  components: {},
  setup(props) {
    // サムネイル画像のURLをCloudFrontの署名URLに変換する
    const thumbnailPath = CloudFrontUtil.getSignedUrl(props.videoLink.img)

    // 現状、PREMIUM の表記は無し
    // const premiumLabelEnabled = props.videoLink.membership === 'paid' && props.membership === 'paid'
    const premiumLabelEnabled = false
    return {
      thumbnailPath,
      premiumLabelEnabled,
    }
  },
  methods: {
    /**
     * モーダル表示 通知
     */
    emitClick() {
      this.$emit('click')
    },
  },
})
