const todoCommentRegex = /_TODO_\{.*?\}/g

/**
 * 引数に指定したObjectのプロパティから_TODO_の文字列を削除したObjectを返す
 * @param obj
 * @return {Object} _TODO_が削除された文字列のプロパティを持つObject
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function removeTodo<T extends Record<string, any>>(obj: T): T {
  const res: Record<string, any> = {}
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      if (obj[key].match(todoCommentRegex)) {
        res[key] = obj[key].replace(obj[key].match(todoCommentRegex)[0], '')
      } else {
        res[key] = obj[key].replace(/_TODO_/gi, '')
      }
    } else if (typeof obj[key] === 'object') {
      res[key] = removeTodo(obj[key])
    }
  })
  return res as T
}
