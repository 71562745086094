import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import BillingInfoDocument, {
  BillingPlanType,
} from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'
import ContractInfoDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoDocument'
import { PlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import ContractInfoPlanPriceDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanPriceDocument'
import useContractInfo from '@/store/hook/useContractInfo'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useContractPlan from '@/store/hook/useContractPlan'
import useContractInfoPlanPrice from '@/store/hook/useContractInfoPlanPrice'
import usePeriodOfUse from '@/components/MypageContractPage/hook/usePeriodOfUse'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

export type MyContractDataType = {
  currentPlanGroupId: PlanGroupType | undefined
  currentPlan: string
  nextPlan: string
  nextPlanGroupId: string
  nextPlanStartDate: string
  registrationDate: string
  nextPaymentDate: string
  nextPaymentPrice: string
  nextPaymentPeriod: string
  paymentMethod: string
  creditCardData: {
    cardLast4Digits: string
    cardExpiration: string
  }
}

/**
 * マイページご契約内容確認画面の機能を提供する。
 */
export default function useMypageContract() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { ownOrganization, effectiveOrgContract, effectiveContractPlan } = contractInfoStore
  const { fetchContractInfo, fetchTargetContractInfo, ownContractInfo } = useContractInfo()
  const { fetchContractInfoPlans, futureContactInfoPlan } = useContractInfoPlan()
  const { fetchContractPlans, contractPlans, getTargetContractPlan } = useContractPlan()
  const { fetchAnnualPlanPrice, contractInfoPlanPrice } = useContractInfoPlanPrice()
  const { fetchProvisionalBillingInfo, ownBillingInfo } = useBillingInfo()
  const { getDisplayDate, getDisplayDateTime } = useDisplayDependingOnLang()
  const { getPeriodOfUseForTargetBilling } = usePeriodOfUse()

  /** 登録日 */
  const displayRegistrationDate = (organization: OrganizationDocument) =>
    getDisplayDate(organization._createdDate)

  /**
   * 次の支払いの利用分の期間を表示（以下のケース）
   * - 月額プラン
   * - 年額プランで請求情報が存在する
   *   - 月額プラン→年額プランに切り替えのケースで年額プランの請求日 < 月額プランの請求日
   *   - クーポン利用中で年額プランに切り替わる予定がある
   */
  const getDisplayNextPaymentPeriod = (plans: Array<BillingPlanType> | undefined | null) => {
    // priceが1円以上になっているプラン情報を取得する
    const targetPlan = plans?.find((v) => !!v?.price && v.price > 0)
    if (!targetPlan?.contractPlanId) {
      return ''
    }
    const contractPlan = getTargetContractPlan(targetPlan.contractPlanId)

    return getPeriodOfUseForTargetBilling(
      contractPlan?.planGroupId,
      targetPlan.startDate,
      targetPlan.endDate,
    ) as string
  }

  /** 次の支払い情報 */
  const displayNextPaymentData = (billingInfo: BillingInfoDocument | undefined) => {
    /**
     * 以下の場合は次の支払いを表示しない
     * 1. 請求情報を取得できない
     * 2. クーポン適用期間中の解約など、課金が発生する前に解約している
     *  ※年額プランを契約する前に解約した場合、'paymentStatus: Cancel' のデータが存在する
     */
    if (!billingInfo || billingInfo.paymentStatus === 'Cancel') {
      return {
        nextPaymentDate: '',
        nextPaymentDateNum: 0,
        nextPaymentPrice: '',
        NextPaymentPeriod: '',
        paymentMethod: '',
      }
    }

    // 次の支払い金額
    const nextPaymentPrice = billingInfo.total ? billingInfo.total.toLocaleString() : ''
    // SFgoはクレジット決済のみだが、請求情報がない場合は表示しないためチェックを入れる
    const isCreditCard = billingInfo.paymentMethod === 'CREDIT_CARD'

    return {
      nextPaymentDate: getDisplayDate(billingInfo.billingDate),
      nextPaymentDateNum: billingInfo.billingDate,
      nextPaymentPrice: nextPaymentPrice ? `¥${nextPaymentPrice}` : '',
      NextPaymentPeriod: getDisplayNextPaymentPeriod(billingInfo?.plans),
      paymentMethod: isCreditCard
        ? I18n.tc('MypagePage.MypageContract.detailsPage.paymentMethodCard')
        : '',
    }
  }

  /**
   * 次の支払い情報（年額プラン向け）
   * 年額プランの場合は、次の支払い情報を契約プラン料金APIで取得する
   * @param contractPlanIdForAnnual 年額プランのcontractPlanId
   */
  const displayNextPaymentDataForAnnual = (contractPlanIdForAnnual: string | null) => {
    const annualPlan = contractInfoPlanPrice.value.plans?.find(
      (plan) => plan.contractPlanId === contractPlanIdForAnnual,
    )
    const charge = contractInfoPlanPrice.value?.charge

    return {
      nextPaymentDate: getDisplayDate(annualPlan?.billingDate),
      nextPaymentPrice: charge?.total ? `¥${charge?.total.toLocaleString()}` : '',
      NextPaymentPeriod: `${getDisplayDate(annualPlan?.startDate)}〜${getDisplayDate(
        annualPlan?.endDate,
      )}`,
      // SFgoの場合、有料プランの決済方法はクレジット決済のみのため、「クレジットカード払い」を表示
      paymentMethod: I18n.tc('MypagePage.MypageContract.detailsPage.paymentMethodCard'),
    }
  }

  /** クレジットカード番号下4桁 */
  const displayCreditCardData = (contractInfo: ContractInfoDocument) => {
    const cardLast4Digits = contractInfo.payment?.paymentService?._cardLast4Digits
    const cardExpirationYear = contractInfo.payment?.paymentService?._cardExpirationYear
    const cardExpirationMonth = contractInfo.payment?.paymentService?._cardExpirationMonth
    return {
      cardLast4Digits: cardLast4Digits ? `xxxx xxxx xxxx ${cardLast4Digits}` : '',
      cardExpiration:
        cardExpirationYear && cardExpirationMonth
          ? `${I18n.tc(
              'MypagePage.MypageContract.detailsPage.period',
            )}: ${cardExpirationMonth}/${cardExpirationYear}`
          : '',
    }
  }

  /**
   * 年額プランの基本プランの料金を取得する処理を呼ぶ
   */
  const requestFetchAnnualPlanPrice = async (contractInfoId: string) => {
    if (!(effectiveOrgContract?.startDate && effectiveOrgContract?.endDate)) {
      // 年額プランの場合、startDate、endDateがセットされているため、startDateまたはendDateがnullの場合はエラーにする
      return null
    }
    const requestData = new ContractInfoPlanPriceDocument({
      // 次年度の契約期間をセットする
      startDate: effectiveOrgContract.endDate + 1,
      endDate: dayjs(effectiveOrgContract.endDate).add(1, 'y').valueOf(),
      newPlans: [
        {
          originContractPlanId: effectiveOrgContract.contractPlanId,
        },
      ],
    })
    return fetchAnnualPlanPrice(contractInfoId, requestData)
  }

  /**
   * 次の支払い情報を返却する
   */
  const getNextPaymentInfo = async (contractInfoId: string) => {
    /**
     * 現在契約中のプラン
     */
    const currentPlan = computed(() =>
      contractPlans.value.find((plan) => plan.planGroupId === effectiveContractPlan?.planGroupId),
    )

    /**
     * 将来のプラン
     */
    const futurePlan = computed(() =>
      futureContactInfoPlan?.value?.contractPlanId
        ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
        : null,
    )

    // 請求情報から次の支払い情報を取得
    const nextPaymentData = displayNextPaymentData(ownBillingInfo.value)

    if (currentPlan.value?.planGroupId === 'annualPlan') {
      if (!futurePlan.value && !nextPaymentData.nextPaymentPrice) {
        /**
         * 将来のプランがない かつ 次の支払い予定がない(支払い予定金額が0円の場合も含む)場合
         */

        // 年額プランの料金はBillingInfoから次の支払い情報を取得できないため、年額プランの基本プランの料金を取得し、その情報を次の支払い情報として表示する
        const annualPlanPriceResult = await requestFetchAnnualPlanPrice(contractInfoId)
        if (!annualPlanPriceResult) {
          return null
        }
        return displayNextPaymentDataForAnnual(currentPlan.value?.contractPlanId)
      }
    }

    /**
     * 上記以外の場合、請求情報から取得した次の支払い情報を返却する
     * 年額プランであっても以下の場合は年額プランを更新しないため、請求情報から取得した内容を返却している
     * - 年額プラン契約中 かつ 将来のプランが存在する（=年額プランから何かしらのプランに切り替え手続きを行った）
     */
    return nextPaymentData
  }

  /**
   * マイページに表示する契約内容情報を取得
   */
  const fetchMyContractData = async () => {
    /** 現在利用している契約情報、請求情報とプラン一覧を取得 */
    await Promise.all([fetchContractInfo(), fetchProvisionalBillingInfo(), fetchContractPlans()])
    // クレジットカード番号下4桁を取得するために、契約情報1件取得を行う
    await fetchTargetContractInfo()

    const { contractInfoId } = ownContractInfo.value

    if (!ownOrganization.value || !contractInfoId) {
      return null
    }

    /* 契約情報契約プラン情報を取得 */
    await fetchContractInfoPlans(contractInfoId)

    /* 次の支払い情報を取得 */
    const nextPaymentInfo = await getNextPaymentInfo(contractInfoId)

    if (!nextPaymentInfo) {
      return null
    }

    /**
     * 将来のプラン
     */
    const futurePlan = computed(() =>
      futureContactInfoPlan?.value?.contractPlanId
        ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
        : null,
    )

    return {
      currentPlanGroupId: effectiveContractPlan?.planGroupId,
      currentPlan: effectiveContractPlan?.name[I18n.locale] ?? '',
      nextPlan: futurePlan.value?.name[I18n.locale] ?? '',
      nextPlanGroupId: futurePlan.value?.planGroupId ?? '',
      nextPlanStartDate: getDisplayDateTime(futureContactInfoPlan.value?.startDate),
      registrationDate: displayRegistrationDate(ownOrganization.value),
      nextPaymentDate: nextPaymentInfo.nextPaymentDate,
      nextPaymentPrice: nextPaymentInfo.nextPaymentPrice,
      nextPaymentPeriod: nextPaymentInfo.NextPaymentPeriod,
      paymentMethod: nextPaymentInfo.paymentMethod,
      creditCardData: displayCreditCardData(ownContractInfo.value),
    }
  }

  return {
    fetchMyContractData,
  }
}
