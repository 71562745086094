var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h1",
    {
      staticClass: "page-title-section",
      class: [
        _vm.colorType ? "page-title-section--" + _vm.colorType : null,
        { "page-title-section--is-pc": _vm.isPc },
      ],
    },
    [_c("span", [_vm._v(_vm._s(_vm.pageTitle))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }