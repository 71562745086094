var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-name-ruby-parts" },
    [
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.ruby"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c("ValidationProvider", {
        attrs: {
          slim: "",
          name: "ruby",
          rules: "required|max:32",
          vid: "familyNameKana",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("atom-input-text", {
                  staticClass: "fieldset-name-ruby-parts__input",
                  attrs: {
                    placeholder: _vm.$t("formParts.placeholder.rubyFamily"),
                    error: errors.length > 0,
                  },
                  on: { enter: _vm.emitEnter },
                  model: {
                    value: _vm.familyNameKana,
                    callback: function ($$v) {
                      _vm.familyNameKana = $$v
                    },
                    expression: "familyNameKana",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("ValidationProvider", {
        attrs: {
          slim: "",
          name: "ruby",
          rules: "required|max:32",
          vid: "firstNameKana",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("atom-input-text", {
                  staticClass: "fieldset-name-ruby-parts__input",
                  attrs: {
                    placeholder: _vm.$t("formParts.placeholder.rubyFirst"),
                    error: errors.length > 0,
                  },
                  on: { enter: _vm.emitEnter },
                  model: {
                    value: _vm.firstNameKana,
                    callback: function ($$v) {
                      _vm.firstNameKana = $$v
                    },
                    expression: "firstNameKana",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showError,
          message:
            _vm.errorMessage !== ""
              ? _vm.errorMessage
              : _vm.$t("formParts.errorMessage.ruby"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }