var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "live-icon-parts",
      class: { "live-icon-parts--live": _vm.isLiveEnabled },
      on: { click: _vm.onLiveClicked },
    },
    [_vm._v(" LIVE ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }