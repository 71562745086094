var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-change-fieldset-parts" },
    [
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.oldPassword"))),
        ]),
      ]),
      _c("password-with-toggle-button", {
        attrs: {
          placeholder: _vm.$t("formParts.placeholder.password"),
          error: _vm.showOldPasswordError,
        },
        on: { enter: _vm.emitEnter },
        model: {
          value: _vm.inputPassword.old,
          callback: function ($$v) {
            _vm.$set(_vm.inputPassword, "old", $$v)
          },
          expression: "inputPassword.old",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showOldPasswordError,
          message: _vm.oldPasswordErrorMessage,
        },
      }),
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.newPassword"))),
        ]),
      ]),
      _c("password-with-toggle-button", {
        attrs: {
          placeholder: _vm.$t("formParts.placeholder.password"),
          error: _vm.showNewPasswordError,
        },
        on: { enter: _vm.emitEnter },
        model: {
          value: _vm.inputPassword.new,
          callback: function ($$v) {
            _vm.$set(_vm.inputPassword, "new", $$v)
          },
          expression: "inputPassword.new",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showNewPasswordError,
          message: _vm.newPasswordErrorMessage,
        },
      }),
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$t("formParts.label.newPasswordReEnter"))),
        ]),
      ]),
      _c("password-with-toggle-button", {
        attrs: {
          placeholder: _vm.$t("formParts.placeholder.password"),
          error: _vm.showNewPasswordReEnterError,
        },
        on: { enter: _vm.emitEnter },
        model: {
          value: _vm.inputPassword.reEnter,
          callback: function ($$v) {
            _vm.$set(_vm.inputPassword, "reEnter", $$v)
          },
          expression: "inputPassword.reEnter",
        },
      }),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.showNewPasswordReEnterError,
          message: _vm.newPasswordReEnterErrorMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }