




























































































































import { computed, defineComponent } from '@vue/composition-api'
import SwitchIcon from '@/components/RaceVideoPage/RaceVideoPane/parts/icons/SwitchIcon.vue'

/**
 * レース動画再生画面 動画プレーヤー 表示アングルスウィッチャー コンポーネント
 */
export default defineComponent({
  name: 'ViewAngleToggleSwitcherParts',
  components: { SwitchIcon },
  props: {
    /**
     * 表示アングル
     * data: 'race' or 'player'
     */
    viewAngle: {
      type: String,
      default: 'race',
    },
    /**
     * 中継映像とオンボード映像の切り替えボタンを押下可能にするかどうか
     */
    angleSwitchEnabled: {
      type: Boolean,
      default: true,
    },
    /**
     * MAP表示フラグ
     */
    mapViewEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
  },
  setup(props) {
    const viewAngleStatus = computed(() => props.viewAngle)

    return {
      viewAngleStatus,
    }
  },
  methods: {
    /**
     * 表示アングル変更
     * @param angle: 'race' or 'player' or 'gps'
     */
    toggleViewAngle(angle: 'race' | 'player' | 'gps'): void {
      if (angle !== 'gps') {
        this.$emit('viwAngleChange', this.viewAngle === 'race' ? 'player' : 'race')
      } else {
        this.$emit('viewMapMode')
      }
    },
  },
})
