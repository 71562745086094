import { render, staticRenderFns } from "./SignupCompleteSection.vue?vue&type=template&id=8e15a934&scoped=true"
import script from "./SignupCompleteSection.vue?vue&type=script&lang=ts"
export * from "./SignupCompleteSection.vue?vue&type=script&lang=ts"
import style0 from "./SignupCompleteSection.vue?vue&type=style&index=0&id=8e15a934&lang=scss&scoped=true"
import style1 from "./SignupCompleteSection.vue?vue&type=style&index=1&id=8e15a934&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e15a934",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8e15a934')) {
      api.createRecord('8e15a934', component.options)
    } else {
      api.reload('8e15a934', component.options)
    }
    module.hot.accept("./SignupCompleteSection.vue?vue&type=template&id=8e15a934&scoped=true", function () {
      api.rerender('8e15a934', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SignupPage/SignupCompletePane/SignupCompleteSection.vue"
export default component.exports