var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "12",
        height: "13",
        viewBox: "0 0 12 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.64645 6.3608C9.74021 6.26703 9.86739 6.21436 10 6.21436C10.1326 6.21436 10.2598 6.26703 10.3536 6.3608C10.4473 6.45457 10.5 6.58175 10.5 6.71436V9.71436C10.5 10.1122 10.342 10.4937 10.0607 10.775C9.77936 11.0563 9.39782 11.2144 9 11.2144H3C2.60218 11.2144 2.22064 11.0563 1.93934 10.775C1.65804 10.4937 1.5 10.1122 1.5 9.71436V3.71436C1.5 3.31653 1.65804 2.935 1.93934 2.6537C2.22064 2.37239 2.60218 2.21436 3 2.21436H6C6.13261 2.21436 6.25979 2.26703 6.35355 2.3608C6.44732 2.45457 6.5 2.58175 6.5 2.71436C6.5 2.84696 6.44732 2.97414 6.35355 3.06791C6.25979 3.16168 6.13261 3.21436 6 3.21436H3C2.86739 3.21436 2.74021 3.26703 2.64645 3.3608C2.55268 3.45457 2.5 3.58175 2.5 3.71436V9.71436C2.5 9.84696 2.55268 9.97414 2.64645 10.0679C2.74021 10.1617 2.86739 10.2144 3 10.2144H9C9.13261 10.2144 9.25979 10.1617 9.35355 10.0679C9.44732 9.97414 9.5 9.84696 9.5 9.71436V6.71436C9.5 6.58175 9.55268 6.45457 9.64645 6.3608Z",
          fill: "currentColor",
          "fill-opacity": "0.7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.79 3.21436H8C7.86739 3.21436 7.74021 3.16168 7.64645 3.06791C7.55268 2.97414 7.5 2.84696 7.5 2.71436C7.5 2.58175 7.55268 2.45457 7.64645 2.3608C7.74021 2.26703 7.86739 2.21436 8 2.21436H10C10.1326 2.21436 10.2598 2.26703 10.3536 2.3608C10.4473 2.45457 10.5 2.58175 10.5 2.71436V4.71436C10.5 4.84696 10.4473 4.97414 10.3536 5.06791C10.2598 5.16168 10.1326 5.21436 10 5.21436C9.86739 5.21436 9.74021 5.16168 9.64645 5.06791C9.55268 4.97414 9.5 4.84696 9.5 4.71436V3.92436L6.355 7.06436C6.30852 7.11122 6.25322 7.14842 6.19229 7.1738C6.13136 7.19919 6.06601 7.21225 6 7.21225C5.93399 7.21225 5.86864 7.19919 5.80771 7.1738C5.74678 7.14842 5.69148 7.11122 5.645 7.06436C5.59814 7.01787 5.56094 6.96257 5.53555 6.90164C5.51017 6.84071 5.4971 6.77536 5.4971 6.70936C5.4971 6.64335 5.51017 6.578 5.53555 6.51707C5.56094 6.45614 5.59814 6.40084 5.645 6.35436L8.79 3.21436Z",
          fill: "currentColor",
          "fill-opacity": "0.7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }