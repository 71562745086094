var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "getBarWidth", staticClass: "highlight-preview-seek-bar-parts" },
    [
      _c("div", {
        ref: "getTimeWidth",
        staticClass: "highlight-preview-seek-bar-parts__current-time",
        class: {
          "highlight-preview-seek-bar-parts__current-time--active":
            _vm.currentTimeVisible,
        },
        style: {
          left: _vm.getCurrentTimeLeftPosition(_vm.currentTimeForSeekbar),
        },
        domProps: { innerHTML: _vm._s(_vm.currentTime) },
      }),
      _c("progress", {
        staticClass: "highlight-preview-seek-bar-parts__progress-bar",
        attrs: { max: _vm.movieLength },
        domProps: { value: _vm.currentTimeForSeekbar },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.seekBarValue,
            expression: "seekBarValue",
            modifiers: { number: true },
          },
        ],
        ref: "seekBarRange",
        staticClass: "highlight-preview-seek-bar-parts__range",
        attrs: { type: "range", min: "0", max: _vm.movieLength },
        domProps: { value: _vm.seekBarValue },
        on: {
          touchstart: _vm.handleTouchStartRange,
          touchend: _vm.handleTouchEndRange,
          touchmove: _vm.handleTouchMoveRange,
          touchcancel: _vm.handleTouchCancelRange,
          mousedown: _vm.handleTouchStartRange,
          mouseup: _vm.handleTouchEndRange,
          __r: function ($event) {
            _vm.seekBarValue = _vm._n($event.target.value)
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }