









































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { PlayerLiveTimingDataType } from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'

/**
 * レース動画再生画面: GPS詳細 ランキング プレイヤーパーツ コンポーネント
 */
export default defineComponent({
  name: 'GpsRankingPlayerParts',
  props: {
    /**
     * 選択中選手ID
     */
    selectedID: {
      type: String,
      default: '',
    },
    /**
     * 選手のライブタイミング情報
     */
    player: {
      type: Object as PropType<PlayerLiveTimingDataType>,
      required: true,
    },
    /**
     * ランキング
     */
    rank: {
      type: String,
      required: true,
    },
    /**
     * 選手画像
     */
    img: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const ranking = computed(() => props.rank)
    const selectedPlayerID = computed(() => props.selectedID)

    /**
     * 選手の顔写真ファイルのパス
     */
    const imgPath = computed(() => props.img || './assets/img/common/card/icon_player__null.svg')

    /**
     * ランキングフォントサイズ調整
     */
    const rankingFontSizeSmall = computed(() => ranking.value.length > 1)

    return {
      selectedPlayerID,
      ranking,
      rankingFontSizeSmall,
      imgPath,
    }
  },
})
