






















import { defineComponent } from '@vue/composition-api'
import PlayerCardParts from '@/components/common/card/PlayerCardParts.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'

/**
 * マイページ: トップ お気に入り選手 セクション
 * todo: 選手データ受渡
 */
export default defineComponent({
  name: 'MypageFavoritePlayerLinkSection',
  components: {
    PlayerCardParts,
    AtomRouterButton,
  },
  props: {
    /**
     * お気に入り選手名
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * お気に入り選手画像
     */
    img: {
      type: String,
    },
    /**
     * 選択変更有効化
     */
    enabledSelect: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
})
