var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cancel-paid-plan-edit-pane" },
    [
      _vm.currentPlan
        ? _c("current-plan-card-section", {
            attrs: {
              sectionTitle: _vm.$tc("MypagePage.plan.currentPlan"),
              plan: _vm.currentPlan,
              contractTerm: _vm.contractTerm,
              isConfirmed: true,
            },
          })
        : _vm._e(),
      _c("choose-free-plan-section", {
        attrs: {
          freePlan: _vm.contractFreePlan,
          currentPlan: _vm.currentPlan,
          futurePlan: _vm.futurePlan,
          canCancelPaidPlan: _vm.canCancelPaidPlan,
          contractTerm: _vm.contractTerm,
          scheduledContractTerm: _vm.scheduledContractTerm,
          selectedPlanGroupId: _vm.selectedPlanGroupId,
          procedureScreen: "cancelPaidPlan",
        },
      }),
      _c("unusable-feature-section"),
      _c("comparison-plan-section", {
        staticClass: "cancel-paid-plan-edit-pane__comparison",
        attrs: {
          "section-title": _vm.$tc("CancelPaidPlan.EditPage.comparisonTitle"),
        },
      }),
      _c(
        "action-buttons-section",
        {
          staticClass: "cancel-paid-plan-edit-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".cancel-paid-plan-edit-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.confirm"),
              isDisabled: _vm.disabledConfirmBtn,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }