














































import { computed, defineComponent, PropType } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import ChooseOtherPlanSection from '@/components/pc/MyPage/PaidPlanChange/PaidPlanChangeEditPane/ChooseOtherPlanSection.vue'
import ComparisonPlanSection from '@/components/SignupPage/common/ComparisonPlanSection.vue'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import StoreUtil from '@/store/StoreUtil'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import useContractTerm from '@/components/hook/useContractTerm'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

/**
 * プラン変更(有料=>有料) ペイン
 */
export default defineComponent({
  name: 'PaidPlanChangeEditPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    CurrentPlanCardSection,
    ChooseOtherPlanSection,
    ComparisonPlanSection,
  },
  props: {
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<PaidPlanGroupType>,
      required: true,
    },
    /**
     * クーポン情報
     */
    coupon: {
      type: Object as PropType<CouponDocument>,
      default: undefined,
    },
  },
  setup(props, context) {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { effectiveContractPlan, effectiveOrgContract, contractPlans } = contractInfoStore
    const { contractPaidPlans, futureContactInfoPlan, getTargetContractPlan } = mypagePageStore
    const { getCurrentContractTerm, getScheduledContractTerm } = useContractTerm()

    /**
     * 確認ボタン非活性化チェック
     */
    const disabledConfirmBtn = computed(() => props.selectedPlanGroupId === '')

    /**
     * 現在契約中のプラン
     */
    const currentPlan = computed(() =>
      contractPlans.value.find((plan) => plan.planGroupId === effectiveContractPlan?.planGroupId),
    )

    /**
     * 将来のプラン
     */
    const futurePlan = computed(() =>
      futureContactInfoPlan?.value?.contractPlanId
        ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
        : null,
    )

    /**
     * 変更可能なプラン
     */
    const otherPlanList = computed(() =>
      contractPaidPlans.value.filter(
        (plan) => plan.planGroupId !== effectiveContractPlan?.planGroupId,
      ),
    )

    /**
     * 契約中のプランの契約期間
     */
    const contractTerm = computed(() =>
      getCurrentContractTerm(
        effectiveContractPlan as ContractPlanDocument,
        effectiveOrgContract as ContractType,
      ),
    )

    /**
     * 契約予定のプランの契約期間
     */
    const scheduledContractTerm = computed(() =>
      futurePlan.value?.planGroupId
        ? getScheduledContractTerm(
            effectiveContractPlan as ContractPlanDocument,
            effectiveOrgContract as ContractType,
            futurePlan.value.planGroupId,
          )
        : null,
    )

    /**
     * プラン選択
     * @param planGroupId {PaidPlanGroupType}
     */
    const handlerPlanSelect = (planGroupId: PaidPlanGroupType) => {
      context.emit('handlerPlanSelect', planGroupId)
    }

    return {
      disabledConfirmBtn,
      currentPlan,
      futurePlan,
      contractTerm,
      scheduledContractTerm,
      otherPlanList,
      handlerPlanSelect,
    }
  },
  methods: {
    /**
     * サブミット処理
     */
    handlerSubmit() {
      this.$emit('handlerSubmit')
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
