
























import { defineComponent } from '@vue/composition-api'
import FieldsetPasswordParts from '@/components/common/form/FieldsetPasswordParts.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

interface NewPasswordData {
  new: string
  reEnter: string
}

type DataType = {
  newPassword: NewPasswordData
  showNewPasswordError: boolean
  showNewPasswordReEnterError: boolean
  newPasswordErrorMessage: string
  newPasswordReEnterErrorMessage: string
}

/**
 * パスワードリセット 新パスワード入力 セクション。
 */
export default defineComponent({
  name: 'PasswordResetNewPasswordSection',
  components: {
    FieldsetPasswordParts,
    FormErrorMessageParts,
  },
  data(): DataType {
    return {
      newPassword: {
        new: '', // 新しいパスワード
        reEnter: '', // 新しいパスワード(再入力)
      },
      newPasswordErrorMessage: '', // 新しいパスワード エラーメッセージ
      newPasswordReEnterErrorMessage: '', // 新しいパスワード(確認用) エラーメッセージ
      showNewPasswordError: false, // 新しいパスワード エラー表示フラグ
      showNewPasswordReEnterError: false, // 新しいパスワード(確認用) エラー表示フラグ
    }
  },
  watch: {
    newPassword: {
      /**
       * パスワード未入力チェック
       */
      handler(val) {
        let rt = true
        if (val.new !== '' && val.reEnter !== '' && val.new === val.reEnter) {
          rt = false
        }
        this.$emit('buttonDisabled', rt)
      },
      deep: true,
    },
  },
  methods: {
    passwordReset() {
      this.newPasswordErrorMessage = ''
      this.newPasswordReEnterErrorMessage = ''
      this.showNewPasswordError = false
      this.showNewPasswordReEnterError = false
      /**
       * 新しいパスワードと新しいパスワード（再入力）が一致しているかチェック
       */
      if (this.newPassword.new !== this.newPassword.reEnter) {
        this.newPasswordReEnterErrorMessage = this.$t(
          'PasswordResetPage.errors.errorMessage.reEnterPassword',
        ) as string
        this.showNewPasswordReEnterError = true
      }
      /**
       * 新しいパスワードのバリデーション チェック
       */
      const regex = new RegExp(/^[a-zA-Z0-9-_.@!#$%&'*+/=?^`{|}~]{8,32}$/)
      if (!regex.test(this.newPassword.new)) {
        this.newPasswordErrorMessage = this.$t(
          'PasswordResetPage.errors.errorMessage.passwordFormat',
        ) as string
        this.showNewPasswordError = true
      }
      /**
       * パスワードリセット
       */
      if (!this.showNewPasswordError && !this.showNewPasswordReEnterError) {
        this.$emit('reset', this.newPassword.new)
      }
    },
  },
})
