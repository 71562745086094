
















































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from '@vue/composition-api'
import MissionAccordionIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionAccordionIcon.vue'
import MissionCompleteIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCompleteIcon.vue'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'

/**
 * ミッション画面 ミッション達成状況セクション
 */
export default defineComponent({
  name: 'MissionCardSection',
  components: {
    MissionCompleteIcon,
    MissionAccordionIcon,
  },
  props: {
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      isMissionNameWide: false,
    })
    const missionName = ref<HTMLElement | null>(null)
    const manageMissionNameWidth = () => {
      const missionNameWidth: number = missionName.value?.getBoundingClientRect().width || 0
      if (missionNameWidth > 300) {
        state.isMissionNameWide = true
      }
    }
    /**
     * フッターを表示するかどうか
     */
    const displayFooter = computed(() => {
      if (props.mission.missionGroupCode === 'CHECKED_IN_BONUS') {
        return !!(
          props.mission.achievementMethod &&
          props.mission.checkPointList &&
          props.mission.checkPointList.length > 0
        )
      }
      return !!props.mission.achievementMethod
    })
    onMounted(() => {
      manageMissionNameWidth()
    })
    watch(
      () => props.mission,
      () => {
        manageMissionNameWidth()
      },
    )
    return {
      state,
      missionName,
      displayFooter,
    }
  },
})
