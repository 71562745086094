













































import { computed, defineComponent } from '@vue/composition-api'
import RacePointRadioCardParts from '@/components/RaceListPage/RaceListPane/parts/RacePointRadioCardParts.vue'
import RacePointVideoCardParts from '@/components/RaceListPage/RaceListPane/parts/RacePointVideoCardParts.vue'
import RankingCardParts from '@/components/RaceListPage/RaceListPane/parts/RankingCardParts.vue'
import StoreUtil from '@/store/StoreUtil'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'

/**
 * レース一覧画面: Race Information セクション
 */
export default defineComponent({
  name: 'RaceInformationSection',
  components: {
    RacePointVideoCardParts,
    RacePointRadioCardParts,
    RankingCardParts,
  },
  props: {
    /**
     * Information 部開閉フラグ
     */
    showInformation: {
      type: Boolean,
      default: true,
    },
  },
  setup: () => {
    const store = StoreUtil.useStore('TopPageStore')

    /**
     * Race Rankのデータを取得する
     */
    const rankingsData = computed(() => store.getRankingCardData.value)
    /**
     * Race Point Videoのデータを取得する
     */
    const racePointVideoData = computed(() => store.racePointVideoData.value)
    /**
     * Race Point Radioのデータを取得する
     */
    const racePointRadioData = computed(() => store.racePointRadioData.value)

    return {
      rankingsData,
      racePointVideoData,
      racePointRadioData,
    }
  },
  methods: {
    /**
     * 無線交信データを再生する。
     * @param radioData 無線交信データ
     */
    playRadioAudio(radioData: RadioDataDocument) {
      this.$emit('radioPlay', radioData)
    },
  },
})
