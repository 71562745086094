var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "round-details-header-parts" }, [
    _c("img", {
      staticClass: "round-details-header-parts-bg",
      attrs: { src: _vm.raceData.imgPath, alt: "" },
    }),
    _c("div", { staticClass: "round-details-header-parts-data" }, [
      _c("p", {
        staticClass: "round-details-header-parts-name",
        domProps: { innerHTML: _vm._s(_vm.raceData.courseName) },
      }),
      _c("p", { staticClass: "round-details-header-parts-round" }, [
        _c("span", { staticClass: "round-details-header-parts-round__label" }, [
          _vm._v("RD."),
        ]),
        _c("span", {
          staticClass: "round-details-header-parts-round__num",
          domProps: { textContent: _vm._s(_vm.raceData.round) },
        }),
      ]),
    ]),
    _c("figure", { staticClass: "round-details-header-parts-course" }, [
      _c("img", {
        attrs: {
          src: _vm.raceData.courseImgPath,
          alt: _vm.raceData.courseName,
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }