var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "player-card-parts", on: { click: _vm.emitClick } },
    [
      _c("figure", { staticClass: "player-card-parts__img" }, [
        _c("img", { attrs: { src: _vm.imgPath, alt: "" } }),
      ]),
      _c("div", { staticClass: "player-card-parts__data" }, [
        _c("p", { staticClass: "player-card-parts__data__name" }, [
          _vm._v(
            " " + _vm._s(_vm.name || _vm.$t("common.favoriteNotSet")) + " "
          ),
        ]),
        _vm.team
          ? _c("p", { staticClass: "player-card-parts__data__team" }, [
              _vm._v(" " + _vm._s(_vm.team) + " "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }