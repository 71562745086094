var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlights-post-item-contents-parts" }, [
    _c(
      "a",
      {
        staticClass: "highlights-post-item-contents-parts__link",
        attrs: { href: _vm.snsPost.link },
      },
      [
        _c("sns-post-item-header-parts", { attrs: { snsPost: _vm.snsPost } }),
        _c("p", { staticClass: "post-item-contents-parts__text" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.snsPost.post) } }),
        ]),
        _c("p", {
          staticClass: "highlights-post-item-contents-parts__highlight-label",
          domProps: {
            textContent: _vm._s(
              _vm.$tc("HomePage.snsHighlightCommentCardLabel")
            ),
          },
        }),
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "highlights-post-item-contents-parts__thumbnail",
        attrs: { href: _vm.snsPost.link },
      },
      [
        _c(
          "figure",
          { staticClass: "highlights-post-item-contents-parts__thumbnail" },
          [
            _c("img", { attrs: { src: _vm.snsPost.thumbnail, alt: "" } }),
            _c("figcaption", [
              _vm._v(_vm._s(_vm.snsPost.highlightComment.sceneTitle)),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "a",
      {
        staticClass: "highlights-post-item-contents-parts__link",
        attrs: { href: _vm.snsPost.link },
      },
      [
        _c("div", { staticClass: "post-item-contents-parts__footer" }, [
          _c(
            "p",
            { staticClass: "highlights-post-item-contents-parts__scene-data" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.snsPost.highlightComment.raceDate) +
                  " Round " +
                  _vm._s(_vm.snsPost.highlightComment.round) +
                  " " +
                  _vm._s(_vm.snsPost.highlightComment.circuit)
              ),
              _c("br"),
              _vm._v(
                " Lap " +
                  _vm._s(_vm.snsPost.highlightComment.lap) +
                  " - S" +
                  _vm._s(_vm.snsPost.highlightComment.sector) +
                  " "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }