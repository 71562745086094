import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoBillingSettleMonthlyDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoBillingSettleMonthlyDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * 月契約の即時決済を実施するための処理を提供する。
 */
export default function useContractInfoBillingSettleMonthly() {
  // Collection modules
  const contractInfoBillingSettleMonthlyCollectionModule = CollectionModule.createStore(
    ContractInfoBillingSettleMonthlyDocument,
  )

  /**
   * 月契約の即時決済を実施する。
   * 決済対象の月は、下記の通り。
   * - 前月が未決済の際には、前月と当月
   * - 前月が決済済みの際には、当月と来月
   * @see https://npbam.slack.com/archives/CB1U12UBH/p1718329640723459?thread_ts=1717033416.269869&cid=CB1U12UBH
   * @return APIレスポンス
   */
  const immediatelyBillingSettleMonthly = async (
    contractInfoId: string,
    recentBillingTargetYearMonth: string,
  ) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/billing_settle/monthly`,
    }
    // 現在日時(UTC)
    const currentDate = dayjs.tz(dayjs())
    // 現在の年を取得
    const currentYear = currentDate.year().toString()
    // 現在の月を取得
    const currentMonth = (currentDate.month() + 1).toString().padStart(2, '0')
    const currentTargetYearMonth = `${currentYear}${currentMonth}`

    const billingTargetYearMonth = [currentTargetYearMonth]

    if (currentTargetYearMonth === recentBillingTargetYearMonth) {
      // 直近の月額プランの決済が、当月の際の処理
      // 来月日時
      const nextMonthDate = dayjs.tz(dayjs().add(1, 'M'))
      // 来月の年を取得
      const nextYear = nextMonthDate.year().toString()
      // 来月の月を取得
      const nextMonth = (nextMonthDate.month() + 1).toString().padStart(2, '0')

      billingTargetYearMonth.push(`${nextYear}${nextMonth}`)
    } else {
      // 直近の月額プランの決済が、先月の際の処理
      // 先月日時
      const beforeMonthDate = dayjs.tz(dayjs().subtract(1, 'M'))
      // 先月の年を取得
      const beforeYear = beforeMonthDate.year().toString()
      // 先月の月を取得
      const beforeMonth = (beforeMonthDate.month() + 1).toString().padStart(2, '0')

      billingTargetYearMonth.unshift(`${beforeYear}${beforeMonth}`)
    }

    return contractInfoBillingSettleMonthlyCollectionModule.save(
      new ContractInfoBillingSettleMonthlyDocument({ contractInfoId, billingTargetYearMonth }),
      options,
    )
  }

  return {
    immediatelyBillingSettleMonthly,
  }
}
