
































import dayjs from 'dayjs'
import { computed, defineComponent, inject, onMounted, ref, Ref } from '@vue/composition-api'
import type VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import MypageCurrentPlanSection from '@/components/MypageContractPage/MypageContractDetailsPane/MypageCurrentPlanSection.vue'
import MypageNextPlanSection from '@/components/MypageContractPage/MypageContractDetailsPane/MypageNextPlanSection.vue'
import MypageContractDetailsSection from '@/components/MypageContractPage/MypageContractDetailsPane/MypageContractDetailsSection.vue'
import I18n from '@/locales/I18n'
import useMypageContract, {
  MyContractDataType,
} from '@/components/MypageContractPage/hook/useMypageContract'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import StoreUtil from '@/store/StoreUtil'
import useContractTerm from '@/components/hook/useContractTerm'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import ExternalConfirmationSection, {
  type ExternalLinkType,
} from '@/components/common/ExternalConfirmation/ExternalConfirmationSection.vue'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'

/**
 * マイページ: 契約情報 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageContractDetailsPane',
  components: {
    ExternalConfirmationSection,
    MypageCurrentPlanSection,
    MypageNextPlanSection,
    MypageContractDetailsSection,
  },
  inject: ['router', 'loading'],
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { fetchMyContractData } = useMypageContract()
    const { effectiveContractPlan, effectiveOrgContract, paymentMethod } = contractInfoStore
    const { getCurrentContractTerm } = useContractTerm()
    const { getDisplayDateTime } = useDisplayDependingOnLang()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    /** ご契約内容に表示するデータ */
    const myContractData: Ref<MyContractDataType | null> = ref(null)

    const currentPlanName = computed(() => myContractData.value?.currentPlan)
    const nextPlanName = computed(() => myContractData.value?.nextPlan)
    const nextPlanStartDate = computed(() => myContractData.value?.nextPlanStartDate)

    /** 契約中のプランの契約期間 */
    const contractTerm = computed(() =>
      getCurrentContractTerm(
        effectiveContractPlan as ContractPlanDocument,
        effectiveOrgContract as ContractType,
      ),
    )
    /** クーポンプランを利用してるかどうか */
    const isCouponUsed = computed(
      () =>
        myContractData.value?.currentPlanGroupId === 'limitedTimePaidPlan' ||
        myContractData.value?.currentPlanGroupId === 'limitedTimePaidPlanForAnnual',
    )

    /** 外部ページでご契約内容を確認するように案内するかどうか */
    const isGuideExternalConfirmation = computed(
      () => paymentMethod === 'AppStore' || paymentMethod === 'GooglePlay',
    )

    /** 外部ページでご契約内容を確認するように案内するかどうか */
    const externalConfirmation = computed(() => {
      let title = ''
      let message = ''
      const links = [] as Array<ExternalLinkType>

      if (paymentMethod === 'AppStore') {
        // App Store決済
        title =
          DeviceInfo.isiOS() || DeviceInfo.isAppleDeviceBrowser()
            ? I18n.tc('MypagePage.common.externalConfirmation.title.iOS')
            : I18n.tc('MypagePage.common.externalConfirmation.title.iOSNotAppleDevice')
        if (DeviceInfo.isAndroid()) {
          // Androidの場合、App Storeで購入していますというメッセージを表示する
          message = I18n.tc('MypagePage.common.externalConfirmation.message.android')
        }
        if (DeviceInfo.isiOS() || DeviceInfo.isAppleDeviceBrowser()) {
          // Apple製品の場合はサブスクリプションの管理画面へのリンクを表示する
          links.push({
            name: I18n.tc('MypagePage.common.externalConfirmation.links.iOS.subscription'),
            url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.IOS,
          })
        }
        links.push({
          name: I18n.tc('MypagePage.common.externalConfirmation.links.iOS.history'),
          url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.HISTORY.IOS,
        })
      } else if (paymentMethod === 'GooglePlay') {
        // Google Play決済
        title = I18n.tc('MypagePage.common.externalConfirmation.title.android')
        if (DeviceInfo.isiOS()) {
          // iOSの場合、Google Playで購入していますというメッセージを表示する
          message = I18n.tc('MypagePage.common.externalConfirmation.message.iOS')
        }
        links.push({
          name: I18n.tc('MypagePage.common.externalConfirmation.links.android.subscription'),
          url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.ANDROID,
        })
      }

      return {
        title,
        message,
        links,
      }
    })

    /** ご契約内容に表示するデータを取得 */
    const fetchMyContract = async () => {
      const loader = loading.show()
      const result = await fetchMyContractData()
      loader.hide()
      if (!result) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageContract.detailsPage.errors.fetchMyContractDataError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageContract.detailsPage.errors.fetchMyContractDataError.message',
          ),
        })
        await router.replace({ name: 'MypageTopPage' })
        return
      }
      // 取得結果をセット
      myContractData.value = result
    }

    /** 注記を設定 */
    const attention1 = I18n.tc('MypagePage.MypageContract.detailsPage.attention')
    const attention2 = computed(() => {
      // 無料プラン、既に解約済みの場合は、「次の更新日の前日までに無料プランに切り替えた場合、〜」の表示は不要
      if (
        myContractData.value?.currentPlanGroupId === 'freePlan' ||
        myContractData.value?.nextPlanGroupId === 'freePlan'
      ) {
        return ''
      }
      // 年額プラン/月額プラン契約中でかつプラン変更や解約手続き済みのユーザーはプラン変更ができないため、「次の更新日の前日までに無料プランに切り替えた場合、〜」の表示は不要
      const hasNextPlan =
        (myContractData.value?.nextPlanGroupId !== '' &&
          myContractData.value?.currentPlanGroupId === 'monthlyPlan') ||
        (myContractData.value?.nextPlanGroupId !== '' &&
          myContractData.value?.currentPlanGroupId === 'annualPlan')
      if (hasNextPlan) {
        return ''
      }

      // クーポン利用中で、解約手続きがまだのユーザーへの注記
      if (
        myContractData.value?.currentPlanGroupId === 'limitedTimePaidPlan' ||
        myContractData.value?.currentPlanGroupId === 'limitedTimePaidPlanForAnnual'
      ) {
        return I18n.tc('MypagePage.MypageContract.detailsPage.couponPlanAttention')
      }

      const { contractEndDate } = contractTerm.value
      // 更新期限日は年額プラン終了日(UTC)の1日前の1:00:00になるため、それまでに手続きをしてもらう必要がある
      const updateContractDate = dayjs(dayjs(contractEndDate).utc())
        .subtract(1, 'd')
        .hour(1)
        .minute(0)
        .second(0)
        .valueOf()

      return myContractData.value?.currentPlanGroupId === 'annualPlan'
        ? `${I18n.t('MypagePage.MypageContract.detailsPage.annualPlanAttention', {
            deadline: getDisplayDateTime(updateContractDate),
          }).toString()}`
        : I18n.tc('MypagePage.MypageContract.detailsPage.paidPlanAttention')
    })

    onMounted(async () => {
      // ご契約内容に表示するデータを取得
      await fetchMyContract()
    })

    return {
      attention1,
      attention2,
      currentPlanName,
      nextPlanName,
      nextPlanStartDate,
      myContractData,
      isCouponUsed,
      contractTerm,
      isGuideExternalConfirmation,
      externalConfirmation,
    }
  },
})
