import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 選手ポジションの型定義
 *
 */
type PlayerPosition = {
  playerMasterId: string
}
/**
 * ホーム選手/アウェイ選手のポジションの型定義
 */
type Position = {
  positionId: string
  playerPositions: Array<PlayerPosition>
}
/**
 * 選手のポジション情報の型定義
 */
type Players = {
  homePlayers: Position
  awayPlayers: Position
  start: { movieStartTime: number }
  end: { movieStartTime: number }
}
/**
 * レースの参加選手情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/863797378/20+API
 */
export default class RaceParticipatingPlayersDocument extends DocumentWrapper {
  constructor(initProps?: Partial<RaceParticipatingPlayersDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/record/game_position'

  idAttr = 'gamePositionId'

  /**
   * レースID
   */
  matchId: string | null = null

  /**
   * レースID
   */
  get raceId() {
    return this.matchId
  }

  /**
   * 選手のポジション情報
   */
  players: Array<Players> = []
}
