import AWS from 'aws-sdk'

/**
 * AWS認証情報
 * ※ 便宜上、リージョンも含めている
 */
export type AWSCredentials = {
  accessKeyId: string
  secretAccessKey: string
  sessionToken: string
  region: string
}
/**
 * AWS SDKを利用するための機能を提供する。
 */
export default function useAWS() {
  /**
   * AWS SDKを指定された認証情報を利用して初期化し、利用可能な状態にする。
   * このメソッドが返却したAWSオブジェクトを利用して、AWS SDKの処理を呼び出す。
   *
   * @param awsCredentials AWS の認証情報
   */
  const initAWS = (awsCredentials: AWSCredentials) => {
    const credentials = new AWS.Credentials(awsCredentials)
    AWS.config.update({
      region: awsCredentials.region,
      credentials,
      httpOptions: {
        timeout: 0,
      },
    })
    return AWS
  }
  return {
    initAWS,
  }
}
