var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-ranking-share-post-section",
      on: { click: function () {} },
    },
    [
      _c("div", { staticClass: "mission-ranking-share-post-section__modal" }, [
        _c(
          "div",
          { staticClass: "mission-ranking-share-post-section__modal-inner" },
          [
            _c(
              "div",
              { staticClass: "mission-ranking-share-post-section__header" },
              [
                _c(
                  "p",
                  { staticClass: "mission-ranking-share-post-section__title" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$tc("MissionPage.RankingPage.Share.postOnlyX")
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                ref: "thumbnail",
                staticClass: "mission-ranking-share-post-section__thumbnail",
              },
              [
                _c("img", {
                  staticClass:
                    "mission-ranking-share-post-section__thumbnail-image",
                  style: { height: _vm.imageHeight - 12 + "px" },
                  attrs: { src: _vm.imageUrl, alt: "Ranking Image" },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "mission-ranking-share-post-section__input" },
              [
                _c("mission-ranking-share-input-section", {
                  on: {
                    onToggleEnabledInput: function ($event) {
                      return _vm.$emit("onClickCancel")
                    },
                    onClickPostSns: _vm.onClickPostSns,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }