var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "members-point-section" },
    [
      _c("div", { staticClass: "members-point-section__header" }, [
        _c("p", { staticClass: "members-point-section__current-point" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "14",
                height: "14",
                viewBox: "0 0 14 14",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM10.8567 6.1955C10.8567 4.8185 9.7767 4.0625 8.1567 4.0625H3.8457V10.3625H5.2857V8.3285H8.1567C9.7767 8.3285 10.8567 7.5905 10.8567 6.1955ZM9.3897 6.2045C9.3897 6.9245 8.8497 7.1405 8.0397 7.1405H5.2857V5.2685H8.0397C8.8497 5.2685 9.3897 5.4845 9.3897 6.2045Z",
                  fill: "white",
                },
              }),
            ]
          ),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.currentPoint.toLocaleString()),
            },
          }),
        ]),
        _vm.nextRank && _vm.nextRank !== "platinum"
          ? _c("p", { staticClass: "members-point-section__next-rank" }, [
              _c("span", {
                staticClass: "members-point-section__next-rank__text",
                domProps: { innerHTML: _vm._s(_vm.rankBenefitsLink) },
              }),
              _c("span", {
                staticClass: "members-point-section__next-rank__point",
                domProps: {
                  textContent: _vm._s(_vm.nextRankPoint.toLocaleString() + "P"),
                },
              }),
            ])
          : _vm._e(),
      ]),
      _c("point-gauge-parts", {
        staticClass: "members-point-section__gauge",
        attrs: {
          membersRankData: _vm.membersRankData,
          isFreePlan: _vm.isFreePlan,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }