var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-card-check-list",
      class: [
        "color-type-" + _vm.mission.colorType,
        { "is-completed": _vm.mission.hasCompleted },
      ],
    },
    _vm._l(_vm.mission.achievementCondition, function (condition, i) {
      return _c(
        "div",
        { key: i, staticClass: "mission-card-check-list__item" },
        [
          _c("mission-hexagon-parts", {
            attrs: { condition: condition, mission: _vm.mission },
          }),
          !_vm.mission.hasCompleted
            ? _c("p", { staticClass: "mission-card-check-list__item-name" }, [
                _vm._v(" " + _vm._s(condition.missionNameOnCheckList) + " "),
              ])
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }