



































import { computed, defineComponent, PropType } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import { PurchasePaymentMethodType } from '@/store/stores/pageStore/common/ContractInfoStore'
import DeviceInfo from '@/util/DeviceInfo'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

/**
 * マイページ: アプリ内課金 支払いプランを切り替える/有料会員を解約するモーダルセクション
 */
export default defineComponent({
  name: 'MypageChangePaidPlanModalSection',
  components: { AtomLinkButton, AtomIconLinkButton, CommonModalSection },
  props: {
    /**
     * 有料プラン購入時の支払い方法
     */
    paymentMethod: {
      type: [String, null] as PropType<PurchasePaymentMethodType | null>,
      default: null,
    },
    /**
     * モーダルID
     */
    modalId: {
      type: String,
      default: '',
    },
    /**
     * タイトル
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * 説明
     */
    description: {
      type: String,
      default: '',
    },
    /**
     * URL
     */
    url: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const linkUrl = 'https://superformula.net/sf3/sfgo/'

    /**
     * 以下の場合に表示するメッセージ
     * App Storeで課金し、Apple製品以外でSFgoアプリを開いている
     */
    const deviceNotSupportedMessage = computed(() => {
      if (
        props.paymentMethod === 'AppStore' &&
        !(DeviceInfo.isiOS() || DeviceInfo.isAppleDeviceBrowser())
      ) {
        if (!(props.modalId === 'changePaidPlan' || props.modalId === 'cancelPaidPlan')) {
          // プランを変更、プランを解約の場合のみ表示を想定
          return ''
        }

        const lang = I18n.locale === 'ja' ? 'JA' : 'EN'

        return I18n.t(
          'MypagePage.MypageTopPage.InAppPurchaseModal.deviceNotSupported.description.android',
          {
            howToUrl:
              props.modalId === 'changePaidPlan'
                ? Const.EXTERNAL_LINKS.IN_APP_PURCHASE.CHANGE.IOS[lang]
                : Const.EXTERNAL_LINKS.IN_APP_PURCHASE.CANCEL.IOS[lang],
            contactUrl: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUPPORT.IOS,
          },
        ).toString()
      }
      // 上記以外
      return ''
    })

    const handleOkClicked = () => {
      emit('onClickOk')
    }
    const handleCloseClicked = () => {
      emit('close')
    }
    return {
      linkUrl,
      deviceNotSupportedMessage,
      handleOkClicked,
      handleCloseClicked,
    }
  },
})
