




















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import PasswordResetMailSentPane from '@/components/PasswordResetPage/PasswordResetMailSentPane.vue'

/**
 * パスワードリセット メール送信完了 ページ
 */
export default defineComponent({
  name: 'PasswordResetMailSentPage',
  components: {
    SubHeaderSection,
    ActionButtonsSection,
    AtomLinkButton,
    PasswordResetMailSentPane,
  },
  setup() {
    const loginUrl = `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/login`

    return {
      loginUrl,
    }
  },
})
