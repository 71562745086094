





















import { defineComponent, Ref } from '@vue/composition-api'
import PlayerSelectListSection from '@/components/MypageFavoritePlayerSelectPage/MypageFavoritePlayerSelectPane/PlayerSelectListSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'

export type DataType = {
  selectedID: string
}

/**
 * マイページ: お気に入り選手変更 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageFavoritePlayerInitSelectPane',
  components: {
    PlayerSelectListSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  data(): DataType {
    return {
      /**
       * セレクトID
       */
      selectedID: '',
    }
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    return {
      players: mypagePageStore.addedSelectLaterAvailablePlayers as Ref<PlayerDocument[]>,
      savingFavoritePlayer: mypagePageStore.savingFavoritePlayer,
      setSavingFavoritePlayerId: mypagePageStore.setSavingFavoritePlayerId,
    }
  },
  mounted() {
    this.selectedID = this.savingFavoritePlayer ? this.savingFavoritePlayer.sid : ''
  },
  methods: {
    changeFavoritePlayer() {
      /**
       * 選択したお気に入り選手をストアに保存
       */
      this.setSavingFavoritePlayerId(this.selectedID)

      this.$emit('changed')
    },
    changeSelected(selectedItemID: string): void {
      /**
       * 選択変更
       * @param selectedItemID : string
       */
      this.selectedID = this.selectedID === selectedItemID ? '' : selectedItemID
    },
  },
})
