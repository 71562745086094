




































import { defineComponent, PropType, reactive } from '@vue/composition-api'
import CalendarChevronDownIcon from '@/components/LinkPage/CalendarPane/parts/icons/CalendarChevronDownIcon.vue'
import { CalendarDateSelectType } from '@/components/LinkPage/hooks/useCalendarSelectList'

export default defineComponent({
  name: 'CalendarYearMonthSelectorSection',
  components: { CalendarChevronDownIcon },
  props: {
    /**
     * モータースポーツカレンダー画面で選択できる年/年月のプルダウン（年）
     */
    calendarYearList: {
      type: Array as PropType<CalendarDateSelectType[]>,
      default: () => [],
    },
    /**
     * モータースポーツカレンダー画面で選択できる年/年月のプルダウン（年.月）
     */
    calendarYearAndMonthList: {
      type: Array as PropType<CalendarDateSelectType[]>,
      default: () => [],
    },
    /**
     * モータースポーツカレンダー画面で選択できる年/年月のプルダウン（年.月）
     */
    selectedYearOrMonth: {
      type: Object as PropType<CalendarDateSelectType>,
      default: () => ({}),
    },
  },
  setup(_props, { emit }) {
    const state = reactive({
      menuVisible: false,
    })

    const handleSelectorClicked = () => {
      state.menuVisible = !state.menuVisible
    }

    /**
     * 年（年月）が変更された場合の処理
     */
    const handleYearsAndMonthsClicked = (yearsAndMonths: CalendarDateSelectType) => {
      state.menuVisible = false
      emit('onYearsAndMonthsSelect', yearsAndMonths)
    }
    const handleOverlayClicked = () => {
      state.menuVisible = false
    }
    return {
      state,
      handleSelectorClicked,
      handleYearsAndMonthsClicked,
      handleOverlayClicked,
    }
  },
})
