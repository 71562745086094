<template>
  <div class="highlight-comment-toggle-parts">
    <button
      class="highlight-comment-toggle-parts__button"
      :class="{ 'highlight-comment-toggle-parts__button--active': isActive }"
      @click="handleToggleCommentClicked"
    >
      <span class="highlight-comment-toggle-parts__button-label"
        >{{ commentCounts }} {{ commentToggleButtonLabel }}</span
      >
      <span class="highlight-comment-toggle-parts__button-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.72388 5.33331L6.90008 10.5095C7.50756 11.117 8.49248 11.117 9.09996 10.5095L14.2762 5.33331L12.3905 5.33331L8.15715 9.5667C8.07037 9.65349 7.92967 9.65349 7.84289 9.5667L3.6095 5.33331H1.72388Z"
            fill="white"
          />
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'HighlightCommentToggleParts',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    commentCounts: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    // コメント用ラベル
    const commentToggleButtonLabel = computed(() =>
      props.commentCounts > 1 ? 'Comments' : 'Comment',
    )

    // コメント表示・非表示ボタンが押下されたときの処理
    const handleToggleCommentClicked = () => {
      emit('onClickToggle')
    }
    return {
      commentToggleButtonLabel,
      handleToggleCommentClicked,
    }
  },
})
</script>

<style scoped lang="scss">
.highlight-comment-toggle-parts {
  display: flex;
  justify-content: flex-end;
  height: 28px;

  &__button {
    font-size: 10px;
    font-weight: 500;
    color: $white;

    &-label {
      margin-right: 4px;
    }

    &-icon {
      display: inline-block;
    }

    &--active {
      .highlight-comment-toggle-parts__button-icon {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
