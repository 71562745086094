import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { PlanAfterCouponEndsType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

/**
 * お知らせAPIのTypeの型
 * TOOD: highlight、commentについてはデザイン実装に合わせて仮で定義している。
 * ハイライト作成、ハイライトコメント投稿時にお知らせを通知する機能実装時、改めてきちんと定義する。
 */
export type NotificationTypeUnion =
  | 'highlight'
  | 'comment'
  | 'SFGO_NOTIFICATION_FROM_MANAGEMENT'
  | 'SFGO_GRANT_POINT_FROM_MANAGEMENT'

export type NotificationTransitionType = null | 'url' | 'raceVideo' | 'sfgoAppPage'

type NotificationTransitionInfoType = {
  type: NotificationTransitionType
  path: string | null
  year?: number
  championshipMasterId?: string
}

/**
 * お知らせ情報を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/2778923009/API
 */
export default class NotificationDocument extends DocumentWrapper {
  constructor(initProps?: Partial<NotificationDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/information'

  idAttr = 'informationId'

  /**
   * お知らせID
   */
  informationId: string | null = null

  /**
   * お知らせタイトル名(ja, en)
   */
  title: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * お知らせ内容(ja, en)
   */
  contents: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 公開日時
   */
  releaseDate: number | null = null

  /**
   * 種別
   */
  type: NotificationTypeUnion | null = null

  /**
   * 追加情報
   */
  additionalData?: {
    /**
     * 遷移先情報
     */
    transition?: NotificationTransitionInfoType
    /**
     * Push通知を受け取るかどうか
     */
    needNotification?: boolean | null
    /**
     * 利用状況・契約状況一覧画面のフィルタ条件
     */
    filterConditions?: {
      /**
       * 退会済みユーザーを表示するかどうか
       */
      isShowWithdrawal: boolean
      /**
       * 決済エラーユーザーのみを表示するかどうか
       */
      isShowPaymentError: boolean
      /**
       * 契約プラン
       * （１）無料（２）月額（３）年額
       */
      filterSelectedPlans: Array<PlanAfterCouponEndsType>
      /**
       * 有料プランを契約したことのあるユーザーのみ表示するかどうか
       */
      isShowHaveContractedPaidPlanUser: boolean
      /**
       * クーポンコード検索のテキスト
       */
      searchTextAffiliateCode: string
      /**
       * 過去にクーポンを利用したことのあるユーザーを表示するかどうか
       */
      isShowHaveUsedCouponUser: boolean
      /**
       * 登録日
       * ・From
       * ・To
       */
      filterRegisterStartDate: string
      filterRegisterEndDate: string
      searchText: string
      filterSelectedPlayer: string
      valueSelectedPlayerName: string
      filterSelectedTeam: string
      valueSelectedTeamName: string
      filterSelectedPrefecture: string
      valueSelectedPrefecture: string
      filterSelectedBirthday: string
      valueSelectedBirthday: string
    }
  } | null = null
}
