




























import { defineComponent, computed, PropType } from '@vue/composition-api'

export type AttentionMessageData = {
  message: string | null
  note: string | null
}

/**
 * 契約導線: 注意喚起 セクション
 */
export default defineComponent({
  name: 'AttentionMessageSection',
  props: {
    /**
     * メッセージデータ
     */
    attentionMessage: {
      type: Object as PropType<AttentionMessageData>,
      required: true,
    },
  },
  setup(props) {
    const message = computed(() => props.attentionMessage?.message)
    const note = computed(() => props.attentionMessage?.note)

    return {
      message,
      note,
    }
  },
})
