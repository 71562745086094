import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import { getDeclineInfo } from '@/util/StripeCodeGetter'
import { ErrorCodeType } from '@/util/APIResponse'

/**
 * ユーザー登録画面の機能を提供する。
 */
export default function useErrorHandling() {
  /** エラページに遷移させるか */
  let isMoveToErrorPage = false

  /**
   * 契約情報契約APIエラー時の制御
   * @return {isMoveToErrorPage: boolean} エラーページに遷移するかどうか
   */
  const createContractError = async (errorCode: ErrorCodeType | undefined) => {
    switch (errorCode?.error_code) {
      case '400_0055':
        // クレジットカード決済エラーの場合
        if (errorCode.payment_service?.decline_code) {
          const declineCodeResult = getDeclineInfo(errorCode.payment_service.decline_code)
          await MessageDialogStore.value.open({
            title: I18n.tc('SignupPage.RegistrationPage.errors.createStripeError.title'),
            message: I18n.tc('SignupPage.RegistrationPage.errors.createStripeError.message'),
            declineCode: errorCode.payment_service.decline_code,
            description: declineCodeResult?.description || '',
            nextUserAction: I18n.tc(
              'SignupPage.RegistrationPage.errors.createStripeError.nextUserAction',
            ),
          })
        } else {
          // payment_service、decline_codeが返却されない場合の考慮も一応入れておく
          await MessageDialogStore.value.open({
            title: I18n.tc('SignupPage.RegistrationPage.errors.createStripeError.title'),
            message: I18n.tc('SignupPage.RegistrationPage.errors.createStripeError.message'),
          })
        }
        break
      case '400_0162':
        // 3Dセキュア認証が必須のクレジットカードを使用した場合
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.RegistrationPage.errors.3dSecureError.title'),
          message: I18n.tc('SignupPage.RegistrationPage.errors.3dSecureError.message'),
        })
        break
      case '400_0143':
        // クーポン有効期間外エラーの場合
        // 有効期間切れの場合はそもそもクーポンを取得できないようになっているが、有効期間ギリギリにクーポンを取得し、有効期間切れのタイミングでプラン登録を実行した場合、こちらのエラーが返ってくる想定。
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.RegistrationPage.errors.couponPeriodError.title'),
          message: I18n.tc('SignupPage.RegistrationPage.errors.couponPeriodError.message'),
        })
        break
      case '400_0144':
        // クーポン利用可能回数エラー
        // 残り利用可能回数0の場合はそもそもクーポンを取得できないようになっているが、残り利用可能回数1のクーポンを2人のユーザーがほぼ同時に使ってプラン登録を試みた場合、片方のユーザーはこちらのエラーが返ってくる想定。
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.RegistrationPage.errors.couponLimitError.title'),
          message: I18n.tc('SignupPage.RegistrationPage.errors.couponLimitError.message'),
        })
        break
      default:
        // 上記以外のエラー場合
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.RegistrationPage.errors.createContractError.title'),
          message: I18n.tc('SignupPage.RegistrationPage.errors.createContractError.message'),
        })
        isMoveToErrorPage = true
        break
    }
    return {
      isMoveToErrorPage,
    }
  }

  /**
   * 現在地取得処理エラー時の制御
   * GeolocationPositionError.code 一覧
   * https://developer.mozilla.org/ja/docs/Web/API/GeolocationPositionError/code
   */
  const createGeolocationError = async (positionError: GeolocationPositionError) => {
    switch (positionError.code) {
      case 1:
        // ページが位置情報の取得を許可していない
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.gpsTurnedOff.title'),
          message: I18n.tc('common.errors.gpsTurnedOff.message'),
        })
        break
      case 3:
        // タイムアウト
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.getGpsTimeOut.title'),
          message: I18n.tc('common.errors.getGpsTimeOut.message'),
        })
        break
      default:
        // 上記以外のエラー場合（内部エラーやネットワークに接続されていない場合など）
        // エラーのバリエーションが予測できないため、Geolocation APIが返すエラーメッセージも表示する
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.getGpsOtherError.title'),
          message: I18n.t('common.errors.getGpsOtherError.message', {
            positionErrorMessage: positionError.message,
          }).toString(),
        })
        break
    }
  }

  return {
    createContractError,
    createGeolocationError,
  }
}
