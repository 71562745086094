


















import { defineComponent, inject } from '@vue/composition-api'
import VueRouter from 'vue-router'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import CalendarPane from '@/components/LinkPage/CalendarPane.vue'

export default defineComponent({
  name: 'MotorsportCalendar',
  components: { CalendarPane, GlobalNavigationPane, SubHeaderSection, HeaderPane },
  setup() {
    const router = inject('router') as VueRouter
    const backToPreviousPage = () => router.back()
    return {
      backToPreviousPage,
    }
  },
})
