/**
 * 請求金額を計算するhook
 */
export default function usePaymentPrice() {
  /**
   * 50円未満の請求を考慮して請求金額を返す
   * @param price 請求金額
   */
  const calculatePaymentPriceString = (price: number | undefined) => {
    let paymentPrice = ''
    if (price && price > 0) {
      // 日割り料金が50円未満の場合は50円を請求する
      paymentPrice = price >= 50 ? price.toLocaleString() : '50'
    }
    return paymentPrice
  }

  return {
    calculatePaymentPriceString,
  }
}
