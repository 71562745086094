var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sns-content-tabs-parts" }, [
    _c("div", { staticClass: "sns-content-tabs-parts__list" }, [
      _c(
        "button",
        {
          staticClass:
            "sns-content-tabs-parts__tab sns-content-tabs-parts__tab__driver",
          class: {
            "sns-content-tabs-parts__tab--current":
              _vm.currentType === "driver",
          },
          on: {
            click: function ($event) {
              return _vm.emitSelect("driver")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "19",
                viewBox: "0 0 18 19",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M17 11.5878C18.5 7.58783 16 1 10 1C5.57106 1 3.72487 2.93953 2.27172 5.99456C1.79401 6.99887 1.35878 8.12373 0.888227 9.33987C0.620375 10.0321 0.341079 10.754 0.0359911 11.5L0 11.5878V14L2 16.5H14L17 11.5878ZM3.67381 6.53982C3.46722 6.96773 3.2634 7.43282 3.05498 7.93762C2.80329 8.54723 2.55319 9.1936 2.28408 9.88911L2.28406 9.88916L2.26969 9.9263C2.07483 10.4299 1.87085 10.9567 1.65378 11.5H6.2759C6.74377 11.5 7.19684 11.336 7.55627 11.0364L10 9L3.67381 6.53982ZM13 9.5C13 10.0523 12.5523 10.5 12 10.5C11.4477 10.5 11 10.0523 11 9.5C11 8.94772 11.4477 8.5 12 8.5C12.5523 8.5 13 8.94772 13 9.5Z",
                  fill: "#2B2B2B",
                },
              }),
            ]
          ),
          _c("span", [_vm._v(_vm._s(_vm.$tc("HomePage.snsTabs.driver")))]),
        ]
      ),
      _c(
        "button",
        {
          staticClass:
            "sns-content-tabs-parts__tab sns-content-tabs-parts__tab__team",
          class: {
            "sns-content-tabs-parts__tab--current": _vm.currentType === "team",
          },
          on: {
            click: function ($event) {
              return _vm.emitSelect("team")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "19",
                viewBox: "0 0 18 19",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { "clip-path": "url(#clip0_8731_40090)" } }, [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M6.50012 5.5C6.50012 6.60457 5.60469 7.5 4.50012 7.5C3.39555 7.5 2.50012 6.60457 2.50012 5.5C2.50012 4.39543 3.39555 3.5 4.50012 3.5C5.60469 3.5 6.50012 4.39543 6.50012 5.5ZM15.5001 5.5C15.5001 6.60457 14.6047 7.5 13.5001 7.5C12.3956 7.5 11.5001 6.60457 11.5001 5.5C11.5001 4.39543 12.3956 3.5 13.5001 3.5C14.6047 3.5 15.5001 4.39543 15.5001 5.5ZM2.56155 8.5H6.43845C7.35618 8.5 8.15615 9.1246 8.37873 10.0149L8.99994 12.4998L9.62115 10.0149C9.84373 9.12459 10.6437 8.5 11.5614 8.5H15.4383C16.3561 8.5 17.156 9.1246 17.3786 10.0149L17.9999 12.5H9H8.99988H0L0.621268 10.0149C0.843852 9.12459 1.64382 8.5 2.56155 8.5ZM0.00012207 15.25H18.0001V13.75H0.00012207V15.25Z",
                    fill: "#2B2B2B",
                  },
                }),
              ]),
              _c("defs", [
                _c("clipPath", { attrs: { id: "clip0_8731_40090" } }, [
                  _c("rect", {
                    attrs: {
                      width: "18",
                      height: "18",
                      fill: "white",
                      transform: "translate(0 0.5)",
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _c("span", [_vm._v(_vm._s(_vm.$tc("HomePage.snsTabs.team")))]),
        ]
      ),
      _c(
        "button",
        {
          staticClass:
            "sns-content-tabs-parts__tab sns-content-tabs-parts__tab__official",
          class: {
            "sns-content-tabs-parts__tab--current":
              _vm.currentType === "official",
          },
          on: {
            click: function ($event) {
              return _vm.emitSelect("official")
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$tc("HomePage.snsTabs.official")))])]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }