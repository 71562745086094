var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-user-init-confirm-pane" },
    [
      _c("user-init-confirm-section"),
      _c(
        "action-buttons-section",
        {
          staticClass: "mypage-user-init-confirm-pane__action-buttons",
          attrs: { scrollTargetSelector: ".mypage-user-init-confirm-pane" },
        },
        [
          _c("atom-input-button", {
            staticClass: "mypage-user-init-confirm-pane__registration",
            attrs: {
              linkText: _vm.$tc("common.registration"),
              isDisabled: false,
            },
            on: { push: _vm.saveUserInfo },
          }),
          _c("atom-router-button", {
            staticClass: "mypage-user-init-confirm-pane__cancel",
            attrs: {
              linkText: _vm.$tc("common.cancel"),
              isDisabled: false,
              to: "/mypage/init",
              colorType: "tertiary",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }