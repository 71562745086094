












import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import CompletePane from '@/components/pc/MyPage/CompletePane/CompletePane.vue'

/**
 * 退会完了ページ
 */
export default defineComponent({
  name: 'CancelCompletePage',
  components: {
    SubHeaderSection,
    CompletePane,
  },
})
