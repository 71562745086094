





















import { computed, defineComponent, ref } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

/**
 * HOME 会員限定動画モーダル
 */
export default defineComponent({
  name: 'LimitedVideoModalSection',
  components: {
    CommonModalSection,
    AtomInputButton,
  },
  props: {
    videoId: {
      type: String,
    },
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const youtubeRef = ref<any>(null)

    // Youtube IFrame Playerのインスタンス。
    // 利用できるメソッドについては以下を参照。
    // https://developers.google.com/youtube/iframe_api_reference#Functions
    const youtubePlayer = computed(() => youtubeRef.value.player)

    // Youtube プレーヤーのパラメタ。
    // 参考: https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5
    const playerVars = { autoplay: 1 }
    return {
      playerVars,
      youtubeRef,
      youtubePlayer,
    }
  },
  methods: {
    /**
     * closeModal
     * 親コンポーネントへ閉じる処理
     */
    emitClose(): void {
      this.$emit('close')
    },
  },
})
