var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "select-race-item-parts",
      class: { "select-race-item-parts__current": _vm.current },
      attrs: { type: "button" },
      on: { click: _vm.push },
    },
    [
      _c(
        "span",
        {
          staticClass: "select-race-item-parts__round",
          style: {
            "background-image": "url(" + _vm.raceImg + ")",
            "background-position": "right bottom",
          },
        },
        [
          _vm.round !== 0
            ? _c("span", [
                _c(
                  "span",
                  { staticClass: "select-race-item-parts__round__label" },
                  [_vm._v("RD.")]
                ),
                _c(
                  "span",
                  { staticClass: "select-race-item-parts__round__num" },
                  [_vm._v(_vm._s(_vm.round))]
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "select-race-item-parts__round__course" }, [
            _c("img", { attrs: { src: _vm.courseImg, alt: _vm.venue } }),
          ]),
        ]
      ),
      _c("span", { staticClass: "select-race-item-parts__data" }, [
        _c("span", { staticClass: "select-race-item-parts__data__venue" }, [
          _vm._v(_vm._s(_vm.venue)),
        ]),
        _c("span", { staticClass: "select-race-item-parts__data__date" }, [
          _vm._v(_vm._s(_vm.raceDate)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }