var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ranking-card-winner-parts" }, [
    _c("div", { staticClass: "ranking-card-winner-parts__content" }, [
      _c("div", { staticClass: "ranking-card-winner-parts__header" }, [
        _c("h4", { staticClass: "ranking-card-winner-parts__name" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.rankingCardData.number
                  ? "#" + _vm.rankingCardData.number + " "
                  : null
              ) +
              " " +
              _vm._s(_vm.rankingCardData.name[_vm.$i18n.locale]) +
              " "
          ),
        ]),
        _c("p", {
          staticClass: "ranking-card-winner-parts__best-data",
          domProps: { textContent: _vm._s(_vm.rankingCardData.bestData) },
        }),
      ]),
      _vm.rankingCardData.links.length > 0
        ? _c(
            "div",
            { staticClass: "ranking-card-winner-parts__links" },
            _vm._l(_vm.rankingCardData.links, function (link, index) {
              return _c("ranking-player-link-parts", {
                key: "rankingCardPartsWinnerLink" + link + "." + index,
                attrs: { linkData: link, winner: true },
              })
            }),
            1
          )
        : _vm._e(),
    ]),
    _c("figure", { staticClass: "ranking-card-winner-parts__img" }, [
      _vm.rankingCardData.img
        ? _c("img", {
            attrs: {
              src: _vm.rankingCardData.img,
              alt: _vm.rankingCardData.name[_vm.$i18n.locale],
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }