var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment-count-parts",
      class: "comment-count-parts--" + _vm.commentCountStatus,
    },
    [
      _vm.remainingCommentCount > -10
        ? _c(
            "svg",
            {
              staticClass: "comment-count-parts__indicator",
              attrs: { width: "24", height: "24", fill: "none" },
            },
            [
              _c("g", { attrs: { transform: "rotate(-90, 12, 12)" } }, [
                _c("circle", {
                  staticClass: "comment-count-parts__indicator-bar",
                  style: _vm.indicatorBarStyle,
                  attrs: {
                    cx: "12",
                    cy: "12",
                    r: _vm.circleRadius,
                    "stroke-width": "2",
                  },
                }),
                _c("circle", {
                  staticClass: "comment-count-parts__indicator-bg",
                  attrs: {
                    cx: "12",
                    cy: "12",
                    r: _vm.circleRadius,
                    "stroke-width": "2",
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.remainingCommentCount <= 20
        ? _c("p", { staticClass: "comment-count-parts__indicator-number" }, [
            _vm._v(" " + _vm._s(_vm.remainingCommentCount) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }