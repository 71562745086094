var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "new-highlights-section",
      class: [
        "new-highlights-section--" + _vm.screenOrientationType,
        { "new-highlights-section--edit": _vm.createHighlight },
        { "new-highlights-section--is-pc": _vm.isPc },
      ],
    },
    [
      !_vm.createHighlight && _vm.showCreateHighlightButton
        ? _c(
            "button",
            {
              staticClass: "new-highlights-section__add-button",
              class: {
                "new-highlights-section__add-button--disabled":
                  _vm.disabledAddButton,
              },
              attrs: { disabled: _vm.disabledAddButton },
              on: { click: _vm.enableHighlightCardCreate },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M11 13V22H13V13H22V11H13V2H11V11H2V13H11Z",
                      fill: "#2B2B2B",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("div", {
        staticClass: "new-highlights-section__mask",
        class: { "new-highlights-section__mask--active": _vm.createHighlight },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return function () {}.apply(null, arguments)
          },
        },
      }),
      _c("div", {
        staticClass: "new-highlights-section__wrapper",
        class: {
          "new-highlights-section__wrapper--active": _vm.createHighlight,
        },
      }),
      _c(
        "transition",
        { attrs: { name: "slide", appear: "" } },
        [
          _vm.createHighlight && _vm.highlightData
            ? _c("new-highlights-input-section", {
                staticClass: "new-highlights-section__create",
                attrs: {
                  videoPlayerKey: _vm.videoPlayerKey,
                  screenOrientationType: _vm.screenOrientationType,
                  highlightData: _vm.highlightData,
                },
                on: {
                  close: _vm.hideHighlightCardCreate,
                  onSaveHighlight: _vm.saveHighlight,
                  showHighlightPreviewVideoPlayer:
                    _vm.showHighlightPreviewVideoPlayer,
                  hideHighlightPreviewVideoPlayer:
                    _vm.hideHighlightPreviewVideoPlayer,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }