var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ranking-player-list-item-parts" }, [
    _c("div", { staticClass: "ranking-player-list-item-parts__content" }, [
      _c("div", { staticClass: "ranking-player-list-item-parts__rank" }, [
        _c("i", [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M13 3.5465V2H4V3.5465H1V5.75C1 7.64375 2.41675 9.1985 4.243 9.449C4.50458 10.2161 4.96855 10.8983 5.58586 11.4234C6.20317 11.9486 6.95089 12.2972 7.75 12.4325V13.25C7.75 13.6478 7.59196 14.0294 7.31066 14.3107C7.02936 14.592 6.64782 14.75 6.25 14.75H5.5V16.25H11.5V14.75H10.75C10.3522 14.75 9.97064 14.592 9.68934 14.3107C9.40804 14.0294 9.25 13.6478 9.25 13.25V12.4325C10.0491 12.2972 10.7968 11.9486 11.4141 11.4234C12.0315 10.8983 12.4954 10.2161 12.757 9.449C14.5833 9.1985 16 7.64375 16 5.75V3.5465H13ZM2.5 5.75V5.0465H4V7.871C3.56162 7.71554 3.18209 7.42822 2.91352 7.04846C2.64495 6.6687 2.5005 6.21513 2.5 5.75ZM14.5 5.75C14.4994 6.21521 14.355 6.66885 14.0864 7.04871C13.8179 7.42857 13.4384 7.71606 13 7.87175V5.0465H14.5V5.75Z",
                  fill: _vm.svgColor,
                },
              }),
            ]
          ),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.rank))]),
      ]),
      _c("div", { staticClass: "ranking-player-list-item-parts__player" }, [
        _c("figure", { staticClass: "ranking-player-list-item-parts__img" }, [
          _vm.playerData.img
            ? _c("img", {
                attrs: {
                  src: _vm.playerData.img,
                  alt: _vm.playerData.name[_vm.$i18n.locale],
                },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "ranking-player-list-item-parts__meta" }, [
          _c("h4", { staticClass: "ranking-player-list-item-parts__name" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.playerData.number
                    ? "#" + _vm.playerData.number + " "
                    : null
                ) +
                " " +
                _vm._s(_vm.playerData.name[_vm.$i18n.locale]) +
                " "
            ),
          ]),
          _c("p", {
            staticClass: "ranking-player-list-item-parts__best-data",
            domProps: { textContent: _vm._s(_vm.playerData.bestData) },
          }),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "ranking-player-list-item-parts__link" },
      [
        _vm.playerData.links[0]
          ? _c("ranking-player-link-parts", {
              attrs: { linkData: _vm.playerData.links[0] },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }