import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * ライブタイミング集計のランキングデータ情報内の各ドライバー毎の記録データを表現するクラス。
 * ライブタイミング集計データの rankings配列内の要素のデータのクラスとして利用される。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3421307066/09.+Race+Rank
 */
export default class RankingRecord extends DocumentWrapper {
  constructor(initProps?: Partial<RankingRecord>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * 順位
   */
  ranking: number | null = null

  /**
   * 値
   */
  value: string | null = null

  /**
   * 値(表示用)
   */
  valueDisp: string | null = null

  /**
   * 周回数
   */
  laps: number | null = null

  /**
   * レコード記録時間
   */
  recordedTime: number | null = null

  /**
   * レコード記録ラップの開始時間
   */
  startLapTimeAtRecorded: number | null = null

  /**
   * 車両番号
   */
  carNo: string | null = null

  /**
   * ドライバー名日本語
   */
  driverNameJa: string | null = null

  /**
   * ドライバー名英語
   */
  driverNameEn: string | null = null

  /**
   * 走行時間
   */
  totalTime: number | null = null

  /**
   * 走行時間(表示用)
   */
  totalTImeDisp: string | null = null
}
