













import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageDetailsSettingPane from '@/components/MypageDetailsSettingPage/MypageDetailsSettingPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * マイページ: 詳細設定
 */
export default defineComponent({
  name: 'MypageDetailsSettingPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageDetailsSettingPane,
  },
  methods: {},
})
