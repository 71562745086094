var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "mission-check-icon",
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M13.5838 5.57092L7.5425 12.2481C7.44325 12.3578 7.27098 12.3578 7.17173 12.2481L4.74939 9.57082H2.72656L6.05943 13.2545C6.75417 14.0224 7.96007 14.0224 8.6548 13.2545L15.6066 5.57092H13.5838Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }