import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/languages/en.json'
import ja from '@/locales/languages/ja.json'
import removeTodo from '@/locales/scripts/removeTodo'
import DeviceInfo from '@/util/DeviceInfo'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'

Vue.use(VueI18n)

// 表示可能な言語、標準はenを設定
const supported = ['en', 'ja'] as Array<UserLang>
let locale = 'en' as UserLang

try {
  // デバイスの言語を取得し設定、サポート外言語の場合はenに設定
  const browserLang = DeviceInfo.getLanguage()
  if (supported.includes(browserLang)) locale = browserLang
  console.info(`Language settings have been configured. lang: ${locale}`)
} catch (e) {
  console.info(`Failed to init i18n settings. e: ${e}`)
}

const i18n = new VueI18n({
  locale: `${locale}`,
  fallbackLocale: ['en'],
  messages: {
    en: removeTodo(en),
    ja: removeTodo(ja),
  },
})

export default i18n
