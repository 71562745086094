



































import { defineComponent, PropType } from '@vue/composition-api'
import SnsPostItemImagesParts from '@/components/HomePage/HomePane/parts/SnsPostItemImagesParts.vue'
import SnsPostItemHeaderParts from '@/components/HomePage/HomePane/parts/SnsPostItemHeaderParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'

/**
 * HOME SNSコンテンツ 投稿アイテム Twitter用パーツ
 */
export default defineComponent({
  name: 'TwitterPostItemContentsParts',
  components: {
    SnsPostItemImagesParts,
    SnsPostItemHeaderParts,
  },
  props: {
    snsPost: {
      type: Object as PropType<SnsPostData>,
      required: true,
    },
  },
  methods: {
    updateSnsContentsData(src: string) {
      this.$emit('updateSnsContentsData', src)
    },
  },
})
