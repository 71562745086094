





















import { defineComponent, PropType, computed } from '@vue/composition-api'
import CarouselListWrapperParts from '@/components/HomePage/HomePane/parts/CarouselListWrapperParts.vue'
import videoLinkCardParts, {
  VideoLinkCardData,
  VideoLinkCardMembershipData,
} from '@/components/HomePage/HomePane/parts/VideoLinkCardParts.vue'

/**
 * HOME Youtubeリストセクション
 */
export default defineComponent({
  name: 'YoutubeListSection',
  components: {
    CarouselListWrapperParts,
    videoLinkCardParts,
  },
  props: {
    title: {
      type: String,
    },
    videoLinks: {
      type: Array as PropType<VideoLinkCardData[]>,
      required: true,
    },
    membershipType: {
      type: String as PropType<VideoLinkCardMembershipData>,
      default: 'free',
    },
    enabledCarouselList: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    /** 動画データが存在しているか */
    const isVideoLinks = computed(() => props.videoLinks.length)
    return { isVideoLinks }
  },
  methods: {
    /**
     * Youtubeカードが選択された場合に呼び出される。
     */
    onVideoSelected(videoCardData: VideoLinkCardData) {
      this.$emit('videoSelected', videoCardData)
    },
  },
})
