

































import { computed, defineComponent, PropType } from '@vue/composition-api'
import RadioListPlayButtonParts from '@/components/RaceVideoPage/common/parts/RadioListPlayButtonParts.vue'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'

/**
 * レース動画再生画面 選手選手用 無線アイテムのコンポーネント
 */
export default defineComponent({
  name: 'RadioListItemParts',
  components: {
    RadioListPlayButtonParts,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選手無線データ
     */
    radioData: {
      type: Object as PropType<RadioDataDocument>,
      require: true,
    },
  },
  setup(props, context) {
    const playerRadioData = computed(() => props.radioData)
    const lap = computed(() => props.radioData?.lap)
    const sector = computed(() => props.radioData?.sector)

    /**
     * 無線再生が実施された際に呼び出される。
     *
     * @param scene 動画再生位置の変更を行うかどうか。
     */
    const playSelectPlayerRadio = (scene: boolean): void => {
      context.emit('selectPlayerRadioPlay', props.radioData, scene)
    }

    return {
      playSelectPlayerRadio,
      playerRadioData,
      lap,
      sector,
    }
  },
})
