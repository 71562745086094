var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navigation-link-icon-parts",
      attrs: {
        width: "25",
        height: "24",
        viewBox: "0 0 25 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5.56668 4.66406V8.66406H9.56668V4.66406H5.56668ZM4.90001 2.66406C4.16363 2.66406 3.56668 3.26102 3.56668 3.9974V9.33073C3.56668 10.0671 4.16363 10.6641 4.90001 10.6641H10.2333C10.9697 10.6641 11.5667 10.0671 11.5667 9.33073V3.9974C11.5667 3.26102 10.9697 2.66406 10.2333 2.66406H4.90001Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5.56668 15.3281V19.3281H9.56668V15.3281H5.56668ZM4.90001 13.3281C4.16363 13.3281 3.56668 13.9251 3.56668 14.6615V19.9948C3.56668 20.7312 4.16363 21.3281 4.90001 21.3281H10.2333C10.9697 21.3281 11.5667 20.7312 11.5667 19.9948V14.6615C11.5667 13.9251 10.9697 13.3281 10.2333 13.3281H4.90001Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M16.2333 4.66406V8.66406H20.2333V4.66406H16.2333ZM15.5667 2.66406C14.8303 2.66406 14.2333 3.26102 14.2333 3.9974V9.33073C14.2333 10.0671 14.8303 10.6641 15.5667 10.6641H20.9C21.6364 10.6641 22.2333 10.0671 22.2333 9.33073V3.9974C22.2333 3.26102 21.6364 2.66406 20.9 2.66406H15.5667Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M16.2333 15.3281V19.3281H20.2333V15.3281H16.2333ZM15.5667 13.3281C14.8303 13.3281 14.2333 13.9251 14.2333 14.6615V19.9948C14.2333 20.7312 14.8303 21.3281 15.5667 21.3281H20.9C21.6364 21.3281 22.2333 20.7312 22.2333 19.9948V14.6615C22.2333 13.9251 21.6364 13.3281 20.9 13.3281H15.5667Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }