var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "information-toggle-button-parts",
      class: {
        "information-toggle-button-parts--hidden":
          !_vm.hasCurrentRaceInformation,
        "information-toggle-button-parts--is-open": _vm.showInformation,
      },
      on: { click: _vm.emitClick },
    },
    [
      _c("span", [
        _vm._v(_vm._s(_vm.$tc("RaceListPage.Information.RaceKeyPoints"))),
      ]),
      _c("i", [
        _c(
          "svg",
          {
            class: { "is-stop": _vm.stopIconBounds },
            attrs: {
              width: "18",
              height: "18",
              viewBox: "0 0 18 18",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M8.40317 3.71814L4.20343 7.91789L2.08211 7.91789L3.14277 6.85723L8.44607 1.55393C8.83659 1.16341 9.46976 1.16341 9.86028 1.55393L15.1636 6.85723L16.2242 7.91789L14.1029 7.91789L9.90317 3.71814L9.90318 16.4032L8.40318 16.4032L8.40317 3.71814Z",
                fill: "black",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }