var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ec-site-connect-section" },
    [
      _vm.ecConnectStatus === "connected"
        ? _c("mypage-ec-site-connect-complete-parts")
        : _c("p", { staticClass: "mypage-ec-site-connect-section__prepare" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc("MypagePage.MypageEcSiteConnectPage.prepareMessage")
                ) +
                " "
            ),
          ]),
      _vm.ecConnectStatus !== "connected"
        ? _c("atom-icon-link-button", {
            staticClass: "mypage-ec-site-connect-section__link",
            attrs: {
              url: _vm.ecSiteRegistrationUrl,
              linkText: _vm.$tc(
                "MypagePage.MypageEcSiteConnectPage.ecSiteRegisterLinkLabel"
              ),
              colorType: "secondary",
              imgPath: require("@/assets/img/icon/icon_external_link_gray.svg"),
              iconPosition: "right",
            },
          })
        : _vm._e(),
      _vm.ecConnectStatus !== "connected"
        ? _c("mypage-ec-site-connect-button-parts", {
            staticClass: "mypage-ec-site-connect-section__button",
            attrs: { ecConnectStatus: _vm.ecConnectStatus },
            on: { connectEc: _vm.connectEc },
          })
        : _vm._e(),
      _vm.ecConnectStatus === "connecting" ||
      _vm.ecConnectStatus === "connected"
        ? _c("atom-icon-link-button", {
            staticClass: "mypage-ec-site-connect-section__link",
            attrs: {
              url: _vm.ecSiteUrl,
              linkText: _vm.$tc(
                "MypagePage.MypageEcSiteConnectPage.ecSiteLinkLabel"
              ),
              imgPath: require("@/assets/img/icon/icon_external_link_gray.svg"),
              colorType: "tertiary",
            },
          })
        : _vm._e(),
      _vm.ecConnectStatus == "connectError"
        ? _c("mypage-ec-site-connect-error-parts", {
            staticClass: "mypage-ec-site-connect-section__error",
            attrs: { errorData: _vm.ecConnectErrorData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }