































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'

/**
 * レース動画再生画面 選手選手用 無線アイテム 再生ボタンのコンポーネント
 */
export default defineComponent({
  name: 'RadioListPlayButtonParts',
  components: {},
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選手無線データ
     */
    radioData: {
      type: Object as PropType<RadioDataDocument>,
      require: true,
    },
  },
  setup(props) {
    const playerRadioData = computed(() => props.radioData)
    const isPlaying = computed(() => props.radioData?.state.isPlaying.value)
    const currentTime = computed(() => props.radioData?.state.currentTime.value)

    /**
     * 無線交信データ再生のプログレス値
     */
    const gaugeWidth = computed(() => {
      const duration = playerRadioData.value ? playerRadioData.value.duration - 0.2 : 0
      const currentTimeValue = currentTime.value ?? 0
      return playerRadioData.value?.duration ? (currentTimeValue / duration) * 100 : 0
    })
    return {
      gaugeWidth,
      playerRadioData,
      isPlaying,
    }
  },
  methods: {
    /**
     * 無線の再生が実施された際に呼び出される。
     */
    playRadioAudio(): void {
      this.$emit('playRadio', this.radioData, false)
    },
  },
})
