










































import {
  computed,
  defineComponent,
  Ref,
  ref,
  WritableComputedRef,
  onBeforeMount,
} from '@vue/composition-api'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import AttentionMessageSection, {
  AttentionMessageData,
} from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import PaymentCycleSection from '@/components/pc/MyPage/common/PaymentCycleSection.vue'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import StoreUtil from '@/store/StoreUtil'
import useContract from '@/components/hook/useContract'
import useErrorHandling from '@/components/hook/useErrorHandling'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import Const from '@/util/Const'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import I18n from '@/locales/I18n'

/**
 * 新規会員登録:プラン選択画面ペインのコンポーネント (有料会員向け)
 */
export default defineComponent({
  name: 'SignupPaymentConfirmPane',
  components: {
    SignupHeaderSection,
    ActionButtonsSection,
    AtomInputButton,
    CurrentPlanCardSection,
    PaymentCycleSection,
    AttentionMessageSection,
  },
  setup() {
    const loginStore = StoreUtil.useStore('LoginStore')
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { createContract } = useContract()

    /** 請求サイクル画像パス */
    const paymentCycleImgPath = computed(() => {
      const path = `SignupPage/confirmPage/payment_cycle_${signupPageStore.selectedPlanGroupId.value}_${I18n.locale}`
      return {
        pc: `${path}_pc.jpg`,
        sp: `${path}_sp.jpg`,
      }
    })
    /** 注意事項テキスト */
    const attentionMessage: Ref<AttentionMessageData | null> = ref(null)
    /** クーポン利用してるかのフラグ */
    const isCouponUsed: Ref<boolean> = ref(false)

    onBeforeMount(async () => {
      if (
        signupPageStore.getSelectedContractPlan.value?.planGroupId === 'limitedTimePaidPlan' ||
        signupPageStore.getSelectedContractPlan.value?.planGroupId ===
          'limitedTimePaidPlanForAnnual'
      ) {
        /** クーポン期限切れ前に通知する設定をしてるかのフラグ */
        const isNeedToNotify = signupPageStore.coupons.value[0]?.notifyBeforeExpires
        const attentionMessageNote = isNeedToNotify
          ? I18n.tc('PaidMembershipChange.ConfirmPage.couponPlan.attentionNote')
          : null

        /** 注意事項テキストをセット */
        attentionMessage.value = {
          message: I18n.tc('SignupPage.SignupPaymentConfirmPage.couponPlan.attentionMessage'),
          note: attentionMessageNote,
        }
        /** クーポン利用かのフラグをセット */
        isCouponUsed.value = true
      }
    })

    return {
      loginStore,
      fetchUser: signupPageStore.fetchUser,
      plan: signupPageStore.getSelectedContractPlan as Ref<ContractPlanDocument>,
      selectedPlanGroupId:
        signupPageStore.selectedPlanGroupId as WritableComputedRef<PaidPlanGroupType>,
      flowPathMemberType: signupPageStore.flowPathMemberType,
      memberType: signupPageStore.memberType,
      paymentCycleImgPath,
      saveRegistrationTypeInProgress: signupPageStore.saveRegistrationTypeInProgress,
      coupons: signupPageStore.coupons,
      getCouponPlanWithCouponDocument: signupPageStore.getCouponPlanWithCouponDocument,
      createContract,
      isCouponUsed,
      attentionMessage,
    }
  },
  methods: {
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      const { createContractError } = useErrorHandling()
      const errorStore = StoreUtil.useStore('ErrorStore')

      const loader = this.$loading.show()
      /** プラン登録手続き中のフラグをセット */
      // ログインユーザー情報を取得
      await this.fetchUser(this.loginStore.loginId)
      /** ログインユーザーをプラン登録手続き中にする
       * 有料プラン登録画面から遷移してくるユーザーは登録手続き中状態になっているが、
       * 無料プラン登録完了後すぐに有料プランにアップグレードする場合は無料プラン登録完了時にプラン登録手続き完了状態になるため、改めて有料プラン手続き中をセットし直す。
       */
      const saveProgressStartResult = await this.saveRegistrationTypeInProgress(
        this.memberType,
        'start',
      )
      if (!saveProgressStartResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$t('SignupPage.RegistrationPage.errors.createContractError.title') as string,
          message: this.$t(
            'SignupPage.RegistrationPage.errors.createContractError.message',
          ) as string,
        })
        errorStore.setConfig('createContractError')
        await this.$router.replace({ name: 'ErrorPage' })
        return
      }

      /**
       * クーポンプランの開始日時が先付けの場合、先に無料プランを登録させる
       * 会員登録画面流入時の会員登録タイプがpaidの場合に処理を行う（会員登録画面流入時の会員登録タイプがfreeの場合は無料プランが登録されるため、ここでは考慮不要）
       */
      const coupon = this.coupons[0]
      if (this.flowPathMemberType === Const.MEMBER_TYPE.PAID && coupon?.plans) {
        const originalCouponPlan = this.getCouponPlanWithCouponDocument(coupon.plans)
        if (originalCouponPlan?.startDate && originalCouponPlan.startDate > new Date().getTime()) {
          // クーポンプランの開始日時が将来の場合、先に無料プランを登録する
          const createFreeContractResult = await this.createContract(
            'freePlan',
            Const.MEMBER_TYPE.FREE,
          )

          if (!createFreeContractResult.isSuccess) {
            loader.hide()
            const response = await createContractError(createFreeContractResult.response?.data)
            if (!response.isMoveToErrorPage) {
              // プラン選択画面に遷移する
              this.$emit('handlerCancel')
            } else {
              // エラーページに遷移する
              errorStore.setConfig('createContractError')
              await this.$router.replace({ name: 'ErrorPage' })
            }
            return
          }
          /** プラン登録手続き完了にする */
          // 後続の処理に失敗したとしても無料プランが登録されるため、ログインユーザーのプラン登録手続きを完了にする
          await this.saveRegistrationTypeInProgress(this.memberType, 'end')
        }
      }

      /** 契約情報契約プランを登録 */
      const createContractResult = await this.createContract(
        this.selectedPlanGroupId,
        this.memberType,
      )

      if (!createContractResult.isSuccess) {
        loader.hide()
        const response = await createContractError(createContractResult.response?.data)
        if (!response.isMoveToErrorPage) {
          // プラン選択画面に遷移する
          this.$emit('handlerCancel')
        } else {
          // エラーページに遷移する
          errorStore.setConfig('createContractError')
          await this.$router.replace({ name: 'ErrorPage' })
        }
        return
      }

      await Promise.all([
        // ログインユーザーのプラン登録手続きを完了にする
        this.saveRegistrationTypeInProgress(this.memberType, 'end'),
        // プラン登録完了画面に請求情報を表示するため、グローバルストアの契約情報を最新化する
        ContractInfoStore.value.fetchUserContractInfo(),
      ])

      this.$emit('handlerSubmit')
      loader.hide()
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
