




























import { defineComponent, ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageFavoritePlayerSelectPane from '@/components/MypageFavoritePlayerSelectPage/MypageFavoritePlayerSelectPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

export type DataType = {
  screenTransitionModal: boolean
  screenTransitionTo: string
}

/**
 * マイページ: お気に入り選手選択ページ
 */
export default defineComponent({
  name: 'MypageFavoritePlayerSelectPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageFavoritePlayerSelectPane,
    ActionButtonsSection,
    AtomInputButton,
  },
  data(): DataType {
    return {
      screenTransitionModal: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '', // 画面遷移警告モーダル 遷移先指定
    }
  },
  setup() {
    const favoritePlayerPane = ref<InstanceType<typeof MypageFavoritePlayerSelectPane>>()
    return {
      favoritePlayerPane,
    }
  },
  methods: {
    /**
     * お気に入り選手を設定する。
     */
    setFavoritePlayer(): void {
      this.favoritePlayerPane?.changeFavoritePlayer()
    },
    /**
     * マイページTOPページに遷移する
     */
    moveToMypageTop(): void {
      this.$router.replace({ path: '/mypage' })
    },
  },
})
