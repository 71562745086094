var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "standings-list-player-parts",
      attrs: { to: _vm.standingsPlayer.link },
    },
    [
      _c(
        "div",
        { staticClass: "standings-list-player-parts__player" },
        [
          _c("div", {
            staticClass: "standings-list-player-parts__player-rank",
            domProps: { textContent: _vm._s(_vm.standingsPlayer.rank) },
          }),
          _c("player-card-parts", {
            staticClass: "standings-list-player-parts__player-data",
            attrs: {
              name: _vm.setPlayerName(_vm.standingsPlayer.name),
              team: _vm.standingsPlayer.teamName,
              img: _vm.standingsPlayer.playerPictureImagePath,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "standings-list-player-parts__total-pts" }, [
        _c("span", {
          staticClass: "standings-list-player-parts__total-pts__label",
          domProps: {
            textContent: _vm._s(_vm.$tc("StandingsPage.TopPage.totalPts")),
          },
        }),
        _c(
          "span",
          { staticClass: "standings-list-player-parts__total-pts__points" },
          [_vm._v(_vm._s(_vm.standingsPlayer.totalPts))]
        ),
      ]),
      _c(
        "svg",
        {
          staticClass: "standings-list-player-parts__arrow",
          attrs: {
            width: "18",
            height: "18",
            viewBox: "0 0 18 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M6.03906 1.89844L11.8623 7.72166C12.5457 8.40508 12.5457 9.51312 11.8623 10.1965L6.03906 16.0198L6.03906 13.8984L10.8016 9.13587C10.8993 9.03824 10.8993 8.87995 10.8016 8.78232L6.03906 4.01976L6.03906 1.89844Z",
              fill: "black",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }