































import { defineComponent } from '@vue/composition-api'

/**
 * ページネーション: セクション
 */
export default defineComponent({
  name: 'PaginationSection',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const PAGINATION_MAX = 5 // 一度に表示する最大個数: 奇数のみ
    const ADJACENT_COUNT = 1 // カレント表示の前後に表示する個数
    const diffFillThreshold = (PAGINATION_MAX - 1) / 2 // 最初と最後からの差分穴埋表示数計算

    const isPageNumberShown = (page: number) =>
      props.totalPages <= PAGINATION_MAX || // ページ数が最大個数以下
      page === 1 || // 最初
      page === props.totalPages || // 最後
      page === props.currentPage || // カレント
      (page >= props.currentPage - ADJACENT_COUNT && page < props.currentPage) || // カレントの前
      (page <= props.currentPage + ADJACENT_COUNT && page > props.currentPage) || // カレントの後
      (page < PAGINATION_MAX && props.currentPage <= diffFillThreshold) || // 1 [2] 3 4 ... 8 この場合の4を出す
      (page > props.totalPages - (PAGINATION_MAX - 1) &&
        props.currentPage > props.totalPages - diffFillThreshold) // 1 ... 7 8 [9] 10 この場合の7を出す

    const isSeparatorFirstShown = (page: number) =>
      props.currentPage > PAGINATION_MAX - diffFillThreshold &&
      PAGINATION_MAX < props.totalPages &&
      page === 1

    const isSeparatorLastShown = (page: number) =>
      props.currentPage <= props.totalPages - (PAGINATION_MAX - diffFillThreshold) &&
      PAGINATION_MAX < props.totalPages &&
      page === props.totalPages

    const onClickButton = (page: number) => {
      if (page === props.currentPage) return
      context.emit('pageSelected', page)
    }

    return {
      isPageNumberShown,
      isSeparatorFirstShown,
      isSeparatorLastShown,
      onClickButton,
    }
  },
})
