



















import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import AwardPane from '@/components/LinkPage/AwardPane.vue'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'

/**
 * SFgo Award ページ
 */
export default defineComponent({
  name: 'AwardPage',
  components: {
    SubHeaderSection,
    HeaderPane,
    AwardPane,
  },
  props: {
    /**
     * ヘッダーナビ表示指定
     */
    hasHeaderNav: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * 前のページに遷移する
     */
    backToPreviousPage() {
      return this.$router.back()
    },
  },
})
