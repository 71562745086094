









































import { computed, defineComponent, PropType } from '@vue/composition-api'
import MissionRankingTitleParts from '@/components/MissionPage/MissionRankingPane/parts/MisstionRankingTitleParts.vue'
import MissionRankingTableSection from '@/components/MissionPage/MissionRankingPane/MissionRankingTableSection.vue'
import MissionRankingPeriodParts from '@/components/MissionPage/MissionRankingPane/parts/MissionRankingPeriodParts.vue'
import MissionRankingEndDateParts from '@/components/MissionPage/MissionRankingPane/parts/MissionRankingEndDateParts.vue'
import {
  PointRankingType,
  RankingType,
} from '@/store/stores/pageStore/MissionPage/MissionPageStore'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import StoreUtil from '@/store/StoreUtil'
import MissionRankingSelfNotRankedParts from '@/components/MissionPage/MissionRankingPane/parts/MissionRankingSelfNotRankedParts.vue'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import useMissionRanking from '@/components/MissionPage/MissionRankingPane/hook/useMissionRanking'

export default defineComponent({
  name: 'MissionRankingListSection',
  components: {
    MissionRankingSelfNotRankedParts,
    MissionRankingEndDateParts,
    MissionRankingPeriodParts,
    MissionRankingTableSection,
    MissionRankingTitleParts,
  },
  props: {
    pointRanking: {
      type: Object as PropType<PointRankingType>,
      required: true,
    },
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
  },
  setup(props) {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { ownOrganization } = contractInfoStore
    const { getOwnRankingData } = useMissionRanking()

    // 自分のランクがランク外である事を確認する
    const isOwnRankUnranked = computed(() => {
      if (!ownOrganization.value || !props.pointRanking.rankings) return undefined
      const ownUserId = LoginStore.value.userId || ''

      return !props.pointRanking.rankings.some((rank) => rank.userId === ownUserId)
    })

    // 自分のランキングデータをカテゴリーごとに出し分ける
    const ownPointRanking = computed(() =>
      getOwnRankingData(props.pointRanking, ownOrganization.value),
    )

    const rankingType = computed<RankingType>(() => {
      const pointRankingKey = props.pointRanking.key || ''
      const typeSeason = /.*SEASON.*/
      const typeMonthly = /.*MONTHLY.*/

      if (typeSeason.test(pointRankingKey)) {
        return 'SEASON'
      }

      if (typeMonthly.test(pointRankingKey)) {
        return 'MONTHLY'
      }

      return 'TOTAL'
    })

    return {
      isOwnRankUnranked,
      ownPointRanking,
      rankingType,
    }
  },
  methods: {
    onClickSnsButton() {
      const userData =
        this.ownPointRanking?.selfData ||
        this.pointRanking.rankings.find((rank) => rank.userId === LoginStore.value.userId)
      this.$emit('onClickSnsButton', userData, this.pointRanking)
    },
    onClickShareIcon() {
      const userData =
        this.ownPointRanking?.selfData ||
        this.pointRanking.rankings.find((rank) => rank.userId === LoginStore.value.userId)
      this.$emit('onClickShareIcon', userData, this.pointRanking)
    },
  },
})
