var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navigation-home-icon-parts",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M13.3,2.5c-0.8-0.7-1.9-0.7-2.7,0L0,12h3l9-8l9,8h3L13.3,2.5z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M18,21H6c-1.1,0-2-0.9-2-2v-7h2v7h12v-7h2v7C20,20.1,19.1,21,18,21z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }