


























import { defineComponent, PropType, Ref } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import TimetableHeaderParts from '@/components/RaceListPage/RaceListPane/parts/TimetableHeaderParts.vue'
import TimetableItemParts from '@/components/RaceListPage/RaceListPane/parts/TimetableItemParts.vue'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'

export default defineComponent({
  name: 'RaceTimetableSection',
  components: { TimetableItemParts, TimetableHeaderParts },
  props: {
    /**
     * レース情報
     */
    raceListByStartDate: {
      type: Object as PropType<Record<string, Array<RaceDocument>>>,
    },
  },
  setup(props, context) {
    const topPageStore = StoreUtil.useStore('TopPageStore')

    const { contentsInfos, angleMovieInfos, qualifyingRaceStartEventList } = topPageStore

    /**
     * DAY1、DAY2などを取得
     */
    const getDayNumber = (dateKey: string) => {
      if (!props.raceListByStartDate) return ''

      const keysArr = Object.keys(props.raceListByStartDate).filter((key) => key !== '')
      const index = keysArr.indexOf(dateKey)
      return index === -1 ? '' : `DAY ${index + 1}`
    }

    /**
     * レースの閲覧権限がない場合、モーダルを表示するために親に通知
     */
    const showFlowLineToPaidPlanModal = () => {
      context.emit('showFlowLineToPaidPlanModal')
    }

    return {
      getDayNumber,
      showFlowLineToPaidPlanModal,
      contentsInfos: contentsInfos as Ref<Array<ContentsInfoDocument>>,
      angleMovieInfos: angleMovieInfos as Ref<Array<AngleMovieInfoDocument>>,
      qualifyingRaceStartEventList: qualifyingRaceStartEventList as Ref<Array<HighlightDocument>>,
    }
  },
})
