var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ec-site-connect-error-parts" },
    [
      _vm.errorData.title
        ? _c(
            "h3",
            { staticClass: "mypage-ec-site-connect-error-parts__title" },
            [_vm._v(" " + _vm._s(_vm.errorData.title) + " ")]
          )
        : _vm._e(),
      _c("p", {
        staticClass: "mypage-ec-site-connect-error-parts__description",
        domProps: { innerHTML: _vm._s(_vm.errorData.description) },
      }),
      _vm.errorData.hasContact
        ? _c("mypage-ec-site-connect-contact-parts", {
            staticClass: "mypage-ec-site-connect-error-parts__contact",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }