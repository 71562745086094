import dayjs from 'dayjs'
import I18n from '@/locales/I18n'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import ContractPlanDocument, {
  PlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * 契約プランの型
 */
export type CouponContractPlanType = {
  /**
   * プラングループID
   */
  planGroupId: PlanGroupType
  /**
   * 開始日時
   */
  startDate?: number | null
  /**
   * 終了日時
   */
  endDate?: number | null
  /**
   * プラン期間
   */
  planDuration?: number | null
  /**
   * プラン情報
   */
  masterContractPlans?: Array<ContractPlanDocument>
}

/**
 * クーポン情報/ユーザー個別クーポン情報を表現する共通クラス
 */
export default class BaseCouponDocument extends DocumentWrapper {
  constructor(initProps?: Partial<BaseCouponDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * 契約プラン
   */
  plans?: Array<CouponContractPlanType> | null = null

  /**
   * クーポン適用期間テキスト
   */
  get couponApplicationPeriodText() {
    const { getDisplayDate } = useDisplayDependingOnLang()

    let startDate: string
    let endDate: string

    const couponPlan = this.plans?.find(
      (plan) =>
        plan.planGroupId === 'limitedTimePaidPlan' ||
        plan.planGroupId === 'limitedTimePaidPlanForAnnual',
    )

    // ワンタイムパスはクーポン利用後に無料プランに戻る
    const isOneTimePassUsed = this.plans?.some((plan) => plan.planGroupId === 'freePlan')

    const durationText = isOneTimePassUsed
      ? 'SignupPage.SelectPlanPage.plan.couponContractTerm'
      : 'SignupPage.SelectPlanPage.plan.freePlanTerm'

    /** 期間指定で作成されたクーポンの場合 */
    if (couponPlan?.planDuration) {
      // 開始日時をクーポン利用日の0時に設定する
      startDate = getDisplayDate(dayjs().startOf('day').valueOf())

      endDate = getDisplayDate(
        dayjs(startDate)
          .add(couponPlan.planDuration / 1000, 's')
          .valueOf() - 1,
        'YYYY/MM/DD',
      )

      return endDate
        ? `${I18n.t(durationText, {
            startDate,
            endDate,
          }).toString()}`
        : ''
    }

    /** 日付指定で作成されたクーポンの場合 */
    // クーポンが即日適用される場合は今日の日付をセットする
    startDate = couponPlan?.startDate
      ? getDisplayDate(dayjs(couponPlan?.startDate).valueOf())
      : getDisplayDate(dayjs().valueOf())

    endDate = getDisplayDate(dayjs(couponPlan?.endDate).valueOf())

    return endDate
      ? `${I18n.t(durationText, {
          startDate,
          endDate,
        }).toString()}`
      : ''
  }
}
