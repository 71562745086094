var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-race-points-card-parts", {
    attrs: {
      cardTitle: _vm.$tc("RaceListPage.Information.videoPoint"),
      pointsCardData: _vm.racePointVideoData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }