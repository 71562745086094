






























import { defineComponent, PropType } from '@vue/composition-api'
import ToggleRadioCardListSection from '@/components/common/ToggleRadioCard/ToggleRadioCardListSection.vue'
import ToggleRadioCardParts from '@/components/common/ToggleRadioCard/ToggleRadioCardParts.vue'
import StoreUtil from '@/store/StoreUtil'
import CouponPlanCardParts from '@/components/SignupPage/common/CouponPlanCardParts.vue'
import PlanCardParts from '@/components/SignupPage/common/PlanCardParts.vue'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ProcedureScreenType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 新規会員登録 プラン選択
 */
export default defineComponent({
  name: 'ChoosePlanSection',
  components: {
    ToggleRadioCardListSection,
    ToggleRadioCardParts,
    CouponPlanCardParts,
    PlanCardParts,
  },
  props: {
    /**
     * 表示するタイトル
     */
    sectionTitle: {
      type: String,
      default: '',
    },
    /**
     * プラン一覧
     */
    planList: {
      type: Array as () => Array<ContractPlanDocument>,
      default: () => [],
    },
    /**
     * 選択中のプラングループID（プラン）
     */
    selectedPlanGroupId: {
      type: String as PropType<PaidPlanGroupType>,
      required: true,
    },
    /**
     * どの手続き画面か
     */
    procedureScreen: {
      type: String as PropType<ProcedureScreenType>,
      default: null,
    },
  },
  setup(props, context) {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { monthlyPlanPriceJpy, annualPlanPriceJpy } = signupPageStore

    /** 月払いプランと比べて、年払いプランを契約した場合のお得額を計算 */
    const bargainPriced = (plan: ContractPlanDocument) => {
      if (plan.planGroupId === 'monthlyPlan') {
        /** 月払いはお得にならない */
        return ''
      }

      // お得額
      return `${(
        100 -
        (annualPlanPriceJpy.value / (monthlyPlanPriceJpy.value * 12)) * 100
      ).toFixed()}%`
    }

    /**
     * プラン選択通知
     * @param val
     */
    const handlerPlanSelect = (val: PaidPlanGroupType) => context.emit('handlerPlanSelect', val)

    return {
      bargainPriced,
      handlerPlanSelect,
    }
  },
})
