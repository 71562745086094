






































import { computed, defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import HighlightsCardSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/HighlightsCardSection.vue'
import { EditHighlightData } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import CommentInputSection from '@/components/RaceVideoPage/RaceVideoHighlightsPane/CommentInputSection.vue'
import i18n from '@/locales/I18n'
import { HighlightOperationType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane.vue'

/**
 * レース動画再生画面 ハイライトカード新規作成/編集 入力セクション
 */
export default defineComponent({
  name: 'NewHighlightsInputSection',
  components: {
    HighlightsCardSection,
    CommentInputSection,
  },
  inject: ['loading'],
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    editType: {
      type: String as PropType<HighlightOperationType>,
      default: 'create',
    },
    /**
     * ハイライトの作成・編集で使用するデータ
     */
    highlightData: {
      type: Object as PropType<EditHighlightData>,
    },
    /**
     * プレビュー動画プレイヤー再描画用のキー
     */
    videoPlayerKey: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const targetInputHighlightComment = ref<string>('')
    // Xにハイライトを投稿するかどうか
    const postX = ref<boolean>(false)
    // Instagramにハイライトを投稿するかどうか
    const postInstagram = ref<boolean>(false)
    const screenLandscape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)
    const viewportHeight = ref<number>(0)
    const title = computed(() => i18n.tc(`RaceVideoPage.highlights.${props.editType}.title`))
    // SNSに投稿したことがあるかどうか
    const postedSns = computed(
      () => props.highlightData?.editHighlightData.additionalData?.postedSns,
    )

    const changeHighlightComment = (val: string) => {
      targetInputHighlightComment.value = val
    }

    /**
     * ハイライト動画をXに投稿する/しないを切り替える
     */
    const onChangePostX = (val: boolean) => {
      postX.value = val
    }

    /**
     * ハイライト動画をInstagramに投稿する/しないを切り替える
     */
    const onChangePostInstagram = (val: boolean) => {
      postInstagram.value = val
    }

    onMounted(() => {
      targetInputHighlightComment.value = props.highlightData?.editHighlightData?.note || ''
    })

    return {
      targetInputHighlightComment,
      changeHighlightComment,
      viewportHeight,
      screenLandscape,
      title,
      postX,
      postInstagram,
      postedSns,
      onChangePostX,
      onChangePostInstagram,
    }
  },
  methods: {
    /**
     * 動画プレイヤーを表示する
     */
    showHighlightPreviewVideoPlayer() {
      this.$emit('showHighlightPreviewVideoPlayer')
    },
    /**
     * 動画プレイヤーを非表示にする
     */
    hideHighlightPreviewVideoPlayer() {
      this.$emit('hideHighlightPreviewVideoPlayer')
    },
    emitClose() {
      this.$emit('close')
    },
    /**
     * ハイライトカード作成
     */
    newHighlightsCard() {
      this.$emit(
        'onSaveHighlight',
        this.targetInputHighlightComment,
        this.highlightData?.editHighlightData,
        this.postX,
        this.postInstagram,
      )
    },
  },
})
