





























import {
  computed,
  defineComponent,
  onBeforeUnmount,
  PropType,
  reactive,
  watch,
} from '@vue/composition-api'
import AtomButton from '@/components/atoms/AtomButton.vue'
import i18n from '@/locales/I18n'
import type { EcConnectStatusType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'

export default defineComponent({
  name: 'MypageEcSiteConnectButtonParts',
  components: {
    AtomButton,
  },
  props: {
    ecConnectStatus: {
      type: String as PropType<EcConnectStatusType>,
      required: true,
    },
  },
  setup(props) {
    const animationDelay = 500
    const state = reactive({
      animationTimerId: 0,
      count: 0,
    })

    /**
     * 連携するボタンのラベル
     */
    const connectButtonLabel = computed(() =>
      props.ecConnectStatus === 'connecting'
        ? i18n.tc('MypagePage.MypageEcSiteConnectPage.connectingButtonLabel')
        : i18n.tc('MypagePage.MypageEcSiteConnectPage.connectButtonLabel'),
    )
    /**
     * 連携中のアニメーション用クラスを付与
     */
    const connectingAnimate = () => {
      state.animationTimerId = window.setInterval(() => {
        state.count += 1
        if (state.count > 3) {
          state.count = 0
        }
      }, animationDelay)
    }
    watch(
      () => props.ecConnectStatus,
      () => {
        if (props.ecConnectStatus === 'connecting') {
          connectingAnimate()
        } else {
          clearInterval(state.animationTimerId)
          state.count = 0
        }
      },
    )
    onBeforeUnmount(() => {
      clearInterval(state.animationTimerId)
    })

    if (props.ecConnectStatus === 'connecting') {
      connectingAnimate()
    }

    return {
      state,
      connectButtonLabel,
    }
  },
  methods: {
    handleConnectEc() {
      this.$emit('connectEc')
    },
  },
})
