import dayjs from 'dayjs'
import { RoleForUserType } from '@/store/stores/loginStore/RoleType'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import Const, { MembersRankNamePointsType } from '@/util/Const'

/**
 * SFgoユーザータイプ別カラー
 * // TODO: ロール、無料/有料でマイページのカード色を出し分ける機能は現在利用していない。
 * // この機能を公開する場合は修正が必要となる。
 */
const USER_COLOR = {
  free: '#fff',
  premium: '#FF4949',
  'team-user': '#00A69C',
  'team-engineer': '#00A69C',
  driver: '#00DBA6',
  guest: '#CE66FF',
  ambassador: '#ECAA00',
  adviser: '#ECAA00',
  'special-supporter': '#ECAA00',
  'jrp-staff': '#ECAA00',
}

/**
 * SFgoユーザー タイプ別背景カラー
 */
const MEMBERS_RANK_BG_COLOR = {
  free: {
    color: '#fff',
    opacity: 0.4,
  },
  bronze: {
    color: '#D98B52',
    opacity: 0.5,
  },
  silver: {
    color: '#C2C2C2',
    opacity: 0.5,
  },
  gold: {
    color: '#ECAA00',
    opacity: 0.4,
  },
  platinum: {
    color: '#85BADE',
    opacity: 0.5,
  },
}

export default function useMembershipCard() {
  /**
   * ユーザーのロールに応じたカラーを取得する
   * @param role RoleType
   */
  const getUserRoleColor = (role: RoleForUserType | undefined): string => {
    if (role) {
      return USER_COLOR[role]
    }

    if (ContractInfoStore.value.isFreePlan) {
      return USER_COLOR.free
    }

    return USER_COLOR.premium
  }

  /**
   * ユーザーのタイプを取得する
   * team-userの場合は、-userを切り取ってteamで返す
   * @param role ユーザーのロール
   */
  const getUserType = (role: RoleForUserType | undefined) => {
    if (role) {
      if (role && role.includes('-user')) {
        return role.replace('-user', '')
      }
      return role
    }
    // 無料
    if (ContractInfoStore.value.isFreePlan) {
      return 'free'
    }
    // 有料
    return 'premium'
  }

  /**
   * 末尾にst,nd,rd,thを付与
   * @param num
   */
  const getNumberWithSuffix = (num: number) => {
    const j = num % 10
    const k = num % 100
    if (j === 1 && k !== 11) {
      return `${num}st Year`
    }
    if (j === 2 && k !== 12) {
      return `${num}nd Year`
    }
    if (j === 3 && k !== 13) {
      return `${num}rd Year`
    }
    return `${num}th Year`
  }

  /**
   * 表示可能な形式のデータを作成
   */
  const getBadgeDisplayYearsInfo = (createdDate: number | undefined) => {
    if (!createdDate) return null
    const now = dayjs()
    const targetDate = dayjs(createdDate)
    /**
     * バッチに表示する年数（getNumberWithSuffixの引数に指定することで、以下のように表示される）
     * 会員登録した年：1st Year
     * 会員登録して1年経過：2nd Year
     */
    const displayYears = now.diff(targetDate, 'year') + 1

    return {
      badgeType: 'year',
      years: displayYears,
      label: getNumberWithSuffix(displayYears || 1),
    }
  }

  /**
   * ポイント獲得数に応じたランクを取得する
   * @param ownedPoint 保有ポイント
   * @returns ランク
   */
  const getCurrentRank = (ownedPoint: number | null) => {
    if (ownedPoint === null) {
      return undefined
    }
    /**
     * reverse()だけだとこの配列自体が逆順になってしまうため、slice().reverse()を使う
     * https://qiita.com/seltzer/items/2f9ee13cf085966f1a4c
     */
    const rank =
      Const.MEMBERS_RANK_NAME_POINTS.slice()
        .reverse()
        .find((rankPoint) => rankPoint.point <= ownedPoint)?.rank ?? 'bronze'

    if (rank === 'platinum') {
      // TODO: GOLDより上のランクについてはリリースしないため、仮定義しているGOLDの次ランクのポイントを保有していたとしてもgoldを返す
      return 'gold'
    }
    return rank
  }

  /**
   * 対象ランクの達成ポイントを取得する
   * @param targetRank ランク
   * @returns number
   */
  const getRankAchievementPoint = (targetRank: MembersRankNamePointsType) => {
    if (targetRank) {
      return (
        Const.MEMBERS_RANK_NAME_POINTS.find((rankPoint) => rankPoint.rank === targetRank)?.point ??
        0
      )
    }
    return 0
  }

  /**
   * ユーザーのランクに応じた背景カラーを取得する
   * @param targetRank ランク
   * @param isFreePlan 無料プランユーザーかどうか
   */
  const getMembersRankColor = (targetRank: MembersRankNamePointsType, isFreePlan: boolean) => {
    const rank = Const.MEMBERS_RANK_NAME_POINTS.find(
      (rankPoint) => rankPoint.rank === targetRank,
    )?.rank
    if (!isFreePlan && rank) {
      return MEMBERS_RANK_BG_COLOR[rank]
    }

    return MEMBERS_RANK_BG_COLOR.free
  }

  return {
    getUserRoleColor,
    getUserType,
    getBadgeDisplayYearsInfo,
    getCurrentRank,
    getRankAchievementPoint,
    getMembersRankColor,
  }
}
