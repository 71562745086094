






































import { computed, defineComponent } from '@vue/composition-api'

/**
 * レース動画再生画面 動画プレーヤー 縦画面 動画コントローラー コンポーネント
 */
export default defineComponent({
  name: 'PortraitVideoControllerParts',
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * ビデオ一時停止フラグ
     */
    videoPaused: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const screenLandScape = computed(() => props.screenOrientationType?.indexOf('landscape') !== -1)
    const videoPausedStatus = computed(() => props.videoPaused)

    return {
      screenLandScape,
      videoPausedStatus,
    }
  },
  methods: {
    playVideo(): void {
      /**
       * ビデオ 再生 処理
       */
      this.$emit('videoPlayPauseChange', false)
    },
    pauseVideo(): void {
      /**
       * ビデオ 一時停止 処理
       */
      this.$emit('videoPlayPauseChange', true)
    },
    prevVideo30s(): void {
      /**
       * ビデオ 30秒巻き戻し 処理
       */
      this.$emit('videoPlayChangeCurrentTime', -30.0)
    },
    nextVideo30s(): void {
      /**
       * ビデオ 30秒先送り 処理
       */
      this.$emit('videoPlayChangeCurrentTime', 30.0)
    },
  },
})
