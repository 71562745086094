var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "membership-card-bg-parts" }, [
    _c(
      "svg",
      {
        staticClass:
          "membership-card-bg-parts__image membership-card-bg-parts__image--pc",
        attrs: {
          width: "800",
          height: "529",
          viewBox: "0 0 800 529",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c(
          "g",
          { attrs: { "clip-path": "url(#membership-card-bg-gradient--pc)" } },
          [
            _c("rect", {
              attrs: {
                opacity: "0.4",
                width: "268.792",
                height: "828.667",
                transform:
                  "matrix(0.866053 -0.499953 0.500047 0.865998 -123.421 -67.2583)",
                fill: "black",
              },
            }),
            _c("rect", {
              attrs: {
                width: "290.124",
                height: "975.84",
                transform:
                  "matrix(-0.866053 -0.499953 -0.500047 0.865998 872.212 -152.097)",
                fill: _vm.membersColor.color,
                opacity: _vm.membersColor.opacity,
              },
            }),
            _c("rect", {
              attrs: {
                x: "-1390",
                y: "241",
                width: "3580",
                height: "184",
                fill: "url(#membership-card-bg-gradient--pc)",
              },
            }),
            _c("rect", {
              attrs: {
                x: "-1390",
                y: "425",
                width: "3580",
                height: "394",
                fill: "#2B2B2B",
              },
            }),
          ]
        ),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "membership-card-bg-gradient--pc",
                  x1: "400",
                  y1: "241",
                  x2: "400",
                  y2: "425",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", {
                  attrs: { "stop-color": "#2B2B2B", "stop-opacity": "0" },
                }),
                _c("stop", { attrs: { offset: "1", "stop-color": "#2B2B2B" } }),
              ],
              1
            ),
            _c("clipPath", { attrs: { id: "clip0_218_4158" } }, [
              _c("rect", {
                attrs: { width: "800", height: "529", fill: "white" },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "svg",
      {
        staticClass:
          "membership-card-bg-parts__image membership-card-bg-parts__image--sp",
        attrs: {
          width: "375",
          height: "658",
          viewBox: "0 0 375 658",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c(
          "g",
          { attrs: { "clip-path": "url(#membership-card-bg-gradient--sp)" } },
          [
            _c("rect", {
              attrs: { width: "375", height: "658", fill: "#393939" },
            }),
            _c("rect", {
              attrs: {
                opacity: "0.4",
                x: "-57.8535",
                y: "-31.5352",
                width: "126",
                height: "388.474",
                transform: "rotate(-30 -57.8535 -31.5352)",
                fill: "black",
              },
            }),
            _c("rect", {
              attrs: {
                width: "136",
                height: "457.468",
                transform: "matrix(-0.866025 -0.5 -0.5 0.866025 408.514 -67)",
                fill: _vm.membersColor.color,
                opacity: _vm.membersColor.opacity,
              },
            }),
            _c("rect", {
              attrs: {
                x: "-695",
                y: "80",
                width: "1765",
                height: "184",
                fill: "url(#membership-card-bg-gradient--sp)",
              },
            }),
            _c("rect", {
              attrs: {
                x: "-695",
                y: "264",
                width: "1765",
                height: "394",
                fill: "#2B2B2B",
              },
            }),
          ]
        ),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "membership-card-bg-gradient--sp",
                  x1: "187.5",
                  y1: "80",
                  x2: "187.5",
                  y2: "264",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", {
                  attrs: { "stop-color": "#2B2B2B", "stop-opacity": "0" },
                }),
                _c("stop", { attrs: { offset: "1", "stop-color": "#2B2B2B" } }),
              ],
              1
            ),
            _c("clipPath", { attrs: { id: "clip0_219_1727" } }, [
              _c("rect", {
                attrs: { width: "375", height: "658", fill: "white" },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }