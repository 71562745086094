











import { defineComponent, onMounted } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import ErrorPagePane from '@/components/ErrorPage/ErrorPagePane.vue'
import i18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'

export default defineComponent({
  name: 'ErrorPage',
  components: {
    ErrorPagePane,
    HeaderPane,
  },
  setup() {
    const errorStore = StoreUtil.useStore('ErrorStore')
    onMounted(async () => {
      console.warn()
    })
    return {
      i18n,
      title: errorStore.title,
      message: errorStore.message,
      descriptions: errorStore.descriptions,
    }
  },
})
