import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { PlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { ContractTermStringType } from '@/store/stores/collectionModule/documents/GeneralTypes'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * マイページご契約内容確認画面 利用期間の機能を提供する。
 */
export default function usePeriodOfUse() {
  const { getDisplayDate, getDisplayDateBasedDayjs } = useDisplayDependingOnLang()

  /**
   * 請求対象の利用期間を取得
   * @param planGroupId プラングループID
   * @param billingStartDate 請求開始日時
   * @param billingEndDate 請求終了日時
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'MM月DD日'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM DD'）
   * @param splitReturn starDate、endDateを分けて返すかどうか
   */
  const getPeriodOfUseForTargetBilling = (
    planGroupId: PlanGroupType | undefined,
    billingStartDate: number | null | undefined,
    billingEndDate: number | null | undefined,
    formatJa = 'MM/DD',
    formatEn = 'MMM DD',
    splitReturn = false,
  ): string | ContractTermStringType => {
    if (!billingStartDate || !billingStartDate) {
      return ''
    }

    if (planGroupId === 'monthlyPlan') {
      /**
       * 請求対象のプランが月額プランの場合の処理
       */

      // 請求範囲の開始日時(UTC) UnixTime(ミリ秒)
      const startDateUtc = dayjs.tz(billingStartDate)
      // 請求範囲の終了日時(UTC) UnixTime(ミリ秒)
      const endDateUtc = dayjs.tz(billingEndDate)

      /**
       * 利用期間開始日
       * 2ヶ月目以降の請求情報の場合、請求範囲の開始日時(UTC)が1日の00:00:000になるので、利用開始日に「1日(UTC)」を表示する
       */
      const startDateOfUse =
        startDateUtc.date() === 1 && startDateUtc.format('HH:mm:sss') === '00:00:000'
          ? getDisplayDateBasedDayjs(startDateUtc, formatJa, formatEn)
          : getDisplayDate(billingStartDate, formatJa, formatEn)
      /**
       * 利用期間終了日
       * 月額プランを継続している請求情報の場合、請求範囲の開始日時(UTC)が月末の23:59:5959になるので、利用終了日に「月末(UTC)」を表示する
       */
      const endDateOfUse =
        endDateUtc.date() === endDateUtc.endOf('month').date() &&
        endDateUtc.format('HH:mm:sss') === '23:59:5959'
          ? getDisplayDateBasedDayjs(endDateUtc, formatJa, formatEn)
          : getDisplayDate(billingEndDate, formatJa, formatEn)

      if (splitReturn) {
        // NOTE: starDate、endDateを分けて受け取れるようにしたかったため、splitReturnで制御できるようにした
        return {
          contractStartDate: startDateOfUse,
          contractEndDate: endDateOfUse,
        }
      }
      return startDateOfUse && endDateOfUse ? `${startDateOfUse}〜${endDateOfUse}` : ''
    }

    /**
     * 請求対象のプランが年額プランの場合の処理
     * 請求範囲の開始日時/請求範囲の終了日時を返す
     */
    if (splitReturn) {
      // NOTE: starDate、endDateを分けて受け取れるようにしたかったため、splitReturnで制御できるようにした
      return {
        contractStartDate: getDisplayDate(billingStartDate),
        contractEndDate: getDisplayDate(billingEndDate),
      }
    }
    return billingStartDate && billingEndDate
      ? `${getDisplayDate(billingStartDate)}〜${getDisplayDate(billingEndDate)}`
      : ''
  }

  return {
    getPeriodOfUseForTargetBilling,
  }
}
