import { computed, Ref, ref } from '@vue/composition-api'
import { orderBy } from 'lodash'
import useVideoPlatformContents from '@/store/hook/useVideoPlatformContents'
import VideoPlatformContentsDocument from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import useHighlightData from '@/store/hook/useHighlightData'
import usePermission from '@/components/hook/usePermission'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

export type RacePointVideoCardDataType = {
  thumbnail: string
  catch: Multilingual
  text: Multilingual
  to: string
  canShow: boolean
}

type RacePointVideoProcessingDataType = {
  racePointVideo: VideoPlatformContentsDocument
  raceData: RaceDocument
}
/**
 * レースのInformation画面のRace Point Video機能を提供する。
 */
export default function useRacePointVideo() {
  const { fetchHighlightsByRaceIds } = useHighlightData()
  const { fetchVideoPlatformContents, videoPlatformContentsList } = useVideoPlatformContents()
  const { canShowRace, getTargetRacePermission } = usePermission()

  /**
   * Race Point Videoのデータ一覧
   */
  const racePointVideoList = computed(() =>
    orderBy(videoPlatformContentsList.value, '_createdDate', 'desc').filter(
      (v) => v.videoPlatform === 'SFgo' && v.videoType === 'video',
    ),
  )

  /**
   * 描画用のRace Point Videoのデータ一覧
   */
  const racePointVideoData = ref() as Ref<Array<RacePointVideoCardDataType>>

  /**
   * レース一覧画面で選択された大会のハイライト一覧
   */
  const currentHighlights = ref() as Ref<Array<HighlightDocument>>

  /**
   * ハイライト再生画面のリンクを作成
   * リンク構造: '/race-video/${レースのchampionshipMasterId}/${レースID}/${ハイライトID}'
   * @param race レース情報
   * @param highlightId ハイライトID（=userGameEventId）
   */
  const getRacePointVideoLink = (race: RaceDocument, highlightId: string | null) =>
    `/race-video/${race.championshipMasterId}/${race.id}/${highlightId}?playType=highlight`

  /**
   * フロント側で表示可能なデータに変換（以下で使用している）
   * @/components/RaceListPage/RaceListPane/parts/CommonRacePointsCardParts.vue
   */
  const convertRacePointCardData = (item: RacePointVideoProcessingDataType) => {
    /**
     * ログインユーザーが対象レースの動画再生画面を見れるかどうか判定
     */
    const canShowRaceMovie = canShowRace(
      item.raceData,
      getTargetRacePermission(item.raceData.raceType),
    )
    return {
      thumbnail: CloudFrontUtil.getSignedUrl(item.racePointVideo.videoThumbnailPath),
      catch: item.racePointVideo.title,
      text: {
        ja: item.racePointVideo.additionalData?.description?.ja ?? '',
        en: item.racePointVideo.additionalData?.description?.en ?? '',
      },
      to: getRacePointVideoLink(item.raceData, item.racePointVideo.videoId),
      canShow: canShowRaceMovie,
    } as RacePointVideoCardDataType
  }

  /**
   * Race Point Videoデータを取得する
   * @param targetRaces 対象レース情報の一覧
   */
  const getTargetRacePointVideoList = (targetRaces: RaceDocument[]) => {
    const results = [] as Array<RacePointVideoProcessingDataType>
    racePointVideoList.value.forEach((v) => {
      const highlightResult = currentHighlights.value.find(
        (highlight) => highlight.userGameEventId === v.videoId,
      )
      if (highlightResult) {
        // ハイライトの重複をチェック
        if (results.find((r) => r.racePointVideo.videoId === highlightResult.userGameEventId)) {
          return
        }
        const raceResult = targetRaces.find((r) => r && r.id === highlightResult.matchId)
        if (raceResult) {
          results.push({
            racePointVideo: v,
            raceData: raceResult,
          })
        }
      }
    })
    return results
  }

  /**
   * 指定した大会のRace Point Videoデータを取得する。
   * @param mainRace 決勝のレース情報
   * @param sessionsWithOutRaces フリー走行/予選のレース情報
   */
  const fetchRacePointVideoData = async (
    mainRace: RaceDocument,
    sessionsWithOutRaces: Array<RaceDocument>,
  ) => {
    // Race Point Videoコンテンツを取得
    await fetchVideoPlatformContents()

    const results = [] as Array<RacePointVideoCardDataType>
    // レース情報一覧
    const targetRaces = [mainRace, ...sessionsWithOutRaces]
    const targetRacesIds = targetRaces.filter((r) => r && r.id !== null).map((v) => v.id as string)
    // raceIdの配列に一致するハイライト情報一覧を取得
    if (targetRacesIds.length > 0) {
      currentHighlights.value = (await fetchHighlightsByRaceIds(targetRacesIds)).data
      // ハイライトにマッチするRace Point Videoデータ一覧を取得
      const targetContents = getTargetRacePointVideoList(targetRaces)
      if (targetContents.length > 0) {
        // 描画可能な形式にデータを変換
        targetContents.forEach((item) => {
          results.push(convertRacePointCardData(item))
        })
      }
    }
    racePointVideoData.value = results
  }
  return {
    racePointVideoData,
    fetchRacePointVideoData,
  }
}
