




import { defineComponent } from '@vue/composition-api'
import AppMypageOneTimePassInputPage from '@/views/MypagePage/MypageOneTimePassInputPage.vue'

/**
 * ブラウザ版: マイページ: ワンタイムパス: 入力ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassInputPage',
  components: {
    AppMypageOneTimePassInputPage,
  },
})
