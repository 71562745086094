var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-header-section" }, [
    _c("div", { staticClass: "highlight-header-section__inner" }, [
      !_vm.screenLandScape || _vm.lockOrientation !== undefined
        ? _c("h3", { staticClass: "highlight-header-section__title" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("RaceVideoPage.highlights.title")) + " "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "highlight-header-section__filter" },
        [
          _c("filter-selector-parts", {
            staticClass: "highlight-header-section__filter__item",
            attrs: {
              defaultItemLabel: "Driver",
              defaultListItemLabel: "Driver (all)",
              filterList: _vm.driverFilterList,
            },
            on: {
              filterEnabled: _vm.handleEnabledFilterSelect,
              filterChange: _vm.onChangeDriverFilter,
            },
          }),
          _c("filter-selector-parts", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedHighlightTab === "official",
                expression: "selectedHighlightTab === 'official'",
              },
            ],
            staticClass: "highlight-header-section__filter__item",
            attrs: {
              defaultItemLabel: "Scene",
              defaultListItemLabel: "Scene (all)",
              filterList: _vm.sceneFilterList,
            },
            on: {
              filterEnabled: _vm.handleEnabledFilterSelect,
              filterChange: _vm.onChangeSceneOrUserFilter,
            },
          }),
          _c("filter-selector-parts", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedHighlightTab === "user",
                expression: "selectedHighlightTab === 'user'",
              },
            ],
            staticClass: "highlight-header-section__filter__item",
            attrs: {
              defaultItemLabel: "User",
              defaultListItemLabel: "User (all)",
              filterList: _vm.userFilterList,
            },
            on: {
              filterEnabled: _vm.handleEnabledFilterSelect,
              filterChange: _vm.onChangeSceneOrUserFilter,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }