/**
 * SFgoアプリの環境設定値を取得するための処理を提供する。
 */
export default class Env {
  /**
   * モックモードかどうかを判定する。
   * @return モックモードの場合、 true を返す。
   */
  static get isMock() {
    return process.env.VUE_APP_MOCK_MODE === 'true'
  }
}
