import StoreUtil from '@/store/StoreUtil'
import { MissionCodeType } from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import I18n from '@/locales/I18n'
import useMissionProgressDesign from '@/components/MissionPage/hook/useMissionProgressDesign'

/**
 * ミッション画面の 有料プランミッション達成状況機能を提供する。
 * 年額/月額プランミッションはミッションマスタで管理していないため、ユーザーポイント管理APIで取得する情報を使って達成状況を表現する
 */
export default function usePaidMemberMissionProgressData() {
  // store
  const mypagePageStore = StoreUtil.useStore('MypagePageStore')
  const { annualPaidMemberMissionPoint, monthlyPaidMemberMissionData } = mypagePageStore
  // hook
  const { annualPaidMember, monthlyPaidMember } = useMissionProgressDesign()

  /**
   * 年額プラン登録/継続ミッション達成状況カードを返す
   */
  const getAnnualPaidMissionProgress = (year: number) => {
    const annualPaidMemberEarnedPoint = annualPaidMemberMissionPoint(year)

    return {
      // ミッションコード
      missionCode: 'ANNUAL_PAID_MEMBER' as MissionCodeType,
      // ミッション名
      missionName: I18n.tc('MissionPage.paidMember.annualPlan.missionName'),
      // ミッション説明
      description: I18n.tc('MissionPage.paidMember.annualPlan.description'),
      // 達成方法
      achievementMethod: {
        title: I18n.tc('MissionPage.paidMember.annualPlan.achievementMethod.title'),
        text: I18n.tc('MissionPage.paidMember.annualPlan.achievementMethod.text'),
        infoLink: '',
        note: I18n.tc('MissionPage.paidMember.annualPlan.achievementMethod.note'),
      },
      // 達成条件
      achievementCondition: [
        {
          operationCount: 1,
          point: 500,
          pointCode: null,
          isAchieved: !!annualPaidMemberEarnedPoint, // 1回達成したらtrue
          // ハニカムの所に表示するミッション名
          missionNameOnCheckList: I18n.tc(
            'MissionPage.paidMember.annualPlan.missionNameOnCheckList',
          ),
        },
      ],
      // 次のポイント獲得までの残数
      leftCountUntilNextAchievement: null,
      // ミッション達成回数
      achievedMissionsCount: null,
      // 獲得済みポイント
      earnedPoints: annualPaidMemberEarnedPoint ?? 0,
      // コンプリート済みのミッションかどうか
      hasCompleted: !!annualPaidMemberEarnedPoint,
      /** デザイン系 */
      // ミッショングループコード
      missionGroupCode: annualPaidMember.missionGroupCode,
      // ミッション運達成状況カードの色
      colorType: annualPaidMember.colorType,
      // ヘッダーアイコン
      headerIcon: annualPaidMember.headerIcon,
      // 達成状況のテキスト表示タイプ
      achievementCountDispType: annualPaidMember.achievementCountDispType,
      // ハニカムをどのように表示するか
      honeycombType: annualPaidMember.honeycombType,
      // 表示順
      order: annualPaidMember.order,
    }
  }

  /**
   * 月額プラン登録/継続ミッション達成状況カードを返す
   */
  const getMonthlyPaidMissionProgress = (year: number) => {
    const monthlyPaidMemberMissionProgress = monthlyPaidMemberMissionData(year)

    return {
      // ミッションコード
      missionCode: 'MONTHLY_PAID_MEMBER' as MissionCodeType,
      // ミッション名
      missionName: I18n.tc('MissionPage.paidMember.monthlyPlan.missionName'),
      // ミッション説明
      description: I18n.tc('MissionPage.paidMember.monthlyPlan.description'),
      // 達成方法
      achievementMethod: {
        title: I18n.tc('MissionPage.paidMember.monthlyPlan.achievementMethod.title'),
        text: I18n.tc('MissionPage.paidMember.monthlyPlan.achievementMethod.text'),
        infoLink: '',
        note: I18n.tc('MissionPage.paidMember.monthlyPlan.achievementMethod.note'),
      },
      // 達成条件
      achievementCondition: null,
      // 次のポイント獲得までの残数（対象シーズンで何ヶ月継続しているかを表示）
      leftCountUntilNextAchievement: monthlyPaidMemberMissionProgress.contractMonths,
      // ミッション達成回数
      achievedMissionsCount: null,
      // 獲得済みポイント
      earnedPoints: monthlyPaidMemberMissionProgress.points ?? 0,
      // コンプリート済みのミッションかどうか
      hasCompleted: monthlyPaidMemberMissionProgress.achievementCounts === 12,
      /** デザイン系 */
      // ミッショングループコード
      missionGroupCode: monthlyPaidMember.missionGroupCode,
      // ミッション運達成状況カードの色
      colorType: monthlyPaidMember.colorType,
      // ヘッダーアイコン
      headerIcon: monthlyPaidMember.headerIcon,
      // 達成状況のテキスト表示タイプ
      achievementCountDispType: monthlyPaidMember.achievementCountDispType,
      // ハニカムをどのように表示するか
      honeycombType: monthlyPaidMember.honeycombType,
      // 表示順
      order: monthlyPaidMember.order,
    }
  }

  return {
    getAnnualPaidMissionProgress,
    getMonthlyPaidMissionProgress,
  }
}
