














import { computed, defineComponent } from '@vue/composition-api'

/**
 * リンクを表示するAtomコンポーネント。
 */
export default defineComponent({
  name: 'AtomLink',
  props: {
    /**
     * リンクの文字
     */
    linkText: {
      type: String,
      required: true,
    },
    /**
     * リンクしたいURL
     */
    url: {
      type: String,
      required: true,
    },
    /**
     * テキストデコレーション（アンダーライン）を表示するかどうか
     */
    disableTextDecoration: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * 新しいタブやウインドウでリンク先を開くかどうか（falseの場合、現在のウィンドウで開く）
     */
    isOpenAsBlank: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * リンクカラー
     */
    linkColor: {
      type: String,
      required: false,
      default: 'black',
    },
    /**
     * アイコン画像パス
     */
    imgPath: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * リンクの開く先を指定する。
     * @return string リンクを別画面で開くかどうか
     */
    const target = computed(() => (props.isOpenAsBlank ? '_blank' : '_self'))

    /**
     * リンクカラークラス指定
     */
    const colorClass = computed(() =>
      props.linkColor ? `atom-link--color-${props.linkColor}` : 'atom-link--color-black',
    )

    return {
      target,
      colorClass,
    }
  },
})
