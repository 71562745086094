var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ticket-today-confirm-modal-section" },
    [
      _c(
        "div",
        { staticClass: "mypage-ticket-today-confirm-modal-section__block" },
        [
          _c(
            "p",
            { staticClass: "mypage-ticket-today-confirm-modal-section__name" },
            [_vm._v(_vm._s(_vm.ticketType.ticketTypeName))]
          ),
          _c(
            "div",
            { staticClass: "mypage-ticket-today-confirm-modal-section__table" },
            [
              _c("mypage-ticket-today-table-parts", {
                attrs: {
                  ticketList: _vm.purchasedTickets,
                  ownOrganization: _vm.ownOrganization,
                  totalCount: _vm.getTodayTicketTotalCountByTicketType(
                    _vm.ticketType,
                    _vm.ownOrganization,
                    _vm.availableTodayProductNumbers
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "mypage-ticket-today-confirm-modal-section__alert" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$tc("MypagePage.MypageTicketTodayPage.confirmAlert")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "ul",
            {
              staticClass: "mypage-ticket-today-confirm-modal-section__buttons",
            },
            [
              _c(
                "li",
                {
                  staticClass:
                    "mypage-ticket-today-confirm-modal-section__button",
                },
                [
                  _c("atom-button", {
                    attrs: {
                      linkText: _vm.$tc(
                        "MypagePage.MypageTicketTodayPage.useTicket"
                      ),
                    },
                    on: { onClick: _vm.handleSubmitClicked },
                  }),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticClass:
                    "mypage-ticket-today-confirm-modal-section__button",
                },
                [
                  _c("atom-button", {
                    attrs: {
                      linkText: _vm.$tc("common.cancel"),
                      colorType: "tertiary",
                    },
                    on: { onClick: _vm.handleCancelClicked },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "mypage-ticket-today-confirm-modal-section__time" },
            [_c("mypage-ticket-today-current-time-parts")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }