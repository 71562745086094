















import { defineComponent, PropType } from '@vue/composition-api'
import InstagramPostItemContentsParts from '@/components/HomePage/HomePane/parts/InstagramPostItemContentsParts.vue'
import TwitterPostItemContentsParts from '@/components/HomePage/HomePane/parts/TwitterPostItemContentsParts.vue'
import { SnsPostData } from '@/store/stores/pageStore/HomePage/HomePageStore'

/**
 * HOME SNS投稿コンテンツ(SNS分岐)
 */
export default defineComponent({
  name: 'SnsPostItemContentsParts',
  components: {
    InstagramPostItemContentsParts,
    TwitterPostItemContentsParts,
  },
  props: {
    snsPost: {
      type: Object as PropType<SnsPostData>,
      required: true,
    },
  },
  methods: {
    updateSnsContentsData(src: string) {
      this.$emit('updateSnsContentsData', src)
    },
  },
})
