





















import { defineComponent } from '@vue/composition-api'
import SnsContentTabsParts from '@/components/HomePage/HomePane/parts/SnsContentTabsParts.vue'
import SnsDriverSection from '@/components/HomePage/HomePane/SnsDriverSection.vue'
import SnsTeamSection from '@/components/HomePage/HomePane/SnsTeamSection.vue'
import SnsOfficialSection from '@/components/HomePage/HomePane/SnsOfficialSection.vue'
import { SnsTabsType } from '@/store/stores/pageStore/HomePage/HomePageStore'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * HOME SNSコンテンツセクション
 */
export default defineComponent({
  name: 'SnsContentSection',
  components: {
    SnsContentTabsParts,
    SnsDriverSection,
    SnsTeamSection,
    SnsOfficialSection,
  },
  setup() {
    const store = StoreUtil.useStore('HomePageStore')
    const isForPC = DeviceInfo.isForPC()

    const tabsChange = (type: SnsTabsType) => {
      store.currentSnsTab.value = type
    }

    return {
      snsContentType: store.currentSnsTab,
      tabsChange,
      isForPC,
    }
  },
})
