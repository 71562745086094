


















import { computed, defineComponent, inject, PropType, ref } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import StandingsPlayerDetailsSection, {
  StandingPlayerDetailsType,
} from '@/components/StandingsPage/StandingsPlayerDetailsPane/StandingsPlayerDetailsSection.vue'
import ConfirmModalSection from '@/components/common/modal/ConfirmModalSection.vue'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import StoreUtil from '@/store/StoreUtil'
import I18n from '@/locales/I18n'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import Const from '@/util/Const'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * Standings: プレイヤー詳細 コンテンツペイン
 */
export default defineComponent({
  name: 'StandingsPlayerDetailsPane',
  components: {
    StandingsPlayerDetailsSection,
    ConfirmModalSection,
  },
  inject: ['loading'],
  props: {
    /**
     * 選手データ
     */
    player: {
      type: Object as PropType<PlayerDocument>,
      required: true,
    },
  },
  setup(props) {
    const loading = inject('loading') as PluginApi

    const standingsPageStore = StoreUtil.useStore('StandingsPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const userStore = StoreUtil.useStore('UserStore')
    const { selectedYear, standingsListByYear, teams } = standingsPageStore
    const { fetchMypagePageData, favoritePlayer, saveFavoritePlayer } = mypagePageStore
    const { user } = userStore

    const userLang = user.value.lang || DeviceInfo.getLanguage()

    /** お気に入り選手のStandings情報を表示しているかどうか */
    const isFavoritePlayer = computed(
      () => favoritePlayer.value && props.player.sid === favoritePlayer.value.sid,
    )
    /** お気に入り登録確認モーダルを表示するか */
    const isOpenConfirmAddFavoriteModal = ref(false)

    /** 表示対象のStandingsデータ */
    const targetStandingsData = standingsListByYear.value[selectedYear.value]?.standings
    const targetPlayerStanding = targetStandingsData?.find((standing) =>
      standing.playerShortName
        ? props.player.getDisplayCarNo() === standing.carNo &&
          props.player.playerShortName.en === standing.playerShortName
        : props.player.getDisplayCarNo() === standing.carNo,
    )

    /**
     * プロフィールURLがない場合の遷移先
     * 選手紹介（ドライバー一覧）画面に遷移させる
     */
    const noProfilePath =
      userLang === 'ja'
        ? Const.EXTERNAL_LINKS.DRIVER_TAXONOMY.JA
        : Const.EXTERNAL_LINKS.DRIVER_TAXONOMY.EN

    /** 所属チーム名 */
    const teamName = teams.value.find((team) => team.sid === props.player.additionalData?.teamId)
      ?.teamName[I18n.locale]

    const playerData: StandingPlayerDetailsType = {
      headerImgPath: CloudFrontUtil.getSignedUrl(
        props.player.additionalData?.standings?.headerImagePath,
      ),
      playerImgPath: CloudFrontUtil.getSignedUrl(
        props.player.additionalData?.standings?.playerImagePath,
      ),
      number: props.player.getDisplayCarNo(),
      name: props.player.getPlayerName()[I18n.locale] ?? '',
      team: teamName ?? '',
      profileLink: props.player.additionalData?.profilePath ?? noProfilePath,
      totalPosition: targetPlayerStanding?.rank ?? 0,
      totalPoints: targetPlayerStanding?.totalPts ?? 0,
    }

    /**
     * お気に入り選手登録確認モーダル表示
     */
    const openConfirmAddFavoriteModal = () => {
      if (!isFavoritePlayer.value) {
        isOpenConfirmAddFavoriteModal.value = true
      }
    }

    /**
     * お気に入り選手登録処理
     */
    const addFavorite = async () => {
      const loader = loading.show()
      const result = await saveFavoritePlayer(props.player.sid)
      if (!result.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageFavoritePlayerSelectPage.errors.title'),
          message: I18n.tc('MypagePage.MypageFavoritePlayerSelectPage.errors.message'),
          errorApiResponse: result.response,
        })

        isOpenConfirmAddFavoriteModal.value = false
        return
      }
      // お気に入り選手変更後のマイページの情報を取得する
      await fetchMypagePageData()
      loader.hide()

      isOpenConfirmAddFavoriteModal.value = false
    }

    return {
      isOpenConfirmAddFavoriteModal,
      playerData,
      isFavoritePlayer,
      // メソッド
      openConfirmAddFavoriteModal,
      addFavorite,
    }
  },
})
