














import { defineComponent, Ref, watch } from '@vue/composition-api'
import PlanSelectForPaidPane from '@/components/SignupPage/PlanSelectForPaidPane.vue'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import { PaidPlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * 有料会員変更 入力ページ
 */
export default defineComponent({
  name: 'PaidMembershipChangeEditPage',
  components: {
    PlanSelectForPaidPane,
  },
  setup() {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { memberType, selectedPlanGroupId, fetchContractPlans, coupons, clearSignupPageData } =
      signupPageStore

    // ストアの会員登録タイプを有料プランにする
    memberType.value = Const.MEMBER_TYPE.PAID

    const fetch = async () => {
      /** SFgo組織の契約プラン情報を取得する（ログイン状態であることが前提） */
      await fetchContractPlans()
    }
    fetch()

    /**
     * 無料プランから有料プランにアップグレードする画面では戻るボタンが常に有効
     */
    const enabledLeaveButton = true

    /**
     * プラン選択
     * @param plan {PaidPlanGroupType}
     */
    const changePlan = (plan: PaidPlanGroupType) => {
      selectedPlanGroupId.value = plan
    }

    /**
     * クーポンプラン選択中に別のクーポンコードを入力し、新たに入力したクーポンコードでクーポンが見つからなかった場合、年払いプランを選択させる
     */
    watch(
      () => coupons.value,
      (couponData) => {
        if (selectedPlanGroupId.value === 'limitedTimePaidPlan' && couponData.length === 0) {
          selectedPlanGroupId.value = 'annualPlan'
        }
      },
    )

    return {
      Const,
      memberType,
      selectedPlanGroupId: selectedPlanGroupId as Ref<PaidPlanGroupType>,
      clearSignupPageData,
      enabledLeaveButton,
      changePlan,
    }
  },
  methods: {
    /**
     * 確認画面へ遷移
     */
    moveToPaidMembershipChangeConfirm() {
      this.$router.replace({ name: 'PaidMembershipChangeConfirmPage' })
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      // signupPageStoreのデータをクリアする
      this.clearSignupPageData()

      const { transitionFromMobileApp } = LocalStorageAccessor
      if (transitionFromMobileApp) {
        // アプリから遷移した場合、マイページのURLを開く。AppLinkの機能で、SFgoアプリが起動する
        window.open(`${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`)
      } else {
        this.$router.replace({ name: 'MypageTopPage' })
      }
    },
  },
})
