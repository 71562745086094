












import { computed, defineComponent, PropType } from '@vue/composition-api'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'

/**
 * ミッション画面 ミッション達成状況 獲得済みポイントパーツ
 */
export default defineComponent({
  name: 'MissionGetPointParts',
  props: {
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
  },
  setup(props) {
    const isDark = computed(
      () =>
        props.mission.honeycombType === 'check' &&
        props.mission.achievementCondition &&
        props.mission.achievementCondition.length % 2 === 0,
    )
    return {
      isDark,
    }
  },
})
