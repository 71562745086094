var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paid-plan-change-edit-pane" },
    [
      _vm.currentPlan
        ? _c("current-plan-card-section", {
            attrs: {
              sectionTitle: _vm.$tc("MypagePage.plan.currentPlan"),
              plan: _vm.currentPlan,
              contractTerm: _vm.contractTerm,
              isConfirmed: true,
            },
          })
        : _vm._e(),
      _c("choose-other-plan-section", {
        staticClass: "paid-plan-change-edit-pane__choose-plan",
        attrs: {
          selectedPlanGroupId: _vm.selectedPlanGroupId,
          otherPlanList: _vm.otherPlanList,
          futurePlan: _vm.futurePlan,
          contractTerm: _vm.contractTerm,
          scheduledContractTerm: _vm.scheduledContractTerm,
          procedureScreen: "paidPlanChange",
        },
        on: { handlerPlanSelect: _vm.handlerPlanSelect },
      }),
      _c("comparison-plan-section", {
        staticClass: "paid-plan-change-edit-pane__comparison",
        attrs: {
          "section-title": _vm.$tc(
            "SignupPage.SelectPlanPage.comparison.comparisonDescriptionForPaid"
          ),
        },
      }),
      _c(
        "action-buttons-section",
        {
          staticClass: "paid-plan-change-edit-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".paid-plan-change-edit-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.confirm"),
              isDisabled: _vm.disabledConfirmBtn,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }