










import { defineComponent, PropType } from '@vue/composition-api'
import { RankingType } from '@/store/stores/pageStore/MissionPage/MissionPageStore'

export default defineComponent({
  name: 'MissionRankingTitleParts',
  props: {
    title: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
    rankingType: {
      type: String as PropType<RankingType>,
      default: 'TOTAL',
    },
  },
})
