var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlight-comment-trigger-parts",
      on: { click: _vm.onClick },
    },
    [
      _c("atom-input-checkbox-parts", {
        staticClass: "highlight-comment-trigger-parts__checkbox",
        attrs: { value: _vm.triggerValue },
      }),
      _c("label", {
        staticClass: "highlight-comment-trigger-parts__label",
        domProps: {
          textContent: _vm._s(
            _vm.$tc("HomePage.snsHighlightCommentTriggerLabel")
          ),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }