




































import { computed, defineComponent, ref } from '@vue/composition-api'
import twitterText from 'twitter-text'
import AtomTextArea from '@/components/atoms/input/AtomTextArea.vue'
import HighlightsSubmitIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsSubmitIcon.vue'
import HighlightsCancelIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsCancelIcon.vue'
import CommentCountParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentCountParts.vue'
import Const from '@/util/Const'

/**
 * レース動画再生画面 ハイライト 投稿コメント編集 パーツコンポーネント
 */
export default defineComponent({
  name: 'CommentEditorParts',
  components: {
    CommentCountParts,
    HighlightsCancelIcon,
    HighlightsSubmitIcon,
    AtomTextArea,
  },
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * コメントメッセージ
     */
    message: {
      type: String as () => string | null,
      default: '',
    },
  },
  setup() {
    // 編集キャンセル確認用モーダル
    const enabledConfirmModal = ref<boolean>(false)
    const messageText = ref('')

    /**
     * コメントの文字数
     */
    const messageLength = computed(() => twitterText.parseTweet(messageText.value).weightedLength)

    /**
     * コメントが文字数オーバーの場合、サブミットボタンをdisabledにする
     */
    const isSubmitDisabled = computed(
      () => messageLength.value > Const.MAX_HIGHLIGHT_COMMENT_LENGTH,
    )

    /**
     * コメントが空かどうか
     * スペースのみ、改行のみを空と扱う
     */
    const isCommentEmpty = computed(() => !messageText.value.replace(/\s+/g, ''))

    return {
      enabledConfirmModal,
      messageText,
      messageLength,
      isSubmitDisabled,
      isCommentEmpty,
    }
  },
  mounted() {
    this.messageText = JSON.parse(JSON.stringify(this.message))
  },
  methods: {
    emitChancel() {
      this.$emit('handlerCancel')
    },
    emitPost() {
      // 入力状態でコメントがある場合
      if (!this.isCommentEmpty && !this.isSubmitDisabled) {
        this.$emit('handlerSave', this.messageText)
      }
    },
  },
})
