var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choose-other-plan-section" },
    [
      !_vm.futurePlan
        ? [
            _c("h4", { staticClass: "choose-other-plan-section__title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$tc("PaidPlanChange.EditPage.selectPaidPlan")) +
                  " "
              ),
            ]),
            _c(
              "toggle-radio-card-list-section",
              _vm._l(_vm.otherPlanList, function (plan, index) {
                return _c(
                  "toggle-radio-card-parts",
                  {
                    key: "planToggleCard" + index,
                    attrs: {
                      radioValue: plan.planGroupId,
                      currentValue: _vm.selectedPlanGroupId,
                    },
                    on: { handlerOnClick: _vm.handlerPlanSelect },
                  },
                  [
                    _c("plan-card-parts", {
                      attrs: {
                        plan: plan,
                        bargainPriced: _vm.bargainPriced(plan),
                        procedureScreen: _vm.procedureScreen,
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
            _c("ul", {
              staticClass: "choose-other-plan-section__attention",
              domProps: { innerHTML: _vm._s(_vm.attention) },
            }),
          ]
        : [
            _c("current-plan-card-section", {
              attrs: {
                sectionTitle: _vm.futurePlanTitle,
                plan: _vm.futurePlan,
                contractTerm: _vm.scheduledContractTerm,
                isConfirmed: true,
              },
            }),
            _c("ul", {
              staticClass:
                "choose-other-plan-section__attention choose-other-plan-section__future",
              domProps: { innerHTML: _vm._s(_vm.attention) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }