




































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import AtomButton from '@/components/atoms/AtomButton.vue'
import HighlightsExternalLinkIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsExternalLinkIcon.vue'
import HighlightsCancelIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsCancelIcon.vue'
import HighlightsDownloadIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsDownloadIcon.vue'
import SceneMoviesDocument from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

export default defineComponent({
  name: 'HighlightSnsPostMenuParts',
  props: {
    /**
     * 動画集
     */
    sceneMovies: {
      type: Object as PropType<SceneMoviesDocument | null>,
      default: () => ({}),
    },
    /**
     * ハイライトの切り抜きが可能かどうか
     */
    canClipTargetOperationHighlightForPc: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HighlightsDownloadIcon,
    HighlightsCancelIcon,
    HighlightsExternalLinkIcon,
    AtomButton,
  },
  setup(props, context) {
    // XのURL
    const xUrl = Const.EXTERNAL_LINKS.X.HOME
    // InstagramのURL
    const instagramUrl = Const.EXTERNAL_LINKS.INSTAGRAM.HOME

    // ダウンロード準備ボタンを表示するかどうか
    const displayDownloadPreparationButton = computed(
      () =>
        !(
          props.sceneMovies &&
          (props.sceneMovies.status === 'InProgress' || props.sceneMovies.status === 'Complete')
        ),
    )
    // ハイライト動画切り抜き：ダウンロード準備中かどうか
    const isInProgress = computed(() => props.sceneMovies?.status === 'InProgress')
    // ハイライト動画切り抜き：ダウンロード準備が完了しているかどうか
    const isComplete = computed(() => props.sceneMovies?.status === 'Complete')
    // ハイライト動画切り抜き：エラーかどうか
    const isError = computed(() => props.sceneMovies?.status === 'Error')

    /** ダウンロードメッセージ */
    const downloadMessage = computed(() =>
      props.canClipTargetOperationHighlightForPc
        ? I18n.tc('RaceVideoPage.highlights.sns.xAndInstagram.messageCanDownload')
        : I18n.tc('RaceVideoPage.highlights.sns.xAndInstagram.messageCannotDownload'),
    )

    /**
     * ダウンロード準備を開始する
     */
    const handleDownloadPreparationClicked = () => {
      context.emit('startDownloadPreparation')
    }
    /**
     * ダウンロード可能状態でユーザーがダウンロードボタンを押した際にファイルをダウンロードする
     */
    const handleDownloadClicked = async () => {
      context.emit('downloadHighlight', props.sceneMovies)
    }

    /**
     * SNS投稿ボタンをクリックした場合に呼び出される。
     * @param url 外部サイトのリンク
     */
    const onClickAnchorLink = (url: string) => {
      // 外部リンクを開く
      window.open(url, '_blank')
      context.emit('onSaveMissionHistory')
    }

    return {
      xUrl,
      instagramUrl,
      displayDownloadPreparationButton,
      isInProgress,
      isError,
      isComplete,
      downloadMessage,
      handleDownloadPreparationClicked,
      handleDownloadClicked,
      onClickAnchorLink,
    }
  },
})
