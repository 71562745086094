










import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import PaidPlanChangeConfirmPane from '@/components/pc/MyPage/PaidPlanChange/PaidPlanChangeConfirmPane.vue'

/**
 * プラン変更(有料=>有料) 確認ページ
 */
export default defineComponent({
  name: 'PaidPlanChangeConfirmPage',
  components: {
    SubHeaderSection,
    PaidPlanChangeConfirmPane,
  },
  methods: {
    /**
     * 登録完了ページに遷移する
     */
    moveToPaidPlanChangeComplete() {
      this.$router.replace({ name: 'PaidPlanChangeCompletePage' })
    },
    /**
     * プラン選択画面へ遷移する
     */
    handlerCancel() {
      this.$router.replace({ name: 'PaidPlanChangeEditPage' })
    },
  },
})
