




































import { computed, defineComponent, PropType } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import MissionCheckInIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCheckInIcon.vue'
import MissionAccordionIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionAccordionIcon.vue'
import MissionCheckedInIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCheckedInIcon.vue'
import MissionProgressDocument from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import DeviceInfo from '@/util/DeviceInfo'

type CheckInStatusType = 'inactive' | 'active' | 'checked'

export default defineComponent({
  name: 'MissionCheckInSection',
  components: { MissionCheckedInIcon, MissionAccordionIcon, MissionCheckInIcon },
  props: {
    /**
     * 開催中のラウンドチェックインまたはイベントチェックインミッション一覧
     */
    checkInMissionsBeingHeld: {
      type: Array as PropType<MissionProgressDocument[]>,
      required: true,
    },
    successfulCheckIn: {
      type: Boolean,
    },
    isFixed: {
      type: Boolean,
    },
  },
  setup(_props, { emit }) {
    const isPc = DeviceInfo.isForPC()
    /**
     * チェックインの状態を管理する
     */
    const checkInStatus = computed(() => {
      let status = 'inactive' as CheckInStatusType
      if (_props.successfulCheckIn) {
        status = 'checked'
      } else if (_props.checkInMissionsBeingHeld.length > 0) {
        status = 'active'
      }
      return status
    })
    /**
     * チェックインボタン下部のメッセージの表示内容
     */
    const message = computed(() => {
      switch (checkInStatus.value) {
        case 'checked':
          return I18n.t('MissionPage.checkIn.message.checked')
        case 'active':
          return I18n.t('MissionPage.checkIn.message.active')
        default:
          return I18n.t('MissionPage.checkIn.message.inactive')
      }
    })
    /**
     * チェックインボタンを押せるかどうか
     */
    const isDisabled = computed(
      () => checkInStatus.value === 'inactive' || checkInStatus.value === 'checked',
    )
    /**
     * 閉じるボタンが押下されたときの処理
     */
    const handleCloseClicked = () => {
      emit('onClickClose')
    }
    /**
     * チェックインボタンが押下されたときの処理
     */
    const handleCheckInClicked = () => {
      emit('onClickCheckIn')
    }
    return {
      isPc,
      isDisabled,
      checkInStatus,
      message,
      handleCloseClicked,
      handleCheckInClicked,
    }
  },
})
