









import { defineComponent, PropType } from '@vue/composition-api'
import FavoriteSelectorButtonParts from '@/components/HomePage/HomePane/parts/FavoriteSelectorButtonParts.vue'

export type SnsFavoriteTypeData = 'driver' | 'team'

/**
 * HOME SNS 表示選手/チームセクション
 */
export default defineComponent({
  name: 'SnsFavoriteSection',
  components: {
    FavoriteSelectorButtonParts,
  },
  props: {
    headingLabel: {
      type: String,
      default: '',
    },
    viewType: {
      type: String as PropType<SnsFavoriteTypeData>,
      default: 'driver',
    },
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
})
