















import { defineComponent } from '@vue/composition-api'

/**
 * マイページ: ご利用について データリスト パーツ
 */
export default defineComponent({
  name: 'AboutUsingDataListParts',
  props: {
    /**
     * データリスト
     */
    aboutUsingListItems: {
      type: Array,
      required: true,
    },
  },
})
