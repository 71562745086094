























import { defineComponent, computed } from '@vue/composition-api'
import ComparisonPlanTableParts from '@/components/common/MembershipPlan/ComparisonPlanTableParts.vue'
import AtomLink from '@/components/atoms/AtomLink.vue'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'

/**
 * 新規会員登録 プラン比較
 */
export default defineComponent({
  name: 'ComparisonPlanSection',
  components: {
    ComparisonPlanTableParts,
    AtomLink,
  },
  props: {
    /**
     * 表示するタイトル
     */
    sectionTitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    // SFgo公式サイトへのリンクを表示するかどうか
    const officialSFgoLinkVisible = computed(() => DeviceInfo.isAndroid())

    const officialSFgoLink =
      I18n.locale === 'ja' ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN

    // 使用端末がアプリかどうか
    const isForApp = computed(() => DeviceInfo.isiOS() || DeviceInfo.isAndroid())

    return {
      officialSFgoLinkVisible,
      officialSFgoLink,
      isForApp,
    }
  },
})
