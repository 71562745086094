var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "atom-btn",
      class: [
        "atom-btn--" + _vm.theme + "--" + _vm.colorType,
        "atom-btn--" + _vm.size,
      ],
      attrs: { disabled: _vm.isDisabled },
      on: { click: _vm.handleClicked },
    },
    [_c("span", [_vm._v(_vm._s(_vm.linkText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }