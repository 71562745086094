











import { defineComponent, onBeforeUnmount, PropType } from '@vue/composition-api'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import SignupCompleteSection from '@/components/SignupPage/SignupCompletePane/SignupCompleteSection.vue'
import { AttentionMessageData } from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import { PaymentScheduleData } from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'

/**
 * 新規会員登録: 会員登録完了ペインのコンポーネント
 */
export default defineComponent({
  name: 'SignupCompletePane',
  components: {
    SignupHeaderSection,
    SignupCompleteSection,
  },
  props: {
    /**
     * クーポンを使用してるか
     */
    isCouponUsed: {
      type: Boolean,
      default: false,
    },
    /**
     * 注意メッセージ
     */
    attentionMessage: {
      type: Object as PropType<AttentionMessageData>,
      default: null,
    },
    /**
     * 請求予定情報
     */
    paymentSchedule: {
      type: Array as PropType<PaymentScheduleData[]>,
      default: null,
    },
  },
  setup() {
    /**
     * 新規会員登録が完了した場合、契約情報更新漏れを防ぐため、完了画面を離れた時にリロードしてログアウト状態にする
     */
    onBeforeUnmount(() => {
      window.location.reload()
    })
  },
})
