






















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import ReferralCodePane from '@/components/MypageReferralCodePage/ReferralCodePane.vue'

/**
 * マイページ: 紹介コードページ
 */
export default defineComponent({
  name: 'MypageReferralCodePage',
  components: {
    AtomInputButton,
    SubHeaderSection,
    GlobalNavigationPane,
    ActionButtonsSection,
    ReferralCodePane,
  },
})
