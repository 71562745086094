import OAuth from 'oauth-1.0a'
import CryptoJS from 'crypto-js'
import HTTPClient, { HTTPMethodType, HTTPRequestDataType } from '@/util/http/HTTPClient'

/**
 * OAuth認可フローで取得したアクセストークン / 悪説トークンシークレット (OAuth Token / OAuth Token Secret)の型
 */
export type OAuthAccessToken = { accessToken: string; accessTokenSecret: string }

/**
 * OAuth 1.0a の認可フローを行うための処理を提供する。
 */
export default class OAuth1Client extends HTTPClient {
  /**
   * コンシューマキー
   */
  consumerKey: string

  /**
   * コンシューマシークレットキー
   */
  consumerSecretKey: string

  oauth: OAuth

  /**
   * 指定されたキーでクライアントを初期化する。
   *
   * @param consumerKey OAuth コンシューマキー
   * @param consumerSecretKey OAuth コンシューマシークレットキー
   */
  constructor(consumerKey: string, consumerSecretKey: string) {
    super()
    this.consumerKey = consumerKey
    this.consumerSecretKey = consumerSecretKey
    this.oauth = new OAuth({
      consumer: {
        key: consumerKey,
        secret: consumerSecretKey,
      },
      signature_method: 'HMAC-SHA1',
      hash_function: (baseString, key) =>
        CryptoJS.HmacSHA1(baseString, key).toString(CryptoJS.enc.Base64),
    })
  }

  /**
   * OAuth 1.0a のトークンを利用した通信を行う際に指定するための Authorization ヘッダーの値を生成する。
   *
   * @param url 接続先のURL
   * @param method HTTPメソッド
   * @param data リクエストボディ
   * @param oAuthAccessToken OAuth アクセルトークン / アクセストークンシークレット
   */
  public makeAuthorizationHeader(
    url: string,
    method: HTTPMethodType,
    data?: HTTPRequestDataType,
    oAuthAccessToken?: OAuthAccessToken,
  ) {
    let token

    if (oAuthAccessToken) {
      token = {
        key: oAuthAccessToken.accessToken,
        secret: oAuthAccessToken.accessTokenSecret,
      }
    }

    const authHeader = this.oauth.toHeader(
      this.oauth.authorize(
        {
          url,
          method: method.toUpperCase(),
          data,
        },
        token,
      ),
    )

    return authHeader.Authorization
  }
}
