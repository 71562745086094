


























import { computed, defineComponent } from '@vue/composition-api'
import dayjs from 'dayjs'
import MissionCheckInIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionCheckInIcon.vue'
import MissionLeftIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionLeftIcon.vue'
import MissionRightIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionRightIcon.vue'

export default defineComponent({
  name: 'MissionYearSelectorParts',
  components: { MissionRightIcon, MissionLeftIcon, MissionCheckInIcon },
  props: {
    /**
     * ミッション一覧画面で表示する年度
     */
    selectedMissionYear: {
      type: Number,
      required: true,
    },
    isFixed: {
      type: Boolean,
    },
    showCheckIn: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const showButton = computed(() => props.isFixed && !props.showCheckIn)
    const handleCheckInClicked = () => {
      emit('onClickCheckIn')
    }

    /**
     * 前年度のミッションを見る活性/非活性判定
     */
    const isDisabledPrev = computed(
      () =>
        // 2024年より前には戻れない
        props.selectedMissionYear < 2025,
    )

    /**
     * 次年度のミッションを見る活性/非活性判定
     */
    const isDisabledNext = computed(() => {
      const thisYear = new Date().getFullYear()
      if (thisYear - 1 > props.selectedMissionYear) {
        // 前々年度以前のミッションを見ている場合は次年度のミッショを見ることができる
        return false
      }
      if (thisYear > props.selectedMissionYear) {
        // 前年度のミッションを見ている場合、2/1 00:00:00(JST)以降であれば次年度のミッションを見ることができる
        return (
          dayjs.tz(`${thisYear}-02-01`, 'Asia/Tokyo').startOf('day').valueOf() > dayjs().valueOf()
        )
      }

      // 同年度のミッションを見ている場合、次年度のミッションを見ることができない
      return true
    })

    /**
     * ミッション一覧画面の年度を変更
     */
    const onChangeMissionYear = (changedYear: number) => {
      emit('onChangeMissionYear', changedYear)
    }

    return {
      showButton,
      handleCheckInClicked,
      isDisabledPrev,
      isDisabledNext,
      onChangeMissionYear,
    }
  },
})
