var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-top-pane" },
    [
      _c("mypage-links-section", {
        staticClass: "mypage-top-pane__links-section",
        attrs: {
          title: _vm.$tc("MypagePage.MypageTopPage.memberInformation"),
          links: _vm.memberInformationLinks,
        },
        on: { openModalClicked: _vm.handleOpenModalClicked },
      }),
      _c("mypage-favorite-player-link-section", {
        attrs: {
          name: _vm.favoritePlayer
            ? _vm.favoritePlayer.getPlayerName()[_vm.$i18n.locale] || ""
            : "",
          img: _vm.playerPictureUrl(_vm.favoritePlayer),
        },
      }),
      _c("mypage-favorite-team-link-section", {
        attrs: {
          name: _vm.favoriteTeam
            ? _vm.getTeamName(_vm.favoriteTeam)[_vm.$i18n.locale] || ""
            : "",
          img: _vm.teamLogoUrl(_vm.favoriteTeam),
        },
      }),
      _c("mypage-links-section", {
        attrs: {
          title: _vm.$tc("MypagePage.MypageTopPage.contractInformation"),
          links: _vm.contractLinks,
        },
      }),
      _c("mypage-links-section", {
        attrs: {
          title: _vm.$tc("MypagePage.MypageTopPage.other"),
          links: _vm.otherLinks,
        },
      }),
      _vm.isOpenReadOnlyModal
        ? _c("read-only-alert-modal-section", {
            attrs: { failedPaymentMethod: _vm.failedPaymentMethod },
            on: { close: _vm.hideReadOnlyModal },
          })
        : _vm._e(),
      _vm.isOpenPaymentPendingModal
        ? _c("payment-pending-alert-modal-section", {
            on: { close: _vm.hidePaymentPendingModal },
          })
        : _vm._e(),
      _vm.isOpenPaidPlanCompleteModal
        ? _c("mypage-paid-plan-complete-modal-section", {
            attrs: { title: _vm.paidPlanCompleteModalTitle },
            on: {
              onClickOk: _vm.handleCompletePaidPlanModalClose,
              close: _vm.handleCompletePaidPlanModalClose,
            },
          })
        : _vm._e(),
      _vm.isOpenChangePaidPlanModal
        ? _c("mypage-change-paid-plan-modal-section", {
            attrs: {
              paymentMethod: _vm.paymentMethod,
              modalId: _vm.state.paidPlanChangeModal.modalId,
              title: _vm.state.paidPlanChangeModal.title,
              description: _vm.state.paidPlanChangeModal.description,
              url: _vm.state.paidPlanChangeModal.url,
            },
            on: { close: _vm.handleChangePaidPlanModalClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }