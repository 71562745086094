





























import { defineComponent, PropType } from '@vue/composition-api'
import { PlayerRankData } from '@/components/RaceListPage/RaceListPane/parts/RankingCardParts.vue'
import RankingPlayerLinkParts from '@/components/RaceListPage/RaceListPane/parts/RankingPlayerLinkParts.vue'

/**
 * レース一覧画面: レース ランキング カードコンポーネント
 */
export default defineComponent({
  name: 'RaceRankingCardParts',
  components: {
    RankingPlayerLinkParts,
  },
  props: {
    rankingCardData: {
      type: Object as PropType<PlayerRankData>,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
