import { ref } from '@vue/composition-api'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual, SectorType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { Permission } from '@/store/stores/loginStore/PermissionType'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import { SectorNameMap } from '@/store/hook/useLiveTiming'

/**
 * 無線交信データを表現するクラス。
 * https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3277455361/API
 */
export default class RadioDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<RadioDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    // セクターの表示形式を変換する(例: SEC1->S1)
    this.sector = SectorNameMap[this.sector]
  }

  _path = 'data/record/radio'

  idAttr = 'audio_clip'

  /**
   * レースの日付
   */
  raceDate = ''

  /**
   * 車体番号
   */
  car_no = 0

  /**
   * ドライバーコード
   */
  driver_code = ''

  /**
   * クリップ生成時刻
   */
  clip_create_time = 0

  /**
   * クリップ取得開始時刻
   */
  clip_start_time = 0

  /**
   * クリップ秒数
   */
  duration = 0

  /**
   * 無音マージン（秒）
   */
  silent_margin = 0

  /**
   * 音声クリップ
   */
  audio_clip_origin = ''

  /**
   * 音声クリップ（RUN.EDGE側のAWS S3に保存されている音声ファイルのURL）
   */
  audio_clip = ''

  /**
   * 非公開フラグ
   */
  private = false

  /**
   * 作成日時
   */
  createdDate = 0

  /**
   * 更新日時
   */
  lastModifiedDate = 0

  /**
   * 受信日時
   */
  sendDate = 0

  /**
   * この無線交信データが生成された時のドライバーの周回数
   */
  lap = ''

  /**
   * この無線交信データが生成された時のドライバーのセクター位置の表示名
   */
  sector: SectorType = '--'

  /**
   * 選手名
   */
  playerName: Multilingual = {
    en: '',
    ja: '',
  }

  /**
   * 選手顔写真パス
   */
  playerPictureImagePath: string | null = null

  /**
   * 選手IDエイリアス
   */
  sid: string | null = null

  /**
   * 選手ID
   */
  playerId: string | null = null

  /**
   * 選手省略名
   */
  abbreviation: Multilingual = {
    en: '',
    ja: '',
  }

  /**
   * 無線交信データの再生位置
   */
  currentTime = 0

  /**
   * 再生中かどうか
   */
  isPlaying = false

  /**
   * 無線交信データの変化する可能性のある値を保持する状態オブジェクト。
   * 無線交信データ自体はリアクティブではないため、値の変化を監視したいものについてはこの状態オブジェクトに定義する。
   * ※ CollectionModule#data はshallowReactiveで作成されているため、配列のみリアクティブで、要素はリアクティブとならない。
   * @see CollectionModule
   */
  state = {
    /** 音声の再生位置 */
    currentTime: ref(0),
    /** 音声の再生状態 */
    isPlaying: ref(false),
    /**
     * レースタイプ
     * R: 決勝, B: フリー走行/予選
     */
    raceType: ref<'R' | 'B' | ''>(''),
  }

  /**
   * このデータを参照するために必要な権限
   */
  requiredPermissions: Array<Permission> = ['useRadio']

  /**
   * この無線交信データが、指定されたレースの開始、終了日時内で作成されたものかどうかを指定する。
   * @param race レース
   */
  isInRaceTimePeriod(race: RaceDocument | undefined) {
    const raceStartTime = Number(race?.startDate) ?? Number.MAX_VALUE
    const raceEndTime = Number(race?.endDate) ?? 0
    return this.createdDate > raceStartTime && this.createdDate < raceEndTime
  }

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `RadioDataDocument[raceDate: ${this.raceDate}, audio_clip: ${this.audio_clip}}, lap: ${this.lap}, sector: ${this.sector}]`
  }
}

export const createEmptyRadioData = () => new RadioDataDocument()
