var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-check-in-point-section" }, [
    _c(
      "div",
      { staticClass: "mission-check-in-point-section__check" },
      [_vm.checkPoint.checked ? _c("mission-check-icon") : _vm._e()],
      1
    ),
    _c("div", { staticClass: "mission-check-in-point-section__main" }, [
      _c("p", { staticClass: "mission-check-in-point-section__name" }, [
        _vm._v(_vm._s(_vm.checkPoint.areaName[_vm.$i18n.locale])),
      ]),
      _c("p", { staticClass: "mission-check-in-point-section__date" }, [
        _vm._v(_vm._s(_vm.checkPoint.eventCheckInMissionTerm)),
      ]),
    ]),
    _vm.checkPoint.googleMapUrl
      ? _c("p", { staticClass: "mission-check-in-point-section__map" }, [
          _c(
            "a",
            {
              staticClass: "mission-check-in-point-section__map-link",
              attrs: { href: _vm.checkPoint.googleMapUrl, target: "_blank" },
            },
            [_c("mission-check-point-map-icon")],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }