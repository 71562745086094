























import { defineComponent } from '@vue/composition-api'

/**
 * アイコン付きリンクボタン(to)を表示するAtomコンポーネント。
 */
export default defineComponent({
  name: 'AtomIconRouterButton',
  props: {
    /**
     * リンクの文字
     */
    linkText: {
      type: String,
      required: true,
    },
    /**
     * リンクしたいURL
     */
    to: {
      type: String,
      required: true,
    },
    /**
     * テーマカラー指定
     */
    theme: {
      type: String,
      default: 'light',
    },
    /**
     * カラー指定
     */
    colorType: {
      type: String,
      default: 'primary',
    },
    /**
     * サイズ指定
     */
    size: {
      type: String,
      default: 'large',
    },
    /**
     * アイコン画像パス
     */
    imgPath: {
      type: String,
      default: '',
    },
    /**
     * 非活性
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * 要素がクリックされたことを親に通知する
     */
    emitClick() {
      this.$emit('emitClick')
    },
  },
})
