
















import { defineComponent, PropType } from '@vue/composition-api'
import StandingsRoundDetailsSection, {
  StandingsRoundDataType,
} from '@/components/StandingsPage/StandingsRoundDataPane/StandingsRoundDetailsSection.vue'

/**
 * Standings: レース一覧 セクション
 */
export default defineComponent({
  name: 'StandingsRoundListSection',
  components: {
    StandingsRoundDetailsSection,
  },
  props: {
    roundsRecords: {
      type: Array as PropType<StandingsRoundDataType[]>,
    },
  },
})
