import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import LiveTimingDocument from '@/store/stores/collectionModule/documents/liveTiming/LiveTimingDocument'
import MathUtil from '@/util/MathUtil'
import useDynamoDB from '@/store/hook/useDynamoDB'
import { SectorType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * セクター定義とセクター表示名のマップ
 */
export const SectorNameMap: Record<string, SectorType> = {
  SEC1: 'S1',
  SEC2: 'S2',
  SEC3: 'S3',
  SEC4: 'S4',
  '': '--',
}

/**
 * 作成日時毎のライブタイミングデータの型。
 * 作成日時をキーにして、ライブタイミングデータを値として保持するマップオブジェクトの型。
 */
type LiveTimingHashedByCreatedDateType = Map<number, LiveTimingDocument>

/**
 * ライブタイミングデータを操作するための処理を提供する。
 */
export default function useLiveTiming() {
  const liveTimingCollectionModule = CollectionModule.createStore(LiveTimingDocument)

  /**
   * 作成日時毎のライブタイミングデータ
   */
  const liveTimingHashedByCreatedDateMap = new Map()

  const { searchDateRangeData } = useDynamoDB()

  /**
   * 指定したレースの指定した期間のライブタイミング情報を取得する。
   * @param race レース
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   * falseの場合、データを取得した場合に、それまで保持していたデータはクリアされる。
   * @see FetchOptions.isUnionExistData
   */
  const fetchLiveTiming = async (race: RaceDocument, fromDate: number, toDate: number) => {
    const result = await searchDateRangeData({
      tableName: 'live-timing-data',
      partitionKey: 'date',
      partitionKeyValue: dayjs(race.scheduleDate).utc().format('YYYY-MM-DD'),
      sortKey: 'createdDate',
      from: fromDate,
      to: toDate,
      attributeNames: { '#raceDate': 'date' },
    })
    liveTimingCollectionModule.data = result as Array<LiveTimingDocument>
  }

  /**
   * 取得したライブタイミング情報をクリアする
   */
  const clearLiveTiming = () => {
    liveTimingCollectionModule.clearData()
    liveTimingHashedByCreatedDateMap.clear()
  }

  /**
   * 取得したライブタイミング情報
   * 作成日時でハッシュされたライブタイミングデータを返す
   */
  const liveTimingHashedByCreatedDate = computed(() =>
    liveTimingCollectionModule.data.reduce<LiveTimingHashedByCreatedDateType>((map, liveTiming) => {
      if (liveTiming.createdDate) {
        const roundedDateTime = MathUtil.round(liveTiming.createdDate, 1000)
        const hashmap = map
        hashmap.set(roundedDateTime, liveTiming)
      }
      return map
    }, liveTimingHashedByCreatedDateMap),
  )

  /**
   * 指定された時間の範囲内に存在するライブタイミングデータを検索する。
   *
   * @param fromDate 開始時間(ミリ秒)
   * @param toDate 終了時間(ミリ秒)
   */
  const getLiveTimingDataByDateRange = (fromDate: number, toDate: number) =>
    liveTimingCollectionModule.data.find((liveTimingData) => {
      const liveTimingCreatedDate = liveTimingData.createdDate ?? 0
      return liveTimingCreatedDate > fromDate && liveTimingCreatedDate < toDate
    })

  /**
   * 対象の日時のデータの取得を実施しているかどうかを判定する.
   * @param createdDate 作成日時
   */
  const isLiveTimingFetched = (createdDate: number) =>
    liveTimingHashedByCreatedDate.value.has(MathUtil.round(createdDate, 1000))

  return {
    fetchLiveTiming,
    liveTimingHashedByCreatedDate,
    clearLiveTiming,
    isLiveTimingFetched,
    getLiveTimingDataByDateRange,
  }
}
