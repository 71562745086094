var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ticket-list-section" },
    [
      _vm.purchasedDigitalTickets && _vm.purchasedDigitalTickets.length > 0
        ? [
            _c(
              "ul",
              _vm._l(_vm.purchasedDigitalTickets, function (ticketEvent) {
                return _c(
                  "li",
                  {
                    key: ticketEvent.uniqueKey,
                    staticClass: "mypage-ticket-list-section__item",
                  },
                  [
                    _c("mypage-ticket-block-section", {
                      attrs: { ticketEvent: ticketEvent },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : [
            _c("p", {
              staticClass: "mypage-ticket-list-section__no-data",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$tc("MypagePage.MypageTicketListPage.noTickets")
                ),
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }