var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "common-race-points-card-parts",
    {
      staticClass: "race-points-radio-card-parts",
      attrs: {
        cardTitle: _vm.$tc("RaceListPage.Information.radioPoint"),
        pointsCardData: _vm.pointsCardData,
      },
    },
    [
      _vm.pointsCardData.raceRadioData
        ? _c(
            "div",
            { staticClass: "race-points-radio-card-parts__radio" },
            [
              _c("radio-list-item-parts", {
                attrs: { radioData: _vm.pointsCardData.raceRadioData },
                on: { radioPlay: _vm.playRadioAudio },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }