






import { defineComponent } from '@vue/composition-api'
import SmartBanner from 'smart-app-banner'
import 'smart-app-banner/dist/smart-app-banner.css'
import AppLoginPage from '@/views/LoginPage.vue'
import DeviceInfo from '@/util/DeviceInfo'

/* eslint-disable @typescript-eslint/no-var-requires */
const smartBannerIconImage = require('@/assets/img/icon/smartBanner/icon.png')

export type DataType = {
  loginType: string
}

/**
 * ログインページ
 */
export default defineComponent({
  name: 'LoginPage',
  components: {
    AppLoginPage,
  },
  head: {
    meta() {
      // PCブラウザ版のログイン画面をSFgoをモバイル端末で表示した際、Smart App Bannerを表示する
      // iOS safariは、apple-itunes-appを設置することで自動的に表示される
      // https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners
      // Androidの場合、その仕組みがないため、 https://github.com/kudago/smart-app-banner を利用する
      return this.showAppBanner
        ? [{ name: 'google-play-app', content: 'app-id=net.superformula.sfgo' }]
        : []
    },
    link: [{ rel: 'android-touch-icon', href: smartBannerIconImage }],
  },
  data(): DataType {
    return {
      /**
       * ログインタイプ指定
       */
      loginType: this.$route.params.loginType || 'login',
    }
  },
  computed: {
    /**
     * iOS/AndroidのApp Bannerを表示するかどうかを判定する算出プロパティ。
     */
    showAppBanner() {
      return DeviceInfo.isMobile() && this.loginType === 'login'
    },
  },
  mounted() {
    /**
     * 以下の条件の場合にスマートバナーを表示する
     * ・Android 用のスマートバナー : Android のブラウザで SFgo を表示
     *  chromeでアクセスした場合にスマートバナーが表示されない問題に対処する
     */
    if (!DeviceInfo.isCordova() && DeviceInfo.isAndroidBrowser() && this.loginType === 'login') {
      // eslint-disable-next-line no-new
      new SmartBanner({
        daysHidden: -1, // Chrome だと 0 を設定しても Cookie を保持し続けてしまうため、 -1 を設定して Cookie を作らないようにする。
        daysReminder: -1,
        title: 'SFgo',
        author: 'JAPAN RACE PROMOTION INC',
        button: 'VIEW',
        store: {
          ios: 'On the App Store',
          android: 'In Google Play',
        },
        price: {
          ios: 'FREE',
          android: 'FREE',
        },
      })
    }
  },
})
