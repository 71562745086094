var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mission-check-in", class: { "is-fixed": _vm.isFixed } },
    [
      _c("div", { staticClass: "mission-check-in__box" }, [
        _c("p", { staticClass: "mission-check-in__title" }, [
          _vm._v("CHECK IN"),
        ]),
        _c(
          "button",
          {
            staticClass: "mission-check-in__button",
            class: "is-" + _vm.checkInStatus,
            attrs: { disabled: _vm.isDisabled },
            on: { click: _vm.handleCheckInClicked },
          },
          [
            _vm.checkInStatus !== "checked"
              ? _c("mission-check-in-icon", {
                  attrs: { width: 44, height: 44 },
                })
              : _c("mission-checked-in-icon"),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "mission-check-in__message",
            class: {
              "mission-check-in__message--active--for-pc":
                _vm.isPc && _vm.checkInStatus === "active",
            },
          },
          [
            _c("p", { staticClass: "mission-check-in__message-check-in" }, [
              _vm._v(" " + _vm._s(_vm.message) + " "),
              _vm.isPc && _vm.checkInStatus === "active"
                ? _c(
                    "span",
                    { staticClass: "mission-check-in__message-pc-caution" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("MissionPage.checkIn.message.caution"))
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _vm.isFixed
          ? _c(
              "button",
              {
                staticClass: "mission-check-in__close",
                on: { click: _vm.handleCloseClicked },
              },
              [
                _c("span", [_vm._v("Close")]),
                _c(
                  "span",
                  { staticClass: "mission-check-in__close-icon" },
                  [_c("mission-accordion-icon")],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }