
















import { defineComponent, onUnmounted } from '@vue/composition-api'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import RewardPane from '@/components/RewardPage/RewardPane.vue'
import useVideoPlatformContents from '@/store/hook/useVideoPlatformContents'

export default defineComponent({
  name: 'RewardPage',
  components: { RewardPane, HeaderPane },
  setup() {
    const { clearVideoPlatformContents } = useVideoPlatformContents()

    onUnmounted(() => {
      clearVideoPlatformContents()
    })
  },
})
