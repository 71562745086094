import { render, staticRenderFns } from "./PasswordResetNewPasswordPage.vue?vue&type=template&id=1218fdbc"
import script from "./PasswordResetNewPasswordPage.vue?vue&type=script&lang=ts"
export * from "./PasswordResetNewPasswordPage.vue?vue&type=script&lang=ts"
import style0 from "./PasswordResetNewPasswordPage.vue?vue&type=style&index=0&id=1218fdbc&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1218fdbc')) {
      api.createRecord('1218fdbc', component.options)
    } else {
      api.reload('1218fdbc', component.options)
    }
    module.hot.accept("./PasswordResetNewPasswordPage.vue?vue&type=template&id=1218fdbc", function () {
      api.rerender('1218fdbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PasswordResetPage/PasswordResetNewPasswordPage.vue"
export default component.exports