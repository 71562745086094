var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-body-section" },
    [
      _c("div", { staticClass: "error-body-section__wrap" }, [
        _c("p", { staticClass: "c-regist__comment" }, [
          _vm._v(_vm._s(_vm.message)),
        ]),
        JSON.parse(_vm.descriptions) && JSON.parse(_vm.descriptions).length > 0
          ? _c(
              "ul",
              { staticClass: "p-error__notes" },
              _vm._l(
                JSON.parse(_vm.descriptions),
                function (description, index) {
                  return _c(
                    "li",
                    {
                      key: "description_" + index,
                      staticClass: "p-error__note",
                    },
                    [_vm._v(" " + _vm._s(description) + " ")]
                  )
                }
              ),
              0
            )
          : _vm._e(),
      ]),
      _c(
        "action-buttons-section",
        {
          staticClass: "error-body-section__action-buttons",
          attrs: { scrollTargetSelector: ".error-body-section", visible: true },
        },
        [
          _c("atom-link-button", {
            attrs: {
              linkText: _vm.$tc("common.toLogin"),
              url: _vm.loginUrl,
              isOpenAsBlank: false,
              colorType: "secondary",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }