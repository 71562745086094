<template>
  <div
    class="mission-ranking-header-section"
    :class="{ 'mission-ranking-header-section--fixed': isFixed }"
  >
    <div
      class="mission-ranking-header-section__result"
      :class="{ 'mission-ranking-header-section__result--fixed': isFixed }"
      ref="result"
    >
      <members-point-section
        class="mission-ranking-header-section__result-section"
        :userData="userViewData"
        :membersRankData="membersRankData"
      />
    </div>
    <div
      class="mission-ranking-header-section__tab"
      :class="{ 'mission-ranking-header-section__tab--fixed': isFixed }"
      :style="tabStyle"
      ref="tab"
    >
      <mission-list-tabs-parts :currentTab="'ranking'" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import MissionListTabsParts from '@/components/MissionPage/MissionListPane/parts/MissionListTabsParts.vue'
import MembersPointSection from '@/components/MypageTopPage/MembershipCardPane/MembersPointSection.vue'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'

export default defineComponent({
  name: 'MissionRankingHeaderSection',
  components: { MembersPointSection, MissionListTabsParts },
  props: {
    isFixed: {
      type: Boolean,
    },
  },
  setup(props) {
    const loginStore = StoreUtil.useStore('LoginStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { getBadgeDisplayYearsInfo, getCurrentRank } = useMembershipCard()
    const { isFreePlan } = contractInfoStore

    /**
     * バッジリストを取得する
     */
    const getBadgeList = () => {
      const results = []
      // sfgo-dev-supporterをロールに持つ場合、開発サポーターのバッジを付与する
      if (loginStore?.userRoles.includes('sfgo-dev-supporter')) {
        results.push({
          badgeType: 'supporter',
          label: '開発サポーター',
        })
      }
      // 登録日からの経過年数に応じたバッジ情報を取得する
      const elapsedYearResult = getBadgeDisplayYearsInfo(
        contractInfoStore?.ownOrganization.value?._createdDate,
      )
      if (elapsedYearResult) {
        results.push(elapsedYearResult)
      }
      return results
    }

    /**
     * ユーザーデータ
     */
    const userViewData = computed(() => ({
      name: mypagePageStore.user.value?.additionalData?.userDisplayName || '',
      image: CloudFrontUtil.getSignedUrl(mypagePageStore.user.value?.userPicture) || '',
      userRole: loginStore?.userRoles.find((r) =>
        Const.SFGO_ROLES.filter((v) => v !== 'sfgo-dev-supporter').includes(r),
      ),
      badgeList: getBadgeList(),
      isFreePlan,
    }))

    /**
     * 会員ランクデータ
     */
    const membersRankData = computed(() => ({
      point: mypagePageStore.currentOwnedPoints.value ?? 0,
      currentRank: getCurrentRank(mypagePageStore.currentOwnedPoints.value),
    }))

    const result = ref(null)

    const tabStyle = computed(() => {
      if (!props.isFixed) {
        return { 'padding-top': `${(result.value?.clientHeight || 0) + 10}px` }
      }
      return {}
    })

    return {
      userViewData,
      membersRankData,
      result,
      tabStyle,
    }
  },
})
</script>

<style scoped lang="scss">
.mission-ranking-header-section {
  &--fixed {
    position: fixed;
    top: 48px;
    left: 0;
    z-index: 1;
    width: 100%;
    padding-bottom: 6px;
    background-color: $white10;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(60px);
  }

  &__result {
    position: fixed;
    top: 48px;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: $white10;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(60px);

    &--fixed {
      position: unset;
      top: auto;
      background-color: transparent;
      box-shadow: none;
      backdrop-filter: unset;
    }

    &-section {
      max-width: 600px;
      background: none;
      border: none;
      border-radius: 0;
      backdrop-filter: none;
    }
  }

  &__tab {
    &--fixed {
      ::v-deep {
        .mission-list-tabs-parts__list {
          background-color: rgba(#000, 0.5);
        }
      }
    }
  }
}
</style>
