




import { defineComponent } from '@vue/composition-api'

/**
 * モーダルヘッダー テンプレート
 */
export default defineComponent({
  name: 'ModalHeaderParts',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
})
