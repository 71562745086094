var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-overlay-section", {
    staticClass: "payment-pending-alert-modal-section",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("overlay-title-parts", {
              attrs: {
                title: _vm.$tc("MypagePage.PaymentPendingAlertModal.title"),
              },
            }),
            _c("overlay-message-parts", {
              attrs: { message: _vm.alertMessage },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-icon-link-button", {
              attrs: {
                linkText: _vm.$tc(
                  "MypagePage.PaymentPendingAlertModal.submitText"
                ),
                url: _vm.paymentUrl,
                imgPath: require("@/assets/img/icon/icon_external_link_outline__green.svg"),
              },
            }),
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$tc("common.close"),
                colorType: "tertiary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }