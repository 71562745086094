






import { defineComponent, onUnmounted } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'

/**
 * MypageTicketPage 共通Wrapper
 */
export default defineComponent({
  name: 'MypageTicketPageWrapper',
  setup() {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const { clearTicketPageData } = mypageTicketPageStore

    onUnmounted(() => {
      clearTicketPageData()
    })
  },
})
