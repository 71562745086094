

































import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { VideoStatusType } from '@/components/hook/useVideoPlayer'
import DeviceInfo from '@/util/DeviceInfo'

export type DataType = {
  isTouchThumb: boolean
  touchId: number | null
  touchStartTime: number
}
/**
 * レース動画再生画面 動画プレーヤー オーバーレイコンポーネント
 */
export default defineComponent({
  name: 'SeekBarParts',
  data(): DataType {
    return {
      isTouchThumb: false,
      touchId: null,
      touchStartTime: Date.now(),
    }
  },
  props: {
    /**
     * シークバー用再生時間データ
     */
    value: {
      type: Object as PropType<VideoStatusType>,
      required: true,
    },
  },
  setup(props, context) {
    const progressValue = reactive(props.value)
    /**
     * 秒数を hh:mm:ss に変換
     * @param val 表示秒数
     */
    const formatTime = (val = 0): string => {
      const hour = `00${Math.floor(val / 3600)}`.slice(-2)
      const min = `00${Math.floor((val % 3600) / 60)}`.slice(-2)
      const rem = `00${val % 60}`.slice(-2)
      return `${hour}:${min}:${rem}`
    }
    const currentTime = ref(formatTime(0))

    const seekBarValue = computed({
      get: () => progressValue.currentTime,
      set: (val: number) => {
        progressValue.currentTime = val
        currentTime.value = formatTime(val)
        context.emit('input', Object.assign(props.value, { currentTime: val }))
      },
    })
    const currentTimeVisible = ref(false)

    return {
      progressValue,
      seekBarValue,
      currentTime,
      currentTimeVisible,
      formatTime,
    }
  },
  methods: {
    /**
     * サムに触れたかどうかの判定
     */
    checkHitThumb(clientX: number, clientY: number): boolean {
      const barWidth = this.$refs.getBarWidth as HTMLDivElement | null
      const seekBarRange = this.$refs.seekBarRange as HTMLInputElement | null
      if (barWidth && seekBarRange) {
        const seekbarRect = barWidth.getBoundingClientRect()

        // サムの半径
        const thumbRadius = 7
        // タッチ領域の半径(タッチ自体は矩形)
        const touchRadius = thumbRadius + 3.5 * 2.0
        const offsetX = clientX - seekbarRect.x
        const offsetY = clientY - seekbarRect.y
        const scale = Number(seekBarRange.value) / this.progressValue.movieLength
        let centerPosX = (seekbarRect.width - thumbRadius * 2) * scale + thumbRadius
        if (centerPosX < thumbRadius) {
          centerPosX = thumbRadius
        } else if (centerPosX >= seekbarRect.width - thumbRadius) {
          centerPosX = seekbarRect.width - thumbRadius
        }
        const centerPosY = seekbarRect.height / 2
        const centerOffsetX = offsetX - centerPosX
        const centerOffsetY = offsetY - centerPosY
        // 範囲内ならサムタッチと判定
        if (Math.abs(centerOffsetX) <= touchRadius && Math.abs(centerOffsetY) <= touchRadius) {
          return true
        }
      }
      return false
    },
    /**
     * タッチした位置のValueを取得
     */
    checkTouchValue(clientX: number): number | null {
      const barWidth = this.$refs.getBarWidth as HTMLDivElement | null
      if (barWidth) {
        const seekbarRect = barWidth.getBoundingClientRect()

        // サムの半径
        const thumbRadius = 7
        const offsetX = clientX - seekbarRect.x

        if (offsetX <= thumbRadius) {
          // 最小値
          return 0
        }
        if (offsetX >= seekbarRect.width - thumbRadius) {
          // 最大値
          return this.progressValue.movieLength
        }
        // 中間
        return (
          ((offsetX - thumbRadius) / (seekbarRect.width - thumbRadius * 2)) *
          this.progressValue.movieLength
        )
      }
      return null
    },
    /**
     * シークバー touchStart時 イベント
     */
    handleTouchStartRange(event: TouchEvent): void {
      let isShowCurrentTime = true
      if (this.touchId === null && DeviceInfo.isCordova()) {
        const touch = event.touches[0] || event.changedTouches[0]
        this.touchId = touch.identifier
        this.isTouchThumb = this.checkHitThumb(touch.clientX, touch.clientY)
        this.touchStartTime = Date.now()

        // サムのタッチ以外では、イベントの処理を無効化する
        const seekBarRange = this.$refs.seekBarRange as HTMLInputElement
        if (this.isTouchThumb) {
          seekBarRange.disabled = false
        } else {
          seekBarRange.disabled = true
          isShowCurrentTime = false
        }
      }

      if (isShowCurrentTime) {
        this.visibleCurrentTime()
      }
      this.$emit('handleTouchStartSeekBar')
    },

    /**
     * 指が外れた時にタッチ状態をリセットする
     */
    releaseTouchState(event: TouchEvent): void {
      if (this.touchId != null) {
        for (let i = 0; i < event.changedTouches.length; i += 1) {
          const item = event.changedTouches[i]
          if (item.identifier === this.touchId) {
            this.touchId = null
            this.isTouchThumb = false
            break
          }
        }
      }
    },

    /**
     * シークバー touchEnd時 イベント
     */
    handleTouchEndRange(event: TouchEvent): void {
      this.releaseTouchState(event)
      this.hideCurrentTime()
      this.$emit('handleTouchEndSeekBar')
    },
    /**
     * シークバー touchMove時 イベント
     */
    handleTouchMoveRange(event: TouchEvent): void {
      // サムのドラック以外でのドラッグ操作
      if (!this.isTouchThumb && this.touchId !== null) {
        const touch = event.touches[0] || event.changedTouches[0]
        if (this.touchId === touch.identifier) {
          // 発火に猶予をもたす
          if (Date.now() - this.touchStartTime >= 100) {
            this.visibleCurrentTime()
            const rangeValue = this.checkTouchValue(touch.clientX)
            if (rangeValue !== null) {
              this.seekBarValue = rangeValue
            }
          }
        }
      }
    },
    /**
     * シークバー touchCancel時 イベント
     */
    handleTouchCancelRange(event: TouchEvent): void {
      this.releaseTouchState(event)
    },
    /**
     * 時間表示コンポーネント表示
     */
    visibleCurrentTime(): void {
      this.currentTime = this.formatTime(this.progressValue.currentTime)
      this.currentTimeVisible = true
    },
    /**
     * 時間表示コンポーネント非表示
     */
    hideCurrentTime(): void {
      this.currentTimeVisible = false
    },
    /**
     * 時間表示コンポーネント left 位置指定
     * @param val 選択時間秒数
     * @return string left指定(px)
     */
    getCurrentTimeLeftPosition(val: number): string {
      const target = this.$refs.getBarWidth as HTMLDivElement | null
      let leftPosition = 0

      if (target) {
        const barWidth = target.clientWidth as number
        const timeWidthHalf =
          ((this.$refs.getTimeWidth as HTMLDivElement).clientWidth as number) / 2
        const position = barWidth * (val / this.progressValue.movieLength)

        if (position < timeWidthHalf) {
          leftPosition = 0
        } else if (position > barWidth - timeWidthHalf) {
          leftPosition = barWidth - timeWidthHalf * 2
        } else {
          leftPosition = position - timeWidthHalf
        }
      }
      return `${leftPosition}px`
    },
  },
})
