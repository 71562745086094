














import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageTicketListPane from '@/components/MypageTicketListPage/MypageTicketListPane.vue'

export default defineComponent({
  name: 'MypageTicketListPage',
  components: {
    MypageTicketListPane,
    GlobalNavigationPane,
    SubHeaderSection,
  },
})
