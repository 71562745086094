





























import { computed, defineComponent, PropType } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'

/**
 * ミッション画面 ミッション達成状況 達成カウント表示パーツ
 */
export default defineComponent({
  name: 'MissionProcessParts',
  props: {
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
  },
  setup(props) {
    const labelSet = computed(() => {
      switch (props.mission.achievementCountDispType) {
        case 'login':
          return {
            title: I18n.tc('MissionPage.process.login.title'),
            restPre: I18n.tc('MissionPage.process.login.restPre'),
            restUnit: I18n.tc('MissionPage.process.login.restUnit'),
            currentPre: I18n.tc('MissionPage.process.login.currentPre'),
            currentUnit: I18n.tc('MissionPage.process.login.currentUnit'),
          }
        case 'countUp':
          return {
            title: I18n.tc('MissionPage.process.countUp.title'),
            restPre: I18n.tc('MissionPage.process.countUp.restPre'),
            restUnit: I18n.tc('MissionPage.process.countUp.restUnit'),
            currentPre: I18n.tc('MissionPage.process.countUp.currentPre'),
            currentUnit: I18n.tc('MissionPage.process.countUp.currentUnit'),
          }
        case 'continuation':
          return {
            title: I18n.tc('MissionPage.process.continuation.title'),
            restPre: I18n.tc('MissionPage.process.continuation.restPre'),
            restUnit: I18n.tc('MissionPage.process.continuation.restUnit'),
            currentPre: I18n.tc('MissionPage.process.continuation.currentPre'),
            currentUnit: I18n.tc('MissionPage.process.continuation.currentUnit'),
          }
        case 'default':
          return {
            title: I18n.tc('MissionPage.process.default.title'),
            restPre: I18n.tc('MissionPage.process.default.restPre'),
            restUnit: I18n.tc('MissionPage.process.default.restUnit'),
            currentPre: I18n.tc('MissionPage.process.default.currentPre'),
            currentUnit: I18n.tc('MissionPage.process.default.currentUnit'),
          }
        default:
          return null
      }
    })
    return {
      labelSet,
    }
  },
})
