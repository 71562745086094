














































































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  computed,
  PropType,
} from '@vue/composition-api'
import dayjs from 'dayjs'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import {
  CourseType,
  SubjectiveEventType,
} from '@/store/stores/collectionModule/documents/event/CountdownEventDocument'
import Const from '@/util/Const'
import CircuitModeSection from '@/components/HomePage/HomePane/CircuitModeSection.vue'
import StoreUtil from '@/store/StoreUtil'
import TicketLinkParts, {
  PurchasedTicketStatusType,
} from '@/components/HomePage/HomePane/parts/TicketLinkParts.vue'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'

/* eslint-disable @typescript-eslint/no-var-requires */
const playLockIconPath = require('@/assets/img/icon/icon_play_lock.svg')

type CountdownDataType = {
  days: string
  hours: string
  min: string
  sec: string
}
export type UpComingEventDataType = {
  eventId: string | null
  type: SubjectiveEventType | undefined
  starDate: Date
  eventName: Multilingual | undefined
  circuit: CourseType | undefined
  isShowTicketButton: boolean | undefined
}

/**
 * HOME カウントダウンセクション
 */
export default defineComponent({
  name: 'CountdownSection',
  components: {
    TicketLinkParts,
    CircuitModeSection,
  },
  props: {
    /**
     * アプリインストール後、オンボーディング画面を表示したかどうか
     */
    hasDisplayedOnboarding: {
      type: Boolean,
    },
    /**
     * カウントダウン対象データ
     */
    countdownTargetData: {
      type: Object as PropType<UpComingEventDataType>,
    },
    /**
     * ライブ配信中のレースがあるかどうか
     */
    hasLiveRace: {
      type: Boolean,
    },
    /**
     * ライブ配信中の動画再生画面の遷移先
     */
    liveRaceLink: {
      type: String,
      default: null,
    },
    /**
     * ライブ配信中の動画再生画面に遷移できるかどうか
     * 対象コンテンツに対する以下の判定結果
     * 1. レース公開範囲
     * 2. 会員種別
     */
    isLiveRaceCanShow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    const homePageStore = StoreUtil.useStore('HomePageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { digitalTicketsByYear } = homePageStore
    const { ownOrganization } = mypagePageStore
    const { userPurchasedTicket } = usePurchasedTicketData()

    /** SFgoのロゴ画像 */
    const logoImage = ref('assets/img/HomePage/logo-gray.svg')

    /**
     * チケット購入済みかどうか
     */
    const purchasedRelatedEventTicketStatus: Ref<PurchasedTicketStatusType> = computed(() => {
      const eventStartYear = props.countdownTargetData?.starDate
        ? dayjs(props.countdownTargetData.starDate).year()
        : 0
      // カウンドダウンイベント開始年に一致するチケットイベントデータの中から対象のカウンドダウンイベントに紐づくチケットイベントを取得する
      const ticketEventList = digitalTicketsByYear.value?.[eventStartYear]?.filter(
        (ticketEvent) => ticketEvent.eventId === props.countdownTargetData?.eventId,
      )
      const purchasedTicket = userPurchasedTicket(ticketEventList, ownOrganization.value)
      // カウントダウンイベントに関連するチケットを購入済みの場合、trueを返す
      if (purchasedTicket.productNumbers.availableToday.length > 0) {
        return 'hasAvailableTodayTickets'
      }
      if (purchasedTicket.productNumbers.all.length > 0) {
        return 'hasTickets'
      }
      return 'noTickets'
    })

    /**
     * 右下コース画像取得
     * 画像パス未指定の場合にフラッグアイコンを表示
     */
    const courseSvgPath = computed(() => {
      switch (props.countdownTargetData?.circuit) {
        case 'FUJI_SPEEDWAY':
          return Const.HOME_COUNTDOWN_IMAGE_PATH.FUJI_SPEEDWAY
        case 'AUTOPOLIS':
          return Const.HOME_COUNTDOWN_IMAGE_PATH.AUTOPOLIS
        case 'SPORTSLAND_SUGO':
          return Const.HOME_COUNTDOWN_IMAGE_PATH.SPORTSLAND_SUGO
        case 'SUZUKA_CIRCUIT':
          return Const.HOME_COUNTDOWN_IMAGE_PATH.SUZUKA_CIRCUIT
        case 'TWIN_RING_MOTEGI':
          return Const.HOME_COUNTDOWN_IMAGE_PATH.TWIN_RING_MOTEGI
        default:
          return Const.HOME_COUNTDOWN_IMAGE_PATH.DEFAULT
      }
    })

    const headerTextCatch = computed(() =>
      props.countdownTargetData?.type === 'race' ? 'NEXT RACE' : 'NEXT EVENT',
    )

    const showTicketButton = computed(() => props.countdownTargetData?.isShowTicketButton ?? false)

    const countdown = ref<CountdownDataType>({
      days: '00',
      hours: '00',
      min: '00',
      sec: '00',
    })

    /**
     * 数字穴埋め関数
     * @param num
     * @param length
     * @return string
     */
    const zeroPadding = (num: number, length: number) =>
      String(Array(length).join('0') + num).slice(-length)

    /**
     * カウントダウンタイマー
     */
    let countdownTimer: number
    let remainTime = 0
    const countdownTarget = props.countdownTargetData?.starDate
    const COUNTDOWN_NUMBER_OF_DIGITS = 2

    onMounted(() => {
      // カウントダウンタイマー起動
      countdownTimer = window.setInterval(() => {
        const nowDate = new Date()
        if (!countdownTarget) return
        remainTime = countdownTarget.getTime() - nowDate.getTime() // 差分取得
        remainTime = remainTime < 0 ? 0 : remainTime

        // 3桁の場合にゼロ埋めを3桁にする(デフォルトは2桁)
        const diffDaysLength =
          String(Math.floor(remainTime / 1000 / 60 / 60 / 24)).length > COUNTDOWN_NUMBER_OF_DIGITS
            ? 3
            : COUNTDOWN_NUMBER_OF_DIGITS

        countdown.value = {
          days: zeroPadding(Math.floor(remainTime / 1000 / 60 / 60 / 24), diffDaysLength),
          hours: zeroPadding(
            Math.floor(remainTime / 1000 / 60 / 60) % 24,
            COUNTDOWN_NUMBER_OF_DIGITS,
          ),
          min: zeroPadding(Math.floor(remainTime / 1000 / 60) % 60, COUNTDOWN_NUMBER_OF_DIGITS),
          sec: zeroPadding(Math.floor(remainTime / 1000) % 60, COUNTDOWN_NUMBER_OF_DIGITS),
        }

        // 過去の場合タイマー停止
        if (remainTime === 0) {
          clearInterval(countdownTimer)
        }
      }, 1000)
    })

    onUnmounted(() => {
      // カウントダウンタイマー解除
      if (countdownTimer) {
        clearInterval(countdownTimer)
      }
    })

    /**
     * 半角英数字判定
     * @param str
     * @return boolean
     */
    const isEnglish = (str: string) => str.match(/^[\x20-\x7e]*$/)

    return {
      isCircuitMode: appConfigStore.currentCircuitMode,
      purchasedRelatedEventTicketStatus,
      countdown,
      isEnglish,
      courseSvgPath,
      headerTextCatch,
      logoImage,
      playLockIconPath,
      showTicketButton,
    }
  },
})
