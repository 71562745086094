

























import { defineComponent } from '@vue/composition-api'

/**
 * プラン比較テーブル 行パーツ
 */
export default defineComponent({
  name: 'ComparisonPlanTableItemParts',
  props: {
    /**
     * 項目
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * 無料プラン
     */
    free: {
      type: Boolean,
      default: false,
    },
    /**
     * 無料プラン脚注
     */
    freeFootnote1: {
      type: Boolean,
      default: null,
    },
    freeFootnote2: {
      type: Boolean,
      default: null,
    },
    /**
     * 有料プラン
     */
    paid: {
      type: Boolean,
      default: false,
    },
  },
})
