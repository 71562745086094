var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-sns-button-parts" }, [
    _c("h3", { staticClass: "highlight-sns-button-parts__title" }, [
      _c("span", { staticClass: "highlight-sns-button-parts__title-label" }, [
        _vm._v(_vm._s(_vm.$tc("RaceVideoPage.highlights.comment.sns"))),
      ]),
      _c(
        "span",
        { staticClass: "highlight-sns-button-parts__title-label-short" },
        [_vm._v(_vm._s(_vm.$tc("RaceVideoPage.highlights.comment.snsShort")))]
      ),
    ]),
    _c("ul", { staticClass: "highlight-sns-button-parts__buttons" }, [
      _c(
        "li",
        {
          ref: "buttonX",
          staticClass: "highlight-sns-button-parts__buttons-item",
        },
        [
          _c(
            "button",
            {
              staticClass: "highlight-sns-button-parts__button",
              class: {
                "highlight-sns-button-parts__button--active": _vm.postX,
                "highlight-sns-button-parts__button--activeBalloon":
                  _vm.isActiveXBalloon,
              },
              on: { click: _vm.onChangePostX },
            },
            [
              _c(
                "label",
                {
                  staticClass: "highlight-sns-button-parts__button-label",
                  attrs: { for: "comment" },
                },
                [_c("highlights-x-icon")],
                1
              ),
              _vm.isXPosted
                ? _c(
                    "span",
                    { staticClass: "highlight-sns-button-parts__button-check" },
                    [
                      _vm.postX
                        ? _c("highlight-check-active-icon")
                        : _c("highlight-check-icon"),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.isActiveXBalloon
            ? _c(
                "div",
                {
                  ref: "balloonX",
                  staticClass: "highlight-sns-button-parts__balloon",
                  style: _vm.balloonXStyle,
                },
                [_c("highlights-sns-balloon-parts")],
                1
              )
            : _vm._e(),
        ]
      ),
      false
        ? _c(
            "li",
            {
              ref: "buttonInstagram",
              staticClass: "highlight-sns-button-parts__buttons-item",
            },
            [
              _c(
                "button",
                {
                  staticClass: "highlight-sns-button-parts__button",
                  class: {
                    "highlight-sns-button-parts__button--active":
                      _vm.postInstagram,
                    "highlight-sns-button-parts__button--activeBalloon":
                      _vm.isActiveInstagramBalloon,
                  },
                  on: { click: _vm.onChangePostInstagram },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "highlight-sns-button-parts__button-label",
                      attrs: { for: "comment" },
                    },
                    [_c("highlights-instagram-icon")],
                    1
                  ),
                  _vm.isInstagramPosted
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "highlight-sns-button-parts__button-check",
                        },
                        [
                          _vm.postInstagram
                            ? _c("highlight-check-active-icon")
                            : _c("highlight-check-icon"),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.isActiveInstagramBalloon
                ? _c(
                    "div",
                    {
                      ref: "balloonInstagram",
                      staticClass: "highlight-sns-button-parts__balloon",
                    },
                    [_c("highlights-sns-balloon-parts")],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }