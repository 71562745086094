














import { defineComponent, inject } from '@vue/composition-api'
import VueRouter from 'vue-router'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageTicketPastPane from '@/components/MypageTicketPastPage/MypageTicketPastPane.vue'
import StoreUtil from '@/store/StoreUtil'

export default defineComponent({
  name: 'MypageTicketListPage',
  components: { MypageTicketPastPane, SubHeaderSection },
  inject: ['router'],
  setup() {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const { selectedPastTicketInfo } = mypageTicketPageStore

    const router = inject('router') as VueRouter

    /**
     * 過去のチケットページに遷移する
     */
    const moveToMypageTicketListPage = () => {
      // ストアに保存していた表示対象のイベントとイベントの開催日目をクリア
      selectedPastTicketInfo.value = undefined
      router.replace('/mypage/ticket-past')
    }

    return {
      moveToMypageTicketListPage,
    }
  },
})
