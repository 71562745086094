<template>
  <p class="mission-ranking-period-parts">
    <span>{{ $tc('MissionPage.RankingPage.aggregationPeriod') }}：</span>
    <span>{{ periodData }}</span>
  </p>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionRankingPeriodParts',
  props: {
    periodData: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.mission-ranking-period-parts {
  font-family: $fontSignup;
  font-size: 12px;
  font-weight: 500;
  color: $white;
  line-height: 1.67;
  letter-spacing: 0.02em;
  text-align: right;
}
</style>
