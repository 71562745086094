import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import PointRankingDataDocument, {
  PointRankingCategoryType,
} from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'
/**
 * ポイントランキングデータを操作するための処理を取得する。
 */
export default function usePointRankingData() {
  // Collection modules
  const pointRankingCollectionModule = CollectionModule.createStore(PointRankingDataDocument)
  const { searchDateRangeData } = useDynamoDB()

  /**
   * ランキングのカテゴリーごとにデータを取得
   */
  const getRankingDataByCategory = (
    category: PointRankingCategoryType,
    fromDate?: number,
    toDate?: number,
  ) =>
    searchDateRangeData({
      tableName: 'point-ranking-aggregation',
      partitionKey: 'category',
      partitionKeyValue: category,
      sortKey: 'aggregationStartDate',
      from: fromDate,
      to: toDate,
    })
  /**
   * 表示するポイントランキングデータを一括で取得する。
   */
  const fetchPointRankingData = async () => {
    // 日本時間で2/1より前の日付の場合、前年度を指定する
    const year =
      dayjs.tz(`${new Date().getFullYear()}-02-01`, 'Asia/Tokyo').startOf('day').valueOf() >
      dayjs().valueOf()
        ? new Date().getFullYear() - 1
        : new Date().getFullYear()

    // シーズンランキングデータは、今シーズンと昨シーズンの２シーズン分取得するためにfrom, toを以下にする
    // 今シーズン開始である今年の2/1に設定
    const filteredSeasonToDate = dayjs()
      .tz('Asia/Tokyo')
      .year(year)
      .startOf('year')
      .add(1, 'month')
      .valueOf()
    // 昨シーズン開始である昨年の2/1に設定
    const filteredSeasonFromDate = dayjs(filteredSeasonToDate).subtract(1, 'year').valueOf()

    // 月間ランキングデータは、今月と先月の２ヶ月分取得するためにfrom, toを以下にする
    // 今月の1日を設定
    const filteredMonthlyToDate = dayjs().tz('Asia/Tokyo').startOf('month').valueOf()
    // 先月の1日を設定
    const filteredMonthlyFromDate = dayjs(filteredMonthlyToDate).subtract(1, 'month').valueOf()

    const result = await Promise.all([
      getRankingDataByCategory('TOTAL_POINT_RANKING'),
      getRankingDataByCategory(
        'MONTHLY_POINT_RANKING',
        filteredMonthlyFromDate,
        filteredMonthlyToDate,
      ),
      getRankingDataByCategory(
        'SEASON_POINT_RANKING',
        filteredSeasonFromDate,
        filteredSeasonToDate,
      ),
    ])
    pointRankingCollectionModule.data = result.flat() as Array<PointRankingDataDocument>
  }
  /**
   * 取得したポイントランキングデータ一覧
   */
  const pointRankingData = computed(() =>
    orderBy(pointRankingCollectionModule.data, 'aggregationStartDate'),
  )
  /**
   * 取得したポイントランキングデータをクリアする
   */
  const clearPointRankingData = () => {
    pointRankingCollectionModule.clearData()
  }
  return {
    fetchPointRankingData,
    pointRankingData,
    clearPointRankingData,
  }
}
