/**
 * ネイティブプレーヤーのインターフェース定義。
 * 各メソッドの仕様については、
 * https://bitbucket.org/pitchbase/pitchbase-player-documents/src/master/player-documents/Api%E4%BB%95%E6%A7%98%E6%9B%B8/VideoPlayer%E3%83%97%E3%83%A9%E3%82%B0%E3%82%A4%E3%83%B3_API%E4%BB%95%E6%A7%98%E6%9B%B8.xlsx を参照のこと
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

export function initComparisonPlay(
  preloadList,
  preloadListShare,
  preloadSignature,
  preloadSignatureShare,
  loadType,
  success,
  error,
) {
  VideoPlayer.initComparisonPlay(
    preloadList,
    preloadListShare,
    preloadSignature,
    preloadSignatureShare,
    loadType,
    success,
    error,
  )
}

// native view add start.
export function initNativeViewOnCordova(
  movieURL,
  playlistServerURL,
  segmentServerURL,
  strTime,
  originX,
  originY,
  width,
  height,
  menuBarOffsetY,
  target,
  mode,
  add_view_item,
  end_timestamp,
  slowPlayRate,
  fastPlayRate,
  success,
  error,
  loadType,
  movieType,
  refresh_torken,
  signature,
  options,
) {
  VideoPlayer.initNativeViewOnCordova(
    movieURL,
    playlistServerURL,
    segmentServerURL,
    strTime,
    originX,
    originY,
    width,
    height,
    menuBarOffsetY,
    target,
    mode,
    add_view_item,
    end_timestamp,
    slowPlayRate,
    fastPlayRate,
    success,
    error,
    loadType,
    movieType,
    refresh_torken,
    signature,
    options,
  )
}

export function changeMovie(target, movieInfo, options, success, error) {
  VideoPlayer.changeMovie(target, movieInfo, options, success, error)
}

export function exitNativePlayer(target, success, error) {
  VideoPlayer.exitNativePlayer(target, success, error)
}

export function hideNativePlayer(target, success, error) {
  VideoPlayer.hideNativePlayer(target, success, error)
}

// noinspection JSUnusedLocalSymbols
export function showNativePlayer(target, success, error) {
  VideoPlayer.showNativePlayer(target, success, error)
}

export function rotateNativePlayer(target, angle, success, error) {
  VideoPlayer.rotateNativePlayer(target, angle, success, error)
}

export function setPlayerVolume(target, volume, success, error) {
  VideoPlayer.setPlayerVolume(target, volume, success, error)
}

export function changePlaybackRate(rate, target, success, error) {
  VideoPlayer.changePlaybackRate(rate, target, success, error)
}

export function pauseNativePlayer(target, success, error) {
  VideoPlayer.pauseNativePlayer(target, success, error)
}

export function playNativePlayer(target, success, error) {
  VideoPlayer.playNativePlayer(target, success, error)
}

export function playHeadNativePlayer(target, success, error) {
  VideoPlayer.playHeadNativePlayer(target, success, error)
}

export function scrollNativePlayer(
  base_x,
  base_y,
  move_x,
  move_y,
  base_h,
  base_w,
  target,
  success,
  error,
) {
  VideoPlayer.scrollNativePlayer(
    base_x,
    base_y,
    move_x,
    move_y,
    base_h,
    base_w,
    target,
    success,
    error,
  )
}

export function fullscreenNativePlayer(
  playStatus,
  slowPlayStatus,
  fastPlay,
  target,
  list,
  signature,
  listTarget,
  language,
  refresh_token,
  displayDigest,
  view_mode,
  listview_mode,
  slowPlayRate,
  fastPlayRate,
  playStateListener,
  success,
  error,
) {
  VideoPlayer.fullscreenNativePlayer(
    playStatus,
    slowPlayStatus,
    fastPlay,
    target,
    list,
    signature,
    listTarget,
    language,
    refresh_token,
    displayDigest,
    view_mode,
    listview_mode,
    slowPlayRate,
    fastPlayRate,
    playStateListener,
    success,
    error,
  )
}

export function changeNativePlayerPosition(target, to, success, error) {
  VideoPlayer.changeNativePlayerPosition(target, to, success, error)
}

export function changeNativePlayerDisplayPosition(target, to, success, error) {
  VideoPlayer.changeNativePlayerDisplayPosition(target, to, success, error)
}

export function changeNativePlayerSize(target, size, success, error) {
  VideoPlayer.changeNativePlayerSize(target, size, success, error)
}

export function zoomNativePlayerDisplay(target, scale, displayPosition, success, error) {
  VideoPlayer.zoomNativePlayerDisplay(target, scale, displayPosition, success, error)
}

export function enableZoomNativePlayerDisplayWithPinchGesture(
  target,
  enable,
  pinchEventListener,
  success,
  error,
) {
  VideoPlayer.enableZoomNativePlayerDisplayWithPinchGesture(
    target,
    enable,
    pinchEventListener,
    success,
    error,
  )
}

export function enableMoveNativePlayerDisplayWithDrag(
  target,
  enable,
  dragEventListener,
  success,
  error,
) {
  VideoPlayer.enableMoveNativePlayerDisplayWithDrag(
    target,
    enable,
    dragEventListener,
    success,
    error,
  )
}

export function backwardNativePlayer(target, success, error) {
  VideoPlayer.backwardNativePlayer(target, success, error)
}

export function forwardNativePlayer(target, success, error) {
  VideoPlayer.forwardNativePlayer(target, success, error)
}

export function getPlayerCurrentTime(target, success, error) {
  VideoPlayer.getPlayerCurrentTime(target, success, error)
}

export function setPlayerCurrentTime(target, time, success, error) {
  VideoPlayer.setPlayerCurrentTime(target, time, success, error)
}

export function addPlayerCurrentTimeListener(
  target,
  currentTimeChangedListner,
  frequency,
  success,
  error,
) {
  VideoPlayer.addPlayerCurrentTimeListener(
    target,
    currentTimeChangedListner,
    frequency,
    success,
    error,
  )
}

export function getMovieDuration(target, url, signature, success, error) {
  VideoPlayer.getMovieDuration(target, url, signature, success, error)
}

export function getNativePlayerStatus(target, success, error) {
  VideoPlayer.getNativePlayerStatus(target, success, error)
}

export function addNativePlayerTapEventListener(target, success, error) {
  VideoPlayer.addNativePlayerTapEventListner(target, success, error)
}

export function showNativePlayerLabel(target, player_id, label, focus, success, error) {
  VideoPlayer.showNativePlayerLabel(target, player_id, label, focus, success, error)
}

export function removeNativePlayerLabel(target, success, error) {
  VideoPlayer.removeNativePlayerLabel(target, success, error)
}

export function playOfflineNativeView(
  play_list_id,
  originX,
  originY,
  width,
  height,
  menuBarOffsetY,
  target,
  slowPlayRate,
  fastPlayRate,
  options,
  success,
  error,
) {
  VideoPlayer.playOfflineNativeView(
    play_list_id,
    originX,
    originY,
    width,
    height,
    menuBarOffsetY,
    target,
    slowPlayRate,
    fastPlayRate,
    options,
    success,
    error,
  )
}
// native view add end.

export function initLocalCache(authAPI, success, error) {
  VideoPlayer.initLocalCache(authAPI, success, error)
}

export function regOffline(list, offline_id, signature, refresh_token, success, error) {
  VideoPlayer.regOffline(list, offline_id, signature, refresh_token, success, error)
}

export function removeOffline(play_list_id, offline_id, success, error) {
  VideoPlayer.removeOffline(play_list_id, offline_id, success, error)
}

export function getInfoOffline(success, error) {
  VideoPlayer.getInfoOffline(success, error)
}

export function searchResultNotification(success, error) {
  VideoPlayer.searchResultNotification(success, error)
}

export function preLoadStartWithPlayListInfoArray(
  elms1,
  elm2,
  signature,
  refresh_token,
  success,
  error,
) {
  VideoPlayer.preLoadStartWithPlayListInfoArray(
    elms1,
    elm2,
    signature,
    refresh_token,
    success,
    error,
  )
}

export function cancelDownloadOffline(success, error) {
  VideoPlayer.cancelDownloadOffline(success, error)
}

export function cancelDownloadIdOffline(offline_id, success, error) {
  VideoPlayer.cancelDownloadIdOffline(offline_id, success, error)
}

export function addTouchBeganEventListener(target, touchEventListener, success, error) {
  VideoPlayer.addTouchBeganEventListener(target, touchEventListener, success, error)
}

export function addTouchMoveEventListener(target, touchEventListener, success, error) {
  VideoPlayer.addTouchMoveEventListener(target, touchEventListener, success, error)
}

export function addTouchEndEventListener(target, touchEventListener, success, error) {
  VideoPlayer.addTouchEndEventListener(target, touchEventListener, success, error)
}

export function drawLine(target, from, to, style, options, success, error) {
  VideoPlayer.drawLine(target, from, to, style, options, success, error)
}

export function drawRect(target, origin, size, rotationAngle, style, options, success, error) {
  VideoPlayer.drawRect(target, origin, size, rotationAngle, style, options, success, error)
}

export function drawArc(
  target,
  origin,
  radius,
  startAngle,
  endAngle,
  style,
  options,
  success,
  error,
) {
  VideoPlayer.drawArc(target, origin, radius, startAngle, endAngle, style, options, success, error)
}

export function drawOval(target, origin, size, style, rotationAngle, options, success, error) {
  VideoPlayer.drawOval(target, origin, size, style, rotationAngle, options, success, error)
}

export function drawImage(target, imagePath, origin, size, rotationAngle, options, success, error) {
  VideoPlayer.drawImage(target, imagePath, origin, size, rotationAngle, options, success, error)
}

export function drawBase64Image(
  target,
  base64ImageData,
  origin,
  size,
  rotationAngle,
  options,
  success,
  error,
) {
  VideoPlayer.drawBase64Image(
    target,
    base64ImageData,
    origin,
    size,
    rotationAngle,
    options,
    success,
    error,
  )
}

export function drawText(target, text, origin, style, options, success, error) {
  VideoPlayer.drawText(target, text, origin, style, options, success, error)
}

export function drawPolygon(target, points, style, options, success, error) {
  VideoPlayer.drawPolygon(target, points, style, options, success, error)
}

export function drawPolygonalLine(target, points, style, options, success, error) {
  VideoPlayer.drawPolygonalLine(target, points, style, options, success, error)
}

export function drawTextBox(
  target,
  text,
  origin,
  size,
  editable,
  textFontStyle,
  textInputStyle,
  textBoxStyle,
  options,
  textChangeEventListener,
  success,
  error,
) {
  VideoPlayer.drawTextBox(
    target,
    text,
    origin,
    size,
    editable,
    textFontStyle,
    textInputStyle,
    textBoxStyle,
    options,
    textChangeEventListener,
    success,
    error,
  )
}
export function drawSeekbar(
  target,
  origin,
  size,
  backgroundStyle,
  barStyle,
  cursorStyle,
  timeTextStyle,
  options,
  success,
  error,
) {
  VideoPlayer.drawSeekbar(
    target,
    origin,
    size,
    backgroundStyle,
    barStyle,
    cursorStyle,
    timeTextStyle,
    options,
    success,
    error,
  )
}
export function updateLine(target, handleId, from, to, style, options, success, error) {
  VideoPlayer.updateLine(target, handleId, from, to, style, options, success, error)
}

export function updateRect(
  target,
  handleId,
  origin,
  size,
  rotationAngle,
  style,
  options,
  success,
  error,
) {
  VideoPlayer.updateRect(
    target,
    handleId,
    origin,
    size,
    rotationAngle,
    style,
    options,
    success,
    error,
  )
}

export function updateArc(
  target,
  handleId,
  origin,
  radius,
  startAngle,
  endAngle,
  style,
  options,
  success,
  error,
) {
  VideoPlayer.updateArc(
    target,
    handleId,
    origin,
    radius,
    startAngle,
    endAngle,
    style,
    options,
    success,
    error,
  )
}

export function updateOval(
  target,
  handleId,
  origin,
  size,
  rotationAngle,
  style,
  options,
  success,
  error,
) {
  VideoPlayer.updateOval(
    target,
    handleId,
    origin,
    size,
    rotationAngle,
    style,
    options,
    success,
    error,
  )
}

export function updateImage(
  target,
  handleId,
  imagePath,
  origin,
  size,
  rotationAngle,
  options,
  success,
  error,
) {
  VideoPlayer.updateImage(
    target,
    handleId,
    imagePath,
    origin,
    size,
    rotationAngle,
    options,
    success,
    error,
  )
}

export function updateText(target, handleId, text, origin, style, options, success, error) {
  VideoPlayer.updateText(target, handleId, text, origin, style, options, success, error)
}

export function updatePolygon(target, handleId, points, style, options, success, error) {
  VideoPlayer.updatePolygon(target, handleId, points, style, options, success, error)
}

export function updatePolygonalLine(target, handleId, points, style, options, success, error) {
  VideoPlayer.updatePolygonalLine(target, handleId, points, style, options, success, error)
}

export function updateBase64Image(
  target,
  handleId,
  base64ImageData,
  origin,
  size,
  rotationAngle,
  options,
  success,
  error,
) {
  VideoPlayer.updateBase64Image(
    target,
    handleId,
    base64ImageData,
    origin,
    size,
    rotationAngle,
    options,
    success,
    error,
  )
}

export function updateTextBox(
  target,
  handleId,
  text,
  origin,
  size,
  editable,
  textFontStyle,
  textInputStyle,
  textBoxStyle,
  options,
  success,
  error,
) {
  VideoPlayer.updateTextBox(
    target,
    handleId,
    text,
    origin,
    size,
    editable,
    textFontStyle,
    textInputStyle,
    textBoxStyle,
    options,
    success,
    error,
  )
}

export function setZPosition(target, handleId, zPosition, success, error) {
  VideoPlayer.setZPosition(target, handleId, zPosition, success, error)
}

export function clearShape(target, handleID, success, error) {
  VideoPlayer.clearShape(target, handleID, success, error)
}

export function clearAllShape(target, success, error) {
  VideoPlayer.clearAllShape(target, success, error)
}

export function moveShape(target, handleID, to, success, error) {
  VideoPlayer.moveShape(target, handleID, to, success, error)
}

export function rotateShape(target, handleID, rotationAngle, success, error) {
  VideoPlayer.rotateShape(target, handleID, rotationAngle, success, error)
}

export function scaleShape(target, handleID, scale, success, error) {
  VideoPlayer.scaleShape(target, handleID, scale, success, error)
}

export function getLocalCacheURL(url) {
  return VideoPlayer.getLocalCacheURL(url)
}

export function getLocalCacheSegmentPath(url) {
  return VideoPlayer.getLocalCacheSegmentPath(url)
}

export function getImageFromNativePlayer(target, size, success, error) {
  VideoPlayer.getImageFromNativePlayer(target, size, success, error)
}

/**
 * RTMP配信用のカメラデバイスを起動する
 * 引数は仕様書を確認すること
 * @see https://bitbucket.org/pitchbase/pitchbase-player-documents/src/master/player-documents/Api%E4%BB%95%E6%A7%98%E6%9B%B8/
 * @param target
 * @param origin
 * @param size
 * @param options
 * @param success
 * @param error
 */
export function initCameraDevice(target, origin, size, options, success, error) {
  VideoPlayer.initCameraDevice(target, origin, size, options, success, error)
}

export function createDrawingLayer(target, name, options, success, error) {
  VideoPlayer.createDrawingLayer(target, name, options, success, error)
}

export function changeOptionDrawingLayer(target, name, options, success, error) {
  VideoPlayer.changeOptionDrawingLayer(target, name, options, success, error)
}

export function startRTMPStreaming(target, url, key, options, statusListener, success, error) {
  VideoPlayer.startRTMPStreaming(target, url, key, options, statusListener, success, error)
}

export function stopRTMPStreaming(target, success, error) {
  VideoPlayer.stopRTMPStreaming(target, success, error)
}

export function changeDeepestLayerStyle(style, success, error) {
  VideoPlayer.changeDeepestLayerStyle(style, success, error)
}

export function getPlayerCurrentProgramDateTime(target, options, success, error) {
  VideoPlayer.getPlayerCurrentProgramDateTime(target, options, success, error)
}

export function movieExport(recordingMethod, options, progressListener, success, error) {
  VideoPlayer.movieExport(recordingMethod, options, progressListener, success, error)
}

export function cancelMovieExport(success, error) {
  VideoPlayer.cancelMovieExport(success, error)
}

export function addPlayerEventListener(target, playerEventListener, success, error) {
  VideoPlayer.addPlayerEventListener(target, playerEventListener, success, error)
}
export function saveImage(imageInfo, success, error) {
  VideoPlayer.saveImage(imageInfo, success, error)
}
/* eslint-enable */
