var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-steering-angle-parts" }, [
    _c("img", {
      staticClass: "telemetry-steering-angle-parts__base",
      attrs: {
        src: require("@/assets/img/RaceVideoPage/telemetry/steering_base.svg"),
        alt: "",
      },
    }),
    _c("img", {
      staticClass: "telemetry-steering-angle-parts__handle",
      style: "transform: rotate(" + _vm.value * -1 + "deg)",
      attrs: {
        src: require("@/assets/img/RaceVideoPage/telemetry/steering_handle.svg"),
        alt: "",
      },
    }),
    _c("p", { staticClass: "telemetry-steering-angle-parts__data" }, [
      _c(
        "span",
        { staticClass: "telemetry-steering-angle-parts__data__number" },
        [_vm._v(_vm._s(Math.floor(_vm.viewValue)))]
      ),
      _c(
        "span",
        { staticClass: "telemetry-steering-angle-parts__data__unit" },
        [_vm._v("°")]
      ),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "telemetry-steering-angle-parts__label" }, [
      _c("span", [_vm._v("Steering")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }