import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoPlanDocument, {
  SavePlanType,
} from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * 契約情報契約プランデータを操作するための処理を提供する。
 */

export default function useContractInfoPlan() {
  // Collection modules
  const contractInfoPlanCollectionModule = CollectionModule.createStore(ContractInfoPlanDocument)

  /**
   * 契約情報契約プラン情報一覧を取得する。
   * @return APIレスポンス
   */
  const fetchContractInfoPlans = (contractInfoId: string) =>
    contractInfoPlanCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/plans`,
    })

  /**
   * 取得した契約情報契約プラン情報一覧
   */
  const contractInfoPlans = computed(() => contractInfoPlanCollectionModule.data)

  /**
   * 将来のプラン情報
   * 開始日が現在時刻より大きいプラン
   */
  const futureContactInfoPlan = computed(() =>
    contractInfoPlanCollectionModule.data.find((contractInfoPlan) => {
      if (!!contractInfoPlan.startDate && contractInfoPlan.startDate > new Date().getTime()) {
        // 終了日が既に存在していて開始日>終了日となっているものはキャンセル済みプランのため将来のプランではない
        return !(
          !!contractInfoPlan.endDate && contractInfoPlan.startDate > contractInfoPlan.endDate
        )
      }
      return false
    }),
  )

  // methods
  /**
   * 契約情報契約プランを保存する。
   * @return APIレスポンス
   */
  const saveContractInfoPlan = async (contractInfoId: string, plans: Array<SavePlanType>) => {
    const requestData = new ContractInfoPlanDocument({
      plans,
    })
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/plans`,
    }
    return contractInfoPlanCollectionModule.save(requestData, options)
  }

  /**
   * 契約情報契約プランを更新する。（契約中のプランを終了する）
   *
   * @return APIレスポンス
   */
  const updateContractInfoPlan = async (
    contractInfoId: string,
    DocumentId: string,
    endDate: number,
  ) => {
    const requestData = new ContractInfoPlanDocument({
      id: contractInfoId,
      endDate,
    })
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/plans/${DocumentId}`,
    }
    return contractInfoPlanCollectionModule.save(requestData, options)
  }

  /**
   * 取得した契約情報契約プラン情報をクリアする
   */
  const clearContractInfoPlans = () => {
    contractInfoPlanCollectionModule.clearData()
  }

  return {
    fetchContractInfoPlans,
    contractInfoPlans,
    futureContactInfoPlan,
    saveContractInfoPlan,
    updateContractInfoPlan,
    clearContractInfoPlans,
  }
}
