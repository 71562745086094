












































import { defineComponent, PropType, ref } from '@vue/composition-api'
import RaceListItemParts from '@/components/RaceListPage/RaceListPane/parts/RaceListItemParts.vue'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * レース一覧画面: レース選択 コンポーネント
 */
export default defineComponent({
  name: 'RaceListSection',
  components: {
    RaceListItemParts,
  },
  props: {
    championships: {
      type: Array as PropType<Array<ChampionshipDocument>>,
      required: true,
      default: () => [],
    },
    currentChampionshipId: {
      type: String,
      required: true,
      default: '',
    },
    enabledRaceListCarousel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const enabledCarouselPrev = ref<boolean>(false)
    const enabledCarouselNext = ref<boolean>(false)

    return {
      enabledCarouselPrev,
      enabledCarouselNext,
    }
  },
  mounted() {
    // 読込時に表示判定
    setTimeout(() => this.checkCarouselEnabled(), 500)

    // リサイズ時に表示判定
    window.addEventListener('resize', this.checkCarouselEnabled)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkCarouselEnabled)
  },
  watch: {
    /**
     * レース選択画面で、指定年を変更した際にも、ページャー有効化判定を行うために監視する
     */
    championships(value) {
      if (value.length > 0) {
        this.$nextTick(() => {
          this.checkCarouselEnabled()
        })
      }
    },
  },
  methods: {
    championshipLogoPath(championship: ChampionshipDocument) {
      return CloudFrontUtil.getSignedUrl(championship.championshipLogo)
    },
    push(championship: ChampionshipDocument) {
      /**
       * ボタンが押下されたことを通知する
       * @event change
       * @type ChampionshipDocument
       */
      this.$emit('change', championship)
    },
    /**
     * カルーセルクリックアクション
     * @param type
     */
    onclickCarousel(type: 'prev' | 'next'): void {
      // スクロールターゲット
      const raceLinkList = this.$refs.selectRaceSectionListWrapper as HTMLElement

      // スクロール量取得
      let scrollValue =
        -(document.querySelector('.select-race-section__list__item-0') as HTMLElement).clientWidth -
        8

      // nextの場合値をマイナス値へ
      scrollValue *= type === 'next' ? -1 : 1

      // スクロール実行
      raceLinkList.scroll({
        left: raceLinkList.scrollLeft + scrollValue,
        behavior: 'smooth',
      })
    },
    /**
     * ページャー有効化判定
     */
    checkCarouselEnabled() {
      const LIST_PADDING = 50
      const raceLinkList = this.$refs.selectRaceSectionListWrapper as HTMLElement

      this.enabledCarouselPrev = raceLinkList.scrollLeft > LIST_PADDING
      this.enabledCarouselNext =
        raceLinkList.scrollLeft < raceLinkList.scrollWidth - raceLinkList.clientWidth - LIST_PADDING
    },
  },
})
