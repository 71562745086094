import axios, { AxiosRequestConfig } from 'axios'
import APIResponse from '@/util/APIResponse'
import Logger from '@/util/logger/Logger'
import { saveImage } from '@/util/videoplayer'
import FileConverter from '@/util/fileConverter/FileConverter'
import HTTPClient from '@/util/http/HTTPClient'
import HTTPClientResponse from '@/util/http/HTTPClientResponse'

/**
 * saveImage引数：imageInfoの型
 */
type ImageInfo = {
  base64ImageData: string
  displayName?: string
}

type SuccessResponseType = {
  errorCode: string
  message: string
  detail: string
  action: string
  filePath: string
  fileSize: string
}

type ErrorResponseType = {
  errorCode: string
  message: string
  detail: string
  action: string
}

type SaveImageResponseType = {
  isSuccess: boolean
  response: ErrorResponseType | SuccessResponseType
}

type loadFileToDownloadForCordovaResponseType = {
  loadFile?: HTTPClientResponse
  saveImage?: SaveImageResponseType
}

/**
 * ダウンロードに関する処理をまとめるhook
 */
export default function useDownload() {
  const httpClient = new HTTPClient()
  /**
   * スマホアプリ：カメラロールへの書き込み権限があるか確認し、ない場合はリクエストする
   */
  const checkPermission = async () =>
    new Promise((resolve) => {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line prefer-destructuring
      const permissions = cordova.plugins.permissions
      const permissionList = [permissions.WRITE_EXTERNAL_STORAGE, permissions.WRITE_MEDIA_IMAGES]

      permissions.checkPermission(
        permissionList,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (status: any) => {
          if (!status.hasPermission) {
            permissions.requestPermissions(
              permissionList,
              () => {
                Logger.info('useDownload#checkPermission: Success request permission.')
                resolve(true)
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (error: any) => {
                Logger.error(
                  `useDownload#checkPermission: Failed request permission. error: ${JSON.stringify(
                    error,
                  )}`,
                )
                resolve(false)
              },
            )
          } else {
            resolve(true)
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          Logger.error(
            `useDownload#checkPermission: Failed check permission. error: ${JSON.stringify(error)}`,
          )
          resolve(false)
        },
      )
    })
  /**
   * 作成した一時ファイルを削除する
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeFile = (fileEntry: any) => {
    fileEntry.remove(
      () => {
        Logger.info('useDownload#downloadFileForCordova: Remove temporary file.')
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-param-reassign
      (removeError: any) => {
        Logger.info(
          `useDownload#downloadFileForCordova: Failed remove temporary file. ${JSON.stringify(
            removeError,
          )}`,
        )
      },
    )
  }
  /**
   * スマホアプリ：一時ファイルを作成する
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createTemporaryFileForCordova = (name: string, data: Blob): Promise<any> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Promise((resolve) => {
      window.requestFileSystem(
        LocalFileSystem.TEMPORARY,
        0,
        (fileSystem) => {
          fileSystem.root.getFile(
            name,
            { create: true, exclusive: false },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (fileEntry: any) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              fileEntry.createWriter((fileWriter: any) => {
                // eslint-disable-next-line no-param-reassign
                fileWriter.onwriteend = () => {
                  Logger.info('useDownload#downloadFileForCordova: Success write file.')
                  resolve(fileEntry)
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-param-reassign
                fileWriter.onerror = (error: any) => {
                  Logger.error(
                    `useDownload#downloadFileForCordova: Failed write file. error: ${JSON.stringify(
                      error,
                    )}`,
                  )
                  resolve(null)
                }
                fileWriter.write(data)
              })
            },
            (error) => {
              Logger.error(
                `useDownload#downloadFileForCordova: Failed unavailable getFile. error: ${JSON.stringify(
                  error,
                )}`,
              )
              resolve(null)
            },
          )
        },
        (error) => {
          Logger.error(
            `useDownload#downloadFileForCordova: Failed unavailable requestFileSystem. error: ${JSON.stringify(
              error,
            )}`,
          )
          resolve(null)
        },
      )
    })
  /**
   * スマホアプリ：fileをダウンロードして、端末に保存する
   */
  const downloadFileForCordova = (base64ImageData: string): Promise<SaveImageResponseType> => {
    const imageInfo = { base64ImageData } as ImageInfo
    return new Promise((resolve) => {
      saveImage(
        imageInfo,
        (response: SuccessResponseType) => {
          Logger.info('useDownload#downloadFileForCordova success')
          const result: SaveImageResponseType = {
            isSuccess: true,
            response,
          }
          resolve(result)
        },
        (error: ErrorResponseType) => {
          Logger.debug(
            `useDownload#downloadFileForCordova errorCode: ${error.errorCode} errorMessage: ${error.message}`,
          )
          const result: SaveImageResponseType = {
            isSuccess: false,
            response: error,
          }
          resolve(result)
        },
      )
    })
  }

  /**
   * fileをダウンロードして、ブラウザからユーザーがダウンロードできるようにする
   */
  const downloadFile = (url: string, fileName: string) => {
    // aタグを作成
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    // aタグをクリックしてファイルをダウンロード
    a.click()
    // aタグをドキュメントから削除
    a.remove()
  }

  /**
   * fileをダウンロードする
   */
  const loadFile = async <T>(url: string, config: AxiosRequestConfig) => {
    try {
      const response = await axios.get<T>(url, config)
      return new APIResponse(response)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new APIResponse(e as any)
    }
  }

  /**
   * fileをダウンロードして、ブラウザからユーザーがダウンロードできるようにする
   */
  const loadFileToDownload = async (fileName: string, url: string, config: AxiosRequestConfig) => {
    const result = await loadFile<ArrayBuffer>(url, config)
    if (!result.isSuccess) {
      return false
    }
    const blob = new Blob([result?.data], { type: config.headers?.accept })
    const objectUrl = URL.createObjectURL(blob)

    downloadFile(objectUrl, fileName)
    // 使用したURLオブジェクトを解放
    URL.revokeObjectURL(objectUrl)

    return true
  }

  /**
   * スマホアプリ：fileをダウンロードして、端末に保存する
   */
  const loadFileToDownloadForCordova = async (
    url: string,
  ): Promise<loadFileToDownloadForCordovaResponseType> => {
    const result = await httpClient.loadImageForCordovaApp(url)
    if (result.status !== 200) {
      return {
        loadFile: result,
      }
    }
    // 画像ファイルをbase64に変換
    const base64 = (await FileConverter.convertBlobToBase64(
      new Blob([result?.data], { type: 'image/png' }),
    )) as string
    const saveImageResult = await downloadFileForCordova(
      base64.replace('data:image/png;base64,', ''),
    )
    return {
      saveImage: saveImageResult,
    }
  }

  return {
    loadFile,
    loadFileToDownload,
    loadFileToDownloadForCordova,
    removeFile,
    downloadFile,
    downloadFileForCordova,
    checkPermission,
    createTemporaryFileForCordova,
  }
}
