import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import I18n from '@/locales/I18n'

/**
 * カレンダー種別の型
 */
export type CalendarType = 'MotorSport'

/**
 * モータースポーツカレンダーデータを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3722903870/12
 */
export default class CalendarDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<CalendarDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * カレンダー種別
   * DynamoDB calendar-dataのパーティションキー
   */
  calendarType: CalendarType | null = null

  /**
   * イベント日付
   * DynamoDB calendar-dataのソートキー
   */
  eventDate = 0

  /**
   * イベント名日本語
   */
  eventJa: string | null = null

  /**
   * イベント名英語
   */
  eventEn: string | null = null

  /**
   * 場所日本語
   */
  placeJa: string | null = null

  /**
   * 場所英語
   */
  placeEn: string | null = null

  /**
   * カテゴリ日本語
   */
  categoryJa: string | null = null

  /**
   * カテゴリ英語
   */
  categoryEn: string | null = null

  /**
   * 場所URL
   */
  placeLink?: string | null = null

  /**
   * カテゴリURL
   */
  categoryLink?: string | null = null

  /**
   * 作成日時
   */
  createdDate: number | null = null

  /**
   * イベント名
   */
  get eventName() {
    return I18n.locale === 'ja' ? this.eventJa : this.eventEn
  }

  /**
   * 場所名
   */
  get placeName() {
    return I18n.locale === 'ja' ? this.placeJa : this.placeEn
  }

  /**
   * カテゴリ名
   */
  get categoryName() {
    return I18n.locale === 'ja' ? this.categoryJa : this.categoryEn
  }
}
