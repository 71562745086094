var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M1 2.2854C0.447715 2.2854 0 2.73312 0 3.2854V15.2854C0 15.8377 0.447716 16.2854 1 16.2854H17C17.5523 16.2854 18 15.8377 18 15.2854V3.2854C18 2.73312 17.5523 2.2854 17 2.2854H1ZM2 4.2854H4V6.2854H2V4.2854ZM8 5.2854L15 9.2854L8 13.2854V5.2854ZM4 12.2854H2V14.2854H4V12.2854ZM2 8.2854H4V10.2854H2V8.2854Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }