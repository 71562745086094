









































import { defineComponent } from '@vue/composition-api'

/**
 * マイページ: トップ リンクリスト パーツ
 */
export default defineComponent({
  name: 'MypageLinkListParts',
  props: {
    /**
     * リンク情報リスト
     */
    links: {
      type: Array,
      required: true,
    },
  },
  setup(_, { emit }) {
    const handleOpenModalClicked = (modalId: string) => {
      emit('onClickOpenModal', modalId)
    }
    return {
      handleOpenModalClicked,
    }
  },
})
