var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-ranking-self-not-ranked-parts" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "mission-ranking-self-not-ranked-parts__self" },
      [
        _c("mission-ranking-table-item-parts", {
          attrs: {
            userData: _vm.selfData,
            isOwnRank: _vm.isOwnRank,
            retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
            rankingType: _vm.rankingType,
          },
          on: {
            onClickSnsButton: _vm.onClickSnsButton,
            onClickShareIcon: _vm.onClickShareIcon,
            savePostSnsMissionHistory: function ($event) {
              return _vm.$emit("savePostSnsMissionHistory")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mission-ranking-self-not-ranked-parts__omitted" },
      [
        _c(
          "p",
          {
            staticClass: "mission-ranking-self-not-ranked-parts__omitted-data",
          },
          [_vm._v("・・・")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }