import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

export type ResultDataType = {
  carNo: string
  earnPoints: string
  earnPointsNum: number
  race: string
  raceNum: number
  qualifying: string
  qualifyingNum: number
  topSpeed: string
  topSpeedNum: number
  bestLap: string
  bestLapNum: number
  sec1BestTime: string
  sec1BestTimeNum: number
  sec2BestTime: string
  sec2BestTimeNum: number
  sec3BestTime: string
  sec3BestTimeNum: number
  sec4BestTime: string
  sec4BestTimeNum: number
}

/**
 * Resultデータ情報を表現するクラス。
 * @see https://docs.google.com/spreadsheets/d/1Qq3QJ6kVCrVcPOzb8nQBEBOK51cnRPFuLvXKcd9zPcw/edit#gid=2021855439
 */
export default class ResultDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ResultDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = ''

  idAttr = 'seasonYear'

  /**
   * 年度
   */
  seasonYear: number | null = null

  /**
   * 大会マスタID
   */
  championshipMasterId: string | null = null

  /**
   * リザルトデータ
   */
  results: Array<Partial<ResultDataType>> | null = null

  /**
   * 大会開催期間開始日
   */
  championshipStartDate: number | null = null

  /**
   * 作成日時
   */
  createdDate: number | null = null

  /**
   * 更新日時
   */
  lastModifiedDate: number | null = null
}
