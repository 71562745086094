// 前回の動画長
let previousDuration = 0
// 前回の遅延時間
let previousDelayTime = 0

/**
 * サーキットモードの際に、動画再生位置の実時間を計算する。
 * @param now 現在日時のミリ秒
 * @param duration 動画長のミリ秒
 * @param currentTime 現在の再生時間のミリ秒
 * @param recordingStartTime 撮影開始時刻のミリ秒
 * @param marginTime 遅延時間のマージンのミリ秒
 */
const calculateVideoTrackDateTimeForCircuitMode = (
  now: number,
  duration: number,
  currentTime: number,
  recordingStartTime: number,
  marginTime = 0,
) => {
  if (!recordingStartTime || !currentTime) return 0

  // 遅延時間を計算する
  // 前回の動画長と今回の動画長が同じ場合は、前回の遅延時間を使用する
  let delayTime =
    previousDuration === duration ? previousDelayTime : now - (recordingStartTime + duration)

  // 遅延時間がマイナスの場合は0にする
  if (delayTime < 0) delayTime = 0

  // 前回の動画長を設定
  previousDuration = duration
  // 前回の遅延時間を設定
  previousDelayTime = delayTime

  return recordingStartTime + currentTime + delayTime - marginTime
}

export default calculateVideoTrackDateTimeForCircuitMode
