import { computed, Ref, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import BillingInfoDocument from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { PaymentScheduleData } from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractPlan from '@/store/hook/useContractPlan'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useMonthlyPlanFirstProRatedBilling from '@/components/pc/MyPage/CompletePane/hook/useMonthlyPlanFirstProRatedBilling'
import usePeriodOfUse from '@/components/MypageContractPage/hook/usePeriodOfUse'
import type { ContractTermStringType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import useContractTerm from '@/components/hook/useContractTerm'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * 請求予定カードに表示するための必要なデータの型（年額プラン用）
 */
type AnnualPlanPaymentScheduleDataType = {
  nextSettlementDateForTitle: string
  planName: string
  billingStartDate: number | null | undefined
  billingEndDate: number | null | undefined
  nextPaymentPrice: string
  nextSettlementDate: number | null | undefined
}

/**
 * 今後の請求予定に関するHook
 */
export default function usePaymentScheduleOnRegisterCoupon() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
  const { fetchContractInfo, ownContractInfo } = useContractInfo()
  const { fetchContractInfoPlans, futureContactInfoPlan } = useContractInfoPlan()
  const { fetchProvisionalBillingInfo, ownAnnualBillingInfo, ownMonthlyBillingInfo } =
    useBillingInfo()
  const { fetchContractPlans, annualPlanPriceJpy, monthlyPlanPriceJpy, getTargetContractPlan } =
    useContractPlan()
  const { getScheduledContractTerm } = useContractTerm()
  const { getDisplayDate } = useDisplayDependingOnLang()
  const { getFirstPaymentInfo } = useMonthlyPlanFirstProRatedBilling()
  const { getPeriodOfUseForTargetBilling } = usePeriodOfUse()

  /**
   * 将来のプラン
   */
  const futurePlan = computed(() =>
    futureContactInfoPlan?.value?.contractPlanId
      ? getTargetContractPlan(futureContactInfoPlan?.value?.contractPlanId)
      : null,
  )

  /**
   * 契約予定のプランの契約期間
   */
  const scheduledContractTerm = computed(() =>
    futurePlan.value?.planGroupId
      ? getScheduledContractTerm(
          effectiveContractPlan as ContractPlanDocument,
          effectiveOrgContract as ContractType,
          futurePlan.value.planGroupId,
        )
      : null,
  )

  /**
   * 請求情報から年額プランの今後の請求予定を取得する
   */
  const getAnnualPaymentScheduleDataFromBilling = (
    annualBillingInfo: BillingInfoDocument | undefined,
  ) => {
    // 年額プランの決済日（タイトル表示用）
    const nextSettlementDateForTitle = getDisplayDate(
      annualBillingInfo?.billingDate,
      'M/D',
      'MMM D',
    )
    // 次の支払い金額
    const nextPaymentPrice = annualBillingInfo?.total
      ? annualBillingInfo.total.toLocaleString()
      : ''

    // 年額プランの請求情報の場合、年額プランのcontractPlanIdを取得できる想定
    const annualBillingInfoPlan = annualBillingInfo?.plans?.find((v) => !!v?.price && v.price > 0)
    // プラン名を取得
    const planName = annualBillingInfoPlan?.contractPlanId
      ? getTargetContractPlan(annualBillingInfoPlan?.contractPlanId)?.name[I18n.locale] ?? ''
      : ''

    return {
      nextSettlementDateForTitle,
      planName,
      billingStartDate: annualBillingInfoPlan?.startDate,
      billingEndDate: annualBillingInfoPlan?.endDate,
      nextPaymentPrice,
      nextSettlementDate: annualBillingInfo?.billingDate,
    } as AnnualPlanPaymentScheduleDataType
  }

  /**
   * プラン情報、契約プラン情報から年額プランの今後の請求予定を取得する
   */
  const getAnnualPaymentScheduleDataFromPlan = (AnnualPlanPrice: number) => {
    // 年額プランの決済日（タイトル表示用）
    const nextSettlementDateForTitle = getDisplayDate(
      scheduledContractTerm.value?.contractStartDate,
      'M/D',
      'MMM D',
    )

    return {
      nextSettlementDateForTitle,
      planName: futurePlan.value?.name[I18n.locale],
      billingStartDate: scheduledContractTerm.value?.contractStartDate,
      billingEndDate: scheduledContractTerm.value?.contractEndDate,
      nextPaymentPrice: AnnualPlanPrice.toLocaleString(),
      nextSettlementDate: scheduledContractTerm.value?.contractStartDate,
    } as AnnualPlanPaymentScheduleDataType
  }

  /**
   * 年額プランの請求予定
   * プラン登録画面/（無料から）有料プランに切り替える画面
   * 年額クーポンを使ってプラン登録した場合の処理
   */
  const displayPaymentScheduleAfterAnnualCoupon = async (
    annualBillingInfo: BillingInfoDocument | undefined,
    AnnualPlanPrice: number,
  ) => {
    /**
     * 年額プランの請求情報を取得する
     * 請求情報が存在する場合は請求情報から取得し、請求情報が存在しない場合はプランマスタの料金を表示する
     * 翌月以降に年額プランに切り替わる場合であっても年額プラン利用分の請求情報が作成されるが、念のため請求金額が1円以上という条件もチェックしている
     */
    const paymentScheduleData =
      annualBillingInfo && annualBillingInfo.total > 0
        ? getAnnualPaymentScheduleDataFromBilling(annualBillingInfo)
        : getAnnualPaymentScheduleDataFromPlan(AnnualPlanPrice)

    return [
      {
        title: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlanForAnnual.card.title', {
          settlementDate: paymentScheduleData.nextSettlementDateForTitle,
          plan: paymentScheduleData.planName,
        }).toString(),
        period: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlanForAnnual.card.period', {
          startDate: getDisplayDate(paymentScheduleData.billingStartDate),
          endDate: getDisplayDate(paymentScheduleData.billingEndDate),
        }).toString(),
        price: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlanForAnnual.card.price', {
          price: paymentScheduleData.nextPaymentPrice,
        }).toString(),
        description: I18n.t(
          'SignupPage.SignupCompletePage.limitedTimePaidPlanForAnnual.card.description',
          {
            debitDate: getDisplayDate(paymentScheduleData.nextSettlementDate),
          },
        ).toString(),
        note: '',
      },
    ]
  }

  /**
   * 請求情報から月額プランの今後の請求予定を取得する
   */
  const getMonthlyPaymentScheduleDataFromBilling = (
    monthlyBillingInfo: BillingInfoDocument | undefined,
  ) => {
    // 月額プランの決済日
    const firstSettlementDateNum = monthlyBillingInfo?.billingDate ?? 0
    // 次の支払い金額
    const firstPaymentPrice = monthlyBillingInfo?.total
      ? monthlyBillingInfo.total.toLocaleString()
      : ''

    // 年額プランの請求情報の場合、年額プランのcontractPlanIdを取得できる想定
    const monthlyBillingInfoPlan = monthlyBillingInfo?.plans?.find((v) => !!v?.price && v.price > 0)
    // プラングループIDを取得
    const planGroupId = monthlyBillingInfoPlan?.contractPlanId
      ? getTargetContractPlan(monthlyBillingInfoPlan?.contractPlanId)?.planGroupId
      : ''

    // 利用分の期間
    const periodOfUse = getPeriodOfUseForTargetBilling(
      planGroupId,
      monthlyBillingInfoPlan?.startDate,
      monthlyBillingInfoPlan?.endDate,
      'YYYY/MM/DD',
      'MMM DD, YYYY',
      true,
    ) as ContractTermStringType

    return {
      price: firstPaymentPrice,
      settlementDate: firstSettlementDateNum,
      billingStartDate: periodOfUse.contractStartDate,
      billingEndDate: periodOfUse.contractEndDate,
    }
  }

  /**
   * 月額プランの請求予定
   * プラン登録画面/（無料から）有料プランに切り替える画面
   * 月額クーポンを使ってプラン登録した場合の処理
   */
  const displayPaymentScheduleAfterMonthlyCoupon = async (
    contractInfoId: string,
    monthlyBillingInfo: BillingInfoDocument | undefined,
    monthlyPlanPrice: number,
  ) => {
    /** 今後の請求情報 */
    const paymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)

    /**
     * 月額プランの初回請求情報（日割り分）を取得する
     * 請求情報が存在しかつ請求金額が1円以上の場合は請求情報から取得し、請求情報が存在しない場合は日割り料金を計算する
     * 翌月以降に月額プランに切り替わる場合は今月利用分の請求金額が0円で請求情報が作成されるため、請求金額が1円以上という条件もチェックしている
     */
    const firstPaymentInfo =
      monthlyBillingInfo && monthlyBillingInfo.total > 0
        ? getMonthlyPaymentScheduleDataFromBilling(monthlyBillingInfo)
        : await getFirstPaymentInfo(futureContactInfoPlan.value, contractInfoId)

    if (!firstPaymentInfo) {
      // 初回の支払い金額を取得できなかった場合は請求予定を表示しない
      return null
    }

    /**
     * 初月の請求情報を作成
     */
    // 初月の決済日
    const firstSettlementDate = getDisplayDate(firstPaymentInfo.settlementDate)
    // 初月の請求対象期間開始日
    // 請求情報から取得する場合はusePeriodOfUseで表示用の日付（string型）に変換しているため、
    const firstBillingStartDate =
      typeof firstPaymentInfo.billingStartDate === 'string'
        ? firstPaymentInfo.billingStartDate
        : getDisplayDate(firstPaymentInfo.billingStartDate)
    // 初月の請求対象期間終了日
    // 請求情報から取得する場合はusePeriodOfUseで表示用の日付（string型）に変換しているため、
    const firstBillingEndDate =
      typeof firstPaymentInfo.billingEndDate === 'string'
        ? firstPaymentInfo.billingEndDate
        : getDisplayDate(firstPaymentInfo.billingEndDate)

    // 初月の請求情報
    paymentSchedule.value = [
      {
        title: I18n.tc('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.title1'),
        period: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.period', {
          startDate: firstBillingStartDate,
          endDate: firstBillingEndDate,
        }).toString(),
        price: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.price', {
          price: firstPaymentInfo.price,
        }).toString(),
        description: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.description1', {
          debitDate: firstSettlementDate,
        }).toString(),
        note: '',
      },
    ]

    /**
     * 2ヶ月目以降の請求情報を作成
     */

    /**
     * 2ヶ月目の決済日
     * UTC基準で月初の0時を取得
     */
    const secondSettlementDate = getDisplayDate(
      dayjs.tz(firstPaymentInfo.settlementDate).add(1, 'M').startOf('month').valueOf(),
    )

    // 2ヶ月目以降の請求情報
    paymentSchedule.value?.push({
      title: I18n.tc('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.title2'),
      period: '',
      price: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.price', {
        price: monthlyPlanPrice.toLocaleString(),
      }).toString(),
      description: I18n.t('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.description2', {
        debitDate: secondSettlementDate,
      }).toString(),
      note: I18n.tc('SignupPage.SignupCompletePage.limitedTimePaidPlan.card.note'),
    })

    return paymentSchedule.value
  }

  /**
   * プラン登録画面/（無料から）有料プランに切り替える画面に表示する次の請求予定情報を返す
   * クーポンを使ってプラン登録した場合の処理
   */
  const getPaymentScheduleOnRegisterCoupon = async () => {
    await Promise.all([fetchContractInfo(), fetchContractPlans(), fetchProvisionalBillingInfo()])

    const { contractInfoId } = ownContractInfo.value

    if (!contractInfoId) {
      return null
    }

    /* 契約情報契約プラン情報を取得 */
    await fetchContractInfoPlans(contractInfoId)

    if (futurePlan.value?.planGroupId === 'annualPlan') {
      // 年額クーポンを使ってプラン登録を行った場合
      return displayPaymentScheduleAfterAnnualCoupon(
        ownAnnualBillingInfo.value,
        annualPlanPriceJpy.value,
      )
    }
    if (futurePlan.value?.planGroupId === 'monthlyPlan') {
      // 月額クーポンを使ってプラン登録を行った場合
      return displayPaymentScheduleAfterMonthlyCoupon(
        contractInfoId,
        ownMonthlyBillingInfo.value,
        monthlyPlanPriceJpy.value,
      )
    }

    // 上記以外
    return null
  }

  /**
   * プラン登録画面/（無料から）有料プランに切り替える画面に表示する注意メッセージに必要なデータを返す
   * NOTE: 事前にgetPaymentScheduleOnRegisterCouponを呼び、契約情報や契約プラン情報を取得していることが前提
   */
  const getDataRequiredForAttention = () => ({
    changeDate: getDisplayDate(scheduledContractTerm.value?.contractStartDate),
    plan: futurePlan.value?.name[I18n.locale],
  })

  return {
    getPaymentScheduleOnRegisterCoupon,
    getDataRequiredForAttention,
  }
}
