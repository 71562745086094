var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inputType === "checkbox"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        staticClass: "atom-input-password",
        class: { "input--validation-error": _vm.error },
        attrs: {
          readonly: _vm.isReadOnly,
          id: _vm.id || null,
          placeholder: _vm.placeholder,
          type: "checkbox",
        },
        domProps: {
          checked: Array.isArray(_vm.inputValue)
            ? _vm._i(_vm.inputValue, null) > -1
            : _vm.inputValue,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.emitEnter.apply(null, arguments)
          },
          focus: _vm.emitFocus,
          blur: _vm.emitBlur,
          change: function ($event) {
            var $$a = _vm.inputValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputValue = $$c
            }
          },
        },
      })
    : _vm.inputType === "radio"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        staticClass: "atom-input-password",
        class: { "input--validation-error": _vm.error },
        attrs: {
          readonly: _vm.isReadOnly,
          id: _vm.id || null,
          placeholder: _vm.placeholder,
          type: "radio",
        },
        domProps: { checked: _vm._q(_vm.inputValue, null) },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.emitEnter.apply(null, arguments)
          },
          focus: _vm.emitFocus,
          blur: _vm.emitBlur,
          change: function ($event) {
            _vm.inputValue = null
          },
        },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        staticClass: "atom-input-password",
        class: { "input--validation-error": _vm.error },
        attrs: {
          readonly: _vm.isReadOnly,
          id: _vm.id || null,
          placeholder: _vm.placeholder,
          type: _vm.inputType,
        },
        domProps: { value: _vm.inputValue },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.emitEnter.apply(null, arguments)
          },
          focus: _vm.emitFocus,
          blur: _vm.emitBlur,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputValue = $event.target.value
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }