








































import { defineComponent, ref } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageUserConfirmPane from '@/components/MypageUserConfirmPage/MypageUserConfirmPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import ConfirmLeavingPageModalSection from '@/components/common/modal/ConfirmLeavingPageModalSection.vue'

export type DataType = {
  confirmLeavingModalVisible: boolean
  screenTransitionTo: string
}

/**
 * マイページ: 会員情報変更内容確認ページ
 */
export default defineComponent({
  name: 'MypageUserConfirmPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    MypageUserConfirmPane,
    ActionButtonsSection,
    AtomInputButton,
    AtomRouterButton,
    ConfirmLeavingPageModalSection,
  },
  data(): DataType {
    return {
      confirmLeavingModalVisible: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '', // 画面遷移警告モーダル 遷移先指定
    }
  },
  setup() {
    const mypageUserConfirmPane = ref<InstanceType<typeof MypageUserConfirmPane>>()
    return {
      mypageUserConfirmPane,
    }
  },
  beforeRouteLeave(to, from, next) {
    /**
     * todo: データに変更ある場合のみの条件を指定
     */
    // todo: 画面遷移できなくなるので一時的にコメントアウト
    // this.screenTransitionTo = to.path
    // this.screenTransitionModal = true
    next()
  },
  methods: {
    /**
     * 変更確認通知
     * todo: 仮処理
     */
    editUserInformation(): void {
      return this.mypageUserConfirmPane?.saveUserInfo()
    },
    /**
     * 確認画面へ遷移
     * @event success-confirm
     */
    moveToComplete() {
      this.$router.replace({ path: '/mypage/user-complete' })
    },
  },
})
