/**
 * デバイスの状態を取得する機能を提供する。
 */
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'

export default class DeviceInfo {
  /**
   * アプリの動作対象としているプラットフォーム。
   * これは、アプリが、PC,モバイル向けのどちらでビルドされたものかを示す。
   */
  static PLATFORM: 'mobile' | 'pc' = 'mobile'

  /**
   * 言語設定を取得する。
   * @return 言語設定。'en' や 'ja' などが返却される。
   */
  static getLanguage() {
    const lang = navigator.language
    return lang.split('-')[0] as UserLang
  }

  /**
   * タイムゾーンの情報から、アプリが動作しているエリア情報を取得する。
   * @return {string} エリア情報。'Asia' や 'Europe' などが返却される。
   * 取得できなかった場合、空文字が返却される。
   */
  static getArea() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezone ? timezone.split('/')[0] : ''
  }

  /**
   * アプリがCordova上で動作しているかどうかかを判定する。
   *
   * @return {boolean} Cordovaで動作している場合、<code>true</code>を返す。
   */
  static isCordova() {
    return !!window.cordova
  }

  /**
   * アプリが、PC向けアプリとして動作しているかどうかを判定する。
   * このメソッドは、アプリがPC向けブラウザ上で動作しているかどうかではなく、
   * 動作しているアプリが、PCモードでビルドされたものかどうかを判定する。
   *
   * @return PC向けアプリの場合、 true を返す
   */
  static isForPC() {
    return DeviceInfo.PLATFORM === 'pc'
  }

  /**
   * アプリがモバイルデバイスで動作しているかどうかを判定する。(iPad含む)
   * アプリ / ブラウザは区別しない
   * @return {boolean} デバイスで動作している場合、<code>true</code>を返す。
   */
  static isMobile() {
    const ua = navigator.userAgent.toLowerCase()
    // ios13以降のiPadでisMobileであることを判定するためにmacintoshを含めている。
    // 詳細は以下を参照のこと。
    // @see https://www.gravity-works.jp/gravica/web/js/004655.html
    return (
      !!ua.match(/(iphone|ipod|ipad|android|blackBerry|iemobile|macintosh)/) &&
      DeviceInfo.supportTouch()
    )
  }

  /**
   * スマホで動作しているかどうかを判定する
   * アプリ / ブラウザは区別しない
   * @return {boolean}
   */
  static isSmartPhone() {
    if (process.env.DEV_SERVER) {
      // dev-serverで動作している場合、ブラウザで動作していると判断する。
      return false
    }
    const ua = navigator.userAgent.toLowerCase()
    return !!ua.match(/(iphone|ipod|android|blackBerry|iemobile)/) && DeviceInfo.supportTouch()
  }

  /**
   * デバイスがタッチ操作をサポートしているかどうかを判定する。
   * @return {boolean} タッチ操作をサポートしている場合、 true を返す。
   */
  static supportTouch() {
    return 'ontouchstart' in document.documentElement
  }

  /**
   * Cordovaの初期化が完了し、Cordovaが利用可能な状態かどうかを判定する。
   * @return Cordovaが利用可能な状態の場合、<code>true</code>を返す。
   */
  static isCordovaRunning(): boolean {
    return DeviceInfo.isCordova() && !!window.device
  }

  /**
   * アプリがiOSで動作しているかどうかを判定する。
   * @return iOSで動作している場合、<code>true</code>を返す。
   */
  static isiOS(): boolean {
    return !!this.deviceModel.match(/(iPhone|iPod|iPad)/)
  }

  /**
   * アプリがiPad実機で動作しているかどうかを判定する。(ブラウザではない)
   * @return iPad実機で動作している場合、<code>true</code>を返す。
   */
  static isiPad(): boolean {
    return !!this.deviceModel.match(/(iPad)/)
  }

  /**
   * アプリがiPhone実機で動作しているかどうかを判定する。(ブラウザではない)
   * @return iPhone実機で動作している場合、trueを返す
   */
  static isiPhone(): boolean {
    return this.isiOS() && !this.isiPad()
  }

  /**
   * アプリがiOSのブラウザで動作しているかどうかを判定する。
   * @return iOSで動作している場合、<code>true</code>を返す。
   */
  static isiOSBrowser(): boolean {
    return !this.isCordova() && !!navigator.userAgent.match(/(iPhone|iPod|iPad)/)
  }

  /**
   * アプリがApple製品のブラウザで動作しているかどうかを判定する。
   * @return iOSで動作している場合、<code>true</code>を返す。
   */
  static isAppleDeviceBrowser(): boolean {
    const ua = navigator.userAgent.toLowerCase()
    return !this.isCordova() && !!ua.match(/(iphone|ipod|ipad|macintosh)/)
  }

  /**
   * アプリがAndroid実機で動作しているかを判定する
   * @return Androidで動作している場合、<code>true</code>を返す。
   */
  static isAndroid(): boolean {
    return this.isCordova() && !!navigator.userAgent.match(/Android/)
  }

  /**
   * アプリがAndroidブラウザで動作しているかを判定する
   * @return Androidブラウザで動作している場合、<code>true</code>を返す。
   */
  static isAndroidBrowser(): boolean {
    return !this.isCordova() && !!navigator.userAgent.match(/Android/)
  }

  /**
   * ネイティブプレーヤーが利用可能かどうかを判定する
   * @return 利用可能な場合、<code>true</code>を返す。
   */
  static isNativeVideoPlayerAvailable(): boolean {
    return this.isCordova() && !!window.VideoPlayer
  }

  /**
   * アプリがどのブラウザで動作しているか判定する。
   * @return ブラウザ名を返す。
   */
  static getBrowser() {
    const ua = navigator.userAgent.toLowerCase()
    if (/msie|trident/i.test(ua)) return 'IE'
    if (/edg/i.test(ua)) return 'Edge'
    if (/chrome/i.test(ua)) return 'Chrome'
    if (/safari/i.test(ua)) return 'Safari'
    if (/firefox/i.test(ua)) return 'Firefox'
    return 'Unknown'
  }

  /**
   * cordova-plugin-deviceの device.model の値を返す。
   * アプリがcordovaで動作していない場合、空文字を返す。
   * @return device.model の値 (iPad2,5; iPhone 5 is iPhone 5,1 など)
   * @see https://github.com/apache/cordova-plugin-device#devicemodel
   */
  static get deviceModel(): string {
    return this.device ? window.device.model : ''
  }

  /**
   * cordova-plugin-deviceの device 情報を返す。
   * アプリがcordovaで動作していない場合、null を返す。
   * @see https://github.com/apache/cordova-plugin-device
   */
  static get device(): typeof window.device {
    return this.isCordova() && window.device ? window.device : null
  }

  /**
   * カスタムURL経由でアプリで開く
   * アプリがインストールされていない場合は何も起こらない
   * @param path アプリに渡すパス
   */
  static openApp(path: string) {
    if (!DeviceInfo.isCordova() && DeviceInfo.isMobile()) {
      window.location.href = `sfgo:/${path}`
    }
  }
}
