var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "notification-list-section" },
    _vm._l(_vm.notificationList, function (notification) {
      return _c("notification-item-parts", {
        key: notification.informationId,
        staticClass: "notification-list-section__item",
        attrs: { notification: notification },
        on: {
          handlerClickLink: function ($event) {
            return _vm.$emit("handlerClickLink", notification)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }