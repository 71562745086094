import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
/**
 * ポイントランキング種別の型
 */
export type PointRankingCategoryType =
  | 'TOTAL_POINT_RANKING'
  | 'MONTHLY_POINT_RANKING'
  | 'SEASON_POINT_RANKING'
export type RankInUserType = {
  userId: string
  point: number
  rank: number
}
/**
 * ポイントランキングデータを表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/3752951814/11-3
 */
export default class PointRankingDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PointRankingDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * カテゴリー
   * DynamoDB point-ranking-aggregationのパーティションキー
   */
  category: PointRankingCategoryType | null = null

  /**
   * 集計期間開始日時
   * DynamoDB point-ranking-aggregationのソートキー
   */
  aggregationStartDate = 0

  /**
   * 集計期間終了日時
   */
  aggregationEndDate = 0

  /**
   * 集計日時
   */
  aggregationDate = 0

  /**
   * ランキング
   */
  rankings: Array<RankInUserType> = []
}
