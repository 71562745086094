import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import InAppPurchaseHistoryDocument from '@/store/stores/collectionModule/documents/history/InAppPurchaseHistoryDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * アプリ内課金通知履歴を操作するための処理を取得する。
 */
export default function useInAppPurchaseHistory() {
  // Collection modules
  const inAppPurchaseHistoryCollectionModule = CollectionModule.createStore(
    InAppPurchaseHistoryDocument,
  )

  /**
   * 対象のアプリ内課金履歴情報を取得する。
   * @param inAppPurchaseId AppStore: transactionId、GooglePlay: purchaseToken
   * @return APIレスポンス
   */
  const fetchInAppPurchaseHistory = (inAppPurchaseId: string) =>
    inAppPurchaseHistoryCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/history/in_app_purchase/`,
      query: {
        filter: { token: inAppPurchaseId },
      },
    })

  /**
   * 取得したアプリ内課金履歴
   */
  const inAppPurchaseHistory = computed(() => inAppPurchaseHistoryCollectionModule.data[0])

  // methods
  /**
   * 取得したアプリ内課金履歴をクリアする
   */
  const clearInAppPurchaseHistory = () => {
    inAppPurchaseHistoryCollectionModule.clearData()
  }

  return {
    fetchInAppPurchaseHistory,
    inAppPurchaseHistory,
    clearInAppPurchaseHistory,
  }
}
