












import { computed, defineComponent } from '@vue/composition-api'

/**
 * ラジオボタンを表示するAtomコンポーネント。
 */
export default defineComponent({
  name: 'AtomInputCheckbox',
  props: {
    /**
     * Value値
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * 非活性にするかどうか
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * inputValue
     * @param {string} val チェックボックスの値
     */
    const inputValue = computed({
      get: () => props.value,
      set: (val: boolean) => context.emit('input', val),
    })

    return {
      inputValue,
    }
  },
})
