var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standings-details-page" },
    [
      _vm.targetPlayer
        ? _c("standings-player-details-pane", {
            attrs: { player: _vm.targetPlayer },
          })
        : _vm._e(),
      _vm.targetPlayer
        ? _c("standings-round-data-pane", {
            attrs: { player: _vm.targetPlayer },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }