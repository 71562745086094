import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import MathUtil from '@/util/MathUtil'
import GpsDocument from '@/store/stores/collectionModule/documents/gps/GpsDocument'
import useDynamoDB from '@/store/hook/useDynamoDB'

/**
 * 作成日時毎のGPSデータの型。
 * 作成日時をキーにして、GPSデータを値として保持するマップオブジェクトの型。
 */
type GpsHashedByCreatedDateType = Map<string, GpsDocument>

/**
 * GPSデータ取得を実施した作成日時を格納するためのマップオブジェクト
 */
const fetchedCreateDateTimes = new Map<number, boolean>()

/**
 * 取得したGPSデータを保持するマップオブジェクト
 */
const GpsHashedByCreatedDateMap = new Map<string, GpsDocument>()

/**
 * ランキング画面で参照する必要のあるストアデータの取得処理を提供する。
 */
export default function useAllPlayerGps() {
  const gpsCollectionModule = CollectionModule.createStore(GpsDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * 指定したレースの指定した期間のGPS情報を取得する。
   * @param race レース
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchGps = async (race: RaceDocument, fromDate: number, toDate: number) => {
    const result = await searchDateRangeData({
      tableName: 'GPS-data',
      partitionKey: 'raceDate',
      partitionKeyValue: dayjs(race.scheduleDate).utc().format('YYYY-MM-DD'),
      sortKey: 'createdDate',
      from: fromDate,
      to: toDate,
    })
    gpsCollectionModule.data = result as Array<GpsDocument>
  }

  /**
   * 取得するGPS情報をクリアする
   */
  const clearGps = () => {
    gpsCollectionModule.clearData()
    fetchedCreateDateTimes.clear()
    GpsHashedByCreatedDateMap.clear()
  }

  /**
   * 対象の日時のデータの取得を実施しているかどうかを判定する.
   * @param createdDate 作成日時
   */
  const isGpsFetched = (createdDate: number) => {
    const createDateRounded = MathUtil.round(createdDate, 50)
    // GPSデータは正確に50ミリ秒毎にデータが登録されない場合があるため、前後のデータも確認する
    return (
      fetchedCreateDateTimes.has(createDateRounded) ||
      fetchedCreateDateTimes.has(createDateRounded - 50) ||
      fetchedCreateDateTimes.has(createDateRounded + 50)
    )
  }

  /**
   * 取得したGPSデータをもとに、車両ごとのGPSデータを算出する。
   * "${車両番号}_${作成日時}"の値をキーとして、その車両のGPSデータが値に設定されたマップオブジェクトを返す。
   */
  const gpsHashedByCreatedDate = computed(() =>
    gpsCollectionModule.data.reduce<GpsHashedByCreatedDateType>((map, gps) => {
      if (gps.createdDate) {
        const roundedDateTime = MathUtil.round(gps.createdDate, 50)
        const hashmap = map

        hashmap.set(`${gps.carNo}_${roundedDateTime}`, gps)
        fetchedCreateDateTimes.set(roundedDateTime, true)
      }
      return map
    }, GpsHashedByCreatedDateMap),
  )

  /**
   * 指定された車両の指定した時間のGPSデータを取得する。
   * @param carNo 車両番号
   * @param createdDate GPSデータの作成日時
   */
  const getPlayerGps = (carNo: string, createdDate: number) => {
    const targetCreatedDate = MathUtil.round(createdDate, 50)
    return gpsHashedByCreatedDate.value.get(`#${carNo}_${targetCreatedDate}`)
  }

  return {
    fetchGps,
    getPlayerGps,
    clearGps,
    gpsHashedByCreatedDate,
    isGpsFetched,
  }
}
