import { computed, Ref, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import BillingInfoDocument from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import useContractTerm from '@/components/hook/useContractTerm'
import { PaymentScheduleData } from '@/components/pc/MyPage/common/PaymentScheduleSection.vue'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractPlan from '@/store/hook/useContractPlan'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT')

/**
 * 今後の請求予定に関するHook
 * プラン登録画面/（無料から）有料プランに切り替える画面で使用
 */
export default function usePaymentScheduleOnRegister() {
  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { effectiveContractPlan, effectiveOrgContract } = contractInfoStore
  const { fetchContractInfo, ownContractInfo } = useContractInfo()
  const { fetchProvisionalBillingInfo, ownBillingInfo } = useBillingInfo()
  const { fetchContractPlans, monthlyPlanPriceJpy } = useContractPlan()
  const { getCurrentContractTerm } = useContractTerm()
  const { getDisplayDate } = useDisplayDependingOnLang()

  /**
   * 契約中のプランの契約期間
   */
  const contractTerm = computed(() =>
    getCurrentContractTerm(
      effectiveContractPlan as ContractPlanDocument,
      effectiveOrgContract as ContractType,
    ),
  )

  /**
   * プラン登録画面/（無料から）有料プランに切り替える画面に表示する次の請求予定情報を返す
   */
  const displayPaymentScheduleOnRegister = async (
    billingInfo: BillingInfoDocument | undefined,
    monthlyPlanPrice: number,
  ) => {
    /** 今後の請求情報 */
    const paymentSchedule: Ref<PaymentScheduleData[] | null> = ref(null)

    /**
     * 初月の請求情報を作成
     */
    // 現在時刻を取得
    const now = dayjs().valueOf()
    // 月額プランの初月の決済日
    let firstSettlementDateNum = billingInfo?.billingDate ?? 0

    // 初月の決済日
    let firstSettlementDate = getDisplayDate(firstSettlementDateNum)
    // 初月の支払い金額
    let firstPaymentPrice = billingInfo?.total ? billingInfo.total.toLocaleString() : ''

    // 初月の請求対象期間開始日
    let firstBillingStartDate = getDisplayDate(
      dayjs(contractTerm.value.contractStartDate).valueOf(),
    )
    // 初月の請求対象期間終了日
    let firstBillingEndDate = getDisplayDate(dayjs(now).endOf('month').valueOf())

    if (!firstPaymentPrice) {
      // 初月の請求情報が0円の場合、次月の請求情報を初月請求として表示する

      // 月額プランの初月の決済日
      firstSettlementDateNum = dayjs
        .tz(firstSettlementDateNum)
        .add(1, 'M')
        .startOf('month')
        .valueOf()
      // 初月の決済日
      firstSettlementDate = getDisplayDate(firstSettlementDateNum)
      // 初月の支払い金額
      firstPaymentPrice = monthlyPlanPrice.toLocaleString()
      // 初月の請求対象期間開始日
      firstBillingStartDate = getDisplayDate(dayjs(now).add(1, 'M').startOf('month').valueOf())
      // 初月の請求対象期間終了日
      firstBillingEndDate = getDisplayDate(dayjs(now).add(1, 'M').endOf('month').valueOf())
    }

    // 初月の請求情報
    paymentSchedule.value = [
      {
        title: I18n.tc('SignupPage.SignupCompletePage.monthlyPlan.card.title1'),
        period: I18n.t('SignupPage.SignupCompletePage.monthlyPlan.card.period', {
          startDate: firstBillingStartDate,
          endDate: firstBillingEndDate,
        }).toString(),
        price: I18n.t('SignupPage.SignupCompletePage.monthlyPlan.card.price', {
          price: firstPaymentPrice,
        }).toString(),
        description: I18n.t('SignupPage.SignupCompletePage.monthlyPlan.card.description1', {
          debitDate: firstSettlementDate,
        }).toString(),
        note: '',
      },
    ]

    /**
     * 2ヶ月目以降の請求情報を作成
     */
    // 2ヶ月目の決済日
    // UTC基準で月初の0時を取得
    const secondSettlementDate = getDisplayDate(
      dayjs.tz(firstSettlementDateNum).add(1, 'M').startOf('month').valueOf(),
    )

    // 2ヶ月目以降の請求情報
    paymentSchedule.value?.push({
      title: I18n.tc('SignupPage.SignupCompletePage.monthlyPlan.card.title2'),
      period: '',
      price: I18n.t('SignupPage.SignupCompletePage.monthlyPlan.card.price', {
        price: monthlyPlanPrice.toLocaleString(),
      }).toString(),
      description: I18n.t('SignupPage.SignupCompletePage.monthlyPlan.card.description2', {
        debitDate: secondSettlementDate,
      }).toString(),
      note: I18n.tc('SignupPage.SignupCompletePage.monthlyPlan.card.note'),
    })

    return paymentSchedule.value
  }

  /**
   * プラン登録画面/（無料から）有料プランに切り替える画面に表示する次の請求予定情報を返す
   */
  const getPaymentScheduleOnRegister = async () => {
    await Promise.all([fetchContractInfo(), fetchProvisionalBillingInfo(), fetchContractPlans()])

    const { contractInfoId } = ownContractInfo.value

    if (!contractInfoId) {
      return null
    }

    return displayPaymentScheduleOnRegister(ownBillingInfo.value, monthlyPlanPriceJpy.value)
  }

  return {
    getPaymentScheduleOnRegister,
  }
}
