







import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import RaceListPane from '@/components/RaceListPage/RaceListPane.vue'

/**
 * レース一覧ページ
 */
export default defineComponent({
  name: 'RaceListPage',
  components: {
    HeaderPane,
    RaceListPane,
  },
  props: {
    /**
     * ヘッダーナビ表示指定
     */
    hasHeaderNav: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
})
