var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-list-section" },
    _vm._l(_vm.listData, function (item, i) {
      return _c(
        "select-list-item-parts",
        {
          key: i,
          staticClass: "select-list-section__item",
          attrs: { item: item, selected: _vm.selectedItemID === item.sid },
          on: {
            select: function ($event) {
              return _vm.changeSelected(item.sid)
            },
          },
        },
        [_vm._t("default", null, { item: item })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }