


































import { computed, defineComponent, PropType } from '@vue/composition-api'
import RacePlayerInfoSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RacePlayerInfoSection.vue'
import RaceTelemetrySection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RaceTelemetrySection.vue'
import PlayerSelectModalSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/PlayerSelectModalSection.vue'
import RaceSelectSection from '@/components/RaceVideoPage/RaceVideoDataViewPane/RaceSelectSection.vue'
import StoreUtil from '@/store/StoreUtil'
import useTelemetry from '@/components/RaceVideoPage/hook/useTelemetry'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

/**
 * レース動画再生画面 動画データ表示ペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceVideoDataViewPane',
  components: {
    RacePlayerInfoSection,
    RaceTelemetrySection,
    PlayerSelectModalSection,
    RaceSelectSection,
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * 無線音声 ON/OFFフラグ
     */
    radioVoiceEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * レース選択 開閉フラグ
     */
    raceSelectEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 選手選択モーダル表示フラグ
     */
    racePlayerSelectModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { setViewAngle, user, selectedPlayerId, isLiveNotStarted, fetchRaceData } =
      raceVideoPageStore
    const favoriteRacePlayerSelected = computed(() => !!user.value?.favoritePlayerId)
    const { getCurrentTelemetryData } = useTelemetry()

    return {
      favoriteRacePlayerSelected,
      setViewAngle,
      selectedPlayerId,
      isLiveNotStarted,
      raceVideoPageStore,
      fetchRaceData,
      getCurrentTelemetryData,
    }
  },
  mounted() {
    // お気に入り選手がいない場合に初期選択モーダル表示
    if (!this.favoriteRacePlayerSelected && !this.selectedPlayerId) {
      this.$emit('playerSelectModalShow', true)
    }
  },
  methods: {
    /**
     * 無線音声 ON/OFFボタンが押下されたことを通知する
     */
    toggleRadioVoice(): void {
      this.$emit('radioVoiceToggle')
    },
    /**
     * レース選択ボタンが押下されたことを通知する
     */
    toggleRaceSelect(state?: string): void {
      this.$emit('raceSelectToggle', state)
    },
    /**
     * 選手選択モーダル表示
     */
    showPlayerSelectModal(): void {
      this.$emit('playerSelectModalShow', true)
    },
    /**
     * 選択選手変更処理
     * @event changePlayer
     * @param submitType
     */
    changePlayer(submitType: string) {
      if (submitType === 'change') {
        // 選手を選択した場合、自動的にアングルを選手に変更する
        this.setViewAngle('player')
        // 視聴履歴取得
        this.raceVideoPageStore.saveHistory()
      }
      this.$emit('playerSelectModalShow', false)
    },
    /**
     * TOP画面に遷移する。
     */
    back() {
      this.$router.replace({ name: 'TopPage' })
    },
  },
})
