




































import { defineComponent, inject, onBeforeMount, Ref, ref } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import AwardHeaderSection from '@/components/LinkPage/AwardPane/AwardHeaderSection.vue'
import AwardRankingSection from '@/components/LinkPage/AwardPane/AwardRankingSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import useAwardData from '@/store/hook/useAwardData'
import usePlayer from '@/store/hook/usePlayer'
import useTeam from '@/store/hook/useTeam'
import StoreUtil from '@/store/StoreUtil'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import { AwardRankingCategoryType } from '@/store/stores/collectionModule/documents/award/AwardDataDocument'
import useConvertObjectKeysType from '@/components/hook/useConvertObjectKeysType'
import SelectYearSection from '@/components/RaceListPage/RaceListPane/SelectYearSection.vue'

export type AwardRankingData = {
  ranking: number
  name: string
  image: string
  time?: string
  favorite: boolean
}

type AwardAllCategoryRankingData = {
  RECOMMEND_DRIVER: Array<AwardRankingData> | undefined
  RECOMMEND_TEAM: Array<AwardRankingData> | undefined
  PIT_CREW: Array<AwardRankingData> | undefined
}

/**
 * AWARD初期データ
 */
const initialAwardData = {
  RECOMMEND_DRIVER: undefined,
  RECOMMEND_TEAM: undefined,
  PIT_CREW: undefined,
}

/**
 * Award コンテンツペイン
 */
export default defineComponent({
  name: 'AwardPane',
  components: {
    SelectYearSection,
    AwardHeaderSection,
    AwardRankingSection,
  },
  setup() {
    const userStore = StoreUtil.useStore('UserStore')
    const { user } = userStore
    const { fetchAwardData, awardListByRankingDataType } = useAwardData()
    const { fetchTargetYearPlayers, players } = usePlayer()
    const { fetchTargetYearTeams, teams } = useTeam()
    const { getKeys } = useConvertObjectKeysType()

    const loading = inject('loading') as PluginApi

    /** データ取得が完了したかどうか */
    const hasFetched = ref(false)

    /** 対象年度のAWARDデータ */
    const awardRankings: Ref<AwardAllCategoryRankingData> = ref(initialAwardData)

    /** AWARD表示対象年 */
    const targetYear = ref(new Date().getFullYear())

    /** 選択できる年 */
    const yearList = ref([] as Array<number>)
    for (let i = 2023; i <= new Date().getFullYear(); i += 1) {
      yearList.value.push(i)
    }

    /**
     * AWARD一覧に表示するデータを作成
     * 対象のランキングデータ種別のAWARDデータを作成する
     */
    const createAwardDataByCategory = (category: AwardRankingCategoryType) => {
      const rankings = awardListByRankingDataType.value[category]?.rankings
        ?.filter((r) => {
          if (category === 'RECOMMEND_DRIVER') {
            // 推しドラはTOP10を表示
            return r.ranking && r.ranking < 11
          }
          // 推しチーム、ピットクルーはTOP5を表示
          return r.ranking && r.ranking < 6
        })
        .map((r) => {
          let imagePath: string
          let isFavorite: boolean

          if (category === 'RECOMMEND_DRIVER') {
            /**
             * 推しドラの処理
             */
            // 対象選手情報を選手マスタから取得
            const targetPlayer = players.value.find(
              (player) => player.getPlayerNameEn() === r.nameEn,
            )
            // 表示画像パス
            imagePath =
              r.ranking === 1
                ? CloudFrontUtil.getSignedUrl(
                    targetPlayer?.additionalData?.raceRank?.playerImagePath,
                  )
                : CloudFrontUtil.getSignedUrl(targetPlayer?.playerPictureImagePath)

            // お気に入り設定中かどうか
            isFavorite = user.value.favoritePlayerId === targetPlayer?.sid
          } else {
            /**
             * 推しチーム、ピットクルーの処理
             */
            // 対象チーム情報を選手マスタから取得
            const targetTeam = teams.value.find((team) => team.teamName.en === r.nameEn)
            // 表示画像パス
            imagePath = CloudFrontUtil.getSignedUrl(targetTeam?.teamLogoImagePath)
            // お気に入り設定中かどうか
            isFavorite = user.value.favoriteTeamId === targetTeam?.sid
          }

          return {
            ranking: r.ranking ?? 0,
            name: I18n.locale === 'ja' ? r.nameJa ?? '' : r.nameEn ?? '',
            image: imagePath,
            time: category === 'PIT_CREW' ? r.valueDisp : undefined,
            favorite: isFavorite,
          }
        })
      return {
        [category as AwardRankingCategoryType]: rankings,
      }
    }

    /**
     * AWARDページに必要な情報を取得する
     */
    const fetchAwardPageData = async (isChange = false) => {
      const loader = loading.show()
      // 初期化
      awardRankings.value = initialAwardData

      try {
        await fetchAwardData(targetYear.value)

        if (
          !isChange &&
          !awardListByRankingDataType.value.RECOMMEND_DRIVER &&
          !awardListByRankingDataType.value.RECOMMEND_TEAM &&
          !awardListByRankingDataType.value.PIT_CREW
        ) {
          // 画面描画時に最新年のアワードを取得できなかった場合の処理

          // 最新年のアワードデータを取得できなかった場合、最新年を年プルダウンから外す
          yearList.value = yearList.value.filter((year) => year !== targetYear.value)
          // 対象年を1つ戻す
          targetYear.value -= 1
          await fetchAwardData(targetYear.value)
        }

        // 対象年の選手、チーム情報を取得する
        const results = await Promise.all([
          fetchTargetYearPlayers(targetYear.value),
          fetchTargetYearTeams(targetYear.value),
        ])
        loader.hide()

        const failedResult = results.find((result) => !result?.isSuccess)
        if (failedResult) {
          await MessageDialogStore.value.open({
            title: I18n.tc('LinkPage.AwardPage.errors.fetchAwardPageDataError.title'),
            message: I18n.tc('LinkPage.AwardPage.errors.fetchAwardPageDataError.message'),
            errorApiResponse: failedResult.response,
          })
          awardRankings.value = initialAwardData
          return
        }
        // データ取得完了にする
        hasFetched.value = true
      } catch (e) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('LinkPage.AwardPage.errors.fetchAwardPageDataError.title'),
          message: I18n.tc('LinkPage.AwardPage.errors.fetchAwardPageDataError.message'),
        })
        awardRankings.value = initialAwardData
        return
      }

      // AWARD画面表示用データ生成
      getKeys(awardListByRankingDataType.value).forEach((category) => {
        const awardDataByCategory = createAwardDataByCategory(category)
        Object.assign(awardRankings.value, awardDataByCategory)
      })
    }

    /**
     * 年が変更された場合の処理
     */
    const changeSelectYear = (year: number) => {
      targetYear.value = year
      fetchAwardPageData(true)
    }

    onBeforeMount(() => {
      fetchAwardPageData()
    })

    return {
      hasFetched,
      awardRankings,
      targetYear,
      yearList,
      changeSelectYear,
    }
  },
})
