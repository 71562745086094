var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-tire-temperature-rr-parts" }, [
    _vm.heatFlag === "cold"
      ? _c("img", {
          staticClass: "telemetry-tire-temperature-rr-parts__tire",
          attrs: {
            src: require("@/assets/img/RaceVideoPage/telemetry/tire_rear_right_cold.svg"),
            alt: "",
          },
        })
      : _vm.heatFlag === "normal"
      ? _c("img", {
          staticClass: "telemetry-tire-temperature-rr-parts__tire",
          attrs: {
            src: require("@/assets/img/RaceVideoPage/telemetry/tire_rear_right.svg"),
            alt: "",
          },
        })
      : _vm.heatFlag === "hot"
      ? _c("img", {
          staticClass: "telemetry-tire-temperature-rr-parts__tire",
          attrs: {
            src: require("@/assets/img/RaceVideoPage/telemetry/tire_rear_right_hot.svg"),
            alt: "",
          },
        })
      : _vm._e(),
    _c(
      "p",
      {
        staticClass: "telemetry-tire-temperature-rr-parts__data",
        class:
          "telemetry-tire-temperature-section__tire_temperature--" +
          _vm.heatFlag,
      },
      [
        _c(
          "span",
          { staticClass: "telemetry-tire-temperature-rr-parts__data__number" },
          [_vm._v(_vm._s(_vm.viewValue.toFixed(1)))]
        ),
        _c(
          "span",
          { staticClass: "telemetry-tire-temperature-rr-parts__data__unit" },
          [_vm._v("°C")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }