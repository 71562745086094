



































import { defineComponent, PropType } from '@vue/composition-api'
import { NotificationTypeUnion } from '@/store/stores/collectionModule/documents/notification/NotificationDocument'

/**
 * Notification: 種類別アイコン表示
 */
export default defineComponent({
  name: 'NotificationTypeIconParts',
  components: {},
  props: {
    notificationType: {
      type: String as PropType<NotificationTypeUnion>,
      required: true,
    },
  },
})
