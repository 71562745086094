import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 契約情報アプリ内課金AppStoreを表現するクラス。
 * アプリ内課金でプラン購入時、バックエンド側でAppStoreからサーバー通知を受け取れるが、購入者と組織を紐づける情報がないため、購入者と組織を紐づけるためのリクエストを送る際に利用するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/828473474/API
 */
export default class ContractInfoAppStoreDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoAppStoreDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contract_info'

  idAttr = 'contractInfoId'

  /**
   * 契約情報ID
   */
  contractInfoId: string | null = null

  /**
   * トランザクションID
   */
  transactionId: string | null = null
}
