































import { defineComponent, PropType } from '@vue/composition-api'
import type { HighlightTabsType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'

/**
 * レース動画再生画面 ハイライト表示 ハイライトタブのパーツ
 */
export default defineComponent({
  name: 'HighlightListTabParts',
  props: {
    /**
     * 選択中のハイライトタブ
     */
    selectedHighlightTab: {
      type: String as PropType<HighlightTabsType>,
      required: true,
    },
  },
  setup(_props, { emit }) {
    /**
     * 公式とユーザーの切り替えボタンが押下されたときの処理
     * @param {string} tabId
     */
    const handleTabClicked = (tabId: HighlightTabsType) => {
      emit('onClickHighlightTab', tabId)
    }

    return {
      handleTabClicked,
    }
  },
})
