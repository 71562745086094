var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("recycle-scroller", {
    staticClass: "radio-list-section",
    class: [
      {
        "radio-list-section--has-radio-data": _vm.playerRadioDataSet.length > 0,
      },
      "radio-list-section--" + _vm.screenOrientationType,
    ],
    attrs: {
      items: _vm.playerRadioDataSet,
      "item-size": _vm.isForPc ? 54 : 56,
      "key-field": "audio_clip",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("radio-list-item-parts", {
              attrs: {
                radioData: item,
                screenOrientationType: _vm.screenOrientationType,
              },
              on: { radioPlay: _vm.playRadioAudio },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }