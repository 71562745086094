var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reward-item-section" },
    [
      _c("div", { staticClass: "reward-item-section__container" }, [
        _c("div", { staticClass: "reward-item-section__image" }, [
          _c("img", { attrs: { src: _vm.signedImagePath, alt: "" } }),
        ]),
        _c("div", { staticClass: "reward-item-section__body" }, [
          _c("p", { staticClass: "reward-item-section__date" }, [
            _vm._v(_vm._s(_vm.reward.distributionDate)),
          ]),
          _c("p", {
            staticClass: "reward-item-section__message",
            domProps: {
              innerHTML: _vm._s(_vm.reward.description[_vm.$i18n.locale]),
            },
          }),
          _c("p", { staticClass: "reward-item-section__deadline" }, [
            _vm._v(
              " " + _vm._s(_vm.deadlineLabel + "：" + _vm.reward.deadline) + " "
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "reward-item-section__footer" },
        [
          _c("atom-button", {
            attrs: { linkText: _vm.buttonLabel, colorType: "secondary" },
            on: { onClick: _vm.handleClick },
          }),
        ],
        1
      ),
      _vm.videoModalEnabled && _vm.reward.videoId
        ? _c("limited-video-modal-section", {
            attrs: { videoId: _vm.reward.videoId },
            on: { close: _vm.hideVideoModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }