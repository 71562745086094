




























import { computed, defineComponent } from '@vue/composition-api'

interface selectDataNameType {
  ja: string
  en: string
}
interface selectDataType {
  name: selectDataNameType
  id: string
}

/**
 * 汎用セレクトフィールド
 * todo: error / disabled 時デザイン
 */
export default defineComponent({
  name: 'AtomInputSelectObject',
  props: {
    /**
     * ID
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Value値
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 選択肢
     */
    options: {
      type: Array as () => selectDataType[],
      default: () => [],
    },
    /**
     * Error判定
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * プレースホルダー
     */
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * country
     * @param {string} val 国
     */
    const inputValue = computed({
      get: () => props.value,
      set: (val: string) => context.emit('input', val),
    })
    return {
      inputValue,
    }
  },
  data() {
    return {
      selectName:
        this.options.find((item) => item.id === this.value)?.name ||
        ({ ja: '', en: '' } as selectDataNameType),
    }
  },
  watch: {
    inputValue: {
      handler() {
        this.selectName =
          this.options.find((item) => item.id === this.inputValue)?.name ||
          ({ ja: '', en: '' } as selectDataNameType)
      },
      deep: true,
    },
  },
  methods: {
    /**
     * 選択が変更されたことを通知する
     */
    change() {
      /**
       * 選択が変更されたことを通知する
       * @event change
       * @type {Object}
       */
      this.$emit('change')
    },
    /**
     * エンターキーが押されたことを通知する
     */
    emitEnter() {
      /**
       * エンターキーが押されたことを通知する
       * @event enter
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
