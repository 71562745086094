import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { PicTimingManager } from 'pic-timing/dist/PicTimingManager'
import { PicTimingResultStateParam } from 'pic-timing/dist/PicTimingResultState'
import IVideoPlayer from '@/util/videoplayer/IVideoPlayer'
import VideoPlayerResult from '@/util/videoplayer/VideoPlayerResult'
import VideoPlayerInitOptionsType from '@/util/videoplayer/VideoPlayerInitOptionsType'
import Logger from '@/util/logger/Logger'
import VideoPlayStatus from '@/util/videoplayer/VideoPlayStatus'
import VideoPlayerStatus from '@/util/videoplayer/VideoPlayerStatus'
import { VideoPlayerClass, VideoPlayerType } from '@/util/videoplayer/VideoPlayerType'
import { VideoPlayerError, VideoPlayerErrorType } from '@/util/videoplayer/VideoPlayerError'
import type SignedCookie from '@/@types/SignedCookie'
import calculateVideoTrackDateTimeForCircuitMode from '@/util/videoplayer/VideoTrackDateTimeCalculateUtil'
import Const from '@/util/Const'

/**
 * video.jsを利用して動画再生を行うための機能を提供する。
 */
export default class BrowserVideoPlayer implements IVideoPlayer {
  /**
   * 割り当てID
   */
  private static nextId = 1

  /**
   * クラスID
   */
  private id: number

  /**
   * 動画プレーヤーのインスタンス
   * videojs.Player が設定される。
   */
  // eslint-disable-next-line
  // @ts-ignore
  private player?: videojs.Player

  /**
   * 動画のcurrentTimeを定期取得するsetIntervalコールバック関数のID
   */
  private currentTimeIntervalId?: number

  /**
   * 動画のmovieLengthを定期取得するsetIntervalコールバック関数のID
   */
  private trackMovieLengthIntervalId?: number

  /**
   * 動画の再生状態(PLAY / PAUSE)が変化した場合に呼び出されるコールバック関数
   */
  private onPlayStatusUpdate?: (status: VideoPlayStatus) => void

  /**
   * 動画の再生可能状態が変化した場合に呼び出されるコールバック関数
   */
  private onPlayerStatusUpdate?: (status: VideoPlayerStatus, movieLength: number | null) => void

  /**
   * 動画プレーヤーの初期化に失敗した場合に呼び出されるコールバック関数を指定する
   * @param error エラー定義
   */
  private onInitializeFailed?: (error: VideoPlayerErrorType) => void

  /**
   * 動画を最後まで再生し、再生が終了した場合に呼び出されるコールバック関数
   */
  private onPlayFinished?: () => void

  /**
   * 再生対象の動画のURLが変化した場合に呼び出されるコールバック関数を指定する。
   * @param movieUrl 現在の動画のURL
   */
  private onChangeMovieUrl?: (movieUrl: string) => void

  /**
   * 映像がシークされた場合に呼び出されるコールバック関数を指定する。
   * @param time 映像の再生時刻
   */
  private onSeeked?: (time: number) => void

  /**
   * 動画プレーヤーの処理結果: 処理成功
   */
  private static SUCCESS = new VideoPlayerResult()

  /**
   * 動画プレーヤーの処理結果: 処理失敗
   */
  private static ERROR = new VideoPlayerResult('error')

  /**
   * 動画プレーヤーの処理結果: 動画プレーヤーの初期化が完了していない
   */
  private static NOT_INITIALIZED = new VideoPlayerResult('not initialized')

  /**
   * 動画プレーヤーの初期化が完了しているかどうか
   */
  private playerInitialized = false

  /**
   * 再生対象の動画がライブ配信動画かどうか
   * @private
   */
  private isLive = false

  /**
   * 再生対象の動画がライブ配信動画かどうか
   * @private
   */
  private picTimingManager: PicTimingManager | null = null

  /**
   * タイムコードが未取得の状態の場合にタイムコード取得を繰り返すフラグ
   * @private
   */
  private isRepeatingGetCurrentTime = false

  /**
   * this.onCurrentTimeUpdate(currentTimeTemp, videoTrackDateTime)の第2引数に渡す値を現在時間に近い値を返すようにするためのフラグ
   * ライブタイミング、テレメトリー、GPSのデータを、videoPlayerの再生位置に基づいた位置のデータではなく、現在時間に近い値を返すために使用する
   */
  private correctVideoTrackDateTimeToCurrent = false

  /**
   * 指定された動画の撮影開始時刻(UnixTime: ミリ秒)
   */
  private recordingStartTime = 0

  /**
   * SFgo が動作しているデバイスと現在時刻とのズレ(ミリ秒)
   */
  private timeDeviations = 0

  /**
   * 動画の再生位置が記録された実時間を取得する処理を行うかどうかを指定する。
   * 指定した場合、 onCurrentTimeUpdate コールバック関数のvideoTrackDateTimeパラメタに、
   * 動画の再生位置が記録された実時間が指定されるようになる。
   */
  readVideoTrackTime = false

  /**
   * 最新位置にシークするかどうか
   * @private
   */
  private seekToEnd = false

  /**
   * コンストラクタ
   */
  constructor() {
    this.id = BrowserVideoPlayer.nextId
    BrowserVideoPlayer.nextId += 1
  }

  /**
   * 動画プレーヤーを初期化する。
   * @param options 動画プレーヤーの初期化オプション
   * @return 動画プレーヤーの初期化を待機するためのPromise
   */
  public init(options: VideoPlayerInitOptionsType): Promise<VideoPlayerResult> {
    this.correctVideoTrackDateTimeToCurrent = options.correctVideoTrackDateTimeToCurrent ?? false
    this.recordingStartTime = options.recordingStartTime ?? 0
    this.timeDeviations = options.timeDeviations ?? 0

    if (this.correctVideoTrackDateTimeToCurrent && this.recordingStartTime === 0) {
      // correctVideoTrackDateTimeToCurrentがtrueの場合、recordingStartTimeを利用して現在日時に近い時間を計算するため必須とする
      Logger.info(
        `BrowserVideoPlayer#init: recordingStartTime is required for correctVideoTrackDateTimeToCurrent true. options: ${JSON.stringify(
          options,
        )}`,
      )
      return Promise.reject(new VideoPlayerResult('error'))
    }

    return new Promise((resolve) => {
      // HLS再生をブラウザのネイティブ機能を利用するかどうかを設定する
      // Chromeの場合、ネイティブでの再生ができない場合があるため、videojs-http-streaming で再生する。
      // @see https://github.com/videojs/http-streaming#overridenative
      // Safariの問題 https://github.com/videojs/video.js/issues/7015
      const isSafari = /Safari/.test(navigator.userAgent) && !videojs.browser.IS_CHROMIUM
      const useOverrideNative = videojs.browser.IS_CHROMIUM || isSafari
      const nativeSupportOptions = {
        vhs: {
          withCredentials: true,
          overrideNative: useOverrideNative,
        },
        nativeVideoTracks: !useOverrideNative,
        nativeAudioTracks: !useOverrideNative,
        nativeTextTracks: false,
      }
      if (useOverrideNative) {
        Logger.info('BrowserVideoPlayer#init: This browser is Chrome, so turn on overrideNative.')
      }

      Logger.debug(
        `BrowserVideoPlayer#init: Start the initialization of the video player.
         nativeSupportOptions: ${JSON.stringify(nativeSupportOptions)},
         readVideoTrackTime: ${options.readVideoTrackTime}`,
      )

      this.player = videojs(options.id, {
        suppressNotSupportedError: true,
        notSupportedMessage: '',
        bigPlayButton: false,
        controlBar: {
          playToggle: false,
          fullscreenToggle: false,
        },
        controls: false,
        muted: options.muted != null ? options.muted : false,
        preload: 'auto',
        width: options.width,
        height: options.height,
        html5: {
          ...nativeSupportOptions,
        },
        liveui: options.live,
      })

      this.onPlayStatusUpdate = options.onPlayStatusUpdate
      this.onPlayerStatusUpdate = options.onPlayerStatusUpdate
      this.onInitializeFailed = options.onInitializeFailed
      this.onPlayFinished = options.onPlayFinished
      this.onChangeMovieUrl = options.onChangeMovieUrl

      this.isLive = !!options.live
      this.seekToEnd = options.seekToEnd ?? false

      this.setMovieUrl(
        options.movieUrl,
        options.currentTime,
        undefined,
        options.readVideoTrackTime,
      ).then(() => {
        if (this.seekToEnd) {
          // 対象の動画がライブ配信動画 かつ サーキットモードトグルボタンで再生モードを切り替ていない場合、末尾にシークする
          // ※ ライブ配信動画が指定された時のふるまいをネイティブプレーヤーと一致させるため、この処理を行う
          this.seekEndOfVideo()
        }
      })
      this.player.ready(() => {
        if (options.volume !== undefined && options.volume >= 0) this.volume(options.volume)
        this.isRepeatingGetCurrentTime = false
        this.picTimingManager = PicTimingManager.reset(
          this.id,
          this.player.currentSrc(),
          this.picTimingManager,
        )
        this.playerInitialized = true
        if (options.onCurrentTimeUpdate) {
          if (this.currentTimeIntervalId) {
            clearInterval(this.currentTimeIntervalId)
          }
          let lastCurrentTime = 0
          this.currentTimeIntervalId = window.setInterval(async () => {
            if (!this.player) {
              return
            }
            if (options.onCurrentTimeUpdate) {
              const currentTime = this.player.currentTime()
              if (Math.abs(lastCurrentTime - currentTime) > 0.1 || this.isRepeatingGetCurrentTime) {
                let currentVideoTrackTime = await this.getCurrentVideoTrackTime(currentTime)

                // 再生位置を現在日時に近い値に補正する
                if (this.correctVideoTrackDateTimeToCurrent) {
                  // デバイスと現在時刻とのズレを考慮する
                  const now = new Date().getTime() + this.timeDeviations
                  const duration = (await this.duration()) * 1000
                  currentVideoTrackTime = calculateVideoTrackDateTimeForCircuitMode(
                    now,
                    duration,
                    currentTime * 1000,
                    this.recordingStartTime,
                    Const.ADJUST_TRACK_DATE_TIME,
                  )
                }

                options.onCurrentTimeUpdate(currentTime, currentVideoTrackTime)
                lastCurrentTime = currentTime
              }
              // 動画の任意の位置を終了位置に設定している場合には、その時間を超えた時に動画を終了扱いにする
              if (options.startTime && options.endTime) {
                if (options.endTime <= currentTime) {
                  this.onPlayFinished?.()
                }
              }
            }
          }, 100)
        }
        if (options.onChangeMovieLength && options.live) {
          if (this.trackMovieLengthIntervalId) {
            clearInterval(this.trackMovieLengthIntervalId)
          }
          this.trackMovieLengthIntervalId = window.setInterval(async () => {
            if (options.onChangeMovieLength) {
              options.onChangeMovieLength(await this.duration())
            }
          }, 1000)
          // this.player.on('handleDurationchange', options.onChangeMovieLength)
        }

        this.trackTimeCode()

        resolve(BrowserVideoPlayer.SUCCESS)
      })
    })
  }

  /**
   * 現在再生している動画の再生位置が記録された実時間を取得する。
   * 動画にタイムコードが含まれている場合のみ取得可能。
   *
   * @param time 実時間を取得する再生位置(秒)。指定しなかった場合、動画プレーヤーから現在の再生位置を取得して、実時間を計算する。
   * @return 現在再生している動画の再生位置が記録された実時間。UnixTime(単位: ミリ秒)
   * 取得できなかった場合、undefined を返す。
   */
  async getCurrentVideoTrackTime(time?: number) {
    if (!this.readVideoTrackTime) {
      return undefined
    }
    if (this.picTimingManager != null) {
      if (this.picTimingManager.isSet) {
        const currentTime = !time ? await this.currentTime() : time
        const result = this.picTimingManager.getPicTiming(currentTime)
        if (result.state === PicTimingResultStateParam.notGet || result.time === null) {
          return undefined
        }
        if (result.state === PicTimingResultStateParam.estimatedValue) {
          // pic_timing直接セグメントを参照していない場合、再度時刻を取り直すようにするフラグ
          this.isRepeatingGetCurrentTime = true
        } else {
          this.isRepeatingGetCurrentTime = false
        }
        // ログ出力が多くなるため、コメントアウトしている。pic_timingを確認したい時に有効にしてください。
        // Logger.debug(
        //   `getCurrentVideoTrackTime : currentTime[${currentTime}] pic_timing[${result.time} / ${PicTimingManager.unixTimeFormat(
        //     result.time,
        //   )}]`,
        // )
        return result.time
      }
    }
    return undefined
  }

  /**
   * 動画のタイムコードの変化を追跡し、PicTimingManager に記録する。
   * @see https://github.com/videojs/http-streaming#segment-metadata
   */
  trackTimeCode() {
    if (!this.player) {
      return
    }
    if (!this.readVideoTrackTime) {
      Logger.debug(
        `BrowserVideoPlayer#trackTimeCode: Skip process of tracking time code. id: ${this.id}`,
      )
      return
    }

    const tracks = this.player.textTracks()
    let segmentMetadataTrack: TextTrack | null = null

    for (let i = 0; i < tracks.length; i += 1) {
      if (tracks[i].label === 'segment-metadata') {
        segmentMetadataTrack = tracks[i]
      }
    }

    if (segmentMetadataTrack) {
      segmentMetadataTrack.addEventListener('cuechange', () => {
        // VideoJSの型定義では、ActiveCuesにvalueフィールドが定義されていないため、any にする
        // https://stackoverflow.com/questions/69891352/videojs-property-text-exists-but-compiler-says-it-does-not-activecues0-t
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const activeCue = segmentMetadataTrack?.activeCues?.[0] as any
        if (activeCue) {
          const trackDateTime = activeCue?.value?.dateTimeObject?.getTime() as number
          const uri = activeCue?.value?.uri
          const start = activeCue?.value?.start
          const end = activeCue?.value?.end
          if (
            trackDateTime !== undefined &&
            trackDateTime !== null &&
            uri !== undefined &&
            uri !== null &&
            start !== undefined &&
            start !== null &&
            end !== undefined &&
            end !== null
          ) {
            this.picTimingManager?.setProgramDateTime(trackDateTime, start, end, uri)
          }
          Logger.debug(
            `currentTime: ${this.player?.currentTime()}, trackDateTime: ${
              activeCue?.value?.dateTimeString
            }`,
          )
        }
      })
    }

    const { vhs } = this.player.tech()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vhs.xhr.segmentLoaded = (options: any) => {
      // セグメントのURLから情報を抽出
      const { baseStartTime = 0.0 } = options.segment
      this.picTimingManager?.setAnalysisMpeg2ts(
        options.segment.bytes,
        options.segment.resolvedUri,
        baseStartTime,
      )
    }
  }

  /**
   * 動画プレーヤーが初期化されているかどうかを返す。
   * @return 初期化されている場合 true を返す
   */
  initialized(): boolean {
    return this.playerInitialized
  }

  /**
   * 現在再生している動画のURLを返す。
   */
  currentMovieUrl() {
    return this.player?.currentSrc()
  }

  /**
   * 動画の長さを取得する。
   * @return 動画長(単位: 秒)
   */
  async duration() {
    if (this.player?.liveTracker.isLive()) {
      return this.player?.liveTracker.liveCurrentTime() || 0
    }
    return this.player?.duration() || 0
  }

  /**
   * 動画のシークイベントのリスナーを登録する。
   * @param listener シークイベントのリスナー
   */
  addSeekEventListener(listener: (time: number) => void): void {
    this.onSeeked = listener
  }

  /**
   * 現在の動画再生位置を返す。
   * @return 動画の再生位置(単位: 秒)
   */
  async currentTime(): Promise<number> {
    return this.player?.currentTime() ?? 0
  }

  /**
   * 動画の再生位置を設定する。
   * @param time 再生位置(単位: 秒)
   * @return 動画再生位置設定処理の完了を待機するためのPromise
   */
  async setCurrentTime(time: number): Promise<VideoPlayerResult> {
    return new Promise((resolve) => {
      if (!this.player) {
        resolve(BrowserVideoPlayer.NOT_INITIALIZED)
        return
      }

      Logger.debug(`BrowserVideoPlayer#setCurrentTime: Start to change currentTime: ${time}`)
      const currentTime = time === undefined ? 0 : time
      if (this.player.currentTime() === currentTime) {
        resolve(BrowserVideoPlayer.SUCCESS)
        return
      }
      const timeUpdateEventHandler = () => {
        if (!this.player) return
        // timeupdateが関係のない再生位置で発火した場合は無視するため、設定した再生位置との差分を比較する
        if (Math.abs(this.player.currentTime() - currentTime) < 0.2) {
          this.player.off('timeupdate', timeUpdateEventHandler)
          resolve(BrowserVideoPlayer.SUCCESS)
          this.onSeeked?.(currentTime)
        }
      }
      this.player.on('timeupdate', timeUpdateEventHandler)
      this.player.currentTime(currentTime)
    })
  }

  /**
   * 動画の最後にシークする。
   */
  async seekEndOfVideo() {
    const endOfVideoTime = await this.duration()
    return this.setCurrentTime(endOfVideoTime)
  }

  /**
   * シーク中かどうかを判定する
   */
  isSeeking() {
    if (this.player?.el()) {
      return !!this.player.seeking()
    }
    return false
  }

  /**
   * タイムリミット付きで動画の再生位置を設定する処理を実行する。
   * @param time 再生位置(単位: 秒)
   * @return 動画再生位置設定処理の完了を待機するためのPromise
   */
  async setCurrentTimeWithTimeLimit(time: number): Promise<VideoPlayerResult> {
    const setCurrentTimeResult: Promise<VideoPlayerResult> = new Promise((raceResolve) => {
      raceResolve(this.setCurrentTime(time))
    })
    // 処理完了までのタイムリミットを2秒にする
    const timeLimit: Promise<'timeout'> = new Promise((resolve) => {
      setTimeout(() => resolve('timeout'), 2000)
    })

    // setMovieUrlが2秒以内に処理されるか判定
    const result = await Promise.race([setCurrentTimeResult, timeLimit])
    if (result === 'timeout') {
      // 動画URL設定後、コールバックを返さない場合があるため、タイムアウト時は正常終了させる
      return BrowserVideoPlayer.SUCCESS
    }

    return result
  }

  /**
   * 再生対象の動画URLを変更する。
   * @param movieUrl 動画のURL
   * @param currentTime 動画URL設定後、この位置にシークする。未指定の場合、先頭位置にシークする
   * @param cookies 動画データを取得する際に付与する署名Cookie
   * @param readVideoTrackTime 動画の再生位置が記録された実時間を取得する処理を行うかどうか
   * @return 動画URL設定変更処理の完了を待機するためのPromise
   */
  async setMovieUrl(
    movieUrl: string,
    currentTime = 0,
    cookies?: Array<SignedCookie>,
    readVideoTrackTime = true,
  ): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED

    Logger.debug(
      `BrowserVideoPlayer#setMovieUrl: Start to change movie url.
       movieUrl: ${movieUrl}, currentTime: ${currentTime},
       readVideoTrackTime: ${readVideoTrackTime}`,
    )

    this.readVideoTrackTime = readVideoTrackTime

    if (this.player.currentSrc() === movieUrl && this.player.readyState() >= 2) {
      // すでに同じURLが設定されており、再生可能になっている場合はシークのみを行う。
      const current = await this.currentTime()
      const diff = Math.abs(current - currentTime)
      Logger.debug(`BrowserVideoPlayer#setMovieUrl: current: ${current}, diff: ${diff}`)
      if (diff > 0.1) {
        // 同じURL,同じシーク位置が指定された場合に、動画の再読み込みが実施されることを防ぐため、現在の再生位置とシーク位置の差が100ms以下の場合、シークしない。
        await this.setCurrentTimeWithTimeLimit(currentTime)
      }
      return BrowserVideoPlayer.SUCCESS
    }
    return new Promise((resolve, reject) => {
      if (!this.player) {
        reject(BrowserVideoPlayer.ERROR)
        return
      }
      const canPlayEventHandler = async () => {
        this.player?.off('canplay', canPlayEventHandler)
        this.onPlayerStatusUpdate?.('Running', await this.duration())
        this.onPlayStatusUpdate?.(this.player?.paused() ? 'PAUSE' : 'PLAY')
        await this.setCurrentTimeWithTimeLimit(currentTime)
        this.player?.show()
        this.onChangeMovieUrl?.(movieUrl)
        this.trackTimeCode()
        return resolve(BrowserVideoPlayer.SUCCESS)
      }

      this.isRepeatingGetCurrentTime = false
      this.picTimingManager = PicTimingManager.reset(this.id, movieUrl, this.picTimingManager)

      this.onPlayerStatusUpdate?.('Not running', 0)
      this.player.hide()
      this.player.on('canplay', canPlayEventHandler)

      const onErrorHandler = () => {
        this.player?.show()
        Logger.info(
          `BrowserVideoPlayer#setMovieUrl: Failed to set movie url. error: ${
            this.player?.error()?.message
          }, code: ${this.player?.error()?.code}`,
        )
        if (this.player?.error()?.code === 2) {
          this.onInitializeFailed?.(VideoPlayerError.NETWORK_ERROR)
        } else if (this.player?.error()?.code === 4) {
          this.onInitializeFailed?.(VideoPlayerError.COMPATIBLE_VIDEO_NOT_FOUND)
        } else {
          this.onInitializeFailed?.(VideoPlayerError.UNKNOWN_ERROR)
        }
        // videoタグ上にエラーメッセージが表示されたままとなってしまうため、クローズする
        this.player?.errorDisplay?.close()
        reject(BrowserVideoPlayer.ERROR)
      }
      this.player.on('error', onErrorHandler)

      const playFinishedEventHandler = () => {
        this.player?.show()
        this.player?.off('ended', canPlayEventHandler)
        this.onPlayStatusUpdate?.('PAUSE')
        this.onPlayFinished?.()
      }
      this.player.on('ended', playFinishedEventHandler)

      // 指定された動画が再生可能になるまでは動画プレーヤーを非表示にする
      this.player?.hide()
      this.player.src({ type: 'application/x-mpegURL', src: movieUrl })
      if (this.isLive) {
        this.player.play()
      }
    })
  }

  /**
   * 動画プレーヤーを破棄する。
   * @return 動画プレーヤー破棄の処理の完了を待機するためのPromise
   */
  async dispose(): Promise<VideoPlayerResult> {
    PicTimingManager.releaseCheck(this.id, this.picTimingManager)

    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED

    if (this.currentTimeIntervalId) {
      clearInterval(this.currentTimeIntervalId)
    }
    if (this.trackMovieLengthIntervalId) {
      clearInterval(this.trackMovieLengthIntervalId)
    }
    this.player.off('ended')
    this.player.off('canplay')
    this.player.off('handleDurationchange')
    this.onSeeked = undefined
    this.player.dispose()
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 動画の再生を一時停止する。
   * @return 動画の再生一時停止処理の完了を待機するためのPromise
   */
  async pause(): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED

    this.player.pause()
    if (this.onPlayStatusUpdate) {
      this.onPlayStatusUpdate(this.player.paused() ? 'PAUSE' : 'PLAY')
    }
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 動画を再生する。
   * @return 動画の再生開始処理の完了を待機するためのPromise
   */
  async play(): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED

    this.player.play()
    if (this.onPlayStatusUpdate) {
      this.onPlayStatusUpdate(this.player.paused() ? 'PAUSE' : 'PLAY')
    }
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 再生速度を設定する
   * @param rate 再生速度
   */
  async setPlaybackRate(rate: number): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED
    this.player.playbackRate(rate)
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 指定されたDOM要素のサイズとX,Y座標を取得する。
   * @param elementId DOM要素のID
   * @return DOM要素のサイズとX,Y座標
   */
  public static getVideoPlayerSize(elementId: string) {
    const videoElement = document.querySelector(`#${elementId}`)
    if (videoElement) {
      const rect = videoElement.getBoundingClientRect()
      return {
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      }
    }
    return null
  }

  /**
   * 動画プレーヤーを非表示にする。
   * @return {Promise<VideoPlayerResult>} 非表示処理完了を待機するためのPromise
   */
  async hide(): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED
    this.player.hide()
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 動画プレーヤーを表示する。
   * @return {Promise<VideoPlayerResult>} 表示処理完了を待機するためのPromise
   */
  async show(): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED
    this.player.show()
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 音量をミュートにする
   * @param shouldMute 音量をミュートにするかミュートを解除するかどうか、ミュートにする場合にはtrueを指定する
   * @return 音量をミュートにする処理の完了を待機するためのPromise
   */
  async muted(shouldMute: boolean): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED

    this.player.muted(shouldMute)
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 音量を設定する。
   * @param volumeLevel 音量
   */
  async volume(volumeLevel: number): Promise<VideoPlayerResult> {
    if (!this.player) return BrowserVideoPlayer.NOT_INITIALIZED
    this.player.volume(volumeLevel)
    return BrowserVideoPlayer.SUCCESS
  }

  /**
   * 動画プレーヤーの種別を返す。
   */
  // eslint-disable-next-line class-methods-use-this
  get playerType(): VideoPlayerType {
    return VideoPlayerClass.Browser
  }

  /**
   * 再生対象の動画が、ライブ配信されている動画かどうかを指定する。
   * video.jsは、動画のライブ状態を指定することはできないため、このメソッドが呼び出されても無視する
   *
   * @param live ライブ配信されている動画の場合、true を指定する
   */
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  set liveMode(live: boolean) {}

  /**
   * 再生対象の動画が、ライブ配信されている動画かどうかを判定する。
   *
   * @return ライブ配信されている動画の場合、true を返す
   */
  get liveMode(): boolean {
    return this.isLive
  }

  /**
   * 動画プレーヤーを表示サイズに変更する。
   * TODO 現状未実装。ネイティブプレイヤーとインターフェイスを共通化するために作成している。
   * @param to 表示位置
   * @param size 表示サイズ
   * @param displayPosition 映像表示領域位置
   * @param scale 表示倍率
   * @return {Promise<VideoPlayerResult>} 非表示処理完了を待機するためのPromise
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */

  // eslint-disable-next-line class-methods-use-this
  async changeDisplaySize(
    to: { x: number; y: number },
    size: { width: number; height: number },
  ): Promise<VideoPlayerResult[]> {
    return [BrowserVideoPlayer.SUCCESS]
  }

  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `BrowserVideoPlayer[player: ${this.player}}]`
  }
}
