























































import { defineComponent, PropType } from '@vue/composition-api'
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { SnsPostImage } from '@/store/stores/pageStore/HomePage/HomePageStore'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * HOME SNSコンテンツ 投稿リンク タブパーツ
 */
export default defineComponent({
  name: 'SnsPostItemImagesParts',
  props: {
    images: {
      type: Array as PropType<SnsPostImage[]>,
      required: true,
    },
    postLink: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const srcError = (event: Event) => {
      const { target } = event
      if (!(target instanceof HTMLImageElement)) {
        return
      }
      target.closest('.sns-post-item-images-parts')?.remove()
      context.emit('updateSnsContentsData', target.src)
    }
    const addSignedUrl = (imgUrl: string) => CloudFrontUtil.getSignedUrl(imgUrl)

    return {
      srcError,
      addSignedUrl,
    }
  },
  mounted() {
    if (this.images.length > 1) {
      // eslint-disable-next-line
      new Swiper(this.$refs.swiper as HTMLElement, {
        modules: [Navigation, Pagination],
        loop: true,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      })
    }
  },
})
