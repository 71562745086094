var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M1.9375 6L7.76072 11.8232C8.44414 12.5066 9.55218 12.5066 10.2356 11.8232L16.0588 6H13.9375L9.17494 10.7626C9.07731 10.8602 8.91902 10.8602 8.82138 10.7626L4.05882 6H1.9375Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }