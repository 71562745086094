import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementLocale,
} from '@stripe/stripe-js'
import { reactive } from '@vue/composition-api'
import i18n from '@/locales/I18n'
import Logger from '@/util/logger/Logger'

export interface StripeInputErrors {
  type: 'validation_error'
  code: string
  message: string
}

/**
 * コンポーネントマウント時に、Stripeを返す
 * @return {Stripe} Stripeを返す
 */
export default function useStripe() {
  const state = reactive({
    stripe: null as Stripe | null,
    cardNumber: null as StripeCardNumberElement | null,
    cardExpiry: null as StripeCardExpiryElement | null,
    cardCvc: null as StripeCardCvcElement | null,
    clientSecret: null as string | null,
    errors: {
      cardNumber: undefined as StripeInputErrors | undefined,
      cardExpiry: undefined as StripeInputErrors | undefined,
      cardCvc: undefined as StripeInputErrors | undefined,
    },
    isFocus: {
      number: false,
      expiry: false,
      cvc: false,
    },
    isBlank: {
      number: true,
      expiry: true,
      cvc: true,
    },
    /**
     * カード名義
     */
    cardName: '',
  })

  /**
   * カード名義を更新
   */
  const setCardName = (cardName: string) => {
    state.cardName = cardName
  }

  /**
   * Stripeを準備する
   * Stripeを利用する場合、この関数をonMountedの中で呼び出す必要がある
   * @param cardNumber カード番号を入力するElement
   * @param cardExpiry カード有効期限を入力するElement
   * @param cardCvc CVCを入力するElement
   * @param clientSecret クライアントシークレット
   */
  const prepare = async (
    cardNumber?: HTMLElement | null,
    cardExpiry?: HTMLElement | null,
    cardCvc?: HTMLElement | null,
    clientSecret?: string | null,
  ) => {
    if (!cardNumber || !cardExpiry || !cardCvc || !clientSecret) return
    state.stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_KEY)
    if (!state.stripe) return
    const elements = state.stripe.elements({
      clientSecret,
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
        },
      ],
      locale: i18n.locale as StripeElementLocale,
    })
    const style = {
      base: {
        color: '#4e4e4e',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.2)',
        },
        ':-webkit-autofill': {
          color: '#4e4e4e',
        },
      },
      invalid: {
        color: '#ff4949',
        iconColor: '#ff4949',
        ':-webkit-autofill': {
          color: '#ff4949',
        },
      },
    }

    const classes = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    }
    state.cardNumber = elements.create('cardNumber', {
      showIcon: true,
      iconStyle: 'solid',
      style,
      classes,
    })
    state.cardNumber.mount(cardNumber)
    state.cardNumber.on('change', ({ error, empty }) => {
      state.errors.cardNumber = error
      state.isBlank.number = empty
    })
    state.cardNumber.on('focus', () => {
      state.isFocus.number = true
    })
    state.cardNumber.on('blur', () => {
      state.isFocus.number = false
    })
    state.cardExpiry = elements.create('cardExpiry', { style, classes })
    state.cardExpiry.mount(cardExpiry)
    state.cardExpiry.on('change', ({ error, empty }) => {
      state.errors.cardExpiry = error
      state.isBlank.expiry = empty
    })
    state.cardExpiry.on('focus', () => {
      state.isFocus.expiry = true
    })
    state.cardExpiry.on('blur', () => {
      state.isFocus.expiry = false
    })
    state.cardCvc = elements.create('cardCvc', { style, classes })
    state.cardCvc.mount(cardCvc)
    state.cardCvc.on('change', ({ error, empty }) => {
      state.errors.cardCvc = error
      state.isBlank.cvc = empty
    })
    state.cardCvc.on('focus', () => {
      state.isFocus.cvc = true
    })
    state.cardCvc.on('blur', () => {
      state.isFocus.cvc = false
    })
    state.clientSecret = clientSecret
  }

  /**
   * PaymentMethodを生成
   * @return PaymentMethod
   */
  const getPaymentMethod = async () => {
    if (!state.clientSecret || !state.cardNumber) {
      return {
        paymentMethod: null,
        error: null,
      }
    }

    // クレジットカードの検証と、検証が成功場合にPaymentMethodを生成する
    const result = await state.stripe?.confirmCardSetup(state.clientSecret, {
      payment_method: {
        card: state.cardNumber,
        billing_details: {
          name: state.cardName,
        },
      },
    })

    if (result?.error) {
      Logger.error(
        `useStripe#getPaymentMethod: Failed to get PaymentMethod. Error code: ${result.error.code}`,
      )
    }

    return {
      paymentMethod: result?.setupIntent?.payment_method,
      error: result?.error,
    }
  }

  const destroy = () => {
    /* eslint-disable no-unused-expressions */
    state.errors = { cardCvc: undefined, cardExpiry: undefined, cardNumber: undefined }
    state.isBlank = { cvc: true, expiry: true, number: true }
    state.cardNumber?.destroy()
    state.cardCvc?.destroy()
    state.cardExpiry?.destroy()
    state.clientSecret = null
  }

  return {
    state,
    setCardName,
    prepare,
    getPaymentMethod,
    destroy,
  }
}
