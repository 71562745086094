var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paid-membership-change-confirm-pane" },
    [
      _c("signup-header-section", {
        attrs: { steps: 0, pageTitle: _vm.$tc("SignupPage.Steps.confirm") },
      }),
      _c("current-plan-card-section", {
        attrs: {
          sectionTitle: _vm.$tc("MypagePage.plan.selectPlan"),
          plan: _vm.plan,
          isConfirmed: true,
        },
      }),
      _vm.isCouponUsed && _vm.attentionMessage
        ? _c("attention-message-section", {
            staticClass:
              "paid-membership-change-confirm-pane__attention-message",
            attrs: { attentionMessage: _vm.attentionMessage },
          })
        : _vm._e(),
      _c("payment-cycle-section", {
        attrs: {
          paymentCycleImgPath: _vm.paymentCycleImgPath,
          attention: _vm.$tc(
            "SignupPage.SignupPaymentConfirmPage." +
              _vm.selectedPlanGroupId +
              ".attention"
          ),
        },
      }),
      _c(
        "action-buttons-section",
        {
          staticClass: "paid-membership-change-confirm-pane__action-buttons",
          attrs: {
            scrollTargetSelector: ".paid-membership-change-confirm-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.registration"),
              isDisabled: false,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }