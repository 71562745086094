var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "external-confirmation-section" }, [
    _c("p", { staticClass: "external-confirmation-section__title" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm.message
      ? _c("p", {
          staticClass: "external-confirmation-section__message",
          domProps: { innerHTML: _vm._s(_vm.message) },
        })
      : _vm._e(),
    _vm.links.length > 0
      ? _c(
          "ul",
          { staticClass: "external-confirmation-section__list" },
          _vm._l(_vm.links, function (link, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "external-confirmation-section__item",
              },
              [
                _c("atom-link", {
                  staticClass: "external-confirmation-section__itemLink",
                  attrs: {
                    url: link.url,
                    linkText: link.name,
                    imgPath: require("@/assets/img/icon/icon_external_link_black.svg"),
                    linkColor: "black80",
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }