var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ranking-ots-parts",
      class: [
        "ranking-ots-parts--" + _vm.screenOrientationType,
        { "ranking-ots-parts--running-low": _vm.otsValue < 20 },
        { "ranking-ots-parts--enabled": _vm.otsEnabled },
        { "ranking-ots-parts--disabled": _vm.otsDisabled },
      ],
    },
    [
      _c(
        "svg",
        {
          staticClass: "ranking-ots-parts__gauge",
          attrs: {
            preserveAspectRatio: "none",
            width: "100",
            height: "8",
            viewBox: "0 0 100 8",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c(
            "clipPath",
            { attrs: { id: "ranking-ots-parts__gauge__clip" + _vm.id } },
            [
              _c("path", {
                attrs: {
                  opacity: "0.3",
                  d: "M4.86133 0.5H88.7924C89.7657 0.5 90.7219 0.755698 91.5653 1.24149L97.2224 4.5H13.2914C12.3181 4.5 11.3619 4.2443 10.5185 3.75851L4.86133 0.5Z",
                  fill: "white",
                },
              }),
            ]
          ),
          _c(
            "mask",
            {
              staticStyle: { "mask-type": "alpha" },
              attrs: {
                id: "ranking-ots-parts__gauge__clip2" + _vm.id + "}",
                maskUnits: "userSpaceOnUse",
                width: "85.36",
                height: "4",
              },
            },
            [
              _c("rect", {
                attrs: {
                  width: _vm.gaugeWidth,
                  height: "4",
                  x: "0",
                  y: "0",
                  transform: "skewX(63)",
                  fill: "white",
                },
              }),
            ]
          ),
          _c(
            "clipPath",
            {
              attrs: {
                id: "telemetry-ots-parts__gauge__enabled-clip" + _vm.id + "}",
              },
            },
            [
              _c("path", {
                staticClass: "st0",
                attrs: {
                  d: "M3.2,0.4h86.3c1,0,2,0.3,2.9,0.9l5.8,4.1H11.9c-1,0-2-0.3-2.9-0.9L3.2,0.4z",
                },
              }),
            ]
          ),
          _c(
            "defs",
            [
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "gaugeReflectGradient" + _vm.id,
                    x1: "0",
                    y1: "4",
                    x2: "170.72",
                    y2: "4",
                    gradientUnits: "userSpaceOnUse",
                    spreadMethod: "reflect",
                  },
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "x1",
                      dur: "2s",
                      from: "-100%",
                      to: "100%",
                      repeatCount: "indefinite",
                    },
                  }),
                  _c("animate", {
                    attrs: {
                      attributeName: "x2",
                      dur: "2s",
                      from: "0%",
                      to: "200%",
                      repeatCount: "indefinite",
                    },
                  }),
                  _c("stop", {
                    attrs: { offset: "0", "stop-color": "#00FFBA" },
                  }),
                  _c("stop", {
                    attrs: {
                      offset: "0.25",
                      "stop-color": "#00FFBA",
                      "stop-opacity": "0.4",
                    },
                  }),
                  _c("stop", {
                    attrs: { offset: "0.50", "stop-color": "#00FFBA" },
                  }),
                  _c("stop", {
                    attrs: {
                      offset: "0.75",
                      "stop-color": "#00FFBA",
                      "stop-opacity": "0.4",
                    },
                  }),
                  _c("stop", {
                    attrs: { offset: "1", "stop-color": "#00FFBA" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("path", {
            attrs: {
              opacity: "0.3",
              d: "M4.86133 0.5H88.7924C89.7657 0.5 90.7219 0.755698 91.5653 1.24149L97.2224 4.5H13.2914C12.3181 4.5 11.3619 4.2443 10.5185 3.75851L4.86133 0.5Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              fill: _vm.fillColor,
              opacity: "0.3",
              d: "M99.3056 6.52778H12.22C11.0374 6.52778 9.87437 6.22576 8.84117 5.65035L1.38889 1.5H0L8.25478 6.35852C9.21557 6.92402 10.3101 7.22222 11.425 7.22222H100L99.3056 6.52778Z",
            },
          }),
          _c(
            "g",
            {
              attrs: {
                "clip-path":
                  "url(#ranking-ots-parts__gauge__clip" + _vm.id + ")",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    mask: "url(#ranking-ots-parts__gauge__clip2" + _vm.id + ")",
                    transform: "translate(" + _vm.gaugePositionX + ", 0)",
                  },
                },
                [
                  !_vm.otsEnabled
                    ? _c("rect", {
                        attrs: {
                          width: "85.36",
                          height: "4",
                          x: "0",
                          y: "0",
                          fill: _vm.fillColor,
                          transform: "skewX(63)",
                          opacity: "0.8",
                        },
                      })
                    : _c("rect", {
                        staticClass:
                          "ranking-ots-parts__gauge__enabled__animation",
                        attrs: {
                          width: "85.36",
                          height: "4",
                          x: "0",
                          y: "0",
                          transform: "skewX(63)",
                          fill: "url(#gaugeReflectGradient" + _vm.id + ")",
                        },
                      }),
                ]
              ),
            ]
          ),
          _vm.otsEnabled
            ? _c(
                "g",
                {
                  attrs: {
                    "clip-path":
                      "url(#telemetry-ots-parts__gauge__enabled-clip" +
                      _vm.id +
                      "})",
                    transform: "translate(-1.5, -1)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      staticClass:
                        "ranking-ots-parts__gauge__enabled__left-gradient",
                      attrs: {
                        transform:
                          "translate(" + (_vm.gaugePositionX - 2) + ", .5)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M5 1H17.7749C18.6145 1 19.44 1.21623 20.1717 1.62785L26.1667 5H13.3918C12.5522 5 11.7267 4.78377 10.9949 4.37215L5 1Z",
                          fill:
                            "url(#enabledOtsGaugeLeftGradation" + _vm.id + ")",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M5 1H17.7749C18.6145 1 19.44 1.21623 20.1717 1.62785L26.1667 5H13.3918C12.5522 5 11.7267 4.78377 10.9949 4.37215L5 1Z",
                          fill:
                            "url(#enabledOtsGaugeLeftGradation" + _vm.id + ")",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M5 1H17.7749C18.6145 1 19.44 1.21623 20.1717 1.62785L26.1667 5H13.3918C12.5522 5 11.7267 4.78377 10.9949 4.37215L5 1Z",
                          fill:
                            "url(#enabledOtsGaugeLeftGradation" + _vm.id + ")",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "g",
                    {
                      staticClass:
                        "ranking-ots-parts__gauge__enabled__left-gradient",
                      attrs: {
                        transform:
                          "translate(" + (_vm.gaugePositionX - 2.5) + ", 0)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M4.82628 0.696474C4.58471 0.56748 4.31508 0.5 4.04122 0.5H3.20834L11.8308 5.29026C12.0784 5.42782 12.357 5.5 12.6402 5.5H98.125L97.7083 5.08333H13.2502C13.1133 5.08333 12.9785 5.04959 12.8577 4.9851L4.82628 0.696474Z",
                          fill: "url(#enabledOtsGaugeUnderLine" + _vm.id + ")",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "defs",
                    [
                      _c(
                        "linearGradient",
                        {
                          attrs: {
                            id: "enabledOtsGaugeLeftGradation" + _vm.id,
                            x1: "6.52778",
                            y1: "6.91667",
                            x2: "18.1389",
                            y2: "0.500001",
                            gradientUnits: "userSpaceOnUse",
                          },
                        },
                        [
                          _c("stop", { attrs: { "stop-color": "white" } }),
                          _c("stop", {
                            attrs: {
                              offset: "1",
                              "stop-color": "white",
                              "stop-opacity": "0",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "linearGradient",
                        {
                          attrs: {
                            id: "enabledOtsGaugeUnderLine" + _vm.id,
                            x1: "-9.77173",
                            y1: "5.5",
                            x2: "-9.76158",
                            y2: "6.54616",
                            gradientUnits: "userSpaceOnUse",
                          },
                        },
                        [
                          _c("stop", { attrs: { "stop-color": "white" } }),
                          _c("stop", {
                            attrs: {
                              offset: "1",
                              "stop-color": "#98FFE6",
                              "stop-opacity": "0.4",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.otsDisabled
        ? _c("p", { staticClass: "ranking-ots-parts__disabled-time" }, [
            _c(
              "svg",
              {
                staticClass: "ranking-ots-parts__disabled-time__icon",
                attrs: {
                  width: "18",
                  height: "18",
                  viewBox: "0 0 18 18",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M14.5 9C14.5 12.0376 12.0376 14.5 9 14.5C7.75165 14.5 6.60043 14.0841 5.67736 13.3833L13.3833 5.67736C14.0841 6.60043 14.5 7.75165 14.5 9ZM4.6167 12.3226L12.3226 4.6167C11.3996 3.9159 10.2484 3.5 9 3.5C5.96243 3.5 3.5 5.96243 3.5 9C3.5 10.2484 3.9159 11.3996 4.6167 12.3226ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9Z",
                    fill: "#FFB800",
                  },
                }),
              ]
            ),
            _c(
              "span",
              { staticClass: "ranking-ots-parts__disabled-time__data" },
              [
                _c("span", { staticClass: "ranking-ots-parts__number" }, [
                  _vm._v(_vm._s(_vm.otsDisabledTime)),
                ]),
                _vm._m(0),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("sup", { staticClass: "ranking-ots-parts__unit" }, [
      _c("span", [_vm._v("s")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }