import BaseCouponDocument from '@/store/stores/collectionModule/documents/coupon/BaseCouponDocument'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { PlanAfterCouponEndsType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

/**
 * ユーザー個別クーポン情報を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3427565651/API?NO_SSR=1
 */
export default class UserCouponDocument extends BaseCouponDocument {
  constructor(initProps?: Partial<UserCouponDocument>) {
    super(initProps as Partial<BaseCouponDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/coupon/user'

  idAttr = 'couponId'

  /**
   * クーポンコード
   */
  couponCode: string | null = null

  /**
   * クーポン名
   */
  couponName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 説明
   */
  description?: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * クーポン情報の追加項目
   */
  additionalData?: {
    /**
     * 見出し
     */
    title: Multilingual
    /**
     * クーポン終了後のプラン
     */
    couponPlanType: PlanAfterCouponEndsType
  }

  /**
   * セパレートID
   */
  separateId: string | null = null
}
