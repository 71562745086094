var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-chevron-down",
      attrs: {
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M1.93945 6L7.76268 11.8232C8.44609 12.5066 9.55413 12.5066 10.2375 11.8232L16.0608 6H13.9395L9.17689 10.7626C9.07926 10.8602 8.92097 10.8602 8.82334 10.7626L4.06077 6H1.93945Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }