






import { defineComponent } from '@vue/composition-api'
import PasswordResetMailInputSection from '@/components/PasswordResetPage/PasswordResetMailInputPane/PasswordResetMailInputSection.vue'

/**
 * パスワードリセット メールアドレス入力ペイン
 */
export default defineComponent({
  name: 'PasswordResetMailInputPane',
  components: {
    PasswordResetMailInputSection,
  },
  methods: {
    moveToSend() {
      /**
       * 送信完了画面へ遷移
       * @event success-regist
       */
      this.$emit('success-regist')
    },
  },
})
