















import { defineComponent, PropType } from '@vue/composition-api'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import AtomLink from '@/components/atoms/AtomLink.vue'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

/**
 * マイページ: 紹介コード: 適用クーポン表示セクション
 */
export default defineComponent({
  name: 'ReferralCouponSection',
  components: {
    CurrentPlanCardSection,
    AtomLink,
  },
  props: {
    baseCouponPlan: {
      type: Object as PropType<ContractPlanDocument>,
      required: true,
    },
  },
})
