













































import { computed, defineComponent, PropType } from '@vue/composition-api'
import MissionRankingTableShareParts from '@/components/MissionPage/MissionRankingPane/parts/MissionRankingTableShareParts.vue'
import { RankInUserType } from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import { RankingType } from '@/store/stores/pageStore/MissionPage/MissionPageStore'
import useMissionRanking from '@/components/MissionPage/MissionRankingPane/hook/useMissionRanking'

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultIcon = require('@/assets/img/icon/icon_user_default.png')

export default defineComponent({
  name: 'MissionRankingTableItemParts',
  components: { MissionRankingTableShareParts },
  props: {
    userData: {
      type: Object as PropType<RankInUserType>,
      required: true,
    },
    isOwnRank: {
      type: Boolean,
      default: false,
    },
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
    },
    rankingType: {
      type: String as PropType<RankingType>,
      default: 'TOTAL',
    },
  },
  setup(props) {
    const { getDisplayUserName } = useMissionRanking()

    const getUserName = computed(() =>
      getDisplayUserName(props.retrieveNameUsersByUserId, props.userData),
    )

    const getUserIcon = computed(() => {
      const userIcon = props.retrieveNameUsersByUserId?.[props.userData.userId]?.userPicture
      return userIcon ? CloudFrontUtil.getSignedUrl(userIcon) : defaultIcon
    })

    return {
      getUserName,
      getUserIcon,
    }
  },
  methods: {
    onClickSnsButton() {
      this.$emit('onClickSnsButton')
    },
    onClickShareIcon() {
      this.$emit('onClickShareIcon')
    },
  },
})
