var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mission-ranking-list-section" }, [
    _c(
      "div",
      { staticClass: "mission-ranking-list-section__header" },
      [
        _c("mission-ranking-title-parts", {
          attrs: {
            title: _vm.pointRanking.title,
            summary: _vm.pointRanking.summary,
            rankingType: _vm.rankingType,
          },
        }),
        _vm.pointRanking.aggregationPeriod
          ? _c(
              "div",
              { staticClass: "mission-ranking-list-section__period" },
              [
                _c("mission-ranking-period-parts", {
                  attrs: { periodData: _vm.pointRanking.aggregationPeriod },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mission-ranking-list-section__body" },
      [
        _c("mission-ranking-table-section", {
          attrs: {
            category: _vm.pointRanking.category,
            rankings: _vm.pointRanking.rankings,
            retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
            rankingType: _vm.rankingType,
          },
          on: {
            onClickSnsButton: _vm.onClickSnsButton,
            onClickShareIcon: _vm.onClickShareIcon,
            savePostSnsMissionHistory: function ($event) {
              return _vm.$emit("savePostSnsMissionHistory")
            },
          },
        }),
      ],
      1
    ),
    _vm.isOwnRankUnranked && _vm.ownPointRanking
      ? _c(
          "div",
          { staticClass: "mission-ranking-list-section__self" },
          [
            _c("mission-ranking-self-not-ranked-parts", {
              attrs: {
                selfData: _vm.ownPointRanking.selfData,
                isOwnRank: _vm.ownPointRanking.isOwnRank,
                retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
                rankingType: _vm.rankingType,
              },
              on: {
                onClickSnsButton: _vm.onClickSnsButton,
                onClickShareIcon: _vm.onClickShareIcon,
                savePostSnsMissionHistory: function ($event) {
                  return _vm.$emit("savePostSnsMissionHistory")
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.pointRanking.displayEndDate
      ? _c(
          "div",
          { staticClass: "mission-ranking-list-section__footer" },
          [
            _c("mission-ranking-end-date-parts", {
              attrs: { endDate: _vm.pointRanking.displayEndDate },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }