import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * 加速度センサーを使ってデバイスの回転の向きを検知するためのクラス
 * 画面の向きとは別物であることに注意
 */
export default class DeviceOrientation {
  /**
   * デバイスの加速度センサーの値を取得する
   * @return {Promise<{x: number, y: number, z: number} | null>} 以下のようなオブジェクトを返す
   * <pre><code>
   * {
   *   x: number (x方向の加速度)
   *   y: number (y方向の加速度)
   *   z: number (z方向の加速度)
   * }
   * </code></pre>
   */
  static getDeviceAcceleration(): Promise<{ x: number; y: number; z: number } | null> {
    return new Promise((resolve, reject) => {
      if (!DeviceInfo.isCordova()) {
        resolve(null)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onSuccess = (acceleration: any) => {
        Logger.debug(`Device Orientation X is ${acceleration.x}`)
        Logger.debug(`Device Orientation Y is ${acceleration.y}`)
        Logger.debug(`Device Orientation Z is ${acceleration.z}`)
        resolve({ x: Number(acceleration.x), y: Number(acceleration.y), z: Number(acceleration.z) })
      }
      const onError = (e: Error) => {
        Logger.error(`DeviceOrientation#getDeviceOrientation. Fail to get DeviceOrientation. ${e}`)
        reject(e)
      }
      window.navigator.accelerometer.getCurrentAcceleration(onSuccess, onError)
    })
  }

  /**
   * 加速度センサーの値から、デバイスの向きを計算して返す
   * 画面の向きとは別物であることに注意
   * @return {Promise<OrientationLockType | null>}
   */
  static async getDeviceOrientation(): Promise<OrientationLockType | null> {
    if (!DeviceInfo.isCordova()) {
      return window.screen.orientation.type
    }
    try {
      const acceleration = await this.getDeviceAcceleration()
      let result: OrientationLockType = 'portrait'
      if (acceleration == null) {
        result = 'portrait'
      } else if (acceleration.y > 5 && Math.abs(acceleration.x) < 5) {
        result = 'portrait'
      } else if (Math.abs(acceleration.y) < 5 && acceleration.x > 5) {
        result = 'landscape-primary'
      } else if (Math.abs(acceleration.y) < 5 && acceleration.x < -5) {
        result = 'landscape-secondary'
      }
      // 判定できない場合や逆さまになった場合は、とりあえずportraitを返すことにする
      return result
    } catch (err) {
      return null
    }
  }

  /**
   * 横向きにする場合の向きを取得する
   * @return {Promise<OrientationLockType>}
   */
  static async getLandscapeDeviceOrientation(
    defaultOrientation: OrientationLockType = 'landscape-primary',
  ): Promise<OrientationLockType> {
    let result: OrientationLockType = defaultOrientation
    const nowOrientation = await DeviceOrientation.getDeviceOrientation()

    if (nowOrientation != null) {
      if (nowOrientation.match(/landscape/) != null) {
        result = nowOrientation
      }
    }
    return result
  }
}
