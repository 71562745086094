


















import { computed, defineComponent, PropType } from '@vue/composition-api'

/**
 * アイコン付きリンクボタン(a)を表示するAtomコンポーネント。
 */
export default defineComponent({
  name: 'AtomIconLinkButton',
  props: {
    /**
     * リンクの文字
     */
    linkText: {
      type: String,
      required: true,
    },
    /**
     * リンクしたいURL
     */
    url: {
      type: String,
      required: true,
    },
    /**
     * 新しいタブやウインドウでリンク先を開くかどうか
     * falseの場合、現在のウィンドウで開く
     */
    isOpenAsBlank: {
      type: Boolean,
      default: true,
    },
    /**
     * テーマカラー指定
     */
    theme: {
      type: String,
      default: 'light',
    },
    /**
     * カラー指定
     */
    colorType: {
      type: String,
      default: 'primary',
    },
    /**
     * サイズ指定
     */
    size: {
      type: String,
      default: 'large',
    },
    /**
     * アイコン画像パス
     */
    imgPath: {
      type: String,
      default: '',
    },
    /**
     * アイコン位置
     */
    iconPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
  },
  setup(props) {
    /**
     * リンクの開く先を指定する。
     * @return string リンクを別画面で開くかどうか
     */
    const target = computed(() => (props.isOpenAsBlank ? '_blank' : '_self'))
    return {
      target,
    }
  },
  methods: {
    push() {
      /**
       * ボタンが押下されたことを通知する
       * @event click
       * @type {Object}
       */
      this.$emit('push')
    },
  },
})
