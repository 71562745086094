































import { computed, defineComponent } from '@vue/composition-api'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'
import useContractInfo from '@/store/hook/useContractInfo'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * 退会確認ページ ペイン
 */
export default defineComponent({
  name: 'CancelConfirmPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    AtomLinkButton,
  },
  setup() {
    // hooks
    const { cancelContract } = useContractInfo()
    const { fetchContractInfo, ownContractInfo } = useContractInfo()

    /**
     * アプリから遷移したかどうか
     */
    const { transitionFromMobileApp } = LocalStorageAccessor
    /**
     * /Androidブラウザで開いている場合はアプリに戻るようにするための条件
     * TODO: ※iPhoneからアプリに戻れないバグの対応が出来次第、「アプリから遷移した場合はアプリに戻る」という挙動にする。
     * 現在はAndroidのみアプリに戻るようにする
     */
    const canReturnToApp = computed(() => transitionFromMobileApp && DeviceInfo.isAndroidBrowser())

    const mypageUrl = computed(() =>
      // アプリから遷移 & Androidブラウザで開いてる場合は、アプリに戻るようにする。
      canReturnToApp.value
        ? `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`
        : '/mypage',
    )

    // 契約情報を取得
    fetchContractInfo()

    return {
      ownContractInfo,
      cancelContract,
      mypageUrl,
    }
  },
  methods: {
    /**
     * 退会処理
     * ContractInfoDocumentのcancellationDateに日時をセットして送信
     */
    async handlerSubmit() {
      const loader = this.$loading.show()
      const newContractInfo = cloneDeep(this.ownContractInfo)
      newContractInfo.cancellationDate = dayjs().valueOf()
      const cancelContractResult = await this.cancelContract(newContractInfo)
      loader.hide()
      if (!cancelContractResult.isSuccess) {
        await this.$router.replace({ name: 'ErrorPage' })
        return
      }
      this.$router.push('/cancel/complete')
    },
  },
})
