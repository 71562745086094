























































import { defineComponent, PropType } from '@vue/composition-api'
import useCheckURL from '@/components/hook/useCheckURL'
import { NotificationItemType } from '@/components/NotificationPane/NotificationPane.vue'
import BaseNotificationItemWrapperParts from '@/components/NotificationPane/NotificationPane/parts/BaseNotificationItemWrapperParts.vue'
import CommentPostDateParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentPostDateParts.vue'
import NotificationTypeIconParts from '@/components/NotificationPane/NotificationPane/parts/NotificationTypeIconParts.vue'

/**
 * Notification 詳細アイテム: ベースパーツ
 */
export default defineComponent({
  name: 'BaseNotificationItemContentsParts',
  components: {
    NotificationTypeIconParts,
    CommentPostDateParts,
    BaseNotificationItemWrapperParts,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItemType>,
      required: true,
    },
  },
  setup() {
    // 外部リンク判定関数取得
    const { isExternalLink } = useCheckURL()

    return {
      isExternalLink,
    }
  },
})
