






import { defineComponent, inject, watch } from '@vue/composition-api'
import VueRouter from 'vue-router'
import usePusherContractInfo from '@/components/hook/usePusherContractInfo'

/**
 * SFgoメインページ
 */
export default defineComponent({
  name: 'MainPage',
  props: {
    /**
     * ヘッダーナビ表示指定
     */
    hasHeaderNav: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { redirectPath } = usePusherContractInfo()
    const router = inject('router') as VueRouter

    /**
     * 以下の条件に当てはまった場合はリダイレクトする
     * 1. 契約情報取得に失敗した場合は、強制ログアウトする（ユーザー操作で再度ログインしてもらう）
     * 2. 有料機能が使えるようになったタイミングで郵便番号が入っていない場合、マイページの会員情報編集画面に遷移する
     */
    watch(
      () => redirectPath.value,
      () => {
        if (redirectPath.value) {
          router.replace({ path: redirectPath.value })
        }
      },
    )
  },
})
