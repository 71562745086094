var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navigation-race-icon-parts",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M22 12H17.7808C17.3219 12 16.9219 12.3123 16.8106 12.7575L16 16H20.2192C20.6781 16 21.0781 15.6877 21.1894 15.2425L22 12Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 16H11.7808C11.3219 16 10.9219 16.3123 10.8106 16.7575L10 20H14.2192C14.6781 20 15.0781 19.6877 15.1894 19.2425L16 16Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6 16H1.78078C1.32191 16 0.921926 16.3123 0.810634 16.7575L0 20H4.21922C4.67809 20 5.07807 19.6877 5.18937 19.2425L6 16Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24 4H19.7808C19.3219 4 18.9219 4.3123 18.8106 4.75746L18 8H22.2192C22.6781 8 23.0781 7.6877 23.1894 7.24254L24 4Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18 8H13.7808C13.3219 8 12.9219 8.3123 12.8106 8.75746L12 12H16.2192C16.6781 12 17.0781 11.6877 17.1894 11.2425L18 8Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 12H7.78078C7.32191 12 6.92193 12.3123 6.81063 12.7575L6 16H10.2192C10.6781 16 11.0781 15.6877 11.1894 15.2425L12 12Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 4H9.78078C9.32191 4 8.92193 4.3123 8.81063 4.75746L8 8H12.2192C12.6781 8 13.0781 7.6877 13.1894 7.24254L14 4Z",
          fill: "currentColor",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 8H3.78078C3.32191 8 2.92193 8.3123 2.81063 8.75746L2 12H6.21922C6.67809 12 7.07807 11.6877 7.18937 11.2425L8 8Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }