var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-reset-new-password-page" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("PasswordResetPage.subTitle") },
      }),
      _c("password-reset-new-password-pane", {
        ref: "passwordResetNewPasswordPane",
        staticClass: "password-reset-new-password-page__content",
        on: {
          buttonDisabled: _vm.changeButtonDisabledStatus,
          "success-reset": _vm.moveToComplete,
        },
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector: ".password-reset-new-password-page__content",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc(
                "PasswordResetPage.NewPasswordPage.submitBtnText"
              ),
              isDisabled: _vm.changeButtonDisabled,
              colorType: "primary",
            },
            on: { push: _vm.resetPassword },
          }),
          _c("atom-router-button", {
            attrs: {
              to: "/login",
              linkText: _vm.$tc("common.cancel"),
              colorType: "tertiary",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }