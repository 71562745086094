<template>
  <div class="mission-term-parts">
    <p class="mission-term-parts__title">{{ mission.missionTermTitle }}</p>
    <p class="mission-term-parts__term">{{ mission.missionTerm }}</p>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MissionTermParts',
  props: {
    mission: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.mission-term-parts {
  text-align: center;

  &__title {
    font-size: 12px;
    font-weight: 500;
    color: #73ffdd;
    letter-spacing: 0.02em;
    line-height: 1.1;
  }

  &__term {
    font-size: 12px;
    font-weight: 500;
    color: #73ffdd;
    letter-spacing: 0.02em;
    line-height: 1.1;
  }
}
</style>
