var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("summary", { staticClass: "timetable-header" }, [
    _c("span", { staticClass: "timetable-header__title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "span",
        {
          staticClass: "timetable-header__title-icon",
          class: { "timetable-header__title-icon--has-title": _vm.title },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "14",
                height: "14",
                viewBox: "0 0 14 14",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M12.7266 9.33337L8.23667 4.84348C7.55325 4.16007 6.44522 4.16007 5.7618 4.84348L1.27191 9.33338L3.39323 9.33337L6.82246 5.90414C6.92009 5.80651 7.07838 5.80651 7.17601 5.90414L10.6052 9.33337L12.7266 9.33337Z",
                  fill: "black",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _c("span", { staticClass: "timetable__block-date" }, [
      _vm._v(_vm._s(_vm.getTimeTableDate(_vm.date))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }