var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "award-ranking-header-section" }, [
    _c("div", { staticClass: "award-ranking-header-section__inner" }, [
      _c("img", {
        staticClass: "award-ranking-header-section__bg",
        attrs: { src: require("@/assets/img/AwardPage/icon_cup.svg"), alt: "" },
      }),
      _c("h3", { staticClass: "award-ranking-header-section__title" }, [
        _c("i", [
          _c("img", {
            attrs: {
              src: require("@/assets/img/AwardPage/icon_driver.svg"),
              alt: "",
            },
          }),
        ]),
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _vm.description
        ? _c("p", {
            staticClass: "award-ranking-header-section__description",
            domProps: { textContent: _vm._s(_vm.description) },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }