import DeviceInfo from '@/util/DeviceInfo'
import IAudioPlayer from '@/util/audioplayer/IAudioPlayer'

/**
 * ブラウザで動作するネイティブの音声プレーヤー。
 * HTML Audio を利用して音声を再生する。
 */
export default class BrowserAudioPlayer implements IAudioPlayer {
  /**
   * 音声プレーヤー本体。 HTML Audio を設定する
   */
  audio = new Audio()

  /**
   * バックグラウンド通知イベントリスナー
   */
  appInBackgroundEvent: (() => void) | null = null

  /**
   * イベントリスナーを登録する。
   *
   * @param type イベントタイプ
   * @param callback イベント発生時に呼び出されるコールバック関数を指定する
   */
  addEventListener(
    type: 'play' | 'pause' | 'timeupdate' | 'ended',
    callback: (time?: number) => void,
  ): void {
    this.audio.addEventListener(type, () => {
      callback()
    })
  }

  /**
   * 音声プレーヤーを破棄する。
   */
  destroy(): void {
    // バックグランドのイベントリスナー破棄
    if (this.appInBackgroundEvent != null) {
      document.removeEventListener('pause', this.appInBackgroundEvent)
      this.appInBackgroundEvent = null
    }
    this.audio.remove()
  }

  /**
   * 音声の現在の再生位置を取得する。
   */
  async getCurrentTime(): Promise<number> {
    return this.audio.currentTime
  }

  /**
   * 音声の長さを取得する。
   */
  getDuration(): number {
    return this.audio.duration
  }

  /**
   * 音声の再生を一時停止する。
   *
   * Audio APIのpauseがPromiseを返さないため、再生停止完了を待機するため、setIntervalで再生が停止されるまで待機する。
   * 再生停止しているかどうかを100ms毎に確認し、最大10回確認する。1000ms を超えても再生停止を確認しなかった場合は、Promiseをresolveする。
   */
  pause(): Promise<void> {
    return new Promise((resolve) => {
      this.audio.pause()
      let counter = 0
      const intervalId = setInterval(() => {
        counter += 1
        if (counter > 10) {
          clearInterval(intervalId)
          resolve()
          return
        }
        if (this.audio.paused) {
          clearInterval(intervalId)
          resolve()
        }
      }, 100)
    })
  }

  /**
   * 音声を再生する。
   */
  play(): Promise<void> {
    return this.audio.play()
  }

  /**
   * 音声のURLを指定する。
   *
   * @param src URL
   */
  setSrc(src: string): void {
    if (this.appInBackgroundEvent != null) {
      document.removeEventListener('pause', this.appInBackgroundEvent)
      this.appInBackgroundEvent = null
    }

    // Androidのみ
    if (DeviceInfo.isCordova() && DeviceInfo.isAndroid()) {
      this.appInBackgroundEvent = this.appInBackground.bind(this)
      document.addEventListener('pause', this.appInBackgroundEvent)
    }

    this.audio.src = src
  }

  /**
   * アプリがバッググランド化した時の処理
   */
  appInBackground() {
    // 先に無線が止まって、映像の音が流れ始めるのでポーズを少し遅らせる
    setTimeout(() => {
      this.pause()
    }, 500)
  }
}
