import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * デバイス情報の型定義
 */
export type DeviceInfo = {
  /** エンドポイントデバイスのモデル名または番号 */
  model?: string
  /** タイムゾーン TimeZone Database の定義名(America/Los_Angeles , Asia/Tokyo など)を指定 */
  timezone?: string
  /** ロケール 例: ja_jp */
  locale?: string
  /** プラットフォーム */
  platform?: string
  /** プラットフォームバージョン */
  platformVersion?: string
}

/**
 * Push通知先エンドポイントの情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1222049874/API
 */
export default class PushNotificationEndpointDocument extends DocumentWrapper {
  constructor(initProps?: Partial<PushNotificationEndpointDocument>) {
    super(initProps as Partial<PushNotificationEndpointDocument>)
    this.mergeToThis(initProps)
  }

  /** エンドポイント */
  _path = 'notification/endpoint'

  /** idは存在しないため未指定 */
  idAttr = ''

  /**
   * 通知先タイプ
   */
  type: string | null = null

  /**
   * 通知先アドレス
   */
  address: string | null = null

  /**
   * デバイス情報
   */
  deviceInfo: DeviceInfo | null = null

  /**
   * デバイスアプリケーションID
   */
  deviceApplicationId: string | null = null

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `PushNotificationEndpointDocument[type: ${this.type}, address: ${this.address}]`
  }
}
