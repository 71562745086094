var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", { staticClass: "trophy-icon-parts" }, [
    _c(
      "svg",
      {
        attrs: {
          width: "19",
          height: "18",
          viewBox: "0 0 19 18",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("path", {
          attrs: {
            d: "M13.25 3.5465V2H4.25V3.5465H1.25V5.75C1.25 7.64375 2.66675 9.1985 4.493 9.449C4.75458 10.2161 5.21855 10.8983 5.83586 11.4234C6.45317 11.9486 7.20089 12.2972 8 12.4325V13.25C8 13.6478 7.84196 14.0294 7.56066 14.3107C7.27936 14.592 6.89782 14.75 6.5 14.75H5.75V16.25H11.75V14.75H11C10.6022 14.75 10.2206 14.592 9.93934 14.3107C9.65804 14.0294 9.5 13.6478 9.5 13.25V12.4325C10.2991 12.2972 11.0468 11.9486 11.6641 11.4234C12.2815 10.8983 12.7454 10.2161 13.007 9.449C14.8333 9.1985 16.25 7.64375 16.25 5.75V3.5465H13.25ZM2.75 5.75V5.0465H4.25V7.871C3.81162 7.71554 3.43209 7.42822 3.16352 7.04846C2.89495 6.6687 2.7505 6.21513 2.75 5.75ZM14.75 5.75C14.7494 6.21521 14.605 6.66885 14.3364 7.04871C14.0679 7.42857 13.6884 7.71606 13.25 7.87175V5.0465H14.75V5.75Z",
            fill: _vm.positionIconColor,
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }