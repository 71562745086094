


















import { defineComponent } from '@vue/composition-api'

/**
 * グローバルナビゲーション: マイページリンクアイコンパーツ
 */
export default defineComponent({
  name: 'NavigationMyPageIconParts',
  props: {
    /**
     * カレント表示指定
     */
    current: {
      type: Boolean,
      default: false,
    },
  },
})
