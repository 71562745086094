var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment-editor-parts",
      class: [
        "comment-editor-parts--" + _vm.screenOrientationType,
        {
          "comment-editor-parts--active": !(
            _vm.isCommentEmpty || _vm.isSubmitDisabled
          ),
        },
      ],
    },
    [
      _c("AtomTextArea", {
        staticClass: "comment-editor-parts__textarea",
        attrs: {
          focusOnMount: true,
          placeholder: _vm.$tc("RaceVideoPage.highlights.comment.placeholder"),
        },
        model: {
          value: _vm.messageText,
          callback: function ($$v) {
            _vm.messageText = $$v
          },
          expression: "messageText",
        },
      }),
      _c("div", { staticClass: "comment-editor-parts__buttons" }, [
        _c(
          "div",
          { staticClass: "comment-editor-parts__count" },
          [
            _c("comment-count-parts", {
              attrs: {
                comment: _vm.messageText,
                commentLength: _vm.messageLength,
              },
            }),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass:
              "comment-editor-parts__button comment-editor-parts__button--cancel",
            on: { click: _vm.emitChancel },
          },
          [_c("highlights-cancel-icon")],
          1
        ),
        _c(
          "button",
          {
            staticClass:
              "comment-editor-parts__button comment-editor-parts__button--save",
            attrs: { disabled: _vm.isCommentEmpty || _vm.isSubmitDisabled },
            on: { click: _vm.emitPost },
          },
          [_c("highlights-submit-icon")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }