var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldset-favorite-player-parts" },
    [
      _c("label", [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.$tc("SignupPage.FormInput.FavoritePlayer"))),
        ]),
        _vm.required
          ? _c("span", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$tc("formParts.required"))),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "fieldset-favorite-player-parts__current-view" },
        [_c("player-card-parts", { attrs: { name: _vm.name, img: _vm.img } })],
        1
      ),
      _vm.name
        ? [
            _c(
              "div",
              { staticClass: "fieldset-favorite-player-parts__link" },
              [
                _c("atom-router-button", {
                  attrs: {
                    to: _vm.linkTo,
                    linkText: _vm.$t(
                      "MypagePage.MypageTopPage.favoritePlayerChange"
                    ),
                    colorType: "tertiary",
                  },
                }),
              ],
              1
            ),
          ]
        : [
            _c(
              "router-link",
              {
                staticClass: "fieldset-favorite-player-parts__selector",
                attrs: { to: _vm.linkTo },
              },
              [
                _c(
                  "figure",
                  {
                    staticClass:
                      "fieldset-favorite-player-parts__selector__img",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "assets/img/common/card/icon_player__green.svg",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "fieldset-favorite-player-parts__selector__message",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fieldset-favorite-player-parts__selector__text",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "SignupPage.FormInput.FavoritePlayerSelectorText"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "fieldset-favorite-player-parts__selector__caption",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "SignupPage.FormInput.FavoritePlayerSelectorCaption"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "svg",
                  {
                    staticClass:
                      "fieldset-favorite-player-parts__selector__arrow",
                    attrs: {
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M7.70709 2.58545L15.7071 10.5854C16.4881 11.3665 16.4881 12.6328 15.7071 13.4139L7.70709 21.4139L7.70709 18.5854L14.2929 11.9997L7.70709 5.41388L7.70709 2.58545Z",
                        fill: "#808080",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }