import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 契約情報クレジットカード登録を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3481010261/API
 */
export default class ContractInfoCardRequestDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoCardRequestDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contract_info/card_request'

  /** idは存在しないため未指定 */
  idAttr = ''

  /**
   * クライアントシークレット
   */
  clientSecret: string | null = null
}
