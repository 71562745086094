import Logger from '@/util/logger/Logger'
import IAudioPlayer from '@/util/audioplayer/IAudioPlayer'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * iOS/Android で動作するネイティブの音声プレーヤー。
 * cordova-plugin-media を利用して実装している。
 * https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-media/index.html
 */
export default class NativeAudioPlayer implements IAudioPlayer {
  /**
   * 音声プレーヤー本体。音声の再生機能を提供する。
   */
  media: Media | null = null

  /**
   * 音声の再生開始イベントのリスナー
   */
  playListener: (() => void) | null = null

  /**
   * 音声の停止イベントのリスナー
   */
  pauseListener: (() => void) | null = null

  /**
   * 音声の再生位置の変更イベントのリスナー
   */
  timeUpdateListener: ((time: number) => void) | null = null

  /**
   * 音声の再生終了イベントのリスナー
   */
  endedListener: (() => void) | null = null

  /**
   * 音声の再生位置が変化した際の、変化前の直前の音声の再生位置
   */
  previousCurrentTime = 0

  /**
   * 音声の再生位置を定期的に確認する setInterval ID
   */
  timeUpdateIntervalId: number | null = null

  /**
   * バックグラウンド通知イベントリスナー
   */
  appInBackgroundEvent: (() => void) | null = null

  /**
   * 音声プレーヤーを破棄する。
   */
  destroy() {
    // バックグランドのイベントリスナー破棄
    if (this.appInBackgroundEvent != null) {
      document.removeEventListener('pause', this.appInBackgroundEvent)
      this.appInBackgroundEvent = null
    }
    this.media?.release()
    if (this.timeUpdateIntervalId) {
      clearInterval(this.timeUpdateIntervalId)
    }
  }

  /**
   * 音声プレーヤーを作成する。
   * @param src 音声のURL。署名URLに変換される。
   */
  createMedia(src: string) {
    this.destroy()
    const mediaUrl = CloudFrontUtil.getSignedUrl(src)
    const media = new Media(
      mediaUrl,
      () => {
        Logger.info(`Success to initialize Native Audio player. src: ${mediaUrl}`)
      },
      (error) => {
        Logger.info(`Failed to initialize Native Audio player. error: ${error}, src: ${mediaUrl}`)
      },
      (status) => {
        // MEDIA_STOPPED, MEDIA_RUNNING 以外は発火しない
        // MEDIA_RUNNINGは再生開始時に一度だけ発火
        if (status === Media.MEDIA_STARTING) {
          Logger.debug(`Media.MEDIA_STARTING event occurred: ${status}`)
          // this.playListener?.()
        } else if (status === Media.MEDIA_RUNNING) {
          Logger.debug(`Media.MEDIA_RUNNING event occurred: ${status}`)
          // this.getCurrentTime().then((currentTime) => this.timeUpdateListener?.(currentTime))
        } else if (status === Media.MEDIA_PAUSED) {
          Logger.debug(`Media.MEDIA_PAUSED event occurred: ${status}`)
          // this.pauseListener?.()
        } else if (status === Media.MEDIA_STOPPED) {
          this.endedListener?.()
        }
      },
    )
    // 音声の再生位置を定期的に取得するため setInterval を利用する。
    this.timeUpdateIntervalId = setInterval(async () => {
      if (!this.media) {
        return
      }
      const currentTime = await this.getCurrentTime()
      if (Math.abs(currentTime - this.previousCurrentTime) > 0.05) {
        // 直前の音声の再生位置から、0.05秒以上変化していたらイベントを発火する
        this.timeUpdateListener?.(currentTime)
      }

      this.previousCurrentTime = currentTime
    }, 100)

    // バックグランド化時のイベントリスナー登録
    this.appInBackgroundEvent = this.appInBackground.bind(this)
    document.addEventListener('pause', this.appInBackgroundEvent)

    return media
  }

  /**
   * 音声の長さを取得する。
   */
  getDuration(): number {
    return this.media?.getDuration() ?? 0
  }

  /**
   * 音声の現在の再生位置を取得する。
   */
  getCurrentTime(): Promise<number> {
    return new Promise((resolve) => {
      if (this.media) {
        this.media.getCurrentPosition((position) => {
          resolve(position)
        })
      } else {
        resolve(0)
      }
    })
  }

  /**
   * 音声のURLを指定する。
   *
   * @param src URL
   */
  setSrc(src: string) {
    this.media = this.createMedia(src)
  }

  /**
   * 音声を再生する。
   */
  async play() {
    this.media?.play({ playAudioWhenScreenIsLocked: true })
    this.playListener?.()
  }

  /**
   * 音声の再生を一時停止する。
   */
  async pause() {
    this.media?.pause()
    this.pauseListener?.()
  }

  /**
   * イベントリスナーを登録する。
   *
   * @param type イベントタイプ
   * @param callback イベント発生時に呼び出されるコールバック関数を指定する
   */
  addEventListener(
    type: 'play' | 'pause' | 'timeupdate' | 'ended',
    callback: (time?: number) => void,
  ) {
    switch (type) {
      case 'play':
        this.playListener = callback
        break
      case 'pause':
        this.pauseListener = callback
        break
      case 'timeupdate':
        this.timeUpdateListener = callback
        break
      case 'ended':
        this.endedListener = callback
        break
      default:
        Logger.warn(`Invalid type specified. type: ${type}`)
    }
  }

  /**
   * アプリがバッググランド化した時の処理
   */
  appInBackground() {
    this.pause()
  }
}
