var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "x-icon-parts",
      attrs: {
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_19050_223137)" } }, [
        _c("path", {
          attrs: {
            d: "M18.6069 14.3736L26.3053 5.4248H24.4811L17.7965 13.1949L12.4576 5.4248H6.2998L14.3733 17.1746L6.2998 26.5587H8.12419L15.1832 18.3533L20.8215 26.5587H26.9793L18.6065 14.3736H18.6069ZM16.1082 17.2781L15.2902 16.1081L8.78154 6.79817H11.5837L16.8362 14.3116L17.6542 15.4816L24.4819 25.2478H21.6798L16.1082 17.2785V17.2781Z",
            fill: "currentColor",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_19050_223137" } }, [
          _c("rect", { attrs: { width: "32", height: "32", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }