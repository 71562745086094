













import { computed, defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypageContractDetailsPane from '@/components/MypageContractPage/MypageContractDetailsPane.vue'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import DeviceInfo from '@/util/DeviceInfo'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * マイページ: 契約情報ページ
 */
export default defineComponent({
  name: 'MypageContractPage',
  components: {
    GlobalNavigationPane,
    SubHeaderSection,
    MypageContractDetailsPane,
  },
  setup() {
    /**
     * アプリから遷移したかどうか
     */
    const { transitionFromMobileApp } = LocalStorageAccessor
    /**
     * /Androidブラウザで開いている場合はアプリに戻るようにするための条件
     * TODO: ※iPhoneからアプリに戻れないバグの対応が出来次第、「アプリから遷移した場合はアプリに戻る」という挙動にする。
     * 現在はAndroidのみアプリに戻るようにする
     */
    const canReturnToApp = computed(() => transitionFromMobileApp && DeviceInfo.isAndroidBrowser())

    const mypageUrl = computed(() =>
      // アプリから遷移 & Androidブラウザで開いてる場合は、アプリに戻るようにする。
      canReturnToApp.value
        ? `${process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL}#/mypage`
        : '/mypage',
    )

    return {
      mypageUrl,
      canReturnToApp,
    }
  },
})
