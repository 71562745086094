import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import SceneMoviesDocument from '@/store/stores/collectionModule/documents/highlight/SceneMoviesDocument'

/**
 * シーン動画集(ハイライト切り抜き動画)を操作するためのstore
 */
export default function useSceneMovies() {
  const sceneMoviesCollectionModule = CollectionModule.createStore(SceneMoviesDocument)

  /**
   * シーン動画集(ハイライト切り抜き動画)一覧を取得する。
   */
  const fetchSceneMoviesList = () => sceneMoviesCollectionModule.fetch()

  /**
   * シーン動画集(ハイライト切り抜き動画)一覧を返す
   */
  const sceneMoviesList = computed(() => sceneMoviesCollectionModule.data)

  /**
   * keyがHighlightIdのvalueがSceneMoviesDocumentのRecordを返す
   */
  const sceneMoviesByHighlightId = computed(() =>
    sceneMoviesCollectionModule.data
      .sort((a, b) => (a._createdDate || 0) - (b._createdDate || 0))
      .reduce((acc, cur) => {
        if (!cur || !cur.scenes || cur.scenes.length === 0) return acc
        acc[cur.scenes[0].gameEventId] = cur

        return acc
      }, {} as Record<string, SceneMoviesDocument>),
  )

  /**
   * fileTitleで禁止されている文字列を除去する
   */
  const removeInvalidChars = (filename: string) => {
    // 正規表現パターンを定義
    const pattern = /[/+{}^%`[\]~<>#|.,\\]/g

    // ファイル名から無効な文字を全て削除
    return filename.replace(pattern, '')
  }

  /**
   * シーン動画集(ハイライト切り抜き動画)を作成する
   */
  const saveSceneMovies = (requestData: SceneMoviesDocument) =>
    sceneMoviesCollectionModule.save(requestData)

  /**
   * 取得した情報をクリアする
   */
  const clearSceneMovies = () => {
    sceneMoviesCollectionModule.clearData()
  }

  return {
    fetchSceneMoviesList,
    removeInvalidChars,
    saveSceneMovies,
    sceneMoviesList,
    sceneMoviesByHighlightId,
    clearSceneMovies,
  }
}
