







import { computed, defineComponent, PropType } from '@vue/composition-api'
import type { CautionMessageInfoType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * メンテナンス表示のセクション。
 */
export default defineComponent({
  name: 'CautionMaintenanceSection',
  props: {
    cautionMessageInfo: {
      type: Object as PropType<CautionMessageInfoType>,
      required: true,
    },
  },
  setup() {
    /** スマートフォンかどうか */
    const isSmartPhone = computed(DeviceInfo.isSmartPhone)

    return {
      isSmartPhone,
    }
  },
})
