















































import { computed, defineComponent, inject, ref, Ref } from '@vue/composition-api'
import VueRouter from 'vue-router'
import MypageContractLogTableSection from '@/components/MypageContractPage/MypageContractLogPane/MypageContractLogTableSection.vue'
import useMypageContractLog, {
  PlanContractLogDataType,
} from '@/components/MypageContractPage/hook/useMypageContractLog'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import AtomLink from '@/components/atoms/AtomLink.vue'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'

/**
 * マイページ: プラン契約履歴 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageContractLogPane',
  components: {
    AtomLink,
    MypageContractLogTableSection,
  },
  setup() {
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { paymentMethod } = contractInfoStore
    const { fetchPlanContractLogData } = useMypageContractLog()

    const router = inject('router') as VueRouter

    /** プラン契約履歴に表示するデータ */
    const planContractLogData: Ref<Array<PlanContractLogDataType> | null> = ref(null)

    /** プラン契約履歴に表示するデータを取得 */
    const fetchPlanContractLog = async () => {
      const result = await fetchPlanContractLogData()
      if (!result) {
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageContract.logPage.errors.fetchPlanContractLogData.title'),
          message: I18n.tc(
            'MypagePage.MypageContract.logPage.errors.fetchPlanContractLogData.message',
          ),
        })
        await router.replace({ name: 'MypageTopPage' })
        return
      }
      // 取得結果をセット
      planContractLogData.value = result
    }
    fetchPlanContractLog()

    // アプリまたはアプリ内課金している場合に表示するメッセージ
    const appMessage = computed(() => {
      if (DeviceInfo.isiOS() || paymentMethod === 'AppStore') {
        return I18n.tc('MypagePage.MypageContract.logPage.message.iOS')
      }
      if (DeviceInfo.isAndroid() || paymentMethod === 'GooglePlay') {
        return I18n.tc('MypagePage.MypageContract.logPage.message.android')
      }
      return ''
    })

    // アプリまたはアプリ内課金している場合に表示する外部リンク集
    const appExternalLinks = computed(() => {
      if (DeviceInfo.isiOS() || paymentMethod === 'AppStore') {
        return [
          {
            text: I18n.tc('MypagePage.MypageContract.logPage.history.iOS'),
            url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.HISTORY.IOS,
          },
          {
            text: I18n.tc('MypagePage.MypageContract.logPage.history.iOSSubscription'),
            url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.IOS,
          },
        ]
      }
      if (DeviceInfo.isAndroid() || paymentMethod === 'GooglePlay') {
        return [
          {
            text: I18n.tc('MypagePage.MypageContract.logPage.history.android'),
            url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.HISTORY.ANDROID,
          },
        ]
      }
      return []
    })

    /**
     * アプリの場合に表示するメッセージ。以下の場合に表示する。
     * 1. Google Playで課金しiOS機器でプラン契約履歴を開く
     * 2. App Storeで課金しAndroidでプラン契約履歴を開く
     */
    const appAdditionalMessage = computed(() => {
      if (DeviceInfo.isiOS() && paymentMethod === 'GooglePlay') {
        return I18n.tc('MypagePage.MypageContract.logPage.message.android')
      }
      if (DeviceInfo.isAndroid() && paymentMethod === 'AppStore') {
        return I18n.tc('MypagePage.MypageContract.logPage.message.iOSWebOnly')
      }
      return ''
    })

    /**
     * スマートフォン用の購入履歴確認リンク集。以下の場合に表示する。
     * 1. Google Playで課金しiOS機器でプラン契約履歴を開く
     * 2. App Storeで課金しAndroidでプラン契約履歴を開く
     */
    const appAdditionalExternalLinks = computed(() => {
      if (DeviceInfo.isiOS() && paymentMethod === 'GooglePlay') {
        return [
          {
            text: I18n.tc('MypagePage.MypageContract.logPage.history.android'),
            url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.HISTORY.ANDROID,
          },
        ]
      }
      if (DeviceInfo.isAndroid() && paymentMethod === 'AppStore') {
        return [
          {
            id: 'iOS',
            text: I18n.tc('MypagePage.MypageContract.logPage.history.iOS'),
            url: Const.EXTERNAL_LINKS.IN_APP_PURCHASE.HISTORY.IOS,
          },
        ]
      }
      return []
    })

    return {
      planContractLogData,
      appMessage,
      appExternalLinks,
      appAdditionalMessage,
      appAdditionalExternalLinks,
    }
  },
})
