var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "common-modal-section",
    {
      staticClass: "mypage-paid-plan-complete-modal-section",
      on: { close: _vm.handleOkClicked },
    },
    [
      _c(
        "div",
        {
          staticClass: "mypage-paid-plan-complete-modal-section__header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", {
            staticClass: "mypage-paid-plan-complete-modal-section__title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mypage-paid-plan-complete-modal-section__content",
          attrs: { slot: "default" },
          slot: "default",
        },
        [
          _c("p", {
            staticClass: "mypage-paid-plan-complete-modal-section__message",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tc("MypagePage.PaidCompleteModal.message")
              ),
            },
          }),
          false
            ? _c("atom-icon-link-button", {
                staticClass: "mypage-paid-plan-complete-modal-section__link",
                attrs: {
                  url: _vm.linkUrl,
                  linkText: _vm.$tc("MypagePage.PaidCompleteModal.linkLabel"),
                  colorType: "tertiary",
                  imgPath: require("@/assets/img/icon/icon_external_link_gray.svg"),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mypage-paid-plan-complete-modal-section__buttons",
          attrs: { slot: "buttons" },
          slot: "buttons",
        },
        [
          _c("atom-button", {
            attrs: { linkText: "OK", colorType: "secondary" },
            on: { onClick: _vm.handleOkClicked },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }