var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("modal-message-parts", {
              attrs: { message: _vm.$t("ConfirmLeavingPageModal.message") },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$t("ConfirmLeavingPageModal.submitText"),
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose("submit")
                },
              },
            }),
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$t("ConfirmLeavingPageModal.cancelText"),
                colorType: "tertiary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose("cancel")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }