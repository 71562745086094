var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-sns-team-select-pane" },
    [
      _c("team-select-list-section", {
        attrs: { listData: _vm.teams, selectedID: _vm.selectedID },
        on: { selectChange: _vm.changeSelected },
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector: ".home-sns-team-select-page__content",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: { linkText: _vm.$tc("common.set"), isDisabled: false },
            on: { push: _vm.changeSnsPostDisplayTeam },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }