import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import useDynamoDB from '@/store/hook/useDynamoDB'
import FeedDataDocument from '@/store/stores/collectionModule/documents/feed/FeedDataDocument'

/**
 * SNS/SF公式サイト記事データを操作するための処理を取得する。
 */
export default function useSnsFeedData() {
  // Collection modules
  const feedDataCollectionModule = CollectionModule.createStore(FeedDataDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * SNSフィード情報を取得する。
   *
   * @param relatedDataId 取得対象のドライバー/チームのマスタID情報のプライマリーキーを指定する.
   * SF公式のSNSアカウントのフィードを取得する場合、 'SF_Official_SNS' を指定する.
   * @param fromDate 取得開始時間(UnixTime 単位: ミリ秒)
   * @param toDate 取得終了時間(UnixTime 単位: ミリ秒)
   */
  const fetchSnsFeed = async (relatedDataId: string, fromDate: number, toDate: number) => {
    const result = await searchDateRangeData({
      tableName: 'feed-data',
      partitionKey: 'relatedDataId',
      partitionKeyValue: relatedDataId,
      sortKey: 'createdDate',
      from: fromDate,
      to: toDate,
    })
    feedDataCollectionModule.data = result as Array<FeedDataDocument>
  }

  /**
   * 取得したSNSフィード情報。
   */
  const feedDataList = computed(() => feedDataCollectionModule.data)

  /**
   * 取得したSNSフィード情報をクリアする
   */
  const clearSnsFeedData = () => {
    feedDataCollectionModule.clearData()
  }

  return {
    fetchSnsFeed,
    feedDataList,
    clearSnsFeedData,
  }
}
