var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comparison-plan-section" },
    [
      _c("h4", {
        staticClass: "comparison-plan-section__title",
        domProps: { innerHTML: _vm._s(_vm.sectionTitle) },
      }),
      _vm.isForApp
        ? _c(
            "p",
            {
              staticClass:
                "comparison-plan-section__in-app-purchase-description",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$tc(
                      "SignupPage.SelectPlanPage.comparison.inAppPurchaseDescription"
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.isForApp
        ? _c(
            "p",
            {
              staticClass: "comparison-plan-section__point-achieve-description",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$tc(
                      "SignupPage.SelectPlanPage.comparison.pointAchieveDescription"
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "comparison-plan-section__official-SFgo" },
        [
          _vm.officialSFgoLinkVisible
            ? _c("atom-link", {
                staticClass: "comparison-plan-section__official-SFgoLink",
                attrs: {
                  url: _vm.officialSFgoLink,
                  linkText: _vm.$tc(
                    "MypagePage.MypageSwitchToPaidPlanPage.officialSFgoLink"
                  ),
                  isOpenAsBlank: true,
                  imgPath: require("@/assets/img/icon/icon_external_link_black.svg"),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("comparison-plan-table-parts"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }