var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "highlight-toast-parts" },
    [
      _c("p", { staticClass: "highlight-toast-parts__message" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$tc("RaceVideoPage.highlights.block.toast.message", 0, {
                name: _vm.userName,
              })
            ) +
            " "
        ),
      ]),
      _c("atom-button", {
        staticClass: "highlight-toast-parts__button",
        attrs: {
          linkText: _vm.$tc("RaceVideoPage.highlights.block.toast.buttonLabel"),
        },
        on: { onClick: _vm.handleCancelClicked },
      }),
      _c(
        "button",
        {
          staticClass: "highlight-toast-parts__close-button",
          on: { click: _vm.handleCloseClicked },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { opacity: "0.7" } }, [
                _c("path", {
                  attrs: {
                    d: "M16.5856 6H19.414L13.414 12.0001L19.4139 18L16.5855 18L11.9997 13.4143L7.41403 18L4.5856 18L10.5855 12.0001L4.58545 6H7.41388L11.9997 10.5859L16.5856 6Z",
                    fill: "white",
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }