import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import type { DynamoDBTableType } from '@/store/stores/loginStore/DynamoDBCredentialStore'

/**
 * DynamoDB 認証情報の型
 */
export type DynamoDBCredentialsType = {
  dynamoDBAccessKey: string
  dynamoDBSecretAccessKey: string
  dynamoDBSessionToken: string
  securityTokenExpiredDate: number
  dynamoDBTableName: DynamoDBTableType | undefined
  dynamoDBRegion: string
}

/**
 * DynamoDBの参照権限リクエストをPOSTする際のボディ情報を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3319365695/DynamoDB+API
 */
export default class AccessRequestDocument extends DocumentWrapper {
  constructor(initProps?: Partial<AccessRequestDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    const gpsInitProps = initProps
    this.mergeToThis(gpsInitProps)
  }

  _path = 'data/access_request'

  /** idは存在しないため未指定 */
  idAttr = ''

  /**
   * テーブル名
   */
  type: null | string = null

  /**
   * DynamoDBアクセスキー
   */
  dynamoDBAccessKey: null | string = null

  /**
   * DynamoDBシークレットアクセスキー
   */
  dynamoDBSecretAccessKey: null | string = null

  /**
   * DynamoDBセッショントークン
   */
  dynamoDBSessionToken: null | string = null

  /**
   * DynamoDBトークン有効期限
   */
  securityTokenExpiredDate = 0

  /**
   * 参照可能なDynamoDBテーブル名
   */
  dynamoDBTableName: undefined | DynamoDBTableType = undefined

  /**
   * DynamoDBの動作するリージョン
   */
  dynamoDBRegion: null | string = null

  /**
   * DynamoDBの認証情報を取得する。
   */
  get dynamoDBCredentials(): DynamoDBCredentialsType | null {
    if (this.hasCredentials) {
      return this as DynamoDBCredentialsType
    }
    return null
  }

  /**
   * アクセス情報を保持しているかどうかを判定する。
   */
  get hasCredentials() {
    return (
      this.dynamoDBAccessKey &&
      this.dynamoDBSecretAccessKey &&
      this.dynamoDBSessionToken &&
      this.securityTokenExpiredDate &&
      this.dynamoDBTableName &&
      this.dynamoDBRegion
    )
  }
}
