var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-video-highlights-pane",
      class: "race-video-highlights-pane--" + _vm.screenOrientationType,
    },
    [
      _c("highlight-header-section", {
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          lockOrientation: _vm.lockOrientation,
        },
        on: {
          filterSelectEnabled: _vm.handleEnabledFilterSelect,
          onChangeDriverFilter: _vm.changeDriverFilter,
          onChangeSceneOrUserFilter: _vm.changeSceneOrUserFilter,
        },
      }),
      _c("highlight-contents-section", {
        ref: "highlightContentsSection",
        attrs: {
          screenOrientationType: _vm.screenOrientationType,
          current: _vm.selectedHighlightId,
          currentPlayer: _vm.selectedPlayerMasterIdForHighlight,
          highlightList: _vm.filteredHighlight,
          playEventList: _vm.playEvents,
          playerList: _vm.participatingPlayers,
          enabledInputsMap: _vm.enabledInputsMap,
          editModesMap: _vm.editModesMap,
          editModesMainCommentMap: _vm.editModesMainCommentMap,
          postingHighlightCommentsMap: _vm.postingHighlightCommentsMap,
          inputComment: _vm.inputComment,
          retrieveNameUsersByUserId: _vm.retrieveNameUsersByUserId,
          scrollToHighlightId: _vm.scrollToHighlightId,
          sceneMoviesByHighlightId: _vm.sceneMoviesByHighlightId,
          highlightCommentCountByHighlightId:
            _vm.highlightCommentCountByHighlightId,
          driverFilterId: _vm.driverFilterId,
          sceneFilterId: _vm.sceneFilterId,
          userFilterId: _vm.userFilterId,
        },
        on: {
          sceneChange: _vm.changeScene,
          onToggleEnabledInput: _vm.changeTargetEnabledInput,
          onToggleIsEditMode: _vm.changeTargetIsEditMode,
          onToggleMainCommentIsEditMode: _vm.changeTargetMainCommentIsEditMode,
          onChangeHighlightComment: _vm.setTargetHighlightComment,
          onChangeHighlightMainComment: _vm.setTargetHighlightMainComment,
          handlerEdit: _vm.editHighlightComment,
          handlerSubmit: _vm.postHighlightComment,
          onChangeLike: _vm.changeLike,
          onClickOpenMenu: _vm.handleOpenMenuClicked,
          onClickCommentMenu: _vm.handleOpenCommentMenuClicked,
          downloadHighlight: _vm.downloadHighlight,
          fetchParentCommentCountAndReactions:
            _vm.fetchParentCommentCountAndReactions,
          fetchDiffTargetHighlightCommentsAndReactions:
            _vm.fetchDiffTargetHighlightCommentsAndReactions,
        },
      }),
      _c("new-highlights-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.viewMode === "highlight" && _vm.newHighlightButtonVisible,
            expression: "viewMode === 'highlight' && newHighlightButtonVisible",
          },
        ],
        staticClass: "race-video-page__highlights",
        attrs: {
          videoPlayerKey: _vm.videoPlayerKey,
          screenOrientationType: _vm.screenOrientationType,
          highlightData: _vm.highlightData,
          createHighlight: _vm.createHighlight,
        },
        on: {
          openHighlightsModal: _vm.openHighlightsModal,
          onClickConfirmCancel: _vm.closeHighlightsModal,
          onSaveHighlight: _vm.saveHighlight,
          showHighlightPreviewVideoPlayer: _vm.showHighlightPreviewVideoPlayer,
          hideHighlightPreviewVideoPlayer: _vm.hideHighlightPreviewVideoPlayer,
        },
      }),
      _vm.messageModal
        ? _c("message-modal-section", {
            staticClass: "race-video-highlights-pane__modal",
            attrs: {
              message: _vm.messageModalText,
              buttonText: _vm.$tc("common.close"),
            },
            on: {
              close: function ($event) {
                _vm.messageModal = false
              },
            },
          })
        : _vm._e(),
      _vm.confirmDeleteModal
        ? _c("confirm-modal-section", {
            staticClass: "race-video-highlights-pane__modal",
            attrs: {
              message: _vm.deleteConfirmMessage,
              confirmButtonLabel: _vm.$tc("common.delete"),
            },
            on: {
              confirm: _vm.onDeleteHighlightComment,
              cancel: _vm.cancelDeleteHighlightComment,
            },
          })
        : _vm._e(),
      _vm.blockConfirmModal
        ? _c("confirm-modal-section", {
            staticClass: "race-video-highlights-pane__modal-block",
            class: { "race-video-highlights-pane__modal-block--pc": _vm.isPc },
            attrs: {
              title: _vm.$tc("RaceVideoPage.highlights.block.modal.title", 0, {
                name: _vm.toBeBlockedUserDisplayName,
              }),
              message:
                "<span>" +
                _vm.$tc("RaceVideoPage.highlights.block.modal.message1", 0, {
                  name: _vm.toBeBlockedUserDisplayName,
                }) +
                "</span><span>" +
                _vm.$tc("RaceVideoPage.highlights.block.modal.message2") +
                "</span>",
            },
            on: {
              confirm: _vm.handleBlockOkClicked,
              cancel: _vm.handleBlockCancelClicked,
            },
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.blockToastView
          ? _c(
              "div",
              {
                staticClass: "race-video-highlights-pane__toast",
                class: { "race-video-highlights-pane__toast--pc": _vm.isPc },
              },
              [
                _c("highlight-toast-parts", {
                  attrs: { userName: _vm.toBeBlockedUserDisplayName },
                  on: {
                    onClickCancel: _vm.cancelBlockUser,
                    onClickClose: _vm.closeBlockToastView,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.noticeHighlightOnboardingVisible
        ? _c("notice-highlight-section", {
            on: { onClickOk: _vm.handleNoticeOkClicked },
          })
        : _vm._e(),
      _vm.highlightSlideMenu
        ? _c("highlights-slide-menu-parts", {
            attrs: {
              highlightSlideMenu: _vm.highlightSlideMenu,
              sceneMovies: _vm.sceneMoviesForSelectedHighlight,
              canClipTargetOperationHighlightForPc:
                _vm.canClipTargetOperationHighlightForPc,
              reportFormUrl: _vm.reportFormUrl,
            },
            on: {
              onClickMenu: _vm.handleMenuClicked,
              onClickBlockMenu: _vm.handleBlockMenuClicked,
              onClickOverlay: _vm.handleMenuOverlayClicked,
              onClickCommentMenu: _vm.handleCommentMenuClicked,
              onClickHighlightPcMenu: _vm.handleHighlightPcMenuClicked,
            },
          })
        : _vm._e(),
      _vm.highlightsBrowserMenuVisible
        ? _c(
            "div",
            { staticClass: "race-video-highlights-pane__sns-post-menu" },
            [
              _c("highlight-sns-post-menu-parts", {
                attrs: {
                  sceneMovies: _vm.sceneMoviesForSelectedHighlight,
                  canClipTargetOperationHighlightForPc:
                    _vm.canClipTargetOperationHighlightForPc,
                },
                on: {
                  onClickClose: function ($event) {
                    _vm.highlightsBrowserMenuVisible = false
                  },
                  startDownloadPreparation: _vm.startDownloadPreparation,
                  downloadHighlight: _vm.downloadHighlight,
                  onSaveMissionHistory: _vm.savePostSnsMissionHistoryForPc,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.editHighlightVisible
        ? _c("edit-highlight-section", {
            attrs: {
              videoPlayerKey: _vm.videoPlayerKey,
              editType: _vm.highlightEditType,
              highlightData: _vm.highlightData,
              screenOrientationType: _vm.screenOrientationType,
              createHighlight: _vm.createHighlight,
            },
            on: {
              onClickClose: _vm.handleEditHighlightCloseClicked,
              onClickCommentCancel: _vm.openHighlightsModal,
              onClickConfirmCancel: _vm.closeHighlightsModal,
              onSaveHighlight: _vm.saveHighlight,
              showHighlightPreviewVideoPlayer:
                _vm.showHighlightPreviewVideoPlayer,
              hideHighlightPreviewVideoPlayer:
                _vm.hideHighlightPreviewVideoPlayer,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }