









import { defineComponent } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import MypageAboutUsingSection from '@/components/MypageAboutUsingPage/MypageAboutUsingPane/MypageAboutUsingSection.vue'
import Const from '@/util/Const'

interface AboutUsingListData {
  name: string
  link: string
  text: string
}

/**
 * マイページ: ご利用について コンテンツペイン
 */
export default defineComponent({
  name: 'MypageAboutUsingPane',
  components: {
    MypageAboutUsingSection,
  },
  setup() {
    // ご利用について データリスト
    const aboutUsingListItems = [
      {
        name: I18n.tc('MypagePage.MypageAboutUsingPage.privacyPolicy'),
        text: '',
        link: Const.EXTERNAL_LINKS.PRIVACY_POLICY,
      },
      {
        name: I18n.tc('MypagePage.MypageAboutUsingPage.tradingRule'),
        text: '',
        link: Const.EXTERNAL_LINKS.TRADING_RULE,
      },
      {
        name: I18n.tc('MypagePage.MypageAboutUsingPage.terms'),
        text: '',
        link: Const.EXTERNAL_LINKS.TERMS,
      },
    ] as Array<AboutUsingListData>

    return {
      aboutUsingListItems,
    }
  },
})
