import { ref, Ref } from '@vue/composition-api'
import DeviceInfo from '@/util/DeviceInfo'
import IAudioPlayer from '@/util/audioplayer/IAudioPlayer'
import NativeAudioPlayer from '@/util/audioplayer/NativeAudioPlayer'
import BrowserAudioPlayer from '@/util/audioplayer/BrowserAudioPlayer'

/**
 * 無線交信データの音声を再生するためのプレーヤーを取得する。
 */
export default function useRadioPlayer() {
  /**
   * 音声プレーヤー。
   */
  const audioPlayer: Ref<IAudioPlayer | null> = ref(null)

  /**
   * 音声プレーヤーを初期化する。
   */
  const initAudioPlayer = () => {
    if (!audioPlayer.value) {
      if (DeviceInfo.isCordova() && DeviceInfo.isiOS()) {
        // iOS の場合、HTML Audio でも音声再生時に映像が止まる問題があるため、ネイティブ音声プレーヤーを利用する。
        document.addEventListener(
          'deviceready',
          () => {
            audioPlayer.value = new NativeAudioPlayer()
          },
          false,
        )
      } else {
        audioPlayer.value = new BrowserAudioPlayer()
      }
    }
  }

  /**
   * 音声プレーヤーを破棄する。
   */
  const deleteAudioPlayer = () => {
    audioPlayer.value = null
  }

  return {
    audioPlayer,
    initAudioPlayer,
    deleteAudioPlayer,
  }
}
