import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RoleDocument from '@/store/stores/collectionModule/documents/role/RoleDocument'
import Const from '@/util/Const'

/**
 * ロールデータを操作するための処理
 */
export default function useRole() {
  // Collection modules
  const roleCollectionModule = CollectionModule.createStore(RoleDocument)

  /**
   * 対象IDの組織情報一覧を取得する。
   */
  const fetchRoles = () => roleCollectionModule.fetch()

  /**
   * 取得したロール情報一覧
   * 会員登録時に"administrator", "analyst", "movie-manager"などのロールが付与されているため、SFgoに関係のないロールは取り除く
   */
  const roles = computed(() =>
    roleCollectionModule.data.filter((role) => role.role && Const.SFGO_ROLES.includes(role.role)),
  )

  /**
   * 取得したロール情報をクリアする
   */
  const clearRoles = () => {
    roleCollectionModule.clearData()
  }

  return {
    fetchRoles,
    roles,
    clearRoles,
  }
}
