








































import { computed, defineComponent, PropType, ref, Ref } from '@vue/composition-api'
import AtomDropdown, { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'
import StoreUtil from '@/store/StoreUtil'
import { OpenFilterIdType } from '@/store/stores/pageStore/LinkPage/CalendarPageStore'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'CalendarFilterSection',
  components: { AtomDropdown },
  props: {
    placeList: {
      type: Array as PropType<DropDownDataType[]>,
      default: () => [],
    },
    categoryList: {
      type: Array as PropType<DropDownDataType[]>,
      default: () => [],
    },
  },
  setup() {
    const calendarPageStore = StoreUtil.useStore('CalendarPageStore')

    const placeFilter = ref<HTMLInputElement | null>(null)
    const categoryFilter = ref<HTMLInputElement | null>(null)

    const placeFilterMaxHeight = computed(
      () =>
        `calc(100svh - 36px - 48px - 8px - ${placeFilter.value?.getBoundingClientRect().top}px)` ||
        'none',
    )
    const categoryFilterMaxHeight = computed(
      () =>
        `calc(100svh - 36px - 48px - 8px - ${
          categoryFilter.value?.getBoundingClientRect().top
        }px)` || 'none',
    )

    /** PCブラウザかどうか */
    const isPc = DeviceInfo.isForPC()

    const handleFilterCurrentClicked = (filterId: OpenFilterIdType) => {
      calendarPageStore.openFilterId.value =
        calendarPageStore.openFilterId.value === filterId ? '' : filterId
    }

    const handleFilterPlaceOptionClicked = (option: DropDownDataType) => {
      calendarPageStore.openFilterId.value = ''
      if (calendarPageStore.selectedPlaceOptions.value.includes(option)) {
        calendarPageStore.selectedPlaceOptions.value =
          calendarPageStore.selectedPlaceOptions.value.filter((item) => item.id !== option.id)
        return
      }
      calendarPageStore.selectedPlaceOptions.value = [
        ...calendarPageStore.selectedPlaceOptions.value,
        option,
      ]
    }
    const handleFilterCategoryOptionClicked = (option: DropDownDataType) => {
      calendarPageStore.openFilterId.value = ''
      if (calendarPageStore.selectedCategoryOptions.value.includes(option)) {
        calendarPageStore.selectedCategoryOptions.value =
          calendarPageStore.selectedCategoryOptions.value.filter((item) => item.id !== option.id)
        return
      }
      calendarPageStore.selectedCategoryOptions.value = [
        ...calendarPageStore.selectedCategoryOptions.value,
        option,
      ]
    }
    const handleOverlayClicked = () => {
      calendarPageStore.openFilterId.value = ''
    }

    return {
      placeFilter,
      placeFilterMaxHeight,
      categoryFilter,
      categoryFilterMaxHeight,
      isPc,
      handleFilterCurrentClicked,
      handleFilterPlaceOptionClicked,
      handleFilterCategoryOptionClicked,
      handleOverlayClicked,
      openFilterId: calendarPageStore.openFilterId as Ref<OpenFilterIdType>,
      selectedPlaceOptions: calendarPageStore.selectedPlaceOptions as Ref<DropDownDataType[]>,
      selectedCategoryOptions: calendarPageStore.selectedCategoryOptions as Ref<DropDownDataType[]>,
    }
  },
})
