















import { computed, defineComponent, PropType } from '@vue/composition-api'
import SelectListSection from '@/components/common/SelectList/SelectListSection.vue'
import TeamCardParts from '@/components/common/card/TeamCardParts.vue'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * マイページ: お気に入りチーム選択リスト セクション
 */
export default defineComponent({
  name: 'TeamSelectListSection',
  components: {
    SelectListSection,
    TeamCardParts,
  },
  props: {
    /**
     * 表示データ
     */
    listData: {
      type: Array as PropType<TeamDocument[]>,
      required: true,
    },
    /**
     * 選択済みフラグ
     */
    selectedID: {
      type: String,
      required: true,
      defalt: '',
    },
  },
  setup(props) {
    /**
     * 選択済みを監視
     */
    const selectedItemID = computed(() => props.selectedID)
    /**
     * チーム表示名を取得する
     * @param target チーム
     */
    const getTeamName = (target: TeamDocument) => {
      if (target.teamShortName) {
        return target.teamShortName
      }
      return target.teamName
    }
    /**
     * チームロゴ画像のURL
     * @param team チーム情報
     */
    const teamLogoUrl = (team?: TeamDocument) => {
      if (!team) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(team.teamLogo)
    }
    return {
      selectedItemID,
      getTeamName,
      teamLogoUrl,
    }
  },
  methods: {
    /**
     * 選択を通知
     * @param selectedItemID : string
     */
    changeSelected(selectedItemID: string): void {
      this.$emit('selectChange', selectedItemID)
    },
  },
})
