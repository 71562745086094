var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan-select-page" },
    [
      _vm.memberType === _vm.Const.MEMBER_TYPE.FREE
        ? _c("plan-select-for-free-pane", {
            attrs: {
              selectedPlanGroupId: _vm.selectedPlanGroupId,
              procedureScreen: "register",
            },
            on: {
              handlerPlanSelect: _vm.changePlan,
              handlerPaidChangeClick: _vm.switchPlanSelectContent,
              handlerSubmit: _vm.moveToSignupComplete,
            },
          })
        : _vm._e(),
      _vm.memberType === _vm.Const.MEMBER_TYPE.PAID
        ? _c("plan-select-for-paid-pane", {
            attrs: {
              selectedPlanGroupId: _vm.selectedPlanGroupId,
              enabledLeaveButton: _vm.enabledLeaveButton,
              procedureScreen: "register",
            },
            on: {
              handlerPlanSelect: _vm.changePlan,
              handlerCancel: _vm.switchPlanSelectContent,
              handlerSubmit: _vm.moveToSignupPaymentConfirm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }