




import { defineComponent } from '@vue/composition-api'
import AppMypageReferralCodePage from '@/views/MypagePage/MypageReferralCodePage.vue'

/**
 * ブラウザ版: マイページ: 紹介コードページ
 */
export default defineComponent({
  name: 'MypageReferralCodePage',
  components: {
    AppMypageReferralCodePage,
  },
})
