














































































import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import twitterText from 'twitter-text'
import AtomTextArea from '@/components/atoms/input/AtomTextArea.vue'
import ConfirmModalSection from '@/components/common/modal/ConfirmModalSection.vue'
import HighlightsCancelIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsCancelIcon.vue'
import HighlightsSubmitIcon from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/icons/HighlightsSubmitIcon.vue'
import HighlightsSnsButtonParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightsSnsButtonParts.vue'
import DeviceInfo from '@/util/DeviceInfo'
import { HighlightOperationType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane.vue'
import I18n from '@/locales/I18n'
import CommentCountParts from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/CommentCountParts.vue'
import Const from '@/util/Const'
import { HighlightPostedSnsType } from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'

/**
 * レース動画再生画面 ハイライト コメント入力 セクションコンポーネント
 * 現状、以下の画面で利用するコンポーネントになっている
 * ハイライト作成
 * ハイライト編集
 * ハイライトコメント投稿
 */
export default defineComponent({
  name: 'CommentInputSection',
  components: {
    CommentCountParts,
    HighlightsSnsButtonParts,
    HighlightsSubmitIcon,
    HighlightsCancelIcon,
    ConfirmModalSection,
    AtomTextArea,
  },
  props: {
    /**
     * 対象ハイライトの入力状態
     */
    targetEnableInput: {
      type: Boolean,
      default: false,
    },
    /**
     * 対象ハイライトに対する入力コメント
     */
    targetInputHighlightComment: {
      type: String,
      default: '',
    },
    hasShare: {
      type: Boolean,
      default: false,
    },
    /**
     * Xにハイライトを投稿するかどうか
     */
    postX: {
      type: Boolean,
      default: false,
    },
    /**
     * Instagramにハイライトを投稿するかどうか
     */
    postInstagram: {
      type: Boolean,
      default: false,
    },
    /**
     * ハイライトスライドメニュータイプ
     * ハイライト作成、ハイライト編集画面から本コンポーネントを呼び出す時に渡ってくる
     */
    editType: {
      type: String as PropType<HighlightOperationType>,
    },
    /**
     * SNSに投稿したことがあるかどうか
     */
    postedSns: {
      type: Object as PropType<HighlightPostedSnsType>,
    },
  },
  setup(props, context) {
    const hashtag = process.env.VUE_APP_X_HASH_TAG
    const isPc = DeviceInfo.isForPC()

    /**
     * enabledInput
     * @param {string} val ハイライトの入力状態
     */
    const enabledInput = computed(() => props.targetEnableInput)

    const cancelMessage = computed(() => {
      if (props.editType === 'create') {
        // ハイライト作成の場合に表示
        return I18n.tc('RaceVideoPage.highlights.inputCancelModal.createCancelMessage')
      }
      if (props.editType === 'edit') {
        // ハイライト編集の場合に表示
        return I18n.tc('RaceVideoPage.highlights.inputCancelModal.editCancelMessage')
      }

      // ハイライトコメント投稿の場合に表示
      return I18n.tc('RaceVideoPage.highlights.comment.inputCancelModal.inputCancelMessage')
    })

    /**
     * comment
     * @param {string} val ハイライトに対するコメント
     */
    const comment = computed({
      get: () => props.targetInputHighlightComment,
      set: (val: string) => context.emit('onChangeHighlightComment', val),
    })

    /**
     * コメントの文字数
     */
    const commentLength = computed(() => {
      if (props.postX || props.postInstagram) {
        return twitterText.parseTweet(`${comment.value}\n${hashtag}`).weightedLength
      }
      return twitterText.parseTweet(comment.value).weightedLength
    })

    /**
     * コメントが文字数オーバーの場合、サブミットボタンをdisabledにする
     */
    const isSubmitDisabled = computed(
      () => commentLength.value > Const.MAX_HIGHLIGHT_COMMENT_LENGTH,
    )

    /**
     * コメントが空かどうか
     * スペースのみ、改行のみを空と扱う
     */
    const isCommentEmpty = computed(() => !comment.value.replace(/\s+/g, ''))

    // コメントキャンセル確認用モーダル
    const enabledConfirmModal = ref<boolean>(false)

    /**
     * コメント入力画面のキャンセルボタンが押下されたときの処理
     */
    const handleCancelClicked = () => {
      // 動画プレイヤーを非表示にする
      context.emit('hideHighlightPreviewVideoPlayer')
      enabledConfirmModal.value = true
    }

    return {
      hashtag,
      isPc,
      comment,
      commentLength,
      isSubmitDisabled,
      isCommentEmpty,
      enabledInput,
      cancelMessage,
      enabledConfirmModal,
      handleCancelClicked,
    }
  },
  methods: {
    /**
     * コメント投稿ボタンを押したことを親に伝える
     */
    async onPostComment() {
      if (this.enabledInput && !this.isCommentEmpty && !this.isSubmitDisabled) {
        // 入力状態でコメントがある場合
        this.$emit('handlerSubmit')
      } else {
        // 入力状態にする
        this.startInput()
      }
    },
    /**
     * 入力モード開始
     */
    startInput() {
      this.$emit('onToggleEnabledInput', true)
    },
    /**
     * キャンセルを押した際の処理
     */
    cancelInput() {
      this.enabledConfirmModal = false
      this.$emit('onToggleEnabledInput', false)
    },
    /**
     * 入力を続けるを押した際の処理
     */
    continueInput() {
      // スマホなら、動画プレイヤーを表示する
      this.$emit('showHighlightPreviewVideoPlayer')
      this.enabledConfirmModal = false
    },
    /**
     * Xに投稿するかどうかの値を変更する
     */
    onChangePostX(val: boolean) {
      this.$emit('onChangePostX', val)
    },
    /**
     * Instagramに投稿するかどうかの値を変更する
     */
    onChangePostInstagram(val: boolean) {
      this.$emit('onChangePostInstagram', val)
    },
  },
})
