var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "referral-code-section" },
    [
      _c("h6", { staticClass: "referral-code-section__title" }, [
        _vm._v(
          " " + _vm._s(_vm.$tc("MypagePage.MypageReferralCode.title")) + " "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "referral-code-section__code",
          on: {
            click: function ($event) {
              return _vm.shareCode(_vm.code)
            },
          },
        },
        [
          _c("span", { staticClass: "referral-code-section__code-value" }, [
            _vm._v(_vm._s(_vm.code)),
          ]),
          _c("i", [
            _c("img", {
              attrs: {
                src: require("@/assets/img/icon/icon_share.svg"),
                alt: "",
              },
            }),
          ]),
        ]
      ),
      _c("message-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpenModal,
            expression: "isOpenModal",
          },
        ],
        attrs: {
          message: _vm.$tc("MypagePage.MypageReferralCode.share.successful"),
        },
        on: {
          close: function ($event) {
            _vm.isOpenModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }