















import { computed, defineComponent, PropType, Ref } from '@vue/composition-api'
import { orderBy } from 'lodash'
import MypageTicketItemDetailParts from '@/components/MypageTicketListPage/MypageTicketListPane/parts/MypageTicketItemDetailParts.vue'
import DigitalTicketDataTicketTypeDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketTypeDocument'
import DigitalTicketDataTicketDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataTicketDocument'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import StoreUtil from '@/store/StoreUtil'
import { UserPurchasedTicketType } from '@/components/hook/ticket/usePurchasedTicketData'

/**
 * マイページ: チケット情報セクション
 */
export default defineComponent({
  name: 'MypageTicketItemSection',
  components: {
    MypageTicketItemDetailParts,
  },
  props: {
    /**
     * デジタルチケット 券種情報
     */
    ticketType: {
      type: Object as PropType<DigitalTicketDataTicketTypeDocument>,
      required: true,
    },
    /**
     * デジタルチケット 券種情報
     */
    purchasedTicketInfo: {
      type: Object as PropType<UserPurchasedTicketType>,
      required: true,
    },
  },
  setup(props) {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { ownOrganization } = mypagePageStore

    /**
     * ログインユーザーが購入したチケット
     */
    const purchasedTickets = computed(() =>
      orderBy(props.ticketType.tickets, 'order')?.filter((ticket) =>
        props.purchasedTicketInfo.productNumbers.all.some(
          (productNumber) => productNumber === ticket.productNumber,
        ),
      ),
    )

    return {
      ownOrganization: ownOrganization as Ref<OrganizationDocument>,
      purchasedTickets: purchasedTickets as Ref<Array<DigitalTicketDataTicketDocument>>,
    }
  },
})
