






































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'

/**
 * レース動画再生画面: GPS詳細 サーキットマップ: Auto Police
 */
export default defineComponent({
  name: 'CircuitMapImageAutoPolice',
})
