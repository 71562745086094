var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "email-change-modal-section",
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("modal-title-parts", {
              attrs: {
                title: _vm.$t(
                  "MypagePage.MypageUserEditPage.mailEditModalTitle"
                ),
              },
            }),
            _c("modal-message-parts", {
              attrs: {
                message: _vm.$t(
                  "MypagePage.MypageUserEditPage.mailEditModalMessage"
                ),
              },
            }),
            _c("fieldset-email-parts", {
              staticClass: "modal__fieldset",
              attrs: {
                showError: _vm.showEMailError,
                isReadOnly: false,
                required: false,
              },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$t(
                  "MypagePage.MypageUserEditPage.mailEditModalButton"
                ),
                isDisabled: _vm.email === "",
              },
              on: {
                push: function ($event) {
                  return _vm.changeEmail("submit")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }