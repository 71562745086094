import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * メールアドレス更新情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1669562369/URL+API
 */
export default class MailAddressChangeTokenDocument extends DocumentWrapper {
  constructor(initProps?: Partial<MailAddressChangeTokenDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/mail_address_change_token'

  idAttr = '_id'

  /**
   * メールアドレス
   */
  mailAddress: string | null = null
}
