var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "award-pane" },
    [
      _c(
        "div",
        { staticClass: "award-pane__select-year" },
        [
          _c("select-year-section", {
            attrs: { raceYear: _vm.targetYear, yearList: _vm.yearList },
            on: { change: _vm.changeSelectYear },
          }),
        ],
        1
      ),
      _c("award-header-section", {
        staticClass: "award-pane__about",
        attrs: { targetYear: _vm.targetYear },
      }),
      _vm.hasFetched
        ? _c(
            "div",
            { staticClass: "award-pane__rankings" },
            [
              _c("award-ranking-section", {
                attrs: {
                  category: "RECOMMEND_DRIVER",
                  targetYear: _vm.targetYear,
                  badgeImage: require("@/assets/img/AwardPage/badge_driver.png"),
                  title: _vm.$i18n.tc("LinkPage.AwardPage.awardDriver.title"),
                  description: _vm.$i18n.tc(
                    "LinkPage.AwardPage.awardDriver.description"
                  ),
                  rankings: _vm.awardRankings.RECOMMEND_DRIVER,
                },
              }),
              _c("award-ranking-section", {
                attrs: {
                  category: "RECOMMEND_TEAM",
                  targetYear: _vm.targetYear,
                  badgeImage: require("@/assets/img/AwardPage/badge_team.png"),
                  title: _vm.$i18n.tc("LinkPage.AwardPage.awardTeam.title"),
                  description: _vm.$i18n.tc(
                    "LinkPage.AwardPage.awardTeam.description"
                  ),
                  rankings: _vm.awardRankings.RECOMMEND_TEAM,
                },
              }),
              _c("award-ranking-section", {
                attrs: {
                  category: "PIT_CREW",
                  targetYear: _vm.targetYear,
                  badgeImage: require("@/assets/img/AwardPage/badge_pit_crew.png"),
                  title: _vm.$i18n.tc("LinkPage.AwardPage.awardPitCrew.title"),
                  description: _vm.$i18n.tc(
                    "LinkPage.AwardPage.awardPitCrew.description"
                  ),
                  rankings: _vm.awardRankings.PIT_CREW,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }