








import { defineComponent, onBeforeMount, onUnmounted } from '@vue/composition-api'
import HeaderPane from '@/components/common/Header/HeaderPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import Logger from '@/util/logger/Logger'
import StoreUtil from '@/store/StoreUtil'

/**
 * Standings 共通Wrapper
 */
export default defineComponent({
  name: 'StandingsPageWrapper',
  components: {
    HeaderPane,
    GlobalNavigationPane,
  },
  setup() {
    const notificationStore = StoreUtil.useStore('NotificationStore')
    const { fetchNotificationData } = notificationStore
    // hook
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()

    onBeforeMount(async () => {
      // リアルタイミングメッセージング通知
      await initRTM()
      subscribeRTM('information', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `StandingsPage#subscribeRTM: Receive notification event. event: ${JSON.stringify(data)}`,
        )
        fetchNotificationData()
      })
    })

    onUnmounted(() => {
      // お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
    })
  },
})
